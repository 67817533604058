import { Text } from '@traba/react-components'
import { DailyViewOfScheduleDetails } from '@traba/types'
import React from 'react'
import { ScheduleDailyViewShareShiftDetailsContent } from './ScheduleDailyViewShareShiftDetailsContent'

interface Props {
  shiftRequestParentDayDetails: DailyViewOfScheduleDetails | undefined
}

export const ScheduleDailyViewShareShiftDetails = (props: Props) => {
  const { shiftRequestParentDayDetails } = props

  return (
    <>
      <Text variant="h4">Share shift details</Text>
      <Text variant="body2">Share shift details with your team members.</Text>
      {Object.values(
        shiftRequestParentDayDetails?.shiftRequestToShiftMetadataMap ?? {},
      ).map((individualMetadata) => (
        <ScheduleDailyViewShareShiftDetailsContent
          individualMetadata={individualMetadata}
          shiftId={individualMetadata.shift.shiftId}
          key={individualMetadata.shift.shiftId}
        />
      ))}
    </>
  )
}
