import styled from 'styled-components'

import { Row } from '..'

export const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`
