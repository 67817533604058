import { SvgIcon } from '@traba/react-components'
import { Text } from '@traba/react-components'
import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'

import * as S from './Pagination.styles'

export type PaginationProps = {
  page: number
  pageSize: number
  onPageLeft: () => void
  onPageRight: () => void
  dataSize: number
}

export const Pagination = (props: PaginationProps) => {
  const { page, pageSize, dataSize, onPageLeft, onPageRight } = props
  const enabledColor = theme.colors.Grey60
  const disabledColor = theme.colors.Grey20
  const { isReactNativeApp } = useMobile()

  const canPageRight = (page + 1) * pageSize < dataSize
  const canPageLeft = page > 0
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <S.StyledSvg
        style={{ paddingRight: 2 }}
        onClick={canPageLeft ? () => onPageLeft() : undefined}
      >
        <SvgIcon
          name="left"
          color={canPageLeft ? enabledColor : disabledColor}
        />
      </S.StyledSvg>
      <Text
        style={{
          width: isReactNativeApp ? 'auto' : 70,
          textAlign: 'center',
        }}
        variant="h5"
      >
        {!dataSize
          ? '...'
          : `${page * pageSize + 1} - ${
              page * pageSize + Math.min(dataSize, pageSize)
            }`}
      </Text>

      <S.StyledSvg
        style={{ paddingLeft: 2 }}
        onClick={canPageRight ? () => onPageRight() : undefined}
      >
        <SvgIcon
          name="right"
          color={canPageRight ? enabledColor : disabledColor}
        />
      </S.StyledSvg>
    </div>
  )
}
