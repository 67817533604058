import { InvitedWorkers } from '@traba/types'
import { releaseToWorkerVerbiage } from 'src/libs/constants'

import { Badge } from '..'

export interface ReleaseBadgeProps {
  invitedWorker?: InvitedWorkers
}

export const ReleaseBadge = (props: ReleaseBadgeProps) => {
  if (props.invitedWorker === 'ALL' || !props.invitedWorker) {
    return null
  }
  return (
    <Badge
      variant="brand"
      title={releaseToWorkerVerbiage[props?.invitedWorker]}
      style={{ marginLeft: '30px' }}
    />
  )
}
