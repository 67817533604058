import {
  InvitedWorkers,
  JobStatus,
  ShiftAttribute,
  ShiftPayType,
  ShiftStatus,
  WorkerCertificationType,
  WorkerShift,
} from '@traba/types'
import { ShiftAndAddress } from 'src/hooks/useShifts'

type ShiftOrWorkerShiftProps = {
  shiftId: string
  shiftRole: string
  startTime: Date
  endTime: Date
  slotsRequested: number
  requiredCertifications?: WorkerCertificationType[]
  requiredAttributes?: ShiftAttribute[] | string[]
  timezone: string
  payType: ShiftPayType
  payRate: number
  numberOfUnits?: number
  invitedWorkers: InvitedWorkers
  shortLocation?: string
  locationName?: string
  status?: ShiftStatus
  confirmationCode?: number
  clockOutCode?: number
  slotsFilled?: number
  jobStatus?: JobStatus
}

function isWorkerShift(
  shiftItem: ShiftAndAddress | WorkerShift,
): shiftItem is WorkerShift {
  return 'shiftInfo' in shiftItem
}

export const getShiftProps = (
  shiftItem: ShiftAndAddress | WorkerShift,
): ShiftOrWorkerShiftProps => {
  if (isWorkerShift(shiftItem)) {
    const {
      shiftId,
      jobStatus,
      clockInTime,
      clockOutTime,
      locationName,
      shiftInfo,
    } = shiftItem

    const {
      shiftRole,
      startTime,
      endTime,
      slotsRequested,
      slotsFilled,
      requiredCertifications,
      requiredAttributes,
      timezone,
      payType,
      payRate,
      numberOfUnits,
      invitedWorkers,
      shortLocation,
    } = shiftInfo

    return {
      shiftId,
      shiftRole,
      startTime: clockInTime ?? startTime,
      endTime: clockOutTime ?? endTime,
      slotsRequested,
      slotsFilled,
      jobStatus,
      requiredCertifications,
      requiredAttributes,
      timezone,
      payType,
      payRate,
      numberOfUnits,
      invitedWorkers,
      locationName,
      shortLocation,
    }
  }
  const {
    shiftId,
    shiftRole,
    startTime,
    endTime,
    slotsRequested,
    slotsFilled,
    status,
    confirmationCode,
    clockOutCode,
    requiredCertifications,
    requiredAttributes,
    timezone,
    payType,
    payRate,
    numberOfUnits,
    invitedWorkers,
    locationName,
    shortLocation,
  } = shiftItem

  return {
    shiftId,
    shiftRole,
    startTime,
    endTime,
    slotsRequested,
    slotsFilled,
    status,
    confirmationCode,
    clockOutCode,
    requiredCertifications,
    requiredAttributes,
    timezone,
    payType,
    payRate,
    numberOfUnits,
    invitedWorkers,
    locationName,
    shortLocation,
  }
}
