import { SvgIconProps } from '@traba/types'

export default function AddChat({
  size,
  color = '#7A8A99',
  strokeWidth = 1.5,
  ...props
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M6.66066 2.8757L6.5071 2.91246C4.57269 3.37551 3.05486 4.94958 2.58917 6.97555C2.33453 8.08336 2.33686 9.24905 2.5915 10.3569C3.06447 12.4145 4.45573 14.127 6.3221 14.9249L6.40345 14.9597C7.2111 15.305 8.1363 14.8891 8.4677 14.0418C8.55891 13.8086 8.77609 13.6539 9.01606 13.6539H9.74678C11.7342 13.6539 13.4631 12.2241 13.9296 10.1946C14.1613 9.18678 14.1613 8.13551 13.9296 7.12769L13.8684 6.86158C13.4188 4.90543 11.9533 3.3856 10.0855 2.93851L9.82315 2.8757C8.78233 2.62656 7.70148 2.62656 6.66066 2.8757Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M8.25108 6.07445L8.25108 10.2273M10.3275 8.15088L6.17465 8.15088"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  )
}
