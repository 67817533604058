import { ModalPaddingContainer } from '@traba/react-components'
import { CardTile } from '@traba/react-components'
import React from 'react'
import { ButtonVariant, Text } from 'src/components/base'
import { Button } from 'src/components/base'

import * as styles from './ErrorModal.styles'

export type ErrorModalProps = {
  cancelFunc: () => void
  titleText: string
  bodyText: string
}

export const ErrorModal = (props: ErrorModalProps) => {
  return (
    <ModalPaddingContainer>
      <styles.ErrorModalContainer>
        <styles.ErrorIconContainer>
          <CardTile size="70px">
            <styles.ErrorIcon name="close" />
          </CardTile>
        </styles.ErrorIconContainer>
        <div style={{ marginBottom: '8px' }}>
          <Text variant="h5" center={true}>
            {props.titleText}
          </Text>
        </div>
        <Text variant="body2" center={true}>
          {props.bodyText}
        </Text>
        <Button
          variant={ButtonVariant.CANCEL}
          style={{ marginTop: '32px', width: '75%' }}
          onClick={() => props.cancelFunc()}
        >
          Cancel
        </Button>
      </styles.ErrorModalContainer>
    </ModalPaddingContainer>
  )
}
