import { SvgIconName } from 'src/assets/svg/icons'
import { Icon, Row, Text } from 'src/components/base'

import * as S from './HeaderMenu.styles'

export type HeaderMenuProps = {
  handleCloseHeaderMenu: () => void
  isHeaderMenuOpened: boolean
  menuOptions: HeaderMenuOptionProps[]
}

type HeaderMenuOptionProps = {
  label: string
  iconName?: SvgIconName
  onClick: () => void
}

export default function HeaderMenu(props: HeaderMenuProps) {
  function handleClickHeaderOption(option: HeaderMenuOptionProps) {
    option.onClick()
    props.handleCloseHeaderMenu()
  }

  return (
    <>
      {props.isHeaderMenuOpened && (
        <S.HeaderMenuOverlay
          onClick={props.handleCloseHeaderMenu}
        ></S.HeaderMenuOverlay>
      )}
      <S.HeaderMenuWrapper isHeaderMenuOpened={props.isHeaderMenuOpened}>
        <Row flexCol card>
          {props.menuOptions.map((option, index) => (
            <S.HeaderMenuItem
              key={index}
              onClick={() => handleClickHeaderOption(option)}
            >
              {option.iconName && <Icon name={option.iconName} />}
              <Text variant="body1">{option.label}</Text>
            </S.HeaderMenuItem>
          ))}
        </Row>
      </S.HeaderMenuWrapper>
    </>
  )
}
