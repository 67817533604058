import { Text } from '@traba/react-components'
import { Schedule } from '@traba/types'
import { WeekdayStr } from 'rrule'

function getRepeatsOnText(schedules: Schedule[]) {
  if (!schedules.length) {
    return ''
  }

  const rRuleWeekdayStrToDisplayableWeekdayStrMap = new Map<WeekdayStr, string>(
    [
      ['SU', 'Su'],
      ['MO', 'M'],
      ['TU', 'Tu'],
      ['WE', 'W'],
      ['TH', 'Th'],
      ['FR', 'F'],
      ['SA', 'Sa'],
    ],
  )
  const weekDaysOrder = Array.from(
    rRuleWeekdayStrToDisplayableWeekdayStrMap.keys(),
  )
  const getDisplayableWeekdayStr = (day: WeekdayStr) => {
    if (!rRuleWeekdayStrToDisplayableWeekdayStrMap.has(day)) {
      return ''
    }
    return rRuleWeekdayStrToDisplayableWeekdayStrMap.get(day)
  }
  const sortWeekDays = (days: WeekdayStr[]) => {
    return days
      .slice()
      .sort((a, b) => weekDaysOrder.indexOf(a) - weekDaysOrder.indexOf(b))
  }
  const week1RepeatsOn = schedules[0].recurringSchedule?.repeatOn
    ? sortWeekDays(schedules[0].recurringSchedule.repeatOn)
        .map(getDisplayableWeekdayStr)
        .join(', ')
    : ''
  const week2RepeatsOn =
    schedules.length > 1 && schedules[1].recurringSchedule?.repeatOn
      ? sortWeekDays(schedules[1].recurringSchedule.repeatOn)
          .map(getDisplayableWeekdayStr)
          .join(', ')
      : ''

  if (schedules.length === 1) {
    return [week1RepeatsOn]
  }

  return [week1RepeatsOn, week2RepeatsOn]
}

export const ShiftTileScheduleCadenceText = ({
  schedules,
}: {
  schedules: Schedule[]
}) => {
  const textChunks = getRepeatsOnText(schedules)

  if (textChunks.length === 1) {
    return (
      <>
        <Text variant="body1">Weekly</Text>
        <Text>{textChunks[0]}</Text>
      </>
    )
  }

  return (
    <>
      <Text variant="body1">Bi-Weekly</Text>
      <Text>Week 1: {textChunks[0]}</Text>
      <Text>Week 2: {textChunks[1]}</Text>
    </>
  )
}
