import { SvgIconProps } from '@traba/types'

export default function ({ size, color, strokeWidth, ...props }: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.06063 14.5415V9.20817M8.06063 14.5415L3.6673 11.7957C3.20777 11.5085 2.97801 11.3649 2.85265 11.1387C2.72729 10.9125 2.72729 10.6416 2.72729 10.0997V5.87484M8.06063 14.5415L10.7273 12.8748L12.454 11.7957C12.9135 11.5085 13.1432 11.3649 13.2686 11.1387C13.394 10.9125 13.394 10.6416 13.394 10.0997V5.87484M8.06063 9.20817L2.72729 5.87484M8.06063 9.20817L13.394 5.87484M2.72729 5.87484L7.00063 3.204C7.5161 2.88184 7.77383 2.72075 8.06063 2.72075C8.34742 2.72075 8.60516 2.88184 9.12063 3.204L13.394 5.87484"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M10.2271 10.2081C10.2271 10.4843 10.451 10.7081 10.7271 10.7081C11.0033 10.7081 11.2271 10.4843 11.2271 10.2081H10.2271ZM10.6018 7.61094L10.1645 7.85333L10.6018 7.61094ZM5.1288 4.63213L9.52213 7.37796L10.0521 6.52996L5.6588 3.78413L5.1288 4.63213ZM11.2271 10.2081V8.64996H10.2271V10.2081H11.2271ZM9.52213 7.37796C9.75987 7.52655 9.90836 7.61998 10.0156 7.70216C10.1143 7.77787 10.1466 7.82113 10.1645 7.85333L11.0391 7.36856C10.9316 7.17458 10.7863 7.03295 10.624 6.90851C10.4701 6.79055 10.2739 6.66858 10.0521 6.52996L9.52213 7.37796ZM11.2271 8.64996C11.2271 8.38842 11.2277 8.15744 11.2092 7.96443C11.1897 7.76082 11.1466 7.56254 11.0391 7.36856L10.1645 7.85333C10.1823 7.88552 10.2019 7.93581 10.2137 8.05969C10.2266 8.19417 10.2271 8.3696 10.2271 8.64996H11.2271Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
