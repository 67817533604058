import { CardTile } from '@traba/react-components'
import { Text } from 'src/components/base/Text'
import { TableFooter, Td } from 'src/components/Table/Table'
import { Money } from 'src/types'
import { getMoneyString } from 'src/utils/moneyUtils'

import * as S2 from '../InvoiceTable/InvoiceTable.styles'

export const MoneyTile = () => (
  <S2.TdMultiLeft>
    <CardTile
      size="40px"
      style={{
        backgroundColor: 'white',
      }}
    >
      💵
    </CardTile>
    <S2.SmallMarginLeft>
      <Text variant="h7" style={{ marginRight: '4px' }}>
        Totals
      </Text>
    </S2.SmallMarginLeft>
  </S2.TdMultiLeft>
)

export default function Footer({
  totalCharge,
  totalGrossPay,
}: {
  totalCharge: Money
  totalGrossPay: Money
}) {
  return (
    <TableFooter>
      <MoneyTile />
      <Td>&nbsp;</Td>
      <Td>&nbsp;</Td>
      <Td>
        {' '}
        {totalGrossPay.amount > 0 && (
          <Text variant="h7">{getMoneyString(totalGrossPay)}</Text>
        )}
      </Td>
      <Td>
        {' '}
        <Text variant="h7">{getMoneyString(totalCharge)}</Text>
      </Td>
    </TableFooter>
  )
}
