import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { useState } from 'react'
import Resizer from 'react-image-file-resizer'

export enum FileType {
  WORKER_AVATARS = 'worker_avatars',
  WORKER_CERTIFICATIONS = 'worker_certifications',
  WORKER_EXPERIENCE = 'worker_experience',
  WORKER_LIFTING = 'worker_lifting',
  WORKER_MEDIA = 'worker_media',
  TRAINING_VIDEOS = 'training_videos',
  SHIFT_IMAGES = 'shift_images',
  SHIFT_VIDEOS = 'shift_videos',
  COMPANY_IMAGES = 'company_images',
  BUSINESS_TIMESHEETS = 'business_timesheets',
}

export enum AcceptedImageMimeTypes {
  IMAGE_JPG = 'image/jpg',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PNG = 'image/png',
  IMAGE_GIF = 'image/gif',
  IMAGE_BMP = 'image/bmp',
  IMAGE_WEBP = 'image/webp',
  IMAGE_WILDCARD = 'image/*',
}

export enum AcceptedVideoMimeTypes {
  VIDEO_QUICKTIME = 'video/quicktime',
  VIDEO_MPEG = 'video/mpeg',
  VIDEO_MP4 = 'video/mp4',
  VIDEO_MP2T = 'video/MP2T',
  VIDEO_3GPP = 'video/3gpp',
  VIDEO_WEBM = 'video/webm',
  VIDEO_OGG = 'video/ogg',
  VIDEO_MSVIDEO = 'video/x-msvideo',
  VIDEO_WMV = 'video/x-ms-wmv',
  VIDEO_WILDCARD = 'video/*',
}

interface ResizeOptions {
  maxWidth: number
  maxHeight: number
  quality: number
  compressionFormat: string
}

interface UploaderProps {
  fileType: FileType
  media: File
  userId?: string
  resizeOptions?: ResizeOptions
  skipResize?: boolean
}

export function useFileUploader() {
  const { showError, handleError } = useAlert()
  const [isLoading, setIsLoading] = useState(false)

  async function resizeFile(
    file: File,
    resizeOptions: ResizeOptions | undefined,
  ) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        resizeOptions?.maxWidth ?? 300,
        resizeOptions?.maxHeight ?? 300,
        resizeOptions?.compressionFormat ?? 'JPEG',
        resizeOptions?.quality ?? 80,
        0,
        (uri) => {
          resolve(uri)
        },
        'file',
      )
    })
  }

  async function handleUpload({
    fileType,
    userId,
    media,
    resizeOptions,
    skipResize = true,
  }: UploaderProps) {
    if (!userId) {
      return showError(
        'Please try again or contact support if the issue persists.',
        'There was an error loading your credentials. ',
      )
    }

    setIsLoading(true)
    try {
      const compressedMedia = !skipResize
        ? ((await resizeFile(media, resizeOptions)) as File)
        : media
      const { url: uploadUrl, filePath } = await requestUpload(
        fileType,
        compressedMedia.type,
        userId,
      )

      await uploadToSignedUrl(uploadUrl, compressedMedia)
      const downloadUrl = await requestDownloadUrl(filePath)
      setIsLoading(false)
      return downloadUrl
    } catch (error) {
      handleError(
        error,
        'uploader -> requestUploadUrl',
        JSON.stringify(error),
        'There was an error uploading the file.',
      )
      return ''
    } finally {
      setIsLoading(false)
    }
  }

  async function requestUpload(
    fileType: FileType,
    mimeType: string,
    userId: string,
  ) {
    try {
      const res = await trabaApi.post('/storage/get-upload-url', {
        fileType,
        mimeType,
        id: userId,
      })
      return res.data
    } catch (error) {
      handleError(
        error,
        'uploader -> requestUploadUrl',
        JSON.stringify(error),
        'There was an error uploading the file.',
      )
      throw error
    }
  }

  async function requestDownloadUrl(filePath: string) {
    try {
      const res = await trabaApi.post('/storage/get-download-url', {
        filePath,
      })
      return res.data
    } catch (error) {
      handleError(
        error,
        'uploader -> requestDownloadUrl',
        JSON.stringify(error),
        'There was an error uploading the file.',
      )
      throw error
    }
  }

  async function uploadToSignedUrl(signedUrl: string, blob: Blob) {
    try {
      const response = await fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': blob.type,
        },
        body: blob,
      })
      return response.status
    } catch (error) {
      handleError(
        error,
        'uploader -> uploadToSignedUrl',
        JSON.stringify(error),
        'There was an error uploading the file.',
      )
      throw error
    }
  }

  return {
    isLoading,
    handleUpload,
    resizeFile,
  }
}
