import { SvgIconProps } from '@traba/types'

export default function ThumbsDown({
  size = 16,
  color = 'currentColor',
  strokeWidth = 1.5,
  ...props
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M5.25823 11.44L3.64049 7.35478C3.54767 7.12037 3.5 6.87053 3.5 6.61842L3.5 3.75C3.5 2.64543 4.39543 1.75 5.5 1.75L9.94641 1.75C10.8095 1.75 11.5752 2.30365 11.8457 3.12325L12.2582 4.37325C12.6852 5.6672 11.7215 7 10.3589 7L9.73225 7C8.62768 7 7.73225 7.89543 7.73225 9L7.73225 10.968C7.73225 11.676 7.15826 12.25 6.45021 12.25C5.92437 12.25 5.45184 11.9289 5.25823 11.44Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M1.75 6.125L1.75 2.625"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  )
}
