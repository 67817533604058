import { isToday } from 'date-fns'
import { HeaderProps } from 'react-big-calendar'
import { Row, Text } from 'src/components'
import { theme } from 'src/libs/theme'

export const CalendarViewCustomHeader = ({ label, date }: HeaderProps) => {
  const isTodayHeader = isToday(date)
  return (
    <Row style={{ height: theme.space.med }} alignCenter>
      <Text
        variant={isTodayHeader ? 'h6' : 'body1'}
        color={isTodayHeader ? theme.colors.Violet : theme.colors.Grey60}
      >
        {label}
      </Text>
    </Row>
  )
}
