import { Address, LocationResponse, Locations } from '@traba/types'

import * as yup from 'yup'

export function getAddressString(address: Address, locationName?: string) {
  try {
    const { street1, street2, city, state, postalCode } = address

    const nameStr = locationName ? locationName + ', ' : ''
    const street2Str = street2 ? street2 + ', ' : ''

    return `${nameStr}${street1}, ${street2Str}${city}, ${state} ${postalCode}`
  } catch (err) {
    console.error(
      'stringUtils -> geAddressString() ERROR. Returning empty address string instead.',
      { address },
      err,
    )
    return ''
  }
}

export function truncatedStreet1AddressString(
  address: Address,
  truncationLength = 3,
) {
  const { street1 } = address
  return street1.split(' ').slice(0, truncationLength).join(' ')
}

export function getLocationNameOrTruncatedAddress(
  location: Pick<LocationResponse, 'name' | 'address'>,
  truncationLength?: number,
) {
  return (
    location.name ||
    truncatedStreet1AddressString(location.address, truncationLength)
  )
}

export function getInitials(firstName: string, lastName: string) {
  return `${firstName?.substring(0, 1)} ${lastName?.substring(0, 1)}`
}

const email = () => yup.string().email('Invalid email')
export const validateEmail = (str: string) => email().validateSync(str)

export const validateUniqueEmails = (emails: string[]) =>
  Array.from(
    new Set(
      emails.filter((e) => {
        try {
          validateEmail(e)
          return true
        } catch (e) {
          return false
        }
      }),
    ),
  )

export const sortAlphabetically = (a: string, b: string) => {
  return a.localeCompare(b)
}

// Pulled from Ops Console codebase, but removing the '...' (could be in shared utils in the future)
export function truncateString(str: string, length = 6, showElipses = false) {
  if (str.length <= length) {
    return str
  }
  return showElipses
    ? `${str.substring(0, length)}...`
    : `${str.substring(0, length)}`
}

export function numToAlpha(num: number, lowercase?: boolean): string {
  const asciiBase = lowercase ? 97 : 65
  return String.fromCharCode(asciiBase + num)
}
