import { Tooltip } from '@mui/material'
import { makePlural } from '@traba/string-utils'
import { theme } from '@traba/theme'
import { LocationResponse } from '@traba/types'
import {
  getLocationNameOrTruncatedAddress,
  sortLocationsByName,
} from '@traba/utils'
import { Row, SvgIcon } from '../base-components'

interface Props {
  overrideText?: string
  locations?: Pick<LocationResponse, 'name' | 'address'>[]
  justifyCenter?: boolean
}

export function LocationsCountTooltip({
  overrideText,
  locations: assignedLocations = [],
  justifyCenter = false,
}: Props) {
  const locationNamesString = sortLocationsByName(assignedLocations)
    .map((loc) => getLocationNameOrTruncatedAddress(loc))
    .join(', ')

  return (
    <Tooltip title={overrideText ? undefined : locationNamesString}>
      <div>
        <Row gap={theme.space.xxs} alignCenter justifyCenter={justifyCenter}>
          <Row alignCenter>
            <SvgIcon
              name="location"
              size={16}
              color={theme.colors.MidnightBlue}
            />
          </Row>
          {overrideText ??
            `${assignedLocations.length} location${makePlural(assignedLocations.length)}`}
        </Row>
      </div>
    </Tooltip>
  )
}
