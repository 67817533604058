import '@stripe/stripe-js'

import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader'
import { datadogRum } from '@datadog/browser-rum'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AlertContextProvider } from '@traba/context'
import { firebaseConfig, FirebaseProvider } from '@traba/firebase-utils'
import { ErrorBoundary } from '@traba/react-components'
import { createBrowserHistory } from 'history'
import React from 'react'
import { createRoot } from 'react-dom/client'
import {
  QueryClient as QueryClientV3,
  QueryClientProvider as QueryClientProviderV3,
} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { FirebaseAppProvider } from 'reactfire'
import AuthProvider from 'src/providers/AuthProvider'

import App from './App'
import { ErrorBoundaryFallback } from './components/ErrorBoundaryFallback/ErrorBoundaryFallback'
import AppContextProvider from './context/appContext/AppContext'
import UserContextProvider from './context/user/UserContext'
import GlobalStyle from './GlobalStyle'
import { CalendarContextProvider } from './hooks/useCalendarContext'
import { theme } from './libs/theme'
import reportWebVitals from './reportWebVitals'

const applicationId = import.meta.env.VITE_DD_APPLICATION_ID
const clientToken = import.meta.env.VITE_DD_CLIENT_TOKEN
const ENVIRONMENTS_WITH_METICULOUS = ['development', 'staging']

declare global {
  const analytics: any
}

async function startApp() {
  if (ENVIRONMENTS_WITH_METICULOUS.includes(import.meta.env.VITE_ENV)) {
    // Note from Meticulous docs: all errors are caught and logged, so no need to surround with try/catch
    await tryLoadAndStartRecorder({
      projectId: import.meta.env.VITE_METICULOUS_PROJECT_ID,
      isProduction: false,
    })
  }

  if (applicationId && clientToken && !window.Meticulous?.isRunningAsTest) {
    datadogRum.init({
      applicationId,
      clientToken,
      site: 'us5.datadoghq.com',
      service: 'traba-business-app',
      env:
        import.meta.env.VITE_ENV === 'production'
          ? 'production'
          : 'development',
      // Identifies the unique deploy version
      version: import.meta.env.VITE_DEPLOY_VERSION ?? 'local',
      sessionSampleRate: 100,
      premiumSampleRate: 100,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'allow',
    })
    datadogRum.startSessionReplayRecording()
  }

  const history = createBrowserHistory()
  let prevPath: string | null = null

  history.listen(({ location }) => {
    if (location.pathname !== prevPath) {
      prevPath = location.pathname
      analytics.page(location.pathname.substring(1), {
        isPwa: window?.matchMedia('(display-mode: standalone)').matches,
      })
    }
  })

  if (
    import.meta.env.VITE_SENTRY_DSN &&
    !window.Meticulous?.isRunningAsTest &&
    (typeof import.meta.env.VITE_SENTRY_DISABLED === 'undefined' ||
      !JSON.parse(import.meta.env.VITE_SENTRY_DISABLED))
  ) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration()],
      debug: false,
      environment:
        import.meta.env.VITE_ENV === 'production'
          ? 'production'
          : 'development',
    })
  } else {
    console.warn('Skipping setting up Sentry. Check your environment variables')
  }

  const queryClientV3 = new QueryClientV3()
  const queryClient = new QueryClient()
  const rootElement = document.getElementById('root')
  if (!rootElement) {
    throw new Error('Root element not found')
  }
  const root = createRoot(rootElement)
  const router = createBrowserRouter([
    {
      path: '*',
      element: (
        <>
          <GlobalStyle />
          <AuthProvider>
            <ErrorBoundary fallbackComponent={ErrorBoundaryFallback}>
              <App />
            </ErrorBoundary>
          </AuthProvider>
        </>
      ),
    },
  ])
  root.render(
    <React.StrictMode>
      <MuiThemeProvider theme={theme}>
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
          <FirebaseProvider>
            <QueryClientProviderV3 client={queryClientV3}>
              <QueryClientProvider client={queryClient}>
                <UserContextProvider>
                  <AlertContextProvider>
                    <AppContextProvider>
                      <CalendarContextProvider>
                        <RouterProvider router={router} />
                      </CalendarContextProvider>
                    </AppContextProvider>
                  </AlertContextProvider>
                </UserContextProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </QueryClientProviderV3>
          </FirebaseProvider>
        </FirebaseAppProvider>
      </MuiThemeProvider>
    </React.StrictMode>,
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}

startApp()
