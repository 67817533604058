import { RemoveWorkersFromScheduleModalContent } from '@traba/react-components'
import { cancelRecurringWorkerShift } from 'src/hooks/useRecurringWorkerShiftEdit'
import { useShiftsByShiftRequestId } from 'src/hooks/useShifts'

interface Props {
  shiftRequestId?: string
  workerIds?: string[]
  showRemoveWorkersModal: boolean
  onCloseModal: () => void
  refetchWorkersOnSchedule: () => void
}

export const RemoveWorkersFromScheduleModal = (props: Props) => {
  const {
    shiftRequestId,
    workerIds,
    showRemoveWorkersModal,
    onCloseModal,
    refetchWorkersOnSchedule,
  } = props
  const { shifts, isLoading } = useShiftsByShiftRequestId(shiftRequestId, true)

  return (
    <RemoveWorkersFromScheduleModalContent
      shifts={shifts}
      workerIds={workerIds}
      isLoading={isLoading}
      showRemoveWorkersModal={showRemoveWorkersModal}
      onCloseModal={onCloseModal}
      shiftRequestId={shiftRequestId}
      refetchWorkersOnSchedule={refetchWorkersOnSchedule}
      cancelRecurringWorkerShift={cancelRecurringWorkerShift}
    />
  )
}
