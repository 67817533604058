import { theme } from '@traba/theme'
import { SvgIconProps } from '@traba/types'

export default function Location({
  size = 16,
  color = theme.colors.MidnightBlue,
  ...rest
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.6574 7.63532C13.6574 10.6455 11.2696 14.185 8.32404 14.185C5.37852 14.185 2.99071 10.6455 2.99071 7.63532C2.99071 4.62519 5.37852 2.185 8.32404 2.185C11.2696 2.185 13.6574 4.62519 13.6574 7.63532Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M6.65736 7.18499C6.65736 6.26452 7.40356 5.51833 8.32403 5.51833C9.24451 5.51833 9.9907 6.26452 9.9907 7.18499C9.9907 8.10547 9.24451 8.85166 8.32403 8.85166C7.40356 8.85166 6.65736 8.10547 6.65736 7.18499Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  )
}
