import { NoTextDivider } from 'src/components/base/Divider'
import styled from 'styled-components'

export const UpdateTimeSheetModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space.med}px;
  width: 543px;
  @media only screen and (max-width: (${({ theme }) =>
      theme.media.maxExtraSmall})) {
    width: 100%;
  }
`

export const ShiftSummary = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.xxs}px;

  @media only screen and (max-width: (${({ theme }) =>
      theme.media.maxExtraSmall})) {
    flex-direction: column;
    gap: 0;
    margin-bottom: ${({ theme }) => theme.space.med}px;
  }
`

export const ClockInNoTextDivider = styled(NoTextDivider)`
  margin: ${({ theme }) => theme.space.med}px -${({ theme }) => theme.space.xxs}px;
  @media only screen and (max-width: (${({ theme }) =>
      theme.media.maxExtraSmall})) {
    display: none;
  }
`
