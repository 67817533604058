import { END_TIME_BUFFER_FOR_NEW_ANNOUNCEMENTS_IN_HOURS } from '@traba/consts'
import { LocationResponse } from '@traba/types'
import { Shift } from '@traba/types'
import { RoleAttributeCategory } from '@traba/types'
import { addHours, isBefore } from 'date-fns'

import { getAddressString } from './stringUtils'

interface PrefillOption {
  title: string
  content: string
  selected: boolean
}

export function canSendNewAnnouncement(endTime: Date) {
  const shiftEndTimePlusBuffer = addHours(
    endTime,
    END_TIME_BUFFER_FOR_NEW_ANNOUNCEMENTS_IN_HOURS,
  )
  return isBefore(new Date(), shiftEndTimePlusBuffer)
}

export const getAnnouncementPrefillSuggestions = (
  shift: Shift,
  location: LocationResponse | undefined,
  parkingLocation: LocationResponse | undefined,
) => {
  const { attireDescription, requiredAttributes } = shift
  const results: PrefillOption[] = [
    ...(location
      ? [
          {
            title: 'Location Reminder',
            content: `Location for this shift is: ${getAddressString(
              location.address,
            )}. `,
            selected: false,
          },
        ]
      : []),
    ...(location?.locationInstructions
      ? [
          {
            title: 'Arrival Instruction',
            content: location?.locationInstructions,
            selected: false,
          },
        ]
      : []),
    ...(parkingLocation?.address
      ? [
          {
            title: 'Parking Location',
            content: `Parking location for this shift is: ${getAddressString(
              parkingLocation.address,
            )}. `,
            selected: false,
          },
        ]
      : []),
    ...(parkingLocation?.locationInstructions
      ? [
          {
            title: 'Parking Instructions',
            content: parkingLocation?.locationInstructions,
            selected: false,
          },
        ]
      : []),
    {
      title: 'Attire Reminder',
      content: `Attire requirement for this shift is: ${attireDescription}. `,
      selected: false,
    },
  ]

  const gears: string[] = []
  if (requiredAttributes) {
    requiredAttributes.map((attr) => {
      if (
        typeof attr === 'object' &&
        attr.category === RoleAttributeCategory.Gear
      ) {
        gears.push(attr.type.replace('_', ' '))
      }
    })
  }

  if (gears.length > 0) {
    results.push({
      title: 'Gear Reminder',
      content: `Remember to bring ${gears.join(', ')}. `,
      selected: false,
    })
  }
  return results
}
