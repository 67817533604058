import { useEffect } from 'react'

export const useDisableNumberInputScroll = () => {
  useEffect(() => {
    function handleWheel(e: Event) {
      if (e.target instanceof HTMLElement) {
        e.target.blur()
      }
    }

    /**
     * We use a MutationObserver to check if anything on the DOM has changed. If so, we find number
     * inputs and add event listeners to wheel events to blur the target and prevent the input from
     * changing due to scrolling. We had to use a MutationObserver because the number inputs may be
     * added to the DOM after the initial render, and as a result listeners would not be added.
     */
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const numberInputs = document.querySelectorAll('input[type="number"]')
          numberInputs.forEach((input) => {
            input.addEventListener('wheel', handleWheel, { passive: false })
          })
        }
      }
    })

    observer.observe(document.body, { childList: true, subtree: true })

    return () => {
      observer.disconnect()
      const numberInputs = document.querySelectorAll('input[type="number"]')
      numberInputs.forEach((input) => {
        input.removeEventListener('wheel', handleWheel)
      })
    }
  }, [])
}

export default useDisableNumberInputScroll
