import {
  ALL_TEXT_SETTINGS,
  BizUserSetting,
  INVOICE_RELATED_EMAILS,
  NotificationItem,
  NotificationPreferenceStatus,
  SettingsMap,
  UserNotificationSettingType,
  UserRole,
} from '@traba/types'

export const getNotificationDescription = (
  notifType: UserNotificationSettingType,
) => {
  switch (notifType) {
    case UserNotificationSettingType.SHIFT_CREATION_EMAIL:
      return 'Alerts for new shifts created'
    case UserNotificationSettingType.SHIFT_CODES_EMAIL:
      return 'Reminders for your first shift of the day (2 hours before)'
    case UserNotificationSettingType.SHIFTS_TOMORROW_EMAIL:
      return 'Reminders for your upcoming shifts (day before)'
    case UserNotificationSettingType.REVIEW_SHIFT_EMAIL:
      return 'Reminders to review your shifts'
    case UserNotificationSettingType.SHIFT_REBOOKING_EMAIL:
      return 'Reminders to rebook shifts for next week'
    case UserNotificationSettingType.SHIFT_CODES_SMS:
      return 'Text message with shift codes'
    case UserNotificationSettingType.INVOICE_CREATED_EMAIL:
      return 'New Invoice'
    case UserNotificationSettingType.INVOICE_VOIDED_EMAIL:
      return 'Invoice updates'
    case UserNotificationSettingType.INVOICE_REMINDER_EMAIL:
      return 'Invoice reminders'
    default:
      return ''
  }
}

enum NotificationPreferenceStatusDisplayText {
  ON = 'On',
  OFF = 'Off',
}

export const mapStatusToDisplayStatus = (
  status: NotificationPreferenceStatus,
) => {
  switch (status) {
    case NotificationPreferenceStatus.ALL:
      return NotificationPreferenceStatusDisplayText.ON
    case NotificationPreferenceStatus.SUPERVISOR_ONLY:
      return NotificationPreferenceStatusDisplayText.ON
    case NotificationPreferenceStatus.NONE:
      return NotificationPreferenceStatusDisplayText.OFF
  }
}

export const notifsHaveShiftsLevelSetting = new Set([
  UserNotificationSettingType.SHIFT_CODES_SMS,
  UserNotificationSettingType.SHIFTS_TOMORROW_EMAIL,
  UserNotificationSettingType.SHIFT_CODES_EMAIL,
  UserNotificationSettingType.REVIEW_SHIFT_EMAIL,
  UserNotificationSettingType.SHIFT_CREATION_EMAIL,
])

export const mapStatusToShiftSelectedStatus = (setting: NotificationItem) => {
  if (notifsHaveShiftsLevelSetting.has(setting.notificationType)) {
    switch (setting.status) {
      case NotificationPreferenceStatus.ALL:
        return 'All shifts'
      case NotificationPreferenceStatus.SUPERVISOR_ONLY:
        return "Shifts I'm supervising"
      case NotificationPreferenceStatus.NONE:
        return 'Select which shifts'
    }
  }
  return undefined
}

// Controls what type of settings user can see
const getUserNotificationSettingTypeByRole = (role?: UserRole) => {
  switch (role) {
    case UserRole.Accounting:
    case UserRole.Admin:
      return Object.values(UserNotificationSettingType)
    case UserRole.LimitedShiftCreator:
    case UserRole.ShiftCreator:
    case UserRole.Supervisor:
      return Object.values(UserNotificationSettingType).filter(
        (type) => !INVOICE_RELATED_EMAILS.includes(type),
      )
    default:
      return Object.values(UserNotificationSettingType).filter(
        (type) => !INVOICE_RELATED_EMAILS.includes(type),
      )
  }
}

export const formatNotificationData = (
  userSettingsResponse: BizUserSetting[] | undefined,
  userRole: UserRole | undefined,
) => {
  const shiftEmailsMap: {
    [key: string]: {
      [key: string]: string[]
    }
  } = {}
  getUserNotificationSettingTypeByRole(userRole).forEach((notificationType) => {
    shiftEmailsMap[notificationType] = {
      [NotificationPreferenceStatus.ALL]: [],
      [NotificationPreferenceStatus.NONE]: [],
      [NotificationPreferenceStatus.SUPERVISOR_ONLY]: [],
    }
  })

  const transformedData =
    userSettingsResponse?.reduce((acc, item) => {
      Object.values(UserNotificationSettingType).forEach((notificationType) => {
        if (acc[notificationType] === undefined) return
        const preferenceStatus = item[notificationType]

        if (!preferenceStatus) return
        acc[notificationType][preferenceStatus].push(item.locationId)
      })

      return acc
    }, shiftEmailsMap) ?? {}
  const userShiftEmailsSettings: NotificationItem[] = []
  const userInvoiceEmailsSettings: NotificationItem[] = []
  const userShiftTextsSettings: NotificationItem[] = []

  Object.keys(transformedData).forEach((setting) => {
    const settingItem = {
      notificationType: setting as UserNotificationSettingType,
      description: getNotificationDescription(
        setting as UserNotificationSettingType,
      ),
      ...getNotificationItemStatus(transformedData[setting]),
    }
    if (setting === UserNotificationSettingType.SHIFT_CODES_SMS) {
      userShiftTextsSettings.push({
        ...settingItem,
      })
    } else if (
      INVOICE_RELATED_EMAILS.includes(setting as UserNotificationSettingType)
    ) {
      userInvoiceEmailsSettings.push(settingItem)
    } else {
      userShiftEmailsSettings.push({
        ...settingItem,
      })
    }
  })
  const allSettings = userShiftEmailsSettings
    .concat(userInvoiceEmailsSettings)
    .concat(userShiftTextsSettings)
  let hasAtLeastOneOff = false
  let isAllOff = true
  const textSettingsMap: SettingsMap = {}
  const emailsSettingsMap: SettingsMap = {}
  allSettings.forEach((item) => {
    if (item.status !== NotificationPreferenceStatus.NONE) isAllOff = false
    if (item.status === NotificationPreferenceStatus.NONE)
      hasAtLeastOneOff = true
    if (ALL_TEXT_SETTINGS.includes(item.notificationType)) {
      textSettingsMap[item.notificationType] = item
    } else {
      emailsSettingsMap[item.notificationType] = item
    }
  })

  return {
    userShiftEmailsSettings,
    userInvoiceEmailsSettings,
    userShiftTextsSettings,
    textSettingsMap,
    emailsSettingsMap,
    hasAtLeastOneOff,
    isAllOff,
  }
}

export const getIfAllSettingsAreOn = (settingsMap: SettingsMap) => {
  return Object.values(settingsMap).every(
    (setting) => setting.status !== NotificationPreferenceStatus.NONE,
  )
}

export const getIfAllSettingsAreOff = (settingsMap: SettingsMap) => {
  return Object.values(settingsMap).every(
    (setting) => setting.status === NotificationPreferenceStatus.NONE,
  )
}

const getNotificationItemStatus = (data: { [key: string]: string[] }) => {
  if (data[NotificationPreferenceStatus.ALL].length > 0) {
    return {
      displayStatus: 'On',
      status: NotificationPreferenceStatus.ALL,
      locationIds: data[NotificationPreferenceStatus.ALL],
    }
  }
  if (
    NotificationPreferenceStatus.SUPERVISOR_ONLY in data &&
    data[NotificationPreferenceStatus.SUPERVISOR_ONLY]?.length > 0
  ) {
    return {
      displayStatus: 'On',
      status: NotificationPreferenceStatus.SUPERVISOR_ONLY,
      locationIds: data[NotificationPreferenceStatus.SUPERVISOR_ONLY],
    }
  }

  return {
    displayStatus: 'Off',
    status: NotificationPreferenceStatus.NONE,
    locationIds: data[NotificationPreferenceStatus.NONE],
  }
}
