import stripeLogo from 'src/assets/svg/stripe-logo.svg'

import { Row } from '..'

export const StripeLogo = () => {
  return (
    <Row my={20} center>
      <img src={stripeLogo} alt="Stripe Logo" />
    </Row>
  )
}
