import styled from 'styled-components'

export const RadioButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: ${({ theme }) => theme.colors.White};
  cursor: pointer;
`
export const SelectedRadioButton = styled.div<{ size?: number }>`
  border-radius: 50%;
  width: ${({ size }) => size || 30}px;
  height: ${({ size }) => size || 30}px;
  background: ${({ theme }) => theme.colors.Violet};
  display: flex;
  justify-self: flex-end;
`
export const NotSelectedRadioButton = styled.div<{
  disabled?: boolean
  size?: number
}>`
  border-radius: 50%;
  width: ${({ size }) => size || 30}px;
  height: ${({ size }) => size || 30}px;
  border: 2px solid ${({ theme }) => theme.colors.Grey20};
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.Grey20 : theme.colors.White};
  display: flex;
  justify-self: flex-end;
`

export const RadioButtonInside = styled.div<{ size?: number }>`
  border-radius: 50%;
  width: ${({ size }) => size || 10}px;
  height: ${({ size }) => size || 10}px;
  background: ${({ theme }) => theme.colors.White};
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
`
