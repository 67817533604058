import { theme } from '@traba/theme'
import { ConnectionType, JobStatus, Worker, WorkerShift } from '@traba/types'
import React, { useCallback } from 'react'
import { BizToWorkerConnection } from 'src/hooks/useConnections'
import { useReviews } from 'src/hooks/useReviews'
import { getIsReviewableStatus } from 'src/utils/workerShiftUtils'
import { Icon, Row } from '../../base'
import { ReviewWorkerForm } from '../../ReviewWorker/ReviewWorkerForm'
import { SlideOut } from '../../Slideout/Slideout'
import { Td, Tr } from '../../Table/Table'
import WorkerConnectionMenu, {
  WorkerConnectionActionButton,
} from '../../WorkerConnectionMenu/WorkerConnectionMenu'
import { WorkerPhotoAndName } from '../../WorkersOnShiftTable/components/WorkerPhotoAndName'
import { useInReview } from '../../WorkersOnShiftTable/WorkersOnShiftTable.hooks'
import { TimeSheetsTableLoggingSource } from '../types'

interface WorkerHeaderProps {
  worker: Worker
  connections?: BizToWorkerConnection
  editMode: boolean
  latestWorkerShift: WorkerShift
  numberOfColumns: number
  showApproveAll?: boolean
  isMobileViewOrReactNative?: boolean
  handleApproveAllWorkerShifts?: () => void
  isExpanded: boolean
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
}

export const TimeSheetsTableWorkerHeaderListItem = (
  props: WorkerHeaderProps,
) => {
  const {
    worker,
    connections,
    editMode,
    latestWorkerShift: workerShift,
    numberOfColumns,
    isMobileViewOrReactNative,
    isExpanded,
    setIsExpanded,
  } = props

  const shouldShowReview = getIsReviewableStatus(workerShift.jobStatus)

  const { inReview, toggleReview } = useInReview()
  const { reviews, refetchReviews } = useReviews({
    shiftId: workerShift.shiftId,
    workerId: workerShift.workerId,
  })
  const isWorkerReviewed = !!reviews?.length

  const onFavorite = useCallback(
    (workerId: string) => {
      connections?.favoriteWorker(workerId).then((connection) => {
        toggleReview([workerId], 'OPEN', ConnectionType.FAVORITE, connection.id)
      })
    },
    [toggleReview, connections],
  )

  const headerContent = (
    <Row
      gap={theme.space.sm}
      my={isMobileViewOrReactNative ? theme.space.xs : undefined}
      mx={isMobileViewOrReactNative ? theme.space.xs : undefined}
      alignCenter
      justifyBetween={isMobileViewOrReactNative}
      fullWidth={isMobileViewOrReactNative}
    >
      <Row alignCenter>
        <WorkerPhotoAndName
          worker={worker}
          onClickWorkerName={() => {
            window.analytics.track('Timesheets Worker Name Clicked', {
              workerId: worker.workerId,
              source:
                TimeSheetsTableLoggingSource.TIMESHEETS_TABLE_WORKER_HEADER,
            })
          }}
        />
        {isMobileViewOrReactNative && (
          <Icon
            name={isExpanded ? 'chevronUp' : 'chevronDown'}
            width={16}
            height={16}
            onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
            style={{ marginLeft: theme.space.xs }}
          />
        )}
      </Row>
      <WorkerConnectionMenu
        worker={worker}
        workerShift={workerShift}
        isBlocked={connections?.isBlocked(worker.workerId) || false}
        isFavorited={connections?.isFavorite(worker.workerId) || false}
        isIneligible={connections?.isIneligible(worker.workerId) || false}
        isScheduledBlock={
          connections?.isScheduledBlock(worker.workerId) || false
        }
        hidden={!connections || !!editMode}
        onFavorite={() => onFavorite(worker.workerId)}
        onReview={() => {
          toggleReview([workerShift.workerId], 'TOGGLE')
        }}
        isReviewed={isWorkerReviewed}
        buttonsToShow={
          workerShift.jobStatus === JobStatus.ToDo
            ? [
                WorkerConnectionActionButton.AddToRoster,
                WorkerConnectionActionButton.MessageWorker,
              ]
            : [
                WorkerConnectionActionButton.AddToRoster,
                WorkerConnectionActionButton.Block,
                WorkerConnectionActionButton.Favorite,
                WorkerConnectionActionButton.MessageWorker,
                WorkerConnectionActionButton.EditEligibilityConnections,
                ...(shouldShowReview
                  ? [WorkerConnectionActionButton.ReviewWorker]
                  : []),
              ]
        }
        alignEnd={true}
        displayAsDotMenu={true}
      />
    </Row>
  )

  const reviewContent = (
    <div
      style={{
        padding: theme.space.sm,
      }}
    >
      <ReviewWorkerForm
        variation={inReview.get(workerShift.workerId)?.variation}
        workerName={worker.firstName}
        workerId={workerShift.workerId}
        shiftId={workerShift.shiftId}
        requiredAttributes={workerShift.shiftInfo?.requiredAttributes || []}
        onSaveReview={() => refetchReviews()}
        connectionId={inReview.get(workerShift.workerId)?.connectionId}
        onClose={() => {
          toggleReview(
            [workerShift.workerId],
            'CLOSE',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            inReview.get(workerShift.workerId)!.variation,
          )
        }}
      />
    </div>
  )

  return (
    <React.Fragment key={worker.workerId}>
      {!isMobileViewOrReactNative ? (
        <Tr
          style={{
            height: 66,
          }}
        >
          <Td
            style={{
              alignItems: 'center',
              whiteSpace: 'nowrap',
            }}
            colSpan={numberOfColumns}
          >
            {headerContent}
          </Td>
        </Tr>
      ) : (
        <Row alignCenter style={{ border: `1px solid ${theme.colors.Grey20}` }}>
          {headerContent}
        </Row>
      )}
      {inReview.has(workerShift.workerId) ? (
        !isMobileViewOrReactNative ? (
          <tr>
            <td colSpan={numberOfColumns}>
              <SlideOut>{reviewContent}</SlideOut>
            </td>
          </tr>
        ) : (
          reviewContent
        )
      ) : null}
    </React.Fragment>
  )
}
