import { trabaApi } from '@traba/api-utils'
import { Invoice } from '@traba/types'
import { useQuery } from 'react-query'

const getInvoice = async (invoiceId?: string) => {
  try {
    if (!invoiceId) {
      return null
    }
    const response = await trabaApi.get(`/my-company/invoices/${invoiceId}`)
    return response.data
  } catch (error) {
    console.log('Error retrieving invoice', error)
  }
}

const getInvoices = async () => {
  try {
    const response = await trabaApi.get(`/my-company/invoices`)
    return response.data
  } catch (error) {
    console.log('Error retrieving invoices', error)
  }
}

export const useInvoice = (invoiceId?: string) => {
  const {
    isLoading,
    isError,
    data: invoice,
    error,
  } = useQuery<Invoice, Error>(['invoice', invoiceId], () =>
    getInvoice(invoiceId),
  )

  return {
    isLoading,
    isError,
    error,
    invoice,
  }
}

export const useInvoices = () => {
  const {
    isLoading,
    isError,
    data: invoices,
    error,
  } = useQuery<Invoice[], Error>('invoices', getInvoices)

  return {
    isLoading,
    isError,
    error,
    invoices: invoices || [],
  }
}
