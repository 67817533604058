export enum TransitStatusToDestination {
  NOT_LEFT = 'NOT_LEFT',
  IN_TRANSIT = 'IN_TRANSIT',
  ARRIVED = 'ARRIVED',
}

export enum OnTimeStatus {
  EXPECTED_ON_TIME = 'EXPECTED_ON_TIME',
  EXPECTED_LATE = 'EXPECTED_LATE',
  ON_TIME = 'ON_TIME',
  LATE = 'LATE',
}

export type WorkerShiftTimeToDestination = {
  shiftId: string
  workerId: string
  transitStatus: TransitStatusToDestination
  timeToDestinationSeconds: number
  onTimeStatus: OnTimeStatus
  arrivedAt?: Date
  recordedAt: Date
}

export type WorkerShiftTimeToDestinationResponse = {
  shiftId: string
  workerId: string
  transitStatus: TransitStatusToDestination
  timeToDestinationSeconds: number
  onTimeStatus: OnTimeStatus
  arrivedAt?: string
  recordedAt: string
}

export type ShiftWorkerShiftTimeToDestinationResponse = {
  workerShiftTimeToDestinations: WorkerShiftTimeToDestinationResponse[]
}

export interface WorkerShiftTimeToDestinationResponseDto {
  shiftId: string
  workerId: string
  transitStatus: TransitStatusToDestination
  timeToDestinationSeconds: number
  onTimeStatus: OnTimeStatus
  arrivedAt?: string
}

export interface ShiftWorkerShiftTimeToDestinationResponseDto {
  workerShiftTimeToDestinations?: WorkerShiftTimeToDestinationResponseDto[]
}
