import {
  COMPANY_WIDE_ID,
  COMPANY_WIDE_TEXT,
  InputStatus,
  RegionIdToRegionMap,
} from '@traba/types'
import { useCallback, useMemo } from 'react'
import { SearchSelect } from '../../SearchSelect/SearchSelect'
import {
  IMenuItem,
  MenuItemGroup,
  SELECT_ALL_OPTION_ID,
  SELECT_ALL_OPTION_TEXT,
} from '../../Select/Select'
import {
  GroupedLocationSearchSelectorLocationProps,
  RegionIdToPartialLocationMap,
} from './types'
import {
  createLocationMenuItemFromLocation,
  createRegionMenuItemGroups,
} from './utils'

interface Props {
  selectedLocationId?: string
  disabled?: boolean
  onChange?: (locationId: string | undefined) => void
  errorMessage?: string
  onBlur?: () => void
  isLoading?: boolean
  regionMap: RegionIdToRegionMap
  regionToLocationsMap: RegionIdToPartialLocationMap
  // If filterLocationId is provided, the options will be limited to the company-wide option
  // and the location with the provided filterLocationId
  filterLocationId?: string
  companyWideOptionSecondaryLabel?: string
  hideCompanyWideOption?: boolean
  showSelectAllOption?: boolean
  selectAllOptionLabel?: string
  placeholder?: string
  label?: string
  style?: React.CSSProperties
  selectStyle?: React.CSSProperties
  showClearButton?: boolean
}

export function GroupedLocationSingleSearchSelector({
  selectedLocationId,
  disabled,
  onChange,
  errorMessage,
  onBlur,
  regionMap,
  regionToLocationsMap,
  isLoading,
  filterLocationId,
  companyWideOptionSecondaryLabel,
  showSelectAllOption,
  selectAllOptionLabel,
  placeholder,
  label,
  hideCompanyWideOption,
  style,
  selectStyle,
  showClearButton,
}: Props) {
  const regionGroups: MenuItemGroup[] = useMemo(
    () => createRegionMenuItemGroups(regionToLocationsMap, regionMap),
    [regionToLocationsMap, regionMap],
  )

  const allGroups: MenuItemGroup[] = useMemo(
    () => [
      { id: COMPANY_WIDE_ID, title: '', hideTitle: true },
      ...regionGroups,
    ],
    [regionGroups],
  )

  let filteredLocation: GroupedLocationSearchSelectorLocationProps | undefined =
    undefined
  // We don't need this when we are showing all locations as options
  if (filterLocationId) {
    const allLocations = Object.values(regionToLocationsMap).flat()
    filteredLocation = allLocations.find(
      (location) => location.locationId === filterLocationId,
    )
  }

  const locationOptions: IMenuItem[] = useMemo(
    () =>
      filteredLocation
        ? [createLocationMenuItemFromLocation(filteredLocation)]
        : regionGroups.flatMap(({ id: regionId }) =>
            regionToLocationsMap[regionId].map(
              createLocationMenuItemFromLocation,
            ),
          ),
    [filteredLocation, regionGroups, regionToLocationsMap],
  )

  const selectAllOption: IMenuItem = useMemo(
    () => ({
      value: SELECT_ALL_OPTION_ID,
      label: selectAllOptionLabel ?? SELECT_ALL_OPTION_TEXT,
      groupId: COMPANY_WIDE_ID,
      secondaryLabel: '',
    }),
    [selectAllOptionLabel],
  )

  const companyWideOption: IMenuItem = useMemo(
    () => ({
      value: COMPANY_WIDE_ID,
      label: COMPANY_WIDE_TEXT,
      groupId: COMPANY_WIDE_ID,
      secondaryLabel: companyWideOptionSecondaryLabel,
    }),
    [companyWideOptionSecondaryLabel],
  )

  const allOptions: IMenuItem[] = useMemo(() => {
    const selectAllOptions = showSelectAllOption ? [selectAllOption] : []
    const companyWideOptions = hideCompanyWideOption ? [] : [companyWideOption]
    return [...selectAllOptions, ...companyWideOptions, ...locationOptions]
  }, [
    locationOptions,
    hideCompanyWideOption,
    companyWideOption,
    showSelectAllOption,
    selectAllOption,
  ])

  const selectedItem = useMemo(
    () => allOptions.find((item) => item.value === selectedLocationId),
    [allOptions, selectedLocationId],
  )

  const onSelectLocation = useCallback(
    (newItem: IMenuItem | undefined) => {
      if (onChange) {
        onChange(newItem?.value)
      }
    },
    [onChange],
  )

  return (
    <SearchSelect
      label={label !== undefined ? label : 'Location'}
      handleSelect={onSelectLocation}
      selectItem={selectedItem}
      isLoading={isLoading}
      disabled={disabled || !onChange}
      style={{ width: '100%', maxWidth: '480px', ...style }}
      labelStyle={{ fontSize: '12px' }}
      options={allOptions}
      groupByGroup
      shouldAlsoSearchSecondaryLabel
      groups={allGroups}
      onBlur={onBlur}
      inputStatus={errorMessage ? InputStatus.error : undefined}
      errorMessage={errorMessage}
      placeholder={placeholder}
      selectStyle={selectStyle}
      showClearButton={showClearButton}
    />
  )
}
