import { SvgIconProps } from '@traba/types'

export default function ({ size, color, strokeWidth, ...props }: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6708 1.41577V3.08126H8.45014V1.41577M6.78465 8.07773H12.3363C12.6429 8.07773 12.8914 7.82917 12.8914 7.52257V1.97093C12.8914 1.66433 12.6429 1.41577 12.3363 1.41577H6.78465C6.47805 1.41577 6.22949 1.66433 6.22949 1.97093V7.52257C6.22949 7.82917 6.47805 8.07773 6.78465 8.07773Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M5.50185 12.8098V14.4753H3.2812V12.8098M1.61571 19.4718H7.16734C7.47395 19.4718 7.7225 19.2232 7.7225 18.9166V13.365C7.7225 13.0584 7.47395 12.8098 7.16734 12.8098H1.61571C1.3091 12.8098 1.06055 13.0584 1.06055 13.365V18.9166C1.06055 19.2232 1.3091 19.4718 1.61571 19.4718Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M15.8397 12.8098V14.4753H13.6191V12.8098M11.9536 19.4718H17.5052C17.8118 19.4718 18.0604 19.2232 18.0604 18.9166V13.365C18.0604 13.0584 17.8118 12.8098 17.5052 12.8098H11.9536C11.647 12.8098 11.3984 13.0584 11.3984 13.365V18.9166C11.3984 19.2232 11.647 19.4718 11.9536 19.4718Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M1.06055 11.5778V8.17776C1.06055 7.61771 1.06055 7.33768 1.16954 7.12377C1.26541 6.93561 1.41839 6.78263 1.60656 6.68675C1.82047 6.57776 2.10049 6.57776 2.66055 6.57776H3.56055M18.0605 11.5778V8.17776C18.0605 7.61771 18.0605 7.33768 17.9516 7.12377C17.8557 6.93561 17.7027 6.78263 17.5145 6.68675C17.3006 6.57776 17.0206 6.57776 16.4605 6.57776H15.5605"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M2.06055 5.07776L4.56055 6.57776L2.06055 8.07776V5.07776Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M17.0605 5.07776L14.5605 6.57776L17.0605 8.07776V5.07776Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
