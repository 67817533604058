import { SvgIconProps } from 'src/types/svg-types'

export default function Warehousing({ size = 52, ...props }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 52 53" fill="none" {...props}>
      <circle cx="26.0099" cy="26.4309" r="26" fill="#F5EBFF" />
      <rect
        x="11.6498"
        y="26.4595"
        width="13.4748"
        height="13.1165"
        rx="2"
        fill="#6600CC"
      />
      <rect
        x="16.2133"
        y="26.4595"
        width="4.34741"
        height="4.95515"
        fill="white"
      />
      <rect
        x="26.8951"
        y="26.4595"
        width="13.4748"
        height="13.1165"
        rx="2"
        fill="#6600CC"
      />
      <rect
        x="31.4587"
        y="26.4595"
        width="4.34741"
        height="4.95515"
        fill="white"
      />
      <rect
        x="18.7508"
        y="11.1178"
        width="13.4748"
        height="13.1165"
        rx="2"
        fill="#6600CC"
      />
      <rect
        x="23.3144"
        y="11.1178"
        width="4.34741"
        height="4.95515"
        fill="white"
      />
    </svg>
  )
}
