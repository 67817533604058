import { Tooltip } from '@mui/material'
import { Icon, Row, Text } from 'src/components'
import { InfoIcon } from 'src/components/WorkerDetails/WorkerDetails.styles'
import { theme } from 'src/libs/theme'

export const EarlyIncentiveBadge = () => {
  const tooltipContent = `To ensure workers arrive on time, we sometimes incentivize them to show up early to shifts. You will not be billed for this extra time.`

  return (
    <Tooltip title={tooltipContent}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Row
          alignCenter
          style={{
            backgroundColor: theme.colors.Violet10,
            borderRadius: 4,
            marginTop: 3,
            paddingTop: 3,
            paddingLeft: 6,
            paddingBottom: 3,
          }}
        >
          <Text color={theme.colors.Violet70} variant="caption">
            EARLY INCENTIVE
          </Text>
          <InfoIcon hover={false}>
            <Icon
              name="info"
              style={{
                // svg is slightly off center :/
                marginLeft: 2,
                marginTop: 1,
              }}
            />
          </InfoIcon>
        </Row>
      </div>
    </Tooltip>
  )
}
