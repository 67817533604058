import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import React, { HTMLProps } from 'react'
import {
  InputErrorMessage,
  InputErrorIcon,
} from 'src/components/base/Input/Input.styles'
import * as S from './MultiSelector.styles'

interface Option<T extends string> {
  value: T
  label: string
  detail?: string
}

export interface MultiSelectorProps<T extends string> {
  options: Option<T>[]
  selectedOptions: T[]
  disabledOptions?: T[]
  onChange: (selected: T[]) => void
  error?: string
  style?: HTMLProps<HTMLDivElement>['style']
  className?: HTMLProps<HTMLDivElement>['className']
  columnGap?: number
}

export function MultiSelector<T extends string>(props: MultiSelectorProps<T>) {
  const {
    options,
    onChange,
    selectedOptions,
    disabledOptions,
    error,
    style,
    className,
    columnGap,
  } = props

  function handleSelectOption(option: Option<T>, isActive: boolean) {
    if (isActive) {
      onChange(
        selectedOptions.filter(
          (selectedOption) => selectedOption !== option.value,
        ),
      )
    } else {
      onChange([...selectedOptions, option.value])
    }
  }

  return (
    <>
      <S.MultiSelectorContainer
        style={style}
        className={className}
        columnGap={columnGap}
      >
        {options.map((option, i: number) => {
          const isActive = !!selectedOptions.filter(
            (opt) => opt === option.value,
          ).length
          const isDisabled = !!disabledOptions?.filter(
            (opt) => opt === option.value,
          )?.length

          return (
            <S.OptionItemContainer key={i}>
              <S.OptionItem
                disabled={isDisabled}
                onClick={() =>
                  !isDisabled && handleSelectOption(option, isActive)
                }
                key={i}
                isActive={isActive}
              >
                {option.label}
              </S.OptionItem>
              {option.detail ? (
                <Text style={{ fontSize: 12 }} variant="label">
                  {option.detail}
                </Text>
              ) : null}
            </S.OptionItemContainer>
          )
        })}
      </S.MultiSelectorContainer>
      {!!error && (
        <InputErrorMessage style={{ marginBottom: theme.space.med }}>
          <InputErrorIcon />
          {error}
        </InputErrorMessage>
      )}
    </>
  )
}
