import { LoadingSpinner } from '@traba/react-components'
import { Worker, WorkerShift } from '@traba/types'
import { theme } from 'src/libs/theme'

import { Button, ButtonVariant, Divider, Row, Text } from '../../base'
import { ButtonIcon } from '../../PersonalProfile/PersonalProfile.styles'
import { ReviewWorkerForm, ReviewWorkerFormProps } from '../ReviewWorkerForm'
import { ReviewWorkerShiftDisplay } from './ReviewWorkerShiftDisplay'

export interface ReviewWorkerModalContentProps {
  worker: Worker | undefined
  workerShift: WorkerShift | undefined
  variation: ReviewWorkerFormProps['variation']
  onClose: () => void
}

export function ReviewWorkerModalContent({
  worker,
  workerShift,
  variation,
  onClose,
}: ReviewWorkerModalContentProps) {
  return (
    <div style={{ width: '100%' }}>
      <Row justifyBetween alignCenter style={{ margin: theme.space.sm }}>
        <Text variant="h4">Thanks for sharing your feedback!</Text>
        <ButtonIcon name="cancel" onClick={onClose} />
      </Row>
      <Divider />
      {!worker || !workerShift ? (
        <div
          style={{
            height: 300,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <div style={{ margin: theme.space.sm }}>
          <ReviewWorkerShiftDisplay
            worker={worker}
            workerShift={workerShift}
            style={{ marginBottom: theme.space.xs }}
          />
          <ReviewWorkerForm
            variation={variation}
            workerName={worker.firstName}
            workerId={workerShift.workerId}
            shiftId={workerShift.shiftId}
            requiredAttributes={workerShift.shiftInfo?.requiredAttributes || []}
          />
        </div>
      )}
      <Divider />
      <Row justifyEnd alignCenter style={{ margin: theme.space.sm }}>
        <Button variant={ButtonVariant.FILLED} onClick={onClose}>
          Done
        </Button>
      </Row>
    </div>
  )
}
