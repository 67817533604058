import { Icon } from 'src/components/base'
import styled from 'styled-components'

export const ErrorIconContainer = styled.div`
  margin-bottom: 32px;
`

export const ErrorModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ErrorIcon = styled(Icon)`
  height: 30px;
  width: 30px;
  top: 1px;
`
