import { SvgIconProps } from 'src/types/svg-types'

export default function Settings({
  size = 24,
  color = '#7A8A99',
  strokeWidth = 1.5,
  ...rest
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.2335 14.2284C11.3864 13.9239 9.50423 13.9239 7.65713 14.2284L7.44935 14.2627C5.14264 14.643 3.44531 16.6984 3.44531 19.1115C3.44531 20.1545 4.26379 21 5.27344 21H15.6172C16.6268 21 17.4453 20.1545 17.4453 19.1115M14.5287 6.9375C14.5287 9.11212 12.7005 10.875 10.4453 10.875C8.19017 10.875 6.362 9.11212 6.362 6.9375C6.362 4.76288 8.19017 3 10.4453 3C12.7005 3 14.5287 4.76288 14.5287 6.9375Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M18.0517 12.2086C18.0517 11.9201 17.8408 11.6748 17.5555 11.6316C17.4831 11.6207 17.4095 11.6207 17.3371 11.6316C17.0518 11.6748 16.8409 11.9201 16.8409 12.2086V12.6166C16.6779 12.6632 16.5226 12.728 16.3775 12.8088L16.0888 12.5201C15.8848 12.3161 15.5623 12.2918 15.33 12.463C15.271 12.5064 15.219 12.5585 15.1755 12.6175C15.0043 12.8498 15.0286 13.1723 15.2327 13.3763L15.5213 13.6649C15.4405 13.8101 15.3757 13.9654 15.3292 14.1284H14.9211C14.6326 14.1284 14.3874 14.3392 14.3442 14.6245C14.3332 14.6969 14.3332 14.7706 14.3442 14.843C14.3874 15.1283 14.6326 15.3392 14.9211 15.3392H15.3292C15.3757 15.5022 15.4405 15.6574 15.5213 15.8026L15.2327 16.0912C15.0286 16.2953 15.0043 16.6178 15.1755 16.8501C15.219 16.909 15.271 16.9611 15.33 17.0045C15.5623 17.1757 15.8848 17.1514 16.0888 16.9474L16.3775 16.6588C16.5226 16.7395 16.6779 16.8044 16.8409 16.8509V17.2589C16.8409 17.5475 17.0518 17.7927 17.3371 17.8359C17.4095 17.8469 17.4831 17.8469 17.5555 17.8359C17.8408 17.7927 18.0517 17.5475 18.0517 17.2589V16.8509C18.2147 16.8044 18.3699 16.7395 18.5151 16.6588L18.8038 16.9474C19.0078 17.1514 19.3303 17.1757 19.5626 17.0045C19.6215 16.9611 19.6736 16.909 19.7171 16.8501C19.8882 16.6178 19.864 16.2953 19.6599 16.0912L19.3713 15.8026C19.4521 15.6574 19.5169 15.5022 19.5634 15.3392H19.9714C20.26 15.3392 20.5052 15.1283 20.5484 14.843C20.5594 14.7706 20.5594 14.6969 20.5484 14.6245C20.5052 14.3392 20.26 14.1284 19.9714 14.1284H19.5634C19.5169 13.9654 19.4521 13.8101 19.3713 13.6649L19.6599 13.3763C19.864 13.1723 19.8882 12.8498 19.7171 12.6175C19.6736 12.5585 19.6215 12.5064 19.5626 12.463C19.3303 12.2918 19.0078 12.3161 18.8038 12.5201L18.5151 12.8088C18.3699 12.728 18.2147 12.6632 18.0517 12.6166V12.2086Z"
        stroke={color}
        strokeWidth={'0.6'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3262 14.7338C16.3262 14.1149 16.8278 13.6133 17.4467 13.6133C18.0655 13.6133 18.5672 14.1149 18.5672 14.7338C18.5672 15.3526 18.0655 15.8543 17.4467 15.8543C16.8278 15.8543 16.3262 15.3526 16.3262 14.7338Z"
        stroke={color}
        strokeWidth="0.6"
      />
    </svg>
  )
}
