import { Tooltip } from '@mui/material'
import {
  ButtonVariant,
  Row,
  SvgIcon,
  Text,
  Breadcrumbs,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  DailyViewOfScheduleDetails,
  ShiftRequestParentWithShiftRequest,
} from '@traba/types'
import {
  formatRepeatsOnForSchedules,
  formatScheduleDateRange,
} from '@traba/utils'
import { format, parse } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { Col, Button as TrabaButton } from 'src/components/base'
import useMobile from 'src/hooks/useMobile'
import {
  getDailyViewUrlSlug,
  getDateInfoFromDayOfShift,
} from 'src/shared/utils/dateUtils'

interface Props {
  shiftRequestParent: ShiftRequestParentWithShiftRequest | undefined
  dayOfShift: string
  shiftRequestParentDayDetails: DailyViewOfScheduleDetails | undefined
}

export const ScheduleDailyViewHeader = (props: Props) => {
  const { shiftRequestParent, dayOfShift, shiftRequestParentDayDetails } = props
  const { isMobileViewOrReactNative } = useMobile()
  const navigate = useNavigate()
  const { dayOfWeek, month, dayOfMonth, year } =
    getDateInfoFromDayOfShift(dayOfShift)
  const firstRecurringShiftRequest = shiftRequestParent?.shiftRequests.find(
    (sr) => sr.schedules.some((s) => s.recurringSchedule),
  )
  if (
    !firstRecurringShiftRequest ||
    !shiftRequestParent ||
    !shiftRequestParentDayDetails
  ) {
    return null
  }

  const prevDate =
    shiftRequestParentDayDetails.dateOfPreviousOccurringShiftOnSchedule
  const nextDate =
    shiftRequestParentDayDetails.dateOfNextOccurringShiftOnSchedule
  const leftNavButton = (
    <Tooltip title={prevDate ? '' : 'No previous shifts'}>
      <TrabaButton
        onClick={() => {
          if (prevDate) {
            navigate(
              `/schedule/${shiftRequestParent.shiftRequestParentId}/${getDailyViewUrlSlug(new Date(prevDate))}`,
            )
          }
        }}
        style={{
          paddingLeft: theme.space.xs,
          paddingRight: theme.space.xs,
          marginRight: theme.space.xxs,
        }}
        disabled={!prevDate}
        variant={ButtonVariant.OUTLINED}
        slim={isMobileViewOrReactNative}
      >
        <SvgIcon name="left" color={theme.colors.Grey60} />
      </TrabaButton>
    </Tooltip>
  )

  const rightNavButton = (
    <Tooltip title={nextDate ? '' : 'This is the last shift in this schedule'}>
      <TrabaButton
        onClick={() => {
          if (nextDate) {
            navigate(
              `/schedule/${shiftRequestParent?.shiftRequestParentId}/${getDailyViewUrlSlug(new Date(nextDate))}`,
            )
          }
        }}
        style={{
          paddingLeft: theme.space.xs,
          paddingRight: theme.space.xs,
          marginRight: theme.space.xxs,
        }}
        variant={ButtonVariant.OUTLINED}
        slim={isMobileViewOrReactNative}
      >
        <SvgIcon name="right" color={theme.colors.Grey60} />
      </TrabaButton>
    </Tooltip>
  )

  const BREADCRUMB_ITEMS = [
    {
      label: shiftRequestParent?.title || 'Schedule',
      isLink: true,
      onClick: () => {
        navigate(`/schedule/${shiftRequestParent?.shiftRequestParentId}`)
      },
    },
    {
      label: format(
        parse(dayOfShift, 'MM-dd-yyyy', new Date()),
        'MMMM do, yyyy',
      ),
      isLink: false,
    },
  ]

  return (
    <>
      <Row
        alignCenter
        pb={theme.space.sm}
        style={{ borderBottom: `1px solid ${theme.colors.Grey}` }}
      >
        <Breadcrumbs items={BREADCRUMB_ITEMS} />
      </Row>
      <Row
        justifyBetween
        alignCenter
        pt={theme.space.sm}
        pb={theme.space.sm}
        style={{ borderBottom: `1px solid ${theme.colors.Grey}` }}
        wrap
      >
        <Row alignCenter>
          <Text variant="h4">{`${dayOfWeek}, ${month} ${dayOfMonth} ${year}`}</Text>
          <Row ml={theme.space.sm}>
            {leftNavButton}
            {rightNavButton}
          </Row>
        </Row>
        <Col style={{ alignItems: 'flex-end' }}>
          <Text>
            {formatRepeatsOnForSchedules(firstRecurringShiftRequest.schedules)}
          </Text>

          <Text variant="body1">
            {formatScheduleDateRange(firstRecurringShiftRequest.schedules)}
          </Text>
        </Col>
      </Row>
    </>
  )
}
