export enum WorkerCertificationType {
  OSHA_FORKLIFT = 'OSHA_FORKLIFT',
  FLORIDA_SECURITY_CLASS_D = 'FLORIDA_SECURITY_CLASS_D',
  TEXAS_SECURITY_LEVEL_2 = 'TEXAS_SECURITY_LEVEL_2',
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  ARIZONA_UNARMED_GUARD = 'ARIZONA_UNARMED_GUARD',
  TWIC = 'TWIC',
  DRUG_TEST_5_PANEL = 'DRUG_TEST_5_PANEL',
  DRUG_TEST_10_PANEL = 'DRUG_TEST_10_PANEL',
}

export enum WorkerCertificationStatus {
  Unapproved = 'UNAPPROVED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export interface WorkerCertification {
  workerId: string
  certificationId: string
  certificationType: WorkerCertificationType
  status: WorkerCertificationStatus
  issueDate: Date
  expirationDate?: Date
  imageUrl?: string
  imageUrls?: string[]
  fullName: string
  updateTime: Date
  createdAt?: Date
  approvedAt?: Date
}
