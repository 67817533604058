import { InputLabel } from '@mui/material'
import React from 'react'
import * as S from './SearchSelect.styles'

export interface SearchSelectPlaceholderProps {
  isValuePresent?: boolean
  placeholderText?: string
  selectStyle?: React.CSSProperties
}

export function SearchSelectPlaceholder({
  isValuePresent,
  placeholderText,
  selectStyle,
}: SearchSelectPlaceholderProps) {
  if (!placeholderText) {
    return null
  }

  return (
    <S.PlaceholderWrapper height={selectStyle?.height}>
      <InputLabel id="search-select-label" shrink={false}>
        {isValuePresent ? undefined : placeholderText}
      </InputLabel>
    </S.PlaceholderWrapper>
  )
}
