import { useAlert } from '@traba/context'
import { FileType, useFileUploader } from '@traba/hooks'
import { MediaUploader } from '@traba/react-components'
import { useForm } from '@traba/utils'
import { useFormik } from 'formik'
import { useState } from 'react'
import { Button, ButtonVariant, Text } from 'src/components/base'
import { Input, Row } from 'src/components/base'
import { Checkbox } from 'src/components/base/CheckboxThemed'
import { useTrainingVideos } from 'src/hooks/useTrainingVideos'
import { useUser } from 'src/hooks/useUser'
import { REQUIRED_FIELD_MESSAGE } from 'src/libs/constants'
import { theme } from 'src/libs/theme'
import * as yup from 'yup'

interface UploadVideoProps {
  fileType: FileType
  onClose: () => void
  onUploadSuccess?: (videoId: string) => void
}

export function UploadVideo(props: UploadVideoProps) {
  const [isRequired, setIsRequired] = useState(false)
  const [file, setFile] = useState<File | undefined>()
  const { handleOnSubmitError } = useForm()
  const { showError } = useAlert()
  const [loading, setLoading] = useState(false)
  const [videoUrl, setVideoUrl] = useState<string | undefined>()
  const { createTrainingVideo } = useTrainingVideos()
  const { user } = useUser()
  const { handleUpload } = useFileUploader()

  async function onChangeFile(f: File | undefined) {
    setFile(f)
    if (f) {
      setLoading(true)
      const url = await handleUpload({
        fileType: props.fileType || FileType.TRAINING_VIDEOS,
        media: f,
        userId: user?.uid,
      })
      setLoading(false)
      setVideoUrl(url)
    }
  }

  async function onDeleteFile() {
    setFile(undefined)
    setLoading(false)
  }

  const formik = useFormik({
    initialValues: {
      videoName: '',
    },
    validationSchema: yup.object({
      videoName: yup.string().required(REQUIRED_FIELD_MESSAGE),
    }),
    onSubmit: (values) => {
      try {
        if (!videoUrl) {
          return
        }
        setLoading(true)
        formik.setStatus({})
        const request = {
          videoLink: videoUrl,
          name: values.videoName,
          requiredForAll: isRequired,
        }
        createTrainingVideo(request, {
          onSuccess: (data) => {
            props.onClose()
            if (props.onUploadSuccess) {
              props.onUploadSuccess(data.id)
            }
          },
          onSettled: () => setLoading(false),
        })
        window.analytics.track(`User Uploaded New Training Video`, {
          ...request,
        })
      } catch (err: any) {
        handleOnSubmitError(err, formik)
        setLoading(false)
      }
    },
  })
  const { values, errors, touched } = formik
  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        label="Training Video name"
        name="videoName"
        width="100%"
        value={values.videoName}
        onChange={formik.handleChange}
        containerStyle={{ marginTop: theme.space.xs }}
        inputStatus={touched.videoName && errors.videoName ? 3 : 1}
        errorMessage={errors.videoName}
      />
      <Row my={theme.space.sm}>
        <Checkbox
          onClick={() => {
            setIsRequired(!isRequired)
          }}
          selected={isRequired}
          style={{ marginRight: theme.space.xxs }}
        />
        <Text variant="body1">
          Do you want to make this training video required for all shifts you
          post in the future?
        </Text>
      </Row>
      <MediaUploader
        fileType="video"
        label="Select video to upload"
        file={file}
        onChange={onChangeFile}
        onDelete={onDeleteFile}
        onError={showError}
        maxFileSizeMB={500}
      />
      <Row justifyBetween style={{ marginTop: theme.space.med }}>
        <Button
          type="button"
          variant={ButtonVariant.OUTLINED}
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          variant={ButtonVariant.FILLED}
          loading={loading}
          type="submit"
          disabled={videoUrl === undefined}
        >
          {loading ? 'Uploading' : 'Confirm upload'}
        </Button>
      </Row>
    </form>
  )
}
