import { MobileTab, Tabs } from '@traba/react-components'

interface Props {
  handleTabs: (e: React.SyntheticEvent<Element, Event>, val: number) => void
  tabIdx: number
}

export const MobileWorkersTabs = ({ tabIdx, handleTabs }: Props) => {
  return (
    <Tabs value={tabIdx} onChange={handleTabs} variant="scrollable">
      <MobileTab label="Today's Workers " />
      <MobileTab label="All " />
      <MobileTab label="Favorite " />
      <MobileTab label="Blocked " />
      <MobileTab label="Rosters" />
      <MobileTab label="Hired" />
    </Tabs>
  )
}
