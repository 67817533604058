import { Money } from './money'
import { JobStatus } from './worker-shift'

export enum TimesheetSource {
  INITIAL = 'initial',
  ADJUSTMENTS = 'adjustments',
  MIGRATIONS = 'migrations',
}

export interface Timesheet {
  companyId: string
  createdAt: Date
  timesheetId?: string
  source: TimesheetSource
  totalTimeWorked: number
  grossPayToWorkers: Money
  totalCharge: Money
  totalBilledTime: number
  totalPaidTime?: number
  totalUnitsWorked?: number
  totalPaidUnits?: number
  shiftId: string
  timesheetApprovalStatus: {
    isApproved: boolean
    approvedAt?: Date
    isPendingEdit?: boolean
  }
}

export const COMPLETED_JOB_STATUSES_FOR_TIMESHEETS = [
  JobStatus.Complete,
  JobStatus.Abandoned,
]
