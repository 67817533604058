import { formatDistanceToNow } from 'date-fns'

export const msFrom = (timeValue: number) => {
  const seconds = timeValue * 1000
  const minutes = seconds * 60
  const hours = minutes * 60

  return { seconds, minutes, hours }
}

export const formatTimeDistanceToNow = (
  timeToFormat: Date,
  showPrefix?: boolean,
) => {
  const formattedTimeDistance = formatDistanceToNow(timeToFormat, {
    addSuffix: true,
  })

  return showPrefix
    ? formattedTimeDistance.replace('minutes', 'mins')
    : formattedTimeDistance
        .replace('about ', '')
        .replace('minutes', 'mins')
        .replace('less than a minute ago', 'just now')
}

export const FIFTEEN_MINUTES = msFrom(15).minutes
