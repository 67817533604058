import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectDatabaseEmulator, getDatabase } from 'firebase/database'
import { getFunctions } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import React from 'react'
import {
  AuthProvider,
  DatabaseProvider,
  FunctionsProvider,
  useFirebaseApp,
} from 'reactfire'

type Props = {
  children?: React.ReactNode
}

export function FirebaseProvider({ children }: Props) {
  const app = useFirebaseApp()
  const database = getDatabase(app)
  const auth = getAuth(app)
  const storage = getStorage(app)
  const functions = getFunctions(app)

  if (
    import.meta.env.VITE_EMULATORS_OFF === undefined &&
    import.meta.env.VITE_ENV !== 'production' &&
    import.meta.env.VITE_ENV !== 'development' &&
    import.meta.env.VITE_ENV !== 'staging' &&
    import.meta.env.VITE_ENV !== 'test'
  ) {
    // Set up emulators
    connectDatabaseEmulator(database, 'localhost', 9000)
    connectStorageEmulator(storage, 'localhost', 9199)
    connectAuthEmulator(auth, 'http://localhost:9099')
  }

  return (
    <FunctionsProvider sdk={functions}>
      <AuthProvider sdk={auth}>
        <DatabaseProvider sdk={database}>{children}</DatabaseProvider>
      </AuthProvider>
    </FunctionsProvider>
  )
}
