import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { Row } from 'src/components'
import { ScheduleDetailsShiftCalendar } from './ScheduleDetailsShiftCalendar'
import { ScheduleDetailsShiftsList } from './ScheduleDetailsShiftsList'

enum DisplayView {
  LIST = 'LIST',
  CALENDAR = 'CALENDAR',
}

interface ScheduleDetailsShiftsProps {
  shiftRequestParentId: string
}

export const ScheduleDetailsShifts: React.FC<ScheduleDetailsShiftsProps> = ({
  shiftRequestParentId,
}) => {
  const [selectedView, setSelectedView] = useState(DisplayView.LIST)

  return (
    <>
      <Row alignEnd>
        <Text variant="h4" style={{ marginRight: theme.space.xxs }}>
          Shifts
        </Text>
        <Text variant="h6" color={theme.colors.Grey60}>
          in this schedule
        </Text>
      </Row>

      <Row justifyBetween mt={theme.space.xs}>
        <Row alignCenter mb={theme.space.xxs}>
          {/* TODO(polyphilz): Refactor out with ToggleButtonGroup in schedule management page */}
          <ToggleButtonGroup
            sx={{
              '& .MuiToggleButton-root': {
                backgroundColor: theme.colors.White,
                color: theme.colors.Grey60,
              },
              '& .MuiToggleButton-root:hover': {
                color: theme.colors.brand,
                backgroundColor: theme.colors.Violet30,
              },
              '& .Mui-selected': {
                backgroundColor: theme.colors.Violet10,
                color: theme.colors.brand,
              },
              '& .Mui-selected:hover': {
                backgroundColor: theme.colors.Violet10,
                color: theme.colors.brand,
              },
            }}
          >
            <ToggleButton
              value={DisplayView.LIST}
              selected={selectedView === DisplayView.LIST}
              onClick={() => {
                setSelectedView(DisplayView.LIST)
              }}
              sx={{
                width: '160px',
              }}
            >
              List view
            </ToggleButton>
            <ToggleButton
              value={DisplayView.CALENDAR}
              selected={selectedView === DisplayView.CALENDAR}
              onClick={() => {
                setSelectedView(DisplayView.CALENDAR)
              }}
              sx={{
                width: '160px',
                color: 'pink',
              }}
            >
              Calendar view
            </ToggleButton>
          </ToggleButtonGroup>
        </Row>
      </Row>
      {selectedView === DisplayView.LIST && (
        <ScheduleDetailsShiftsList
          shiftRequestParentId={shiftRequestParentId}
        />
      )}
      {selectedView === DisplayView.CALENDAR && (
        <ScheduleDetailsShiftCalendar
          shiftRequestParentId={shiftRequestParentId}
        />
      )}
    </>
  )
}
