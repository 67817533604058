import { BookShiftsProps } from '../../BookShiftsScreen'
import { BookShiftsLocationSection } from './BookShiftsLocationSection'

export function BookShiftsParkingLocationSection(props: BookShiftsProps) {
  const {
    shiftRequest: { parkingLocationId },
    shiftRequestMetadata: { activeParkingRegion },
  } = props

  return (
    <BookShiftsLocationSection
      locationId={parkingLocationId}
      activeRegion={activeParkingRegion}
    />
  )
}
