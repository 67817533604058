// OVERALL FEEDBACK

export type CompanyWorkerFeedback = {
  id: string
  createdAt: Date
  updatedAt: Date
  rating: number | null
  source: string | null
  note: string | null
  workerId: string
  companyId: string
  shiftId: string
  userId: string | null
  internalUserId: string | null
  connectionId: string | null
  companyWorkerAttributeFeedback: CompanyWorkerAttributeFeedback[]
}

export interface CreateCompanyWorkerFeedback {
  rating: number | null
  workerId: string
  shiftId: string
  note?: string | null
  connectionId?: string | null
  attributesFeedback?: CreateCompanyWorkerAttributeFeedback[]
}

export interface UpdateCompanyWorkerFeedback {
  rating?: number | null
  note?: string | null
  connectionId?: string | null
  attributesFeedback?: CreateCompanyWorkerAttributeFeedback[]
}

// ATTRIBUTE FEEDBACK

export enum CompanyWorkerAttributeFeedbackRating {
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE',
}

export interface CompanyWorkerAttributeFeedback {
  createdAt: Date
  updatedAt: Date
  rating: CompanyWorkerAttributeFeedbackRating
  roleAttributeType: string
  behaviorAttributeType: string
  companyWorkerFeedbackId: string
}

export interface CreateCompanyWorkerAttributeFeedback {
  rating: string
  roleAttributeType?: string
  behaviorAttributeType?: string
}

export interface QueryCompanyWorkerFeedbackDto {
  shiftId?: string
  workerId?: string
  companyId: string
}
