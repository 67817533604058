import { LoadingSpinner } from '@traba/react-components'
import { Button, ButtonVariant, SvgIcon, Table, Td, Tr } from 'src/components'
import { useBillingInfo } from 'src/hooks/useBilling'
import { theme } from 'src/libs/theme'

export const BillingContactsTable: React.FC<{
  isLoading: boolean
  billingEmails: string[]
}> = (props) => {
  const { patchBilling } = useBillingInfo()

  if (props.isLoading) {
    return <LoadingSpinner />
  }

  const removeEmail = async (emailToRemove: string) => {
    await patchBilling({
      additionalEmails: props.billingEmails.filter(
        (currentEmail) => currentEmail != emailToRemove,
      ),
    })
  }

  return (
    <Table
      headers={['Email', '']}
      style={{ marginBottom: theme.space.lg }}
      itemType="billing emails"
      showEmptyState
    >
      {props.billingEmails.map((email) => (
        <Tr
          key={email}
          style={{
            paddingTop: theme.space.xs,
            paddingBottom: theme.space.xs,
          }}
        >
          <Td style={{ width: '100%' }}>{email}</Td>
          <Td>
            <Button
              style={{ height: 48, width: 48, marginLeft: theme.space.sm }}
              onClick={async () => {
                removeEmail(email)
              }}
              variant={ButtonVariant.OUTLINED}
            >
              <SvgIcon name="trash" color={theme.colors.Grey50} />
            </Button>
          </Td>
        </Tr>
      ))}
    </Table>
  )
}
