import { Tooltip } from '@mui/material'
import { theme } from '@traba/theme'
import { IconName, SvgIconProps } from '@traba/types'
import { HTMLProps, MouseEventHandler } from 'react'
import { SvgIcon } from '../SvgIcon'
import * as S from './Badge.styles'

export interface BadgeProps {
  variant?: S.BadgeVariant
  round?: boolean
  title: string
  style?: HTMLProps<HTMLDivElement>['style']
  iconName?: IconName
  iconProps?: Omit<SvgIconProps, 'name'>
  dismissible?: boolean
  onDismiss?: () => void
  'aria-label'?: string | undefined
  displayIconOnRight?: boolean
  tooltipText?: string
  hoverBorderColor?: string
  hoverTextColor?: string
  onClick?: MouseEventHandler<HTMLDivElement> | undefined
  sm?: boolean
}

const BADGE_CERTIFICATION_VARIANTS = ['certification', 'blueCertification']

export function Badge({
  variant = 'info',
  round,
  title,
  iconName,
  iconProps,
  dismissible = false,
  onDismiss,
  displayIconOnRight,
  tooltipText,
  hoverBorderColor,
  hoverTextColor,
  onClick,
  ...props
}: BadgeProps) {
  let icon: JSX.Element | undefined
  if (BADGE_CERTIFICATION_VARIANTS.includes(variant)) {
    icon = (
      <SvgIcon
        name="award"
        color={S.COLOR_VARIANTS[variant].color}
        size={16}
        style={{
          ...(displayIconOnRight
            ? { marginLeft: theme.space.xxxs }
            : { marginRight: theme.space.xxxs }),
        }}
        {...iconProps}
      />
    )
  }
  if (iconName) {
    icon = (
      <SvgIcon
        color={S.COLOR_VARIANTS[variant].color}
        name={iconName}
        size={16}
        style={{
          ...(displayIconOnRight
            ? { marginLeft: theme.space.xxxs }
            : { marginRight: theme.space.xxxs }),
        }}
        {...iconProps}
      />
    )
  }
  const badge = (
    <S.BadgeContainer
      round={round}
      variant={variant}
      aria-label={props['aria-label']}
      style={{
        ...props.style,
        justifyContent: 'center',
      }}
      hoverBorderColor={hoverBorderColor}
      hoverTextColor={hoverTextColor}
      onClick={onClick}
      {...props}
    >
      {!displayIconOnRight && icon}
      {title}
      {dismissible && (
        <SvgIcon
          onClick={onDismiss}
          name="cancel"
          style={{
            ...(displayIconOnRight ? { marginRight: 10 } : { marginLeft: 10 }),
            cursor: 'pointer',
          }}
          width={9}
          height={9}
        />
      )}
      {displayIconOnRight && icon}
    </S.BadgeContainer>
  )

  return tooltipText ? (
    <Tooltip title={tooltipText}>
      <div>{badge}</div>
    </Tooltip>
  ) : (
    badge
  )
}
