import {
  CancellationSource,
  ScheduleInvitationStatus,
  WorkerShift,
} from '@traba/types'
import { Badge } from '../base-components/Badge/Badge'
import { BadgeVariant } from '../base-components/Badge/Badge.styles'

export function ScheduleInvitationBadge({
  id,
  status,
  canceledWorkerShift,
}: {
  id: string
  status: ScheduleInvitationStatus
  canceledWorkerShift?: WorkerShift
}) {
  let badgeTitle = ''
  let badgeVariant: BadgeVariant
  switch (status) {
    case ScheduleInvitationStatus.ACCEPTED:
      if (
        canceledWorkerShift &&
        canceledWorkerShift.cancellationSource !== CancellationSource.Business
      ) {
        badgeVariant = 'danger'
        if (
          canceledWorkerShift.cancellationSource === CancellationSource.Worker
        ) {
          badgeTitle = 'Declined'
        } else {
          badgeTitle = 'Removed by Traba'
        }
      } else {
        badgeTitle = 'Accepted'
        badgeVariant = 'success'
      }

      break
    case ScheduleInvitationStatus.DECLINED:
      badgeTitle = 'Declined'
      badgeVariant = 'danger'
      break

    case ScheduleInvitationStatus.RESCINDED:
      badgeTitle = 'Rescinded'
      badgeVariant = 'yellow'
      break

    default:
      badgeTitle = 'Waiting for a response'
      badgeVariant = 'warning'
      break
  }
  return <Badge key={id} title={badgeTitle} variant={badgeVariant} />
}
