import { ClickAwayListener, Switch } from '@mui/material'
import { LoadingSpinner } from '@traba/react-components'
import { useState } from 'react'
import { Col, Row, Text } from 'src/components'
import { useUserContext } from 'src/context/user/UserContext'
import { useMembers } from 'src/hooks/useMembers'
import { useHotSettings } from 'src/hooks/useSystem'
import { patchUserCommunicationPermissions } from 'src/hooks/useUserCommunicationPermissions'
import { theme } from 'src/libs/theme'
import { UserRole, UserWithRole } from 'src/types'

import SvgIcon from '../base/SvgIcon'

interface Props {
  member: UserWithRole
  handleCloseTooltip: () => void
}

export const MembersSettingsTooltipContent = ({
  member,
  handleCloseTooltip,
}: Props) => {
  const userContext = useUserContext()
  const { refetchMembers } = useMembers()
  const [isLoading, setIsLoading] = useState(false)
  const { hotSettings } = useHotSettings()
  return (
    <ClickAwayListener onClickAway={handleCloseTooltip}>
      <div>
        <Row
          alignCenter
          justifyBetween
          style={{
            paddingBottom: theme.space.xxs,
            borderBottom: `1px solid ${theme.colors.Grey20}`,
          }}
        >
          <Text variant="h5">Shifts Notifications</Text>
          <SvgIcon
            name="cancel"
            color={theme.colors.Grey50}
            onClick={handleCloseTooltip}
          />
        </Row>
        <Row alignCenter mt={theme.space.xs}>
          <Col>
            <Row alignCenter>
              <Text variant="h6">
                Permission to receive codes for all shifts
              </Text>
              {isLoading && (
                <LoadingSpinner
                  style={{ height: 24, width: 24, alignSelf: 'center' }}
                />
              )}
            </Row>
            <Text variant="body3" mt={theme.space.xxs}>
              {hotSettings?.enableEmailPreferencesForClockInOut
                ? `By enabling this, ${member.firstName} will be able to receive text messages and emails with clock in and clock out codes for all future shifts.`
                : `By enabling this, ${member.firstName} will receive text messages with clock in and clock out codes for all shifts in the future.`}
            </Text>

            {hotSettings?.enableEmailPreferencesForClockInOut ? (
              <Text variant="body3" mt={theme.space.xxs}>
                Once this is enabled, they can control their preferences from
                the Personal Settings tab on their account to start receiving
                all shift codes.
              </Text>
            ) : null}
          </Col>
          <Switch
            checked={member.communicationPermissions?.receiveAllShiftCodesSms}
            disabled={userContext.state.userProfile?.role !== UserRole.Admin}
            onChange={async () => {
              setIsLoading(true)
              await patchUserCommunicationPermissions(member.uid, {
                communicationPermissions: {
                  receiveAllShiftCodesSms:
                    !member.communicationPermissions?.receiveAllShiftCodesSms,
                },
              })

              await refetchMembers()
              setIsLoading(false)
            }}
          />
        </Row>
      </div>
    </ClickAwayListener>
  )
}
