export function useForm() {
  /**
   * Handles form onSubmit errors logging and feedback
   */
  async function handleOnSubmitError(err: any, formik: any) {
    // Log error
    console.error('formik.onSubmit ERROR', err)
    formik.setStatus({
      error: true,
      message: formErrorMessage(err),
    })
  }

  return {
    handleOnSubmitError,
  }
}

// TODO: create an error type
export function formErrorMessage(err: any) {
  if (err.name === 'FirebaseError') {
    let firebaseMessage
    // left as a switch case instead of a if statement to allow for more errors in the future
    switch (err.code) {
      case 'auth/invalid-login-credentials':
        firebaseMessage = 'Invalid email or password. Please try again.'
        break
      default:
        firebaseMessage = 'There was an error sending the form'
    }
    return firebaseMessage
  }

  if (err.response?.data?.message) return err.response.data.message

  let message = 'There was an error sending the form'
  const errorMessage =
    err.message ?? (typeof err === 'string' ? err : undefined)

  switch (errorMessage) {
    case 'auth/email-already-exists':
      message =
        'This email address is already associated with an existing account. Please log in or use a different email address.'
      break
    case 'auth/phone-number-already-exists':
      message =
        'This phone number is already associated with an existing account. Please log in or use a different phone number.'
      break
    case 'auth/invalid-password':
      message =
        'The password provided is too weak. Please enter a different password.'
      break
    case 'auth/invalid-email':
      message =
        'The email address provided is not valid. Please enter a different email address.'
      break
    case 'Error creating user: The password must be a string with at least 6 characters.':
      message = 'The password must be at least 6 characters long.'
      break
    case 'Invalid phone number':
      message =
        'The phone number provided is not valid. Please enter a different number.'
      break
  }
  return message
}
