import { Tooltip } from '@mui/material'
import { useHotSettings } from '@traba/hooks'
import { theme } from '@traba/theme'
import { WorkerShiftWithWorkerDetails } from '@traba/types'
import { useState } from 'react'
import useMobile from 'src/hooks/useMobile'
import { getReadableTimeInTimezone } from 'src/shared/utils/dateUtils'
import { Icon, Row, Text } from '../base'
import { WorkerAction } from '../WorkersManagementMenu/WorkersManagementMenu'
import ClockInAndOutTooltip from './ClockInAndOutTooltip'

interface EditableTimeProps {
  canEdit: boolean
  onChange: (newTime: Date) => void
  startDate: Date
  endDate: Date
  value: Date
  timezone: string
  workerShift: WorkerShiftWithWorkerDetails
  workerAction: WorkerAction
  showPendingClockOut?: boolean
}

interface TimeTextProps {
  showWarning?: boolean
  value: string
  isMobileViewOrReactNative: boolean
}

function TimeText(props: TimeTextProps) {
  const { showWarning, value, isMobileViewOrReactNative } = props
  const textVariant = showWarning ? 'h6' : undefined
  const textColor = showWarning
    ? theme.colors.Yellow80
    : theme.colors.MidnightBlue

  return (
    <Text
      mt={isMobileViewOrReactNative ? theme.space.xxxs : undefined}
      variant={isMobileViewOrReactNative ? 'body2' : textVariant}
      color={textColor}
    >
      {showWarning && '⚠️\u00A0'}
      {value}
    </Text>
  )
}

export function EditableTime(props: EditableTimeProps) {
  const { isMobileViewOrReactNative } = useMobile()
  const { hotSettings } = useHotSettings()
  const {
    canEdit,
    onChange,
    value,
    timezone,
    workerShift,
    showPendingClockOut,
  } = props
  const [isEditing, setEditing] = useState(false)

  const autoClockOutTimeInHour =
    hotSettings?.processPendingClockOutsEndBeforeSubHours
      ? hotSettings?.processPendingClockOutsEndBeforeSubHours + 0.5
      : 1
  const autoClockOutTimeInHourText =
    autoClockOutTimeInHour <= 1
      ? `${autoClockOutTimeInHour} hour`
      : `${autoClockOutTimeInHour} hours`
  const tooltipText = showPendingClockOut
    ? 'The worker did not confirm their time yet. ' +
      `They will be auto-confirmed within ${autoClockOutTimeInHourText}, ` +
      'or you can confirm their time by clicking the edit icon.'
    : ''

  return (
    <Tooltip
      key={workerShift.workerId}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      onClose={() => {
        isEditing && setEditing(false)
      }}
      open={isEditing}
      placement="bottom-start"
      sx={{ m: 1 }}
      PopperProps={{
        disablePortal: false, // display outside of container
      }}
      componentsProps={{
        tooltip: {
          style: {
            maxWidth: 500,
            minWidth: 360,
            background: theme.colors.White,
            border: `1px solid ${theme.colors.Grey20}`,
            padding: 0,
          },
        },
      }}
      title={
        <div>
          <ClockInAndOutTooltip
            onConfirm={(newTime) => {
              newTime && onChange(newTime)
              setEditing(false)
            }}
            handleClose={() => {
              setEditing(false)
            }}
            startDate={props.startDate}
            endDate={props.endDate}
            defaultDate={props.value}
            timezone={props.timezone}
            workerShift={props.workerShift}
            workerAction={props.workerAction}
          />
        </div>
      }
    >
      <div>
        <Row alignCenter gap={theme.space.xxs}>
          <Tooltip title={tooltipText} placement="bottom-start">
            <div>
              <TimeText
                showWarning={showPendingClockOut}
                value={getReadableTimeInTimezone(value, timezone, true)}
                isMobileViewOrReactNative={isMobileViewOrReactNative}
              />
            </div>
          </Tooltip>
          {canEdit && (
            <Icon name="editPurple" onClick={() => setEditing(true)} />
          )}
        </Row>
      </div>
    </Tooltip>
  )
}
