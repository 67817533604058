import { InputStatus } from '@traba/types'
import styled, { createGlobalStyle } from 'styled-components'
import { RequiredProps } from '../Required'

const extractHeightFromStyle = (height?: number | string) => {
  if (typeof height === 'number') {
    return height
  }
  if (typeof height === 'string') {
    return parseInt(height.replace('px', ''))
  }
  return undefined
}

export const SearchSelectContainer = styled.div`
  width: ${({ style }) => style?.width};
  margin: ${({ style }) => style?.margin};
  max-width: 100%;

  #search-select {
    font-family: 'Poppins';
    color: ${({ theme }) => theme.colors.MidnightBlue};
    font-size: 14px;
    z-index: 2;
  }
  #search-select-label {
    font-family: 'Poppins';
    z-index: 2;
  }
  .MuiOutlinedInput-root {
    height: 40px;
    border-radius: 8px;
    background-color: white;
  }
`

export const PlaceholderWrapper = styled.div<{ height?: number | string }>`
  // this top override is needed in order to center our placeholder within
  // the material UI component
  top: ${({ height }) => {
    const defaultHeightOfSelect = 40
    const extractedHeight =
      extractHeightFromStyle(height) || defaultHeightOfSelect
    return `${-4 + (extractedHeight - defaultHeightOfSelect) / 2}px`
  }};

  .MuiInputLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.Grey50};
    font-size: 14px;
    font-weight: 300;
  }
`

export const SearchSelectBoxStyling = createGlobalStyle`
  #search-text-field {
    font-family: 'Poppins';
  }
  .MuiOutlinedInput-root.Mui-focused fieldset {
    border: ${({ theme }) => `1px solid ${theme.colors.brand} !important`};
  }
  .MuiOutlinedInput-root.Mui-disabled fieldset {
    background: ${({ theme }) => theme.colors.Grey10};
    border-color: ${({ theme }) => `${theme.colors.Grey20} !important`};
  }
  .MuiOutlinedInput-input.Mui-disabled {
    z-index: 2;
  }
  .MuiOutlinedInput-root fieldset {
    border-radius: 8px !important;
  }
  .MuiInputLabel-root {
    z-index: 2;
  }
`
export const SearchSelectLabel = styled.label<RequiredProps>`
  top: -10px;
  left: 12px;
  position: absolute;
  background-color: white;
  border-radius: 4px;
  padding: 0px 5px 0px 5px;
  font-size: 1.1em;
  transition: 0.2s;
  pointer-events: none;
  font-size: 14px;
  font-weight: 500;
  z-index: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  color: ${({ theme, inputStatus }) => {
    switch (inputStatus) {
      case InputStatus.error:
        return theme.error.border
      default:
        return theme.colors.Grey50
    }
  }};
`
