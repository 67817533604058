import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Col, Row } from 'src/components'

interface ColHeaderAndContentProps {
  header: string
  content: string
}

export const ColHeaderAndContent: React.FC<ColHeaderAndContentProps> = ({
  header,
  content,
}) => {
  return (
    <Col>
      <Row>
        <Text variant="caption">{header}</Text>
      </Row>
      <Row mt={theme.space.xxs}>
        <Text
          variant="caption"
          style={{ fontSize: 14 }}
          color={theme.colors.MidnightBlue}
        >
          {content}
        </Text>
      </Row>
    </Col>
  )
}
