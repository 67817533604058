import { useAlert } from '@traba/context'
import { DotMenu, LoadingSpinner, MODAL_SIZE } from '@traba/react-components'
import {
  ChatEndedStatus,
  ChatMessageDeletedStatus,
  ChatMessageDeliveryStatus,
  ChatSummary,
} from '@traba/types'
import { useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate } from 'react-router-dom'
import {
  Avatar,
  ButtonVariant,
  Modal,
  Row,
  SvgIcon,
  Text,
} from 'src/components/base'
import { Dialog } from 'src/components/base/Dialog/Dialog'
import { BlockWorkerForm } from 'src/components/BlockWorker/BlockWorker'
import { InviteWorkersToShiftModal } from 'src/components/Modals/InviteWorkerToShiftModal/InviteWorkersToShiftModal'
import { updateChatMessage, useMessages } from 'src/hooks/useChatMessages'
import { updateChat } from 'src/hooks/useChats'
import { useCompany } from 'src/hooks/useCompany'
import { useCompanyWorkersByIds } from 'src/hooks/useCompanyWorkers'
import { useConnections } from 'src/hooks/useConnections'
import { useWorkerById } from 'src/hooks/useWorker'
import { theme } from 'src/libs/theme'
import { ClickableRow } from 'src/screens/BookShifts/BookShiftsScreen.styles'
import { IconName } from 'src/types/svg-types'

import { MessageInputSection } from './MessageInputSection'
import MessageItem from './MessageItem'

interface Props {
  selectedChat: ChatSummary
  setSelectedChat: React.Dispatch<React.SetStateAction<ChatSummary | undefined>>
  handleGoToMessagingCenterClick?: () => void
  handleClickClose?: () => void
  refetchChats: () => void
  isMobileView?: boolean
  buttonRef?: React.RefObject<HTMLButtonElement>
}

export const MessagesPanel = ({
  setSelectedChat,
  selectedChat,
  handleGoToMessagingCenterClick,
  handleClickClose,
  refetchChats,
  isMobileView,
  buttonRef,
}: Props) => {
  const {
    summaryId,
    name,
    profilePictureUrl,
    recipient: { workerId },
    companyEndedChatStatus,
  } = selectedChat ?? {}

  const [showBlockModal, setShowBlockModal] = useState(false)
  const [showEndChatModal, setShowEndChatModal] = useState(false)
  const [inviteWorkerOpen, setInviteWorkerOpen] = useState(false)

  const navigate = useNavigate()
  const { company, isLoading: isLoadingCompany } = useCompany()
  const { data: worker, isLoading: isLoadingWorker } = useWorkerById(workerId)
  const { workers: workersDetailsList = [], isLoading: isLoadingShiftHistory } =
    useCompanyWorkersByIds({ workerIds: worker?.uid ? [worker?.uid] : [] })
  const {
    messages: rawMessages,
    isLoading: isLoadingMessages,
    refetch: refetchMessages,
    hasNextPage,
    fetchNextPage,
  } = useMessages(summaryId)

  const messages = rawMessages?.pages.map((page) => page?.data).flat()
  const { favoriteWorker, isLoading: isLoadingConnections } = useConnections()
  const { showError, showSuccess } = useAlert()

  const isFromChatWidget = useMemo(
    () => !!handleGoToMessagingCenterClick,
    [handleGoToMessagingCenterClick],
  )

  const isLoading =
    isLoadingMessages ||
    isLoadingWorker ||
    isLoadingShiftHistory ||
    isLoadingConnections ||
    isLoadingCompany

  useEffect(() => {
    refetchMessages()
    buttonRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [buttonRef, companyEndedChatStatus, refetchMessages, summaryId])

  useEffect(() => {
    let intervalId: number
    // Check most recent 10 messages for refetching for undelivered messages, don't want to check all messages just in case there's bad data and cause it to refetch forever
    const recentUndeliveredMessages = messages
      ?.slice(0, 10)
      .filter(
        (message) =>
          message.deliveryStatus === ChatMessageDeliveryStatus.UNDELIVERED,
      )
    if (recentUndeliveredMessages && recentUndeliveredMessages.length > 0) {
      intervalId = window.setInterval(refetchMessages, 3000)
    }
    return () => clearInterval(intervalId)
  }, [messages, refetchMessages])

  const actions = [
    {
      title: 'End chat',
      iconName: 'closeChat' as IconName,
      color: theme.colors.MidnightBlue,
      onClick: () => {
        setShowEndChatModal(true)
      },
    },
    {
      title: 'Favorite worker',
      iconName: 'heart' as IconName,
      color: theme.colors.MidnightBlue,
      onClick: async () => {
        await favoriteWorker(workerId)
        showSuccess('Worker favorited')
      },
    },
    {
      title: 'Block worker',
      iconName: 'block' as IconName,
      color: theme.colors.red,
      onClick: () => {
        window.analytics.track(`User Clicked Block Worker`, {
          workerName: name,
        })
        setShowBlockModal(true)
      },
    },
  ]

  return (
    <div
      style={{
        alignItems: 'center',
        width: isFromChatWidget ? '100%' : '60%',
        height: isFromChatWidget ? '100%' : 800,
        borderLeftColor: theme.colors.Grey30,
        borderLeftWidth: isFromChatWidget ? 0 : 1,
        borderLeftStyle: 'solid',
        marginLeft: isFromChatWidget ? 0 : theme.space.xs,
        paddingLeft: isFromChatWidget ? 0 : theme.space.sm,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Row
            justifyBetween
            alignCenter
            pb={theme.space.xxs}
            pr={isFromChatWidget ? theme.space.xxs : theme.space.sm}
            fullWidth
            style={{
              backgroundColor: theme.colors.White,
              marginTop: isFromChatWidget ? theme.space.xxs : 0,
              borderBottomColor: theme.colors.Grey20,
              borderBottomWidth: 1,
              borderStyle: 'solid',
            }}
          >
            <Row alignCenter>
              <SvgIcon
                name={isFromChatWidget ? 'back' : 'doubleForward'}
                color={theme.colors.Grey60}
                size={20}
                onClick={() => {
                  setSelectedChat(undefined)
                }}
                style={{
                  marginRight: isFromChatWidget
                    ? theme.space.xxs
                    : theme.space.sm,
                }}
              />
              <ClickableRow
                alignCenter
                onClick={() => {
                  navigate(`/worker/${workerId}`)
                }}
              >
                <Avatar
                  src={profilePictureUrl}
                  name={name ?? 'worker'}
                  size={isFromChatWidget ? 40 : 50}
                />
                <Text variant={'h5'} ml={theme.space.xxs} mr={theme.space.xxs}>
                  {name}
                </Text>
              </ClickableRow>
              {isFromChatWidget && (
                <DotMenu
                  buttonVariant={ButtonVariant.TEXT}
                  type="worker-management"
                  menuItems={actions}
                  style={{
                    height: isFromChatWidget ? 30 : 40,
                    width: isFromChatWidget ? 30 : 40,
                    padding: 0,
                  }}
                />
              )}
            </Row>
            {!isFromChatWidget && (
              <DotMenu
                buttonVariant={ButtonVariant.TEXT}
                type="worker-management"
                menuItems={actions}
                style={{
                  height: isFromChatWidget ? 30 : 40,
                  width: isFromChatWidget ? 30 : 40,
                  padding: 0,
                }}
              />
            )}
            {isFromChatWidget && (
              <Row>
                <SvgIcon
                  name={'link'}
                  color={theme.colors.Grey60}
                  size={20}
                  style={{ marginRight: theme.space.xxs }}
                  onClick={handleGoToMessagingCenterClick}
                />
                <SvgIcon
                  name={'cancel'}
                  color={theme.colors.Grey60}
                  size={20}
                  onClick={() => {
                    if (handleClickClose) {
                      handleClickClose()
                    }
                  }}
                />
              </Row>
            )}
          </Row>
          <div
            style={{
              width: '100%',
            }}
          >
            <InfiniteScroll
              style={{
                overflow: 'scroll',
                width: 'auto',
                flexGrow: 1,
                overflowY: 'scroll',
                flexDirection: 'column-reverse',
                display: 'flex',
              }}
              height={isFromChatWidget ? 440 : 480}
              dataLength={messages?.length ?? 0}
              next={async () => fetchNextPage()}
              hasMore={!!hasNextPage}
              loader={
                <LoadingSpinner
                  style={{ alignSelf: 'center', textAlign: 'center' }}
                />
              }
              endMessage={
                <Text
                  style={{ textAlign: 'center' }}
                  variant="body2"
                  my={theme.space.xxs}
                >
                  - This is the beginning of all messages. -
                </Text>
              }
              inverse={true}
            >
              {messages?.map((message, index) => (
                <MessageItem
                  isMobileView={isMobileView}
                  message={message}
                  key={index}
                  profilePictureUrl={profilePictureUrl}
                  logoUrl={company?.companyLogo}
                  companyName={company?.employerName}
                  isFromChatWidget={isFromChatWidget}
                  onDeleteMessage={async (messageId: string) => {
                    try {
                      await updateChatMessage(
                        { deletedStatus: ChatMessageDeletedStatus.DELETED },
                        messageId,
                      )
                      refetchMessages()
                      showSuccess('Message deleted')
                    } catch (error) {
                      showError('Error deleting message, please try again')
                    }
                  }}
                />
              ))}
            </InfiniteScroll>
          </div>
          <div
            style={{
              width: '100%',
              marginBottom: isFromChatWidget ? theme.space.xs : 0,
            }}
          >
            <MessageInputSection
              setSelectedChat={setSelectedChat}
              isFromChatWidget={isFromChatWidget}
              buttonRef={buttonRef}
              summaryId={summaryId}
              companyEndedChatStatus={companyEndedChatStatus}
              setInviteWorkerOpen={setInviteWorkerOpen}
            />
          </div>
        </>
      )}

      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showBlockModal}
        handleClose={() => setShowBlockModal(false)}
      >
        <BlockWorkerForm
          onClose={() => setShowBlockModal(false)}
          worker={worker}
          workerShift={workersDetailsList[0]?.shiftHistory.mostRecentShift}
        />
      </Modal>

      <InviteWorkersToShiftModal
        workers={worker ? [worker] : undefined}
        isOpen={inviteWorkerOpen}
        handleModalClose={() => setInviteWorkerOpen(false)}
        refetchMessages={refetchMessages}
        refetchChats={refetchChats}
        isFromChat={true}
      />
      <Dialog
        open={showEndChatModal}
        onClose={() => setShowEndChatModal(false)}
        size={MODAL_SIZE.SMALL}
        dialogTitle={`End chat with ${name}`}
        onConfirmCTA="End chat"
        onConfirm={async () => {
          try {
            await updateChat(
              { companyEndedChatStatus: ChatEndedStatus.ENDED },
              [summaryId],
            )
            refetchChats()
            setShowEndChatModal(false)
            setSelectedChat(undefined)
            showSuccess('Chat has been ended')
          } catch (error) {
            showError('Error ending chat, please try again')
          }
        }}
        confirmButtonVariant={ButtonVariant.CANCEL}
      >
        <Text variant="body2">
          Ending this chat will close this ongoing conversation and prevent the
          worker from responding to you.
        </Text>
        <Text variant="body2" style={{ marginTop: theme.space.xs }}>
          If you want to communicate with this worker again, you can start a new
          message.
        </Text>
      </Dialog>
    </div>
  )
}
