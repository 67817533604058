import { Shift, ShiftPayType } from '@traba/types'
import { getShiftTimeString, truncateString } from '@traba/utils'
import React, { memo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  ButtonVariant,
  Col,
  Icon,
  Row,
  Text,
} from 'src/components/base'
import useMobile from 'src/hooks/useMobile'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { theme } from 'src/libs/theme'
import { getMoneyString, getPayUnitString } from 'src/utils/moneyUtils'

import { ClockCode } from '../base/ClockCode'
import { ShiftBadges } from '../ShiftBadges'
import { ShiftStatusBadge } from '../ShiftBadges/ShiftStatusBadge'
import { WorkersOnShiftTable } from '../WorkersOnShiftTable'
import { ShiftTileUIProps } from './ShiftTile.hooks'
import * as S from './ShiftTile.styles'
import { ShiftTileScheduleCadenceText } from './ShiftTileScheduleCadenceText'
import { isInProgressOrStartsInThreshold } from './ShiftTileTimebarUtils'
import ShiftTypeBadge, { ShiftType } from './ShiftTypeBadge'

export const MobileShiftTileWithWorkersList: React.FC<
  ShiftTileUIProps & ShiftAndAddress
> = memo((props) => {
  const {
    shiftId,
    shiftRole,
    endTime,
    slotsRequested,
    status,
    confirmationCode,
    clockOutCode,
    showClockCodes,
    requiredCertifications,
    timezone,
    path,
    userCanViewWages,
    shortLocation,
    slotsRemaining,
    locationName,
    payType,
    payRate,
    numberOfUnits,
    showTimeBar,
    defaultShowWorkerShifts = false,
    schedules = [],
  } = props
  const startTime = props.businessStartTime ?? props.startTime
  const { isReactNativeApp } = useMobile()

  const shift: Shift = { ...props }
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultShowWorkerShifts)
  const handleIsExpandedClick = () => {
    setIsExpanded((expanded: boolean) => {
      return !expanded
    })
  }

  const isPartOfRecurringSchedule =
    schedules.length && schedules[0].isRecurringSchedule

  useEffect(() => {
    setIsExpanded(defaultShowWorkerShifts)
  }, [defaultShowWorkerShifts])

  const workersDropdownButton = (
    <Button
      variant={ButtonVariant.TEXT}
      onClick={handleIsExpandedClick}
      style={{
        color: theme.colors.brand,
        padding: 0,
      }}
    >
      {slotsRemaining !== undefined &&
        `Workers ${slotsRequested - slotsRemaining}/${slotsRequested} `}
      <Icon
        name={isExpanded ? 'chevronUp' : 'chevronDown'}
        style={{
          paddingLeft: theme.space.xxs,
          color: theme.colors.brand,
        }}
      />
    </Button>
  )

  return (
    <>
      <S.MobileExpandableShiftTileCard isExpanded={isExpanded}>
        <Row
          alignCenter
          justifyBetween
          px={theme.space.xs}
          pt={theme.space.xs}
          pb={theme.space.xs}
          style={{
            borderBottom: `2px solid ${theme.colors.Grey20}`,
          }}
        >
          <Link
            to={`/${path}/${shiftId}`}
            style={{
              textDecoration: 'none',
            }}
          >
            <Col>
              {/*
               * If status is canceled, it means we will show a 'CANCELED'
               * <ShiftBadge> and therefore need some extra spacing.
               */}
              <Text
                variant="h5"
                mb={status === 'CANCELED' ? theme.space.xxs : 0}
              >
                {truncateString(shiftRole, 18, true)}
              </Text>
              {/*
               * TODO(polyphilz): This needs a refactor. <ShiftBadges> uses
               * <RoleBadges> also but it isn't showing up there for some reason.
               * For now, we only want to show <ShiftBadges> in the React Native
               * view if the shift is canceled (there's no point in showing a
               * bunch of completed status badges).
               */}
              {(!isReactNativeApp ||
                (isReactNativeApp && status === 'CANCELED')) && (
                <ShiftBadges
                  status={status}
                  requiredCertifications={requiredCertifications}
                  showCanceledBadge={false}
                />
              )}
            </Col>
          </Link>
          <Col>
            {showTimeBar &&
              isInProgressOrStartsInThreshold(startTime, endTime) && (
                <ShiftStatusBadge
                  shiftStartTime={startTime}
                  shiftEndTime={endTime}
                  shiftIsCanceled={status === 'CANCELED'}
                  style={{
                    position: undefined,
                    borderRadius: theme.space.xxxs,
                  }}
                />
              )}
          </Col>
        </Row>
        <Row
          alignCenter
          justifyBetween
          px={theme.space.xs}
          pt={theme.space.xs}
          pb={theme.space.xs}
          mb={!isPartOfRecurringSchedule ? theme.space.xxs : 0}
          style={{
            borderBottom: `2px solid ${theme.colors.Grey20}`,
          }}
        >
          <Col style={{ minWidth: '50%' }}>
            <Text variant="body1" style={{ fontSize: theme.space.xsmed }}>
              {getShiftTimeString(startTime, endTime, timezone)}
            </Text>
          </Col>
          <Col>
            {isPartOfRecurringSchedule ? (
              <Text variant="body1" style={{ fontSize: theme.space.xsmed }}>
                <ShiftTileScheduleCadenceText schedules={schedules} />
              </Text>
            ) : (
              workersDropdownButton
            )}
          </Col>
        </Row>
        {isPartOfRecurringSchedule && (
          <Row
            alignCenter
            justifyBetween
            mb={theme.space.xxs}
            px={theme.space.xs}
            pt={theme.space.xs}
            pb={theme.space.xs}
            style={{
              borderBottom: `2px solid ${theme.colors.Grey20}`,
            }}
          >
            <Col>
              <ShiftTypeBadge
                shiftType={ShiftType.RECURRING}
                iconName="rotate_left"
                variant="info"
              />
            </Col>
            <Col>
              <Button
                variant={ButtonVariant.TEXT}
                onClick={handleIsExpandedClick}
                style={{
                  color: theme.colors.brand,
                  padding: 0,
                }}
              >
                {slotsRemaining !== undefined &&
                  `Workers ${slotsRequested - slotsRemaining}/${slotsRequested} `}
                <Icon
                  name={isExpanded ? 'chevronUp' : 'chevronDown'}
                  style={{
                    paddingLeft: theme.space.xxs,
                    color: theme.colors.brand,
                  }}
                />
              </Button>
            </Col>
          </Row>
        )}
        {!isExpanded && (
          <>
            {showClockCodes && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 12,
                  width: 220,
                  paddingLeft: theme.space.xs,
                  paddingRight: theme.space.xs,
                }}
              >
                <S.ShiftTileBodyText variant="body2">
                  <Text variant="caption">CLOCK IN </Text>
                  <ClockCode
                    code={confirmationCode}
                    tileHeight={24}
                    tileWidth={20}
                    fontSize={12}
                  />
                </S.ShiftTileBodyText>
                <S.ShiftTileBodyText variant="body2">
                  <Text variant="caption">CLOCK OUT </Text>
                  <ClockCode
                    code={clockOutCode}
                    tileHeight={24}
                    tileWidth={20}
                    fontSize={12}
                  />
                </S.ShiftTileBodyText>
              </div>
            )}
            <Row wrap justifyBetween px={theme.space.xs} pb={theme.space.xxs}>
              <S.ShiftTileBodyText variant="body2">
                {locationName || shortLocation}
              </S.ShiftTileBodyText>
              {userCanViewWages && (
                <S.ShiftTileBodyText variant="body2">
                  <Row>
                    {getMoneyString(payRate)}
                    {getPayUnitString(payType)}
                  </Row>
                  {payType === ShiftPayType.UNIT && (
                    <Row>
                      <S.ShiftTileBodyText variant="body2">
                        {numberOfUnits} units
                      </S.ShiftTileBodyText>
                    </Row>
                  )}
                </S.ShiftTileBodyText>
              )}
            </Row>
          </>
        )}
      </S.MobileExpandableShiftTileCard>
      {isExpanded && (
        <S.ShiftTileWorkerListContainer>
          <WorkersOnShiftTable
            shift={shift}
            hideHeader={true}
            isUpcoming={true}
            maxRowsBeforeScroll={4}
            isReactNativeApp={true}
          />
        </S.ShiftTileWorkerListContainer>
      )}
    </>
  )
})
