import { SvgIconProps } from '@traba/types'

export default function Abandon({ size, color, ...rest }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
      <path
        d="M6.58529 2.9596C7.84653 2.68013 9.15364 2.68013 10.4149 2.9596L10.7175 3.02665C11.1219 3.11626 11.5223 2.86107 11.6119 2.45667C11.7016 2.05226 11.4464 1.65178 11.042 1.56217L10.7394 1.49513C9.2644 1.16829 7.73577 1.16829 6.26078 1.49513L5.9582 1.56217C3.86384 2.02625 2.21281 3.63626 1.69578 5.71842C1.32374 7.21671 1.32374 8.78329 1.69578 10.2816C2.21281 12.3637 3.86384 13.9737 5.9582 14.4378L6.26078 14.5049C7.73577 14.8317 9.2644 14.8317 10.7394 14.5049L11.042 14.4378C11.4464 14.3482 11.7016 13.9477 11.6119 13.5433C11.5223 13.1389 11.1219 12.8837 10.7175 12.9733L10.4149 13.0404C9.15364 13.3199 7.84653 13.3199 6.58529 13.0404L6.2827 12.9733C4.74434 12.6325 3.53142 11.4498 3.15157 9.92009C2.83847 8.65919 2.83847 7.34082 3.15157 6.07991C3.53142 4.55018 4.74435 3.36753 6.28271 3.02665L6.58529 2.9596Z"
        fill={color}
      />
      <path
        d="M12.3507 5.64626C12.1553 5.4511 11.8387 5.45127 11.6436 5.64664C11.4484 5.842 11.4486 6.15858 11.644 6.35374L13.292 8H10.5C10.2239 8 10 8.22386 10 8.5C10 8.77614 10.2239 9 10.5 9H13.292L11.644 10.6463C11.4486 10.8414 11.4484 11.158 11.6436 11.3534C11.8387 11.5487 12.1553 11.5489 12.3507 11.3537L14.8534 8.85374C14.9472 8.75996 15 8.6327 15 8.5C15 8.3673 14.9472 8.24004 14.8534 8.14626L12.3507 5.64626Z"
        fill={color}
      />
    </svg>
  )
}
