import { theme } from '@traba/theme'
import { ShiftRequestParentWithShiftRequest } from '@traba/types'
import { isRecurringShiftRequest } from '@traba/utils'
import { ScheduleTileDateTimeInfo } from './ScheduleTileDateTimeInfo'
import { ScheduleTileHeader } from './ScheduleTileHeader'
import { ScheduleTileLocation } from './ScheduleTileLocation'
import { ScheduleTileRolesInfo } from './ScheduleTileRolesInfo'

interface ScheduleTileProps {
  shiftRequestParent?: ShiftRequestParentWithShiftRequest
  hideActionButtons?: boolean
}

export const ScheduleTile: React.FC<ScheduleTileProps> = ({
  shiftRequestParent,
  hideActionButtons,
}) => {
  if (!shiftRequestParent) {
    return null
  }

  // We should only have shift request parent objects with at least one shift
  // request, and each of those shift requests should have 1-2 schedule objects.
  // This filtering is performed on the backend.
  const recurringShiftRequests = shiftRequestParent.shiftRequests.filter((sr) =>
    isRecurringShiftRequest(sr),
  )

  if (!recurringShiftRequests || !recurringShiftRequests.length) {
    return null
  }
  const schedules = recurringShiftRequests[0].schedules
  if (!schedules.length) {
    return null
  }
  // All shifts in a schedule is of one location, so we can get from first one
  const scheduleLocationId = shiftRequestParent.shiftRequests[0].locationId

  return (
    <div
      style={{
        marginBottom: theme.space.sm,
        border: `1px solid ${theme.colors.Grey20}`,
        borderRadius: theme.space.xxs,
      }}
    >
      <ScheduleTileHeader
        scheduleTitle={shiftRequestParent.title}
        shiftRequestParentId={shiftRequestParent.shiftRequestParentId}
        hideActionButtons={hideActionButtons}
      />
      <ScheduleTileLocation locationId={scheduleLocationId} />
      <ScheduleTileDateTimeInfo
        schedules={schedules}
        totalWrittenShiftCounts={shiftRequestParent.totalWrittenShiftCounts}
        timezone={recurringShiftRequests[0].timezone ?? 'utc'}
      />
      <ScheduleTileRolesInfo shiftRequests={recurringShiftRequests} />
    </div>
  )
}
