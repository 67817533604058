import { createCalendar } from '@internationalized/date'
import { AriaCalendarProps } from '@react-aria/calendar'
import { useCalendar } from '@react-aria/calendar'
import { useLocale } from '@react-aria/i18n'
import { useCalendarState } from '@react-stately/calendar'
import { DateValue } from '@react-types/datepicker'
import { Row, Text } from 'src/components/base'
import Icon from 'src/components/base/Icon'

import { CalendarButton } from './Button'
import CalendarGrid from './CalendarGrid'

interface CalendarProps extends AriaCalendarProps<DateValue> {
  timezone: string
}

export default function Calendar(props: CalendarProps) {
  const { locale } = useLocale()
  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
  })

  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useCalendar(props, state)

  return (
    <div {...calendarProps}>
      <Row center alignCenter pb={8} justifyBetween>
        <Text variant="h5">{title}</Text>
        <Row gap={8}>
          <CalendarButton {...prevButtonProps}>
            <Icon name="chevronLeft" width={15} height={15} />
          </CalendarButton>
          <CalendarButton {...nextButtonProps}>
            <Icon name="chevronRight" width={15} height={15} />
          </CalendarButton>
        </Row>
      </Row>
      <CalendarGrid state={state} timezone={props.timezone} />
    </div>
  )
}
