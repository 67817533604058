import { AlertColor } from '@mui/material'
import { FadeIn } from '@traba/react-components'
import { WorkerShiftWithWorkerDetails } from '@traba/types'
import { getShiftDateString, getShiftTimeString } from '@traba/utils'
import { AxiosResponse } from 'axios'
import {
  addHours,
  addMinutes,
  differenceInMinutes,
  isAfter,
  subHours,
} from 'date-fns'
import { isBefore } from 'date-fns/esm'
import { useState } from 'react'
import { UseMutateAsyncFunction } from 'react-query'
import { SvgIconName } from 'src/assets/svg/icons'
import { Text } from 'src/components/base/Text'
import { useCompany } from 'src/hooks/useCompany'
import {
  BulkWorkerActionBasePayload,
  ClockInOutResult,
  ClockInWorkers,
  ClockOutWorkers,
  RejectWorkers,
} from 'src/hooks/workerShiftHooks'
import { theme } from 'src/libs/theme'
import { BUSINESS_SUPPORT_EMAIL } from 'src/utils/supportUtils'

import { ButtonVariant, Col, InlineBanner, Input, Row } from '../base'
import TimeField from '../base/AriaDatePicker/TimeField'
import { Dialog } from '../base/Dialog/Dialog'
import { RadioButton } from '../RadioButton'
import { SelectedWorkersCard } from '../SelectedWorkersCard'
import {
  NEGATIVE_WORKER_ACTIONS,
  WorkerAction,
} from '../WorkersManagementMenu/WorkersManagementMenu'
import { RemoveWorkerBizCancelModal } from './RemoveWorkerBizCancelModal'

export function getClockInOutError(
  shiftStartTime: Date,
  shiftEndTime: Date,
  clockInOutTime: Date,
  workerAction: WorkerAction,
  hoursToClockInEarlyBy?: number,
): { type: AlertColor; message: string } | null {
  const now = new Date()

  if (
    ![
      WorkerAction.ClockIn,
      WorkerAction.ClockOut,
      WorkerAction.EditClockIn,
      WorkerAction.Abandon,
    ].includes(workerAction)
  ) {
    return null
  }

  // 30 minutes in the future for abandon, strict
  if (
    workerAction === WorkerAction.Abandon &&
    isAfter(clockInOutTime, addMinutes(now, 30))
  ) {
    return {
      message: `Cannot set abandonment time more than 30 minutes into the future.`,
      type: 'error',
    }
  }

  // 30 minutes in the future
  if (isAfter(clockInOutTime, addMinutes(now, 30))) {
    return {
      message: `Looks like you're clocking ${
        workerAction === WorkerAction.ClockIn ||
        workerAction === WorkerAction.EditClockIn
          ? 'in'
          : 'out'
      } the worker more than 30 minutes into the future. Is that correct?`,
      type: 'warning',
    }
  }

  if (
    workerAction === WorkerAction.ClockIn ||
    workerAction === WorkerAction.EditClockIn
  ) {
    // If after the company's specified allowed hours for early clock-in and before 5 hours of the shift start time, allow the clock-in without warning
    if (
      hoursToClockInEarlyBy &&
      isAfter(
        clockInOutTime,
        subHours(shiftStartTime, hoursToClockInEarlyBy),
      ) &&
      isBefore(clockInOutTime, addHours(shiftStartTime, 5))
    ) {
      return null
    }

    // Early Clock In  - more rigid
    if (isBefore(clockInOutTime, subHours(shiftStartTime, 5))) {
      return {
        message:
          'Cannot clock in workers more than 5 hours before the scheduled shift start. Please contact support if you need further assistance',
        type: 'error',
      }
    }
    if (isBefore(clockInOutTime, subHours(shiftStartTime, 3))) {
      return {
        message:
          'Looks like you are clocking in this worker more than 3 hours before the scheduled shift start - are you sure this is correct?',
        type: 'warning',
      }
    }

    // Late Clock In - more lenient
    if (isAfter(clockInOutTime, addHours(shiftStartTime, 5))) {
      return {
        message: `Looks like you're clocking in the worker more than 5 hours after the scheduled shift start - is that correct?`,
        type: 'warning',
      }
    }
  }

  if (workerAction === WorkerAction.ClockOut) {
    // Late Clock Out - lenient
    if (isAfter(clockInOutTime, addHours(shiftEndTime, 5))) {
      return {
        message: `Looks like you're clocking out the worker more than 5 hours after the scheduled shift end - is that correct?`,
        type: 'warning',
      }
    }
    if (isAfter(clockInOutTime, addHours(shiftEndTime, 3))) {
      return {
        message:
          'Looks like you are clocking out this worker more than 3 hours after the scheduled shift end - are you sure this is correct?',
        type: 'warning',
      }
    }

    // Early Clock Out - more lenient
    if (isBefore(clockInOutTime, subHours(shiftEndTime, 5))) {
      return {
        message: `Looks like you're clocking out the worker more than 5 hours before the scheduled shift end - are you sure this is correct?`,
        type: 'warning',
      }
    }
  }

  return null
}

export type WorkerManagementModalProps = {
  shiftId: string
  isOpen: boolean
  handleClose: () => void
  workers: WorkerShiftWithWorkerDetails[]
  workerAction: WorkerAction
  shiftStartTime: Date
  shiftEndTime: Date
  shiftRole: string
  timezone: string
  minimumPaidTime?: number
  clockInWorkers: UseMutateAsyncFunction<
    ClockInOutResult,
    Error,
    ClockInWorkers
  >
  editClockInWorkers: UseMutateAsyncFunction<
    ClockInOutResult,
    Error,
    ClockInWorkers
  >
  clockOutWorkers: UseMutateAsyncFunction<
    ClockInOutResult,
    Error,
    ClockOutWorkers
  >
  abandonWorkers: UseMutateAsyncFunction<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    ClockOutWorkers
  >
  rejectWorkers: UseMutateAsyncFunction<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    RejectWorkers
  >
  noShowWorkers: UseMutateAsyncFunction<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    BulkWorkerActionBasePayload
  >

  bizCancelRemoveWorkers: UseMutateAsyncFunction<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    BulkWorkerActionBasePayload
  >
}

export const WorkerManagementModal = (props: WorkerManagementModalProps) => {
  const {
    shiftId,
    workerAction,
    workers,
    shiftStartTime,
    shiftEndTime,
    timezone,
    shiftRole,
    minimumPaidTime,
    clockInWorkers,
    editClockInWorkers,
    clockOutWorkers,
    handleClose,
    isOpen,
    abandonWorkers,
    rejectWorkers,
    noShowWorkers,
    bizCancelRemoveWorkers,
  } = props

  const defaultDate = [WorkerAction.ClockIn, WorkerAction.EditClockIn].includes(
    workerAction,
  )
    ? shiftStartTime
    : workerAction === WorkerAction.ClockOut
      ? shiftEndTime
      : new Date()
  const hasMultipleWorkers = workers.length > 1
  const isShiftEnded = shiftEndTime && isAfter(new Date(), shiftEndTime)
  const [clockInOutTime, setClockInOutTime] = useState<Date>(
    isShiftEnded ? defaultDate : new Date(),
  )

  const [isDirty, setIsDirty] = useState(false)
  const [actionInProgress, setActionInProgress] = useState(false)
  const [shouldNotifyWorker, setShouldNotifyWorker] = useState(false)
  const { company } = useCompany()

  if (!workers?.length) {
    return null
  }

  let titleText = ``,
    clockInOutLabel = ``,
    actionReasonLabel = '',
    modalCTA = ``,
    descriptionElem = <></>,
    warningText = ``,
    showWarningTextInBanner = true,
    showNotificationRadioOptions = false,
    isUnderMinimumPaidTime = false,
    dialogTitleIcon: SvgIconName | undefined = 'clock',
    customModal: JSX.Element | undefined = undefined

  let confirmAction = async () => {
    // will be overriden below
  }

  switch (workerAction) {
    case WorkerAction.ClockIn:
      titleText = hasMultipleWorkers
        ? `Clock in ${workers.length} Scheduled Workers`
        : `Clock in ${workers[0].worker.firstName} ${workers[0].worker.lastName}`
      clockInOutLabel = 'Start Time'
      modalCTA = `Clock in`
      confirmAction = async () => {
        await clockInWorkers({
          workerIds: workers.map(({ worker }) => worker.uid),
          clockInTime: clockInOutTime,
          shiftId,
        })
        window.analytics.track(`Business Clocked In Workers`, {
          workers,
          clockInTime: clockInOutTime,
        })
      }
      if (shiftEndTime && minimumPaidTime) {
        const minutesUntilShiftEnd = differenceInMinutes(
          shiftEndTime,
          clockInOutTime,
        )
        isUnderMinimumPaidTime = minutesUntilShiftEnd < minimumPaidTime
      }
      break
    case WorkerAction.EditClockIn:
      titleText = hasMultipleWorkers
        ? `Update Clock in for ${workers.length} Workers`
        : `Update Clock in for ${workers[0].worker.firstName} ${workers[0].worker.lastName}`
      clockInOutLabel = 'Start Time'
      modalCTA = `Edit Clock in`
      confirmAction = async () => {
        await editClockInWorkers({
          workerIds: workers.map(({ worker }) => worker.uid),
          clockInTime: clockInOutTime,
          shiftId,
        })
        window.analytics.track(`Business Edited Clock In for Workers`, {
          workers,
          clockInTime: clockInOutTime,
        })
      }
      if (shiftEndTime && minimumPaidTime) {
        const minutesUntilShiftEnd = differenceInMinutes(
          shiftEndTime,
          clockInOutTime,
        )
        isUnderMinimumPaidTime = minutesUntilShiftEnd < minimumPaidTime
      }
      break
    case WorkerAction.ClockOut:
      modalCTA = `Clock out`
      titleText = hasMultipleWorkers
        ? `Clock out ${workers.length} in-progress Workers`
        : `Clock out ${workers[0].worker.firstName} ${workers[0].worker.lastName}`
      descriptionElem = (
        <>
          This is the official {modalCTA.toLowerCase()} time, you will not be
          able to edit after you click {modalCTA.toLowerCase()}.
        </>
      )
      clockInOutLabel = `End Time`
      confirmAction = async () => {
        await clockOutWorkers({
          workerIds: workers.map(({ worker }) => worker.uid),
          clockOutTime: clockInOutTime,
          shiftId,
        })
        window.analytics.track(`Business Clocked Out Workers`, {
          workers,
          clockOutTime: clockInOutTime,
        })
      }
      if (shiftStartTime && minimumPaidTime) {
        const minutesSinceShiftStart = differenceInMinutes(
          clockInOutTime,
          shiftStartTime,
        )
        isUnderMinimumPaidTime = minutesSinceShiftStart < minimumPaidTime
      }
      break
    case WorkerAction.Abandon:
      titleText = hasMultipleWorkers
        ? `Mark ${workers.length} Workers as Abandoned`
        : `Mark ${workers[0].worker.firstName} ${workers[0].worker.lastName} as Abandoned`
      warningText = `We're sorry that the worker abandoned the shift. Marking a worker as abandoned will impact their reliability score. 
      If this situation is due to a medical emergency or any exceptional circumstance, please consider clocking them out normally. This cannot be undone.`
      clockInOutLabel = `Abandonment Time`
      modalCTA = `Mark as abandoned`
      confirmAction = async () => {
        await abandonWorkers({
          workerIds: workers.map(({ worker }) => worker.uid),
          clockOutTime: clockInOutTime,
          shiftId,
        })
        window.analytics.track(`Business Marked Workers as Abandoned`, {
          workers,
          clockOutTime: clockInOutTime,
        })
      }
      break
    case WorkerAction.NoShow:
      titleText = hasMultipleWorkers
        ? `Mark ${workers.length} Workers as No Show`
        : `Mark ${workers[0].worker.firstName} ${workers[0].worker.lastName} as no-show`

      warningText = `We're sorry that the worker did not show up. Marking a worker as no-show will impact their reliability score.
       This cannot be undone.`
      modalCTA = `Mark as no-show`
      confirmAction = async () => {
        await noShowWorkers({
          workerIds: workers.map(({ worker }) => worker.uid),
          shiftId,
        })
        window.analytics.track(`Business Marked Workers as no-show`, {
          workers,
        })
      }
      break
    case WorkerAction.TurnAway:
      titleText = hasMultipleWorkers
        ? `Turn away ${workers.length} Workers`
        : `Turn away ${workers[0].worker.firstName} ${workers[0].worker.lastName}`
      showWarningTextInBanner = false
      warningText = `We're sorry that the worker did not meet the shift requirements. Turning away a worker from working the shift will impact their reliability score. This cannot be undone.`
      actionReasonLabel = 'Reason for turning away (required)'
      modalCTA = `Turn away worker`
      showNotificationRadioOptions = true
      confirmAction = async () => {
        await rejectWorkers({
          workerIds: workers.map(({ worker }) => worker.uid),
          rejectionReason: actionReason,
          shouldNotifyWorker,
          shiftId,
        })
        window.analytics.track(`Business Rejected Worker`, {
          workers,
        })
      }
      break
    case WorkerAction.RemoveBizCancel:
      customModal = (
        <RemoveWorkerBizCancelModal
          shiftId={shiftId}
          isOpen={isOpen}
          onClose={handleClose}
          workerShifts={workers}
          bizCancelRemoveWorkers={bizCancelRemoveWorkers}
        />
      )
      break
    case WorkerAction.RemoveContactUs:
      titleText = hasMultipleWorkers
        ? `Remove ${workers.length} Workers`
        : `Remove ${workers[0].worker.firstName} ${workers[0].worker.lastName}`
      descriptionElem = (
        <>
          Since it is less than 18 hours before the shift, please email or text
          your dedicated Traba representative with any questions or concerns
          about this worker for the fastest response. If you don't have their
          email or phone number, please email{' '}
          <span style={{ fontWeight: 'bold' }}>{BUSINESS_SUPPORT_EMAIL}</span>.
        </>
      )
      modalCTA = `Email us`
      confirmAction = async () => {
        window.open(`mailto:${BUSINESS_SUPPORT_EMAIL}`)
        window.analytics.track(
          `Business Clicked Email Button trying to Remove Workers`,
          {
            workers,
          },
        )
      }
      dialogTitleIcon = undefined
      break
  }

  const [actionReason, setActionReason] = useState('')

  const errorOrWarning =
    shiftStartTime &&
    shiftEndTime &&
    workerAction &&
    getClockInOutError(
      shiftStartTime,
      shiftEndTime,
      clockInOutTime,
      workerAction,
      company?.hoursToClockInEarlyBy ?? undefined,
    )

  const isError = errorOrWarning?.type === 'error'
  const subtitle = (
    <>
      {shiftRole}
      {' • '}
      {!!shiftStartTime &&
        !!shiftEndTime &&
        getShiftDateString(shiftStartTime, shiftEndTime, timezone, {
          year: undefined,
          weekday: 'short',
        })}
      {', '}
      {!!shiftStartTime &&
        !!shiftEndTime &&
        getShiftTimeString(shiftStartTime, shiftEndTime, timezone)}
    </>
  )

  if (customModal) {
    return customModal
  }

  return (
    <Dialog
      dialogTitle={titleText}
      confirmButtonVariant={
        NEGATIVE_WORKER_ACTIONS.includes(workerAction)
          ? ButtonVariant.CANCEL
          : undefined
      }
      dialogSubtitle={subtitle}
      dialogTitleIcon={dialogTitleIcon}
      onClose={handleClose}
      onConfirmCTA={modalCTA}
      open={isOpen}
      onConfirm={async () => {
        setActionInProgress(true)
        await confirmAction()
        setActionInProgress(false)
        handleClose()
      }}
      confirming={actionInProgress}
      confirmDisabled={
        !!isError ||
        (workerAction === WorkerAction.EditClockIn && !isDirty) ||
        actionInProgress ||
        (!!actionReasonLabel && !actionReason)
      }
    >
      <Col gap={theme.space.xs}>
        {descriptionElem && <Text>{descriptionElem}</Text>}
        {warningText ? (
          showWarningTextInBanner ? (
            <InlineBanner severity="warning" text={warningText} />
          ) : (
            <Text variant="body1">{warningText}</Text>
          )
        ) : null}
        {/* Selected Workers Section */}
        {workers.length > 1 && <SelectedWorkersCard workers={workers} />}

        {clockInOutLabel && (
          <Col gap={theme.space.xxs}>
            <Text variant="h6">{clockInOutLabel}</Text>
            <TimeField
              time={clockInOutTime || defaultDate}
              setTime={(clockTime) => {
                setIsDirty(true)
                if (clockTime) {
                  setClockInOutTime(clockTime)
                }
              }}
              timezone={timezone}
            />
          </Col>
        )}

        {actionReasonLabel && (
          <Input
            rows={2}
            label={actionReasonLabel}
            placeholder="Add reason"
            type="textarea"
            className="xs-12"
            value={actionReason}
            onChange={(e) => setActionReason(e.target.value)}
          />
        )}

        {showNotificationRadioOptions && (
          <>
            <Row mt={theme.space.xs}>
              <Text variant="h6">Do you want to notify the worker?</Text>
            </Row>
            <Row alignCenter style={{ columnGap: theme.space.xl }}>
              <Col>
                <Row
                  alignCenter
                  style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
                  onClick={() => setShouldNotifyWorker(false)}
                >
                  <RadioButton selected={!shouldNotifyWorker} />
                  <Text variant="body1">No, don't notify the worker</Text>
                </Row>
              </Col>
              <Col>
                <Row
                  alignCenter
                  style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
                  onClick={() => setShouldNotifyWorker(true)}
                >
                  <RadioButton selected={shouldNotifyWorker} />
                  <Text variant="body1">Yes, notify the worker</Text>
                </Row>
              </Col>
            </Row>
            <FadeIn
              shouldDisplay={shouldNotifyWorker}
              duration={800}
              translateY={0}
            >
              <InlineBanner text="Only notify workers that you did not see in person. If you saw the worker and turned them away, you don't need to notify them again." />
            </FadeIn>
          </>
        )}

        {errorOrWarning && (
          <InlineBanner
            severity={errorOrWarning.type}
            text={errorOrWarning.message}
          />
        )}

        {!!isUnderMinimumPaidTime && !!minimumPaidTime && (
          <InlineBanner
            severity="warning"
            text={`This shift has a ${Math.floor(
              minimumPaidTime / 60,
            )} hour minimum, workers will be paid for at least ${Math.floor(
              minimumPaidTime / 60,
            )} hours of time`}
          />
        )}
      </Col>
    </Dialog>
  )
}
