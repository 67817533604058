import styled, { createGlobalStyle } from 'styled-components'

export const Autocomplete = createGlobalStyle`
  .MuiAutocomplete-listbox {
    background-image: url('/img/powered-by-google.png');
    background-repeat: no-repeat;
    background-position: right 10px bottom 10px;
    padding-bottom: 40px;
    max-height: 500px;
  }
`

export const Option = styled.li`
  display: flex;
  flex-direction: column;
  span {
    width: 100%;
    display: block;
    font-size: 14px;
  }
  b {
    width: 100%;
    display: block;
    font-weight: 600;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.textButton};
  }
`
