import { SvgIconProps } from 'src/types/svg-types'

export default function FoodPrepAndService({
  size = 52,
  ...props
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 42 42" fill="none" {...props}>
      <circle cx="21" cy="21" r="21" fill="#E6E8FF" />
      <path
        d="M29.4536 19.5832C31.4123 17.6245 31.4249 14.4615 29.4817 12.5184C27.5386 10.5752 24.3756 10.5878 22.4169 12.5465L11.5466 23.4168C9.5879 25.3755 9.5753 28.5385 11.5184 30.4816C13.4616 32.4248 16.6246 32.4122 18.5833 30.4535L29.4536 19.5832Z"
        fill="#8000FF"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.7681 24.1748L16.8826 27.6777"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.3062 19.7964L21.3411 23.2993"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.5259 15.1792L25.6404 18.6821"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
