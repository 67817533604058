import React from 'react'
import { useSpring, animated } from 'react-spring'

type FadeInProps = {
  shouldDisplay: boolean
  duration: number
  translateY: number
  children: React.ReactNode
}

export function FadeIn({
  shouldDisplay,
  duration,
  translateY,
  children,
}: FadeInProps) {
  // Define the animation
  const fadeIn = useSpring({
    opacity: shouldDisplay ? 1 : 0,
    transform: shouldDisplay ? 'translateY(0)' : `translateY(${translateY}px)`,
    config: { duration }, // Adjust duration for the speed of the animation
  })

  return (
    <>
      {shouldDisplay && <animated.div style={fadeIn}>{children}</animated.div>}
    </>
  )
}
