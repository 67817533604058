import { useParams } from 'react-router-dom'
import { MainLayout } from 'src/components'
import WorkerDetails from 'src/components/WorkerDetails/WorkerDetails'

export default function WorkerDetailsScreen() {
  const { workerId } = useParams<{ workerId: string }>()

  return (
    <MainLayout title="Worker Details">
      <WorkerDetails workerId={workerId || ''} />
    </MainLayout>
  )
}
