import styled from 'styled-components'

export const PriceLineContainer = styled.div<{ compress?: boolean }>`
  justify-content: flex-end;
  display: flex;
  align-items: center;
  margin: ${(props) => (props.compress ? '0' : ' 20px 0px')};
`

export const BusinessFaqAnchor = styled.a<{ isPaidCancellation?: boolean }>`
  font-size: 14px;
  font-weight: 500px;
  text-decoration: none;
  margin-bottom: 24px;
  color: ${(props) => (props.isPaidCancellation ? '#F20D59' : '#1AB273')};
`
