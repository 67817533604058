import { Input, LoadingSpinner } from '@traba/react-components'
import { useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Col, Row, Text } from 'src/components/base'
import { Checkbox } from 'src/components/base/CheckboxThemed'
import { EmptyDataLocationSubtitle } from 'src/components/base/RegionalFilterSelect/EmptyDataLocationSubtitle'
import { useCalendarContext } from 'src/hooks/useCalendarContext'
import useMobile from 'src/hooks/useMobile'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { MEDIA, theme } from 'src/libs/theme'

interface Props {
  shifts: ShiftAndAddress[] | undefined
  isLoading: boolean
}

const MAX_SHIFTS = 3

export const CalendarRoleSelector = ({ shifts, isLoading }: Props) => {
  const {
    state: { rolesMap },
    dispatch,
  } = useCalendarContext()
  const [showAll, setShowAll] = useState<boolean>(
    shifts !== undefined && shifts?.length > MAX_SHIFTS,
  )
  const [isAllSelected, setIsAllSelected] = useState<boolean>(true)
  const [searchFilter, setSearchFilter] = useState<string>('')
  const { isMobileViewOrReactNative } = useMobile()
  const roleOptions = Array.from(Object.entries(rolesMap)).map(
    ([roleId, entry]) => {
      return {
        label: entry.name,
        value: roleId,
      }
    },
  )
  const isMobileView = useMediaQuery({
    maxWidth: MEDIA.LARGE,
  })
  const filteredRoleOptions = useMemo(
    () =>
      roleOptions.filter((ro) =>
        ro.label.toLowerCase().includes(searchFilter.toLowerCase()),
      ),
    [roleOptions, searchFilter],
  )

  const searchInput = (
    <Input
      placeholder="Search roles..."
      leftIconName="search"
      name="roleSearch"
      type="text"
      defaultValue=""
      containerStyle={{
        marginTop: theme.space.xs,
        marginBottom: theme.space.xxs,
      }}
      width={isMobileViewOrReactNative ? '100%' : '80%'}
      value={searchFilter}
      onChange={(e) => {
        e.preventDefault()
        setSearchFilter(e.target.value)
      }}
      onClear={() => setSearchFilter('')}
    />
  )

  return (
    <Col>
      <Row alignCenter justifyBetween>
        <Text variant="h5">Roles</Text>
        <Text
          variant="link"
          onClick={() => {
            setIsAllSelected((prev) => !prev)
            dispatch({
              type: 'TOGGLE_ROLE_FOR_ALL',
            })
          }}
        >
          {isAllSelected ? 'Unselect all' : 'Select all'}
        </Text>
      </Row>
      {isLoading ? (
        <LoadingSpinner style={{ width: theme.space.lg }} />
      ) : roleOptions.length === 0 ? (
        <>
          <Text variant="body2">
            You don't have any shifts in current view.
          </Text>

          <EmptyDataLocationSubtitle
            isMobileView={isMobileView}
            extraText="to see shifts in those locations, or book a new shift."
            style={{
              textAlign: 'left',
              flexDirection: 'column',
              marginTop: theme.space.xxxs,
            }}
          />
        </>
      ) : (
        <>
          {searchInput}
          {filteredRoleOptions
            .slice(0, showAll ? roleOptions.length : MAX_SHIFTS)
            ?.map((sq) => (
              <Row alignCenter mt={theme.space.xxs} key={sq.value}>
                <Checkbox
                  selected={!rolesMap[sq.value]?.hideFromView}
                  onClick={() => {
                    dispatch({
                      type: 'HIDE_ROLE',
                      payload: {
                        roleId: sq.value,
                        hideFromView: !rolesMap[sq.value]?.hideFromView,
                      },
                    })
                  }}
                  selectedColor={rolesMap[sq.value]?.color.borderColor}
                />
                <Text ml={theme.space.xxs} variant="body1">
                  {sq.label}
                </Text>
              </Row>
            ))}
          {roleOptions.length > MAX_SHIFTS && (
            <Row mt={theme.space.xxs}>
              <Text
                variant="link"
                onClick={() => {
                  setShowAll(!showAll)
                }}
              >
                {showAll ? '- Show less' : '+ Show all'}
              </Text>
            </Row>
          )}
        </>
      )}
    </Col>
  )
}
