import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { ShiftRequestEditInput } from '@traba/types'

export const createShiftRequestEdits = async (shiftRequestEdit: {
  edit: ShiftRequestEditInput
}) => {
  try {
    const response = await trabaApi.post(
      `shift-request-edits/company-edit`,
      shiftRequestEdit,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}
