export function ChevronUp({
  color = '#7A8A99',
  size,
  ...props
}: {
  color: string
  size?: number
}) {
  return (
    <svg
      width={size ?? '12'}
      height={size ?? '7'}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.999999 6L6 1L11 6"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
