import { LoadingSpinner } from '@traba/react-components'
import React from 'react'

import { usePersonalProfile } from './PersonalProfile.hooks'
import { PersonalProfileUI } from './PersonalProfile.ui'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook

export const PersonalProfile = () => {
  const logic = usePersonalProfile()
  if (logic.isLoading) {
    return <LoadingSpinner />
  }
  return <PersonalProfileUI {...logic} />
}
