import { Icon, Text as BaseText } from 'src/components/base'
import { ChevronDown as BaseChevronDown } from 'src/components/base/Icon'
import styled, { css } from 'styled-components'

export const HeaderWrapper = styled.div`
  width: 100%;
  height: 80px;
  padding: 0px 28px;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.BorderColor};
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  right: 0;

  @media only screen and (min-width: ${({ theme }) => theme.media.minMedium}) {
    padding: 0px 32px;
  }
`

export const Badge = styled.span`
  width: 8px;
  height: 8px;
  border: 1px solid white;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.red};
  margin-top: 13px;
  margin-left: -6px;
`

export const Text = styled(BaseText)`
  margin-left: 20px;
  margin-right: 16px;
`

export const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 14px;
`

export const ProfileIcon = styled(Icon)`
  width: 15px;
  margin-right: 14px;
`

export const ChevronDownWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
`

export const ChevronDown = styled(BaseChevronDown)<{
  $isActive?: boolean
  color?: string
}>`
  transition: all 0.3s;

  ${({ $isActive, color, theme }) =>
    $isActive
      ? css`
          transform: rotate(180deg);

          path {
            stroke: ${color || theme.colors.brand};
          }
        `
      : ''}
`

/**
 * Animated Hamburger Menu Icon adapted from https://jonsuh.com/hamburgers/ "squeeze"
 */
export const HamburgerInner = styled.span<{ $isOpen?: boolean }>`
  display: block;
  position: absolute;
  top: 50%;
  width: 18px;
  height: 1.5px;
  border-radius: 1.5px;
  background-color: ${({ theme }) => theme.colors.MidnightBlue};

  transition-property: transform;
  transition-timing-function: ease;
  transition-duration: 75ms;

  :before,
  :after {
    display: block;
    position: absolute;
    width: 18px;
    height: 1.5px;
    border-radius: 1.5px;
    background-color: ${({ theme }) => theme.colors.MidnightBlue};

    transition-property: transform;
    transition-timing-function: ease;
    transition-duration: 75ms;

    content: '';
    box-sizing: inherit;
  }

  :before {
    transition:
      top 75ms ease 0.12s,
      opacity 75ms ease;
    top: -7px;
  }

  :after {
    transition:
      bottom 75ms ease 0.12s,
      transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    bottom: -7px;
  }

  ${({ $isOpen }) => {
    return $isOpen
      ? css`
          transition-delay: 0.12s;
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transform: rotate(45deg);

          :before {
            top: 0;
            transition:
              top 75ms ease,
              opacity 75ms ease 0.12s;
            opacity: 0;
          }

          :after {
            bottom: 0;
            transition:
              bottom 75ms ease,
              transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
            transform: rotate(-90deg);
          }
        `
      : ``
  }}
`

export const HamburgerBox = styled.span`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 24px;
  box-sizing: inherit;
`

export const HamburgerWrapper = styled.button`
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 5px;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;

  @media only screen and (min-width: ${({ theme }) => theme.media.minMedium}) {
    display: none;
  }
`
