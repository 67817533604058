import React from 'react'
import styled from 'styled-components'
import { Text, TextVariant } from './Text'

type Props = {
  full?: boolean
  type?: string
  name?: string
  label?: string | JSX.Element
  textvariant?: TextVariant | undefined
  readOnly?: boolean
  checked?: boolean
  placeholder?: string
  defaultChecked?: boolean
  labelStyle?: { [key: string]: string | number }
  style?: { [key: string]: string | number }
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => any
  disabled?: boolean
}

const StyledCheckbox = styled.label<{ readOnly?: boolean; disabled?: boolean }>`
  display: block;
  position: relative;
  padding-left: 37px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* On mouse-over, add a grey background color */
  :hover input ~ .checkmark {
    background-color: ${(props) =>
      props.readOnly || props.disabled ? '' : '#ccc'};
  }

  /* When the checkbox is checked, add a theme-colored background */
  input:checked ~ .checkmark {
    background-color: #8000ff;
    :hover {
      background-color: #ae00ff;
    }
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.Grey30 : theme.colors.Violet};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: ${({ disabled, theme }) =>
      disabled ? theme.colors.Grey30 : '#eee'};
    border-radius: 8px;
  }

  /* Checkmark indicator */
  .checkmark:after {
    left: 10px;
    top: 4px;
    width: 4px;
    height: 11px;
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

export function Checkbox({ labelStyle, style, ...props }: Props) {
  return (
    <StyledCheckbox
      style={{ ...labelStyle, ...style }}
      readOnly={props.readOnly}
      disabled={props.disabled}
    >
      <input
        type="checkbox"
        {...props}
        readOnly={props.readOnly}
        disabled={props.disabled}
      />
      <span className="checkmark"></span>
      <Text variant={props.textvariant ?? 'body2'}>{props.label}</Text>
    </StyledCheckbox>
  )
}
