import { trabaApi } from '@traba/api-utils'
import { Card, LoadingSpinner } from '@traba/react-components'
import {
  ShiftWithWorkerShiftsAndCharges,
  WorkerShift,
  WorkerShiftWithCharges,
  WorkerWithWorkerShiftAndCharges,
} from '@traba/types'
import { AdjustmentSource } from '@traba/types'
import { InputStatus } from '@traba/types'
import { formatShiftTime } from '@traba/utils'
import { useMemo, useState } from 'react'
import React from 'react'
import {
  Button,
  ButtonVariant,
  Col,
  InlineBanner,
  Input,
  Row,
} from 'src/components/base'
import Divider, { NoTextDivider } from 'src/components/base/Divider'
import { Text } from 'src/components/base/Text'
import { TimeSheetsWorkerEarning } from 'src/components/TimeSheetsTable/components/TimeSheetsWorkerEarning'
import { parseTimesheetData } from 'src/components/TimeSheetsTable/utils'
import { WorkerPhotoAndName } from 'src/components/WorkersOnShiftTable/components/WorkerPhotoAndName'
import { useTimeSheetWorkerShiftCharges } from 'src/hooks/useTimesheet'
import { useWorkersByIds } from 'src/hooks/useWorker'
import { theme } from 'src/libs/theme'
import { getWorkerShiftStringId } from 'src/utils/workerShiftUtils'
import styled from 'styled-components'

export type UpdateWorkerShiftTimeSheetsModalProps = {
  handleClose: () => void
  onUpdate: () => void
  data:
    | WorkerWithWorkerShiftAndCharges[]
    | ShiftWithWorkerShiftsAndCharges[]
    | undefined
  editedWorkerShifts: Record<string, Partial<WorkerShift>>
}

export const UpdateWorkerShiftTimeSheetsModal = (
  props: UpdateWorkerShiftTimeSheetsModalProps,
) => {
  const { onUpdate, handleClose, data, editedWorkerShifts } = props
  const [error, setError] = useState('')
  const [adjustmentReason, setAdjustmentReason] = useState('')
  const [updating, setUpdating] = useState(false)
  const [showAdjustmentReasonError, setShowAdjustmentReasonError] =
    useState(false)

  const editedWorkerShiftsWithCharges: WorkerShiftWithCharges[] =
    useMemo(() => {
      const editedEntitiesWithWorkerShiftsAndCharges:
        | WorkerWithWorkerShiftAndCharges[]
        | ShiftWithWorkerShiftsAndCharges[] = parseTimesheetData(
        data,
        editedWorkerShifts,
      )
      return (
        editedEntitiesWithWorkerShiftsAndCharges?.flatMap((entity) =>
          entity.workerShifts.filter(
            (ws) =>
              !!editedWorkerShifts[
                getWorkerShiftStringId(ws.workerId, ws.shiftId)
              ],
          ),
        ) ?? []
      )
    }, [data, editedWorkerShifts])

  const workerShiftCharges = useTimeSheetWorkerShiftCharges(
    editedWorkerShiftsWithCharges,
  )
  const { getWorkerById, isLoading: isLoadingWorkers } = useWorkersByIds(
    editedWorkerShiftsWithCharges.map((ws) => ws.workerId),
  )

  const shiftIdToWorkerShifts = editedWorkerShiftsWithCharges.reduce(
    (acc, workerShift) => {
      const shiftId = workerShift.shiftId
      if (!acc[shiftId]) {
        acc[shiftId] = []
      }
      acc[shiftId].push(workerShift)
      return acc
    },
    {} as Record<string, WorkerShiftWithCharges[]>,
  )

  const largeEditWarning =
    'Some of these worker times have been adjusted by greater than 15%. Edits for these workers will be reviewed by the Traba team before processing.'
  const displayLargeEditWarning =
    workerShiftCharges.some(
      (charge) => charge.data && !charge.data.shouldAutoApprove,
    ) || editedWorkerShiftsWithCharges.some((ws) => !!ws.pendingAdjustment)
  const isLoading = workerShiftCharges.some((charge) => charge.isLoading)

  const handleConfirm = async () => {
    if (!adjustmentReason || adjustmentReason.length === 0) {
      setShowAdjustmentReasonError(true)
      return
    }

    setUpdating(true)
    try {
      const promises = Object.entries(shiftIdToWorkerShifts).map(
        ([shiftId, workerShifts]) =>
          trabaApi.post(`/my-company/worker-shifts/${shiftId}/adjustments`, {
            workerShiftBizAdjustments: workerShifts.map((edits) => ({
              ...edits,
              adjustmentReason,
              source: AdjustmentSource.BUSINESS,
            })),
          }),
      )

      const results = await Promise.allSettled(promises)

      const failedWorkerIdsAndReasons = {}
      const succeededWorkerIds = []

      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          const data = result.value.data
          if (data.failedWorkerIdsAndReasons) {
            Object.assign(
              failedWorkerIdsAndReasons,
              data.failedWorkerIdsAndReasons,
            )
          }
          succeededWorkerIds.push(...data.succeededWorkerIds)
        }
      })

      if (Object.keys(failedWorkerIdsAndReasons).length) {
        throw new Error('Something went wrong. Please try again.')
      }
      onUpdate()
      handleClose()
    } catch (err) {
      setError((err as Error)?.message)
    } finally {
      setUpdating(false)
    }
  }

  const titleText = `Update Timesheet`

  return (
    <UpdateTimeSheetModalContainer>
      <Row alignCenter style={{ marginBottom: `${theme.space.xs}px` }}>
        <Text variant="h4"> {titleText}</Text>
      </Row>

      <NoTextDivider />

      {error && (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity={'error'}
          text={error}
        />
      )}

      <Card style={{ marginTop: theme.space.sm }}>
        <Text variant="h4">{'New Adjusted Total'}</Text>
        {displayLargeEditWarning && (
          <InlineBanner
            style={{ marginTop: theme.space.xs }}
            severity={'error'}
            text={largeEditWarning}
          />
        )}
        <Text variant="body2" style={{ padding: '16px 0' }}>
          This approximate includes pay for these workers and associated fees,
          including markup.
        </Text>

        {isLoading || isLoadingWorkers ? (
          <LoadingSpinner style={{ width: 30, height: 30 }} />
        ) : (
          <>
            {editedWorkerShiftsWithCharges.map((workerShift, idx) => {
              const { data: workerCharge, isLoading } = workerShiftCharges[idx]
              const worker = getWorkerById(workerShift.workerId)
              const workerShiftStringId = getWorkerShiftStringId(
                workerShift.workerId,
                workerShift.shiftId,
              )
              return (
                <React.Fragment key={workerShiftStringId}>
                  <Row justifyBetween>
                    <Col>
                      <WorkerPhotoAndName worker={worker} disableLink />
                      <Text>{`${workerShift.shiftInfo.shiftRole}, ${workerShift.shiftInfo.shortLocation}`}</Text>
                      <Text>
                        {formatShiftTime(
                          workerShift.clockInTime ||
                            workerShift.shiftInfo.startTime,
                          workerShift.clockOutTime ||
                            workerShift.shiftInfo.endTime,
                          workerShift.shiftInfo.timezone,
                        )}
                      </Text>
                    </Col>
                    <Col>
                      <TimeSheetsWorkerEarning
                        workerCharge={workerCharge}
                        isLoading={isLoading}
                        showEstimatedCharge={workerShift.hasPendingEdits}
                        hideBizPendingAdjustmentTooltip={true}
                      />
                    </Col>
                  </Row>
                  <Divider
                    wrapperStyle={{
                      marginTop: theme.space.xs,
                      marginBottom: theme.space.xs,
                    }}
                  />
                </React.Fragment>
              )
            })}
          </>
        )}
      </Card>

      <Input
        rows={2}
        label="Adjustment Reason (required)"
        placeholder="Add a reason"
        type="textarea"
        className="xs-12"
        value={adjustmentReason}
        errorMessage={'Please add a reason for this adjustment.'}
        inputStatus={
          showAdjustmentReasonError ? InputStatus.error : InputStatus.default
        }
        onChange={(e) => {
          setAdjustmentReason(e.target.value)
          setShowAdjustmentReasonError(e.target.value.length === 0)
        }}
      />

      <Row
        justifyBetween
        style={{
          marginTop: theme.space.med,
          gap: theme.space.xxs,
        }}
      >
        <Button
          variant={ButtonVariant.OUTLINED}
          style={{ margin: `${theme.space.xxs / 2}px 0`, width: '200px' }}
          onClick={handleClose}
        >
          Continue Editing
        </Button>
        <Button
          variant={ButtonVariant.FILLED}
          disabled={updating || isLoading}
          style={{ margin: `${theme.space.xxs / 2}px 0`, width: '200px' }}
          onClick={async () => {
            await handleConfirm()
          }}
        >
          {updating ? (
            <LoadingSpinner style={{ width: 15, height: 15 }} />
          ) : (
            'Update'
          )}
        </Button>
      </Row>
    </UpdateTimeSheetModalContainer>
  )
}

const UpdateTimeSheetModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space.med}px;
  /* width: 543px; */
  @media only screen and (max-width: (${({ theme }) =>
      theme.media.maxExtraSmall})) {
    width: 100%;
  }
`
