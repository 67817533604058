import { fromDate } from '@internationalized/date'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ClearIcon from '@mui/icons-material/Clear'
import { useDateRangePicker } from '@react-aria/datepicker'
import { useDateRangePickerState } from '@react-stately/datepicker'
import {
  AriaDateRangePickerProps,
  DateRange,
  DateValue,
} from '@react-types/datepicker'
import { useRef } from 'react'
import { theme } from 'src/libs/theme'
import { getLocalTimezone } from 'src/shared/utils/dateUtils'
import styled from 'styled-components'

import { InputErrorIcon, InputErrorMessage } from '../Input/Input.styles'
import Row from '../Row'
import { FieldButton } from './Button'
import DateField from './DateField'
import Dialog from './Dialog'
import Popover from './Popover'
import RangeCalendar from './RangeCalendar'
import * as S from './styles'

const DateFieldRow = styled(Row)<{ isInvalid: boolean }>`
  display: flex;
  border: 1px solid;
  border-color: ${({ isInvalid }) =>
    isInvalid ? theme.colors.red : theme.colors.Grey30};
  border-radius: 5px;
  &:hover {
    border-color: ${({ isInvalid }) =>
      isInvalid ? theme.colors.red : theme.colors.Grey50};
  }

  &:focus-within {
    border-color: ${({ isInvalid }) =>
      isInvalid ? theme.colors.red : theme.colors.Violet};
  }
`

interface DateRangePickerProps extends AriaDateRangePickerProps<DateValue> {
  dateRange?: [Date | null, Date | null]
  setDateRange: (newDateRange: [Date | null, Date | null]) => void
  maxDate?: Date
  isClearable?: boolean
  inlineLabel?: boolean
  timezone?: string
  style?: React.CSSProperties
}

export default function DateRangePicker(props: DateRangePickerProps) {
  const timezone = props.timezone || getLocalTimezone()
  const modifiedProps = {
    ...props,
    onChange: (date: DateRange) => {
      if (!date) {
        props.setDateRange([null, null])
      } else {
        props.setDateRange([
          date.start.toDate(timezone),
          date.end.toDate(timezone),
        ])
      }
    },
    value:
      props.dateRange && props.dateRange[0] && props.dateRange[1]
        ? {
            start: fromDate(props.dateRange[0], timezone),
            end: fromDate(props.dateRange[1], timezone),
          }
        : null,
    maxValue: props.maxDate ? fromDate(props.maxDate, timezone) : undefined,
  }
  const state = useDateRangePickerState({
    ...modifiedProps,
    shouldCloseOnSelect: false,
  })

  const handleClearButtonClick = () => {
    state.setValue(null)
  }

  const ref = useRef(null)
  const {
    labelProps,
    groupProps,
    startFieldProps,
    endFieldProps,
    buttonProps,
    dialogProps,
    calendarProps,
  } = useDateRangePicker(modifiedProps, state, ref)

  return (
    <div
      style={{
        ...props.style,
        display: 'inline-flex',
        flexDirection: 'column',
      }}
    >
      <S.Label {...labelProps} inlineLabel={props.inlineLabel ?? false}>
        {props.label}
      </S.Label>
      <div {...groupProps} ref={ref} className="group">
        <DateFieldRow
          style={{ alignItems: 'center' }}
          isInvalid={state.isInvalid}
        >
          <DateField {...startFieldProps} />
          <span>-</span>
          <DateField {...endFieldProps} />
          <div style={{ display: 'flex' }}>
            <FieldButton {...buttonProps}>
              <CalendarMonthIcon fontSize="small" color="action" />
            </FieldButton>
            {props.isClearable && (
              <S.ClearIconContainer onClick={handleClearButtonClick}>
                <ClearIcon fontSize="small" />
              </S.ClearIconContainer>
            )}
          </div>
        </DateFieldRow>
      </div>
      {state.isInvalid &&
        state.displayValidation.validationErrors.map((errorMessage, index) => (
          <InputErrorMessage key={index}>
            <InputErrorIcon />
            {errorMessage}
          </InputErrorMessage>
        ))}
      {state.isOpen && (
        <Popover state={state} triggerRef={ref} placement="bottom start">
          <Dialog {...dialogProps}>
            <RangeCalendar {...calendarProps} timezone={timezone} />
          </Dialog>
        </Popover>
      )}
    </div>
  )
}
