import {
  CardTile,
  SvgIcon,
  Col,
  Row,
  Text,
  DotMenu,
  DotMenuProps,
  Badge,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { CostCenterType } from '@traba/types'

export type CostCenterProps = {
  costCenter: CostCenterType
  archive?: (costCenter: CostCenterType) => void
  edit?: (costCenter: CostCenterType) => void
  userCanEdit?: boolean
}

export const CostCenter = (props: CostCenterProps) => {
  const { costCenter, archive, edit } = props

  const menuItems: DotMenuProps['menuItems'] = []
  if (edit) {
    menuItems.push({
      title: 'Edit cost center',
      iconName: 'edit',
      onClick: () => {
        edit(costCenter)
        window.analytics.track(`User Clicked Edit Cost Center`, {})
      },
      color: theme.colors.MidnightBlue,
    })
  }

  if (archive) {
    menuItems.push({
      title: 'Archive cost center',
      iconName: 'trash',
      onClick: () => {
        archive(costCenter)
        window.analytics.track(`User Clicked Archive Cost Center`, {})
      },
      color: theme.colors.Red60,
    })
  }

  const actionableCard = props.userCanEdit && menuItems.length > 0

  return (
    <Row alignCenter gap={theme.space.xxs}>
      <CardTile size="56px">
        <SvgIcon name="dollar" />{' '}
      </CardTile>
      <Col
        pl={24}
        width={actionableCard ? '90%' : '100%'}
        gap={theme.space.xxs}
      >
        <Row alignCenter gap={theme.space.xs}>
          <Text variant="h4">{costCenter.name}</Text>
          <Badge
            variant={costCenter.status === 'ACTIVE' ? 'success' : 'business'}
            title={costCenter.status}
          />
        </Row>
        {costCenter.locations.length > 0 && (
          <Row alignCenter gap={theme.space.xxs}>
            <Text variant="h6">Locations:</Text>
            <Text variant="body2">
              {costCenter.locations.map((loc) => loc.name).join(', ')}
            </Text>
          </Row>
        )}
      </Col>
      {actionableCard && (
        <Col width="10%">
          <Row justifyEnd>
            <DotMenu
              type="costCenters"
              dotMenuKey={costCenter.id}
              menuItems={menuItems}
            />
          </Row>
        </Col>
      )}
    </Row>
  )
}
