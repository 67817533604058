import { RegionIdToRegionMap } from '@traba/types'
import {
  getAddressString,
  getLocationNameOrTruncatedAddress,
} from '@traba/utils'
import { IMenuItem, MenuItemGroup } from '../../Select/Select'
import {
  RegionIdToPartialLocationMap,
  GroupedLocationSearchSelectorLocationProps,
} from './types'

export function createRegionMenuItemGroups(
  regionToLocationsMap: RegionIdToPartialLocationMap,
  regionMap: RegionIdToRegionMap,
): MenuItemGroup[] {
  return Object.keys(regionToLocationsMap).map((regionId) => ({
    id: regionId,
    title: regionMap[regionId]?.displayName,
  }))
}

export function createLocationMenuItemsWithRegionGroups(
  regionMenuItemGroups: MenuItemGroup[],
  regionToLocationsMap: RegionIdToPartialLocationMap,
): IMenuItem[] {
  return regionMenuItemGroups.flatMap(({ id: regionId }) =>
    regionToLocationsMap[regionId].map((loc) => ({
      value: loc.locationId,
      label: getLocationNameOrTruncatedAddress(loc),
      secondaryLabel: getAddressString(loc.address),
      groupId: regionId,
    })),
  )
}

export function createLocationMenuItemFromLocation(
  location: GroupedLocationSearchSelectorLocationProps,
): IMenuItem {
  return {
    value: location.locationId,
    label: getLocationNameOrTruncatedAddress(location),
    secondaryLabel: getAddressString(location.address),
    groupId: location.regionId,
  }
}
