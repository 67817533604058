export enum TimesheetApprovalTab {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
}

export enum TimesheetsPage {
  APPROVAL = 'APPROVAL',
  TOTALS = 'TOTALS',
  DAILY = 'DAILY',
}

export enum TimesheetsGroupBy {
  WORKER = 'WORKER',
  SHIFT = 'SHIFT',
}

export type TimesheetSummaryResponse = {
  shifts: {
    id: string
    startTime: Date
    endTime: Date
    timezone: string
    roleName: string
    activeLocationId: string
    numWorkerShifts: number
    numApprovedWorkerShifts: number
    uniqueWorkerIds: string[]
    totalTimeWorked: number
  }[]
}
