import { SetStateAction } from 'react'
import { theme } from 'src/libs/theme'

import { Row, Text } from '../base'
import { Checkbox } from '../base/CheckboxThemed'

interface Props {
  agreedToSmsConsent: boolean
  setAgreedToSmsConsent: (value: SetStateAction<boolean>) => void
}

export const SignUpSmsConsent = ({
  agreedToSmsConsent,
  setAgreedToSmsConsent,
}: Props) => {
  return (
    <Row mt={theme.space.xs}>
      <Checkbox
        selected={agreedToSmsConsent}
        onClick={() => {
          setAgreedToSmsConsent(!agreedToSmsConsent)
        }}
      />
      <Text variant="body3" ml={theme.space.xxs}>
        By checking the box, I consent to receive text messages from Traba Inc.
        at the number provided above, and I agree that texts may be sent using
        through an autodialer or other technology.
      </Text>
    </Row>
  )
}
