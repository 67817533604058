import { Tooltip } from '@mui/material'
import * as Sentry from '@sentry/react'
import { useAlert } from '@traba/context'
import { ParentInvoiceGroup } from '@traba/types'
import { InputStatus } from '@traba/types'
import { useState } from 'react'
import { Col, Icon, Input, Row, Text } from 'src/components/base'
import { Dialog } from 'src/components/base/Dialog/Dialog'
import { RadioButton } from 'src/components/RadioButton'
import { InfoIcon } from 'src/components/WorkerDetails/WorkerDetails.styles'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { REQUIRED_FIELD_MESSAGE } from 'src/libs/constants'
import { theme } from 'src/libs/theme'

type CreateOrEditInvoiceGroupModalProps = {
  onClose: () => void
  onCreateOrEdit?: (group: ParentInvoiceGroup) => void
  group?: ParentInvoiceGroup // if editing
}

export const CreateOrEditInvoiceGroupModal = (
  props: CreateOrEditInvoiceGroupModalProps,
) => {
  const { group: existingGroup } = props

  const [name, setName] = useState<string | undefined>(
    existingGroup?.name ?? undefined,
  )
  const [description, setDescription] = useState<string | undefined>(
    existingGroup?.description ?? undefined,
  )
  const [recurring, setRecurring] = useState(existingGroup?.recurring ?? false)

  const [nameTouched, setNameTouched] = useState(false)
  const [upsertingGroup, setUpsertingGroup] = useState(false)

  const { createInvoiceGroup, editInvoiceGroup } = useInvoiceGroups()
  const { showError } = useAlert()

  function handleCreate() {
    if (!name) {
      return
    }
    setUpsertingGroup(true)
    createInvoiceGroup(
      { name, description, recurring },
      {
        onSuccess: (newGroup: ParentInvoiceGroup) => {
          if (props.onCreateOrEdit) {
            props.onCreateOrEdit(newGroup)
          }
          setUpsertingGroup(false)
          props.onClose()
        },
        onError: (err) => {
          Sentry.captureException(err, {
            tags: { action: 'creating invoice group' },
          })
          setUpsertingGroup(false)
          showError(
            'An error has occurred. Please try again or contact support if the issue persists.',
            'Unable to create invoice group',
          )
        },
      },
    )
  }

  function handleEdit() {
    if (!existingGroup) {
      return
    }
    setUpsertingGroup(true)
    editInvoiceGroup(
      {
        groupId: existingGroup.id,
        updateGroupData: { name, description, recurring },
      },
      {
        onSuccess: (editedGroup: ParentInvoiceGroup) => {
          if (props.onCreateOrEdit) {
            props.onCreateOrEdit(editedGroup)
          }
          setUpsertingGroup(false)
          props.onClose()
        },
        onError: (err) => {
          Sentry.captureException(err, {
            tags: { action: 'editing invoice group' },
          })
          setUpsertingGroup(false)
          showError(
            'An error has occurred. Please try again or contact support if the issue persists.',
            'Unable to edit invoice group',
          )
        },
      },
    )
  }

  return (
    <Dialog
      dialogTitle={
        existingGroup ? 'Edit invoice group' : 'Create invoice group'
      }
      onConfirmCTA={existingGroup ? 'Edit' : 'Create'}
      onConfirm={existingGroup ? handleEdit : handleCreate}
      onClose={props.onClose}
      confirmDisabled={!name}
      open
      confirming={upsertingGroup}
    >
      <div style={{ width: '100%' }}>
        <Text variant="body1">
          Invoice groups can be used to customize how your shifts are invoiced.
          Shifts in the same invoice group will be charged together and the
          group name and description will be included on the invoice memo.
        </Text>
        <Row style={{ columnGap: theme.space.sm }}>
          <div style={{ flex: 1 }}>
            <Input
              label="Group name"
              value={name}
              inputStatus={
                nameTouched && !name ? InputStatus.error : InputStatus.default
              }
              onChange={(e) => setName(e.target.value)}
              onBlur={() => setNameTouched(true)}
              errorMessage={!name ? REQUIRED_FIELD_MESSAGE : undefined}
              full
              containerStyle={{
                marginTop: theme.space.xs,
                width: '100%',
              }}
            />
          </div>
        </Row>

        <Row style={{ columnGap: theme.space.sm }}>
          <div style={{ flex: 1 }}>
            <Input
              type="textarea"
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              full
              rows={2}
              containerStyle={{
                marginTop: theme.space.xs,
                width: '100%',
              }}
            />
          </div>
        </Row>

        <Row>
          <Text
            variant="h6"
            style={{ marginTop: theme.space.xs, marginBottom: theme.space.xs }}
          >
            Type
          </Text>
          <Tooltip
            title="Select One-time Purchase Order for any group that will not be reused and is for a specific set of shifts or events (Example: All shifts for a specific concert). Select Reusable Invoice group for any invoice group that you want to use in the future (Example: Weekly shifts at Location 1)."
            style={{ marginTop: theme.space.xs }}
          >
            <InfoIcon hover={false}>
              <Icon
                name="info"
                style={{
                  // svg is slightly off center :/
                  marginLeft: 2,
                  marginTop: 4,
                }}
              />
            </InfoIcon>
          </Tooltip>
        </Row>

        <Row alignCenter style={{ columnGap: theme.space.xl }}>
          <Col>
            <Row
              alignCenter
              style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
              onClick={() => setRecurring(false)}
            >
              <RadioButton selected={!recurring} />
              <Text variant="body1">One-time purchase order</Text>
            </Row>
          </Col>
          <Col>
            <Row
              alignCenter
              style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
              onClick={() => setRecurring(true)}
            >
              <RadioButton selected={recurring} />
              <Text variant="body1">Reusable invoice group</Text>
            </Row>
          </Col>
        </Row>
      </div>
    </Dialog>
  )
}
