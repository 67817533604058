import { ROLE_LOCATION_SHIFT_LOCATION_MISMATCH_ERROR_MSG } from '@traba/consts'
import { COMPANY_WIDE_ID, Role } from '@traba/types'
import { getLocationNameOrTruncatedAddress } from './stringUtils'

export const isRoleLocationNotMatchingShiftLocation = (
  locationId?: string,
  role?: Pick<Role, 'location'>,
): boolean => {
  // If no role or location to compare, we are not considering they are mismatch
  if (!role || !role.location || !locationId) {
    return false
  }

  return role.location?.locationId !== locationId
}

export const getRoleSearchSelectErrorMessage = (
  locationId?: string,
  role?: Pick<Role, 'location'>,
): string | undefined => {
  return isRoleLocationNotMatchingShiftLocation(locationId, role)
    ? ROLE_LOCATION_SHIFT_LOCATION_MISMATCH_ERROR_MSG
    : undefined
}

export function sortRolesByLocationNameThenRoleName(
  roleA: Partial<Role>,
  roleB: Partial<Role>,
) {
  const roleNameA = roleA.roleName || ''
  const roleNameB = roleB.roleName || ''
  const roleLocationA = roleA.location
  const roleLocationB = roleB.location
  const roleIsCompanyWideA = !roleLocationA
  const roleIsCompanyWideB = !roleLocationB
  // if the role is company-wide, show those roles first
  if (roleIsCompanyWideA && roleIsCompanyWideB) {
    return roleNameA.localeCompare(roleNameB)
  }
  if (roleIsCompanyWideA) {
    return -1
  }
  if (roleIsCompanyWideB) {
    return 1
  }

  // compare the role's location names next
  const locationNameA = getLocationNameOrTruncatedAddress(roleLocationA)
  const locationNameB = getLocationNameOrTruncatedAddress(roleLocationB)

  if (locationNameA !== locationNameB) {
    return locationNameA.localeCompare(locationNameB)
  }

  // compare role name next
  return roleNameA.localeCompare(roleNameB)
}

export function isRoleNameDupeInSameLocation({
  roles = [],
  newRoleName,
  newRoleLocationId,
  currentRoleId,
}: {
  roles?: Pick<Role, 'roleName' | 'location' | 'roleId'>[]
  newRoleName: string
  newRoleLocationId?: string
  currentRoleId?: string
}) {
  const isNewRoleCompanyWide =
    !newRoleLocationId || newRoleLocationId === COMPANY_WIDE_ID

  const rolesInTheSameLocation = roles.filter((role) => {
    if (currentRoleId === role.roleId) {
      // do not check the current role if the name is the same
      return false
    }

    const isRoleCompanyWide = !role.location
    return (
      (isRoleCompanyWide && isNewRoleCompanyWide) ||
      role.location?.locationId === newRoleLocationId
    )
  })

  return !!rolesInTheSameLocation.find(
    (role) =>
      role.roleName.trim().toLocaleLowerCase() ===
      newRoleName.trim().toLocaleLowerCase(),
  )
}
