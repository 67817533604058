import { useHotSettings } from '@traba/hooks'
import { theme } from '@traba/theme'
import { WorkerShiftWithCharges } from '@traba/types'
import { calculateTimeWorkedInMinutes } from '@traba/utils'
import { TableFooter, Td } from 'src/components/Table/Table'
import {
  TimeSheetsWorkerCharge,
  WorkerShiftIdDto,
} from 'src/hooks/useTimesheet'
import { TimesheetsGroupBy } from 'src/screens/TimeSheets/types'
import { formatDuration } from 'src/shared/utils/dateUtils'
import { Button, ButtonVariant, SvgIcon, Text } from '../../base'
import { chargeIsApprovable, chargeIsUnapprovable } from '../utils'
import { TimeSheetsWorkerEarning } from './TimeSheetsWorkerEarning'

interface TimeSheetsTableTotalsRowProps {
  groupBy: TimesheetsGroupBy
  workerShifts?: WorkerShiftWithCharges[]
  editMode: boolean
  userCanViewWages: boolean
  workerCharge: TimeSheetsWorkerCharge | undefined
  isLoadingEstimatedCharge?: boolean
  numberOfColumns: number
  showApproveAll: boolean
  enableWorkerSegments?: boolean
  showUnapproveAll: boolean
  handleApproveAllWorkerShifts: (
    workerShiftIds: WorkerShiftIdDto[],
  ) => Promise<void>
  handleUnapproveAllWorkerShifts: (
    workerShiftIds: WorkerShiftIdDto[],
  ) => Promise<void>
}
export const TimeSheetsTableTotalsRow = (
  props: TimeSheetsTableTotalsRowProps,
) => {
  const { hotSettings } = useHotSettings()
  const {
    groupBy,
    workerShifts,
    userCanViewWages,
    numberOfColumns,
    showApproveAll,
    enableWorkerSegments,
    showUnapproveAll,
    handleApproveAllWorkerShifts,
    handleUnapproveAllWorkerShifts,
  } = props
  const totalTimeWorked = workerShifts?.reduce(
    (sum, ws) =>
      sum + calculateTimeWorkedInMinutes(ws, hotSettings?.enableWorkerEditTime),
    0,
  )

  const showEstimatedCharge = workerShifts?.some((ws) => ws.hasPendingEdits)
  const numLeftEmptyCols =
    (groupBy === TimesheetsGroupBy.WORKER ? 4 : 2) +
    (enableWorkerSegments ? 1 : 0)
  const numRightEmptyCols =
    numberOfColumns -
    (numLeftEmptyCols +
      Number(userCanViewWages) +
      Number(showApproveAll || showUnapproveAll) +
      3)
  const showBulkApproval = showApproveAll || showUnapproveAll
  return (
    <TableFooter
      style={{ backgroundColor: theme.colors.Grey20 }}
      noBorderRadius={true}
    >
      {Array(numLeftEmptyCols)
        .fill(null)
        .map((_, index) => (
          <Td key={index}>&nbsp;</Td>
        ))}
      <Td colSpan={2}>
        <Text
          variant="caption"
          style={{ textAlign: 'right' }}
        >{`${groupBy} TOTALS`}</Text>
      </Td>
      <Td>
        <Text variant="h7">{formatDuration(totalTimeWorked ?? 0)}</Text>
      </Td>
      {userCanViewWages && (
        <Td>
          <TimeSheetsWorkerEarning
            workerCharge={props.workerCharge}
            isLoading={!!props.isLoadingEstimatedCharge}
            showEstimatedCharge={showEstimatedCharge}
            hideBizPendingAdjustmentTooltip={true}
          />
        </Td>
      )}

      {showBulkApproval && (
        <Td>
          {showApproveAll && (
            <Button
              rightIcon={<SvgIcon name="check" />}
              variant={ButtonVariant.BRANDLINK}
              iconPadding={`${theme.space.xxs}px`}
              onClick={async () => {
                workerShifts &&
                  handleApproveAllWorkerShifts(
                    workerShifts
                      .filter((ws) => chargeIsApprovable(ws))
                      .map((ws) => ({
                        workerId: ws.workerId,
                        shiftId: ws.shiftId,
                      })),
                  )
              }}
            >
              {'Approve All'}
            </Button>
          )}
          {showUnapproveAll && (
            <Button
              rightIcon={<SvgIcon name="cancel" color={theme.colors.red} />}
              variant={ButtonVariant.ERROR}
              iconPadding={`${theme.space.xxs}px`}
              onClick={async () => {
                workerShifts &&
                  handleUnapproveAllWorkerShifts(
                    workerShifts
                      .filter((ws) => chargeIsUnapprovable(ws))
                      .map((ws) => ({
                        workerId: ws.workerId,
                        shiftId: ws.shiftId,
                      })),
                  )
              }}
            >
              {'Unapprove All'}
            </Button>
          )}
        </Td>
      )}
      {Array(numRightEmptyCols)
        .fill(null)
        .map((_, index) => (
          <Td key={index}>&nbsp;</Td>
        ))}
    </TableFooter>
  )
}
