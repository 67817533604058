import styled from 'styled-components'

export const CompanyLogoContainer = styled.div``

export const LogoImageWrapper = styled.div`
  width: 72px;
  height: 72px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 3px #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LogoImage = styled.img`
  width: 90%;
  border-radius: 8px;
`

export const LogoIconWrapper = styled.div`
  width: 72px;
  height: 72px;
  > * {
    background-color: #fff;
  }
`

export const CompanyFirstLetter = styled.p<{ fontSize: string }>`
  color: ${({ theme }) => theme.colors.brand};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: bold;
`
