import * as Sentry from '@sentry/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { ARCHIVED_REGION_ID } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  LocationResponse,
  RegionIdToLocationMap,
  UpdateLocationDto,
  UpdateLocationResponseDto,
} from '@traba/types'
import {
  isLocationActive,
  isLocationArchived,
  isLocationValid,
} from '@traba/utils'
import { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { LocationRequest } from 'src/types'
import { useMembers } from './useMembers'
import { useSelectSingleRegionalFilterLocation } from './useRegionalFilter'
import { SHIFTS_LOCATIONS_QUERY_KEY } from './useShiftsLocationsForSupervisor'
import { SHIFTS_SUPERVISORS_FOR_LOCATION_QUERY_KEY } from './useShiftsSupervisorsForLocation'

const MY_COMPANY_LOCATIONS_QUERY_KEY = 'locations'
const MY_COMPANY_ALL_LOCATIONS_QUERY_KEY = 'allLocations'

export async function getActiveRegion(postalCode: string) {
  try {
    const response = await trabaApi.get(
      `/regions/postal-code/${postalCode}/active-region?consumer=USER`,
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const getLocations = async () => {
  try {
    const response = await trabaApi.get(`/my-company/locations`)
    return response.data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error, {
      tags: { action: `My Company Get Locations` },
    })
    throw error
  }
}

const createLocation = async (newLocation: LocationRequest) => {
  const { locationMedia, ...rest } = newLocation
  const response = await trabaApi.post(`my-company/locations`, {
    ...rest,
    media: locationMedia,
  })
  return response.data
}

interface EditLocationParams {
  locationId: string
  updatedLocation: UpdateLocationDto
}

const editLocation = async ({
  locationId,
  updatedLocation,
}: EditLocationParams) => {
  const response = await trabaApi.patch(
    `my-company/location/${locationId}`,
    updatedLocation,
  )
  return response.data
}

const archiveLocation = async (locationId: string) => {
  const response = await trabaApi.patch(
    `my-company/location/${locationId}/archive`,
  )
  return response.data
}

/*
 * Locations of most recent active ones (if the location was edited, it will contain most recent one
 * and if it was archived, it will contain the location that was archived  (archived not by edit)
 * For instance, if location1.0 was edited to location1.1, location1.1 will be included
 * If location1.1 was archived, location1.1 will be included
 */
const getUniqueLocations = (locations: LocationResponse[] | undefined) => {
  return (
    locations?.filter(
      (location) => location.activeLocationId === location.locationId,
    ) ?? []
  )
}

// NOTE: these returned locations are filtered by the
// user's UserAccessLevel + which locations they are assigned to
// (if they are COMPANY_WIDE UserAccessLevel, then all company locations are returned)
export const useLocations = () => {
  const queryClient = useQueryClient()
  const { showSuccess, showError, handleError } = useAlert()
  const { refetchMembers } = useMembers()
  const { refetch: refetchAllCompanyLocations } = useAllCompanyLocations()
  const { selectSingleLocationForRegionalFilter } =
    useSelectSingleRegionalFilterLocation()

  const {
    isLoading,
    isError,
    data: locations,
    error,
    isFetched: isLocationsFetched,
    refetch,
  } = useQuery<LocationResponse[], Error>({
    queryKey: [MY_COMPANY_LOCATIONS_QUERY_KEY],
    queryFn: getLocations,
  })

  const createLocationMutation = useMutation<
    LocationResponse,
    AxiosError,
    LocationRequest
  >({
    mutationFn: createLocation,
    onSuccess: (response, variables) => {
      queryClient.setQueryData(
        [MY_COMPANY_LOCATIONS_QUERY_KEY],
        (currentLocations: LocationResponse[] | undefined) => {
          return currentLocations ? [response, ...currentLocations] : [response]
        },
      )

      // refetch members if users were assigned to the new location
      // b/c this is a *new* location, we only need to refetch if users were assigned to it
      // (aka when userIds is not empty), otherwise current users are unaffected
      if (variables.userIds && variables.userIds.length > 0) {
        refetchMembers()
      }

      refetch() // refetch for the new location
      refetchAllCompanyLocations()

      // auto select the location in the global regional filter on creation
      selectSingleLocationForRegionalFilter(response)

      showSuccess('Location created successfully!')
    },
    onError: (error) => {
      const message =
        error.message ??
        'There was an error creating the location. Please try again or contact support if the issue persists.'
      handleError(
        error,
        'CreateLocationUI -> createLocation',
        message,
        'Error creating location',
      )
    },
  })

  const editLocationMutation = useMutation<
    UpdateLocationResponseDto,
    AxiosError,
    EditLocationParams
  >({
    mutationFn: editLocation,
    onSuccess: (response, variables) => {
      const locationId = variables.locationId
      if (!response.updatedLocation) {
        showError(
          'If you meant to update the role, double check the fields you wish to update.',
          'No changes detected',
        )
        return
      }
      let successMessage =
        'Changes were successfully saved. Future shifts tied to this location have been updated.'
      if (response.archivedLocationId) {
        successMessage +=
          ' Shifts that were in the past, are ongoing, or start within 2 hours were not updated. If you need these shifts updated, please contact support.'
      }
      showSuccess(successMessage, 'Edit location successful.')

      // refetch members as updated assigned members may have changed
      refetchMembers()

      // invalidate future shifts for the users that were replaced & their replacements
      const idsToInvalidateForFutureShifts =
        variables.updatedLocation.replacementSupervisorsForUsers?.flatMap(
          ({ oldSupervisorId, newSupervisorId }) => [
            oldSupervisorId,
            newSupervisorId,
          ],
        ) || []

      for (const userId of idsToInvalidateForFutureShifts) {
        queryClient.invalidateQueries({
          queryKey: [SHIFTS_LOCATIONS_QUERY_KEY, userId],
        })
      }

      // invalidate the future shifts for this location if supervisors were replaced
      if (idsToInvalidateForFutureShifts.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [SHIFTS_SUPERVISORS_FOR_LOCATION_QUERY_KEY, locationId],
        })
      }

      // update location
      refetch()
      refetchAllCompanyLocations()
    },
    onError: (error) => {
      let errorTitle = 'Error editing location'
      let errorMessage =
        error.message ??
        'There was an error editing the location. Please try again or contact support if the issue persists.'

      if (error.message === 'edit-location/exceeds-radius-threshold') {
        errorTitle = 'Invalid location edit.'
        errorMessage =
          'Unfortunately, the new location you entered is farther than the allowed distance from your previous location. If you need this location updated, please contact support.'
      }

      handleError(
        error,
        'useLocations -> editLocationMutation',
        errorMessage,
        errorTitle,
      )
    },
  })

  const archiveLocationMutation = useMutation<
    LocationResponse,
    AxiosError,
    string
  >({
    mutationFn: archiveLocation,
    onSuccess: (response) => {
      // refetch members as updated assigned members may have changed
      refetchMembers()

      // update location
      queryClient.setQueryData(
        [MY_COMPANY_LOCATIONS_QUERY_KEY],
        (currentLocations: LocationResponse[] | undefined) => {
          return currentLocations
            ? currentLocations.map((location: LocationResponse) =>
                location.locationId === response.locationId
                  ? response
                  : location,
              )
            : []
        },
      )
      showSuccess(
        'Location will no longer be suggested when creating shifts.',
        'Location archived!',
      )
    },
    onError: (error) => {
      const errorMessage =
        error.message ||
        'There was an error editing the location. Please try again or contact support if the issue persists.'
      handleError(
        error,
        'useLocations -> archiveLocationMutation',
        errorMessage,
        'Error archiving location',
      )
    },
  })

  const getLocationById = useCallback(
    (id?: string) => {
      if (!id) {
        return undefined
      }
      return locations?.find((location) => location.locationId === id)
    },
    [locations],
  )
  const getLocationsByIds = useCallback(
    (ids: string[]) => {
      if (!ids.length) {
        return []
      }

      const idsSet = new Set(ids)
      return locations?.filter((location) => idsSet.has(location.locationId))
    },
    [locations],
  )

  const uniqueLocations = useMemo(
    () => getUniqueLocations(locations),
    [locations],
  )
  const validLocations = useMemo(
    () => uniqueLocations.filter(isLocationValid),
    [uniqueLocations],
  )
  const activeLocations = useMemo(
    () => uniqueLocations?.filter(isLocationActive) || [],
    [uniqueLocations],
  )
  const activeValidLocations = useMemo(
    () => activeLocations.filter(isLocationValid),
    [activeLocations],
  )
  const archivedLocations = useMemo(
    () => locations?.filter(isLocationArchived) || [],
    [locations],
  )
  const archivedValidLocations = useMemo(
    () => archivedLocations.filter(isLocationValid),
    [archivedLocations],
  )

  return {
    isLoading,
    isError,
    error,
    isLocationsFetched,
    getLocationById,
    getLocationsByIds,
    locations: locations || [],
    activeLocations,
    activeValidLocations,
    archivedLocations,
    archivedValidLocations,
    uniqueLocations,
    validLocations,
    createLocation: createLocationMutation.mutateAsync,
    editLocation: editLocationMutation.mutateAsync,
    archiveLocation: archiveLocationMutation.mutateAsync,
    refetch,
  }
}

const getAllCompanyLocations = async () => {
  try {
    const response = await trabaApi.get('/my-company/all-locations')
    return response.data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error, {
      tags: { action: `My Company All Locations` },
    })
    throw error
  }
}

// With Regional Access updates, the locations returned for a user will be filtered
// based on their access level and/or which locations they are assigned to.
// For some cases, we want to return all locations of the company, regardless of the user's access level.
export function useAllCompanyLocations() {
  const {
    isLoading,
    isError,
    data: locations,
    error,
    isFetched: isLocationsFetched,
    refetch,
  } = useQuery<LocationResponse[], Error>({
    queryKey: [MY_COMPANY_ALL_LOCATIONS_QUERY_KEY],
    queryFn: getAllCompanyLocations,
  })

  const uniqueLocations = getUniqueLocations(locations)
  const validLocations = uniqueLocations.filter(isLocationValid)
  const activeLocations = uniqueLocations?.filter(isLocationActive) || []
  const activeValidLocations = activeLocations.filter(isLocationValid)
  const archivedLocations = locations?.filter(isLocationArchived) || []
  const archivedValidLocations = archivedLocations.filter(isLocationValid)

  return {
    isLoading,
    isError,
    error,
    isLocationsFetched,
    refetch,
    locations: locations || [],
    uniqueLocations,
    validLocations,
    activeLocations,
    activeValidLocations,
    archivedLocations,
    archivedValidLocations,
  }
}

export function useRegionLocationMap() {
  const { isLoading, validLocations, activeValidLocations } = useLocations()

  const regionLocationMap = useMemo(
    () =>
      validLocations.reduce<RegionIdToLocationMap>((acc, location) => {
        const key = isLocationArchived(location)
          ? ARCHIVED_REGION_ID
          : location.regionId
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(location)
        return acc
      }, {}),
    [validLocations],
  )

  const activeRegionsWithLocationsMap = useMemo(
    () =>
      activeValidLocations.reduce<RegionIdToLocationMap>((acc, location) => {
        const key = location.regionId
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(location)
        return acc
      }, {}),
    [activeValidLocations],
  )

  return { isLoading, regionLocationMap, activeRegionsWithLocationsMap }
}
