import styled from 'styled-components'

export const ServiceFeeContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
`
export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => theme.space.xs}px
    ${({ theme }) => theme.space.med}px;
`
