import * as Sentry from '@sentry/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  CreateShiftRequest,
  ShiftRequestEditInput,
  ShiftRequestParentWithShiftRequest,
  UpdateShiftRequestParentEndDateProps,
} from '@traba/types'
import { ErrorNames } from '@traba/utils'
import { AxiosResponse } from 'axios'
import { useNavigate } from 'react-router-dom'
import { FIVE_MINUTES_IN_MS } from '../libs/constants'

export interface UpdatedShiftRequestInvitation {
  shiftRequestId: string
  workerIds: string[]
}

interface EditShiftRequestParentMetadata {
  title: string
}

interface EditShiftRequestParentParams {
  shiftRequestParentId: string
  newShiftRequests: CreateShiftRequest[]
  shiftRequestEdits: ShiftRequestEditInput[]
  updatedShiftRequestInvitations: UpdatedShiftRequestInvitation[]
  shiftRequestParentMetadata?: EditShiftRequestParentMetadata
}

const getShiftRequestParent = async (
  shiftRequestParentId: string,
): Promise<ShiftRequestParentWithShiftRequest | undefined> => {
  try {
    const { data } = await trabaApi.get(
      `my-company/shift-request-parents/${shiftRequestParentId}`,
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

const editShiftRequestParent = async ({
  shiftRequestParentId,
  ...input
}: EditShiftRequestParentParams) => {
  const { data } = await trabaApi.patch(
    `/my-company/shift-request-parents/${shiftRequestParentId}`,
    input,
  )
  return data
}

export const useShiftRequestParent = (shiftRequestParentId: string) => {
  const { showSuccess, showError } = useAlert()
  const navigate = useNavigate()
  const {
    isLoading,
    isError,
    data: shiftRequestParent,
    error,
    isFetched,
    refetch,
  } = useQuery<ShiftRequestParentWithShiftRequest | undefined, Error>({
    queryKey: [`shift-request-parent`, shiftRequestParentId],
    queryFn: () => getShiftRequestParent(shiftRequestParentId),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!shiftRequestParentId,
  })

  const editShiftRequestParentMutation = useMutation<
    ShiftRequestParentWithShiftRequest,
    Error,
    EditShiftRequestParentParams
  >({
    mutationFn: editShiftRequestParent,
    onSuccess: () => {
      refetch()
      showSuccess('Schedule Edited')
      navigate(`/schedule/${shiftRequestParentId}`)
    },
    onError: (error) => {
      console.error(error)
      if (error.name === ErrorNames.SHIFT_EDIT_WINDOW_CLOSED) {
        showError(error.message)
      } else {
        showError('Failed to edit schedule')
      }
    },
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    shiftRequestParent,
    refetch,
    editShiftRequestParent: editShiftRequestParentMutation.mutateAsync,
  }
}

export function useBizShiftRequestParentEndDateMutation() {
  const { showError, showSuccess } = useAlert()

  const mutation = useMutation<
    AxiosResponse<string, string>,
    Error,
    UpdateShiftRequestParentEndDateProps
  >({
    mutationFn: (query: UpdateShiftRequestParentEndDateProps) => {
      const { shiftRequestParentId, endDate } = query
      return trabaApi.patch(
        `my-company/shift-request-parents/${shiftRequestParentId}/end-date`,
        { endDate },
      )
    },
    onSuccess: () => {
      showSuccess('Schedule end date updated successfully')
    },
    onError: (err) => {
      showError(
        `There was an error updating shift request parent end date: ${err.message}`,
      )
      console.error(err)
      Sentry.captureException(err)
    },
  })

  return {
    updateShiftRequestParentEndDate: mutation.mutateAsync,
    isPending: mutation.isPending,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  }
}
