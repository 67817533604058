import { Controls, Player } from '@lottiefiles/react-lottie-player'
import { HTMLProps } from 'react'

interface LoadingSpinnerProps {
  style?: HTMLProps<HTMLElement>['style']
}

export function LoadingSpinner(props: LoadingSpinnerProps) {
  return (
    <Player
      autoplay
      loop
      src="https://assets10.lottiefiles.com/packages/lf20_l3f63uqc.json"
      style={{ height: '80px', width: '80px', ...props.style }}
      speed={0.82}
      className="meticulous-ignore"
    >
      <Controls
        visible={false}
        buttons={['play', 'repeat', 'frame', 'debug']}
      />
    </Player>
  )
}
