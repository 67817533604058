import React from 'react'

import { CreatePaymentUI } from './CreatePayment.ui'

export type CreatePaymentProps = {
  phoneNumber: string
  email: string
  stripeReturnPath: string
  isOnboardingFlow: boolean
  completeOnboarding?: () => void
}

export const CreatePayment = (props: CreatePaymentProps) => {
  return <CreatePaymentUI {...props} />
}
