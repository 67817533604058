import { UserRolePermission } from '@traba/types'
import { BillingSettings } from '../BillingSettings/BillingSettings'
import { CompanyProfile } from '../CompanyProfile'
import { CostCentersProfile } from '../CostCentersProfile/CostCentersProfile'
import { DeleteAccount } from '../DeleteAccount'
import { LocationsProfile } from '../LocationsProfile'
import { MembersProfile } from '../MembersProfile'
import { NotificationSettings } from '../NotificationSettings/NotificationSettings'
import { PersonalProfile } from '../PersonalProfile'
import { RolesProfile } from '../RolesProfile'
import { TrainingVideosProfile } from '../TrainingVideos'
import { SettingsTab, SETTINGS_TAB_KEYS } from './types'

export const ACCOUNT_SETTINGS_TABS: SettingsTab[] = [
  {
    key: SETTINGS_TAB_KEYS.PERSONAL_SETTINGS,
    label: 'Personal settings',
    Component: PersonalProfile,
    permissions: [],
  },
  {
    key: SETTINGS_TAB_KEYS.NOTIFICATION_SETTINGS,
    label: 'Notification settings',
    Component: NotificationSettings,
    permissions: [],
  },
  {
    key: SETTINGS_TAB_KEYS.DELETE_ACCOUNT,
    label: 'Delete Account',
    Component: DeleteAccount,
    permissions: [UserRolePermission.ViewShifts],
  },
]

export const COMPANY_SETTINGS_TABS: SettingsTab[] = [
  {
    key: SETTINGS_TAB_KEYS.COMPANY_PROFILE,
    label: 'Company profile',
    Component: CompanyProfile,
    permissions: [UserRolePermission.ViewCompanySettings],
  },
  {
    key: SETTINGS_TAB_KEYS.MEMBERS,
    label: 'Members',
    Component: MembersProfile,
    permissions: [
      UserRolePermission.ViewUserRoles,
      UserRolePermission.ViewShifts,
    ],
  },
  {
    key: SETTINGS_TAB_KEYS.LOCATIONS,
    label: 'Locations',
    Component: LocationsProfile,
    permissions: [UserRolePermission.ViewShifts],
  },
  {
    key: SETTINGS_TAB_KEYS.BILLING,
    label: 'Billing',
    Component: BillingSettings,
    permissions: [UserRolePermission.ViewPaymentSettings],
  },
  {
    key: SETTINGS_TAB_KEYS.ROLES,
    label: 'Roles',
    Component: RolesProfile,
    permissions: [UserRolePermission.ViewShifts],
  },
  {
    key: SETTINGS_TAB_KEYS.TRAINING_VIDEOS,
    label: 'Training Videos',
    Component: TrainingVideosProfile,
    permissions: [
      UserRolePermission.ManageCompanySettings,
      UserRolePermission.ManageShifts,
    ],
  },
  {
    key: SETTINGS_TAB_KEYS.COST_CENTERS,
    label: 'Cost Centers',
    Component: CostCentersProfile,
    permissions: [],
  },
]
