import { SvgIconProps } from '@traba/types'

export default function Reject({ size, color, ...rest }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
      <path
        d="M12.4211 2.29044C12.1861 2.29044 11.9632 2.34474 11.7626 2.4413C11.6265 1.67535 10.9826 1.09338 10.2105 1.09338C9.968 1.09338 9.73817 1.15091 9.53262 1.25344C9.36235 0.534598 8.73996 0 8 0C7.26004 0 6.63765 0.534631 6.46738 1.25344C6.26186 1.15094 6.032 1.09338 5.78947 1.09338C4.91884 1.09338 4.21053 1.83334 4.21053 2.74286V6.77493C4.01698 6.68632 3.80344 6.6368 3.57895 6.6368C2.70832 6.6368 2 7.37676 2 8.28628V11.6784C2 14.0613 3.85577 16 6.13684 16H9.86316C12.1442 16 14 14.0613 14 11.6784V3.93993C14 3.03037 13.2917 2.29044 12.4211 2.29044ZM13.0526 11.6784C13.0526 13.5156 11.6218 15.0103 9.86316 15.0103H6.13684C4.37818 15.0103 2.94737 13.5156 2.94737 11.6784V8.28628C2.94737 7.92247 3.23069 7.62649 3.57895 7.62649C3.9272 7.62649 4.21053 7.92247 4.21053 8.28628V10.9255H5.15789V2.74286C5.15789 2.37905 5.44122 2.08307 5.78947 2.08307C6.13773 2.08307 6.42105 2.37905 6.42105 2.74286V7.95639H7.36842V1.64948C7.36842 1.28567 7.65175 0.989691 8 0.989691C8.34825 0.989691 8.63158 1.28567 8.63158 1.64948V7.95639H9.57895V2.74286C9.57895 2.37905 9.86227 2.08307 10.2105 2.08307C10.5588 2.08307 10.8421 2.37905 10.8421 2.74286V7.95639H11.7895V3.93989C11.7895 3.57608 12.0728 3.2801 12.4211 3.2801C12.7693 3.2801 13.0526 3.57608 13.0526 3.93989V11.6784Z"
        fill={color}
      />
    </svg>
  )
}
