import { Button, SvgIcon, Text, ButtonVariant } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Td } from '../../Table/Table'

export function ViewCostCentersButton({
  handleCostCentersClick,
  viewCostCenters,
}: {
  handleCostCentersClick: () => void
  viewCostCenters: boolean
}) {
  return (
    <Td
      style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
      onClick={handleCostCentersClick}
    >
      <Button variant={ButtonVariant.BRANDLINK}>
        <Text variant="link">View</Text>
        <SvgIcon
          name={viewCostCenters ? 'chevronUp' : 'chevronDown'}
          size={14}
          style={{ marginLeft: theme.space.xxs }}
        />
      </Button>
    </Td>
  )
}
