import { NotifPreferencesOption } from '../PersonalProfileNotificationsCard'

export const emailInvoiceOptions: NotifPreferencesOption[] = [
  {
    name: 'Invoice updates and reminders',
    isSelected: (communicationPreferences) => {
      return (
        (communicationPreferences?.finalizeInvoice?.email &&
          communicationPreferences?.invoiceReminder?.email) ??
        false
      )
    },
    onClick: async (user, patchUser, communicationPreferences) => {
      patchUser({
        communicationPreferences: {
          ...communicationPreferences,
          finalizeInvoice: {
            ...communicationPreferences?.finalizeInvoice,
            email: true,
          },
          invoiceReminder: {
            ...communicationPreferences?.invoiceReminder,
            email: true,
          },
        },
      })
    },
    shouldShowOption: (_user) => {
      return true
    },
  },

  {
    name: 'Invoice updates only',
    isSelected: (communicationPreferences) => {
      return (
        (communicationPreferences?.finalizeInvoice?.email &&
          !communicationPreferences?.invoiceReminder?.email) ??
        false
      )
    },
    onClick: async (_user, patchUser, communicationPreferences) => {
      patchUser({
        communicationPreferences: {
          ...communicationPreferences,
          finalizeInvoice: {
            ...communicationPreferences?.finalizeInvoice,
            email: true,
          },
          invoiceReminder: {
            ...communicationPreferences?.invoiceReminder,
            email: false,
          },
        },
      })
    },
    shouldShowOption: (_user) => {
      return true
    },
  },

  {
    name: 'None',
    isSelected: (communicationPreferences) => {
      return (
        (!communicationPreferences?.finalizeInvoice?.email &&
          !communicationPreferences?.invoiceReminder?.email) ??
        false
      )
    },
    onClick: async (user, patchUser, communicationPreferences) => {
      patchUser({
        communicationPreferences: {
          ...communicationPreferences,
          finalizeInvoice: {
            ...communicationPreferences?.finalizeInvoice,
            email: false,
          },
          invoiceReminder: {
            ...communicationPreferences?.invoiceReminder,
            email: false,
          },
        },
      })
    },
    shouldShowOption: (_user) => {
      return true
    },
  },
]
