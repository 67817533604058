import { SvgIconProps } from '@traba/types'

export default function ({
  size = 13,
  color,
  strokeWidth,
  ...props
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size ?? (size * 18) / 13}
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.4587 1.4469V4.08587H9.94011V1.4469M11.6994 9.3638H14.3384M2.02321 4.08587H3.78252M1.14355 7.60449H3.78252M2.02321 11.1231H3.78252M7.30114 12.0028H16.0977C16.5835 12.0028 16.9774 11.6089 16.9774 11.1231V2.32656C16.9774 1.84073 16.5835 1.4469 16.0977 1.4469H7.30114C6.81532 1.4469 6.42149 1.84073 6.42149 2.32655V11.1231C6.42149 11.6089 6.81532 12.0028 7.30114 12.0028Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
