import { Menu, MenuItem, Tooltip } from '@mui/material'
import { ClientShift, Shift } from '@traba/types'
import { UserRole } from '@traba/types'
import { ShiftPayType } from '@traba/types'
import { shiftIsClientShift } from '@traba/utils'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, SvgIcon, Text } from 'src/components/base'
import useMobile from 'src/hooks/useMobile'
import { useHotSettings } from 'src/hooks/useSystem'
import { useUser } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'

export default function EditShiftMenu({
  shift,
  isSeries,
}: {
  shift: Shift | ClientShift
  isSeries?: boolean
}) {
  const { isMobileViewOrReactNative } = useMobile()

  const { user } = useUser()
  const navigate = useNavigate()
  const [anchorElement, setAnchorElement] = React.useState<
    null | SVGElement | HTMLButtonElement
  >(null)
  const open = !!anchorElement
  function handleClick(
    event: React.MouseEvent<SVGElement | HTMLButtonElement>,
  ) {
    window.analytics.track(`User Clicked Edit Shift Menu`, {
      shift,
      isSeries,
    })
    setAnchorElement(event.currentTarget)
  }
  function handleClose() {
    setAnchorElement(null)
  }

  const { hotSettings } = useHotSettings()
  const shouldFreezeShiftPosting = hotSettings?.freezeShiftsPosting

  const disabled =
    user?.role === UserRole.LimitedShiftCreator &&
    shift.payType === ShiftPayType.UNIT

  const editItemsIfInfinite = [
    <MenuItem
      onClick={() => {
        navigate(`/edit-shifts/${shift.shiftId}?editOperation=SINGLE`)
        window.analytics.track(`User Clicked Edit Shift Menu Edit Single`, {
          shift,
          isSeries,
        })
        handleClose()
      }}
      style={{ padding: `${theme.space.sm / 2} ${theme.space.sm}` }}
    >
      Edit Single Shift
    </MenuItem>,
    <MenuItem
      onClick={() => {
        navigate(`/edit-shifts/${shift.shiftId}?editOperation=ALL_FUTURE`)
        window.analytics.track(`User Clicked Edit Shift Menu Edit All Future`, {
          shift,
          isSeries,
        })
        handleClose()
      }}
      style={{ padding: `${theme.space.sm / 2} ${theme.space.sm}` }}
    >
      Edit All Future Shifts In Series
    </MenuItem>,
  ]

  const editItemsIfNotInfinite = [
    <MenuItem
      disabled={!isSeries}
      onClick={() => {
        navigate(`/edit-shifts/${shift.shiftId}?editOperation=SERIES`)
        window.analytics.track(`User Clicked Edit Shift Menu Edit Series`, {
          shift,
          isSeries,
        })
        handleClose()
      }}
      style={{ padding: `${theme.space.sm / 2} ${theme.space.sm}` }}
    >
      Edit Multiple in Series
    </MenuItem>,
    <MenuItem
      onClick={() => {
        navigate(`/edit-shifts/${shift.shiftId}`)
        window.analytics.track(`User Clicked Edit Shift Menu Edit Single`, {
          shift,
          isSeries,
        })
        handleClose()
      }}
      style={{ padding: `${theme.space.sm / 2} ${theme.space.sm}` }}
    >
      Edit Single Shift
    </MenuItem>,
  ]

  return (
    <>
      <Tooltip
        title={
          disabled
            ? `You don't have permission to edit this shift`
            : shouldFreezeShiftPosting
              ? `Shift editing temporarily disabled`
              : ''
        }
      >
        {isMobileViewOrReactNative ? (
          <SvgIcon
            name="edit"
            onClick={
              !disabled && !shouldFreezeShiftPosting ? handleClick : undefined
            }
          />
        ) : (
          <Button
            leftIcon={<SvgIcon name="edit" color={'white'} />}
            onClick={handleClick}
            disabled={disabled || shouldFreezeShiftPosting}
          >
            <Text variant="h7" style={{ color: 'white' }}>
              Edit
            </Text>
          </Button>
        )}
      </Tooltip>

      {/* MENU ITEMS */}
      <Menu
        id={`shift-menu`}
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `shift-menu`,
        }}
      >
        {...shiftIsClientShift(shift) && shift.editInfinite
          ? editItemsIfInfinite
          : editItemsIfNotInfinite}
      </Menu>
    </>
  )
}
