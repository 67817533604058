import { LoadingSpinner } from '@traba/react-components'
import { ShiftStatus, RoleAttributeStatus } from '@traba/types'
import { getShiftDateString, getShiftTimeString } from '@traba/utils'
import { isAfter } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import {
  Avatar,
  Badge,
  Button,
  Col,
  Row,
  SvgIcon,
  Text,
} from 'src/components/base'
import { ClockCode } from 'src/components/base/ClockCode'
import { PointOfContact } from 'src/components/PointOfContact'
import { useWorkersOnShift } from 'src/components/WorkersOnShiftTable/WorkersOnShiftTable.hooks'
import { useRoleAttributes } from 'src/hooks/useAttributes'
import {
  certificationName,
  useCertifications,
} from 'src/hooks/useCertifications'
import { useLocations } from 'src/hooks/useLocations'
import { useMembers } from 'src/hooks/useMembers'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { useWorkerShifts } from 'src/hooks/workerShiftHooks'
import { theme } from 'src/libs/theme'
import { getDailyViewUrlSlug } from 'src/shared/utils/dateUtils'
import { getMoneyString, getPayUnitString } from 'src/utils/moneyUtils'
import { getAddressString } from 'src/utils/stringUtils'

interface Props {
  shift: ShiftAndAddress
  userCanViewWages?: boolean
  setSelectedShift: React.Dispatch<
    React.SetStateAction<ShiftAndAddress | undefined>
  >
  isMobileView: boolean
  isFromScheduleDetails?: boolean
}
export const CalendarShiftDetails = ({
  shift,
  userCanViewWages,
  setSelectedShift,
  isMobileView,
  isFromScheduleDetails,
}: Props) => {
  const navigate = useNavigate()

  const { getLocationById, isLoading: isLoadingLocation } = useLocations()
  const { getMemberById, isLoading: isLoadingMembers } = useMembers()
  const { certifications, isLoading: isLoadingCertifications } =
    useCertifications()
  const { roleAttributes, isLoading: isLoadingAttributes } = useRoleAttributes(
    RoleAttributeStatus.Active,
  )

  const {
    startTime,
    endTime,
    timezone,
    requiredCertifications,
    clockOutCode,
    confirmationCode,
    requiredAttributes,
    payRate,
    payType,
    slotsRequested,
    id,
  } = shift
  const location = getLocationById(shift.locationId)
  const parkingLocation = getLocationById(shift?.parkingLocationId || '')
  const pointOfContact = getMemberById(shift?.supervisorId || '')
  const { data: workerShifts, isLoading: isLoadingWorkerShifts } =
    useWorkerShifts(id)
  const { workersOnShift } = useWorkersOnShift({
    isUpcoming: isAfter(startTime, new Date()),
    workerShifts,
    slotsRequested,
  })

  const navToShiftDetails = () => {
    if (isFromScheduleDetails) {
      shift.shiftRequestParentId &&
        navigate(
          `/schedule/${shift.shiftRequestParentId}/${getDailyViewUrlSlug(shift.originalStartTime)}`,
        )
    } else {
      navigate(`/calendar/${shift.shiftId}`)
    }
  }

  const isLoading =
    isLoadingLocation ||
    isLoadingMembers ||
    isLoadingAttributes ||
    isLoadingWorkerShifts ||
    isLoadingCertifications

  return (
    <div
      style={{
        backgroundColor: theme.colors.White,
        zIndex: 100,
        width: isMobileView ? '100%' : 540,
        minHeight: 580,
        padding: isMobileView ? theme.space.ms : theme.space.xs,
      }}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {!isMobileView && (
            <>
              <Row justifyEnd>
                <SvgIcon
                  name="cancel"
                  onClick={() => setSelectedShift(undefined)}
                />
              </Row>
              <Row alignCenter mb={theme.space.xxs}>
                <Text variant="h4">{shift?.shiftRole}</Text>
                {shift.status === ShiftStatus.CANCELED && (
                  <Badge
                    style={{ marginLeft: theme.space.xxs }}
                    variant="danger"
                    title="canceled"
                  />
                )}
              </Row>
            </>
          )}

          <Text variant="body2">{`${getShiftDateString(
            startTime,
            endTime,
            timezone,
          )}, ${getShiftTimeString(startTime, endTime, timezone)}`}</Text>
          {location && (
            <Text variant="body2">{getAddressString(location.address)}</Text>
          )}

          <Row mt={theme.space.sm} wrap>
            <Col width={'50%'} mb={theme.space.sm}>
              <Text variant="caption" mb={theme.space.xxs}>
                CLOCK IN
              </Text>
              <ClockCode
                code={confirmationCode}
                tileHeight={32}
                tileWidth={24}
                fontSize={14}
              />
            </Col>
            <Col width={'50%'} mb={theme.space.sm}>
              <Text variant="caption" mb={theme.space.xxs}>
                CLOCK OUT
              </Text>
              <ClockCode
                code={clockOutCode}
                tileHeight={32}
                tileWidth={24}
                fontSize={14}
              />
            </Col>
            {userCanViewWages && (
              <Col width={'50%'} mb={theme.space.sm}>
                <Text variant="caption" mb={theme.space.xxs}>
                  PAY RATE
                </Text>
                <Text variant="body1">
                  {getMoneyString(payRate)}
                  {getPayUnitString(payType)}
                </Text>
              </Col>
            )}
            <Col width={'50%'} mb={theme.space.sm}>
              <Text variant="caption" mb={theme.space.xxs}>
                REQUIREMENTS
              </Text>
              {requiredAttributes ? (
                <>
                  {requiredAttributes.map((a) => {
                    const attr = roleAttributes?.find(
                      (attr) =>
                        attr.type === (typeof a === 'string' ? a : a.type),
                    )
                    if (!attr) {
                      return null
                    }
                    return (
                      <Text style={{ overflowWrap: 'break-word' }}>
                        {attr.displayName}
                      </Text>
                    )
                  })}
                </>
              ) : (
                <Text>None</Text>
              )}
            </Col>
            <Col width={'50%'} mb={theme.space.sm}>
              <Text variant="caption" mb={theme.space.xxs}>
                CERTIFICATIONS
              </Text>
              <Text variant="body1" style={{ overflowWrap: 'break-word' }}>
                {requiredCertifications?.length && certifications
                  ? requiredCertifications
                      .map((rc) => certificationName(rc, certifications))
                      .join(', ')
                  : 'None'}
              </Text>
            </Col>

            {pointOfContact?.firstName ? (
              <Col width={'50%'} mb={theme.space.sm}>
                <Text variant="caption" mb={theme.space.xxs}>
                  ON-SITE POINT OF CONTACT
                </Text>
                <PointOfContact user={pointOfContact} slim />
              </Col>
            ) : null}
            {parkingLocation?.address && (
              <Col width={'50%'} mb={theme.space.sm}>
                <Text variant="caption" mb={theme.space.xxs}>
                  PARKING ADDRESS
                </Text>
                <Text variant="body1">{parkingLocation.name}</Text>
                <Text variant="body1">
                  {getAddressString(parkingLocation.address)}
                </Text>
              </Col>
            )}
          </Row>
          <Col mb={theme.space.xs}>
            <Text variant="caption" mb={theme.space.xxs}>
              {`WORKERS ON SHIFT (${workersOnShift.length} / ${slotsRequested})`}
            </Text>
            <Row wrap alignCenter>
              {workersOnShift.slice(0, 3).map((worker) => {
                const { worker: workerDetails } = worker
                return (
                  <Row key={worker.workerId} alignCenter mr={theme.space.xxs}>
                    <Avatar
                      src={workerDetails.photoUrl}
                      name={`${workerDetails.firstName} ${workerDetails.lastName}`}
                      size={30}
                    />
                    <Text>{`${workerDetails.firstName} ${workerDetails.lastName}`}</Text>
                  </Row>
                )
              })}
              {workersOnShift.length > 3 && (
                <Text>{`+${workersOnShift.length - 3} more`}</Text>
              )}
            </Row>
          </Col>
          {!isMobileView && (
            <Row justifyEnd mt={theme.space.xs}>
              <Button slim onClick={navToShiftDetails}>
                Go to shift details
              </Button>
            </Row>
          )}
        </>
      )}
    </div>
  )
}
