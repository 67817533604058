import { SvgIconProps } from 'src/types/svg-types'

export default function Language({ size = 52, ...props }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 52 52" fill="none" {...props}>
      <circle cx="26" cy="26" r="26" fill="#F5EBFF" />
      <g clip-path="url(#clip0_345_4597)">
        <path
          d="M13.6737 23.1624C14.6593 19.0224 17.8717 15.8059 21.9658 14.8597L22.2908 14.7846C24.4936 14.2754 26.7811 14.2754 28.984 14.7846L29.5393 14.9129C33.4923 15.8265 36.594 18.9322 37.5456 22.9295L37.6751 23.4733C38.1653 25.5328 38.1653 27.681 37.6751 29.7404C36.6878 33.8876 33.0287 36.8095 28.8223 36.8095H27.2758C26.7679 36.8095 26.3083 37.1256 26.1153 37.6021C25.4139 39.3335 23.4557 40.1833 21.7464 39.4777L21.5742 39.4066C17.6242 37.7761 14.6796 34.2767 13.6786 30.072C13.1397 27.8082 13.1348 25.4262 13.6737 23.1624Z"
          fill="#6600CC"
        />
        <path
          d="M20.8435 22.9254H29.6323M20.8435 27.7877H26.4363M27.2758 36.8095H28.8223C33.0287 36.8095 36.6878 33.8876 37.6751 29.7404C38.1653 27.681 38.1653 25.5328 37.6751 23.4733L37.5456 22.9295C36.594 18.9322 33.4923 15.8265 29.5393 14.9129L28.984 14.7846C26.7811 14.2754 24.4936 14.2754 22.2908 14.7846L21.9658 14.8597C17.8717 15.8059 14.6593 19.0224 13.6737 23.1624C13.1348 25.4262 13.1397 27.8082 13.6786 30.072C14.6796 34.2767 17.6242 37.7761 21.5742 39.4066L21.7464 39.4777C23.4557 40.1833 25.4139 39.3335 26.1153 37.6021C26.3083 37.1256 26.7679 36.8095 27.2758 36.8095Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_345_4597">
          <rect
            width="29"
            height="28"
            fill="white"
            transform="translate(11.1573 12.4264)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
