import * as Sentry from '@sentry/react'
import { ButtonVariant, DotMenu, DotMenuProps } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { WorkerShift, WorkerShiftWithWorkerDetails } from '@traba/types'
import { AxiosResponse } from 'axios'
import { differenceInHours, differenceInMinutes } from 'date-fns'
import { useState } from 'react'
import { UseMutateAsyncFunction } from 'react-query'
import { ReviewWorkerWizard } from 'src/components/ReviewWorker/components/ReviewWorkerWizard'
import { useConnections } from 'src/hooks/useConnections'
import useMobile from 'src/hooks/useMobile'
import {
  BulkWorkerActionBasePayload,
  ClockInOutResult,
  ClockInWorkers,
  ClockOutWorkers,
  RejectWorkers,
} from 'src/hooks/workerShiftHooks'
import { theme } from 'src/libs/theme'
import { JobStatus } from 'src/types'
import { IconName } from 'src/types/svg-types'

import { RemoveConnectionConfirmationModal } from '../RemoveConnectionConfirmationModal/RemoveConnectionConfirmationModal'
import { WorkerManagementModal } from '../WorkerManagementModal/WorkerManagementModal'

const EIGHTEEN_HOURS_IN_MINUTES = 18 * 60

interface WorkerMenuProps {
  shiftId: string
  workers: WorkerShiftWithWorkerDetails[]
  connections: ReturnType<typeof useConnections>
  onFavorite: (workerIds: string[]) => void
  shiftStartTime: Date
  shiftEndTime: Date
  shiftRole: string
  timezone: string
  minimumPaidTime: number
  onManagementModalClose?: () => void
  clockInWorkers: UseMutateAsyncFunction<
    ClockInOutResult,
    Error,
    ClockInWorkers
  >
  editClockInWorkers: UseMutateAsyncFunction<
    ClockInOutResult,
    Error,
    ClockInWorkers
  >
  clockOutWorkers: UseMutateAsyncFunction<
    ClockInOutResult,
    Error,
    ClockOutWorkers
  >
  abandonWorkers: UseMutateAsyncFunction<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    ClockOutWorkers
  >
  rejectWorkers: UseMutateAsyncFunction<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    RejectWorkers
  >
  noShowWorkers: UseMutateAsyncFunction<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    BulkWorkerActionBasePayload
  >
  bizCancelRemoveWorkers: UseMutateAsyncFunction<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    BulkWorkerActionBasePayload
  >
  workerRowContext?: boolean
  buttonVariant?: ButtonVariant
}

export enum WorkerAction {
  ClockIn = 'CLOCK_IN',
  EditClockIn = 'EDIT_CLOCK_IN',
  ClockOut = 'CLOCK_OUT',
  Favorite = 'FAVORITE',
  UnFavorite = 'UN_FAVORITE',
  UnBlock = 'UN_BLOCK',
  ReviewWorkers = 'REVIEW_WORKERS',
  Block = 'BLOCK',
  Abandon = 'ABANDON',
  // "Turn Away" a worker after 30 mins pre-shift start
  TurnAway = 'TURN_AWAY',
  NoShow = 'NO_SHOW',
  // "Remove" a worker before 18 hours pre-shift start -- treated as a business cancel
  RemoveContactUs = 'REMOVE_CONTACT_US',
  // "Remove" a worker after 18 hours pre-shift start -- opens modal to contact support
  RemoveBizCancel = 'REMOVE_BIZ_CANCEL',
}

export const NEGATIVE_WORKER_ACTIONS: WorkerAction[] = [
  WorkerAction.Abandon,
  WorkerAction.TurnAway,
  WorkerAction.NoShow,
  WorkerAction.Block,
]

function filterInProgressWorkers(
  workerShifts: WorkerShiftWithWorkerDetails[],
  allowTODO?: boolean,
): WorkerShiftWithWorkerDetails[] {
  return workerShifts.filter(
    (worker) =>
      worker.jobStatus === JobStatus.InProgress ||
      worker.jobStatus === JobStatus.OnBreak ||
      (allowTODO && worker.jobStatus === JobStatus.ToDo),
  )
}

function getWorkersText(workers: WorkerShift[]) {
  return `worker${makePlural<WorkerShift>(workers)}`
}

export function getActionMetadata({
  action,
  workerShifts,
  connections,
  workerRowContext,
  shiftStartTime,
}: {
  action: WorkerAction
  workerShifts: WorkerShiftWithWorkerDetails[]
  connections: ReturnType<typeof useConnections>
  openWorkerManagementModal?: () => void
  workerRowContext?: boolean
  shiftStartTime: Date
}) {
  let eligibleWorkers: WorkerShiftWithWorkerDetails[] = []
  let menuItemTitle = ``,
    enabledTooltipText = ``
  let iconName: IconName = 'check'
  let hidden = false
  switch (action) {
    case WorkerAction.ClockIn:
      eligibleWorkers = workerRowContext
        ? []
        : workerShifts.filter((worker) => worker.jobStatus === JobStatus.ToDo)
      hidden = !eligibleWorkers.length
      menuItemTitle = `Clock in ${getWorkersText(eligibleWorkers)}`
      enabledTooltipText = workerRowContext
        ? 'Clock in worker.'
        : `Clock in ${eligibleWorkers.length} scheduled ${getWorkersText(
            eligibleWorkers,
          )}.`
      iconName = 'clockIn'
      break
    case WorkerAction.EditClockIn:
      eligibleWorkers = workerRowContext
        ? []
        : filterInProgressWorkers(workerShifts)
      hidden = eligibleWorkers.length !== workerShifts.length // all must be clocked in
      menuItemTitle = `Edit ${getWorkersText(eligibleWorkers)} Clock In`
      enabledTooltipText = workerRowContext
        ? 'Update clock-in time for worker.'
        : `Update clock-in time for ${eligibleWorkers.length} ${getWorkersText(
            eligibleWorkers,
          )}.`
      iconName = 'clockIn'

      break
    case WorkerAction.ClockOut:
      eligibleWorkers = workerRowContext
        ? []
        : filterInProgressWorkers(workerShifts)
      hidden = eligibleWorkers.length !== workerShifts.length // all must be clocked in
      menuItemTitle = `Clock out ${getWorkersText(eligibleWorkers)}`
      enabledTooltipText = workerRowContext
        ? 'Clock out worker.'
        : `Clock out ${eligibleWorkers.length} in progress ${getWorkersText(
            eligibleWorkers,
          )}.`
      iconName = 'clockOut'
      break
    case WorkerAction.Abandon:
      eligibleWorkers = filterInProgressWorkers(workerShifts)
      hidden = !eligibleWorkers.length
      menuItemTitle = `Mark ${getWorkersText(eligibleWorkers)} as abandoned`
      enabledTooltipText = workerRowContext
        ? 'Mark worker as abandoned.'
        : `Mark ${
            eligibleWorkers.length
          } in progress worker${makePlural<WorkerShift>(
            eligibleWorkers,
          )} as abandoned.`
      iconName = 'abandon'
      break
    case WorkerAction.NoShow:
      eligibleWorkers = filterInProgressWorkers(workerShifts, true)
      // Only show item past shift start time
      hidden = !eligibleWorkers.length || new Date() < shiftStartTime
      menuItemTitle = `Mark ${getWorkersText(eligibleWorkers)} as no-show`
      enabledTooltipText = workerRowContext
        ? 'Mark worker as no-show.'
        : `Mark ${eligibleWorkers.length} ${getWorkersText(
            eligibleWorkers,
          )} as no-show.`
      iconName = 'noShow'
      break
    case WorkerAction.TurnAway:
      eligibleWorkers = filterInProgressWorkers(workerShifts, true)
      // Only show item if past 30 minutes before shift start time
      hidden =
        !eligibleWorkers.length ||
        differenceInMinutes(shiftStartTime, new Date()) > 30
      menuItemTitle = `Turn away ${getWorkersText(eligibleWorkers)}`
      enabledTooltipText = workerRowContext
        ? 'Turn away worker from working the shift.'
        : `Turn away ${eligibleWorkers.length} ${getWorkersText(
            eligibleWorkers,
          )} from working the shift.`
      iconName = 'reject'
      break
    case WorkerAction.Favorite:
      eligibleWorkers = workerRowContext
        ? []
        : workerShifts.filter(
            (worker) => !connections.isFavorite(worker.workerId),
          )
      hidden = !eligibleWorkers.length
      menuItemTitle = `Add to favorites`
      enabledTooltipText = `Add ${eligibleWorkers.length} ${getWorkersText(
        eligibleWorkers,
      )} to your favorites.`
      iconName = 'heart'
      break
    case WorkerAction.UnFavorite:
      eligibleWorkers = workerRowContext
        ? []
        : workerShifts.filter((worker) =>
            connections.isFavorite(worker.workerId),
          )
      hidden = eligibleWorkers.length !== workerShifts.length // all must be favorites
      menuItemTitle = `Unfavorite`
      enabledTooltipText = `Remove ${eligibleWorkers.length} ${getWorkersText(
        eligibleWorkers,
      )} from your favorites.`
      iconName = 'unfavorite'
      break
    case WorkerAction.Block:
      eligibleWorkers = workerRowContext
        ? []
        : workerShifts.filter(
            (worker) =>
              !connections.isBlocked(worker.workerId) &&
              worker.jobStatus !== JobStatus.InProgress &&
              worker.jobStatus !== JobStatus.OnBreak &&
              worker.jobStatus !== JobStatus.ToDo,
          )
      hidden = !eligibleWorkers.length
      menuItemTitle = `Add to blocked`
      enabledTooltipText = `Block ${eligibleWorkers.length} ${getWorkersText(
        eligibleWorkers,
      )}.`
      iconName = 'check'
      break
    case WorkerAction.UnBlock:
      eligibleWorkers = workerRowContext
        ? []
        : workerShifts.filter(
            (worker) =>
              connections.isBlocked(worker.workerId) ||
              connections.isScheduledBlock(worker.workerId),
          )
      hidden = !eligibleWorkers.length
      menuItemTitle = `Unblock`
      enabledTooltipText = `Remove ${eligibleWorkers.length} ${getWorkersText(
        eligibleWorkers,
      )} from your blocked list.`
      iconName = 'unblock'
      break
    case WorkerAction.RemoveContactUs: {
      eligibleWorkers = filterInProgressWorkers(workerShifts, true)
      const inTimeWindowRemove18To30 =
        differenceInMinutes(shiftStartTime, new Date()) <=
          EIGHTEEN_HOURS_IN_MINUTES &&
        differenceInMinutes(shiftStartTime, new Date()) > 30
      hidden = !eligibleWorkers.length || !inTimeWindowRemove18To30
      menuItemTitle = `Remove`
      enabledTooltipText = `Remove ${eligibleWorkers.length} ${getWorkersText(
        eligibleWorkers,
      )} from the shift.`
      iconName = 'reject'
      break
    }
    case WorkerAction.RemoveBizCancel: {
      eligibleWorkers = filterInProgressWorkers(workerShifts, true)
      const inTimeWindowPre18 =
        differenceInHours(shiftStartTime, new Date()) > 18
      hidden = !eligibleWorkers.length || !inTimeWindowPre18
      menuItemTitle = `Remove`
      enabledTooltipText = `Remove ${eligibleWorkers.length} ${getWorkersText(
        eligibleWorkers,
      )} from the shift.`
      iconName = 'reject'
      break
    }
    case WorkerAction.ReviewWorkers:
      eligibleWorkers = workerRowContext
        ? []
        : workerShifts.filter(
            (worker) =>
              worker.jobStatus !== JobStatus.Canceled &&
              worker.jobStatus !== JobStatus.NoShow &&
              worker.jobStatus !== JobStatus.ToDo,
          )
      hidden = !eligibleWorkers.length
      menuItemTitle = `Review ${getWorkersText(eligibleWorkers)}`
      enabledTooltipText = `Review ${eligibleWorkers.length} ${getWorkersText(
        eligibleWorkers,
      )}.`
      iconName = 'star'
      break
  }

  return {
    tooltip: enabledTooltipText,
    hidden,
    eligibleWorkers,
    buttonText: menuItemTitle,
    iconName,
  }
}

export default function WorkersManagementMenu({
  shiftId,
  workers,
  connections,
  onFavorite,
  onManagementModalClose,
  workerRowContext,
  shiftStartTime,
  buttonVariant,
  ...rest
}: WorkerMenuProps) {
  const { isReactNativeApp } = useMobile()

  const [
    workerManagementModalActionInProgress,
    setWorkerManagementModalActionInProgress,
  ] = useState<WorkerAction | undefined>()
  const disabled = workers.length === 0

  function mapActionToMenuItem(
    action: WorkerAction,
  ): DotMenuProps['menuItems'][0] {
    const { tooltip, eligibleWorkers, buttonText, hidden, iconName } =
      getActionMetadata({
        action,
        workerShifts: workers,
        connections,
        workerRowContext,
        shiftStartTime,
      })

    return {
      title: buttonText,
      onClick: () => {
        if (action === WorkerAction.Favorite) {
          onFavorite(eligibleWorkers.map((w) => w.workerId))
        } else if (action === WorkerAction.Block && workers.length > 1) {
          Sentry.captureMessage('Cannot block more than one worker at a time.')
        } else {
          setWorkerManagementModalActionInProgress(action)
        }
      },
      color: NEGATIVE_WORKER_ACTIONS.includes(action)
        ? theme.colors.Red60
        : theme.colors.MidnightBlue,
      hidden,
      toolTip: tooltip,
      iconName,
    }
  }

  const menuActions = Object.values(WorkerAction).filter(
    (action) => ![WorkerAction.Block].includes(action),
  )

  const menuItems = disabled
    ? []
    : menuActions.map((action) => mapActionToMenuItem(action))
  const eligibleWorkersForMenuAction = workerManagementModalActionInProgress
    ? getActionMetadata({
        action: workerManagementModalActionInProgress,
        workerShifts: workers,
        connections,
        workerRowContext,
        shiftStartTime,
      }).eligibleWorkers
    : []
  return (
    <>
      <DotMenu
        type="worker-management"
        dotMenuKey={'worker-management'}
        menuItems={menuItems}
        disabled={disabled}
        style={{
          height: isReactNativeApp ? 30 : undefined,
        }}
        buttonVariant={buttonVariant}
      />
      {workerManagementModalActionInProgress &&
        ([WorkerAction.UnBlock, WorkerAction.UnFavorite].includes(
          workerManagementModalActionInProgress,
        ) ? (
          <RemoveConnectionConfirmationModal
            isOpen={!!workerManagementModalActionInProgress}
            handleClose={() =>
              setWorkerManagementModalActionInProgress(undefined)
            }
            workers={eligibleWorkersForMenuAction}
            type={
              workerManagementModalActionInProgress === WorkerAction.UnFavorite
                ? 'favorite'
                : 'blocked'
            }
          />
        ) : [WorkerAction.ReviewWorkers].includes(
            workerManagementModalActionInProgress,
          ) ? (
          <ReviewWorkerWizard
            open={!!workerManagementModalActionInProgress}
            onClose={() => setWorkerManagementModalActionInProgress(undefined)}
            workerShiftsToReview={eligibleWorkersForMenuAction}
            title="Review Workers"
          />
        ) : (
          <WorkerManagementModal
            shiftId={shiftId}
            isOpen={!!workerManagementModalActionInProgress}
            handleClose={() => {
              setWorkerManagementModalActionInProgress(undefined)
              onManagementModalClose && onManagementModalClose()
            }}
            workers={eligibleWorkersForMenuAction}
            workerAction={workerManagementModalActionInProgress}
            shiftStartTime={shiftStartTime}
            {...rest}
          />
        ))}
    </>
  )
}
