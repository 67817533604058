import { NotificationSettingsTables } from '@traba/react-components'
import { useEffect, useState } from 'react'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'
import { useUser } from 'src/hooks/useUser'
import { Modal } from '../base'
import { SmsConsentModalForSettings } from '../Modals/SmsConsentModal/SmsConsentModalForSettings'

export const NotificationSettings = () => {
  const [showSmsConsentModal, setShowSmsConsentModal] = useState(false)
  const { user } = useUser()
  const { dispatch: appContextDispatch } = useAppContext()

  useEffect(() => {
    appContextDispatch({
      type: 'SET_REGIONAL_FILTER_STATUS',
      value: RegionalFilterStatus.DISABLED,
    })
    return () => {
      appContextDispatch({
        type: 'SET_REGIONAL_FILTER_STATUS',
        value: RegionalFilterStatus.HIDE,
      })
    }
  }, [appContextDispatch])

  return (
    <>
      <NotificationSettingsTables
        handleUserHasNoConsent={() => {
          setShowSmsConsentModal(true)
        }}
        hasSMSConsent={user?.smsConsent?.agreedToSmsConsent}
      />
      <Modal
        isOpen={showSmsConsentModal}
        handleClose={() => setShowSmsConsentModal(false)}
      >
        <SmsConsentModalForSettings
          setShowSmsConsentModal={setShowSmsConsentModal}
          onCancel={() => {
            setShowSmsConsentModal(false)
          }}
        />
      </Modal>
    </>
  )
}
