import { SvgIcon } from '@traba/react-components'
import {
  CompanyCategory,
  CompanyCategoryIcon,
  CompanyCategoryValues,
} from '@traba/types'
import { theme } from 'src/libs/theme'
import { Row, Text } from '..'

export interface IndustryBadgeProps {
  industry: CompanyCategory
}

export function IndustryBadge({ industry }: IndustryBadgeProps) {
  const name = CompanyCategoryValues[industry]
  const icon = CompanyCategoryIcon[industry]

  return (
    <Row
      style={{
        paddingTop: theme.space.xxxs,
        paddingBottom: theme.space.xxxs,
        paddingLeft: theme.space.xs,
        paddingRight: theme.space.xs,
        marginRight: theme.space.xxxs,
        display: undefined,
        border: `2px solid ${theme.colors.Grey20}`,
        borderRadius: 4,
        alignItems: 'center',
      }}
    >
      <Text style={{ marginRight: theme.space.xxxs }} variant="body1">
        {name}
      </Text>
      <SvgIcon
        color={theme.colors.MidnightBlue}
        name={icon}
        size={24}
        strokeWidth={1}
      />
    </Row>
  )
}
