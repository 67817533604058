import { theme } from '@traba/theme'
import { Shift } from '@traba/types'

import { anyToDate, formatTimeDateString } from '@traba/utils'
import { Checkbox } from '../base-components/Checkbox'
import { Text } from '../base-components/Text'
import * as S from './ShiftItem.styles'

export function ShiftItem({
  shift,
  selected,
  setSelected,
  disabledChecked,
}: {
  shift: Shift
  selected: Shift[]
  setSelected: (shifts: Shift[]) => void
  disabledChecked?: boolean
}) {
  const checked =
    !!selected.find((s) => s?.shiftId === shift.shiftId) || !!disabledChecked

  return (
    <S.ShiftItemContainer
      onClick={() => {
        if (disabledChecked) {
          return
        }
        if (checked) {
          setSelected(selected.filter((s) => s?.shiftId !== shift.shiftId))
        } else {
          setSelected([...selected, shift])
        }
      }}
    >
      <Checkbox
        checked={checked}
        disabled={disabledChecked}
        style={{ marginRight: theme.space.xs }}
      />
      <Text variant="body2">
        {formatTimeDateString(anyToDate(shift.startTime), shift.timezone)}
        {' · '}
        {`${shift.slotsFilled} of ${shift.slotsRequested} workers`}
      </Text>
    </S.ShiftItemContainer>
  )
}
