import { LocationResponse, Shift } from '@traba/types'
import { useState } from 'react'
import { Col, Divider, Row, Text } from 'src/components/base'
import useMobile from 'src/hooks/useMobile'
import { useShiftAnnouncements } from 'src/hooks/useShiftAnnouncements'
import { theme } from 'src/libs/theme'

import { canSendNewAnnouncement } from 'src/utils/announcementUtils'
import { AddAnnouncementSection } from './AddAnnouncementSection'
import { AnnouncementEmptyLoading } from './AnnouncementEmptyLoading'
import { AnnouncementItem } from './AnnouncementItem'

interface Props {
  shiftId: string
  shift: Shift
  location: LocationResponse | undefined
  parkingLocation: LocationResponse | undefined
}

export const Announcements = ({
  shiftId,
  shift,
  location,
  parkingLocation,
}: Props) => {
  const { isMobileViewOrReactNative } = useMobile()

  const { announcements, isLoading } = useShiftAnnouncements(shiftId)
  const [isAdding, setIsAdding] = useState(false)

  return (
    <div style={{ minHeight: 300 }}>
      <Col mb={theme.space.sm}>
        <Row justifyBetween>
          <Text variant="h4">Announcements</Text>{' '}
          {!isAdding && canSendNewAnnouncement(shift.endTime) && (
            <Text
              variant="link1"
              onClick={() => {
                setIsAdding(true)
              }}
              style={{ cursor: 'pointer' }}
            >
              {isMobileViewOrReactNative ? '+ New' : '+ New announcement'}
            </Text>
          )}
        </Row>

        <Text variant="body2">
          {canSendNewAnnouncement(shift.endTime)
            ? 'Send an announcement to all workers on this shift, and view past announcements here.'
            : 'View past announcements here.'}
        </Text>
      </Col>

      {isAdding && (
        <AddAnnouncementSection
          location={location}
          parkingLocation={parkingLocation}
          shift={shift}
          onSubmitOrCancel={() => {
            setIsAdding(false)
          }}
        />
      )}
      {announcements && announcements.length > 0 ? (
        <>
          <Divider />
          {announcements?.map((announcement) => (
            <AnnouncementItem
              announcement={announcement}
              timezone={shift.timezone}
            />
          ))}
        </>
      ) : isAdding ? null : (
        <AnnouncementEmptyLoading isLoading={isLoading} />
      )}
    </div>
  )
}
