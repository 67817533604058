import {
  CreateShiftRequest,
  RoleInfoForCreateShiftRequest,
  ShiftRequest,
} from '@traba/types'

export function duplicateShiftRequest(
  shiftRequest: CreateShiftRequest,
  minHourlyPay?: number,
): CreateShiftRequest {
  const { roleId: _roleId, payRate, ...restOfShiftRequest } = shiftRequest
  return {
    ...restOfShiftRequest,
    payRate: minHourlyPay ?? payRate,
    roleId: '',
  }
}

export function getRoleInfoForCreateShiftRequest({
  shiftRequest,
  minHourlyPay,
  copyRoleId = false,
}: {
  shiftRequest: Pick<
    ShiftRequest,
    | 'roleId'
    | 'payType'
    | 'payRate'
    | 'numberOfUnits'
    | 'slotsRequested'
    | 'minSlotsRequested'
    | 'supervisorId'
    | 'forwardFillMax'
  >
  minHourlyPay?: number
  copyRoleId?: boolean
}): RoleInfoForCreateShiftRequest {
  return {
    roleId: copyRoleId ? shiftRequest.roleId : '',
    payType: shiftRequest.payType,
    payRate: minHourlyPay ?? shiftRequest.payRate,
    numberOfUnits: shiftRequest.numberOfUnits,
    slotsRequested: shiftRequest.slotsRequested,
    minSlotsRequested: shiftRequest.minSlotsRequested,
    supervisorId: shiftRequest.supervisorId,
    forwardFillMax: shiftRequest.forwardFillMax,
  }
}
