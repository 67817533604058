import { Card } from '@traba/react-components'
import { Money, ShiftStatus, Shift, WorkerShift } from '@traba/types'
import { theme } from 'src/libs/theme'

import { Text } from '../base'
import { CancellationFeeCostSummary } from './CancellationFeeCostSummary'
import { ShiftPayLineItems } from './ShiftPayLineItems'

export interface CostSummaryProps {
  payRate: number
  estimatedCost: [number, number] | number
  serviceFee: [number, number] | number
  workers?: [number, number] | number
  shiftCount?: number
  title?: string | JSX.Element
  singleWorkerPay?: Money
  totalWorkerPay?: number
  summary?: string | JSX.Element
  workerPaySubtext?: string | JSX.Element
  shift: Shift
  workerShifts?: WorkerShift[]
  totalCharge?: Money
  hideTitle?: boolean
}

export const CostSummary = (props: CostSummaryProps) => {
  const payRate = props.payRate?.toFixed(2)
  let workerPay: number | number[] = 0

  if (props.totalWorkerPay) {
    workerPay = (props.totalWorkerPay || 0) / 100
  } else if (props.workers && props.singleWorkerPay && props.shiftCount) {
    if (Array.isArray(props.workers)) {
      workerPay = props.workers.map(
        (worker: number) =>
          (props.singleWorkerPay!.amount / 100) * props.shiftCount! * worker,
      )
    } else {
      workerPay =
        (props.singleWorkerPay.amount / 100) * props.shiftCount * props.workers
    }
  }

  return props.shift.status === ShiftStatus.CANCELED ? (
    <CancellationFeeCostSummary {...props} />
  ) : (
    <>
      {!props.hideTitle && (
        <Text variant="h4" mb={theme.space.sm}>
          {props.title ? props.title : 'Estimated Total'}
        </Text>
      )}
      <Card>
        <ShiftPayLineItems
          {...props}
          workerPay={workerPay}
          payRateFixed={payRate}
        />
      </Card>
    </>
  )
}
