import styled, { css } from 'styled-components'
import { ButtonProps, ButtonVariant } from './Button'

export const ButtonContainer = styled.button.attrs((props) => ({
  style: props.style,
}))<ButtonProps>`
  ${(props) => (!props.disabled ? 'cursor: pointer;' : '')}
  border-radius: 8px;
  line-height: 24px;
  font-size: 14px;
  line-height: 24px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  max-height: 48px;
  ${(props) => (props.full ? 'width: 100%;' : '')}
  padding: ${(prop) => (prop.slim ? css`8px 16px` : css`12px 24px`)};

  ${({ theme, variant, reverse, disabled }) => {
    switch (variant) {
      case ButtonVariant.TEXT:
        return css`
          color: ${theme?.TextButton};
          border-radius: 8px;
          border: 0px;
          background: none;
          ${!disabled &&
          css`
            :hover {
              color: ${disabled ? '' : theme?.HoverTextButton};
            }
          `}
        `
      case ButtonVariant.OUTLINED:
        return css`
          background: #fff;
          color: #08105e;
          border: 1px solid ${theme?.BorderColor};
          box-sizing: border-box;
          box-shadow: 0px 1px 2px rgba(196, 204, 212, 0.5);
          border-radius: 8px;
          ${disabled
            ? css`
                cursor: default;
                color: #b6bfc9;
                box-shadow: none;
                border: 1px solid #e2e6e9;
                background: ${theme?.colors.Grey10};
              `
            : css`
                :hover {
                  border: 1px solid ${theme?.HoverBorderColor};
                }
              `}
        `
      case ButtonVariant.REDOUTLINED:
        return css`
          background: #fff;
          color: ${theme?.colors.red};
          border: 1px solid ${theme?.colors.red};
          box-sizing: border-box;
          box-shadow: 0px 1px 2px rgba(196, 204, 212, 0.5);
          border-radius: 8px;
          ${disabled
            ? css`
                cursor: default;
                color: #b6bfc9;
                box-shadow: none;
                border: 1px solid #e2e6e9;
                background: #ffffff;
              `
            : css`
                :hover {
                  border: 1px solid ${theme?.colors.Red70};
                  color: ${theme?.colors.Red70};
                }
              `}
        `
      case ButtonVariant.CANCEL:
        return css`
          border: none;
          background: ${theme?.colors.red};
          color: #fff;
          ${disabled
            ? css`
                cursor: default;
                background-color: #e2e6e9;
                color: #b6bfc9;
                box-shadow: none;
                border: 0px;
              `
            : css`
                :hover {
                  background: ${theme?.colors.Red70};
                }
              `}
        `
      case ButtonVariant.LINK:
        return css`
          border: none;
          background: none;
          padding: 0;
          text-decoration: underline;
          color: ${theme.colors.MidnightBlue};
          ${disabled
            ? css`
                color: ${theme.colors.Grey40};
              `
            : css`
          :hover {
            color: ${theme.colors.brand};
          `}
        `
      case ButtonVariant.BRANDLINK:
        return css`
          border: none;
          background: none;
          padding: 0;
          color: ${theme.colors.brand};
          ${disabled
            ? css`
                color: ${theme.colors.Grey40};
              `
            : css`
                :hover {
                  color: ${theme.colors.Violet90};
                }
              `}
        `
      case ButtonVariant.DASHED:
        return css`
          background: transparent;
          color: ${theme.colors.MidnightBlue};
          border: 1px dashed ${theme.colors.Grey40};
          border-radius: 8px;
          ${disabled
            ? css`
                cursor: default;
                color: #b6bfc9;
                box-shadow: none;
                border: 1px dashed #e2e6e9;
                background: transparent;
              `
            : css`
                :hover {
                  border: 1px dashed ${theme.colors.Grey50};
                }
              `}
        `
      case ButtonVariant.GREEN:
        return css`
          color: ${theme.colors.Green80};
          background: ${theme.colors.Green10};
          border: 1px solid ${theme.colors.Green40};
          box-sizing: border-box;
          box-shadow: 0px 1px 2px rgba(196, 204, 212, 0.5);
          border-radius: 8px;
          ${disabled
            ? css`
                cursor: default;
                color: #b6bfc9;
                box-shadow: none;
                border: 1px solid #e2e6e9;
                background: #ffffff;
              `
            : css`
                :hover {
                  border: 1px solid ${theme.colors.Green70};
                }
              `}
        `

      case ButtonVariant.PRESSED:
        return css`
          background: ${theme.colors.Grey10};
          color: ${theme.colors.MidnightBlue};
          border: 1px solid ${theme?.BorderColor};
          box-sizing: border-box;
          box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.12);
          border-radius: 8px;
          ${disabled
            ? css`
                cursor: default;
                color: #b6bfc9;
                box-shadow: none;
                border: 1px solid #e2e6e9;
                background: #ffffff;
              `
            : css`
                :hover {
                  border: 1px solid ${theme?.HoverBorderColor};
                }
              `}
        `
      case ButtonVariant.BRANDNEW:
        return css`
          background: ${theme.colors.reverseBackColor};
          color: ${theme.colors.Violet};
          box-sizing: border-box;
          border-radius: 8px;
          ${disabled
            ? css`
                cursor: default;
                color: #b6bfc9;
                box-shadow: none;
                border: 1px solid #e2e6e9;
                background: #ffffff;
              `
            : css`
                :hover {
                  border: 1px solid ${theme?.colors.Violet};
                }
              `}
        `
      case ButtonVariant.ERROR:
        return css`
          border: none;
          background: none;
          padding: 0;
          color: ${theme.colors.Red60};
          ${disabled
            ? css`
                color: ${theme.colors.Grey40};
              `
            : css`
                :hover {
                  color: ${theme.colors.Red80};
                }
              `}
        `
      default:
        return css`
          border: none;
          background: ${reverse
            ? theme?.ReverseBackColor
            : theme?.ActiveBackColor};
          color: ${reverse ? theme?.ActiveBackColor : '#fff'};
          ${disabled
            ? css`
                cursor: default;
                background-color: ${theme.colors.Grey30};
                color: ${theme.colors.White};
                box-shadow: none;
                border: 0px;
              `
            : css`
                :hover {
                  background: ${reverse
                    ? theme?.ReverseHoverColor
                    : theme?.ActiveHoverColor};
                }
              `}
        `
    }
  }}
`

export type ButtonIconProps = {
  reverse: boolean
  leftPadding?: string
  rightPadding?: string
}

export const ButtonIcon = styled.div<ButtonIconProps>`
  display: flex;
  align-self: center;
  box-sizing: content-box;
  padding-left: ${({ leftPadding: padding }) => (padding ? padding : '10px')};
  padding-right: ${({ rightPadding: padding }) => (padding ? padding : '10px')};
  width: 12px;
  color: ${({ reverse, theme }) => (reverse ? theme?.ActiveBackColor : '#fff')};
`

export const ButtonLabelContainer = styled.div<{
  variant: ButtonProps['variant']
}>`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: ${({ variant }) => (variant === 'link' ? '300' : '400')};
`
