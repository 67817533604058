import styled from 'styled-components'

import { Td } from '../Table/Table'

export const InvoiceTableContainer = styled.div``

export const TdMultiLeft = styled(Td)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const TdMultiRight = styled(Td)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const SmallMarginLeft = styled.span`
  margin-left: 10px;
`
