import { RaisedCardTile } from '@traba/react-components'
import React from 'react'
import { SvgIconName } from 'src/assets/svg/icons'

import Icon from '../Icon'
import Row from '../Row'
import * as S from './CreditCardTile.styles'

export interface CreditCardTileProps {
  brand: SvgIconName
  last4: string
  expMonth: number
  expYear: number
}

// Brands we have icon svgs for--if it's a diff card brand we'll show a generic icon
const CREDIT_CARD_BRANDS = ['amex', 'mastercard', 'visa']

export const CreditCardTile = (props: CreditCardTileProps) => {
  const iconName = CREDIT_CARD_BRANDS.includes(props.brand)
    ? props.brand
    : 'card'
  return (
    <S.CreditCardTileContainer>
      <Row style={{ alignItems: 'center', gap: '20px' }}>
        <RaisedCardTile size="56px">
          <Icon name={iconName} />
        </RaisedCardTile>
        <span>{`•••• •••• •••• ${props.last4}`}</span>
        <span
          style={{ marginLeft: 'auto' }}
        >{`${props.expMonth}/${props.expYear}`}</span>{' '}
      </Row>
    </S.CreditCardTileContainer>
  )
}

export default CreditCardTile
