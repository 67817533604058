import { theme } from '@traba/theme'
import { WorkerOnScheduleShiftInfo } from '@traba/types'
import { getShiftTimeString } from '@traba/utils'
import { Card } from '../base-components/Card/Card'
import { Text } from '../base-components/Text'

interface Props {
  ws: WorkerOnScheduleShiftInfo | null
  getStylesForShiftRequest: (shiftRequestId: string) => {
    backgroundColor: string
    borderColor: string
  }
}

export const WorkersOnScheduleTableCard = ({
  ws,
  getStylesForShiftRequest,
}: Props) => {
  if (!ws) {
    return <></>
  }
  const isCanceled = ws.isCanceled
  const { backgroundColor, borderColor } = getStylesForShiftRequest(
    ws.shiftRequestId,
  )
  const canceledBackgroundColor = theme.colors.Red10
  const canceledBorderColor = theme.colors.Red70

  return (
    <Card
      backgroundColor={isCanceled ? canceledBackgroundColor : backgroundColor}
      borderColor={isCanceled ? canceledBorderColor : borderColor}
      style={{
        padding: theme.space.xs,
        textDecoration: isCanceled ? 'line-through' : undefined,
      }}
    >
      <Text variant="h6">{ws.role}</Text>
      <Text variant="body2" color={theme.colors.MidnightBlue}>
        {getShiftTimeString(ws.startTime, ws.endTime, ws.timezone)}
      </Text>
    </Card>
  )
}
