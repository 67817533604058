import { Option } from 'fp-ts/Option'
import { Money } from './money'
import { ShiftInfo } from './shifts'

export enum AdjustmentSource {
  OPS = 'OPS',
  BUSINESS = 'BUSINESS',
}

export enum AdjustmentLabel {
  SHOULD_FLAG = 'SHOULD_FLAG',
  SHOULD_NOT_FLAG = 'SHOULD_NOT_FLAG',
  UNDETERMINED = 'UNDETERMINED',
  TIMESHEET_ADJUSTMENT = 'TIMESHEET_ADJUSTMENT',
}

export type ShiftTime = {
  startTime: Date | null
  endTime: Date | null
}

export type WorkerShiftAdjustment = {
  adjustmentReason: string
  source: AdjustmentSource
  createdAt: Date
  clockInTime: Date | null
  clockOutTime: Date | null
  breaks: ShiftTime[] | null
  grossPay: Money
  totalTrustAndSafetyFee: Money
  netPay: Money
  instantPayFee?: Money
  timeWorked: number
  workerId: string
  shiftId: string
  shiftInfo: ShiftInfo
  totalPaidTime: number
  unitsWorked?: number
}

export type CompletedAdjustments = {
  succeededWorkerIds: string[]
  failedWorkerIdsAndReasons: Array<[string, string]>
}

export type HandleAdjustmentParams = {
  shouldAdjustPayment: boolean
  shouldInstantPay: boolean
  adjustmentReason: string
  clockInTime: Option<Date>
  clockOutTime: Option<Date>
  breaks: ShiftTime[]
  payRate: number
  minPaidTime: number
  breakType: string
  adjustmentLabel: AdjustmentLabel
  unitsWorked: number | undefined
}
