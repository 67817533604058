import { BreakType } from '@traba/types'
import { getBreakOptions } from '@traba/utils'
import { useEffect, useState } from 'react'
import {
  Button,
  ButtonVariant,
  InlineBanner,
  Input,
  Row,
  SvgIcon,
  Text,
} from 'src/components'
import { SelectDropdown } from 'src/components/base/Select/Select'
import { useCompany } from 'src/hooks/useCompany'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'

import { BookShiftsProps } from '../../BookShiftsScreen'

const BREAK_OPTIONS = getBreakOptions()

function getBreakMessage(breakType: BreakType | undefined) {
  switch (breakType) {
    case BreakType.PAID:
      return ''
    case BreakType.MANUAL_UNPAID:
      return 'Please add the standard break time. Workers will have to clock in and out of their breaks to record their actual break time. '
    default:
      return 'These breaks will be automatically removed from the workers’ paid time. '
  }
}

export function BookShiftsScheduleBreaksSection(props: BookShiftsProps) {
  const { hotSettings } = useHotSettings()
  const { company } = useCompany()
  const { shiftRequest, setShiftRequest, isEdit, defaultBreaks, isRebook } =
    props
  const [defaultBreakLengths, setdefaultBreakLengths] = useState<number[]>(
    isRebook || !defaultBreaks
      ? []
      : new Array(defaultBreaks?.count).fill(defaultBreaks?.breakLength),
  )
  const [additionalBreakLengths, setAdditionalBreakLengths] = useState<
    number[]
  >(
    shiftRequest.scheduledBreaks.flatMap((breaks) =>
      Array(breaks.count).fill(breaks.breakLength),
    ),
  )

  function editBreakLength(breaks: number[], index: number, newLength: number) {
    const oldLen = breaks[index]
    breaks[index] = newLength
    window.analytics.track(`User Edited Break Length`, {
      oldBreakLength: oldLen,
      newBreakLength: newLength,
      breaks,
    })
    return breaks
  }

  function removeBreak(breaks: number[], index: number) {
    const removedLen = breaks[index]
    breaks.splice(index, 1)
    window.analytics.track(`User Removed Break`, {
      breakLength: removedLen,
      breaks,
      isEdit,
    })
    return breaks
  }

  function addBreak(breaks: number[]) {
    breaks.push(30)
    window.analytics.track(`User Added Break`, {
      breakLength: 30,
      breaks,
      isEdit,
    })
    return breaks
  }

  // if company has default breaks, set them in the shiftRequest initially
  useEffect(() => {
    if (!isRebook) {
      setShiftRequest({
        scheduledBreaks: defaultBreaks
          ? [...(shiftRequest.scheduledBreaks || []), defaultBreaks]
          : shiftRequest.scheduledBreaks,
      })
    }
  }, [isRebook])

  // This function is used to combine the default breaks and additional breaks and set the scheduled breaks
  function combineAndSetBreaks() {
    const defaultBreaks = defaultBreakLengths.reduce<{
      [key: number]: number
    }>((acc, curr) => {
      acc[curr] = (acc[curr] || 0) + 1
      return acc
    }, {})
    const totalBreaks = additionalBreakLengths.reduce<{
      [key: number]: number
    }>((acc, curr) => {
      acc[curr] = (acc[curr] || 0) + 1
      return acc
    }, defaultBreaks)

    setShiftRequest({
      scheduledBreaks: Object.entries(totalBreaks).map(
        ([breakLength, count]) => ({
          breakLength: +breakLength,
          count,
        }),
      ),
    })
  }

  return (
    <>
      <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
        {isEdit ? 'Add or remove unpaid breaks' : 'Add unpaid breaks'}
      </Text>
      <Text variant="body2" style={{ marginBottom: theme.space.xs }}>
        {hotSettings?.enableReturnOfManualBreak &&
          getBreakMessage(company?.breakType)}
        Only unpaid breaks need to be added to ensure paid time is correctly
        calculated for workers at your shift.
      </Text>
      {defaultBreaks && !isRebook && (
        <>
          <Text variant="h6" style={{ marginBottom: theme.space.xxs }}>
            Default Break
          </Text>
          <Text
            variant="body2"
            style={{
              marginBottom: theme.space.xs,
              color: theme.colors.MidnightBlue,
            }}
          >
            Default breaks are added to your shift as per your company settings.
            These can be edited in your settings as needed.
          </Text>
          {defaultBreakLengths.map((breakLength, index) => (
            <Row
              key={`${breakLength}-${index}`}
              className="xs-12 sm-6"
              style={{
                columnGap: theme.space.xs,
                marginBottom: theme.space.xs,
              }}
            >
              <Input
                disabled={true}
                containerStyle={{ flex: 2, marginTop: 0 }}
                value={breakLength + ' min'}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onChange={() => {}}
                leftIconName="clock"
              />
              <Button
                variant={ButtonVariant.OUTLINED}
                onClick={() => {
                  setdefaultBreakLengths(
                    removeBreak(defaultBreakLengths, index),
                  )
                  combineAndSetBreaks()
                }}
                style={{
                  alignSelf: 'stretch',
                  padding: `0 ${theme.space.xs}px`,
                }}
              >
                <SvgIcon name="trash" color={theme.colors.Grey50} />
              </Button>
            </Row>
          ))}
          <Text variant="h6" style={{ marginBottom: theme.space.xxs }}>
            Additional Breaks
          </Text>
          <Text
            variant="body2"
            style={{
              marginBottom: theme.space.xs,
              color: theme.colors.MidnightBlue,
            }}
          >
            Any breaks you add will be in addition to the default break.
          </Text>
        </>
      )}

      {additionalBreakLengths.map((len, i) => (
        <Row
          key={`${len}-${i}`}
          className="xs-12 sm-6"
          style={{
            columnGap: theme.space.xs,
            marginBottom: theme.space.xs,
          }}
        >
          <SelectDropdown
            key={`${len}-${i}-dropdown`}
            style={{ flex: 1 }}
            value={`${len}`}
            menuItems={BREAK_OPTIONS}
            placeholder="Duration"
            handleSelect={(val) => {
              setAdditionalBreakLengths(
                editBreakLength(additionalBreakLengths, i, +val),
              )
              combineAndSetBreaks()
            }}
          />
          <Button
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              setAdditionalBreakLengths(removeBreak(additionalBreakLengths, i))
              combineAndSetBreaks()
            }}
            style={{
              alignSelf: 'stretch',
              padding: `0 ${theme.space.xs}px`,
            }}
          >
            <SvgIcon name="trash" color={theme.colors.Grey50} />
          </Button>
        </Row>
      ))}
      <Button
        variant={ButtonVariant.DASHED}
        className="xs-12 sm-6"
        onClick={() => {
          setAdditionalBreakLengths(addBreak(additionalBreakLengths))
          combineAndSetBreaks()
        }}
      >
        {defaultBreaks ? 'Add another break' : 'Add break'}
      </Button>
      {!isRebook &&
        defaultBreaks &&
        (additionalBreakLengths.length > 0 ||
          defaultBreakLengths.length < (defaultBreaks?.count ?? 0)) && (
          <InlineBanner
            text="Please note that you have modified breaks for this shift to be different from your default setting. If you did not intend this, please update the total breaks to be accurate."
            style={{ marginTop: `${theme.space.xs}px` }}
            severity="error"
          />
        )}
    </>
  )
}
