import {
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  SwitchProps,
} from '@mui/material'
import { theme } from '@traba/theme'
import Row from '../Row'
import { Text } from '../Text'

interface ToggleProps extends Partial<FormControlLabelProps> {
  label: FormControlLabelProps['label']
  buttonState: boolean
  runOnChange: () => void
  switchProps?: SwitchProps
  disabled?: boolean
  formControlLabelProps?: FormControlLabelProps
  color?: string
  containerStyle?: React.CSSProperties
  labelStyle?: React.CSSProperties
}

export function Toggle(props: ToggleProps) {
  const {
    buttonState,
    switchProps,
    label,
    formControlLabelProps,
    runOnChange,
    color,
    containerStyle,
    labelStyle,
    ...otherProps
  } = props
  return (
    <Row
      style={{
        backgroundColor: color || theme.colors.Violet10,
        borderRadius: theme.space.xs,
        paddingRight: theme.space.xs,
        ...containerStyle,
      }}
    >
      <FormControlLabel
        control={<Switch checked={buttonState} {...switchProps} />}
        labelPlacement={'start'}
        style={labelStyle}
        label={
          <Text
            variant="body1"
            style={{
              fontWeight: 600,
              color: buttonState ? theme.colors.Violet70 : theme.colors.Grey60,
            }}
          >
            {label}
          </Text>
        }
        onChange={runOnChange}
        {...formControlLabelProps}
        {...otherProps}
      />
    </Row>
  )
}
