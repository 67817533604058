import { UserRolePermission } from '@traba/types'

export enum SETTINGS_TAB_KEYS {
  PERSONAL_SETTINGS = 'personal-settings',
  NOTIFICATION_SETTINGS = 'notification-settings',
  DELETE_ACCOUNT = 'delete-account',
  COMPANY_PROFILE = 'company-profile',
  MEMBERS = 'members',
  LOCATIONS = 'locations',
  BILLING = 'billing',
  ROLES = 'roles',
  TRAINING_VIDEOS = 'training-videos',
  COST_CENTERS = 'cost-centers',
}

export interface SettingsTab {
  key: SETTINGS_TAB_KEYS
  label: string
  Component: React.FC
  permissions: UserRolePermission[]
}
