import { Checkbox, SelectChangeEvent } from '@mui/material'
import { theme } from '@traba/theme'
import Row from '../Row'
import {
  CHECKED_CHECKBOX_STATUS_VALUES,
  CheckedStatus,
  StyledSelectCheckbox,
} from '../Select/StyledSelectCheckbox'
import { Text } from '../Text'

export interface SearchSelectGroupTitleProps {
  title?: string
  groupId: string
  groupCheckedStatus?: CheckedStatus
  isGroupDisabled?: boolean
  onSelectGroup?: (e: SelectChangeEvent<string>) => void
}

export function SearchSelectGroupTitle({
  title,
  groupId,
  groupCheckedStatus = CheckedStatus.UNCHECKED,
  isGroupDisabled,
  onSelectGroup,
}: SearchSelectGroupTitleProps) {
  if (!title) {
    return null
  }

  const showCheckbox = !!onSelectGroup
  return (
    <Row
      alignCenter
      py={theme.space.xms}
      px={theme.space.xs}
      pl={showCheckbox ? theme.space.xsmed : theme.space.xs}
      style={{ backgroundColor: theme.colors.Grey20 }}
    >
      {showCheckbox && (
        <Checkbox
          checked={CHECKED_CHECKBOX_STATUS_VALUES.includes(
            groupCheckedStatus || CheckedStatus.UNCHECKED,
          )}
          style={{
            padding: theme.space.xxs,
            margin: '-4px',
            marginRight: theme.space.xxxs,
          }}
          icon={
            <StyledSelectCheckbox disabled={isGroupDisabled} isGroupCheckbox />
          }
          checkedIcon={
            <StyledSelectCheckbox
              checkedStatus={groupCheckedStatus}
              isGroupCheckbox
              disabled={isGroupDisabled}
            />
          }
          onChange={onSelectGroup}
          value={groupId}
          disabled={isGroupDisabled}
        />
      )}
      <Text
        variant="body3"
        color={
          showCheckbox &&
          isGroupDisabled &&
          groupCheckedStatus === CheckedStatus.UNCHECKED
            ? theme.colors.Grey30
            : theme.colors.Grey80
        }
      >
        {title.toUpperCase()}
      </Text>
    </Row>
  )
}
