import { LoadingSpinner, MODAL_SIZE } from '@traba/react-components'
import { ShiftStatus, Worker } from '@traba/types'
import { addHours, addWeeks } from 'date-fns'
import { useMemo, useState } from 'react'
import { Dialog } from 'src/components/base/Dialog/Dialog'
import { ShiftSelector } from 'src/components/ShiftSelector/ShiftSelector'
import { UpcomingShiftDetails } from 'src/components/UpcomingShiftDetails/UpcomingShiftDetails'
import { useShiftInvitations } from 'src/hooks/useShiftInvitations'
import { ShiftAndAddress, useShifts } from 'src/hooks/useShifts'
import { SearchWorker } from 'src/hooks/useWorkerSearch'

export function InviteWorkersToShiftModal({
  workers,
  isOpen,
  handleModalClose,
  refetchMessages,
  refetchChats,
  onInviteClicked,
  isFromChat,
}: {
  workers: (Worker | SearchWorker)[] | undefined
  isOpen: boolean
  handleModalClose: () => void
  refetchMessages?: () => void
  refetchChats?: () => void
  onInviteClicked?: () => void
  isFromChat?: boolean
}) {
  const [input, setInput] = useState('')
  const [selectedShiftId, setSelectedShiftId] = useState<string | undefined>(
    undefined,
  )
  const [invitationLoading, setInvitationLoading] = useState(false)
  const { sendInvitations } = useShiftInvitations({
    shiftIds: selectedShiftId ? [selectedShiftId] : [],
  })
  const shiftsResult = useShifts({
    startAfter: addHours(new Date(), 2),
    startBefore: addWeeks(new Date(), 2),
    status: ShiftStatus.ACTIVE,
  })
  const { isLoading: isLoadingShifts } = shiftsResult
  const shifts = useMemo(() => {
    return shiftsResult.data?.sort(
      (a, b) =>
        (b.businessStartTime ?? b.startTime).getUTCMilliseconds() -
        (a.businessStartTime ?? a.startTime).getUTCMilliseconds(),
    )
  }, [shiftsResult])

  function handleClose() {
    setSelectedShiftId(undefined)
    setInput('')
    handleModalClose()
  }

  if (isLoadingShifts || !shifts) {
    return <LoadingSpinner />
  }
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="paper"
      open={isOpen}
      confirming={invitationLoading}
      onClose={handleClose}
      onConfirmCTA="Confirm & Invite Worker"
      dialogTitle={
        workers && workers.length > 0
          ? workers.length === 1
            ? `Invite ${workers[0].firstName} to a shift`
            : `Invite ${workers.length} workers to a shift`
          : `Invite a worker to your shift`
      }
      confirmDisabled={selectedShiftId === undefined}
      formId="invite-worker"
      size={MODAL_SIZE.LARGE}
      onConfirm={async () => {
        if (!workers || workers.length < 1 || !selectedShiftId) {
          return
        }
        setInvitationLoading(true)
        await sendInvitations({
          shiftId: selectedShiftId,
          workerIds: workers.map((worker) => worker.workerId),
          isFromChat,
        })
        handleClose()
        // TODO(ben): remove this ASAP
        setTimeout(() => {
          refetchMessages && refetchMessages()
          refetchChats && refetchChats()
        }, 3000)
        onInviteClicked && onInviteClicked()
        setInvitationLoading(false)
      }}
    >
      <ShiftSelector
        input={input}
        setInput={setInput}
        setSelectedShift={(shift: ShiftAndAddress | undefined) =>
          setSelectedShiftId(shift ? shift.id || shift.shiftId : undefined)
        }
        shifts={shifts}
      />
      {selectedShiftId && (
        <UpcomingShiftDetails
          showShiftSummary={false}
          showHeader={false}
          shiftId={selectedShiftId}
        />
      )}
    </Dialog>
  )
}
