import { Card, Col, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { DailyViewShiftMetadata, ShiftPayType } from '@traba/types'
import { ScheduledBreaksInfo } from 'src/components/UpcomingShiftDetails/components/ScheduledBreaksInfo'
import {
  certificationName,
  useCertifications,
} from 'src/hooks/useCertifications'
import { useMembers } from 'src/hooks/useMembers'
import { useTrainingVideos } from 'src/hooks/useTrainingVideos'
import { getTrainingVideoText } from 'src/screens/BookShifts/utils'

interface Props {
  selectedMetaData: DailyViewShiftMetadata
}

export const ScheduleDailyViewRoleCard = (props: Props) => {
  const { selectedMetaData } = props
  const { supervisorName, shift } = selectedMetaData
  const {
    payRate,
    requiredCertifications,
    attireDescription,
    videoIds,
    shiftCreatorId,
    scheduledBreaks,
    payType,
  } = shift
  const { certifications } = useCertifications()
  const { trainingVideos } = useTrainingVideos()
  const { getMemberById } = useMembers()

  // Traba Operations Team leaves behind a null for the shiftCreatorId
  // A deleted user will still leave behind their id
  const creatorEmail = shiftCreatorId
    ? getMemberById(shiftCreatorId)?.email || 'Unknown User'
    : 'Traba Operations Team'

  return (
    <Card style={{ marginTop: theme.space.xxs, marginBottom: theme.space.xs }}>
      <Row wrap gap={theme.space.med} style={{ rowGap: theme.space.sm }}>
        <Col mb={theme.space.sm}>
          <Text variant="caption" mb={theme.space.xxs}>
            ON-SITE POINT OF CONTACT
          </Text>
          <Text variant="body1">{supervisorName}</Text>
        </Col>
        <Col>
          <Text variant="caption">PAY RATE</Text>
          <Text variant="body1">
            {payRate} {payType === ShiftPayType.UNIT ? ' /unit' : ' /hr'}{' '}
          </Text>
        </Col>
        <Col>
          <Text variant="caption">BREAKS</Text>
          <Text variant="body1">
            <ScheduledBreaksInfo scheduledBreaks={scheduledBreaks} />
          </Text>
        </Col>
        <Col>
          <Text variant="caption">CERTIFICATIONS</Text>
          <Text variant="body1">
            {requiredCertifications?.length && certifications
              ? requiredCertifications
                  .map((rc) => certificationName(rc, certifications))
                  .join(', ')
              : 'None'}
          </Text>
        </Col>
        <Col>
          <Text variant="caption">REQUIRED ATTIRE</Text>
          <Text variant="body1">{attireDescription}</Text>
        </Col>
        {videoIds && (
          <Col>
            <Text variant="caption">REQUIRED TRAINING VIDEOS</Text>
            <Text variant="body1">
              {getTrainingVideoText(trainingVideos, videoIds)}
            </Text>
          </Col>
        )}

        <Col>
          <Text variant="caption">POSTED BY</Text>
          <Text variant="body1">{creatorEmail}</Text>
        </Col>
      </Row>
    </Card>
  )
}
