import { createGlobalStyle } from 'styled-components'

export const SearchBoxStyling = createGlobalStyle`
  .MuiAutocomplete-tag {
    background-color: ${({ theme }) => theme.colors.Violet10} !important;
    border-color: ${({ theme }) => theme.colors.Violet10} !important;
    border-radius: 6px !important;
  }
  .MuiChip-label {
    color: ${({ theme }) => theme.colors.Violet} !important;
    font-weight: 600 !important;
  }
  .MuiChip-deleteIcon {
    color: ${({ theme }) => theme.colors.Violet} !important;
    font-size: 14px !important;
  }
`
