import { format } from 'date-fns'
import { theme } from 'src/libs/theme'
import { useCheckHiredStatus } from '../../../hooks/useWorkerJobPlacements'
import { Badge } from '../../base'

export type HiredStatusBadgeProps = {
  workerId: string
}

export function HiredStatusBadge({ workerId }: HiredStatusBadgeProps) {
  const { data } = useCheckHiredStatus(workerId)

  if (!data || data.isEnded) {
    return undefined
  }

  return (
    <Badge
      title="Hired"
      variant="success"
      style={{ marginLeft: theme.space.xxs, padding: theme.space.xxs }}
      iconName="greenCheck"
      tooltipText={`Hired on ${format(data.startDate, 'MM/dd/yyyy')}`}
    />
  )
}
