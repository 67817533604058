import { Skeleton } from '@mui/material'
import { CardTile } from '@traba/react-components'
import React from 'react'

function RoleSkeleton() {
  return (
    <div>
      <div>
        <div
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div
            style={{
              justifyContent: 'flex-start',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <CardTile size="56px">
              <Skeleton animation="wave" width="30px" height="50px" />
            </CardTile>
            <span style={{ marginLeft: '25px' }}>
              <Skeleton animation="wave" width="200px" height="60px" />
            </span>
          </div>
        </div>
        <div style={{ marginTop: '32px' }}>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </div>
        <div style={{ marginTop: '32px' }}>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </div>
      </div>
    </div>
  )
}

export default RoleSkeleton
