import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { ShiftWorkerShiftTimeToDestinationResponseDto } from '@traba/types'
import { useQuery } from 'react-query'
import { ONE_MINUTE_IN_MS } from 'src/libs/constants'

async function getTimeToDestinations(shiftId: string) {
  if (!shiftId) {
    return []
  }
  try {
    const response = await trabaApi.get(
      `/my-company/time-to-destinations/${shiftId}`,
    )
    return response.data
  } catch (e) {
    Sentry.captureException(e)
    return []
  }
}

export function useTimeToDestinations(shiftId?: string) {
  const {
    data: timeToDestinations,
    isFetched,
    isLoading,
    error,
  } = useQuery<ShiftWorkerShiftTimeToDestinationResponseDto, Error>(
    ['time_to_destinations', shiftId],
    async () => {
      if (!shiftId) {
        return {
          workerShiftTimeToDestinations: [],
        }
      }
      return getTimeToDestinations(shiftId)
    },
    {
      enabled: !!shiftId,
      staleTime: ONE_MINUTE_IN_MS,
    },
  )

  return {
    timeToDestinations,
    isFetched,
    isLoading,
    error,
  }
}
