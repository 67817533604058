import { useAlert } from '@traba/context'
import { CardTile } from '@traba/react-components'
import { DotMenu } from '@traba/react-components'
import { ChatArchivalStatus, ChatEndedStatus } from '@traba/types'
import { startOfDay, subDays } from 'date-fns'
import { useState } from 'react'
import { Button, ButtonVariant, Row, SvgIcon, Text } from 'src/components'
import { FilterDropdown } from 'src/components/ShiftFilters/FilterDropdown'
import { updateChat } from 'src/hooks/useChats'
import { theme } from 'src/libs/theme'
import { IconName } from 'src/types/svg-types'

enum ChatFilter {
  All = 'All-time',
  Today = 'Today',
  Last7Days = 'Last 7 days',
  Last30Days = 'Last 30 days',
}
interface Props {
  onClickNewMessage: () => void
  onToggleArchived?: () => void
  viewArchived?: boolean
  isPanelOpen: boolean
  hasMessages: boolean
  selectedSummaries?: string[]
  setSelectedSummaries?: React.Dispatch<React.SetStateAction<string[]>>
  setStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>
  refetchChats: () => void
}

const mapFilterToDate = (filter?: ChatFilter) => {
  switch (filter) {
    case ChatFilter.All:
      return undefined
    case ChatFilter.Today: {
      return startOfDay(new Date())
    }

    case ChatFilter.Last7Days:
      return startOfDay(subDays(new Date(), 7))

    case ChatFilter.Last30Days:
      return startOfDay(subDays(new Date(), 30))
    default:
      return undefined
  }
}

export const MessageHeader = ({
  onClickNewMessage,
  isPanelOpen,
  hasMessages,
  onToggleArchived,
  viewArchived,
  selectedSummaries,
  setSelectedSummaries,
  setStartDate,
  refetchChats,
}: Props) => {
  const [selectedFilter, setSelectedFilter] = useState<ChatFilter>(
    ChatFilter.All,
  )

  const { handleError, showSuccess } = useAlert()

  const onClickArchive = async () => {
    try {
      if (selectedSummaries) {
        await updateChat(
          {
            archivalStatus: viewArchived
              ? ChatArchivalStatus.NONE
              : ChatArchivalStatus.ARCHIVED,
          },
          selectedSummaries,
        )
      }
      if (setSelectedSummaries) {
        setSelectedSummaries([])
      }
      refetchChats()
      if (viewArchived) {
        showSuccess('Chats unarchived successfully!')
      } else {
        showSuccess('Chats archived successfully!')
      }
    } catch (error) {
      handleError(
        error,
        'Update chat archival status failed',
        'Failed to update chat archival status, please try again',
        'Something went wrong',
      )
    }
  }

  const onClickEndChats = async () => {
    try {
      if (selectedSummaries) {
        await updateChat(
          {
            companyEndedChatStatus: ChatEndedStatus.ENDED,
          },
          selectedSummaries,
        )
      }
      if (setSelectedSummaries) {
        setSelectedSummaries([])
      }
      refetchChats()
      showSuccess('Chats ended successfully!')
    } catch (error) {
      handleError(
        error,
        'Update chat end status failed',
        'Failed to update chat end status, please try again',
        'Something went wrong',
      )
    }
  }
  const actions = [
    {
      title: 'End selected chats',
      color: theme.colors.MidnightBlue,
      iconName: 'closeChat' as IconName,
      onClick: onClickEndChats,
    },
    {
      title: viewArchived
        ? 'Unarchive selected chats'
        : 'Archive selected chats',
      color: theme.colors.MidnightBlue,
      iconName: 'archived' as IconName,
      onClick: onClickArchive,
    },
  ]

  const filters = [
    { label: 'All-time', value: ChatFilter.All },
    { label: 'Today', value: ChatFilter.Today },
    {
      label: 'Last 7 days',
      value: ChatFilter.Last7Days,
    },
    { label: 'Last 30 days', value: ChatFilter.Last30Days },
  ]

  return (
    <>
      <Row justifyBetween alignCenter mb={theme.space.sm} mt={theme.space.xxs}>
        <FilterDropdown
          overrideMenuItems
          label={'Time Period'}
          selected={selectedFilter}
          items={filters}
          onSelectItem={async (value) => {
            setSelectedFilter(value as ChatFilter)
            setStartDate(mapFilterToDate(value as ChatFilter))
          }}
        />
        {isPanelOpen ? (
          <CardTile size="40px" onClick={onClickNewMessage}>
            <SvgIcon color={theme.colors.Grey60} name="addChat" />
          </CardTile>
        ) : (
          <Row alignCenter>
            <Button variant={ButtonVariant.FILLED} onClick={onClickNewMessage}>
              <SvgIcon
                color={theme.colors.White}
                name="addChat"
                style={{ marginRight: theme.space.xxs }}
              />
              New message
            </Button>
            <DotMenu
              disabled={!selectedSummaries || selectedSummaries?.length === 0}
              type="chat-menu"
              menuItems={actions}
              style={{
                marginLeft: theme.space.xs,
                height: 40,
                width: 40,
                padding: 0,
              }}
            />
          </Row>
        )}
      </Row>
      {hasMessages && (
        <Row justifyBetween>
          <Text variant="h6">
            {viewArchived ? 'Archived chats' : 'All messages'}
          </Text>
          {!isPanelOpen && (
            <Button
              variant={ButtonVariant.TEXT}
              onClick={() => {
                if (onToggleArchived) {
                  onToggleArchived()
                }
                if (setSelectedSummaries) {
                  setSelectedSummaries([])
                }
              }}
            >
              <SvgIcon name={viewArchived ? 'back' : 'simpleMessage'} />
              <Text
                variant="h6"
                color={theme.colors.Violet}
                ml={theme.space.ms}
              >
                {viewArchived ? 'All messages' : 'Archived chats'}
              </Text>
            </Button>
          )}
        </Row>
      )}
    </>
  )
}
