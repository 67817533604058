import { TabPanel, Tab, Tabs, TabsContainer } from '@traba/react-components'
import { Text } from 'src/components/base'
import useMobile from 'src/hooks/useMobile'
import { SettingsTab } from './types'

export interface SettingsPageWithTabsProps {
  pageTitle: string
  activeTabs: SettingsTab[]
  currentTabIndex: number
  setCurrentTabIndex: (arg: number) => void
  desktopBanner?: React.ReactNode
}

export function SettingsPageWithTabs({
  pageTitle,
  activeTabs,
  currentTabIndex,
  setCurrentTabIndex,
  desktopBanner,
}: SettingsPageWithTabsProps) {
  const { isReactNativeApp } = useMobile()

  return (
    <div>
      {!isReactNativeApp && (
        <>
          <Text variant="h2">{pageTitle}</Text>
          {desktopBanner}
          <TabsContainer>
            <Tabs
              variant="scrollable"
              value={currentTabIndex}
              onChange={(_, v) => setCurrentTabIndex(v)}
            >
              {activeTabs.map((tab) => (
                <Tab key={tab.label} label={tab.label} />
              ))}
            </Tabs>
          </TabsContainer>
        </>
      )}
      {activeTabs.map(({ Component }, i) => (
        <TabPanel key={`tab-panel-${i}`} value={currentTabIndex} index={i}>
          <Component />
        </TabPanel>
      ))}
    </div>
  )
}
