import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { User } from '@traba/types'
import { useQuery } from 'react-query'

const getBusinessUser = async (businessUserId?: string) => {
  try {
    const path = businessUserId ? `/users/${businessUserId}` : `/me`
    const response = await trabaApi.get(path)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const useBusinessUser = (businessUserId?: string) => {
  const {
    isLoading,
    isError,
    data: user,
    error,
    isFetched,
    refetch,
  } = useQuery<User, Error>(
    ['user', businessUserId],
    () => getBusinessUser(businessUserId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
      refetchOnWindowFocus: 'always',
    },
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    user,
    refetch,
  }
}
