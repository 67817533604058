import { fromDate } from '@internationalized/date'
import { theme } from '@traba/theme'
import { IMenuItem } from '@traba/types'
import { formatDuration } from '@traba/utils'
import { addDays, differenceInMinutes, startOfMinute } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Badge,
  Button,
  ButtonVariant,
  DatePicker,
  Row,
  SearchSelect,
  SvgIcon,
  Td,
  Tr,
} from '../../base-components'
import { Text } from '../../base-components'
import { SegmentError, EditSegment } from '../types'
import { isEndTimeInvalid, isStartTimeInvalid, shouldWarnOnTime } from '../util'

interface SegmentRowProps {
  clockInTime: Date
  clockOutTime?: Date
  shiftEndTime: Date
  segment: EditSegment
  validationErrors: SegmentError[] | undefined
  costCenterMenuItems: IMenuItem[]
  timezone: string
  updateTime: (req: {
    startTime: Date | null
    endTime: Date | null
    id: string
  }) => void
  removeRow: (id: string) => void
  selectCostCenter: (costCenterId: string | undefined, id: string) => void
}

export function SegmentRow(props: SegmentRowProps): React.JSX.Element {
  const {
    segment,
    clockInTime,
    clockOutTime,
    shiftEndTime,
    validationErrors,
    costCenterMenuItems,
    timezone,
    updateTime,
    removeRow,
    selectCostCenter,
  } = props

  const [localStartTime, setLocalStartTime] = useState<Date | null>(
    segment.startTime ?? null,
  )
  const [localEndTime, setLocalEndTime] = useState<Date | null>(
    segment.endTime ?? null,
  )

  const invalidStartTime = useMemo(
    () => isStartTimeInvalid(validationErrors),
    [validationErrors],
  )

  const invalidEndTime = useMemo(
    () => isEndTimeInvalid(validationErrors),
    [validationErrors],
  )

  const warningOnTime = useMemo(
    () => shouldWarnOnTime(validationErrors),
    [validationErrors],
  )

  useEffect(() => {
    setLocalStartTime(segment.startTime ?? null)
    setLocalEndTime(segment.endTime ?? null)
  }, [segment.startTime, segment.endTime])

  const onBlur = useCallback(() => {
    if (
      localStartTime === segment.startTime &&
      localEndTime === segment.endTime
    ) {
      return
    }

    updateTime({
      startTime: localStartTime,
      endTime: localEndTime,
      id: segment.id,
    })
  }, [
    updateTime,
    localStartTime,
    localEndTime,
    segment.id,
    segment.startTime,
    segment.endTime,
  ])

  return (
    <Tr key={segment.id} style={{ padding: 70 }}>
      <Td>
        {segment.isUnaccounted && (
          <Badge variant="business" title="UNACCOUNTED TIME" />
        )}
        {segment.isBreak && <Badge variant="info" title="BREAK" />}
      </Td>
      <Td>
        <SearchSelect
          options={costCenterMenuItems}
          onlyShowLabel
          showClearButton
          selectItem={costCenterMenuItems.find(
            (item) => item.value === segment.costCenterId,
          )}
          placeholder="No Cost Center"
          handleSelect={(item) => selectCostCenter(item?.value, segment.id)}
        />
      </Td>
      <Td>
        <DatePicker
          aria-label={`${segment.id}-start`}
          timezone={timezone}
          date={localStartTime}
          minDate={clockInTime}
          // Giving a buffer to the shift end time in case worker goes beyond the scheduled
          maxDate={clockOutTime || addDays(shiftEndTime, 1)}
          setDate={setLocalStartTime}
          maxGranularity="hour"
          placeholderValue={
            clockOutTime
              ? fromDate(clockOutTime, timezone)
              : fromDate(shiftEndTime, timezone)
          }
          onBlur={onBlur}
          isInvalid={invalidStartTime}
        />
      </Td>
      <Td>
        <DatePicker
          aria-label={`${segment.id}-end`}
          timezone={timezone}
          date={localEndTime}
          minDate={clockInTime}
          // Giving a buffer to the shift end time in case worker goes beyond the scheduled
          maxDate={clockOutTime || addDays(shiftEndTime, 1)}
          setDate={setLocalEndTime}
          maxGranularity="hour"
          placeholderValue={
            clockOutTime
              ? fromDate(clockOutTime, timezone)
              : fromDate(shiftEndTime, timezone)
          }
          onBlur={onBlur}
          isInvalid={invalidEndTime}
        />
      </Td>
      <Td>
        {!invalidStartTime &&
          !invalidEndTime &&
          !segment.isBreak &&
          segment.endTime &&
          segment.startTime && (
            <SegmentTime
              startTime={segment.startTime}
              endTime={segment.endTime}
              isWarning={warningOnTime}
            />
          )}
      </Td>
      <Td>
        {!invalidStartTime &&
          !invalidEndTime &&
          segment.isBreak &&
          segment.endTime &&
          segment.startTime && (
            <SegmentTime
              startTime={segment.startTime}
              endTime={segment.endTime}
              isWarning={warningOnTime}
            />
          )}
      </Td>
      <Td>
        <Button
          variant={ButtonVariant.TEXT}
          style={{ color: 'red' }}
          onClick={() => removeRow(segment.id)}
        >
          Delete
        </Button>
      </Td>
    </Tr>
  )
}

function SegmentTime(props: {
  startTime: Date
  endTime: Date
  isWarning: boolean
}): React.JSX.Element {
  const { startTime, endTime, isWarning } = props
  return (
    <Row gap={theme.space.xxxs}>
      <Text variant={isWarning ? 'warning' : undefined}>
        {formatDuration(
          Math.round(
            differenceInMinutes(
              startOfMinute(endTime),
              startOfMinute(startTime),
            ),
          ),
        )}
      </Text>
      {isWarning && <SvgIcon name="info" color={theme.colors.Orange70} />}
    </Row>
  )
}
