import { SVGProps } from 'react'

export const GreenCheck: React.FunctionComponent<SVGProps<SVGSVGElement>> = ({
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      width={width}
      height={height}
      {...rest}
    >
      <rect width="24" height="24" rx="12" fill="#1AB373" />
      <path
        d="M18 7L9.42857 17L6 13"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
