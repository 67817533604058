import { DEFAULT_TIMEZONE, MINIMUM_MINUTES_BEFORE_SHIFT } from '@traba/consts'
import { MultiDatePicker, ToText } from '@traba/react-components'
import {
  CreateSchedule,
  RequiredMultiShiftType,
  ShiftPayType,
} from '@traba/types'
import {
  combineTwoDatesForDateAndTime,
  getFirstShiftDateError,
} from '@traba/utils'
import {
  addDays,
  addMinutes,
  differenceInMinutes,
  format,
  isValid,
  startOfDay,
  subMinutes,
} from 'date-fns'
import { useState } from 'react'
import { Col, InlineBanner, Input, Row, Text } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import TimeField from 'src/components/base/AriaDatePicker/TimeField'
import { RadioButton } from 'src/components/RadioButton'
import { ShiftSchedule } from 'src/components/ShiftSchedule/ShiftSchedule'
import { useCompany } from 'src/hooks/useCompany'
import { useLocations } from 'src/hooks/useLocations'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import {
  getTimeAfterTimeWithin24Hours,
  getTimeZoneAbbreviation,
} from 'src/shared/utils/dateUtils'
import { getEarlyArrivalTimeBufferInMinutes } from 'src/utils/earlyArrivalTimeUtils'
import styled from 'styled-components'

import { BookShiftsProps } from '../../BookShiftsScreen'
import { ScheduleEditType } from '../../BookShiftsScreen.hooks'
import RecurringScheduleSelector from '../../components/RecurringScheduleSelector'
import { updateSchedules } from '../../utils'
import {
  getDateError,
  getEndDateError,
  getTimeError,
  MAX_END_DATE,
  MAX_START_DATE,
} from '../../validation'

const MobileRow = styled(Row)`
  column-gap: ${({ theme }) => theme.space.med}px;
  @media only screen and (max-width: (${({ theme }) =>
      theme.media.maxExtraSmall})) {
    justify-content: flex-start;
    column-gap: ${({ theme }) => theme.space.xs}px;
  }
`

export function BookShiftsScheduleDateSectionShiftDataModel({
  shiftRequest,
  setShiftRequest,
  shiftRequestMetadata,
  setShiftRequestMetadata,
  isEdit,
  setSelectSingleShiftDates,
  selectedSingleShiftDates,
  initialShift,
  scheduleEditType,
}: BookShiftsProps) {
  const isScheduleEdit = scheduleEditType === ScheduleEditType.SCHEDULE
  const { scheduleExpanded } = shiftRequestMetadata
  const { locationId } = shiftRequest
  const { company } = useCompany()
  const [scheduleA, scheduleB] = shiftRequest.schedules
  const { startTime, endTime, isRecurringSchedule, recurringSchedule } =
    scheduleA
  const [endDate, setEndDate] = useState<Date>(addDays(endTime, 13))
  const { getLocationById } = useLocations()
  const location = getLocationById(locationId)
  const timezone = location?.timezone || DEFAULT_TIMEZONE
  const recurring = isRecurringSchedule && !!recurringSchedule
  const isOvernight = startTime.getDate() !== endTime.getDate()
  const [isOngoing, setIsOngoing] = useState<boolean>(recurring)
  const { hotSettings } = useHotSettings()
  const endTextArr: string[] = []
  const timeZoneAbrv = getTimeZoneAbbreviation(startTime, timezone)
  const shiftStartDateText = `${timeZoneAbrv ? ` (${timeZoneAbrv})` : ''}`
  if (timeZoneAbrv) {
    endTextArr.push(timeZoneAbrv)
  }
  const overnightDate = isOvernight ? `${format(endTime, 'MM/dd/yy')}` : ''
  if (overnightDate) {
    endTextArr.push(overnightDate)
  }
  const shiftEndDateText = ` (${endTextArr.join(' ')})`
  const eatBuffer =
    (initialShift && getEarlyArrivalTimeBufferInMinutes(initialShift)) ?? 0
  const businessStartTime = eatBuffer
    ? addMinutes(startTime, eatBuffer)
    : undefined

  const minutesAheadForShiftPosting =
    company?.minutesAheadForShiftPosting ?? MINIMUM_MINUTES_BEFORE_SHIFT

  function setToRecurring() {
    if (!scheduleExpanded) {
      setShiftRequestMetadata({ scheduleExpanded: true })
    }
    window.analytics.track(`User Selected Shift Recurring`, { recurring: true })
    const schedules = updateSchedules(scheduleA, scheduleB, {
      addRecurringSchedule: true,
    })
    setIsOngoing(true)
    setSelectSingleShiftDates(null)
    setShiftRequest({ schedules })
  }

  function setToOneTime() {
    if (!scheduleExpanded) {
      setShiftRequestMetadata({ scheduleExpanded: true })
    }
    if (!recurring) {
      return
    }
    window.analytics.track(`User Selected Shift Recurring`, {
      recurring: false,
    })
    const schedules = updateSchedules(scheduleA, null, {
      removeRecurringSchedule: true,
    })
    setShiftRequest({
      schedules,
      ...(!!hotSettings?.allowRequiredMultiShiftInShiftRequest && {
        requiredMultiShiftType: RequiredMultiShiftType.None,
      }),
    })
  }

  function removeEndDateFromSchedules() {
    if (shiftRequest.schedules.length > 0) {
      window.analytics.track('Selected Ongoing Schedule')
      const schedules: CreateSchedule[] = updateSchedules(
        scheduleA,
        scheduleB,
        {
          removeEndDate: true,
        },
      )
      setShiftRequest({ schedules })
    }
  }

  function setStartTime(newStartTime: Date) {
    if (!isValid(newStartTime)) {
      return
    }
    if (newStartTime.getTime() > MAX_START_DATE.getTime()) {
      return
    }
    if (newStartTime.getTime() < startOfDay(new Date()).getTime()) {
      return
    }
    // make sure that end time is after start time
    const newEndTime = getTimeAfterTimeWithin24Hours(endTime, newStartTime)
    const [scheduleA, scheduleB] = shiftRequest.schedules
    const schedules = updateSchedules(scheduleA, scheduleB, {
      startTime: subMinutes(newStartTime, eatBuffer),
      endTime: newEndTime,
    })
    setShiftRequest({ schedules })
  }

  function setEndTime(time: Date) {
    const newEndTime = getTimeAfterTimeWithin24Hours(time, startTime)
    if (!isValid(newEndTime)) {
      return
    }
    if (newEndTime.getTime() > MAX_END_DATE.getTime()) {
      return
    }
    if (newEndTime.getTime() < startOfDay(new Date()).getTime()) {
      return
    }
    const schedules = updateSchedules(scheduleA, scheduleB, {
      endTime: newEndTime,
    })
    setShiftRequest({ schedules })
  }

  function updateEndDate(newEndDate: Date) {
    if (!recurring) {
      return
    }
    if (!isValid(newEndDate)) {
      return
    }
    if (newEndDate.getTime() > MAX_END_DATE.getTime()) {
      return
    }
    if (newEndDate.getTime() < Date.now()) {
      return
    }
    const schedules = updateSchedules(scheduleA, scheduleB, {
      endDate: newEndDate,
    })
    setShiftRequest({ schedules })
    setEndDate(newEndDate)
    setIsOngoing(false)
  }

  const firstShiftDateError = getFirstShiftDateError(shiftRequest)?.message
  const timeError = getTimeError(
    shiftRequest,
    minutesAheadForShiftPosting,
  )?.message
  const dateError = isScheduleEdit
    ? getEndDateError(shiftRequest)?.message // TODO(tanzir): minutesAheadForShiftPosting
    : getDateError(shiftRequest, minutesAheadForShiftPosting)?.message

  const durationMinutes = differenceInMinutes(endTime, startTime)
  const durationHours = Math.floor(durationMinutes / 60)
  const durationMinutesRemainder = durationMinutes % 60
  const timeSection = (
    <>
      <Text
        variant="h5"
        style={{
          margin: isEdit
            ? `0 0 ${theme.space.xxs}px`
            : `${theme.space.lg}px 0 ${theme.space.sm}px`,
        }}
      >
        What are the start and end times for the shift?
      </Text>
      {shiftRequest.payType === ShiftPayType.HOURLY ? (
        <Text
          style={{ marginBottom: theme.space.sm, marginTop: theme.space.xs }}
        >
          We have a 4 hour minimum paid time policy for shifts.{' '}
          <Text
            variant="link"
            href="https://www.traba.work/business-faq#faq_question13"
            target="_blank"
          >
            Learn more
          </Text>
          .
        </Text>
      ) : (
        <Text
          variant="body2"
          style={{ marginBottom: theme.space.xs, marginTop: theme.space.xxs }}
        >
          While we understand that some shifts might take longer than expected,
          we use the end time to be able to better schedule workers' times.
        </Text>
      )}
      <MobileRow gap={theme.space.xxs} alignEnd>
        <Col gap={theme.space.xxs}>
          <Text variant="body3">Start Time {shiftStartDateText}</Text>
          <TimeField
            aria-label="Start Time for shift"
            isDisabled={isScheduleEdit}
            time={businessStartTime ?? startTime}
            setTime={(newDate) => {
              newDate && setStartTime(newDate)
              window.analytics.track(`User Updated Start Time`, {
                time: newDate,
                schedules: [scheduleA, scheduleB],
                isEdit,
              })
            }}
            timezone={timezone}
          />
        </Col>

        <ToText />

        <Col gap={theme.space.xxs}>
          <Text variant="body3">End Time {shiftEndDateText}</Text>
          <TimeField
            aria-label="End Time for shift"
            isDisabled={isScheduleEdit}
            time={endTime}
            setTime={(newDate) => {
              newDate && setEndTime(newDate)
              window.analytics.track(`User Updated End Time`, {
                time: newDate,
                schedules: [scheduleA, scheduleB],
                isEdit,
              })
            }}
            timezone={timezone}
          />
        </Col>

        <Text variant="body2">
          {durationHours} hours{' '}
          {durationMinutesRemainder
            ? `${durationMinutesRemainder} minutes`
            : null}
        </Text>
      </MobileRow>

      {timeError ? (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity="error"
          text={timeError}
        />
      ) : isOvernight ? (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity="warning"
          text={`This shift is overnight. It ends on ${overnightDate}.`}
        />
      ) : null}
    </>
  )

  return (
    <>
      {!isEdit && (
        <>
          <Text variant="h5" style={{ margin: `0 0 ${theme.space.sm}px 0` }}>
            Shift type
          </Text>
          <Col gap={theme.space.xs}>
            <Row
              alignCenter
              style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
              onClick={isScheduleEdit ? undefined : setToOneTime}
            >
              <RadioButton
                disabled={isScheduleEdit}
                selected={!scheduleExpanded ? false : !recurring}
              />
              <Text variant="body1">
                Single- or multi-day shift (for shifts happening on select
                dates)
              </Text>
            </Row>

            <Row
              alignCenter
              style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
              onClick={isScheduleEdit ? undefined : setToRecurring}
            >
              <RadioButton
                disabled={isScheduleEdit}
                selected={!scheduleExpanded ? false : recurring}
              />
              <Text variant="body1">
                Long term schedule with recurring pattern (weekly, bi-weekly)
              </Text>
            </Row>
          </Col>
        </>
      )}
      {!scheduleExpanded ? null : recurring && !isEdit ? (
        <>
          <Col mt={theme.space.lg} mb={theme.space.xxs}>
            <Text variant="h5">Schedule Name</Text>
            <Input
              disabled={isScheduleEdit}
              onChange={(e) =>
                setShiftRequestMetadata({
                  shiftRequestParentTitle: e.target.value,
                })
              }
              placeholder="Give your schedule a name!"
            />
          </Col>
          <MobileRow gap={theme.space.xxs} justifyBetween>
            <Col>
              <Text variant="h5" mt={theme.space.lg} mb={theme.space.sm}>
                First shift
              </Text>
              <DatePicker
                isDisabled={isScheduleEdit}
                aria-label="First shift start date"
                date={startTime}
                setDate={(d) => {
                  d && setStartTime(d)
                  window.analytics.track('User Updated Start Date', {
                    date: d,
                    schedules: [scheduleA, scheduleB],
                  })
                }}
                isClearable={false}
                granularity="day"
                minDate={
                  isScheduleEdit
                    ? undefined
                    : new Date(new Date().setHours(0, 0, 0, 0))
                }
                maxDate={isScheduleEdit ? undefined : MAX_START_DATE}
                timezone={timezone}
              />
            </Col>
            <Col>
              <Text variant="h5" mt={theme.space.lg} mb={theme.space.sm}>
                Last shift
              </Text>
              <Col gap={theme.space.xs}>
                <Row
                  alignCenter
                  style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
                  onClick={() => {
                    setIsOngoing(true)
                    removeEndDateFromSchedules()
                  }}
                >
                  <RadioButton selected={isOngoing} />
                  <Text variant="body1">Ongoing</Text>
                </Row>

                <Row
                  alignCenter
                  style={{
                    columnGap: theme.space.xs,
                    cursor: 'pointer',
                    rowGap: theme.space.xs,
                  }}
                  onClick={() => {
                    setIsOngoing(false)
                    updateEndDate(endDate)
                  }}
                  wrap
                >
                  <RadioButton selected={!isOngoing} />
                  <Text variant="body1">On</Text>
                  <DatePicker
                    aria-label="End date"
                    date={recurringSchedule.endDate ?? endDate}
                    setDate={(d) => {
                      d && updateEndDate(d)
                      window.analytics.track('User Updated End Date', {
                        date: d,
                        schedules: [scheduleA, scheduleB],
                      })
                    }}
                    isClearable={false}
                    granularity="day"
                    minDate={recurringSchedule.endDate ? startTime : undefined}
                    maxDate={MAX_END_DATE}
                    timezone={timezone}
                  />
                </Row>
              </Col>
            </Col>
          </MobileRow>
          {dateError ? (
            <InlineBanner
              style={{ marginTop: theme.space.xs }}
              severity="error"
              text={dateError}
            />
          ) : firstShiftDateError ? (
            <InlineBanner
              style={{ marginTop: theme.space.xs }}
              severity="error"
              text={firstShiftDateError}
            />
          ) : null}
          <RecurringScheduleSelector
            shiftRequest={shiftRequest}
            setShiftRequest={setShiftRequest}
            isScheduleEdit={isScheduleEdit}
          />
          {timeSection}
        </>
      ) : (
        <>
          {!isEdit && (
            <>
              <Text
                variant="h5"
                style={{
                  margin: `${theme.space.lg}px 0 ${theme.space.sm}px`,
                }}
              >
                What are your shift dates? (
                {(selectedSingleShiftDates ?? [startTime]).length ?? 0}{' '}
                selected)
              </Text>
              <MultiDatePicker
                disabled={isScheduleEdit}
                minDate={startOfDay(new Date())}
                maxDate={MAX_START_DATE}
                selectedDates={selectedSingleShiftDates ?? [startTime]}
                onSelectDates={(dates: Date[]) => {
                  setSelectSingleShiftDates(dates)
                  if (dates.length) {
                    const newDateWithTimePersisted =
                      combineTwoDatesForDateAndTime(dates[0], startTime)
                    setStartTime(newDateWithTimePersisted)
                    window.analytics.track(`User Updated Start Date`, {
                      date: dates[0],
                      schedules: [scheduleA, scheduleB],
                    })
                  }
                }}
              />
              {dateError ? (
                <InlineBanner
                  style={{ marginTop: theme.space.xs }}
                  severity="error"
                  text={dateError}
                />
              ) : null}
            </>
          )}
          {timeSection}
        </>
      )}
      {recurring &&
      !!recurringSchedule.repeatOn.length &&
      recurringSchedule.endDate &&
      !isEdit ? (
        <>
          <Text
            variant="h5"
            style={{ margin: `${theme.space.lg}px 0 ${theme.space.sm}px` }}
          >
            Review your schedule
          </Text>
          <ShiftSchedule shiftRequest={shiftRequest} shiftTimeZone={timezone} />
        </>
      ) : null}
    </>
  )
}
