import { SvgIconProps } from '@traba/types'

export default function ({ size, strokeWidth, color, ...props }: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.72729 7.55819C2.72729 5.37203 2.72729 4.27896 3.26984 3.50412C3.47056 3.21746 3.7199 2.96812 4.00657 2.7674C4.7814 2.22485 5.87448 2.22485 8.06063 2.22485V2.22485C10.2468 2.22485 11.3399 2.22485 12.1147 2.7674C12.4014 2.96812 12.6507 3.21746 12.8514 3.50412C13.394 4.27896 13.394 5.37203 13.394 7.55819V8.89152C13.394 11.0777 13.394 12.1707 12.8514 12.9456C12.6507 13.2322 12.4014 13.4816 12.1147 13.6823C11.3399 14.2249 10.2468 14.2249 8.06063 14.2249V14.2249C5.87448 14.2249 4.7814 14.2249 4.00657 13.6823C3.7199 13.4816 3.47056 13.2322 3.26984 12.9456C2.72729 12.1707 2.72729 11.0777 2.72729 8.89152V7.55819Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M10.0605 12.2249L10.0605 14.2249M6.06055 12.2249L6.06055 14.2249"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M6.06055 4.55823L10.0605 4.55823"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M6.06055 7.22485L10.0605 7.22485"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M6.06055 10.0022L10.0605 10.0022"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
