import { SvgIconProps } from 'src/types/svg-types'

export default function EllipsesFilled({ size, color, ...rest }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
      <path
        d="M2.5 6.5C1.675 6.5 1 7.175 1 8C1 8.825 1.675 9.5 2.5 9.5C3.325 9.5 4 8.825 4 8C4 7.175 3.325 6.5 2.5 6.5Z"
        fill={color}
        stroke={color}
        stroke-width="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13.5 6.5C12.675 6.5 12 7.175 12 8C12 8.825 12.675 9.5 13.5 9.5C14.325 9.5 15 8.825 15 8C15 7.175 14.325 6.5 13.5 6.5Z"
        fill={color}
        stroke={color}
        stroke-width="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8 6.5C7.175 6.5 6.5 7.175 6.5 8C6.5 8.825 7.175 9.5 8 9.5C8.825 9.5 9.5 8.825 9.5 8C9.5 7.175 8.825 6.5 8 6.5Z"
        fill={color}
        stroke={color}
        stroke-width="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
