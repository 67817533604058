import { SvgIconProps } from '@traba/types'

export default function ({ size, color, strokeWidth, ...props }: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6232 7.22504C14.6232 11.1986 10.3659 13.9052 8.98634 14.6779C8.71937 14.8275 8.40161 14.8275 8.13463 14.6779C6.75512 13.9052 2.4978 11.1986 2.4978 7.22504C2.4978 3.58743 5.43538 1.16235 8.56049 1.16235C11.7939 1.16235 14.6232 3.58743 14.6232 7.22504Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <circle
        cx="8.56058"
        cy="7.22513"
        r="2.73343"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
