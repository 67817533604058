import {
  Button,
  ButtonVariant,
  LoadingSpinner,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'

import { addDays, addMonths, addYears, subDays } from 'date-fns'
import { useCallback, useMemo, useState } from 'react'
import { useShiftsWithPagination } from 'src/hooks/useShifts'
import { EmptyPlaceholderTile } from '../base'
import DateRangePicker from '../base/AriaDatePicker/DateRangePicker'
import { ScheduleDetailsShiftsMultiShiftTile } from './ScheduleDetailsShiftsMultiShiftTile'
import {
  convertDateRangeToReadableFormat,
  groupShiftsByWeekDayAndShiftRequest,
  ShiftsGroupedByWeekThenDayThenRole,
} from './utils'

interface ScheduleDetailsShiftsListProps {
  shiftRequestParentId: string
}

export const ScheduleDetailsShiftsList: React.FC<
  ScheduleDetailsShiftsListProps
> = ({ shiftRequestParentId }) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ])
  const [shiftIdInEdit, setShiftIdInEdit] = useState<string | null>(null)
  const today = new Date()
  const todaysDayOfWeek = today.getDay()

  const {
    rawShifts,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isError,
    isLoading,
    refetch: refetchShifts,
  } = useShiftsWithPagination({
    shiftRequestParentIds: [shiftRequestParentId],
    startAfter: dateRange[0] ?? subDays(today, todaysDayOfWeek),
    endBefore:
      dateRange[1] ?? addMonths(addDays(today, 7 - todaysDayOfWeek), 2),
  })

  const upcomingShiftsOnSchedule = rawShifts?.pages.flatMap(
    (page) => page?.data,
  )

  const groupedShifts: ShiftsGroupedByWeekThenDayThenRole = useMemo(
    () => groupShiftsByWeekDayAndShiftRequest(upcomingShiftsOnSchedule ?? []),
    [upcomingShiftsOnSchedule],
  )

  const dateRanges = Object.keys(groupedShifts)

  const onClickLoadMore = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage])

  if ((isLoading || !upcomingShiftsOnSchedule) && !isError) {
    return <LoadingSpinner />
  }

  if (isError) {
    return (
      <Text variant="body2">
        Error loading shifts for this schedule. Please try again later.
      </Text>
    )
  }

  if (upcomingShiftsOnSchedule && upcomingShiftsOnSchedule.length === 0) {
    return (
      <EmptyPlaceholderTile
        title="Shifts will appear here three weeks before the schedule starts."
        iconName="calendar"
      />
    )
  }

  return (
    <>
      <DateRangePicker
        style={{ marginBottom: theme.space.med }}
        dateRange={dateRange}
        setDateRange={setDateRange}
        granularity="day"
        isClearable
        maxDate={addYears(new Date(), 1)}
      />
      {dateRanges.map((dateRange, index) => (
        <div
          key={`date-range-${index}`}
          style={{ marginBottom: theme.space.med }}
        >
          <Text variant="h4" style={{ marginBottom: theme.space.xs }}>
            {convertDateRangeToReadableFormat(dateRange)}
          </Text>
          {Object.entries(groupedShifts[dateRange]).map(
            ([date, roleIdsToShift], index) => (
              <div
                key={`schedule-shift-${index}`}
                style={{ marginBottom: theme.space.xs }}
              >
                <ScheduleDetailsShiftsMultiShiftTile
                  dateOfShift={new Date(date)}
                  roleIdsToShift={roleIdsToShift}
                  shiftRequestParentId={shiftRequestParentId}
                  shiftIdInEdit={shiftIdInEdit}
                  setShiftIdInEdit={setShiftIdInEdit}
                  refetchShifts={refetchShifts}
                />
              </div>
            ),
          )}
        </div>
      ))}
      <div
        style={{
          alignContent: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        {dateRange[0] && dateRange[1] ? (
          <Text variant="body2">
            {'<- This is the end of shifts in selected time range. ->'}{' '}
          </Text>
        ) : hasNextPage ? (
          <Button
            variant={ButtonVariant.OUTLINED}
            slim
            loading={isFetching}
            onClick={onClickLoadMore}
          >
            Load more
          </Button>
        ) : (
          <Text variant="body2">{'<- This is the end of shifts. ->'} </Text>
        )}
      </div>
    </>
  )
}
