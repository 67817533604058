import { Skeleton } from '@mui/material'
import React from 'react'
import { theme } from 'src/libs/theme'

import { Col, Row, Text } from '..'
import * as S from '../ShiftTile/ShiftTile.styles'

function MobileShiftTileSkeleton() {
  return (
    <div style={{ paddingBottom: theme.space.xxs }}>
      <S.ShiftTileContainer style={{ padding: 17 }}>
        <Row justifyBetween style={{ alignItems: 'center' }}>
          <Skeleton animation="wave" width="100px" height={theme.space.xl} />
          <Skeleton animation="wave" width="60px" height={theme.space.xl} />
        </Row>

        <Row>
          <Col style={{ width: 134 }}>
            <Text variant="caption">CLOCK IN</Text>
            <Skeleton animation="wave" width="86px" height={theme.space.lg} />
          </Col>
          <Col>
            <Text variant="caption">CLOCK OUT</Text>
            <Skeleton animation="wave" width="86px" height={theme.space.lg} />
          </Col>
        </Row>

        <Row style={{ justifyContent: 'space-between' }}>
          <S.ShiftTileCol>
            <Skeleton animation="wave" width="150px" height="49px" />
          </S.ShiftTileCol>
          <S.ShiftTileCol>
            <Skeleton animation="wave" width="70px" height="49px" />
          </S.ShiftTileCol>
        </Row>
      </S.ShiftTileContainer>
    </div>
  )
}

export default MobileShiftTileSkeleton
