import { Tooltip } from '@mui/material'
import { InputStatus } from '@traba/types'
import React from 'react'
import {
  Button,
  ButtonVariant,
  Checkbox,
  Divider,
  Icon,
  Row,
  Text,
} from 'src/components'
import { IMenuItem, SelectDropdown } from 'src/components/base/Select/Select'
import { InfoIcon } from 'src/components/WorkerDetails/WorkerDetails.styles'
import { theme } from 'src/libs/theme'

import { BookShiftsProps } from '../BookShiftsScreen'

export type Section = {
  title: string
  subtitle?: string
  singular?: string
  plural: string
  options: IMenuItem[]
  selected?: string
  onChange: (v: string) => void
  onCreate?: () => void
  optional?: boolean
  hide: boolean
  description?: string
  checkboxLabel?: string
  contentExpanded?: boolean
  errorMessage?: string
  tooltip?: string
  onClickCheckbox?: () => void
  Content?: (props: BookShiftsProps) => JSX.Element | null
  CreationContent?: (props: BookShiftsProps) => JSX.Element | null
  customDropdown?: JSX.Element
}

export function BookShiftStepSection(props: {
  section: Section
  bookShiftProps: BookShiftsProps
  noDivider?: boolean
}) {
  const { section: s } = props
  const CreationContent = s.CreationContent
  const Content = s.Content
  return (
    <React.Fragment key={s.title}>
      {!s.optional ? (
        <Text variant="h5">{s.title}</Text>
      ) : (
        <Row
          style={{
            alignItems: 'flex-end',
            columnGap: 4,
          }}
        >
          <Text variant="h5">{s.title}</Text>
          <Text variant="h7">(optional)</Text>
          {s.tooltip && (
            <Tooltip
              title={s.tooltip}
              style={{ marginTop: `${theme.space.sm}px` }}
            >
              <InfoIcon hover={false}>
                <Icon
                  name="info"
                  style={{
                    // svg is slightly off center :/
                    marginLeft: 2,
                    marginTop: 1,
                  }}
                />
              </InfoIcon>
            </Tooltip>
          )}
        </Row>
      )}
      {s.subtitle && (
        <Text
          variant="body2"
          style={{
            marginBottom: theme.space.xs,
            marginTop: theme.space.xxs,
          }}
        >
          {s.subtitle}
        </Text>
      )}
      {s.description ? (
        <Text variant="body2" style={{ marginTop: theme.space.xxs }}>
          {s.description}
        </Text>
      ) : null}
      {s.checkboxLabel && (
        <Checkbox
          labelStyle={{ marginTop: theme.space.xs }}
          defaultChecked={!s.contentExpanded}
          value={!s.contentExpanded}
          label={s.checkboxLabel}
          labelVariant="body1"
          onChange={s.onClickCheckbox}
        />
      )}
      {s.contentExpanded && (
        <>
          {s.options.length &&
            (s.customDropdown || (
              <SelectDropdown
                placeholder={`Choose from existing ${s.plural}`}
                value={s.selected || ''}
                handleSelect={s.onChange}
                menuItems={[
                  { label: `Choose from existing ${s.plural}`, value: '' },
                  ...s.options,
                ]}
                style={{
                  margin: `${theme.space.xs}px 0 ${theme.space.xxs}px`,
                }}
                inputStatus={
                  props.bookShiftProps.isRebook && !s.selected && !s.optional
                    ? InputStatus.error
                    : undefined
                }
                errorMessage={s.errorMessage}
              />
            ))}
          <Row>
            {CreationContent ? (
              <CreationContent {...props.bookShiftProps} />
            ) : (
              s.onCreate && (
                <Button
                  variant={ButtonVariant.LINK}
                  onClick={s.onCreate}
                  style={{ marginTop: theme.space.xxs }}
                >
                  Create new {s.singular}
                </Button>
              )
            )}
          </Row>
          {Content && <Content {...props.bookShiftProps} />}
        </>
      )}
      {props.noDivider ? null : (
        <Divider
          wrapperStyle={{
            margin: `${theme.space.lg}px 0`,
          }}
        />
      )}
    </React.Fragment>
  )
}
