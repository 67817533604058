import { Company } from '@traba/types'
import React, { useEffect } from 'react'
import { Button, ButtonVariant, Text } from 'src/components/base'
import { useUserPermissions } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { UserRolePermission } from 'src/types'

import { EditCompany } from '../EditCompany'
import { ReadOnlyCompany } from '../ReadOnlyCompany'
import { useCompanyKioskPin } from './CompanyProfile.hooks'
import * as S from './CompanyProfile.styles'

export type CompanyProfileProps = {
  company: Company | undefined
  patchCompany: any
  isLoading?: boolean
}

export const CompanyProfileUI: React.FC<CompanyProfileProps> = (
  props: CompanyProfileProps,
) => {
  const userCanEditCompany = useUserPermissions([
    UserRolePermission.ManageCompanySettings,
  ])
  const [editMode, setEditMode] = React.useState(false)
  const defaultCompany = {
    employerName: props.company?.employerName,
    companyLogo: props.company?.companyLogo,
    bannerUrl: props.company?.bannerUrl,
  }
  const [companyUpdate, setCompanyUpdate] = React.useState(defaultCompany)

  const { data: kioskPin } = useCompanyKioskPin(props.company)

  useEffect(() => {
    setCompanyUpdate({
      employerName: props.company?.employerName,
      bannerUrl: props.company?.bannerUrl,
      companyLogo: props.company?.companyLogo,
    })
  }, [editMode])

  return (
    <S.CompanyProfileContainer>
      <div
        style={{
          display: 'flex',
          marginBottom: theme.space.xs,
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Text style={{ marginBottom: theme.space.xxs }} variant="h5">
            Company
          </Text>
          <Text variant="body2">
            {userCanEditCompany ? 'Manage' : 'View'} your company‘s display
            settings.
          </Text>
        </div>
        {userCanEditCompany ? (
          <Button
            onClick={() => {
              setEditMode(!editMode)
            }}
            variant={ButtonVariant.OUTLINED}
          >
            <S.ButtonIcon name="edit" />
          </Button>
        ) : null}
      </div>
      {!editMode ? (
        <ReadOnlyCompany
          company={{ ...props.company, kioskPin: kioskPin?.pin }}
        />
      ) : (
        <EditCompany
          companyId={props.company?.companyId}
          companyUpdate={companyUpdate}
          setCompanyUpdate={setCompanyUpdate}
          patchCompany={props.patchCompany}
          setEditMode={setEditMode}
          defaultBreaks={props.company?.defaultBreaks}
        />
      )}
    </S.CompanyProfileContainer>
  )
}
