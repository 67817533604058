import { Tab, Tabs } from '@traba/react-components'

interface Props {
  handleTabs: (e: React.SyntheticEvent<Element, Event>, val: number) => void
  tabIdx: number
}

export const WorkersTabs = ({ tabIdx, handleTabs }: Props) => {
  return (
    <Tabs variant="scrollable" value={tabIdx} onChange={handleTabs}>
      <Tab label="Today's workers" />
      <Tab label="All workers" />
      <Tab label="Favorite workers" />
      <Tab label="Blocked workers" />
      <Tab label="Rosters" />
      <Tab label="Hired" />
    </Tabs>
  )
}
