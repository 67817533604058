import { SvgIcon } from '@traba/react-components'
import { IconName } from '@traba/types'
import { HTMLAttributes } from 'react'
import { theme } from 'src/libs/theme'

import { Button, ButtonVariant, Row, Text } from '../base'
import * as S from './EditableContentCard.styles'

interface EditableContentCardHeaderProps {
  title: string
  onEdit?: () => void
  iconName?: IconName
  customEditText?: string
}

function EditableContentCardHeader(props: EditableContentCardHeaderProps) {
  return (
    <S.EditableContentCardHeader justifyBetween>
      <Row alignCenter gap={theme.space.xxs}>
        {props.iconName && <SvgIcon name={props.iconName} />}
        <Text variant="h5">{props.title}</Text>
      </Row>

      {props.onEdit ? (
        <Button onClick={props.onEdit} variant={ButtonVariant.BRANDLINK}>
          {props.customEditText || 'Edit'}
        </Button>
      ) : null}
    </S.EditableContentCardHeader>
  )
}

interface EditableContentCardLabeledValueProps {
  label?: string
  value?: string | React.ReactNode
}

function EditableContentCardLabeledValue(
  props: EditableContentCardLabeledValueProps,
) {
  return (
    <S.EditableContentCardEntry>
      <Text
        variant="h7"
        style={{
          textTransform: 'uppercase',
          color: theme.colors.Grey50,
        }}
      >
        {props.label}
      </Text>
      <Text variant="body1">{props.value}</Text>
    </S.EditableContentCardEntry>
  )
}

interface EditableContentCardProps extends HTMLAttributes<HTMLDivElement> {
  sections: {
    title?: string
    onEdit?: () => void
    entries: { label: string; value: string | React.ReactNode }[]
    iconName?: IconName
    customEditText?: string
  }[]
}

export function EditableContentCardV2(props: EditableContentCardProps) {
  return (
    <S.EditableContentCardV2 {...props}>
      {props.sections.map((s, idx) => (
        <S.EditableContentCardSection
          key={`EditableContentCardV2_section_${s.title}_${idx}`}
        >
          {s.title ? (
            <EditableContentCardHeader
              title={s.title}
              onEdit={s.onEdit}
              iconName={s.iconName}
              customEditText={s.customEditText}
            />
          ) : null}
          <div>
            {s.entries.map((e, idx) => (
              <EditableContentCardLabeledValue
                key={`EditableContentCardLabeledValue_entry_${e.label}_${idx}`}
                {...e}
              />
            ))}
          </div>
        </S.EditableContentCardSection>
      ))}
    </S.EditableContentCardV2>
  )
}
