import { useHotSettings } from '@traba/hooks'
import {
  IMenuItem,
  LoadingSpinner,
  MenuItemGroup,
  SearchSelect,
} from '@traba/react-components'
import { COMPANY_WIDE_ID, COMPANY_WIDE_TEXT, IconName } from '@traba/types'
import {
  doesUserHaveAccessToAnyLocation,
  getLocationNameOrTruncatedAddress,
  getUserFullName,
  isBizMemberCompanyWide,
  sortMemberByFullName,
} from '@traba/utils'
import { useCallback, useMemo } from 'react'
import { useMembers } from 'src/hooks/useMembers'
import { useSelectedRegionalFilterLocations } from 'src/hooks/useRegionalFilter'

const SELECT_ALL_MEMBERS_GROUP_ID = 'SELECT_ALL_MEMBERS_GROUP_ID'
const SELECT_ALL_MEMBERS_OPTION_ID = 'SELECT_ALL_MEMBERS_OPTION_ID'
const SELECT_ALL_MEMBERS_OPTION_TEXT = 'All Members'
const LOCATION_ASSIGNED_MEMBERS_GROUP_ID = 'LOCATION_ASSIGNED_MEMBERS_GROUP_ID'
const LOCATION_ASSIGNED_MEMBERS_TEXT = 'Location Assigned Members'
const COMPANY_WIDE_MEMBERS_TEXT = 'Company Wide Members'

interface MembersWithLocationsFilteredSearchSelectProps {
  onChange: (supervisorId: string) => void
  supervisorId?: string
  placeholder?: string
}

export function MembersWithLocationsFilteredSearchSelect({
  supervisorId,
  onChange,
  placeholder,
}: MembersWithLocationsFilteredSearchSelectProps) {
  const { hotSettings } = useHotSettings()
  const { members, isLoading } = useMembers()
  const { selectedLocationIds } = useSelectedRegionalFilterLocations()

  const groups: MenuItemGroup[] = useMemo(
    () => [
      {
        id: SELECT_ALL_MEMBERS_GROUP_ID,
        title: SELECT_ALL_MEMBERS_OPTION_TEXT,
        hideTitle: true,
      },
      {
        id: LOCATION_ASSIGNED_MEMBERS_GROUP_ID,
        title: LOCATION_ASSIGNED_MEMBERS_TEXT,
      },
      {
        id: COMPANY_WIDE_ID,
        title: COMPANY_WIDE_MEMBERS_TEXT,
      },
    ],
    [],
  )

  const filteredMembers = useMemo(
    () =>
      members.filter((member) =>
        doesUserHaveAccessToAnyLocation({
          user: member,
          locationIds: selectedLocationIds,
        }),
      ),
    [members, selectedLocationIds],
  )

  const allMemberOptions: IMenuItem[] = useMemo(
    () =>
      filteredMembers.sort(sortMemberByFullName).map((member) => {
        const isMemberCompanyWide = isBizMemberCompanyWide(member)
        let tagsRow
        if (isMemberCompanyWide) {
          tagsRow = [
            {
              title: COMPANY_WIDE_TEXT,
              iconName: 'location' as IconName,
              variant: 'darkOrange',
            },
          ]
        } else {
          tagsRow = (member.locations || []).map((loc) => ({
            title: getLocationNameOrTruncatedAddress(loc),
            iconName: 'location' as IconName,
            variant: 'business',
          }))
        }

        const groupId: string | undefined = isMemberCompanyWide
          ? COMPANY_WIDE_ID
          : LOCATION_ASSIGNED_MEMBERS_GROUP_ID

        return {
          value: member.uid || '',
          label: getUserFullName(member),
          tagsRow: hotSettings?.enableRegionalAccessPhase2
            ? tagsRow
            : undefined,
          groupId,
        }
      }),
    [filteredMembers, hotSettings?.enableRegionalAccessPhase2],
  )

  const allOptions = useMemo(() => {
    const selectAllMembersOption: IMenuItem = {
      value: SELECT_ALL_MEMBERS_OPTION_ID,
      label: SELECT_ALL_MEMBERS_OPTION_TEXT,
      groupId: SELECT_ALL_MEMBERS_GROUP_ID,
    }

    return [selectAllMembersOption, ...allMemberOptions]
  }, [allMemberOptions])

  const selectedItem = useMemo(() => {
    return allOptions.find((option) => option.value === supervisorId)
  }, [allOptions, supervisorId])

  const onSelectItem = useCallback(
    (item: IMenuItem | undefined) => {
      if (!item?.value || item?.value === SELECT_ALL_MEMBERS_OPTION_ID) {
        onChange('')
      } else if (item?.value) {
        onChange(item.value)
      }
    },
    [onChange],
  )

  if (isLoading) {
    return <LoadingSpinner />
  }

  // if there is only one member, no need to show the search select
  if (members.length <= 1) {
    return null
  }

  return (
    <SearchSelect
      groupByGroup={hotSettings?.enableRegionalAccessPhase2}
      groups={groups}
      onlyShowLabel
      selectItem={selectedItem}
      handleSelect={onSelectItem}
      options={allOptions}
      selectStyle={{ height: '48px' }}
      style={{ width: 300 }}
      placeholder={placeholder}
      showClearButton
    />
  )
}
