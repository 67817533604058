import { WorkerDetails } from 'src/hooks/useCompanyWorkers'

export const filterWorkersByNameStringSearch = (
  workers: WorkerDetails[] | undefined,
  searchFilter: string,
): WorkerDetails[] => {
  if (workers === undefined) {
    return []
  }
  if (searchFilter === undefined || searchFilter === '') {
    return workers
  }
  const filteredWorkers = workers?.filter((worker: WorkerDetails) => {
    const name = `${worker.worker.firstName} ${worker.worker.lastName}`
    return name.toLowerCase().includes(searchFilter.toLowerCase())
  })
  return filteredWorkers
}
