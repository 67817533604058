import { useState } from 'react'
import { useBillingInfo } from 'src/hooks/useBilling'
import { theme } from 'src/libs/theme'
import { validateUniqueEmails } from 'src/utils/stringUtils'

import { Plus } from '../assets/svg'
import { Button, ButtonVariant, Col, Row, Text } from '../base'
import { BillingContactsModal } from './BillingContactsModal'
import { BillingContactsTable } from './BillingContactsTable'

type BillingContactsSectionProps = {
  canEditBillingContacts: boolean
}

export const BillingContactsSection = (props: BillingContactsSectionProps) => {
  const [showBillingContactsModal, setShowBillingContactsModal] =
    useState(false)

  const { isFetching, billing, patchBilling } = useBillingInfo()
  const billingEmails = billing?.additionalEmails || []

  return (
    <>
      <Col>
        <Row
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: theme.space.xs,
          }}
        >
          <Col style={{ flexGrow: 1, marginRight: theme.space.sm }}>
            <Text style={{ marginBottom: theme.space.xxs }} variant="h5">
              Billing contacts
            </Text>
            <Text style={{ marginBottom: theme.space.xxs }} variant="body2">
              These contacts will receive all invoicing notifications related to
              the account.
            </Text>
          </Col>
          {props.canEditBillingContacts && (
            <Col>
              <Button
                leftIcon={<Plus />}
                reverse
                style={{ flexShrink: 0 }}
                variant={ButtonVariant.TEXT}
                onClick={() => {
                  setShowBillingContactsModal(true)
                }}
              >
                Add Contact
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col width={'100%'}>
            <BillingContactsTable
              isLoading={isFetching}
              billingEmails={billingEmails}
            />
          </Col>
        </Row>
      </Col>
      <BillingContactsModal
        setShowModal={setShowBillingContactsModal}
        isOpen={showBillingContactsModal}
        billingEmails={billingEmails}
        onAddNewEmails={async (emails) => {
          await patchBilling({
            additionalEmails: validateUniqueEmails([
              ...billingEmails,
              ...emails,
            ]),
          })
        }}
      />
    </>
  )
}
