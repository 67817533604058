import { ShiftAttribute } from '@traba/types'
import { RoleAttributeCategory } from '@traba/types'

export const parseFreeFormAttributesToRequiredAttributes = (
  requiredAttributes: ShiftAttribute[] | undefined,
  freeformAttributes:
    | Partial<Record<RoleAttributeCategory, string>>
    | undefined,
) => {
  const fullRequiredAttributes = requiredAttributes ? requiredAttributes : []

  const hasLiftingCategory = requiredAttributes?.some(
    (attribute) => attribute.category === RoleAttributeCategory.Lifting,
  )

  if (!hasLiftingCategory) {
    requiredAttributes?.push({
      category: RoleAttributeCategory.Lifting,
      type: `other_${RoleAttributeCategory.Lifting}`,
    })
  }

  if (freeformAttributes) {
    if (freeformAttributes[RoleAttributeCategory.Responsibilities]) {
      fullRequiredAttributes.push({
        type: `other_${RoleAttributeCategory.Responsibilities}`,
        category: RoleAttributeCategory.Responsibilities,
      })
    }
    if (freeformAttributes[RoleAttributeCategory.Equipment]) {
      fullRequiredAttributes.push({
        type: `other_${RoleAttributeCategory.Equipment}`,
        category: RoleAttributeCategory.Equipment,
      })
    }
  }
  return fullRequiredAttributes
}
