import { ModalPaddingContainer } from '@traba/react-components'
import React from 'react'
import { Row, Text } from 'src/components/base'
import { CreateMember } from 'src/components/CreateMember'
import { theme } from 'src/libs/theme'
import { UserData } from 'src/types'
import { ButtonIcon } from '../../PersonalProfile/PersonalProfile.styles'

export type CreateMemberModalProps = {
  onCloseModal: () => void
  onCreate?: (m: UserData) => void
}

export const CreateMemberModal = (props: CreateMemberModalProps) => {
  return (
    <ModalPaddingContainer>
      <Row justifyBetween style={{ marginBottom: theme.space.xs }}>
        <Text variant="h2">Create contact</Text>
        <ButtonIcon name="cancel" onClick={props.onCloseModal} />
      </Row>
      <CreateMember
        onCreate={props.onCreate}
        onCloseModal={props.onCloseModal}
      />
    </ModalPaddingContainer>
  )
}
