import { Card } from '@traba/react-components'
import { Button, ButtonVariant, Row, SvgIcon, Text } from 'src/components/base'
import { useTrainingVideos } from 'src/hooks/useTrainingVideos'
import { theme } from 'src/libs/theme'
import { TrainingVideo } from 'src/types'

interface Props {
  videos: TrainingVideo[]
}

export const MobileTrainingVideos = ({ videos }: Props) => {
  const { removeTrainingVideo } = useTrainingVideos()
  return (
    <>
      {videos.map((video, index) => (
        <Card
          key={index}
          style={{
            marginTop: '8px',
            width: '100%',
          }}
        >
          <Row alignCenter justifyBetween mb={theme.space.sm}>
            <Text variant="h5">{video.name}</Text>
            <Button
              variant={ButtonVariant.OUTLINED}
              onClick={() => {
                removeTrainingVideo(video.id)
                window.analytics.track(`User Removed Training Video`, {
                  videoId: video.id,
                })
              }}
            >
              <SvgIcon name="trash" color={theme.colors.Grey50} />
            </Button>
          </Row>
          <Row justifyBetween>
            <div>
              <Text variant="h6">Required for all?</Text>
              <Text>{video.requiredForAll ? 'Yes' : 'No'}</Text>
            </div>
            <div>
              <Text variant="h6">Created at</Text>
              <Text>{new Date(video.createdAt).toLocaleString('en-US')}</Text>
            </div>
          </Row>
          <video width="200" controls>
            <source src={video.videoLink} />
          </video>
        </Card>
      ))}
    </>
  )
}
