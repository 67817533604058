import { EditableContentCard } from 'src/components/EditableContentCard/EditableContentCard'
import { useRoleAttributes } from 'src/hooks/useAttributes'
import { useCertifications } from 'src/hooks/useCertifications'
import { useCompany } from 'src/hooks/useCompany'
import { useRoles } from 'src/hooks/useRoles'
import { theme } from 'src/libs/theme'

import { BookShiftsProps } from '../../BookShiftsScreen'
import { getRoleCardEntries } from '../../utils'

export function BookShiftsSiteRoleSection(props: BookShiftsProps) {
  const {
    shiftRequest: { roleId },
  } = props
  const { roles } = useRoles()
  const { company } = useCompany()
  const { roleAttributes } = useRoleAttributes()
  const { certifications } = useCertifications()
  const role = roleId ? roles.find((r) => r.roleId === roleId) : undefined

  if (!role) {
    return null
  }

  return (
    <EditableContentCard
      style={{ marginTop: theme.space.xs }}
      sections={[
        {
          entries: getRoleCardEntries(
            role,
            roleAttributes,
            certifications,
            company,
          ),
        },
      ]}
    />
  )
}
