import { AriaDialogProps, useDialog } from '@react-aria/dialog'
import React, { useRef } from 'react'
import { theme } from 'src/libs/theme'

interface DialogProps extends AriaDialogProps {
  title?: string
  children: React.ReactNode
}

export default function Dialog({ title, children, ...props }: DialogProps) {
  const ref = useRef(null)
  const { dialogProps, titleProps } = useDialog(props, ref)

  return (
    <div {...dialogProps} ref={ref} style={{ padding: theme.space.xs }}>
      {title && (
        <h3 {...titleProps} style={{ marginTop: 0 }}>
          {title}
        </h3>
      )}
      {children}
    </div>
  )
}
