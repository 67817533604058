import { useHotSettings } from '@traba/hooks'
import { recurringSchedulesEnabled } from '@traba/utils'
import { useCompany } from 'src/hooks/useCompany'
import { CalendarMobileWidget } from 'src/screens/Calendar/CalendarMobileWidget'
import { TextVariant } from '../base/Text'
import { useShiftCalendar } from './ShiftCalendar.hooks'
import { ShiftCalendarUI } from './ShiftCalendar.ui'
import { ShiftCalendarWithSchedules } from './ShiftCalendarWithSchedules'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook
export const ShiftCalendar = ({
  before,
  after,
  workerId,
  cacheKey,
  hideWorkers,
  hideFilters,
  sectionTitleTextVariant,
}: {
  before?: Date
  after?: Date
  workerId?: string
  cacheKey?: string
  hideWorkers?: boolean
  hideFilters?: boolean
  sectionTitleTextVariant?: TextVariant
}) => {
  const logic = useShiftCalendar(
    before,
    after,
    workerId,
    cacheKey,
    hideWorkers,
    hideFilters,
    sectionTitleTextVariant,
  )
  const {
    shifts,
    isShiftsFetched,
    dispatch,
    filters,
    showDesktopCard,
    isShowWorkersToggled,
    setIsShowWorkersToggled,
  } = logic
  const { company } = useCompany()
  const { hotSettings } = useHotSettings()
  return (
    <>
      {recurringSchedulesEnabled({
        companyId: company?.companyId,
        hotSettings,
      }) ? (
        <ShiftCalendarWithSchedules {...logic} />
      ) : (
        <ShiftCalendarUI {...logic} />
      )}
      {!showDesktopCard && (
        <CalendarMobileWidget
          shifts={shifts}
          isLoading={!isShiftsFetched}
          dispatch={dispatch}
          filters={filters}
          isFromCalendarScreen={false}
          isShowWorkersToggled={isShowWorkersToggled}
          setIsShowWorkersToggled={setIsShowWorkersToggled}
        />
      )}
    </>
  )
}
