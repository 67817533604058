import { SvgIconProps } from 'src/types/svg-types'

export default function FoodProduction({ size, color, ...rest }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 17 17" fill="none" {...rest}>
      <path
        d="M14.2332 7.72979C15.4868 6.47624 15.4949 4.45189 14.2513 3.20829C13.0077 1.96468 10.9833 1.97275 9.72975 3.2263L2.77276 10.1833C1.51921 11.4368 1.51114 13.4612 2.75475 14.7048C3.99835 15.9484 6.0227 15.9403 7.27625 14.6868L14.2332 7.72979Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        d="M5.47461 10.6685L6.18791 12.9103"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        d="M8.37891 7.86621L9.04127 10.1081"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        d="M11.0796 4.91113L11.7929 7.15298"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </svg>
  )
}
