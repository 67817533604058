import { makePlural } from '@traba/string-utils'
import { ClientShift, Shift, WorkerShift } from '@traba/types'
import { shiftIsClientShift } from '@traba/utils'
import { Row, Text } from 'src/components/base'
import { theme } from 'src/libs/theme'
import { formatDuration } from 'src/shared/utils/dateUtils'
import { Money } from 'src/types'
import { getMoneyString } from 'src/utils/moneyUtils'

interface Props {
  useTotalTitle: boolean | undefined
  totalChargedCents: Money | undefined
  totalTimeWorked: number | undefined
  isUpcoming: boolean | undefined
  shift: Shift | ClientShift
  workersTotal: number
  IsShiftInTheFuture: boolean
  filteredWorkers: WorkerShift[]
  numberOfBackupWorkers: number
  onClickInviteWorker: (() => void) | undefined
  userCanViewWages: boolean
  isEditSlotsEnabled: boolean
  onClickEditWorkerSlots?: (() => void) | undefined
}

export const WorkerOnShiftTableHeader = ({
  useTotalTitle,
  totalChargedCents,
  totalTimeWorked,
  workersTotal,
  isUpcoming,
  filteredWorkers,
  IsShiftInTheFuture,
  shift,
  numberOfBackupWorkers,
  onClickInviteWorker,
  userCanViewWages,
  isEditSlotsEnabled,
  onClickEditWorkerSlots,
}: Props) => {
  let backupWorkersString = ''
  if (numberOfBackupWorkers > 0) {
    backupWorkersString = `(${numberOfBackupWorkers} backup${makePlural(
      numberOfBackupWorkers,
    )})`
  }

  return (
    <>
      {useTotalTitle ? (
        <Row alignEnd>
          <Text variant="h4">Total</Text>
          <Text variant="body1" style={{ marginLeft: theme.space.xxs }}>
            {`
          ${workersTotal} worker${makePlural(workersTotal)} • ${formatDuration(
            totalTimeWorked || 0,
          )} ${
            userCanViewWages
              ? `• ${getMoneyString(totalChargedCents)} billed`
              : ''
          }`}
          </Text>
        </Row>
      ) : (
        <Row alignEnd>
          <Text variant="h4">Workers</Text>
          <Text variant="body1" style={{ marginLeft: theme.space.xxs }}>
            {workersTotal}{' '}
            {isUpcoming
              ? `of ${shift.slotsRequested} ${backupWorkersString}`
              : 'total'}
          </Text>
          {isEditSlotsEnabled && onClickEditWorkerSlots && (
            <Text
              variant="link"
              style={{ paddingLeft: theme.space.xxs }}
              onClick={onClickEditWorkerSlots}
            >
              Edit
            </Text>
          )}
        </Row>
      )}
      {!(shiftIsClientShift(shift) && shift.hideInvitations) &&
        IsShiftInTheFuture &&
        filteredWorkers.length === 0 && (
          <Text
            ml={theme.space.xs}
            variant="link"
            onClick={() => {
              onClickInviteWorker && onClickInviteWorker()
            }}
          >
            + Invite Workers
          </Text>
        )}
    </>
  )
}
