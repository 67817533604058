import { useEffect } from 'react'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'
import useMobile from 'src/hooks/useMobile'
import { MobileDisclaimer } from '../Disclaimer/MobileDisclaimer'
import { ActiveMembersSection } from './ActiveMembersSection/ActiveMembersSection'
import { ContactsSection } from './ContactsSection'
import { InvitationsSection } from './InvitationsSection'
import * as S from './MembersProfile.styles'

export const MembersProfile: React.FC = () => {
  const { isExclusivelyMobileView } = useMobile()
  const { dispatch: appContextDispatch } = useAppContext()

  useEffect(() => {
    appContextDispatch({
      type: 'SET_REGIONAL_FILTER_STATUS',
      value: RegionalFilterStatus.ALL_COMPANY_LOCATIONS,
    })
    return () => {
      appContextDispatch({
        type: 'SET_REGIONAL_FILTER_STATUS',
        value: RegionalFilterStatus.HIDE,
      })
    }
  }, [appContextDispatch])

  if (isExclusivelyMobileView) {
    return <MobileDisclaimer />
  }

  return (
    <S.MembersProfileContainer>
      <ActiveMembersSection />

      <InvitationsSection />

      <ContactsSection />
    </S.MembersProfileContainer>
  )
}
