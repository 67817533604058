import { theme } from '@traba/theme'
import { SvgIcon } from '../SvgIcon'
import { StyledCheckboxContainer } from './Select.styles'

export enum CheckedStatus {
  CHECKED = 'checked',
  UNCHECKED = 'unchecked',
  PARTIAL = 'partial',
}
export const CHECKED_CHECKBOX_STATUS_VALUES = [
  CheckedStatus.CHECKED,
  CheckedStatus.PARTIAL,
]

export interface StyledSelectCheckboxProps {
  checkedStatus?: CheckedStatus
  disabled?: boolean
  isGroupCheckbox?: boolean
}

export function StyledSelectCheckbox({
  checkedStatus = CheckedStatus.UNCHECKED,
  disabled,
  isGroupCheckbox,
}: StyledSelectCheckboxProps) {
  return (
    <StyledCheckboxContainer
      checked={CHECKED_CHECKBOX_STATUS_VALUES.includes(checkedStatus)}
      disabled={disabled}
      isGroupCheckbox={isGroupCheckbox}
    >
      {checkedStatus === CheckedStatus.CHECKED && (
        <SvgIcon
          name="check"
          size={16}
          color={disabled ? theme.colors.Grey40 : theme.colors.White}
        />
      )}
      {checkedStatus === CheckedStatus.PARTIAL && (
        <SvgIcon
          name="minus"
          size={16}
          color={disabled ? theme.colors.Grey40 : theme.colors.White}
        />
      )}
    </StyledCheckboxContainer>
  )
}
