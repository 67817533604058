import { FixedLengthArray } from '@traba/types'

export function getQueryParams(
  params: FixedLengthArray<[string, string | null | undefined | boolean]>[],
) {
  const paramsToAdd: string[] = []

  for (const queryParam of params) {
    if (typeof queryParam[1] === 'boolean') {
      paramsToAdd.push(`${queryParam[0]}=${queryParam[1].toString()}`)
      continue
    }
    if (queryParam[1]) {
      paramsToAdd.push(`${queryParam[0]}=${queryParam[1]}`)
    }
  }

  if (!paramsToAdd.length) return ''
  return `?${paramsToAdd.join('&')}`
}
