import styled, { css } from 'styled-components'
import { BaseChevronDown } from './svgs/BaseChevronDown'

export const ChevronDownWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
`

export const ChevronDown = styled(BaseChevronDown)<{
  $isActive?: boolean
  color?: string
}>`
  transition: all 0.3s;

  ${({ $isActive, color, theme }) =>
    $isActive
      ? css`
          transform: rotate(180deg);

          path {
            stroke: ${color || theme.colors.brand};
          }
        `
      : ''}
`
