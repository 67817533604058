import { SvgIconProps } from '@traba/types'

export default function ThumbsUp({
  size = 16,
  color = 'currentColor',
  strokeWidth = 1.5,
  ...props
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M5.25823 2.56001L3.64049 6.64522C3.54767 6.87963 3.5 7.12947 3.5 7.38158V10.25C3.5 11.3546 4.39543 12.25 5.5 12.25H9.94641C10.8095 12.25 11.5752 11.6964 11.8457 10.8768L12.2582 9.62675C12.6852 8.3328 11.7215 7 10.3589 7H9.73225C8.62768 7 7.73225 6.10457 7.73225 5V3.03204C7.73225 2.32399 7.15826 1.75 6.45021 1.75C5.92437 1.75 5.45184 2.07111 5.25823 2.56001Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M1.75 7.875V11.375"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  )
}
