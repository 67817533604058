export enum StyleVariant {
  FILLED = 'filled',
  OUTLINED = 'outlined',
  TEXT = 'text',
}

export enum InputStatus {
  default = 1,
  disabled = 2,
  error = 3,
  success = 4,
}

export enum Envs {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
  TEST = 'test',
}
