import { SvgIconProps } from 'src/types/svg-types'

export default function Certification({ size = 32, ...props }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 32 31" fill="none" {...props}>
      <circle cx="15.9968" cy="15.2267" r="15.1454" fill="#F5EBFF" />
      <path
        d="M17.4651 16.3106C17.95 16.2004 18.3963 15.9973 18.7832 15.7213L19.5478 18.7259C19.7773 19.628 18.8123 20.3777 17.9529 19.965L16.6644 19.3461C16.2438 19.1441 15.7499 19.1441 15.3293 19.3461L14.0408 19.965C13.1813 20.3777 12.2163 19.628 12.4459 18.7259L13.2105 15.7213C13.5974 15.9973 14.0437 16.2004 14.5285 16.3106M17.4651 16.3106C16.4994 16.53 15.4943 16.53 14.5285 16.3106M17.4651 16.3106C18.8024 16.0067 19.8466 14.9955 20.1603 13.7003C20.3868 12.7649 20.3868 11.7915 20.1603 10.8561C19.8466 9.56094 18.8024 8.54966 17.4651 8.24585C16.4994 8.02645 15.4943 8.02645 14.5285 8.24585C13.1912 8.54966 12.1471 9.56094 11.8334 10.8561C11.6069 11.7915 11.6069 12.7649 11.8334 13.7003C12.1471 14.9955 13.1912 16.0067 14.5285 16.3106"
        stroke="#8000FF"
        stroke-width="1.5"
      />
    </svg>
  )
}
