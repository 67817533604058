import { SvgIconProps } from '@traba/types'

export function ArrowRight({ size, color, strokeWidth = 1.5 }: SvgIconProps) {
  return (
    <svg
      width={size ?? '16'}
      height={size ?? '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 8H14M14 8L9.41176 4M14 8L9.41176 12"
        stroke={color ?? '#8000FF'}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
