import { Company } from '@traba/types'
import { getBreakOptions } from '@traba/utils'
import React from 'react'
import {
  Col,
  InlineBanner,
  Text,
  Row,
  Input,
  Button,
  ButtonVariant,
} from 'src/components/base'
import { theme } from 'src/libs/theme'
import { SelectDropdown } from '../base/Select/Select'

export type DefaultShiftBreaksProps = {
  companyUpdate?: any
  defaultBreaksLength: number | undefined
  defaultBreaksCount: number | undefined
  setDefaultBreaksLength?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
  setDefaultBreaksCount?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
  setCompanyUpdate?: (companyUpdate: Partial<Company>) => void
  isEdit?: boolean
}

export const DefaultShiftBreaks: React.FC<DefaultShiftBreaksProps> = (
  props: DefaultShiftBreaksProps,
) => {
  const {
    companyUpdate,
    defaultBreaksLength,
    defaultBreaksCount,
    setDefaultBreaksLength,
    setDefaultBreaksCount,
    setCompanyUpdate,
    isEdit,
  } = props
  const BREAK_OPTIONS = getBreakOptions()
  return (
    <Col mb={theme.space.xxxs}>
      <Text variant="h5" mb={theme.space.xxs}>
        Default Shift Breaks
      </Text>
      <Text variant="body2" mb={theme.space.xxxs}>
        Use default shift breaks to ensure that all posted shifts align with
        your company's standard break times.{' '}
      </Text>
      <InlineBanner
        text="Default breaks will be
      automatically applied to each shift posting, while still allowing you
      the flexibility to adjust or remove them as necessary during the
      posting process."
        style={{ marginTop: theme.space.xxs }}
      />
      <Row
        style={{
          columnGap: theme.space.xs,
          marginTop: theme.space.sm,
          marginBottom: theme.space.xs,
        }}
      >
        {isEdit ? (
          <SelectDropdown
            label="Duration"
            style={{ flex: 2 }}
            menuItems={BREAK_OPTIONS}
            placeholder="Duration"
            value={defaultBreaksLength?.toString() || 'N/A'}
            handleSelect={(val) => {
              if (!setDefaultBreaksLength || !setCompanyUpdate) {
                return
              }
              const breakLength = parseInt(val)
              setDefaultBreaksLength(breakLength)
              setCompanyUpdate({
                ...companyUpdate,
                defaultBreaks: {
                  breakLength,
                  count: defaultBreaksCount,
                },
              })
            }}
          />
        ) : (
          <Input
            label="Duration"
            placeholder="Duration"
            disabled={!isEdit}
            containerStyle={{ flex: 2, marginTop: 0 }}
            value={
              (defaultBreaksLength && defaultBreaksLength + ' min') || 'N/A'
            }
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onChange={() => {}}
            leftIconName="clock"
          />
        )}

        <Input
          placeholder="Count"
          label="Count"
          containerStyle={{ flex: 1, marginTop: 0 }}
          onChange={(e) => {
            if (!setDefaultBreaksCount || !setCompanyUpdate) {
              return
            }
            const count = parseInt(e.target.value)
            setDefaultBreaksCount(count)
            setCompanyUpdate({
              ...companyUpdate,
              defaultBreaks: {
                breakLength: defaultBreaksLength,
                count,
              },
            })
          }}
          disabled={!isEdit}
          value={defaultBreaksCount || (isEdit ? '' : 'N/A')}
        />
        {isEdit && (
          <Button
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              if (
                !setDefaultBreaksLength ||
                !setDefaultBreaksCount ||
                !setCompanyUpdate
              ) {
                return
              }
              setDefaultBreaksLength(undefined)
              setDefaultBreaksCount(undefined)
              setCompanyUpdate({
                ...companyUpdate,
                defaultBreaks: null,
              })
            }}
          >
            <Text style={{ fontWeight: 'bold' }}>Clear</Text>
          </Button>
        )}
      </Row>
    </Col>
  )
}
