import { useNavigate } from 'react-router-dom'
import { Button, ButtonVariant } from 'src/components/base'
import { Text } from 'src/components/base/Text'
import { ModalWithImage } from 'src/components/Modals/ModalWithImage'

export function ShiftCreatedModalContent({
  shiftCount,
  setShowModal,
}: {
  shiftCount: number
  setShowModal: (showModal: boolean) => void
}) {
  const navigate = useNavigate()

  const multipleShifts = shiftCount > 1
  return (
    <ModalWithImage handleClose={() => setShowModal(false)}>
      <Text variant="h3" style={{ marginTop: '32px', marginBottom: '16px' }}>
        {multipleShifts
          ? `New shifts have been booked`
          : `New shift has been booked`}
      </Text>
      <Text variant="body2" style={{ marginBottom: '64px' }}>
        {multipleShifts
          ? `Congratulations, you just booked a set of shifts. How amazing is
            that! Would you like to book some more?`
          : `Congratulations, you just booked a shift. How amazing is that! Would you like to book another?`}
      </Text>
      <Button
        style={{ margin: '4px 0' }}
        onClick={() => navigate('/book-shifts')}
      >
        Book more shifts
      </Button>
      <Button
        variant={ButtonVariant.OUTLINED}
        style={{ margin: '4px 0' }}
        onClick={() => setShowModal(false)}
        aria-label="skip"
      >
        Maybe Later
      </Button>
    </ModalWithImage>
  )
}
