import { Card } from '@traba/react-components'
import { WorkerShiftWithWorkerDetails } from '@traba/types'
import { Text } from 'src/components/base/Text'
import * as S from 'src/components/EditableContentCard/EditableContentCard.styles'
import { theme } from 'src/libs/theme'

import { Col } from '../base'

export type SelectedWorkersProps = {
  workers?: WorkerShiftWithWorkerDetails[]
}

/**
 * Simple Card that displays a list of workers
 */
export const SelectedWorkersCard = (props: SelectedWorkersProps) => {
  const { workers } = props

  if (!workers?.length) {
    return null
  }

  const midway = Math.ceil(workers.length / 2)
  const firstHalf = workers.slice(0, midway)
  const secondHalf = workers.slice(midway)

  return (
    <Col gap={theme.space.xxs}>
      {/* Selected Workers Section */}
      <Text variant="h6">Selected Workers</Text>
      <Card
        style={{
          padding: 0,
          flexDirection: 'row',
        }}
      >
        {workers.length < 4 ? (
          <S.EditableContentColumn style={{ width: '100%' }}>
            {workers.map(({ worker }) => (
              <Text>
                {worker.firstName} {worker.lastName}
              </Text>
            ))}
          </S.EditableContentColumn>
        ) : (
          <>
            <S.EditableContentColumn style={{ width: '50%' }}>
              {firstHalf.map(({ worker }) => (
                <Text>
                  {worker.firstName} {worker.lastName}
                </Text>
              ))}
            </S.EditableContentColumn>
            <S.EditableContentColumn style={{ width: '50%' }}>
              {secondHalf.map(({ worker }) => (
                <Text>
                  {worker.firstName} {worker.lastName}
                </Text>
              ))}
            </S.EditableContentColumn>
          </>
        )}
      </Card>
    </Col>
  )
}
