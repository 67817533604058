import { SvgIconProps } from 'src/types/svg-types'

export default function Training({ size = 52, ...props }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 32 31" fill="none" {...props}>
      <circle cx="16.1049" cy="15.3054" r="15.1454" fill="#F5EBFF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5606 9.57885C16.7797 9.15906 15.8429 9.14752 15.0519 9.54796L9.11362 12.554C8.26564 12.9833 8.26341 14.1935 9.10979 14.6259L11.4159 15.804V18.8939C11.4159 19.0085 11.4644 19.6375 12.1466 20.2349C12.8221 20.8263 14.0656 21.3397 16.3506 21.3549C18.643 21.3703 19.9219 20.839 20.6336 20.2379C20.9885 19.9381 21.1911 19.63 21.3051 19.3864C21.3619 19.2653 21.3962 19.1613 21.4168 19.0835C21.4271 19.0447 21.434 19.0123 21.4386 18.9876C21.4408 18.9753 21.4425 18.9648 21.4438 18.9564L21.4453 18.9454L21.4459 18.941L21.4462 18.9383C21.4462 18.9383 21.4463 18.9374 21.0613 18.8939L21.4462 18.9383L21.4485 18.9178L21.4847 15.5016L22.9585 14.7021V18.256C22.9585 18.47 23.132 18.6434 23.3459 18.6434C23.5599 18.6434 23.7333 18.47 23.7333 18.256V13.6072C23.7333 13.5946 23.7327 13.582 23.7315 13.5696C23.7244 13.1745 23.5204 12.7826 23.1198 12.5672L17.5606 9.57885ZM15.4018 10.2392C15.9668 9.95321 16.636 9.96145 17.1938 10.2613L22.7529 13.2497C23.0243 13.3955 23.025 13.7845 22.7542 13.9314L16.4828 17.3336C16.3706 17.3945 16.2356 17.3961 16.1218 17.338L9.46228 13.9359C9.18015 13.7918 9.1809 13.3884 9.46355 13.2453L15.4018 10.2392ZM20.7053 15.9244L16.8523 18.0146C16.5156 18.1973 16.1105 18.2023 15.7694 18.028L12.1907 16.1998V18.8895C12.1932 18.9162 12.2269 19.2754 12.657 19.652C13.139 20.074 14.1704 20.5655 16.3558 20.5801C18.5336 20.5947 19.6088 20.0892 20.1337 19.646C20.397 19.4236 20.5333 19.2077 20.6035 19.0578C20.6389 18.9822 20.658 18.9222 20.6679 18.885C20.6708 18.8742 20.6728 18.8654 20.6743 18.8587L20.7053 15.9244Z"
        fill="#8000FF"
      />
    </svg>
  )
}
