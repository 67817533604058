import {
  Button,
  MODAL_SIZE,
  Dialog,
  DialogProps,
} from '@traba/react-components'
import React from 'react'
import { useModal } from 'src/components/base'
import { getAllComponentCombinationsFromList } from 'src/utils/testUtils'

const dialogTitle = 'Dummy Dialog Title'
const dialogSubtitle = 'This is a dummy dialog subtitle'

export const dialogPropsList: Partial<DialogProps>[] = [
  {
    dialogTitle: dialogTitle,
    onConfirmCTA: 'OK',
  },
  {
    dialogTitle: dialogTitle,
    dialogSubtitle: dialogSubtitle,
  },
  {
    dialogTitle: dialogTitle,
    dialogSubtitle: dialogSubtitle,
    size: MODAL_SIZE.SMALL,
  },
  {
    dialogTitle: dialogTitle,
    dialogSubtitle: dialogSubtitle,
    size: MODAL_SIZE.MEDIUM,
  },
  {
    dialogTitle: dialogTitle,
    dialogSubtitle: dialogSubtitle,
    size: MODAL_SIZE.LARGE,
  },
  {
    dialogTitle: dialogTitle,
    dialogSubtitle: dialogSubtitle,
    size: MODAL_SIZE.LARGE,
  },
  {
    dialogTitle: dialogTitle,
    dialogSubtitle: dialogSubtitle,
    size: MODAL_SIZE.LARGE,
    confirmDisabled: true,
  },
  {
    dialogTitle: dialogTitle,
    dialogSubtitle: dialogSubtitle,
    size: MODAL_SIZE.LARGE,
    dismissable: false,
  },
]

const dialogVariations = getAllComponentCombinationsFromList(
  Dialog,
  dialogPropsList,
)

export default function TestingDialog() {
  const { handleClose, open, isOpen } = useModal()
  const [currentVariation, setCurrentVariation] = React.useState(0)

  const CurrentDialogVariation = dialogVariations[currentVariation]

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          marginBottom: '20px',
        }}
      >
        {dialogVariations.map((_, index) => (
          <Button
            key={index}
            data-testid={`dialog-case`}
            onClick={() => {
              setCurrentVariation(index)
              open()
            }}
            aria-label={`Open Dialog Variation ${index}`}
          >
            Open Dialog {index}
          </Button>
        ))}
      </div>
      {React.cloneElement(CurrentDialogVariation, {
        open: isOpen,
        onClose: handleClose,
        onConfirm: handleClose,
        children: (
          <>
            <p>This is the content of the dummy dialog.</p>
            <p>It uses the custom Dialog component provided.</p>
          </>
        ),
      })}
    </>
  )
}
