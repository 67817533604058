import { MouseEventHandler } from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon, Text } from 'src/components/base'
import styled from 'styled-components'

type Props = {
  onClick?: MouseEventHandler<HTMLElement> & ((ev: Event) => void)
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  buttonText?: string
}

const StyledBackButton = styled(Text)`
  cursor: pointer;
  font-weight: 500;
`

function BackButton(props: Props) {
  const navigate = useNavigate()
  const { onClick, children, className, style, buttonText } = props

  return (
    <StyledBackButton
      variant="body2"
      onClick={onClick ?? (() => navigate(-1))}
      className={className}
      style={style}
    >
      {children ?? (
        <>
          <Icon name="leftArrow" style={{ marginRight: 4 }} />
          {`${buttonText ?? 'Go Back'}`}
        </>
      )}
    </StyledBackButton>
  )
}

export default BackButton
