import { MODAL_SIZE } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { ParentInvoiceGroup } from '@traba/types'
import { useState } from 'react'
import {
  Button,
  ButtonVariant,
  Col,
  EmptyPlaceholderTile,
  Modal,
  Row,
  Text,
} from 'src/components/base'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { theme } from 'src/libs/theme'

import { Plus } from '../assets/svg'
import { ArchiveInvoiceGroupModal } from '../Modals/InvoiceGroups/ArchiveInvoiceGroupModal'
import { CreateOrEditInvoiceGroupModal } from '../Modals/InvoiceGroups/CreateOrEditInvoiceGroupModal'
import { InvoiceGroup } from './InvoiceGroup'

type InvoiceGroupsSectionProps = {
  userCanEdit?: boolean
}

export const InvoiceGroupsSection = (props: InvoiceGroupsSectionProps) => {
  const { activeInvoiceGroups } = useInvoiceGroups()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [groupToEdit, setGroupToEdit] = useState<
    ParentInvoiceGroup | undefined
  >(undefined)
  const [showArchiveModal, setShowArchiveModal] = useState(false)
  const [groupToArchive, setGroupToArchive] = useState<
    ParentInvoiceGroup | undefined
  >(undefined)

  function openModal() {
    setIsModalOpen(true)
  }
  function closeModal() {
    setIsModalOpen(false)
  }

  const handleEdit = (group: ParentInvoiceGroup) => {
    setShowEditModal(true)
    setGroupToEdit(group)
  }

  const handleEditModalClose = () => {
    setShowEditModal(false)
    setGroupToEdit(undefined)
  }

  const handleArchive = (group: ParentInvoiceGroup) => {
    setShowArchiveModal(true)
    setGroupToArchive(group)
  }

  const handleArchiveModalClose = () => {
    setShowArchiveModal(false)
    setGroupToArchive(undefined)
  }

  return (
    <div>
      <Row
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: theme.space.xs,
        }}
      >
        <Col mr={theme.space.sm}>
          <Text style={{ marginBottom: theme.space.xxs }} variant="h5">
            Invoice groups
          </Text>

          <Text variant="body2">
            {props.userCanEdit ? 'Manage' : 'View'} your company‘s invoice
            groups.
          </Text>
        </Col>

        {props.userCanEdit ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              leftIcon={<Plus />}
              onClick={openModal}
              reverse
              variant={ButtonVariant.TEXT}
              style={{ paddingLeft: '0px' }}
            >
              Create new invoice group
            </Button>
          </div>
        ) : null}
      </Row>

      <Row gap={theme.space.sm} wrap>
        {activeInvoiceGroups.length ? (
          activeInvoiceGroups.map(
            (group: ParentInvoiceGroup, index: number) => (
              <Card key={index} style={{ width: '528px' }}>
                <InvoiceGroup
                  group={group}
                  userCanEdit={props.userCanEdit}
                  edit={handleEdit}
                  archive={handleArchive}
                />
              </Card>
            ),
          )
        ) : (
          <EmptyPlaceholderTile
            title="Your invoice groups will show up here"
            subtitle="You can add an invoice group above."
            iconName="folder"
          />
        )}
      </Row>

      <Modal isOpen={isModalOpen} handleClose={closeModal}>
        <CreateOrEditInvoiceGroupModal onClose={closeModal} />
      </Modal>

      <Modal
        size={MODAL_SIZE.MEDIUM}
        isOpen={showArchiveModal}
        handleClose={handleArchiveModalClose}
      >
        {groupToArchive && (
          <ArchiveInvoiceGroupModal
            group={groupToArchive}
            handleClose={handleArchiveModalClose}
          />
        )}
      </Modal>

      <Modal isOpen={showEditModal} handleClose={handleEditModalClose}>
        {groupToEdit && (
          <CreateOrEditInvoiceGroupModal
            group={groupToEdit}
            onClose={handleEditModalClose}
          />
        )}
      </Modal>
    </div>
  )
}
