import { useHotSettings } from '@traba/hooks'
import { DotMenu, MODAL_SIZE } from '@traba/react-components'
import { UserAccessLevel } from '@traba/types'
import React, { useCallback, useState } from 'react'
import { Modal } from 'src/components'
import { useUser, useUserCanManageUsers } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { UserWithRole } from 'src/types'
import { ArchiveUserModal } from '../Modals/ArchiveUserModal'
import { ChangeMemberRoleModal } from '../Modals/ChangeMemberRoleModal'
import { DeactivateUserModal } from '../Modals/DeactivateUserModal'
import { EditMemberModal } from '../Modals/EditMemberModal'

interface EditActiveMembersDropdownMenuProps {
  member: UserWithRole
}

export function EditActiveMembersDropdownMenu({
  member,
}: EditActiveMembersDropdownMenuProps) {
  const { hotSettings } = useHotSettings()
  const { user } = useUser()
  const userCanEditActiveMembersAndInvitations = useUserCanManageUsers()

  const [showEditMemberModal, setShowEditMemberModal] = useState(false)
  const [showMemberModal, setShowMemberModal] = useState(false)
  const [showDeactivateModal, setShowDeactivateModal] = useState(false)
  const [showArchiveModal, setShowArchiveModal] = useState(false)

  const onEditMember = useCallback(() => {
    setShowEditMemberModal(true)
    window.analytics.track(`User Clicked Edit Member`, {
      member,
    })
  }, [])

  const onChangeRole = useCallback(() => {
    setShowMemberModal(true)
    window.analytics.track(`User Clicked Change Member Role`, {
      member,
    })
  }, [])

  const onArchive = useCallback(() => {
    setShowArchiveModal(true)
    window.analytics.track(`User Clicked Archive Member`, {
      member,
    })
  }, [])

  const onDeactivate = useCallback(() => {
    setShowDeactivateModal(true)
    window.analytics.track(`User Clicked Deactivate Member`, {
      member,
    })
  }, [])

  const handleEditMemberModalClose = useCallback(() => {
    setShowEditMemberModal(false)
  }, [])

  const handleMemberModalClose = useCallback(() => {
    setShowMemberModal(false)
  }, [])

  const handleDeactivateModalClose = useCallback(() => {
    setShowDeactivateModal(false)
  }, [])

  const handleArchiveModalClose = useCallback(() => {
    setShowArchiveModal(false)
  }, [])

  const menuItems = [
    ...(hotSettings?.enableRegionalAccessPhase2
      ? [
          {
            title: 'Edit member',
            onClick: onEditMember,
          },
        ]
      : [
          {
            title: 'Change role',
            onClick: onChangeRole,
          },
        ]),
    // Only company wide users can deactivate or archive member
    ...(user?.userAccessLevel === UserAccessLevel.COMPANY_WIDE
      ? [
          {
            title: 'Deactivate account',
            onClick: onDeactivate,
          },
          {
            title: 'Archive member',
            onClick: onArchive,
            color: theme.colors.Red60,
          },
        ]
      : []),
  ]

  if (!userCanEditActiveMembersAndInvitations) {
    return null
  }

  return (
    <div>
      <DotMenu
        type="active-members"
        dotMenuKey={member.uid}
        menuItems={menuItems}
      />

      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showEditMemberModal}
        handleClose={handleEditMemberModalClose}
      >
        <EditMemberModal
          handleClose={handleEditMemberModalClose}
          member={member}
        />
      </Modal>
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showMemberModal}
        handleClose={handleMemberModalClose}
      >
        <ChangeMemberRoleModal
          handleClose={handleMemberModalClose}
          member={member}
        />
      </Modal>
      <Modal
        size={MODAL_SIZE.MEDIUM}
        isOpen={showArchiveModal}
        handleClose={handleArchiveModalClose}
      >
        <ArchiveUserModal
          member={member}
          handleClose={handleArchiveModalClose}
        />
      </Modal>
      <Modal
        size={MODAL_SIZE.MEDIUM}
        isOpen={showDeactivateModal}
        handleClose={handleDeactivateModalClose}
      >
        <DeactivateUserModal
          member={member}
          handleClose={handleDeactivateModalClose}
        />
      </Modal>
    </div>
  )
}
