import { SvgIconProps } from '@traba/types'

export default function Filter({
  size,
  color = '#7A8A99',
  strokeWidth = 1.5,
  ...props
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.9882 3H5.87976C4.25428 3 3.30773 4.8362 4.25068 6.16022L8.38005 11.9583C8.86259 12.6359 9.1219 13.447 9.1219 14.2788V18.3293C9.1219 19.8043 10.3176 21 11.7926 21C13.2676 21 14.4633 19.8043 14.4633 18.3293V14.3385C14.4633 13.4698 14.7461 12.6247 15.269 11.931L19.5854 6.20375C20.5787 4.88587 19.6385 3 17.9882 3Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
