import { theme } from '@traba/theme'
import React from 'react'
import Row from './Row'
import { SvgIcon } from './SvgIcon'
import { Text } from './Text'

interface BreadcrumbsProps {
  items: Array<{ label: string; isLink: boolean; onClick?: () => void }>
}

export const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  return (
    <Row alignCenter>
      {items.map((item, i) => {
        return (
          <React.Fragment key={`breadcrumb-item-${i}`}>
            <Text
              variant={item.isLink ? 'link2' : 'h5'}
              onClick={item.onClick || undefined}
              style={{
                cursor: item.isLink ? 'pointer' : 'default',
                marginRight: i !== items.length - 1 ? theme.space.xxs : 0,
                marginLeft: i > 0 ? theme.space.xxs : 0,
              }}
            >
              {item.label}
            </Text>
            {i !== items.length - 1 && (
              <SvgIcon
                name="chevronRight"
                width={theme.space.xs}
                height={theme.space.xs}
                color={theme.colors.MidnightBlue}
              />
            )}
          </React.Fragment>
        )
      })}
    </Row>
  )
}

export default Breadcrumbs
