import { makePlural } from '@traba/string-utils'
import { WorkerShift, WorkerShiftWithWorkerDetails } from '@traba/types'
import { Worker } from '@traba/types'
import { useState } from 'react'
import { Badge, EmptyPlaceholderTile, Input, Row, Text } from 'src/components'
import {
  SelectWorkerCard,
  SelectWorkerCardAction,
} from 'src/components/SelectWorkerCard'
import { WorkerOnShiftBadge } from 'src/components/WorkerOnShiftBadge/WorkerOnShiftBadge'
import { WorkerDetails } from 'src/hooks/useCompanyWorkers'
import { theme } from 'src/libs/theme'
import { formatDateString } from 'src/shared/utils/dateUtils'
import { sortWorkersByFirstNameThenLastName } from 'src/shared/utils/sortUtils'
import { getInitials } from 'src/utils/stringUtils'

type SearchInputProps = {
  searchFilter: string
  setSearchFilter: (value: string) => void
}

export type RemoveWorkersSelectionProps = {
  workerShifts: WorkerShiftWithWorkerDetails[] | undefined
  workersOnBackup?: WorkerShiftWithWorkerDetails[]
  companyWorkers: WorkerDetails[] | undefined
  workersToRemove: WorkerShiftWithWorkerDetails[]
  setWorkersToRemove: React.Dispatch<
    React.SetStateAction<WorkerShiftWithWorkerDetails[]>
  >
}

const SearchInput: React.FC<SearchInputProps> = ({
  searchFilter,
  setSearchFilter,
}) => (
  <Input
    placeholder="Search workers by name..."
    leftIconName="search"
    name="workerSearch"
    containerStyle={{
      margin: 0,
    }}
    type="text"
    defaultValue=""
    width="300px"
    value={searchFilter}
    onChange={(e) => {
      e.preventDefault()
      setSearchFilter(e.target.value)
    }}
    onClear={() => setSearchFilter('')}
  />
)

interface WorkerRowProps {
  workerDetails: WorkerDetails | Worker
  onClickWorker: (workerId: string) => void
  isSelected: boolean
  badge: JSX.Element
}

const WorkerRow = ({
  workerDetails,
  onClickWorker,
  isSelected,
  badge,
}: WorkerRowProps) => {
  const hasShiftHistory = 'shiftHistory' in workerDetails
  const { uid, photoUrl, firstName, lastName } = hasShiftHistory
    ? workerDetails.worker
    : workerDetails

  const initials = getInitials(firstName, lastName)

  const pastShiftsCount = hasShiftHistory
    ? workerDetails.shiftHistory.totalShiftCount
    : 0

  const shiftHistoryText = `${pastShiftsCount} shift${makePlural(
    pastShiftsCount,
  )} with you${
    hasShiftHistory &&
    workerDetails.shiftHistory.mostRecentShift &&
    workerDetails.shiftHistory.mostRecentShiftId &&
    workerDetails.shiftHistory.mostRecentShiftStart
      ? ` • Last shift: ${
          workerDetails.shiftHistory.mostRecentShift.shiftInfo.shiftRole
        } on ${formatDateString(
          workerDetails.shiftHistory.mostRecentShiftStart,
          workerDetails.shiftHistory.mostRecentShift.shiftInfo.timezone,
        )}`
      : ''
  }`

  return (
    <SelectWorkerCard
      onClick={() => onClickWorker(uid)}
      title={`${firstName} ${lastName}`}
      description={shiftHistoryText}
      selected={isSelected}
      photoUrl={photoUrl}
      icon={<Text variant="h5">{initials}</Text>}
      action={SelectWorkerCardAction.Add}
      checkbox
      badge={badge}
    />
  )
}

export const RemoveWorkersSelection = (props: RemoveWorkersSelectionProps) => {
  const {
    workerShifts,
    workersOnBackup,
    companyWorkers,
    workersToRemove,
    setWorkersToRemove,
  } = props
  const [searchFilter, setSearchFilter] = useState('')
  const backupWorkerIds = new Set(
    (workersOnBackup || []).map((w) => w.workerId),
  )

  const handleDismiss = (worker: WorkerShift) => {
    setWorkersToRemove(workersToRemove.filter((w) => w !== worker))
  }

  const handleSelectUnselectWorker = (workerId: string) => {
    if (workersToRemove.some((w) => w.workerId === workerId)) {
      setWorkersToRemove((prevWorkers) =>
        prevWorkers.filter((w) => w.workerId !== workerId),
      )
    } else {
      setWorkersToRemove((prevWorkers) => {
        const workerToAdd = workerShifts?.find((w) => w.workerId === workerId)
        return workerToAdd ? [...prevWorkers, workerToAdd] : prevWorkers
      })
    }
  }

  const workersToRemoveBadges = workersToRemove.map((worker) => (
    <Badge
      key={worker.workerId}
      title={worker.worker.firstName + ' ' + worker.worker.lastName}
      variant="brand"
      dismissible
      onDismiss={() => handleDismiss(worker)}
      style={{
        marginRight: theme.space.xs,
        marginBottom: theme.space.xs,
        borderRadius: 10,
      }}
    />
  ))

  // Filter the allWorkers array based on searchString and selectedWorkerShifts
  const filteredWorkers =
    workerShifts?.filter((workerShift) => {
      const { worker: workerInfo } = workerShift

      const isInSelectedShifts = workersToRemove.some(
        (selectedWorker) => selectedWorker.workerId === workerShift.workerId,
      )

      const name = workerInfo.firstName + ' ' + workerInfo.lastName
      const includesSearchString = name
        .toLowerCase()
        .includes(searchFilter.toLowerCase())

      return isInSelectedShifts || includesSearchString
    }) || []

  filteredWorkers?.sort((a, b) => {
    return sortWorkersByFirstNameThenLastName(a.worker, b.worker)
  })

  return (
    <>
      <Row my={theme.space.sm} wrap>
        {workersToRemoveBadges}
        {workersToRemove.length > 1 && (
          <Text variant="link" onClick={() => setWorkersToRemove([])}>
            Clear all
          </Text>
        )}
      </Row>
      <Row mb={theme.space.sm}>
        <SearchInput
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
        />
      </Row>
      {filteredWorkers.length > 0 ? (
        <div>
          {filteredWorkers.map((workerShift) => {
            const { worker } = workerShift

            // If worker has previously worked for company, pass in those worker details
            const companyWorker = companyWorkers?.find(
              (companyWorker) =>
                companyWorker.worker.workerId === worker.workerId,
            )
            return (
              <WorkerRow
                key={worker.uid}
                workerDetails={companyWorker ?? worker}
                onClickWorker={handleSelectUnselectWorker}
                isSelected={
                  !!workersToRemove.some(
                    (selectedWorker) =>
                      selectedWorker.workerId === workerShift.workerId,
                  )
                }
                badge={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <WorkerOnShiftBadge
                      workerShift={workerShift}
                      isBackup={backupWorkerIds.has(workerShift.workerId)}
                    />
                  </div>
                }
              />
            )
          })}
        </div>
      ) : (
        <EmptyPlaceholderTile
          iconName="info"
          title={
            searchFilter
              ? 'No workers found'
              : "You don't currently have any workers on this shift"
          }
        />
      )}
    </>
  )
}
