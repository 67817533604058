import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  CreateParentInvoiceGroup,
  ParentInvoiceGroup,
  UpdateParentInvoiceGroup,
} from '@traba/types'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'

async function createInvoiceGroup(
  createGroupData: CreateParentInvoiceGroup,
): Promise<ParentInvoiceGroup> {
  const res = await trabaApi.post(
    `invoice-groups/parent-group`,
    createGroupData,
  )
  return res.data
}

async function editInvoiceGroup({
  groupId,
  updateGroupData,
}: {
  groupId: string
  updateGroupData: UpdateParentInvoiceGroup
}): Promise<ParentInvoiceGroup> {
  const res = await trabaApi.patch(
    `invoice-groups/parent-group/${groupId}`,
    updateGroupData,
  )
  return res.data
}

async function getInvoiceGroups(): Promise<ParentInvoiceGroup[]> {
  const response = await trabaApi.get(`invoice-groups/active`)
  return response.data
}

export function useInvoiceGroups() {
  const queryClient = useQueryClient()
  const { showSuccess } = useAlert()

  const {
    data: activeInvoiceGroups,
    isLoading,
    refetch,
  } = useQuery<ParentInvoiceGroup[], Error>(
    ['invoice-groups/active'],
    getInvoiceGroups,
  )

  const createInvoiceGroupMutation = useMutation<
    ParentInvoiceGroup,
    AxiosError,
    CreateParentInvoiceGroup
  >(createInvoiceGroup, {
    onSuccess: (newGroup: ParentInvoiceGroup) => {
      queryClient.setQueryData(
        'invoice-groups/active',
        (currActiveGroups: ParentInvoiceGroup[] | undefined) => {
          return currActiveGroups ? [...currActiveGroups, newGroup] : [newGroup]
        },
      )
    },
  })

  const editInvoiceGroupMutation = useMutation<
    ParentInvoiceGroup,
    AxiosError,
    {
      groupId: string
      updateGroupData: UpdateParentInvoiceGroup
    }
  >(editInvoiceGroup, {
    onSuccess: () => {
      showSuccess('Edit invoice group successful.')
      refetch()
    },
  })

  const getActiveInvoiceGroupById = async (id: string) => {
    const activeGroups = await getInvoiceGroups()
    return activeGroups?.find((group) => group.id === id)
  }

  return {
    activeInvoiceGroups: activeInvoiceGroups || [],
    getActiveInvoiceGroupById,
    isLoading,
    createInvoiceGroup: createInvoiceGroupMutation.mutateAsync,
    editInvoiceGroup: editInvoiceGroupMutation.mutateAsync,
    refetch,
  }
}
