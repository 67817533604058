import { SvgIconProps } from 'src/types/svg-types'

export default function Left({
  size,
  color,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <svg viewBox="0 0 16 16" width={size} height={size} fill="none" {...rest}>
      <path
        d="M11 13L6 8L11 3"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
