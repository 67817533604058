import { Shift } from '@traba/types'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { ShiftAndAddress } from 'src/hooks/useShifts'

export function replaceStartTimeWithBusinessStartTime(shift: Shift) {
  const { businessStartTime } = shift
  if (!businessStartTime) {
    return shift
  }
  return { ...shift, startTime: businessStartTime }
}

export function replaceStartTimeWithBusinessStartTimeForShifts(
  shifts: ShiftAndAddress[] | undefined,
) {
  return shifts ? shifts.map(replaceStartTimeWithBusinessStartTime) : undefined
}

export function getEarlyArrivalTimeBufferInMinutes(shift: Shift) {
  const bizStartTime = shift.businessStartTime
  if (!bizStartTime) {
    return undefined
  }
  return differenceInMinutes(bizStartTime, shift.startTime)
}
