import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import { Connection, ConnectionReason, ConnectionType } from '@traba/types'
import { AxiosError } from 'axios'
import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { CreateConnection } from 'src/types'

export interface BizToWorkerConnection {
  isLoading: boolean
  isError: boolean
  error: Error | null
  isBlocked: (workerId: string) => boolean
  isFavorite: (workerId: string) => boolean
  isIneligible: (workerId: string) => boolean
  isScheduledBlock: (workerId: string) => boolean
  getWorkerConnections: (workerId: string) => Connection[]
  removeConnection: UseMutateFunction<
    Connection,
    AxiosError<any>,
    { workerId: string; connectionType: ConnectionType },
    Connection
  >
  blockWorker: (
    workerId: string,
    connectionReasons: ConnectionReason[],
  ) => Promise<Connection>
  favoriteWorker: (workerId: string) => Promise<Connection>
  blockedWorkers: string[]
  favoriteWorkers: string[]
  scheduledBlockWorkers: string[]
  connections: Connection[] // You may want to specify the type of the 'connections' array
}

const getConnections = async () => {
  try {
    const response = await trabaApi.get(`/connections/company`)
    return response.data
  } catch (error) {
    console.log('Error retrieving connections', error)
  }
}
export const useConnections = (): BizToWorkerConnection => {
  const queryClient = useQueryClient()
  const { showSuccess, handleError } = useAlert()
  const {
    isLoading,
    isError,
    data: connections,
    error,
  } = useQuery<Connection[], Error>('connections', getConnections, {
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const getConnectionsByType = (connectionType: ConnectionType) =>
    connections
      ?.filter((connection) => connection.connectionType === connectionType)
      .map((conn) => conn.workerId) || []

  const createConnection = useMutation<
    Connection,
    AxiosError,
    CreateConnection,
    Connection
  >(
    async (updatedUser) => {
      const response = await trabaApi.post(`/connections/worker`, updatedUser)
      return response.data
    },
    {
      onSuccess: (response: Connection) => {
        queryClient.setQueryData('connections', (currentConnections: any) => {
          const connectionIndex = currentConnections.findIndex(
            (connection: Connection) =>
              connection.workerId === response.workerId,
          )
          if (connectionIndex > -1) {
            currentConnections[connectionIndex] = response
            return [...currentConnections]
          } else {
            return [...currentConnections, response]
          }
        })
        const newConnectionType = response.connectionType
        if (newConnectionType === ConnectionType.SCHEDULED_BLOCK) {
          showSuccess(
            'The worker will not be informed of the block until the end of the day. Please exercise caution discussing the block with the worker during the shift to prevent any unintended reactions.',
            'Worker block has been scheduled.',
          )
        }
      },
      onError: (error: Error) => {
        // TODO: remove this after backend which allows blocking during shift is deployed
        const message =
          error?.message === 'connections/ongoing-shift'
            ? 'This worker is currently on-shift. Please try again later.'
            : 'There was an error creating the connection. Please try again or contact support if the issue persists.'
        handleError(
          error,
          'useConnections -> createConnection()',
          message,
          'Error creating connection',
        )
      },
    },
  )

  const removeConnection = useMutation<
    Connection,
    AxiosError,
    { workerId: string; connectionType: ConnectionType },
    Connection
  >(
    (props) =>
      trabaApi.delete(
        `/connections/worker/?workerId=${props.workerId}&connectionType=${props.connectionType}`,
      ),
    {
      onSuccess: (response: any, values: any) => {
        queryClient.setQueryData('connections', (currentConnections: any) =>
          currentConnections.filter(
            (connections: Connection) =>
              connections.workerId !== values.workerId,
          ),
        )
      },
      onError: (error) => {
        handleError(
          error,
          'useConnections -> removeConnection()',
          'Please try again or contact support if the issue persists.',
          'An error has occurred',
        )
      },
    },
  )

  const blockedWorkers = getConnectionsByType(ConnectionType.BLOCK)
  const scheduledBlockWorkers = getConnectionsByType(
    ConnectionType.SCHEDULED_BLOCK,
  )
  const favoriteWorkers = getConnectionsByType(ConnectionType.FAVORITE)
  const ineligibleWorkers = getConnectionsByType(ConnectionType.INELIGIBLE)

  const hasConnection = (workerId: string, connectionType: ConnectionType) => {
    let workers: string[] = []
    switch (connectionType) {
      case ConnectionType.BLOCK:
        workers = blockedWorkers
        break
      case ConnectionType.FAVORITE:
        workers = favoriteWorkers
        break
      case ConnectionType.INELIGIBLE:
        workers = ineligibleWorkers
        break
      case ConnectionType.SCHEDULED_BLOCK:
        workers = scheduledBlockWorkers
        break
      default:
        break
    }
    return workers.findIndex((w: string) => w === workerId) > -1
  }

  return {
    isLoading,
    isError,
    error,
    isBlocked: (workerId: string) =>
      hasConnection(workerId, ConnectionType.BLOCK),
    isFavorite: (workerId: string) =>
      hasConnection(workerId, ConnectionType.FAVORITE),
    isIneligible: (workerId: string) =>
      hasConnection(workerId, ConnectionType.INELIGIBLE),
    isScheduledBlock: (workerId: string) =>
      hasConnection(workerId, ConnectionType.SCHEDULED_BLOCK),
    getWorkerConnections: (workerId: string) =>
      connections?.filter((c) => c.workerId === workerId) ?? [],
    removeConnection: removeConnection.mutate,
    blockWorker: (workerId: string, connectionReasons: ConnectionReason[]) =>
      createConnection.mutateAsync({
        workerId,
        connectionType: ConnectionType.BLOCK,
        connectionReasons,
      }),
    favoriteWorker: (workerId: string) =>
      createConnection.mutateAsync({
        workerId,
        connectionType: ConnectionType.FAVORITE,
      }),
    blockedWorkers,
    favoriteWorkers,
    scheduledBlockWorkers,
    connections: connections || [],
  }
}
