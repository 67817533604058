import { memo } from 'react'
import DesktopWorkersOnShiftTable from './DesktopWorkersOnShiftTable'
import MobileWorkersOnShiftTable from './MobileWorkersOnShiftTable'
import {
  WorkersOnShiftTableAdditionalProps,
  WorkersOnShiftTableProps,
  useWorkersOnShiftTable,
} from './WorkersOnShiftTable.hooks'

const WorkersOnShiftTableUI: React.FC<
  WorkersOnShiftTableAdditionalProps & Partial<WorkersOnShiftTableProps>
> = (props) => {
  const logic = useWorkersOnShiftTable({
    shift: props.shift,
    isUpcoming: props.isUpcoming || false,
  })
  const { isReactNativeApp } = props

  return isReactNativeApp ? (
    <MobileWorkersOnShiftTable {...logic} {...props} />
  ) : (
    <DesktopWorkersOnShiftTable {...logic} {...props} />
  )
}

export const WorkersOnShiftTable = memo(WorkersOnShiftTableUI)
