import { LoadingSpinner, SvgIcon } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Badge } from 'src/components/base'

interface Props {
  onRefresh: () => void
  isRecalculating: boolean
  isRefetching?: boolean
}

export const TimeSheetsChargeRecalculationBadge = ({
  isRecalculating,
  onRefresh,
  isRefetching,
}: Props) => {
  const title = isRecalculating
    ? 'Some charges are being recalculated'
    : 'All charges up to date'
  return (
    <>
      <Badge
        title={title}
        iconName={isRecalculating ? 'warning' : 'greenCheck'}
        variant={isRecalculating ? 'warning' : 'success'}
        displayIconOnRight={true}
        style={{ marginRight: isRecalculating ? theme.space.xxs : 0 }}
      />
      {isRecalculating &&
        (isRefetching ? (
          <LoadingSpinner style={{ height: 20, width: 20 }} />
        ) : (
          <SvgIcon name="refresh" onClick={onRefresh} size={16} />
        ))}
    </>
  )
}
