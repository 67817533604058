import { theme } from 'src/libs/theme'
import styled from 'styled-components'

export const Label = styled.label<{ inlineLabel: boolean }>`
  top: ${({ inlineLabel }) => (inlineLabel ? '-10px' : '0')};
  left: ${({ inlineLabel }) => (inlineLabel ? '12px' : '0')};
  position: ${({ inlineLabel }) => (inlineLabel ? 'absolute' : 'relative')};
  background-color: white;
  padding: 0px 5px 0px 5px;
  transition: 0.2s;
  pointer-events: none;
  font-size: ${theme.default.fontSize};
  font-weight: 500;
  z-index: 1;
  color: ${theme.colors.Grey60};
`

export const ClearIconContainer = styled.div<any>`
  display: flex;
  height: 20px;
  width: 20px;
  align-items: center;
  align-self: center;
  color: ${theme.colors.White};
  background-color: ${theme.colors.Violet40};
  border-color: ${theme.colors.Violet40};
  margin-right: ${theme.space.xxxs}px;
  border-radius: 100%;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.Violet};
    border-color: ${theme.colors.Violet};
  }
`
