import { Col, Row } from '@traba/react-components'
import React from 'react'
import { Button, ButtonVariant, Text } from 'src/components'
import { theme } from 'src/libs/theme'

interface MembersSubheaderProps {
  title: string
  description: string
  action?: string
  onClick?: () => void
  userCanTakeAction?: boolean
}

export function MembersSubheader({
  title,
  description,
  action,
  userCanTakeAction,
  onClick,
}: MembersSubheaderProps) {
  return (
    <Row alignStart justifyBetween gap={theme.space.sm}>
      <Col gap={theme.space.xxs}>
        <Text variant="h5">{title}</Text>
        <Text variant="body2">{description}</Text>
      </Col>
      {action && userCanTakeAction && (
        <Button
          variant={ButtonVariant.FILLED}
          onClick={onClick}
          disabled={!onClick}
        >
          {action}
        </Button>
      )}
    </Row>
  )
}
