import { AuthScreenWrapper } from 'src/components/auth'
import { Row, Text } from 'src/components/base'
import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'

export default function RequireInvitationScreen() {
  const { isMobileViewOrReactNative } = useMobile()

  const leftCol = (
    <>
      <Text
        variant={isMobileViewOrReactNative ? 'h3' : 'h2'}
        mt={theme.space.med}
      >
        Invitation is required
      </Text>
      <Row mt={theme.space.sm} mb={theme.space.lg}>
        <Text
          variant={isMobileViewOrReactNative ? 'h5' : 'h4'}
          style={{ lineHeight: '1.75' }}
        >
          Your admin needs to assign a role to you. Please contact one of the
          Traba admin users for your company to send you an invitation.
        </Text>
      </Row>
    </>
  )
  return (
    <AuthScreenWrapper
      leftCol={leftCol}
      helmetTitle="Traba - Require Invitation"
    />
  )
}
