export const MEMBERS_COPY = {
  ACTIVE_MEMBERS_TITLE: 'Active members',
  ACTIVE_MEMBERS_EDITOR_DESCRIPTION:
    'Manage your company’s members below. You may update their roles or remove them entirely.',
  ACTIVE_MEMBERS_VIEWER_DESCRIPTION: 'View your company’s members below.',
  ACTIVE_MEMBERS_ACTION: 'Invite member',
  INVITATIONS_TITLE: 'Invitations',
  INVITATIONS_EDITOR_DESCRIPTION:
    'Manage your company’s invitations below. You may resend or revoke outstanding invitations. Invitations expire a week after they‘re sent.',
  INVITATIONS_VIEWER_DESCRIPTION: 'View your company’s invitations below.',
  CONTACTS_TITLE: 'Additional contacts',
  CONTACTS_EDITOR_DESCRIPTION:
    'Manage your company’s additional contacts below. They do not have Traba accounts but may be listed as the onsite point of contact for shifts.',
  CONTACTS_VIEWER_DESCRIPTION:
    'View your company’s additional contacts below. They do not have Traba accounts but may be listed as the onsite point of contact for shifts.',
  CONTACTS_ACTION: 'Add contact',
}
