import { Alert, AlertTitle } from '@mui/material'
import Fade from '@mui/material/Fade'
import { useAlert } from '@traba/context'
import { useEffect } from 'react'

import * as S from './Tooltip.styles'

export const TooltipModal = () => {
  const { state, cancelAlert } = useAlert()
  const { active, severity, message, title, durationInMs } = state

  useEffect(() => {
    if (active) {
      setTimeout(() => {
        cancelAlert()
      }, durationInMs)
    }
  }, [active])

  return (
    <S.TooltipContainer>
      <Fade in={active}>
        <Alert
          severity={severity}
          style={{ justifyContent: 'center', margin: 10 }}
        >
          {title ? <AlertTitle>{title}</AlertTitle> : null}
          {message}
        </Alert>
      </Fade>
    </S.TooltipContainer>
  )
}
