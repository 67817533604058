import { SettingsTab } from './types'

export function getInitialTabIndex(search: string, activeTabs: SettingsTab[]) {
  const query = new URLSearchParams(search)
  const tabQuery = query.get('tab')
  if (!tabQuery) {
    return 0
  }
  const index = activeTabs.findIndex((t) => t.key === tabQuery.toLowerCase())
  if (index === -1) {
    return 0
  }
  return index
}
