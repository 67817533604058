import { theme } from 'src/libs/theme'
import styled from 'styled-components'

import { Row, Text } from '../base'

export const SectionHeader = styled(Text)`
  padding-bottom: ${theme.space.xs}px;
  width: 100%;
  min-width: 300px;
  @media only screen and (min-width: ${({ theme }) => theme.media.minLarge}) {
    width: 479px;
  }
`

export const SimpleCardContainer = styled.div`
  display: flex;
  background: #ffffff;
  justify-content: space-between;
  border: 1px solid #e2e6e9;
  border-radius: 8px;
  width: 100%;
  padding: ${theme.space.xs}px ${theme.space.sm}px;
  margin: ${theme.space.xxs}px 0px 0px 0px;
  min-height: 75px;
  min-width: 300px;
  @media only screen and (min-width: ${({ theme }) => theme.media.minLarge}) {
    width: 479px;
  }
`

export const AttributeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const LeftColumn = styled.div`
  flex: 1 1 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const RightColumn = styled(Row)`
  flex: 1 1 20%;
`
interface InfoIconProps {
  hover?: boolean
}

export const InfoIcon = styled.div<InfoIconProps>`
  border-radius: ${theme.space.xxs}px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;

  ${({ hover }) =>
    hover &&
    `
    :hover {
      background-color: ${theme.colors.Grey10};
    }
  `}
`

InfoIcon.defaultProps = {
  hover: true,
}
