import { useAlert } from '@traba/context'
import { ModalPaddingContainer } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { LocationResponse } from '@traba/types'
import { useState } from 'react'
import { Button, ButtonVariant, Row, Text } from 'src/components/base'
import { Location } from 'src/components/Location'
import { ButtonIcon } from 'src/components/PersonalProfile/PersonalProfile.styles'
import { useLocations } from 'src/hooks/useLocations'
import { theme } from 'src/libs/theme'

export type ArchiveLocationModalProps = {
  location: LocationResponse
  handleClose: () => void
}

export const ArchiveLocationModal = (props: ArchiveLocationModalProps) => {
  const { archiveLocation } = useLocations()
  const { showError } = useAlert()
  const [loading, setLoading] = useState(false)

  const handleArchiveClick = async () => {
    setLoading(true)
    await archiveLocation(props.location.locationId, {
      onSettled: () => {
        setLoading(false)
        props.handleClose()
      },
      onError: (err) => {
        if (err.message === 'archive/active-shifts') {
          showError(
            'Unfortunately, you cannot archive a location associated with a current or upcoming shift. Please reach out to support if you need this location archived.',
            'Unable to archive location',
          )
        } else {
          showError(
            'An error has occurred. Please try again or contact support if the issue persists.',
            'Unable to archive location',
          )
        }
      },
    })
    window.analytics.track(`User Archived Location`, {
      location: props.location,
    })
  }

  return (
    <ModalPaddingContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Text variant="h2">Archive Location</Text>
        <ButtonIcon name="cancel" onClick={() => props.handleClose()} />
      </div>
      <Text variant="body2">
        Archiving a location will remove it from the dropdown list when creating
        a shift. However, the location information will continue to show up for
        any previous shift it was tied to.
      </Text>

      <Text
        style={{ marginTop: theme.space.xs, marginBottom: theme.space.xxs }}
        variant="h5"
      >
        Location to Archive
      </Text>
      <Card
        style={{
          marginTop: '8px',
          width: '100%',
          display: 'flex',
        }}
      >
        <Location location={props.location} />
      </Card>
      <Row justifyBetween pt={theme.space.med}>
        <Button
          type="button"
          variant={ButtonVariant.OUTLINED}
          onClick={() => props.handleClose()}
        >
          Back
        </Button>
        <Button
          variant={ButtonVariant.CANCEL}
          onClick={handleArchiveClick}
          loading={loading}
        >
          Archive
        </Button>
      </Row>
    </ModalPaddingContainer>
  )
}
