import styled from 'styled-components'
import { Button } from '../Button/Button'

export const MediaUploaderLabel = styled.label`
  border-radius: 8px;
  line-height: 24px;
  max-height: 48px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 16px;
  background: #fff;
  color: #08105e;
  border: 1px solid ${({ theme }) => theme?.BorderColor};
  box-shadow: 0px 1px 2px rgba(196, 204, 212, 0.5);
  cursor: pointer;

  img {
    margin-right: 5px;
  }

  :hover {
    border: 1px solid ${({ theme }) => theme?.HoverBorderColor};
  }
`

export const ImagePreviewWrapper = styled.div`
  height: 48px;
  width: 48px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 16px;
`

export const ImagePreview = styled.img`
  object-fit: cover;
  width: 50px;
  height: 50px;
`

export const LargeImagePreviewWrapper = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 16px;
  width: 100%;
  height: 100%;
`

export const LargeImagePreview = styled.img`
  object-fit: cover;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 2500px;
`

export const FileUploadInput = styled.input`
  display: none;
`

export const DeleteButton = styled(Button)`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.space.xs}px;
`
