import { addPixelUnit } from '@traba/theme'
import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

export type RowProps = {
  mt?: string | number
  mb?: string | number
  mr?: string | number
  ml?: string | number
  my?: string | number
  mx?: string | number
  m?: string | number
  pt?: string | number
  pb?: string | number
  pr?: string | number
  pl?: string | number
  py?: string | number
  px?: string | number
  p?: string | number
  gap?: string | number
  center?: boolean
  justifyBetween?: boolean
  justifyEnd?: boolean
  justifyStart?: boolean
  justifyCenter?: boolean
  justifyEvenly?: boolean
  alignCenter?: boolean
  alignStart?: boolean
  alignEnd?: boolean
  flexCol?: boolean
  fullHeight?: boolean
  fullWidth?: boolean
  children: React.ReactNode
  style?: HTMLAttributes<HTMLDivElement>['style']
  className?: string
  card?: boolean
  onClick?: any
  wrap?: boolean
  ref?: React.MutableRefObject<HTMLDivElement | null>
}

const StyledRow = styled.div<RowProps>`
  display: flex;
  gap: ${(props) => addPixelUnit(props.gap ?? props.gap ?? 0)};

  /* Margins */
  margin-top: ${(props) => addPixelUnit(props.mt ?? props.my ?? props.m ?? 0)};
  margin-bottom: ${(props) =>
    addPixelUnit(props.mb ?? props.my ?? props.m ?? 0)};
  margin-left: ${(props) => addPixelUnit(props.ml ?? props.mx ?? props.m ?? 0)};
  margin-right: ${(props) =>
    addPixelUnit(props.mr ?? props.mx ?? props.m ?? 0)};

  /* Paddings */
  padding-top: ${(props) => addPixelUnit(props.pt ?? props.py ?? props.p ?? 0)};
  padding-bottom: ${(props) =>
    addPixelUnit(props.pb ?? props.py ?? props.p ?? 0)};
  padding-left: ${(props) =>
    addPixelUnit(props.pl ?? props.px ?? props.p ?? 0)};
  padding-right: ${(props) =>
    addPixelUnit(props.pr ?? props.px ?? props.p ?? 0)};

  /* Flex-related */
  ${(props) => props.center && `justify-content: center;`}
  ${(props) => props.justifyBetween && `justify-content: space-between;`}
  ${(props) => props.justifyStart && `justify-content: flex-start;`}
  ${(props) => props.justifyEnd && `justify-content: end;`}
  ${(props) => props.justifyCenter && `justify-content: center;`}
  ${(props) => props.justifyEvenly && `justify-content: space-evenly;`}
  ${(props) => props.alignCenter && `align-items: center;`}
  ${(props) => props.alignEnd && `align-items: flex-end;`}
  ${(props) => props.alignStart && `align-items: flex-start;`}
  ${(props) => props.flexCol && `flex-direction: column;`}
  ${({ card, theme }) =>
    card &&
    css`
      background-color: #fff;
      border: 1px solid ${theme.border.color};
      border-radius: ${theme.border.radius};
      padding: 8px 16px;
    `}
  ${(props) => props.fullHeight && `height: 100%;`}
  ${(props) => props.fullWidth && `width: 100%;`}
  ${(props) => props.wrap && `flex-wrap: wrap`}
`

export default function Row({ className = '', ...props }: RowProps) {
  return <StyledRow {...props} className={`Row ${className}`} />
}
