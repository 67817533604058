import { trabaApi } from '@traba/api-utils'
import {
  WorkerShift,
  AdjustmentSource,
  RecalculatedWorkedTime,
} from '@traba/types'
import { useQuery } from 'react-query'

export const getWorkerEarningEstimate = async (
  shiftId: string,
  editedWorkerShift: Partial<WorkerShift>,
): Promise<
  (RecalculatedWorkedTime & { shouldAutoApprove: boolean }) | undefined
> => {
  try {
    const response = await trabaApi.post(
      `/my-company/worker-shifts/${shiftId}/worker-shift-estimate`,
      {
        ...editedWorkerShift,
        adjustmentReason: 'estimate',
        source: AdjustmentSource.BUSINESS,
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const useWorkerEarningEstimate = ({
  workerShift,
  enabled,
}: {
  workerShift: WorkerShift
  enabled: boolean
}) => {
  const { isLoading, data } = useQuery<
    RecalculatedWorkedTime | undefined,
    Error
  >(
    [workerShift],
    () => getWorkerEarningEstimate(workerShift.shiftId, workerShift),
    { enabled },
  )

  return {
    isLoading,
    data,
  }
}
