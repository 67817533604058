import { theme } from '@traba/theme'
import { UserRole, USER_ROLE_NAMES } from '@traba/types'
import React from 'react'
import { Card, CardTile } from '../../base-components/Card/Card'
import { Col } from '../../base-components/Col'
import Row from '../../base-components/Row'
import { SvgIcon } from '../../base-components/SvgIcon'
import { Text } from '../../base-components/Text'

export interface MemberCardProps {
  firstName?: string
  lastName?: string
  email?: string
  role?: UserRole
}

export function MemberCard({
  firstName,
  lastName,
  email = '--',
  role,
}: MemberCardProps) {
  const roleName = role ? USER_ROLE_NAMES[role] : '--'

  return (
    <Card>
      <Row alignCenter>
        <CardTile size="56px">
          <SvgIcon name="user" color={theme.colors.Grey50} />
        </CardTile>
        <Col pl={24} width="100%">
          <Text variant="h5">
            {firstName} {lastName}
          </Text>
          <Row>
            <Text variant="body2">Email: {email}</Text>
          </Row>
          <Row>
            <Text variant="body2">Role: {roleName}</Text>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}
