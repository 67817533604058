import React from 'react'
import { Text } from 'src/components/base'
import { getShiftNumericDateAndTimeString } from 'src/shared/utils/dateUtils'

export const ShiftDateAndTime = ({
  startTime,
  endTime,
  timezone,
  additionalOptions,
  prefixString,
  suffixString,
}: {
  startTime: Date
  endTime: Date
  timezone: string
  additionalOptions?: Intl.DateTimeFormatOptions
  prefixString?: string
  suffixString?: string
}) => {
  return (
    <Text variant="body1">
      {prefixString}
      {getShiftNumericDateAndTimeString({
        startTime,
        endTime,
        timezone,
        dateOptions: {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          ...additionalOptions,
        },
      })}
      {suffixString}
    </Text>
  )
}
