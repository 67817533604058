import { useAlert } from '@traba/context'
import { makePlural } from '@traba/string-utils'
import { Roster } from '@traba/types'
import { useState } from 'react'
import { useRosters } from 'src/hooks/useRosters'
import { theme } from 'src/libs/theme'
import { Button, ButtonVariant, Col, Input, Row, SvgIcon, Text } from '../base'
import * as S from './AddToRosterMenu.styles'

function RosterRow({
  roster,
  onClickRoster,
  isDisabled,
  isSelected,
}: {
  roster: Roster
  onClickRoster: (rosterId: string) => void
  isDisabled: boolean
  isSelected: boolean
}) {
  return (
    <S.RosterCard
      isClickable
      onClick={() => onClickRoster(roster.id)}
      style={{ justifyContent: 'center' }}
      disabled={isDisabled}
    >
      <Row justifyBetween alignCenter>
        <Col style={{ flex: 1, marginRight: theme.space.xxs }}>
          <Text variant="h6">{roster.rosterName}</Text>
          <Text variant="body2">{`${roster.workers.length} Workers`}</Text>
        </Col>
        <SvgIcon
          size={28}
          name={isDisabled || isSelected ? 'addInverse' : 'add'}
          color={isDisabled ? theme.colors.Grey30 : theme.colors.brand}
        />
      </Row>
    </S.RosterCard>
  )
}

export function AddToRosterView({
  searchFilter,
  setSearchFilter,
  workerIds,
  handleClose,
  onClickNewRoster,
  onClickAdd,
}: {
  searchFilter: string
  setSearchFilter: (s: string) => void
  workerIds: string[]
  handleClose: () => void
  onClickNewRoster?: (workerIds: string[]) => void
  onClickAdd?: () => void
}) {
  const { handleError, showSuccess } = useAlert()
  const { rosters, addWorkersToRosters } = useRosters()
  const [addButtonLoading, setAddButtonLoading] = useState(false)
  const workerIdsSet = new Set(workerIds)
  const [selectedRosters, setSelectedRosters] = useState(new Set<string>())

  function doClose() {
    setSelectedRosters(new Set())
    handleClose()
  }

  const disabledRostersSet =
    workerIdsSet.size < 2
      ? new Set(
          rosters
            ?.filter(
              (roster) =>
                roster.workers.findIndex((w) => workerIdsSet.has(w.workerId)) >=
                0,
            )
            .map((roster) => roster.id),
        )
      : workerIdsSet

  const addToRosters = async (workerIds: string[], rosterIds: string[]) => {
    window.analytics?.track(`User Clicked Add To Rosters`, {
      workerIds,
      rosterIds,
    })
    setAddButtonLoading(true)
    addWorkersToRosters &&
      addWorkersToRosters(
        { rosterIds, workerIds },
        {
          onError: (error) => {
            const message =
              'There was an error adding the worker to these rosters. Please try again or contact support if the issue persists.'
            handleError(
              error,
              'addToRosters -> addWorkersToRosters()',
              message,
              'Error updating rosters',
            )
          },
        },
      )
    setAddButtonLoading(false)
    setSelectedRosters(new Set<string>())
    onClickAdd && onClickAdd()
    showSuccess('', `Worker${makePlural(workerIds)} added to roster!`)
    handleClose()
  }

  const onClickRoster = (rosterId: string) => {
    const newRosters = new Set(selectedRosters)
    let selected = false
    if (selectedRosters.has(rosterId)) {
      newRosters.delete(rosterId)
    } else {
      selected = true
      newRosters.add(rosterId)
    }
    window.analytics?.track(
      `User ${selected ? 'Selected' : 'Unselected'} Roster To Add`,
      {
        workerIds,
        rosterId,
      },
    )
    setSelectedRosters(newRosters)
  }
  const filteredRosters = searchFilter.length
    ? rosters?.filter((r) => new RegExp(searchFilter, 'i').test(r.rosterName))
    : rosters

  return (
    <div>
      <S.AddToRosterMenuHeader
        justifyBetween
        style={{
          padding: theme.space.xs,
          borderBottom: `1px solid ${theme.colors.Grey20}`,
        }}
      >
        <Text variant="h6">{`Add worker${makePlural(
          workerIds,
        )} to Roster${makePlural(Array.from(selectedRosters.values()))} (${
          selectedRosters.size
        })`}</Text>
        <SvgIcon
          name="cancel"
          color={theme.colors.Grey50}
          onClick={handleClose}
        />
      </S.AddToRosterMenuHeader>
      <S.RostersContainer>
        {rosters && rosters.length ? (
          <div>
            <Input
              placeholder="Search..."
              name="rosterSearch"
              containerStyle={{
                marginTop: 0,
                marginBottom: theme.space.xxs,
              }}
              type="text"
              defaultValue=""
              width="100%"
              value={searchFilter}
              onChange={(e) => {
                e.preventDefault()
                setSearchFilter(e.target.value)
              }}
              onClear={() => setSearchFilter('')}
            />
            {filteredRosters?.map((roster) => (
              <RosterRow
                key={roster.id}
                roster={roster}
                isDisabled={disabledRostersSet.has(roster.id)}
                isSelected={selectedRosters.has(roster.id)}
                onClickRoster={onClickRoster}
              />
            ))}
          </div>
        ) : (
          <Text variant="body2" color={theme.colors.MidnightBlue}>
            {`Create a new roster in order to add this worker.`}
          </Text>
        )}
      </S.RostersContainer>
      <S.AddToRosterMenuFooter justifyEnd>
        <Button
          loading={addButtonLoading}
          slim
          variant={ButtonVariant.OUTLINED}
          onClick={() => {
            window.analytics?.track(
              `User Clicked New Roster From Worker Menu`,
              {
                workerIds,
              },
            )
            onClickNewRoster && onClickNewRoster(workerIds)
            doClose()
          }}
        >
          New Roster
        </Button>
        <Button
          slim
          variant={ButtonVariant.FILLED}
          style={{ marginLeft: theme.space.xxs }}
          onClick={() => addToRosters(workerIds, Array.from(selectedRosters))}
          disabled={!selectedRosters.size}
        >
          Add
        </Button>
      </S.AddToRosterMenuFooter>
    </div>
  )
}
