import { LinkText } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Shift } from '@traba/types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ShiftStatusBadge } from 'src/components/ShiftBadges/ShiftStatusBadge'
import { Col, Icon, Row, Text } from '../../base'
import { Td, Tr } from '../../Table/Table'
import { ShiftDateAndTime } from '../../UpcomingShiftDetails/components/ShiftDateAndTime'

interface ShiftHeaderProps {
  shift: Shift
  isMobileViewOrReactNative?: boolean
  isExpanded: boolean
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
}

export const TimeSheetsTableShiftHeaderListItem = (props: ShiftHeaderProps) => {
  const { shift, isMobileViewOrReactNative, isExpanded, setIsExpanded } = props
  const showShiftStatusBadge = shift.endTime > new Date()
  const formattedStartTime = (
    shift.businessStartTime ?? shift.startTime
  ).toLocaleString('en-US', {
    timeZone: shift.timezone,
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  })
  const navigate = useNavigate()

  if (isMobileViewOrReactNative) {
    return (
      <Row
        alignCenter
        px={theme.space.sm}
        py={theme.space.xs}
        style={{ border: `1px solid ${theme.colors.Grey20}` }}
      >
        <Text
          variant="h5"
          mr={theme.space.xxs}
        >{`${shift.shiftRole}, ${formattedStartTime}`}</Text>
        {isMobileViewOrReactNative && (
          <Icon
            name={isExpanded ? 'chevronUp' : 'chevronDown'}
            width={16}
            height={16}
            onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
            style={{ marginLeft: theme.space.xs }}
          />
        )}
      </Row>
    )
  }

  return (
    <React.Fragment key={shift.shiftId}>
      <Tr style={{ height: 66 }}>
        <Td
          style={{
            alignItems: 'center',
            justifyItems: 'center',
            whiteSpace: 'nowrap',
          }}
          colSpan={6}
        >
          <Col>
            <Row alignCenter>
              <LinkText
                variant="h5"
                mr={theme.space.xxs}
                onClick={() => {
                  navigate(`/calendar/${shift.id}`)
                }}
              >{`${shift.shiftRole}`}</LinkText>
              <ShiftDateAndTime
                startTime={shift.businessStartTime ?? shift.startTime}
                endTime={shift.endTime}
                timezone={shift.timezone}
                additionalOptions={{
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                  weekday: undefined,
                }}
                prefixString={
                  shift.shortLocation ? `(${shift.shortLocation}, ` : '('
                }
                suffixString=")"
              />
              {showShiftStatusBadge && (
                <ShiftStatusBadge
                  shiftStartTime={shift.businessStartTime ?? shift.startTime}
                  shiftEndTime={shift.endTime}
                  shiftIsCanceled={false}
                  style={{
                    position: 'relative',
                    borderRadius: 10,
                    marginLeft: theme.space.xs,
                  }}
                />
              )}
            </Row>
          </Col>
        </Td>
      </Tr>
    </React.Fragment>
  )
}
