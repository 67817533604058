import { Tooltip } from '@mui/material'
import { Button, SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { COMPLETED_JOB_STATUSES_FOR_TIMESHEETS } from '@traba/types'
import { useState } from 'react'
import { ButtonVariant, Col } from 'src/components/base'
import Row from 'src/components/base/Row'
import { WorkerOnShiftTableBreaksField } from 'src/components/WorkersOnShiftTable/components/WorkerOnShiftTableBreaksField'
import { WorkerOnShiftTableClockInField } from 'src/components/WorkersOnShiftTable/components/WorkerOnShiftTableClockInField'
import { WorkerOnShiftTableClockOutField } from 'src/components/WorkersOnShiftTable/components/WorkerOnShiftTableClockOutField'
import { WorkerOnShiftTableTotalWorked } from 'src/components/WorkersOnShiftTable/components/WorkerOnShiftTableTotalWorked'
import { WorkerPhotoAndName } from 'src/components/WorkersOnShiftTable/components/WorkerPhotoAndName'
import { useWorkerShiftMutations } from 'src/hooks/workerShiftHooks'
import { TimesheetsGroupBy } from 'src/screens/TimeSheets/types'
import {
  BaseTimeSheetsTableWorkerShiftListItemProps,
  ChargeApprovalAction,
} from '../types'

export const MobileTimeSheetsTableWorkerShiftListItem = (
  props: BaseTimeSheetsTableWorkerShiftListItemProps,
) => {
  const {
    workerShift,
    worker,
    onEditWorkerShift,
    refetchWorkerShifts,
    approveWorkerShifts,
    unapproveWorkerShifts,
    userCanManageTimesheets,
    userCanManagePaymentSettings,
    groupBy,
  } = props
  const [isUpdatingApproval, setIsUpdatingApproval] = useState(false)
  const { isApproved, isInvoiced } = workerShift
  const isWorkerShiftComplete = COMPLETED_JOB_STATUSES_FOR_TIMESHEETS.includes(
    workerShift.jobStatus,
  )
  const { startTime, timezone, shiftRole } = workerShift.shiftInfo
  const formattedStartTime = startTime.toLocaleString('en-US', {
    timeZone: timezone,
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  })
  const { clockInWorkers, clockOutWorkers, editClockInWorkers } =
    useWorkerShiftMutations({
      data: [{ ...workerShift, worker }],
      onSuccess: refetchWorkerShifts,
    })

  const handleApprovalChange = async (action: ChargeApprovalAction) => {
    setIsUpdatingApproval(true)
    const workerShiftIds = [
      { workerId: workerShift.workerId, shiftId: workerShift.shiftId },
    ]

    if (action === ChargeApprovalAction.APPROVE) {
      await approveWorkerShifts(workerShiftIds)
    } else {
      await unapproveWorkerShifts(workerShiftIds)
    }
    refetchWorkerShifts()

    setIsUpdatingApproval(false)
  }

  const handleApprove = () => handleApprovalChange(ChargeApprovalAction.APPROVE)
  const handleUnapprove = () =>
    handleApprovalChange(ChargeApprovalAction.UNAPPROVE)

  return (
    <Col
      style={{
        backgroundColor: theme.colors.Grey10,
        borderTop: `1px solid ${theme.colors.Grey20}`,
      }}
      px={theme.space.xs}
      py={theme.space.xxs}
    >
      <Row justifyBetween alignCenter fullWidth mb={theme.space.xxs}>
        <Col>
          {groupBy === TimesheetsGroupBy.WORKER ? (
            <>
              <Text variant="body2">Shift</Text>
              <Row alignCenter>
                <Text
                  variant="body1"
                  mr={theme.space.xxs}
                >{`${shiftRole}, ${formattedStartTime}`}</Text>
              </Row>
            </>
          ) : (
            <WorkerPhotoAndName worker={worker} />
          )}
        </Col>
        {userCanManageTimesheets && (
          <Col>
            {!isApproved ? (
              <Button
                rightIcon={<SvgIcon name="check" />}
                variant={ButtonVariant.BRANDLINK}
                iconPadding={`${theme.space.xxs}px`}
                onClick={handleApprove}
                loading={isUpdatingApproval}
                disabled={!isWorkerShiftComplete}
              >
                {'Approve'}
              </Button>
            ) : workerShift.shiftInfo.payRate === 0 ? (
              <Tooltip title="This time is auto-approved by the Traba team because the shift has a $0 pay-rate.">
                <Text
                  color={theme.colors.MidnightBlue}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {'Auto-Approved'}
                </Text>
              </Tooltip>
            ) : (
              <Col gap={theme.space.xxxs}>
                <Text color={theme.colors.MidnightBlue}>Approved</Text>
                {!isInvoiced && userCanManagePaymentSettings && (
                  <Button
                    rightIcon={
                      <SvgIcon name="cancel" color={theme.colors.red} />
                    }
                    variant={ButtonVariant.ERROR}
                    iconPadding={`${theme.space.xxs}px`}
                    onClick={handleUnapprove}
                    loading={isUpdatingApproval}
                    disabled={!isWorkerShiftComplete}
                    style={{
                      padding: 0,
                      justifyContent: 'flex-start',
                      width: 'fit-content',
                    }}
                  >
                    {'Unapprove'}
                  </Button>
                )}
              </Col>
            )}
          </Col>
        )}
      </Row>
      <Row justifyBetween fullWidth wrap={true}>
        <Col>
          <Text variant="body2">In</Text>
          <WorkerOnShiftTableClockInField
            showEditableTimeInput={false}
            clockInWorkers={clockInWorkers}
            clockOutWorkers={clockOutWorkers}
            editClockInWorkers={editClockInWorkers}
            workerShift={{ ...workerShift, worker }}
            timezone={workerShift.shiftInfo.timezone}
            onEditWorkerShift={onEditWorkerShift}
            refetchWorkerShifts={refetchWorkerShifts}
            isFromTimesheetDetails={isApproved && isWorkerShiftComplete}
          />
        </Col>
        <Col>
          <Text variant="body2">Out</Text>
          <WorkerOnShiftTableClockOutField
            showEditableTimeInput={false}
            clockInWorkers={clockInWorkers}
            clockOutWorkers={clockOutWorkers}
            editClockInWorkers={editClockInWorkers}
            workerShift={{ ...workerShift, worker }}
            timezone={workerShift.shiftInfo.timezone}
            onEditWorkerShift={onEditWorkerShift}
            refetchWorkerShifts={refetchWorkerShifts}
            isFromTimesheetDetails={isApproved && isWorkerShiftComplete}
          />
        </Col>
        <Col>
          <Text variant="body2">Break</Text>
          <WorkerOnShiftTableBreaksField
            workerShift={{ ...workerShift, worker }}
            onEditWorkerShift={onEditWorkerShift}
            editMode={false}
            timezone={workerShift.shiftInfo.timezone}
            scheduledBreaks={workerShift.shiftInfo.scheduledBreaks}
            refetchWorkerShifts={refetchWorkerShifts}
            isFromTimesheetDetails={isApproved}
          />
        </Col>
        <Col>
          <Text variant="body2">Total hrs</Text>
          <WorkerOnShiftTableTotalWorked
            isWorkerShiftComplete={isWorkerShiftComplete}
            editMode={false}
            workerShift={workerShift}
          />
        </Col>
      </Row>
    </Col>
  )
}
