import { useAlert } from '@traba/context'
import { ModalPaddingContainer } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { COMPANY_WIDE_ID } from '@traba/types'
import { useState } from 'react'
import { Divider, Row, Text } from 'src/components/base'
import { RoleForm } from 'src/components/RoleForm'
import { RoleStepper } from 'src/components/RoleStepper'
import { useRoles } from 'src/hooks/useRoles'
import { theme } from 'src/libs/theme'
import { CreateRoleData, RoleData, UpdateRoleResponseDto } from 'src/types'
import { parseFreeFormAttributesToRequiredAttributes } from 'src/utils/roleUtils'

import { ButtonIcon } from '../../PersonalProfile/PersonalProfile.styles'

export type EditRoleModalProps = {
  role: RoleData
  handleClose: () => void
  isEventCompany: boolean
}

export const EditRoleModal = (props: EditRoleModalProps) => {
  const { handleClose, isEventCompany, role } = props
  const [submitLoading, setSubmitLoading] = useState(false)
  const { editRole, refetchRoles } = useRoles()
  const { showSuccess, showError } = useAlert()

  function handleEdit(editedRole: CreateRoleData) {
    setSubmitLoading(true)
    editRole(
      {
        roleId: role.roleId,
        updatedRole: editedRole,
      },
      {
        onSuccess: (response: UpdateRoleResponseDto) => {
          if (!response.role) {
            showError(
              'If you meant to update the role, double check the fields you wish to update.',
              'No changes detected',
            )
            handleClose()
            return
          }
          const title = 'Edit role successful.'
          let message = 'Changes were successfully saved.'
          if (response.previousRoleId) {
            message +=
              ' Shifts that were in the past, are ongoing, or start within 18 hours were not updated.'
          }
          if (response.updatedShiftIds && response.updatedShiftIds.length > 0) {
            message += ` Successfully updated ${
              response.updatedShiftIds.length
            } shift${makePlural(
              response.updatedShiftIds,
            )} with new role information.`
          }
          if (
            response.failedToUpdateShiftIds &&
            response.failedToUpdateShiftIds.length > 0
          ) {
            message += ` Failed to updated ${
              response.failedToUpdateShiftIds.length
            } shift${makePlural(
              response.failedToUpdateShiftIds,
            )} due to eligibility issues for workers already signed up for these shifts.`
          }
          showSuccess(message, title)
          refetchRoles()
          handleClose()
        },
        onSettled: () => {
          setSubmitLoading(false)
        },
      },
    )
  }

  return isEventCompany ? (
    <ModalPaddingContainer>
      <Row justifyBetween style={{ marginBottom: theme.space.xs }}>
        <Text variant="h2">Edit role</Text>
        <ButtonIcon name="cancel" onClick={handleClose} />
      </Row>
      <RoleForm
        currentRoleId={role.roleId}
        isEdit={true}
        onConfirm={handleEdit}
        onCancel={handleClose}
        submitLoading={submitLoading}
        initialRoleState={{
          roleName: role.roleName,
          roleDescription: role.roleDescription,
          requiredAttire: role.requiredAttire,
          requiredCertifications: role.requiredCertifications || [],
          requiredAttributes: role.requiredAttributes || [],
          videoIds: role.videoIds || [],
          defaultPayRate: role.defaultPayRate,
          genderPreference: role.genderPreference,
          locationId: role.location?.locationId || COMPANY_WIDE_ID,
        }}
      />
    </ModalPaddingContainer>
  ) : (
    <div style={{ width: '100%' }}>
      <Row justifyBetween alignCenter style={{ margin: theme.space.sm }}>
        <Text variant="h4">Edit Role</Text>
        <ButtonIcon name="cancel" onClick={handleClose} />
      </Row>
      <Divider />
      <RoleStepper
        currentRoleId={role.roleId}
        isEdit={true}
        initialRoleState={{
          roleName: role.roleName,
          roleDescription: role.roleDescription,
          requiredAttire: role.requiredAttire,
          requiredCertifications: role.requiredCertifications || [],
          requiredAttributes: parseFreeFormAttributesToRequiredAttributes(
            role.requiredAttributes,
            role.freeformAttributes,
          ),
          freeformAttributes: role.freeformAttributes || {},
          videoIds: role.videoIds || [],
          defaultPayRate: role.defaultPayRate,
          genderPreference: role.genderPreference,
          locationId: role.location?.locationId || COMPANY_WIDE_ID,
          requiredTrainingIds: role.requiredTrainingIds,
        }}
        fromPrevious={false}
        onConfirm={handleEdit}
        onCancel={handleClose}
        submitLoading={submitLoading}
      />
    </div>
  )
}
