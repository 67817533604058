import { Tooltip } from '@mui/material'
import { ShiftStatus, Shift } from '@traba/types'
import { formatShiftDateWithTimezone, getShiftTimeString } from '@traba/utils'
import React, { memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Icon,
  Row,
  SvgIcon,
  Text,
  Button,
  Col,
  ButtonVariant,
} from 'src/components/base'
import { useNavigateToTimeSheetDailyView } from 'src/hooks/useTimesheet'
import { theme } from 'src/libs/theme'
import { truncateString } from 'src/utils/stringUtils'
import { ShiftStatusBadge } from '../ShiftBadges/ShiftStatusBadge'
import { WorkersOnShiftTable } from '../WorkersOnShiftTable'
import { ShiftTileUIProps } from './ShiftTile.hooks'
import * as S from './ShiftTile.styles'
import { ShiftTileScheduleCadenceText } from './ShiftTileScheduleCadenceText'
import { isInProgressOrStartsInThreshold } from './ShiftTileTimebarUtils'
import ShiftTypeBadge, { ShiftType } from './ShiftTypeBadge'

export const DesktopShiftTileWithWorkersList: React.FC<
  ShiftTileUIProps & Shift
> = memo((props) => {
  const {
    shiftId,
    shiftRole,
    endTime,
    slotsRequested,
    confirmationCode,
    clockOutCode,
    timezone,
    path,
    addressString,
    slotsRemaining,
    showTimeBar,
    defaultShowWorkerShifts = false,
    alwaysHideWorkers,
    // TODO(polyphilz): Eventually, you will need the shift request parent ID
    // here to get the saved schedule name (for when we build that feature for
    // shift data model). For now, we can ignore it.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shiftRequestParentId,
    schedules = [],
  } = props
  const shift: Shift = { ...props }
  const startTime = shift.businessStartTime ?? shift.startTime

  const navigate = useNavigate()
  const navigateToTimeSheetDailyView = useNavigateToTimeSheetDailyView()

  const [isExpanded, setIsExpanded] = useState<boolean>(defaultShowWorkerShifts)

  const isPartOfRecurringSchedule =
    schedules.length && schedules[0].isRecurringSchedule
  const endDateOfRecurringSchedule =
    schedules.length && schedules[0].recurringSchedule?.endDate

  useEffect(() => {
    setIsExpanded(defaultShowWorkerShifts)
  }, [defaultShowWorkerShifts])

  return (
    <>
      <S.ExpandableShiftTileCard isExpanded={isExpanded}>
        <Row>
          {shift.status === ShiftStatus.CANCELED ? (
            <ShiftStatusBadge
              shiftStartTime={startTime}
              shiftEndTime={endTime}
              shiftIsCanceled={true}
            />
          ) : showTimeBar &&
            isInProgressOrStartsInThreshold(startTime, endTime) ? (
            <ShiftStatusBadge
              shiftStartTime={startTime}
              shiftEndTime={endTime}
              shiftIsCanceled={false}
            />
          ) : null}
          <Row
            mt={theme.space.med}
            mb={theme.space.med}
            pl={theme.space.xs}
            pr={theme.space.xs}
            style={{ alignItems: 'center' }}
            fullWidth
          >
            <Row alignCenter>
              <S.ShiftTileCol
                ml={theme.space.sm}
                style={{
                  width: '130px',
                }}
              >
                <Row>
                  <Tooltip title={shiftRole}>
                    <Text
                      variant="h5"
                      onClick={() => {
                        if (path === 'timesheet') {
                          navigateToTimeSheetDailyView(shiftId, startTime)
                        } else {
                          navigate(`/${path}/${shiftId}`)
                        }
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {truncateString(shiftRole, 12, true)}
                    </Text>
                  </Tooltip>
                </Row>
                <ShiftTypeBadge
                  shiftType={
                    isPartOfRecurringSchedule
                      ? ShiftType.RECURRING
                      : ShiftType.ONE_TIME
                  }
                  iconName={
                    isPartOfRecurringSchedule ? 'rotate_left' : undefined
                  }
                  variant={isPartOfRecurringSchedule ? 'info' : 'brand'}
                />
              </S.ShiftTileCol>
            </Row>
            <S.ShiftTileCol style={{ paddingRight: theme.space.med }}>
              <S.ShiftTileBodyText
                variant="body2"
                style={{ paddingBottom: theme.space.xxxs }}
              >
                {getShiftTimeString(startTime, endTime, timezone)}
              </S.ShiftTileBodyText>
              <Tooltip title={addressString}>
                <S.ShiftTileBodyText variant="body2">
                  {truncateString(addressString, 26, true)}
                </S.ShiftTileBodyText>
              </Tooltip>
            </S.ShiftTileCol>
            <S.ShiftTileCol className="sm">
              <S.ShiftTileBodyText
                variant="body2"
                style={{ paddingBottom: theme.space.xxxs }}
              >
                {`Clock in: ${confirmationCode}`}
              </S.ShiftTileBodyText>
              <S.ShiftTileBodyText variant="body2">
                {`Clock out: ${clockOutCode}`}
              </S.ShiftTileBodyText>
            </S.ShiftTileCol>
          </Row>

          {isPartOfRecurringSchedule && (
            <Col
              className="xs-2"
              style={{
                justifyContent: 'center',
                borderLeft: `2px solid ${theme.colors.Grey20}`,
                minWidth: 300,
                alignItems: 'left',
              }}
            >
              <Row
                pl={theme.space.xs}
                mt={theme.space.med}
                mb={theme.space.med}
              >
                <S.ShiftTileCol>
                  <S.ShiftTileBodyText
                    variant="body2"
                    style={{
                      paddingBottom: theme.space.xxxs,
                      marginBottom: theme.space.xxxs,
                      minWidth: '100%',
                    }}
                  >
                    <ShiftTileScheduleCadenceText schedules={schedules} />
                  </S.ShiftTileBodyText>
                  {endDateOfRecurringSchedule && (
                    <S.ShiftTileBodyText variant="body2">
                      {`Until ${formatShiftDateWithTimezone(endDateOfRecurringSchedule, shift.timezone)}`}
                    </S.ShiftTileBodyText>
                  )}
                </S.ShiftTileCol>
              </Row>
            </Col>
          )}

          <Col
            className="xs-2"
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              borderLeft: `2px solid ${theme.colors.Grey20}`,
              minWidth: 200,
            }}
          >
            <Row mt={theme.space.sm} mb={theme.space.sm}>
              <S.ShiftTileCol style={{ alignItems: 'start' }} className="xs-3">
                <Row>
                  <Button
                    variant={ButtonVariant.TEXT}
                    onClick={() => {
                      if (path === 'timesheet') {
                        navigateToTimeSheetDailyView(shiftId, startTime)
                      } else {
                        navigate(`/${path}/${shiftId}`)
                      }
                    }}
                    style={{
                      color: theme.colors.brand,
                      alignSelf: 'end',
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginBottom: theme.space.xxxs,
                    }}
                  >
                    <SvgIcon
                      name={'link'}
                      color={theme.colors.brand}
                      size={theme.space.xs}
                      style={{ marginRight: theme.space.xxs }}
                    />
                    Shift Details
                  </Button>
                </Row>
                {alwaysHideWorkers ||
                slotsRemaining === undefined ? undefined : (
                  <Row>
                    <Button
                      variant={ButtonVariant.TEXT}
                      onClick={() => setIsExpanded(!isExpanded)}
                      style={{
                        color: theme.colors.brand,
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <Icon
                        name={isExpanded ? 'chevronUp' : 'chevronDown'}
                        style={{ marginRight: theme.space.xsmed }}
                      />
                      {isExpanded ? 'Hide Workers' : 'Show Workers'}{' '}
                      {'(' +
                        (slotsRequested - slotsRemaining).toString() +
                        '/' +
                        slotsRequested.toString() +
                        ')'}
                    </Button>
                  </Row>
                )}
              </S.ShiftTileCol>
            </Row>
          </Col>
        </Row>
      </S.ExpandableShiftTileCard>

      {isExpanded && (
        <S.ShiftTileWorkerListContainer>
          <WorkersOnShiftTable
            shift={shift}
            hideHeader={true}
            isUpcoming={true}
            maxRowsBeforeScroll={4}
          />
        </S.ShiftTileWorkerListContainer>
      )}
    </>
  )
})
