import { theme } from '@traba/theme'
import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'
import Row from '../Row'

export interface PillProps {
  children: React.ReactNode
  addMargin?: boolean
  onClick?: MouseEventHandler<HTMLDivElement> | undefined
  color?: string
  hoverColor?: string
  addBorder?: boolean
  borderColor?: string
  borderHoverColor?: string
  width?: string
  style?: React.CSSProperties
}

export function Pill(props: PillProps) {
  const {
    children,
    addMargin,
    onClick,
    color = theme.colors.Violet10,
    hoverColor,
    addBorder = true,
    borderColor = theme.colors.Violet30,
    borderHoverColor,
    width,
    style,
  } = props
  return (
    <StyledRow
      onClick={onClick}
      color={color}
      hoverColor={hoverColor}
      borderColor={borderColor}
      borderHoverColor={borderHoverColor}
      addBorder={addBorder}
      addMargin={addMargin}
      width={width}
      style={style}
    >
      {children}
    </StyledRow>
  )
}

const StyledRow = styled(Row)<{
  color: string
  hoverColor?: string
  borderColor?: string
  borderHoverColor?: string
  addBorder: boolean
  addMargin?: boolean
  width?: string
}>`
  border: 1.5px solid
    ${({ borderColor, addBorder }) => (addBorder ? borderColor : 'transparent')};
  border-radius: 10000px;
  background-color: ${({ color }) => color};
  margin-right: ${({ addMargin }) =>
    addMargin ? `${theme.space.xxs}px` : undefined};
  align-items: center;
  width: ${({ width }) => width};
  padding-left: ${theme.space.xs}px;
  padding-right: ${theme.space.xs}px;

  &:hover {
    background-color: ${({ hoverColor }) => hoverColor};
    border-color: ${({ borderHoverColor, addBorder }) =>
      addBorder ? borderHoverColor : 'transparent'};
    box-shadow: 0px 2px 5px rgba(196, 204, 212, 0.55);
  }
`
