import {
  COMPLETED_JOB_STATUSES_FOR_TIMESHEETS,
  ShiftWithWorkerShiftsAndCharges,
  WorkerShift,
  WorkerShiftWithCharges,
  WorkerWithWorkerShiftAndCharges,
} from '@traba/types'
import { cloneDeep } from 'lodash'
import {
  TimeSheetsEstimatedChargeResponse,
  TimeSheetsWorkerCharge,
} from 'src/hooks/useTimesheet'
import { getWorkerShiftStringId } from 'src/utils/workerShiftUtils'

export function combineMultipleEstimatedWorkerShiftsCharges(
  timeSheetsEstimatedCharges: TimeSheetsEstimatedChargeResponse[],
): TimeSheetsWorkerCharge {
  const summedCharges = timeSheetsEstimatedCharges.reduce<{
    amount: number
    updatedAmount: number
  }>(
    (acc, charge) => {
      return {
        amount: acc.amount + (charge.data?.charge?.amount ?? 0),
        updatedAmount:
          acc.updatedAmount +
          (charge.data?.updatedCharge?.amount ??
            charge.data?.charge?.amount ??
            0),
      }
    },
    { amount: 0, updatedAmount: 0 },
  )
  return {
    charge: {
      amount: summedCharges.amount,
      currency: 'USD',
    },
    updatedCharge: {
      amount: summedCharges.updatedAmount,
      currency: 'USD',
    },
  }
}

/* Some tables require the related data to be parsed into the entity object */
export function parseTimesheetData(
  data:
    | WorkerWithWorkerShiftAndCharges[]
    | ShiftWithWorkerShiftsAndCharges[]
    | undefined,
  editedWorkerShifts?: Record<string, Partial<WorkerShift>>,
): WorkerWithWorkerShiftAndCharges[] | ShiftWithWorkerShiftsAndCharges[] {
  if (!data) {
    return []
  }
  const workersOrShifts = cloneDeep(data)
  // for each worker or shift
  for (let i = 0; i < workersOrShifts.length; i++) {
    // for each workerShift
    for (let j = 0; j < workersOrShifts[i].workerShifts.length; j++) {
      const workerShift = workersOrShifts[i].workerShifts[j]
      const workerShiftPendingAdjustment = workerShift.pendingAdjustment
      const workerShiftLocalEdit = editedWorkerShifts
        ? editedWorkerShifts[
            getWorkerShiftStringId(workerShift.workerId, workerShift.shiftId)
          ]
        : undefined
      workersOrShifts[i].workerShifts[j] = {
        ...workerShift,
        ...(workerShiftPendingAdjustment || {}),
        ...(workerShiftLocalEdit || {}),
        hasPendingEdits:
          !!workerShiftPendingAdjustment || !!workerShiftLocalEdit,
      }
    }
  }
  return workersOrShifts
}

export function chargeIsApprovable(ws: WorkerShiftWithCharges) {
  return (
    !ws.isApproved &&
    COMPLETED_JOB_STATUSES_FOR_TIMESHEETS.includes(ws.jobStatus)
  )
}

export function chargeIsUnapprovable(ws: WorkerShiftWithCharges) {
  return (
    ws.isApproved &&
    !ws.isInvoiced &&
    COMPLETED_JOB_STATUSES_FOR_TIMESHEETS.includes(ws.jobStatus)
  )
}
