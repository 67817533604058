import { Tooltip } from '@mui/material'
import { Card } from '@traba/react-components'
import {
  ChatMessage,
  ChatMessageDeletedStatus,
  ChatMessageDeliveryStatus,
} from '@traba/types'
import { Worker } from '@traba/types'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Avatar,
  Button,
  ButtonVariant,
  Col,
  Icon,
  Image,
  Modal,
  Row,
  SvgIcon,
  Text,
} from 'src/components/base'
import { useShiftForInvitation } from 'src/hooks/useShiftForInvitation'
import { useNavigateToTimeSheetDailyView } from 'src/hooks/useTimesheet'
import { BULLET_CHAR } from 'src/libs/constants'
import { theme } from 'src/libs/theme'
import { formatShiftTimeWithTime } from 'src/shared/utils/dateUtils'
import { formatTimeDistanceToNow } from 'src/utils/timeUtils'

import MessageItemStatus from './MessageItemStatus'

interface Props {
  message: ChatMessage
  profilePictureUrl: string
  logoUrl?: string
  companyName?: string
  isFromChatWidget?: boolean
  onDeleteMessage: (messageId: string) => Promise<void>
  isMobileView?: boolean
  worker?: Worker
}

const MessageItem = ({
  message,
  isFromChatWidget,
  logoUrl,
  companyName,
  onDeleteMessage,
  isMobileView,
  worker,
  profilePictureUrl,
}: Props) => {
  const {
    messageContent,
    attachmentUrl,
    workerSenderName,
    workerSenderId,
    companyUserSenderName,
    sentAt,
    messageId,
    invitationId,
  } = message
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [showImageModal, setShowImageModal] = useState(false)
  const endAlignMessage = workerSenderId === undefined
  const senderName = workerSenderName ?? companyUserSenderName
  const isDeleted = message.deletedStatus === ChatMessageDeletedStatus.DELETED
  const isFailed =
    message.deliveryStatus === ChatMessageDeliveryStatus.MODERATED_EXPLICIT
  const navigate = useNavigate()
  const navigateToTimeSheetDailyView = useNavigateToTimeSheetDailyView()
  const { shift } = useShiftForInvitation(invitationId)

  if (
    (!messageContent && !attachmentUrl && !invitationId) ||
    (invitationId && !shift)
  ) {
    return null
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        justifyContent: endAlignMessage ? 'flex-end' : 'flex-start',
        marginTop: isFromChatWidget ? theme.space.us : theme.space.xxs,
      }}
    >
      <Row
        justifyEnd
        style={{
          marginBottom: theme.space.xs,
        }}
      >
        {!endAlignMessage && (
          <Avatar
            src={profilePictureUrl}
            name={workerSenderName ?? 'worker'}
            size={isFromChatWidget ? 40 : 50}
          />
        )}
        <Tooltip
          title={
            endAlignMessage &&
            message.deliveryStatus ===
              ChatMessageDeliveryStatus.MODERATED_EXPLICIT
              ? 'This message failed to send because it includes inappropriate language.'
              : ''
          }
        >
          <div
            onMouseEnter={() => {
              setShowDeleteButton(true)
            }}
            onMouseLeave={() => {
              setShowDeleteButton(false)
            }}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1,
            }}
          >
            <Col mx={theme.space.xxs}>
              <Card
                style={{
                  backgroundColor: endAlignMessage
                    ? theme.colors.Violet10
                    : theme.colors.Grey10,
                  paddingTop: theme.space.xxs,
                  paddingBottom: theme.space.xxs,
                  paddingLeft: theme.space.xs,
                  borderWidth: 0,
                  alignSelf: endAlignMessage ? 'flex-end' : 'flex-start',
                }}
              >
                <Col>
                  {invitationId && shift ? (
                    <Row style={{ alignSelf: 'end' }}>
                      <Button
                        onClick={() => {
                          if (new Date() > shift.endTime) {
                            navigateToTimeSheetDailyView(
                              shift.shiftId,
                              shift.businessStartTime ?? shift.startTime,
                            )
                          } else {
                            navigate(`/calendar/${shift.shiftId}`)
                          }
                        }}
                        variant={ButtonVariant.OUTLINED}
                      >
                        <Row>
                          <SvgIcon
                            style={{
                              marginRight: theme.space.xxxs,
                            }}
                            name="forwardArrow"
                            size={16}
                            color={theme.colors.MidnightBlue}
                          />
                          <Col
                            style={{
                              alignItems: 'start',
                            }}
                          >
                            <Text
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: 160,
                              }}
                              variant="h7"
                            >
                              {shift.shiftRole}
                            </Text>
                            <Text
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: 160,
                              }}
                              variant="body3"
                            >
                              {formatShiftTimeWithTime(
                                shift.businessStartTime ?? shift.startTime,
                                shift.timezone,
                              )}
                            </Text>
                          </Col>
                        </Row>
                      </Button>
                    </Row>
                  ) : undefined}
                  <Text
                    variant={isDeleted ? 'body2' : 'body1'}
                    style={{ wordBreak: 'break-word' }}
                    aria-label={'Message'}
                  >
                    {isDeleted
                      ? 'This message has been deleted.'
                      : invitationId
                        ? `You invited ${
                            worker ? worker.firstName : 'them'
                          } to a shift!`
                        : messageContent}
                  </Text>
                  {attachmentUrl && !isDeleted && (
                    <div
                      onClick={() => {
                        if (!isMobileView) {
                          setShowImageModal(true)
                        }
                      }}
                    >
                      <Image
                        style={{
                          borderRadius: theme.shape.borderRadius,
                          cursor: 'pointer',
                          height: 100,
                          width: 100,
                        }}
                        expandable={isMobileView === true}
                        alt="chat-image"
                        src={attachmentUrl}
                      />
                    </div>
                  )}
                </Col>
              </Card>

              <Row justifyEnd={endAlignMessage} alignCenter>
                <Text
                  variant={isFromChatWidget ? 'body3' : 'body2'}
                  style={{
                    textAlign: endAlignMessage ? 'end' : 'start',
                    marginRight: theme.space.ms,
                  }}
                >
                  {formatTimeDistanceToNow(sentAt)}
                  {senderName ? ` ${BULLET_CHAR} ${senderName}` : ''}
                </Text>
                {endAlignMessage && (
                  <MessageItemStatus
                    deliveryStatus={message.deliveryStatus}
                    isFromChatWidget={isFromChatWidget}
                  />
                )}
                {showDeleteButton &&
                endAlignMessage &&
                !invitationId &&
                !isDeleted &&
                !isFailed ? (
                  <Icon
                    name="trash"
                    onClick={() => {
                      onDeleteMessage(messageId)
                    }}
                  />
                ) : (
                  <div style={{ width: 16 }} />
                )}
              </Row>
            </Col>
          </div>
        </Tooltip>
        {endAlignMessage && (
          <Avatar
            src={logoUrl}
            name={companyName ?? 'company'}
            size={isFromChatWidget ? 40 : 50}
          />
        )}
      </Row>
      <Modal
        isOpen={!!showImageModal}
        handleClose={() => setShowImageModal(false)}
      >
        <img
          src={attachmentUrl}
          style={{ maxHeight: 800, padding: theme.space.xl }}
        />
      </Modal>
    </div>
  )
}

export default MessageItem
