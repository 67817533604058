import { SvgIconProps } from '@traba/types'

export default function Lipstick({
  size = 16,
  color = '#7A8A99',
  ...props
}: SvgIconProps) {
  return (
    <svg
      width={(size * 28) / 82}
      height={size}
      viewBox="0 0 28 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.5 45H25V33.5C25 32.6 25 31.8 24 31.4V4.79999C24 2.99999 22.9 1.29999 21.3 0.599986C19.6 -0.100014 17.7 0.299986 16.4 1.59999L5.4 12.4C4.5 13.3 4 14.4 4 15.6V31.2C4 31.6 3 32.5 3 33.5V45H2.5C1.1 45 0 46.1 0 47.5V79.6C0 81 1.1 82 2.5 82H25.5C26.9 82 28 81 28 79.6V47.5C28 46.1 26.9 45 25.5 45ZM19 45V36H20V45H19ZM19 5.99999V31H9V15.8L19 5.99999ZM8 36H16V45H8V36ZM5 50C5 50 5.1 50 5.2 50H18V77H5V50ZM21 77V50H22.8C22.9 50 23 50 23 50V77H21Z"
        fill={color}
      />
    </svg>
  )
}
