import { Navigate, Route, Routes } from 'react-router-dom'
import { useUserContext } from 'src/context/user/UserContext'
import BasicDetailsScreen from 'src/screens/auth/BasicDetailsScreen'
import CompanyDetailsScreen from 'src/screens/auth/CompanyDetailsScreen'
import FinalizeInvitationScreen from 'src/screens/auth/FinalizeInvitationScreen'

function Onboarding() {
  const userContext = useUserContext()
  const profile = userContext.state.userProfile
  const firstName = profile?.firstName
  const lastName = profile?.lastName
  const phoneNumber = profile?.phoneNumber
  const hasAllBasicDetails =
    firstName &&
    firstName.length > 0 &&
    lastName &&
    lastName.length > 0 &&
    phoneNumber &&
    phoneNumber.length > 0

  return (
    <Routes>
      <Route path="/basic-details" element={<BasicDetailsScreen />} />
      <Route path="/company-details" element={<CompanyDetailsScreen />} />
      <Route
        path="/finalize-invitation"
        element={<FinalizeInvitationScreen />}
      />
      <Route
        path="*" // fallback route
        element={
          <Navigate
            to={
              hasAllBasicDetails
                ? '/company-details'
                : profile?.companyId
                  ? '/finalize-invitation'
                  : '/basic-details'
            }
          />
        }
      />
    </Routes>
  )
}

export default Onboarding
