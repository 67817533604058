import { theme } from '@traba/theme'
import { User, UserRole } from '@traba/types'
import React from 'react'
import { Button, ButtonVariant } from '../../../base-components/Button/Button'
import { ModalPaddingContainer } from '../../../base-components/Modal/Modal.styles'
import Row from '../../../base-components/Row'
import { SvgIcon } from '../../../base-components/SvgIcon'
import { Text } from '../../../base-components/Text'
import { MemberCard } from '../../MemberCard'

export type DeactivateUserModalContentProps = {
  member: Pick<User, 'email' | 'firstName' | 'lastName'> & {
    role?: UserRole
  }
  handleClose: () => void
  onDeactivateMember: () => void
  loading?: boolean
}

const DEACTIVATE_MEMBER_DESCRIPTION =
  'Deactivating a member will remove their access to Traba, but they will still be an option when selecting a supervisor for a shift'

export function DeactivateUserModalContent(
  props: DeactivateUserModalContentProps,
) {
  const { member, handleClose, onDeactivateMember, loading } = props
  const { firstName, lastName, email, role } = member

  return (
    <ModalPaddingContainer>
      <Row justifyBetween pb={theme.space.xs}>
        <Text variant="h2">Deactivate Member</Text>
        <SvgIcon
          size={24}
          color={theme.colors.Grey50}
          name="cancel"
          onClick={handleClose}
        />
      </Row>
      <Text variant="body2">{DEACTIVATE_MEMBER_DESCRIPTION}</Text>

      <Row justifyBetween pt={theme.space.xs} pb={theme.space.xxs}>
        <Text variant="h5">Member to Deactivate</Text>
      </Row>
      <MemberCard
        firstName={firstName}
        lastName={lastName}
        email={email}
        role={role}
      />

      <Row justifyBetween pt={theme.space.med}>
        <Button
          type="button"
          variant={ButtonVariant.OUTLINED}
          onClick={handleClose}
        >
          Back
        </Button>
        <Button
          variant={ButtonVariant.FILLED}
          onClick={onDeactivateMember}
          loading={loading}
        >
          Deactivate
        </Button>
      </Row>
    </ModalPaddingContainer>
  )
}
