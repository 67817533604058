import { TextField, TextFieldProps } from '@mui/material'
import React from 'react'

const InputComponent = ({ ...other }: any) => (
  <div
    {...other}
    style={{ marginRight: 5, marginLeft: 5, ...other.style, border: 'none' }}
  />
)

type BorderBoxProps = {
  label?: string
  children: React.ReactNode
  style?: React.CSSProperties
  helperText?: string
  border?: 'none' | 'onError'
} & TextFieldProps

const BorderBox = ({
  children,
  label,
  style,
  helperText,
  ...props
}: BorderBoxProps) => {
  return (
    <TextField
      label={label}
      multiline
      {...props}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent,
      }}
      sx={{
        width: '100%',
        ...style,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none',
          },
        },
      }}
      inputProps={{ children: children }}
      helperText={helperText}
    />
  )
}

export default BorderBox
