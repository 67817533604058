import { theme } from '@traba/theme'
import { WorkerShiftAvailability } from '@traba/types'
import { formatDateString as baseFormatDateString } from '@traba/utils'
import Row from '../base-components/Row'
import { SvgIcon } from '../base-components/SvgIcon'

export function WorkerAvailabilityText({
  availability,
  short = false,
}: {
  availability: WorkerShiftAvailability
  short?: boolean
}) {
  const formatDateString = (date: Date) =>
    short
      ? baseFormatDateString(date, { weekday: undefined })
      : baseFormatDateString(date)
  if (availability.isAlreadyPaidBackupForShift) {
    return (
      <Row
        gap={theme.space.xxxs}
        style={{ color: theme.colors.Green80 }}
        alignCenter
      >
        <SvgIcon
          name="filledCheck"
          size={theme.space.xsmed}
          color={theme.colors.Green80}
        />
        {short ? 'Paid backup' : 'Already paid backup for this shift'}
      </Row>
    )
  }
  if (availability.isAlreadySignedUpForShift) {
    return (
      <Row
        gap={theme.space.xxxs}
        style={{ color: theme.colors.Green80 }}
        alignCenter
      >
        <SvgIcon
          name="filledCheck"
          size={theme.space.xsmed}
          color={theme.colors.Green70}
        />
        {short ? 'Signed up' : 'Already signed up for this shift'}
      </Row>
    )
  }
  if (availability.isAlreadyInvitedToShift) {
    return (
      <Row
        gap={theme.space.xxxs}
        style={{ color: theme.colors.Yellow80 }}
        alignCenter
      >
        <SvgIcon
          name="info"
          size={theme.space.xsmed}
          color={theme.colors.Yellow80}
        />
        {short ? 'Invited' : 'Already invited to this shift'}
      </Row>
    )
  }
  if (availability.shiftConflicts.length > 1) {
    const earliestShiftConflict = availability.shiftConflicts.reduce(
      (earliest, current) =>
        earliest.startTime < current.startTime ? earliest : current,
    )
    return (
      <Row
        gap={theme.space.xxxs}
        style={{ color: theme.colors.Red80 }}
        alignCenter
      >
        <SvgIcon
          name="alert"
          size={theme.space.xs}
          color={theme.colors.Red80}
        />
        {short
          ? `Conflicts ${formatDateString(earliestShiftConflict.startTime)}`
          : `Conflicting shifts starting on ${formatDateString(earliestShiftConflict.startTime)}`}
      </Row>
    )
  }
  if (availability.shiftConflicts.length === 1) {
    const shiftConflict = availability.shiftConflicts[0]
    return (
      <Row
        gap={theme.space.xxxs}
        style={{ color: theme.colors.Red80 }}
        alignCenter
      >
        <SvgIcon
          name="alert"
          size={theme.space.xs}
          color={theme.colors.Red80}
        />
        {short
          ? `Conflict ${formatDateString(shiftConflict.startTime)}`
          : `Conflicting start on ${formatDateString(shiftConflict.startTime)}`}
      </Row>
    )
  }
  if (availability.shiftInvitationConflicts.length > 1) {
    const earliestInvitationConflict =
      availability.shiftInvitationConflicts.reduce((earliest, current) =>
        earliest.startTime < current.startTime ? earliest : current,
      )
    return (
      <Row
        gap={theme.space.xxxs}
        style={{ color: theme.colors.Yellow80 }}
        alignCenter
      >
        <SvgIcon
          name="info"
          size={theme.space.xsmed}
          color={theme.colors.Yellow80}
        />
        {short
          ? `Invites ${formatDateString(earliestInvitationConflict.startTime)}`
          : `Conflicting invites starting on ${formatDateString(earliestInvitationConflict.startTime)}`}
      </Row>
    )
  }
  if (availability.shiftInvitationConflicts.length === 1) {
    const invitationConflict = availability.shiftInvitationConflicts[0]
    return (
      <Row
        gap={theme.space.xxxs}
        style={{ color: theme.colors.Yellow80 }}
        alignCenter
      >
        <SvgIcon
          name="info"
          size={theme.space.xsmed}
          color={theme.colors.Yellow80}
        />
        {short
          ? `Invite ${formatDateString(invitationConflict.startTime)}`
          : `Conflicting invite on ${formatDateString(invitationConflict.startTime)}`}
      </Row>
    )
  }
}
