import { CardTile } from '@traba/react-components'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonVariant } from 'src/components/base'
import Icon from 'src/components/base/Icon'
import { Text } from 'src/components/base/Text'
import { theme } from 'src/libs/theme'
import { openInNewTab } from 'src/utils/miscUtils'
import * as S from '../InvoiceTable.styles'

export type InvoiceButtonProps = {
  stripeInvoiceId: string
  invoiceUrl?: string
  isInvoicePaid: boolean
  slim?: boolean
  userCanManageInvoices: boolean
  upflowPortalUrl?: string
}

export const InvoiceButtonUI = ({
  invoiceUrl,
  slim,
  stripeInvoiceId,
  isInvoicePaid,
  userCanManageInvoices,
  upflowPortalUrl,
  downloadInvoice,
}: InvoiceButtonProps & {
  downloadInvoice: (invoiceId: string) => void
}) => {
  if (!isInvoicePaid && !userCanManageInvoices) {
    return null
  }

  let text: string

  if (isInvoicePaid) {
    text = slim ? 'View' : upflowPortalUrl ? 'View on Upflow' : 'View on Stripe'
  } else {
    text = slim ? 'Pay' : upflowPortalUrl ? 'Pay via Upflow' : 'Pay via Stripe'
  }

  return (
    <Button
      leftIcon={<Icon name="billing_small_primary" />}
      onClick={() => {
        if (upflowPortalUrl) {
          return openInNewTab(upflowPortalUrl)
        }
        /**
         * Invoices that are more than 30 days past due will have their hosted invoice url's expire.
         * Rather than refreshing these forever we will just fetch on click for paid invoices
         * */
        isInvoicePaid
          ? stripeInvoiceId && downloadInvoice(stripeInvoiceId)
          : openInNewTab(invoiceUrl)
      }}
      variant={ButtonVariant.FILLED}
      slim={slim}
      style={{ marginLeft: theme.space.xxs, marginRight: '4px' }}
    >
      <Text variant="h7" style={{ color: '#FFFFFF' }}>
        {text}
      </Text>
    </Button>
  )
}

export const InvoiceTile = ({
  invoiceNumber,
  invoiceId,
}: {
  invoiceNumber: string
  invoiceId: string
}) => {
  const navigate = useNavigate()

  return (
    <S.TdMultiLeft
      onClick={() => navigate(`/billing/${invoiceId}`)}
      style={{ cursor: 'pointer' }}
    >
      <CardTile size="40px">
        <Icon name="document" />
      </CardTile>
      <S.SmallMarginLeft>{invoiceNumber}</S.SmallMarginLeft>
    </S.TdMultiLeft>
  )
}
