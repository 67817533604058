import { IneligibleSelect, LoadingSpinner } from '@traba/react-components'
import { EligibilityConnectionMutationPayload } from '@traba/types'
import { useMemo } from 'react'
import { Row } from 'src/components/base'
import { useUserContext } from 'src/context/user/UserContext'
import { useBizEligibilityConnections } from 'src/hooks/useEligibilityConnections'
import { useLocations } from 'src/hooks/useLocations'
import { useRegionalFilter } from 'src/hooks/useRegionalFilter'
import { useRoles } from 'src/hooks/useRoles'

type ConnectionProps = {
  workerId: string
  companyId: string
  setPayload: React.Dispatch<
    React.SetStateAction<EligibilityConnectionMutationPayload | undefined>
  >
}

export const EligibilityConnectionContent = (props: ConnectionProps) => {
  const { workerId, companyId, setPayload } = props

  const { getStoredRegionalFilter } = useRegionalFilter()
  const { roles } = useRoles()
  const { activeLocations } = useLocations()
  const { state } = useUserContext()
  const email = state.userProfile?.email

  const regions = getStoredRegionalFilter() || {}
  const validLocationIds = Object.values(regions).flat()
  const validLocationIdSet = new Set(validLocationIds)

  const { eligibilityConnections, globalEligibilityConnection } =
    useBizEligibilityConnections({
      workerId,
      businessFilters: { locations: validLocationIds },
    })

  const { filteredLocations, filteredRoles } = useMemo(() => {
    const filteredLocations = activeLocations.filter((location) =>
      validLocationIdSet.has(location.locationId),
    )
    const filteredRoles = roles.filter(
      (role) =>
        !role.location?.locationId ||
        validLocationIdSet.has(role.location?.locationId),
    )
    return { filteredLocations, filteredRoles }
  }, [activeLocations, roles])

  const modalReady =
    email &&
    eligibilityConnections &&
    globalEligibilityConnection !== undefined &&
    roles?.length &&
    activeLocations?.length

  return (
    <Row style={{ justifyContent: 'center' }} flexCol>
      {modalReady ? (
        <IneligibleSelect
          eligibilityConnections={eligibilityConnections}
          globalEligibilityConnection={globalEligibilityConnection}
          roles={filteredRoles}
          locations={filteredLocations}
          workerId={workerId}
          companyId={companyId}
          setPayload={setPayload}
          initiatedBy={email}
        />
      ) : (
        <LoadingSpinner />
      )}
    </Row>
  )
}
