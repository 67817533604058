import { DEFAULT_TIMEZONE } from '@traba/consts'
import {
  ShiftPayType,
  ShiftStatus,
  WorkerShiftWithWorkerDetails,
} from '@traba/types'
import { isAfter } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import {
  Button,
  ButtonVariant,
  EmptyPlaceholderTile,
  Input,
  Row,
} from 'src/components'
import { useTimeToDestinations } from 'src/hooks/useTimeToDestinations'
import { useUserPermissions } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { anyToDate } from 'src/shared/utils/dateUtils'
import { UserRolePermission } from 'src/types'
import useNavigationGuard from '../NavigationGuard/NavigationGuard'
import Skeletons from '../Skeletons/Skeletons'
import { WorkerOnShiftTableActions } from './components/WorkerOnShiftTableActions'
import { WorkerOnShiftTableHeader } from './components/WorkerOnShiftTableHeader'
import { MobileWorkersOnShiftListItem } from './MobileWorkersOnShiftListItem'
import { WorkersOnShiftTableProps } from './WorkersOnShiftTable.hooks'

export default function MobileWorkersOnShiftTable(
  props: WorkersOnShiftTableProps,
) {
  const {
    additionalWorkerActions,
    connections,
    shift,
    isUpcoming,
    activeWorkerShifts,
    inactiveWorkerShifts = [],
    workersOnBackup,
    editMode,
    onClickInviteWorker,
    useTotalTitle,
    totalTimeWorked,
    totalChargedCents,
    isFromTimesheetDetails = false,
    hasEdits = false,
    refetchWorkerShifts,
    hideHeader,
    isEditSlotsEnabled = false,
    onClickEditWorkerSlots,
  } = props

  useNavigationGuard({
    when: !!editMode,
    message: 'You have unsaved edits, do you really want to leave?',
  })

  const { timeToDestinations } = useTimeToDestinations(shift?.shiftId)
  const userCanViewWages = useUserPermissions([UserRolePermission.ViewPay])
  const timezone = shift?.timezone || DEFAULT_TIMEZONE
  const shiftIsCanceled = shift && shift.status === ShiftStatus.CANCELED
  const showEarnedMoney = userCanViewWages && !isUpcoming && !shiftIsCanceled
  const isPBUShift = shift?.payType === ShiftPayType.UNIT

  const [allSelected, setAllSelected] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<
    WorkerShiftWithWorkerDetails[]
  >([])
  const [searchFilter, setSearchFilter] = useState('')

  const backupWorkerIds = new Set(
    (workersOnBackup || []).map((w) => w.workerId),
  )
  const allWorkers: Array<WorkerShiftWithWorkerDetails> = useMemo(
    () => [
      ...activeWorkerShifts,
      ...(workersOnBackup || []),
      ...inactiveWorkerShifts,
    ],
    [activeWorkerShifts, workersOnBackup, inactiveWorkerShifts],
  )
  const timeToDestinationsByWorkerId = new Map(
    timeToDestinations?.workerShiftTimeToDestinations?.map((ttd) => [
      ttd.workerId,
      ttd,
    ]) || [],
  )

  // Filter the allWorkers array based on searchString and selectedWorkerShifts
  const filteredWorkers = allWorkers.filter((workerShift) => {
    const { worker: workerInfo } = workerShift

    // Check if the workerShift is in the selectedWorkerShifts array
    const isInSelectedShifts =
      !editMode &&
      selectedRows.some(
        (selectedRow) => selectedRow.workerId === workerShift.workerId,
      )

    // Check if the name includes the searchString (case-insensitive)
    const name = workerInfo.firstName + ' ' + workerInfo.lastName
    const includesSearchString = name
      .toLowerCase()
      .includes(searchFilter.toLowerCase())

    // Keep the worker if either it's in selectedWorkerShifts or the name includes the searchString
    return isInSelectedShifts || includesSearchString
  })

  useEffect(() => {
    if (
      filteredWorkers?.length &&
      filteredWorkers?.length === selectedRows?.length
    ) {
      setAllSelected(true)
    } else {
      setAllSelected(false)
    }
  }, [selectedRows, filteredWorkers, setAllSelected])

  const handleSelectAllClick = () => {
    allSelected ? setSelectedRows([]) : setSelectedRows(filteredWorkers)
    setAllSelected((allSelected) => {
      return !allSelected
    })
  }

  const workerListToUse = isFromTimesheetDetails ? filteredWorkers : allWorkers

  const IsShiftInTheFuture = isAfter(
    anyToDate(shift.businessStartTime ?? shift.startTime),
    new Date(),
  )

  const numberOfBackupWorkers = workersOnBackup?.length || 0

  const workersTotal =
    activeWorkerShifts.length +
    numberOfBackupWorkers +
    inactiveWorkerShifts.length

  if (!shift) {
    return null
  }

  const searchInput = (
    <Input
      placeholder="Search workers..."
      leftIconName="search"
      name="workerSearch"
      containerStyle={{
        margin: 0,
      }}
      type="text"
      defaultValue=""
      width="225px"
      value={searchFilter}
      onChange={(e) => {
        e.preventDefault()
        setSearchFilter(e.target.value)
      }}
      onClear={() => setSearchFilter('')}
    />
  )

  const bulkActionsRow = (
    <Row justifyBetween pt={theme.space.xxs} px={theme.space.xs} fullWidth>
      {hideHeader && (
        <Button
          variant={ButtonVariant.TEXT}
          onClick={() => {
            handleSelectAllClick()
          }}
          style={{
            color: theme.colors.brand,
            padding: 0,
          }}
        >
          {allSelected ? 'Deselect all' : 'Select all'}
        </Button>
      )}
      <WorkerOnShiftTableActions
        {...props}
        shift={shift}
        connections={connections}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        editMode={editMode}
        timezone={timezone}
      />
    </Row>
  )

  if (props.isLoadingWorkerShifts) {
    return (
      <Row px={theme.space.xs}>
        <Skeletons count={1} component="WORKERS_LIST" />
      </Row>
    )
  }

  return (
    <>
      <Row alignCenter wrap justifyBetween pl={0}>
        {!hideHeader && (
          <>
            <WorkerOnShiftTableHeader
              useTotalTitle={useTotalTitle}
              totalChargedCents={totalChargedCents}
              totalTimeWorked={totalTimeWorked}
              workersTotal={workersTotal}
              isUpcoming={isUpcoming}
              filteredWorkers={workerListToUse}
              IsShiftInTheFuture={IsShiftInTheFuture}
              shift={shift}
              numberOfBackupWorkers={numberOfBackupWorkers}
              userCanViewWages={userCanViewWages}
              onClickInviteWorker={onClickInviteWorker}
              isEditSlotsEnabled={isEditSlotsEnabled}
              onClickEditWorkerSlots={onClickEditWorkerSlots}
            />
            {isFromTimesheetDetails && (
              <Row mt={theme.space.xs} mb={theme.space.xxs}>
                {searchInput}
                {!hasEdits && (
                  <Row ml={theme.space.xxs}>
                    {additionalWorkerActions || null}
                  </Row>
                )}
              </Row>
            )}
            {isFromTimesheetDetails && hasEdits && (
              <Row ml={theme.space.xxs}>{additionalWorkerActions || null}</Row>
            )}
          </>
        )}
        {!isFromTimesheetDetails &&
          workerListToUse.length > 0 &&
          bulkActionsRow}
      </Row>
      {workerListToUse.length > 0 ? (
        <>
          {workerListToUse.map((workerShift, index, array) => {
            const isLastRow = index === array.length - 1
            return (
              <MobileWorkersOnShiftListItem
                key={index}
                {...props}
                workerShift={workerShift}
                timeToDestinationsByWorkerId={timeToDestinationsByWorkerId}
                shift={shift}
                editMode={editMode}
                isUpcoming={isUpcoming}
                isBackup={backupWorkerIds.has(workerShift.workerId)}
                timezone={timezone}
                showEarnedMoney={showEarnedMoney}
                isPBUShift={isPBUShift}
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
                isFromTimesheetDetails={isFromTimesheetDetails}
                refetchWorkerShifts={refetchWorkerShifts}
                tableRowStyle={
                  hideHeader
                    ? {
                        paddingLeft: theme.space.xs,
                        paddingRight: theme.space.xs,
                      }
                    : {}
                }
                hideBottomDivider={isLastRow}
              />
            )
          })}
        </>
      ) : (
        <EmptyPlaceholderTile
          iconName="info"
          title="You don't currently have any workers on this shift"
          subtitle={
            IsShiftInTheFuture && !hideHeader
              ? 'Click on Invite Workers to invite workers to the shift.'
              : ''
          }
          style={hideHeader ? { border: 'none' } : {}}
        />
      )}
    </>
  )
}
