import { useCallback } from 'react'
import Input from './Input/Input'

interface SearchBarProps {
  filtersKey: string
  searchPlaceholder?: string
  searchFilterText: string
  setSearchFilterText: (newString: string) => void
  onFocus?: () => void
}

export function SearchBar({
  searchPlaceholder = 'Search...',
  filtersKey,
  searchFilterText,
  setSearchFilterText,
  onFocus,
}: SearchBarProps) {
  const onChangeSearchFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.preventDefault()
      setSearchFilterText(e.target.value)
    },
    [],
  )
  const onClearSearchFilter = useCallback(() => {
    setSearchFilterText('')
  }, [])

  return (
    <Input
      placeholder={searchPlaceholder}
      leftIconName="search"
      name={`${filtersKey}Search`}
      containerStyle={{ maxWidth: '480px', margin: 0 }}
      type="text"
      defaultValue=""
      width="100%"
      value={searchFilterText}
      onChange={onChangeSearchFilter}
      onClear={onClearSearchFilter}
      onFocus={onFocus}
    />
  )
}
