import {
  InputLabel as MaterialInputLabel,
  Select as MaterialSelect,
} from '@mui/material'
import styled, { css } from 'styled-components'

export const SelectContainer = styled.div``

export const Select = styled(MaterialSelect)`
  padding: 5.5px 7px;

  > .MuiOutlinedInput-input {
    color: ${({ theme }) => theme.colors.MidnightBlue};
  }

  &:hover > .MuiOutlinedInput-notchedOutline {
    ${({ disabled, theme }) =>
      !disabled
        ? css`
            border-color: ${theme.colors.Grey50};
          `
        : ''}
  }
`

export const InputLabel = styled(MaterialInputLabel)`
  z-index: 0;
`
