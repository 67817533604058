import * as Sentry from '@sentry/react'
import {
  useMutation,
  useQuery,
  useQueryClient,
  useQueries,
  UseQueryResult,
} from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { DEFAULT_PAGE_SIZE } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  ChargeWithApproval,
  Money,
  Pagination,
  RecalculatedWorkedTime,
  ShiftWithWorkerShiftsAndCharges,
  WorkerShiftWithCharges,
  WorkerWithWorkerShiftAndCharges,
} from '@traba/types'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { ONE_MINUTE_IN_MS } from 'src/libs/constants'
import {
  TimesheetSummaryResponse,
  TimesheetsGroupBy,
} from 'src/screens/TimeSheets/types'
import { multiplyMoney } from 'src/utils/moneyUtils'
import { getWorkerEarningEstimate } from './useWorkerEarningEstimate'

export const TIMESHEET_WORKER_SHIFT_QUERY_KEY =
  'workerShiftsForTimesheetQueryKey'
const TIMESHEET_SUMMARY_QUERY_KEY = 'timesheetSummaryQueryKey'
const TIMESHEET_WORKER_SHIFT_CHARGE_QUERY_KEY =
  'timesheetWorkerShiftChargeQueryKey'

export interface WorkerShiftsForTimesheetsProps {
  limit?: number
  startAt?: number
  groupBy: TimesheetsGroupBy
  before?: Date
  after?: Date
  hideApproved?: boolean
  hideUnapproved?: boolean
  activeLocationIds?: string[]
  firstName?: string
  lastName?: string
  enabled?: boolean
  shiftIds?: string[]
  supervisorId?: string
}

export type TimeSheetsWorkerCharge = {
  charge: Money | undefined
  updatedCharge?: Money | undefined
  shouldAutoApprove?: boolean
}

export type TimeSheetsEstimatedChargeResponse = {
  data: TimeSheetsWorkerCharge | undefined
  isLoading: boolean
  refetch: () => void
}

export type TestTimeSheetsEstimatedChargeResponse = {
  data: RecalculatedWorkedTime | undefined
  isLoading: boolean
  refetch: () => void
}

export type TimeSheetsWorkerShiftResponse = {
  result: WorkerWithWorkerShiftAndCharges[] | ShiftWithWorkerShiftsAndCharges[]
  count: number
}

interface TimesheetSummaryProps
  extends Pick<
    WorkerShiftsForTimesheetsProps,
    'before' | 'after' | 'activeLocationIds' | 'supervisorId'
  > {}

export type WorkerShiftIdDto = {
  workerId: string
  shiftId: string
}

export const combineWorkerShiftCharges = (charges: ChargeWithApproval[]) => {
  return charges.reduce(
    (acc, charge) => {
      return {
        amount: acc.amount + charge.amount,
        currency: charge.currency,
      }
    },
    { amount: 0, currency: 'USD' },
  )
}

const approveWorkerShiftCharges = async (
  workerShiftIds: WorkerShiftIdDto[],
) => {
  try {
    await trabaApi.patch('/charges/approve-worker-shifts-charges', {
      workerShiftIds,
    })
  } catch (error) {
    console.error(error)
    Sentry.captureException(error, {
      tags: { action: `Approve Worker Shifts` },
      extra: { workerShiftIds },
    })
    throw error
  }
}

const unapproveWorkerShiftCharges = async (
  workerShiftIds: WorkerShiftIdDto[],
) => {
  try {
    await trabaApi.patch('/charges/unapprove-worker-shifts-charges', {
      workerShiftIds,
    })
  } catch (error) {
    console.error(error)
    Sentry.captureException(error, {
      tags: { action: `Unapprove Worker Shifts` },
      extra: { workerShiftIds },
    })
    throw error
  }
}

const getWorkerShiftCharges = async (
  shiftId: string,
  workerShift: WorkerShiftWithCharges,
): Promise<TimeSheetsWorkerCharge | undefined> => {
  const originalCharge = combineWorkerShiftCharges(workerShift.charges)
  if (!workerShift.hasPendingEdits) {
    return {
      charge: originalCharge,
      shouldAutoApprove: true,
    }
  }

  try {
    const earningsEstimate = await getWorkerEarningEstimate(
      shiftId,
      workerShift,
    )
    return {
      charge: originalCharge,
      updatedCharge: earningsEstimate
        ? multiplyMoney(
            earningsEstimate.grossPay,
            earningsEstimate.calculatedMarkup + 1,
          )
        : undefined,
      shouldAutoApprove: earningsEstimate?.shouldAutoApprove,
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}

const queryWorkerShiftsForTimesheets = async (
  props: WorkerShiftsForTimesheetsProps,
) => {
  const {
    groupBy,
    hideApproved,
    hideUnapproved,
    before,
    after,
    activeLocationIds,
    firstName,
    lastName,
    limit,
    startAt,
    shiftIds,
    supervisorId,
  } = props
  try {
    const response = await trabaApi.post(
      '/timesheets/query-worker-shift-timesheets',
      {
        limit: limit ?? DEFAULT_PAGE_SIZE,
        startAt: startAt ?? 0,
        groupBy: groupBy,
        before,
        after,
        activeLocationIds,
        ...(hideApproved
          ? { excludeWorkerShiftsWithApprovedCharges: true }
          : {}),
        ...(hideUnapproved
          ? { excludeWorkerShiftsWithUnapprovedCharges: true }
          : {}),
        firstName,
        lastName,
        shiftIds,
        supervisorId,
      },
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

const queryTimesheetsSummary = async (props: TimesheetSummaryProps) => {
  const { before, after, activeLocationIds, supervisorId } = props
  try {
    const response = await trabaApi.post(
      '/timesheets/query-timesheets-summary',
      {
        before,
        after,
        activeLocationIds,
        supervisorId,
      },
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const useWorkerShiftsForTimesheetWithPagination = (
  props: WorkerShiftsForTimesheetsProps,
  pagination: Pagination,
): UseQueryResult<TimeSheetsWorkerShiftResponse, Error> => {
  return useQuery<TimeSheetsWorkerShiftResponse, Error>({
    queryKey: [TIMESHEET_WORKER_SHIFT_QUERY_KEY, props, pagination],
    queryFn: () => queryWorkerShiftsForTimesheets({ ...props, ...pagination }),
    staleTime: ONE_MINUTE_IN_MS,
    enabled: props.enabled,
  })
}

export const useTimesheetSummary = (props: TimesheetSummaryProps) => {
  const { isLoading, isError, data, refetch } = useQuery<
    TimesheetSummaryResponse,
    Error
  >({
    queryKey: [TIMESHEET_SUMMARY_QUERY_KEY, props],
    queryFn: () => queryTimesheetsSummary(props),
    staleTime: ONE_MINUTE_IN_MS,
  })

  return {
    isLoading,
    isError,
    data,
    refetch,
  }
}

export const useTimeSheetMutations = () => {
  const { showSuccess, showError } = useAlert()
  const queryClient = useQueryClient()
  const approveWorkerShiftChargesMutation = useMutation({
    mutationFn: approveWorkerShiftCharges,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TIMESHEET_WORKER_SHIFT_QUERY_KEY],
      })
      queryClient.invalidateQueries({ queryKey: [TIMESHEET_SUMMARY_QUERY_KEY] })
      showSuccess('Successfully approved worker shifts')
    },
    onError: () => {
      showError(
        'There was an error approving the worker shifts. Please try again.',
      )
    },
  })

  const unapproveWorkerShiftChargesMutation = useMutation<
    void,
    AxiosError,
    WorkerShiftIdDto[]
  >({
    mutationFn: unapproveWorkerShiftCharges,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TIMESHEET_WORKER_SHIFT_QUERY_KEY],
      })
      queryClient.invalidateQueries({ queryKey: [TIMESHEET_SUMMARY_QUERY_KEY] })
      showSuccess('Successfully unapproved worker shifts')
    },
    onError: () => {
      showError(
        'There was an error unapproving the worker shifts. Please try again.',
      )
    },
  })

  return {
    approveWorkerShifts: approveWorkerShiftChargesMutation.mutateAsync,
    unapproveWorkerShifts: unapproveWorkerShiftChargesMutation.mutateAsync,
  }
}

/**
 * Calculates and returns charge for workerShift. If workerShift has pending adjustments, queries
 * /my-company/worker-shifts/${shiftId}/worker-shift-estimate to get estimated charge. Otherwise
 * pulls from charges on workerShift.
 *
 * Returns a list of [{ data, isLoading, refetch }, { data, isLoading, refetch }] for each workerShift.
 * Data is type TimeSheetsWorkerCharge representing an aggregation of all the ws charges.
 */
export const useTimeSheetWorkerShiftCharges = (
  workerShifts: WorkerShiftWithCharges[],
): TimeSheetsEstimatedChargeResponse[] => {
  const workerShiftsResponse = useQueries({
    queries: workerShifts.map((workerShift) => ({
      queryKey: [
        TIMESHEET_WORKER_SHIFT_CHARGE_QUERY_KEY,
        {
          shiftId: workerShift.shiftId,
          workerId: workerShift.workerId,
          clockInTime: workerShift.clockInTime,
          clockOutTime: workerShift.clockOutTime,
          breaks: workerShift.breaks,
          hasPendingEdits: workerShift.hasPendingEdits,
          timeWorked: workerShift.timeWorked,
          grossPay: workerShift.grossPay,
          charges: workerShift.charges,
        },
      ],
      queryFn: () => getWorkerShiftCharges(workerShift.shiftId, workerShift),
      staleTime: ONE_MINUTE_IN_MS,
    })),
  })

  return workerShiftsResponse.map(({ isLoading, data, refetch }) => ({
    isLoading,
    data: data,
    refetch,
  }))
}

export const useNavigateToTimeSheetDailyView = () => {
  const navigate = useNavigate()

  const navigateToTimeSheetDailyView = (shiftId: string, day: Date) => {
    const params = new URLSearchParams()
    params.append('view', 'daily')
    params.append('shiftId', shiftId)
    params.append('day', day.toISOString())
    navigate({
      pathname: '/timesheet/totals',
      search: `?${params.toString()}`,
    })
  }

  return navigateToTimeSheetDailyView
}
