import { useAlert } from '@traba/context'
import { useHotSettings } from '@traba/hooks'
import {
  ArchiveMemberParams,
  ArchiveUserModalContent,
} from '@traba/react-components'
import { getStartOfRecentFiveMinBlock } from '@traba/utils'
import { useCallback, useState } from 'react'

import { useMemberIdToMemberMap, useMembers } from 'src/hooks/useMembers'
import { useShiftsLocationsForSupervisor } from 'src/hooks/useShiftsLocationsForSupervisor'
import { UserData } from 'src/types'

export type ArchiveUserModalProps = {
  member: UserData
  handleClose: () => void
}

export function ArchiveUserModal({
  member,
  handleClose,
}: ArchiveUserModalProps) {
  const { hotSettings } = useHotSettings()

  // using the start of the 5min block to take advantage of cached query with 5min stale-time
  const startOfLastFiveMinBlock = getStartOfRecentFiveMinBlock(
    new Date(),
  ).toISOString()

  const { isLoading: isShiftLocationsLoading, locations } =
    useShiftsLocationsForSupervisor({
      supervisorId: member.uid,
      minShiftStartTime: startOfLastFiveMinBlock,
    })
  const {
    members,
    isLoading: isMembersLoading,
    archiveMember,
    isArchiveMemberLoading,
  } = useMembers()
  const { memberIdToMemberMap, isLoading: isMemberIdToMemberMapLoading } =
    useMemberIdToMemberMap()
  const { showError } = useAlert()
  const [activeShiftsError, setActiveShiftsError] = useState(false)

  const handleArchiveClick = useCallback(
    async ({
      replacementUserId,
      replacementSupervisorsForLocations,
    }: ArchiveMemberParams) => {
      await archiveMember(
        {
          uid: member.uid,
          replacementUserId: replacementUserId || undefined,
          replacementSupervisorsForLocations,
        },
        {
          onSuccess: handleClose,
          onError: (err) => {
            if (err.message === 'archive/active-shifts') {
              setActiveShiftsError(true)
              return
            } else {
              showError(
                'An error has occurred. Please try again or contact support if the issue persists.',
                `Unable to archive ${member.role ? 'member' : 'contact'}`,
              )
              handleClose()
            }
          },
        },
      )
      window.analytics.track(`User Archived User`, {
        user: member,
      })
    },
    [archiveMember, member, handleClose, showError],
  )

  return (
    <ArchiveUserModalContent
      member={member}
      replacementMembers={members}
      handleClose={handleClose}
      onArchiveMember={handleArchiveClick}
      loading={
        isArchiveMemberLoading ||
        isShiftLocationsLoading ||
        isMembersLoading ||
        isMemberIdToMemberMapLoading
      }
      activeShiftsError={activeShiftsError}
      locationsForFutureShifts={locations}
      showLocationSpecifiedSupervisorDropdown={
        hotSettings?.enableRegionalAccessPhase2
      }
      memberIdToMemberMap={memberIdToMemberMap}
    />
  )
}
