import { ComponentProps, ReactNode } from 'react'
import styled from 'styled-components'

export type ClickableProps = {
  children: ReactNode
} & ComponentProps<'div'>

const ClickableContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
`

export function Clickable(props: ClickableProps) {
  const { children, ...other } = props
  return <ClickableContainer {...(other as any)}>{children}</ClickableContainer>
}
