import { Switch } from '@mui/material'
import { theme } from '@traba/theme'

import {
  NotificationPreferenceStatus,
  SettingsMap,
  UserNotificationSettingType,
} from '@traba/types'
import { mapStatusToDisplayStatus } from '@traba/utils'
import Row from '../base-components/Row'
import { SvgIcon } from '../base-components/SvgIcon'
import { Table, Td, Tr } from '../base-components/Table/Table'

import { Text } from '../base-components/Text'
import { NotificationSettingsLocationSelect } from './NotificationSettingsLocationSelect'
import { NotificationSettingsShiftsSelect } from './NotificationSettingsShiftsSelect'

interface Props {
  isAllOff: boolean
  headers: string[]
  settings: UserNotificationSettingType[]
  isEditing: boolean
  setIsLocationFilterOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsShiftsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  updatedItems: SettingsMap
  setUpdatedItems: React.Dispatch<React.SetStateAction<SettingsMap>>
  setEditingSettingTypes: React.Dispatch<
    React.SetStateAction<UserNotificationSettingType[] | null>
  >
  handleUserHasNoConsent?: () => void
  hasSMSConsent?: boolean | undefined
}
const getTextColor = (
  isAllOff: boolean,
  isSettingOn: boolean,
  isEditing: boolean,
) => {
  if (isAllOff) {
    return theme.colors.Grey40
  }
  if (isEditing) {
    if (isSettingOn) {
      return theme.colors.Violet
    }
    return theme.colors.Grey40
  }
  return isSettingOn ? theme.colors.MidnightBlue : theme.colors.Grey40
}

export const NotificationSettingsTable = ({
  headers,
  settings,
  isEditing,
  setAnchorEl,
  setIsLocationFilterOpen,
  setIsShiftsFilterOpen,
  updatedItems,
  setUpdatedItems,
  setEditingSettingTypes,
  isAllOff,
  handleUserHasNoConsent,
  hasSMSConsent,
}: Props) => {
  return (
    <Table headers={headers}>
      {settings.map((settingType, index) => {
        const setting = updatedItems?.[settingType]
        if (!setting) {
          return null
        }

        const isSettingOn = setting.status !== NotificationPreferenceStatus.NONE
        const textColor = getTextColor(isAllOff, isSettingOn, isEditing)

        const displayStatus = mapStatusToDisplayStatus(setting.status)
        return (
          <Tr key={index} style={{ height: '62px' }}>
            <Td style={{ width: '45%' }}>{setting.description}</Td>
            <Td style={{ width: '10%' }}>
              <Row alignCenter>
                <Text variant={isSettingOn ? 'h6' : 'body2'}>
                  {displayStatus}
                </Text>
                {isSettingOn && !isEditing && (
                  <SvgIcon
                    name="dot"
                    size={8}
                    fill={theme.colors.Violet}
                    style={{ marginLeft: theme.space.xxxs }}
                  />
                )}
                {isEditing && (
                  <Switch
                    checked={isSettingOn}
                    onChange={() => {
                      if (
                        !hasSMSConsent &&
                        handleUserHasNoConsent &&
                        !isSettingOn
                      ) {
                        handleUserHasNoConsent()
                        return
                      }
                      setUpdatedItems({
                        ...updatedItems,
                        [setting.notificationType]: {
                          ...setting,
                          status: isSettingOn
                            ? NotificationPreferenceStatus.NONE
                            : NotificationPreferenceStatus.ALL,
                        },
                      })
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
              </Row>
            </Td>
            <Td>
              <NotificationSettingsShiftsSelect
                setting={setting}
                textColor={textColor}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  if (!isEditing) {
                    return
                  }
                  setAnchorEl(event.currentTarget)
                  setIsShiftsFilterOpen(true)
                  setEditingSettingTypes([setting.notificationType])
                }}
              />
            </Td>
            <Td style={{ width: '20%' }}>
              <NotificationSettingsLocationSelect
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  if (!isEditing) {
                    return
                  }
                  setAnchorEl(event.currentTarget)
                  setIsLocationFilterOpen(true)
                  setEditingSettingTypes([setting.notificationType])
                }}
                textColor={textColor}
                selectedLocationLength={setting.locationIds?.length ?? 0}
                isSettingOn={isSettingOn}
              />
            </Td>
          </Tr>
        )
      })}
    </Table>
  )
}
