import * as Sentry from '@sentry/react'
import {
  ActionCodeSettings,
  getAuth,
  sendEmailVerification,
} from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'
import config from 'src/config'

const LAST_RESEND_EMAIL_TIME_KEY = 'lastResendEmailTime'
const RESEND_TIME_LIMIT = 60

export const useEmailVerification = () => {
  const [lastResendEmailTimeString, setLastResendEmailTimeString] =
    useLocalStorage<string | undefined>(LAST_RESEND_EMAIL_TIME_KEY, undefined)
  const [timeRemainingBeforeResend, setTimeRemainingBeforeResend] = useState<
    number | null
  >(null)

  const onSendVerificationEmail = async () => {
    const auth = getAuth()
    const user = auth.currentUser
    const actionCodeSettings: ActionCodeSettings = {
      url: config.FRONTEND_URL || 'https://app.traba.work/',
    }

    if (user) {
      setLastResendEmailTimeString(new Date().toISOString())
      setTimeRemainingBeforeResend(RESEND_TIME_LIMIT)
      sendEmailVerification(user, actionCodeSettings).catch(function (error) {
        Sentry.captureException(error, {
          tags: { action: 'useEmailVerification -> onSendVerificationEmail()' },
        })
      })
    }
  }

  /**
   * Update the time remaining before the user can resend the email
   */
  useEffect(() => {
    const intervalId = window.setInterval(() => {
      if (lastResendEmailTimeString) {
        const lastResendEmailTime = new Date(lastResendEmailTimeString)
        const now = new Date()
        const timeDiff = Math.floor(
          (now.getTime() - lastResendEmailTime.getTime()) / 1000,
        )
        if (timeDiff > RESEND_TIME_LIMIT) {
          setTimeRemainingBeforeResend(0)
          setLastResendEmailTimeString('')
          clearInterval(intervalId)
        } else {
          setTimeRemainingBeforeResend(RESEND_TIME_LIMIT - timeDiff)
        }
      }
    }, 1000)
    return () => clearInterval(intervalId)
  }, [lastResendEmailTimeString, setLastResendEmailTimeString])

  return {
    onSendVerificationEmail,
    timeRemainingBeforeResend,
  }
}
