import {
  Autocomplete as MuiAutocomplete,
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
  Chip,
  FilterOptionsState,
  TextField,
} from '@mui/material'
import { ChatSuggestion } from '@traba/types'
import { useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import { Avatar, ButtonVariant, Row, Text } from 'src/components'
import { containsText } from 'src/components/base/Autocomplete/Autocomplete'
import * as S from 'src/components/base/Autocomplete/Autocomplete.styles'
import { useChatSuggestions } from 'src/hooks/useChatSuggestion'
import {
  FiltersAction,
  CalendarFilters as CalendarFiltersType,
} from 'src/hooks/useShiftFilters.hooks'
import { theme } from 'src/libs/theme'

interface Props {
  dispatch: React.Dispatch<FiltersAction>
  filters?: CalendarFiltersType
}

export const CalendarFindWorker = ({ dispatch, filters }: Props) => {
  const [query, setQuery] = useState('')
  const [input, setInput] = useState('')
  const { suggestions, isLoading: isLoadingSuggestions } =
    useChatSuggestions(query)

  useEffect(() => {
    if (filters?.workerId === undefined) {
      setInput('')
    }
  }, [filters?.workerId])

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} label={'Search workers'} />
  )

  const renderTags = (
    tags: ChatSuggestion[],
    getTagProps: AutocompleteGetTagProps,
  ) =>
    tags.map((option: ChatSuggestion, index: number) => (
      <Chip
        variant={ButtonVariant.OUTLINED}
        label={option.name}
        {...getTagProps({ index })}
      />
    ))

  const filterOptions = (
    options: ChatSuggestion[],
    state: FilterOptionsState<ChatSuggestion>,
  ) =>
    options.filter(
      (o) => !input.includes(o.name) && containsText(o.name, state.inputValue),
    )

  useDebounce(
    () => {
      setQuery(input)
    },
    500,
    [input],
  )

  return (
    <>
      <Text variant="h5" mb={theme.space.xxs} mt={theme.space.med}>
        Find workers
      </Text>
      <S.SearchBoxStyling />
      <MuiAutocomplete
        multiple
        style={{ zIndex: 0 }}
        loading={isLoadingSuggestions}
        filterSelectedOptions
        autoHighlight
        options={suggestions ?? []}
        renderOption={(props, option: ChatSuggestion) => {
          const { name, photoUrl, workerId } = option
          return (
            <li {...props} key={workerId}>
              <Row alignCenter>
                <Avatar src={photoUrl} name={name} size={50} />
                <Text variant="h6" ml={theme.space.xxs}>
                  {name}
                </Text>
              </Row>
            </li>
          )
        }}
        inputValue={input}
        onInputChange={(event, value: string) => {
          if (event) {
            if (event.type === 'keydown') {
              const nativeEvent = event.nativeEvent as KeyboardEvent
              if (nativeEvent.key === 'Enter') {
                setInput(value)
              }
            } else {
              setInput(value)
            }
          }
        }}
        onChange={(_, value) => {
          if (value) {
            const workerIds = value
              .map((chatSuggestion) => chatSuggestion.workerId)
              .filter((workerId): workerId is string => workerId !== undefined)
            dispatch({
              type: 'SET_WORKERS',
              workerIds: workerIds.length === 0 ? undefined : workerIds,
            })
          } else {
            dispatch({ type: 'SET_WORKERS', workerIds: undefined })
          }
        }}
        filterOptions={filterOptions}
        renderInput={renderInput}
        getOptionLabel={(option: ChatSuggestion) => `${option.name}`}
        renderTags={renderTags}
      />
    </>
  )
}
