import { makePlural } from '@traba/string-utils'
import { theme } from '@traba/theme'
import { formatDateString, formatDuration } from '@traba/utils'
import { Card, Col, Icon, Row, Text } from 'src/components/base'

interface Props {
  timePeriod: [Date | null, Date | null]
  day: Date
  locationCount: number
  shiftCount: number
  pendingApprovalCount: number
  workerCount: number
  totalTimeWorked: number
  isTotalsView: boolean
}

export const TimeSheetSummaryCard = (props: Props) => {
  const {
    timePeriod,
    day,
    locationCount,
    shiftCount,
    pendingApprovalCount,
    workerCount,
    totalTimeWorked,
    isTotalsView,
  } = props
  const dateFormatOptions = { timeZone: 'America/New_York' }
  const dateString = isTotalsView
    ? `${formatDateString(timePeriod[0] ?? new Date(), dateFormatOptions)} - ${formatDateString(timePeriod[1] ?? new Date(), dateFormatOptions)}`
    : formatDateString(day, dateFormatOptions)

  return (
    <Card style={{ backgroundColor: theme.colors.Grey10 }}>
      <Text variant="h4" mb={theme.space.sm}>
        SUMMARY
      </Text>
      <Row alignCenter justifyBetween wrap gap={theme.space.sm}>
        <Col>
          <Text variant="caption">{isTotalsView ? 'TIME PERIOD' : 'DAY'}</Text>
          <Row alignCenter>
            <Text variant="h6" ml={theme.space.xxxs}>
              {dateString}
            </Text>
          </Row>
        </Col>
        <Col>
          <Text variant="caption">LOCATIONS</Text>
          <Row alignCenter>
            <Icon name="location" width={20} height={20} />
            <Text variant="h6" ml={theme.space.xxxs}>
              {`${locationCount} location${makePlural(locationCount)}`}
            </Text>
          </Row>
        </Col>
        <Col>
          <Text variant="caption">SHIFTS</Text>
          <Row alignCenter>
            <Icon name="company" width={20} height={20} />
            <Text variant="h6" ml={theme.space.xxxs}>
              {`${shiftCount} shift${makePlural(shiftCount)}`}
            </Text>
          </Row>
        </Col>
        <Col>
          <Text variant="caption">WORKERS</Text>
          <Row alignCenter>
            <Icon name="two_user" width={20} height={20} />
            <Text variant="h6" ml={theme.space.xxxs}>
              {`${workerCount} worker${makePlural(workerCount)}`}
            </Text>
          </Row>
        </Col>
        <Col>
          <Text variant="caption">TOTAL HOURS</Text>
          <Row alignCenter>
            <Text variant="h6" ml={theme.space.xxxs}>
              {formatDuration(totalTimeWorked)}
            </Text>
          </Row>
        </Col>
        <Col>
          <Text variant="caption">APPROVALS</Text>
          <Row alignCenter>
            <Text variant="h6" ml={theme.space.xxxs}>
              {`${pendingApprovalCount} worker time${makePlural(pendingApprovalCount)} pending approval`}
            </Text>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}
