import { CardTile } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Company } from '@traba/types'
import React from 'react'
import { Text } from 'src/components/base'

import { DefaultShiftBreaks } from '../EditCompany/DefaultShiftBreaks'
import * as S from './ReadOnlyCompany.styles'

export type ReadOnlyCompanyProps = {
  company: Company
}

export const ReadOnlyCompanyUI: React.FC<ReadOnlyCompanyProps> = (
  props: ReadOnlyCompanyProps,
) => {
  const defaultBreaks = props.company?.defaultBreaks
  return (
    <S.ReadOnlyCompanyContainer>
      <Text variant="h5">Company Name</Text>
      <Text variant="body2">{props.company?.employerName}</Text>
      <Text variant="h5">Logo</Text>
      {props.company?.companyLogo ? (
        <CardTile size="52px">
          <img
            style={{ maxWidth: theme.space.xl, maxHeight: '48px' }}
            src={props.company?.companyLogo}
            alt="Company Logo"
          />
        </CardTile>
      ) : (
        <Text variant="body2">Please upload logo</Text>
      )}
      <DefaultShiftBreaks
        defaultBreaksLength={defaultBreaks?.breakLength}
        defaultBreaksCount={defaultBreaks?.count}
        isEdit={false}
      />
      {props.company?.kioskPin && (
        <>
          <Text variant="h5">Kiosk mode settings</Text>
          <Text variant="body2">PIN Code: {props.company.kioskPin}</Text>
        </>
      )}
    </S.ReadOnlyCompanyContainer>
  )
}
