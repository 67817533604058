import { MODAL_SIZE } from '@traba/react-components'
import { Dialog } from 'src/components/base/Dialog/Dialog'

export function ConfirmationDialog({
  open,
  confirmationText,
  title,
  onClose,
  onConfirm,
  onConfirmCTA,
}: {
  open: boolean
  title?: string
  confirmationText: string
  onClose: () => void
  onConfirm: () => void
  onConfirmCTA: string
}) {
  return open ? (
    <Dialog
      maxWidth="sm"
      open={!!open}
      onClose={onClose}
      onConfirm={() => onConfirm()}
      onConfirmCTA={onConfirmCTA}
      dialogTitle={title || 'Confirmation'}
      size={MODAL_SIZE.SMALL}
    >
      {confirmationText}
    </Dialog>
  ) : null
}
