import { BookShiftsProps } from '../../BookShiftsScreen'
import { BookShiftsLocationSection } from './BookShiftsLocationSection'

export function BookShiftsSiteLocationSection(props: BookShiftsProps) {
  const {
    shiftRequest: { locationId },
    shiftRequestMetadata: { activeRegion },
  } = props

  return (
    <BookShiftsLocationSection
      locationId={locationId}
      activeRegion={activeRegion}
    />
  )
}
