import useAppAuth from 'src/hooks/authHook'
import { theme } from 'src/libs/theme'
import { Button, ButtonVariant, Text } from '../base'

import GoogleIcon from './AuthIcon'

export const GoogleAuthButton = ({ disabled }: { disabled?: boolean }) => {
  const { handleLoginWithGoogle } = useAppAuth()
  return (
    <Button
      disabled={disabled}
      variant={ButtonVariant.OUTLINED}
      type="button"
      leftIcon={<GoogleIcon />}
      style={{
        width: '100%',
        marginTop: theme.space.sm,
        marginBottom: theme.space.xs,
      }}
      onClick={handleLoginWithGoogle}
    >
      <Text ml={theme.space.xs} variant="h6">
        Continue with Google
      </Text>
    </Button>
  )
}
