import { Badge } from 'src/components/base'
import { theme } from 'src/libs/theme'

export default function CancelFeeBadge({
  cancellationChargeSummary,
  shouldWaiveCancellationFees,
}: {
  cancellationChargeSummary: string
  shouldWaiveCancellationFees: boolean
}) {
  return (
    <Badge
      style={{ marginRight: theme.space.xs }}
      variant="opaqueRed"
      title={`${cancellationChargeSummary} FEE${
        shouldWaiveCancellationFees ? ` WAIVED` : ``
      }`}
    />
  )
}
