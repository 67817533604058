import { CardTile, LoadingSpinner } from '@traba/react-components'
import { RoleAttributeStatus } from '@traba/types'
import { getShiftDateString, getShiftTimeString } from '@traba/utils'
import { useNavigate } from 'react-router-dom'
import { Col, Row, SvgIcon, Text } from 'src/components/base'
import { ClockCode } from 'src/components/base/ClockCode'
import { PointOfContact } from 'src/components/PointOfContact'
import { useRoleAttributes } from 'src/hooks/useAttributes'
import {
  certificationName,
  useCertifications,
} from 'src/hooks/useCertifications'
import { useLocations } from 'src/hooks/useLocations'
import { useMembers } from 'src/hooks/useMembers'
import { useShiftById } from 'src/hooks/useShifts'
import { theme } from 'src/libs/theme'
import { getAddressString } from 'src/utils/stringUtils'

interface Props {
  shiftId: string
  onClickBack: () => void
}

export const AnnouncementShiftDetailsSection = ({
  shiftId,
  onClickBack,
}: Props) => {
  const { isLoading, shift } = useShiftById(shiftId)
  const { getLocationById } = useLocations()
  const { getMemberById } = useMembers()
  const { certifications } = useCertifications()
  const { roleAttributes } = useRoleAttributes(RoleAttributeStatus.Active)
  const navigate = useNavigate()

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!shift) {
    return null
  }
  const {
    startTime,
    endTime,
    timezone,
    requiredCertifications,
    clockOutCode,
    confirmationCode,
    requiredAttributes,
  } = shift
  const location = getLocationById(shift.locationId)
  const parkingLocation = getLocationById(shift?.parkingLocationId || '')
  const pointOfContact = getMemberById(shift?.supervisorId || '')

  return (
    <>
      <Row mb={theme.space.sm} justifyBetween alignCenter>
        <CardTile size={'40px'} onClick={onClickBack}>
          <SvgIcon name="doubleForward" color={theme.colors.Grey50} />
        </CardTile>
        <Text
          variant="link"
          onClick={() => {
            navigate(`/calendar/${shiftId}`)
          }}
        >
          Go to shift details
        </Text>
      </Row>
      <Text variant="h5" mb={theme.space.xxs}>
        {shift?.shiftRole}
      </Text>
      <Text variant="body2">{`${getShiftDateString(
        startTime,
        endTime,
        timezone,
      )}, ${getShiftTimeString(startTime, endTime, timezone)}`}</Text>
      {location && (
        <Text variant="body2">{getAddressString(location.address)}</Text>
      )}
      <Text variant="h6" mt={theme.space.med} mb={theme.space.xxs}>
        Shift Details
      </Text>
      <Row justifyBetween>
        <Col>
          <Text variant="caption" mb={theme.space.xxs}>
            CLOCK IN
          </Text>
          <ClockCode
            code={confirmationCode}
            tileHeight={32}
            tileWidth={24}
            fontSize={14}
          />
        </Col>
        <Col width={'50%'}>
          <Text variant="caption" mb={theme.space.xxs}>
            CLOCK OUT
          </Text>
          <ClockCode
            code={clockOutCode}
            tileHeight={32}
            tileWidth={24}
            fontSize={14}
          />
        </Col>
      </Row>
      <Row mt={theme.space.sm} justifyBetween>
        <Col>
          <Text variant="caption" mb={theme.space.xxs}>
            REQUIREMENTS
          </Text>
          {requiredAttributes ? (
            <>
              {requiredAttributes.map((a) => {
                const attr = roleAttributes?.find(
                  (attr) => attr.type === (typeof a === 'string' ? a : a.type),
                )
                if (!attr) {
                  return null
                }
                return <Text>{attr.displayName}</Text>
              })}
            </>
          ) : (
            <Text>None</Text>
          )}
        </Col>
        <Col width={'50%'}>
          <Text variant="caption" mb={theme.space.xxs}>
            CERTIFICATIONS
          </Text>
          <Text variant="body1">
            {requiredCertifications?.length && certifications
              ? requiredCertifications
                  .map((rc) => certificationName(rc, certifications))
                  .join(', ')
              : 'None'}
          </Text>
        </Col>
      </Row>
      <Row mt={theme.space.sm} justifyBetween>
        {pointOfContact?.firstName && (
          <Col width="50%">
            <Text variant="caption" mb={theme.space.xxs}>
              ON-SITE POINT OF CONTACT
            </Text>
            <PointOfContact user={pointOfContact} slim />
          </Col>
        )}
        {parkingLocation?.address && (
          <Col width="50%">
            <Text variant="caption" mb={theme.space.xxs}>
              PARKING ADDRESS
            </Text>
            <Text variant="body1">{parkingLocation.name}</Text>
            <Text variant="body1">
              {getAddressString(parkingLocation.address)}
            </Text>
          </Col>
        )}
      </Row>
    </>
  )
}
