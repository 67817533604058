import {
  Worker,
  WorkerShiftWithCharges,
  WorkerWithWorkerShiftAndCharges,
} from '@traba/types'
import { BizToWorkerConnection } from 'src/hooks/useConnections'
import { WorkerShiftIdDto } from 'src/hooks/useTimesheet'
import { TimesheetsGroupBy } from 'src/screens/TimeSheets/types'

export enum ChargeApprovalAction {
  APPROVE = 'Approve',
  UNAPPROVE = 'Unapprove',
}

export interface BaseTimeSheetsTableWorkerShiftListItemProps {
  workerShift: WorkerShiftWithCharges
  worker: Worker
  editMode: boolean
  onEditWorkerShift?: (
    newWorkerShift: Partial<WorkerWithWorkerShiftAndCharges> & {
      workerId: string
      shiftId: string
    },
  ) => void
  connections?: BizToWorkerConnection
  refetchWorkerShifts: () => void
  userCanViewWages: boolean
  userCanManageTimesheets: boolean
  userCanManagePaymentSettings: boolean
  groupBy: TimesheetsGroupBy
  approveWorkerShifts: (workerShiftIds: WorkerShiftIdDto[]) => Promise<void>
  unapproveWorkerShifts: (workerShiftIds: WorkerShiftIdDto[]) => Promise<void>
  hideHeaderRow?: boolean
  loggingContext?: TimeSheetsLoggingContext
}

export interface TimeSheetsTableBaseProps {
  groupBy: TimesheetsGroupBy
  refetchData: () => void
  isLoading: boolean
  onEditWorkerShift?: (
    newWorkerShift: Partial<WorkerWithWorkerShiftAndCharges> & {
      workerId: string
      shiftId: string
    },
  ) => void
  editMode: boolean
  approveWorkerShifts: (workerShiftIds: WorkerShiftIdDto[]) => Promise<void>
  unapproveWorkerShifts: (workerShiftIds: WorkerShiftIdDto[]) => Promise<void>
  hideHeaderRow?: boolean
  hideTotalsRow?: boolean
  hideEmptyState?: boolean
  isRecalculatingCharges?: boolean
  stickyApprovalsColumn?: boolean
  loggingContext?: TimeSheetsLoggingContext
}

export type TimeSheetsLoggingContext = Record<string, string>

export enum TimeSheetsTableLoggingSource {
  TIMESHEETS_TABLE_WORKER_HEADER = 'TIMESHEETS_TABLE_WORKER_HEADER',
  TIMESHEETS_TABLE_WORKER_SHIFT_ROW = 'TIMESHEETS_TABLE_WORKER_SHIFT_ROW',
  TIMESHEETS_APPROVAL_SCREEN = 'TIMESHEET_APPROVAL_SCREEN',
  TIMESHEETS_TOTALS_SCREEN = 'TIMESHEETS_TOTALS_SCREEN',
  SHIFT_DETAILS_SCREEN = 'SHIFT_DETAILS_SCREEN',
}

export enum TimeSheetsTableWorkerNameSearchSource {
  SHIFT_DETAILS = 'SHIFT_DETAILS',
  TIMESHEETS = 'TIMESHEETS',
}
