import { CalendarDate, getWeeksInMonth } from '@internationalized/date'
import { AriaCalendarGridProps, useCalendarGrid } from '@react-aria/calendar'
import { useLocale } from '@react-aria/i18n'
import { CalendarState, RangeCalendarState } from '@react-stately/calendar'
import { Text } from 'src/components/base'

import CalendarCell from './CalendarCell'

interface CalendarGridProps extends AriaCalendarGridProps {
  state: CalendarState | RangeCalendarState
  timezone: string
}

export default function CalendarGrid({ state, ...props }: CalendarGridProps) {
  const { locale } = useLocale()
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state)

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale)

  return (
    <table {...gridProps} style={{ flex: 1, width: '100%' }} cellPadding={0}>
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <th key={index}>
              <Text variant="h5">{day}</Text>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from(Array(weeksInMonth).keys()).map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date: CalendarDate | null, i: number) =>
                date ? (
                  <CalendarCell
                    key={i}
                    state={state}
                    date={date}
                    timezone={props.timezone}
                  />
                ) : (
                  <td key={i} />
                ),
              )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
