import React, { useState } from 'react'
import { Col, Row, SvgIcon, Text } from 'src/components/base'
import { ShiftSelector } from 'src/components/ShiftSelector/ShiftSelector'

import { ShiftAndAddress } from 'src/hooks/useShifts'
import { theme } from 'src/libs/theme'

interface Props {
  shifts: ShiftAndAddress[]
  onSelectShift: (shift: ShiftAndAddress | undefined) => void
  loading: boolean
  onClickBack: () => void
}

export const AnnouncementSearchBar = ({
  shifts,
  onSelectShift,
  loading,
  onClickBack,
}: Props) => {
  const currTime = Date.now()
  // Sort shifts by ongoing shifts first
  const sortedShifts = shifts.sort((a) => {
    return (a.businessStartTime ?? a.startTime).getTime() <= currTime &&
      (a.businessStartTime ?? a.endTime).getTime() >= currTime
      ? -1
      : 1
  })
  const [input, setInput] = useState('')

  return (
    <Col mb={theme.space.med}>
      <Row onClick={onClickBack} alignCenter mb={theme.space.xs}>
        <SvgIcon name="back" />
        <Text variant="h6" color={theme.colors.Violet} ml={theme.space.xxs}>
          All announcements
        </Text>
      </Row>
      <Text variant="h5">New announcement</Text>
      <Text variant="body2" mb={theme.space.sm}>
        To all workers on the shift
      </Text>
      <ShiftSelector
        input={input}
        setInput={setInput}
        setSelectedShift={onSelectShift}
        shifts={sortedShifts}
        isLoading={loading}
      />
    </Col>
  )
}
