import { LoadingSpinner, ModalPaddingContainer } from '@traba/react-components'
import { useForm } from '@traba/utils'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, ButtonVariant, InputPhone, Row } from 'src/components/base'
import { Text } from 'src/components/base/Text'
import { useUser } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { phoneNumber } from 'src/utils/formUtils'
import {
  checkArePhoneNumbersMatch,
  formatPhoneNumber,
} from 'src/utils/phoneNumberUtils'

import * as yup from 'yup'

export type SmsConsentModalContentProps = {
  setShowSmsConsentModal: React.Dispatch<React.SetStateAction<boolean>>
  onCancel: () => void
}

export const SmsConsentModalForSettings = ({
  onCancel,
  setShowSmsConsentModal,
}: SmsConsentModalContentProps) => {
  const { handleOnSubmitError } = useForm()
  const [isPhoneMismatch, setIsPhoneMismatch] = useState(false)
  const { user, patchUser, refetch, isLoading } = useUser()
  const defaultUser = {
    phoneNumber: formatPhoneNumber(user?.phoneNumber || ''),
  }

  const formik = useFormik({
    initialValues: defaultUser,
    validationSchema: yup.object({
      phoneNumber: phoneNumber(),
    }),
    onSubmit: async (values) => {
      try {
        if (
          !checkArePhoneNumbersMatch(
            values.phoneNumber,
            defaultUser.phoneNumber,
          )
        ) {
          setIsPhoneMismatch(true)
          return
        }
        patchUser({
          smsConsent: { agreedToSmsConsent: true },
          communicationPreferences: {
            shiftCodesSmsEnabled: true,
          },
        })
        await refetch()
        window.analytics.track('User Agreed to SMS Consent')
        setShowSmsConsentModal(false)
      } catch (err: unknown) {
        handleOnSubmitError(err, formik)
      }
    },
  })

  const { errors } = formik
  useEffect(() => {
    if (!formik.values.phoneNumber && !!user?.phoneNumber) {
      formik.handleChange({
        target: { value: user?.phoneNumber, name: 'phoneNumber' },
      })
    }
  }, [user?.phoneNumber])

  if (isLoading) {
    return <LoadingSpinner />
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalPaddingContainer>
        <Text variant="h3">Please confirm your phone number</Text>
        <InputPhone
          value={formik.values.phoneNumber}
          onChange={(val: string) =>
            formik.handleChange({
              target: { value: val, name: 'phoneNumber' },
            })
          }
        />
        {errors && (
          <Text variant="error" mt={theme.space.xxs}>
            {errors.phoneNumber}
          </Text>
        )}
        <Text variant="body3" mt={theme.space.xxs} mb={theme.space.xxs}>
          By clicking on “I agree”, I consent to receive text messages from
          Traba Inc. at the number provided above, and I agree that texts may be
          sent using through an autodialer or other technology.
        </Text>
        {isPhoneMismatch && (
          <Text variant="error" mb={theme.space.xxs}>
            {`The phone number you entered is ${formatPhoneNumber(
              formik.values.phoneNumber,
              true,
            )}, which is different from the phone number on your account: ${formatPhoneNumber(
              defaultUser.phoneNumber,
              true,
            )}.
            Do you want to update it? `}
          </Text>
        )}
        <Row alignCenter justifyBetween mt={theme.space.lg}>
          <Button variant={ButtonVariant.OUTLINED} onClick={onCancel}>
            Cancel
          </Button>
          {isPhoneMismatch ? (
            <Button
              onClick={() => {
                patchUser({
                  smsConsent: { agreedToSmsConsent: true },
                  communicationPreferences: {
                    shiftCodesSmsEnabled: true,
                  },
                  phoneNumber: formik.values.phoneNumber,
                })
                window.analytics.track('User Agreed to SMS Consent')
                setShowSmsConsentModal(false)
              }}
            >
              Update and continue
            </Button>
          ) : (
            <Button type="submit">I agree</Button>
          )}
        </Row>
      </ModalPaddingContainer>
    </form>
  )
}
