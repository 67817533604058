import styled, { css } from 'styled-components'

type HeaderMenuWrapperProps = {
  isHeaderMenuOpened: boolean
}

export const HeaderMenuOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
`

export const HeaderMenuWrapper = styled.div<HeaderMenuWrapperProps>`
  position: absolute;
  top: 70px;
  right: 50px;
  min-width: 222px;
  transition: all 0.2s;

  // Opacity Transition
  ${({ isHeaderMenuOpened }) =>
    isHeaderMenuOpened
      ? css`
          opacity: 1;
          transform: scaleY(1);
          min-width: 222px;
        `
      : css`
          opacity: 0;
          pointer-events: none;
          transform: scaleY(0);
          min-width: 0px;
        `}
`

export const HeaderMenuItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 0;
  cursor: pointer;

  img {
    margin-right: 15px;
  }

  &:last-child {
    border-top: 1px solid ${({ theme }) => theme.border.color};
    margin: 12px -12px 0;
    padding: 16px 12px;
  }
`
