import { useAlert } from '@traba/context'
import { FileType, useFileUploader } from '@traba/hooks'
import { MediaUploader } from '@traba/react-components'
import { Company, ScheduledBreak } from '@traba/types'
import { AxiosError } from 'axios'
import React from 'react'
import { useState } from 'react'
import { UseMutateAsyncFunction } from 'react-query'

import { Button, ButtonVariant, Input, Text } from '../base'
import { DefaultShiftBreaks } from './DefaultShiftBreaks'
import * as S from './EditCompany.styles'

export type EditCompanyProps = {
  companyId: string
  companyUpdate: any
  setCompanyUpdate: any
  patchCompany: UseMutateAsyncFunction<
    Company,
    AxiosError<any>,
    Partial<Company>,
    Company
  >
  setEditMode: any
  defaultBreaks?: ScheduledBreak
}

export const EditCompanyUI: React.FC<EditCompanyProps> = (
  props: EditCompanyProps,
) => {
  const { companyId, setCompanyUpdate } = props

  const [defaultBreaksLength, setDefaultBreaksLength] = React.useState<
    number | undefined
  >(props.defaultBreaks?.breakLength)
  const [defaultBreaksCount, setDefaultBreaksCount] = React.useState<
    number | undefined
  >(props.defaultBreaks?.count)

  const { handleUpload } = useFileUploader()
  const { showError } = useAlert()
  const [logoFile, setLogoFile] = useState<File | undefined>()
  const [companyLogoLoading, setCompanyLogoLoading] = useState<boolean>(false)

  async function onChangeLogoFile(f: File | undefined) {
    if (!f) {
      return
    }
    setLogoFile(f)
    if (f) {
      setCompanyLogoLoading(true)
      // TODO(faris-traba): ENG-9698
      const url = await handleUpload({
        fileType: FileType.COMPANY_IMAGES,
        media: f,
        userId: companyId,
      })
      setCompanyLogoLoading(false)
      setCompanyUpdate({
        ...props.companyUpdate,
        companyLogo: url,
      })
    }
  }

  async function onDeleteFile(
    setFile: React.Dispatch<React.SetStateAction<File | undefined>>,
  ) {
    setFile(undefined)
  }

  return (
    <S.EditCompanyContainer>
      <Text variant="h5">Company Name</Text>
      <Input
        label="Company name"
        width="100%"
        onChange={(e) => {
          props.setCompanyUpdate({
            ...props.companyUpdate,
            employerName: e.target.value,
          })
        }}
        containerStyle={{ marginTop: 0 }}
        value={props.companyUpdate.employerName}
      />
      <Text variant="h5">Logo</Text>
      <MediaUploader
        id="logo-upload"
        label="Upload Logo"
        file={logoFile}
        onChange={onChangeLogoFile}
        onDelete={() => onDeleteFile(setLogoFile)}
        onError={(error) => {
          showError(error, 'File upload/delete error. Please try again.')
        }}
        fileType="largeImage"
        maxFileSizeMB={1}
        marginTop={0}
      />
      <DefaultShiftBreaks
        companyUpdate={props.companyUpdate}
        defaultBreaksLength={defaultBreaksLength}
        defaultBreaksCount={defaultBreaksCount}
        setDefaultBreaksLength={setDefaultBreaksLength}
        setDefaultBreaksCount={setDefaultBreaksCount}
        setCompanyUpdate={props.setCompanyUpdate}
        isEdit={true}
      />
      <div style={{ display: 'flex', marginTop: '40px' }}>
        <Button
          style={{ marginRight: '8px' }}
          onClick={() => {
            props.setEditMode(false)
          }}
          variant={ButtonVariant.OUTLINED}
        >
          Discard changes
        </Button>
        <Button
          onClick={async () => {
            await props.patchCompany(props.companyUpdate)
            props.setEditMode(false)
          }}
          variant={ButtonVariant.FILLED}
          disabled={!!defaultBreaksCount !== !!defaultBreaksLength}
          loading={companyLogoLoading}
        >
          Save changes
        </Button>
      </div>
    </S.EditCompanyContainer>
  )
}
