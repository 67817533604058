import { Card } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { CancellationSettings } from '@traba/types'
import { useState } from 'react'
import { SvgIconName } from 'src/assets/svg/icons'
import { Col, Divider, Icon, Text } from 'src/components/base'
import { FunnyMoney } from 'src/components/CostSummary/FunnyMoney'
import { theme } from 'src/libs/theme'
import { getMoneyString } from 'src/utils/moneyUtils'

import CancelFeeBadge from './CancelFeeBadge'
import * as S from './CancellationCost.styles'

export const PriceLine = ({
  label,
  children,
  shouldCompress = false,
}: {
  label: string
  children: React.ReactNode
  shouldCompress?: boolean
}) => {
  return (
    <S.PriceLineContainer compress={shouldCompress}>
      <Text
        variant="caption"
        style={{
          lineHeight: '24px',
          flex: 1,
        }}
      >
        {label}
      </Text>
      <div
        style={{
          marginLeft: '8px',
          justifyContent: 'flex-end',
          display: 'flex',
        }}
      >
        {children}
      </div>
    </S.PriceLineContainer>
  )
}

export default function CancellationCost(props: {
  isPaidCancellation: boolean
  totalCancellationCost: number
  singleWorkerCharge: number
  cancellationSettings?: CancellationSettings
  cancellationChargeSummary: string
  totalWorkersAffected: number
  renderSelectedCardTiles?: () => JSX.Element[]
  largeHeader?: boolean
}) {
  const {
    isPaidCancellation,
    renderSelectedCardTiles,
    totalCancellationCost,
    singleWorkerCharge,
    cancellationSettings,
    cancellationChargeSummary,
    totalWorkersAffected,
    largeHeader,
  } = props

  const shouldWaiveCancellationFees =
    !!cancellationSettings?.shouldWaiveCancellationFees
  const cancellationBusinessTimeWindow =
    cancellationSettings?.cancellationBusinessTimeWindow ?? 18

  const [chevronIcon, setChevronIcon] = useState<SvgIconName>('chevronDown')
  const [paidCancellationCardsVisible, setPaidCancellationCardsVisible] =
    useState(false)
  return (
    <>
      <Text variant={largeHeader ? 'h4' : 'h5'} mb={theme.space.sm}>
        Cancellation cost
      </Text>
      <Card
        style={{
          backgroundColor: isPaidCancellation
            ? theme.colors.Red10
            : theme.colors.Green10,
          border: 'none',
          paddingLeft: theme.space.lg,
          paddingTop: theme.space.sm,
        }}
      >
        <div>
          <Text variant="h6">
            {isPaidCancellation ? 'Paid cancellation' : 'Free cancellation'}
          </Text>
        </div>
        <Text
          variant="body2"
          style={{
            color: theme.colors.Grey60,
          }}
        >
          {isPaidCancellation && shouldWaiveCancellationFees
            ? 'The late cancellation fees for this shift have been waived.'
            : isPaidCancellation
              ? `There are fees associated with cancelling shifts starting within ${cancellationBusinessTimeWindow} hours of their start time. Please contact us under extenuating circumstances (eg: extreme weather)`
              : 'This cancellation will be free under our current terms and conditions.'}
        </Text>
        <Text variant="body2" mt={theme.space.xs}>
          <S.BusinessFaqAnchor
            href="https://www.traba.work/business-faq#faq_question9"
            target="_blank"
            isPaidCancellation={isPaidCancellation}
          >
            View our policy
          </S.BusinessFaqAnchor>
        </Text>

        {isPaidCancellation && (
          <>
            <PriceLine label="CHARGE PER WORKER">
              {cancellationChargeSummary ? (
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <CancelFeeBadge
                    cancellationChargeSummary={cancellationChargeSummary}
                    shouldWaiveCancellationFees={shouldWaiveCancellationFees}
                  />
                </Col>
              ) : null}
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                {singleWorkerCharge > 0 && shouldWaiveCancellationFees && (
                  <Text variant="h7">{getMoneyString(0)}</Text>
                )}
                <Text
                  variant={
                    singleWorkerCharge > 0 && shouldWaiveCancellationFees
                      ? 'body3'
                      : 'h6'
                  }
                  style={{
                    textDecoration:
                      singleWorkerCharge > 0 && shouldWaiveCancellationFees
                        ? 'line-through'
                        : '',
                  }}
                >
                  {getMoneyString(singleWorkerCharge)}
                </Text>
              </Col>
            </PriceLine>
            <Divider />
            <PriceLine label="WORKERS TO PAY">
              <Text variant="h6">
                {totalWorkersAffected} worker{makePlural(totalWorkersAffected)}
              </Text>
              {!!renderSelectedCardTiles && (
                <div
                  onClick={() => {
                    setPaidCancellationCardsVisible(
                      !paidCancellationCardsVisible,
                    )
                    setChevronIcon(
                      chevronIcon === 'chevronUp' ? 'chevronDown' : 'chevronUp',
                    )
                  }}
                  style={{ marginLeft: theme.space.xs }}
                >
                  <div style={{ cursor: 'pointer' }}>
                    <Icon name={chevronIcon} />
                  </div>
                </div>
              )}
            </PriceLine>

            <Divider />

            {!!renderSelectedCardTiles && (
              <div style={{ marginBottom: theme.space.xs }}>
                {paidCancellationCardsVisible && renderSelectedCardTiles()}
              </div>
            )}

            <PriceLine label="TOTAL COST">
              <div>
                <FunnyMoney
                  strikeThrough={shouldWaiveCancellationFees}
                  amount={totalCancellationCost}
                  superSize={true}
                />
              </div>
              {shouldWaiveCancellationFees && (
                <FunnyMoney
                  amount={0}
                  superSize={true}
                  style={{ marginLeft: theme.space.xs }}
                />
              )}
            </PriceLine>
          </>
        )}
      </Card>
    </>
  )
}
