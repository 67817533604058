import styled from 'styled-components'

export const ShiftDetailsHeaderContainer = styled.div``

export const ActionItemsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.space.xxs}px;
`

export const TextButton = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.004em;
  text-decoration: none;
  color: #8000ff;
`
