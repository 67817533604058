import { END_TIME_BUFFER_FOR_NEW_ANNOUNCEMENTS_IN_HOURS } from '@traba/consts'
import { Announcement } from '@traba/types'
import { LocationResponse } from '@traba/types'
import { startOfDay, subHours } from 'date-fns'
import { useEffect, useState } from 'react'
import { Button, ButtonVariant, Row } from 'src/components'
import { PaginationDeprecated } from 'src/components/base/PaginationDeprecated'
import { FilterDropdown } from 'src/components/ShiftFilters/FilterDropdown'
import { AddAnnouncementSection } from 'src/components/UpcomingShiftDetails/components/AddAnnouncementSection'
import { AnnouncementEmptyLoading } from 'src/components/UpcomingShiftDetails/components/AnnouncementEmptyLoading'
import { AnnouncementItem } from 'src/components/UpcomingShiftDetails/components/AnnouncementItem'
import { useCompanyAnnouncements } from 'src/hooks/useCompanyAnnouncements'
import { useLocations } from 'src/hooks/useLocations'
import { useShiftPagination } from 'src/hooks/useShiftPagination'
import { ShiftAndAddress, useShifts } from 'src/hooks/useShifts'
import { theme } from 'src/libs/theme'
import { SearchParams } from 'src/types'
import { AnnouncementSearchBar } from './AnnouncementSearchBar'
import { AnnouncementShiftDetailsSection } from './AnnouncementShiftDetails'
import { ShiftPastAnnouncements } from './ShiftPastAnnouncements'

const ANNOUNCEMENTS_PAGE_SIZE = 10

enum AnnouncementFilter {
  All = 'All',
  PastShifts = 'Past Shifts',
  OngoingShifts = 'Ongoing Shifts',
  FutureShifts = 'Future Shifts',
}

const getParamsByFilter = (filter: AnnouncementFilter) => {
  const currTime = new Date()
  if (filter === AnnouncementFilter.PastShifts) {
    return {
      endBefore: currTime,
    }
  } else if (filter === AnnouncementFilter.OngoingShifts) {
    return { endAfter: currTime, startBefore: currTime }
  } else if (filter === AnnouncementFilter.FutureShifts) {
    return { startAfter: currTime }
  } else {
    return {}
  }
}

export const AnnouncementsTab = () => {
  const currTime = new Date()
  const [selectedShift, setSelectedShift] = useState<ShiftAndAddress>()
  const [location, setLocation] = useState<LocationResponse | undefined>()
  const [parkingLocation, setParkingLocation] = useState<
    LocationResponse | undefined
  >()
  const [isAdding, setIsAdding] = useState(false)
  const [showShiftDetails, setShowShiftDetails] = useState(true)
  const [selectedFilter, setSelectedFilter] = useState<AnnouncementFilter>(
    AnnouncementFilter.All,
  )
  const [queryParams, setQueryParams] = useState<SearchParams>()
  const { getLocationById } = useLocations()

  const {
    page,
    onPageLeft,
    onPageRight,
    isLoading,
    resetPagination,
    setStartAtIndex,
    refetch: refetchAnnouncements,
    data: announcements = [],
  } = useShiftPagination<Announcement>(
    'company-announcements',
    {
      limit: ANNOUNCEMENTS_PAGE_SIZE + 1,
      sortDir: 'desc',
      ...queryParams,
    },
    useCompanyAnnouncements,
    ANNOUNCEMENTS_PAGE_SIZE,
  )

  const startOfToday = startOfDay(currTime)
  const todaySubtractNewAnnouncementsHoursBuffer = subHours(
    startOfToday,
    END_TIME_BUFFER_FOR_NEW_ANNOUNCEMENTS_IN_HOURS,
  )
  const { data: shifts = [], isLoading: isLoadingShifts } = useShifts({
    endAfter: todaySubtractNewAnnouncementsHoursBuffer,
  })

  useEffect(() => {
    if (selectedShift) {
      const location = getLocationById(selectedShift.locationId)
      if (selectedShift.parkingLocationId) {
        const parkingLocation = getLocationById(selectedShift.parkingLocationId)
        setParkingLocation(parkingLocation)
      } else {
        setParkingLocation(undefined)
      }
      setLocation(location)
    }
  }, [getLocationById, selectedShift])

  const filters = [
    { label: 'All', value: AnnouncementFilter.All },
    { label: 'Past Shifts', value: AnnouncementFilter.PastShifts },
    { label: 'Ongoing Shifts', value: AnnouncementFilter.OngoingShifts },
    { label: 'Future Shifts', value: AnnouncementFilter.FutureShifts },
  ]

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isAdding ? (
        <>
          <AnnouncementSearchBar
            shifts={shifts}
            onSelectShift={(shift: ShiftAndAddress | undefined) => {
              setShowShiftDetails(true)
              setSelectedShift(shift)
            }}
            loading={isLoadingShifts}
            onClickBack={() => {
              setIsAdding(false)
              setSelectedShift(undefined)
            }}
          />
          {selectedShift && (
            <Row justifyBetween>
              <AddAnnouncementSection
                shift={selectedShift}
                onSubmitOrCancel={() => {
                  setIsAdding(false)
                  setSelectedShift(undefined)
                  refetchAnnouncements()
                }}
                location={location}
                parkingLocation={parkingLocation}
                onClickExpandShiftDetail={
                  showShiftDetails
                    ? undefined
                    : () => {
                        setShowShiftDetails(true)
                      }
                }
              />
              {showShiftDetails && (
                <div
                  style={{
                    borderLeftColor: theme.BorderColor,
                    borderLeftWidth: 1,
                    borderLeftStyle: 'solid',
                    paddingLeft: theme.space.med,
                    marginLeft: theme.space.med,
                    width: '45%',
                  }}
                >
                  <AnnouncementShiftDetailsSection
                    shiftId={selectedShift.id}
                    onClickBack={() => {
                      setShowShiftDetails(false)
                    }}
                  />
                </div>
              )}
            </Row>
          )}
          {selectedShift && (
            <ShiftPastAnnouncements
              shiftId={selectedShift.id}
              timezone={selectedShift.timezone}
            />
          )}
        </>
      ) : (
        <>
          <Row justifyBetween mb={theme.space.sm} mt={theme.space.xxs}>
            <FilterDropdown
              overrideMenuItems
              label={'Shifts'}
              selected={selectedFilter}
              items={filters}
              onSelectItem={(value) => {
                setStartAtIndex(0)
                resetPagination()
                setSelectedFilter(value as AnnouncementFilter)
                setQueryParams(getParamsByFilter(value as AnnouncementFilter))
              }}
            />
            <Button
              variant={ButtonVariant.FILLED}
              onClick={() => {
                setIsAdding(true)
              }}
              style={{ alignSelf: 'flex-end' }}
            >
              + New announcement
            </Button>
          </Row>
          {announcements.length > 0 && (
            <Row justifyEnd>
              <PaginationDeprecated
                page={page}
                pageSize={ANNOUNCEMENTS_PAGE_SIZE}
                onPageLeft={onPageLeft}
                onPageRight={onPageRight}
                dataSize={announcements?.length}
              />
            </Row>
          )}

          {isLoading ||
          announcements === undefined ||
          announcements.length === 0 ? (
            <AnnouncementEmptyLoading isLoading={isLoading} />
          ) : (
            announcements?.map((announcement) => (
              <AnnouncementItem
                announcement={announcement}
                key={announcement.id}
              />
            ))
          )}
        </>
      )}
    </>
  )
}
