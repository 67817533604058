import { LoadingSpinner, MODAL_SIZE } from '@traba/react-components'
import { Shift } from '@traba/types'
import { useState } from 'react'
import {
  Button,
  ButtonVariant,
  Modal,
  Row,
  SvgIcon,
  Text,
} from 'src/components'
import { Checkbox } from 'src/components/base/CheckboxThemed'
import { ErrorModal } from 'src/components/Modals/ErrorModal'
import { useShiftsByShiftRequestId } from 'src/hooks/useShifts'
import { theme } from 'src/libs/theme'
import { formatTimeDateString } from 'src/shared/utils/dateUtils'

import * as S from './EditShiftsSelectShiftsContent.styles'

function ShiftItem({
  shift,
  selected,
  setSelected,
  disabledChecked,
}: {
  shift: Shift
  selected: Shift[]
  setSelected: (shifts: Shift[]) => void
  disabledChecked?: boolean
}) {
  const checked =
    !!selected.find((s) => s?.shiftId === shift.shiftId) || !!disabledChecked
  return (
    <S.ShiftItemContainer
      onClick={() => {
        if (disabledChecked) {
          return
        }
        if (checked) {
          setSelected(selected.filter((s) => s?.shiftId !== shift.shiftId))
        } else {
          setSelected([...selected, shift])
        }
      }}
    >
      <Checkbox
        selected={checked}
        disabled={disabledChecked}
        style={{ marginRight: theme.space.xs }}
      />
      <Text variant="body2">
        {formatTimeDateString(
          shift.businessStartTime ?? shift.startTime,
          shift.timezone,
        )}
        {' · '}
        {`${shift.slotsFilled} of ${shift.slotsRequested} workers`}
      </Text>
    </S.ShiftItemContainer>
  )
}

export function EditShiftsSelectShiftsModal(props: {
  shiftRequestId?: string
  initialShift?: Shift
  selectedShifts: Shift[]
  setSelectedShifts: (shifts: Shift[]) => void
  showSelectModal?: boolean
  setShowSelectModal: (showModal?: boolean) => void
}) {
  const {
    shiftRequestId,
    initialShift,
    showSelectModal,
    setShowSelectModal,
    selectedShifts,
    setSelectedShifts,
  } = props
  const [selected, setSelected] = useState<Shift[]>(selectedShifts)
  const { shifts: allShifts, isError } = useShiftsByShiftRequestId(
    shiftRequestId,
    true,
  )
  const handleToggleAll = () => {
    if (allShifts?.length === selected.length) {
      initialShift && setSelected([initialShift])
      return
    }
    allShifts && setSelected(allShifts)
  }
  return (
    <Modal
      size={MODAL_SIZE.SMALL}
      isOpen={!!showSelectModal}
      handleClose={() => {
        setShowSelectModal(false)
      }}
    >
      <div style={{ padding: theme.space.lg, width: '100%' }}>
        <Row justifyBetween alignCenter>
          <Text variant="h5">Selected Shift</Text>
          <SvgIcon
            name="cancel"
            color={theme.colors.Grey60}
            size={theme.space.sm}
            style={{ cursor: 'pointer' }}
            onClick={() => setShowSelectModal(false)}
          />
        </Row>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: `${theme.space.sm / 2}px 0px ${theme.space.sm}px 0px`,
          }}
        >
          <div>
            <Text variant="body2">{initialShift?.shiftRole}</Text>
            <Text variant="body2">
              {initialShift &&
                formatTimeDateString(
                  initialShift.businessStartTime ?? initialShift.startTime,
                  initialShift.timezone,
                )}
            </Text>
            <Text variant="body2">
              {`${initialShift?.slotsFilled} of ${initialShift?.slotsRequested} workers scheduled`}
            </Text>
          </div>
        </div>

        {!allShifts?.length || allShifts?.length < 2 ? null : (
          <>
            <div style={{ display: 'flex', gap: 10 }}>
              <Text variant="h5">
                Apply these changes to other upcoming shifts in this series?
              </Text>
              <Text variant="body2">(Optional)</Text>
            </div>

            <Button
              variant={ButtonVariant.LINK}
              onClick={handleToggleAll}
              style={{ marginTop: theme.space.xxs }}
            >
              {selected.length === allShifts?.length
                ? 'Deselect All'
                : 'Select All'}
            </Button>
            <div
              style={{
                backgroundColor: theme.colors.Grey10,
                maxHeight: 300,
                overflow: 'scroll',
                margin: `${theme.space.xs}px 0px`,
                padding: theme.space.xs,
                borderRadius: theme.space.xxs,
              }}
            >
              {isError ? (
                <ErrorModal
                  cancelFunc={() => setShowSelectModal(false)}
                  titleText={'There was an error loading your shift details'}
                  bodyText={'Please try again later'}
                />
              ) : (
                <>
                  {initialShift && (
                    <ShiftItem
                      disabledChecked
                      shift={initialShift}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  {allShifts ? (
                    allShifts
                      ?.filter(
                        ({ shiftId }) => shiftId !== initialShift?.shiftId,
                      )
                      .map((shift: Shift) => (
                        <ShiftItem
                          shift={shift}
                          selected={selected}
                          setSelected={setSelected}
                        />
                      ))
                  ) : (
                    <LoadingSpinner />
                  )}
                </>
              )}
            </div>

            <Row justifyBetween>
              <Button
                onClick={() => {
                  setShowSelectModal(false)
                }}
                variant={ButtonVariant.OUTLINED}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setSelectedShifts(selected)
                  setShowSelectModal(false)
                }}
              >
                Confirm
              </Button>
            </Row>
          </>
        )}
      </div>
    </Modal>
  )
}
