import { SvgIcon, Text } from 'src/components/base'
import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'

import * as S from './Pagination.styles'

export type PaginationProps = {
  page: number
  pageSize: number
  onPageLeft: () => void
  onPageRight: () => void
  dataSize: number
}

export const PaginationDeprecated = (props: PaginationProps) => {
  const { page, pageSize, dataSize, onPageLeft, onPageRight } = props
  const enabledColor = theme.colors.Grey60
  const disabledColor = theme.colors.Grey20
  const { isReactNativeApp } = useMobile()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <S.StyledSvg
        style={{ paddingRight: 2 }}
        onClick={page > 0 ? () => onPageLeft() : undefined}
      >
        <SvgIcon name="left" color={page > 0 ? enabledColor : disabledColor} />
      </S.StyledSvg>
      <Text
        style={{
          width: isReactNativeApp ? 'auto' : 70,
          textAlign: 'center',
        }}
        variant="h5"
      >
        {!dataSize
          ? '...'
          : `${page * pageSize + 1} - ${
              page * pageSize + Math.min(dataSize, pageSize)
            }`}
      </Text>

      <S.StyledSvg
        style={{ paddingLeft: 2 }}
        onClick={dataSize > pageSize ? () => onPageRight() : undefined}
      >
        <SvgIcon
          name="right"
          color={dataSize > pageSize ? enabledColor : disabledColor}
        />
      </S.StyledSvg>
    </div>
  )
}
