import { Card } from '@traba/react-components'
import { Col, Row, Text } from 'src/components/base'
import { BrandColors, theme } from 'src/libs/theme'
import styled, { css } from 'styled-components'

export const ShiftTileContainer = styled(Card)`
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const ExpandableShiftTileCard = styled(ShiftTileContainer)<{
  isExpanded?: boolean
}>`
  ${(props) =>
    props.isExpanded &&
    css`
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}
`

export const MobileExpandableShiftTileCard = styled(ExpandableShiftTileCard)<{
  isExpanded?: boolean
}>`
  ${(props) =>
    props.isExpanded &&
    css`
      border-bottom: 0;
    `}
`

export const ShiftTileWorkerListContainer = styled(ShiftTileContainer)`
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: 0;
`

export const ShiftTileContent = styled.div`
  ${({ onClick }) =>
    typeof onClick === 'function'
      ? `
    cursor: pointer
  `
      : ''}
`

export const ShiftTileCol = styled(Col)`
  padding-right: ${theme.space.xs}px;
`

export const ShiftTileColWide = styled(ShiftTileCol)`
  width: 25%;
  padding-right: ${theme.space.xs}px;
`

export const ShiftTileAccentText = styled(Text)<{
  ml?: string
}>`
  color: ${theme.ActiveBackColor};
  ${(props) => props.ml && `margin-left: ${props.ml}`};
`

export const ShiftTileBodyText = styled(Text)`
  @media only screen and (min-width: ${theme.media.minLarge}) {
    color: ${BrandColors.MidnightBlue};
  }
`

export const ShiftTileFooter = styled.div<{ variant: 'approved' | 'pending' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  ${({ variant }) =>
    variant === 'pending'
      ? css`
          color: ${({ theme }) => theme.colors.brand};
          background-color: ${({ theme }) => theme.colors.reverseBackColor};
        `
      : variant === 'approved' &&
        css`
          color: ${({ theme }) => theme.colors.Green70};
          background-color: ${({ theme }) => theme.colors.Green10};
        `}
`

export const ShiftTileRow = styled(Row)`
  @media only screen and (min-width: ${theme.media.minLarge}) {
    margin-bottom: 24px;
  }
`
