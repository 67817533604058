import React from 'react'

import * as S from './Checkbox.styles'

export type CheckboxProps = {
  selected: boolean
  onClick?: () => void
  style?: React.CSSProperties
  disabled?: boolean
  hidden?: boolean
  selectedColor?: string
}

export const Checkbox = (props: CheckboxProps) => {
  return (
    <S.CheckboxContainer
      disabled={props.disabled}
      onClick={props.disabled ? undefined : props.onClick}
      style={{
        ...props.style,
        visibility: props.hidden ? 'hidden' : undefined,
      }}
    >
      {props.selected ? (
        <S.SelectedCheckbox
          disabled={props.disabled}
          selectedColor={props.selectedColor}
        >
          <S.Checkmark>
            <S.CheckmarkStem />
            <S.CheckmarkKick />
          </S.Checkmark>
        </S.SelectedCheckbox>
      ) : (
        <S.NotSelectedCheckbox />
      )}
    </S.CheckboxContainer>
  )
}
