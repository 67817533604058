import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import { Anchor, CardTile } from '@traba/react-components'
import {
  Worker,
  ShiftInvitation,
  Roster,
  ForwardFillMax,
  RoleInfoForCreateShiftRequest,
  ShiftInvitationBatchWithWorkers,
  CreateShiftRequest,
  ScheduleShiftRequestWorkerInvitation,
} from '@traba/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, InlineBanner, Row, SvgIcon } from 'src/components/base'
import { Text } from 'src/components/base/Text'
import { RadioButton } from 'src/components/RadioButton'
import { Table, Td, Tr } from 'src/components/Table/Table'
import { TableHeader } from 'src/components/Table/Table.styles'
import { WorkerPhotoAndName } from 'src/components/WorkersOnShiftTable/components/WorkerPhotoAndName'
import { useCompanyEmploymentType } from 'src/hooks/useCompanyEmploymentType'
import { useRoster } from 'src/hooks/useRoster'
import { useRosters } from 'src/hooks/useRosters'
import { useVirtualRosters } from 'src/hooks/useVirtualRosters'
import { theme } from 'src/libs/theme'
import { sortWorkersByFirstNameThenLastName } from 'src/shared/utils/sortUtils'
import * as S from '../../BookShiftsScreen.styles'
import { BookShiftsInvitationsModal } from './BookShiftsInvitationsModal'

const WorkerRow = ({ worker }: { worker: Worker }) => {
  return (
    <Tr key={worker.uid}>
      <Td>
        <WorkerPhotoAndName worker={worker} disableLink />
      </Td>
    </Tr>
  )
}

type InvitationInput =
  | ShiftInvitation[]
  | ScheduleShiftRequestWorkerInvitation[]

function isShiftInvitation(
  input: ShiftInvitation | ScheduleShiftRequestWorkerInvitation,
): input is ShiftInvitation {
  return !!(input as ShiftInvitation).shiftId
}

function isBookingSingleShifts(
  existingInvitations: InvitationInput,
  selectedSingleShiftDates: Date[] | null,
): existingInvitations is ShiftInvitation[] {
  if (!existingInvitations.length) {
    // dp something smarter
    return true
  }
  return (
    isShiftInvitation(existingInvitations[0]) &&
    !!selectedSingleShiftDates?.length
  )
}

export function BookShiftsInvitationsSectionShiftDataModel({
  role,
  locationId,
  existingInvitations,
  shiftRequest,
  selectedSingleShiftDates,
  isW2Shift,
  getWorkerById,
  updateRoleInfoForCreateShiftRequest,
}: {
  role: RoleInfoForCreateShiftRequest
  locationId: string
  existingInvitations: InvitationInput
  shiftRequest: CreateShiftRequest
  selectedSingleShiftDates: Date[] | null
  isW2Shift?: boolean
  getWorkerById: (uid: string) => Worker
  updateRoleInfoForCreateShiftRequest: (
    updatedRoleInfoForCreateShiftRequest: RoleInfoForCreateShiftRequest,
    originalRoleId: string,
  ) => void
}) {
  const isUsingShiftInvitations = isBookingSingleShifts(
    existingInvitations,
    selectedSingleShiftDates,
  )
  const existingInvitationWorkerIds = existingInvitations.map((i) => i.workerId)

  const {
    roleId,
    slotsRequested,
    shiftInvitations,
    scheduleInvitations,
    forwardFillMax,
  } = role

  const hasInvitations: boolean = isUsingShiftInvitations
    ? !!shiftInvitations?.length
    : !!scheduleInvitations?.workerIds.length

  const { virtualRosters, virtualRostersWorkersMap } = useVirtualRosters(
    locationId,
    roleId,
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedRoster, setSelectedRoster] = useState<Roster | undefined>()
  const { roster, workersMap } = useRoster(selectedRoster?.id || '')

  const [selectedWorkers, setSelectedWorkers] = useState(
    isUsingShiftInvitations
      ? new Set(
          shiftInvitations?.flatMap((i) =>
            i.workers.map((worker) => worker.workerId),
          ),
        )
      : new Set<string>(existingInvitationWorkerIds),
  )
  const [showInvitationsWarning, setShowInvitationsWarning] = useState(false)

  const { rosters } = useRosters()

  const isInvitationShift =
    forwardFillMax === ForwardFillMax.INVITED_ONLY ||
    forwardFillMax === ForwardFillMax.INVITED_FIRST

  const invitationsPossible = !!(
    (rosters && rosters.length > 0) ||
    Object.values(virtualRosters ?? {}).some(
      (roster) => roster.workers.length > 0,
    )
  )

  // TODO: @W2 this is a temporary control
  const { inviteOnly } = useCompanyEmploymentType()

  useEffect(() => {
    if (isUsingShiftInvitations) {
      if (!shiftInvitations) {
        setSelectedWorkers(new Set())
      }
    } else if (!scheduleInvitations) {
      setSelectedWorkers(new Set())
    }
  }, [shiftInvitations, scheduleInvitations, isUsingShiftInvitations])

  useEffect(() => {
    if (
      isInvitationShift &&
      slotsRequested >
        selectedWorkers.size + existingInvitationWorkerIds.length &&
      !inviteOnly
    ) {
      setShowInvitationsWarning(true)
      updateRoleInfoForCreateShiftRequest(
        { ...role, forwardFillMax: ForwardFillMax.INVITED_FIRST },
        roleId,
      )
    } else {
      setShowInvitationsWarning(false)
    }
  }, [
    slotsRequested,
    selectedWorkers.size,
    isInvitationShift,
    existingInvitationWorkerIds.length,
  ])

  const onClickWorker = (workerId: string) => {
    const newWorkers = new Set(selectedWorkers)
    if (selectedWorkers.has(workerId)) {
      newWorkers.delete(workerId)
    } else {
      newWorkers.add(workerId)
    }
    setSelectedWorkers(newWorkers)
  }

  function onClickContinue() {
    const workersArray = Array.from(selectedWorkers)
    const newRole = { ...role }
    if (isUsingShiftInvitations) {
      newRole.shiftInvitations = workersArray.length
        ? [
            {
              batch: 1,
              workers: workersArray.map((workerId, index) => ({
                workerId,
                index: index + 1,
              })),
            },
          ]
        : undefined
    } else {
      newRole.scheduleInvitations = workersArray.length
        ? { workerIds: workersArray }
        : undefined
    }
    updateRoleInfoForCreateShiftRequest(newRole, roleId)
    setIsModalOpen(false)
  }

  const getNewInvitedWorkersSorted = useCallback(
    (workerIds: string[]): Worker[] => {
      const workersAlreadyInvited = new Set(existingInvitationWorkerIds)
      return workerIds
        .filter((workerId) => !workersAlreadyInvited.has(workerId))
        .map(
          (workerId) =>
            workersMap.get(workerId)?.worker ||
            virtualRostersWorkersMap.get(workerId),
        )
        .filter((worker): worker is Worker => !!worker)
        .sort(sortWorkersByFirstNameThenLastName)
    },
    [workersMap, virtualRostersWorkersMap, existingInvitationWorkerIds],
  )

  const { existingInvitedWorkers, newWorkerInvitations } = useMemo(() => {
    const existingInvitedWorkers: Worker[] = existingInvitationWorkerIds
      .map(getWorkerById)
      .sort(sortWorkersByFirstNameThenLastName)

    let newWorkerInvitations: ShiftInvitationBatchWithWorkers[] = []

    if (isUsingShiftInvitations && shiftInvitations) {
      newWorkerInvitations = shiftInvitations?.map(
        (shiftInvitation): ShiftInvitationBatchWithWorkers => {
          const { batch, workers } = shiftInvitation

          if (!shiftInvitation.workers.length) {
            return { batch, workers: [] }
          }

          const newInvitationWorkers = getNewInvitedWorkersSorted(
            workers.map((w) => w.workerId),
          )

          return {
            batch,
            workers: newInvitationWorkers,
          }
        },
      )
    } else if (!isUsingShiftInvitations && scheduleInvitations) {
      const newInvitationWorkers = getNewInvitedWorkersSorted(
        scheduleInvitations.workerIds,
      )
      newWorkerInvitations = [{ batch: 0, workers: newInvitationWorkers }]
    }
    return { existingInvitedWorkers, newWorkerInvitations }
  }, [
    existingInvitationWorkerIds,
    getNewInvitedWorkersSorted,
    getWorkerById,
    isUsingShiftInvitations,
    scheduleInvitations,
    shiftInvitations,
  ])

  return (
    <>
      <S.ClickableCard isClickable={invitationsPossible}>
        <S.ClickableRow
          alignCenter
          fullWidth
          justifyBetween
          onClick={
            invitationsPossible
              ? () => {
                  setIsModalOpen(true)
                  if (
                    !role.forwardFillMax ||
                    (role.forwardFillMax && !inviteOnly)
                  ) {
                    updateRoleInfoForCreateShiftRequest(
                      {
                        ...role,
                        forwardFillMax: ForwardFillMax.INVITED_FIRST,
                      },
                      roleId,
                    )
                  }
                }
              : undefined
          }
        >
          <Row style={{ marginRight: theme.space.xxs }}>
            <CardTile
              size={`${theme.space.xxl}px`}
              style={{ minWidth: 56, marginRight: theme.space.xs }}
            >
              <SvgIcon
                name="userPlus"
                size={theme.space.sm}
                color={theme.colors.Grey50}
              />
            </CardTile>
            <Col>
              <Row>
                <Text color={theme.colors.MidnightBlue} variant="h5">
                  Invite from roster
                </Text>
              </Row>
              <Text variant="body2">
                This option will allow you to invite workers who have worked
                with you before.
              </Text>
            </Col>
          </Row>
          <RadioButton
            selected={
              forwardFillMax === ForwardFillMax.INVITED_FIRST ||
              forwardFillMax === ForwardFillMax.INVITED_ONLY
            }
          />
        </S.ClickableRow>

        {!invitationsPossible && (
          <InlineBanner
            text={
              <>
                You can only invite workers whom you've already added to your
                rosters or marked as favorites. You can easily favorite workers
                or add them to rosters from the{' '}
                <Anchor openInNewTab={true} href="/workers">
                  worker management
                </Anchor>{' '}
                tab after they have worked with you.
              </>
            }
            style={{ marginTop: theme.space.xs }}
          />
        )}

        {/* No newly added invitations */}
        {isInvitationShift && !hasInvitations && (
          <>
            <Text
              mt="xxs"
              style={{ textAlign: 'end' }}
              variant="link"
              onClick={() => setIsModalOpen(true)}
            >
              + Add workers
            </Text>
            {!existingInvitationWorkerIds?.length && (
              <InlineBanner
                severity="warning"
                text="Please invite some workers to continue."
                style={{ marginTop: theme.space.xs }}
              />
            )}
          </>
        )}

        {(hasInvitations || !!existingInvitationWorkerIds?.length) && (
          <>
            <Row
              style={{
                marginTop: theme.space.xxs,
              }}
            >
              {forwardFillMax && forwardFillMax !== ForwardFillMax.NONE && (
                <FormGroup style={{ flex: 1 }}>
                  <FormControlLabel
                    componentsProps={{
                      typography: {
                        color: theme.colors.MidnightBlue,
                        fontSize: theme.space.xs,
                      },
                    }}
                    control={
                      <Switch
                        checked={
                          forwardFillMax === ForwardFillMax.INVITED_FIRST
                        }
                        disabled={showInvitationsWarning || !!isW2Shift}
                        onChange={() =>
                          updateRoleInfoForCreateShiftRequest(
                            {
                              ...role,
                              forwardFillMax:
                                forwardFillMax === ForwardFillMax.INVITED_ONLY
                                  ? ForwardFillMax.INVITED_FIRST
                                  : ForwardFillMax.INVITED_ONLY,
                            },
                            roleId,
                          )
                        }
                      />
                    }
                    label={
                      <Text variant="h6" color={theme.colors.MidnightBlue}>
                        Invite all qualified Traba workers if these workers are
                        not available?{' '}
                        <Text variant="caption">
                          {forwardFillMax === ForwardFillMax.INVITED_FIRST
                            ? 'Yes'
                            : 'No'}
                        </Text>
                      </Text>
                    }
                  />
                </FormGroup>
              )}

              {hasInvitations && (
                <Text variant="link" onClick={() => setIsModalOpen(true)}>
                  Edit workers
                </Text>
              )}
            </Row>

            {showInvitationsWarning && (
              <InlineBanner
                severity="warning"
                text={`Currently, ${
                  selectedWorkers.size +
                  (existingInvitationWorkerIds?.length ?? 0)
                } of the ${slotsRequested} slots requested will be reserved for the invited workers.
                Either decrease the number of workers requested or invite more workers to be able to disable this option.`}
                style={{ marginTop: theme.space.xs }}
              />
            )}

            <Table style={{ marginTop: theme.space.xxs }}>
              {!!existingInvitedWorkers?.length && (
                <TableHeader>
                  <Text
                    variant="h6"
                    style={{
                      paddingLeft: theme.space.xs,
                    }}
                  >
                    Previously invited workers
                  </Text>
                </TableHeader>
              )}
              {existingInvitedWorkers?.map((worker) => (
                <WorkerRow worker={worker} />
              ))}
            </Table>

            {newWorkerInvitations?.map((shiftInvitation) => {
              if (!shiftInvitation.workers.length) {
                return null
              }

              return (
                <Table
                  key={shiftInvitation.batch}
                  style={{ marginTop: theme.space.xxs }}
                >
                  {!!shiftInvitation.workers.length && (
                    <TableHeader>
                      <Text variant="h6" style={{ padding: theme.space.xxs }}>
                        New Workers to Invite
                      </Text>
                    </TableHeader>
                  )}
                  {shiftInvitation.workers.map((worker) => {
                    return worker && <WorkerRow worker={worker} />
                  })}
                </Table>
              )
            })}
          </>
        )}
      </S.ClickableCard>

      <BookShiftsInvitationsModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        selectedRoster={selectedRoster}
        setSelectedRoster={setSelectedRoster}
        selectedWorkers={selectedWorkers}
        setSelectedWorkers={setSelectedWorkers}
        onConfirm={onClickContinue}
        roster={roster}
        onClickWorker={onClickWorker}
        confirmOnDismiss
        disabledWorkerIdsSet={new Set(existingInvitationWorkerIds)}
        locationId={locationId}
        roleId={roleId}
        shiftRequest={shiftRequest}
        selectedSingleShiftDates={selectedSingleShiftDates}
      />
    </>
  )
}
