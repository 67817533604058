import { ShiftStatus } from '@traba/types'
import { getShiftTimeString } from '@traba/utils'
import { Event as BigCalendarEvent } from 'react-big-calendar'
import { Col, Row, Text } from 'src/components/base'
import { theme } from 'src/libs/theme'
import { getAddressString } from 'src/utils/stringUtils'
import {
  TrabaCalendarEvent,
  isShiftRequestParentWithShifts,
} from './CalendarView'

export const CalendarViewCustomEvent = ({
  event,
  currView,
}: {
  event: BigCalendarEvent
  currView: string
}) => {
  const typedEvent = event as TrabaCalendarEvent
  const { start, end, resource } = typedEvent
  const { item } = resource ?? {}

  if (!item) {
    return <></>
  }

  if (isShiftRequestParentWithShifts(item)) {
    return (
      <Col
        style={{
          whiteSpace: 'nowrap',
          width: '100%',
        }}
      >
        <Text variant="h7" style={{ marginBottom: theme.space.xxxs }}>
          {item.title ? item.title : 'Untitled Schedule'}
        </Text>
        {currView !== 'month' && (
          <>
            <Text variant="body3" style={{ marginBottom: theme.space.xxxs }}>
              {item.timeString}
            </Text>
            {item.shifts.map((shiftItem) => {
              return (
                <Row
                  style={{
                    border: `1px solid ${shiftItem.style.borderColor}`,
                    borderRadius: 4,
                    backgroundColor: shiftItem.style.backgroundColor,
                  }}
                  my={theme.space.ms}
                >
                  <Text variant="h7" style={{ margin: theme.space.xxxs }}>
                    {shiftItem.shift.shiftRole}
                  </Text>
                </Row>
              )
            })}
          </>
        )}
      </Col>
    )
  } else {
    const shift = item
    return (
      <Col
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        <s
          style={{
            color:
              shift.status === ShiftStatus.CANCELED
                ? theme.colors.MidnightBlue
                : 'transparent',
          }}
        >
          <Text variant="h7">{event.title}</Text>
          {currView !== 'month' && (
            <>
              {start && end && (
                <Text variant="body3" color={theme.colors.MidnightBlue}>
                  {getShiftTimeString(start, end, shift.timezone)}
                </Text>
              )}
              <Text variant="body3" color={theme.colors.MidnightBlue}>
                {getAddressString(shift.address)}
              </Text>
            </>
          )}
        </s>
      </Col>
    )
  }
}
