import * as Sentry from '@sentry/react'
import { useAlert } from '@traba/context'
import { MODAL_SIZE } from '@traba/react-components'
import React, { useState } from 'react'
import { Button, ButtonVariant, Input, Row, Text } from 'src/components/base'
import useAppAuth from 'src/hooks/authHook'
import {
  RequestAccountDeleteResponse,
  useRequestDeleteAccount,
} from 'src/hooks/useRequestDeleteAccount'
import { theme } from 'src/libs/theme'

import { Checkbox } from '../base/CheckboxThemed'
import { Dialog } from '../base/Dialog/Dialog'
import { MultiLineInput } from '../base/Input/Input.styles'

const deleteReasons = [
  'I am a worker who accidentally created a company account.',
  'My company doesn’t need to use Traba.',
  'I don’t need access to this account anymore.',
  'Other',
]

export const DeleteAccountUI: React.FC = () => {
  const { showSuccess, showError } = useAlert()
  const { handleLogout, validateExistingPassword, userHasEmailProvider } =
    useAppAuth()
  const { requestAccountDeletion } = useRequestDeleteAccount()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [selectedReason, setSelectedReason] = useState<string | undefined>(
    undefined,
  )
  const [extraDetails, setExtraDetails] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const [confirmationLoading, setConfirmationLoading] = useState(false)

  const requiresPasswordValidation = userHasEmailProvider()

  const validateUserPassword = async (password: string) => {
    try {
      return await validateExistingPassword(password)
    } catch (error) {
      setPasswordConfirmation('')
      showError('Incorrect password. Please try again.')
      setConfirmationLoading(false)
    }
  }

  const handleConfirmationClose = () => {
    setShowConfirmModal(false)
    setPasswordConfirmation('')
  }

  const handleDeleteConfirmation = async () => {
    if (!selectedReason) {
      showError('Please select a reason for deleting your account.')
      return
    }
    setConfirmationLoading(true)
    if (requiresPasswordValidation) {
      const credential = await validateUserPassword(passwordConfirmation)
      if (!credential) {
        return
      }
    }
    requestAccountDeletion(
      {
        reasons: [selectedReason],
        extraDetails,
      },
      {
        onSuccess: (data: RequestAccountDeleteResponse) => {
          showSuccess(
            data.userDeleted
              ? 'Your account has been deleted.'
              : 'A request to delete your account has been submitted.',
          )
          if (data.userDeleted) {
            handleLogout()
          }
          setSelectedReason(undefined)
          setExtraDetails('')
          setShowConfirmModal(false)
        },
        onError: (e: Error) => {
          Sentry.captureException(e)
          showError('There was an error requesting your account be deleted.')
        },
        onSettled: () => {
          setConfirmationLoading(false)
        },
      },
    )
  }

  return (
    <div
      style={{
        marginBottom: theme.space.xs,
      }}
    >
      <div style={{ marginBottom: theme.space.med }}>
        <Text style={{ marginBottom: theme.space.xxs }} variant="h5">
          Delete Account
        </Text>
        <Text style={{ marginBottom: theme.space.xxs }} variant="body2">
          Deleting your account will remove your profile from Traba, and you
          will lose any open shifts or roles you have with us. After you submit
          a request to delete your account, we will review it and process it.
        </Text>
      </div>
      <div
        style={{
          flexGrow: 1,
          marginRight: theme.space.sm,
          marginBottom: theme.space.med,
        }}
      >
        <Text mb={theme.space.sm} variant="h5">
          Why are you deleting your account? (required)
        </Text>
        {Array.from(deleteReasons).map((reason, index) => (
          <Row mb={theme.space.xxs} key={index}>
            <Checkbox
              key={reason}
              selected={reason === selectedReason}
              onClick={() => {
                setSelectedReason(
                  reason === selectedReason ? undefined : reason,
                )
              }}
              style={{ marginRight: theme.space.xxs }}
            />
            <Text>{reason}</Text>
          </Row>
        ))}
      </div>
      <div style={{ marginBottom: theme.space.med }}>
        <Text mb={theme.space.xxs} variant="body1">
          Your feedback is important to us. Any additional details? (optional)
        </Text>
        <MultiLineInput
          onChange={(val) => setExtraDetails(val.target.value)}
          placeholder="Provide more details"
          style={{ width: 476, height: 144 }}
          value={extraDetails}
        />
      </div>
      <Button
        variant={ButtonVariant.CANCEL}
        onClick={() => {
          setShowConfirmModal(true)
        }}
        disabled={selectedReason === undefined}
      >
        Submit Delete Request
      </Button>
      <Dialog
        open={showConfirmModal}
        onClose={handleConfirmationClose}
        style={{ display: 'flex', flexDirection: 'column' }}
        size={MODAL_SIZE.SMALL}
        dialogTitle={'Are you sure you want to delete your account?'}
        onConfirmCTA="Submit Delete Request"
        dialogTitleIcon="error"
        onConfirm={handleDeleteConfirmation}
        confirmButtonVariant={ButtonVariant.CANCEL}
        confirmDisabled={
          requiresPasswordValidation && passwordConfirmation === ''
        }
        confirming={confirmationLoading}
      >
        <Text variant="body2" style={{ marginTop: theme.space.xs }}>
          This is a permanent action that will delete all your information.
        </Text>

        {requiresPasswordValidation && (
          <Input
            label="Confirm your password"
            type="password"
            value={passwordConfirmation}
            onChange={(event) => setPasswordConfirmation(event.target.value)}
          />
        )}
      </Dialog>
    </div>
  )
}
