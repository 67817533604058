import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { LocationResponse, RecordStatus } from '@traba/types'

export const SHIFTS_LOCATIONS_QUERY_KEY = 'shiftsLocations'

interface GetShiftsLocationsParams {
  supervisorId: string | undefined
  recordStatus?: RecordStatus
  minShiftStartTime?: string
}

const getShiftsLocationsForSupervisor = async (
  queryParams: GetShiftsLocationsParams,
): Promise<LocationResponse[] | undefined> => {
  try {
    const response = await trabaApi.get(`/my-company/shifts-locations`, {
      params: queryParams,
    })
    return response.data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error, {
      tags: { action: `Get Locations For Future Shifts of Supervisor` },
      extra: { supervisorId: queryParams.supervisorId },
    })
    throw error
  }
}

export const useShiftsLocationsForSupervisor = ({
  supervisorId,
  recordStatus,
  minShiftStartTime,
}: GetShiftsLocationsParams) => {
  const {
    isLoading,
    isError,
    data: locations = [],
    error,
    isFetched,
    refetch,
  } = useQuery<LocationResponse[] | undefined, Error>({
    queryKey: [
      SHIFTS_LOCATIONS_QUERY_KEY,
      supervisorId,
      minShiftStartTime,
      recordStatus,
    ],
    queryFn: () =>
      getShiftsLocationsForSupervisor({
        supervisorId,
        recordStatus,
        minShiftStartTime,
      }),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!supervisorId,
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    locations,
    refetch,
  }
}
