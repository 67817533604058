import { MainLayout } from 'src/components'
import { CompanySettings } from 'src/components/Settings/CompanySettings'

export default function CompanySettingsScreen() {
  return (
    <MainLayout title="Company Settings">
      <CompanySettings />
    </MainLayout>
  )
}
