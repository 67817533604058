import styled, { css } from 'styled-components'

export const StyledSvg = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ onClick }) => {
    if (onClick) {
      return css`
        :hover {
          background: ${({ theme }) => theme.colors.Grey20};
          border-radius: 50%;
          cursor: pointer;
        }
      `
    }
  }};
`
