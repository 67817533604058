import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import {
  WorkerCertification,
  WorkerMetrics,
  WorkerProfile,
  Worker,
} from '@traba/types'
import { Pagination } from './usePagination'

export interface WorkerSearchParameters {
  firstName?: string
  lastName?: string
  regionIds?: string[]
  behavioralAttributes?: string[]
  shiftAttributes?: string[]
  industries?: string[]
}

export interface SearchWorker extends Worker {
  metrics?: WorkerMetrics
  certifications: WorkerCertification[]
  profile: WorkerProfile[]
}
export interface WorkerSearchResponse {
  workers: SearchWorker[]
  count: number
}

async function workerSearch(
  searchParams: WorkerSearchParameters,
  pagination: Pagination,
): Promise<WorkerSearchResponse | undefined> {
  try {
    const params = new URLSearchParams({
      startAt: pagination.startAt.toString(),
      limit: pagination.limit.toString(),
    })
    const response = await trabaApi.post(
      `/my-company/workers/search${
        params.size > 0 ? `?${params.toString()}` : ''
      }`,
      {
        parameters: searchParams,
      },
    )
    return response.data as WorkerSearchResponse
  } catch (error) {
    Sentry.captureException(error)
    return undefined
  }
}

export function useWorkerSearch(
  searchParams: WorkerSearchParameters,
  pagination: Pagination,
) {
  return useQuery<WorkerSearchResponse | undefined, Error>({
    queryKey: ['worker-search', searchParams, pagination],
    queryFn: () => workerSearch(searchParams, pagination),
  })
}
