import { trabaApi } from '@traba/api-utils'
import {
  ChatMessage,
  ChatMessageContent,
  UpdateChatMessageRequest,
} from '@traba/types'
import { useInfiniteQuery } from 'react-query'

export const MESSAGES_PER_PAGE = 20
export const updateChatMessage = async (
  request: UpdateChatMessageRequest,
  messageId: string,
) => {
  const response = await trabaApi.patch(
    `my-company/chats/messages/${messageId}`,
    request,
  )
  return response.data
}

export const postChatMessage = async (
  request: ChatMessageContent,
  summaryId: string,
) => {
  const response = await trabaApi.post(
    `my-company/chats/${summaryId}?doModerationAsync=true`,
    request,
  )
  return response.data
}

export const useMessages = (summaryId: string) => {
  const getMessages = async ({
    pageParam = 0,
  }): Promise<{ data: ChatMessage[]; nextPage?: number }> => {
    try {
      const response = await trabaApi.get(
        `/my-company/chats/${summaryId}?startAt=${
          pageParam * MESSAGES_PER_PAGE
        }&limit=${MESSAGES_PER_PAGE}`,
      )

      return {
        data: response.data.messages,
        nextPage: pageParam + 1,
      }
    } catch (error) {
      console.error(error)
      return { data: [], nextPage: undefined }
    }
  }

  const {
    isLoading,
    isError,
    error,
    isFetched,
    data: messages,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery<{ data: ChatMessage[]; nextPage?: number }, Error>(
    ['messages', summaryId],
    getMessages,
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.data.length === 0) {
          return null
        }

        return lastPage?.nextPage
      },
    },
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    refetch,
    messages,
    fetchNextPage,
    hasNextPage,
  }
}
