import { Card, CardTile } from '@traba/react-components'
import React from 'react'
import { theme } from 'src/libs/theme'
import { Col, Image, Row, SvgIcon, Text } from '../base'
import { Checkbox } from '../base/CheckboxThemed'
import * as S from './SelectWorkerCard.styles'

export enum SelectWorkerCardAction {
  Add = 'ADD',
  Remove = 'REMOVE',
}

export interface SelectWorkerCardProps {
  disabled?: boolean
  onClick: () => void
  icon?: JSX.Element
  photoUrl?: string
  title?: string
  description?: React.ReactNode
  selected?: boolean
  action: SelectWorkerCardAction
  checkbox?: boolean
  badge?: JSX.Element
}

export const SelectWorkerCard = (props: SelectWorkerCardProps) => {
  const {
    disabled,
    onClick,
    title,
    description,
    selected,
    icon,
    photoUrl,
    action,
    checkbox = false,
    badge,
  } = props
  return (
    <Card
      style={{ margin: `${theme.space.xxs}px 0`, padding: theme.space.xs }}
      isClickable={!disabled}
      disabled={disabled}
      onClick={onClick}
    >
      <S.StyledRow style={{ columnGap: theme.space.xs }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            columnGap: theme.space.xs,
            width: '100%',
          }}
        >
          <Row alignCenter gap={theme.space.xs}>
            <CardTile
              style={{ alignSelf: 'center', flexShrink: 0 }}
              size="40px"
            >
              {photoUrl ? (
                <Image
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: theme.space.xxs,
                  }}
                  src={photoUrl}
                  alt="worker-photo"
                />
              ) : (
                icon
              )}
            </CardTile>
            <Col>
              <Text variant="h6">{title}</Text>
              {description && <Text variant="body3">{description}</Text>}
            </Col>
          </Row>
          {badge}
        </div>
        {!checkbox ? (
          <SvgIcon
            size={28}
            name={
              selected
                ? action === SelectWorkerCardAction.Add
                  ? 'addInverse'
                  : 'subtractInverse'
                : action === SelectWorkerCardAction.Add
                  ? 'add'
                  : 'subtract'
            }
            color={
              disabled
                ? theme.colors.Grey30
                : action === SelectWorkerCardAction.Add
                  ? theme.colors.brand
                  : theme.colors.Red60
            }
          />
        ) : (
          <Checkbox selected={selected || false} />
        )}
      </S.StyledRow>
    </Card>
  )
}
