import { Col, MODAL_SIZE, SELECT_ALL_OPTION_ID } from '@traba/react-components'
import { COMPANY_WIDE_ID, USER_ROLE_NAMES } from '@traba/types'
import {
  assignedLocationIdsForMember,
  containsText,
  hasRole,
  isBizMemberCompanyWide,
  sortMemberByFullName,
} from '@traba/utils'
import { useCallback, useMemo, useState } from 'react'
import { Modal } from 'src/components'
import { useMembers } from 'src/hooks/useMembers'
import { useHotSettings } from 'src/hooks/useSystem'
import { useUserCanManageUsers } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { CreateInvitationModal } from '../../Modals/CreateInvitationModal'
import { SearchWithLocationSelectionRow } from '../../SearchWithLocationSelectionRow'
import { MEMBERS_COPY } from '../constants'
import { MembersSubheader } from '../MembersSubheader'
import { ActiveMembersTable } from './ActiveMembersTable'

export function ActiveMembersSection() {
  const { hotSettings } = useHotSettings()
  const userCanEditActiveMembersAndInvitations = useUserCanManageUsers()

  const { members, isLoading } = useMembers()

  const [searchFilterText, setSearchFilterText] = useState('')
  const [selectedLocation, setSelectedLocation] = useState(SELECT_ALL_OPTION_ID)

  const isAllLocationsSelected = selectedLocation === SELECT_ALL_OPTION_ID
  const isCompanyWideSelected = selectedLocation === COMPANY_WIDE_ID
  const selectedLocationId =
    !isAllLocationsSelected && !isCompanyWideSelected
      ? selectedLocation
      : undefined

  const filteredMembers = useMemo(
    () =>
      members
        .filter((member) => {
          const isMemberCompanyWide = isBizMemberCompanyWide(member)
          const locationIdsForMember = new Set(
            assignedLocationIdsForMember(member),
          )

          const isMemberInSelectedLocation =
            isAllLocationsSelected ||
            (isMemberCompanyWide && isCompanyWideSelected) ||
            (selectedLocationId && locationIdsForMember.has(selectedLocationId))
          const showMemberBasedOnLocation = isMemberInSelectedLocation

          if (
            hotSettings?.enableRegionalAccessPhase2 &&
            !showMemberBasedOnLocation
          ) {
            return false
          }

          const textMatches = `${member.firstName} ${member.lastName} ${member.role ? USER_ROLE_NAMES[member.role] : ''}`
          return containsText(textMatches, searchFilterText)
        })
        .sort(sortMemberByFullName),
    [
      members,
      hotSettings?.enableRegionalAccessPhase2,
      searchFilterText,
      isAllLocationsSelected,
      isCompanyWideSelected,
      selectedLocationId,
    ],
  )

  const membersWithRoles = useMemo(
    () => filteredMembers.filter(hasRole),
    [filteredMembers],
  )

  const [showInvitationModal, setShowInvitationModal] = useState(false)
  const handleInvitationModalOpen = useCallback(() => {
    setShowInvitationModal(true)
    window.analytics.track(`User Clicked Invite Member`)
  }, [])
  const handleInvitationModalClose = useCallback(() => {
    setShowInvitationModal(false)
  }, [])

  return (
    <Col gap={theme.space.xs}>
      <MembersSubheader
        title={MEMBERS_COPY.ACTIVE_MEMBERS_TITLE}
        description={
          userCanEditActiveMembersAndInvitations
            ? MEMBERS_COPY.ACTIVE_MEMBERS_EDITOR_DESCRIPTION
            : MEMBERS_COPY.ACTIVE_MEMBERS_VIEWER_DESCRIPTION
        }
        action={MEMBERS_COPY.ACTIVE_MEMBERS_ACTION}
        onClick={handleInvitationModalOpen}
        userCanTakeAction={userCanEditActiveMembersAndInvitations}
      />
      <SearchWithLocationSelectionRow
        filtersKey="members"
        analyticsKey="Members"
        searchFilterText={searchFilterText}
        setSearchFilterText={setSearchFilterText}
        searchPlaceholder="Search by member name or role"
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        hideLocationSearch={!hotSettings?.enableRegionalAccessPhase2}
        allLocationsSelectedText="All members"
      />
      <div>
        <ActiveMembersTable
          members={membersWithRoles}
          userCanEdit={userCanEditActiveMembersAndInvitations}
          isLoading={isLoading}
        />

        <Modal
          size={MODAL_SIZE.LARGE}
          isOpen={showInvitationModal}
          handleClose={handleInvitationModalClose}
        >
          <CreateInvitationModal
            handleClose={handleInvitationModalClose}
            member={null}
          />
        </Modal>
      </div>
    </Col>
  )
}
