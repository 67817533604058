import { Tooltip } from '@mui/material'
import { UserRole } from '@traba/types'
import { useState } from 'react'
import { Button, ButtonVariant } from 'src/components'
import { theme } from 'src/libs/theme'
import { UserWithRole } from 'src/types'

import SvgIcon from '../base/SvgIcon'
import { MembersSettingsTooltipContent } from './MembersPermissionTooltipContent'

interface Props {
  member: UserWithRole
  self?: string
}

export const MembersProfilePermission = ({ member }: Props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const handleTooltipClose = () => {
    tooltipOpen && setTooltipOpen(false)
  }

  const handleButtonClick = () => {
    window.analytics?.track(`User Clicked Permission Icon In Members Table`, {
      userId: member.uid,
      isOpen: !tooltipOpen,
    })
    setTooltipOpen(!tooltipOpen)
  }

  const handleCloseTooltip = () => {
    tooltipOpen && setTooltipOpen(false)
  }

  return (
    <Tooltip
      onClose={handleTooltipClose}
      open={tooltipOpen}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      placement="bottom-start"
      sx={{ m: 1 }}
      PopperProps={{
        disablePortal: true,
      }}
      componentsProps={{
        tooltip: {
          style: {
            maxWidth: 500,
            minWidth: 400,
            background: theme.colors.White,
            border: `1px solid ${theme.colors.Grey20}`,
            padding: theme.space.xs,
          },
        },
      }}
      title={
        <MembersSettingsTooltipContent
          member={member}
          handleCloseTooltip={handleCloseTooltip}
        />
      }
    >
      {member.role === UserRole.Admin ? (
        <></>
      ) : (
        <Button
          style={{ height: 48, width: 48, marginLeft: theme.space.sm }}
          onClick={handleButtonClick}
          variant={ButtonVariant.OUTLINED}
        >
          <SvgIcon
            name="userSettings"
            color={tooltipOpen ? theme.colors.brand : theme.colors.Grey50}
          />
        </Button>
      )}
    </Tooltip>
  )
}
