import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useQuery, UseQueryResult } from 'react-query'
import { WorkerShift } from 'shared/types/src/lib/worker-shift'
import { SearchParams } from 'src/types'
import { useSelectedRegionalFilterLocations } from './useRegionalFilter'

export interface WorkerHistoryWithCompanyDto {
  shiftsWithCompany: number
  minutesWithCompany: number
  lastWorkedAtCompany?: Date
}

export const WORKER_HISTORY_QUERY_KEY = 'workerHistoryWithMe'
export const WORKER_SHIFT_HISTORY_QUERY_KEY = 'workerShiftHistoryWithMe'

const getWorkerHistoryWithMe = async ({
  workerId,
  locationIds,
}: {
  workerId: string
  locationIds?: string[]
}): Promise<WorkerHistoryWithCompanyDto | undefined> => {
  try {
    const response = await trabaApi.post(
      `/my-company/workers/${workerId}/query-history-with-me`,
      {
        locationIds,
      },
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

export const useWorkerHistoryWithMe = (workerId: string) => {
  const { selectedLocationIdsArray } = useSelectedRegionalFilterLocations()
  const {
    isLoading,
    isError,
    data: historyWithMe,
    error,
    isFetched,
  } = useQuery<WorkerHistoryWithCompanyDto | undefined, Error>({
    queryKey: [WORKER_HISTORY_QUERY_KEY, workerId, selectedLocationIdsArray],
    queryFn: () =>
      getWorkerHistoryWithMe({
        workerId,
        locationIds: selectedLocationIdsArray,
      }),
    staleTime: FIVE_MINUTES_IN_MS,
    refetchOnWindowFocus: 'always',
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    historyWithMe,
  }
}

export const queryWorkerShiftHistoryWithMe = async (
  params: SearchParams,
): Promise<WorkerShift[]> => {
  const { startAt, startAtIndex, limit, workerId, locationIds } = params
  try {
    const res = await trabaApi.post(
      `my-company/workers/${workerId}/query-worker-shifts`,
      {
        startAt,
        startAtIndex,
        limit,
        locationIds,
      },
    )
    const { workerShifts } = res.data
    return workerShifts
  } catch (error) {
    Sentry.captureException(error, {
      tags: { action: 'workers/:workerId/worker-shifts' },
      extra: { params },
    })
    console.error(error)
    throw error
  }
}

export const useWorkerShiftHistoryWithMe = (
  queryDto: Pick<
    SearchParams,
    'workerId' | 'limit' | 'startAt' | 'startAtIndex'
  >,
): UseQueryResult<WorkerShift[], Error> => {
  const { limit, startAt, startAtIndex, workerId } = queryDto
  const { selectedLocationIdsArray } = useSelectedRegionalFilterLocations()

  const queryKey = [
    WORKER_SHIFT_HISTORY_QUERY_KEY,
    limit,
    startAt,
    startAtIndex,
    workerId,
    selectedLocationIdsArray,
  ]

  return useQuery<WorkerShift[], Error>(
    queryKey,
    () => {
      return queryWorkerShiftHistoryWithMe({
        limit,
        startAt,
        startAtIndex,
        workerId,
        locationIds: selectedLocationIdsArray,
      })
    },
    {
      enabled: !!queryDto.workerId,
      staleTime: FIVE_MINUTES_IN_MS,
      refetchOnWindowFocus: 'always',
    },
  )
}
