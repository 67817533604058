import { Tooltip } from '@mui/material'
import { SvgIcon, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import useMobile from 'src/hooks/useMobile'
import { getReadableTimeInTimezone } from 'src/shared/utils/dateUtils'

interface AbandonedTimeProps {
  canEdit: boolean
  value: Date
  timezone: string
}

export function AbandonedTime(props: AbandonedTimeProps) {
  const { isMobileViewOrReactNative } = useMobile()
  const { canEdit, value, timezone } = props
  const displayValue = getReadableTimeInTimezone(value, timezone, true)
  return (
    <Tooltip
      title={
        canEdit
          ? 'This worker abandoned their shift, you cannot adjust their times. Please reach out to support if you need to adjust their times.'
          : ''
      }
    >
      <div>
        <Row alignCenter gap={theme.space.xxs}>
          {isMobileViewOrReactNative ? (
            <Text
              mt={theme.space.xxxs}
              variant="body2"
              color={theme.colors.MidnightBlue}
            >
              {displayValue}
            </Text>
          ) : (
            displayValue
          )}
          {canEdit && <SvgIcon name="info" />}
        </Row>
      </div>
    </Tooltip>
  )
}
