import { LoadingSpinner } from '@traba/react-components'
import { theme } from '@traba/theme'
import React from 'react'

import { Text } from '../base'
import * as S from './Table.styles'
import { TableCell, TableFooter, TableRow } from './Table.styles'

export interface TableProps {
  children?: React.ReactNode
  headers?: string[] | React.ReactNode
  style?: React.CSSProperties
  headerStyle?: React.CSSProperties
  isLoading?: boolean
  error?: Error | null
  itemType?: string
  showEmptyState?: boolean
  centerHeaders?: boolean
  textAlignEndLast?: boolean
  stickyLastColumn?: boolean
}

export { TableRow as Tr, TableCell as Td, TableFooter }

function TableLoading() {
  return (
    <S.TableLoadingOrError>
      <LoadingSpinner style={{ width: 50 }} />
    </S.TableLoadingOrError>
  )
}

function TableError(props: Pick<TableProps, 'itemType'>) {
  return (
    <S.TableLoadingOrError>
      <Text variant="body2">
        We encountered an error loading your {props.itemType || 'data'}.
      </Text>
    </S.TableLoadingOrError>
  )
}

function TableEmpty(props: Pick<TableProps, 'itemType'>) {
  return (
    <S.TableLoadingOrError>
      <Text variant="body2">No {props.itemType || 'data'} found.</Text>
    </S.TableLoadingOrError>
  )
}

export function Table({
  children,
  headers,
  style,
  headerStyle,
  isLoading,
  error,
  itemType,
  showEmptyState,
  centerHeaders,
  stickyLastColumn,
}: TableProps) {
  const empty =
    !!showEmptyState && (Array.isArray(children) ? !children.length : !children)
  const stickyStyle: React.CSSProperties = {
    position: 'sticky',
    right: 0,
    zIndex: 1,
    background: theme.colors.Grey20,
    borderLeft: `1px solid ${theme.colors.Grey20}`,
  }
  return (
    <>
      <S.TableContainer style={style}>
        <thead>
          <S.TableHeader style={{ ...headerStyle }}>
            {Array.isArray(headers) &&
              headers.map((header, index) => (
                <S.TableHeaderItem
                  style={{
                    ...(centerHeaders &&
                    index !== 0 &&
                    index !== headers.length - 1
                      ? { textAlign: 'center' }
                      : {}),
                    ...(stickyLastColumn &&
                      index === headers.length - 1 &&
                      stickyStyle),
                    ...headerStyle,
                  }}
                  key={`${index}_${header}`}
                >
                  {stickyLastColumn && index === headers.length - 1 && (
                    <div
                      style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        borderLeft: `1px solid ${theme.colors.Grey30}`,
                        width: 0,
                      }}
                    />
                  )}
                  {header}
                </S.TableHeaderItem>
              ))}
          </S.TableHeader>
        </thead>
        {!isLoading && !error && !empty ? (
          <S.TableBody>{children}</S.TableBody>
        ) : null}
      </S.TableContainer>
      {isLoading ? (
        <TableLoading />
      ) : error ? (
        <TableError itemType={itemType} />
      ) : empty ? (
        <TableEmpty itemType={itemType} />
      ) : null}
    </>
  )
}
