import { Tooltip } from '@mui/material'
import { Card, DotMenu, LoadingSpinner, SvgIcon } from '@traba/react-components'
import { Pill } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import {
  BehaviorAttribute,
  CompanyCategory,
  RoleAttribute,
  WorkerCertificationType,
} from '@traba/types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useBehaviorAttributes,
  useRoleAttributes,
} from 'src/hooks/useAttributes'
import useMobile from 'src/hooks/useMobile'
import { useHotSettings } from 'src/hooks/useSystem'
import { SearchWorker } from 'src/hooks/useWorkerSearch'
import { BULLET_CHAR } from 'src/libs/constants'
import { theme } from 'src/libs/theme'
import { Button, ButtonVariant, Col, Row, Text } from '../base'
import { Checkbox } from '../base/CheckboxThemed'
import { IndustryBadge } from '../base/WorkerBadge/IndustryBadge'
import { getWorkerBadges, WorkerBadge } from '../base/WorkerBadge/WorkerBadge'
import { ProfileIcon, ProfileImage } from '../Header/Header.styles'
import { WorkerLinkText } from '../WorkerDetails/WorkerLinkText'

const INDUSTRY_MAX = 3
const ATTRIBUTES_MAX = 5

export function WorkerSearchItem({
  worker,
  selectedIndustries,
  selectedAttributes: { behavioralAttributes, shiftAttributes },
  selected,
  onCheckboxClicked,
  addToRosterClicked,
  addMargin,
  inviteToShiftClicked,
}: {
  worker: SearchWorker
  selectedIndustries: CompanyCategory[]
  selectedAttributes: {
    behavioralAttributes: string[] | undefined
    shiftAttributes: string[] | undefined
  }
  selected: boolean
  onCheckboxClicked: () => void
  addToRosterClicked: () => void
  addMargin?: boolean
  inviteToShiftClicked: () => void
}) {
  const forkliftCertification = worker.certifications.find(
    (certification) =>
      certification.certificationType === WorkerCertificationType.OSHA_FORKLIFT,
  )
  const { behaviorAttributes, isLoading: behaviorAttrsAreLoading } =
    useBehaviorAttributes()
  const { roleAttributes, isLoading: roleAttrsAreLoading } = useRoleAttributes()
  const { hotSettings } = useHotSettings()
  const certObj = Object.fromEntries(
    (hotSettings?.certifications || []).map((cert) => [cert.type, cert]),
  )
  const navigate = useNavigate()

  const { isMobileViewOrReactNative } = useMobile()

  if (
    !behaviorAttributes ||
    behaviorAttrsAreLoading ||
    !roleAttributes ||
    roleAttrsAreLoading
  ) {
    return <LoadingSpinner />
  }

  function getIndustryItems() {
    const industries = Object.keys(
      worker.metrics?.allTimeMetrics.industries || {},
    ).map((industry) => industry as CompanyCategory)

    const selectedIndustriesSet = new Set(selectedIndustries)
    const sortedIndustries = industries.sort((a, b) => {
      const aSelected = selectedIndustriesSet.has(a)
      const bSelected = selectedIndustriesSet.has(b)
      if (aSelected && !bSelected) {
        return -1
      }
      if (bSelected && !aSelected) {
        return 1
      }
      return 0
    })
    return sortedIndustries.slice(0, INDUSTRY_MAX)
  }

  function getAttributeItems() {
    const allAttributes = new Map<string, number>([
      ...Object.entries(
        worker.metrics?.allTimeMetrics.highlyRatedBehaviorAttributes || {},
      ),
      ...Object.entries(
        worker.metrics?.allTimeMetrics.highlyRatedRoleAttributes || {},
      ),
    ])
    const selectedAttrsSet = new Set([
      ...(behavioralAttributes || []),
      ...(shiftAttributes || []),
    ])
    const sortedAttributes = Array.from(allAttributes.keys()).sort((a, b) => {
      const aSelected = selectedAttrsSet.has(a)
      const bSelected = selectedAttrsSet.has(b)
      if (aSelected && !bSelected) {
        return -1
      }
      if (bSelected && !aSelected) {
        return 1
      }
      const aCount = allAttributes.get(a)
      const bCount = allAttributes.get(b)
      if (aCount !== undefined && bCount !== undefined) {
        return bCount - aCount
      }
      return 0
    })
    const behaviorAttrsMap = new Map(
      (behaviorAttributes || []).map((attr) => [attr.type, attr]),
    )
    const roleAttrsMap = new Map(
      (roleAttributes || []).map((attr) => [attr.type, attr]),
    )
    const attrItems: React.ReactNode[] = []
    for (
      let i = 0;
      i < sortedAttributes.length && attrItems.length < ATTRIBUTES_MAX;
      i++
    ) {
      const attr = sortedAttributes[i]
      const count = allAttributes.get(attr)
      const behaviorAttributeDisplayName =
        hotSettings?.useBehaviorAttributesFromRoleAttributes
          ? (behaviorAttrsMap.get(attr) as RoleAttribute)?.displayName
          : (behaviorAttrsMap.get(attr) as BehaviorAttribute)?.displayNameEn
      const displayName =
        behaviorAttributeDisplayName || roleAttrsMap.get(attr)?.displayName
      if (!displayName) {
        continue
      }
      if (count === undefined) {
        continue
      }
      attrItems.push(
        <Tooltip
          key={attr}
          title={`${count} positive review${makePlural(
            count,
          )} for ${displayName}`}
          placement="top"
        >
          <div>
            <Pill
              addMargin={true}
              children={
                <Text
                  variant="body1"
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    overflowWrap: 'break-word',
                    display: 'block',
                    textOverflow: 'ellipsis',
                    lineClamp: 1,
                    maxLines: 1,
                  }}
                >
                  {displayName}
                </Text>
              }
            />
          </div>
        </Tooltip>,
      )
    }
    return attrItems
  }

  const infoItems = [
    ...(worker.certifications.length > 0
      ? [
          <Row style={{ alignItems: 'center' }}>
            <SvgIcon
              style={{ marginRight: theme.space.xxxs }}
              name="certification"
              size={24}
            />
            <Text
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                minWidth: 0,
              }}
              variant="body1"
            >
              {
                certObj[
                  (forkliftCertification || worker.certifications[0])
                    .certificationType
                ]?.en.name
              }
            </Text>
          </Row>,
        ]
      : []),
  ]
  const industryItems = getIndustryItems()
  const workerBadgesItems = getWorkerBadges({
    profile: worker.profile,
    completedShifts: worker.metrics?.allTimeMetrics.completedShifts,
    onTimeRate: worker.metrics?.allTimeMetrics.onTimeRate,
    perfectShiftsStreak: worker.metrics?.perfectShiftsStreak,
    favoriteCount: worker.metrics?.allTimeMetrics.favoriteCount,
  })
  const attributeItems = getAttributeItems()
  return (
    <Card style={{ marginBottom: addMargin ? theme.space.xs : undefined }}>
      <Row>
        <Checkbox
          style={{ marginRight: theme.space.xs }}
          selected={selected}
          onClick={onCheckboxClicked}
        />
        <Col style={{ flex: 1 }}>
          <Row style={{ marginBottom: theme.space.xs }}>
            {worker.photoUrl ? (
              <ProfileImage src={worker.photoUrl} />
            ) : (
              <ProfileIcon name="userProfile" />
            )}
            <Row
              style={{
                flex: '1',
                alignItems: 'start',
                justifyContent: 'space-between',
              }}
            >
              <Col>
                <WorkerLinkText
                  variant="h5"
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                  onClick={() => {
                    window.analytics.track(
                      `Worker Search Worker Name Clicked`,
                      {
                        workerId: worker.workerId || worker.uid,
                        firstName: worker.firstName,
                        lastName: worker.lastName,
                      },
                    )
                    navigate(`/worker/${worker.uid}`)
                  }}
                >
                  {`${worker.firstName} ${worker.lastName}`}
                </WorkerLinkText>
                {workerBadgesItems.length > 0 ? (
                  <Row
                    style={{
                      flexWrap: 'wrap',
                      rowGap: theme.space.xxs,
                      marginTop: theme.space.xxxs,
                    }}
                  >
                    {workerBadgesItems.map((item, i) => {
                      return <WorkerBadge {...item} key={i} />
                    })}
                  </Row>
                ) : undefined}
              </Col>
              {isMobileViewOrReactNative ? (
                <DotMenu
                  disabled={selected}
                  type="search-menu"
                  menuItems={[
                    {
                      title: 'Add To Roster',
                      onClick: addToRosterClicked,
                    },
                    {
                      title: 'Invite to Shift',
                      onClick: inviteToShiftClicked,
                    },
                  ]}
                />
              ) : (
                <Row style={{ flexWrap: 'wrap', rowGap: theme.space.xxs }}>
                  <Button
                    style={{ marginRight: theme.space.xs }}
                    variant={ButtonVariant.BRANDLINK}
                    disabled={selected}
                    onClick={addToRosterClicked}
                  >
                    Add to roster
                  </Button>
                  <Button
                    style={{ marginRight: theme.space.xs }}
                    variant={ButtonVariant.BRANDLINK}
                    disabled={selected}
                    onClick={inviteToShiftClicked}
                  >
                    Invite to shift
                  </Button>
                </Row>
              )}
            </Row>
          </Row>
          {infoItems.length > 0 ? (
            <Row
              style={{
                flexWrap: 'wrap',
                rowGap: theme.space.xxs,
                marginBottom: theme.space.xs,
              }}
            >
              {infoItems.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    {item}
                    {i < infoItems.length - 1 ? (
                      <Text
                        style={{
                          marginRight: theme.space.xxxs,
                          marginLeft: theme.space.xxxs,
                        }}
                        variant="body1"
                      >
                        {BULLET_CHAR}
                      </Text>
                    ) : undefined}
                  </React.Fragment>
                )
              })}
            </Row>
          ) : undefined}
          {attributeItems.length > 0 ? (
            <Row
              style={{
                flexWrap: 'wrap',
                rowGap: theme.space.xxs,
                marginBottom: theme.space.xs,
              }}
            >
              {attributeItems}
            </Row>
          ) : undefined}
          {industryItems.length > 0 ? (
            <Row
              style={{
                flexWrap: 'wrap',
                rowGap: theme.space.xxs,
              }}
            >
              {industryItems.map((industry, i) => (
                <IndustryBadge key={i} industry={industry} />
              ))}
            </Row>
          ) : undefined}
        </Col>
      </Row>
    </Card>
  )
}
