import { ShiftRequest, User } from '@traba/types'
import { doesUserHaveAccessToLocation } from './businessUserUtils'

export function getFilteredShiftRequestsByAssignedLocations({
  shiftRequests,
  assignedLocationIds,
}: {
  shiftRequests: ShiftRequest[]
  assignedLocationIds: Set<string>
}): ShiftRequest[] {
  return shiftRequests.filter((shiftRequest) =>
    assignedLocationIds.has(shiftRequest.locationId),
  )
}

export function getSelectSupervisorForBookingShiftErrorMessage({
  locationId,
  supervisor,
  isRebook,
  isRegionalAccessEnabled,
}: {
  locationId?: string
  supervisor?: Partial<User>
  isRebook?: boolean
  isRegionalAccessEnabled?: boolean
}): string | undefined {
  if (isRebook && !supervisor) {
    return `The contact in the previous shift request was archived${isRegionalAccessEnabled ? ' or removed from this location' : ''}. Please select a new contact.`
  }

  if (!locationId) {
    return undefined
  }

  const doesSupervisorHaveAccessAtLocation = doesUserHaveAccessToLocation({
    user: supervisor,
    locationId,
  })

  if (!doesSupervisorHaveAccessAtLocation && supervisor) {
    return 'The chosen contact does not have access to the shift location. Please choose a contact that has access to the shift location.'
  }

  return undefined
}
