import styledComp, { css } from 'styled-components'
export const StrikeThrough = styledComp('div')<{
  strikeThrough?: boolean
}>`
    ${({ strikeThrough, color, theme }) => {
      if (!strikeThrough) {
        return
      }
      return css`
        &:after {
          content: '';
          border-bottom: 2px solid ${color || theme.colors.Blue100};
          width: 100%;
          position: absolute;
          right: 0;
          top: 47%;
        }
      `
    }}
  `
