import { SvgIcon } from '@traba/react-components'
import { RoleAttribute, ShiftAttribute } from '@traba/types'
import { Row, Text } from 'src/components/base'
import { theme } from 'src/libs/theme'

import { IconName } from 'src/types/svg-types'
import { ResponsibilityListItem } from './ResponsibilityListItem'

interface Props {
  responsibilities: RoleAttribute[]
  onChange(type: string): void
  isAttributeSelected: (a: ShiftAttribute) => boolean
  title: string
  iconName: IconName
}
export const ResponsibilityList = ({
  responsibilities,
  onChange,
  isAttributeSelected,
  title,
  iconName,
}: Props) => {
  return (
    <div
      style={{
        flexWrap: 'wrap',
        backgroundColor: theme.colors.Grey10,
        padding: theme.space.sm,
        marginTop: theme.space.xs,
      }}
    >
      <Row alignCenter>
        <SvgIcon name={iconName} size={theme.space.lg} />
        <Text variant="h5" ml={theme.space.xxs}>
          {title}
        </Text>
      </Row>

      <Row style={{ flexWrap: 'wrap' }}>
        {responsibilities.map((o) => (
          <ResponsibilityListItem
            {...o}
            key={`${o.type}_${o.category}`}
            selected={isAttributeSelected(o)}
            onChange={onChange}
          />
        ))}
      </Row>
    </div>
  )
}
