import { Shift, ShiftStatus } from '@traba/types'
import { AxiosError } from 'axios'
import { compact, isEmpty } from 'lodash'
import React from 'react'
import { UseMutateFunction } from 'react-query'
import {
  ButtonVariant,
  EmptyPlaceholderTile,
  Row,
  Text,
} from 'src/components/base'
import { useMembers } from 'src/hooks/useMembers'
import { UpdateShiftData } from 'src/types'
import { email } from 'src/utils/formUtils'

import { Autocomplete } from '../../base/Autocomplete/Autocomplete'
import Badge from '../../base/Badge'
import SvgIcon from '../../base/SvgIcon'
import * as S from '../UpcomingShiftDetails.styles'

const validateEmail = (str: string) => str && email().validateSync(str)

interface Props {
  editing: boolean
  setEditing: React.Dispatch<React.SetStateAction<boolean>>
  updateShift: UseMutateFunction<Shift, AxiosError, UpdateShiftData>
  setShiftToUpdate: React.Dispatch<React.SetStateAction<UpdateShiftData>>
  shiftToUpdate: UpdateShiftData
  shift: Shift
  hideTitle?: boolean
}

export default function ShareShiftDetails(props: Props) {
  const { endTime, status, supervisorId, additionalEmails = [] } = props.shift
  const {
    editing,
    setEditing,
    updateShift,
    setShiftToUpdate,
    shiftToUpdate,
    hideTitle,
  } = props
  const { members } = useMembers()
  const isPastShift = new Date() > endTime || status === ShiftStatus.CANCELED
  const isAdditionalEmailEmpty = isEmpty(additionalEmails)
  const onClickShareShift = () => {
    !editing
      ? setShiftToUpdate({
          ...shiftToUpdate,
          additionalEmails,
        })
      : updateShift(shiftToUpdate)
    setEditing(!editing)
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  const onChangeEmails = (event: React.ChangeEvent<{}>, value: string[]) => {
    setShiftToUpdate({
      ...shiftToUpdate,
      additionalEmails: value,
    })
  }

  const AddButton = !isPastShift ? (
    <Row alignCenter>
      {!editing && <SvgIcon name="edit" size={14} />}
      <S.EditButton variant={ButtonVariant.TEXT} onClick={onClickShareShift}>
        <Text variant="link1">
          {editing ? 'Save' : isAdditionalEmailEmpty ? 'Add' : 'Edit'}
        </Text>
      </S.EditButton>
    </Row>
  ) : undefined

  return (
    <>
      <Row justifyBetween={!hideTitle} justifyEnd={hideTitle} mb="xs">
        {!hideTitle && <Text variant="h4">Share shift details</Text>}
        {AddButton}
      </Row>
      {isAdditionalEmailEmpty && !editing ? (
        <EmptyPlaceholderTile
          iconName="info"
          title="You haven't shared shift details with anyone yet."
          subtitle={
            !isPastShift ? 'Click on Add to share shift details via email.' : ''
          }
        />
      ) : (
        <>
          <Text variant="body3" style={{ marginBottom: '20px' }}>
            We'll email reminders about this shift to the email addresses below.
            The onsite point of contact will always receive reminders.
          </Text>
          {editing ? (
            <>
              <Autocomplete
                label=""
                value={shiftToUpdate.additionalEmails || []}
                options={compact(members.map((m) => m.email)).filter((e) => {
                  const member = members.find((s) => s.uid === supervisorId)
                  return e !== member?.email
                })}
                onChangeValues={onChangeEmails}
                errorMessage="Email address is invalid"
                validateInput={validateEmail}
              />
              <Row mt={4} style={{ justifyContent: 'end' }}>
                <S.EditButton
                  variant={ButtonVariant.TEXT}
                  style={{ fontSize: 12 }}
                  onClick={() => {
                    setEditing(!editing)
                  }}
                >
                  <Text variant="body3">Cancel</Text>
                </S.EditButton>
              </Row>
            </>
          ) : (
            <Row style={{ flexWrap: 'wrap' }}>
              {additionalEmails.map((email: string) => (
                <Badge
                  variant="brand"
                  title={email}
                  key={email}
                  style={{ margin: '0px 4px 4px 0px' }}
                />
              ))}
            </Row>
          )}
        </>
      )}
    </>
  )
}
