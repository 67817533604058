import { theme } from '@traba/theme'
import {
  InputStatus,
  RegionIdToRegionMap,
  USER_ACCESS_LEVEL_OPTIONS,
  UserAccessLevel,
} from '@traba/types'
import { useCallback, useMemo } from 'react'
import {
  Col,
  InputInfoCalloutRow,
  LoadingSpinner,
  Row,
  SelectDropdown,
} from '../base-components'
import { GroupedLocationMultiSearchSelector } from '../base-components/selectors/GroupedLocationSearchSelector/GroupedLocationMultiSearchSelector'
import { RegionIdToPartialLocationMap } from '../base-components/selectors/GroupedLocationSearchSelector/types'

export type AssignAccessLevelAndLocationsRowProps = {
  userAccessLevel?: UserAccessLevel
  setUserAccessLevel: (newUserAccessLevel: UserAccessLevel) => void
  assignedLocationIds: Set<string>
  setAssignedLocationIds: (newIds: Set<string>) => void
  loading?: boolean
  regionMap: RegionIdToRegionMap
  regionToLocationsMap: RegionIdToPartialLocationMap
  onBlurLocationSelector?: () => void
  errors?: { assignedLocationIds?: string; userAccessLevel?: string }
  activeLocationIdsForUser?: Set<string>
  disableAccessLevelUpdate?: boolean
  locationSelectorLabel?: string
}

export function AssignAccessLevelAndLocationsRow({
  userAccessLevel,
  setUserAccessLevel,
  assignedLocationIds,
  setAssignedLocationIds,
  loading,
  regionMap,
  regionToLocationsMap,
  onBlurLocationSelector,
  errors,
  activeLocationIdsForUser,
  disableAccessLevelUpdate,
  locationSelectorLabel,
}: AssignAccessLevelAndLocationsRowProps) {
  const onUpdateUserAccessLevel = useCallback(
    (newUserAccessLevel: UserAccessLevel) => {
      setUserAccessLevel(newUserAccessLevel)

      if (
        newUserAccessLevel === UserAccessLevel.COMPANY_WIDE &&
        assignedLocationIds.size > 0
      ) {
        setAssignedLocationIds(new Set())
      }
    },
    [assignedLocationIds, setUserAccessLevel, setAssignedLocationIds],
  )

  const disabledLocationIds = useMemo(() => {
    return new Set(
      Array.from(assignedLocationIds).filter((id) =>
        activeLocationIdsForUser ? !activeLocationIdsForUser.has(id) : false,
      ),
    )
  }, [assignedLocationIds, activeLocationIdsForUser])

  if (loading) {
    return <LoadingSpinner />
  }

  const placeholderText = !userAccessLevel
    ? 'Select location access level first'
    : userAccessLevel === UserAccessLevel.COMPANY_WIDE
      ? 'All locations'
      : 'Select locations'

  return (
    <Col style={{ width: '100%' }} gap={theme.space.xxs}>
      <Row fullWidth alignStart justifyBetween gap={theme.space.sm}>
        <Col gap={theme.space.xxs} style={{ width: '50%' }}>
          <SelectDropdown
            label="Location Access"
            name="userAccessLevel"
            menuItems={USER_ACCESS_LEVEL_OPTIONS}
            value={userAccessLevel}
            handleSelect={onUpdateUserAccessLevel}
            inputStatus={
              errors?.userAccessLevel ? InputStatus.error : undefined
            }
            errorMessage={errors?.userAccessLevel}
            disabled={disableAccessLevelUpdate}
          />
        </Col>

        <Col style={{ width: '50%' }}>
          <GroupedLocationMultiSearchSelector
            label={locationSelectorLabel ?? 'Assign Location(s)'}
            disabled={userAccessLevel !== UserAccessLevel.LOCATIONS_ASSIGNED}
            placeholder={placeholderText}
            onChange={setAssignedLocationIds}
            regionMap={regionMap}
            regionToLocationsMap={regionToLocationsMap}
            selectedLocationIds={assignedLocationIds}
            onBlur={onBlurLocationSelector}
            selectStyle={{ height: '48px' }}
            disabledLocationIds={disabledLocationIds}
            disabledTooltipText={
              userAccessLevel === UserAccessLevel.COMPANY_WIDE
                ? 'Company wide members will always have access to all locations.'
                : undefined
            }
          />
        </Col>
      </Row>
      <InputInfoCalloutRow text="Company wide members will always have access to all locations. Location specific members will only be able to access their assigned locations." />
    </Col>
  )
}
