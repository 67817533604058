import { ClickAwayListener, Popper } from '@mui/material'
import { Z_INDEXES, theme } from '@traba/theme'

import { RegionAndLocationSelectWithSearch } from '../RegionAndLocationSelectWithSearch'

interface Props {
  companyId?: string
  isLocationFilterOpen: boolean
  setIsLocationFilterOpen: React.Dispatch<React.SetStateAction<boolean>>
  anchorEl: HTMLElement | null
  selectedLocations: Set<string>
  onUpdateSelectedLocations: (newSelectedLocations: Set<string>) => void
  allowedLocationIds?: Set<string>
}

export const NotificationSettingsLocationSelectPopper = ({
  companyId,
  isLocationFilterOpen,
  setIsLocationFilterOpen,
  anchorEl,
  onUpdateSelectedLocations,
  selectedLocations,
  allowedLocationIds,
}: Props) => {
  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => {
        if (isLocationFilterOpen) {
          setIsLocationFilterOpen(false)
        }
      }}
    >
      <Popper
        anchorEl={anchorEl}
        open={isLocationFilterOpen}
        style={{
          width: 400,
          height: 400,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 10,
          boxShadow: `0px 0px 10px 0px ${theme.colors.Violet30}`,
          backgroundColor: theme.colors.White,
          padding: theme.space.xs,
          paddingLeft: theme.space.xxs,
          zIndex: Z_INDEXES.MODAL_POPPER,
        }}
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
          },
        ]}
      >
        <RegionAndLocationSelectWithSearch
          selectedLocations={selectedLocations}
          onUpdateSelectedLocations={onUpdateSelectedLocations}
          companyId={companyId}
          allowedLocationIds={allowedLocationIds}
        />
      </Popper>
    </ClickAwayListener>
  )
}
