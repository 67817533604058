import { ShiftStatus } from '@traba/types'
import React from 'react'
import { shiftStatusBadge } from 'src/libs/constants'

import { Badge } from '..'

export interface CanceledBadgeProps {
  status?: ShiftStatus
}

export const CanceledBadge = (props: CanceledBadgeProps) => {
  if (props.status !== ShiftStatus.CANCELED) {
    return null
  }
  return (
    <Badge
      variant="danger"
      title={shiftStatusBadge[ShiftStatus.CANCELED]}
      style={{ marginLeft: 6 }}
    />
  )
}
