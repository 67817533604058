import { Skeleton } from '@mui/material'
import {
  getAddressString,
  getLocationNameOrTruncatedAddress,
  recurringSchedulesEnabled,
} from '@traba/utils'
import { useCallback, useEffect, useState } from 'react'
import React from 'react'
import { Button, Card, InlineBanner, Modal, Row } from 'src/components'
import { LocationSingleSearchSelector } from 'src/components/LocationSingleSearchSelector'
import { CreateOrEditLocationModal } from 'src/components/Modals/CreateOrEditLocationModal/CreateOrEditLocationModal'
import { useCompany } from 'src/hooks/useCompany'
import { getActiveRegion, useLocations } from 'src/hooks/useLocations'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import EditShiftsSaveButton from 'src/screens/EditShifts/EditShiftsSaveButton'
import { BookShiftsProps } from '../BookShiftsScreen'
import { CustomDropdownSearchSelectContainer } from '../BookShiftsScreen.styles'
import { BookShiftStepSection, Section } from '../components/BookShiftSection'
import {
  getErrorMessageForLocationSelection,
  validateShiftEdits,
  validateSiteStep,
  validateSiteStepShiftDataModel,
} from '../validation'
import { BookShiftsParkingLocationSection } from './sections/BookShiftsParkingLocationSection'
import { BookShiftsSiteLocationSection } from './sections/BookShiftsSiteLocationSection'

type BookShiftsSiteContentModal = 'LOCATION' | 'PARKING_LOCATION'

export function BookShiftsDetailsContentShiftDataModel(props: BookShiftsProps) {
  const { company } = useCompany()
  const {
    shiftRequest,
    setShiftRequest,
    shiftRequestMetadata,
    setShiftRequestMetadata,
    onContinue,
    onSaveChanges,
    selectedShifts,
    setShowSelectModal,
    shiftUpdates,
    isEdit,
    defaultShiftRequest,
  } = props

  const { activeLocations, isLoading: isLoadingLocations } = useLocations()
  const { hotSettings } = useHotSettings()

  const [modalType, setModalType] = useState<BookShiftsSiteContentModal | null>(
    null,
  )
  function closeModal() {
    setModalType(null)
  }

  const { locationId, parkingLocationId } = shiftRequest

  const postalCode = activeLocations.find((l) => l.locationId === locationId)
    ?.address?.postalCode

  const parkingPostalCode = activeLocations.find(
    (l) => l.locationId === parkingLocationId,
  )?.address?.postalCode

  useEffect(() => {
    async function checkActiveRegion() {
      if (postalCode) {
        const active = await getActiveRegion(postalCode)
        setShiftRequestMetadata({ activeRegion: !!active })
        !active &&
          window.analytics.track(
            `User Selected Location Outside of Active Regions`,
            { postalCode, isEdit },
          )
      }
    }

    checkActiveRegion()
  }, [postalCode, setShiftRequestMetadata, isEdit])

  useEffect(() => {
    async function checkActiveRegion() {
      if (parkingPostalCode) {
        const active = await getActiveRegion(parkingPostalCode)
        setShiftRequestMetadata({ activeParkingRegion: !!active })
        !active &&
          window.analytics.track(
            `User Selected Parking Location Outside of Active Regions`,
            { parkingPostalCode, isEdit },
          )
      }
    }

    checkActiveRegion()
  }, [parkingPostalCode, setShiftRequestMetadata, isEdit])

  const onCreateNewParkingLocation = useCallback(() => {
    setModalType('PARKING_LOCATION')
    window.analytics.track(`User Clicked Create New Parking Location`, {
      isEdit,
    })
  }, [])

  const onChangeParkingLocation = useCallback(
    (v?: string) => {
      if (!v) {
        return
      }

      setShiftRequest({ parkingLocationId: v })
      window.analytics.track(`User Selected Parking Location`, {
        parkingLocationId: v,
        isEdit,
      })
    },
    [isEdit, setShiftRequest],
  )

  const onCreateLocation = useCallback(() => {
    setModalType('LOCATION')
    window.analytics.track(`User Clicked Create New Location`, { isEdit })
  }, [])

  const onChangeLocation = useCallback(
    (v?: string) => {
      if (!v) {
        return
      }

      const location = activeLocations.find((l) => l.locationId === v)
      setShiftRequest({
        locationId: v,
        schedules: location
          ? [
              {
                ...shiftRequest.schedules[0],
                timeZone: location.timezone,
              },
            ]
          : shiftRequest.schedules,
      })
      setShiftRequestMetadata({
        locationName: location?.name || location?.shortLocation,
      })
      window.analytics.track(`User Selected Location`, {
        locationId: v,
        isEdit,
      })
    },
    [
      activeLocations,
      shiftRequest,
      isEdit,
      setShiftRequest,
      setShiftRequestMetadata,
    ],
  )

  const baseLocationOptions = {
    singular: 'location',
    plural: 'locations',
    options: activeLocations.map((l) => ({
      value: l.locationId,
      label: getLocationNameOrTruncatedAddress(l),
      secondaryLabel: getAddressString(l.address),
    })),
  }

  const sections: Array<Section> = [
    {
      ...baseLocationOptions,
      title: 'Work site location',
      selected: locationId,
      onChange: onChangeLocation,
      onCreate: onCreateLocation,
      optional: false,
      hide: false,
      contentExpanded: true,
      Content: BookShiftsSiteLocationSection,
      customDropdown: (
        <CustomDropdownSearchSelectContainer>
          <LocationSingleSearchSelector
            placeholder="Choose from existing locations"
            selectedLocationId={locationId}
            onChange={onChangeLocation}
            label=""
            errorMessage={
              props.isRebook && !locationId
                ? getErrorMessageForLocationSelection({
                    isParkingLocationSelection: false,
                    isRegionalAccessPhase2Enabled:
                      hotSettings?.enableRegionalAccessPhase2,
                  })
                : undefined
            }
            selectStyle={{ height: '48px' }}
            style={{ maxWidth: '100%' }}
            hideCompanyWideOption
          />
        </CustomDropdownSearchSelectContainer>
      ),
    },
    {
      ...baseLocationOptions,
      title: 'Parking location',
      selected: parkingLocationId || '',
      onChange: onChangeParkingLocation,
      onCreate: onCreateNewParkingLocation,
      hide: false,
      contentExpanded: shiftRequestMetadata.parkingLocationExpanded,
      checkboxLabel: 'Same as work site location',
      onClickCheckbox: () => {
        shiftRequestMetadata.parkingLocationExpanded &&
          setShiftRequest({ parkingLocationId: '' })
        setShiftRequestMetadata({
          parkingLocationExpanded:
            !shiftRequestMetadata.parkingLocationExpanded,
        })
      },
      Content: BookShiftsParkingLocationSection,
      customDropdown: (
        <CustomDropdownSearchSelectContainer>
          <LocationSingleSearchSelector
            placeholder="Choose from existing locations"
            selectedLocationId={parkingLocationId}
            onChange={onChangeParkingLocation}
            label=""
            errorMessage={
              props.isRebook && !parkingLocationId
                ? getErrorMessageForLocationSelection({
                    isParkingLocationSelection: true,
                    isRegionalAccessPhase2Enabled:
                      hotSettings?.enableRegionalAccessPhase2,
                  })
                : undefined
            }
            selectStyle={{ height: '48px' }}
            style={{ maxWidth: '100%' }}
            hideCompanyWideOption
          />
        </CustomDropdownSearchSelectContainer>
      ),
    },
  ]

  const validationError = recurringSchedulesEnabled({
    companyId: company?.companyId,
    hotSettings,
  })
    ? validateSiteStepShiftDataModel(shiftRequest, shiftRequestMetadata)
    : validateSiteStep(shiftRequest, shiftRequestMetadata)

  const editsError =
    selectedShifts &&
    defaultShiftRequest &&
    validateShiftEdits(defaultShiftRequest, selectedShifts, shiftUpdates)

  if (isLoadingLocations) {
    return (
      <>
        {Array.from(Array(4)).map((_, index) => (
          <React.Fragment
            key={`bookshiftsdetailscontentshiftdatamodel_${index}`}
          >
            <Skeleton animation="pulse" width="100%" height="60px" />
            <Skeleton animation="pulse" width="100%" height="120px" />
          </React.Fragment>
        ))}
      </>
    )
  }

  return (
    <Card>
      {sections
        .filter((s) => !s.hide)
        .map((s, i, arr) => (
          <BookShiftStepSection
            key={i}
            section={s}
            bookShiftProps={props}
            noDivider={i === arr.length - 1}
          />
        ))}

      {isEdit && editsError && (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity={'error'}
          text={editsError.message}
        />
      )}

      <Row style={{ justifyContent: 'flex-end', marginTop: theme.space.lg }}>
        {isEdit ? (
          <EditShiftsSaveButton
            onSaveChanges={onSaveChanges}
            validationError={editsError || validationError}
            selectedShifts={selectedShifts}
            setShowSelectModal={setShowSelectModal}
          />
        ) : (
          <Button onClick={onContinue} disabled={!!validationError}>
            Continue
          </Button>
        )}
      </Row>
      <Modal isOpen={modalType !== null} handleClose={closeModal}>
        {modalType === 'LOCATION' ? (
          <CreateOrEditLocationModal
            setShowModal={closeModal}
            onCreate={(l) =>
              setShiftRequest({
                locationId: l.locationId,
                schedules: [
                  {
                    ...shiftRequest.schedules[0],
                    timeZone: l.timezone,
                  },
                ],
              })
            }
          />
        ) : modalType === 'PARKING_LOCATION' ? (
          <CreateOrEditLocationModal
            setShowModal={closeModal}
            onCreate={(l) =>
              setShiftRequest({ parkingLocationId: l.locationId })
            }
          />
        ) : null}
      </Modal>
    </Card>
  )
}
