import { theme } from '@traba/theme'
import {
  InputStatus,
  LocationResponse,
  ReplacementSupervisorForLocation,
} from '@traba/types'
import { User, UserRole } from '@traba/types'
import { startCase } from 'lodash'
import { useCallback, useState } from 'react'
import { Button, ButtonVariant } from '../../../base-components/Button/Button'
import { Col } from '../../../base-components/Col'
import {
  InputErrorIcon,
  InputErrorMessage,
} from '../../../base-components/Input/Input.styles'
import { ModalPaddingContainer } from '../../../base-components/Modal/Modal.styles'
import Row from '../../../base-components/Row'
import { SelectDropdown } from '../../../base-components/Select/Select'
import { SvgIcon } from '../../../base-components/SvgIcon'
import { Text } from '../../../base-components/Text'
import { MemberCard } from '../../MemberCard'
import { ReplaceSupervisorForLocationsSection } from '../../ReplaceSupervisorForLocationsSection'

export interface ArchiveMemberParams {
  replacementUserId?: string
  replacementSupervisorsForLocations?: ReplacementSupervisorForLocation[]
}

export interface ArchiveUserModalContentProps {
  member: Pick<User, 'email' | 'firstName' | 'lastName'> & {
    role?: UserRole
    uid?: string
  }
  replacementMembers: (Pick<User, 'firstName' | 'lastName'> & {
    uid?: string
  })[]
  handleClose: () => void
  onArchiveMember: ({
    replacementUserId,
    replacementSupervisorsForLocations,
  }: ArchiveMemberParams) => void
  loading?: boolean
  activeShiftsError: boolean
  locationsForFutureShifts?: LocationResponse[]
  memberIdToMemberMap: Map<string, Partial<User>>
  showLocationSpecifiedSupervisorDropdown?: boolean
}

const REPLACEMENT_SUPERVISOR_SUBTEXT =
  'Please select a replacement supervisor for current and upcoming shifts where this user is assigned as the supervisor.'

const ARCHIVE_MEMBER_DESCRIPTION =
  'Archiving a member will deactivate their Traba account and remove them from the supervisor dropdown list when creating a shift. However, their information will continue to show up for any previous shifts they supervised.'
const ARCHIVE_CONTACT_DESCRIPTION =
  'Archiving a contact will remove them from the supervisor dropdown list when creating a shift. However, their information will continue to show up for any previous shifts they supervised.'

export function ArchiveUserModalContent(props: ArchiveUserModalContentProps) {
  const {
    member,
    handleClose,
    onArchiveMember,
    loading,
    replacementMembers,
    activeShiftsError,
    locationsForFutureShifts = [],
    memberIdToMemberMap,
    showLocationSpecifiedSupervisorDropdown = false,
  } = props
  const { firstName, lastName, email, role, uid } = member

  const [replacementUserId, setReplacementUserId] = useState<string>('')

  const [replacementSupervisorMap, setReplacementSupervisorMap] = useState<
    Record<string, string>
  >({})

  const handleArchiveMember = useCallback(() => {
    const replacementSupervisorsForLocations = Object.entries(
      replacementSupervisorMap,
    ).map(([locationId, newSupervisorId]) => ({ locationId, newSupervisorId }))

    const archiveMemberParams = showLocationSpecifiedSupervisorDropdown
      ? { replacementSupervisorsForLocations }
      : { replacementUserId }

    onArchiveMember(archiveMemberParams)
  }, [onArchiveMember, replacementUserId, replacementSupervisorMap])

  const userType = role ? 'member' : 'contact'

  const missingLocationReplacements = locationsForFutureShifts.filter(
    (loc) => !replacementSupervisorMap[loc.locationId],
  )

  const showNoReplacementForActiveShiftError =
    activeShiftsError &&
    (showLocationSpecifiedSupervisorDropdown
      ? missingLocationReplacements.length > 0
      : replacementUserId === '')

  const availableReplacementUsersOptions = replacementMembers
    .filter((m) => !!m.uid && m.uid !== uid)
    .map((m) => ({
      value: m.uid as string,
      label: `${m.firstName} ${m.lastName}`,
    }))

  // this user has no future shifts in any locations, so hide the replacement supervisor section
  const hideReplacementSupervisorSection =
    showLocationSpecifiedSupervisorDropdown &&
    locationsForFutureShifts.length === 0 &&
    !showNoReplacementForActiveShiftError

  return (
    <ModalPaddingContainer>
      <Row justifyBetween pb={theme.space.xs}>
        <Text variant="h2">Archive {startCase(userType)}</Text>
        <SvgIcon
          size={24}
          color={theme.colors.Grey50}
          name="cancel"
          onClick={handleClose}
        />
      </Row>
      <Text variant="body2">
        {role ? ARCHIVE_MEMBER_DESCRIPTION : ARCHIVE_CONTACT_DESCRIPTION}
      </Text>

      <Row justifyBetween pt={theme.space.xs} pb={theme.space.xxs}>
        <Text variant="h5"> {startCase(userType)} to Archive</Text>
      </Row>
      <MemberCard
        firstName={firstName}
        lastName={lastName}
        email={email}
        role={role}
      />

      {!hideReplacementSupervisorSection && (
        <>
          <Text mt={theme.space.xs} mb={theme.space.xs} variant="h5">
            Replacement Supervisor
          </Text>
          <Text variant="body2">
            {showLocationSpecifiedSupervisorDropdown
              ? REPLACEMENT_SUPERVISOR_SUBTEXT
              : `You can select a replacement supervisor if you believe this ${userType} is a supervisor on a current or upcoming shifts.`}
          </Text>
          <Col pt={theme.space.xs}>
            {showLocationSpecifiedSupervisorDropdown ? (
              <ReplaceSupervisorForLocationsSection
                member={member}
                locations={locationsForFutureShifts}
                replacementMembers={replacementMembers}
                replacementSupervisorMap={replacementSupervisorMap}
                setReplacementSupervisorMap={setReplacementSupervisorMap}
                memberIdToMemberMap={memberIdToMemberMap}
              />
            ) : (
              <SelectDropdown
                placeholder="Select a replacement supervisor"
                value={replacementUserId}
                handleSelect={setReplacementUserId}
                menuItems={[
                  { label: `--`, value: '' },
                  ...availableReplacementUsersOptions,
                ]}
                inputStatus={
                  showNoReplacementForActiveShiftError
                    ? InputStatus.error
                    : undefined
                }
                errorMessage={`This ${userType} is a supervisor on current or upcoming shifts, please select a replacement.`}
                style={{ width: '100%' }}
              />
            )}
          </Col>

          {showNoReplacementForActiveShiftError &&
            showLocationSpecifiedSupervisorDropdown && (
              <InputErrorMessage>
                <InputErrorIcon />
                {`This ${userType} is a supervisor on current or upcoming shifts, please select a replacement.`}
              </InputErrorMessage>
            )}
        </>
      )}

      <Row justifyBetween pt={theme.space.med}>
        <Button
          type="button"
          variant={ButtonVariant.OUTLINED}
          onClick={handleClose}
        >
          Back
        </Button>
        <Button
          variant={ButtonVariant.CANCEL}
          onClick={handleArchiveMember}
          loading={loading}
          disabled={showNoReplacementForActiveShiftError}
        >
          Archive
        </Button>
      </Row>
    </ModalPaddingContainer>
  )
}
