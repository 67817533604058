import { WorkerShift, CancellationSource } from '@traba/types'
import { Badge } from 'src/components/base'
import {
  statusToBadgeTitle,
  statusToBadgeVariant,
} from 'src/components/WorkerOnShiftBadge/WorkerOnShiftBadge'
import { JobStatus } from 'src/types'

export const TimesheetBadge = ({
  workerShift,
}: {
  workerShift: WorkerShift
}) => {
  if (
    workerShift.jobStatus === JobStatus.Canceled &&
    workerShift.cancellationSource === CancellationSource.Business
  ) {
    return (
      <Badge
        variant="danger"
        title="You removed"
        style={{ whiteSpace: 'nowrap' }}
      />
    )
  }
  if (workerShift.jobStatus === JobStatus.Abandoned) {
    return (
      <Badge
        variant={statusToBadgeVariant.ABANDONED}
        title={statusToBadgeTitle.ABANDONED}
        style={{ whiteSpace: 'nowrap' }}
      />
    )
  }
  return null
}
