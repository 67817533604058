import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { LoadingSpinner } from '@traba/react-components'
import {
  AdjustmentSource,
  JobStatus,
  WorkerShiftWithWorkerDetails,
} from '@traba/types'
import { addHours, isAfter, isEqual, subHours } from 'date-fns'
import dayjs from 'dayjs'
import { useState } from 'react'
import { UseMutateFunction } from 'react-query'
import { useCompany } from 'src/hooks/useCompany'
import {
  ClockInOutResult,
  ClockInWorkers,
  ClockOutWorkers,
} from 'src/hooks/workerShiftHooks'
import DatePicker from '../base/AriaDatePicker/DatePicker'
import TimeField from '../base/AriaDatePicker/TimeField'
import { ConfirmationDialog } from '../Modals/ConfirmationDialog/ConfirmationDialog'
import { getClockInOutError } from '../WorkerManagementModal/WorkerManagementModal'
import { WorkerAction } from '../WorkersManagementMenu/WorkersManagementMenu'
import { AbandonedTime } from './AbandonedTime'
import { EditableTime } from './EditableTime'
import { EmptyState } from './EmptyState'

export type ClockInOutButtonProps = {
  workerShift: WorkerShiftWithWorkerDetails
  workerAction: WorkerAction.ClockIn | WorkerAction.ClockOut
  timezone: string
  clockInWorkers: UseMutateFunction<ClockInOutResult, Error, ClockInWorkers>
  editClockInWorkers: UseMutateFunction<ClockInOutResult, Error, ClockInWorkers>
  clockOutWorkers: UseMutateFunction<ClockInOutResult, Error, ClockOutWorkers>
  isFetching?: boolean
  refetchWorkerShifts: () => void
  isFromTimesheetDetails: boolean | undefined
}

export const ClockInClockOutButton = (props: ClockInOutButtonProps) => {
  const {
    workerShift,
    workerAction,
    timezone,
    clockInWorkers,
    editClockInWorkers,
    clockOutWorkers,
    refetchWorkerShifts,
    isFromTimesheetDetails,
  } = props
  const [actionInProgress, setActionInProgress] = useState(false)
  const [confirmationText, setConfirmationText] = useState('')
  const [onConfirm, setOnConfirm] = useState<() => void | undefined>()
  let toRender: JSX.Element = <></>
  const showPendingClockOut =
    workerAction === WorkerAction.ClockOut &&
    !!workerShift.clockOutTimeBeforeWorkerEdit &&
    !workerShift.clockOutTime
  const timeValue =
    workerAction === WorkerAction.ClockIn
      ? workerShift.clockInTime
      : showPendingClockOut
        ? workerShift.clockOutTimeBeforeWorkerEdit
        : workerShift.clockOutTime
  const shiftStartTime = workerShift.shiftInfo.startTime
  const shiftEndTime = workerShift.shiftInfo.endTime
  const cta = workerAction === WorkerAction.ClockIn ? 'Clock in' : 'Clock out'
  const { showError } = useAlert()
  const { company } = useCompany()
  if (
    [JobStatus.NoShow, JobStatus.Rejected, JobStatus.Canceled].includes(
      workerShift.jobStatus,
    )
  ) {
    return null
  }
  const confirmAction = (newTime: Date) => {
    const validation = getClockInOutError(
      shiftStartTime,
      shiftEndTime,
      newTime,
      workerAction,
      company?.hoursToClockInEarlyBy ?? undefined,
    )
    if (validation) {
      if (validation.type === 'error') {
        const errorTitle = `Error clocking ${
          workerAction === WorkerAction.ClockIn ? 'in' : 'out'
        } worker`
        showError(validation.message, errorTitle, 5000)
      } else if (validation.type === 'warning') {
        setConfirmationText(validation.message)
        setOnConfirm(() => () => {
          onAction(newTime)
          setConfirmationText('')
          setOnConfirm(undefined)
        })
      }
    } else {
      onAction(newTime)
    }
  }

  const adjustClockInOut = async (newClockIn?: Date, newClockOut?: Date) => {
    try {
      const updateResponse: {
        data: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          failedWorkerIdsAndReasons?: Record<string, any>
          succeededWorkerIds: string[]
        }
      } = await trabaApi.post(
        `/my-company/worker-shifts/${workerShift.shiftId}/adjustments`,
        {
          workerShiftBizAdjustments: [
            {
              workerId: workerShift.workerId,
              clockOutTime: newClockOut,
              clockInTime: newClockIn,
              adjustmentReason: newClockIn
                ? 'Wrong Clock In'
                : 'Wrong Clock Out',
              source: AdjustmentSource.BUSINESS,
            },
          ],
        },
      )
      if (updateResponse.data?.failedWorkerIdsAndReasons?.length) {
        throw new Error('Something went wrong. Please try again.')
      }
    } catch (e) {
      console.error(e)
      showError(
        'Please try again.',
        `Error adjusting clock ${newClockIn ? 'in' : 'out'}.`,
      )
    }
    refetchWorkerShifts()
  }

  const onAction = async (newTime: Date) => {
    setActionInProgress(true)
    if (workerAction === WorkerAction.ClockIn) {
      if (workerShift.jobStatus !== JobStatus.Complete) {
        const action = workerShift.clockInTime
          ? editClockInWorkers
          : clockInWorkers
        await action({
          workerIds: [workerShift.workerId],
          clockInTime: newTime,
          shiftId: workerShift.shiftId,
        })

        window.analytics.track(`Business Clocked In Workers`, {
          inline: true,
          workerShift: [workerShift],
          clockInTime: newTime,
        })
      } else {
        await adjustClockInOut(newTime, undefined)
        window.analytics.track(`Business Adjusted Clock In Times`, {
          inline: true,
          workerShift: [workerShift],
          clockInTime: newTime,
        })
      }
    } else {
      if (workerShift.jobStatus !== JobStatus.Complete) {
        await clockOutWorkers({
          workerIds: [workerShift.workerId],
          clockOutTime: newTime,
          shiftId: workerShift.shiftId,
        })
        window.analytics.track(`Business Clocked Out Workers`, {
          inline: true,
          workerShift: [workerShift],
          clockOutTime: newTime,
        })
      } else {
        await adjustClockInOut(undefined, newTime)
        window.analytics.track(`Business Adjusted Clock Out Times`, {
          inline: true,
          workerShift: [workerShift],
          clockInTime: newTime,
        })
      }
    }
    setActionInProgress(false)
  }

  if (actionInProgress) {
    return <LoadingSpinner style={{ height: 25, width: 25 }} />
  }

  if (timeValue) {
    // allow clock in edits only if the worker hasn't clocked out yet
    const canEdit =
      (workerAction === WorkerAction.ClockIn ||
        workerAction === WorkerAction.ClockOut) &&
      !isFromTimesheetDetails

    toRender =
      workerShift.jobStatus === JobStatus.Abandoned ? (
        <AbandonedTime
          canEdit={canEdit}
          value={timeValue}
          timezone={timezone}
        />
      ) : (
        <EditableTime
          canEdit={canEdit}
          onChange={confirmAction}
          value={timeValue}
          timezone={timezone}
          startDate={shiftStartTime}
          endDate={shiftEndTime}
          workerShift={workerShift}
          workerAction={workerAction}
          showPendingClockOut={showPendingClockOut}
        />
      )
  } else {
    const isShiftEnded = shiftEndTime && isAfter(new Date(), shiftEndTime)
    if (isShiftEnded && workerAction === WorkerAction.ClockIn) {
      return null
    }
    const defaultDate =
      workerAction === WorkerAction.ClockIn ? shiftStartTime : shiftEndTime
    if (!workerShift.clockInTime && workerAction === WorkerAction.ClockOut) {
      return null
    }
    toRender = (
      <EmptyState
        key={workerShift.workerId}
        cta={cta}
        onChooseTime={confirmAction}
        timezone={timezone}
        defaultTime={defaultDate}
        startDate={shiftStartTime}
        endDate={shiftEndTime}
        workerShift={workerShift}
        workerAction={workerAction}
      />
    )
  }
  return (
    <>
      {toRender}
      {onConfirm && confirmationText && (
        <ConfirmationDialog
          open={!!confirmationText}
          confirmationText={confirmationText}
          onClose={() => {
            setConfirmationText('')
            setOnConfirm(undefined)
          }}
          title={`${cta} worker?`}
          onConfirm={onConfirm}
          onConfirmCTA="Yes"
        />
      )}
    </>
  )
}

export const EditableTimeInput = (props: {
  startDate: Date
  endDate: Date
  value: Date | null
  timezone: string
  onChange: (newDate: Date | null) => void
}) => {
  const bufferedStartDate = subHours(props.startDate, 3)
  const bufferedEndDate = addHours(props.endDate, 3)

  const isOvernight = !dayjs(bufferedStartDate).isSame(
    dayjs(bufferedEndDate),
    'day',
  )

  if (!isOvernight) {
    return (
      <TimeField
        time={props.value}
        timezone={props.timezone}
        setTime={(newDate) => {
          newDate &&
            !isEqual(newDate, props.value || 0) &&
            props.onChange(newDate)
        }}
        minTime={bufferedStartDate}
        maxTime={bufferedEndDate}
      />
    )
  }

  return (
    <DatePicker
      showTimeFieldInPopover={true}
      setDate={(newDate) => {
        newDate &&
          !isEqual(newDate, props.value || 0) &&
          props.onChange(newDate)
      }}
      isClearable={false}
      inlineLabel={true}
      aria-label="Time"
      date={props.value}
      timezone={props.timezone}
      minDate={bufferedStartDate}
      maxDate={bufferedEndDate}
    />
  )
}
