import { theme } from '@traba/theme'
import { UserRolePermission } from '@traba/types'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { MainLayout } from 'src/components'
import { Text } from 'src/components/base'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'
import useMobile from 'src/hooks/useMobile'
import { useUserPermissions } from 'src/hooks/useUser'
import { TimeSheetApprovalLayout } from './TimeSheetApprovalLayout'
import { TimesheetApprovalTab } from './types'

export default function TimeSheetApprovalScreen() {
  const { dispatch: appContextDispatch } = useAppContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const status =
    searchParams.get('status')?.toUpperCase() ?? TimesheetApprovalTab.PENDING
  const [tabIndex, setTabIndex] = useState(
    status === TimesheetApprovalTab.APPROVED ? 1 : 0,
  )
  const { isReactNativeApp } = useMobile()
  useEffect(() => {
    appContextDispatch({
      type: 'SET_REGIONAL_FILTER_STATUS',
      value: RegionalFilterStatus.ENABLED,
    })
    return () => {
      appContextDispatch({
        type: 'SET_REGIONAL_FILTER_STATUS',
        value: RegionalFilterStatus.HIDE,
      })
    }
  }, [appContextDispatch])
  const userCanManageTimesheets = useUserPermissions([
    UserRolePermission.ManageTimesheets,
  ])
  useEffect(() => {
    window.analytics.track('Timesheets Loaded Approvals Screen')
  }, [])

  /**
   * Handles Tab selection
   * @param e Change event
   * @param val The new tab index
   */
  function handleChangeTabs(
    ev: React.SyntheticEvent<Element, Event>,
    val: number,
  ) {
    setTabIndex(val)
    const status =
      val === 0 ? TimesheetApprovalTab.PENDING : TimesheetApprovalTab.APPROVED
    setSearchParams({ status: status.toLowerCase() })
    window.analytics.track(`Timesheets Quick Approval Tab Changed`, {
      tab: status,
    })
  }

  return (
    <MainLayout
      title="Timesheet Approval"
      contentStyle={{
        ...(isReactNativeApp ? { paddingLeft: 0, paddingRight: 0 } : {}),
        maxWidth: '100%',
      }}
    >
      <Text
        variant="h2"
        style={{
          ...(isReactNativeApp
            ? { paddingLeft: theme.space.sm, paddingRight: theme.space.sm }
            : {}),
        }}
      >
        {isReactNativeApp ? 'Timesheets' : 'Timesheet Approval'}
      </Text>
      <TimeSheetApprovalLayout
        tabIndex={tabIndex}
        handleChangeTabs={handleChangeTabs}
        userCanManageTimesheets={userCanManageTimesheets}
      />
    </MainLayout>
  )
}
