// Determining the visible month in the calendar based on the range start and end big calendar onRangeChange callback returns
export const determineVisibleMonth = (rangeStart: Date, rangeEnd: Date) => {
  const rangeStartMonth = rangeStart.getMonth()
  const rangeEndMonth = rangeEnd.getMonth()
  if (rangeStartMonth === rangeEndMonth) {
    return rangeStart
  }

  if (rangeStartMonth === rangeEndMonth - 1) {
    return rangeEnd.getDate() > 15 ? rangeEnd : rangeStart
  }

  const monthInBetween = new Date(rangeStart)
  /*
  why setDate(1)? 
  edge case but eg. rangeStart = March 31st, 2024. When you call setMonth(rangeStartMonth + 1), JavaScript interprets it as setting the month to April. 
  However, since April only has 30 days, JavaScript adjusts the date to May 1st to make it a valid date. 
  This behavior is consistent with JavaScript's handling of date manipulation to maintain consistency and avoid invalid dates.
  */
  monthInBetween.setDate(1)
  monthInBetween.setMonth(rangeStartMonth + 1)

  return monthInBetween
}

// Get the range of dates that displayed on the calender view
export const getCalendarMonthDisplayRange = (currDate: Date) => {
  const year = currDate.getFullYear()
  const month = currDate.getMonth() + 1
  const lastDay = new Date(year, month, 0).getDate()
  const firstDayInMonth = new Date(year, month - 1, 1)
  const lastDayInMonth = new Date(year, month - 1, lastDay)

  const firstDayInView = new Date(firstDayInMonth)
  firstDayInView.setDate(firstDayInMonth.getDate() - firstDayInMonth.getDay())

  /**
   * If the last day of the month is Sunday (getDay() returns 0), then 6 - 0 = 6, indicating that there are 6 days remaining in the week (Monday to Saturday).
 .   If the last day of the month is Monday (getDay() returns 1), then 6 - 1 = 5, indicating that there are 5 days remaining in the week (Tuesday to Saturday).
   */
  const lastDayInView = new Date(lastDayInMonth)
  lastDayInView.setDate(
    lastDayInMonth.getDate() + (6 - lastDayInMonth.getDay()),
  )
  // To include all shifts till end of the day
  lastDayInView.setHours(23, 59, 59, 999)

  return [firstDayInView, lastDayInView]
}
