import { CancellationSettings, Shift, ShiftStatus } from '@traba/types'
import React from 'react'
import { Money } from 'src/types'
import { getIsShiftLateCanceled } from 'src/utils/cancellationUtils'
import { getMoneyString } from 'src/utils/moneyUtils'

import { TextVariant } from '../base/Text'
import { ShiftTileProps } from './ShiftTile.hooks'
import * as S from './ShiftTile.styles'

type EstimatedCostProps = ShiftTileProps &
  Shift & {
    minEstimatedCost: Money
    maxEstimatedCost: Money
    ml?: string
    className?: string
    style?: React.CSSProperties
    variant?: TextVariant
    totalCharge?: Money
  }

export default function EstimatedCost(props: EstimatedCostProps) {
  const {
    minSlotsRequested,
    slotsRequested,
    isPastShift,
    totalCharge,
    status,
    minEstimatedCost,
    maxEstimatedCost,
    cancellationSettings,
  } = props as EstimatedCostProps & {
    cancellationSettings?: CancellationSettings
  } // shared-types is broken right now so can't update cancellationSettings on the shift, TO_DO @isabeltraba return to pull from the shift type once fixed

  const showTotalCharge = !!(isPastShift || status === ShiftStatus.CANCELED)
  const shiftIsLateCanceled = getIsShiftLateCanceled(props)
  const shiftIsWaived = !!(
    shiftIsLateCanceled && !!cancellationSettings?.shouldWaiveCancellationFees
  )
  if (showTotalCharge) {
    return (
      <S.ShiftTileAccentText
        variant={props.variant || 'h5'}
        ml={props.ml}
        className={props.className}
        style={props.style}
      >
        {shiftIsWaived ? getMoneyString(0) : getMoneyString(totalCharge)}
      </S.ShiftTileAccentText>
    )
  }

  return (
    <S.ShiftTileAccentText
      variant={props.variant || 'h5'}
      ml={props.ml}
      className={props.className}
      style={props.style}
    >
      {minSlotsRequested !== slotsRequested
        ? `Est. ${getMoneyString(minEstimatedCost)} - ${getMoneyString(
            maxEstimatedCost,
          )}`
        : `Est. ${getMoneyString(maxEstimatedCost)}`}
    </S.ShiftTileAccentText>
  )
}
