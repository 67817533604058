import { CardTile } from '@traba/react-components'
import React from 'react'
import { Col, Icon, Row, Text } from 'src/components/base'
import { UserData } from 'src/types'
import { formatPhoneNumber } from 'src/utils/phoneNumberUtils'
import { getInitials } from 'src/utils/stringUtils'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook
export type PointOfContactProps = {
  user?: UserData
  slim?: boolean
}

export const PointOfContact = (props: PointOfContactProps) => {
  const { user, slim } = props

  const fullName = `${user?.firstName} ${user?.lastName}`
  const initials =
    user?.firstName && user?.lastName
      ? getInitials(user.firstName, user.lastName)
      : ''
  const phoneNum = user?.phoneNumber
    ? formatPhoneNumber(user.phoneNumber, true)
    : ''

  if (slim) {
    return (
      <Col className="xl-8" mb={10}>
        <Text variant="body1">{fullName}</Text>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Text variant="body1">{phoneNum}</Text>
        </div>
        {user?.email && (
          <Text variant="body1" style={{ overflowWrap: 'break-word' }}>
            {user.email}
          </Text>
        )}
      </Col>
    )
  }

  return (
    <Col className="xl-8">
      <Row>
        <CardTile
          className="xs-hide sm-show"
          style={{ marginRight: '24px', flexShrink: 0 }}
          size="56px"
        >
          <Text variant="h5">{initials}</Text>
        </CardTile>

        <Col width="100%">
          <Text variant="h5">{fullName}</Text>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Icon
              name="phone"
              style={{
                height: 14,
                width: 14,
                top: '5px',
                marginRight: '10px',
              }}
            />
            <Text variant="body2">{phoneNum}</Text>
          </div>
          {user?.email && <Text variant="body2">{user.email}</Text>}
        </Col>
      </Row>
    </Col>
  )
}
