import { Row, Text } from 'src/components/base'
import { theme } from 'src/libs/theme'

interface Props {
  itemName: string
  itemValue: string
}

export const PersonalProfileListItem = ({ itemName, itemValue }: Props) => {
  return (
    <Row
      justifyBetween
      style={{
        paddingBottom: theme.space.xs,
        paddingTop: theme.space.xs,
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.Grey20,
        borderStyle: 'solid',
      }}
    >
      <Text variant="h6">{itemName}</Text>
      <Text>{itemValue}</Text>
    </Row>
  )
}
