import { Z_INDEXES } from '@traba/theme'
import styled, { css } from 'styled-components'

export const MainWrapper = styled.div<{
  isReactNativeApp: boolean
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${({ isReactNativeApp }) => (isReactNativeApp ? '0px' : '80px')};
  @media only screen and (min-width: ${({ theme }) => theme.media.minMedium}) {
    padding-top: 0px;
  }
`

export const Content = styled.div<{
  isCalendar: boolean
}>`
  padding: 25px;
  width: 100%;
  max-width: ${({ theme, isCalendar }) =>
    isCalendar ? undefined : theme.media.minExtraLarge};
  @media only screen and (min-width: ${({ theme }) => theme.media.minSmall}) {
    padding: 56px;
  }
`
export const LeftCol = styled.div`
  @media only screen and (min-width: ${({ theme }) => theme.media.minLarge}) {
    width: 300px;
  }
`
export const RightCol = styled.div`
  overflow: auto;
`

export const DrawerCol = styled.div<{
  isOpen?: boolean
}>`
  ${({ isOpen }) => {
    if (isOpen) {
      return css`
        position: absolute;
        width: 100%;
        top: 80px;
        transform: translate3d(0, 0, 0);
        transition:
          transform 0.35s cubic-bezier(0.24, 1, 0.32, 1),
          -webkit-transform 0.35s cubic-bezier(0.24, 1, 0.32, 1);
        z-index: ${Z_INDEXES.DRAWER};
      `
    } else {
      return css`
        position: absolute;
        width: 100%;
        top: 80px;
        transform: translate3d(-100%, 0, 0);
        transition:
          transform 0.3s ease,
          background 0.3s ease,
          box-shadow 0.3s ease,
          -webkit-transform 0.3s ease,
          -webkit-box-shadow 0.3s ease;
        z-index: ${Z_INDEXES.DRAWER};
      `
    }
  }}
`
