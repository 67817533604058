import { SvgIconName } from 'src/assets/svg/icons'
import { Badge } from 'src/components'
import * as S from '../base/Badge/Badge.styles'

export enum ShiftType {
  ONE_TIME = 'ONE-TIME',
  RECURRING = 'RECURRING',
}

interface ShiftTypeBadgeProps {
  shiftType: ShiftType
  variant?: S.BadgeVariant
  iconName?: SvgIconName
}

const ShiftTypeBadge: React.FC<ShiftTypeBadgeProps> = ({
  shiftType,
  variant = 'info',
  iconName = undefined,
}) => {
  return (
    <Badge
      title={shiftType as string}
      variant={variant}
      iconName={iconName}
      style={{ whiteSpace: 'nowrap', width: 'fit-content' }}
    />
  )
}

export default ShiftTypeBadge
