import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { RecordStatus } from '@traba/types'
import { UserData } from 'src/types'

export const SHIFTS_SUPERVISORS_FOR_LOCATION_QUERY_KEY =
  'shiftSupervisorsForLocation'

interface GetShiftsSupervisorsParams {
  locationId: string | undefined
  recordStatus?: RecordStatus
  minShiftStartTime?: string
}

const getShiftsSupervisorsForLocation = async (
  queryParams: GetShiftsSupervisorsParams,
): Promise<UserData[] | undefined> => {
  if (!queryParams.locationId) {
    return undefined
  }

  try {
    const response = await trabaApi.get(
      `/my-company/users/shifts-supervisors`,
      {
        params: queryParams,
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error, {
      tags: { action: `Get Supervisors For Location` },
      extra: { locationId: queryParams.locationId },
    })
    throw error
  }
}

export const useShiftsSupervisorsForLocation = ({
  locationId,
  recordStatus,
  minShiftStartTime,
}: GetShiftsSupervisorsParams) => {
  const {
    isLoading,
    isError,
    data: supervisorsForFutureShiftAtLocation = [],
    error,
    isFetched,
    refetch,
  } = useQuery<UserData[] | undefined, Error>({
    queryKey: [
      SHIFTS_SUPERVISORS_FOR_LOCATION_QUERY_KEY,
      locationId,
      minShiftStartTime,
      recordStatus,
    ],
    queryFn: () =>
      getShiftsSupervisorsForLocation({
        locationId,
        recordStatus,
        minShiftStartTime,
      }),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!locationId,
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    supervisorsForFutureShiftAtLocation,
    refetch,
  }
}
