import { DEFAULT_PAGE_SIZE } from '@traba/consts'
import { Region, ShiftRequestParent } from '@traba/types'
import React, { Dispatch, useMemo } from 'react'
import { EmptyPlaceholderTile, Row, Text } from 'src/components/base'
import useMobile from 'src/hooks/useMobile'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { theme } from 'src/libs/theme'
import { UserData } from 'src/types'

import {
  CalendarFilters,
  FiltersAction,
} from '../../hooks/useShiftFilters.hooks'
import { PaginationDeprecated } from '../base/PaginationDeprecated'
import { EmptyDataLocationSubtitle } from '../base/RegionalFilterSelect/EmptyDataLocationSubtitle'
import { TextVariant } from '../base/Text'
import { ShiftFilters } from '../ShiftFilters/ShiftFilters'
import { ShiftStats } from '../ShiftStats/ShiftStats'
import { DesktopShiftsTileWithWorkersList } from '../ShiftTile/DesktopShiftsTileWithWorkersList'
import { MobileShiftsTileWithWorkersList } from '../ShiftTile/MobileShiftsTileWithWorkersList'
import Skeletons from '../Skeletons/Skeletons'
import * as S from './ShiftCalendar.styles'
import { ShowWorkersToggle } from './ShowWorkersToggle'

export type ShiftCalendarProps = {
  shifts: ShiftAndAddress[]
  isShiftsFetched: boolean
  regions: Region[]
  isRegionsFetched: boolean
  members: UserData[]
  isMembersFetched: boolean
  filters: CalendarFilters
  dispatch: Dispatch<FiltersAction>
  page: number
  onPageLeft: () => void
  onPageRight: () => void
  showDesktopCard: boolean
  hideWorkers?: boolean
  hideFilters?: boolean
  sectionTitleTextVariant?: TextVariant
  isShowWorkersToggled: boolean
  setIsShowWorkersToggled: React.Dispatch<React.SetStateAction<boolean>>
}

type Section = {
  title: string
  date: Date
  shiftRequestParentToShiftsMap: Map<string, ShiftsToDisplay>
  singleShifts: ShiftAndAddress[]
}

type ShiftsToDisplay = {
  shiftCardTitle: string
  shiftRequestParent?: ShiftRequestParent
  shifts: ShiftAndAddress[]
}

type OrganizedSection = {
  title: string
  date: Date
  data: ShiftsToDisplay[]
}

export const ShiftCalendarWithSchedules: React.FC<ShiftCalendarProps> = (
  props,
) => {
  const {
    shifts,
    isShiftsFetched,
    isRegionsFetched,
    isMembersFetched,
    page,
    onPageLeft,
    onPageRight,
    showDesktopCard,
    hideFilters,
    hideWorkers,
    sectionTitleTextVariant,
    isShowWorkersToggled,
    setIsShowWorkersToggled,
  } = props

  const { isMobileViewOrReactNative, isReactNativeApp } = useMobile()

  // need to use the total shifts array length to determine if we need to show pagination
  const CalendarPagination = () => (
    <PaginationDeprecated
      page={page}
      pageSize={DEFAULT_PAGE_SIZE}
      onPageLeft={onPageLeft}
      onPageRight={onPageRight}
      dataSize={shifts?.length || 0}
    />
  )

  function getSections(shifts: ShiftAndAddress[]): OrganizedSection[] {
    const sectionMap: {
      [key: string]: Section
    } = {}
    shifts.forEach((shift) => {
      const startTime = new Date(shift.businessStartTime ?? shift.startTime)
      const localeDateString: string = startTime.toLocaleDateString('en-US', {
        timeZone: shift.timezone,
      })
      if (sectionMap[localeDateString] === undefined) {
        sectionMap[localeDateString] = {
          title: startTime.toLocaleDateString('en-us', {
            timeZone: shift.timezone,
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          }),
          date: startTime,
          shiftRequestParentToShiftsMap: new Map(),
          singleShifts: [],
        }
      }
      if (shift.shiftRequestParent && shift.shiftRequestParentId) {
        const currentShifts = sectionMap[
          localeDateString
        ].shiftRequestParentToShiftsMap.get(shift.shiftRequestParentId)?.shifts
        sectionMap[localeDateString].shiftRequestParentToShiftsMap.set(
          shift.shiftRequestParentId,
          {
            shiftCardTitle: shift.shiftRequestParent?.title,
            shiftRequestParent: shift.shiftRequestParent,
            shifts: currentShifts?.concat(shift) ?? [shift],
          },
        )
      } else {
        sectionMap[localeDateString].singleShifts.push(shift)
      }
    })

    const organizedSection: OrganizedSection[] = []
    Object.keys(sectionMap).forEach((key) => {
      const section = sectionMap[key]
      const { shiftRequestParentToShiftsMap, singleShifts } = section
      const data: ShiftsToDisplay[] = []
      shiftRequestParentToShiftsMap.forEach((shiftsToDisplay) => {
        data.push(shiftsToDisplay)
      })
      singleShifts.forEach((shift) => {
        data.push({
          shiftCardTitle: shift.shiftRole,
          shifts: [shift],
        })
      })
      organizedSection.push({
        title: section.title,
        date: section.date,
        data: data,
      })
    })

    return organizedSection
  }
  const organizedSection = useMemo(() => {
    // only show the first {DEFAULT_PAGE_SIZE} shifts
    const shiftsToDisplay = shifts?.slice(0, DEFAULT_PAGE_SIZE) || []
    return getSections(shiftsToDisplay)
  }, [shifts])

  if (!isShiftsFetched || !isRegionsFetched || !isMembersFetched) {
    const defaultSectionTitle = new Date().toLocaleDateString('en-us', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    })
    return (
      <>
        {hideFilters ? undefined : (
          <Row alignCenter>
            {!isReactNativeApp && (
              <>
                <ShiftFilters {...props} />
                <ShowWorkersToggle
                  isShowWorkersToggled={isShowWorkersToggled}
                  setIsShowWorkersToggled={setIsShowWorkersToggled}
                />
              </>
            )}
          </Row>
        )}
        <div
          style={{
            marginRight: theme.space.xl,
            paddingTop: theme.space.xxs,
          }}
        >
          <Text
            variant={sectionTitleTextVariant ? sectionTitleTextVariant : 'h3'}
          >
            {defaultSectionTitle}
          </Text>
        </div>
        <Row fullWidth style={{ marginTop: '8px' }}>
          <Skeletons count={3} component="SHIFT_TILE" />
        </Row>
      </>
    )
  }

  return (
    <S.ShiftCalendarContainer>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {hideFilters ? undefined : (
          <Row
            alignCenter
            style={{
              overflowX: 'auto',
              maxWidth: '100vw',
              paddingBottom: showDesktopCard ? theme.space.sm : 0,
            }}
          >
            {!isMobileViewOrReactNative && (
              <>
                <Row justifyBetween fullWidth>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ShiftFilters {...props} />
                    <ShowWorkersToggle
                      isShowWorkersToggled={isShowWorkersToggled}
                      setIsShowWorkersToggled={setIsShowWorkersToggled}
                    />
                  </div>
                </Row>
                <CalendarPagination />
              </>
            )}
            {isMobileViewOrReactNative && (
              <Row ml={'auto'}>
                <CalendarPagination />
              </Row>
            )}
          </Row>
        )}
        {organizedSection.length ? (
          <>
            {organizedSection.map((section: OrganizedSection, i) => (
              <div
                key={`organizedShifts_section_${i}`}
                style={{
                  flexDirection: 'row',
                  ...(i !== 0
                    ? { marginTop: theme.space.med }
                    : { marginTop: theme.space.xxs }), // apply small gap margin for the top section
                }}
              >
                {isMobileViewOrReactNative && (
                  <Row justifyBetween mb={theme.space.xxs}>
                    <Text
                      variant={
                        sectionTitleTextVariant ? sectionTitleTextVariant : 'h3'
                      }
                    >
                      {section.title}
                    </Text>
                  </Row>
                )}
                <ShiftStats
                  title={section.title}
                  date={section.date}
                  filters={props.filters}
                  showDesktopCard={showDesktopCard}
                />
                <div className="shiftTiles" style={{ flex: 'auto' }}>
                  {section.data
                    // Start time is currently not properly sorted on the backend
                    // so sorting by start time here [ENG-6201]
                    .sort(
                      (shiftA, shiftB) =>
                        (
                          shiftA.shifts[0].businessStartTime ??
                          shiftA.shifts[0].startTime
                        ).getTime() -
                        (
                          shiftB.shifts[0].businessStartTime ??
                          shiftB.shifts[0].startTime
                        ).getTime(),
                    )
                    .map(
                      (
                        {
                          shifts,
                          shiftRequestParent,
                          shiftCardTitle,
                        }: ShiftsToDisplay,
                        j,
                      ) => (
                        <div
                          key={`organizedShifts_shiftTile_${j}`}
                          style={{ marginTop: theme.space.sm }}
                        >
                          {isMobileViewOrReactNative ? (
                            <MobileShiftsTileWithWorkersList
                              shiftRequestParent={shiftRequestParent}
                              shifts={shifts}
                              shiftCardTitle={shiftCardTitle}
                              defaultShowWorkerShifts={
                                hideWorkers ? false : isShowWorkersToggled
                              }
                            />
                          ) : (
                            <DesktopShiftsTileWithWorkersList
                              shiftRequestParent={shiftRequestParent}
                              shifts={shifts}
                              shiftCardTitle={shiftCardTitle}
                              alwaysHideWorkers={hideWorkers}
                              defaultShowWorkerShifts={
                                hideWorkers ? false : isShowWorkersToggled
                              }
                            />
                          )}
                        </div>
                      ),
                    )}
                </div>
              </div>
            ))}
          </>
        ) : (
          <EmptyPlaceholderTile
            title="View your ongoing and future shifts here!"
            subtitle={
              <EmptyDataLocationSubtitle
                isMobileView={isMobileViewOrReactNative}
                extraText="to see shifts in those locations, or book a new shift."
              />
            }
            iconName="folder"
          />
        )}
      </div>
    </S.ShiftCalendarContainer>
  )
}
