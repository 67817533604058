import { SvgIconProps } from '@traba/types'

export default function DollarPurple({ size = 16, ...props }: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.92368 5.89916C2.37211 4.09322 3.80409 2.69689 5.62045 2.29441L5.92303 2.22737C7.29115 1.92421 8.70902 1.92421 10.0771 2.22737L10.3797 2.29441C12.1961 2.69689 13.628 4.09323 14.0765 5.89917C14.4191 7.27877 14.4191 8.72123 14.0765 10.1008C13.628 11.9068 12.1961 13.3031 10.3797 13.7056L10.0771 13.7726C8.70902 14.0758 7.29115 14.0758 5.92303 13.7726L5.62045 13.7056C3.80409 13.3031 2.37211 11.9068 1.92368 10.1008C1.58111 8.72124 1.58111 7.27876 1.92368 5.89916Z"
        fill="white"
        stroke="#9933FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00016 4.66666C8.25506 4.66666 8.4617 4.86132 8.4617 5.10144V5.45715H8.51282C9.37115 5.45715 10 6.16009 10 6.94595C10 7.18607 9.79336 7.38073 9.53846 7.38073C9.28356 7.38073 9.07692 7.18607 9.07692 6.94595C9.07692 6.56757 8.78738 6.32671 8.51282 6.32671H8.4617V7.69549L9.08486 7.90895C9.65113 8.10293 10 8.63049 10 9.18921C10 9.90038 9.43006 10.5428 8.6444 10.5428H8.4617V10.8985C8.4617 11.1387 8.25506 11.3333 8.00016 11.3333C7.74526 11.3333 7.53863 11.1387 7.53863 10.8985V10.5428H7.48718C6.62885 10.5428 6 9.83984 6 9.05398C6 8.81386 6.20664 8.6192 6.46154 8.6192C6.71644 8.6192 6.92308 8.81386 6.92308 9.05398C6.92308 9.43236 7.21262 9.67322 7.48718 9.67322H7.53863V8.30455L6.91514 8.09098C6.34887 7.897 6 7.36944 6 6.81072C6 6.09955 6.56994 5.45715 7.3556 5.45715H7.53863V5.10144C7.53863 4.86132 7.74526 4.66666 8.00016 4.66666ZM7.53863 6.32671H7.3556C7.1537 6.32671 6.92308 6.50703 6.92308 6.81072C6.92308 7.04296 7.06671 7.21763 7.2306 7.27377L7.53863 7.37928V6.32671ZM8.4617 8.62076V9.67322H8.6444C8.8463 9.67322 9.07692 9.4929 9.07692 9.18921C9.07692 8.95697 8.93329 8.7823 8.7694 8.72616L8.4617 8.62076Z"
        fill="#9933FF"
      />
    </svg>
  )
}
