import { EditableContentCard } from 'src/components/EditableContentCard/EditableContentCard'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { theme } from 'src/libs/theme'

import { BookShiftsProps } from '../../BookShiftsScreen'
import { getInvoiceGroupEntries } from '../../utils'

export function BookShiftsInvoiceGroupSection(props: BookShiftsProps) {
  const {
    shiftRequest: { parentInvoiceGroupId },
  } = props
  const { activeInvoiceGroups } = useInvoiceGroups()
  const invoiceGroup =
    parentInvoiceGroupId && activeInvoiceGroups
      ? activeInvoiceGroups.find((group) => group.id === parentInvoiceGroupId)
      : undefined

  if (!invoiceGroup) {
    return null
  }

  return (
    <EditableContentCard
      style={{ marginTop: theme.space.xs }}
      sections={[{ entries: getInvoiceGroupEntries(invoiceGroup) }]}
    />
  )
}
