import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  ButtonVariant,
  Col,
  Row,
  SvgIcon,
  Text,
} from 'src/components/base'
import { theme } from 'src/libs/theme'
import { FeatureAnnouncementModalProps } from './utils'

const pageData = [
  {
    title: 'Introducing Shift Schedules!',
    description:
      'We made it easier to book recurring shifts and manage the workers that sign up for them.',
    image: '/img/sdm-announcement-1.png',
  },
  {
    title: 'Schedule Management',
    description: 'Manage all schedule details seamlessly in one place.',
    image: '/img/sdm-announcement-2.png',
  },
  {
    title: 'Day Shift Management',
    description:
      'Manage all shifts from one daily view, including workers, announcements, and more.',
    image: '/img/sdm-announcement-3.png',
  },
  {
    title: 'Worker Management',
    description:
      'Get a clear view of your scheduled workers, invited workers, and their replacements.',
    image: '/img/sdm-announcement-4.png',
  },
]

export const SDMAnnouncementContent = (
  props: FeatureAnnouncementModalProps,
) => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState<number>(0)

  return (
    <div
      style={{
        background: `linear-gradient(to bottom, ${theme.colors.Violet20}, ${theme.colors.White})`,
        flexDirection: 'column',
        display: 'flex',
        height: 840,
      }}
    >
      <img
        src={pageData[currentPage].image}
        alt="sdm-announcement"
        style={{
          width: '100%',
          marginTop: theme.space.lg,
          alignSelf: 'center',
        }}
      />
      <Col ml={theme.space.xxl}>
        <Text variant="h3">{pageData[currentPage].title}</Text>
        <Text variant="body1">{pageData[currentPage].description}</Text>
      </Col>
      <Row justifyBetween alignCenter mx={theme.space.xxl} mt={theme.space.lg}>
        {currentPage > 0 ? (
          <Button
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              setCurrentPage(currentPage - 1)
            }}
            trackingEvent={{
              message: 'User Clicked Previous on SDM Feature Announcement',
            }}
          >
            Previous
          </Button>
        ) : (
          <div />
        )}
        <Row gap={theme.space.sm}>
          <Button
            variant={ButtonVariant.BRANDLINK}
            aria-label="skip"
            onClick={props.handleClose}
            trackingEvent={{
              message: 'User Dismissed SDM Feature Announcement',
            }}
          >
            Skip
          </Button>
          <Button
            onClick={() => {
              if (currentPage < 3) {
                setCurrentPage(currentPage + 1)
              } else {
                navigate({
                  pathname: '/schedules',
                })
              }
            }}
            trackingEvent={{
              message: 'User Clicked Next on SDM Feature Announcement',
            }}
          >
            {currentPage === 3 ? 'Get started' : 'Next'}
          </Button>
        </Row>
      </Row>

      <div
        onClick={props.handleClose}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          right: theme.space.med,
          top: theme.space.med,
        }}
        id="modal-close-icon"
      >
        <SvgIcon name="cancel" size={24} color={theme.colors.MidnightBlue} />
      </div>
    </div>
  )
}
