import { InputAdornment, TextField } from '@mui/material'
import { ROLE_NAME_IS_NOT_UNIQUE_IN_LOCATION_ERROR_MESSAGE } from '@traba/consts'
import { FileType } from '@traba/hooks'

import {
  InputInfoCalloutRow,
  LoadingSpinner,
  RoleSearchSelect,
  Row,
  Col,
} from '@traba/react-components'

import {
  CertificationEntry,
  Company,
  GenderPreference,
  ShiftAttribute,
  WorkerCertificationType,
  RoleAttribute,
  RoleAttributeCategory,
  RoleAttributeStatus,
  RoleAttributeVisibility,
  TrainingVideo,
  AttributeLevelType,
  COMPANY_WIDE_ID,
  InputStatus,
} from '@traba/types'
import { isRoleNameDupeInSameLocation } from '@traba/utils'
import { upperFirst } from 'lodash'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import {
  Button,
  ButtonVariant,
  Divider,
  InlineBanner,
  Input,
  Modal,
  Text,
} from 'src/components/base'
import { Checkbox as ThemedCheckbox } from 'src/components/base/CheckboxThemed'
import { SelectDropdown } from 'src/components/base/Select/Select'
import SelectableCard from 'src/components/base/SelectableCard'
import { RadioButton } from 'src/components/RadioButton'
import { useRoleAttributes } from 'src/hooks/useAttributes'
import { useCertifications } from 'src/hooks/useCertifications'
import { useCompany } from 'src/hooks/useCompany'
import { useSelectedRegionalFilterLocations } from 'src/hooks/useRegionalFilter'
import { useRoles } from 'src/hooks/useRoles'
import { useHotSettings } from 'src/hooks/useSystem'
import { useTrainings } from 'src/hooks/useTrainings'
import { useTrainingVideos } from 'src/hooks/useTrainingVideos'
import { useUserCanEditCompanyWideRoles } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { getRoleCardEntries } from 'src/screens/BookShifts/utils'
import {
  getMinHourlyPayRate,
  MIN_WORKER_HOURLY_PAY_DEFAULT,
} from 'src/screens/BookShifts/validation'
import { CreateRoleData } from 'src/types'
import { parseFreeFormAttributesToRequiredAttributes } from 'src/utils/roleUtils'

import { EditableContentCard } from '../EditableContentCard/EditableContentCard'
import { GenderPreferenceSelector } from '../GenderPreferenceSelector'
import { LocationSingleSearchSelector } from '../LocationSingleSearchSelector'
import { UploadVideoModal } from '../Modals/UploadVideoModal'
import { ResponsibilityList } from './ResponsibilityList'

const RESPONSIBILITY_SORT = [
  'order_picking',
  'packing',
  'loading',
  'unloading',
  'sorting',
  'stocking',
  'organizing',
  'inventory_control',
  'quality_control',
  'assembly_line',
  'building',
]

const FORKLIFT_AND_EQUIPMENT_SORT = [
  'forklift',
  'cherry_picker',
  'hi_low_reach_truck',
  'rider_pallet_jack',
  'pallet_jack',
  'pallet_wrapper',
  'extension_ladder',
  'computer',
]

const GEAR_SORT = [
  'steel_toe_boots',
  'highlighter_vest',
  'hard_hat',
  'steel_hammer',
  'work_gloves',
]

const LANGUAGES_SORT = ['en', 'es']

const INITIAL_ROLE_STATE = {
  roleName: '',
  roleDescription: '',
  requiredAttire: '',
  requiredCertifications: [],
  requiredAttributes: [],
  freeformAttributes: {},
  defaultPayRate: undefined,
}

function getOtherType(
  displayName: string,
  category: RoleAttributeCategory,
  companyDescription?: string,
): RoleAttribute {
  return {
    category,
    displayName,
    companyDescription,
    type: `other_${category}`,
    reportable: true,
    visibility: RoleAttributeVisibility.Visible,
    status: RoleAttributeStatus.Inactive,
    levelType: AttributeLevelType.BASE_LEVELING,
    allowOpsLeveling: false,
  }
}

function genericSort(a: RoleAttribute, b: RoleAttribute, list: string[]) {
  const indexA = list.indexOf(a.type)
  const indexB = list.indexOf(b.type)

  if (indexA === -1) {
    return 1
  }
  if (indexB === -1) {
    return -1
  }

  return indexA - indexB
}

function getWeight(type: string) {
  const match = type.match(/([0-9]+)_lbs/)
  if (!match || !match[1] || isNaN(+match[1])) {
    return 0
  }
  return +match[1]
}

function liftingSort(a: RoleAttribute, b: RoleAttribute) {
  return getWeight(a.type) - getWeight(b.type)
}

interface RoleStepProps {
  role: CreateRoleData
  company?: Company
  currentRoleId?: string
  setRole: (r: Partial<CreateRoleData>) => void
  previousRoleId: string
  duplicatePreviousRoleId?: string
  filterLocationId?: string
  setPreviousRoleId: (r: string) => void
  attributes: RoleAttribute[]
  certifications: CertificationEntry[]
  toggleAttribute: (a: ShiftAttribute) => void
  isAttributeSelected: (a: ShiftAttribute) => boolean
  toggleCertification: (c: string) => void
  isCertificationSelected: (c: string) => boolean
  isGMPTrainingSelected: boolean
  setIsGMPTrainingSelected: (selected: boolean) => void
  forkliftCertificationInteracted: boolean
  setForkliftCertificationInteracted: (val: boolean) => void
  trainingVideos: TrainingVideo[]
  requiredForAllTrainingVideos?: TrainingVideo[]
  closeUploadVideoModal: () => void
  onUploadSuccess: (videoId: string) => void
  openUploadVideoModal: () => void
  isModalOpen: boolean
  setSelectedVideoIds: (vIds: string[]) => void
  selectedVideoIds: string[]
  nextStep: () => void
  isEdit: boolean
  minHourlyPayRate: number
  genderPreference?: GenderPreference
  setGenderPreference: (genderPreference?: GenderPreference) => void
}

const ROLE_RESPONSIBILITY_CATEGORIES: RoleAttributeCategory[] = [
  RoleAttributeCategory.Responsibilities,
  RoleAttributeCategory.MANUFACTURING_AND_PRODUCTION,
  RoleAttributeCategory.FOOD_PREPARATION_AND_SERVICE,
]

function RoleResponsibilityStep(props: RoleStepProps) {
  const responsibilities = useMemo(
    () => [
      ...props.attributes
        .filter((a) => a.category === RoleAttributeCategory.Responsibilities)
        .sort((a, b) => genericSort(a, b, RESPONSIBILITY_SORT)),
      getOtherType(
        'Other',
        RoleAttributeCategory.Responsibilities,
        'Enter additional custom responsibilities.',
      ),
    ],
    [props.attributes],
  )

  const manuSkills = useMemo(
    () => [
      ...props.attributes
        .filter(
          (a) =>
            a.category === RoleAttributeCategory.MANUFACTURING_AND_PRODUCTION,
        )
        .sort((a, b) => genericSort(a, b, RESPONSIBILITY_SORT)),
    ],
    [props.attributes],
  )

  const foodSkills = useMemo(
    () => [
      ...props.attributes
        .filter(
          (a) =>
            a.category === RoleAttributeCategory.FOOD_PREPARATION_AND_SERVICE,
        )
        .sort((a, b) => genericSort(a, b, RESPONSIBILITY_SORT)),
    ],
    [props.attributes],
  )

  const getOnChange = (category: RoleAttributeCategory) => (type: string) => {
    props.toggleAttribute({
      type,
      category,
    })
    if (type === 'gmp') {
      props.setIsGMPTrainingSelected(true)
    }
  }

  const otherSelected = !!props.role.requiredAttributes?.some(
    (ra) => ra.type === `other_${RoleAttributeCategory.Responsibilities}`,
  )

  return (
    <>
      <Text variant="h5">
        What will workers be responsible for in this role?
      </Text>
      <ResponsibilityList
        iconName="warehousing"
        title="General Warehousing Responsibilities"
        responsibilities={responsibilities}
        onChange={getOnChange(RoleAttributeCategory.Responsibilities)}
        isAttributeSelected={props.isAttributeSelected}
      />
      {manuSkills.length > 0 && (
        <ResponsibilityList
          iconName="manuAndProd"
          title="Manufacturing and Production"
          responsibilities={manuSkills}
          onChange={getOnChange(
            RoleAttributeCategory.MANUFACTURING_AND_PRODUCTION,
          )}
          isAttributeSelected={props.isAttributeSelected}
        />
      )}
      {foodSkills.length > 0 && (
        <ResponsibilityList
          iconName="foodPrepAndService"
          title="Food Preparation and Service"
          responsibilities={foodSkills}
          onChange={getOnChange(
            RoleAttributeCategory.FOOD_PREPARATION_AND_SERVICE,
          )}
          isAttributeSelected={props.isAttributeSelected}
        />
      )}
      {otherSelected ? (
        <Input
          rows={2}
          label="Other"
          placeholder="Please list additional responsibilities here."
          type="textarea"
          className="xs-12"
          value={
            (props.role.freeformAttributes &&
              props.role.freeformAttributes[
                RoleAttributeCategory.Responsibilities
              ]) ??
            ''
          }
          onChange={(e) =>
            props.setRole({
              freeformAttributes: {
                ...props.role.freeformAttributes,
                [RoleAttributeCategory.Responsibilities]: e.target.value,
              },
            })
          }
        />
      ) : null}
    </>
  )
}

function RoleOtherAttributesStep(props: RoleStepProps) {
  const analyticsAction = props.isEdit ? 'Editing' : 'Creation'
  const lifting = useMemo(
    () => [
      getOtherType('No weight requirement', RoleAttributeCategory.Lifting),
      ...props.attributes
        .filter((a) => a.category === RoleAttributeCategory.Lifting)
        .sort((a, b) => liftingSort(a, b)),
    ],
    [props.attributes],
  )
  const forkliftAndEquipment = useMemo(
    () => [
      ...props.attributes
        .filter((a) => a.category === RoleAttributeCategory.Forklift)
        .sort((a, b) => genericSort(a, b, FORKLIFT_AND_EQUIPMENT_SORT)),
      ...props.attributes
        .filter((a) => a.category === RoleAttributeCategory.Equipment)
        .sort((a, b) => genericSort(a, b, FORKLIFT_AND_EQUIPMENT_SORT)),
      getOtherType('Other', RoleAttributeCategory.Equipment),
    ],
    [props.attributes],
  )
  const languages = useMemo(
    () =>
      props.attributes
        .filter((a) => a.category === RoleAttributeCategory.Languages)
        .sort((a, b) => genericSort(a, b, LANGUAGES_SORT)),
    [props.attributes],
  )
  const certifications = useMemo(
    () =>
      props.certifications.filter(
        (a) => a.type !== WorkerCertificationType.OSHA_FORKLIFT,
      ),
    [props.certifications],
  )

  const liftingSelected = props.role.requiredAttributes?.find(
    (ra) => ra.category === RoleAttributeCategory.Lifting,
  )

  function handleLiftingChange(type: string) {
    window.analytics.track(
      `User Selected Lifting During Role ${analyticsAction}`,
      {
        value: type,
        preValue: liftingSelected?.type,
      },
    )
    props.setRole({
      requiredAttributes: (props.role.requiredAttributes || [])
        .filter((ra) => ra.category !== RoleAttributeCategory.Lifting)
        .concat(
          type !== ''
            ? [{ type, category: RoleAttributeCategory.Lifting }]
            : [],
        ),
    })
  }

  const forkliftCertificationSelected = props.isCertificationSelected(
    WorkerCertificationType.OSHA_FORKLIFT,
  )

  const forkliftSelected = !!props.role.requiredAttributes?.some(
    (ra) => ra.category === RoleAttributeCategory.Forklift,
  )

  const otherSelected = !!props.role.requiredAttributes?.some(
    (ra) => ra.type === `other_${RoleAttributeCategory.Equipment}`,
  )

  return (
    <>
      <Text
        variant="h5"
        style={{ marginTop: theme.space.sm, marginBottom: theme.space.xs }}
      >
        How much weight will workers need to lift in this role?
      </Text>
      <SelectDropdown
        placeholder="Select amount…"
        value={liftingSelected?.type ?? ''}
        handleSelect={handleLiftingChange}
        className="xs-6"
        menuItems={lifting.map((l) => ({
          value: l.type,
          label: upperFirst(l.displayName),
        }))}
      />
      <Text
        variant="h5"
        style={{ marginTop: theme.space.sm, marginBottom: theme.space.xs }}
      >
        What equipment will be used on the job?
      </Text>
      <Row style={{ flexWrap: 'wrap', gap: theme.space.xxs }}>
        {forkliftAndEquipment.map((o) => (
          <SelectableCard
            key={`${o.type}_${o.category}`}
            label={upperFirst(o.displayName)}
            onClick={() => props.toggleAttribute(o)}
            selected={props.isAttributeSelected(o)}
          />
        ))}
      </Row>
      {otherSelected ? (
        <Input
          rows={2}
          label="Other"
          placeholder="Please list additional equipment here."
          type="textarea"
          className="xs-12"
          value={
            (props.role.freeformAttributes &&
              props.role.freeformAttributes[RoleAttributeCategory.Equipment]) ??
            ''
          }
          onChange={(e) =>
            props.setRole({
              freeformAttributes: {
                ...props.role.freeformAttributes,
                [RoleAttributeCategory.Equipment]: e.target.value,
              },
            })
          }
        />
      ) : null}
      {forkliftSelected ? (
        <>
          <Text
            variant="h5"
            style={{ marginTop: theme.space.sm, marginBottom: theme.space.xs }}
          >
            Is OSHA forklift certification required for this role?
          </Text>
          <Row alignCenter style={{ columnGap: theme.space.xl }}>
            <Col>
              <Row
                alignCenter
                style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
                onClick={() => {
                  window.analytics.track(
                    `User Selected Forklift Preferred During Role ${analyticsAction}`,
                  )
                  if (!props.forkliftCertificationInteracted) {
                    props.setForkliftCertificationInteracted(true)
                    return
                  }
                  props.toggleCertification(
                    WorkerCertificationType.OSHA_FORKLIFT,
                  )
                }}
              >
                <RadioButton
                  selected={
                    !props.forkliftCertificationInteracted
                      ? false
                      : !forkliftCertificationSelected
                  }
                />
                <Text variant="body1">Preferred</Text>
              </Row>
            </Col>
            <Col>
              <Row
                alignCenter
                style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
                onClick={() => {
                  window.analytics.track(
                    `User Selected Forklift Required During Role ${analyticsAction}`,
                  )
                  if (!props.forkliftCertificationInteracted) {
                    props.setForkliftCertificationInteracted(true)
                  }
                  props.toggleCertification(
                    WorkerCertificationType.OSHA_FORKLIFT,
                  )
                }}
              >
                <RadioButton
                  selected={
                    !props.forkliftCertificationInteracted
                      ? false
                      : forkliftCertificationSelected
                  }
                />
                <Text variant="body1">Required</Text>
              </Row>
            </Col>
          </Row>
        </>
      ) : null}
      <Text
        variant="h5"
        style={{ marginTop: theme.space.sm, marginBottom: theme.space.xs }}
      >
        Any additional requirements?
      </Text>
      <Row style={{ flexWrap: 'wrap', gap: theme.space.xxs }}>
        {certifications.map((o) => (
          <SelectableCard
            key={o.type}
            label={o.name}
            onClick={() => props.toggleCertification(o.type)}
            selected={props.isCertificationSelected(o.type)}
          />
        ))}
        {languages.map((o) => (
          <SelectableCard
            key={`${o.type}_${o.category}`}
            label={upperFirst(o.displayName)}
            onClick={() => props.toggleAttribute(o)}
            selected={props.isAttributeSelected(o)}
          />
        ))}
      </Row>
      {props.company?.allowGenderPreference && (
        <GenderPreferenceSelector
          selectedGenderPreference={props.genderPreference}
          setSelectedGenderPreference={props.setGenderPreference}
          title="Is there a preferred gender for this role?"
        />
      )}
    </>
  )
}

function RoleGearStep(props: RoleStepProps) {
  const gear = useMemo(
    () =>
      props.attributes
        .filter((a) => a.category === RoleAttributeCategory.Gear)
        .sort((a, b) => genericSort(a, b, GEAR_SORT)),
    [props.attributes],
  )

  return (
    <>
      <Text
        variant="h5"
        style={{ marginTop: theme.space.sm, marginBottom: theme.space.xs }}
      >
        What should workers wear in this role?
      </Text>
      <Input
        rows={4}
        placeholder="Describe the type, colors, and style of clothing…"
        type="textarea"
        className="xs-12"
        value={props.role.requiredAttire}
        onChange={(e) => props.setRole({ requiredAttire: e.target.value })}
      />
      <Text
        variant="h5"
        style={{ marginTop: theme.space.sm, marginBottom: theme.space.xs }}
      >
        What gear will workers need to bring?
      </Text>
      <Row style={{ flexWrap: 'wrap', gap: theme.space.xxs }}>
        {gear.map((o) => (
          <SelectableCard
            key={`${o.type}_${o.category}`}
            label={upperFirst(o.displayName)}
            onClick={() => props.toggleAttribute(o)}
            selected={props.isAttributeSelected(o)}
          />
        ))}
      </Row>
      <Text
        variant="h5"
        style={{ marginTop: theme.space.sm, marginBottom: theme.space.xxs }}
      >
        What training videos are required?
        <Text
          style={{ marginLeft: theme.space.xxs }}
          variant="linkUnderline"
          onClick={() => {
            props.openUploadVideoModal()
          }}
        >
          Upload new video
        </Text>
      </Text>
      {props.trainingVideos.map((tv) => {
        const isRequired =
          props.requiredForAllTrainingVideos?.findIndex(
            (v) => v.id === tv.id,
          ) !== -1
        return (
          <Row my={theme.space.xxs}>
            <ThemedCheckbox
              key={tv.id}
              disabled={isRequired}
              selected={props.selectedVideoIds.includes(tv.id)}
              onClick={() => {
                if (isRequired) {
                  return
                }
                const idx = props.selectedVideoIds.findIndex(
                  (videoId) => videoId === tv.id,
                )
                const newVideoIds = [...props.selectedVideoIds]
                if (idx === -1) {
                  newVideoIds.push(tv.id)
                } else {
                  newVideoIds.splice(idx, 1)
                }
                props.setSelectedVideoIds(newVideoIds)
                props.setRole({ videoIds: newVideoIds })
              }}
            />
            <Text variant="body1" style={{ marginLeft: theme.space.xs }}>
              {tv.name}
            </Text>
          </Row>
        )
      })}
      {props.attributes.findIndex((attr) => attr.type === 'gmp') !== -1 && (
        <>
          <Text
            variant="h5"
            style={{
              marginTop: theme.space.med,
              marginBottom: theme.space.xs,
            }}
          >
            What Traba training modules are required?
          </Text>
          <Row my={theme.space.xxs}>
            <ThemedCheckbox
              selected={props.isGMPTrainingSelected}
              onClick={() => {
                props.setIsGMPTrainingSelected(!props.isGMPTrainingSelected)
              }}
            />
            <Col mx={theme.space.xs}>
              <Text variant="body1">
                Traba Good Manufacturing Practices Awareness Education
              </Text>
              <Text variant="body2">
                This is selected because you picked Good Manufacturing Practices
                as a required responsibility for this role. We offer GMP
                awareness education for workers through the app.
              </Text>
            </Col>
          </Row>
        </>
      )}
      <Modal
        isOpen={props.isModalOpen}
        handleClose={props.closeUploadVideoModal}
      >
        <UploadVideoModal
          onClose={props.closeUploadVideoModal}
          onUploadSuccess={props.onUploadSuccess}
          fileType={FileType.TRAINING_VIDEOS}
        />
      </Modal>
    </>
  )
}

interface DefaultPayRateComponentProps {
  defaultPayRate: number
  setDefaultPayRate: (num: number) => void
  previousRoleId?: string
  minHourlyPayRate: number
}

export const DefaultPayRateComponent = (
  props: DefaultPayRateComponentProps,
) => {
  const { defaultPayRate, setDefaultPayRate, previousRoleId } = props

  const [maskedDefaultPayRate, setMaskedDefaultPayRate] = useState(
    defaultPayRate.toFixed(2),
  )
  const [touchedDefaultPayRate, setTouchedDefaultPayRate] = useState(false)

  const onDefaultPayRateChange = (masked: string) => {
    const payRate = +Math.abs(+masked).toFixed(2)
    setMaskedDefaultPayRate(masked)
    setDefaultPayRate(payRate)
  }

  const onDefaultPayRateBlur = () => {
    setMaskedDefaultPayRate(defaultPayRate.toFixed(2))
    setTouchedDefaultPayRate(true)
  }

  useEffect(() => {
    setMaskedDefaultPayRate(defaultPayRate.toFixed(2))
  }, [previousRoleId])

  const defaultPayRateValidationError = defaultPayRate < props.minHourlyPayRate

  return (
    <>
      <Text
        variant="h5"
        style={{ marginTop: theme.space.sm, marginBottom: theme.space.xxs }}
      >
        What should be the default hourly pay for this role?
      </Text>
      <Text variant="body2" style={{ marginTop: theme.space.xxs }}>
        The default hourly pay rate will fill the initial pay rate when creating
        shifts with this role. The user creating the shift will still be able to
        update the pay rate.
      </Text>
      <TextField
        id="payrate"
        label="Default Pay Rate"
        type="number"
        style={{
          width: 200,
          marginTop: theme.space.sm,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Text variant="body1">$</Text>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Text variant="body1">/ hr</Text>
            </InputAdornment>
          ),
        }}
        value={maskedDefaultPayRate}
        onChange={(e) => onDefaultPayRateChange(e.currentTarget.value)}
        onBlur={onDefaultPayRateBlur}
      />
      {touchedDefaultPayRate && defaultPayRateValidationError && (
        <InlineBanner
          severity="error"
          text={`The hourly rate must be $${props.minHourlyPayRate} or greater.`}
          style={{ marginTop: theme.space.xs }}
        />
      )}
    </>
  )
}

function RoleDetailStep(props: RoleStepProps) {
  const { hotSettings } = useHotSettings()
  const userCanEditCompanyWideRoles = useUserCanEditCompanyWideRoles()
  const { roles } = useRoles()
  const [roleNameError, setRoleNameError] = useState<string | undefined>(
    undefined,
  )
  const { roleName, locationId: roleLocationId } = props.role

  const isRoleNameDupeInLocation = useCallback(
    (roleName: string, locationId?: string) => {
      if (!locationId) {
        // if location ID has not been set yet, do not check
        return false
      }

      return isRoleNameDupeInSameLocation({
        roles,
        newRoleName: roleName,
        newRoleLocationId: locationId,
        currentRoleId: props.currentRoleId,
      })
    },
    [roles, props.currentRoleId],
  )

  const onBlurRoleName = useCallback(() => {
    const isNameDuplicatedInLocation = isRoleNameDupeInLocation(
      roleName,
      roleLocationId,
    )
    setRoleNameError(
      isNameDuplicatedInLocation
        ? ROLE_NAME_IS_NOT_UNIQUE_IN_LOCATION_ERROR_MESSAGE
        : undefined,
    )
  }, [roleName, roleLocationId, isRoleNameDupeInLocation])

  const onChangeRoleLocation = useCallback(
    (locationId: string | undefined) => {
      props.setRole({ locationId })
      const isNameDuplicatedInLocation = isRoleNameDupeInLocation(
        roleName,
        locationId,
      )
      setRoleNameError(
        isNameDuplicatedInLocation
          ? ROLE_NAME_IS_NOT_UNIQUE_IN_LOCATION_ERROR_MESSAGE
          : undefined,
      )
    },
    [roles, roleName, isRoleNameDupeInLocation],
  )

  return (
    <>
      <Text variant="h5" mt={theme.space.sm} mb={theme.space.xs}>
        Tell us about this role
      </Text>
      <Input
        label="Role Name"
        placeholder="e.g. Warehouse Associate"
        className="xs-12"
        value={roleName}
        onChange={(e) => props.setRole({ roleName: e.target.value })}
        onBlur={onBlurRoleName}
        inputStatus={roleNameError ? InputStatus.error : undefined}
        errorMessage={roleNameError}
      />
      {hotSettings?.enableRegionalAccessPhase1 && (
        <Col mt={theme.space.sm} gap={theme.space.xxs}>
          <LocationSingleSearchSelector
            disabled={props.isEdit}
            onChange={onChangeRoleLocation}
            filterLocationId={props.filterLocationId}
            selectedLocationId={roleLocationId}
            hideCompanyWideOption={!userCanEditCompanyWideRoles}
          />

          {props.isEdit && (
            <InputInfoCalloutRow text="Cannot edit location of a role" />
          )}
        </Col>
      )}
      <Input
        rows={3}
        label="Overview"
        placeholder="Please share a brief overview of this role. This information will be displayed to workers in the Traba app. In the following steps, you’ll be asked to add job details and requirements."
        type="textarea"
        className="xs-12"
        value={props.role.roleDescription}
        onChange={(e) => props.setRole({ roleDescription: e.target.value })}
      />
      <DefaultPayRateComponent
        defaultPayRate={props.role.defaultPayRate}
        setDefaultPayRate={(defaultPayRate) =>
          props.setRole({ defaultPayRate })
        }
        minHourlyPayRate={props.minHourlyPayRate}
      />
    </>
  )
}

export function CreateRoleFromPreviousStep(props: RoleStepProps) {
  const {
    role,
    setRole,
    nextStep,
    previousRoleId,
    setPreviousRoleId,
    duplicatePreviousRoleId,
    filterLocationId,
  } = props
  const { roleName, locationId: roleLocationId } = role
  const { hotSettings } = useHotSettings()
  const userCanEditCompanyWideRoles = useUserCanEditCompanyWideRoles()
  const { roles } = useRoles()
  const { company } = useCompany()
  const { roleAttributes } = useRoleAttributes()
  const { certifications } = useCertifications()
  const [roleNameError, setRoleNameError] = useState<string | undefined>(
    undefined,
  )

  const { selectedActiveLocationIds } = useSelectedRegionalFilterLocations()
  const sortedDisplayedRolesForDropdown = useMemo(() => {
    const rolesToDisplay = hotSettings?.enableRegionalAccessPhase2
      ? roles.filter(
          (r) =>
            !r.location?.locationId ||
            r.location.locationId === filterLocationId ||
            selectedActiveLocationIds.has(r.location.locationId),
        )
      : roles
    return rolesToDisplay.sort((a, b) => a.roleName.localeCompare(b.roleName))
  }, [
    roles,
    selectedActiveLocationIds,
    hotSettings?.enableRegionalAccessPhase2,
  ])

  const onBlurRoleName = useCallback(() => {
    const isNameDuplicatedInLocation = isRoleNameDupeInSameLocation({
      roles,
      newRoleName: roleName,
      newRoleLocationId: roleLocationId,
    })
    setRoleNameError(
      isNameDuplicatedInLocation && roleLocationId
        ? ROLE_NAME_IS_NOT_UNIQUE_IN_LOCATION_ERROR_MESSAGE
        : undefined,
    )
  }, [roles, roleName, roleLocationId])

  const onChangeRoleLocation = useCallback(
    (locationId: string | undefined) => {
      setRole({ locationId })

      const isNameDuplicatedInLocation = isRoleNameDupeInSameLocation({
        roles,
        newRoleName: roleName,
        newRoleLocationId: locationId,
      })
      setRoleNameError(
        isNameDuplicatedInLocation && locationId
          ? ROLE_NAME_IS_NOT_UNIQUE_IN_LOCATION_ERROR_MESSAGE
          : undefined,
      )
    },
    [roles, roleName],
  )

  function onSelect(roleId: string) {
    setPreviousRoleId(roleId)
    const selected = roles.find((r) => r.roleId === roleId)
    if (!roleId || !selected) {
      window.analytics.track('User Cleared Previous Role During Role Creation')
      setRole(INITIAL_ROLE_STATE)
    } else {
      window.analytics.track(
        'User Selected Previous Role During Role Creation',
        { role: selected },
      )
      const {
        roleName,
        roleDescription,
        requiredAttributes,
        requiredCertifications,
        requiredAttire,
        freeformAttributes,
        defaultPayRate,
      } = selected
      const requiredAttributesWithOther =
        parseFreeFormAttributesToRequiredAttributes(
          requiredAttributes,
          freeformAttributes,
        )
      setRole({
        roleName,
        roleDescription,
        requiredAttributes: requiredAttributesWithOther,
        requiredCertifications,
        requiredAttire,
        freeformAttributes,
        defaultPayRate,
      })

      const isNameDuplicatedInLocation = isRoleNameDupeInSameLocation({
        roles,
        newRoleName: roleName,
        newRoleLocationId: role.locationId,
      })
      setRoleNameError(
        isNameDuplicatedInLocation && role.locationId
          ? ROLE_NAME_IS_NOT_UNIQUE_IN_LOCATION_ERROR_MESSAGE
          : undefined,
      )
    }
  }

  useEffect(() => {
    if (duplicatePreviousRoleId) {
      onSelect(duplicatePreviousRoleId)
    }
  }, [])

  return (
    <>
      <Text
        variant="h5"
        style={{ marginTop: theme.space.sm, marginBottom: theme.space.xs }}
      >
        Select a role
      </Text>

      <Row alignStart style={{ gap: theme.space.xs }}>
        {!hotSettings?.enableRegionalAccessPhase1 ? (
          <SelectDropdown
            disabled={!!duplicatePreviousRoleId}
            label={previousRoleId ? 'Previous role' : undefined}
            placeholder="Choose from existing roles"
            value={previousRoleId}
            handleSelect={onSelect}
            menuItems={[
              { label: `Choose from existing roles`, value: '' },
              ...sortedDisplayedRolesForDropdown.map((r) => ({
                value: r.roleId,
                label: r.roleName,
              })),
            ]}
            style={{ flex: previousRoleId ? 1 : 0.5 }}
          />
        ) : (
          <RoleSearchSelect
            roles={sortedDisplayedRolesForDropdown}
            selectStyle={{ minHeight: '48px' }}
            labelStyle={{ fontSize: '12px' }}
            selectedRoleId={previousRoleId}
            handleRoleChange={onSelect}
            placeholder="Choose from existing roles"
            style={{ flex: previousRoleId ? 1 : 0.5, margin: 0 }}
            disabled={!!duplicatePreviousRoleId}
            label={previousRoleId ? 'Previous role' : undefined}
          />
        )}
        {previousRoleId ? (
          <Col style={{ flex: 1 }}>
            <Input
              onChange={(e) => setRole({ roleName: e.target.value })}
              value={role.roleName}
              label="RoleName"
              placeholder="e.g. Warehouse Associate"
              className="xs-12"
              containerStyle={{ marginTop: 0 }}
              onBlur={onBlurRoleName}
              inputStatus={roleNameError ? InputStatus.error : undefined}
              errorMessage={roleNameError}
            />
          </Col>
        ) : null}
      </Row>

      {previousRoleId ? (
        <>
          {hotSettings?.enableRegionalAccessPhase1 && (
            <Row mt={theme.space.sm}>
              <LocationSingleSearchSelector
                onChange={onChangeRoleLocation}
                selectedLocationId={role.locationId}
                filterLocationId={filterLocationId}
                hideCompanyWideOption={!userCanEditCompanyWideRoles}
              />
            </Row>
          )}
          <Input
            rows={3}
            label="Overview"
            placeholder="Please share a brief overview of this role. This information will be displayed to workers in the Traba app. In the following steps, you'll be asked to add job details and requirements."
            type="textarea"
            className="xs-12"
            value={role.roleDescription}
            onChange={(e) => setRole({ roleDescription: e.target.value })}
          />
          <DefaultPayRateComponent
            defaultPayRate={role.defaultPayRate}
            setDefaultPayRate={(defaultPayRate) => setRole({ defaultPayRate })}
            previousRoleId={previousRoleId}
            minHourlyPayRate={props.minHourlyPayRate}
          />
          <EditableContentCard
            style={{ marginTop: theme.space.xs }}
            sections={[
              {
                title: 'Details',
                onEdit: nextStep,
                entries: getRoleCardEntries(
                  role,
                  roleAttributes,
                  certifications,
                  company,
                  true,
                ),
              },
            ]}
          />
        </>
      ) : null}
    </>
  )
}

type RoleStepperProps = {
  initialRoleState: CreateRoleData
  duplicatePreviousRoleId?: string
  filterLocationId?: string
  fromPrevious: boolean
  onCancel: () => void
  onConfirm: (role: any) => void
  submitLoading: boolean
  isEdit: boolean
  error?: any
  currentRoleId?: string
}

export const RoleStepper = (props: RoleStepperProps) => {
  const {
    initialRoleState,
    fromPrevious,
    onCancel,
    onConfirm,
    submitLoading,
    isEdit,
    duplicatePreviousRoleId = '',
    filterLocationId,
    currentRoleId,
  } = props

  const analyticsAction = isEdit ? 'Editing' : 'Creation'

  const [role, setRole] = useReducer(
    (state: CreateRoleData, value: Partial<CreateRoleData>) => ({
      ...state,
      ...value,
    }),
    initialRoleState,
  )

  const [step, setStep] = useState(0)
  const { roleAttributes } = useRoleAttributes()
  const { certifications } = useCertifications()
  const { trainings } = useTrainings()
  const { trainingVideos, requiredForAllTrainingVideos } = useTrainingVideos()
  const { roles } = useRoles()
  const [selectedVideoIds, setSelectedVideoIds] = useState<string[]>([])
  const [previousRoleId, setPreviousRoleId] = useState(duplicatePreviousRoleId)
  const [genderPreference, setGenderPreference] = useState<
    GenderPreference | undefined
  >(initialRoleState.genderPreference || undefined)
  const { company } = useCompany()
  const { hotSettings } = useHotSettings()

  const gmpTrainingId = trainings?.find(
    (training) =>
      training.nameEn === 'Good Manufacturing Practices Awareness Education',
  )?.id
  const [isGMPTrainingSelected, setIsGMPTrainingSelected] = useState<boolean>(
    !!gmpTrainingId && !!role.requiredTrainingIds?.includes(gmpTrainingId),
  )

  const [forkliftCertificationInteracted, setForkliftCertificationInteracted] =
    useState(
      initialRoleState.requiredAttributes?.some(
        (attr) => attr.category === RoleAttributeCategory.Forklift,
      ),
    )
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    const preSelectedVideoIds = trainingVideos
      .filter((tv) => tv.requiredForAll)
      .map((tv) => tv.id)

    const videoIds = Array.from(
      new Set([...preSelectedVideoIds, ...(initialRoleState.videoIds ?? [])]),
    )
    setSelectedVideoIds(videoIds)
    setRole({ videoIds: videoIds })
  }, [trainingVideos, initialRoleState?.videoIds])

  function openUploadVideoModal() {
    setIsModalOpen(true)
  }

  function closeUploadVideoModal() {
    setIsModalOpen(false)
  }

  function onUploadSuccess(videoId: string) {
    setSelectedVideoIds([...selectedVideoIds, videoId])
    setRole({ videoIds: [...selectedVideoIds, videoId] })
  }

  const selectedAttributes = useMemo(
    () => new Set(role.requiredAttributes?.map((ra) => ra.type)),
    [role.requiredAttributes],
  )
  function isAttributeSelected(attribute: ShiftAttribute) {
    return selectedAttributes.has(attribute.type)
  }
  const selectedCertifications = useMemo(
    () => new Set(role.requiredCertifications),
    [role.requiredCertifications],
  )
  function isCertificationSelected(c: string) {
    return selectedCertifications.has(c)
  }

  const minPayRate = getMinHourlyPayRate({
    companyMinHourlyPay: company?.minHourlyPayRate,
    platformMinHourlyPay:
      hotSettings?.platformMinHourlyPayRate ?? MIN_WORKER_HOURLY_PAY_DEFAULT,
  })
  const STEPS = [
    {
      Component: !fromPrevious ? RoleDetailStep : CreateRoleFromPreviousStep,
      validate(): boolean {
        return (
          !!role.roleName &&
          !!role.roleDescription &&
          role.defaultPayRate >= minPayRate &&
          !isRoleNameDupeInSameLocation({
            roles,
            newRoleName: role.roleName,
            newRoleLocationId: role.locationId,
            currentRoleId,
          }) &&
          (!hotSettings?.enableRegionalAccessPhase1 || !!role.locationId)
        )
      },
    },
    {
      Component: RoleResponsibilityStep,
      validate(): boolean {
        const hasResponsibilities = role.requiredAttributes?.some((ra) =>
          ROLE_RESPONSIBILITY_CATEGORIES.includes(ra.category),
        )
        if (!hasResponsibilities) {
          return false
        }
        const otherSelected = role.requiredAttributes?.some(
          (ra) => ra.type === `other_${RoleAttributeCategory.Responsibilities}`,
        )
        const otherProvided =
          role.freeformAttributes &&
          role.freeformAttributes[RoleAttributeCategory.Responsibilities]
        if (otherSelected && !otherProvided) {
          return false
        }
        return true
      },
    },
    {
      Component: RoleOtherAttributesStep,
      validate(): boolean {
        const hasLifting = role.requiredAttributes?.some(
          (ra) => ra.category === RoleAttributeCategory.Lifting,
        )
        if (!hasLifting) {
          return false
        }
        const hasForklift = role.requiredAttributes?.some(
          (ra) => ra.category === RoleAttributeCategory.Forklift,
        )
        if (hasForklift && !forkliftCertificationInteracted) {
          return false
        }
        const otherSelected = role.requiredAttributes?.some(
          (ra) => ra.type === `other_${RoleAttributeCategory.Equipment}`,
        )
        const otherProvided =
          role.freeformAttributes &&
          role.freeformAttributes[RoleAttributeCategory.Equipment]
        if (otherSelected && !otherProvided) {
          return false
        }
        return true
      },
    },
    {
      Component: RoleGearStep,
      validate(): boolean {
        return !!role.requiredAttire
      },
    },
  ]

  const atEnd = step === STEPS.length - 1 || (fromPrevious && step === 0)
  const { Component: RoleStepComponent, validate } = STEPS[step]
  const valid = validate()
  function onContinue(forceNext = false) {
    if (!valid) {
      return
    }
    if (atEnd && !forceNext) {
      // Remove "other" types
      const scrubbedRequiredAttributes = role.requiredAttributes?.filter(
        (ra) => !!roleAttributes?.find((a) => a.type === ra.type),
      )
      // Remove entries for which "other" is no longer selected
      const scrubbedFreeformAttributes = !role.freeformAttributes
        ? undefined
        : Object.fromEntries(
            Object.entries(role.freeformAttributes).filter(([key]) =>
              role.requiredAttributes?.some((ra) => ra.type === `other_${key}`),
            ),
          )

      // Remove OSHA_FORKLIFT cert if forklift type attribute is not selected
      const hasForkliftAttribute = role.requiredAttributes.some(
        (attribute) => attribute.category === RoleAttributeCategory.Forklift,
      )

      // Add training id if needed

      const requiredTrainingIds =
        isGMPTrainingSelected && gmpTrainingId ? [gmpTrainingId] : []

      const scrubbedRequiredCertfications = hasForkliftAttribute
        ? role.requiredCertifications
        : role.requiredCertifications.filter(
            (certification) =>
              certification !== WorkerCertificationType.OSHA_FORKLIFT,
          )
      const scrubbedRole = {
        ...role,
        requiredTrainingIds,
        requiredAttributes: scrubbedRequiredAttributes,
        requiredCertifications: scrubbedRequiredCertfications,
        freeformAttributes: scrubbedFreeformAttributes,
        locationId:
          role.locationId === COMPANY_WIDE_ID ? undefined : role.locationId,
      }
      if (isEdit) {
        const scrubbedEditRole = {
          ...scrubbedRole,
          genderPreference:
            scrubbedRole.genderPreference === undefined
              ? null
              : scrubbedRole.genderPreference,
          locationId: undefined, // we do not allow updating location after creation
        }
        onConfirm(scrubbedEditRole)
        return
      }

      onConfirm(scrubbedRole)

      window.analytics.track(
        `User Clicked ${
          isEdit ? 'Save' : 'Create'
        } During Role ${analyticsAction}`,
        {
          role: scrubbedRole,
          fromPrevious,
        },
      )
    } else {
      window.analytics.track(
        `User Clicked Continue During Role ${analyticsAction}`,
        {
          step,
        },
      )
      setStep(step + 1)
    }
  }
  function onPrevious() {
    window.analytics.track(
      `User Clicked Previous During Role ${analyticsAction}`,
      {
        step,
      },
    )
    if (step === 0) {
      onCancel()
    } else {
      setStep(step - 1)
    }
  }

  function addAttribute(attribute: ShiftAttribute) {
    window.analytics.track(
      `User Selected Attribute During Role ${analyticsAction}`,
      {
        attribute,
      },
    )
    setRole({
      requiredAttributes: (role.requiredAttributes || []).concat([
        { type: attribute.type, category: attribute.category },
      ]),
    })
  }

  function removeAttribute(attribute: ShiftAttribute) {
    window.analytics.track(
      `User Removed Attribute During Role ${analyticsAction}`,
      {
        attribute,
      },
    )
    setRole({
      requiredAttributes: role.requiredAttributes?.filter(
        (ra) => ra.type !== attribute.type,
      ),
    })
  }

  function toggleAttribute(attribute: ShiftAttribute) {
    if (isAttributeSelected(attribute)) {
      removeAttribute(attribute)
    } else {
      addAttribute(attribute)
    }
  }

  function addCertification(c: string) {
    window.analytics.track(
      `User Selected Certification During Role ${analyticsAction}`,
      {
        certification: c,
      },
    )
    setRole({
      requiredCertifications: (role.requiredCertifications || []).concat([c]),
    })
  }

  function removeCertification(c: string) {
    window.analytics.track(
      `User Removed Certification During Role ${analyticsAction}`,
      {
        certification: c,
      },
    )
    setRole({
      requiredCertifications: role.requiredCertifications?.filter(
        (rc) => rc !== c,
      ),
    })
  }

  function toggleCertification(c: string) {
    if (isCertificationSelected(c)) {
      removeCertification(c)
    } else {
      addCertification(c)
    }
  }

  function handleGenderSelection(genderPreference?: GenderPreference) {
    setGenderPreference(genderPreference)
    setRole({ genderPreference })
  }

  return (
    <>
      {!roleAttributes || !certifications ? (
        <div
          style={{
            height: 300,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <div style={{ margin: theme.space.sm }}>
          <RoleStepComponent
            role={role}
            company={company}
            setRole={setRole}
            previousRoleId={previousRoleId}
            duplicatePreviousRoleId={duplicatePreviousRoleId}
            setPreviousRoleId={setPreviousRoleId}
            attributes={roleAttributes}
            certifications={certifications}
            toggleAttribute={toggleAttribute}
            isAttributeSelected={isAttributeSelected}
            toggleCertification={toggleCertification}
            isCertificationSelected={isCertificationSelected}
            forkliftCertificationInteracted={forkliftCertificationInteracted}
            setForkliftCertificationInteracted={
              setForkliftCertificationInteracted
            }
            isModalOpen={isModalOpen}
            closeUploadVideoModal={closeUploadVideoModal}
            openUploadVideoModal={openUploadVideoModal}
            onUploadSuccess={onUploadSuccess}
            setSelectedVideoIds={setSelectedVideoIds}
            selectedVideoIds={selectedVideoIds}
            requiredForAllTrainingVideos={requiredForAllTrainingVideos}
            trainingVideos={trainingVideos}
            nextStep={() => onContinue(true)}
            isEdit={isEdit}
            isGMPTrainingSelected={isGMPTrainingSelected}
            setIsGMPTrainingSelected={setIsGMPTrainingSelected}
            minHourlyPayRate={minPayRate}
            genderPreference={genderPreference}
            setGenderPreference={handleGenderSelection}
            filterLocationId={filterLocationId}
            currentRoleId={currentRoleId}
          />
        </div>
      )}
      <Divider />
      <Row justifyBetween alignCenter style={{ margin: theme.space.sm }}>
        <Button variant={ButtonVariant.OUTLINED} onClick={onPrevious}>
          {step === 0 ? 'Cancel' : 'Previous'}
        </Button>

        <Button
          variant={ButtonVariant.FILLED}
          onClick={() => onContinue()}
          loading={submitLoading}
          disabled={!valid}
        >
          {atEnd ? (isEdit ? 'Save' : 'Create') : 'Continue'}
        </Button>
      </Row>
    </>
  )
}
