import { isToday } from '@internationalized/date'
import { AriaCalendarCellProps } from '@react-aria/calendar'
import { useCalendarCell } from '@react-aria/calendar'
import { CalendarState, RangeCalendarState } from '@react-stately/calendar'
import { theme } from '@traba/theme'
import { useRef } from 'react'
import styled from 'styled-components'
import { Text, TextProps } from '../Text'

const StyledText = styled(Text)<
  TextProps & { isToday: boolean; isInvalid: boolean; isDisabled: boolean }
>`
  cursor: ${(props) =>
    props.isInvalid || props.isDisabled ? 'not-allowed' : 'pointer'};
  ${({ isToday }) => isToday && `font-weight: bold;`};
`

const CalendarCellContainer = styled.div<{
  isInvalid: boolean
  isSelected: boolean
  isDisabled: boolean
  isUnavailable: boolean
}>`
  width: 28px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.isSelected ? theme.colors.Violet : 'transparent'};
  ${StyledText} {
    color: ${(props) =>
      props.isInvalid || props.isDisabled
        ? theme.colors.Grey40
        : props.isSelected
          ? theme.colors.White
          : theme.colors.Grey90};
  }
  cursor: ${(props) =>
    props.isInvalid || props.isDisabled ? 'not-allowed' : 'pointer'};
  &:hover {
    background-color: ${(props) =>
      props.isInvalid || props.isDisabled
        ? theme.colors.red
        : theme.colors.Violet50};
    ${StyledText} {
      color: ${theme.colors.White};
    }
  }
`
interface CalendarCellProps extends AriaCalendarCellProps {
  state: CalendarState | RangeCalendarState
  timezone: string
}

export function CalendarCell({ state, date, timezone }: CalendarCellProps) {
  const ref = useRef(null)
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    isUnavailable,
    formattedDate,
    isInvalid,
  } = useCalendarCell({ date }, state, ref)

  const isDateToday = isToday(date, timezone)

  return (
    <td {...cellProps} style={{ textAlign: 'center' }}>
      <CalendarCellContainer
        {...buttonProps}
        ref={ref}
        hidden={isOutsideVisibleRange}
        isInvalid={isInvalid}
        isSelected={isSelected}
        isDisabled={isDisabled}
        isUnavailable={isUnavailable}
      >
        <StyledText
          variant="body1"
          isToday={isDateToday}
          isInvalid={isInvalid}
          isDisabled={isDisabled}
        >
          {formattedDate}
        </StyledText>
      </CalendarCellContainer>
    </td>
  )
}
