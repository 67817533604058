import { theme } from 'src/libs/theme'
import { IMenuItem, SelectDropdown } from '../base/Select/Select'

interface FilterDropdownProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label: string
  selected: string | undefined
  items: IMenuItem[]
  onSelectItem: (value: string) => void
  overrideMenuItems?: boolean
}

export function FilterDropdown(props: FilterDropdownProps) {
  const {
    label,
    selected,
    items,
    onSelectItem,
    style,
    overrideMenuItems,
    ...rest
  } = props
  return (
    <SelectDropdown
      {...rest}
      label={label}
      menuItems={
        overrideMenuItems ? items : [...[{ value: '', label: 'All' }], ...items]
      }
      handleSelect={onSelectItem}
      style={{
        width: 190,
        marginBottom: theme.space.xs,
        ...style,
      }}
      value={selected}
    />
  )
}
