import { ShiftPayType, WorkerShift } from '@traba/types'
import { getShiftTimeString } from '@traba/utils'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row, Text } from 'src/components/base'
import useMobile from 'src/hooks/useMobile'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { theme } from 'src/libs/theme'
import { getMoneyString, getPayUnitString } from 'src/utils/moneyUtils'

import { ClockCode } from '../base/ClockCode'
import { RoleBadges } from '../RoleBadges'
import { ShiftBadges } from '../ShiftBadges'
import { ShiftStatusBadge } from '../ShiftBadges/ShiftStatusBadge'
import EstimatedCost from './EstimatedCost'
import { ShiftTileUIProps } from './ShiftTile.hooks'
import * as S from './ShiftTile.styles'
import { isInProgressOrStartsInThreshold } from './ShiftTileTimebarUtils'
import { getShiftProps } from './ShiftTileUtils'

export const MobileShiftTile: React.FC<
  ShiftTileUIProps &
    (
      | { shift: ShiftAndAddress; workerShift?: never }
      | { shift?: never; workerShift: WorkerShift }
    )
> = memo((props) => {
  const { shift, workerShift, ...rest } = props

  const {
    isPastShift,
    path,
    userCanViewWages,
    dateString,
    minEstimatedCost,
    maxEstimatedCost,
    slotsRemaining,
    showClockCodes,
    showTimeBar,
    showRequiredAttributes = true,
    showTotals = true,
    showSignedUp = true,
  } = rest

  const {
    shiftId,
    shiftRole,
    startTime,
    endTime,
    slotsRequested,
    slotsFilled,
    status,
    jobStatus,
    confirmationCode,
    clockOutCode,
    requiredCertifications,
    requiredAttributes,
    timezone,
    payType,
    payRate,
    numberOfUnits,
    locationName,
    shortLocation,
  } = getShiftProps(shift ?? workerShift)

  const { isReactNativeApp } = useMobile()
  const urlPath =
    path === 'calendar'
      ? `/${path}/${shiftId}`
      : `/timesheet/totals?view=daily&shiftId=${shiftId}&day=${endTime.toISOString()}`

  return (
    <S.ShiftTileContainer isClickable>
      {showTimeBar && isInProgressOrStartsInThreshold(startTime, endTime) && (
        <ShiftStatusBadge
          shiftStartTime={startTime}
          shiftEndTime={endTime}
          shiftIsCanceled={status === 'CANCELED'}
          style={{
            borderRadius: theme.space.xxxs,
          }}
        />
      )}
      <Link
        to={urlPath}
        style={{
          textDecoration: 'none',
          padding: theme.space.xs,
        }}
      >
        <Row justifyBetween mb={theme.space.xxs}>
          <Col>
            {/*
             * If status is canceled, it means we will show a 'CANCELED'
             * <ShiftBadge> and therefore need some extra spacing.
             */}
            <Text variant="h5" mb={status === 'CANCELED' ? theme.space.xxs : 0}>
              {shiftRole}
            </Text>
            {/*
             * TODO(polyphilz): This needs a refactor. <ShiftBadges> uses
             * <RoleBadges> also but it isn't showing up there for some reason.
             * For now, we only want to show <ShiftBadges> in the React Native
             * view if the shift is canceled (there's no point in showing a
             * bunch of completed status badges).
             */}
            {(!isReactNativeApp ||
              (isReactNativeApp && status === 'CANCELED')) && (
              <ShiftBadges
                status={status}
                jobStatus={jobStatus}
                requiredCertifications={requiredCertifications}
              />
            )}
            {requiredAttributes && showRequiredAttributes && (
              <RoleBadges requiredAttributes={requiredAttributes} />
            )}
          </Col>
          {showTotals &&
          isPastShift &&
          userCanViewWages &&
          shift &&
          minEstimatedCost !== undefined &&
          maxEstimatedCost !== undefined ? (
            <EstimatedCost
              {...rest}
              {...shift}
              className="xs-3"
              style={{ justifyContent: 'flex-end' }}
              ml="auto"
              minEstimatedCost={minEstimatedCost}
              maxEstimatedCost={maxEstimatedCost}
            />
          ) : (
            (showSignedUp ??
            (slotsRemaining !== undefined && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                }}
              >
                <Text
                  variant="caption"
                  style={{
                    color: theme.ActiveBackColor,
                  }}
                >
                  SIGNED UP
                </Text>
                <div
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                  }}
                >
                  {/*
                   * TODO(polyphilz): This number is incorrect and doesn't take
                   * overbooks into account.
                   * */}
                  <Text variant="h5" style={{ color: theme.ActiveBackColor }}>
                    {`${slotsRequested - slotsRemaining} / ${slotsRequested} `}
                  </Text>
                </div>
              </div>
            )))
          )}
        </Row>
        {showClockCodes &&
          shift &&
          confirmationCode !== undefined &&
          clockOutCode !== undefined && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 12,
                width: 220,
              }}
            >
              <S.ShiftTileBodyText variant="body2">
                <Text variant="caption">CLOCK IN </Text>
                <ClockCode
                  code={confirmationCode}
                  tileHeight={24}
                  tileWidth={20}
                  fontSize={12}
                />
              </S.ShiftTileBodyText>
              <S.ShiftTileBodyText variant="body2">
                <Text variant="caption">CLOCK OUT </Text>
                <ClockCode
                  code={clockOutCode}
                  tileHeight={24}
                  tileWidth={20}
                  fontSize={12}
                />
              </S.ShiftTileBodyText>
            </div>
          )}
        {isReactNativeApp ? (
          <>
            {isPastShift && (
              <Row wrap justifyBetween>
                <S.ShiftTileBodyText variant="body2">
                  {dateString}
                </S.ShiftTileBodyText>
                {userCanViewWages && (
                  <S.ShiftTileBodyText variant="body2">
                    <Row>
                      {getMoneyString(payRate)}
                      {getPayUnitString(payType)}
                    </Row>
                    {payType === ShiftPayType.UNIT && (
                      <Row>
                        <S.ShiftTileBodyText variant="body2">
                          {numberOfUnits} units
                        </S.ShiftTileBodyText>
                      </Row>
                    )}
                  </S.ShiftTileBodyText>
                )}
              </Row>
            )}
            <Row wrap justifyBetween>
              <S.ShiftTileBodyText variant="body2">
                {getShiftTimeString(startTime, endTime, timezone)}
              </S.ShiftTileBodyText>
              <Row>
                <S.ShiftTileBodyText variant="body2">
                  {`${slotsFilled} ${slotsFilled === 1 ? 'worker' : 'workers'}`}
                </S.ShiftTileBodyText>
              </Row>
            </Row>
          </>
        ) : (
          <>
            <Row wrap>
              <S.ShiftTileBodyText variant="body2">
                {getShiftTimeString(startTime, endTime, timezone)}
              </S.ShiftTileBodyText>
            </Row>
            {isPastShift && (
              <Row wrap>
                <S.ShiftTileBodyText variant="body2">
                  {dateString}
                </S.ShiftTileBodyText>
              </Row>
            )}
          </>
        )}
        <Row wrap justifyBetween>
          <S.ShiftTileBodyText variant="body2">
            {locationName || shortLocation}
          </S.ShiftTileBodyText>
          {!isReactNativeApp && userCanViewWages && (
            <S.ShiftTileBodyText variant="body2">
              <Row>
                {getMoneyString(payRate)}
                {getPayUnitString(payType)}
              </Row>
              {payType === ShiftPayType.UNIT && (
                <Row>
                  <S.ShiftTileBodyText variant="body2">
                    {numberOfUnits} units
                  </S.ShiftTileBodyText>
                </Row>
              )}
            </S.ShiftTileBodyText>
          )}
        </Row>
      </Link>
    </S.ShiftTileContainer>
  )
})
