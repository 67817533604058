export type ChatSummariesResponse = {
  summaries: ChatSummary[]
}
export type ChatSummary = {
  name: string
  mostRecentMessageContent?: string
  mostRecentMessageAttachmentUrl?: string
  mostRecentMessageSentAt?: Date
  readStatus: ChatReadStatus
  profilePictureUrl: string
  summaryId: string
  recipient: {
    workerId: string
    regionId: string
  }
  archivalStatus?: ChatArchivalStatus
  companyEndedChatStatus?: ChatEndedStatus
}

export enum ChatArchivalStatus {
  NONE = 'NONE',
  ARCHIVED = 'ARCHIVED',
}

export enum ChatEndedStatus {
  NONE = 'NONE',
  ENDED = 'ENDED',
}

export type UpdateChatRequest = {
  archivalStatus?: ChatArchivalStatus
  companyEndedChatStatus?: ChatEndedStatus
}

export enum ChatReadStatus {
  READ = 'READ',
  UNREAD = 'UNREAD',
}

export type ChatSuggestionsResponse = {
  suggestions: ChatSuggestion[]
}

export type ChatSuggestion = {
  name: string
  photoUrl?: string
  workerId?: string
}

export type ChatMessageContent = {
  messageContent?: string
  attachmentUrl?: string
}

export type UpdateChatMessageRequest = {
  deletedStatus?: ChatMessageDeletedStatus
}

export type ChatMessagesResponse = {
  messages: ChatMessage[]
}

export type ChatMessage = {
  chatSummaryId: string
  companyUserSenderId?: string
  workerSenderId?: string
  sentAt: Date
  messageId: string
  invitationId?: string
  deletedStatus?: ChatMessageDeletedStatus
  messageContent?: string
  attachmentUrl?: string
  companyUserSenderName?: string
  workerSenderProfileUrl?: string
  workerSenderName?: string
  deliveryStatus: ChatMessageDeliveryStatus
}

export enum ChatMessageDeliveryStatus {
  UNDELIVERED = 'UNDELIVERED',
  MODERATED_PAY_QUESTIONS = 'MODERATED_PAY_QUESTIONS',
  MODERATED_EXPLICIT = 'MODERATED_EXPLICIT',
  DELIVERED = 'DELIVERED',
}

export enum ChatMessageDeletedStatus {
  NONE = 'NONE',
  DELETED = 'DELETED',
}
