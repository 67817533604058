import { patchUserCommunicationPermissions } from 'src/hooks/useUserCommunicationPermissions'
import { UserProfile, UserRole } from 'src/types'

import { NotifPreferencesOption } from '../PersonalProfileNotificationsCard'

export const emailShiftOptions: NotifPreferencesOption[] = [
  {
    name: 'All shifts',
    isSelected: (communicationPreferences) => {
      return (
        (communicationPreferences?.shiftCodesEmailEnabled &&
          !communicationPreferences?.onlyReceiveSupervisedShiftsEmail) ??
        false
      )
    },
    onClick: async (
      user,
      patchUser,
      communicationPreferences,
      _onClickContext,
    ) => {
      if (user?.role === UserRole.Admin) {
        patchUser({
          communicationPreferences: {
            ...communicationPreferences,
            onlyReceiveSupervisedShiftsEmail: false,
            shiftCodesEmailEnabled: true,
          },
        })
        await patchUserCommunicationPermissions(user.uid, {
          communicationPermissions: {
            // Currently used for both sms and email
            receiveAllShiftCodesSms: true,
          },
        })
      } else {
        patchUser({
          communicationPreferences: {
            ...communicationPreferences,
            onlyReceiveSupervisedShiftsEmail: false,
            shiftCodesEmailEnabled: true,
          },
        })
      }
    },
    shouldShowOption: (user: UserProfile) => {
      return (
        user?.communicationPermissions?.receiveAllShiftCodesSms ||
        user?.role === UserRole.Admin
      )
    },
  },

  {
    name: 'Shifts that I’m supervising',
    isSelected: (communicationPreferences) =>
      (communicationPreferences?.shiftCodesEmailEnabled &&
        communicationPreferences?.onlyReceiveSupervisedShiftsEmail) ??
      false,
    onClick: (user, patchUser, communicationPreferences, _onClickContext) => {
      patchUser({
        communicationPreferences: {
          ...communicationPreferences,
          onlyReceiveSupervisedShiftsEmail: true,
          shiftCodesEmailEnabled: true,
        },
      })
    },
    shouldShowOption: (_user) => {
      return true
    },
  },

  {
    name: 'None',
    isSelected: (communicationPreferences) =>
      !communicationPreferences?.shiftCodesEmailEnabled,
    onClick: async (_user, patchUser, communicationPreferences) => {
      patchUser({
        communicationPreferences: {
          ...communicationPreferences,
          shiftCodesEmailEnabled: false,
        },
      })
    },
    shouldShowOption: (_user) => {
      return true
    },
  },
]
