import { Card, LoadingSpinner } from '@traba/react-components'
import { HotSettings, UserRole } from '@traba/types'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { MutateOptions } from 'react-query'
import { Modal, Row, Text } from 'src/components/base'
import { useHotSettings } from 'src/hooks/useSystem'
import { useUser } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { CommunicationPreferences, UserProfile } from 'src/types'

import { SmsConsentModalForSettings } from '../Modals/SmsConsentModal/SmsConsentModalForSettings'
import { RadioButton } from '../RadioButton'
import { emailInvoiceOptions } from './NotificationPreferences/EmailInvoiceOptions'
import { emailShiftOptions } from './NotificationPreferences/EmailShiftOptions'
import { smsShiftOptions } from './NotificationPreferences/SmsShiftOptions'

interface Props {
  communicationPreferences: CommunicationPreferences | undefined
}

export type NotifPreferencesCollection = {
  header: string
  options: NotifPreferencesOption[]
  shouldShowSection?: (
    user: UserProfile | undefined,
    hotSettings: HotSettings | undefined,
  ) => boolean
}

export type NotifPreferencesOption = {
  name: string
  onClick: (
    user: UserProfile | undefined,
    patchUser: (
      variables: Partial<UserProfile>,
      options?:
        | MutateOptions<
            UserProfile,
            AxiosError<any>,
            Partial<UserProfile>,
            UserProfile
          >
        | undefined,
    ) => void,
    communicationPreferences: CommunicationPreferences | undefined,
    onClickContext?: {
      setShowSmsConsentModal: React.Dispatch<React.SetStateAction<boolean>>
    },
  ) => void

  isSelected: (
    communicationPreferences: CommunicationPreferences | undefined,
  ) => boolean
  shouldShowOption?: (user: UserProfile) => boolean
}

const notifPreferenceCollections: NotifPreferencesCollection[] = [
  {
    header: 'Receive text messages with clock in/out codes for:',
    options: smsShiftOptions,
  },
  {
    header: 'Receive emails with clock in/out codes for:',
    options: emailShiftOptions,
    shouldShowSection(_user, hotSettings) {
      return hotSettings?.enableEmailPreferencesForClockInOut === true
    },
  },
  {
    header: 'Receive invoicing related emails:',
    options: emailInvoiceOptions,
    shouldShowSection(user, _hotSettings) {
      return user?.role === UserRole.Admin || user?.role === UserRole.Accounting
    },
  },
]

const NotifPreferencesOptionComponent = ({
  communicationPreferences,
  option,
  setNotifPrefComponentLoading,
  setShowSmsConsentModal,
}: {
  communicationPreferences: CommunicationPreferences | undefined
  option: NotifPreferencesOption
  setNotifPrefComponentLoading: React.Dispatch<React.SetStateAction<boolean>>
  setShowSmsConsentModal: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { patchUser, user, refetch } = useUser()

  const onClickContext = {
    setShowSmsConsentModal,
  }

  const onClick = async () => {
    setNotifPrefComponentLoading(true)
    await option.onClick(
      user,
      patchUser,
      communicationPreferences,
      onClickContext,
    )
    await refetch()
    setNotifPrefComponentLoading(false)
  }

  const isSelected = option.isSelected(communicationPreferences)

  return (
    <Row alignCenter mt={theme.space.xs} onClick={onClick}>
      <RadioButton size={22} selected={isSelected} />
      <Text ml={theme.space.xxs}>{option.name}</Text>
    </Row>
  )
}

const NotifPreferencesSectionComponent = ({
  notificationPrefCollection,
  communicationPreferences,
  setNotifPrefComponentLoading,
  setShowSmsConsentModal,
}: {
  notificationPrefCollection: NotifPreferencesCollection
  communicationPreferences: CommunicationPreferences | undefined
  setNotifPrefComponentLoading: React.Dispatch<React.SetStateAction<boolean>>
  setShowSmsConsentModal: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { user } = useUser()
  const { hotSettings } = useHotSettings()

  const shouldShowSection =
    notificationPrefCollection.shouldShowSection?.(user, hotSettings) ?? true
  return shouldShowSection ? (
    <div style={{ marginBottom: theme.space.sm }}>
      <Text variant="h6">{notificationPrefCollection.header}</Text>
      {notificationPrefCollection.options.map((option, i) => (
        <NotifPreferencesOptionComponent
          key={`notif-preferences-option-${i}`}
          option={option}
          communicationPreferences={communicationPreferences}
          setNotifPrefComponentLoading={setNotifPrefComponentLoading}
          setShowSmsConsentModal={setShowSmsConsentModal}
        />
      ))}
    </div>
  ) : null
}

export const PersonalProfileNotificationsCard = ({
  communicationPreferences,
}: Props) => {
  const { patchUser } = useUser()
  const { hotSettings } = useHotSettings()
  const [showSmsConsentModal, setShowSmsConsentModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  if (hotSettings?.enableNotificationPreferencesV2) {
    return null
  }

  const notifSettings = notifPreferenceCollections.map(
    (notifPreferenceCollection, i) => (
      <NotifPreferencesSectionComponent
        key={`notif-preferences-section-${i}`}
        communicationPreferences={communicationPreferences}
        notificationPrefCollection={notifPreferenceCollection}
        setNotifPrefComponentLoading={setIsLoading}
        setShowSmsConsentModal={setShowSmsConsentModal}
      />
    ),
  )

  return (
    <Card style={{ marginTop: theme.space.sm }}>
      <Row>
        <Text mb={theme.space.xs} variant="h5" mr={theme.space.xxs}>
          Notification Settings
        </Text>
        {isLoading && (
          <LoadingSpinner style={{ height: '24px', width: '24px' }} />
        )}
      </Row>
      {notifSettings}
      <Modal
        isOpen={showSmsConsentModal}
        handleClose={() => setShowSmsConsentModal(false)}
      >
        <SmsConsentModalForSettings
          setShowSmsConsentModal={setShowSmsConsentModal}
          onCancel={() => {
            patchUser({
              communicationPreferences: {
                ...communicationPreferences,
                shiftCodesSmsEnabled: false,
              },
            })
            setShowSmsConsentModal(false)
          }}
        />
      </Modal>
    </Card>
  )
}
