import { Skeleton } from '@mui/material'
import { CardTile } from '@traba/react-components'
import { DotMenu } from '@traba/react-components'
import { ParentInvoiceGroup } from '@traba/types'
import { theme } from 'src/libs/theme'

import { SvgIcon, Text } from '../base'

type InvoiceGroupProps = {
  group: ParentInvoiceGroup
  userCanEdit?: boolean
  edit?: (group: ParentInvoiceGroup) => void
  archive?: (group: ParentInvoiceGroup) => void
}

export const InvoiceGroup = (props: InvoiceGroupProps) => {
  const { group, userCanEdit, edit, archive } = props

  const groupActionMenuItems = []

  if (edit) {
    groupActionMenuItems.push({
      title: 'Edit invoice group',
      onClick: () => {
        edit(group)
        window.analytics.track(`User Clicked Edit Invoice Group`, {
          invoiceGroup: group,
        })
      },
    })
  }

  if (archive) {
    groupActionMenuItems.push({
      title: 'Archive invoice group',
      onClick: () => {
        archive(group)
        window.analytics.track(`User Clicked Archive Invoice Group`, {
          invoiceGroup: group,
        })
      },
      color: theme.colors.Red60,
    })
  }

  if (!group) {
    return <Skeleton />
  }

  const actionable = userCanEdit && groupActionMenuItems.length > 0

  return (
    <>
      <div
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <div
          style={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <CardTile size="56px">
            <SvgIcon name="dollar" />
          </CardTile>
          <span style={{ marginLeft: '25px' }}>
            <Text variant="h5">{group.name}</Text>
          </span>
        </div>
        {actionable && (
          <div style={{ paddingLeft: '8px' }}>
            <DotMenu
              type="invoice-group"
              dotMenuKey={group.id}
              menuItems={groupActionMenuItems}
            />
          </div>
        )}
      </div>

      {group.description && (
        <div style={{ marginTop: theme.space.xs }}>
          <Text variant="caption">DESCRIPTION</Text>
          <Text>{group.description}</Text>
        </div>
      )}

      <div style={{ marginTop: theme.space.xs }}>
        <Text variant="caption">TYPE</Text>
        <Text>
          {group.recurring
            ? 'Reusable invoice group'
            : 'One-time purchase order'}
        </Text>
      </div>
    </>
  )
}
