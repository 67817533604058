import { ScheduleStrip, SvgIcon } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftRequestParentWithShiftRequest } from '@traba/types'
import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { Col, Divider, Row, Text } from 'src/components'
import { RadioButton } from 'src/components/RadioButton'
import styled from 'styled-components'

type SelectScheduleCardProps = {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  onSelect: Dispatch<SetStateAction<string | null>>
  selected: boolean
}

const HoverCol = styled(Col)`
  border: 1.5px solid ${theme.colors.Grey30};
  border-radius: ${theme.space.xxs}px;
  &:hover {
    border-color: ${theme.colors.Grey50};
    cursor: pointer;
  }
`

export function SelectScheduleCard(props: SelectScheduleCardProps) {
  const { shiftRequestParent, selected, onSelect } = props
  const { shortLocation, schedules } = shiftRequestParent.shiftRequests[0]

  const dateToString = useCallback(
    (date: Date) =>
      date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }),
    [],
  )

  return (
    <Row
      pt={theme.space.xs}
      fullWidth
      gap={theme.space.xxs}
      onClick={() => onSelect(shiftRequestParent.shiftRequestParentId)}
    >
      <RadioButton size={theme.space.sm} selected={selected} />
      <HoverCol width={'90%'}>
        <Text
          variant="h6"
          style={{
            paddingTop: theme.space.xxs,
            paddingBottom: theme.space.xxxs,
            paddingLeft: theme.space.xs,
          }}
        >
          {shiftRequestParent.title}
        </Text>
        <Divider />
        <Row
          alignCenter
          py={theme.space.xxs}
          px={theme.space.xsmed}
          gap={theme.space.ms}
        >
          <SvgIcon
            name="location"
            size={theme.space.xs}
            color={theme.colors.Grey50}
          />
          <Text variant="body3">{shortLocation}</Text>
        </Row>
        <Divider />
        <Row alignCenter py={theme.space.xxs} px={theme.space.xsmed}>
          <ScheduleStrip schedules={schedules} timeToString={dateToString} />
        </Row>
        <Divider />
        {/* Role Rows */}
        {shiftRequestParent.shiftRequests.map((shiftRequest, index) => (
          <React.Fragment key={shiftRequest.shiftRequestId}>
            <Row
              alignCenter
              py={theme.space.xxs}
              px={theme.space.xsmed}
              gap={theme.space.ms}
            >
              <SvgIcon
                name="twoUser"
                size={theme.space.xs}
                color={theme.colors.Grey50}
              />
              <Text variant="body3">{`${shiftRequest.slotsRequested} ${shiftRequest.shiftRole} @ $${shiftRequest.payRate}/hr`}</Text>
            </Row>
            {index < shiftRequestParent.shiftRequests.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </HoverCol>
    </Row>
  )
}
