import styled from 'styled-components'

const AuthProviderImg = styled.img`
  width: 20px;
  height: 20px;
`

export default function GoogleIcon() {
  return <AuthProviderImg src="/img/google-icon.png" alt="Google Icon" />
}

export function AppleIcon() {
  return <AuthProviderImg src="/img/apple-icon.png" alt="Apple Icon" />
}

export function OktaIcon() {
  return <AuthProviderImg src="/img/okta-icon.png" alt="Okta Icon" />
}
