import { theme } from 'src/libs/theme'
import styled from 'styled-components'

type VerticalLineProps = {
  color?: string
  height?: string | number
}

const VerticalLine = styled.div<VerticalLineProps>`
  border-left: 1px solid ${(props) => props.color || theme.colors.Grey20};
  height: ${(props) => props.height || '32px'};
  width: 0;
`

export default VerticalLine
