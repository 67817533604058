import { GroupedLocationSingleSearchSelector } from '@traba/react-components'
import { useMemo } from 'react'
import { useValidRegionsMap } from 'src/hooks/useLocationRegions'
import { useLocations } from 'src/hooks/useLocations'
import { useSelectedRegionalFilterLocations } from 'src/hooks/useRegionalFilter'

interface Props {
  selectedLocationId?: string
  // If filterLocationId is provided, the options will be limited to the company-wide option
  // and the location with the provided filterLocationId
  filterLocationId?: string
  disabled?: boolean
  onChange?: (locationId: string | undefined) => void
  errorMessage?: string
  onBlur?: () => void
  placeholder?: string
  label?: string
  hideCompanyWideOption?: boolean
  style?: React.CSSProperties
  selectStyle?: React.CSSProperties
}

export function LocationSingleSearchSelector({
  selectedLocationId,
  filterLocationId,
  disabled,
  onChange,
  errorMessage,
  onBlur,
  placeholder,
  label,
  hideCompanyWideOption,
  style,
  selectStyle,
}: Props) {
  const { isLoading: isLoadingLocations, getLocationById } = useLocations()
  const {
    isLoading: isRegionalFilterLoading,
    activeRegionIdsToLocationsMap,
    selectedActiveLocationIds,
  } = useSelectedRegionalFilterLocations()
  const { isLoading: isRegionsLoading, regionMap } = useValidRegionsMap()

  const regionIdsToLocationsMap = useMemo(() => {
    const selectedLocation =
      selectedLocationId && getLocationById(selectedLocationId)
    const filterLocation = filterLocationId && getLocationById(filterLocationId)

    let regionToLocationsMap = activeRegionIdsToLocationsMap

    // manually add in the selected location such that it appears in the options even if it
    // was not selected in the regional filter b/c we still want to display it
    if (
      selectedLocation &&
      !selectedActiveLocationIds.has(selectedLocationId)
    ) {
      regionToLocationsMap = {
        ...activeRegionIdsToLocationsMap,
        [selectedLocation.regionId]: [selectedLocation],
      }
    }

    // manually add in the "filter location" such that it appears in the options even if it
    // was not selected in the regional filter b/c we still want to display it
    if (filterLocation && !selectedActiveLocationIds.has(filterLocationId)) {
      regionToLocationsMap = {
        ...regionToLocationsMap,
        [filterLocation.regionId]: [filterLocation],
      }
    }

    return regionToLocationsMap
  }, [
    selectedLocationId,
    filterLocationId,
    selectedActiveLocationIds,
    activeRegionIdsToLocationsMap,
    getLocationById,
  ])

  return (
    <GroupedLocationSingleSearchSelector
      selectedLocationId={selectedLocationId}
      disabled={disabled}
      onChange={onChange}
      errorMessage={errorMessage}
      onBlur={onBlur}
      regionMap={regionMap}
      regionToLocationsMap={regionIdsToLocationsMap}
      isLoading={
        isLoadingLocations || isRegionalFilterLoading || isRegionsLoading
      }
      filterLocationId={filterLocationId}
      companyWideOptionSecondaryLabel="Default Setting. The role can be used in any location."
      placeholder={placeholder}
      label={label}
      hideCompanyWideOption={hideCompanyWideOption}
      style={style}
      selectStyle={selectStyle}
    />
  )
}
