import { DEFAULT_TIMEZONE } from '@traba/consts'
import { ShiftPayType } from '@traba/types'
import { InlineBanner, Text } from 'src/components'
import { EditableContentCard } from 'src/components/EditableContentCard/EditableContentCard'
import { useRoleAttributes } from 'src/hooks/useAttributes'
import { useCertifications } from 'src/hooks/useCertifications'
import { useCompany } from 'src/hooks/useCompany'
import { useCompanyWorkersByIds } from 'src/hooks/useCompanyWorkers'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { useLocations } from 'src/hooks/useLocations'
import { useMembers } from 'src/hooks/useMembers'
import { useRoles } from 'src/hooks/useRoles'
import { useTrainingVideos } from 'src/hooks/useTrainingVideos'
import { theme } from 'src/libs/theme'
import { BookShiftsProps } from '../BookShiftsScreen'
import {
  getContactCardEntries,
  getInvoiceGroupEntries,
  getLocationCardEntries,
  getRoleCardEntries,
  getScheduleCardEntries,
  getWorkersCardEntries,
} from '../utils'

export function BookShiftsConfirmContent(props: BookShiftsProps) {
  const {
    shiftRequest,
    onNavigate,
    isEdit,
    editsResult,
    userHasLimitedShiftManagementAbility,
    selectedSingleShiftDates,
    initialShift,
  } = props

  const workerIdsByBatch = shiftRequest.shiftInvitations?.map(
    (shiftInvitation) =>
      shiftInvitation.workers.map((worker) => worker.workerId),
  )
  const workerIds =
    workerIdsByBatch && ([] as string[]).concat(...workerIdsByBatch)
  const { workersMap } = useCompanyWorkersByIds({ workerIds })

  const { roles } = useRoles()
  const { roleAttributes } = useRoleAttributes()
  const { certifications } = useCertifications()
  const { activeLocations } = useLocations()
  const { members } = useMembers()
  const { activeInvoiceGroups: invoiceGroups } = useInvoiceGroups()
  const { company } = useCompany()
  const { trainingVideos } = useTrainingVideos()

  const role = roles.find((r) => r.roleId === shiftRequest.roleId)
  const location = activeLocations.find(
    (l) => l.locationId === shiftRequest.locationId,
  )
  const parkingLocation = activeLocations.find(
    (l) => l.locationId === shiftRequest.parkingLocationId,
  )
  const contact = members.find((m) => m.uid === shiftRequest.supervisorId)

  const hasInvoiceGroup = !!shiftRequest.parentInvoiceGroupId
  const invoiceGroup = hasInvoiceGroup
    ? invoiceGroups.find(
        (group) => group.id === shiftRequest.parentInvoiceGroupId,
      )
    : undefined

  const baseIndex = isEdit ? 1 : 0

  const businessStartTime = initialShift?.businessStartTime

  return (
    <>
      {!isEdit && (
        <Text variant="h4" style={{ marginBottom: theme.space.xs }}>
          Confirm and book
        </Text>
      )}
      {isEdit && editsResult && (
        <InlineBanner
          style={{ marginBottom: theme.space.sm }}
          severity={editsResult.severity}
          text={editsResult.title}
          subTitle={editsResult.subTitle}
        />
      )}
      <div>
        <EditableContentCard
          sections={[
            {
              title: 'Role',
              onEdit: () => {
                onNavigate(baseIndex)
                window.analytics.track(`User Clicked Edit Role`, { isEdit })
              },
              entries: getRoleCardEntries(
                role,
                roleAttributes,
                certifications,
                company,
              ),
            },
            {
              title: 'Work site location',
              onEdit: () => {
                onNavigate(baseIndex)
                window.analytics.track(`User Clicked Edit Location`, { isEdit })
              },
              entries: getLocationCardEntries(location),
            },
            ...(parkingLocation
              ? [
                  {
                    title: 'Parking location',
                    onEdit: () => {
                      onNavigate(baseIndex)
                      window.analytics.track(
                        `User Clicked Edit Parking Location`,
                        {
                          isEdit,
                        },
                      )
                    },
                    entries: getLocationCardEntries(parkingLocation),
                  },
                ]
              : []),
            {
              title: 'Onsite contact',
              onEdit: () => {
                onNavigate(baseIndex)
                window.analytics.track(`User Clicked Edit Contact`, { isEdit })
              },
              entries: getContactCardEntries(contact),
            },
            ...(hasInvoiceGroup
              ? [
                  {
                    title: 'Invoice Group',
                    onEdit: () => {
                      onNavigate(baseIndex)
                      window.analytics.track(
                        `User Clicked Edit Invoice Group`,
                        {
                          isEdit,
                        },
                      )
                    },
                    entries: getInvoiceGroupEntries(invoiceGroup),
                  },
                ]
              : []),
          ]}
        />
        <EditableContentCard
          style={{ marginTop: theme.space.lg }}
          sections={[
            {
              title: `Workers${
                shiftRequest.payType === ShiftPayType.UNIT ? ' and Units' : ''
              }`,
              onEdit: () => {
                onNavigate(baseIndex + 1)
                window.analytics.track(`User Clicked Edit Workers`, { isEdit })
              },
              entries: getWorkersCardEntries({
                shiftRequest,
                workersMap,
                trainingVideos,
                userCanViewPay: !userHasLimitedShiftManagementAbility,
                company,
              }),
            },
          ]}
        />
        <EditableContentCard
          style={{ marginTop: theme.space.lg }}
          sections={[
            {
              title: 'Schedule',
              onEdit: () => {
                onNavigate(baseIndex + 2)
                window.analytics.track(`User Clicked Edit Schedule`, { isEdit })
              },
              entries: getScheduleCardEntries(
                shiftRequest,
                location?.timezone || DEFAULT_TIMEZONE,
                isEdit,
                selectedSingleShiftDates,
                businessStartTime,
              ),
            },
          ]}
        />
      </div>
    </>
  )
}
