import { subHours } from 'date-fns'
import { compact } from 'lodash'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'
import {
  useCompanyWorkersByIds,
  WorkerDetails,
} from 'src/hooks/useCompanyWorkers'
import useMobile from 'src/hooks/useMobile'
import { useReviews } from 'src/hooks/useReviews'

import { ReviewWorkerWizard } from './ReviewWorkerWizard'

const HOURS_IN_A_WEEK = 24 * 7
const FIVE_MINUTES_IN_MS = 1000 * 60 * 5

export const ReviewWorkerReminderPrompt = () => {
  const { isReactNativeApp } = useMobile()
  // get all worker reviews

  const { reviews, isLoading: isLoadingReviews } = useReviews({
    staleTime: FIVE_MINUTES_IN_MS,
  })
  const [neverReviewedWorkers, setNeverReviewedWorkers] = useState<
    WorkerDetails[]
  >([])
  const { workers: allWorkers, isLoading: isLoadingWorkers } =
    useCompanyWorkersByIds()

  const [lastReviewReminderDateString, setLastReviewReminderDateString] =
    useLocalStorage('lastReviewReminderDate', '')

  const onCloseWizard = () => {
    setLastReviewReminderDateString(new Date().toISOString())
    setShowReviewWorkerPrompt(false)
    window.analytics.track(`User skipped review worker reminder`)
  }

  const [showReviewWorkerPrompt, setShowReviewWorkerPrompt] = useState(false)

  useEffect(() => {
    if (!isLoadingReviews && reviews && allWorkers && !isLoadingWorkers) {
      // find workers who have worked in the last 6 weeks
      const todayMinus2Weeks = subHours(new Date(), HOURS_IN_A_WEEK * 2)
      const recentWorkers: WorkerDetails[] = allWorkers.filter((worker) => {
        if (worker.shiftHistory.mostRecentShift) {
          return (
            worker.shiftHistory.mostRecentShift.shiftInfo.endTime >
            todayMinus2Weeks
          )
        }
        return false
      })
      // get all workers who have never been reviewed
      let neverReviewed = recentWorkers.filter((worker) => {
        return !reviews?.some(
          (review) =>
            review.workerId === worker.worker.workerId ||
            review.workerId === worker.worker.uid,
        )
      })

      if (!neverReviewed.length) {
        return // all recent workers have been reviewed
      }

      // only take the first three workers to not overwhelm the user
      neverReviewed = neverReviewed.slice(0, 3)

      setNeverReviewedWorkers(neverReviewed)
      const todayMinus1Week = subHours(new Date(), HOURS_IN_A_WEEK)

      // MORE THAN A WEEK CASE
      const lastReviewReminderDate =
        lastReviewReminderDateString && new Date(lastReviewReminderDateString)
      const beenMoreThanAWeekSinceLastReminder =
        !lastReviewReminderDate || lastReviewReminderDate < todayMinus1Week

      // WITHIN THE WEEK UP UNTIL 24 HOURS CASE
      const remindedWithinLastWeek =
        lastReviewReminderDate && lastReviewReminderDate > todayMinus1Week
      const isTodayThursday = new Date().getDay() === 4
      const remindedInTheLast24Hours =
        lastReviewReminderDate &&
        lastReviewReminderDate > subHours(new Date(), 24)

      /**
       * If there are recent workers who have never been reviewed and
       * the last time we reminded the user to review workers was more than a week ago
       * OR we did remind the worker within the week, but it's Thursday
       * (we only want to remind the user once a week, on Thursdays, so this sets them on the same cadence)
       * then show the wizard.
       */
      const shouldShowWizard =
        neverReviewed.length &&
        (beenMoreThanAWeekSinceLastReminder ||
          (remindedWithinLastWeek &&
            isTodayThursday &&
            !remindedInTheLast24Hours))

      if (shouldShowWizard && !showReviewWorkerPrompt && !isReactNativeApp) {
        setShowReviewWorkerPrompt(true)
      }
    }
  }, [
    reviews,
    isLoadingReviews,
    isLoadingWorkers,
    lastReviewReminderDateString,
    showReviewWorkerPrompt,
    isReactNativeApp,
  ])

  return (
    showReviewWorkerPrompt && (
      <ReviewWorkerWizard
        open={showReviewWorkerPrompt}
        onClose={onCloseWizard}
        workerShiftsToReview={compact(
          neverReviewedWorkers?.map(
            (workerDetails) => workerDetails.shiftHistory.mostRecentShift,
          ) || [],
        )}
      />
    )
  )
}
