import { theme } from 'src/libs/theme'

import { Row, Text } from '../base'
import { StrikeThrough } from '../base/StrikeThrough'

export const FunnyMoney = ({
  amount,
  superSize,
  strikeThrough,
  style,
}: {
  amount: number | number[]
  superSize?: boolean
  strikeThrough?: boolean
  style?: React.CSSProperties
}) => {
  const amountArray = Array.isArray(amount) ? amount : [amount]
  const dedupedArray = amountArray.filter(
    (a, i, arr) => i < 1 || arr[i - 1] !== a,
  )

  const magicComponents = dedupedArray.map((value, index) => {
    const splitMoney = value.toFixed(2).split('.')
    return (
      <StrikeThrough key={index} strikeThrough={!!strikeThrough} style={style}>
        {index > 0 && <>{' - '}</>}
        <Row>
          <Text
            variant={superSize ? 'h6' : 'body3'}
            color={theme.colors.MidnightBlue}
          >
            $
          </Text>
          {superSize ? (
            <Text variant="h3">{splitMoney[0]}</Text>
          ) : (
            <Text variant="body1">{splitMoney[0]}</Text>
          )}

          <Text
            style={{ alignSelf: superSize ? 'flex-end' : 'center' }}
            variant={superSize ? 'h6' : 'body3'}
            color={theme.colors.MidnightBlue}
          >{`.${splitMoney[1]}`}</Text>
        </Row>
      </StrikeThrough>
    )
  })
  return <>{magicComponents}</>
}
