import {
  CancellationBusinessChargeType,
  CancellationSettings,
  CancellationSource,
  Shift,
} from '@traba/types'

import CancellationCost from '../Modals/CancelShiftModal/CancellationCost'
import { isCanceledByBusiness } from '../WorkersOnShiftTable/WorkersOnShiftTable.hooks'
import { CostSummaryProps } from '.'

const DEFAULT_CHARGE_HOURLY = 4 // Hrs
const DEFAULT_CHARGE_PERCENTAGE = 0.5 // 50%

const getChargeSummaryFromChargeType = (
  cancellationBusinessChargeType: CancellationBusinessChargeType,
) => {
  if (
    cancellationBusinessChargeType === CancellationBusinessChargeType.Percentage
  ) {
    return `${DEFAULT_CHARGE_PERCENTAGE * 100}%`
  } else {
    // Default to hourly charge
    return `${DEFAULT_CHARGE_HOURLY} HR`
  }
}

export const CancellationFeeCostSummary = (props: CostSummaryProps) => {
  const { cancellationSettings, cancellationSource } = props.shift as Shift & {
    cancellationSettings?: CancellationSettings
  } // shared-types is broken right now so can't update cancellationSettings on the shift, TO_DO @isabeltraba return to pull from the shift type once fixed

  const canceledWorkerShifts =
    (props.workerShifts &&
      props.workerShifts.filter((ws) => isCanceledByBusiness(ws))) ||
    []
  const totalWorkersAffected = canceledWorkerShifts.length
  const totalChargeAmount = props.totalCharge
    ? props.totalCharge.amount / 100
    : 0
  const shiftIsLateCanceled = !!(
    cancellationSource === CancellationSource.Business && totalChargeAmount > 0
  )

  return (
    <CancellationCost
      isPaidCancellation={!!shiftIsLateCanceled}
      totalCancellationCost={totalChargeAmount}
      singleWorkerCharge={totalChargeAmount / totalWorkersAffected}
      cancellationSettings={cancellationSettings}
      cancellationChargeSummary={getChargeSummaryFromChargeType(
        cancellationSettings?.cancellationBusinessChargeType ||
          CancellationBusinessChargeType.Hourly,
      )}
      totalWorkersAffected={totalWorkersAffected}
      largeHeader
    />
  )
}
