import { MODAL_SIZE, LoadingSpinner } from '@traba/react-components'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import { useHotSettings } from 'src/hooks/useSystem'
import { useUserPermissions } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { UserRolePermission } from 'src/types'

import { EmptyPlaceholderTile } from '..'
import { useBillingInfo } from '../../hooks/useBilling'
import { Button, Modal, Text } from '../base'
import { CreatePaymentModal } from '../Modals/CreatePaymentModal'
import { BillingContactsSection } from './BillingContactsSection'
import { BillingProfileUI } from './BillingProfile.ui'
import { InvoiceGroupsSection } from './InvoiceGroupsSection'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook

export const BillingSettings = () => {
  const userCanEditBilling = useUserPermissions([
    UserRolePermission.ManagePaymentSettings,
  ])
  const [showBillingDetailsModal, setShowBillingDetailsModal] = useState(false)
  const { billing, isLoading: isBillingDetailsLoading } = useBillingInfo()
  const { hotSettings } = useHotSettings()

  const renderPlaceholderContents = () => {
    const { paymentSettings: { phoneNumber = '', email = '' } = {} } =
      billing || {}

    return (
      <div style={{ maxWidth: '502px' }}>
        <EmptyPlaceholderTile
          title="Your billing details will show up here"
          subtitle="Billing details can be viewed/edited via Stripe."
          iconName="folder"
          content={
            userCanEditBilling ? (
              <Button
                style={{ margin: '24px 0 0' }}
                onClick={() => setShowBillingDetailsModal(true)}
              >
                Add Payment Method via Stripe
              </Button>
            ) : undefined
          }
        />
        <Modal
          size={MODAL_SIZE.MEDIUM}
          isOpen={showBillingDetailsModal}
          handleClose={() => setShowBillingDetailsModal(false)}
        >
          <CreatePaymentModal
            phoneNumber={phoneNumber}
            email={email}
            setShowModal={setShowBillingDetailsModal}
            stripeReturnPath="/account-settings"
          />
        </Modal>
      </div>
    )
  }

  const renderBillingDetails = () => {
    if (isBillingDetailsLoading) {
      return <LoadingSpinner />
    }
    if (billing && !isEmpty(billing.paymentMethods)) {
      return (
        <BillingProfileUI
          billing={billing}
          userCanEditBilling={userCanEditBilling}
        />
      )
    }
    return renderPlaceholderContents()
  }

  return (
    <>
      <div style={{ maxWidth: '502px', marginBottom: theme.space.xl }}>
        <Text style={{ marginBottom: theme.space.xxs }} variant="h5">
          Billing details
        </Text>
        {renderBillingDetails()}
      </div>
      {hotSettings?.enableBillingContact && (
        <BillingContactsSection canEditBillingContacts={userCanEditBilling} />
      )}
      <InvoiceGroupsSection userCanEdit={userCanEditBilling} />
    </>
  )
}
