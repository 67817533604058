import { SvgIconProps } from '@traba/types'

export default function ({
  size = 14,
  color,
  strokeWidth,
  ...props
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size ?? (size * 18) / 14}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.84593 6.337H16.1631M7.28499 3.67356H10.8362M3.73374 13.4395V11.6639M14.3875 13.4395V11.6639M1.07031 1.01013H17.0509M7.28499 9.00043H10.8362M1.95812 1.01013H16.1631V11.6639H1.95812V1.01013Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
