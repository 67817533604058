import {
  ShiftStatus,
  Shift,
  WorkerShift,
  WorkerShiftWithWorkerDetails,
} from '@traba/types'
import { AxiosResponse } from 'axios'
import _ from 'lodash'
import { useState } from 'react'
import { UseMutateAsyncFunction } from 'react-query'
import { useConnections } from 'src/hooks/useConnections'
import { BizToWorkerConnection } from 'src/hooks/useConnections'
import { useWorkersByIds } from 'src/hooks/useWorker'
import {
  useWorkerShiftMutations,
  useWorkerShifts,
} from 'src/hooks/workerShiftHooks'
import {
  BulkWorkerActionBasePayload,
  ClockInOutResult,
  ClockInWorkers,
  ClockOutWorkers,
  RejectWorkers,
} from 'src/hooks/workerShiftHooks'
import { anyToDate, sortByDate } from 'src/shared/utils/dateUtils'
import { Money, JobStatus } from 'src/types'

import { ReviewWorkerFormProps } from '../ReviewWorker/ReviewWorkerForm'

export type WorkersOnShiftTableStateProps = {
  shift: Shift
  activeWorkerShifts: WorkerShiftWithWorkerDetails[]
  workersOnBackup?: WorkerShiftWithWorkerDetails[]
  inactiveWorkerShifts?: WorkerShiftWithWorkerDetails[]
  isUpcoming?: boolean
  connections?: BizToWorkerConnection
  clockInWorkers: UseMutateAsyncFunction<
    ClockInOutResult,
    Error,
    ClockInWorkers
  >
  editClockInWorkers: UseMutateAsyncFunction<
    ClockInOutResult,
    Error,
    ClockInWorkers
  >
  clockOutWorkers: UseMutateAsyncFunction<
    ClockInOutResult,
    Error,
    ClockOutWorkers
  >
  abandonWorkers: UseMutateAsyncFunction<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    ClockOutWorkers
  >
  rejectWorkers: UseMutateAsyncFunction<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    RejectWorkers
  >
  noShowWorkers: UseMutateAsyncFunction<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    BulkWorkerActionBasePayload
  >
  bizCancelRemoveWorkers: UseMutateAsyncFunction<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    BulkWorkerActionBasePayload
  >
  refetchWorkerShifts: () => void
  isLoadingWorkerShifts: boolean
}

export type WorkersOnShiftTableAdditionalProps = {
  shift: Shift
  isReactNativeApp?: boolean
  onClickInviteWorker?: () => void
  editMode?: boolean
  onEditWorkerShift?: (
    newWorkerShift: Partial<WorkerShift> & {
      workerId: string
      shiftId: string
    },
  ) => void

  additionalWorkerActions?: JSX.Element
  useTotalTitle?: boolean
  totalTimeWorked?: number
  totalChargedCents?: Money
  isFromTimesheetDetails?: boolean
  hasEdits?: boolean
  hideHeader?: boolean
  maxRowsBeforeScroll?: number
  isEditSlotsEnabled?: boolean
  onClickEditWorkerSlots?: () => void
}

export type WorkersOnShiftTableProps = WorkersOnShiftTableStateProps &
  WorkersOnShiftTableAdditionalProps

export function isCanceledByBusiness(workerShift: WorkerShift) {
  return (
    workerShift.jobStatus === 'CANCELED' &&
    workerShift.cancellationSource === 'BUSINESS'
  )
}

type WorkersOnShiftProps = {
  workerShifts?: WorkerShiftWithWorkerDetails[]
  isUpcoming?: boolean
  isShiftCanceled?: boolean
  slotsRequested: number
}

const JOB_STATUS_ORDER = [
  JobStatus.Complete,
  JobStatus.InProgress,
  JobStatus.OnBreak,
  JobStatus.ToDo,
  JobStatus.Abandoned,
  JobStatus.Rejected,
  JobStatus.Appeased,
  JobStatus.NoShow,
  JobStatus.Canceled,
]

export const sortByJobStatus = (a: JobStatus, b: JobStatus) => {
  return JOB_STATUS_ORDER.indexOf(a) - JOB_STATUS_ORDER.indexOf(b)
}

function getActiveWorkerShifts(
  workerShifts?: WorkerShiftWithWorkerDetails[],
  isUpcoming?: boolean,
  isShiftCanceled?: boolean,
) {
  if (!workerShifts) {
    return []
  }

  // Show canceled workers on workers table if shift has been canceled by the business or it's rendered in a timesheet.
  const showCanceled = isShiftCanceled || !isUpcoming

  return (
    workerShifts?.filter(
      (ws) =>
        ws.jobStatus === JobStatus.InProgress ||
        ws.jobStatus === JobStatus.OnBreak ||
        ws.jobStatus === JobStatus.Complete ||
        (showCanceled && isCanceledByBusiness(ws)) ||
        (isUpcoming && ws.jobStatus === JobStatus.ToDo),
    ) || []
  )
}

function getInactiveWorkerShifts(
  workerShifts?: WorkerShiftWithWorkerDetails[],
  isUpcoming?: boolean,
) {
  if (!workerShifts) {
    return []
  }
  if (!isUpcoming) {
    return workerShifts?.filter((ws) => ws.jobStatus === JobStatus.Abandoned)
  }
  return (
    workerShifts?.filter(
      (ws) =>
        ws.jobStatus === JobStatus.NoShow ||
        ws.jobStatus === JobStatus.Rejected ||
        ws.jobStatus === JobStatus.Abandoned,
    ) || []
  )
}

function sortWorkerShifts(
  workerShifts: WorkerShiftWithWorkerDetails[],
  isUpcoming?: boolean,
) {
  return workerShifts.sort((a, b) => {
    const orderDiff = sortByJobStatus(a.jobStatus, b.jobStatus)
    if (orderDiff !== 0) {
      return orderDiff
    }
    return isUpcoming
      ? sortByDate(anyToDate(a.createdAt), anyToDate(b.createdAt))
      : a.worker.firstName.localeCompare(b.worker.firstName)
  })
}

export const useWorkersOnShift = (props: WorkersOnShiftProps) => {
  const { isUpcoming, slotsRequested } = props
  const workerShifts = sortWorkerShifts(props.workerShifts || [], isUpcoming)
  let inactiveWorkerShifts = getInactiveWorkerShifts(workerShifts, isUpcoming)
  let activeWorkerShifts = getActiveWorkerShifts(
    workerShifts,
    isUpcoming,
    props.isShiftCanceled,
  )

  const workerList = workerShifts?.map((w) => w.workerId) || []

  const { getWorkerById } = useWorkersByIds(workerList)

  // If there are TO_DO workers, they'll show as backups if they
  // exceed the slotsRequested mark
  let lastToDoIndex = -1
  for (let i = activeWorkerShifts.length - 1; i >= 0; i--) {
    if (activeWorkerShifts[i].jobStatus === JobStatus.ToDo) {
      lastToDoIndex = i
      break
    }
  }

  const shouldShowBackups =
    lastToDoIndex > -1 && lastToDoIndex + 1 > slotsRequested

  let workersOnBackup: WorkerShiftWithWorkerDetails[] = []

  if (shouldShowBackups) {
    workersOnBackup = activeWorkerShifts.slice(slotsRequested)
    activeWorkerShifts = activeWorkerShifts.slice(0, slotsRequested)
  }

  // only show no shows if they contributed to the total number of slots requested
  // otherwise they're simply an artifact of the overbooking process
  let totalNumberOfNoShowsToShow =
    slotsRequested -
    (activeWorkerShifts.length +
      inactiveWorkerShifts.filter((ws) => ws.jobStatus !== JobStatus.NoShow)
        .length)

  if (totalNumberOfNoShowsToShow > 0) {
    inactiveWorkerShifts = _.takeWhile(inactiveWorkerShifts, (ws) => {
      if (ws.jobStatus === JobStatus.NoShow) {
        return --totalNumberOfNoShowsToShow >= 0
      }
      return true
    })
  }

  return {
    workersOnShift: activeWorkerShifts,
    workersOnBackup,
    getWorkerById,
    inactiveWorkerShifts,
  }
}

export const useWorkersOnShiftTable = (props: {
  shift: Shift
  isUpcoming: boolean
}): WorkersOnShiftTableStateProps => {
  const { shiftId, slotsRequested } = props.shift

  const {
    data: workerShifts,
    isLoading: isLoadingWorkerShifts,
    refetch: refetchWorkerShifts,
  } = useWorkerShifts(shiftId)

  const {
    clockInWorkers,
    editClockInWorkers,
    clockOutWorkers,
    noShowWorkers,
    rejectWorkers,
    abandonWorkers,
    bizCancelRemoveWorkers,
  } = useWorkerShiftMutations({
    data: workerShifts,
    onSuccess: refetchWorkerShifts,
  })

  const {
    workersOnShift: activeWorkerShifts,
    workersOnBackup,
    inactiveWorkerShifts,
  } = useWorkersOnShift({
    isUpcoming: props.isUpcoming,
    workerShifts,
    isShiftCanceled: props.shift.status === ShiftStatus.CANCELED,
    slotsRequested,
  })

  const connections = useConnections()

  return {
    shift: props.shift,
    activeWorkerShifts,
    workersOnBackup,
    inactiveWorkerShifts,
    isUpcoming: props.isUpcoming,
    connections,
    clockInWorkers,
    editClockInWorkers,
    clockOutWorkers,
    noShowWorkers,
    rejectWorkers,
    abandonWorkers,
    bizCancelRemoveWorkers,
    refetchWorkerShifts,
    isLoadingWorkerShifts,
  }
}

export function useInReview() {
  const [inReview, setInReview] = useState<
    Map<string, Pick<ReviewWorkerFormProps, 'variation' | 'connectionId'>>
  >(new Map())

  function toggleReview(
    workerIds: string[],
    type: 'OPEN' | 'CLOSE' | 'TOGGLE',
    variation?: ReviewWorkerFormProps['variation'],
    connectionId?: string,
  ) {
    const newInReview = new Map(inReview)
    for (const workerId of workerIds) {
      switch (type) {
        case 'TOGGLE': {
          if (newInReview.has(workerId)) {
            newInReview.delete(workerId)
          } else {
            newInReview.set(workerId, { variation, connectionId })
          }
          continue
        }
        case 'OPEN': {
          newInReview.set(workerId, { variation, connectionId })
          continue
        }
        case 'CLOSE': {
          newInReview.delete(workerId)
          continue
        }
      }
    }
    setInReview(newInReview)
  }

  return { inReview, toggleReview }
}
