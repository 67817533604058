import { Shift, ShiftStatus, Timesheet, WorkerShift } from '@traba/types'
import React from 'react'
import { CostSummary } from 'src/components/CostSummary'
import {
  calculateServiceFeeInDollars,
  calculateSingleWorkerPay,
} from 'src/utils/moneyUtils'

import { UpcomingShiftDetailsProps } from '../UpcomingShiftDetails.hooks'

export interface ShiftCostSummaryProps {
  workerShifts: WorkerShift[]
  shift: Shift
  expectedCost: UpcomingShiftDetailsProps['expectedCost']
  timesheet?: Timesheet
  hideTitle?: boolean
}

export const ShiftCostSummary: React.FC<ShiftCostSummaryProps> = (
  props: ShiftCostSummaryProps,
) => {
  let summary
  const { shift, expectedCost, workerShifts = [], timesheet, hideTitle } = props

  const shiftEnded = new Date() > shift.endTime

  const {
    minSlotsRequested,
    slotsRequested,
    calculatedMarkup,
    startTime,
    endTime,
    scheduledBreaks,
    breakType,
    payRate,
    payType,
    numberOfUnits,
    businessStartTime,
  } = shift

  const isCompleted = shiftEnded || shift.status === ShiftStatus.CANCELED // If a Shift is canceled it is "complete"
  const singleWorkerCost = calculateSingleWorkerPay(
    {
      payRate: payRate,
      scheduledBreaks: scheduledBreaks,
      startTime: startTime,
      endTime: endTime,
      breakType,
      payType,
      numberOfUnits: numberOfUnits || 0,
      slotsRequested,
    },
    businessStartTime,
  )

  return isCompleted &&
    timesheet?.totalCharge &&
    timesheet?.grossPayToWorkers ? (
    <CostSummary
      hideTitle={hideTitle}
      shift={shift}
      title="Shift Total"
      payRate={payRate}
      summary={summary}
      serviceFee={
        timesheet.totalCharge.amount / 100 -
        (businessStartTime
          ? singleWorkerCost.amount
          : timesheet.grossPayToWorkers.amount) /
          100
      }
      totalWorkerPay={
        businessStartTime
          ? singleWorkerCost.amount
          : timesheet.grossPayToWorkers.amount
      }
      estimatedCost={timesheet.totalCharge.amount / 100}
      workerShifts={workerShifts}
      totalCharge={timesheet.totalCharge}
    />
  ) : (
    <CostSummary
      hideTitle={hideTitle}
      shift={shift}
      payRate={payRate}
      shiftCount={1}
      summary={summary}
      workers={[minSlotsRequested, slotsRequested]}
      serviceFee={[
        calculateServiceFeeInDollars({
          singleWorkerCost,
          markup: calculatedMarkup,
          slots: minSlotsRequested,
        }).amount / 100,
        calculateServiceFeeInDollars({
          singleWorkerCost,
          markup: calculatedMarkup,
          slots: slotsRequested,
        }).amount / 100,
      ]}
      singleWorkerPay={singleWorkerCost}
      estimatedCost={[
        expectedCost.minEstimatedCost.amount / 100,
        expectedCost.maxEstimatedCost.amount / 100,
      ]}
      totalCharge={timesheet?.totalCharge}
    />
  )
}
