import { INFINITE_SHIFT_REQ_CREATE_SHIFT_LEAD_TIME_WEEKS } from '@traba/consts'
import { CreateSchedule, CreateShiftRequest } from '@traba/types'
import { addWeeks, isAfter, isFuture } from 'date-fns'
import { WEEKDAY_TO_NUM, getRecurringShifts } from './dateUtils'

export function getRepeatOnError(data: CreateShiftRequest) {
  for (let i = 0; i < data.schedules.length; i++) {
    const schedule = data.schedules[i]
    if (!schedule.recurringSchedule) {
      continue
    }
    if (!schedule.recurringSchedule.repeatOn.length) {
      return {
        message: `Please select at least one day of the week for your shift in week ${i + 1}`,
        title: 'Schedule error',
      }
    }

    const customEndDateIfFutureShift = isFuture(schedule.startTime)
      ? addWeeks(
          schedule.startTime,
          INFINITE_SHIFT_REQ_CREATE_SHIFT_LEAD_TIME_WEEKS,
        )
      : undefined

    const { shiftCount } = getRecurringShifts(
      schedule,
      customEndDateIfFutureShift,
    )
    if (shiftCount < 1) {
      return {
        message: `Your schedule must have at least one shift`,
        title: 'Schedule error',
      }
    }
  }
  return
}

export function getFirstShiftDateError(data: { schedules: CreateSchedule[] }) {
  const [schedule] = data.schedules
  const weekA = schedule.recurringSchedule?.repeatOn
  if (!schedule.isRecurringSchedule || !weekA?.length) {
    return
  }
  const startDay = schedule.startTime.getDay()
  const isStartDayInWeekA = weekA.some(
    (day) => WEEKDAY_TO_NUM[day] === startDay,
  )
  if (!isStartDayInWeekA) {
    return {
      message:
        'First Shift Date must fall on a day selected in the weekly pattern below',
      title: 'Schedule error',
    }
  }
  return
}

export function getEndDateError(data: { schedules: CreateSchedule[] }) {
  const [schedule] = data.schedules

  if (isAfter(schedule.startTime, schedule.endTime)) {
    return {
      message: 'Shift end time cannot be before shift start time',
      title: 'Schedule error',
    }
  }
  return
}
