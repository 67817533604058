import React, { ReactNode } from 'react'
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
} from 'react-error-boundary'
import { useNavigate } from 'react-router-dom'

interface ErrorBoundaryProps {
  children: ReactNode
  fallbackComponent: React.ComponentType<FallbackProps>
}

export function ErrorBoundary({
  children,
  fallbackComponent,
}: ErrorBoundaryProps) {
  const navigate = useNavigate()
  return (
    <ReactErrorBoundary
      FallbackComponent={fallbackComponent}
      onReset={() => {
        navigate('/')
      }}
    >
      {children}
    </ReactErrorBoundary>
  )
}
