import { Row, SvgIcon, Text } from 'src/components'
import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'
import { WebToRNEventName } from 'src/types/events'

export const StartOverButton = () => {
  const { isReactNativeApp } = useMobile()

  return isReactNativeApp ? (
    <Row
      alignCenter
      onClick={() => {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            event: WebToRNEventName.SHIFT_BOOKING_START_OVER,
          }),
        )
      }}
    >
      <SvgIcon name="reset" />
      <Text ml={theme.space.xxxs} variant="link">
        Start over
      </Text>
    </Row>
  ) : null
}
