import { SxProps, Theme } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import { theme } from '@traba/theme'
import * as React from 'react'
import { Text } from '../base-components/Text'

export const WorkerOnScheduleTableCell = ({
  title,
  backgroundColor,
  sx,
}: {
  title: string
  backgroundColor?: string
  sx?: SxProps<Theme> | undefined
}) => {
  return (
    <TableCell style={{ backgroundColor }} align="left" sx={sx}>
      <Text variant="h6" color={theme.colors.Grey60}>
        {title}
      </Text>
    </TableCell>
  )
}
