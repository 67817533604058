import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useState } from 'react'
import { useQuery } from 'react-query'

type ProviderIdMap = {
  email?: string
  google?: string
  apple?: string
  okta?: string
}

const getAuthProviderIds = async (
  companyId: string | undefined,
): Promise<string[] | undefined> => {
  if (!companyId || companyId === '') {
    return
  }

  try {
    const response = await trabaApi.get(
      `/companies/${companyId}/auth-providers `,
    )
    return response.data.acceptedAuthProviderIds
  } catch (error) {
    console.error(error)
  }
}

export const useAuthProviderIds = (companyId: string | undefined) => {
  const [providerIdMap, setProviderIdMap] = useState<ProviderIdMap>({
    email: undefined,
    google: undefined,
    apple: undefined,
    okta: undefined,
  })

  const {
    isLoading,
    isError,
    data: authProviderIds,
    error,
    isFetched,
    refetch,
  } = useQuery<string[] | undefined, Error>(
    ['authProviderIds', companyId],
    () => getAuthProviderIds(companyId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
      refetchOnMount: 'always',
    },
  )

  const updateProviderIdMap = () => {
    if (!authProviderIds) {
      setProviderIdMap({
        email: 'email',
        google: 'google.com',
        apple: 'apple.com',
        okta: undefined, // Okta should be hidden by default
      })
      return
    }

    const newProviderIdMap: ProviderIdMap = {
      email: undefined,
      google: undefined,
      apple: undefined,
      okta: undefined,
    }

    for (const providerId of authProviderIds) {
      const providerIdLowercase = providerId.toLowerCase()
      if (providerIdLowercase.includes('email')) {
        newProviderIdMap.email = providerId
      } else if (providerIdLowercase.includes('google')) {
        newProviderIdMap.google = providerId
      } else if (providerIdLowercase.includes('apple')) {
        newProviderIdMap.apple = providerId
      } else if (providerIdLowercase.includes('okta')) {
        newProviderIdMap.okta = providerId
      }
    }

    setProviderIdMap(newProviderIdMap)
  }

  return {
    isLoading,
    isError,
    error,
    isFetched,
    authProviderIds,
    refetch,
    updateProviderIdMap,
    providerIdMap,
  }
}
