import {
  CreateCompanyWorkerFeedback,
  WorkerShiftWithWorkerDetails,
} from '@traba/types'
import { AxiosResponse } from 'axios'
import { useState } from 'react'
import { UseMutateAsyncFunction } from 'react-query'
import { ButtonVariant, InlineBanner, Row, Text } from 'src/components'
import { useBlocks } from 'src/hooks/useBlocks'
import { BulkWorkerActionBasePayload } from 'src/hooks/workerShiftHooks'
import { theme } from 'src/libs/theme'

import { Dialog } from '../base/Dialog/Dialog'
import { MultiLineInput } from '../base/Input/Input.styles'
import { RadioButton } from '../RadioButton'

export type RemoveWorkerBizCancelModalProps = {
  shiftId: string
  isOpen: boolean
  onClose: () => void
  workerShifts: WorkerShiftWithWorkerDetails[]
  bizCancelRemoveWorkers: UseMutateAsyncFunction<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    BulkWorkerActionBasePayload
  >
}

const CheckboxSelectOptionRow = (props: {
  isChecked: boolean
  onClick: () => void
  header: string
  subtext: string
}) => {
  return (
    <Row
      style={{
        padding: theme.space.xs,
        border: `1px solid ${theme.colors.Grey20}`,
        borderRadius: theme.space.xxs,
        cursor: 'pointer',
        marginTop: theme.space.xs,
      }}
      alignCenter
      onClick={props.onClick}
    >
      <RadioButton selected={props.isChecked} size={theme.space.sm} />
      <Row flexCol>
        <Text
          variant="h5"
          style={{
            marginLeft: theme.space.xs,
            color: theme.colors.MidnightBlue,
          }}
        >
          {props.header}
        </Text>
        <Text
          variant="body2"
          style={{
            marginLeft: theme.space.xs,
          }}
        >
          {props.subtext}
        </Text>
      </Row>
    </Row>
  )
}

export const RemoveWorkerBizCancelModal = (
  props: RemoveWorkerBizCancelModalProps,
) => {
  const [modalScreenPage, setModalScreenPage] = useState<
    'primary' | 'secondary'
  >('primary')

  const [modalActionType, setModalAction] = useState<
    'remove' | 'block_and_remove'
  >('remove')

  const [actionInProgress, setActionInProgress] = useState(false)

  const [extraDetails, setExtraDetails] = useState('')

  const [blockWorkerReason, setBlockWorkerReason] = useState<
    'worked_before' | 'not_worked_before'
  >('worked_before')

  const { blockAndReviewWorker } = useBlocks()

  const { workerShifts, shiftId } = props
  const nameOrNumWorkers =
    workerShifts.length > 1
      ? `${workerShifts.length} workers`
      : `${workerShifts[0].worker.firstName}`

  const handleBlock = async () => {
    await Promise.allSettled(
      workerShifts.map((workerShift) => {
        const feedback: CreateCompanyWorkerFeedback = {
          workerId: workerShift.workerId,
          shiftId: workerShift.shiftId,
          note: extraDetails,
          rating: 1,
        }
        return blockAndReviewWorker(workerShift.workerId, [], feedback)
      }),
    )
    window.analytics.track(`Business Blocked Workers Pre-Shift`, {
      workers: workerShifts,
      modalScreenPage,
      blockWorkerReason,
    })
    setActionInProgress(false)
    props.onClose()
  }

  const primaryScreen = () => {
    const titleText = `Remove ${nameOrNumWorkers}`

    const workerSubtextPart =
      workerShifts.length > 1 ? `these workers` : `this worker`

    const isAnyWorkerFirstTime = workerShifts.some(
      (workerShift) => workerShift.isFirstTimeWorkerShiftWithCompany,
    )

    const handleRemoveBizCancel = async () => {
      await props.bizCancelRemoveWorkers({
        workerIds: workerShifts.map(({ worker }) => worker.uid),
        shiftId,
      })
      window.analytics.track(`Business Removed Workers Pre-Shift`, {
        workers: workerShifts,
      })
      setActionInProgress(false)
      props.onClose()
    }

    const handleConfirm = async () => {
      setActionInProgress(true)
      if (modalActionType === 'remove') {
        await handleRemoveBizCancel()
      } else {
        // Directly block if all workers have worked with business before
        if (isAnyWorkerFirstTime) {
          setModalScreenPage('secondary')
          setActionInProgress(false)
        } else {
          await handleBlock()
        }
      }
    }

    return (
      <Dialog
        dialogTitle={titleText}
        onClose={props.onClose}
        onConfirmCTA={
          modalActionType === 'block_and_remove' && isAnyWorkerFirstTime
            ? 'Next'
            : 'Confirm'
        }
        onConfirm={handleConfirm}
        open={props.isOpen}
        confirmButtonVariant={ButtonVariant.CANCEL}
        confirming={actionInProgress}
      >
        <Row style={{ fontWeight: 'bold', color: theme.colors.MidnightBlue }}>
          There are two actions you can take at the moment:
        </Row>
        <CheckboxSelectOptionRow
          isChecked={modalActionType === 'remove'}
          onClick={() => {
            setModalAction('remove')
          }}
          header={`Remove ${workerSubtextPart}`}
          subtext={`This will remove ${nameOrNumWorkers} from this shift.`}
        />
        <CheckboxSelectOptionRow
          isChecked={modalActionType === 'block_and_remove'}
          onClick={() => {
            setModalAction('block_and_remove')
          }}
          header={`Block and Remove ${workerSubtextPart}`}
          subtext={`This will prevent ${nameOrNumWorkers} from signing up for future shifts at your business and will remove them from any shifts they are currently signed up for.`}
        />
        {modalActionType === 'block_and_remove' && !isAnyWorkerFirstTime && (
          <MultiLineInput
            style={{
              marginTop: theme.space.xs,
              width: '100%',
              height: theme.space.xl * 2,
              minWidth: '100%',
              maxWidth: '100%',
            }}
            onChange={(val) => setExtraDetails(val.target.value)}
            placeholder="Add any details you'd like to share about your reason"
            value={extraDetails}
          />
        )}
      </Dialog>
    )
  }

  const secondaryScreen = () => {
    const titleText = `Block and Remove ${nameOrNumWorkers}`

    const workerSubtextPart =
      workerShifts.length > 1 ? `some of these workers` : `this worker`

    return (
      <Dialog
        dialogTitle={titleText}
        onClose={props.onClose}
        onConfirmCTA={'Confirm'}
        confirmButtonVariant={ButtonVariant.CANCEL}
        open={props.isOpen}
        onConfirm={async () => {
          setActionInProgress(true)
          await handleBlock()
        }}
        confirming={actionInProgress}
      >
        <InlineBanner
          severity="warning"
          text={`Blocking and Removing will prevent ${nameOrNumWorkers} from
            signing up for future shifts at your business and will remove 
            them from any shifts they are currently signed up for.`}
          style={{ marginBottom: theme.space.xs }}
        />
        <Text variant="h6">
          Our records indicate that you've never worked with {workerSubtextPart}{' '}
          on Traba before. What is the reason you are blocking them?
        </Text>
        <Row
          alignCenter
          mt={theme.space.xxs}
          onClick={() => {
            setBlockWorkerReason('worked_before')
          }}
        >
          <RadioButton
            size={22}
            selected={blockWorkerReason === 'worked_before'}
          />
          <Text ml={theme.space.xs}>
            I've worked with {workerSubtextPart} outside of Traba and they
            didn't meet our standard.
          </Text>
        </Row>
        <Row
          alignCenter
          mt={theme.space.xxs}
          onClick={() => {
            setBlockWorkerReason('not_worked_before')
          }}
        >
          <RadioButton
            size={22}
            selected={blockWorkerReason === 'not_worked_before'}
          />
          <Text ml={theme.space.xs}>
            I haven't worked with {workerSubtextPart} before but their profile
            doesn't seem like a fit for my business.
          </Text>
        </Row>
        <MultiLineInput
          style={{
            marginTop: theme.space.xs,
            width: '100%',
            height: theme.space.xl * 2,
            minWidth: '100%',
            maxWidth: '100%',
          }}
          onChange={(val) => setExtraDetails(val.target.value)}
          placeholder="Add any details you’d like to share about your reason"
          value={extraDetails}
        />
      </Dialog>
    )
  }

  if (modalScreenPage === 'primary') {
    return primaryScreen()
  } else {
    return secondaryScreen()
  }
}
