import {
  Autocomplete as MuiAutocomplete,
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
  Chip,
  FilterOptionsState,
  TextField,
} from '@mui/material'
import { MAX_ANNOUNCEMENT_MESSAGE_LENGTH } from '@traba/consts'
import { useAlert } from '@traba/context'
import { FileType, useFileUploader } from '@traba/hooks'
import { MediaUploader } from '@traba/react-components'
import { ChatMessageContent, ChatSuggestion } from '@traba/types'
import { InputStatus } from '@traba/types'
import { useState } from 'react'
import { useDebounce } from 'react-use'
import {
  Avatar,
  Button,
  ButtonVariant,
  Col,
  Input,
  Row,
  SvgIcon,
  Text,
} from 'src/components'
import { containsText } from 'src/components/base/Autocomplete/Autocomplete'
import * as S from 'src/components/base/Autocomplete/Autocomplete.styles'
import { useChatSuggestions } from 'src/hooks/useChatSuggestion'
import { useUser } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'

interface Props {
  onClickBack: () => void
  onSubmit: (content: ChatMessageContent, workerIds: string[]) => Promise<void>
}

export const NewMessageSection = ({ onClickBack, onSubmit }: Props) => {
  const [selectedSuggestion, setSelectedSuggestion] = useState<
    ChatSuggestion[]
  >([])
  const [messageContent, setMessageContent] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | undefined>()
  const [imageUrl, setImageUrl] = useState<string | undefined>()
  const { handleUpload } = useFileUploader()
  const { user } = useUser()
  const { showError } = useAlert()
  const [input, setInput] = useState('')
  const [query, setQuery] = useState('')
  const { suggestions, isLoading: isLoadingSuggestions } =
    useChatSuggestions(query)

  useDebounce(
    () => {
      setQuery(input)
    },
    500,
    [input],
  )

  async function onChangeFile(f: File | undefined) {
    setIsLoading(true)
    setFile(f)
    if (f) {
      const url = await handleUpload({
        fileType: FileType.SHIFT_IMAGES,
        media: f,
        userId: user?.uid,
      })
      setImageUrl(url)
    }
    setIsLoading(false)
  }

  async function onDeleteFile() {
    setFile(undefined)
    setImageUrl(undefined)
  }

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} label={'Search workers'} />
  )

  const renderTags = (
    tags: ChatSuggestion[],
    getTagProps: AutocompleteGetTagProps,
  ) =>
    tags.map((option: ChatSuggestion, index: number) => (
      <Chip
        variant={ButtonVariant.OUTLINED}
        label={option.name}
        {...getTagProps({ index })}
      />
    ))

  const filterOptions = (
    options: ChatSuggestion[],
    state: FilterOptionsState<ChatSuggestion>,
  ) =>
    options.filter(
      (o) => !input.includes(o.name) && containsText(o.name, state.inputValue),
    )

  return (
    <>
      <Row onClick={onClickBack} alignCenter mb={theme.space.xs}>
        <SvgIcon name="back" />
        <Text variant="h6" color={theme.colors.Violet} ml={theme.space.xxs}>
          All messages
        </Text>
      </Row>

      <Text variant="h5" mb={theme.space.xs}>
        New message
      </Text>
      <Col pb={theme.space.xxs}>
        <S.SearchBoxStyling />
        <MuiAutocomplete
          multiple
          loading={isLoadingSuggestions}
          fullWidth
          filterSelectedOptions
          options={suggestions ?? []}
          renderOption={(props, option: ChatSuggestion) => {
            const { name, photoUrl, workerId } = option

            return (
              <li {...props} key={workerId}>
                <Row alignCenter>
                  <Avatar src={photoUrl} name={name} size={50} />
                  <Text variant="h6" ml={theme.space.xxs}>
                    {name}
                  </Text>
                </Row>
              </li>
            )
          }}
          inputValue={input}
          onInputChange={(event, value: string) => {
            if (event) {
              if (event.type === 'keydown') {
                const nativeEvent = event.nativeEvent as KeyboardEvent
                if (nativeEvent.key === 'Enter') {
                  setInput(value)
                }
              } else {
                setInput(value)
              }
            }
          }}
          onChange={(_, value) => {
            if (value) {
              setSelectedSuggestion(value)
            }
          }}
          filterOptions={filterOptions}
          renderInput={renderInput}
          getOptionLabel={(option: ChatSuggestion) => `${option.name}`}
          renderTags={renderTags}
        />
        {selectedSuggestion.length > 1 && (
          <Text variant="body2" mt={theme.space.ms}>
            You will start separate chats with selected workers.
          </Text>
        )}
      </Col>
      <Input
        rows={5}
        label="Add your message here"
        type="textarea"
        className="xs-12"
        value={messageContent}
        onChange={(e) => setMessageContent(e.target.value)}
        inputStatus={
          messageContent &&
          messageContent.length > MAX_ANNOUNCEMENT_MESSAGE_LENGTH
            ? InputStatus.error
            : InputStatus.default
        }
        errorMessage={
          messageContent &&
          `Please limit your message to under ${MAX_ANNOUNCEMENT_MESSAGE_LENGTH} characters. You have used ${messageContent.length} characters.`
        }
      />
      <MediaUploader
        label="Add image"
        fileType="image"
        file={file}
        onChange={onChangeFile}
        onDelete={onDeleteFile}
        onError={showError}
        maxFileSizeMB={10}
        initialPreviewSrc={imageUrl}
      />
      <Row justifyEnd mt={theme.space.sm}>
        <Button
          onClick={() => {
            onClickBack()
          }}
          variant={ButtonVariant.OUTLINED}
          style={{ marginRight: theme.space.xxs }}
        >
          Cancel
        </Button>
        <Button
          disabled={
            (!messageContent && !imageUrl) ||
            (!!messageContent &&
              messageContent.length > MAX_ANNOUNCEMENT_MESSAGE_LENGTH)
          }
          onClick={async () => {
            try {
              setIsLoading(true)
              await onSubmit(
                { messageContent, attachmentUrl: imageUrl },
                selectedSuggestion.map((s) => s.workerId as string),
              )
              setImageUrl(undefined)
              setMessageContent(undefined)
              setFile(undefined)
              setIsLoading(false)
            } catch (e: any) {
              setIsLoading(false)
              if (e?.name === 'BusinessMessageBlockedExplicit') {
                showError('Your message includes inappropriate language.')
              } else {
                showError('Something went wrong. Please try again.')
                console.error(e)
              }
            }
          }}
          variant={ButtonVariant.FILLED}
          style={{
            paddingLeft: theme.space.med,
            paddingRight: theme.space.med,
          }}
          loading={isLoading}
        >
          Send
        </Button>
      </Row>
    </>
  )
}
