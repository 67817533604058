import { SvgIconProps } from 'src/types/svg-types'

export default function ShieldCheck({
  size,
  color = '#7A8A99',
  strokeWidth = 1.5,
  ...props
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 26 26" fill="none" {...props}>
      <path
        d="M16.7711 19.5353L16.9517 19.4104C17.5167 19.0197 17.7992 18.8243 18.0519 18.6149C19.4252 17.4767 20.3475 15.8903 20.6545 14.1384C20.711 13.816 20.7404 13.4749 20.7991 12.7928L20.8295 12.4401C20.9266 11.311 20.917 10.1753 20.8005 9.04808L20.7645 8.69924C20.5607 6.72655 19.3497 4.99757 17.5602 4.12448C14.6179 2.68898 11.1736 2.68898 8.23134 4.12448C6.44183 4.99757 5.23079 6.72655 5.02702 8.69924L4.99099 9.04809C4.87455 10.1754 4.86486 11.311 4.96205 12.4401L4.99241 12.7928C5.05113 13.4749 5.08048 13.816 5.13698 14.1384C5.44396 15.8903 6.36631 17.4767 7.73955 18.6149C7.99227 18.8243 8.27485 19.0197 8.83982 19.4104L9.02036 19.5353C9.78848 20.0664 10.1726 20.332 10.5575 20.5166C12.035 21.225 13.7565 21.225 15.234 20.5166C15.6189 20.332 16.003 20.0664 16.7711 19.5353Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M10.5312 12.2875L12.6146 14.3708L16.2604 10.4646"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
