import { otherColors } from 'src/libs/theme'
import styled from 'styled-components'

export const InvoiceDisclaimerAnchor = styled.a`
  font-weight: bold;
  text-decoration: none;
  color: ${otherColors.Grey60};

  :hover {
    color: ${otherColors.Grey90};
    text-decoration: underline;
  }
`
