import { useStatsigClient } from '@statsig/react-bindings'
import { CompanyFeatureFlag } from '@traba/types'
import { SettingsPageWithTabs } from '../SettingsPageWithTabs'
import { useCompanySettings } from './CompanySettings.hooks'
import { LocationAssignedUsersAnnouncementBanner } from './LocationAssignedUsersAnnouncementBanner'

export const CompanySettings = () => {
  const { currentTabIndex, setCurrentTabIndex, activeTabs } =
    useCompanySettings()
  const { client: statsigClient } = useStatsigClient()

  const isLocationsAssignedUserBannerEnabled = statsigClient.checkGate(
    CompanyFeatureFlag.LOCATIONS_ASSIGNED_USER_ANNOUNCEMENT_BANNER,
  )

  return (
    <SettingsPageWithTabs
      pageTitle="Company Settings"
      currentTabIndex={currentTabIndex}
      setCurrentTabIndex={setCurrentTabIndex}
      activeTabs={activeTabs}
      desktopBanner={
        isLocationsAssignedUserBannerEnabled ? (
          <LocationAssignedUsersAnnouncementBanner />
        ) : undefined
      }
    />
  )
}
