import { Text } from '@traba/react-components'

export const MobileDisclaimer = () => {
  return (
    <Text variant="body1">
      Mobile view not supported. Please use a desktop or download the Traba
      Business app from the{' '}
      <a
        href="https://apps.apple.com/us/app/traba-business/id6473748752"
        target="_blank"
        rel="noreferrer"
      >
        App Store
      </a>{' '}
      or the{' '}
      <a
        href="https://play.google.com/store/apps/details?id=com.traba.trabasupervisor"
        target="_blank"
        rel="noreferrer"
      >
        Google Play Store
      </a>{' '}
      to access the contents of this page.
    </Text>
  )
}
