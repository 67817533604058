import { ClickAwayListener } from '@mui/material'
import { WorkerShiftWithWorkerDetails } from '@traba/types'
import { addHours, subHours } from 'date-fns'
import dayjs from 'dayjs'
import { useState } from 'react'
import { ButtonVariant, SvgIcon, Text } from 'src/components/base'
import { Button } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { useCompany } from 'src/hooks/useCompany'
import { theme } from 'src/libs/theme'

import TimeField from '../base/AriaDatePicker/TimeField'
import { WorkerAction } from '../WorkersManagementMenu/WorkersManagementMenu'
import * as S from './ClockInAndOutTooltipStyles'

interface ClockInModalProps {
  handleClose: () => void
  onConfirm: (d: Date) => void
  startDate: Date
  endDate: Date
  defaultDate: Date
  timezone: string
  workerShift: WorkerShiftWithWorkerDetails
  workerAction: WorkerAction
}

export default function ClockInAndOutTooltip({
  handleClose,
  onConfirm,
  startDate,
  endDate,
  defaultDate,
  timezone,
  workerShift,
  workerAction,
}: ClockInModalProps) {
  const [dateTime, setdateTime] = useState<Date | null>(defaultDate)
  const [isValidTime, setIsValidTime] = useState<boolean>(true)
  const { company } = useCompany()

  // Allow clock in/out 3 hours before and after the shift
  const bufferedStartDate = subHours(
    startDate,
    company?.hoursToClockInEarlyBy ?? 3,
  )
  const bufferedEndDate = addHours(endDate, 3)

  const isOvernight = !dayjs(bufferedStartDate).isSame(
    dayjs(bufferedEndDate),
    'day',
  )

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <S.ClockInAndOutTooltipContainer>
        <S.ClockInAndOutTooltipHeader
          justifyBetween
          style={{
            padding: theme.space.xs,
            borderBottom: `1px solid ${theme.colors.Grey20}`,
          }}
        >
          <Text variant="h5">
            {`${
              workerAction === WorkerAction.ClockIn ? 'Clock In' : 'Clock Out'
            } ${workerShift.worker.firstName} ${workerShift.worker.lastName}`}
          </Text>
          <SvgIcon
            name="cancel"
            color={theme.colors.Grey50}
            onClick={handleClose}
          />
        </S.ClockInAndOutTooltipHeader>
        <S.DatePickerContainer>
          {isOvernight ? (
            <DatePicker
              showTimeFieldInPopover={true}
              setDate={(dateTime) => {
                setdateTime(dateTime)
              }}
              isClearable={false}
              inlineLabel={true}
              label="Time"
              date={dateTime}
              defaultDate={defaultDate}
              timezone={timezone}
              minDate={bufferedStartDate}
              maxDate={bufferedEndDate}
              onValidationChange={(isValidTime) => setIsValidTime(isValidTime)}
            />
          ) : (
            <TimeField
              time={dateTime}
              timezone={timezone}
              setTime={(newDate) => {
                setdateTime(newDate)
              }}
              minTime={bufferedStartDate}
              maxTime={bufferedEndDate}
            />
          )}
        </S.DatePickerContainer>
        <S.ClockInAndOutTooltipFooter justifyEnd>
          <Button slim variant={ButtonVariant.OUTLINED} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            slim
            variant={ButtonVariant.FILLED}
            style={{ marginLeft: theme.space.xxs }}
            onClick={() => {
              if (dateTime && isValidTime) {
                onConfirm(dateTime)
                handleClose()
              }
            }}
            disabled={!dateTime || !isValidTime}
          >
            Confirm
          </Button>
        </S.ClockInAndOutTooltipFooter>
      </S.ClockInAndOutTooltipContainer>
    </ClickAwayListener>
  )
}
