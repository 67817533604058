import { Button, ButtonVariant, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import React from 'react'
import { InlineBanner } from 'src/components'
import { RoleData } from 'src/types'

interface RoleCreationContentProps {
  cannotCreateRole?: boolean
  roles: RoleData[]
  isEventCompany?: boolean
  onCreateRole: () => void
  onCreateRoleFromPrevious: () => void
}

export const RoleCreationContent: React.FC<RoleCreationContentProps> = ({
  cannotCreateRole,
  roles,
  isEventCompany,
  onCreateRole,
  onCreateRoleFromPrevious,
}) => {
  if (cannotCreateRole) {
    return roles.length ? null : (
      <InlineBanner
        severity="warning"
        text="Your role does not have permissions to create a new role. Please reach out to your administrator"
        style={{ marginTop: theme.space.xxs }}
      />
    )
  }

  return (
    <Row alignCenter gap={theme.space.xxs} mt={theme.space.xxs} wrap>
      <Button variant={ButtonVariant.LINK} onClick={onCreateRole}>
        Create new role
      </Button>
      {!isEventCompany && roles.length > 0 && (
        <>
          <Text variant="body1">OR</Text>
          <Button
            variant={ButtonVariant.LINK}
            onClick={onCreateRoleFromPrevious}
          >
            Create role using your previous roles
          </Button>
        </>
      )}
    </Row>
  )
}
