import styled from 'styled-components'

export const SelectableCardContainer = styled.div<{
  selected: boolean
  slim?: boolean
}>`
  cursor: pointer;
  text-align: center;
  width: auto;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.Grey30};
  justify-content: space-between;
  height: max-content;
  box-shadow: 0px 1px 2px rgba(196, 204, 212, 0.4);
  padding: ${({ slim, theme }) =>
    `${slim ? theme.space.xxs / 2 : theme.space.xxs}px ${
      slim ? theme.space.xs : theme.space.xs
    }px`};
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.Violet10 : '#ffffff'};
  border-color: ${({ theme, selected }) =>
    selected ? theme.colors.Violet20 : theme.border.color};
  :hover {
    box-shadow: 0px 2px 5px rgba(196, 204, 212, 0.55);
  }
`
