import React from 'react'

import { useInvoiceDetailsTable } from './InvoiceDetailsTable.hooks'
import { InvoiceDetailsTableUI } from './InvoiceDetailsTable.ui'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook

export const InvoiceDetailsTable = (props: { invoiceId: string }) => {
  const logic = useInvoiceDetailsTable(props)
  return <InvoiceDetailsTableUI {...logic} {...props} />
}
