import { BreakType, Shift, ShiftPayType, ShiftTime } from '@traba/types'
import { WorkerShift } from '@traba/types'
import { getTotalScheduledBreakTime } from '@traba/utils'
import { differenceInMinutes, startOfMinute } from 'date-fns'
import { dateMax } from 'src/shared/utils/dateUtils'

import { Money, ScheduledBreak } from '../types'
import { getEarlyArrivalTimeBufferInMinutes } from './earlyArrivalTimeUtils'

// These are all util methods copied over from the traba-app repo. Maybe should move to a lib repo?

export function getMoneyString(
  money: Money | number | undefined,
  hideCents?: boolean | undefined,
) {
  const currencySymbol = '$' // eventually have this map based on the 'currency' property in the Money object
  return money !== undefined
    ? typeof money === 'number'
      ? `${currencySymbol}${String(money.toFixed(hideCents ? 0 : 2))}`
      : `${currencySymbol}${(money.amount / 100).toFixed(hideCents ? 0 : 2)}`
    : ''
}

export function getPayUnitString(
  payType: ShiftPayType = ShiftPayType.HOURLY,
): string {
  return payType === ShiftPayType.UNIT ? '/unit' : '/hr'
}

function convertPayRateToCents(payRate: number) {
  // we might have floating point errors that we want to round, e.g. payRate = $1.10
  return Math.round(payRate * 100)
}

// Returns a money object, for now currency is hardcoded as USD
function calculateGrossPay({
  timeWorkedInMinutes,
  payRate,
}: {
  timeWorkedInMinutes: number
  payRate: number
}): Money {
  // get payRate into cents
  // multiply payRateInCents * timeWorkedInMinutes / 60 minutes = decimal amount in cents
  // round decimal amount in cents to closest integer value (pay in cents)
  const payRateInCents = convertPayRateToCents(payRate)
  const hours = timeWorkedInMinutes / 60
  const amount = Math.round(payRateInCents * hours)
  return { amount, currency: 'USD' }
}

function getEstimatedCostOfShift({
  singleWorkerCost,
  slots,
  markup,
}: {
  singleWorkerCost: Money
  slots: number
  markup: number
}): Money {
  return {
    amount: singleWorkerCost.amount * slots * (1 + markup),
    currency: 'USD',
  }
}

export interface ICalculateSingleWorkerPay {
  startTime: Date
  endTime: Date
  payRate: number
  scheduledBreaks: ScheduledBreak[]
  breakType?: BreakType
  payType: ShiftPayType
  numberOfUnits: number
  slotsRequested: number
}

export function calculateServiceFeeInDollars({
  singleWorkerCost,
  slots,
  markup,
}: {
  singleWorkerCost: Money
  slots: number
  markup: number
}): Money {
  return {
    amount: singleWorkerCost.amount * slots * markup,
    currency: 'USD',
  }
}

export function calculatePaidShiftTime(
  totalScheduledBreakTime: number,
  shiftLengthInMinutesIncludingBreaks: number,
  breakType?: BreakType,
): number {
  // If a shifts breaks are paid, breaks should not be deducted to get the total paid time.
  // Otherwise, if they are unpaid or manual unpaid, we should deduct the scheduled breaks for the total estimated paid time.
  const shiftLengthMinusBreaks: number =
    breakType === BreakType.PAID
      ? shiftLengthInMinutesIncludingBreaks
      : shiftLengthInMinutesIncludingBreaks - totalScheduledBreakTime

  return shiftLengthMinusBreaks
}

//TODO Arvind calculate on the server
// TODO: This is a slightly diff version of the method from shared/utils. We should consolidate
function calculateBreaksInMinutes(breaks: ShiftTime[]) {
  const breakTimes =
    breaks?.map((shiftBreak) => {
      const { startTime, endTime } = shiftBreak
      if (!endTime || !startTime) {
        return 0
      }
      return differenceInMinutes(
        startOfMinute(endTime),
        startOfMinute(startTime),
      )
    }) || []

  return breakTimes?.reduce((acc, curr) => {
    return acc + curr
  }, 0)
}

// TODO: This is a slightly diff version of the method from shared/utils. We should consolidate
function calculateBreakTimes(
  shiftInfo: ICalculateSingleWorkerPay,
  breaks: ShiftTime[] | undefined | null,
) {
  const { breakType, scheduledBreaks } = shiftInfo

  if (breakType === BreakType.PAID) {
    return 0
  }

  /* If breaks[] has any values, we consider this an override to the 
  scheduledBreaks and return the total break time from the array. */
  const breaksInMinutes = calculateBreaksInMinutes(breaks ?? [])
  if (breaks && breaks?.length > 0) {
    return breaksInMinutes
  }

  const scheduledBreaksInMinutes = getTotalScheduledBreakTime(scheduledBreaks)
  if (!breaks && breakType !== BreakType.MANUAL_UNPAID) {
    return scheduledBreaksInMinutes
  }

  if (breakType === BreakType.AUTO_UNPAID) {
    return Math.max(scheduledBreaksInMinutes, breaksInMinutes)
  }

  if (breakType === BreakType.MANUAL_UNPAID) {
    return breaksInMinutes
  }

  return 0
}

export function getWorkerShiftUpdatesWithoutEarlyArrival(
  shift: Shift,
  workerShift: WorkerShift,
) {
  const businessStartTime = shift.businessStartTime
  const { clockInTime, clockOutTime } = workerShift
  if (!clockInTime || !clockOutTime) {
    return undefined
  }

  const shouldRemoveEarlyBufferedTime =
    !!businessStartTime &&
    !!getEarlyArrivalTimeBufferInMinutes({
      ...shift,
      startTime: workerShift.shiftInfo.startTime,
    })

  if (shouldRemoveEarlyBufferedTime) {
    const maxStartTime = dateMax(businessStartTime, new Date(clockInTime))

    const grossPay = calculateSingleWorkerPay(
      {
        ...workerShift.shiftInfo,
        startTime: maxStartTime,
        endTime: clockOutTime,
        scheduledBreaks: shift.scheduledBreaks,
      },
      undefined,
      workerShift.breaks,
    )

    const chargeableWorkerPay = grossPay

    return chargeableWorkerPay
  }

  return workerShift.grossPay
}

export function calculateSingleWorkerPay(
  shiftInfo: ICalculateSingleWorkerPay,
  businessStartTime?: Date | null,
  breaks?: ShiftTime[] | null,
): Money {
  const {
    startTime,
    endTime,
    payRate,
    payType,
    breakType,
    slotsRequested,
    numberOfUnits,
  } = shiftInfo

  if (payType === ShiftPayType.UNIT) {
    const payPerWorkerInCents =
      ((payRate * numberOfUnits) / slotsRequested) * 100
    return { amount: payPerWorkerInCents, currency: 'USD' }
  }

  const totalBreakTime = calculateBreakTimes(shiftInfo, breaks)

  const shiftLengthInMinutesIncludingBreaks: number = differenceInMinutes(
    endTime,
    businessStartTime ?? startTime,
  )

  const paidTimeInMinutes = calculatePaidShiftTime(
    totalBreakTime,
    shiftLengthInMinutesIncludingBreaks,
    breakType,
  )
  const grossPay = calculateGrossPay({
    timeWorkedInMinutes: paidTimeInMinutes,
    payRate,
  })

  return grossPay
}

export interface IExpectedCost extends ICalculateSingleWorkerPay {
  minSlotsRequested: number
  slotsRequested: number
  calculatedMarkup: number
}

export interface IExpectedCostResponse {
  minEstimatedCost: Money
  maxEstimatedCost: Money
}

export function calculateExpectedCost(
  {
    startTime,
    endTime,
    payRate,
    scheduledBreaks,
    minSlotsRequested,
    slotsRequested,
    calculatedMarkup,
    breakType,
    payType,
    numberOfUnits,
  }: IExpectedCost,
  businessStartTime: Date | null | undefined,
) {
  const singleWorkerCost = calculateSingleWorkerPay(
    {
      startTime,
      endTime,
      payRate,
      scheduledBreaks,
      breakType,
      payType,
      slotsRequested,
      numberOfUnits,
    },
    businessStartTime,
  )

  const minEstimatedCost = getEstimatedCostOfShift({
    singleWorkerCost,
    slots: minSlotsRequested,
    markup: calculatedMarkup,
  })

  const maxEstimatedCost = getEstimatedCostOfShift({
    singleWorkerCost,
    slots: slotsRequested,
    markup: calculatedMarkup,
  })

  return {
    minEstimatedCost,
    maxEstimatedCost,
  }
}

export function numberToCurrency(num: number) {
  const str = num.toFixed(2)
  const [dollars, cents] = str.split('.')
  let formattedDollars = ''
  let count = 0
  for (let i = dollars.length - 1; i >= 0; i--) {
    if (i !== dollars.length - 1 && count % 3 === 0) {
      formattedDollars = `,${formattedDollars}`
    }
    formattedDollars = `${dollars[i]}${formattedDollars}`
    count++
  }
  return `$${formattedDollars}.${cents}`
}

export function multiplyMoney(money: Money | undefined, factor: number): Money {
  return {
    amount: (money?.amount || 0) * factor,
    currency: money?.currency || 'USD',
  }
}
