import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { MEDIA } from 'src/libs/theme'
import InvoiceTableSkeleton from './InvoiceTable.Skeleton'
import MobileShiftTileSkeleton from './MobileShiftTile.Skeleton'
import MobileWorkersListSkeleton from './MobileWorkersListSkeleton'
import RoleSkeleton from './Role.Skeleton'
import ShiftTileSkeleton from './ShiftTile.Skeleton'

export type SkeletonsProps = {
  count: number
  component: 'ROLE' | 'SHIFT_TILE' | 'INVOICE_TABLE' | 'WORKERS_LIST'
}
function Skeletons(props: SkeletonsProps) {
  const { count = 5, component } = props
  const showDesktopCard = useMediaQuery({
    query: `(min-width: ${MEDIA.LARGE}px)`,
  })

  switch (component) {
    case 'INVOICE_TABLE':
      return (
        <>
          {Array(count)
            .fill(5)
            .map((n: number, index: number) => (
              <InvoiceTableSkeleton key={index} />
            ))}
        </>
      )
    case 'SHIFT_TILE': {
      const Component = showDesktopCard
        ? ShiftTileSkeleton
        : MobileShiftTileSkeleton
      return (
        <div style={{ width: '100%' }}>
          {Array(count)
            .fill(5)
            .map((n: number, index: number) => (
              <Component key={index} />
            ))}
        </div>
      )
    }
    case 'ROLE':
      return (
        <>
          {Array(count)
            .fill(5)
            .map((n: number, index: number) => (
              <RoleSkeleton key={index} />
            ))}
        </>
      )
    case 'WORKERS_LIST':
      return (
        <>
          {Array(count)
            .fill(5)
            .map((n: number, index) => (
              <MobileWorkersListSkeleton key={index} />
            ))}
        </>
      )
    default:
      return null
  }
}

export default Skeletons
