export enum NotificationPreferenceStatus {
  ALL = 'ALL',
  NONE = 'NONE',
  SUPERVISOR_ONLY = 'SUPERVISOR_ONLY',
}

export enum UserNotificationSettingType {
  SHIFT_CODES_SMS = 'shiftCodesSMS',
  SHIFT_CODES_EMAIL = 'shiftCodesEmail',
  SHIFTS_TOMORROW_EMAIL = 'shiftsTomorrowEmail',
  SHIFT_CREATION_EMAIL = 'shiftCreationEmail',
  SHIFT_REBOOKING_EMAIL = 'shiftRebookingEmail',
  REVIEW_SHIFT_EMAIL = 'reviewShiftEmail',
  INVOICE_CREATED_EMAIL = 'invoiceCreatedEmail',
  INVOICE_VOIDED_EMAIL = 'invoiceVoidedEmail',
  INVOICE_REMINDER_EMAIL = 'invoiceReminderEmail',
}

export type BizUserSetting = {
  locationId: string
  [UserNotificationSettingType.SHIFT_CODES_SMS]?: NotificationPreferenceStatus
  [UserNotificationSettingType.SHIFT_CREATION_EMAIL]?: NotificationPreferenceStatus
  [UserNotificationSettingType.SHIFTS_TOMORROW_EMAIL]?: NotificationPreferenceStatus
  [UserNotificationSettingType.SHIFT_CODES_EMAIL]?: NotificationPreferenceStatus
  [UserNotificationSettingType.REVIEW_SHIFT_EMAIL]?: NotificationPreferenceStatus
  [UserNotificationSettingType.SHIFT_REBOOKING_EMAIL]?: NotificationPreferenceStatus
  [UserNotificationSettingType.INVOICE_CREATED_EMAIL]?: NotificationPreferenceStatus
  [UserNotificationSettingType.INVOICE_REMINDER_EMAIL]?: NotificationPreferenceStatus
  [UserNotificationSettingType.INVOICE_VOIDED_EMAIL]?: NotificationPreferenceStatus
}

export type updateUserSettingsRequest = {
  locationIds: string[]
} & Omit<BizUserSetting, 'locationId'>

export interface NotificationItem {
  status: NotificationPreferenceStatus
  locationIds?: string[]
  notificationType: UserNotificationSettingType
  description: string
}

export interface SettingsMap {
  [key: string]: NotificationItem
}

export const INVOICE_RELATED_EMAILS = [
  UserNotificationSettingType.INVOICE_CREATED_EMAIL,
  UserNotificationSettingType.INVOICE_REMINDER_EMAIL,
  UserNotificationSettingType.INVOICE_VOIDED_EMAIL,
]

export const SHIFT_RELATED_EMAILS = [
  UserNotificationSettingType.SHIFT_CODES_EMAIL,
  UserNotificationSettingType.SHIFTS_TOMORROW_EMAIL,
  UserNotificationSettingType.SHIFT_CREATION_EMAIL,
  UserNotificationSettingType.REVIEW_SHIFT_EMAIL,
  UserNotificationSettingType.SHIFT_REBOOKING_EMAIL,
]

export const SHIFT_RELATED_TEXT = [UserNotificationSettingType.SHIFT_CODES_SMS]
export const ALL_TEXT_SETTINGS = SHIFT_RELATED_TEXT
export const ALL_EMAIL_SETTINGS = [
  ...INVOICE_RELATED_EMAILS,
  ...SHIFT_RELATED_EMAILS,
]
