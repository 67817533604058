import { Td } from 'src/components'
import styled from 'styled-components'

export const MembersProfileContainer = styled.div`
  max-width: ${({ theme }) => theme.media.minLarge};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.lg}px;
`

export const StyledTdEditMenuContainer = styled(Td)`
  display: flex;
  justify-content: flex-end;
`
