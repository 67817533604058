import useAppAuth from 'src/hooks/authHook'
import { theme } from 'src/libs/theme'
import { Button, ButtonVariant, Text } from '../base'

import { OktaIcon } from './AuthIcon'

interface OktaAuthButtonProps {
  providerId: string
  disabled?: boolean
}

export const OktaAuthButton = ({
  providerId,
  disabled,
}: OktaAuthButtonProps) => {
  const { handleLoginWithOkta } = useAppAuth()
  return (
    <Button
      disabled={disabled}
      variant={ButtonVariant.OUTLINED}
      type="button"
      leftIcon={<OktaIcon />}
      style={{
        width: '100%',
        marginTop: theme.space.xxs,
        marginBottom: theme.space.xs,
      }}
      onClick={() => handleLoginWithOkta(providerId)}
    >
      <Text ml={theme.space.xs} variant="h6">
        Continue with Okta
      </Text>
    </Button>
  )
}
