import { theme } from 'src/libs/theme'
import { IconName, SvgIconProps } from 'src/types/svg-types'

import Abandon from '../svgs/Abandon'
import Add from '../svgs/Add'
import AddChat from '../svgs/AddChat'
import AddInverse from '../svgs/AddInverse'
import Alert from '../svgs/Alert'
import Archived from '../svgs/Archived'
import { Back } from '../svgs/Back'
import Block from '../svgs/Block'
import Briefcases from '../svgs/Briefcase'
import Calendar from '../svgs/Calendar'
import Cancel from '../svgs/Cancel'
import Certification from '../svgs/Certification'
import Check from '../svgs/Check'
import CircleCheck from '../svgs/CircleCheck'
import ClockIn from '../svgs/ClockIn'
import ClockOut from '../svgs/ClockOut'
import CloseChat from '../svgs/CloseChat'
import Company from '../svgs/Company'
import DistributionCenters from '../svgs/DistributionCenters'
import Dollar from '../svgs/Dollar'
import Dot from '../svgs/Dot'
import { DoubleBack } from '../svgs/DoubleBack'
import { DoubleForward } from '../svgs/DoubleForward'
import Download from '../svgs/Download'
import Dumbbell from '../svgs/Dumbbell'
import Edit from '../svgs/Edit'
import Ellipses from '../svgs/Ellipses'
import EllipsesFilled from '../svgs/EllipsesFilled'
import Equipment from '../svgs/Equipment'
import Eye from '../svgs/Eye'
import EyeSlash from '../svgs/EyeSlash'
import Filter from '../svgs/Filter'
import Flash from '../svgs/Flash'
import FoodPrepAndService from '../svgs/FoodPrepAndService'
import FoodProduction from '../svgs/FoodProduction'
import ForwardArrow from '../svgs/ForwardArrow'
import GraduationCap from '../svgs/GraduationCap'
import Heart from '../svgs/Heart'
import Info from '../svgs/Info'
import Language from '../svgs/Language'
import Left from '../svgs/Left'
import Lifting from '../svgs/Lifting'
import Link from '../svgs/Link'
import Location from '../svgs/Location'
import LocationPin from '../svgs/LocationPin'
import ManuAndProd from '../svgs/ManuAndProd'
import Manufacturing from '../svgs/Manufacturing'
import Menu from '../svgs/Menu'
import Message from '../svgs/Message'
import MiniCheck from '../svgs/MiniCheck'
import NoShow from '../svgs/NoShow'
import Pencil from '../svgs/Pencil'
import Plus from '../svgs/Plus'
import Reject from '../svgs/Reject'
import Reset from '../svgs/Reset'
import Right from '../svgs/Right'
import Search from '../svgs/Search'
import Send from '../svgs/Send'
import Share from '../svgs/Share'
import ShieldCheck from '../svgs/ShieldCheck'
import SimpleMessage from '../svgs/SimpleMessage'
import Star from '../svgs/Star'
import Subtract from '../svgs/Subtract'
import SubtractInverse from '../svgs/SubtractInverse'
import ThumbsDown from '../svgs/ThumbsDown'
import ThumbsUp from '../svgs/ThumbsUp'
import Training from '../svgs/Training'
import Trash from '../svgs/Trash'
import TwoUser from '../svgs/TwoUser'
import Unblock from '../svgs/Unblock'
import Unfavorite from '../svgs/Unfavorite'
import User from '../svgs/User'
import UserPlus from '../svgs/UserPlus'
import UserSettings from '../svgs/UserSettings'
import Warehousing from '../svgs/Warehousing'
import Clock from './Clock'

const DEFAULT_STROKE_WIDTH = 1.5
const DEFAULT_ICON_SIZE = 20

const iconSvgAssets: Record<IconName, any> = {
  add: Add,
  addInverse: AddInverse,
  alert: Alert,
  back: Back,
  doubleBack: DoubleBack,
  doubleForward: DoubleForward,
  calendar: Calendar,
  cancel: Cancel,
  check: Check,
  edit: Edit,
  info: Info,
  plus: Plus,
  left: Left,
  location: Location,
  right: Right,
  eye: Eye,
  eyeSlash: EyeSlash,
  trash: Trash,
  download: Download,
  circleCheck: CircleCheck,
  dot: Dot,
  dumbbell: Dumbbell,
  star: Star,
  link: Link,
  menu: Menu,
  message: Message,
  pencil: Pencil,
  forwardArrow: ForwardArrow,
  subtract: Subtract,
  subtractInverse: SubtractInverse,
  thumbsUp: ThumbsUp,
  thumbsDown: ThumbsDown,
  twoUser: TwoUser,
  userPlus: UserPlus,
  user: User,
  search: Search,
  ellipses: Ellipses,
  noShow: NoShow,
  reject: Reject,
  abandon: Abandon,
  unfavorite: Unfavorite,
  clock: Clock,
  clockIn: ClockIn,
  clockOut: ClockOut,
  heart: Heart,
  unblock: Unblock,
  block: Block,
  addChat: AddChat,
  reset: Reset,
  closeChat: CloseChat,
  filter: Filter,
  archived: Archived,
  send: Send,
  locationPin: LocationPin,
  certification: Certification,
  warehousing: Warehousing,
  foodPrepAndService: FoodPrepAndService,
  manuAndProd: ManuAndProd,
  training: Training,
  language: Language,
  lifting: Lifting,
  equipment: Equipment,
  briefcase: Briefcases,
  miniCheck: MiniCheck,
  simpleMessage: SimpleMessage,
  shieldCheck: ShieldCheck,
  share: Share,
  flash: Flash,
  ellipsesFilled: EllipsesFilled,
  graduationCap: GraduationCap,
  distributionCenters: DistributionCenters,
  foodProduction: FoodProduction,
  manufacturing: Manufacturing,
  userSettings: UserSettings,
  dollar: Dollar,
  company: Company,
}

export default function SvgIcon(props: SvgIconProps) {
  const {
    name,
    color,
    size = DEFAULT_ICON_SIZE,
    strokeWidth = DEFAULT_STROKE_WIDTH,
    style,
  } = props
  let iconColor = color
  if (color === 'brand' || !color) {
    iconColor = theme.colors.Violet
  }
  const Component = iconSvgAssets[name]

  return (
    <Component
      size={size}
      color={iconColor}
      strokeWidth={strokeWidth}
      {...props}
      style={{ ...style, ...(props.onClick ? { cursor: 'pointer' } : {}) }}
    />
  )
}
