import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'

import { Col, Row, SvgIcon, Text } from '../base'

export const LoginRightCol = () => {
  const { isMobileViewOrReactNative } = useMobile()

  return (
    <Row
      style={{
        borderRadius: '8px',
        border: '1px solid var(--violet-40, #a95cd9)',
        background: isMobileViewOrReactNative ? 'none' : theme.colors.White,
        padding: theme.space.sm,
      }}
    >
      <SvgIcon name="shieldCheck" size={60} />
      <Col mt={theme.space.xs} ml={theme.space.xxs}>
        <Text
          variant="h5"
          center
          color={theme.colors.Violet70}
          style={{
            textAlign: 'left',
          }}
        >
          Trusted by top workers and leading businesses in the Light Industrial
          space
        </Text>

        <Text
          variant="body1"
          style={{
            textAlign: 'left',
          }}
          mt={theme.space.xs}
          mb={theme.space.xxs}
        >
          “Traba has been a true partner to us. Their worker quality has been a
          differentiator for us. I do really think this is the future.”
        </Text>

        <Text variant="body1">HR Liaison, Lone Star Bakery</Text>
      </Col>
    </Row>
  )
}
