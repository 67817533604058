import { SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftRequest } from '@traba/types'
import { Row } from 'src/components'

interface ScheduleTileRolesInfoProps {
  shiftRequests: ShiftRequest[]
}

function createRoleDescriptionString(
  numOfRole: number,
  roleName: string,
  payRate: number,
) {
  const payRateWithDollarSign = `$${payRate}`
  const pluralIfNeeded = numOfRole > 1 ? 's' : ''
  return `${numOfRole} ${roleName}${pluralIfNeeded} @${payRateWithDollarSign}/hr`
}

export const ScheduleTileRolesInfo: React.FC<ScheduleTileRolesInfoProps> = ({
  shiftRequests,
}) => {
  return (
    <>
      {shiftRequests.map((shiftRequest: ShiftRequest, index: number) => {
        const isLast = index === shiftRequests.length - 1

        return (
          <Row
            key={`schedule-tile-roles-info-${shiftRequest.shiftRequestId}`}
            justifyBetween
            alignCenter
            style={{
              padding: theme.space.sm,
              borderBottom: isLast
                ? 'none'
                : `1px solid ${theme.colors.Grey20}`,
            }}
          >
            <Row alignCenter>
              <SvgIcon
                name={'user'}
                color={theme.colors.MidnightBlue}
                size={theme.space.xs}
              />
              <Text
                style={{ marginLeft: theme.space.xxs }}
                variant="body2"
                color={theme.colors.MidnightBlue}
              >
                {createRoleDescriptionString(
                  shiftRequest.minSlotsRequested || shiftRequest.slotsRequested,
                  shiftRequest.shiftRole,
                  shiftRequest.payRate,
                )}
              </Text>
            </Row>
          </Row>
        )
      })}
    </>
  )
}
