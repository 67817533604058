import styled, { css } from 'styled-components'

const cellHorizontalPadding = css`
  padding-left: 8px;
  padding-right: 8px;
  &:first-child {
    padding-left: 12px;
  }
  &:last-child {
    padding-right: 12px;
  }
`

const trBorderRadius = css`
  td:first-child,
  th:first-child {
    border-top-left-radius: ${({ theme }) => theme.border.radius};
    border-bottom-left-radius: ${({ theme }) => theme.border.radius};
  }
  td:last-child,
  th:last-child {
    border-top-right-radius: ${({ theme }) => theme.border.radius};
    border-bottom-right-radius: ${({ theme }) => theme.border.radius};
  }
`

export const TableContainer = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
`

export const TableHeader = styled.tr`
  background: ${({ theme }) => theme.colors.Grey10};
  ${trBorderRadius}
`

export const TableHeaderItem = styled.th`
  font-weight: 600;
  font-size: 10px;
  line-height: 160%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: left;
  color: ${({ theme }) => theme.colors.Grey50};
  padding-top: 12px;
  padding-bottom: 12px;
  ${cellHorizontalPadding}
`

export const TableBody = styled.tbody``

export const TableRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey20};
`
export const TableCell = styled.td`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.darker};
  padding-top: 12px;
  padding-bottom: 12px;
  ${cellHorizontalPadding}
`

export const TableFooter = styled.tr<{ noBorderRadius?: boolean }>`
  background: ${({ theme }) => theme.colors.Grey10};
  border-radius: ${({ theme }) => theme.border.radius};

  ${({ noBorderRadius }) => !noBorderRadius && trBorderRadius}
`

export const TableLoadingOrError = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ theme }) => theme.space.lg}px 0;
`
