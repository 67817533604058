import { Invoice, InvoiceStatus } from '@traba/types'
import { format, isBefore } from 'date-fns'
import { getMoneyString } from 'src/utils/moneyUtils'

export interface InvoiceTableRow {
  hostedInvoiceUrl?: string
  invoiceId: string
  stripeInvoiceNumber?: string
  status: InvoiceStatus
  dueDate: Date
  cells: string[]
  stripeInvoiceId: string
}

export const headers = [
  'INVOICE ID',
  'PERIOD START',
  'PERIOD END',
  'LINE ITEMS',
  'INVOICE AMOUNT',
  'DUE DATE',
  'INVOICE GROUP',
  'STATUS',
  '',
]

export function getPeriodFromInvoice(invoice: Invoice) {
  if (!invoice.startDate || !invoice.endDate) {
    const sorted = invoice.lineItems.sort(
      (a, b) => a.date.getTime() - b.date.getTime(),
    )

    return {
      periodStart: sorted[0].date,
      periodEnd: sorted[sorted.length - 1].date,
    }
  }
  return {
    periodStart: invoice.startDate,
    periodEnd: invoice.endDate,
  }
}

export function filterInvoices(allInvoices: Invoice[]) {
  const allVisibleInvoices: InvoiceTableRow[] = [] // All invoices not draft or void.
  const outstandingInvoiceTableData: InvoiceTableRow[] = [] // All visible invoices that are not paid (due date in past or future)
  const pastDueInvoiceTableData: InvoiceTableRow[] = [] // All visible invoices that are not paid + a due date in the past
  const paidInvoiceTableData: InvoiceTableRow[] = [] // All visible invoices that are paid (due date in past or future)

  allInvoices.forEach((invoice) => {
    if (
      invoice.status === InvoiceStatus.DRAFT ||
      invoice.status === InvoiceStatus.VOID
    ) {
      return
    }

    if (invoice.status === InvoiceStatus.PAID) {
      paidInvoiceTableData.push(convertToRowData(invoice))
    } else {
      // Invoices that are already past due
      if (isBefore(invoice.dueDate, new Date())) {
        pastDueInvoiceTableData.push(convertToRowData(invoice))
      }
      // Add to outstanding regardless of whether they are past due or not
      outstandingInvoiceTableData.push(convertToRowData(invoice))
    }

    allVisibleInvoices.push(convertToRowData(invoice))
  })

  return {
    allVisibleInvoices,
    outstandingInvoiceTableData,
    pastDueInvoiceTableData,
    paidInvoiceTableData,
  }
}

function convertToRowData(invoice: Invoice): InvoiceTableRow {
  const { periodStart, periodEnd } = getPeriodFromInvoice(invoice)

  return {
    hostedInvoiceUrl: invoice.hostedInvoiceUrl,
    invoiceId: invoice.invoiceId,
    stripeInvoiceNumber: invoice.stripeInvoiceNumber,
    stripeInvoiceId: invoice.stripeInvoiceId,
    dueDate: invoice.dueDate,
    status: invoice.status,
    cells: [
      `#${invoice.stripeInvoiceNumber}`,
      format(periodStart, 'M/dd/yyyy'),
      format(periodEnd, 'M/dd/yyyy'),
      invoice.lineItems.length > 1
        ? `${invoice.lineItems.length} Items`
        : `${invoice.lineItems.length} Item`,
      getMoneyString(invoice.totalCharge),
      format(invoice.dueDate, 'M/dd/yyyy'),
      `${invoice.invoiceGroupName ? invoice.invoiceGroupName : '-'}`,
    ],
  }
}
