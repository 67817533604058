import { SvgIconProps } from '@traba/types'

export function UpArrow({ size, color, strokeWidth, ...rest }: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8 14L8 3M8 3L4 7.58824M8 3L12 7.58824"
        stroke={color}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
