import { AlertIcon } from 'src/components/assets/svg'
import { theme } from 'src/libs/theme'

import { InformationIcon } from '../Input/Input.styles'
import Row from '../Row'
import SvgIcon from '../SvgIcon'
import { Text } from '../Text'
import * as S from './styles'

export const InlineBanner = ({
  text,
  severity,
  subTitle,
  ...props
}: S.InlineBannerProps) => {
  return (
    <S.InlineBanner severity={severity} {...props}>
      <SvgIcon
        size={20}
        strokeWidth={2}
        color={
          severity === 'error'
            ? theme.colors.Red70
            : severity === 'warning'
              ? theme.colors.Orange70
              : severity === 'success'
                ? theme.colors.Green70
                : theme.colors.Blue80
        }
        name="info"
        style={{ flexShrink: 0 }}
      />
      <div style={{ marginLeft: theme.space.xxs }}>
        <Text
          variant="body1"
          style={{ fontWeight: 500, fontSize: 12, lineHeight: '20px' }}
        >
          {text}
        </Text>
        {subTitle && (
          <Text variant="body1" style={{ fontSize: 12, lineHeight: '20px' }}>
            {subTitle}
          </Text>
        )}
      </div>
    </S.InlineBanner>
  )
}

export function InlineMessage({ text }: { text: string }) {
  return (
    <Row alignCenter>
      <InformationIcon>
        <AlertIcon color={theme.colors.Grey50} />
      </InformationIcon>
      <Text variant="body2">{text}</Text>
    </Row>
  )
}
