import React from 'react'
import { useAppContext } from 'src/context/appContext/AppContext'
import { theme } from 'src/libs/theme'
import { Text } from '..'

interface Props {
  isMobileView: boolean
  extraText?: string
  style?: React.CSSProperties
}

export const EmptyDataLocationSubtitle: React.FC<Props> = (props) => {
  const { dispatch: appDispatch } = useAppContext()
  return (
    <Text
      variant="body2"
      className="xs-hide md-show"
      style={
        props.style || {
          textAlign: 'center',
          marginTop: theme.space.xxxs,
        }
      }
    >
      <Text variant="body2">
        <button
          onClick={() => {
            appDispatch({ type: 'SHOW_REGIONAL_FILTER' })
          }}
          style={{
            backgroundColor: 'white',
            color: theme.colors.brand,
            cursor: 'pointer',
            fontSize: 'inherit',
          }}
        >
          Select locations in the filter
        </button>
        {props.isMobileView ? ' in the top' : ' in the top left'}{' '}
        {props.extraText}
      </Text>
    </Text>
  )
}
