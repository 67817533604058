import { SvgIconProps } from '@traba/types'

export function DownArrow({ size, color, strokeWidth, ...rest }: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8 2L8 13M8 13L12 8.41176M8 13L4 8.41176"
        stroke={color}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
