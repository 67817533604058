import * as Sentry from '@sentry/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import { useHotSettings } from '@traba/hooks'
import { UserRole } from '@traba/types'
import {
  assignedActiveLocationsForMember,
  canManageUserByLocationAccess,
  isBizMemberCompanyWide,
} from '@traba/utils'
import { AxiosError } from 'axios'
import { useMemo } from 'react'
import { useUserContext } from 'src/context/user/UserContext'
import { UserProfile, UserRolePermission } from 'src/types'
import { hasPermissions } from 'src/utils/userUtils'
import { useLocations } from './useLocations'
import { useInvitations, useMembers } from './useMembers'

const USER_QUERY_KEY = 'user'

const getUser = async () => {
  try {
    const response = await trabaApi.get(`/me`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const updateUser = async (
  updatedUser: Partial<UserProfile>,
): Promise<UserProfile> => {
  try {
    return await trabaApi.patch(`/me`, updatedUser)
  } catch (error) {
    console.log(error)
    Sentry.captureException(error)
    throw error
  }
}

export const useUser = () => {
  const queryClient = useQueryClient()
  const { handleError } = useAlert()

  const {
    isLoading,
    isError,
    data: user,
    error,
    isFetched,
    refetch,
  } = useQuery<UserProfile, Error>({
    queryKey: [USER_QUERY_KEY],
    queryFn: getUser,
    staleTime: FIVE_MINUTES_IN_MS,
    refetchOnWindowFocus: 'always',
  })

  const patchUserMutation = useMutation<
    UserProfile,
    AxiosError,
    Partial<UserProfile>,
    UserProfile
  >({
    mutationFn: updateUser,
    onSuccess: (data: any) => {
      queryClient.setQueryData([USER_QUERY_KEY], data.data)
    },
    onError: (error) => {
      handleError(
        error,
        'useUser -> patchUserMutation()',
        'There was an error updating your profile. Please try again or contact support if the issue persists.',
        'Error updating user profile',
      )
    },
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    user,
    patchUser: patchUserMutation.mutate,
    refetch,
  }
}

export function useUserPermissions(requiredPermissions: UserRolePermission[]) {
  const userContext = useUserContext()
  return hasPermissions(userContext.state.userProfile, requiredPermissions)
}

export function useUserCanManageUsers() {
  const userContext = useUserContext()
  return hasPermissions(userContext.state.userProfile, [
    UserRolePermission.ManageUserRoles,
  ])
}

export function useActiveCompanyLocationsForUser() {
  const { user, isLoading: loadingUser } = useUser()
  const { activeValidLocations, isLoading: loadingLocations } = useLocations()

  const activeLocationsForUser = useMemo(
    () =>
      user
        ? isBizMemberCompanyWide(user)
          ? activeValidLocations
          : assignedActiveLocationsForMember(user)
        : [],
    [user, activeValidLocations],
  )

  const activeLocationIdsForUser = useMemo(
    () => new Set(activeLocationsForUser.map((loc) => loc.locationId)),
    [activeLocationsForUser],
  )

  return {
    activeLocationsForUser,
    activeLocationIdsForUser,
    isLoading: loadingUser || loadingLocations,
  }
}

export function useUserCanEditRoles() {
  const { user } = useUser()
  // ManagerUserRoles permission is not applicable as that is for managing roles
  // attached to business app users
  return (
    useUserPermissions([UserRolePermission.ManageShifts]) &&
    user?.role !== UserRole.LimitedShiftCreator
  )
}

export function useUserCanEditCompanyWideRoles() {
  const { hotSettings } = useHotSettings()
  const { user } = useUser()
  const userCanEditShiftRoles = useUserCanEditRoles()
  const isUserCompanyWide = user && isBizMemberCompanyWide(user)
  return (
    userCanEditShiftRoles &&
    (isUserCompanyWide || !hotSettings?.enableRegionalAccessPhase2)
  )
}

export function useMemberLocationAccessManagement() {
  const { user } = useUser()
  const { members } = useMembers()
  const { activeLocationIdsForUser } = useActiveCompanyLocationsForUser()

  const memberIdsManageableByUserLocationAccess = useMemo(() => {
    return members.reduce((acc, member) => {
      if (!member.uid) {
        return acc
      }

      const memberLocationIds = assignedActiveLocationsForMember(member).map(
        (location) => location.locationId,
      )

      if (
        canManageUserByLocationAccess({
          currentUserAccessLevel: user?.userAccessLevel,
          targetUserAccessLevel: member.userAccessLevel,
          targetLocationIds: memberLocationIds,
          activeLocationIdsForCurrentUser: activeLocationIdsForUser,
        })
      ) {
        acc.add(member.uid)
      }
      return acc
    }, new Set<string>())
  }, [user?.userAccessLevel, members, activeLocationIdsForUser])

  return { memberIdsManageableByUserLocationAccess }
}

export function useInvitationLocationAccessManagement() {
  const { user } = useUser()
  const { invitations = [] } = useInvitations()
  const { activeLocationIdsForUser } = useActiveCompanyLocationsForUser()

  const invitationIdsManageableByUserLocationAccess = useMemo(() => {
    return invitations.reduce((acc, invitation) => {
      const invitationLocationIds = assignedActiveLocationsForMember(
        invitation,
      ).map((location) => location.locationId)

      if (
        canManageUserByLocationAccess({
          currentUserAccessLevel: user?.userAccessLevel,
          targetUserAccessLevel: invitation.userAccessLevel,
          targetLocationIds: invitationLocationIds,
          activeLocationIdsForCurrentUser: activeLocationIdsForUser,
        })
      ) {
        acc.add(invitation.invitationId)
      }
      return acc
    }, new Set<string>())
  }, [user?.userAccessLevel, invitations, activeLocationIdsForUser])

  return { invitationIdsManageableByUserLocationAccess }
}
