import { Text } from '@traba/react-components'
import { DailyViewOfScheduleDetails } from '@traba/types'
import { ScheduleDailyViewEstimatedPayContent } from './ScheduleDailyViewEstimatedPayContent'

interface Props {
  shiftRequestParentDayDetails: DailyViewOfScheduleDetails | undefined
}

export const ScheduleDailyViewEstimatedPay = (props: Props) => {
  const { shiftRequestParentDayDetails } = props

  return (
    <>
      <Text variant="h4">Estimated cost</Text>
      <Text variant="body2">
        View the break down of estimated cost of each shift.
      </Text>
      {Object.values(
        shiftRequestParentDayDetails?.shiftRequestToShiftMetadataMap ?? {},
      ).map((individualMetadata) => (
        <ScheduleDailyViewEstimatedPayContent
          individualMetadata={individualMetadata}
          shiftId={individualMetadata.shift.shiftId}
          key={individualMetadata.shift.shiftId}
        />
      ))}
    </>
  )
}
