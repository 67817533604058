import {
  MAX_ANNOUNCEMENT_MESSAGE_LENGTH,
  END_TIME_BUFFER_FOR_NEW_ANNOUNCEMENTS_IN_HOURS,
  MIN_MINUTES_FOR_FUTURE_ANNOUNCEMENT,
  SCHEDULING_OPTION,
} from '@traba/consts'
import { useAlert } from '@traba/context'
import { FileType, useFileUploader } from '@traba/hooks'
import { MediaUploader } from '@traba/react-components'
import { CardTile } from '@traba/react-components'
import { LocationResponse, Shift } from '@traba/types'
import { InputStatus } from '@traba/types'
import { addHours, addMinutes } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import {
  Button,
  ButtonVariant,
  Col,
  Input,
  Row,
  SvgIcon,
  Text,
} from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { IMenuItem, SelectDropdown } from 'src/components/base/Select/Select'
import SelectableCard from 'src/components/base/SelectableCard'
import useMobile from 'src/hooks/useMobile'
import { useShiftAnnouncements } from 'src/hooks/useShiftAnnouncements'
import { useUser } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { getAnnouncementPrefillSuggestions } from 'src/utils/announcementUtils'

const SCHEDULING_OPTIONS: IMenuItem[] = [
  {
    label: SCHEDULING_OPTION.SEND_NOW,
    value: SCHEDULING_OPTION.SEND_NOW,
  },
  {
    label: SCHEDULING_OPTION.SCHEDULE_FOR_FUTURE,
    value: SCHEDULING_OPTION.SCHEDULE_FOR_FUTURE,
  },
]

interface Props {
  shift: Shift
  onSubmitOrCancel: () => void
  location: LocationResponse | undefined
  parkingLocation: LocationResponse | undefined
  onClickExpandShiftDetail?: () => void
}

export const AddAnnouncementSection = ({
  shift,
  onSubmitOrCancel,
  location,
  parkingLocation,
  onClickExpandShiftDetail,
}: Props) => {
  const { isMobileViewOrReactNative, isReactNativeApp } = useMobile()

  const [content, setContent] = useState('')
  const [file, setFile] = useState<File | undefined>()
  const [imageUrl, setImageUrl] = useState<string | undefined>()
  const [suggestions, setSuggestions] = useState(
    getAnnouncementPrefillSuggestions(shift, location, parkingLocation),
  )
  const { user } = useUser()
  const { handleUpload } = useFileUploader()
  const { showError, handleError, showSuccess } = useAlert()
  const { refetchAnnouncements, sendAnnouncement, isSendingAnnouncement } =
    useShiftAnnouncements(shift.id)
  const [selectedSchedulingOption, setSelectedSchedulingOption] =
    useState<SCHEDULING_OPTION>(SCHEDULING_OPTION.SEND_NOW)
  const [scheduledFor, setScheduledFor] = useState<Date | null>(null)
  const [showScheduledForDatePicker, setShowScheduledForDatePicker] =
    useState<boolean>(false)

  useEffect(() => {
    setSuggestions(
      getAnnouncementPrefillSuggestions(shift, location, parkingLocation),
    )
  }, [shift, location, parkingLocation])

  useMemo(() => {
    switch (selectedSchedulingOption) {
      case SCHEDULING_OPTION.SEND_NOW: {
        setShowScheduledForDatePicker(false)
        setScheduledFor(null)
        break
      }
      case SCHEDULING_OPTION.SCHEDULE_FOR_FUTURE: {
        setShowScheduledForDatePicker(true)
        break
      }
    }
  }, [selectedSchedulingOption])

  async function onChangeFile(f: File | undefined) {
    setFile(f)
    if (f) {
      const url = await handleUpload({
        fileType: FileType.SHIFT_IMAGES,
        media: f,
        userId: user?.uid,
      })
      setImageUrl(url)
    }
  }

  async function onDeleteFile() {
    setFile(undefined)
    setImageUrl(undefined)
  }

  function getMinDateForScheduleAhead() {
    return addMinutes(new Date(), MIN_MINUTES_FOR_FUTURE_ANNOUNCEMENT)
  }

  function getMaxDateForScheduleAhead() {
    return addHours(
      shift.endTime,
      END_TIME_BUFFER_FOR_NEW_ANNOUNCEMENTS_IN_HOURS,
    )
  }

  const datePicker = (
    <DatePicker
      showTimeFieldInPopover={true}
      setDate={(dateTime) => {
        setScheduledFor(dateTime)
      }}
      isClearable={false}
      inlineLabel={true}
      label="When?"
      date={scheduledFor}
      defaultDate={new Date()}
      timezone={shift.timezone}
      minDate={getMinDateForScheduleAhead()}
      maxDate={getMaxDateForScheduleAhead()}
    />
  )

  return (
    <div style={{ flex: 1 }}>
      <Col pb={theme.space.sm}>
        <div style={{ marginBottom: theme.space.xs }}>
          <Row mb={theme.space.ms}>
            <Text variant="h6">Schedule your announcement</Text>
          </Row>
          <Row>
            <SelectDropdown
              handleSelect={(value) =>
                setSelectedSchedulingOption(value as SCHEDULING_OPTION)
              }
              menuItems={SCHEDULING_OPTIONS}
              value={selectedSchedulingOption}
              style={{
                minWidth: 205,
              }}
            />
            {showScheduledForDatePicker && !isMobileViewOrReactNative && (
              <div style={{ marginLeft: theme.space.xs }}>{datePicker}</div>
            )}
          </Row>
          {showScheduledForDatePicker && isMobileViewOrReactNative && (
            <Row>
              <div style={{ marginTop: theme.space.xs }}>{datePicker}</div>
            </Row>
          )}
        </div>
        <Row justifyBetween>
          <Text variant="h6" mt={theme.space.xs}>
            Quick fills
          </Text>
          {onClickExpandShiftDetail && (
            <CardTile size={'40px'} onClick={onClickExpandShiftDetail}>
              <SvgIcon name="doubleBack" color={theme.colors.Grey50} />
            </CardTile>
          )}
        </Row>
        <Text variant="body2" mb={theme.space.xs}>
          Select from below to pre-fill the announcement.
        </Text>
        <Row
          wrap
          style={{
            gap: isReactNativeApp ? theme.space.xs : theme.space.med,
          }}
          mb={theme.space.xxs}
        >
          {suggestions.map((suggestion) => (
            <SelectableCard
              onMouseEnter={() => {
                if (!suggestion.selected) {
                  setContent(`${content}${suggestion.content}`)
                }
              }}
              onMouseLeave={() => {
                if (!suggestion.selected) {
                  setContent(content.replace(suggestion.content, ''))
                }
              }}
              key={suggestion.title}
              label={suggestion.title}
              onClick={() => {
                if (suggestion.selected) {
                  setContent(content.replace(suggestion.content, ''))
                }
                setSuggestions(
                  suggestions.map((s) =>
                    s.title === suggestion.title
                      ? { ...s, selected: !s.selected }
                      : s,
                  ),
                )
              }}
              selected={suggestion.selected}
            />
          ))}
        </Row>
        <Input
          rows={5}
          label="Your message to workers on this shift"
          type="textarea"
          className="xs-12"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          inputStatus={
            content && content.length > MAX_ANNOUNCEMENT_MESSAGE_LENGTH
              ? InputStatus.error
              : InputStatus.default
          }
          errorMessage={`Please limit your message to under ${MAX_ANNOUNCEMENT_MESSAGE_LENGTH} characters. You have used ${content.length} characters.`}
        />
        <MediaUploader
          label="Add image"
          fileType="image"
          file={file}
          onChange={onChangeFile}
          onDelete={onDeleteFile}
          onError={showError}
          maxFileSizeMB={10}
          initialPreviewSrc={imageUrl}
        />
        <Row justifyEnd mt={theme.space.sm}>
          <Button
            onClick={onSubmitOrCancel}
            variant={ButtonVariant.OUTLINED}
            style={{ marginRight: theme.space.xxs }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              content === '' ||
              content.length > MAX_ANNOUNCEMENT_MESSAGE_LENGTH ||
              (selectedSchedulingOption ===
                SCHEDULING_OPTION.SCHEDULE_FOR_FUTURE &&
                !scheduledFor)
            }
            onClick={() =>
              sendAnnouncement({
                shiftId: shift.id,
                content,
                imageUrl,
                scheduledFor: scheduledFor ?? new Date(),
                onSubmitOrCancel,
                showSuccess,
                handleError,
                refetch: refetchAnnouncements,
              })
            }
            variant={ButtonVariant.FILLED}
            style={{
              paddingLeft: theme.space.med,
              paddingRight: theme.space.med,
            }}
            loading={isSendingAnnouncement}
          >
            Send
          </Button>
        </Row>
      </Col>
    </div>
  )
}
