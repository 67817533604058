import { useAlert } from '@traba/context'
import { LocationResponse } from '@traba/types'
import { isEqual } from 'lodash'
import { useMemo, useState, useCallback } from 'react'
import {
  LocationData,
  LocationSearchAndSelect,
} from 'src/components/LocationSearchAndSelect/LocationSearchAndSelect'
import { useAppContext } from 'src/context/appContext/AppContext'
import { useUserContext } from 'src/context/user/UserContext'
import { useLocations } from 'src/hooks/useLocations'
import {
  getRegionalFilterFeatureKey,
  useRegionalFilterSelectAll,
  useSelectedRegionalFilterLocations,
} from 'src/hooks/useRegionalFilter'
import { theme } from 'src/libs/theme'
import { WebToRNEventName } from 'src/types/events'
import { Button, ButtonVariant } from '../Button'
import Row from '../Row'
import SvgIcon from '../SvgIcon'
import { Text } from '../Text'

export const RegionalFilter: React.FC = () => {
  const {
    state: { userProfile },
  } = useUserContext()
  const { state: appState, dispatch: appDispatch } = useAppContext()
  const { showSuccess, showError } = useAlert()
  const { enableRegionalFilterSelectAll, disableRegionalFilterSelectAll } =
    useRegionalFilterSelectAll()
  const { selectedLocationIds } = useSelectedRegionalFilterLocations()
  const { activeValidLocations } = useLocations()

  const [selectedLocations, setSelectedLocations] =
    useState<Set<string>>(selectedLocationIds)
  const locationSelectionChanged = useMemo(() => {
    return !isEqual(selectedLocations, selectedLocationIds)
  }, [selectedLocations, selectedLocationIds])

  const [locationData, setLocationData] = useState<LocationData | null>(null)

  const handleLocationsLoaded = useCallback((data: LocationData) => {
    setLocationData(data)
  }, [])

  const handleEditedLocation = (
    locationId: string,
    newStoredRegionalFilter: Record<string, string[]>,
  ) => {
    const editedLocation = locationData?.uniqueLocations.find(
      (l) => l.locationId === locationId,
    )
    if (editedLocation && editedLocation.activeLocationId) {
      if (!newStoredRegionalFilter[editedLocation.regionId]) {
        newStoredRegionalFilter[editedLocation.regionId] = []
      }
      newStoredRegionalFilter[editedLocation.regionId].push(
        editedLocation.activeLocationId,
      )
    }
  }

  const handleValidLocation = (
    locationData: LocationResponse,
    newStoredRegionalFilter: Record<string, string[]>,
  ) => {
    if (!newStoredRegionalFilter[locationData.regionId]) {
      newStoredRegionalFilter[locationData.regionId] = []
    }
    newStoredRegionalFilter[locationData.regionId].push(locationData.locationId)
  }

  const saveSelectedLocations = useCallback(async () => {
    if (!locationData) {
      return
    }

    if (selectedLocations.size === 0) {
      showError('Please select at least one location')
      return
    }

    const { validLocations } = locationData

    // Set select all option to true if all locations are selected, otherwise, disable
    validLocations.length === selectedLocations.size
      ? enableRegionalFilterSelectAll()
      : disableRegionalFilterSelectAll()

    // RegionId to locationIds map
    const newStoredRegionalFilter: Record<string, string[]> = {}

    // Construct the new newStoredRegionalFilter from selectedLocations
    for (const locationId of selectedLocations) {
      const locationData = validLocations.find(
        (l) => l.locationId === locationId,
      )

      // If location is not found, check if the location was edited
      // If there are non-active locations that are selected, we check if the location was edited and set the new edited location id (activeLocationId) in filter
      if (!locationData) {
        handleEditedLocation(locationId, newStoredRegionalFilter)
        continue
      }

      handleValidLocation(locationData, newStoredRegionalFilter)
    }
    if (locationSelectionChanged) {
      // Update the state in case there is edited location
      setSelectedLocations(
        new Set(Object.values(newStoredRegionalFilter).flat()),
      )

      // Update the local storage and context
      appDispatch({
        type: 'SAVE_REGIONAL_FILTER',
        value: newStoredRegionalFilter,
        uid: userProfile?.uid ?? '', // User logged in so userProfile should not be null
      })
      // Reload the webview screens in supervisor app to fetch new data
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          event: WebToRNEventName.RELOAD_WEBVIEW_SCREENS,
        }),
      )
    }

    showSuccess(
      'Your default location settings have been updated successfully.',
    )
    appDispatch({ type: 'HIDE_REGIONAL_FILTER' })
    window.analytics.track(`User Clicked Save Regional Filter`, {
      selectedLocations: selectedLocations,
    })

    // Mark that the user used regional filter feature so that they don't see the announcement again
    localStorage.setItem(
      getRegionalFilterFeatureKey(userProfile?.uid ?? ''),
      new Date().toISOString(),
    )
  }, [
    locationData,
    selectedLocations,
    appDispatch,
    userProfile,
    showSuccess,
    enableRegionalFilterSelectAll,
    disableRegionalFilterSelectAll,
    appState.regionalFilter,
  ])

  const childButtons = (
    <Row justifyEnd>
      <Button
        onClick={() => appDispatch({ type: 'HIDE_REGIONAL_FILTER' })}
        variant={ButtonVariant.OUTLINED}
        style={{
          fontSize: appState.isReactNativeApp ? 'small' : undefined,
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={saveSelectedLocations}
        style={{
          marginLeft: theme.space.xs,
          marginRight: theme.space.xs,
          fontSize: appState.isReactNativeApp ? 'small' : undefined,
        }}
        disabled={activeValidLocations.length === 0}
      >
        Save
      </Button>
    </Row>
  )

  return (
    <>
      <Row
        justifyBetween
        alignCenter
        fullWidth
        style={{
          backgroundColor: theme.colors.White,
          borderBottomColor: theme.colors.Grey20,
          borderBottomWidth: 1,
          borderStyle: 'solid',
          paddingBottom: theme.space.xs,
        }}
      >
        <Text
          variant="h5"
          style={{
            marginLeft: theme.space.xs,
            marginTop: theme.space.xxs,
          }}
        >
          Select your default location
        </Text>
        <SvgIcon
          name={'cancel'}
          color={theme.colors.Grey60}
          size={20}
          style={{
            marginRight: theme.space.xs,
          }}
          onClick={() => appDispatch({ type: 'HIDE_REGIONAL_FILTER' })}
        />
      </Row>
      <LocationSearchAndSelect
        selectedLocations={selectedLocations}
        setSelectedLocations={setSelectedLocations}
        onLocationsLoaded={handleLocationsLoaded}
        children={childButtons}
      />
    </>
  )
}
