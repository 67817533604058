import { theme } from '@traba/theme'

export function ChevronRight({
  color = '#7A8A99',
  size,
  ...props
}: {
  color: string
  size?: number
}) {
  return (
    <svg
      width={size ?? theme.space.xs}
      height={size ?? theme.space.xs}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 3L10 8L5 13"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
