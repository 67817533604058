import {
  MODAL_SIZE,
  Button,
  ButtonVariant,
  SvgIcon,
} from '@traba/react-components'
import { HTMLProps, useCallback, useState } from 'react'
import { Modal } from 'src/components'
import { theme } from 'src/libs/theme'
import { MemberRoleInfoModal } from '../Modals/MemberRoleInfoModal'

interface MemberRoleInfoButtonProps extends HTMLProps<HTMLDivElement> {
  hideIcon?: boolean
}

export function MemberRoleInfoButton({
  hideIcon,
  ...divProps
}: MemberRoleInfoButtonProps) {
  const [showMemberRoleInfoModal, setShowMemberRoleInfoModal] = useState(false)
  const onOpenModal = useCallback(() => {
    setShowMemberRoleInfoModal(true)
    window.analytics.track(`User Clicked Learn More About Member Roles`)
  }, [])
  const onCloseModal = useCallback(() => {
    setShowMemberRoleInfoModal(false)
  }, [])
  return (
    <>
      <div {...divProps}>
        <Button
          variant={ButtonVariant.BRANDLINK}
          leftIcon={hideIcon ? undefined : <SvgIcon name="info" />}
          iconWidth={20}
          onClick={onOpenModal}
          iconPadding={`${theme.space.xxxs}px`}
          style={{ fontSize: 12 }}
        >
          Learn more about team member roles
        </Button>
      </div>
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showMemberRoleInfoModal}
        handleClose={onCloseModal}
      >
        <MemberRoleInfoModal handleClose={onCloseModal} />
      </Modal>
    </>
  )
}
