import { Button, ButtonVariant } from '@traba/react-components'
import { useNavigate } from 'react-router-dom'
import { EmptyPlaceholderTile } from 'src/components/base'

interface MissingShiftTileProps {
  title?: string
  shiftRequestParentId?: string
  style?: React.CSSProperties
}

export const MissingShiftTile = (props: MissingShiftTileProps) => {
  const {
    title = 'This shift does not exist',
    shiftRequestParentId,
    style,
  } = props
  const navigate = useNavigate()
  return (
    <EmptyPlaceholderTile
      iconName="info"
      title={title}
      subtitle={
        <Button
          variant={ButtonVariant.BRANDLINK}
          onClick={() =>
            navigate(
              shiftRequestParentId
                ? `/schedule/${shiftRequestParentId}`
                : '/schedules',
            )
          }
        >
          Go back to schedules
        </Button>
      }
      style={style}
    />
  )
}
