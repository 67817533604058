import { useAlert } from '@traba/context'
import { MODAL_SIZE } from '@traba/react-components'
import { Worker } from '@traba/types'
import { useForm } from '@traba/utils'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Input } from 'src/components/base'
import { Dialog } from 'src/components/base/Dialog/Dialog'
import { SelectWorkers } from 'src/components/SelectWorkers/SelectWorkers'
import { WorkerDetails } from 'src/hooks/useCompanyWorkers'
import { useRosters } from 'src/hooks/useRosters'
import { theme } from 'src/libs/theme'
import * as yup from 'yup'

export function RosterModal({
  initialSelectedWorkers,
  workers,
  isOpen,
  setIsOpen,
  onRosterCreated,
}: {
  initialSelectedWorkers?: Set<string>
  workers?: (WorkerDetails | Worker)[]
  isOpen: boolean
  setIsOpen: (value: React.SetStateAction<boolean>) => void
  onRosterCreated?: () => void
}) {
  const { createRoster } = useRosters()
  const [selectedWorkers, setSelectedWorkers] = useState(
    initialSelectedWorkers ? initialSelectedWorkers : new Set<string>(),
  )
  const { showSuccess } = useAlert()
  const onClickWorker = (workerId: string) => {
    const newWorkers = new Set(selectedWorkers)
    if (selectedWorkers.has(workerId)) {
      newWorkers.delete(workerId)
    } else {
      newWorkers.add(workerId)
    }
    setSelectedWorkers(newWorkers)
  }
  const { handleOnSubmitError } = useForm()
  const { handleError } = useAlert()

  useEffect(() => {
    initialSelectedWorkers && setSelectedWorkers(initialSelectedWorkers)
  }, [initialSelectedWorkers])

  const hasSelectedWorkers = selectedWorkers.size > 0

  /**
   * 'shiftHistory' check is necessary because this modal is used on screens where we have
   * a) worker as WorkerDetails (on WorkersScreen)
   * b) worker as Worker (from the worker shift response on TimeSheetDetailsScreen, UpcomingShiftDetails)
   */
  const getWorkerId = (worker: WorkerDetails | Worker) => {
    return 'worker' in worker ? worker.worker.uid : worker.uid
  }

  const workersAtTop =
    workers?.filter((worker) =>
      initialSelectedWorkers?.has(getWorkerId(worker)),
    ) || []
  const workersToDisplay: (WorkerDetails | Worker)[] | undefined =
    workers?.filter(
      (worker) => !initialSelectedWorkers?.has(getWorkerId(worker)),
    )
  workersToDisplay?.unshift(...workersAtTop)

  const formik = useFormik({
    initialValues: { rosterName: '' },
    validationSchema: yup.object({
      rosterName: yup.string().required('Please enter a roster name'),
    }),
    onSubmit: async (values) => {
      try {
        const reqBody = {
          ...values,
          workerIds: Array.from(selectedWorkers),
        }
        window.analytics?.track('User Clicked Create Roster', { ...reqBody })
        formik.setStatus({})
        createRoster(reqBody, {
          onError: (error) => {
            const message =
              'There was an error creating the roster. Please try again or contact support if the issue persists.'
            handleError(
              error,
              'RosterModal -> createRoster()',
              message,
              'Error creating roster',
            )
          },
        })
        setSelectedWorkers(new Set())
        setIsOpen(false)
        onRosterCreated && onRosterCreated()
        showSuccess('', 'Roster created!')
        formik.resetForm()
      } catch (err) {
        handleOnSubmitError(err, formik)
      }
    },
  })
  const { errors, touched } = formik

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="paper"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onConfirmCTA="Create Roster"
      dialogTitle="New Roster"
      confirmDisabled={!hasSelectedWorkers || !formik.isValid}
      formId="create-roster"
      size={MODAL_SIZE.LARGE}
    >
      <form id="create-roster" onSubmit={formik.handleSubmit}>
        <Input
          placeholder="Roster Name"
          containerStyle={{
            marginTop: theme.space.xxs,
            marginBottom: theme.space.xs,
          }}
          width="100%"
          {...formik.getFieldProps('rosterName')}
          errorMessage={errors.rosterName}
          inputStatus={
            touched.rosterName && errors.rosterName && formik.touched ? 3 : 1
          }
        />
        <SelectWorkers
          selectedWorkers={selectedWorkers}
          setSelectedWorkers={setSelectedWorkers}
          onClickWorker={onClickWorker}
          workers={workersToDisplay}
        />
      </form>
    </Dialog>
  )
}
