/**
 * Rounds a number to a specific number of decimal places.
 * For example:
 *   roundToDecimalPlaces(28.999, 4) => 28.999
 *   roundToDecimalPlaces(28.9999, 4) => 28.9999
 *   roundToDecimalPlaces(28.99999, 4) => 29
 */
export function roundToDecimalPlaces(num: number, decimalPlaces: number) {
  const factor = Math.pow(10, decimalPlaces)
  return Math.round(num * factor) / factor
}

/**
 * Converts MegaBytes to Bytes
 * @param sizeInMB size in MB
 * @returns size in Bytes
 */
export function megabytesToBytes(sizeInMB: number) {
  return sizeInMB * 1000000
}
