import { UserState, UserDispatch } from '@traba/types'
import { createContext, useContext } from 'react'

export const initialState: UserState = {
  userProfile: null,
  isLoadingUser: true,
  isUserLoggedIn: false,
}

export const UserContext = createContext<{
  state: UserState
  dispatch: UserDispatch
}>({
  state: initialState,
  dispatch: () => null,
})

export function useUserContext() {
  return useContext(UserContext)
}
