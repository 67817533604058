import { InvoiceDisclaimerAnchor } from './InvoiceDisclaimerText.styles'

interface InvoiceDisclaimerTextProps {
  isW2Company?: boolean
}

const InvoiceDisclaimerText = (props: InvoiceDisclaimerTextProps) => {
  const { isW2Company } = props
  return (
    <>
      <br />
      Invoices not paid by their due date will be subject to{' '}
      <InvoiceDisclaimerAnchor
        href={`https://www.traba.work/customer-agreement/${isW2Company ? 'laborthatworks' : 'traba'}`}
      >
        late fees of 1.5% per month.
      </InvoiceDisclaimerAnchor>{' '}
    </>
  )
}

export default InvoiceDisclaimerText
