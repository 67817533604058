import { ModalPaddingContainer } from '@traba/react-components'
import React from 'react'
import { Text } from 'src/components/base'
import { CreatePayment } from 'src/components/CreatePayment'

import { ButtonIcon } from '../../PersonalProfile/PersonalProfile.styles'

export type CreatePaymentModalProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  phoneNumber: string
  email: string
  stripeReturnPath: string
}

export const CreatePaymentModal = (props: CreatePaymentModalProps) => {
  const { phoneNumber, email, stripeReturnPath, setShowModal } = props
  return (
    <ModalPaddingContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Text variant="h2">Add credit card</Text>
        <ButtonIcon name="cancel" onClick={() => setShowModal(false)} />
      </div>
      <CreatePayment
        phoneNumber={phoneNumber}
        email={email}
        stripeReturnPath={stripeReturnPath}
        isOnboardingFlow={false}
      />
    </ModalPaddingContainer>
  )
}
