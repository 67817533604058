import { theme } from 'src/libs/theme'
import styled from 'styled-components'

export const TooltipContainer = styled.div`
  position: fixed;
  bottom: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
  min-width: 200px;
  z-index: 1500;

  @media only screen and (min-width: ${theme.media.minSmall}) {
    min-width: 500px;
  }
`
