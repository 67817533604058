import { CardTile } from '@traba/react-components'
import { ShiftPayType, WorkerShift } from '@traba/types'
import { getShiftTimeString } from '@traba/utils'
import React, { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Badge, Icon, Row, Text } from 'src/components/base'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { useNavigateToTimeSheetDailyView } from 'src/hooks/useTimesheet'
import { theme } from 'src/libs/theme'
import { getMoneyString, getPayUnitString } from 'src/utils/moneyUtils'
import { ClockCode } from '../base/ClockCode'
import { ReleaseBadge } from '../ReleaseBadge'
import { RoleBadges } from '../RoleBadges'
import { ShiftBadges } from '../ShiftBadges'
import EstimatedCost from './EstimatedCost'
import { ShiftTileUIProps } from './ShiftTile.hooks'
import * as S from './ShiftTile.styles'
import { getShiftProps } from './ShiftTileUtils'

export const DesktopShiftTile: React.FC<
  ShiftTileUIProps &
    (
      | { shift: ShiftAndAddress; workerShift?: never }
      | { shift?: never; workerShift: WorkerShift }
    )
> = memo((props) => {
  const { shift, workerShift, ...rest } = props
  const {
    isPastShift,
    path,
    userCanViewWages,
    addressString,
    dateString,
    minEstimatedCost,
    maxEstimatedCost,
    slotsRemaining,
    showClockCodes,
    showTotals = true,
  } = rest

  const {
    shiftId,
    shiftRole,
    startTime,
    endTime,
    slotsRequested,
    status,
    jobStatus,
    confirmationCode,
    clockOutCode,
    requiredCertifications,
    requiredAttributes,
    timezone,
    payType,
    payRate,
    numberOfUnits,
    invitedWorkers,
  } = getShiftProps(shift ?? workerShift)

  const navigate = useNavigate()
  const navigateToTimeSheetDailyView = useNavigateToTimeSheetDailyView()

  return (
    <S.ShiftTileContainer
      onClick={() => {
        if (path === 'timesheet') {
          navigateToTimeSheetDailyView(shiftId, startTime)
        } else {
          navigate(`/${path}/${shiftId}`)
        }
      }}
      isClickable
    >
      <Row>
        <S.ShiftTileCol style={{ flex: 1, padding: theme.space.med }}>
          <Row mb={22} style={{ alignItems: 'center' }}>
            <CardTile size="56px" style={{ minWidth: '56px' }}>
              <Icon name="clock" style={{ height: 18, width: 18 }} />
            </CardTile>
            <S.ShiftTileCol
              className="xs-6"
              style={{ marginLeft: theme.space.sm }}
            >
              <Row>
                <Text variant="h5">{shiftRole}</Text>
                <div>
                  <ShiftBadges
                    status={status}
                    jobStatus={jobStatus}
                    requiredCertifications={requiredCertifications}
                  />
                </div>
              </Row>
              {requiredAttributes && (
                <RoleBadges requiredAttributes={requiredAttributes} />
              )}
            </S.ShiftTileCol>
            {shift &&
            showTotals &&
            userCanViewWages &&
            minEstimatedCost &&
            maxEstimatedCost ? (
              <EstimatedCost
                {...rest}
                {...shift}
                className="xs-3"
                style={{ justifyContent: 'flex-end' }}
                ml="auto"
                minEstimatedCost={minEstimatedCost}
                maxEstimatedCost={maxEstimatedCost}
              />
            ) : null}
          </Row>
          <Row>
            <S.ShiftTileCol className="xs-9">
              <S.ShiftTileRow wrap>
                <S.ShiftTileCol className="xs-3">
                  <Text variant="caption">DATE</Text>
                  <S.ShiftTileBodyText variant="body2">
                    {dateString}
                  </S.ShiftTileBodyText>
                </S.ShiftTileCol>
                <S.ShiftTileCol className="xs-3">
                  <Text variant="caption">TIME</Text>
                  <S.ShiftTileBodyText variant="body2">
                    {getShiftTimeString(startTime, endTime, timezone)}
                  </S.ShiftTileBodyText>
                </S.ShiftTileCol>
                <S.ShiftTileColWide className="xs-5">
                  <Text variant="caption">LOCATION</Text>
                  <S.ShiftTileBodyText variant="body2">
                    {addressString}
                  </S.ShiftTileBodyText>
                </S.ShiftTileColWide>
              </S.ShiftTileRow>
              <Row wrap>
                {shift && slotsRemaining !== undefined && !isPastShift && (
                  <S.ShiftTileCol className="xs-3">
                    <Text variant="caption">SIGNED UP</Text>
                    <Row alignCenter gap={theme.space.xxs}>
                      <Badge
                        variant="info"
                        title={(slotsRequested - slotsRemaining).toString()}
                      />
                      <S.ShiftTileBodyText variant="body2">
                        {`of ${slotsRequested}`}
                      </S.ShiftTileBodyText>
                    </Row>
                  </S.ShiftTileCol>
                )}
                <S.ShiftTileColWide className="xs-3">
                  {userCanViewWages ? (
                    <>
                      <Text variant="caption">PAY RATE</Text>
                      <S.ShiftTileBodyText variant="body2">
                        {getMoneyString(payRate)}
                        {getPayUnitString(payType)}
                      </S.ShiftTileBodyText>
                    </>
                  ) : null}
                </S.ShiftTileColWide>
                {payType === ShiftPayType.UNIT ? (
                  <S.ShiftTileCol className="xs-3">
                    <Text variant="caption">UNITS</Text>
                    <S.ShiftTileBodyText variant="body2">
                      {numberOfUnits}
                      <ReleaseBadge invitedWorker={invitedWorkers} />
                    </S.ShiftTileBodyText>
                  </S.ShiftTileCol>
                ) : null}
              </Row>
            </S.ShiftTileCol>
            <S.ShiftTileCol
              className="xs-3"
              style={{
                margin: 0,
                padding: 0,
              }}
            >
              {showClockCodes &&
                confirmationCode !== undefined &&
                clockOutCode !== undefined && (
                  <>
                    <S.ShiftTileRow wrap>
                      <S.ShiftTileCol>
                        <Text variant="caption">CLOCK IN CODE</Text>
                        <S.ShiftTileBodyText variant="body2">
                          <ClockCode
                            code={confirmationCode}
                            tileHeight={32}
                            tileWidth={24}
                            fontSize={14}
                          />
                        </S.ShiftTileBodyText>
                      </S.ShiftTileCol>
                    </S.ShiftTileRow>
                    <Row wrap>
                      <S.ShiftTileCol>
                        <Text variant="caption">CLOCK OUT CODE</Text>
                        <S.ShiftTileBodyText variant="body2">
                          <ClockCode
                            code={clockOutCode}
                            tileHeight={32}
                            tileWidth={24}
                            fontSize={14}
                          />
                        </S.ShiftTileBodyText>
                      </S.ShiftTileCol>
                    </Row>
                  </>
                )}
            </S.ShiftTileCol>
          </Row>
        </S.ShiftTileCol>
      </Row>
    </S.ShiftTileContainer>
  )
})
