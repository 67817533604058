import { styled } from '@mui/material/styles'

export const SummaryRow = styled('div')`
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
`

export const SummaryRowItem = styled('div')(({ theme }) => ({
  color: theme.colors.Grey50,
  fontVariant: 'small-caps',
  fontWeight: 500,
}))

export const SummaryRowValue = styled('span')(({ theme }) => ({
  color: theme.colors.Blue100,
  fontWeight: 600,
}))
