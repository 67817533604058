import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector'
import styled from 'styled-components'

export const WizardStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: theme.space.xs,
  },
  [`&.${stepConnectorClasses.root}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.colors.brand,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.colors.brand,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: theme.space.ms,
    width: '90%',
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.colors.Grey90 : '#eaeaf0',
  },
}))
