import { FormControl, MenuItem, Select } from '@mui/material'
import { useAlert } from '@traba/context'
import { LoadingSpinner } from '@traba/react-components'
import {
  Company,
  Shift,
  ShiftCancellationCostResponse,
  ShiftStatus,
} from '@traba/types'
import { sum } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Divider } from 'src/components'
import { Badge, Button, ButtonVariant, Row, SvgIcon } from 'src/components/base'
import { ShiftSelectorTile } from 'src/components/base/ShiftSelectorTile'
import { Text } from 'src/components/base/Text'
import { CancelShiftLineItem } from 'src/components/CancelShiftLineItem'
import { cancelShiftsById, getShiftCancellationCost } from 'src/hooks/useShifts'
import { theme } from 'src/libs/theme'
import { BusinessCancellationReasons } from 'src/types'

import Input from '../../base/Input/Input'
import * as S from '../../base/UpcomingShiftDetailsHeader/ShiftDetailsHeader.styles'
import CancellationCost from './CancellationCost'
import { ModalContainer } from './CancelShiftModal.styles'

export type CancelShiftModalProps = {
  shift: Shift
  allShiftsInSeries: Shift[]
  setModalVisible: any
  company: Company
}

export const CancelShiftModal: React.FC<CancelShiftModalProps> = (
  props: CancelShiftModalProps,
) => {
  const { company, shift, allShiftsInSeries: shifts } = props
  const { showSuccess, showError } = useAlert()

  const [loading, setLoading] = useState(false)
  const [reasonDropdownValue, setReasonDropdownValue] = useState<
    BusinessCancellationReasons | string
  >(BusinessCancellationReasons.CREATED_BY_MISTAKE)
  const [customReasonInput, setCustomReasonInput] = useState('')
  const [selectedShiftIds, setSelectedShiftIds] = useState<string[]>([shift.id])
  const [showAllRecurringShifts, setShowAllRecurringShifts] = useState(false)
  const [cancellationData, setCancellationData] =
    useState<ShiftCancellationCostResponse[]>()

  const activeShiftsLength = shifts.filter(
    (curShift) => curShift.status === ShiftStatus.ACTIVE,
  ).length

  const { cancellationSettings, cancellationChargeSummary } =
    cancellationData?.length
      ? cancellationData[0]
      : {
          cancellationSettings: undefined,
          cancellationChargeSummary: ``,
        }

  const handleConfirm = async () => {
    setLoading(true)
    const cancellationReason =
      reasonDropdownValue === BusinessCancellationReasons.OTHER
        ? customReasonInput
        : reasonDropdownValue
    const res = await cancelShiftsById(selectedShiftIds, cancellationReason)
    if (res) {
      showSuccess('Successfully canceled selected shifts')
    } else {
      showError('There was an issue canceling selected shifts')
    }
    setLoading(false)
    props.setModalVisible(false)
    history.back()
  }

  const handleTileToggleAll = () => {
    if (selectedShiftIds.length === activeShiftsLength) {
      setSelectedShiftIds([shift.id])
      return
    }
    setSelectedShiftIds(
      shifts
        .filter((curShift) => curShift.status === ShiftStatus.ACTIVE)
        .map((curShift) => curShift.id),
    )
  }

  const calculateShiftCancellationData = useCallback(
    (shiftId: string) => {
      const shiftCancellationData = cancellationData?.find(
        ({ shiftId: id }) => id === shiftId,
      )
      const { cancellations = [] } = shiftCancellationData || {}

      const numberWorkersAffected = cancellations.filter(
        (c) => c.totalCharge && c.totalCharge.amount > 0,
      ).length

      const [baseWorkerCancellation] = cancellations

      const singleWorkerCharge =
        baseWorkerCancellation && baseWorkerCancellation.totalCharge
          ? baseWorkerCancellation.totalCharge.amount / 100
          : 0

      const totalBusinessCharge =
        sum(cancellations.map((c) => c.totalCharge?.amount)) / 100

      return {
        numberWorkersAffected,
        singleWorkerCharge,
        totalBusinessCharge,
      }
    },
    [cancellationData],
  )
  const cancellationCosts = useMemo(
    () => selectedShiftIds.map((id) => calculateShiftCancellationData(id)),
    [selectedShiftIds, calculateShiftCancellationData],
  )

  // Saves the fixed cost per worker for any shift in this request that is eligible for charge (is being cancelled within the late cancel window).
  // We save the single worker charge here so the per worker charge can be displayed in the cancellation summary.
  // This will largely be the same for any shift in the same series (small edge case if a single shift in a series has its time edited, but think its worth it to still have a rough summary charge).
  const singleWorkerCharge =
    cancellationCosts.find((s) => s.singleWorkerCharge > 0)
      ?.singleWorkerCharge || 0

  const totalCancellationCost = cancellationCosts.reduce((acc, cost) => {
    const { totalBusinessCharge = 0 } = cost
    return acc + totalBusinessCharge
  }, 0)

  const totalWorkersAffected = cancellationCosts.reduce((acc, cost) => {
    const { numberWorkersAffected = 0 } = cost
    return acc + numberWorkersAffected
  }, 0)

  const renderSelectedCardTiles = () => {
    return shifts
      .filter(({ id }) =>
        selectedShiftIds.find((selectedId) => selectedId === id),
      )
      .map((curShift) => {
        const { totalBusinessCharge, singleWorkerCharge } =
          calculateShiftCancellationData(curShift.id)
        return (
          <CancelShiftLineItem
            shift={curShift}
            company={company}
            totalFee={totalBusinessCharge || 0}
            singleWorkerFee={singleWorkerCharge || 0}
            cancellationChargeSummary={cancellationChargeSummary}
            cancellationSettings={cancellationSettings}
          />
        )
      })
  }

  const isPaidCancellation = totalCancellationCost > 0

  const fetchCancellationData = useCallback(async () => {
    const promises = shifts.map(({ id = '' }) => {
      return getShiftCancellationCost(id)
    })
    const cancellations = await Promise.all(promises || [])
    setCancellationData(cancellations)
  }, [shifts])

  useEffect(() => {
    fetchCancellationData()
  }, [shifts, fetchCancellationData])

  return (
    <ModalContainer>
      <Row alignCenter justifyBetween mt={theme.space.xs} mb={theme.space.xs}>
        <Text variant="h4">Cancel Shift</Text>
        <SvgIcon
          name="cancel"
          color={theme.colors.MidnightBlue}
          onClick={() => {
            props.setModalVisible(false)
          }}
        />
      </Row>
      <Divider />
      <div>
        <Text
          variant="h5"
          style={{ marginTop: theme.space.xs, marginBottom: theme.space.xxs }}
        >
          Shift
        </Text>
        <ShiftSelectorTile showStatus={false} shift={shift} company={company} />
        {/* Cancel Multiple Shifts */}
        {shifts.length > 1 && (
          <>
            <Row wrap mt={theme.space.med}>
              <Row justifyBetween>
                <Text variant="h5">
                  Cancel more shifts in this recurring series
                </Text>
                <Badge
                  style={{ marginLeft: theme.space.xxs, height: 'fit-content' }}
                  variant="info"
                  title="optional"
                />
              </Row>
            </Row>
            <Row justifyEnd>
              <S.TextButton
                style={{ cursor: 'pointer' }}
                onClick={handleTileToggleAll}
              >
                {selectedShiftIds.length === activeShiftsLength
                  ? 'Deselect All'
                  : 'Select All'}
              </S.TextButton>
              {shifts.length > 4 ? (
                <S.TextButton
                  style={{ cursor: 'pointer', marginLeft: '18px' }}
                  onClick={() =>
                    setShowAllRecurringShifts(!showAllRecurringShifts)
                  }
                >
                  {showAllRecurringShifts ? `Show Less` : `Show All`}
                </S.TextButton>
              ) : null}
            </Row>
          </>
        )}
        {shifts
          .filter(
            (curShift, ind) =>
              curShift.id !== shift.id &&
              ((ind < 4 && !showAllRecurringShifts) || showAllRecurringShifts),
          )
          .map((seriesShift: Shift) => {
            if (seriesShift.status === ShiftStatus.CANCELED) {
              return (
                <div>
                  <ShiftSelectorTile
                    shift={seriesShift}
                    company={company}
                    showStatus={true}
                  />
                </div>
              )
            }
            return (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (!selectedShiftIds.includes(seriesShift.id)) {
                    setSelectedShiftIds([...selectedShiftIds, seriesShift.id])
                  } else {
                    setSelectedShiftIds(
                      selectedShiftIds.filter(
                        (shiftId) => shiftId !== seriesShift.id,
                      ),
                    )
                  }
                }}
              >
                <ShiftSelectorTile
                  shift={seriesShift}
                  company={company}
                  showStatus={true}
                  selected={selectedShiftIds.includes(seriesShift.id)}
                />
              </div>
            )
          })}
        <Text variant="h5" mt={theme.space.med} mb={theme.space.sm}>
          Cancellation reason
        </Text>
        {/* Cancellation Costs */}
        <FormControl fullWidth>
          <Select
            labelId="cancellation-dropdown"
            id="cancellation-dropdown"
            value={reasonDropdownValue}
            onChange={(evt) => {
              setReasonDropdownValue(evt.target.value)
            }}
            placeholder="Reason"
          >
            {Object.values(BusinessCancellationReasons).map((rsn) => (
              <MenuItem value={rsn}>{rsn}</MenuItem>
            ))}
          </Select>
          {reasonDropdownValue === BusinessCancellationReasons.OTHER && (
            <Input
              label="Reason"
              width="100%"
              height="500"
              value={customReasonInput}
              onChange={(e) => {
                setCustomReasonInput(e.target.value)
              }}
            />
          )}
        </FormControl>
        {!cancellationData ? (
          <LoadingSpinner
            style={{
              margin: '40px 0px 16px 0px',
              paddingBottom: '24px',
              width: '100%',
            }}
          />
        ) : (
          <div style={{ marginTop: theme.space.med }}>
            <CancellationCost
              isPaidCancellation={isPaidCancellation}
              totalCancellationCost={totalCancellationCost}
              singleWorkerCharge={singleWorkerCharge}
              renderSelectedCardTiles={renderSelectedCardTiles}
              cancellationSettings={cancellationSettings}
              cancellationChargeSummary={cancellationChargeSummary}
              totalWorkersAffected={totalWorkersAffected}
            />
          </div>
        )}
      </div>

      <Row justifyBetween mt={theme.space.sm}>
        <Button
          variant={ButtonVariant.OUTLINED}
          style={{ margin: '4px 0' }}
          onClick={() => {
            props.setModalVisible(false)
          }}
        >
          Discard
        </Button>
        <Button
          variant={ButtonVariant.CANCEL}
          style={{ margin: '4px 0' }}
          onClick={handleConfirm}
          loading={loading}
        >
          {`Cancel ${selectedShiftIds.length} Shift${
            selectedShiftIds.length > 1 ? 's' : ''
          }`}
        </Button>
      </Row>
    </ModalContainer>
  )
}
