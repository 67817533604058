import { FileType } from '@traba/hooks'
import { useEffect, useState } from 'react'
import { Modal, Row, Text } from 'src/components/base'
import { Checkbox } from 'src/components/base/CheckboxThemed'
import { UploadVideoModal } from 'src/components/Modals/UploadVideoModal'
import { useRoles } from 'src/hooks/useRoles'
import { useTrainingVideos } from 'src/hooks/useTrainingVideos'
import { theme } from 'src/libs/theme'
import { TrainingVideo } from 'src/types'

import { BookShiftsProps } from '../../BookShiftsScreen'

export function BookShiftsWorkerVideosSection(props: BookShiftsProps) {
  const { shiftRequest, setShiftRequest } = props
  const { trainingVideos, requiredForAllTrainingVideos } = useTrainingVideos()
  const [selectedVideoIds, setSelectedVideoIds] = useState<string[]>([])
  const { getRoleById } = useRoles()
  const role = getRoleById(shiftRequest.roleId)
  const [isModalOpen, setIsModalOpen] = useState(false)
  useEffect(() => {
    const preSelectedIds = trainingVideos
      .filter((tv) => tv.requiredForAll || role?.videoIds?.includes(tv.id))
      .map((tv) => tv.id)
    setSelectedVideoIds(preSelectedIds)
    setShiftRequest({
      videoIds: preSelectedIds.length ? preSelectedIds : undefined,
    })
  }, [role?.videoIds, trainingVideos, setShiftRequest])

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  function onUploadSuccess(videoId: string) {
    setSelectedVideoIds([...selectedVideoIds, videoId])
    setShiftRequest({
      videoIds: [...selectedVideoIds, videoId],
    })
  }

  return (
    <>
      <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
        What training videos are required?
      </Text>
      <Text variant="body2" style={{ marginBottom: theme.space.xs }}>
        Check the boxes of the training videos you'd like to require workers to
        view. All Traba workers will be asked to watch the training videos
        before signing up for this shift.
        <Text
          style={{ marginLeft: theme.space.xxs }}
          variant="linkUnderline"
          onClick={() => {
            openModal()
          }}
        >
          Upload new video
        </Text>
      </Text>
      {trainingVideos.map((tv: TrainingVideo) => {
        const isRequired =
          requiredForAllTrainingVideos?.findIndex((v) => v.id === tv.id) !== -1
        return (
          <Row my={theme.space.xxs}>
            <Checkbox
              key={tv.id}
              disabled={isRequired}
              selected={selectedVideoIds.includes(tv.id)}
              onClick={() => {
                if (isRequired) {
                  return
                }
                const idx = selectedVideoIds.findIndex(
                  (videoId) => videoId === tv.id,
                )
                const newVideoIds = [...selectedVideoIds]
                if (idx === -1) {
                  newVideoIds.push(tv.id)
                } else {
                  newVideoIds.splice(idx, 1)
                }
                setSelectedVideoIds(newVideoIds)
                setShiftRequest({
                  videoIds: newVideoIds,
                })
              }}
            />
            <Text variant="body1" style={{ marginLeft: theme.space.xs }}>
              {tv.name}
            </Text>
          </Row>
        )
      })}
      <Modal isOpen={isModalOpen} handleClose={closeModal}>
        <UploadVideoModal
          onClose={closeModal}
          onUploadSuccess={onUploadSuccess}
          fileType={FileType.TRAINING_VIDEOS}
        />
      </Modal>
    </>
  )
}
