//NOTE: Commas are only used for within our region. Separator needs to be changed depending on region

import { ChatMessage } from '@traba/types'
import { ShiftInvitation, ShiftInvitationStatus } from '@traba/types'

/*
Pass rows of CSV and this function will actually download the CSV with the passed file name
 */
export function downloadAsCSV(csv: string[], filename: string) {
  const csv_string = csv.join('\n')
  const link = document.createElement('a')
  link.style.display = 'none'
  link.setAttribute('target', '_blank')
  link.setAttribute(
    'href',
    'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string),
  )
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const openInNewTab = (url: string | URL | undefined) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

export function isChatMessage(message: any): message is ChatMessage {
  return message.chatSummaryId !== undefined && message.messageId !== undefined
}

export function isInvitation(invitation: any): invitation is ShiftInvitation {
  return (
    Object.values(ShiftInvitationStatus).includes(invitation.status) &&
    invitation.workerId &&
    invitation.companyId
  )
}

export function getDateFromChatOrInvitation(a: ChatMessage | ShiftInvitation) {
  if (isChatMessage(a)) {
    return a.sentAt
  }
  if (isInvitation(a)) {
    return a.createdAt
  }
  return undefined
}
