import { FormGroup, FormControlLabel, Switch } from '@mui/material'
import { Text } from 'src/components/base'
import { theme } from 'src/libs/theme'

type ShowWorkersToggleProps = {
  isShowWorkersToggled: boolean
  setIsShowWorkersToggled: React.Dispatch<React.SetStateAction<boolean>>
}

export const ShowWorkersToggle: React.FC<ShowWorkersToggleProps> = ({
  isShowWorkersToggled,
  setIsShowWorkersToggled,
}) => {
  const handleToggle = () => {
    const newState = !isShowWorkersToggled
    setIsShowWorkersToggled(newState)
  }

  return (
    <FormGroup>
      <FormControlLabel
        labelPlacement="start"
        style={{
          marginLeft: 0,
          marginRight: theme.space.xs,
        }}
        control={
          <Switch
            checked={isShowWorkersToggled}
            onChange={handleToggle}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label={
          <Text
            variant="h6"
            color={theme.colors.MidnightBlue}
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            Show workers
          </Text>
        }
      />
    </FormGroup>
  )
}
