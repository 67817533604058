import React from 'react'

import { useLocationsProfile } from './LocationsProfile.hooks'
import { LocationsProfileUI } from './LocationsProfile.ui'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook

export const LocationsProfile = () => {
  const logic = useLocationsProfile()
  return <LocationsProfileUI {...logic} />
}
