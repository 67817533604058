import { SvgIconProps } from 'src/types/svg-types'

export default function Download({
  size,
  color,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.33398 12H12.6673"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M7.99935 2.66797L7.99935 6.0013L7.99935 9.33464M7.99935 9.33464L10.666 6.95368M7.99935 9.33464L5.33268 6.95368"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
