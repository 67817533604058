import { getWeekStart } from '@traba/utils'
import { utcToZonedTime } from 'date-fns-tz'
import { ShiftAndAddress } from 'src/hooks/useShifts'

function formatDate(date: Date) {
  return date
    .toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })
    .replace(/\//g, '/')
}

export type ShiftsGroupedByWeekThenDayThenRole = {
  [weekKey: string]: {
    [dateKey: string]: {
      [roleId: string]: ShiftAndAddress
    }
  }
}

export type RoleIdToShiftMap = {
  [roleId: string]: ShiftAndAddress
}

/**
 * Given some list of shifts, groups them by week, day, and then role.
 *
 * For example:
 * {
 *   'July 21-27, 2024': {
 *     '07/21/2024': {
 *       'role-id-1': { <the-shift> },
 *       'role-id-2': { <the-other-shift> },
 *     },
 *     '07/23/2024': {
 *       ...
 *     },
 *     '07/25/2024': {
 *       ...
 *     },
 *   },
 *   'July 28 - August 3, 2024': {
 *     '07/28/2024': {
 *       ...
 *     },
 *     ...,
 *   },
 *   'December 29, 2024 - January 4, 2025': {
 *     ...,
 *   }
 * }
 */
export function groupShiftsByWeekDayAndShiftRequest(
  shifts: ShiftAndAddress[],
): ShiftsGroupedByWeekThenDayThenRole {
  const weekMap = new Map()

  shifts.forEach((shift: ShiftAndAddress) => {
    const startDate = utcToZonedTime(
      new Date(shift.businessStartTime ?? shift.startTime),
      shift.timezone,
    )
    const weekStart = getWeekStart(startDate)
    const weekEnd = new Date(weekStart)
    weekEnd.setDate(weekEnd.getDate() + 6)

    const weekKey = `${formatDate(weekStart)} - ${formatDate(weekEnd)}`
    const dateKey = formatDate(startDate)
    const shiftRequestId = shift.shiftRequestId

    if (!weekMap.has(weekKey)) {
      weekMap.set(weekKey, {})
    }
    const weekData = weekMap.get(weekKey)

    if (!weekData[dateKey]) {
      weekData[dateKey] = {}
    }

    weekData[dateKey][shiftRequestId] = shift
  })

  return Object.fromEntries(weekMap)
}

export function convertDateRangeToReadableFormat(dateRange: string) {
  const [start, end] = dateRange.split(' - ')
  const [startMonth, startDay, startYear] = start.split('/')
  const [endMonth, endDay, endYear] = end.split('/')

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const startMonthName = monthNames[parseInt(startMonth) - 1]

  // If the years are different, include both years
  if (startYear !== endYear) {
    return `${startMonthName} ${parseInt(startDay)}, ${startYear} - ${monthNames[parseInt(endMonth) - 1]} ${parseInt(endDay)}, ${endYear}`
  }

  // If the months are different, include both months
  if (startMonth !== endMonth) {
    return `${startMonthName} ${parseInt(startDay)} - ${monthNames[parseInt(endMonth) - 1]} ${parseInt(endDay)}, ${startYear}`
  }

  // If the months are the same, just show the date range
  return `${startMonthName} ${parseInt(startDay)} - ${parseInt(endDay)}, ${startYear}`
}
