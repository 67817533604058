import { trabaApi } from '@traba/api-utils'
import { WorkerCertification } from '@traba/types'

export async function getWorkerCertificationsById(
  workerId: string,
): Promise<WorkerCertification[] | undefined> {
  try {
    const res = await trabaApi.get(`workers/${workerId}/worker-certifications`)
    return res.data
  } catch (error) {
    console.error('useWorker -> getWorkerCertificationsyId() ERROR', error)
  }
}
