import { useHotSettings } from '@traba/hooks'
import { LoadingSpinner, StarRating } from '@traba/react-components'
import {
  BehaviorAttribute,
  CompanyWorkerAttributeFeedbackRating,
  CompanyWorkerFeedback,
  ConnectionType,
  CreateCompanyWorkerAttributeFeedback,
  CreateCompanyWorkerFeedback,
  RoleAttribute,
  ShiftAttribute,
} from '@traba/types'
import { memo, useEffect, useMemo, useState } from 'react'
import { useCallback } from 'react'
import { useDebounce } from 'react-use'
import {
  useBehaviorAttributes,
  useRoleAttributes,
} from 'src/hooks/useAttributes'
import { useReviews } from 'src/hooks/useReviews'
import { theme } from 'src/libs/theme'

import { Col, Row, Text } from '../base'
import SelectableCard from '../base/SelectableCard'
import { ButtonIcon } from '../PersonalProfile/PersonalProfile.styles'
import { getReviewAttributes } from './components/ReviewableAttribute'
import * as S from './ReviewWorker.styles'

export interface ReviewWorkerFormProps {
  workerName: string
  shiftId: string
  workerId: string
  requiredAttributes: ShiftAttribute[]
  variation?: ConnectionType.FAVORITE | ConnectionType.BLOCK
  onSaveReview?: (review: CompanyWorkerFeedback) => void
  onChangeReview?: (
    review: CreateCompanyWorkerFeedback | CompanyWorkerFeedback,
  ) => void
  connectionId?: string
  /**
   * If true, the form will not automatically save the review when the user
   * changes the rating. This is useful for cases where the review is being
   * used in a modal and the modal should only close when the user clicks the
   * save button (Example: BlockWorkerForm)
   * @default false
   */
  noAutoSave?: boolean
  onClose?: () => void
  additionalInfoProps?: {
    alwaysShowInputField?: boolean
    placeholderText?: string
  }
}

enum FormState {
  INIT = 'init',
  EDITING = 'editing',
  SAVING = 'saving',
  SAVED = 'saved',
  ERROR = 'error',
}

export const ReviewWorkerForm = memo((props: ReviewWorkerFormProps) => {
  const { hotSettings } = useHotSettings()
  const { isLoading: isLoadingBehaviorAttributes, behaviorAttributes } =
    useBehaviorAttributes()
  const { roleAttributes, isLoading: isLoadingRoleAttributes } =
    useRoleAttributes(undefined, true)
  const {
    workerName,
    shiftId,
    workerId,
    requiredAttributes,
    variation,
    onSaveReview,
    onClose,
    additionalInfoProps = {},
  } = props

  const {
    reviews,
    isLoading: isLoadingReview,
    createReview,
    updateReview,
    refetchReviews,
  } = useReviews({ shiftId, workerId })
  // review should be a unique for combination of shiftId and workerId
  const existingReview = reviews?.length ? reviews[0] : undefined
  const {
    alwaysShowInputField: alwaysShowAdditionalInfo,
    placeholderText: additionalInfoPlaceholderText,
  } = additionalInfoProps
  const [showAdditionalInfo, setShowAdditionalInfo] = useState<boolean>(
    alwaysShowAdditionalInfo || false,
  )
  const [formState, setFormState] = useState<FormState>(FormState.INIT)

  const toggleShowAdditionalInfo = useCallback(() => {
    setShowAdditionalInfo((prevShow) => !prevShow)
  }, [])

  const roleAttributesToReview = useMemo(
    () => getReviewAttributes(requiredAttributes, roleAttributes),
    [requiredAttributes, roleAttributes],
  )

  const variantBasedDefaultRating = useMemo(() => {
    switch (variation) {
      case ConnectionType.FAVORITE: {
        return 5
      }
      case ConnectionType.BLOCK: {
        return 1
      }
      default:
        return null
    }
  }, [variation])

  const [currentReview, setCurrentReview] =
    useState<CreateCompanyWorkerFeedback>({
      workerId,
      shiftId,
      attributesFeedback: [],
      note: '',
      rating: variantBasedDefaultRating,
      connectionId: props.connectionId,
    })

  useEffect(() => {
    // update the current review once loading is complete
    if (
      !isLoadingReview &&
      !isLoadingRoleAttributes &&
      !isLoadingBehaviorAttributes &&
      formState === FormState.INIT &&
      existingReview
    ) {
      const attributesFeedback =
        existingReview.companyWorkerAttributeFeedback?.map(
          (existingAttribute) => {
            const roleAttribute = roleAttributes?.find(
              (attr) => attr.type === existingAttribute.roleAttributeType,
            )
            const behaviorAttribute = behaviorAttributes?.find(
              (attr) =>
                attr.type === existingAttribute.behaviorAttributeType ||
                attr.type === existingAttribute.roleAttributeType,
            )
            return {
              roleAttributeType: roleAttribute?.type,
              behaviorAttributeType: behaviorAttribute?.type,
              rating: existingAttribute.rating || undefined,
            }
          },
        )
      setCurrentReview((oldValue) => {
        return {
          workerId,
          shiftId,
          attributesFeedback: attributesFeedback || oldValue.attributesFeedback,
          note: existingReview.note || oldValue.note,
          rating: existingReview.rating || oldValue.rating,
          connectionId: existingReview.connectionId || oldValue.connectionId,
        }
      })
      // show the additional info if the review has a note
      setShowAdditionalInfo(alwaysShowAdditionalInfo || !!existingReview?.note)
    }
  }, [
    alwaysShowAdditionalInfo,
    isLoadingReview,
    isLoadingRoleAttributes,
    isLoadingBehaviorAttributes,
    existingReview,
    workerId,
    shiftId,
    formState,
    roleAttributes,
    behaviorAttributes,
    hotSettings?.useBehaviorAttributesFromRoleAttributes,
  ])

  useEffect(() => {
    if (props.onChangeReview && currentReview) {
      if (existingReview) {
        props.onChangeReview({
          ...currentReview,
          id: existingReview.id,
        })
      } else {
        props.onChangeReview(currentReview)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReview])

  const saveReview = async () => {
    let review: CompanyWorkerFeedback
    if (existingReview) {
      review = await updateReview({ ...currentReview, id: existingReview.id })
    } else {
      review = await createReview(currentReview)
    }
    if (onSaveReview) {
      onSaveReview(review)
    }
    refetchReviews()
  }

  // debounce the saveReview function so that it doesn't get called too often, especially when the user is typing
  useDebounce(
    async () => {
      if (!props.noAutoSave) {
        // only save if the state was set as an edit or inititial state with a non-null rating
        if (
          formState === FormState.EDITING ||
          (!isLoadingReview &&
            formState === FormState.INIT &&
            !existingReview &&
            currentReview.rating)
        ) {
          setFormState(FormState.SAVING)
          try {
            await saveReview()
            setFormState(FormState.SAVED)
          } catch (e) {
            setFormState(FormState.ERROR)
          }
        }
      }
    },
    500,
    [formState, isLoadingReview],
  )

  // save the review when the component unmounts
  useEffect(() => {
    return () => {
      if (formState === FormState.EDITING && !props.noAutoSave) {
        saveReview()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    isLoadingReview ||
    isLoadingBehaviorAttributes ||
    isLoadingRoleAttributes
  ) {
    return (
      <Row fullWidth center>
        <LoadingSpinner
          style={{
            width: 50,
          }}
        />
      </Row>
    )
  }

  const note = currentReview?.note ?? ''
  const currentAttributesFeedback = currentReview?.attributesFeedback ?? []
  const currentBehaviorAttributesFeedback = currentAttributesFeedback.filter(
    (attrFeedback) => attrFeedback.behaviorAttributeType,
  )
  const currentRoleAttributesFeedback = currentAttributesFeedback.filter(
    (attrFeedback) => attrFeedback.roleAttributeType,
  )

  const isPositiveRatingFlow = currentReview.rating && currentReview.rating > 3

  const updateAttributeRating = (
    updatedAttribute: CreateCompanyWorkerAttributeFeedback,
  ) => {
    setFormState(FormState.EDITING)
    window.analytics.track(`User updated attribute rating`, {
      workerId,
      updatedAttribute,
    })
    const updatedAttributes = [...currentAttributesFeedback]
    const existingAttributeFeedbackIndex = currentAttributesFeedback.findIndex(
      (attr) =>
        (attr.roleAttributeType &&
          attr.roleAttributeType === updatedAttribute.roleAttributeType) ||
        (attr.behaviorAttributeType &&
          attr.behaviorAttributeType ===
            updatedAttribute.behaviorAttributeType),
    )
    if (existingAttributeFeedbackIndex > -1) {
      updatedAttributes[existingAttributeFeedbackIndex] = updatedAttribute
    } else {
      updatedAttributes.push(updatedAttribute)
    }
    setCurrentReview({
      ...currentReview,
      attributesFeedback: updatedAttributes,
    })
  }

  const removeAttributeRating = (attributeType: string) => {
    setFormState(FormState.EDITING)
    window.analytics.track(`User removed attribute rating`, {
      workerId,
      attributeType,
    })
    const updatedAttributes = [...currentAttributesFeedback]
    const existingAttributeFeedbackIndex = currentAttributesFeedback.findIndex(
      (attr) =>
        (attr.roleAttributeType && attr.roleAttributeType === attributeType) ||
        (attr.behaviorAttributeType &&
          attr.behaviorAttributeType === attributeType),
    )
    if (existingAttributeFeedbackIndex > -1) {
      updatedAttributes.splice(existingAttributeFeedbackIndex, 1)
    }
    setCurrentReview({
      ...currentReview,
      attributesFeedback: updatedAttributes,
    })
  }

  const updateStarRating = (rating: number | null) => {
    if (!rating) {
      return
    }
    window.analytics.track(`User updated star rating`, {
      workerId,
      rating,
    })
    setFormState(FormState.EDITING)
    setCurrentReview({
      ...currentReview,
      // if the user goes from 4-5 (positive) to 1-3 (negative), remove all the attribute feedback
      attributesFeedback:
        (rating > 3 && currentReview.rating && currentReview.rating <= 3) ||
        (rating <= 3 && currentReview.rating && currentReview.rating > 3)
          ? []
          : currentAttributesFeedback,
      rating,
    })
  }

  const updateAdditionalInfo = (note: string) => {
    setFormState(FormState.EDITING)
    setCurrentReview({
      ...currentReview,
      note,
    })
  }

  /**
   * TODO Abstract feedback component into a reusable separate component
   * and use it for both the role attributes and behavioral attributes @anurag
   */
  const roleAttributesFeedback = roleAttributesToReview?.length ? (
    <>
      <Text variant="h8" mt={theme.space.xs}>
        {`Shift attributes`}
      </Text>
      <Row mt={theme.space.xxs} gap={theme.space.xs} wrap>
        {roleAttributesToReview.map((attributeMetadata) => {
          const existingFeedback = currentRoleAttributesFeedback.find(
            (a) => a.roleAttributeType === attributeMetadata.type,
          )
          const isPositiveFeedback =
            existingFeedback?.rating ===
            CompanyWorkerAttributeFeedbackRating.Positive
          const selected =
            !!existingFeedback && isPositiveRatingFlow === isPositiveFeedback

          return (
            <SelectableCard
              key={attributeMetadata.type}
              label={attributeMetadata.displayName}
              selected={selected}
              onClick={() => {
                const value = !selected
                if (value) {
                  let newRating: CompanyWorkerAttributeFeedbackRating
                  if (isPositiveRatingFlow) {
                    newRating = CompanyWorkerAttributeFeedbackRating.Positive
                  } else {
                    newRating = CompanyWorkerAttributeFeedbackRating.Negative
                  }
                  const newAttribute: CreateCompanyWorkerAttributeFeedback = {
                    roleAttributeType: attributeMetadata.type,
                    rating: newRating,
                  }
                  updateAttributeRating(newAttribute)
                } else {
                  removeAttributeRating(attributeMetadata.type)
                }
              }}
              slim
            />
          )
        })}
      </Row>
    </>
  ) : null

  const behavioralAttributesFeedback = (
    <>
      <Text variant="h8" mt={theme.space.xs}>
        {`Behavioral skills`}
      </Text>
      <Row mt={theme.space.xxs} gap={theme.space.xs} alignCenter wrap>
        {behaviorAttributes?.map((behaviorAttribute) => {
          const existingFeedback = currentBehaviorAttributesFeedback.find(
            (b) => b.behaviorAttributeType === behaviorAttribute.type,
          )
          const isPositiveFeedback =
            existingFeedback?.rating ===
            CompanyWorkerAttributeFeedbackRating.Positive
          const selected =
            !!existingFeedback && isPositiveRatingFlow === isPositiveFeedback
          const label = hotSettings?.useBehaviorAttributesFromRoleAttributes
            ? (behaviorAttribute as RoleAttribute).displayName
            : (behaviorAttribute as BehaviorAttribute).displayNameEn

          return (
            <SelectableCard
              key={behaviorAttribute.type}
              label={label}
              selected={selected}
              onClick={() => {
                const value = !selected

                if (value) {
                  let newRating: CompanyWorkerAttributeFeedbackRating
                  if (isPositiveRatingFlow) {
                    newRating = CompanyWorkerAttributeFeedbackRating.Positive
                  } else {
                    newRating = CompanyWorkerAttributeFeedbackRating.Negative
                  }
                  const newAttribute: CreateCompanyWorkerAttributeFeedback = {
                    behaviorAttributeType: behaviorAttribute.type,
                    rating: newRating,
                  }
                  updateAttributeRating(newAttribute)
                } else {
                  removeAttributeRating(behaviorAttribute.type)
                }
              }}
              slim
            />
          )
        })}
      </Row>
    </>
  )

  const additionalFeedback = (
    <>
      {alwaysShowAdditionalInfo ? (
        <Text variant="h6" mt={theme.space.sm}>
          Any feedback for the worker?
        </Text>
      ) : (
        <S.StyledInputTitleLink onClick={toggleShowAdditionalInfo}>
          Any feedback for the worker?
        </S.StyledInputTitleLink>
      )}
      {showAdditionalInfo && (
        <S.ReviewInput
          placeholder={
            additionalInfoPlaceholderText ||
            `Add any details you‘d like to share about ${workerName}‘s performance.`
          }
          value={note}
          width={'100%'}
          onChange={(e) => {
            updateAdditionalInfo(e.target.value)
          }}
          containerStyle={{
            marginTop: theme.space.xs,
          }}
          type="textarea"
        />
      )}
    </>
  )

  const detailedReview = (
    <>
      <Text variant="h6" mt={theme.space.sm}>
        {isPositiveRatingFlow ? `What went well?` : `What didn't go well?`}
      </Text>

      {roleAttributesFeedback}
      {behavioralAttributesFeedback}

      {additionalFeedback}
    </>
  )

  const autoSaveStatus = props.noAutoSave ? null : (
    <Text
      variant="body3"
      color={formState === FormState.ERROR ? theme.colors.Red60 : undefined}
    >
      {(formState === FormState.SAVING || formState === FormState.EDITING) &&
        'Saving'}
      {formState === FormState.SAVED && 'Saved'}
      {formState === FormState.ERROR && 'Error Saving. Try again.'}
    </Text>
  )

  return (
    <Col>
      {onClose ? (
        <Row justifyEnd alignCenter>
          <ButtonIcon
            style={{ width: 12, marginBottom: theme.space.ms }}
            name="cancel"
            onClick={onClose}
          />
        </Row>
      ) : null}
      <Row justifyBetween alignCenter mb={theme.space.xs}>
        <Text variant="h6">Overall rating</Text>
        {autoSaveStatus}
      </Row>

      <StarRating
        id={`${workerId}-${variation}`}
        value={currentReview.rating}
        onValueChange={updateStarRating}
      />

      {
        // only show the detailed review if the user has selected a rating
        currentReview.rating ? detailedReview : null
      }
    </Col>
  )
})
