import { SvgIconProps } from 'src/types/svg-types'

export default function Unblock({ size, color, ...rest }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
      <circle cx="8" cy="8" r="6" stroke={color} stroke-width="1.5" />
      <path d="M4 12L6.5 9.5M12 4L9.5 6.5" stroke={color} stroke-width="1.5" />
    </svg>
  )
}
