import { BannerWithTitleAndLink, Row } from '@traba/react-components'
import { theme } from '@traba/theme'

export function LocationAssignedUsersAnnouncementBanner() {
  return (
    <Row mt={theme.space.xs}>
      <BannerWithTitleAndLink
        title="You can now assign location access to your members"
        body="Grant your business members access to specific locations or all locations in your company."
        onClickCta={() => {
          window.analytics.track(
            `User Viewed Location Assigned User Feature Help Article`,
          )
          window.open(
            'https://help.traba.work/en/articles/10239103-location-access-for-business-members',
            '_blank',
            'noopener,noreferrer',
          )
        }}
        linkText="Learn more"
      />
    </Row>
  )
}
