import React from 'react'
import { TextVariant, Text } from './Text'

interface AnchorProps {
  children: React.ReactNode
  href: string
  variant?: TextVariant
  style?: React.CSSProperties
  openInNewTab?: boolean
}

export function Anchor(props: AnchorProps) {
  return (
    <Text
      variant={props.variant || 'link'}
      rel="noreferrer"
      target={props.openInNewTab ? '_blank' : undefined}
      style={{
        ...props.style,
      }}
      href={props.href}
    >
      {props.children}
    </Text>
  )
}
