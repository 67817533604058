import { ClickAwayListener, Popper } from '@mui/material'
import { Z_INDEXES, theme } from '@traba/theme'
import {
  ALL_TEXT_SETTINGS,
  NotificationPreferenceStatus,
  SettingsMap,
  UserNotificationSettingType,
} from '@traba/types'
import { useMemo } from 'react'
import { RadioButton } from '../base-components/RadioButton'
import Row from '../base-components/Row'
import { Text } from '../base-components/Text'

interface Props {
  isShiftsFilterOpen: boolean
  setIsShiftsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>
  anchorEl: HTMLElement | null
  editingSettingTypes: UserNotificationSettingType[] | null
  updatedEmailItems: SettingsMap
  updatedTextItems: SettingsMap
  setUpdatedEmailItems: React.Dispatch<React.SetStateAction<SettingsMap>>
  setUpdatedTextItems: React.Dispatch<React.SetStateAction<SettingsMap>>
}

export const NotificationSettingsShiftsSelectPopper = ({
  isShiftsFilterOpen,
  setIsShiftsFilterOpen,
  anchorEl,
  editingSettingTypes,
  updatedEmailItems,
  updatedTextItems,
  setUpdatedEmailItems,
  setUpdatedTextItems,
}: Props) => {
  const updatedItems = useMemo(
    () => ({
      ...updatedEmailItems,
      ...updatedTextItems,
    }),
    [updatedEmailItems, updatedTextItems],
  )
  const isAllShiftsSelected = useMemo(() => {
    if (editingSettingTypes) {
      return editingSettingTypes?.every(
        (type) =>
          updatedItems[type]?.status === NotificationPreferenceStatus.ALL,
      )
    }
    return false
  }, [editingSettingTypes, updatedItems])

  const isSupOnlySelected = useMemo(() => {
    if (editingSettingTypes) {
      return editingSettingTypes?.every(
        (type) =>
          updatedItems[type]?.status ===
          NotificationPreferenceStatus.SUPERVISOR_ONLY,
      )
    }
    return false
  }, [editingSettingTypes, updatedItems])

  const onClickRadio = (status: NotificationPreferenceStatus) => {
    if (editingSettingTypes) {
      const emailSettingWithUpdatedStatus = editingSettingTypes.reduce(
        (acc, type) => {
          if (ALL_TEXT_SETTINGS.includes(type)) {
            return acc
          }
          return {
            ...acc,
            [type]: {
              ...updatedEmailItems[type],
              status,
            },
          }
        },
        {},
      )
      const textSettingWithUpdatedStatus = editingSettingTypes.reduce(
        (acc, type) => {
          if (!ALL_TEXT_SETTINGS.includes(type)) {
            return acc
          }
          return {
            ...acc,
            [type]: {
              ...updatedTextItems[type],
              status,
            },
          }
        },
        {},
      )
      setUpdatedEmailItems({
        ...updatedEmailItems,
        ...emailSettingWithUpdatedStatus,
      })
      setUpdatedTextItems({
        ...updatedTextItems,
        ...textSettingWithUpdatedStatus,
      })
    }
  }

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => {
        if (isShiftsFilterOpen) {
          setIsShiftsFilterOpen(false)
        }
      }}
    >
      <Popper
        anchorEl={anchorEl}
        open={isShiftsFilterOpen}
        style={{
          width: 300,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 10,
          boxShadow: `0px 0px 10px 0px ${theme.colors.Violet30}`,
          backgroundColor: theme.colors.White,
          padding: theme.space.xs,
          zIndex: Z_INDEXES.MODAL_POPPER,
        }}
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
          },
        ]}
      >
        <Row
          alignCenter
          my={theme.space.xs}
          gap={theme.space.xs}
          style={{ cursor: 'pointer' }}
          onClick={() => onClickRadio(NotificationPreferenceStatus.ALL)}
        >
          <RadioButton selected={isAllShiftsSelected} size={24} />
          <Text variant={isAllShiftsSelected ? 'h6' : 'body1'}>All shifts</Text>
        </Row>
        <Row
          alignCenter
          gap={theme.space.xs}
          mb={theme.space.xs}
          onClick={() =>
            onClickRadio(NotificationPreferenceStatus.SUPERVISOR_ONLY)
          }
        >
          <RadioButton selected={isSupOnlySelected} size={24} />
          <Text variant={isSupOnlySelected ? 'h6' : 'body1'}>
            Shift I'm supervising
          </Text>
        </Row>
      </Popper>
    </ClickAwayListener>
  )
}
