import { GenderPreference } from '@traba/types'
import { Badge } from './base'
import { BadgeVariant } from './base/Badge/Badge.styles'

export type GenderBadgeProps = {
  gender?: GenderPreference
}

const genderToBadgeVariantDict: Record<GenderPreference, BadgeVariant> = {
  [GenderPreference.Male]: 'info',
  [GenderPreference.Female]: 'success',
}

export default function GenderBadge({ gender }: GenderBadgeProps) {
  return (
    <Badge
      title={gender || 'Not Filled'}
      variant={gender ? genderToBadgeVariantDict[gender] : 'disabled'}
    />
  )
}
