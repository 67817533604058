import { SelectChangeEvent } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import { theme } from '@traba/theme'
import { IconName, InputStatus } from '@traba/types'
import React from 'react'

import { BadgeVariant } from '../Badge/Badge.styles'
import { InputErrorIcon, InputErrorMessage } from '../Input/Input.styles'
import { Text } from '../Text'
import * as S from './Select.styles'

export const SELECT_ALL_OPTION_ID = 'select-all'
export const SELECT_ALL_OPTION_TEXT = 'All'

export interface MenuItemGroup {
  id: string
  title: string
  hideTitle?: boolean
}

export interface Tag {
  title: string
  iconName?: IconName
  variant: BadgeVariant
}

export interface IMenuItem<T = string> {
  value: T
  label: string
  secondaryLabel?: string
  subtitle?: string
  icon?: IconName
  disabled?: boolean
  children?: IMenuItem[] // needed for subgroup multiselect
  groupId?: string
  trailingTags?: Tag[]
  tagsRow?: Tag[]
}

export const IMenuItemAll: IMenuItem[] = [{ value: 'all', label: 'All' }]

export interface SelectDropdownProps<T extends string = string>
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string
  placeholder?: string
  inputStatus?: InputStatus
  menuItems: Array<IMenuItem>
  value?: T
  handleSelect: (value: T) => void
  errorMessage?: string
  showEmptyOption?: boolean
}

export function SelectDropdown<T extends string = string>(
  props: SelectDropdownProps<T>,
) {
  const hasError = props.inputStatus === InputStatus.error
  return (
    <>
      <S.SelectContainer className={props.className} style={props.style}>
        {
          <FormControl fullWidth error={hasError}>
            {props.label ? (
              <S.InputLabel id="select-dropdown">{props.label}</S.InputLabel>
            ) : null}
            <S.Select
              labelId="select-dropdown"
              id="select-dropdown"
              value={props.value as T}
              label={props.label}
              onChange={(event: SelectChangeEvent<unknown>) =>
                props.handleSelect(event.target.value as T)
              }
              disabled={props.disabled}
              displayEmpty={!!props.placeholder}
              renderValue={(v: unknown) =>
                v ? (
                  props.menuItems.find((m) => m.value === v)?.label
                ) : props.placeholder ? (
                  <Text variant="body2">{props.placeholder}</Text>
                ) : null
              }
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: theme.colors.Grey50,
                },
              }}
            >
              {props.showEmptyOption && (
                <MenuItem value={undefined} key={`item-undefined`}>
                  {props.placeholder || '-'}
                </MenuItem>
              )}
              {props.menuItems.map((item: IMenuItem, idx: number) => (
                <MenuItem
                  style={
                    item.value
                      ? undefined
                      : { fontWeight: 300, color: '#767676' }
                  }
                  value={item.value}
                  key={`item-${idx}`}
                  disabled={!!item.disabled}
                >
                  <>
                    <Text
                      variant={idx === 0 && !item.value ? 'body2' : 'h6'}
                      style={{ marginRight: theme.space.xxs }}
                    >
                      {item.label}
                    </Text>
                    {item.subtitle && (
                      <Text variant="body3">{item.subtitle}</Text>
                    )}
                  </>
                </MenuItem>
              ))}
            </S.Select>
          </FormControl>
        }
      </S.SelectContainer>
      {hasError ? (
        <InputErrorMessage>
          <InputErrorIcon />
          {props.errorMessage}
        </InputErrorMessage>
      ) : null}
    </>
  )
}
