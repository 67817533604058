import styled from 'styled-components'

export const ShiftItemContainer = styled.div<{ disableChecked?: boolean }>`
  display: flex;
  padding: ${({ theme }) => theme.space.xs}px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.Grey20};
  border-radius: ${({ theme }) => theme.space.xxs}px;
  margin: ${({ theme }) => theme.space.xxs}px 0;
  cursor: ${({ disableChecked }) => (disableChecked ? 'default' : 'pointer')};
`
