import { trabaApi } from '@traba/api-utils'
import { ShiftRequest } from '@traba/types'
import { useQuery } from 'react-query'
import { useShiftById } from 'src/hooks/useShifts'

export function useEditShift(shiftId = '', fetchShift = true) {
  const {
    shift,
    isLoading: isLoadingShift,
    refetch: refetchShift,
  } = useShiftById(shiftId || '', undefined, fetchShift /* enabled */)

  const { data: shiftRequestTemplate, isLoading: isLoadingShiftReq } = useQuery<
    ShiftRequest,
    Error
  >(
    ['shiftRequestById', shift?.shiftRequestId],
    () =>
      trabaApi
        .get(`my-company/shift-requests/${shift?.shiftRequestId}`)
        .then((r) => r.data),
    { enabled: !!shift?.shiftRequestId, retry: false, staleTime: Infinity },
  )

  return {
    shift,
    shiftRequestTemplate,
    isLoadingShift,
    isLoadingShiftReq,
    refetch: refetchShift,
  }
}
