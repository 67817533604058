import { SvgIconProps } from 'src/types/svg-types'

export default function Equipment({ size = 52, ...props }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 53 53" fill="none" {...props}>
      <circle cx="26.558" cy="26.4193" r="26" fill="#F5EBFF" />
      <path
        d="M45.7868 37.0007H33.4439V13.8378"
        stroke="#6600CC"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M33.463 29.2031H29.5553M29.5553 29.2031H25.3944C20.9761 29.2031 17.3944 25.6213 17.3944 21.2031V17.4164C17.3944 16.3118 18.2898 15.4164 19.3944 15.4164H22.0304C23.7466 15.4164 25.2715 16.5111 25.8203 18.1372L29.5553 29.2031Z"
        stroke="#6600CC"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M17.394 24.6512H12.6964C11.0396 24.6512 9.69641 25.9944 9.69641 27.6512V34.0161C9.69641 35.673 11.0396 37.0161 12.6964 37.0161H33.4282"
        stroke="#6600CC"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M12.6964 24.6512H15.6761C16.7403 24.6512 17.7147 25.2236 18.3521 26.0758C18.821 26.7025 19.4098 27.3914 19.9965 27.8165C21.2621 28.7333 23.5821 29.1933 24.2981 29.3179C24.4424 29.343 24.5878 29.3534 24.7343 29.3534H30.4282C32.0851 29.3534 33.4282 30.6966 33.4282 32.3534V34.0161C33.4282 35.673 32.0851 37.0161 30.4282 37.0161H12.6964C11.0396 37.0161 9.69641 35.673 9.69641 34.0161V27.6512C9.69641 25.9944 11.0396 24.6512 12.6964 24.6512Z"
        fill="#6600CC"
        stroke="#6600CC"
        stroke-width="2"
        stroke-linecap="round"
      />
      <circle
        cx="16.415"
        cy="37.0009"
        r="2.03542"
        fill="#D9D9D9"
        stroke="#6600CC"
        stroke-width="2"
      />
      <circle
        cx="27.558"
        cy="37.0009"
        r="2.03542"
        fill="#D9D9D9"
        stroke="#6600CC"
        stroke-width="2"
      />
    </svg>
  )
}
