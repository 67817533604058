import { REQUIRED_FIELD_MESSAGE } from '@traba/consts'
import { theme } from '@traba/theme'
import { User, UserRole } from '@traba/types'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import * as yup from 'yup'
import { Button, ButtonVariant } from '../../../base-components'
import { ModalPaddingContainer } from '../../../base-components/Modal/Modal.styles'
import Row from '../../../base-components/Row'
import { SvgIcon } from '../../../base-components/SvgIcon'
import { Text } from '../../../base-components/Text'
import { ChangeMemberRoleRow } from './ChangeMemberRoleRow'

export interface ChangeMemberRoleModalContentProps {
  member: Pick<User, 'role' | 'firstName' | 'lastName'>
  handleClose: () => void
  onChangeMemberRole: (role: UserRole) => void
  loading?: boolean
}

export function ChangeMemberRoleModalContent(
  props: ChangeMemberRoleModalContentProps,
) {
  const { member, handleClose, onChangeMemberRole, loading } = props

  const { role } = member
  const formik = useFormik({
    initialValues: { role },
    validationSchema: yup.object({
      role: yup.string().required(REQUIRED_FIELD_MESSAGE),
    }),
    onSubmit: ({ role: newRole }) => {
      formik.setStatus({})
      onChangeMemberRole(newRole)
    },
  })
  const { errors, touched } = formik

  useEffect(() => {
    formik.setFieldValue('role', role)
  }, [role])

  return (
    <ModalPaddingContainer>
      <Row justifyBetween pb={theme.space.xs}>
        <Text variant="h2">Change role</Text>
        <SvgIcon
          size={24}
          color={theme.colors.Grey50}
          name="cancel"
          onClick={handleClose}
        />
      </Row>

      <Text variant="body2">
        Change the role assigned to the selected member below.
      </Text>

      <form onSubmit={formik.handleSubmit}>
        <Row mt={theme.space.sm} fullWidth>
          <ChangeMemberRoleRow
            member={member}
            role={formik.values.role}
            changeMemberRole={(val: UserRole) =>
              formik.setFieldValue('role', val)
            }
            errors={touched.role ? errors : undefined}
          />
        </Row>

        <Row justifyBetween pt={theme.space.med}>
          {handleClose && (
            <Button
              type="button"
              variant={ButtonVariant.OUTLINED}
              onClick={handleClose}
            >
              Back
            </Button>
          )}
          <Button variant={ButtonVariant.FILLED} loading={loading}>
            Save change
          </Button>
        </Row>
      </form>
    </ModalPaddingContainer>
  )
}
