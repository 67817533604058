import { JobStatus, ShiftStatus } from '@traba/types'

import { AbandonedBadge } from '../AbandonedBadge'
import { CanceledBadge } from '../CanceledBadge'
import { CompleteBadge } from '../CompleteBadge'
import { RoleBadges } from '../RoleBadges'
import * as S from './ShiftBadges.styles'

type ShiftBadgesProps = {
  status?: ShiftStatus
  requiredCertifications?: string[]
  showCanceledBadge?: boolean
  jobStatus?: JobStatus
}

export function ShiftBadges(props: ShiftBadgesProps) {
  const {
    status,
    jobStatus,
    requiredCertifications,
    showCanceledBadge = true,
  } = props
  return (
    <S.ShiftBadgesContainer>
      <RoleBadges requiredCertifications={requiredCertifications} />
      {showCanceledBadge && <CanceledBadge status={status} />}
      <CompleteBadge status={status} jobStatus={jobStatus} />
      <AbandonedBadge jobStatus={jobStatus} />
    </S.ShiftBadgesContainer>
  )
}
