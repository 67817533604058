import { loadStripe } from '@stripe/stripe-js'
import { trabaApi } from '@traba/api-utils'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { Billing, UserRolePermission } from '../types'
import { useUserPermissions } from './useUser'

const createCustomer = async ({
  email,
  phoneNumber,
  returnPath,
}: {
  email: string
  phoneNumber: string
  returnPath: string
}) => {
  try {
    const response = await trabaApi.post(
      `/my-company/billing/customer?returnPath=${returnPath}`,
      {
        email,
        phoneNumber,
      },
    )
    return response.data
  } catch (error) {
    // will catch later
    console.log('error creating Stripe customer: ', error)
    throw error
  }
}

const getBilling = async () => {
  try {
    const response = await trabaApi.get(`/my-company/billing`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const startStripeSetupIntent = async ({
  email,
  phoneNumber,
  returnPath,
}: {
  email: string
  phoneNumber: string
  returnPath: string
}) => {
  const data = await createCustomer({
    email,
    phoneNumber,
    returnPath,
  })

  const { checkoutSession } = data
  return checkoutSession.id
}

const fetchStripePublishableKey = async () => {
  try {
    const response = await trabaApi.get(`/stripe/config`)
    return response.data
  } catch (error) {
    console.log('error fetching Stripe key: ', error)
  }
}

const setupStripe = async () => {
  try {
    const stripePublishableKey = await fetchStripePublishableKey()
    return loadStripe(stripePublishableKey)
  } catch (error) {
    console.log('error setting up Stripe: ', error)
  }
}

const retrieveCheckoutSession = async (sessionId: string) => {
  try {
    const response = await trabaApi.get(
      `/my-company/billing/checkout-session/${sessionId}`,
    )
    return response.data
  } catch (error) {
    console.log('error retrieving checkout session: ', error)
  }
}

export const useBilling = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [stripe, setStripe] = useState<any>()
  const userCanManageBilling = useUserPermissions([
    UserRolePermission.ManagePaymentSettings,
  ])
  async function initStripe() {
    try {
      const stripeConfig = await setupStripe()
      setStripe(stripeConfig)
    } catch (error) {
      console.log('error configuring stripe: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (userCanManageBilling) {
      initStripe()
    }
  }, [userCanManageBilling])

  return {
    isLoading,
    setIsLoading,
    stripe,
    createCustomer,
    startStripeSetupIntent,
    retrieveCheckoutSession,
  }
}

export const useBillingInfo = () => {
  const queryClient = useQueryClient()
  const {
    isLoading,
    isError,
    data: billing,
    error,
    isFetched,
    isFetching,
    refetch,
  } = useQuery<Billing, Error>('billing', getBilling)

  const patchBillingMutation = useMutation<
    Billing,
    AxiosError,
    Partial<Billing>,
    Billing
  >((updatedBilling) => trabaApi.patch(`/my-company/billing`, updatedBilling), {
    onSuccess: (response: any) => {
      queryClient.setQueryData('company', response.data)
      refetch()
    },
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    billing,
    isFetching,
    patchBilling: patchBillingMutation.mutateAsync,
  }
}
