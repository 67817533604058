import { SvgIconProps } from '@traba/types'

export default function ScheduledBlock({
  size,
  color = '#F20D59',
  strokeWidth = 1.5,
  ...props
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M8.5 12.811C8.02057 12.9344 7.51795 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 7.51795 12.9344 8.02057 12.811 8.5"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
        <path d="M3 11L11 3" stroke={color} strokeWidth={strokeWidth} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9835 10.1176C11.0577 10.3348 10.3348 11.0577 10.1176 11.9835C9.96079 12.6521 9.96079 13.3479 10.1176 14.0165C10.3348 14.9423 11.0577 15.6652 11.9835 15.8824C12.6521 16.0392 13.3479 16.0392 14.0165 15.8824C14.9423 15.6652 15.6652 14.9423 15.8824 14.0165C16.0392 13.3479 16.0392 12.6521 15.8824 11.9835C15.6652 11.0577 14.9423 10.3348 14.0165 10.1176C13.3479 9.96079 12.6521 9.96079 11.9835 10.1176ZM12.5 12C12.5 11.7239 12.7239 11.5 13 11.5C13.2761 11.5 13.5 11.7239 13.5 12V12.785L14.0685 13.3536C14.2638 13.5488 14.2638 13.8654 14.0685 14.0607C13.8733 14.2559 13.5567 14.2559 13.3614 14.0607L12.7032 13.4024C12.5799 13.3114 12.5 13.165 12.5 13V12Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
