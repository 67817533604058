import { firebaseToken } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { httpsCallable } from 'firebase/functions'
import { useState } from 'react'
import { useFunctions } from 'reactfire'
import { openInNewTab } from 'src/utils/miscUtils'

export type GenerateResumeResponseDto = {
  downloadLink: string
}

export function useDownloadResume(workerId: string) {
  const [isLoading, setIsLoading] = useState(false)
  const { showError } = useAlert()
  const functions = useFunctions()
  const generateResume = httpsCallable(functions, 'createWorkerResumeBiz')

  const downloadResume = async () => {
    try {
      setIsLoading(true)
      const response = await generateResume({ workerId, token: firebaseToken })
      const { downloadLink } = response.data as GenerateResumeResponseDto

      if (!downloadLink) {
        throw new Error('no link returned for worker resume')
      }
      openInNewTab(downloadLink)
      return response.data
    } catch (error) {
      showError(
        'There was a problem downloading the resume. Please Try again.',
        'Error',
      )
    } finally {
      setIsLoading(false)
    }
  }
  return {
    isLoading,
    downloadResume,
  }
}
