import { ShiftPayType } from '@traba/types'
import { InlineBanner, Input, Row, Text } from 'src/components/base'
import { theme } from 'src/libs/theme'

import { BookShiftsProps } from '../../BookShiftsScreen'
import { validateWorkers } from '../../validation'

export function BookShiftsWorkersCountSection(props: BookShiftsProps) {
  const { shiftRequest, setShiftRequest, isEdit } = props
  const { slotsRequested, minSlotsRequested, numberOfUnits } = shiftRequest
  const validationError = validateWorkers(shiftRequest)

  return (
    <>
      <Text variant="h5">How many workers do you need?</Text>
      <Row
        alignCenter
        style={{ margin: `${theme.space.xs}px 0`, columnGap: theme.space.xs }}
      >
        <Text variant="body1" style={{ flexShrink: 0 }}>
          I need
        </Text>
        <Input
          containerStyle={{ marginTop: 0, flexShrink: 1, width: 80 }}
          width="100%"
          type="number"
          placeholder="e.g. 10"
          value={minSlotsRequested > 0 ? `${minSlotsRequested}` : ''}
          onChange={(e) => {
            const input = +e.target.value
            const numSlotsRequested = input && input > 0 ? input : 0
            setShiftRequest({
              minSlotsRequested: numSlotsRequested,
              slotsRequested: numSlotsRequested,
            })
          }}
          onBlur={() => {
            window.analytics.track(`User Updated Slots Requested`, {
              slotsRequested: minSlotsRequested,
              isEdit,
            })
          }}
        />
        <Text variant="body1">workers.</Text>
      </Row>

      {shiftRequest.payType === ShiftPayType.UNIT ? (
        <>
          <Text style={{ marginTop: theme.space.xs }} variant="h5">
            How many units do you need completed?
          </Text>
          <Row
            alignCenter
            style={{
              margin: `${theme.space.xs}px 0`,
              columnGap: theme.space.xs,
            }}
          >
            <Text variant="body1" style={{ flexShrink: 0 }}>
              I need
            </Text>
            <Input
              containerStyle={{ marginTop: 0, flexShrink: 1, width: 80 }}
              width="100%"
              type="number"
              placeholder="10"
              value={Number(numberOfUnits) > 0 ? `${numberOfUnits}` : ''}
              onChange={(e) => {
                const input = +e.target.value
                setShiftRequest({
                  numberOfUnits: input && input > 0 ? input : 0,
                })
              }}
              onBlur={() => {
                window.analytics.track(`User Updated Units Requested`, {
                  unitsRequested: numberOfUnits,
                  isEdit,
                })
              }}
            />
            <Text variant="body1">units.</Text>
          </Row>
        </>
      ) : null}
      {slotsRequested > 0 &&
      minSlotsRequested > 0 &&
      numberOfUnits !== undefined &&
      validationError ? (
        <InlineBanner
          severity="error"
          text={validationError.message}
          style={{ marginTop: theme.space.xs }}
        />
      ) : null}
    </>
  )
}
