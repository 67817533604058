import { Navigate, Route, Routes } from 'react-router-dom'
import { TestingRoutes, TEST_ROUTES_ENABLED } from 'src/routes/routeUtils'
import { AcceptInvitationScreen } from 'src/screens/auth/AcceptInvitationScreen'
import LandingRegistrationScreen from 'src/screens/auth/LandingRegistrationScreen'
import LeadDetailsScreen from 'src/screens/auth/LeadDetailsScreen'
import LoginScreen from 'src/screens/auth/LoginScreen'
import RequireInvitationScreen from 'src/screens/auth/RequireInvitationScreen'
import ForgotPasswordScreen from 'src/screens/ForgotPasswordScreen'

function LoggedOut() {
  return (
    <Routes>
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
      <Route path="/landing" element={<LandingRegistrationScreen />} />
      <Route path="/basic-info" element={<LeadDetailsScreen />} />
      <Route path="/accept-invitation" element={<AcceptInvitationScreen />} />
      <Route path="/require-invitation" element={<RequireInvitationScreen />} />
      <Route path="*" element={<Navigate to="/login" />} />
      {TEST_ROUTES_ENABLED ? TestingRoutes() : null}
    </Routes>
  )
}

export default LoggedOut
