import { Skeleton } from '@mui/material'
import React from 'react'

import { Row } from '..'
import { Table, Td, Tr } from '../Table/Table'

function InvoiceTableSkeleton() {
  const headers = [
    'INVOICE ID',
    'PERIOD START',
    'PERIOD END',
    'LINE ITEMS',
    'INVOICE AMOUNT',
    'DUE DATE',
    '',
    '',
  ]
  return (
    <Table headers={headers}>
      {Array(3)
        .fill(5)
        .map((n: number, index) => (
          <Tr key={index}>
            <Td>
              <Skeleton animation="wave" width="100" height="60px" />
              <div style={{ paddingRight: 150 }}></div>
            </Td>
            <Td>
              <Skeleton animation="wave" width="100px" height="60px" />
            </Td>
            <Td>
              <Skeleton animation="wave" width="100px" height="60px" />
            </Td>
            <Td>
              <Skeleton animation="wave" width="100px" height="60px" />
            </Td>
            <Td>
              <Skeleton animation="wave" width="100px" height="60px" />
            </Td>
            <Td>
              <Skeleton animation="wave" width="100px" height="60px" />
            </Td>
            <Td>
              <Row>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    flex: 1,
                  }}
                >
                  <Skeleton animation="wave" width="175px" height="80px" />
                  <div style={{ padding: 10 }}></div>
                  <Skeleton animation="wave" width="175px" height="80px" />
                </div>
              </Row>
            </Td>
          </Tr>
        ))}
    </Table>
  )
}

export default InvoiceTableSkeleton
