import { useLocation } from 'react-router-dom'

import { SpacingPoints, spacingPoints, theme } from './libs/theme'

/**
 * Transforms pure number amounts in strings with pixel unit added
 * E.g. 5 becomes "5px"
 * @param measure measure amount
 * @returns string value
 */
export function addPixelUnit(measure: string | number) {
  if (typeof measure === 'string') {
    if (spacingPoints.indexOf(measure) >= 0) {
      const spacingMeasure = theme.space[measure as SpacingPoints]
      return `${spacingMeasure}px`
    }
    return measure
  }
  return `${measure}px`
}

/**
 * Debounces a function
 * Alternative to lodash.debouce for react controlled inputs
 * @see: https://stackoverflow.com/questions/59383595/how-to-debounce-a-controlled-input
 * @param cb Function to be debounced
 * @param delay the debounce delay
 */
let timeoutId: NodeJS.Timeout | null = null
export const debounce = (cb: () => unknown, delay = 300) => {
  if (timeoutId) {
    clearTimeout(timeoutId)
  }
  timeoutId = setTimeout(() => {
    timeoutId = null
    cb()
  }, delay)
}

export function useQueryParams() {
  return new URLSearchParams(useLocation().search)
}

/**
 * Converts MegaBytes to Bytes
 * @param sizeInMB size in MB
 * @returns size in Bytes
 */
export function megabytesToBytes(sizeInMB: number) {
  return sizeInMB * 1000000
}
