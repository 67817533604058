import { useHotSettings } from '@traba/hooks'
import { CardTile, DotMenu, DotMenuProps } from '@traba/react-components'
import { LocationResponse } from '@traba/types'
import {
  getLocationNameOrTruncatedAddress,
  getUserNameWithRole,
  isBizMemberCompanyWide,
  sortMemberByFullName,
} from '@traba/utils'
import { useMemo } from 'react'
import { Col, Icon, Row, Text } from 'src/components/base'
import { useLocationAssignedMembers, useMembers } from 'src/hooks/useMembers'
import { useRoles } from 'src/hooks/useRoles'
import { theme } from 'src/libs/theme'
import { UserData } from 'src/types'
import { getAddressString } from 'src/utils/stringUtils'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook

const getSortedFilteredMembersString = (
  members: UserData[],
  withRole: boolean,
) =>
  members
    .filter((user) => (withRole ? !!user.role : !user.role))
    .sort(sortMemberByFullName)
    .map(getUserNameWithRole)
    .join(', ')

const PLACEHOLDER_TEXT = '-'

export type LocationProps = {
  location: LocationResponse
  archive?: (location: LocationResponse) => void
  edit?: (location: LocationResponse) => void
  userCanEdit?: boolean
}

export const Location = (props: LocationProps) => {
  const { hotSettings } = useHotSettings()
  const { location, archive, edit } = props

  const locationAssignedMembers =
    useLocationAssignedMembers(location.locationId) || []
  const locationAssignedMembersString = useMemo(
    () => getSortedFilteredMembersString(locationAssignedMembers, true),
    [locationAssignedMembers],
  )
  const { members } = useMembers()
  const companyWideMembers = useMemo(
    () => members.filter(isBizMemberCompanyWide).sort(sortMemberByFullName),
    [members],
  )
  const companyWideMembersString = useMemo(
    () => getSortedFilteredMembersString(companyWideMembers, true),
    [companyWideMembers],
  )
  const additionalContactsString = useMemo(
    () => getSortedFilteredMembersString(members, false),
    [members],
  )

  const { getRolesByLocationId } = useRoles()
  const locationAssignedRoles = getRolesByLocationId(location.locationId) || []
  const rolesString = useMemo(
    () => locationAssignedRoles.map((role) => role.roleName).join(', '),
    [locationAssignedRoles],
  )

  const menuItems: DotMenuProps['menuItems'] = []
  if (edit) {
    menuItems.push({
      title: 'Edit location',
      iconName: 'edit',
      onClick: () => {
        edit(location)
        window.analytics.track(`User Clicked Edit Location`, {})
      },
      color: theme.colors.MidnightBlue,
    })
  }

  if (archive) {
    menuItems.push({
      title: 'Archive location',
      iconName: 'trash',
      onClick: () => {
        archive(location)
        window.analytics.track(`User Clicked Archive Location`, {})
      },
      color: theme.colors.Red60,
    })
  }

  const actionableCard = props.userCanEdit && menuItems.length > 0

  return (
    <Col gap={theme.space.sm}>
      <Row alignCenter gap={theme.space.xxs} justifyBetween>
        <Row gap={theme.space.sm} alignCenter>
          <CardTile size="56px">
            <Icon name="location" />
          </CardTile>
          <Col>
            <Text variant="h5">
              {getLocationNameOrTruncatedAddress(location)}
            </Text>
            <Text>{getAddressString(location.address)}</Text>
          </Col>
        </Row>

        {actionableCard && (
          <DotMenu
            type="locations"
            dotMenuKey={location.locationId}
            menuItems={menuItems}
          />
        )}
      </Row>

      <Col gap={theme.space.xs}>
        <div>
          <Text variant="caption">ARRIVAL INSTRUCTIONS</Text>
          <Text>{location.locationInstructions || PLACEHOLDER_TEXT}</Text>
        </div>

        {hotSettings?.enableRegionalAccessPhase2 && (
          <>
            <div>
              <Text variant="caption">ASSIGNED MEMBERS</Text>
              <Text>{locationAssignedMembersString || PLACEHOLDER_TEXT}</Text>
            </div>

            <div>
              <Text variant="caption">COMPANY WIDE MEMBERS WITH ACCESS</Text>
              <Text>{companyWideMembersString || PLACEHOLDER_TEXT}</Text>
            </div>

            <div>
              <Text variant="caption">ADDITIONAL CONTACTS</Text>
              <Text>{additionalContactsString || PLACEHOLDER_TEXT}</Text>
            </div>
          </>
        )}

        <div>
          <Text variant="caption">ROLES</Text>
          <Text>{rolesString || PLACEHOLDER_TEXT}</Text>
        </div>
      </Col>
    </Col>
  )
}
