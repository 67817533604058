import { Tooltip } from '@mui/material'
import { upperFirst } from 'lodash'
import { Checkbox, Row, SvgIcon, Text } from 'src/components/base'
import { theme } from 'src/libs/theme'

export const ResponsibilityListItem = (props: {
  type: string
  displayName: string
  companyDescription?: string
  selected: boolean
  onChange: (type: string) => void
}) => {
  return (
    <Row style={{ marginTop: theme.space.xs, width: '50%' }}>
      <Checkbox
        value={props.selected}
        defaultChecked={props.selected}
        onChange={() => props.onChange(props.type)}
        label={
          <Row alignCenter>
            <Text variant="body2">{upperFirst(props.displayName)}</Text>
            <Tooltip
              title={props.companyDescription ?? ''}
              className="xs-hide sm-show"
              style={{ display: 'flex' }}
            >
              <div>
                <SvgIcon
                  name="info"
                  color={theme.colors.Grey50}
                  style={{ marginLeft: theme.space.xxs, alignSelf: 'center' }}
                />
              </div>
            </Tooltip>
          </Row>
        }
      />
    </Row>
  )
}
