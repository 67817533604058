import { Skeleton } from '@mui/material'
import { CardTile } from '@traba/react-components'
import { theme } from 'src/libs/theme'

import { Col, Row, Text } from '..'
import * as S from '../ShiftTile/ShiftTile.styles'

function ShiftTileSkeleton() {
  return (
    <div style={{ paddingBottom: theme.space.xxs }}>
      <S.ShiftTileContainer style={{ padding: theme.space.med }}>
        <Row fullWidth mb={22} style={{ alignItems: 'center' }} justifyBetween>
          <Row>
            <CardTile size="56px">
              <Skeleton animation="wave" width="30px" height="50px" />
            </CardTile>
            <Text variant="h5" style={{ marginLeft: 24 }}>
              <Skeleton animation="wave" width="325px" height="56px" />
            </Text>
          </Row>
          <Skeleton animation="wave" width="100px" height="56px" />
        </Row>
        <Row>
          <S.ShiftTileCol className="xs-9">
            <Row mb={24}>
              <S.ShiftTileColWide>
                <Text variant="caption">DATE</Text>
                <Skeleton
                  animation="wave"
                  width="90px"
                  height={theme.space.lg}
                />
              </S.ShiftTileColWide>
              <S.ShiftTileColWide>
                <Text variant="caption">TIME</Text>
                <Skeleton
                  animation="wave"
                  width="90px"
                  height={theme.space.lg}
                />
              </S.ShiftTileColWide>
              <S.ShiftTileColWide>
                <Text variant="caption">LOCATION</Text>
                <Skeleton
                  animation="wave"
                  width="90px"
                  height={theme.space.lg}
                />
              </S.ShiftTileColWide>
            </Row>

            <Row>
              <S.ShiftTileColWide>
                <Text variant="caption">SIGNED UP</Text>
                <Skeleton
                  animation="wave"
                  width="90px"
                  height={theme.space.lg}
                />
              </S.ShiftTileColWide>
              <S.ShiftTileColWide>
                <Text variant="caption">PAY RATE</Text>
                <Skeleton
                  animation="wave"
                  width="90px"
                  height={theme.space.lg}
                />
              </S.ShiftTileColWide>
            </Row>
          </S.ShiftTileCol>
          <S.ShiftTileCol
            className="xs-3"
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            <Row mb={24}>
              <Col>
                <Text variant="caption">CLOCK IN CODE</Text>
                <Skeleton
                  animation="wave"
                  width="90px"
                  height={theme.space.lg}
                />
              </Col>
            </Row>
            <Row mb={24}>
              <Col>
                <Text variant="caption">CLOCK OUT CODE</Text>
                <Skeleton
                  animation="wave"
                  width="90px"
                  height={theme.space.lg}
                />
              </Col>
            </Row>
          </S.ShiftTileCol>
        </Row>
      </S.ShiftTileContainer>
    </div>
  )
}

export default ShiftTileSkeleton
