import { SvgIconProps } from 'src/types/svg-types'

export default function CircleCheck({
  size,
  color = '#7A8A99',
  strokeWidth = '1.5',
  checkColor,
  fill,
  ...rest
}: SvgIconProps & { checkColor: string }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1.35288 6.95043C2.00437 4.17301 4.17301 2.00437 6.95043 1.35288C8.95626 0.882375 11.0437 0.882374 13.0496 1.35288C15.827 2.00437 17.9956 4.17301 18.6471 6.95044C19.1176 8.95626 19.1176 11.0437 18.6471 13.0496C17.9956 15.827 15.827 17.9956 13.0496 18.6471C11.0437 19.1176 8.95626 19.1176 6.95043 18.6471C4.17301 17.9956 2.00437 15.827 1.35288 13.0496C0.882374 11.0437 0.882374 8.95626 1.35288 6.95043Z"
        stroke-width={strokeWidth}
        stroke={color}
        fill={fill}
      />
      <path
        d="M7.25 9.75L9.25 11.75L12.75 8"
        strokeLinecap="round"
        stroke={checkColor}
        stroke-width={strokeWidth}
        strokeLinejoin="round"
      />
    </svg>
  )
}
