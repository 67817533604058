import { useRegions } from 'src/hooks/useRegions'
import { theme } from 'src/libs/theme'

import { FilterDropdown } from '../ShiftFilters/FilterDropdown'
import { InvoiceTableProps } from './InvoiceTable.ui'

interface InvoiceFilterProps extends InvoiceTableProps {
  style?: React.CSSProperties
}

export function InvoiceFilters(props: InvoiceFilterProps) {
  const { regions = [] } = useRegions()
  const { filters, dispatch, style } = props

  return (
    <div style={{ ...style, marginBottom: theme.space.sm }}>
      {regions.length > 1 && (
        <FilterDropdown
          label="Region"
          selected={filters.regionId}
          items={regions
            .map((r) => ({
              value: r.regionId,
              label: r.displayName,
            }))
            .sort((a, b) => (a.label < b.label ? -1 : 1))}
          onSelectItem={(value) =>
            dispatch({ type: 'SET_REGION', regionId: value })
          }
        />
      )}
    </div>
  )
}
