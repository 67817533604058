import { DEFAULT_PAGE_SIZE } from '@traba/consts'
import { LoadingSpinner } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { Row } from 'antd'
import { useMediaQuery } from 'react-responsive'
import { useSearchParams } from 'react-router-dom'
import { WorkerShift } from 'shared/types/src/lib/worker-shift'
import { useShiftPagination } from 'src/hooks/useShiftPagination'
import { useWorkerShiftHistoryWithMe } from 'src/hooks/useWorkerHistoryWithMyCompany'
import { MEDIA, theme } from 'src/libs/theme'
import { Col, Text } from '../base'
import { PaginationDeprecated } from '../base/PaginationDeprecated'
import { ShiftTile } from '../ShiftTile'

export function PastShiftsWithYou({
  workerId,
  numPastShifts,
}: {
  workerId: string
  numPastShifts: number
}) {
  const [searchParams] = useSearchParams()
  const startPage = parseInt(searchParams.get('p') ?? '0')
  const {
    page,
    onPageLeft,
    onPageRight,
    data: shifts,
    isLoading,
  } = useShiftPagination<WorkerShift>(
    'past_shifts_with_you',
    {
      limit: DEFAULT_PAGE_SIZE + 1,
      workerId,
    },
    useWorkerShiftHistoryWithMe,
    DEFAULT_PAGE_SIZE,
    startPage,
  )
  const showDesktopCard = useMediaQuery({
    query: `(min-width: ${MEDIA.LARGE}px)`,
  })

  if (!shifts || isLoading) {
    return <LoadingSpinner />
  }
  const workerShiftsToDisplay = shifts.slice(0, DEFAULT_PAGE_SIZE)
  return (
    <Col>
      <Row
        style={{
          flex: 1,
          justifyContent: 'space-between',
        }}
      >
        <Text variant="h5">
          Completed {numPastShifts} shift{makePlural(numPastShifts)} in selected
          locations
        </Text>
        {numPastShifts > 0 && (
          <PaginationDeprecated
            page={page}
            pageSize={DEFAULT_PAGE_SIZE}
            onPageLeft={onPageLeft}
            onPageRight={onPageRight}
            dataSize={shifts.length}
          />
        )}
      </Row>
      <Col style={{ marginBottom: theme.space.xs }}>
        {workerShiftsToDisplay.map((workerShift) => {
          return (
            <Row
              style={{
                marginTop: theme.space.xs,
              }}
              key={workerShift.shiftId}
            >
              <ShiftTile
                isPastShift
                workerShift={workerShift}
                showDesktopCard={showDesktopCard}
                showTimeBar={false}
                showWorkerShifts={false}
                showTotals={false}
                showSignedUp={false}
              />
            </Row>
          )
        })}
      </Col>
      <Row style={{ flex: 1, justifyContent: 'end' }}>
        {numPastShifts > 0 && (
          <PaginationDeprecated
            page={page}
            pageSize={DEFAULT_PAGE_SIZE}
            onPageLeft={onPageLeft}
            onPageRight={onPageRight}
            dataSize={shifts.length}
          />
        )}
      </Row>
    </Col>
  )
}
