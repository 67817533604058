import {
  RecurringSchedule,
  Schedule,
  ShiftRequest,
  ShiftRequestEditType,
  ShiftRequestParentWithShiftRequest,
  ShiftStatus,
} from '@traba/types'
import { isBefore, min } from 'date-fns'

export const isRecurringShiftRequest = (
  shiftRequest: ShiftRequest,
): shiftRequest is ShiftRequest & {
  schedules: {
    isRecurringSchedule: true
    recurringSchedule: RecurringSchedule
  }[]
} => {
  return shiftRequest.schedules.some(
    (schedule) => schedule.isRecurringSchedule && !!schedule.recurringSchedule,
  )
}

export const isShiftRequestInactive = (sr: ShiftRequest): boolean => {
  const today = new Date()
  return (
    sr.status === ShiftStatus.CANCELED ||
    sr.shiftRequestEdits?.some(
      (edit) =>
        edit.editType === ShiftRequestEditType.ALL_FUTURE &&
        edit.status === ShiftStatus.CANCELED,
    ) ||
    sr.schedules.some(
      (schedule) =>
        !schedule.isRecurringSchedule ||
        (schedule.recurringSchedule?.endDate &&
          isBefore(schedule.recurringSchedule?.endDate, today)),
    )
  )
}

export const isShiftRequestParentInactive = (
  srp: ShiftRequestParentWithShiftRequest,
): boolean => {
  return srp.shiftRequests.every(isShiftRequestInactive)
}

export const isScheduleFinite = (schedule: Schedule): boolean => {
  return !!schedule.recurringSchedule?.endDate
}

export const getStartTime = (schedules: Schedule[]): Date => {
  return min(schedules.map((s) => s.startTime))
}
