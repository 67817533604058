import { CardTile } from '@traba/react-components'
import { Worker, WorkerShift } from '@traba/types'
import { getShiftDateString } from '@traba/utils'
import { HTMLAttributes } from 'react'
import { theme } from 'src/libs/theme'
import { getInitials } from 'src/utils/stringUtils'

import { Image, Row, Text } from '../../base'

interface ReviewWorkerShiftDisplayProps {
  worker: Worker
  workerShift: WorkerShift
  style?: HTMLAttributes<HTMLDivElement>['style']
}

export function ReviewWorkerShiftDisplay({
  worker,
  workerShift,
  style,
}: ReviewWorkerShiftDisplayProps) {
  return (
    <Row alignCenter gap={theme.space.xs} style={style}>
      <CardTile size="60px" style={{ minWidth: 60 }}>
        {worker.photoUrl ? (
          <Image
            style={{
              width: 60,
              height: 60,
              borderRadius: theme.space.xxs,
            }}
            src={worker.photoUrl}
            alt="worker-photo"
          />
        ) : (
          <Text variant="h5">
            {getInitials(worker.firstName, worker.lastName)}
          </Text>
        )}
      </CardTile>
      <div>
        <Text variant="body1">
          {worker.firstName} {worker.lastName}
        </Text>
        <Text variant="body1">
          {/* eslint-disable-next-line no-irregular-whitespace */}
          {workerShift.shiftInfo.shiftRole} • 
          {getShiftDateString(
            workerShift.shiftInfo.startTime,
            workerShift.shiftInfo.endTime,
            workerShift.shiftInfo.timezone,
          )}
        </Text>
      </div>
    </Row>
  )
}
