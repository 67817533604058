import styled from 'styled-components'

export const ShiftBadgesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;

  @media only screen and (max-width: ${({ theme }) => theme.media.maxMedium}) {
    margin-left: -6px;
  }
`
