import * as Sentry from '@sentry/react'
import { Dialog, MODAL_SIZE } from '@traba/react-components'
import { CostCenterType, CostCenterFormData } from '@traba/types'
import React, { useState } from 'react'
import { CostCenterForm } from 'src/components/CostCenterForm/CostCenterForm'
import { useCostCentersMutations } from 'src/hooks/useCostCenters'

export type CreateOrEditCostCenterModalProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  // if editing a cost center, pass in the cost center to edit
  costCenter?: CostCenterType
}

function CreateOrEditCostCenterModal(props: CreateOrEditCostCenterModalProps) {
  const { createCostCenter, updateCostCenter } = useCostCentersMutations()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<CostCenterFormData | undefined>()
  const [isValid, setIsValid] = useState(false)

  function closeModal() {
    props.setShowModal(false)
  }

  async function handleCreate(formData: CostCenterFormData): Promise<void> {
    try {
      setLoading(true)
      if (formData.name) {
        await createCostCenter({
          name: formData.name,
          locationIds: formData.locationIds,
        })
      } else {
        throw new Error('Cost Center name is required')
      }
      closeModal()
    } catch (error) {
      Sentry.captureException(error)
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  async function handleEdit(formData: CostCenterFormData): Promise<void> {
    try {
      setLoading(true)
      if (props.costCenter?.id) {
        await updateCostCenter({
          id: props.costCenter.id,
          ...formData,
        })
      }
      closeModal()
    } catch (error) {
      Sentry.captureException(error)
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      size={MODAL_SIZE.LARGE}
      dialogTitle={props.costCenter ? 'Edit Cost Center' : 'Create Cost Center'}
      dialogTitleIcon={'dollar'}
      onConfirm={() => {
        if (formData) {
          if (props.costCenter) {
            handleEdit(formData)
          } else {
            handleCreate(formData)
          }
        }
      }}
      onClose={closeModal}
      confirmDisabled={!formData || !isValid}
      onConfirmCTA={props.costCenter ? 'Submit' : 'Create'}
      open={true}
      confirming={loading}
    >
      <CostCenterForm
        onChange={(formData, error) => {
          setFormData(formData)
          setIsValid(!error)
        }}
        onCancel={closeModal}
        costCenter={props.costCenter}
      />
    </Dialog>
  )
}

export default CreateOrEditCostCenterModal
