import { SvgIconProps } from 'src/types/svg-types'

export default function Link({
  size,
  color = '#7A8A99',
  strokeWidth = 1.5,
  ...props
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 25" fill="none" {...props}>
      <path
        d="M12 3.73438C10.9767 3.73438 9.95334 3.852 8.95043 4.08725C6.17301 4.73875 4.00437 6.90738 3.35288 9.68481C2.88237 11.6906 2.88237 13.7781 3.35288 15.784C4.00437 18.5614 6.17301 20.73 8.95044 21.3815C10.9563 21.852 13.0437 21.852 15.0496 21.3815C17.827 20.73 19.9956 18.5614 20.6471 15.7839C20.8824 14.781 21 13.7577 21 12.7343"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M17 3.73438H21M21 3.73438V8.40104M21 3.73438L15 10.7344"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
