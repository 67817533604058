import { makePlural } from '@traba/string-utils'
import {
  ForwardFillMax,
  Shift,
  WorkerShiftWithWorkerDetails,
} from '@traba/types'
import { getShiftTimeString } from '@traba/utils'
import { differenceInHours } from 'date-fns'
import { useEffect, useState } from 'react'
import { ButtonVariant, InlineBanner, Input, Row, Text } from 'src/components'
import { Dialog } from 'src/components/base/Dialog/Dialog'
import { RadioButton } from 'src/components/RadioButton'
import { useCompanyEmploymentType } from 'src/hooks/useCompanyEmploymentType'
import { WorkerDetails } from 'src/hooks/useCompanyWorkers'
import { theme } from 'src/libs/theme'
import {
  BUSINESS_SUPPORT_EMAIL,
  BUSINESS_SUPPORT_NUMBER,
} from 'src/utils/supportUtils'
import { RemoveWorkersSelection } from './RemoveWorkersSelection'
import { DECREASE_WORKER_SLOTS_TIME_WINDOW } from './utils'

export interface UpdateSlotsRequestedProps {
  newSlotsRequested: number
  autoRemoveWorkersFromShift?: boolean
  workerIdsToRemove?: string[]
  updateForwardFillMaxToNone?: boolean
}

interface EditWorkerSlotsModalProps {
  shift: Shift
  workerShifts: WorkerShiftWithWorkerDetails[] | undefined
  workersOnBackup?: WorkerShiftWithWorkerDetails[]
  companyWorkers: WorkerDetails[] | undefined
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onClose: () => void
  onClickInviteWorker: () => void
  handleUpdateSlotsRequested: (
    props: UpdateSlotsRequestedProps,
  ) => Promise<void>
  entryPoint: EditWorkerSlotsEntryPoint
  isMultiShiftEdit?: boolean
  shiftsRequiringWorkerRemoval?: Shift[]
  newSlotsRequested?: number
}

export enum EditWorkerSlotsEntryPoint {
  EDIT_SHIFT_SCREEN = 'EDIT_SHIFT_SCREEN',
  WORKERS_ON_SHIFT_TABLE = 'WORKERS_ON_SHIFT_TABLE',
}

const CheckboxSelectOptionRow = (props: {
  isChecked: boolean
  onClick: () => void
  header: string
  subtext: string
}) => {
  return (
    <Row
      style={{
        padding: theme.space.xs,
        border: `1px solid ${theme.colors.Grey20}`,
        borderRadius: theme.space.xxs,
        cursor: 'pointer',
        marginTop: theme.space.xs,
      }}
      alignCenter
      onClick={props.onClick}
    >
      <RadioButton selected={props.isChecked} size={theme.space.sm} />
      <Row flexCol>
        <Text
          variant="h5"
          style={{
            marginLeft: theme.space.xs,
            color: theme.colors.MidnightBlue,
          }}
        >
          {props.header}
        </Text>
        <Text
          variant="body2"
          style={{
            marginLeft: theme.space.xs,
          }}
        >
          {props.subtext}
        </Text>
      </Row>
    </Row>
  )
}

const ShiftDescriptionRow = ({ shift }: { shift: Shift }) => {
  return (
    <Row mt={theme.space.xs}>
      <Text variant="body1">{shift.shiftRole}</Text>
      <Text ml={theme.space.xxs} mr={theme.space.xxs}>
        {'•'}
      </Text>
      <Text variant="body2">
        {(shift.businessStartTime ?? shift.startTime).toLocaleDateString(
          'en-us',
          {
            timeZone: shift.timezone,
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          },
        )}
        ,{' '}
        {getShiftTimeString(
          shift.businessStartTime ?? shift.startTime,
          shift.endTime,
          shift.timezone,
        )}
      </Text>
      <Text ml={theme.space.xxs} mr={theme.space.xxs}>
        {'•'}
      </Text>
      <Text variant="body1">
        {`${Math.min(shift.slotsRequested, shift.slotsFilled)} signed up`}
      </Text>
    </Row>
  )
}

enum EditWorkerSlotsModalSteps {
  EditOrInvite = 1,
  EditSlots,
  RemoveWorkersAutoOrManual,
  RemoveWorkersIneligibleTimeWindow,
  RemoveWorkersAutoConfirmation,
  RemoveWorkers,
}

const EditSlotsStep: React.FC<{
  slotsRequested: number
  setNewSlots: React.Dispatch<React.SetStateAction<number>>
  isInviteOnlyShift?: boolean
}> = ({ slotsRequested, setNewSlots, isInviteOnlyShift }) => {
  const [slots, setSlots] = useState<number>(slotsRequested)
  useEffect(() => {
    setNewSlots(slots)
  }, [slots, setNewSlots])

  return (
    <>
      <Row style={{ fontWeight: 'bold', color: theme.colors.MidnightBlue }}>
        Edit slots
      </Row>
      <Input
        type="number"
        value={slots}
        onChange={(e) => {
          e.preventDefault()
          setSlots(parseInt(e.target.value))
        }}
      />
      {slots > slotsRequested && isInviteOnlyShift && (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity="info"
          text={'Increasing workers on invite-only shift.'}
          subTitle={
            'If you increase workers on an invite-only shift, the shift will be opened to all workers.'
          }
        />
      )}
    </>
  )
}

export const EditWorkerSlotsModal = (props: EditWorkerSlotsModalProps) => {
  const {
    shift,
    workersOnBackup,
    companyWorkers,
    entryPoint,
    isMultiShiftEdit,
    shiftsRequiringWorkerRemoval,
    newSlotsRequested,
  } = props
  const [step, setStep] = useState<EditWorkerSlotsModalSteps>(
    EditWorkerSlotsModalSteps.EditOrInvite,
  )
  const [newSlots, setNewSlots] = useState<number>(
    newSlotsRequested ?? shift.slotsRequested,
  )

  const [workersToRemove, setWorkersToRemove] = useState<
    WorkerShiftWithWorkerDetails[]
  >([])
  const [actionInProgress, setActionInProgress] = useState<boolean>(false)
  const workerShifts = [
    ...(workersOnBackup || []),
    ...(props.workerShifts || []),
  ]

  const { inviteOnly: companyIsInviteOnly } = useCompanyEmploymentType()

  useEffect(() => {
    if (entryPoint === EditWorkerSlotsEntryPoint.EDIT_SHIFT_SCREEN) {
      setStep(
        isMultiShiftEdit
          ? EditWorkerSlotsModalSteps.RemoveWorkersAutoConfirmation
          : EditWorkerSlotsModalSteps.RemoveWorkersAutoOrManual,
      )
    }
  }, [entryPoint, isMultiShiftEdit])

  /* If there are any overbooks (appear as backups to biz), this will equal shift.slotsRequested.
   Otherwise, it will equal shift.slotsFilled. */
  const slotsFilled = props.workerShifts?.length || 0

  // Radio button selections
  const [editSlotsSelected, setEditSlotsSelected] = useState(true)
  const [autoRemoveWorkersSelected, setAutoRemoveWorkersSelected] =
    useState(true)

  const onClose = () => {
    // Reset states and close modal
    setStep(
      entryPoint === EditWorkerSlotsEntryPoint.WORKERS_ON_SHIFT_TABLE
        ? EditWorkerSlotsModalSteps.EditOrInvite
        : isMultiShiftEdit
          ? EditWorkerSlotsModalSteps.RemoveWorkersAutoConfirmation
          : EditWorkerSlotsModalSteps.RemoveWorkersAutoOrManual,
    )
    setNewSlots(newSlotsRequested ?? shift.slotsRequested)
    setEditSlotsSelected(true)
    setAutoRemoveWorkersSelected(true)
    setActionInProgress(false)
    setWorkersToRemove([])
    props.onClose()
  }

  const handleUpdateSlotsRequested = async (
    autoRemoveWorkersFromShift?: boolean,
    workerIdsToRemove?: string[],
  ) => {
    setActionInProgress(true)
    await props.handleUpdateSlotsRequested({
      newSlotsRequested: newSlots,
      autoRemoveWorkersFromShift: autoRemoveWorkersFromShift,
      workerIdsToRemove: workerIdsToRemove,
      updateForwardFillMaxToNone:
        newSlots > shift.slotsRequested &&
        !companyIsInviteOnly &&
        shift.forwardFillMax === ForwardFillMax.INVITED_ONLY,
    })
    setActionInProgress(false)
  }

  const handleConfirmEditOrInviteStep = () => {
    if (!editSlotsSelected) {
      props.onClickInviteWorker()
      onClose()
      return
    }
    setStep(EditWorkerSlotsModalSteps.EditSlots)
  }

  const handleConfirmEditSlotsStep = async () => {
    if (!newSlots) {
      return
    }

    if (newSlots < shift.slotsRequested) {
      /* If within the 18 hour time window, we don't allow decreasing slots requested. */
      if (
        differenceInHours(
          shift.businessStartTime ?? shift.startTime,
          new Date(),
        ) <= DECREASE_WORKER_SLOTS_TIME_WINDOW
      ) {
        setStep(EditWorkerSlotsModalSteps.RemoveWorkersIneligibleTimeWindow)
        return
      }

      /* If user decreased slots and there are now more workers than slots on the shift,
        then we need to ask the user if they want to remove workers automatically or manually. */
      if (newSlots < slotsFilled) {
        setStep(EditWorkerSlotsModalSteps.RemoveWorkersAutoOrManual)
        return
      }
    }

    // Only update slots if changed
    newSlots !== shift.slotsRequested && (await handleUpdateSlotsRequested())
    onClose()
  }

  const handleConfirmRemoveWorkersAutoOrManualStep = () => {
    if (autoRemoveWorkersSelected) {
      setStep(EditWorkerSlotsModalSteps.RemoveWorkersAutoConfirmation)
      return
    }
    setStep(EditWorkerSlotsModalSteps.RemoveWorkers)
  }

  const handleConfirmRemoveWorkersAutoConfirmationStep = async () => {
    await handleUpdateSlotsRequested(true)
    onClose()
  }

  const handleConfirmRemoveWorkersStep = async () => {
    await handleUpdateSlotsRequested(
      false,
      workersToRemove.map((worker) => worker.workerId),
    )
    onClose()
  }

  const onConfirm = () => {
    switch (step) {
      case EditWorkerSlotsModalSteps.EditOrInvite: {
        handleConfirmEditOrInviteStep()
        break
      }
      case EditWorkerSlotsModalSteps.EditSlots: {
        handleConfirmEditSlotsStep()
        break
      }
      case EditWorkerSlotsModalSteps.RemoveWorkersAutoOrManual: {
        handleConfirmRemoveWorkersAutoOrManualStep()
        break
      }
      case EditWorkerSlotsModalSteps.RemoveWorkersIneligibleTimeWindow: {
        onClose()
        break
      }
      case EditWorkerSlotsModalSteps.RemoveWorkersAutoConfirmation: {
        handleConfirmRemoveWorkersAutoConfirmationStep()
        break
      }
      case EditWorkerSlotsModalSteps.RemoveWorkers: {
        handleConfirmRemoveWorkersStep()
        break
      }
    }
  }

  const isConfirmDisabled: boolean = (() => {
    switch (step) {
      case EditWorkerSlotsModalSteps.EditOrInvite:
      case EditWorkerSlotsModalSteps.RemoveWorkersAutoOrManual:
      case EditWorkerSlotsModalSteps.RemoveWorkersAutoConfirmation:
      case EditWorkerSlotsModalSteps.RemoveWorkersIneligibleTimeWindow:
        return false
      case EditWorkerSlotsModalSteps.EditSlots:
        return !newSlots || newSlots === shift.slotsRequested
      case EditWorkerSlotsModalSteps.RemoveWorkers: {
        const numWorkersToRemove = slotsFilled - newSlots
        return workersToRemove.length < numWorkersToRemove
      }
      default:
        return false
    }
  })()

  const titleText = `Manage number of workers on shift${makePlural(
    isMultiShiftEdit ? 2 : 1,
  )}`
  const subtitleText = `${shift.shiftRole} • ${(
    shift.businessStartTime ?? shift.startTime
  ).toLocaleDateString('en-us', {
    timeZone: shift.timezone,
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })}, ${getShiftTimeString(shift.businessStartTime ?? shift.startTime, shift.endTime, shift.timezone)}`

  const EditOrInviteStep = () => {
    return (
      <>
        <Row style={{ fontWeight: 'bold', color: theme.colors.MidnightBlue }}>
          Do you want to change the number of slots on this shift or invite more
          workers?
        </Row>
        <CheckboxSelectOptionRow
          isChecked={editSlotsSelected}
          onClick={() => {
            setEditSlotsSelected(true)
          }}
          header={`Edit slots`}
          subtext={`Increase or decrease the number of worker slots on this shift.`}
        />
        <CheckboxSelectOptionRow
          isChecked={!editSlotsSelected}
          onClick={() => {
            setEditSlotsSelected(false)
          }}
          header={`Invite new workers`}
          subtext={`Invite specific workers to your shift.`}
        />
      </>
    )
  }

  const RemoveWorkersSelectionStep = () => {
    return (
      <>
        <Row style={{ fontWeight: 'bold', color: theme.colors.MidnightBlue }}>
          There are more workers signed up than the number of slots you need,
          how would you like to manage the excess workers?
        </Row>
        <CheckboxSelectOptionRow
          isChecked={autoRemoveWorkersSelected}
          onClick={() => {
            setAutoRemoveWorkersSelected(true)
          }}
          header={'Remove workers for me'}
          subtext={
            'Our team will automatically remove the workers that match the least with your preferences and needs for this role, ensuring the highest quality of workers for your shift.'
          }
        />
        <CheckboxSelectOptionRow
          isChecked={!autoRemoveWorkersSelected}
          onClick={() => {
            setAutoRemoveWorkersSelected(false)
          }}
          header={'Manually remove workers'}
          subtext={
            'You can go in and manually pick which workers you want to remove from this shift..'
          }
        />
      </>
    )
  }

  const RemoveWorkersAutoConfirmationStep = () => {
    const text =
      entryPoint === EditWorkerSlotsEntryPoint.EDIT_SHIFT_SCREEN &&
      isMultiShiftEdit
        ? 'Of the multiple shifts you are editing, the following have more workers signed up than the number you need. We will automatically remove workers that match the least with your preferences and needs from the shift.  However, if you want to manually remove workers from the relevant shift, please edit shifts individually.'
        : 'Our team will automatically remove the workers that match the least with your preferences and needs for this role, ensuring the highest quality of workers for your shift.'
    return (
      <>
        <Row style={{ fontWeight: 'bold', color: theme.colors.MidnightBlue }}>
          {text}
        </Row>
        {isMultiShiftEdit &&
          shiftsRequiringWorkerRemoval?.map((shift) => (
            <ShiftDescriptionRow shift={shift} />
          ))}
      </>
    )
  }

  const RemoveWorkersStep = () => {
    const numWorkersToRemove = slotsFilled - newSlots
    return (
      <>
        <Row style={{ fontWeight: 'bold', color: theme.colors.MidnightBlue }}>
          Select worker{makePlural(numWorkersToRemove)} to remove
        </Row>
        <Row>
          <Text variant="body2">
            You need to remove {numWorkersToRemove} worker
            {makePlural(numWorkersToRemove)}.
          </Text>
        </Row>
        <RemoveWorkersSelection
          workerShifts={workerShifts}
          workersOnBackup={workersOnBackup}
          companyWorkers={companyWorkers}
          workersToRemove={workersToRemove}
          setWorkersToRemove={setWorkersToRemove}
        />
      </>
    )
  }

  const RemoveWorkersIneligibleTimeWindowStep = () => {
    return (
      <Row>
        <Text variant="body1">
          Since it is less than 18 hours before the shift, you cannot decrease
          the slots requested. If you have any questions, you can send us a text
          at{' '}
          <span style={{ fontWeight: 'bold' }}>{BUSINESS_SUPPORT_NUMBER}</span>{' '}
          or{' '}
          <Text
            variant="link"
            onClick={() => {
              window.open(`mailto:${BUSINESS_SUPPORT_EMAIL}`)
              window.analytics.track(
                'Business Clicked Email Button trying to decrease slots requested with workers on shift within 18 hours.',
              )
            }}
          >
            email us.
          </Text>
        </Text>
      </Row>
    )
  }

  return (
    <Dialog
      dialogTitle={titleText}
      dialogSubtitle={subtitleText}
      dialogTitleIcon={'two_user'}
      onClose={onClose}
      onConfirmCTA={
        step === EditWorkerSlotsModalSteps.RemoveWorkersAutoConfirmation ||
        step === EditWorkerSlotsModalSteps.RemoveWorkersIneligibleTimeWindow
          ? 'Got it, thanks'
          : 'Confirm'
      }
      onConfirm={onConfirm}
      open={props.isOpen}
      confirmButtonVariant={ButtonVariant.FILLED}
      confirmDisabled={isConfirmDisabled}
      confirming={actionInProgress}
    >
      {step === EditWorkerSlotsModalSteps.EditOrInvite && <EditOrInviteStep />}
      {step === EditWorkerSlotsModalSteps.EditSlots && (
        <EditSlotsStep
          slotsRequested={shift.slotsRequested}
          setNewSlots={setNewSlots}
          isInviteOnlyShift={
            shift.forwardFillMax === ForwardFillMax.INVITED_ONLY
          }
        />
      )}
      {step === EditWorkerSlotsModalSteps.RemoveWorkersIneligibleTimeWindow && (
        <RemoveWorkersIneligibleTimeWindowStep />
      )}
      {step === EditWorkerSlotsModalSteps.RemoveWorkersAutoOrManual && (
        <RemoveWorkersSelectionStep />
      )}
      {step === EditWorkerSlotsModalSteps.RemoveWorkersAutoConfirmation && (
        <RemoveWorkersAutoConfirmationStep />
      )}
      {step === EditWorkerSlotsModalSteps.RemoveWorkers && (
        <RemoveWorkersStep />
      )}
    </Dialog>
  )
}
