import { Col } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import {
  ForwardFillMax,
  LocationResponse,
  InvitedWorkers,
  ShiftPayType,
  CertificationEntry,
  CompanyCategory,
  Company,
  ParentInvoiceGroup,
  RoleAttribute,
  RoleAttributeCategory,
  TrainingVideo,
  CreateShiftRequest,
  CreateSchedule,
  RoleInfoForCreateShiftRequest,
  RecurringSchedule,
} from '@traba/types'
import {
  combineTwoDatesForDateAndTime,
  getNextStartAndEndTime,
  WEEKDAY_TO_NUM,
  numToAlpha,
} from '@traba/utils'
import { addMinutes, differenceInMinutes } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { capitalize, upperFirst } from 'lodash'
import React from 'react'
import { Row, Text } from 'src/components'
import { ShiftSchedule } from 'src/components/ShiftSchedule/ShiftSchedule'
import { WorkersInvited } from 'src/components/WorkersInvited/WorkersInvited'
import { WorkerDetails } from 'src/hooks/useCompanyWorkers'
import { BULLET_CHAR } from 'src/libs/constants'
import { theme } from 'src/libs/theme'
import {
  getReadableTimeInTimezone,
  getTimeZoneAbbreviation,
  WEEKDAY_NAMES,
} from 'src/shared/utils/dateUtils'
import { CreateRoleData, RoleData, UserData } from 'src/types'
import { formatPhoneNumber } from 'src/utils/phoneNumberUtils'

export function getRoleCardEntries(
  role?: RoleData | CreateRoleData,
  attributes?: RoleAttribute[],
  certifications?: CertificationEntry[],
  company?: Company,
  fromPrevious?: boolean,
) {
  function getAttributesByCategory(category: RoleAttributeCategory) {
    const filteredAttributes = (
      role?.requiredAttributes?.filter((ra) => ra.category === category) ?? []
    )
      .map((r) =>
        upperFirst(attributes?.find((a) => a.type === r.type)?.displayName),
      )
      .filter((a) => !!a)
    if (role?.freeformAttributes && category in role.freeformAttributes) {
      filteredAttributes.push(role.freeformAttributes[category]!)
    }
    return filteredAttributes
  }
  const certificationNames = (role?.requiredCertifications || []).map(
    (r) => certifications?.find((c) => c.type === r)?.name,
  )

  function withFallback(content?: string) {
    return !role ? '…' : content || 'None'
  }

  const gearString = getAttributesByCategory(RoleAttributeCategory.Gear).join(
    ', ',
  )

  const isEventCompany = company?.category === CompanyCategory.EVENTS

  const nameSection = { label: 'Name', value: withFallback(role?.roleName) }
  const responsibilitiesSection = {
    label: 'Responsibilities & equipment',
    value: withFallback(
      [
        ...getAttributesByCategory(RoleAttributeCategory.Responsibilities),
        ...getAttributesByCategory(RoleAttributeCategory.Lifting),
        ...getAttributesByCategory(RoleAttributeCategory.Equipment),
        ...getAttributesByCategory(
          RoleAttributeCategory.MANUFACTURING_AND_PRODUCTION,
        ),
        ...getAttributesByCategory(
          RoleAttributeCategory.FOOD_PREPARATION_AND_SERVICE,
        ),
      ].join(', '),
    ),
  }
  const requirementsSection = {
    label: 'Requirements & certifications',
    value: withFallback(
      [
        ...certificationNames,
        ...getAttributesByCategory(RoleAttributeCategory.Languages),
        ...(isEventCompany
          ? getAttributesByCategory(RoleAttributeCategory.Lifting)
          : []),
      ].join(', '),
    ),
  }
  const attireSection = {
    label: 'Attire & gear',
    value: withFallback(
      gearString
        ? `${gearString}; ${role?.requiredAttire}`
        : role?.requiredAttire,
    ),
  }
  const detailsSection = {
    label: 'Overview',
    value: withFallback(role?.roleDescription),
  }

  return (
    !isEventCompany
      ? [
          nameSection,
          responsibilitiesSection,
          requirementsSection,
          attireSection,
          detailsSection,
        ]
      : [nameSection, detailsSection, requirementsSection, attireSection]
  ).filter((s) => !fromPrevious || !['Name', 'Overview'].includes(s.label))
}

export function getRoleCardEntriesShiftDataModel({
  roleNumber,
  role,
  supervisor,
  roleInfo,
  workersMap,
}: {
  roleNumber?: number
  role?: RoleData | CreateRoleData
  supervisor?: UserData
  roleInfo: RoleInfoForCreateShiftRequest
  workersMap: Map<string, WorkerDetails>
}) {
  const roleName = !role ? '…' : role.roleName || 'None'
  const { slotsRequested, payRate, payType } = roleInfo

  const label = roleNumber ? `Role ${roleNumber}` : 'Role'
  const value = (
    <Col gap={theme.space.xxxs} key={roleInfo.roleId}>
      <Row gap={theme.space.xxs}>
        <Text variant="body1">{`${slotsRequested} ${roleName}${makePlural(slotsRequested)} @ $${payRate}/${payType === ShiftPayType.HOURLY ? 'hr' : 'unit'}`}</Text>
        {supervisor && <Text variant="body1">{BULLET_CHAR}</Text>}
        {supervisor && (
          <Text variant="body1">Supervisor: {getName(supervisor)}</Text>
        )}
      </Row>
      <WorkersInvited roleInfo={roleInfo} workersMap={workersMap} subdued />
    </Col>
  )

  return { label, value }
}

function getAddress(location?: LocationResponse) {
  if (!location) {
    return ''
  }
  const { address } = location
  const { street1, street2, city, state, postalCode } = address
  return `${street1}${
    street2 ? ` ${street2}` : ''
  } ${city}, ${state} ${postalCode}`
}

function ImageThumbnail(imageSrc: string, index: number) {
  return (
    <div
      style={{
        height: '48px',
        width: '48px',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.space.xxs,
        marginRight: theme.space.xxs,
      }}
      key={index}
    >
      <img
        style={{
          objectFit: 'cover',
          width: '48px',
          height: '48px',
        }}
        src={imageSrc}
        alt="location media"
      />
    </div>
  )
}

export function getLocationCardEntries(location?: LocationResponse) {
  function withFallback(content?: string) {
    return !location ? '…' : content || 'None'
  }

  return [
    ...(location?.name
      ? [
          {
            label: 'Location',
            value: withFallback(location?.name),
          },
        ]
      : []),
    {
      label: 'Address',
      value: withFallback(getAddress(location)),
    },
    {
      label: 'Arrival instructions',
      value: withFallback(location?.locationInstructions),
    },
    ...(location?.media && location.media.length
      ? [
          {
            label: 'Photos',
            value: (
              <Row>
                {location.media.map(
                  (m, i) => 'imageUrl' in m && ImageThumbnail(m.imageUrl, i),
                )}
              </Row>
            ),
          },
        ]
      : []),
  ]
}

function getName(contact?: UserData) {
  if (!contact) {
    return ''
  }
  return `${contact.firstName} ${contact.lastName}`
}

export function getContactCardEntries(contact?: UserData) {
  function withFallback(content?: string) {
    return !contact ? '…' : content || 'None'
  }

  return [
    {
      label: 'Name',
      value: withFallback(getName(contact)),
    },
    {
      label: 'Phone number',
      value: withFallback(
        contact?.phoneNumber && formatPhoneNumber(contact.phoneNumber, true),
      ),
    },
    {
      label: 'Email',
      value: withFallback(contact?.email),
    },
  ]
}

export function getInvoiceGroupEntries(invoiceGroup?: ParentInvoiceGroup) {
  function withFallback(content?: string | React.ReactNode) {
    return !invoiceGroup ? '…' : content || 'None'
  }

  return [
    {
      label: 'Name',
      value: withFallback(invoiceGroup?.name),
    },
    {
      label: 'Description',
      value: withFallback(invoiceGroup?.description),
    },
    {
      label: 'Type',
      value: withFallback(
        invoiceGroup?.recurring
          ? 'Reusable invoice group'
          : 'One-time purchase order',
      ),
    },
  ]
}

const getSingleShiftsScheduleObject = (
  startTime: Date,
  shiftTimeZone: string,
  selectedSingleShiftDates?: Date[] | null,
) => {
  if (!selectedSingleShiftDates || selectedSingleShiftDates.length < 2) {
    return {
      label: 'Date',
      value: formatInTimeZone(startTime, shiftTimeZone, 'EEEE, MMM d, yyyy'),
    }
  }
  return {
    label: 'Date',
    value: selectedSingleShiftDates
      .map((sssd) => formatInTimeZone(sssd, shiftTimeZone, 'EEEE, MMM d, yyyy'))
      .join(` ${BULLET_CHAR} `),
  }
}

export function getScheduleCardEntries(
  shiftRequest: CreateShiftRequest,
  shiftTimeZone: string,
  isEdit?: boolean,
  selectedSingleShiftDates?: Date[] | null,
  businessStartTime?: Date | null,
) {
  const schedule = shiftRequest.schedules[0]
  const { startTime, recurringSchedule } = schedule

  const dateEntry =
    recurringSchedule && !isEdit
      ? {
          label: 'Dates',
          value: (
            <ShiftSchedule
              shiftRequest={shiftRequest}
              shiftTimeZone={shiftTimeZone}
              subdued
            />
          ),
        }
      : getSingleShiftsScheduleObject(
          businessStartTime ?? startTime,
          shiftTimeZone,
          selectedSingleShiftDates,
        )

  const breaksEntry = {
    label: 'Breaks',
    value:
      shiftRequest.scheduledBreaks && shiftRequest.scheduledBreaks.length
        ? shiftRequest.scheduledBreaks
            .map((b) =>
              Array(b.count).fill(`${b.breakLength} minutes`).join(', '),
            )
            .join(', ')
        : 'None',
  }

  const timeEntry = getSingleTimeObject(shiftRequest, businessStartTime)

  return [
    dateEntry,
    timeEntry,
    breaksEntry,
    ...(recurringSchedule
      ? [
          {
            label: 'Repeat on',
            value: recurringSchedule.repeatOn
              .map((r) => WEEKDAY_NAMES[r])
              .join(', '),
          },
        ]
      : []),
  ]
}

function getRepeatOnLabels(schedules: CreateSchedule[]): string[] {
  const recurringSchedules = schedules.filter(
    (s): s is CreateSchedule & { recurringSchedule: RecurringSchedule } =>
      s.isRecurringSchedule && !!s.recurringSchedule,
  )

  if (recurringSchedules.length === 1) {
    const { recurringSchedule } = recurringSchedules[0]
    return [
      `Repeats ${recurringSchedule.repeatOn
        .sort((a, b) => WEEKDAY_TO_NUM[a] - WEEKDAY_TO_NUM[b])
        .map((r) => WEEKDAY_NAMES[r].slice(0, 3))
        .join(', ')}`,
    ]
  }

  const repeatOnLabels: string[] = []
  for (let i = 0; i < recurringSchedules.length; ++i) {
    const schedule = recurringSchedules[i]
    const { recurringSchedule } = schedule
    const weekdays = recurringSchedule.repeatOn
      .sort((a, b) => WEEKDAY_TO_NUM[a] - WEEKDAY_TO_NUM[b])
      .map((r) => WEEKDAY_NAMES[r].slice(0, 3))
    const label = `Week ${numToAlpha(i)}: ${weekdays.join(', ')}`
    repeatOnLabels.push(label)
  }
  return repeatOnLabels
}

function getSingleTimeObject(
  shiftRequest: CreateShiftRequest,
  businessStartTime?: Date | null,
) {
  const { schedules } = shiftRequest
  const { startTime, endTime, timeZone } = schedules[0]
  return {
    label: 'Time',
    value: `${getReadableTimeInTimezone(
      businessStartTime ?? startTime,
      timeZone,
      true,
    )} - ${getReadableTimeInTimezone(
      endTime,
      timeZone,
      true,
    )} ${getTimeZoneAbbreviation(startTime, timeZone)}`,
  }
}

export function getScheduleCardEntriesShiftDataModel(
  shiftRequest: CreateShiftRequest,
  shiftTimeZone: string,
  isEdit?: boolean,
  selectedSingleShiftDates?: Date[] | null,
  businessStartTime?: Date | null,
) {
  const { schedules } = shiftRequest
  const { startTime, endTime, recurringSchedule, timeZone } = schedules[0]
  const repeatOnLabels = getRepeatOnLabels(schedules)

  const dateEntry =
    recurringSchedule && !isEdit
      ? [
          {
            label: 'Date & Time',
            value: (
              <Col gap={theme.space.xxxs}>
                <Row gap={theme.space.xxs}>
                  <Text variant="body1">{`${getReadableTimeInTimezone(startTime, timeZone, true)} - ${getReadableTimeInTimezone(endTime, timeZone, true)} ${getTimeZoneAbbreviation(startTime, timeZone)}`}</Text>
                  {repeatOnLabels.map((s, idx) => (
                    <Row key={`repeatOnLabel_${idx}`} gap={theme.space.xxs}>
                      <Text variant="body1">{BULLET_CHAR}</Text>
                      <Text variant="body1">{s}</Text>
                    </Row>
                  ))}
                </Row>
                <ShiftSchedule
                  shiftRequest={shiftRequest}
                  shiftTimeZone={shiftTimeZone}
                  subdued
                />
              </Col>
            ),
          },
        ]
      : [
          getSingleShiftsScheduleObject(
            startTime,
            shiftTimeZone,
            selectedSingleShiftDates,
          ),
          getSingleTimeObject(shiftRequest, businessStartTime),
        ]

  return dateEntry
}

export const getTrainingVideoText = (
  trainingVideos: TrainingVideo[],
  videoIds: string[] | undefined,
) => {
  if (trainingVideos.length > 0 && !!videoIds && videoIds?.length > 0) {
    const trainingVideoText: string[] = []
    trainingVideos.forEach((tv) => {
      if (videoIds.includes(tv.id)) {
        trainingVideoText.push(tv.name)
      }
    })
    return trainingVideoText.join(', ')
  }
  return 'None'
}

export function getWorkersCardEntries({
  shiftRequest,
  trainingVideos,
  workersMap,
  userCanViewPay,
  company,
}: {
  shiftRequest: CreateShiftRequest
  trainingVideos: TrainingVideo[]
  workersMap: Map<string, WorkerDetails>
  userCanViewPay: boolean
  company?: Company
}): Array<{ label: string; value: string | JSX.Element }> {
  const {
    slotsRequested,
    minSlotsRequested,
    invitedWorkers,
    forwardFillMax,
    shiftInvitations,
    payRate,
    payType,
    numberOfUnits,
    videoIds,
  } = shiftRequest
  const isPayTypeUnit = payType === ShiftPayType.UNIT
  const shouldShowGenderPreference =
    company?.allowGenderPreference && shiftRequest.genderPreference

  const countText =
    minSlotsRequested === slotsRequested
      ? `${minSlotsRequested} ${minSlotsRequested === 1 ? 'worker' : 'workers'}`
      : `${minSlotsRequested} - ${slotsRequested} workers`
  const unitsEntries = isPayTypeUnit
    ? [
        {
          label: 'Pay Type',
          value: shiftRequest.payType as string,
        },
        { label: 'Number of workers', value: countText },
        { label: 'Number of units', value: numberOfUnits + '' },
        {
          label: 'Unit pay',
          value: `$${payRate.toFixed(2)}/unit`,
        },
      ]
    : userCanViewPay
      ? [
          { label: 'Count', value: countText },
          {
            label: 'Hourly pay',
            value: `$${payRate.toFixed(2)}/hr`,
          },
        ]
      : [{ label: 'Count', value: countText }]

  const invitedText =
    forwardFillMax === ForwardFillMax.INVITED_ONLY ||
    forwardFillMax === ForwardFillMax.INVITED_FIRST ? (
      <>
        {shiftInvitations?.map((shiftInvitation) =>
          shiftInvitation.workers.map((worker) => (
            <Text variant="body1" style={{ fontSize: 13, lineHeight: '21px' }}>
              {workersMap.get(worker.workerId)?.worker.firstName}{' '}
              {workersMap.get(worker.workerId)?.worker.lastName}
            </Text>
          )),
        )}
        <Text
          variant="body1"
          mt="xxs"
          style={{ fontSize: 13, lineHeight: '21px' }}
        >
          {`We WILL ${
            forwardFillMax === ForwardFillMax.INVITED_ONLY ? 'NOT' : ''
          } invite other qualified Traba workers if these workers are
          not available.`}
        </Text>
      </>
    ) : invitedWorkers === InvitedWorkers.FAVORITES_FIRST ? (
      'Shifts will be exclusively available to your favorites first'
    ) : (
      'Shifts will be available to all qualified workers'
    )
  return [
    ...unitsEntries,
    { label: 'Workers invited', value: invitedText },
    ...(shouldShowGenderPreference
      ? [
          {
            label: 'Preferred Gender',
            value: capitalize(`${shiftRequest.genderPreference}`),
          },
        ]
      : []),
    {
      label: 'Required training videos',
      value: getTrainingVideoText(trainingVideos, videoIds),
    },
  ]
}

export function updateSchedules(
  scheduleA: CreateSchedule,
  scheduleB: CreateSchedule | null,
  data: {
    startTime?: Date
    endTime?: Date
    removeEndDate?: boolean
    endDate?: Date
    removeRecurringSchedule?: boolean
    addRecurringSchedule?: boolean
  },
): CreateSchedule[] {
  const updatedScheduleA: CreateSchedule = {
    ...scheduleA,
    startTime: data.startTime ?? scheduleA.startTime,
    endTime: data.endTime ?? scheduleA.endTime,
    recurringSchedule: scheduleA.recurringSchedule
      ? {
          ...scheduleA.recurringSchedule,
          endDate: data.removeEndDate
            ? undefined
            : (data.endDate ?? scheduleA.recurringSchedule.endDate),
        }
      : undefined,
  }

  let updatedScheduleB: CreateSchedule | undefined
  if (scheduleB) {
    updatedScheduleB = {
      ...scheduleB,
      recurringSchedule: scheduleB.recurringSchedule
        ? {
            ...scheduleB.recurringSchedule,
            endDate: data.removeEndDate
              ? undefined
              : (data.endDate ?? scheduleB.recurringSchedule.endDate),
          }
        : undefined,
    }
  }

  if (updatedScheduleB && (data.startTime || data.endTime)) {
    const { startTime, endTime } = getNextStartAndEndTime(
      updatedScheduleA,
      updatedScheduleB,
    )
    updatedScheduleB.startTime = startTime
    updatedScheduleB.endTime = endTime
  }

  if (data.removeRecurringSchedule) {
    updatedScheduleA.isRecurringSchedule = false
    updatedScheduleA.recurringSchedule = undefined
    if (updatedScheduleB) {
      updatedScheduleB.isRecurringSchedule = false
      updatedScheduleB.recurringSchedule = undefined
    }
  }

  if (data.addRecurringSchedule) {
    const interval = scheduleB ? 2 : 1
    updatedScheduleA.isRecurringSchedule = true
    updatedScheduleA.recurringSchedule = {
      interval,
      freq: 'WEEKLY',
      repeatOn: [],
    }
    if (updatedScheduleB) {
      updatedScheduleB.isRecurringSchedule = true
      updatedScheduleB.recurringSchedule = {
        interval,
        freq: 'WEEKLY',
        repeatOn: [],
      }
    }
  }

  if (updatedScheduleB) {
    return [updatedScheduleA, updatedScheduleB]
  }
  return [updatedScheduleA]
}

export function createBulkShiftRequestSchedules(
  selectedSingleShiftDates: Date[] | null,
  schedules: CreateSchedule[],
): CreateSchedule[][] {
  return (
    selectedSingleShiftDates?.map((date) => {
      const shiftLength = differenceInMinutes(
        schedules[0].endTime,
        schedules[0].startTime,
      )
      const newStartTimeWithTimePersisted = combineTwoDatesForDateAndTime(
        date,
        schedules[0].startTime,
      )
      const newEndTimeWithTimePersisted = addMinutes(
        newStartTimeWithTimePersisted,
        shiftLength,
      )

      return schedules.map((schedule) => ({
        ...schedule,
        startTime: newStartTimeWithTimePersisted,
        endTime: newEndTimeWithTimePersisted,
      }))
    }) ?? [schedules]
  )
}
