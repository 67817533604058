import { Shift } from '@traba/types'
import { Button, ButtonVariant } from 'src/components'
import { theme } from 'src/libs/theme'

export default function EditShiftsSelectedShiftsButton({
  selectedShifts,
  setShowSelectModal,
}: {
  selectedShifts?: Shift[]
  setShowSelectModal?: (show: boolean) => void
}) {
  return (
    <Button
      style={{ marginBottom: theme.space.xxs }}
      variant={ButtonVariant.LINK}
      onClick={() => setShowSelectModal && setShowSelectModal(true)}
    >
      Selected Shifts ({selectedShifts?.length})
    </Button>
  )
}
