import { theme } from '@traba/theme'
import React from 'react'
import styled from 'styled-components'
import { Col } from './Col'
import Row from './Row'
import { SvgIcon } from './SvgIcon'

interface DraggableProps {
  className: string
  children: React.ReactNode
  containerStyle?: React.CSSProperties
  dragHandleStyle?: React.CSSProperties
  isDragging: boolean
  isDraggingClassName: string
}

const DragHandleWrapper = styled.div<{ isDragging: boolean }>`
  height: 80px;
  border-radius: ${theme.space.xxxs}px;
  background-color: ${({ isDragging }) =>
    isDragging ? theme.colors.Violet10 : 'transparent'};
  &:hover {
    background-color: ${({ isDragging }) =>
      isDragging ? theme.colors.Violet10 : theme.colors.Grey10};
  }
`

const DragHandle = styled.div`
  position: relative;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

const Overlay = styled.div<{ className: string }>`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.Grey10};
  z-index: 10;
  border-radius: ${theme.space.xxxs}px;
  ${({ className }) => `
    .${className} & {
      display: block;
    }
  `}
`

function DraggableComponent({
  className,
  containerStyle,
  dragHandleStyle,
  children,
  isDragging,
  isDraggingClassName,
}: DraggableProps) {
  return (
    <Row gap={theme.space.xxs} style={containerStyle}>
      <DragHandleWrapper
        className={className}
        isDragging={isDragging}
        style={dragHandleStyle}
      >
        <DragHandle>
          <SvgIcon
            name="dragHandle"
            size={theme.space.sm}
            color={isDragging ? theme.colors.brand : theme.colors.Grey30}
          />
          <Overlay className={isDraggingClassName} />
        </DragHandle>
      </DragHandleWrapper>
      <Col grow={1} style={{ position: 'relative' }}>
        {children}
        <Overlay className={isDraggingClassName} />
      </Col>
    </Row>
  )
}

export const Draggable = React.memo(DraggableComponent)
