import { ShiftRequestParent } from '@traba/types'
import { Text } from 'src/components'
import { FilterDropdown } from 'src/components/ShiftFilters/FilterDropdown'
import { theme } from 'src/libs/theme'

interface Props {
  shiftRequestParents: Map<string, ShiftRequestParent>
  shiftRequestParentIdFilter: string | undefined
  setShiftRequestParentIdFilter: (id: string) => void
}

export const CalendarShiftRequestParentSelector = ({
  shiftRequestParents,
  setShiftRequestParentIdFilter,
  shiftRequestParentIdFilter,
}: Props) => {
  return (
    <>
      <Text variant="h5" mb={theme.space.xxs} mt={theme.space.med}>
        Schedule
      </Text>
      <FilterDropdown
        label="Schedule"
        selected={shiftRequestParentIdFilter}
        items={Array.from(shiftRequestParents.values())
          .map((shiftRequestParent) => {
            return {
              value: shiftRequestParent.shiftRequestParentId,
              label: shiftRequestParent.title,
            }
          })
          .sort((a, b) => {
            const aValue = shiftRequestParents.get(a.value)
            const bValue = shiftRequestParents.get(b.value)
            if (!aValue || !bValue) {
              // should never happen
              return 0
            }
            return bValue.createdAt.getTime() - aValue.createdAt.getTime()
          })}
        onSelectItem={setShiftRequestParentIdFilter}
        style={{ marginTop: theme.space.xxs, marginBottom: theme.space.sm }}
      />
    </>
  )
}
