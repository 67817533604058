import { JobStatus } from '@traba/types'
import { shiftStatusBadge } from 'src/libs/constants'

import { Badge } from '..'

interface AbandonedBadgeProps {
  jobStatus?: JobStatus
}

export function AbandonedBadge({ jobStatus }: AbandonedBadgeProps) {
  if (jobStatus !== JobStatus.Abandoned) {
    return null
  }
  return (
    <Badge
      variant="danger"
      title={shiftStatusBadge[JobStatus.Abandoned]}
      style={{ marginLeft: 6 }}
    />
  )
}
