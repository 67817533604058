import { useCallback } from 'react'
import {
  Button,
  ButtonVariant,
  Col,
  Row,
  SvgIcon,
  Text,
} from 'src/components/base'
import { theme } from 'src/libs/theme'
import { FeatureAnnouncementModalProps } from './utils'

export function LocationsAssignedUserAnnouncementContent({
  handleClose,
}: FeatureAnnouncementModalProps) {
  const openHelpArticle = useCallback(() => {
    window.open(
      'https://help.traba.work/en/articles/10239103-location-access-for-business-members',
      '_blank',
      'noopener,noreferrer',
    )
  }, [])

  return (
    <Col
      style={{
        background: `linear-gradient(to bottom, ${theme.colors.Violet20}, ${theme.colors.White})`,
        flexDirection: 'column',
        display: 'flex',
        height: '100%',
      }}
    >
      <img
        src="/img/locations-assigned-user-announcement.png"
        alt="Regional Filter Announcement Zommed Out"
        style={{
          width: '95%',
          alignSelf: 'center',
          marginTop: theme.space.lg,
        }}
      />
      <Col
        width="97.5%"
        mt={theme.space.xxs}
        mb={theme.space.xxxs}
        style={{ alignSelf: 'end' }}
        gap={theme.space.xxs}
      >
        <Text variant="h3">Location Access for Members</Text>
        <Text variant="body1">
          Grant your business members access to specific locations or all
          locations in your company.
        </Text>
      </Col>

      <Row
        style={{
          alignSelf: 'flexGrow',
          maxWidth: '100%',
        }}
        mx={theme.space.med}
        mb={theme.space.med}
        mt={theme.space.med}
        justifyBetween
      >
        <Button
          style={{ fontSize: 16 }}
          variant={ButtonVariant.BRANDLINK}
          onClick={openHelpArticle}
          trackingEvent={{
            message: 'User Viewed Locations Assigned User Feature Help Article',
          }}
        >
          <SvgIcon
            name="link"
            size={20}
            style={{
              marginLeft: theme.space.zero,
              marginRight: theme.space.xxxs,
            }}
          />
          Read Help Article
        </Button>
        <Button
          variant={ButtonVariant.FILLED}
          onClick={handleClose}
          trackingEvent={{
            message: 'User Dismissed Locations Assigned User Announcement',
          }}
          style={{
            lineHeight: 2,
            fontSize: 16,
            paddingLeft: theme.space.med,
            paddingRight: theme.space.med,
          }}
        >
          Sounds good
        </Button>
      </Row>
    </Col>
  )
}
