import { useForm } from '@traba/utils'
import { useFormik } from 'formik'
import React from 'react'
import {
  Alert,
  Button,
  ButtonVariant,
  Input,
  InputPhone,
  Row,
} from 'src/components/base'
import { useBilling } from 'src/hooks/useBilling'
import {
  email as emailValidation,
  phoneNumber as phoneNumberValidation,
} from 'src/utils/formUtils'
import * as yup from 'yup'
import { StripeLogo } from '../StripeLogo'
import { StripePaymentTerms } from '../StripePaymentTerms'
import * as S from './CreatePayment.styles'
import { CreatePaymentProps } from '.'

export const CreatePaymentUI: React.FC<CreatePaymentProps> = (
  props: CreatePaymentProps,
) => {
  const {
    phoneNumber,
    email,
    stripeReturnPath,
    isOnboardingFlow,
    completeOnboarding,
  } = props
  const { handleOnSubmitError } = useForm()
  const { startStripeSetupIntent, stripe, isLoading, setIsLoading } =
    useBilling()
  const formik = useFormik({
    initialValues: { email, phoneNumber },
    validationSchema: yup.object({
      email: emailValidation(),
      phoneNumber: phoneNumberValidation(),
    }),
    onSubmit: async (values) => {
      try {
        formik.setStatus({})
        setIsLoading(true)
        const checkoutSessionId = await startStripeSetupIntent({
          email: values.email,
          phoneNumber: values.phoneNumber,
          returnPath: stripeReturnPath,
        })
        await stripe.redirectToCheckout({ sessionId: checkoutSessionId })
      } catch (err) {
        handleOnSubmitError(err, formik)
        setIsLoading(false)
      }
    },
  })

  const { errors, touched } = formik

  const renderSkipPaymentButton = () => {
    return (
      <Row mt={24} mb={24}>
        <Button
          disabled={isLoading}
          full
          type="button"
          variant={ButtonVariant.OUTLINED}
          onClick={() => {
            typeof completeOnboarding === 'function' && completeOnboarding()
          }}
          loading={isLoading}
        >
          Skip
        </Button>
      </Row>
    )
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <S.CreatePaymentContainer>
        <Input
          full
          label="Billing email address"
          {...formik.getFieldProps('email')}
          inputStatus={touched.email && errors.email ? 3 : 1}
          errorMessage={errors.email}
        />
        <InputPhone
          value={formik.values.phoneNumber}
          onChange={(val) =>
            formik.handleChange({
              target: { value: val, name: 'phoneNumber' },
            })
          }
          error={touched.phoneNumber ? errors.phoneNumber : null}
        />
        {formik.status && formik.status.error && (
          <Alert mt={24}>{formik.status.message}</Alert>
        )}
        <StripePaymentTerms />
        <Row mt={32}>
          <Button disabled={isLoading} full loading={isLoading}>
            Add Payment Method
          </Button>
        </Row>
        {isOnboardingFlow ? renderSkipPaymentButton() : null}
        <StripeLogo />
      </S.CreatePaymentContainer>
    </form>
  )
}
