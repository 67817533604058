export const MAX_ANNOUNCEMENT_MESSAGE_LENGTH = 1250 // 1600 char limit in Twilio, but we have some prepended and appended text

export enum SCHEDULING_OPTION {
  SEND_NOW = 'Send now',
  SCHEDULE_FOR_FUTURE = 'Schedule for the future',
}

export const MIN_MINUTES_FOR_FUTURE_ANNOUNCEMENT = 4

export const END_TIME_BUFFER_FOR_NEW_ANNOUNCEMENTS_IN_HOURS = 72
