import styled from 'styled-components'

export const EstimatedTotalContainer = styled.div`
  width: 100%;
  border: 1px solid;
  border-color: ${({ theme }) => theme.border.color};
  box-shadow: 0px 1px 2px rgba(196, 204, 212, 0.4);
  border-radius: ${({ theme }) => theme.border.radius};
  padding: ${({ theme }) => theme.space.sm}px;
`
