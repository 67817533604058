import { LoadingSpinner } from '@traba/react-components'
import { useParams } from 'react-router-dom'
import { MainLayout } from 'src/components'
import { useQueryParams } from 'src/helpers'

import { BookShiftsScreenUI } from '../BookShifts/BookShiftsScreen'
import { EditOperation } from '../BookShifts/types'
import { useEditShift } from './EditShifts.hooks'

export function EditShiftsScreen() {
  const query = useQueryParams()
  const { shiftId } = useParams<{ shiftId: string }>()
  const editOperation = query.get('editOperation') as EditOperation

  const { shift, shiftRequestTemplate, isLoadingShift, isLoadingShiftReq } =
    useEditShift(shiftId, true /* fetchShift */)

  return (
    <MainLayout hideSidebar title="Edit Shifts">
      {isLoadingShift || isLoadingShiftReq ? (
        <LoadingSpinner />
      ) : (
        <BookShiftsScreenUI
          shiftTemplate={shift}
          shiftRequestTemplate={shiftRequestTemplate}
          editOperation={editOperation}
          isEdit
        />
      )}
    </MainLayout>
  )
}
