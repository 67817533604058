import * as Sentry from '@sentry/react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  CancellationSource,
  DailyShiftStatsResponse,
  Shift,
  ShiftCancellationCostResponse,
  ShiftRequest,
  UpdateShift,
} from '@traba/types'
import axios from 'axios'
import { addMonths, differenceInHours, format } from 'date-fns'
import { omitBy } from 'lodash'
import { useMemo } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { RegionalFilterStatus } from 'src/context/appContext/AppContext'
import { ONE_MINUTE_IN_MS } from 'src/libs/constants'
import { Address, SearchParams, UpdateShiftData } from 'src/types'
import { useSelectedRegionalFilterLocations } from './useRegionalFilter'

export type ShiftAndAddress = Shift & {
  address: Address
  locationName?: string
}

export function getActiveQueryParams(searchParams: SearchParams) {
  const activeQueryParams = []

  // Define outside as TS doesn't let you set types within the left side of a for in ..
  let key: keyof SearchParams
  for (key in searchParams) {
    const value = searchParams[key]
    if (value) {
      if (Array.isArray(value)) {
        continue
      } else {
        activeQueryParams.push(`${key}=${value.toString()}`)
      }
    }
  }

  return activeQueryParams
}

const queryShifts = async (
  body: SearchParams,
  reverseResponse?: boolean,
  queryRecurringShifts?: boolean,
): Promise<ShiftAndAddress[]> => {
  // Remove empty string fields because post request will take them as empty strings
  body = omitBy(body, (value) => value === '')
  try {
    const res = await trabaApi.post(
      `my-company/query-shifts${queryRecurringShifts ? `?queryRecurringShifts=true` : ''}`,
      body,
    )
    const data = res.data.shifts
    return reverseResponse ? data.reverse() : data
  } catch (error) {
    console.error('Error querying shifts: ', error, 'Query body: ', body)
    Sentry.captureException(error, {
      tags: { action: 'queryShifts' },
      extra: { body },
    })
    throw error
  }
}

const queryShiftStats = async (
  body: SearchParams,
): Promise<DailyShiftStatsResponse> => {
  // Remove empty string fields because post request will take them as empty strings
  body = omitBy(body, (value) => value === '')
  try {
    const res = await trabaApi.post(`my-company/query-shift-stats`, body)
    return res.data
  } catch (err) {
    console.error('useShifts -> getShiftStats ERROR', err)
    throw err
  }
}

export async function updateShiftById(data: UpdateShiftData): Promise<Shift> {
  const { shiftId, ...body } = data
  const res = await trabaApi.patch(`my-company/shifts/${shiftId}`, body)
  return res.data[0].value.updates
}

export async function getShiftById(
  shiftId: string,
): Promise<Shift | undefined> {
  try {
    const res = await trabaApi.get(`my-company/shifts/${shiftId}`)
    return res.data
  } catch (error) {
    console.error('useShifts -> getShiftById() ERROR', error)
  }
}

export function useShifts(
  searchParams: SearchParams,
  reverseResponse?: boolean,
  isEnabled?: boolean,
  queryRecurringShifts?: boolean,
  overrideRegionalFilterStatus?: RegionalFilterStatus,
): UseQueryResult<ShiftAndAddress[], Error> {
  const { selectedLocationIds, isRegionalFilterEnabledOnPage } =
    useSelectedRegionalFilterLocations(overrideRegionalFilterStatus)
  const activeLocationIds = isRegionalFilterEnabledOnPage
    ? Array.from(selectedLocationIds)
    : undefined

  const queryKey = [
    'shifts',
    searchParams.startBefore?.getDate(),
    searchParams.startAfter?.getDate(),
    searchParams.endBefore?.getDate(),
    searchParams.endAfter?.getDate(),
    searchParams.limit,
    searchParams.sortBy,
    searchParams.sortDir,
    searchParams.startAt,
    searchParams.startAtIndex,
    searchParams.timeApproved,
    searchParams.totalCharge,
    searchParams.regionId,
    searchParams.status,
    searchParams.statuses,
    searchParams.supervisorId,
    searchParams.locationId,
    searchParams.workerId,
    searchParams.workerIds,
    searchParams.workerShiftStatuses,
    searchParams.shiftIds,
    searchParams.shiftRequestParentIds,
    activeLocationIds,
    queryRecurringShifts,
  ]
  return useQuery<ShiftAndAddress[], Error>(
    queryKey,
    () => {
      const { locationId, ...queryShiftsParams } = searchParams

      // if locationId is provided in filters, that takes precedent over the regional filter
      queryShiftsParams.activeLocationIds = locationId
        ? [locationId]
        : activeLocationIds

      return queryShifts(
        queryShiftsParams,
        reverseResponse,
        queryRecurringShifts,
      )
    },
    { enabled: isEnabled },
  )
}

export function useShiftsWithPagination(searchParams: SearchParams) {
  interface PageParams {
    start?: Date
    end?: Date
  }
  const queryKey = [
    'shifts',
    searchParams.startBefore?.getDate(),
    searchParams.startAfter?.getDate(),
    searchParams.endBefore?.getDate(),
    searchParams.endAfter?.getDate(),
    searchParams.limit,
    searchParams.sortBy,
    searchParams.sortDir,
    searchParams.startAt,
    searchParams.startAtIndex,
    searchParams.timeApproved,
    searchParams.totalCharge,
    searchParams.regionId,
    searchParams.status,
    searchParams.statuses,
    searchParams.supervisorId,
    searchParams.workerId,
    searchParams.workerShiftStatuses,
    searchParams.shiftIds,
    searchParams.shiftRequestParentIds,
  ]
  const queryShifts = async (
    pageParam: PageParams,
  ): Promise<{
    data: ShiftAndAddress[]
    nextPage: PageParams
  }> => {
    // Remove empty string fields because post request will take them as empty strings
    searchParams = omitBy(searchParams, (value) => value === '') as SearchParams
    try {
      const res = await trabaApi.post(
        `my-company/query-shifts?queryRecurringShifts=true`,
        {
          ...searchParams,
          startAfter: pageParam.start,
          endBefore: pageParam.end,
        },
      )
      const data = res.data.shifts

      return {
        data,
        nextPage: {
          start: pageParam.start ? addMonths(pageParam.start, 1) : undefined,
          end: pageParam.end ? addMonths(pageParam.end, 1) : undefined,
        },
      }
    } catch (error) {
      console.error(
        'Error querying shifts: ',
        error,
        'Query body: ',
        searchParams,
      )
      Sentry.captureException(error, {
        tags: { action: 'queryShifts' },
        extra: { searchParams },
      })
      throw error
    }
  }

  const {
    isLoading,
    isError,
    error,
    isFetched,
    isFetching,
    data: rawShifts,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery<
    { data: ShiftAndAddress[]; nextPage?: PageParams },
    Error
  >({
    queryKey: [queryKey],
    initialPageParam: {
      start: searchParams.startAfter,
      end: searchParams.endBefore,
    },
    queryFn: ({ pageParam }) => queryShifts(pageParam as PageParams),
    getNextPageParam: (lastPage) => {
      if (lastPage?.data.length === 0) {
        return null
      }

      return lastPage?.nextPage
    },
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    rawShifts,
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetching,
  }
}

export function useShiftStats({
  startBefore,
  startAfter,
  regionId,
  supervisorId,
  locationId,
  status,
  statuses,
}: SearchParams = {}): UseQueryResult<DailyShiftStatsResponse, Error> {
  const { selectedLocationIds, isRegionalFilterEnabledOnPage } =
    useSelectedRegionalFilterLocations()

  // if locationId is present in the filter, that takes precedent over the regional filter locations
  const activeLocationIds = locationId
    ? [locationId]
    : isRegionalFilterEnabledOnPage
      ? Array.from(selectedLocationIds)
      : undefined

  const queryKey = [
    'shiftStats',
    startAfter?.getDate(),
    startBefore?.getDate(),
    startAfter?.getDate(),
    regionId,
    supervisorId,
    locationId,
    status,
    statuses,
    activeLocationIds,
  ]
  return useQuery<DailyShiftStatsResponse, Error>(
    queryKey,
    () =>
      queryShiftStats({
        startBefore,
        startAfter,
        supervisorId,
        status,
        statuses,
        activeLocationIds,
      }),
    {
      staleTime: ONE_MINUTE_IN_MS,
    },
  )
}

export function useShiftById(
  shiftId: string,
  queryKey?: string,
  enabled?: boolean,
) {
  // Get Shift By Id
  const {
    isLoading,
    isError,
    data: shift,
    error,
    isFetched,
    refetch,
  } = useQuery<Shift | undefined, Error>(
    [queryKey || 'shiftById', shiftId],
    () => getShiftById(shiftId),
    { enabled: enabled },
  )
  return {
    isLoading,
    isError,
    shift,
    error,
    isFetched,
    refetch,
  }
}

const getShiftsByShiftRequestId = async (
  shiftRequestId: string,
): Promise<Shift[] | undefined> => {
  try {
    const res = await trabaApi.get(
      `/my-company/shift-requests/${shiftRequestId}/shifts`,
    )
    return res.data
  } catch (err) {
    console.error('useShifts -> getShifts() ERROR', err)
  }
}

export const useShiftsByShiftRequestId = (
  shiftRequestId: string | undefined,
  upcomingShiftsOnly?: boolean,
) => {
  const { isLoading, isError, data, error, isFetched } = useQuery<
    Shift[] | undefined,
    Error
  >(['shifts', shiftRequestId], () =>
    shiftRequestId ? getShiftsByShiftRequestId(shiftRequestId) : [],
  )
  const shifts = useMemo(() => {
    if (!upcomingShiftsOnly) {
      return data
    } else {
      return data?.filter(
        ({ startTime }) =>
          differenceInHours(startTime || new Date(), new Date()) > 1,
      )
    }
  }, [data, upcomingShiftsOnly])
  return { shifts, isLoading, isError, error, isFetched }
}

export const getShiftCancellationCost = async (
  shiftId: string,
): Promise<ShiftCancellationCostResponse> => {
  try {
    const res = await trabaApi.get(
      `/my-company/shifts/${shiftId}/cancellation-costs`,
    )
    return res.data
  } catch (error) {
    console.log('Error retrieving cancellations costs: ', error)
  }
  return {
    cancellations: [],
    calculatedMarkup: 0,
    shiftId: '',
    cancellationChargeSummary: ``,
    cancellationSettings: {},
  }
}

export const useShiftCancellationCost = (
  shiftId: string | undefined,
): ShiftCancellationCostResponse => {
  const { data } = useQuery(['cancellation', shiftId], () =>
    shiftId
      ? getShiftCancellationCost(shiftId)
      : {
          cancellations: [],
          calculatedMarkup: 0,
          shiftId: '',
          cancellationChargeSummary: ``,
          cancellationSettings: {},
        },
  )
  return {
    cancellations: data?.cancellations || [],
    calculatedMarkup: data?.calculatedMarkup || 0,
    shiftId: data?.shiftId || '',
    cancellationChargeSummary: ``,
    cancellationSettings: {},
  }
}

export const cancelShiftsById = async (
  shiftIds: string[],
  cancellationReason: string,
): Promise<string[] | undefined> => {
  try {
    const res = await trabaApi.put(`/my-company/shifts/cancel`, {
      ids: shiftIds,
      cancellationSource: CancellationSource.Business,
      cancellationReason: cancellationReason,
    })
    return res.data
  } catch (err) {
    console.error('useShifts -> getShifts() ERROR', err)
  }
}

export const useUpdateShifts = () => {
  const { showInfo, showError } = useAlert()
  const updateShift = async (shiftIds: string[], updates: UpdateShift) => {
    try {
      return await trabaApi.patch<ShiftRequest>(`my-company/shifts`, updates, {
        params: { shiftIds },
      })
    } catch (error: any) {
      const errorMessage = axios.isAxiosError(error)
        ? error.response?.data?.message
        : error.response?.message
          ? error.response?.message
          : error.message
            ? error.message
            : error

      let rejected, succeeded
      if (Array.isArray(errorMessage) && errorMessage.length) {
        rejected = errorMessage
          .filter((e: any) => e.status === 'rejected')
          .map((e: any) => e?.reason?.message || 'Unable to update shift')
        succeeded = errorMessage
          .filter((e: any) => e.status === 'fulfilled')
          .map((e: any) =>
            e?.value?.startTime
              ? format(new Date(e?.value?.startTime), 'EEE MMM dd')
              : ``,
          )
        if (succeeded.length && rejected.length) {
          showInfo(
            `${rejected.join(', ')} \nSuccessfully updated ${
              succeeded.length
            } shifts (${succeeded.join(', ')})`,
            'Something went wrong',
            10000,
          )
          return {
            status: 206,
            succeeded,
            rejected,
          }
        } else {
          showError(rejected.join(', '), 'Something went wrong')
        }
      } else if (typeof errorMessage === 'string') {
        showError(errorMessage, 'Something went wrong')
      } else {
        showError('Unable to update shift', 'Something went wrong')
      }

      Sentry.captureException(error, {
        tags: { action: 'useUpdateShifts -> updateShift()' },
      })
    }
  }

  return {
    updateShift,
  }
}
