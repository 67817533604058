import styled from 'styled-components'

export const AuthFormLeftContainer = styled.div<{ isMobileView: boolean }>`
  background-color: ${({ theme }) => theme.colors.White};
  border-radius: 10px;
  box-shadow:
    0px -4px 8px 0px rgba(192, 141, 255, 0.2),
    0px 4px 8px 0px rgba(192, 141, 255, 0.2);
  padding: ${({ theme }) => theme.space.lg}px;
  padding-top: ${({ isMobileView, theme }) =>
    isMobileView ? theme.space.sm : 80}px;
`

export const AuthFullScreenContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 32px;
  padding: 32px;
`
