import { DEFAULT_TIMEZONE } from '@traba/consts'
import { Card } from '@traba/react-components'
import { ShiftPayType } from '@traba/types'
import { getShiftTimeString } from '@traba/utils'
import { differenceInDays } from 'date-fns'
import React from 'react'
import { Row, Text } from 'src/components/base'
import GenderBadge from 'src/components/GenderBadge'
import { useCompany } from 'src/hooks/useCompany'
import { useTrainingVideos } from 'src/hooks/useTrainingVideos'
import { theme } from 'src/libs/theme'
import { getTrainingVideoText } from 'src/screens/BookShifts/utils'
import { formatDuration } from 'src/shared/utils/dateUtils'
import { ScheduledBreak } from 'src/types'
import { getMoneyString, getPayUnitString } from 'src/utils/moneyUtils'
import { getAddressString } from 'src/utils/stringUtils'

import { ShiftDetailsHeader } from '../../../base/UpcomingShiftDetailsHeader/ShiftDetailsHeader'
import { PointOfContact } from '../../../PointOfContact'
import { RoleBadges } from '../../../RoleBadges'
import { ShiftBadges } from '../../../ShiftBadges'
import { UpcomingShiftDetailsProps } from '../../UpcomingShiftDetails.hooks'

export const MobileUpcomingShiftInfo: React.FC<UpcomingShiftDetailsProps> = (
  props: UpcomingShiftDetailsProps,
) => {
  const {
    confirmationCode,
    clockOutCode,
    endTime,
    attireDescription,
    scheduledBreaks = [],
    shiftRole,
    status,
    requiredCertifications,
    requiredAttributes,
    videoIds,
    payType,
    numberOfUnits,
    payRate,
    genderPreference,
  } = props.shift
  const startTime = props.shift.businessStartTime ?? props.shift.startTime
  const { location, parkingLocation, userCanViewWages } = props
  const [showMore, setShowMore] = React.useState(false)
  const timezone = location?.timezone || DEFAULT_TIMEZONE
  const { company } = useCompany()
  const { trainingVideos } = useTrainingVideos()
  function displayBreaks(scheduledBreaks: ScheduledBreak[]): JSX.Element {
    const resultingBreaks: string[] = []
    let index = 0
    for (const eachBreak of scheduledBreaks) {
      for (let i = 0; i < eachBreak.count; i++) {
        index = index + i
        resultingBreaks.push(`1 x ${formatDuration(eachBreak.breakLength)}`)
      }
      index++
    }
    return <Text variant="body1">{resultingBreaks.join(', ')}</Text>
  }

  const startLocalTime = new Date(
    startTime.toLocaleString('en-us', { timeZone: timezone }),
  )
  const endLocalTime = new Date(
    endTime.toLocaleString('en-us', { timeZone: timezone }),
  )

  // Difference in number of days between date of the shift start/time
  // instead of the number of 24 hour 'day' periods
  const numDayDifference = differenceInDays(
    endLocalTime.setHours(0, 0, 0, 0),
    startLocalTime.setHours(0, 0, 0, 0),
  )
  const shouldShowGenderBadge =
    company?.allowGenderPreference && !!genderPreference

  return (
    <div>
      <Row justifyBetween alignCenter wrap gap={theme.space.xs}>
        <Text variant="h3" style={{ flex: 1 }}>
          {shiftRole}
        </Text>
        <ShiftDetailsHeader
          shift={props.shift}
          userCanManageShift={props.userCanManageShift}
        />
      </Row>

      <Text variant="body1">
        {startTime.toLocaleDateString('en-us', {
          timeZone: timezone,
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
      </Text>
      <Text variant="body1">
        {getShiftTimeString(startTime, endTime, timezone)}
        {numDayDifference > 0 && ` (+${numDayDifference})`}
      </Text>
      {location?.address && (
        <>
          <Text variant="body1">{location.name}</Text>
          <Text variant="body1">{getAddressString(location.address)}</Text>
        </>
      )}
      <Row alignCenter gap={theme.space.xs} mb={theme.space.xxs}>
        <Text>{`Clock in: ${confirmationCode}`}</Text>
        <Text>{`Clock out: ${clockOutCode}`}</Text>
      </Row>

      <Text
        variant="link"
        onClick={() => {
          setShowMore(!showMore)
        }}
      >
        {showMore ? 'View less' : 'View more'}
      </Text>

      {showMore && (
        <Card
          style={{
            backgroundColor: theme.colors.Grey10,
            borderWidth: 0,
            marginTop: theme.space.xxs,
          }}
        >
          {requiredAttributes && (
            <>
              <Text variant="caption" color={theme.colors.MidnightBlue}>
                REQUIREMENTS
              </Text>
              <RoleBadges requiredAttributes={requiredAttributes} />
            </>
          )}

          <Text
            variant="caption"
            mt={theme.space.xs}
            color={theme.colors.MidnightBlue}
          >
            REQUIRED ATTIRE
          </Text>
          <Text variant="body1">{attireDescription}</Text>

          {requiredCertifications && requiredCertifications.length > 0 && (
            <>
              <Text
                variant="caption"
                color={theme.colors.MidnightBlue}
                mt={theme.space.xs}
              >
                REQUIRED CERTIFICATIONS
              </Text>
              <ShiftBadges
                status={status}
                requiredCertifications={requiredCertifications}
              />
            </>
          )}

          {parkingLocation?.address && (
            <>
              <Text variant="caption" style={{ paddingTop: theme.space.xs }}>
                PARKING ADDRESS
              </Text>
              <Text variant="body1">{parkingLocation.name}</Text>
              <Text variant="body1">
                {getAddressString(parkingLocation.address)}
              </Text>
            </>
          )}
          {props.pointOfContact?.firstName && (
            <>
              <Text
                variant="caption"
                color={theme.colors.MidnightBlue}
                style={{ paddingTop: theme.space.xs }}
              >
                ON-SITE POINT OF CONTACT
              </Text>
              <PointOfContact user={props.pointOfContact} slim />
            </>
          )}
          {userCanViewWages && (
            <>
              <Text variant="caption" color={theme.colors.MidnightBlue}>
                PAY RATE
              </Text>
              <Text variant="body1">
                {getMoneyString(payRate)}
                {getPayUnitString(payType)}
              </Text>
            </>
          )}
          {payType === ShiftPayType.UNIT && (
            <>
              <Text
                variant="caption"
                style={{ paddingTop: theme.space.xs }}
                color={theme.colors.MidnightBlue}
              >
                UNITS
              </Text>
              <Text variant="body1">{numberOfUnits}</Text>
            </>
          )}

          <Text
            variant="caption"
            color={theme.colors.MidnightBlue}
            style={{ paddingTop: theme.space.xs }}
          >
            BREAKS
          </Text>
          <Text variant="body1">
            {scheduledBreaks.length ? displayBreaks(scheduledBreaks) : 'None'}
          </Text>

          {shouldShowGenderBadge && (
            <>
              <Text
                variant="caption"
                style={{ paddingTop: theme.space.xs }}
                color={theme.colors.MidnightBlue}
              >
                GENDER PREFERENCE
              </Text>
              <GenderBadge gender={genderPreference} />
            </>
          )}

          {videoIds && (
            <>
              <Text
                variant="caption"
                style={{ paddingTop: theme.space.xs }}
                color={theme.colors.MidnightBlue}
              >
                REQUIRED TRAINING VIDEOS
              </Text>
              <Text variant="body1">
                {getTrainingVideoText(trainingVideos, videoIds)}
              </Text>
            </>
          )}
        </Card>
      )}
    </div>
  )
}
