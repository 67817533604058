import { SvgIconProps } from 'src/types/svg-types'

export default function ForwardArrow({ size, color, ...rest }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 17" fill="none" {...rest}>
      <path
        d="M14.1464 8.37577C14.3417 8.18051 14.3417 7.86393 14.1464 7.66866L9.35355 2.87577C9.03857 2.56079 8.5 2.78387 8.5 3.22932V5.55522C8.5 5.83136 8.27561 6.05404 7.99965 6.06422C4.80224 6.18211 2.10565 7.49708 1.58916 12.2629C1.53488 12.7637 2.19782 12.9742 2.52023 12.5872C4.07789 10.7173 5.76507 10.1052 7.99968 10.0303C8.27567 10.0211 8.5 10.2461 8.5 10.5222V12.8151C8.5 13.2606 9.03857 13.4836 9.35355 13.1687L14.1464 8.37577Z"
        stroke={color}
        stroke-width="1.5"
      />
    </svg>
  )
}
