import { CardTile } from '@traba/react-components'
import { CancellationSettings, Company, Shift } from '@traba/types'
import { getShiftTimeString } from '@traba/utils'
import { format } from 'date-fns'
import { Text } from 'src/components/base/Text'
import { theme } from 'src/libs/theme'
import { getMoneyString } from 'src/utils/moneyUtils'

import { Col, CompanyLogo } from '../base'
import CancelFeeBadge from '../Modals/CancelShiftModal/CancelFeeBadge'
import { TextContainer } from './CancelShiftLineItem.styles'

export type CancelShiftLineItemProps = {
  shift: Shift
  company: Company
  totalFee: number
  singleWorkerFee: number
  cancellationChargeSummary: string
  cancellationSettings?: CancellationSettings
}

export const CancelShiftLineItem = (props: CancelShiftLineItemProps) => {
  const {
    company,
    shift,
    totalFee,
    singleWorkerFee,
    cancellationChargeSummary,
    cancellationSettings,
  } = props
  const workerString =
    shift.slotsFilled !== 1
      ? `${shift.slotsFilled} workers`
      : `${shift.slotsFilled} worker`

  const shouldWaiveCancellationFees =
    !!cancellationSettings?.shouldWaiveCancellationFees
  return (
    <CardTile
      style={{
        width: '100%',
        height: 'auto',
        borderStyle: 'none',
        paddingTop: theme.space.xs,
      }}
    >
      <CompanyLogo
        style={{
          height: '40px',
          width: '40px',
          marginRight: theme.space.xs,
        }}
        companyLogoUrl={company.companyLogo}
        companyName={company.employerName}
      />
      <TextContainer>
        <Text variant="h7">{shift.shiftRole}</Text>
        <Text variant="body3">
          {format(shift.businessStartTime ?? shift.startTime, 'MMM d, y')}
          {' - '}
          {getShiftTimeString(
            shift.businessStartTime ?? shift.startTime,
            shift.endTime,
            shift.timezone,
          )}
          {' - '}
          {workerString}
        </Text>
      </TextContainer>
      {totalFee > 0 && cancellationChargeSummary ? (
        <CancelFeeBadge
          cancellationChargeSummary={cancellationChargeSummary}
          shouldWaiveCancellationFees={shouldWaiveCancellationFees}
        />
      ) : null}
      <Col
        style={{
          display: 'flex',
          alignItems: 'flex-end',
        }}
      >
        {shouldWaiveCancellationFees ? (
          <>
            <Text variant="h7">{getMoneyString(0)}</Text>
            {totalFee > 0 && (
              <Text
                variant="body3"
                style={{
                  textDecoration: 'line-through',
                }}
              >
                {getMoneyString(totalFee)}
              </Text>
            )}
          </>
        ) : (
          <Text variant="h7">{getMoneyString(totalFee)}</Text>
        )}
        {singleWorkerFee > 0 && !shouldWaiveCancellationFees && (
          <Text variant="body3" style={{ textAlign: 'end' }}>
            {getMoneyString(singleWorkerFee)} / slot
          </Text>
        )}
      </Col>
    </CardTile>
  )
}
