import { Link as RRLink } from 'react-router-dom'
import styled from 'styled-components'

const Link = styled(RRLink)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.004em;
  text-decoration: none;
  color: #8000ff;
  :hover {
    text-decoration: underline;
  }
`

export default Link
