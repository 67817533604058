import { SVGProps } from 'react'
import { theme } from 'src/libs/theme'

export function Logo(props: SVGProps<SVGSVGElement> & { size?: number }) {
  return (
    <svg width={props.size ?? 72} viewBox="0 0 72 20" fill="none" {...props}>
      <path
        d="M10.0833 11.6215L10.0781 19.9951H14.3784V11.7755C14.3784 9.62566 16.1201 7.87094 18.2651 7.87094V3.55078C13.8013 3.55078 10.1598 7.15775 10.0833 11.6215Z"
        fill={theme.colors.brand}
      />
      <path
        d="M26.6948 3.55078C22.18 3.55078 18.5078 7.23984 18.5078 11.7755C18.5078 16.3112 22.18 20.0002 26.6948 20.0002C27.3792 20.0002 28.038 19.9078 28.6714 19.7488V15.1311C28.0891 15.4799 27.415 15.68 26.6948 15.68C24.5549 15.68 22.8081 13.9304 22.8081 11.7755C22.8081 9.62566 24.5497 7.87093 26.6948 7.87093C28.7019 7.87093 30.3619 9.41017 30.561 11.3804L30.5814 20.0002H34.8818L34.8613 11.3804C34.6519 7.02948 31.0768 3.55078 26.6948 3.55078Z"
        fill={theme.colors.brand}
      />
      <path
        d="M71.3887 11.3804C71.1792 7.02948 67.6042 3.55078 63.2221 3.55078C58.7073 3.55078 55.0352 7.23984 55.0352 11.7755C55.0352 16.3112 58.7073 20.0002 63.2221 20.0002C63.9065 20.0002 64.5653 19.9078 65.1986 19.7488V15.1311C64.6164 15.4799 63.9422 15.68 63.2221 15.68C61.0822 15.68 59.3355 13.9304 59.3355 11.7755C59.3355 9.62566 61.077 7.87093 63.2221 7.87093C65.2293 7.87093 66.8891 9.41017 67.0883 11.3804L67.1088 20.0002H71.4091L71.3887 11.3804Z"
        fill={theme.colors.brand}
      />
      <path
        d="M44.9604 3.55052C44.276 3.55052 43.6172 3.64288 42.9839 3.80193V8.41967C43.5661 8.07078 44.2403 7.87067 44.9604 7.87067C47.1004 7.87067 48.847 9.62031 48.847 11.7753C48.847 13.925 47.1054 15.6798 44.9604 15.6798C42.9532 15.6798 41.2934 14.1406 41.0941 12.1703C41.0839 12.0729 41.0737 11.7803 41.0737 11.7753V4.54077V0H36.7734L36.7938 12.1651C37.0033 16.5161 40.5783 19.9948 44.9604 19.9948C49.4752 19.9948 53.1473 16.3057 53.1473 11.7701C53.1473 7.23959 49.4752 3.55052 44.9604 3.55052Z"
        fill={theme.colors.brand}
      />
      <path
        d="M4.30033 0H0V3.55052V7.87067L0.00510728 11.9291C0.0817165 16.3878 3.7232 19.9999 8.18697 19.9999V15.6798C6.04191 15.6798 4.30033 13.925 4.30033 11.7753V7.87067H8.18697V3.55052H4.30033V0Z"
        fill={theme.colors.brand}
      />
    </svg>
  )
}
