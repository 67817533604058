import { SvgIconProps } from '@traba/types'

export default function Send({
  size,
  color = '#7A8A99',
  strokeWidth = 1.5,
  ...props
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M8.16783 2.12886C8.16783 1.57226 7.76105 1.09922 7.21072 1.01587C7.07104 0.994711 6.92897 0.994711 6.78928 1.01587C6.23896 1.09922 5.83216 1.57226 5.83216 2.12887V2.91598C5.51775 3.00572 5.21823 3.13079 4.93817 3.28662L4.38136 2.72981C3.98779 2.33623 3.36566 2.28938 2.91758 2.61958C2.80385 2.70339 2.70339 2.80385 2.61958 2.91758C2.28938 3.36566 2.33622 3.9878 2.7298 4.38138L3.28661 4.93818C3.13078 5.21823 3.00572 5.51775 2.91598 5.83217H2.12887C1.57226 5.83217 1.09922 6.23896 1.01587 6.78928C0.994711 6.92897 0.994711 7.07104 1.01587 7.21072C1.09922 7.76105 1.57226 8.16783 2.12886 8.16783H2.91597C3.00571 8.48225 3.13078 8.78177 3.28661 9.06182L2.72981 9.61863C2.33623 10.0122 2.28938 10.6343 2.61958 11.0824C2.70339 11.1962 2.80385 11.2966 2.91759 11.3804C3.36566 11.7106 3.98779 11.6638 4.38137 11.2702L4.93817 10.7134C5.21823 10.8692 5.51774 10.9943 5.83216 11.084V11.8711C5.83216 12.4277 6.23895 12.9008 6.78928 12.9841C6.92896 13.0053 7.07104 13.0053 7.21072 12.9841C7.76105 12.9008 8.16783 12.4277 8.16783 11.8711V11.084C8.48225 10.9943 8.78177 10.8692 9.06182 10.7134L9.61863 11.2702C10.0122 11.6638 10.6343 11.7106 11.0824 11.3804C11.1962 11.2966 11.2966 11.1962 11.3804 11.0824C11.7106 10.6343 11.6638 10.0122 11.2702 9.61864L10.7134 9.06183C10.8692 8.78178 10.9943 8.48225 11.084 8.16783H11.8711C12.4277 8.16783 12.9008 7.76105 12.9841 7.21072C13.0053 7.07104 13.0053 6.92897 12.9841 6.78928C12.9008 6.23896 12.4277 5.83217 11.8711 5.83217H11.084C10.9943 5.51775 10.8692 5.21823 10.7134 4.93817L11.2702 4.38136C11.6638 3.98779 11.7106 3.36566 11.3804 2.91758C11.2966 2.80385 11.1962 2.70339 11.0824 2.61958C10.6343 2.28938 10.0122 2.33623 9.61863 2.7298L9.06182 3.28661C8.78177 3.13078 8.48225 3.00571 8.16783 2.91598V2.12886Z"
        stroke={color}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 7C6 6.44772 6.44772 6 7 6C7.55228 6 8 6.44772 8 7C8 7.55228 7.55228 8 7 8C6.44772 8 6 7.55228 6 7Z"
        stroke="#7A8A99"
        stroke-width="1.5"
      />
    </svg>
  )
}
