import { SvgIconProps } from 'src/types/svg-types'

export default function ManuAndProd({ size = 52, ...props }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 42 42" fill="none" {...props}>
      <circle cx="21" cy="21" r="21" fill="#E6E8FF" />
      <path d="M15.8 15.877L20.075 20.2386L15.8 15.877Z" fill="#6600CC" />
      <path
        d="M12.95 12L15.8 12.9692V15.877H12.95L12 12.9692L12.95 12Z"
        fill="#6600CC"
      />
      <path
        d="M28.396 12.7182L25.8998 15.265C25.5236 15.6489 25.3355 15.8408 25.265 16.0621C25.203 16.2568 25.203 16.4665 25.265 16.6611C25.3355 16.8824 25.5236 17.0744 25.8998 17.4582L26.1252 17.6882C26.5014 18.072 26.6895 18.2639 26.9064 18.3358C27.0972 18.3991 27.3028 18.3991 27.4936 18.3358C27.7105 18.2639 27.8986 18.072 28.2748 17.6882L30.6098 15.3058C30.8613 15.9302 31 16.614 31 17.3309C31 20.275 28.6607 22.6617 25.775 22.6617C25.4271 22.6617 25.0871 22.627 24.7583 22.5609C24.2964 22.4679 24.0655 22.4215 23.9255 22.4357C23.7767 22.4508 23.7034 22.4736 23.5715 22.5456C23.4474 22.6133 23.323 22.7403 23.0742 22.9941L16.275 29.9311C15.488 30.734 14.212 30.734 13.425 29.9311C12.638 29.1281 12.638 27.8263 13.425 27.0233L20.2242 20.0864C20.473 19.8325 20.5974 19.7056 20.6638 19.579C20.7344 19.4445 20.7567 19.3696 20.7715 19.2178C20.7855 19.075 20.7399 18.8394 20.6489 18.3682C20.584 18.0326 20.55 17.6858 20.55 17.3309C20.55 14.3867 22.8893 12 25.775 12C26.7302 12 27.6256 12.2615 28.396 12.7182Z"
        fill="#6600CC"
      />
      <path
        d="M21.5 24.6002L26.725 29.931C27.512 30.7339 28.788 30.7339 29.575 29.931C30.362 29.128 30.362 27.8262 29.575 27.0232L25.2765 22.6378C24.9723 22.6084 24.6756 22.5524 24.3887 22.4721C24.0191 22.3686 23.6137 22.4437 23.3427 22.7202L21.5 24.6002Z"
        fill="#6600CC"
      />
      <path
        d="M15.8 15.877L20.075 20.2386M15.8 15.877H12.95L12 12.9692L12.95 12L15.8 12.9692V15.877ZM28.396 12.7182L25.8998 15.265C25.5236 15.6489 25.3355 15.8408 25.265 16.0621C25.203 16.2568 25.203 16.4665 25.265 16.6611C25.3355 16.8824 25.5236 17.0744 25.8998 17.4582L26.1252 17.6882C26.5014 18.072 26.6895 18.2639 26.9064 18.3358C27.0972 18.3991 27.3028 18.3991 27.4936 18.3358C27.7105 18.2639 27.8986 18.072 28.2748 17.6882L30.6098 15.3058C30.8613 15.9302 31 16.614 31 17.3309C31 20.275 28.6607 22.6617 25.775 22.6617C25.4271 22.6617 25.0871 22.627 24.7583 22.5609C24.2964 22.4679 24.0655 22.4215 23.9255 22.4357C23.7767 22.4508 23.7034 22.4736 23.5715 22.5456C23.4474 22.6133 23.323 22.7403 23.0742 22.9941L16.275 29.9311C15.488 30.734 14.212 30.734 13.425 29.9311C12.638 29.1281 12.638 27.8263 13.425 27.0233L20.2242 20.0864C20.473 19.8325 20.5974 19.7056 20.6638 19.579C20.7344 19.4445 20.7567 19.3696 20.7715 19.2178C20.7855 19.075 20.7399 18.8394 20.6489 18.3682C20.584 18.0326 20.55 17.6858 20.55 17.3309C20.55 14.3867 22.8893 12 25.775 12C26.7302 12 27.6256 12.2615 28.396 12.7182ZM21.5 24.6002L26.725 29.931C27.512 30.7339 28.788 30.7339 29.575 29.931C30.362 29.128 30.362 27.8262 29.575 27.0232L25.2765 22.6378C24.9723 22.6084 24.6756 22.5524 24.3887 22.4721C24.0191 22.3686 23.6137 22.4437 23.3427 22.7202L21.5 24.6002Z"
        stroke="#6600CC"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
