import { useState } from 'react'
import { useDebounce } from 'react-use'

export function useDebouncedState<T>(
  initialState: T,
  debouncedValueChanging?: (newValue: T) => void,
  delayMs = 300,
): [T, T, (newState: T) => void] {
  const [state, setState] = useState<T>(initialState)
  const [debouncedState, setDebouncedState] = useState<T>(initialState)
  useDebounce(
    () => {
      debouncedValueChanging && debouncedValueChanging(state)
      setDebouncedState(state)
    },
    delayMs,
    [state],
  )
  return [state, debouncedState, setState]
}
