import { AxiosError } from 'axios'
import React from 'react'
import { UseMutateFunction } from 'react-query'
import { Text } from 'src/components/base'
import useAppAuth from 'src/hooks/authHook'
import { theme } from 'src/libs/theme'
import { UserProfile } from 'src/types'

import { PersonalInfoSection } from './PersonalInfoSection'
import * as S from './PersonalProfile.styles'
import { PersonalProfileNotificationsCard } from './PersonalProfileNotificationsCard'

export type PersonalProfileProps = {
  user: UserProfile | undefined
  patchUser: UseMutateFunction<
    UserProfile,
    AxiosError<any>,
    Partial<UserProfile>,
    UserProfile
  >
  isLoading?: boolean
}

export const PersonalProfileUI: React.FC<PersonalProfileProps> = (
  props: PersonalProfileProps,
) => {
  const { userHasEmailProvider } = useAppAuth()
  const showUpdatePassword = userHasEmailProvider()

  const [editMode, setEditMode] = React.useState<
    'information' | 'password' | undefined
  >()

  return (
    <S.PersonalProfileContainer>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <div>
          <Text style={{ marginBottom: theme.space.xxs }} variant="h5">
            Personal settings
          </Text>
          <Text variant="body2">Manage your personal settings.</Text>
        </div>
      </div>
      <PersonalInfoSection
        user={props.user}
        patchUser={props.patchUser}
        setEditMode={setEditMode}
        editMode={editMode}
        showUpdatePassword={showUpdatePassword}
      />
      <PersonalProfileNotificationsCard
        communicationPreferences={props.user?.communicationPreferences}
      />
    </S.PersonalProfileContainer>
  )
}
