import { AuthScreenWrapper } from 'src/components/auth'
import { BasicDetailsForm } from 'src/components/auth/BasicDetailsForm'
import { FillRateRightCol } from 'src/components/auth/FillRateRightCol'
import { Text } from 'src/components/base'

export default function FinalizeInvitationScreen() {
  const leftCol = (
    <>
      <Text variant="h2">Finish Invitation</Text>
      <BasicDetailsForm cta="Join Traba" />
    </>
  )
  return (
    <AuthScreenWrapper
      helmetTitle={'Finalize Invitation'}
      leftCol={leftCol}
      rightCol={<FillRateRightCol />}
    />
  )
}
