import {
  EditMemberModalContent,
  EditMemberModalContentUpdateUser,
} from '@traba/react-components'
import { RecordStatus, UserAccessLevel } from '@traba/types'
import {
  assignedLocationIdsForMember,
  getStartOfRecentFiveMinBlock,
} from '@traba/utils'
import { useCallback, useMemo } from 'react'
import { useValidRegionsMap } from 'src/hooks/useLocationRegions'
import { useRegionLocationMap } from 'src/hooks/useLocations'
import { useMemberIdToMemberMap, useMembers } from 'src/hooks/useMembers'
import { useShiftsLocationsForSupervisor } from 'src/hooks/useShiftsLocationsForSupervisor'
import {
  useActiveCompanyLocationsForUser,
  useMemberLocationAccessManagement,
  useUser,
} from 'src/hooks/useUser'
import { UserWithRole } from 'src/types'

export interface EditMemberModalProps {
  member: UserWithRole
  handleClose: () => void
}

export function EditMemberModal({ member, handleClose }: EditMemberModalProps) {
  const {
    members,
    isLoading: isLoadingMembers,
    updateMember,
    isUpdateMemberLoading,
  } = useMembers()
  const { memberIdToMemberMap } = useMemberIdToMemberMap()
  const { regionMap, isLoading: isLoadingRegionMap } = useValidRegionsMap()
  const { activeRegionsWithLocationsMap, isLoading: loadingRegionLocationMap } =
    useRegionLocationMap()
  const { activeLocationIdsForUser } = useActiveCompanyLocationsForUser()
  const { memberIdsManageableByUserLocationAccess } =
    useMemberLocationAccessManagement()
  const { user } = useUser()

  // Adding locations that the member has access to the map in case they are assigned to the locations
  // that current user does not have access to.
  const activeRegionsWithLocationsMapWithMemberLocations = useMemo(() => {
    return (
      member.locations?.reduce((acc, location) => {
        if (location.recordStatus === RecordStatus.Archived) {
          return acc
        }

        if (!activeRegionsWithLocationsMap[location.regionId]) {
          acc[location.regionId] = []
        }
        if (
          !activeRegionsWithLocationsMap[location.regionId].some(
            (loc) => loc.locationId === location.locationId,
          )
        ) {
          acc[location.regionId].push(location)
        }
        return acc
      }, activeRegionsWithLocationsMap) || activeRegionsWithLocationsMap
    )
  }, [member.locations, activeRegionsWithLocationsMap])

  // using the start of the 5min block to take advantage of cached query with 5min stale-time
  const startOfLastFiveMinBlock = getStartOfRecentFiveMinBlock(
    new Date(),
  ).toISOString()

  const { locations } = useShiftsLocationsForSupervisor({
    supervisorId: member.uid,
    minShiftStartTime: startOfLastFiveMinBlock,
  })

  const onUpdateMember = useCallback(
    async (updatedMemberProps: EditMemberModalContentUpdateUser) => {
      await updateMember(
        {
          uid: member.uid,
          ...updatedMemberProps,
          locationIds:
            updatedMemberProps.locationIds ||
            assignedLocationIdsForMember(member),
          userAccessLevel:
            updatedMemberProps.userAccessLevel || member.userAccessLevel,
        },
        {
          onSuccess: handleClose,
        },
      )
      window.analytics.track(`User Saved Edit Member`, {
        prevMemberData: member,
        ...updatedMemberProps,
      })
    },
    [updateMember, handleClose, member],
  )

  return (
    <EditMemberModalContent
      member={member}
      handleClose={handleClose}
      onUpdateMember={onUpdateMember}
      loading={
        isLoadingMembers ||
        loadingRegionLocationMap ||
        isLoadingRegionMap ||
        isUpdateMemberLoading
      }
      replacementMembers={members}
      locationsForFutureShifts={locations}
      regionMap={regionMap}
      regionToLocationsMap={activeRegionsWithLocationsMapWithMemberLocations}
      activeLocationIdsForUser={activeLocationIdsForUser}
      memberIdToMemberMap={memberIdToMemberMap}
      disableEditMemberRole={
        !!member.uid && !memberIdsManageableByUserLocationAccess.has(member.uid)
      }
      disableAccessLevelUpdate={
        user?.userAccessLevel === UserAccessLevel.LOCATIONS_ASSIGNED
      }
      locationSelectorLabel={
        user?.userAccessLevel === UserAccessLevel.LOCATIONS_ASSIGNED
          ? 'Assign to your location(s)'
          : undefined
      }
      analyticsTrackingFunction={window.analytics.track}
    />
  )
}
