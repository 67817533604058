import React from 'react'

import { useInvoiceDownloadButton } from './InvoiceButton.hooks'
import { InvoiceButtonUI, InvoiceButtonProps } from './InvoiceButtons.ui'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook
export const InvoiceButton = (props: InvoiceButtonProps) => {
  const logic = useInvoiceDownloadButton()

  return <InvoiceButtonUI {...logic} {...props} />
}
