import styled from 'styled-components'

export const ShiftCalendarContainer = styled.div``

export const FiltersContainer = styled.div<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : undefined)};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
