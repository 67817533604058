import { SvgIconProps } from '@traba/types'

export default function CloseChat({
  size,
  color = '#7A8A99',
  strokeWidth = 1.5,
  ...props
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M6.85603 1.99057L6.70247 2.02733C4.76807 2.49038 3.25023 4.06445 2.78455 6.09042C2.52991 7.19823 2.53223 8.36392 2.78687 9.47173C3.25984 11.5294 4.65111 13.2418 6.51747 14.0398L6.59882 14.0745C7.40647 14.4198 8.33167 14.004 8.66307 13.1567C8.75428 12.9235 8.97146 12.7688 9.21143 12.7688H9.94215C11.9296 12.7688 13.6585 11.339 14.125 9.30947C14.3566 8.30165 14.3566 7.25038 14.125 6.24256L14.0638 5.97645C13.6142 4.0203 12.1486 2.50047 10.2809 2.05338L10.0185 1.99057C8.9777 1.74142 7.89686 1.74142 6.85603 1.99057Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M9.91478 5.79736L6.97827 8.73388M9.91478 8.73388L6.97827 5.79736"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  )
}
