import { Menu, MenuItem, Tooltip } from '@mui/material'

import { theme } from '@traba/theme'
import { IconName, SvgIconProps } from '@traba/types'
import React from 'react'

import { Button, ButtonVariant } from '../Button/Button'
import { SvgIcon } from '../SvgIcon'

export interface DotMenuProps {
  style?: React.CSSProperties | undefined
  dotMenuKey?: string | number
  type: string
  menuItems: {
    title: string
    onClick: () => void
    color?: string
    iconName?: IconName
    iconProps?: Partial<SvgIconProps>
    disabled?: boolean
    toolTip?: string
    hidden?: boolean
  }[]
  disabled?: boolean
  buttonVariant?: ButtonVariant
  showToolTipWhenDisabled?: boolean
  buttonIconName?: IconName
}

export function DotMenu({
  type,
  dotMenuKey,
  menuItems,
  disabled,
  style,
  buttonVariant,
  showToolTipWhenDisabled,
  buttonIconName,
}: DotMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<
    null | SVGElement | HTMLButtonElement
  >(null)
  const open = !!anchorEl
  function handleClick(
    event: React.MouseEvent<SVGElement | HTMLButtonElement>,
  ) {
    setAnchorEl(event.currentTarget)
  }
  function handleClose() {
    setAnchorEl(null)
  }
  const menuListItems = menuItems
    .filter((item) => !item.hidden)
    .map((item, index) => {
      const menuItem = (
        <MenuItem
          key={index}
          onClick={() => {
            item.onClick()
            handleClose()
          }}
          sx={{ color: item.color }}
          disabled={item.disabled}
        >
          {item.iconName && (
            <SvgIcon
              name={item.iconName}
              size={16}
              style={{ marginRight: 8 }}
              color={item.color || undefined}
              {...item.iconProps}
            />
          )}
          {item.title}
        </MenuItem>
      )
      if (item.toolTip) {
        if (showToolTipWhenDisabled) {
          return (
            <Tooltip key={item.title} title={item.toolTip} placement="right">
              <div>{menuItem}</div>
            </Tooltip>
          )
        } else {
          return (
            <Tooltip key={item.title} title={item.toolTip} placement="right">
              {menuItem}
            </Tooltip>
          )
        }
      }
      return menuItem
    })

  if (menuListItems.length === 0) {
    disabled = true
  }
  return (
    <>
      <Button
        disabled={disabled}
        variant={buttonVariant ?? ButtonVariant.OUTLINED}
        onClick={handleClick}
        aria-label="Dot Menu"
        style={{
          height: theme.space.xl,
          width: theme.space.xl,
          border: open ? `1px solid ${theme.colors.brand}` : undefined,
          ...style,
        }}
      >
        <SvgIcon
          name={buttonIconName || (open ? 'ellipsesFilled' : 'ellipses')}
          size={theme.space.xs}
          color={open ? theme.colors.brand : theme.colors.Grey50}
        />
      </Button>
      <Menu
        id={`${type}-menu-${dotMenuKey}`}
        style={{
          marginTop: theme.space.xxs / 2,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `${type}-menu-button-${dotMenuKey}`,
        }}
      >
        {menuListItems}
      </Menu>
    </>
  )
}
