import { Tooltip } from '@mui/material'
import {
  Button,
  Col,
  Input,
  LoadingSpinner,
  Text,
  Toggle,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  isShiftRequestParentInactive,
  recurringSchedulesEnabled,
} from '@traba/utils'
import { useEffect } from 'react'
import { useMemo } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ButtonVariant,
  EmptyPlaceholderTile,
  MainLayout,
  Row,
} from 'src/components'
import { EmptyDataLocationSubtitle } from 'src/components/base/RegionalFilterSelect/EmptyDataLocationSubtitle'
import { RegionalFilterButton } from 'src/components/base/RegionalFilterSelect/RegionalFilterButton'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'
import { useCompany } from 'src/hooks/useCompany'
import useMobile from 'src/hooks/useMobile'
import { useShiftRequestParents } from 'src/hooks/useShiftRequestParents'
import { useHotSettings } from 'src/hooks/useSystem'
import { SchedulesList } from './SchedulesList'

const SchedulesScreen = () => {
  const { dispatch: appContextDispatch } = useAppContext()
  const navigate = useNavigate()
  const [
    showCompletedShiftRequestParents,
    setShowCompletedShiftRequestParents,
  ] = useState<boolean>(false)
  const { hotSettings, isLoading: isLoadingHotSettings } = useHotSettings()
  const { company, isLoading: isLoadingCompany } = useCompany()
  const { isMobileViewOrReactNative, isReactNativeApp } = useMobile()
  const { isLoading: isLoadingShiftRequestParents, shiftRequestParents } =
    useShiftRequestParents({ includeCompletedSchedules: false })
  const [searchFilter, setSearchFilter] = useState<string>('')

  useEffect(() => {
    appContextDispatch({
      type: 'SET_REGIONAL_FILTER_STATUS',
      value: RegionalFilterStatus.ENABLED,
    })
    return () => {
      appContextDispatch({
        type: 'SET_REGIONAL_FILTER_STATUS',
        value: RegionalFilterStatus.HIDE,
      })
    }
  }, [appContextDispatch])

  useEffect(() => {
    if (isLoadingHotSettings || isLoadingCompany) {
      return
    }
    if (
      !recurringSchedulesEnabled({
        companyId: company?.companyId,
        hotSettings,
      })
    ) {
      navigate('/dashboard')
    }
  }, [company, hotSettings, isLoadingCompany, isLoadingHotSettings, navigate])

  const filteredSchedules = useMemo(
    () =>
      shiftRequestParents
        ?.filter((srp) => {
          const lowerCaseFilter = searchFilter.toLowerCase()
          return srp.title.toLowerCase().includes(lowerCaseFilter)
        })
        ?.filter(
          (srp) =>
            showCompletedShiftRequestParents ||
            !isShiftRequestParentInactive(srp),
        ),
    [shiftRequestParents, searchFilter, showCompletedShiftRequestParents],
  )

  if (isLoadingShiftRequestParents) {
    return <LoadingSpinner />
  }

  const schedulesExistButNotInFilteredResults =
    filteredSchedules?.length === 0 && !!shiftRequestParents?.length
  const emptyListTitle = schedulesExistButNotInFilteredResults
    ? 'No schedules matching the filters'
    : 'You have no schedules in selected locations'
  const emptyListSubtitle = schedulesExistButNotInFilteredResults
    ? undefined
    : 'to see schedules in those locations, or book a new shift.'

  return (
    <MainLayout title="Schedules">
      <div
        style={{ marginTop: isMobileViewOrReactNative ? 0 : theme.space.xs }}
      >
        <Row
          justifyBetween
          mb={theme.space.med}
          style={{
            columnGap: theme.space.xxs,
            marginBottom: isMobileViewOrReactNative
              ? theme.space.xs
              : undefined,
          }}
        >
          <Col>
            <Text variant="h2">Schedules</Text>
            <Input
              placeholder="Search schedules"
              leftIconName="search"
              name="scheduleSearch"
              type="text"
              defaultValue=""
              width={'100%'}
              value={searchFilter}
              onChange={(e) => {
                e.preventDefault()
                setSearchFilter(e.target.value)
              }}
              onClear={() => setSearchFilter('')}
            />
          </Col>
          <Col>
            <Tooltip title="Create schedule">
              <Button
                variant={ButtonVariant.FILLED}
                style={{ flexShrink: 0 }}
                onClick={() =>
                  navigate(
                    recurringSchedulesEnabled({
                      hotSettings,
                      companyId: company?.companyId,
                    })
                      ? '/new'
                      : '/book-shifts',
                  )
                }
              >
                Create a schedule
              </Button>
            </Tooltip>
            <Toggle
              label="Show inactive schedules"
              buttonState={showCompletedShiftRequestParents}
              runOnChange={() =>
                setShowCompletedShiftRequestParents(
                  !showCompletedShiftRequestParents,
                )
              }
              containerStyle={{ marginTop: theme.space.sm }}
            />
          </Col>
        </Row>

        {isReactNativeApp && (
          <Row
            style={{
              marginLeft: theme.space.zero,
              marginBottom: theme.space.xxs,
            }}
          >
            <RegionalFilterButton />
          </Row>
        )}
        {filteredSchedules && filteredSchedules.length > 0 ? (
          <SchedulesList shiftRequestParents={filteredSchedules} />
        ) : (
          <EmptyPlaceholderTile
            title={emptyListTitle}
            subtitle={
              emptyListSubtitle && (
                <EmptyDataLocationSubtitle
                  isMobileView={isMobileViewOrReactNative}
                  extraText={emptyListSubtitle}
                />
              )
            }
            iconName="folder"
          />
        )}
      </div>
    </MainLayout>
  )
}

export default SchedulesScreen
