import { Col, MODAL_SIZE } from '@traba/react-components'
import { DotMenu } from '@traba/react-components'
import { hasRole, matchesOutstandingOrAcceptedInvitation } from '@traba/utils'
import React, { useCallback, useState } from 'react'
import { Modal, Table, Td, Tr } from 'src/components'
import { useUserContext } from 'src/context/user/UserContext'
import { useInvitations, useMembers } from 'src/hooks/useMembers'
import { useUserCanManageUsers } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { UserData, UserRolePermission, UserWithRole } from 'src/types'
import { formatPhoneNumber } from 'src/utils/phoneNumberUtils'
import { sortByName } from 'src/utils/stringUtils'
import { hasPermissions } from 'src/utils/userUtils'
import { ArchiveUserModal } from '../Modals/ArchiveUserModal'
import { CreateInvitationModal } from '../Modals/CreateInvitationModal'
import { CreateMemberModal } from '../Modals/CreateMemberModal'
import { MEMBERS_COPY } from './constants'
import { StyledTdEditMenuContainer } from './MembersProfile.styles'
import { MembersSubheader } from './MembersSubheader'

interface ContactTableMenuProps {
  contact: UserData
  isInvitable: boolean
  onInvite: (contact: UserData) => void
  onArchive: (contact: UserData) => void
}

function ContactTableMenu({
  contact,
  isInvitable,
  onInvite,
  onArchive,
}: ContactTableMenuProps) {
  const menuItems = [
    {
      title: isInvitable ? 'Invite contact' : 'Already invited',
      onClick: () => {
        onInvite(contact)
      },
    },
    {
      title: 'Archive contact',
      onClick: () => {
        onArchive(contact)
      },
      color: theme.colors.Red60,
    },
  ]
  return (
    <StyledTdEditMenuContainer>
      <DotMenu type="contact" dotMenuKey={contact.uid} menuItems={menuItems} />
    </StyledTdEditMenuContainer>
  )
}

interface ContactTableProps
  extends Pick<ContactTableMenuProps, 'onInvite' | 'onArchive'> {
  members: UserData[]
  isLoading?: boolean
  error?: Error | null
  userCanEdit: boolean
  isContactInvitable: (c: UserData) => boolean
}

const ContactsTable: React.FC<ContactTableProps> = ({
  members,
  onInvite,
  onArchive,
  isContactInvitable,
  userCanEdit,
  isLoading,
  error,
}) => {
  return (
    <Table
      headers={['Name', 'Email', 'Phone', ...(userCanEdit ? [''] : [])]}
      style={{
        marginBottom: members.length === 0 ? undefined : theme.space.lg,
      }}
      itemType="contacts"
      showEmptyState
      isLoading={isLoading}
      error={error}
    >
      {members.map((m) => (
        <Tr key={m.uid}>
          <Td>
            {m.firstName} {m.lastName}
          </Td>
          <Td>{m.email}</Td>
          <Td>{m.phoneNumber ? formatPhoneNumber(m.phoneNumber, true) : ''}</Td>
          {userCanEdit && (
            <ContactTableMenu
              contact={m}
              onInvite={onInvite}
              onArchive={onArchive}
              isInvitable={isContactInvitable(m)}
            />
          )}
        </Tr>
      ))}
    </Table>
  )
}

export function ContactsSection() {
  const { members, isLoading, error } = useMembers()
  const membersWithoutRoles = members
    .filter((m) => !hasRole(m))
    .sort(sortByName)

  const userContext = useUserContext()
  const userCanEditActiveMembersAndInvitations = useUserCanManageUsers()
  const userCanAddContacts = hasPermissions(userContext.state.userProfile, [
    UserRolePermission.ManageShifts,
  ])

  const { invitations } = useInvitations()

  const [memberToInvite, setMemberToInvite] = useState<UserData | null>(null)
  const [memberToArchive, setMemberToArchive] = useState<
    UserWithRole | UserData | null
  >(null)
  const [showInvitationModal, setShowInvitationModal] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)
  const [showArchiveModal, setShowArchiveModal] = useState(false)

  const onArchive = useCallback((m: UserWithRole | UserData) => {
    setMemberToArchive(m)
    setShowArchiveModal(true)
    window.analytics.track(`User Clicked Archive Contact`, {
      contact: m,
    })
  }, [])

  const onInviteMember = useCallback((m: UserData) => {
    setMemberToInvite(m)
    setShowInvitationModal(true)
    window.analytics.track(`User Clicked Invite Contact`, {
      contact: m,
    })
  }, [])

  const handleContactModalOpen = useCallback(() => {
    setShowContactModal(true)
    window.analytics.track(`User Clicked Add Contact`)
  }, [])

  const handleContactModalClose = useCallback(() => {
    setShowContactModal(false)
  }, [])

  const handleInvitationModalClose = useCallback(() => {
    setShowInvitationModal(false)
    setMemberToInvite(null)
  }, [])

  const handleArchiveModalClose = useCallback(() => {
    setShowArchiveModal(false)
    setMemberToArchive(null)
  }, [])

  const isContactInvitable = useCallback(
    (c: UserData) => {
      return !invitations?.find((i) =>
        matchesOutstandingOrAcceptedInvitation(c, i),
      )
    },
    [invitations],
  )

  return (
    <Col gap={theme.space.xs}>
      <MembersSubheader
        title={MEMBERS_COPY.CONTACTS_TITLE}
        description={
          userCanAddContacts
            ? MEMBERS_COPY.CONTACTS_EDITOR_DESCRIPTION
            : MEMBERS_COPY.CONTACTS_VIEWER_DESCRIPTION
        }
        action={MEMBERS_COPY.CONTACTS_ACTION}
        onClick={handleContactModalOpen}
        userCanTakeAction={userCanAddContacts}
      />
      <div>
        <ContactsTable
          userCanEdit={userCanEditActiveMembersAndInvitations}
          onInvite={onInviteMember}
          onArchive={onArchive}
          members={membersWithoutRoles}
          isContactInvitable={isContactInvitable}
          isLoading={isLoading}
          error={error}
        />
      </div>

      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showInvitationModal}
        handleClose={handleInvitationModalClose}
      >
        <CreateInvitationModal
          handleClose={handleInvitationModalClose}
          member={memberToInvite}
        />
      </Modal>
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showContactModal}
        handleClose={handleContactModalClose}
      >
        <CreateMemberModal onCloseModal={handleContactModalClose} />
      </Modal>
      <Modal
        size={MODAL_SIZE.MEDIUM}
        isOpen={showArchiveModal}
        handleClose={handleArchiveModalClose}
      >
        {memberToArchive && (
          <ArchiveUserModal
            member={memberToArchive}
            handleClose={handleArchiveModalClose}
          />
        )}
      </Modal>
    </Col>
  )
}
