import { DEFAULT_TIMEZONE } from '@traba/consts'
import { useHotSettings } from '@traba/hooks'
import {
  ShiftPayType,
  ShiftStatus,
  WorkerShiftWithWorkerDetails,
} from '@traba/types'
import { isAfter } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { EmptyPlaceholderTile, Input, Row, Table } from 'src/components'
import { useUserContext } from 'src/context/user/UserContext'
import { useCompany } from 'src/hooks/useCompany'
import { useWorkersWithSegments } from 'src/hooks/useCostCenters'
import { useTimeToDestinations } from 'src/hooks/useTimeToDestinations'
import { useUserPermissions } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { anyToDate, getTimeZoneAbbreviation } from 'src/shared/utils/dateUtils'
import { UserRolePermission } from 'src/types'
import { hasPermissions } from 'src/utils/userUtils'
import { Checkbox } from '../base/CheckboxThemed'
import useNavigationGuard from '../NavigationGuard/NavigationGuard'
import { TableHeaderItem } from '../Table/Table.styles'
import { WorkerOnShiftTableActions } from './components/WorkerOnShiftTableActions'
import { WorkerOnShiftTableHeader } from './components/WorkerOnShiftTableHeader'
import { WorkerOnShiftListItem } from './WorkerOnShiftListItem'
import { WorkersOnShiftTableProps } from './WorkersOnShiftTable.hooks'

const TABLE_ROW_HEIGHT = 66
const TABLE_ROW_HEADER_HEIGHT = 116

export default function DesktopWorkersOnShiftTable(
  props: WorkersOnShiftTableProps,
) {
  const {
    shift,
    connections,
    isUpcoming,
    activeWorkerShifts,
    inactiveWorkerShifts = [],
    workersOnBackup,
    editMode,
    onClickInviteWorker,
    useTotalTitle,
    totalTimeWorked,
    totalChargedCents,
    hideHeader,
    maxRowsBeforeScroll,
    isEditSlotsEnabled = false,
    onClickEditWorkerSlots,
  } = props
  useNavigationGuard({
    when: !!editMode,
    message: 'You have unsaved edits, do you really want to leave?',
  })

  const userContext = useUserContext()
  const userCanManageWorkers = hasPermissions(userContext.state.userProfile, [
    UserRolePermission.ManageWorkers,
  ])
  const { timeToDestinations } = useTimeToDestinations(shift?.shiftId)
  const userCanViewWages = useUserPermissions([UserRolePermission.ViewPay])
  const timezone = shift?.timezone || DEFAULT_TIMEZONE
  const shiftIsCanceled = shift && shift.status === ShiftStatus.CANCELED
  const showEarnedMoney = userCanViewWages && !isUpcoming && !shiftIsCanceled
  const isPBUShift = shift?.payType === ShiftPayType.UNIT
  const [allSelected, setAllSelected] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<
    WorkerShiftWithWorkerDetails[]
  >([])
  const [searchFilter, setSearchFilter] = useState('')
  const backupWorkerIds = new Set(
    (workersOnBackup || []).map((w) => w.workerId),
  )
  const allWorkers: Array<WorkerShiftWithWorkerDetails> = useMemo(
    () => [
      ...activeWorkerShifts,
      ...(workersOnBackup || []),
      ...inactiveWorkerShifts,
    ],
    [activeWorkerShifts, workersOnBackup, inactiveWorkerShifts],
  )

  const { hotSettings } = useHotSettings()
  const { company } = useCompany()
  const enableWorkerSegments =
    hotSettings?.costCenterEnabledByCompanyIds?.includes(
      company?.companyId ?? '',
    )
  const timeToDestinationsByWorkerId = new Map(
    timeToDestinations?.workerShiftTimeToDestinations?.map((ttd) => [
      ttd.workerId,
      ttd,
    ]) || [],
  )

  // Filter the allWorkers array based on searchString and selectedWorkerShifts
  const filteredWorkers = allWorkers.filter((workerShift) => {
    const { worker: workerInfo } = workerShift

    // Check if the workerShift is in the selectedWorkerShifts array
    const isInSelectedShifts =
      !editMode &&
      selectedRows.some(
        (selectedRow) => selectedRow.workerId === workerShift.workerId,
      )

    // Check if the name includes the searchString (case-insensitive)
    const name = workerInfo.firstName + ' ' + workerInfo.lastName
    const includesSearchString = name
      .toLowerCase()
      .includes(searchFilter.toLowerCase())

    // Keep the worker if either it's in selectedWorkerShifts or the name includes the searchString
    return isInSelectedShifts || includesSearchString
  })

  useEffect(() => {
    if (
      filteredWorkers?.length &&
      filteredWorkers?.length === selectedRows?.length
    ) {
      setAllSelected(true)
    } else {
      setAllSelected(false)
    }
  }, [selectedRows, filteredWorkers, setAllSelected])
  const tzAbbreviation = getTimeZoneAbbreviation(
    shift.businessStartTime ?? shift.startTime,
    timezone,
  )
  const IsShiftInTheFuture = isAfter(
    anyToDate(shift.businessStartTime ?? shift.startTime),
    new Date(),
  )
  const selectionDisallowed = editMode || !filteredWorkers.length

  const bulkActions = (
    <WorkerOnShiftTableActions
      {...props}
      shift={shift}
      connections={connections}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      editMode={editMode}
      timezone={timezone}
    />
  )

  const { data: workersSegmentsMap } = useWorkersWithSegments(shift.shiftId)

  const headers = [
    <div
      key="worker name header"
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: theme.space.xxs,
        fontWeight: 600,
      }}
    >
      <Checkbox
        selected={allSelected}
        disabled={selectionDisallowed}
        hidden={editMode}
        onClick={() => {
          allSelected ? setSelectedRows([]) : setSelectedRows(filteredWorkers)
          setAllSelected(!allSelected)
        }}
        style={{ justifyContent: 'center' }}
      />
      Worker name
    </div>,
    ...(isUpcoming ? ['Status'] : []),
    ...(enableWorkerSegments ? ['Cost Center'] : []),
    `Clock in (${tzAbbreviation})`,
    `Clock out (${tzAbbreviation})`,
    'Break Time',
    ...(isPBUShift ? ['Units Completed'] : []),
    'Worked Time',
    ...(showEarnedMoney ? ['Worker Charge'] : []),
    ...(userCanManageWorkers && !editMode ? ['Actions'] : []),
    ...(userCanManageWorkers && !editMode
      ? hideHeader
        ? [<TableHeaderItem>{bulkActions}</TableHeaderItem>]
        : ['']
      : []), // context menu
  ]

  const numberOfBackupWorkers = workersOnBackup?.length || 0

  const workersTotal =
    activeWorkerShifts.length +
    numberOfBackupWorkers +
    inactiveWorkerShifts.length

  if (!shift) {
    return null
  }

  const searchInput = (
    <Input
      placeholder="Search workers by name..."
      leftIconName="search"
      name="workerSearch"
      containerStyle={{
        margin: 0,
      }}
      type="text"
      defaultValue=""
      width="300px"
      value={searchFilter}
      onChange={(e) => {
        e.preventDefault()
        setSearchFilter(e.target.value)
      }}
      onClear={() => setSearchFilter('')}
    />
  )

  const handleWorkerShiftRowSelection = (
    checked: boolean,
    workerShift: WorkerShiftWithWorkerDetails,
  ) => {
    setSelectedRows((prevSelectedRows) => {
      if (checked) {
        return prevSelectedRows.filter(
          ({ workerId }) => workerId !== workerShift.workerId,
        )
      } else {
        return [...prevSelectedRows, workerShift]
      }
    })
  }

  return (
    <>
      {!hideHeader && (
        <>
          <Row justifyBetween alignCenter mb={theme.space.sm}>
            <WorkerOnShiftTableHeader
              useTotalTitle={useTotalTitle}
              totalChargedCents={totalChargedCents}
              totalTimeWorked={totalTimeWorked}
              workersTotal={workersTotal}
              isUpcoming={isUpcoming}
              filteredWorkers={filteredWorkers}
              IsShiftInTheFuture={IsShiftInTheFuture}
              shift={shift}
              numberOfBackupWorkers={numberOfBackupWorkers}
              userCanViewWages={userCanViewWages}
              onClickInviteWorker={onClickInviteWorker}
              isEditSlotsEnabled={isEditSlotsEnabled}
              onClickEditWorkerSlots={onClickEditWorkerSlots}
            />
          </Row>
          <Row alignCenter wrap justifyBetween mb={theme.space.xs}>
            {searchInput}
            {bulkActions}
          </Row>
        </>
      )}
      {filteredWorkers.length > 0 ? (
        <div
          style={
            maxRowsBeforeScroll
              ? {
                  maxHeight:
                    TABLE_ROW_HEADER_HEIGHT +
                    maxRowsBeforeScroll * TABLE_ROW_HEIGHT,
                  overflowY: 'auto',
                  overflowX: 'auto',
                }
              : {
                  overflowX: 'auto',
                }
          }
        >
          <Table textAlignEndLast headers={headers} showEmptyState>
            {filteredWorkers.map((workerShift, index, array) => {
              const checked = !!selectedRows.find(
                ({ workerId }) => workerId === workerShift.workerId,
              )
              const isLastRow = index === array.length - 1
              return (
                <WorkerOnShiftListItem
                  key={workerShift.id}
                  {...props}
                  checked={checked}
                  onCheckBoxClicked={() => {
                    handleWorkerShiftRowSelection(checked, workerShift)
                  }}
                  isBackup={backupWorkerIds.has(workerShift.workerId)}
                  isPBUShift={isPBUShift}
                  reviewColSpan={headers.length}
                  shift={shift}
                  showEarnedMoney={showEarnedMoney}
                  timeToDestinationsByWorkerId={timeToDestinationsByWorkerId}
                  timezone={timezone}
                  workerShift={workerShift}
                  connections={connections}
                  editMode={editMode}
                  isUpcoming={isUpcoming}
                  tableRowStyle={
                    hideHeader && isLastRow ? { borderBottom: 0 } : {}
                  }
                  enableWorkerSegments={enableWorkerSegments}
                  workerWithSegments={
                    workersSegmentsMap?.[workerShift.workerId]
                  }
                />
              )
            })}
          </Table>
        </div>
      ) : (
        <EmptyPlaceholderTile
          iconName="info"
          title={
            searchFilter
              ? 'No workers found'
              : "You don't currently have any workers on this shift"
          }
          subtitle={
            IsShiftInTheFuture && !hideHeader
              ? 'Click on Invite Workers to invite workers to the shift.'
              : ''
          }
          style={hideHeader ? { border: 'none' } : {}}
        />
      )}
    </>
  )
}
