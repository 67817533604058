import { fromDate } from '@internationalized/date'
import { theme } from '@traba/theme'
import { formatDuration, getLocalTimezone } from '@traba/utils'
import React from 'react'
import {
  Col,
  DateField,
  Image,
  Row,
  Td,
  TimeField,
} from '../../base-components'
export function WorkerShiftInfo(props: {
  timezone: string
  photoUrl: string | undefined
  firstName: string
  lastName: string
  clockInTime: Date
  clockOutTime?: Date
  workedTime: number
  totalBreakTime: number
}): React.JSX.Element {
  return (
    <>
      <Td>
        <Row style={{ columnGap: theme.space.xxs, alignItems: 'center' }}>
          <Col>
            {props.photoUrl && (
              <Image
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: theme.space.xxs,
                }}
                src={props.photoUrl}
                alt="worker-photo"
              />
            )}
          </Col>
          <Col>{`${props.firstName} ${props.lastName}`}</Col>
        </Row>
      </Td>
      <Td>
        <DateField
          aria-label="shift-day"
          value={fromDate(props.clockInTime, getLocalTimezone())}
          granularity="day"
          isReadOnly={true}
        />
      </Td>
      <Td>
        <TimeField
          aria-label="clockIn"
          time={props.clockInTime}
          timezone={props.timezone}
          isReadOnly={true}
        />
      </Td>
      <Td>
        <TimeField
          aria-label="clockOut"
          time={props.clockOutTime}
          timezone={props.timezone}
          isReadOnly={true}
        />
      </Td>
      {props.clockOutTime && <Td>{formatDuration(props.workedTime)}</Td>}
      {props.clockOutTime && <Td>{formatDuration(props.totalBreakTime)}</Td>}
    </>
  )
}
