import { Card } from '@traba/react-components'
import { useForm } from '@traba/utils'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import React from 'react'
import { UseMutateFunction } from 'react-query'
import {
  Button,
  ButtonVariant,
  Input,
  InputPhone,
  Row,
  Text,
} from 'src/components/base'
import { theme } from 'src/libs/theme'
import { UserProfile } from 'src/types'
import { email, name, phoneNumber } from 'src/utils/formUtils'
import { formatPhoneNumber } from 'src/utils/phoneNumberUtils'
import * as yup from 'yup'

interface Props {
  setEditMode: React.Dispatch<
    React.SetStateAction<'information' | 'password' | undefined>
  >
  user: UserProfile | undefined
  patchUser: UseMutateFunction<
    UserProfile,
    AxiosError<unknown>,
    Partial<UserProfile>,
    UserProfile
  >
}

export const UpdatePersonalInfoSection = ({
  setEditMode,
  user,
  patchUser,
}: Props) => {
  const { handleOnSubmitError } = useForm()
  const defaultUser = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    phoneNumber: formatPhoneNumber(user?.phoneNumber || ''),
  }

  const formik = useFormik({
    initialValues: defaultUser,
    validationSchema: yup.object({
      phoneNumber: phoneNumber(),
      email: email(),
      firstName: name(),
      lastName: name(),
    }),
    onSubmit: async (values) => {
      const trimmedValues = {
        ...values,
        firstName: values?.firstName?.trim(),
        lastName: values?.lastName?.trim(),
        email: values?.email?.trim(),
        phoneNumber: values.phoneNumber.trim(),
      }
      try {
        patchUser(trimmedValues)
        setEditMode(undefined)
      } catch (err: unknown) {
        handleOnSubmitError(err, formik)
      }
    },
  })

  const { errors, touched } = formik

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <Text variant="h5">Full Name</Text>
        <Input
          label="First name"
          width="100%"
          {...formik.getFieldProps('firstName')}
          inputStatus={touched.firstName && errors.firstName ? 3 : 1}
          errorMessage={errors.firstName}
        />
        <Input
          label="Last name"
          width="100%"
          {...formik.getFieldProps('lastName')}
          inputStatus={touched.lastName && errors.lastName ? 3 : 1}
          errorMessage={errors.lastName}
        />
        <Text mt={theme.space.sm} variant="h5">
          Email address
        </Text>

        <Input
          label="Email address"
          width="100%"
          {...formik.getFieldProps('email')}
          inputStatus={touched.email && errors.email ? 3 : 1}
          errorMessage={errors.email}
        />

        <Text mt={theme.space.sm} variant="h5">
          Phone number
        </Text>

        <InputPhone
          value={formik.values.phoneNumber}
          onChange={(val: string) =>
            formik.handleChange({
              target: { value: val, name: 'phoneNumber' },
            })
          }
          error={touched.phoneNumber ? errors.phoneNumber : null}
        />
        <Row mt={theme.space.sm}>
          <Button
            style={{ marginRight: '8px', height: 40 }}
            full
            onClick={() => {
              setEditMode(undefined)
            }}
            variant={ButtonVariant.OUTLINED}
          >
            Discard changes
          </Button>
          <Button
            type="submit"
            full
            style={{ height: 40 }}
            variant={ButtonVariant.FILLED}
          >
            Save changes
          </Button>
        </Row>
      </Card>
    </form>
  )
}
