import { InputStatus, RegionIdToRegionMap } from '@traba/types'
import { useCallback, useMemo } from 'react'
import { SearchSelect } from '../../SearchSelect/SearchSelect'
import { IMenuItem, MenuItemGroup } from '../../Select/Select'
import { RegionIdToPartialLocationMap } from './types'
import {
  createLocationMenuItemFromLocation,
  createRegionMenuItemGroups,
} from './utils'

interface Props {
  selectedLocationIds?: Set<string>
  disabled?: boolean
  disabledTooltipText?: string
  placeholder?: string
  onChange?: (locationIds: Set<string>) => void
  errorMessage?: string
  onBlur?: () => void
  isLoading?: boolean
  regionMap: RegionIdToRegionMap
  regionToLocationsMap: RegionIdToPartialLocationMap
  // If filteredLocationIds are provided, the options will be limited to only the locations
  // within the filteredLocationIds set
  filteredLocationIds?: Set<string>
  label?: string
  style?: React.CSSProperties
  selectStyle?: React.CSSProperties
  disabledLocationIds?: Set<string>
}

export function GroupedLocationMultiSearchSelector({
  selectedLocationIds = new Set(),
  disabled,
  disabledTooltipText,
  placeholder,
  onChange,
  errorMessage,
  onBlur,
  regionMap,
  regionToLocationsMap,
  isLoading,
  filteredLocationIds,
  label,
  style,
  selectStyle,
  disabledLocationIds,
}: Props) {
  const regionGroups: MenuItemGroup[] = useMemo(
    () => createRegionMenuItemGroups(regionToLocationsMap, regionMap),
    [regionToLocationsMap, regionMap],
  )

  const locationOptions: IMenuItem[] = useMemo(
    () =>
      regionGroups.flatMap(({ id: regionId }) =>
        regionToLocationsMap[regionId].map((location) => ({
          ...createLocationMenuItemFromLocation(location),
          disabled: disabledLocationIds?.has(location.locationId),
        })),
      ),
    [regionGroups, regionToLocationsMap, disabledLocationIds],
  )

  const displayedOptions = useMemo(
    () =>
      filteredLocationIds && filteredLocationIds.size > 0
        ? locationOptions.filter(({ value }) => filteredLocationIds.has(value))
        : locationOptions,
    [filteredLocationIds, locationOptions],
  )

  const selectedItems = useMemo(() => {
    return displayedOptions.filter((item) =>
      selectedLocationIds.has(item.value),
    )
  }, [displayedOptions, selectedLocationIds])

  const onSelectMultipleLocations = useCallback(
    (newItems: IMenuItem[]) => {
      if (onChange) {
        onChange(new Set(newItems?.map((item) => item.value)))
      }
    },
    [onChange],
  )

  return (
    <SearchSelect
      label={label || 'Location(s)'}
      multiple
      selectedItems={selectedItems}
      handleSelectMultiple={onSelectMultipleLocations}
      isLoading={isLoading}
      disabled={disabled || !onChange}
      style={{ width: '100%', maxWidth: '480px', ...style }}
      labelStyle={{ fontSize: '12px' }}
      options={displayedOptions}
      groupByGroup
      shouldAlsoSearchSecondaryLabel
      groups={regionGroups}
      onBlur={onBlur}
      inputStatus={errorMessage ? InputStatus.error : undefined}
      errorMessage={errorMessage}
      selectStyle={selectStyle}
      multipleNoneSelectedLabel={placeholder}
      isGroupsSelectable
      disabledTooltipText={disabledTooltipText}
    />
  )
}
