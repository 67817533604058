import { theme } from '@traba/theme'
import { formatDuration } from '@traba/utils'
import { differenceInMinutes } from 'date-fns'
import React, { useMemo } from 'react'
import { Col, InlineBanner } from '../../base-components'
import { EditSegment, SegmentError, SegmentErrorProperties } from '../types'

export function ModalBanners(props: {
  segments: EditSegment[]
  invalidRows: Record<string, SegmentError[]>
  needsConfirmation: boolean
}): React.JSX.Element {
  const { segments, invalidRows, needsConfirmation } = props

  const totalUnassignedTime = useMemo(
    () =>
      segments
        .filter(
          (s): s is EditSegment & { startTime: Date; endTime: Date } =>
            !!s.isUnaccounted && !!s.startTime && !!s.endTime,
        )
        .reduce((total, segment) => {
          return total + differenceInMinutes(segment.endTime, segment.startTime)
        }, 0),
    [segments],
  )

  const uniqueErrors = useMemo(() => {
    const errors = Object.values(invalidRows).flat()
    if (errors.length === 0) {
      return []
    }

    return [
      ...new Set(errors.filter((e) => !SegmentErrorProperties[e].isWarning)),
    ]
  }, [invalidRows])

  const uniqueWarns = useMemo(() => {
    const errors = Object.values(invalidRows).flat()
    if (errors.length === 0) {
      return []
    }

    return [
      ...new Set(errors.filter((e) => SegmentErrorProperties[e].isWarning)),
    ]
  }, [invalidRows])

  return (
    <Col gap={theme.space.xxs}>
      {needsConfirmation && (
        <InlineBanner
          severity="warning"
          text="Rows have been formatted. Please confirm once more before saving."
        />
      )}
      {totalUnassignedTime > 0 && (
        <InlineBanner
          severity="warning"
          text={`There is [${formatDuration(totalUnassignedTime)}] minutes of unaccounted time in this worker's shift. Please confirm this is accurate.`}
        />
      )}
      {uniqueErrors.length > 0 && (
        <InlineBanner
          severity="error"
          text={
            <>
              There are some validation errors. Please edit to continue:
              {uniqueErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </>
          }
        />
      )}
      {uniqueWarns.length > 0 && (
        <InlineBanner
          severity="warning"
          text={
            <>
              There are some rows that may need attention:
              {uniqueWarns.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </>
          }
        />
      )}
    </Col>
  )
}
