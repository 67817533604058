import { useAlert } from '@traba/context'
import { LoadingSpinner } from '@traba/react-components'
import { isEmpty } from 'lodash'
import { MobileUpcomingShiftInfo } from './components/mobile/MobileUpcomingShiftInfo'
import { MobileUpcomingShiftSummary } from './components/mobile/MobileUpcomingShiftSummary'
import { UpcomingShiftInfo } from './components/UpcomingShiftInfo'
import { UpcomingShiftSummary } from './components/UpcomingShiftSummary'
import { useUpcomingShiftDetails } from './UpcomingShiftDetails.hooks'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook

export const UpcomingShiftDetails = ({
  shiftId,
  showShiftSummary = true,
  showHeader,
}: {
  shiftId: string
  showShiftSummary?: boolean
  showHeader?: boolean
}) => {
  const { showSuccess, handleError } = useAlert()
  const onShiftUpdateSuccess = () => {
    showSuccess('Shift updated successfully!')
  }
  const onShiftUpdateError = (error: any) => {
    handleError(
      error,
      'useUpcomingShiftDetails',
      'Failed to update the shift. Please try again or contact support.',
      'Error updating shift',
    )
  }
  const props = useUpcomingShiftDetails(
    shiftId,
    onShiftUpdateSuccess,
    onShiftUpdateError,
    showHeader,
  )
  const { loading, shift, isMobileViewOrReactNative } = props
  if (loading) {
    return <LoadingSpinner />
  }
  if (isEmpty(shift)) {
    return null
  }

  if (isMobileViewOrReactNative) {
    return (
      <>
        <MobileUpcomingShiftInfo {...props} />
        {showShiftSummary && <MobileUpcomingShiftSummary {...props} />}
      </>
    )
  }

  return (
    <>
      <UpcomingShiftInfo {...props} />
      {showShiftSummary && <UpcomingShiftSummary {...props} />}
    </>
  )
}
