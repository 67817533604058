import { Tooltip } from '@mui/material'
import { BannerWithTitleAndLink, MODAL_SIZE } from '@traba/react-components'
import { recurringSchedulesEnabled } from '@traba/utils'
import { startOfDay } from 'date-fns'
import { useMemo, useState } from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MainLayout } from 'src/components'
import { Button, ButtonVariant, Col, Row, Text } from 'src/components/base'
import { Modal } from 'src/components/base/Modal/Modal'
import { RegionalFilterButton } from 'src/components/base/RegionalFilterSelect/RegionalFilterButton'
import { LocationsAssignedUserAnnouncementContent } from 'src/components/Modals/FeatureAnnouncementModal/LocationsAssignedUsersAnnouncementContent'
import { SDMAnnouncementContent } from 'src/components/Modals/FeatureAnnouncementModal/SDMAnnouncementContent'
import { FeatureAnnouncementType } from 'src/components/Modals/FeatureAnnouncementModal/utils'
import { RoleCreatedModalContent } from 'src/components/Modals/RoleCreatedModalContent'
import { SmsConsentModalContent } from 'src/components/Modals/SmsConsentModal/SmsConsentModalContent'
import { SETTINGS_TAB_KEYS } from 'src/components/Settings/types'
import { ShiftCalendar } from 'src/components/ShiftCalendar'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'
import { useUserContext } from 'src/context/user/UserContext'
import { useCompany } from 'src/hooks/useCompany'
import useMobile from 'src/hooks/useMobile'
import {
  useRemoveUsersJoinedDate,
  useSetUsersJoinedDateForNotificationsBanner,
  useShowNewUserNotificationsBanner,
} from 'src/hooks/useNewUserNotificationsBanner'
import { useHotSettings } from 'src/hooks/useSystem'
import { HAS_SHOWN_SMS_CONSENT_MODAL } from 'src/libs/constants'
import { theme } from 'src/libs/theme'
import { UserRolePermission } from 'src/types'
import { hasPermissions } from 'src/utils/userUtils'

export const ACTIVE_FEATURE = FeatureAnnouncementType.SDM // FeatureAnnouncementType.YOUR_FEATURE (or NONE to disable feature announcement)

export default function DashboardScreen() {
  const { dispatch: appContextDispatch } = useAppContext()
  const { hotSettings } = useHotSettings()
  const { isMobileViewOrReactNative, isReactNativeApp } = useMobile()
  const { state } = useUserContext()
  const navigate = useNavigate()
  const location = useLocation()
  const { company } = useCompany()
  const { modalType, prevPath } = location.state || {}
  const [showRoleModal, setShowRoleModal] = useState(false)
  const [showSmsConsentModal, setShowSmsConsentModal] = useState(false)
  const [showFeatureAnnouncementModal, setShowFeatureAnnouncementModal] =
    useState(false)
  const [showFeatureAnnouncementBanner, setShowFeatureAnnouncementBanner] =
    useState(false)

  const [
    showLocationsAssignedUserFeatureAnnouncementModal,
    setShowLocationsAssignedUserFeatureAnnouncementModal,
  ] = useState(false)

  // we want to direct users to set their notifications when they are first invited
  const showNotificationsBanner = useShowNewUserNotificationsBanner()

  const isRecurringScheduleEnabled = useMemo(
    () =>
      recurringSchedulesEnabled({
        hotSettings,
        companyId: company?.companyId,
      }),
    [company?.companyId, hotSettings],
  )
  // Show sms consent modal after feature announcement
  // Only show once for user who hasn't opted in, unless they're chosen to receive all messages by admin
  const shouldShowSmsConsentModal =
    !state.userProfile?.smsConsent?.agreedToSmsConsent &&
    (!localStorage.getItem(HAS_SHOWN_SMS_CONSENT_MODAL) ||
      state.userProfile?.communicationPermissions?.receiveAllShiftCodesSms)
  const featureKey = `FEATURE_ANNOUNCEMENT_${state.userProfile?.uid}_${ACTIVE_FEATURE}`
  const locationsAssignedUserFeatureKey = `FEATURE_ANNOUNCEMENT_${state.userProfile?.uid}_${FeatureAnnouncementType.LocationsAssignedUser}`

  useEffect(() => {
    switch (modalType) {
      case 'create-role-success':
        setShowRoleModal(true)
        window.history.replaceState({}, '') // clears modalType so it doesn't show on refresh
        return
    }
    if (isMobileViewOrReactNative) {
      return
    }
    // check if we should show the "new user notifications banner"
    if (prevPath === '/accept-invitation') {
      useSetUsersJoinedDateForNotificationsBanner()
      window.history.replaceState({}, '') // clears prevPath to not reset on refresh
      return
    }

    const hasSeenFeatureAnnouncement = !!localStorage.getItem(featureKey)
    if (hasSeenFeatureAnnouncement) {
      if (shouldShowSmsConsentModal) {
        setShowSmsConsentModal(true)
        localStorage.setItem(
          HAS_SHOWN_SMS_CONSENT_MODAL,
          new Date().toISOString(),
        )
        window.analytics.track(`User Viewed SMS Consent Modal`)
      }
    }
    // Note: Comment out for adding new feature announcement
    //     else {
    //  setShowFeatureAnnouncementModal(true)
    //     }
  }, [])

  useEffect(() => {
    const hasSeenFeatureAnnouncement = !!localStorage.getItem(featureKey)
    if (
      !hasSeenFeatureAnnouncement &&
      isRecurringScheduleEnabled &&
      !isReactNativeApp
    ) {
      setShowFeatureAnnouncementBanner(true)
    }
  }, [featureKey, isReactNativeApp, isRecurringScheduleEnabled])

  // This is for the location assigned user feature announcement that will be shown once to the user
  // This useEffect will be removed in this ticket: https://linear.app/traba/issue/ENG-12391/clean-up-announcement-modal-and-announcement-banner-in-biz-app-code
  useEffect(() => {
    const hasSeenLocationsAssignedUserFeatureAnnouncement =
      !!localStorage.getItem(locationsAssignedUserFeatureKey)
    if (!hasSeenLocationsAssignedUserFeatureAnnouncement) {
      setShowLocationsAssignedUserFeatureAnnouncementModal(true)
      localStorage.setItem(
        locationsAssignedUserFeatureKey,
        new Date().toISOString(),
      )
      window.analytics.track(
        `User Viewed Locations Assigned User Announcement Modal`,
      )
    }
  }, [])

  useEffect(() => {
    appContextDispatch({
      type: 'SET_REGIONAL_FILTER_STATUS',
      value: RegionalFilterStatus.ENABLED,
    })
    return () => {
      appContextDispatch({
        type: 'SET_REGIONAL_FILTER_STATUS',
        value: RegionalFilterStatus.HIDE,
      })
    }
  }, [appContextDispatch])

  const shouldFreezeShiftPosting = hotSettings?.freezeShiftsPosting

  const userCanCreate = hasPermissions(state.userProfile, [
    UserRolePermission.ManageShifts,
  ])
  const createButtons = isReactNativeApp ? null : (
    <Tooltip
      title={
        shouldFreezeShiftPosting ? `Shift posting temporarily disabled` : ''
      }
    >
      <Button
        variant={ButtonVariant.FILLED}
        style={{ flexShrink: 0 }}
        aria-label="Book a shift"
        trackingEvent={{
          message: 'User Clicked Book Shifts',
          properties: {
            source: 'Dashboard',
          },
        }}
        onClick={() =>
          navigate(
            recurringSchedulesEnabled({
              hotSettings,
              companyId: company?.companyId,
            })
              ? '/new'
              : '/book-shifts',
          )
        }
        disabled={shouldFreezeShiftPosting}
      >
        Book a shift
      </Button>
    </Tooltip>
  )
  // get all shifts starting at the last midnight in local time zone to make sure whole day shifts are included
  const startOfTodayInLocalTime = startOfDay(new Date())

  return (
    <MainLayout title="Dashboard">
      <div
        style={{ marginTop: isMobileViewOrReactNative ? 0 : theme.space.xs }}
      >
        <Row
          justifyBetween
          alignCenter
          mb={theme.space.med}
          style={{
            columnGap: theme.space.xxs,
            marginBottom: isMobileViewOrReactNative
              ? theme.space.xs
              : undefined,
          }}
        >
          <Text variant="h2">{`Welcome ${state.userProfile?.firstName}!`}</Text>

          {userCanCreate ? <Col>{createButtons}</Col> : null}
        </Row>

        {showNotificationsBanner && (
          <Row fullWidth mb={theme.space.sm}>
            <BannerWithTitleAndLink
              title="Customize your notification settings"
              body="Update your notifications anytime in Notification Settings under the Account Settings tab."
              onClickCta={() => {
                navigate(
                  `/account-settings?tab=${SETTINGS_TAB_KEYS.NOTIFICATION_SETTINGS}`,
                )
                useRemoveUsersJoinedDate()
              }}
              linkText="Edit Notifications"
            />
          </Row>
        )}

        {!showNotificationsBanner &&
          isRecurringScheduleEnabled &&
          showFeatureAnnouncementBanner && (
            <Row fullWidth mb={theme.space.sm}>
              <BannerWithTitleAndLink
                title="Introducing Shift Schedules!"
                body="We made it easier to book recurring shifts and manage the
                  workers that sign up for them."
                onClickCta={() => {
                  setShowFeatureAnnouncementModal(true)
                  localStorage.setItem(featureKey, new Date().toISOString())
                  window.analytics.track(
                    `User Viewed Shift Schedules Feature Announcement Modal`,
                  )
                }}
                linkText="Learn more"
              />
            </Row>
          )}
        {isReactNativeApp && (
          <Row
            style={{
              marginLeft: theme.space.zero,
              marginBottom: theme.space.xxs,
            }}
          >
            <RegionalFilterButton />
          </Row>
        )}

        <ShiftCalendar after={startOfTodayInLocalTime} />
      </div>
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showRoleModal}
        handleClose={() => setShowRoleModal(false)}
      >
        <RoleCreatedModalContent setShowRoleModal={setShowRoleModal} />
      </Modal>
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showSmsConsentModal}
        handleClose={() => setShowSmsConsentModal(false)}
      >
        <SmsConsentModalContent
          setShowSmsConsentModal={setShowSmsConsentModal}
        />
      </Modal>
      <Modal
        size={MODAL_SIZE.EXTRA_EXTRA_LARGE}
        isOpen={showFeatureAnnouncementModal}
        handleClose={() => setShowFeatureAnnouncementModal(false)}
        style={{ overflow: 'hidden' }}
        disableCloseOnBackdropClick={true}
      >
        <SDMAnnouncementContent
          activeFeature={ACTIVE_FEATURE}
          handleClose={() => setShowFeatureAnnouncementModal(false)}
        />
      </Modal>
      <Modal
        size={MODAL_SIZE.EXTRA_LARGE}
        isOpen={showLocationsAssignedUserFeatureAnnouncementModal}
        handleClose={() => {
          setShowLocationsAssignedUserFeatureAnnouncementModal(false)
        }}
        style={{ overflow: 'hidden' }}
        disableCloseOnBackdropClick={false}
      >
        <LocationsAssignedUserAnnouncementContent
          handleClose={() => {
            setShowLocationsAssignedUserFeatureAnnouncementModal(false)
          }}
          activeFeature={FeatureAnnouncementType.LocationsAssignedUser}
        />
      </Modal>
    </MainLayout>
  )
}
