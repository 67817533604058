import { SVGProps } from 'react'
import { theme } from 'src/libs/theme'

export function ShortLogo(
  props: SVGProps<SVGSVGElement> & { height?: number; width?: number },
) {
  return (
    <svg
      width={props.width || 13}
      height={props.height || 31}
      viewBox="0 0 13 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.82845 0H0V5.50334V12.1996L0.00810979 18.4902C0.129757 25.4012 5.91204 31 13 31V24.3038C9.59389 24.3038 6.82845 21.5838 6.82845 18.2517V12.1996H13V5.50334H6.82845V0Z"
        fill={theme.colors.brand}
      />
    </svg>
  )
}
