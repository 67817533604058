import {
  TabsContainer,
  Tabs,
  Tab,
  LoadingSpinner,
  TabPanel,
} from '@traba/react-components'

import { theme } from '@traba/theme'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { MainLayout } from 'src/components'
import { useShiftRequestParent } from 'src/hooks/useShiftRequestParent'
import { useShiftRequestParentDetailsByDay } from 'src/hooks/useShiftRequestParentDetailsByDay'
import { MissingShiftTile } from './components/MissingShiftTile'
import { ScheduleDailyViewAnnouncements } from './components/ScheduleDailyViewAnnouncements'
import { ScheduleDailyViewDetails } from './components/ScheduleDailyViewDetails'
import { ScheduleDailyViewEstimatedPay } from './components/ScheduleDailyViewEstimatedPay'
import { ScheduleDailyViewHeader } from './components/ScheduleDailyViewHeader'
import { ScheduleDailyViewShareShiftDetails } from './components/ScheduleDailyViewShareShiftDetails'

import { ScheduleDailyViewShiftInvitations } from './components/ScheduleDailyViewShiftInvitations'
import { ScheduleDailyViewWorkers } from './components/ScheduleDailyViewWorkers'

type ScheduleDailyViewParams = {
  shiftRequestParentId: string
  dayOfShift: string
}

export default function ScheduleDailyViewScreen() {
  const { shiftRequestParentId = '', dayOfShift = '' } =
    useParams<ScheduleDailyViewParams>()
  const {
    shiftRequestParentDayDetails,
    isLoading: isLoadingDailyDetails,
    isError: isErrorDailyDetails,
  } = useShiftRequestParentDetailsByDay({
    shiftRequestParentId,
    day: dayOfShift,
  })
  const { shiftRequestParent, isLoading } =
    useShiftRequestParent(shiftRequestParentId)
  const [currentTab, setCurrentTab] = useState(0)
  const TABS = [
    {
      label: 'Workers',
      component: (
        <ScheduleDailyViewWorkers
          shiftRequestParentId={shiftRequestParentId}
          dayOfShift={dayOfShift}
          shiftRequestParentDayDetails={shiftRequestParentDayDetails}
        />
      ),
    },
    {
      label: 'Shift Invitations',
      component: (
        <ScheduleDailyViewShiftInvitations
          shiftRequestParentDayDetails={shiftRequestParentDayDetails}
          shiftRequestParentId={shiftRequestParentId}
        />
      ),
    },
    {
      label: 'Announcements',
      component: (
        <ScheduleDailyViewAnnouncements
          shiftRequestParentDayDetails={shiftRequestParentDayDetails}
        />
      ),
    },
    {
      label: 'Share Shift Details',
      component: (
        <ScheduleDailyViewShareShiftDetails
          shiftRequestParentDayDetails={shiftRequestParentDayDetails}
        />
      ),
    },
    {
      label: 'Estimated Cost',
      component: (
        <ScheduleDailyViewEstimatedPay
          shiftRequestParentDayDetails={shiftRequestParentDayDetails}
        />
      ),
    },
  ]

  const individualMetadata = Object.values(
    shiftRequestParentDayDetails?.shiftRequestToShiftMetadataMap ?? {},
  )
  const shiftIds = individualMetadata.map((metadata) => {
    return metadata.shift.id
  })

  return (
    <MainLayout title="Schedule Daily View">
      {isLoading || isLoadingDailyDetails || isErrorDailyDetails ? (
        isErrorDailyDetails ? (
          <MissingShiftTile />
        ) : (
          <LoadingSpinner />
        )
      ) : (
        <>
          <ScheduleDailyViewHeader
            shiftRequestParent={shiftRequestParent}
            dayOfShift={dayOfShift}
            shiftRequestParentDayDetails={shiftRequestParentDayDetails}
          />
          <ScheduleDailyViewDetails
            shiftRequestParentDayDetails={shiftRequestParentDayDetails}
          />
          {shiftIds.every((shiftId) => !!shiftId) ? (
            <>
              <TabsContainer>
                <Tabs
                  variant="scrollable"
                  value={currentTab}
                  onChange={(_, v) => setCurrentTab(v)}
                >
                  {TABS.map((t, index) => (
                    <Tab key={index} label={t.label} />
                  ))}
                </Tabs>
              </TabsContainer>

              {TABS.map(({ component }, i) => (
                <TabPanel value={currentTab} index={i} key={i}>
                  {component}
                </TabPanel>
              ))}
            </>
          ) : (
            <MissingShiftTile
              style={{ marginTop: theme.space.lg }}
              title={
                'Details will be available 21 days prior to the shift start date.'
              }
              shiftRequestParentId={shiftRequestParentId}
            />
          )}
        </>
      )}
    </MainLayout>
  )
}
