import { SvgIconProps } from '@traba/types'

export default function ({ size, color, strokeWidth, ...props }: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.37231 15.3293H10.7487"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M8.06055 14.9454V11.8732"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M3.18408 5.72863C3.18408 5.58059 3.3041 5.46057 3.45214 5.46057H12.6689C12.8169 5.46057 12.9369 5.58059 12.9369 5.72863V6.49669C12.9369 9.18986 10.7537 11.3731 8.0605 11.3731C5.36733 11.3731 3.18408 9.18986 3.18408 6.49669V5.72863Z"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M7.57978 7.89554C7.50392 8.16106 7.65767 8.4378 7.92319 8.51366C8.1887 8.58953 8.46545 8.43578 8.54131 8.17026L7.57978 7.89554ZM9.32718 3.59968L9.80794 3.73704L9.32718 3.59968ZM10.5074 2.29217L10.6931 2.75641V2.75641L10.5074 2.29217ZM9.68114 2.73274L10.0523 3.06777L10.0523 3.06777L9.68114 2.73274ZM8.54131 8.17026L9.80794 3.73704L8.84642 3.46232L7.57978 7.89554L8.54131 8.17026ZM10.6931 2.75641L13.6227 1.5846L13.2513 0.656123L10.3218 1.82793L10.6931 2.75641ZM9.80794 3.73704C9.95377 3.22666 9.9928 3.13368 10.0523 3.06777L9.30999 2.39771C9.05854 2.67627 8.96525 3.04642 8.84642 3.46232L9.80794 3.73704ZM10.3218 1.82793C9.92015 1.98857 9.56145 2.11914 9.30999 2.39771L10.0523 3.06777C10.1118 3.00186 10.2003 2.95354 10.6931 2.75641L10.3218 1.82793Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
