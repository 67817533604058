import { SvgIconProps } from '@traba/types'

export default function DistributionCenters({
  size,
  color,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 17 16" fill="none" {...rest}>
      <path
        d="M2.50293 4.81937L7.1696 2.30957L14.5029 4.81937M2.50293 4.81937V7.95663L9.83626 10.4664L14.5029 7.95663V4.81937M2.50293 4.81937L9.83626 7.32918L14.5029 4.81937"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M2.50293 7.95654V11.0938L9.83626 13.6036L14.5029 11.0938V7.95654"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
