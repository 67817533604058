import * as Sentry from '@sentry/react'
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  CostCenterResponse,
  CreateCostCenter,
  UpdateSegmentsForWorkerShiftRequest,
  UpdateCostCenter,
  CostCentersForShiftResponse,
  WorkersWithSegmentsResponse,
  WorkersSegmentsMap,
} from '@traba/types'
import { AxiosError } from 'axios'
import { useEffect, useMemo } from 'react'

const COST_CENTER_PAGE_SIZE = 100
const COST_CENTER_QUERY_KEY = 'costCenterQueryKey'
const COST_CENTERS_FOR_SHIFT_QUERY_KEY = 'costCentersForShiftQueryKey'
const WORKERS_WITH_SEGMENTS_QUERY_KEY = 'workersWithSegmentsQueryKey'
const WORKERS_WITH_SEGMENTS_PAGE_SIZE = 50

async function listCostCenters(page: unknown) {
  try {
    const response = await trabaApi.get(`/my-company/cost-center`, {
      params: { page, pageSize: COST_CENTER_PAGE_SIZE },
    })
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

export function useCostCenters() {
  return useInfiniteQuery<CostCenterResponse, Error>({
    queryKey: [COST_CENTER_QUERY_KEY],
    queryFn: ({ pageParam = 1 }) => listCostCenters(pageParam),
    getNextPageParam: (lastPage, _allPages) =>
      lastPage.page < lastPage.pageCount ? lastPage.page + 1 : undefined,
    initialPageParam: 1,
    staleTime: FIVE_MINUTES_IN_MS,
  })
}

async function getCostCentersForShift(shiftId: string, page: unknown) {
  try {
    const response = await trabaApi.get(
      `/my-company/shifts/${shiftId}/cost-centers`,
      { params: { page } },
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

export function useCostCentersForShift(shiftId: string) {
  return useInfiniteQuery<CostCentersForShiftResponse, Error>({
    queryKey: [COST_CENTERS_FOR_SHIFT_QUERY_KEY, shiftId],
    queryFn: ({ pageParam = 1 }) => getCostCentersForShift(shiftId, pageParam),
    getNextPageParam: (lastPage, _allPages) =>
      lastPage.page < lastPage.pageCount ? lastPage.page + 1 : undefined,
    initialPageParam: 1,
    staleTime: FIVE_MINUTES_IN_MS,
  })
}

async function createCostCenter(costCenterData: CreateCostCenter) {
  try {
    const response = await trabaApi.post(
      `/my-company/cost-center`,
      costCenterData,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

async function updateCostCenter(costCenterData: UpdateCostCenter) {
  try {
    const response = await trabaApi.patch(
      `/my-company/cost-center/${costCenterData.id}`,
      costCenterData,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

async function deleteCostCenter(costCenterId: string) {
  try {
    await trabaApi.delete(`/my-company/cost-center/${costCenterId}`)
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

export function useCostCentersMutations() {
  const { showSuccess, showError } = useAlert()
  const queryClient = useQueryClient()

  const createCostCenterMutation = useMutation<
    void,
    AxiosError,
    CreateCostCenter
  >({
    mutationFn: (costCenterData) => createCostCenter(costCenterData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COST_CENTER_QUERY_KEY] })
      showSuccess('Cost center created successfully')
    },
    onError: (error) => {
      showError(error.message, 'Failed to create cost center')
    },
  })

  const updateCostCenterMutation = useMutation<
    void,
    AxiosError,
    UpdateCostCenter
  >({
    mutationFn: (costCenterData) => updateCostCenter(costCenterData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COST_CENTER_QUERY_KEY] })
      showSuccess('Cost center updated successfully')
    },
    onError: (error) => {
      showError(error.message, 'Failed to update cost center')
    },
  })

  const deleteCostCenterMutation = useMutation<void, AxiosError, string>({
    mutationFn: (costCenterId) => deleteCostCenter(costCenterId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COST_CENTER_QUERY_KEY] })
      showSuccess('Cost center deleted successfully')
    },
    onError: (error) => {
      showError(error.message, 'Failed to delete cost center')
    },
  })

  return {
    createCostCenter: createCostCenterMutation.mutateAsync,
    updateCostCenter: updateCostCenterMutation.mutateAsync,
    deleteCostCenter: deleteCostCenterMutation.mutateAsync,
    isCreatingCostCenter: createCostCenterMutation.isPending,
    isUpdatingCostCenter: updateCostCenterMutation.isPending,
    isDeletingCostCenter: deleteCostCenterMutation.isPending,
  }
}

async function getWorkersWithSegments(
  shiftId: string,
  page: unknown,
): Promise<WorkersWithSegmentsResponse> {
  try {
    const response = await trabaApi.get<WorkersWithSegmentsResponse>(
      `/my-company/shifts/${shiftId}/worker-details`,
      { params: { page, pageSize: WORKERS_WITH_SEGMENTS_PAGE_SIZE } },
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

export const useWorkersWithSegments = (shiftId: string) => {
  const { data, fetchNextPage, hasNextPage, isFetching, ...query } =
    useInfiniteQuery<WorkersWithSegmentsResponse, Error>({
      queryKey: [WORKERS_WITH_SEGMENTS_QUERY_KEY, shiftId],
      queryFn: ({ pageParam = 1 }) =>
        getWorkersWithSegments(shiftId, pageParam),
      getNextPageParam: (lastPage, _allPages) =>
        lastPage.page < lastPage.pageCount ? lastPage.page + 1 : undefined,
      initialPageParam: 1,
      staleTime: FIVE_MINUTES_IN_MS,
    })

  const workerSegmentMap = useMemo(
    () =>
      data?.pages
        .flatMap((p) => p.members)
        .reduce((acc, worker) => {
          acc[worker.worker.id] = worker
          return acc
        }, {} as WorkersSegmentsMap) ?? {},
    [data?.pages],
  )

  useEffect(() => {
    if (hasNextPage && !isFetching) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetching])

  return {
    ...query,
    data: workerSegmentMap,
    fetchNextPage,
    hasNextPage,
    isFetching,
  }
}

async function updateSegmentsForWorkerShift(
  shiftId: string,
  workerId: string,
  updatedSegments: UpdateSegmentsForWorkerShiftRequest,
): Promise<void> {
  try {
    await trabaApi.put(
      `/my-company/shifts/${shiftId}/workers/${workerId}/worker-details`,
      updatedSegments,
    )
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

export function useWorkersWithSegmentsMutation(
  shiftId: string,
  workerId: string,
) {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending } = useMutation<
    void,
    Error,
    UpdateSegmentsForWorkerShiftRequest,
    unknown
  >({
    mutationFn: (updatedSegments: UpdateSegmentsForWorkerShiftRequest) =>
      updateSegmentsForWorkerShift(shiftId, workerId, updatedSegments),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [WORKERS_WITH_SEGMENTS_QUERY_KEY, shiftId],
      })
    },
  })

  return { updateWorkerShiftSegments: mutateAsync, isUpdating: isPending }
}
