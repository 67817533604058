import './App.css'

import { SegmentAnalytics } from '@segment/analytics.js-core'
import { useDisableNumberInputScroll } from '@traba/hooks'
import { LoadingSpinner } from '@traba/react-components'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useUserContext } from 'src/context/user/UserContext'
import LoggedIn from 'src/routes/LoggedIn'
import LoggedOut from 'src/routes/LoggedOut'
import Onboarding from 'src/routes/Onboarding'

import { ReviewWorkerReminderPrompt } from './components/ReviewWorker/components/ReviewWorkerReminderPrompt'
import { TooltipModal } from './components/Tooltip/Tooltip'
import useAppAuth from './hooks/authHook'
import useMobile from './hooks/useMobile'
import { EmailVerificationScreen } from './screens/EmailVerificationScreen'
import { ChatWidget } from './screens/Messaging/components/ChatWidget'
import { RNToWebEventName, WebToRNEventName } from './types/events'

declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage: (data: string) => void
    }
    analytics: SegmentAnalytics.AnalyticsJS
    __meticulous?: {
      stopRecording?: () => void
    }
    Meticulous?: {
      isRunningAsTest: boolean
    }
  }
}

function App() {
  const { isReactNativeApp, isMobileViewOrReactNative } = useMobile()

  const userContext = useUserContext()
  const auth = getAuth()
  const { handleLogout } = useAppAuth()
  const [isLoading, setIsLoading] = useState(false)
  const { isLoadingUser, isUserLoggedIn, userProfile } = userContext.state
  const hasUserOnboarded = userProfile?.onboarding?.hasCompleted
  const shouldVerifyEmail =
    !auth.currentUser?.emailVerified && !userProfile?.companyId // If the user already has a company, they don't need to verify their email

  const eventHandler = (message: MessageEvent<any>) => {
    try {
      const data = JSON.parse(message.data)

      if (data.event === RNToWebEventName.LOG_OUT) {
        if (auth.currentUser === null) {
          return
        }
        setIsLoading(true)
        handleLogout()
        setIsLoading(false)
      } else if (
        data.event === RNToWebEventName.LOG_IN &&
        auth.currentUser?.email !== data.email
      ) {
        setIsLoading(true)
        signInWithCustomToken(auth, data.token).then(() => {
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              event: WebToRNEventName.LOGGED_IN,
            }),
          )
          setIsLoading(false)
        })
      }
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }
  //REACT NATIVE APP ONLY: listener to receive msgs from react native
  useEffect(() => {
    if (!isReactNativeApp) {
      return
    }
    const messageListener = window.addEventListener(
      'message',
      eventHandler,
      true,
    )
    // Tell react native that the webview is ready to receive messages
    if (auth.currentUser === null) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          event: WebToRNEventName.WEBVIEW_READY,
        }),
      )
    }
    return messageListener
  }, [])

  useDisableNumberInputScroll()

  if (isLoadingUser || isLoading) {
    return <LoadingSpinner />
  }

  return (
    <div
      className="App"
      style={{ width: isMobileViewOrReactNative ? '100vw' : undefined }}
    >
      {isUserLoggedIn ? (
        shouldVerifyEmail ? (
          <EmailVerificationScreen />
        ) : hasUserOnboarded ? (
          <>
            <LoggedIn />
            <ReviewWorkerReminderPrompt />
            <ChatWidget />
          </>
        ) : (
          <Onboarding />
        )
      ) : (
        <LoggedOut />
      )}

      <TooltipModal />
    </div>
  )
}

export default App
