import { addDays, isBefore, startOfDay } from 'date-fns'

export const JOINED_DATE_KEY = 'userJoinedAtDate'

// we want to direct users to set their notifications when they are first invited
// so we will show a banner
export function useShowNewUserNotificationsBanner() {
  const startOfTodayInLocalTime = startOfDay(new Date())

  const dateStringWhenMemberJoined = localStorage.getItem(JOINED_DATE_KEY)

  const showNotificationsBanner =
    dateStringWhenMemberJoined &&
    // the item is set to '' when the user clicked on the notification banner so we no longer need to show
    dateStringWhenMemberJoined !== '' &&
    // only show the banner until the end of their second day on the platform
    isBefore(
      startOfTodayInLocalTime,
      addDays(new Date(dateStringWhenMemberJoined), 2),
    )

  return showNotificationsBanner
}

// set local storage with the new user's "joined at date" to determine whether to show
// a banner informing users they can set their notifications in their account settings
export function useSetUsersJoinedDateForNotificationsBanner() {
  localStorage.setItem(JOINED_DATE_KEY, new Date().toString())
}

// remove the new user's "joined at date" from local storage to now hide the banner
export function useRemoveUsersJoinedDate() {
  localStorage.setItem(JOINED_DATE_KEY, '')
}
