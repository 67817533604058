import { trabaApi } from '@traba/api-utils'
import {
  FAVORITE_WORKERS,
  HAS_WORKED_AS_ROLE,
  HAS_WORKED_AT_LOCATION,
  HAS_WORKED_WITH_COMPANY,
} from '@traba/consts'
import { Worker } from '@traba/types'
import { useQuery } from 'react-query'
import { useUserContext } from 'src/context/user/UserContext'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { useConnections } from './useConnections'
import { useWorkersByIds } from './useWorker'

async function getVirtualRosters(
  withLocationId?: string,
  withRoleId?: string,
): Promise<Record<string, Worker[]> | undefined> {
  try {
    const searchParams = new URLSearchParams()
    searchParams.append('withLocationId', withLocationId?.toString() ?? '')
    searchParams.append('withRoleId', withRoleId?.toString() ?? '')
    const res = await trabaApi.get(`my-company/virtual-rosters?${searchParams}`)

    return res.data
  } catch (error) {
    console.error('getVirtualRosters() ERROR', error)
  }
}

export function useVirtualRosters(locationId?: string, roleId?: string) {
  const {
    state: { userProfile },
  } = useUserContext()
  const companyId = userProfile?.companyId ?? ''
  const { favoriteWorkers } = useConnections()
  const { getWorkerById } = useWorkersByIds(favoriteWorkers)

  const {
    isLoading,
    isError,
    data: workersWorkedWithCompany,
    error,
    isFetched,
    refetch,
  } = useQuery<Record<string, Worker[]> | undefined, Error>(
    [
      'virtual_rosters',
      {
        locationId,
        roleId,
      },
    ],
    () => getVirtualRosters(locationId, roleId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
      refetchOnWindowFocus: 'always',
    },
  )

  const virtualRosters = [
    {
      id: FAVORITE_WORKERS,
      rosterName: 'Business Favorites',
      companyId,
      workers: favoriteWorkers.map((workerId) => {
        return {
          workerId,
        }
      }),
    },
    {
      id: HAS_WORKED_WITH_COMPANY,
      rosterName: 'Has worked with this company',
      companyId,
      workers:
        workersWorkedWithCompany?.hasWorkedWithCompany?.map((worker) => {
          return {
            workerId: worker.workerId,
          }
        }) ?? [],
    },
    {
      id: HAS_WORKED_AT_LOCATION,
      rosterName: 'Has worked at this location',
      companyId,
      workers:
        workersWorkedWithCompany?.hasWorkedAtLocation?.map((worker) => {
          return {
            workerId: worker.workerId,
          }
        }) ?? [],
    },
    {
      id: HAS_WORKED_AS_ROLE,
      rosterName: 'Has worked this role',
      companyId,
      workers:
        workersWorkedWithCompany?.hasWorkedAsRole?.map((worker) => {
          return {
            workerId: worker.workerId,
          }
        }) ?? [],
    },
  ]

  const virtualRostersWorkersMap: Map<string, Worker> = Object.values(
    workersWorkedWithCompany ?? {},
  ).reduce((acc, workers) => {
    workers.forEach((worker: Worker) => acc.set(worker.uid, worker))
    return acc
  }, new Map())

  favoriteWorkers.forEach((workerId: string) => {
    const worker = getWorkerById(workerId)
    virtualRostersWorkersMap.set(worker.uid, worker)
  })

  return {
    isLoading,
    isError,
    virtualRosters,
    virtualRostersWorkersMap,
    error,
    isFetched,
    refetch,
  }
}
