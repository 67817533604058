import { SvgIconProps } from 'src/types/svg-types'

export default function GraduationCap({ size, color, ...rest }: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clip-path="url(#clip0_636_1801)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.33588 0.374006C7.61929 -0.111718 6.75965 -0.125068 6.0338 0.338257L0.584792 3.81644C-0.193319 4.31312 -0.195368 5.71344 0.581285 6.21373L2.69736 7.57685V11.1521C2.69736 11.2847 2.74187 12.0125 3.36793 12.7037C3.9877 13.388 5.12876 13.9819 7.22551 13.9996C9.32909 14.0174 10.5026 13.4026 11.1557 12.7072C11.4814 12.3603 11.6672 12.0038 11.7719 11.722C11.824 11.5818 11.8555 11.4614 11.8744 11.3715C11.8838 11.3265 11.8902 11.2891 11.8943 11.2605C11.8964 11.2462 11.898 11.2342 11.8991 11.2244L11.9005 11.2116L11.901 11.2066L11.9012 11.2044C11.9013 11.2039 11.9014 11.2025 11.5482 11.1521L11.9014 11.2025L11.9034 11.1797L11.9366 7.22697L13.289 6.30185V10.414C13.289 10.6616 13.4482 10.8623 13.6445 10.8623C13.8408 10.8623 14 10.6616 14 10.414V5.03508C14 5.02041 13.9994 5.0059 13.9983 4.99159C13.9919 4.53442 13.8046 4.0809 13.437 3.83171L8.33588 0.374006ZM6.3549 1.1381C6.87337 0.807158 7.4874 0.816694 7.99925 1.16364L13.1004 4.62135C13.3494 4.79014 13.3501 5.24018 13.1016 5.41017L7.34686 9.34668C7.24387 9.41713 7.11997 9.41906 7.01563 9.35185L0.90473 5.41538C0.645845 5.24862 0.646529 4.78185 0.905899 4.61629L6.3549 1.1381ZM11.2215 7.71615L7.68588 10.1347C7.37691 10.346 7.0052 10.3518 6.69218 10.1502L3.40833 8.03483V11.147L3.4085 11.1488C3.41079 11.1796 3.44161 11.5935 3.83628 12.0292C4.27853 12.5175 5.22499 13.0862 7.23027 13.1032C9.22872 13.12 10.2153 12.5351 10.6969 12.0223C10.9385 11.765 11.0636 11.5152 11.128 11.3417C11.1605 11.2543 11.1781 11.1848 11.1872 11.1417C11.1898 11.1293 11.1917 11.1191 11.193 11.1114L11.2215 7.71615Z"
          fill={color}
          stroke={color}
          stroke-width="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_636_1801">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
