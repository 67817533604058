import { SvgIconProps } from '@traba/types'

export default function Flash({
  size,
  color = '#7A8A99',
  strokeWidth = 1.5,
  ...props
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 23" fill="none" {...props}>
      <path
        d="M10.2401 1.7381L0.906738 11.5604H14.9067L5.5734 21.3826"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
