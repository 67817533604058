import { SvgIconProps } from 'src/types/svg-types'

export default function UserPlus({
  size,
  color,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M21 12H17M19 14L19 10"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M3 19.1115C3 16.6984 4.69732 14.643 7.00404 14.2627L7.21182 14.2284C9.05892 13.9239 10.9411 13.9239 12.7882 14.2284L12.996 14.2627C15.3027 14.643 17 16.6984 17 19.1115C17 20.1545 16.1815 21 15.1719 21H4.82813C3.81848 21 3 20.1545 3 19.1115Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M14.0834 6.9375C14.0834 9.11212 12.2552 10.875 10 10.875C7.74486 10.875 5.91669 9.11212 5.91669 6.9375C5.91669 4.76288 7.74486 3 10 3C12.2552 3 14.0834 4.76288 14.0834 6.9375Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
