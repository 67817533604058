import { Page } from '@playwright/test'
import {
  BGCRequirement,
  BreakType,
  Company,
  CompanyCategory,
  InvitedWorkers,
  Shift,
  ShiftPayType,
  ShiftStatus,
} from '@traba/types'
import React from 'react'
export const STORY_BOOK_PREFIX = '/?path=/story/components'
export const PERMANENT_PICTURE_URL =
  'https://avatars.githubusercontent.com/u/00000'

type PropDefinition<T> = {
  [K in keyof T]: {
    name: K
    values: T[K][]
  }
}[keyof T]

// Recursively generate all possible combinations of props for a component
export function getAllPropCombinations<T>(
  Component: React.ComponentType<T>,
  propDefinitions: PropDefinition<T>[],
): Partial<T>[] {
  // Inner recursive function
  function generateCombinations(
    index: number,
    currentProps: Partial<T>,
    propDefs: PropDefinition<T>[],
  ): Partial<T>[] {
    if (index === propDefs.length) {
      return [currentProps]
    }

    const propDef = propDefs[index]
    const { name, values } = propDef
    const combinations: Partial<T>[] = []

    for (const value of values) {
      combinations.push(
        ...generateCombinations(
          index + 1,
          {
            ...currentProps,
            [name]: value,
          },
          propDefs,
        ),
      )
    }

    return combinations
  }
  // Call the inner function with initial values
  return generateCombinations(0, {}, propDefinitions)
}

export function getAllComponentCombinations<T>(
  Component: React.ComponentType<T>,
  propDefinitions: PropDefinition<T>[],
): React.ReactElement<T>[] {
  const propCombinations = getAllPropCombinations(Component, propDefinitions)

  return propCombinations.map((props, index) => (
    <Component key={index} {...(props as T)} />
  ))
}

// Generates component combinations from a list of props
export function getAllComponentCombinationsFromList<T>(
  Component: React.ComponentType<T>,
  propsList: Partial<T>[],
): React.ReactElement<T>[] {
  return propsList.map((props, index) => (
    <Component key={index} {...(props as T)} />
  ))
}

export async function setupStorybookPage(page: Page, id: string) {
  const params = new URLSearchParams({
    id: `components-${id}`,
    viewMode: 'story',
    nav: '0',
  })
  await page.goto(`/iframe.html?${params.toString()}`, {
    waitUntil: 'networkidle',
  })
  await page.waitForLoadState('domcontentloaded')
  await page.waitForSelector('#storybook-root')
}

export const shiftMock: Shift = {
  id: '',
  shiftId: '',
  shiftRequestId: '',
  companyId: '',
  roleId: '',
  locationId: '',
  geohash: '',
  regionId: '',
  supervisorId: '',
  allShiftsRequired: false,
  favoriteWorkersFirst: false,
  employerName: '',
  startTime: new Date('2023-07-06T13:00:00.000Z'),
  endTime: new Date('2023-07-06T18:00:00.000Z'),
  originalStartTime: new Date('2023-07-06T13:00:00.000Z'),
  scheduledBreaks: [],
  slotsFilled: 0,
  slotsRequested: 0,
  minSlotsRequested: 0,
  invitedWorkers: InvitedWorkers.ALL,
  trustAndSafetyFeeHourly: {
    amount: 0,
    currency: 'USD',
  },
  confirmationCode: 0,
  clockOutCode: 0,
  workerMedia: [],
  createdAt: new Date(),
  shiftRole: 'Shift Role',
  roleDescription: '',
  attireDescription: '',
  shortLocation: '',
  tags: [],
  hasPaidBreaks: false,
  breakType: BreakType.AUTO_UNPAID,
  timesheetApprovalStatus: {
    isApproved: false,
    approvedAt: new Date(),
    isPendingEdit: false,
    timesheetId: '',
  },
  calculatedMarkup: 20,
  status: ShiftStatus.ACTIVE,
  backfillSettings: {
    allowsBackfill: true,
    lateTolerance: 0,
  },
  extraBGCRequirement: BGCRequirement.STANDARD,
  payRate: 16,
  payType: ShiftPayType.HOURLY,
  requireW9Authorization: false,
  pluckFromRequiredMultiShift: false,
  companyLogo: PERMANENT_PICTURE_URL,
  timezone: 'America/New_York',
}

export const companyMock: Company = {
  companyId: '',
  category: CompanyCategory.EVENTS,
  referralCode: '',
  employerName: '',
  companyDescription: '',
  companyPhoneNumber: '',
  rating: 0,
  baseMarkup: 0,
  calculatedMarkup: 0,
  workerMedia: [],
  bannerUrl: PERMANENT_PICTURE_URL,
  companyLogo: PERMANENT_PICTURE_URL,
  createdAt: new Date(),
  address: {
    city: '',
    street1: '',
    street2: '',
    postalCode: '',
    state: '',
  },
  isApproved: false,
  isHandpicked: false,
  extraBGCRequirement: BGCRequirement.STANDARD,
  showAddressToNonWorkersForCompany: false,
  showNameToNonWorkersForCompany: false,
  disableLeftFencePromptOverride: false,
  requireW9Authorization: false,
}
