import styled from 'styled-components'

import { Button } from '../Button/index'

export const AddContentContainer = styled.div`
  border-radius: 10px;
  border: 1px dashed ${({ theme }) => theme.colors.Grey20};
  height: 198px;
  width: 235px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: ${({ theme }) => theme.space.xs}px;

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.Grey30};
    cursor: pointer;
  }
`

export const ImagePreviewWrapper = styled.div`
  height: 198px;
  width: 235px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.space.xxs}px;
  border: 1px solid ${({ theme }) => theme.colors.Grey20};
  :not(:last-child) {
    margin-right: ${({ theme }) => theme.space.xs}px;
  }
`

export const ImagePreview = styled.img`
  object-fit: contain;
  width: 235px;
  height: 198px;
`

export const FileUploadInput = styled.input`
  display: none;
`

export const DeleteButton = styled(Button)`
  width: 40px;
  height: 40px;
  position: absolute;
  top: ${({ theme }) => theme.space.xxs}px;
  right: ${({ theme }) => theme.space.xxs}px;
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.space.xs}px;
`
