import { ClickAwayListener, Tooltip } from '@mui/material'
import { Button, Text, SvgIcon } from '@traba/react-components'
import { useState } from 'react'
import { StrikeThrough } from 'src/components/base/StrikeThrough'
import { TimeSheetsWorkerCharge } from 'src/hooks/useTimesheet'
import { theme } from 'src/libs/theme'
import { getMoneyString } from 'src/utils/moneyUtils'
import { Badge, ButtonVariant, Col, Icon, Row } from '../../base'

interface Props {
  workerCharge: TimeSheetsWorkerCharge | undefined
  isLoading: boolean
  showEstimatedCharge?: boolean
  showBizPendingAdjustmentTooltip?: boolean
  hideBizPendingAdjustmentTooltip?: boolean
  isRecalculating?: boolean
  isRecentlyRecalculated?: boolean
  showFourHourMinBadge?: boolean
  onRefresh?: () => void
}

export const TimeSheetsWorkerEarning = ({
  workerCharge,
  isLoading,
  showEstimatedCharge,
  showBizPendingAdjustmentTooltip,
  hideBizPendingAdjustmentTooltip,
  isRecalculating,
  isRecentlyRecalculated,
  showFourHourMinBadge,
  onRefresh,
}: Props) => {
  const { charge, updatedCharge } = workerCharge ?? {}
  const originalMoneyString = getMoneyString(charge)

  const showPendingAdjustmentTooltip =
    showBizPendingAdjustmentTooltip && !hideBizPendingAdjustmentTooltip

  if (isLoading) {
    return <div>{'...'}</div> // ellipses instead of a loading spinner to reduce jarring-ness
  }
  const money =
    !showEstimatedCharge ||
    !updatedCharge ||
    updatedCharge.amount === charge?.amount ? (
      originalMoneyString
    ) : (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <StrikeThrough
          strikeThrough
          color={theme.colors.Grey60}
          style={{ color: theme.colors.Grey60 }}
        >
          {originalMoneyString}
        </StrikeThrough>

        <Row gap={theme.space.xxs}>
          <div>{getMoneyString(updatedCharge)}</div>
          {showPendingAdjustmentTooltip && (
            <Tooltip title="This adjustment was more than 15% and is pending approval from Traba">
              <div>
                <Icon name="info" />
              </div>
            </Tooltip>
          )}
        </Row>
      </div>
    )
  return (
    <Row alignCenter gap={theme.space.xxs}>
      <Col
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: theme.space.xxs,
        }}
      >
        {money}
        {showFourHourMinBadge && (
          <Badge
            iconName="info"
            variant="info"
            title="4hr min"
            tooltipText="This charge reflects the four hour minimum paid to workers."
          />
        )}
      </Col>
      <div style={{ cursor: 'pointer' }}>
        <TimeSheetChargeRecalculationIcon
          isRecalculating={!!isRecalculating}
          isRecentlyRecalculated={!!isRecentlyRecalculated}
          onRefresh={onRefresh}
        />
      </div>
    </Row>
  )
}

const TimeSheetChargeRecalculationIcon = ({
  isRecalculating,
  isRecentlyRecalculated,
  onRefresh,
}: {
  isRecalculating: boolean
  isRecentlyRecalculated: boolean
  onRefresh?: () => void
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  if (!isRecalculating && !isRecentlyRecalculated) {
    return null
  }
  return (
    <ClickAwayListener onClickAway={() => setIsTooltipOpen(false)}>
      <div>
        <Tooltip
          disableFocusListener
          disableHoverListener
          disableTouchListener
          open={isTooltipOpen}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => setIsTooltipOpen(false)}
          title={
            <div style={{ width: '240px' }}>
              <Text
                variant="h7"
                color={
                  isRecalculating ? theme.colors.Yellow70 : theme.colors.Green80
                }
              >
                {isRecalculating
                  ? 'This charge is being recalculated. Please refresh to ensure it is up-to-date.'
                  : 'This charge was recently recalculated and is now up-to-date'}
              </Text>
              {isRecalculating && (
                <Button
                  rightIcon={<SvgIcon name="refresh" size={16} />}
                  iconPadding={`${theme.space.xxs}px`}
                  slim
                  variant={ButtonVariant.TEXT}
                  onClick={() => onRefresh && onRefresh()}
                >
                  {'Refresh '}
                </Button>
              )}
            </div>
          }
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: isRecalculating
                  ? theme.colors.Yellow10
                  : theme.colors.Green10,
                border: `1px solid ${
                  isRecalculating ? theme.colors.Yellow70 : theme.colors.Green80
                }`,
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              },
            },
          }}
        >
          <div
            onClick={() => {
              setIsTooltipOpen(true)
            }}
          >
            <Icon
              name={isRecalculating ? 'warning' : 'greenCheck'}
              height={16}
              width={16}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
}
