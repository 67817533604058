import { endOfDay, startOfDay } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import { useMembers } from 'src/hooks/useMembers'
import { useRegions } from 'src/hooks/useRegions'
import { useShifts } from 'src/hooks/useShifts'

import { MEDIA } from 'src/libs/theme'
import useCalendarFilters from '../../hooks/useShiftFilters.hooks'

export const useTodaysWorkers = () => {
  const [filters, dispatch] = useCalendarFilters()

  const startOfTodayInEastern = startOfDay(
    utcToZonedTime(new Date(), 'America/New_York'),
  )
  const endOfTodayInEastern = endOfDay(
    utcToZonedTime(new Date(), 'America/New_York'),
  )

  const { data: shifts = [], isLoading: isLoadingShifts } = useShifts({
    sortBy: 'startTime',
    sortDir: 'asc',
    ...filters,
    startBefore: endOfTodayInEastern,
    startAfter: startOfTodayInEastern,
    ...filters,
  })

  const { regions = [], isFetched: isRegionsFetched } = useRegions()

  const { members = [], isFetched: isMembersFetched } = useMembers()

  const navigate = useNavigate()

  const showDesktopCard = useMediaQuery({
    query: `(min-width: ${MEDIA.LARGE}px)`,
  })

  return {
    shifts,
    isShiftsFetched: !isLoadingShifts,
    regions,
    isRegionsFetched,
    members,
    isMembersFetched,
    filters,
    dispatch,
    showDesktopCard,
    navigate,
  }
}
