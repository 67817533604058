import { CardTile } from '@traba/react-components'
import { Company, Shift, ShiftStatus } from '@traba/types'
import { getShiftTimeString } from '@traba/utils'
import { CompanyLogo } from 'src/components'
import { Text } from 'src/components/base/Text'

import Badge from '../Badge'
import { Checkbox } from '../CheckboxThemed'

export type ShiftSelectorTileProps = {
  shift: Shift
  showStatus: boolean
  selected?: boolean
  company: Company
}

export const ShiftSelectorTile = (props: ShiftSelectorTileProps) => {
  const { shift } = props
  return (
    <CardTile
      style={{
        width: '100%',
        justifyContent: 'left',
        marginBottom: '8px',
        height: 'max-content',
      }}
    >
      <CompanyLogo
        style={{
          height: '56px',
          width: '56px',
          margin: '32px 24px 32px 32px',
        }}
        companyLogoUrl={props.company.companyLogo}
        companyName={props.company.employerName}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Text variant="h5">{shift.shiftRole}</Text>
        <Text variant="body2">
          {(shift.businessStartTime ?? shift.startTime).toLocaleDateString(
            'en-us',
            {
              timeZone: shift.timezone,
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            },
          )}
          {' - '}
          {getShiftTimeString(
            shift.businessStartTime ?? shift.startTime,
            shift.endTime,
            shift.timezone,
          )}
        </Text>
      </div>
      {props.showStatus ? (
        <div style={{ marginLeft: 'auto', marginRight: '32px' }}>
          {shift.status === ShiftStatus.ACTIVE ? (
            <Checkbox selected={props.selected || false} />
          ) : (
            <Badge variant="danger" title="canceled" />
          )}
        </div>
      ) : null}
    </CardTile>
  )
}
