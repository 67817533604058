import { Training } from './trainings'

export enum TrainingStatus {
  COMPLETED = 'COMPLETED',
  STARTED = 'STARTED',
}

export type WorkerTrainingStatus = {
  createdAt: Date
  updatedAt?: Date
  workerId: string
  trainingId: string
  status: TrainingStatus
  training: Training
}
