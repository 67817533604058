import { trabaApi } from '@traba/api-utils'
import { useMutation } from 'react-query'

interface RequestAccountDeleteRequest {
  reasons: string[]
  extraDetails: string
}

export type RequestAccountDeleteResponse = {
  userDeleted: boolean
}

export function useRequestDeleteAccount() {
  const requestAccountDeletionMutation = useMutation<
    RequestAccountDeleteResponse,
    Error,
    RequestAccountDeleteRequest
  >(async (req: RequestAccountDeleteRequest) => {
    const res = await trabaApi.post('/my-company/request-delete-account', req)
    return res.data
  })

  return {
    requestAccountDeletion: requestAccountDeletionMutation.mutate,
  }
}
