import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { Anchor } from '@traba/react-components'
import { useForm } from '@traba/utils'
import { useFormik } from 'formik'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthScreenWrapper } from 'src/components/auth'
import { FillRateRightCol } from 'src/components/auth/FillRateRightCol'
import {
  Alert,
  Button,
  Input,
  InputPhone,
  Row,
  Text,
} from 'src/components/base'
import PlacesAutocomplete from 'src/components/PlacesAutocomplete/PlacesAutocomplete'
import { useUserContext } from 'src/context/user/UserContext'
import { useQueryParams } from 'src/helpers'
import useAppAuth from 'src/hooks/authHook'
import { useEmailVerification } from 'src/hooks/useEmailVerification'
import useMobile from 'src/hooks/useMobile'
import {
  PREFILLED_INFO_BUSINESS_NAME,
  PREFILLED_INFO_CITY,
  REQUIRED_FIELD_MESSAGE,
} from 'src/libs/constants'
import { Address } from 'src/types'
import { email, name, password, phoneNumber } from 'src/utils/formUtils'
import * as yup from 'yup'

async function postUserWithCachedIfPossible(
  values: {
    email: string
    password: string
    firstName?: string
    lastName?: string
    phoneNumber?: string
    agreedToSmsConsent?: boolean
  },
  companyId?: string,
) {
  const {
    firstName,
    lastName,
    phoneNumber,
    agreedToSmsConsent,
    email,
    password,
  } = values

  const req: any = {
    email,
    password,
    ...(firstName && { firstName }),
    ...(lastName && { lastName }),
    ...(phoneNumber && { phoneNumber }),
    ...(companyId && { companyId }),
    ...(agreedToSmsConsent && { smsConsent: { agreedToSmsConsent: true } }),
  }

  try {
    const { data } = await trabaApi.post('/users', req)
    return data
  } catch (e: any) {
    // Don't want to retry if user invite already exists
    if (
      e.message === 'user-creation/existing-invitation' ||
      e.message === 'user-creation/must-login-with-okta'
    ) {
      throw e
    }
    // catch the first attempt and swallow the error in case the failure is because of the cached items
    // make another attempt with just the email and password because those are the only two that can be
    // corrected on this page. The failure for the other items will be handled by routing to the basic
    // details page later
    await trabaApi.post('/users', {
      email: values.email,
      password: values.password,
    })
  }
}

export default function LeadDetailsScreen(): JSX.Element {
  const { isReactNativeApp } = useMobile()

  const navigate = useNavigate()
  const { handleOnSubmitError } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const searchParams = new URLSearchParams(document.location.search)
  const query = useQueryParams()
  const userContext = useUserContext()
  const { handleLogin } = useAppAuth()
  const referralCode =
    (query.get('referralCode') as string) || Cookies.get('referralCode')
  const { companyId } = userContext.state.userProfile ?? {}
  const { onSendVerificationEmail } = useEmailVerification()
  const { handleError } = useAlert()

  useEffect(() => {
    if (referralCode) {
      Cookies.set('referralCode', referralCode.toUpperCase(), { expires: 1 })
    }
  })

  const formik = useFormik({
    initialValues: {
      phoneNumber: searchParams.get('phoneNumber') || '',
      firstName: searchParams.get('firstName') || '',
      lastName: searchParams.get('lastName') || '',
      email: searchParams.get('email') || '',
      businessName: searchParams.get('businessName') || '',
      city: searchParams.get('city') || '',
      password: '',
    },
    validationSchema: yup.object({
      phoneNumber: phoneNumber(),
      firstName: name(),
      lastName: name(),
      email: email(),
      businessName: name(),
      city: yup.string().required(REQUIRED_FIELD_MESSAGE),
      password: password(),
    }),
    onSubmit: async (values) => {
      try {
        formik.setStatus({})
        window.analytics.track('create_business_account')
        window.analytics.track('continue_click', {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
          businessName: values.businessName,
        })
        const newLeadDetails = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
          businessName: values.businessName,
          city: values.city,
        }
        setIsLoading(true)
        await trabaApi.post('companies/new-account-started', newLeadDetails)
        localStorage.setItem(PREFILLED_INFO_BUSINESS_NAME, values.businessName)
        localStorage.setItem(PREFILLED_INFO_CITY, values.city)
        formik.setStatus({})
        const data = await postUserWithCachedIfPossible(values, companyId)
        if (data) {
          userContext.dispatch({ type: 'EDIT_USER', value: data })
        }
        await handleLogin(values.email, values.password)
        onSendVerificationEmail()
        setIsLoading(false)
      } catch (e: any) {
        if (e.message === 'user-creation/invalid-user') {
          handleError(
            e,
            'LeadDetailsScreen -> onSubmit()',
            'We are having trouble creating your account. Please contact support.',
            'There was an error creating your account',
            10000,
          )
        } else if (e.message === 'user-creation/existing-invitation') {
          handleError(
            e,
            'LeadDetailsScreen -> onSubmit()',
            'This email address is already associated with an invitation. Please check your email.',
            'There was an error creating your account',
            10000,
          )
        } else if (e.message === 'user-creation/must-login-with-okta') {
          handleError(
            e,
            'LeadDetailsScreen -> onSubmit()',
            'Your company requires Okta SSO to sign in. Please try again with Okta credentials',
            'There was an error creating your account',
            10000,
          )
        } else if (e.message === 'user-creation/requires-invite') {
          handleError(
            e,
            'LeadDetailsScreen -> onSubmit()',
            "Your company's email domain is already registered. Please check your inbox for an invite or contact your company administrator to invite you.",
            'There was an error creating your account',
            10000,
          )
          navigate('/require-invitation')
        } else {
          handleOnSubmitError(e, formik)
        }
        setIsLoading(false)
      }
    },
  })

  function handleChangePlacesAutocomplete(val: Address) {
    formik.setFieldValue('address', val.city)
  }

  const { errors, touched } = formik

  const pageTitle = 'Basic Details'
  const leftCol = (
    <>
      <div>
        <Text variant="h6">Step 1/3</Text>
        <Text variant="h2">{pageTitle}</Text>

        <Input
          full
          label="First Name"
          {...formik.getFieldProps('firstName')}
          inputStatus={touched.firstName && errors.firstName ? 3 : 1}
          errorMessage={errors.firstName}
        />
        <Input
          full
          label="Last Name"
          {...formik.getFieldProps('lastName')}
          inputStatus={touched.lastName && errors.lastName ? 3 : 1}
          errorMessage={errors.lastName}
        />
        <Input
          full
          label="Business Name"
          {...formik.getFieldProps('businessName')}
          inputStatus={touched.businessName && errors.businessName ? 3 : 1}
          errorMessage={errors.businessName}
        />
        <Input
          full
          label="Email address"
          {...formik.getFieldProps('email')}
          value={formik.values.email}
          onChange={formik.handleChange}
          inputStatus={touched.email && errors.email ? 3 : 1}
          errorMessage={errors.email}
        />
        <Row mt={24}>
          <PlacesAutocomplete
            onSelect={handleChangePlacesAutocomplete}
            onChange={(val) => formik.setFieldValue('city', val)}
            value={formik.values?.city}
            label="City"
            errorMessage={touched.city && errors.city ? errors.city : null}
            placesApiType="(cities)"
          />
        </Row>
        <InputPhone
          value={formik.values.phoneNumber}
          onChange={(val) =>
            formik.handleChange({
              target: { value: val, name: 'phoneNumber' },
            })
          }
          error={touched.phoneNumber ? errors.phoneNumber : null}
        />
        <Input
          full
          type="password"
          label="Password"
          {...formik.getFieldProps('password')}
          inputStatus={touched.password && errors.password ? 3 : 1}
          errorMessage={formik.errors.password}
        />
      </div>
      <div>
        {formik.status && formik.status.error && (
          <Alert mt={24}>{formik.status.message}</Alert>
        )}
        <Row my={32}>
          <Button
            type="submit"
            style={{ width: '100%' }}
            disabled={isLoading}
            loading={isLoading}
          >
            Continue
          </Button>
        </Row>
        {!isReactNativeApp && (
          <>
            <Text variant="body2" center>
              Looking for work?{' '}
              <Anchor href="https://www.traba.work/workers">
                Download our worker app
              </Anchor>
            </Text>
            <Text variant="body2" center>
              Already have a business account?{' '}
              <Text
                variant="link"
                onClick={() => {
                  navigate('/login')
                }}
              >
                Log in
              </Text>
            </Text>
          </>
        )}

        <Text variant="body2" center>
          <Anchor href="https://traba.work/business-contact">
            Contact Sales Directly
          </Anchor>
        </Text>
      </div>
    </>
  )
  return (
    <form onSubmit={formik.handleSubmit}>
      <AuthScreenWrapper
        helmetTitle={pageTitle}
        leftCol={leftCol}
        rightCol={<FillRateRightCol />}
      />
    </form>
  )
}
