import styled from 'styled-components'

import { Button, Input, Text } from '../base'

export const ReviewInput = styled(Input)`
  font-size: 12px;
  ::placeholder {
    font-size: 12px;
  }
  resize: vertical;
`

export const ReviewLinkButton = styled(Button)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.Violet};
  font-size: 12px;

  :hover {
    text-decoration: underline;
  }
`

export const ReviewThumb = styled.button<{
  positive: boolean
  selected: boolean | undefined
}>`
  cursor: pointer;
  background: ${({ theme }) => theme.colors.White};
  padding: ${({ theme }) => theme.space.xxs}px;
  margin: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  opacity: ${({ selected }) => (selected === false ? 0.5 : 1)};
  box-shadow: 0px 1px 2px rgba(196, 204, 212, 0.4);
  background-color: ${({ selected, positive, theme }) =>
    selected && positive
      ? theme.colors.Green80
      : selected && !positive
        ? theme.colors.Red70
        : '#ffffff'};
  border-color: ${({ selected, positive, theme }) =>
    selected && positive
      ? theme.colors.Green80
      : selected && !positive
        ? theme.colors.Red70
        : theme.border.color};
  color: ${({ selected, positive, theme }) =>
    selected === undefined && positive
      ? theme.colors.Green40
      : selected === undefined && !positive
        ? theme.colors.Red30
        : selected
          ? theme.colors.White
          : theme.colors.Grey50};
  :hover {
    box-shadow: 0px 2px 5px rgba(196, 204, 212, 0.55);
  }
`

export const StyledInputTitleLink = styled(Text).attrs({
  variant: 'link',
})`
  font-size: 12px;
  margin-top: ${({ theme }) => theme.space.sm}px;
`
