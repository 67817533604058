import { SvgIconProps } from '@traba/types'

export default function FoodProduction({
  size,
  color,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.7909 7.68108C15.0444 6.42753 15.0525 4.40319 13.8089 3.15958C12.5653 1.91598 10.5409 1.92404 9.28737 3.17759L2.33038 10.1346C1.07682 11.3881 1.06876 13.4125 2.31236 14.6561C3.55597 15.8997 5.58031 15.8916 6.83387 14.6381L13.7909 7.68108Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M5.03247 10.6199L5.74577 12.8617"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M7.93652 7.8175L8.59888 10.0594"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M10.6372 4.8623L11.3505 7.10415"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
