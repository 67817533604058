import { DialogActions } from '@mui/material'
import styled from 'styled-components'
import { SvgIcon } from '../SvgIcon'

export const ButtonIcon = styled(SvgIcon)`
  height: ${({ theme }) => theme.space.xs}px;
  width: ${({ theme }) => theme.space.xs}px;
  cursor: pointer;
`

export const StyledDialogActions = styled(DialogActions)`
  padding: ${({ theme }) => theme.space.sm}px;
`
