import { LoadingSpinner } from '@traba/react-components'
import React from 'react'

import { useCompanyProfile } from './CompanyProfile.hooks'
import { CompanyProfileUI } from './CompanyProfile.ui'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook

export const CompanyProfile = () => {
  const logic = useCompanyProfile()
  if (logic.isLoading) {
    return <LoadingSpinner />
  }
  return <CompanyProfileUI {...logic} />
}
