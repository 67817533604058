import { Skeleton } from '@mui/material'
import { theme } from 'src/libs/theme'
import { Col, Row } from '../base'

function MobileWorkersListSkeleton() {
  return (
    <Col width="100%">
      <Row alignCenter justifyBetween>
        <Skeleton animation="wave" variant="text" width="76px" height="40px" />
        <Skeleton animation="wave" width="50px" height="50px" />
      </Row>
      <Row alignCenter justifyBetween fullWidth>
        <Row alignCenter>
          <Skeleton
            animation="wave"
            width="26px"
            height="40px"
            style={{ marginRight: theme.space.xs }}
          />
          <Skeleton animation="wave" width="40px" height="60px" />
          <Col ml={theme.space.xxs} my={0}>
            <Skeleton animation="wave" width="80px" height="30px" />
            <Skeleton animation="wave" width="70px" height="30px" />
          </Col>
        </Row>
        <Skeleton animation="wave" width="24px" height="30px" />
      </Row>
    </Col>
  )
}

export default MobileWorkersListSkeleton
