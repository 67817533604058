import { trabaApi } from '@traba/api-utils'
import { Shift } from '@traba/types'
import { useQuery } from 'react-query'

export const WORKER_ALREADY_INVITED_TO_SHIFT_HTTP_CODE = 480

const getShiftForInvitation = async (invitationId?: string) => {
  if (!invitationId) {
    return undefined
  }
  try {
    const response = await trabaApi.get(
      `/my-company/shifts/shift-for-invitation/${invitationId}`,
    )
    return response.data
  } catch (error) {
    console.log(`Error retrieving shift for invitation ${invitationId}`, error)
  }
}

export function useShiftForInvitation(invitationId?: string) {
  const {
    isLoading,
    isError,
    data: shift,
    error,
    refetch,
  } = useQuery<Shift | undefined, Error>(
    ['shift-for-invitation', invitationId],
    () => getShiftForInvitation(invitationId),
  )

  return {
    isLoading,
    isError,
    error,
    shift,
    refetch,
  }
}
