import 'react-phone-input-2/lib/material.css'

import { useState } from 'react'
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2'
import * as StyledInput from 'src/components/base/Input/Input.styles'

import * as S from './InputPhone.styles'

type Props = {
  id?: string | number
  name?: string
  error?: string | null
  title?: string
}

export default function InputPhone({
  error,
  onChange,
  title,
  ...props
}: Props & PhoneInputProps) {
  const [focused, setFocused] = useState(false)
  return (
    <>
      <S.PhoneInputWrapper error={!!error} focused={focused}>
        <PhoneInput
          enableSearch
          country={'us'}
          specialLabel={title ?? 'Phone number'}
          searchPlaceholder={'Search...'}
          autocompleteSearch
          onlyCountries={['us']}
          onChange={onChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          {...props}
        />
      </S.PhoneInputWrapper>
      {!!error && <StyledInput.StyledInputError message={error} />}
    </>
  )
}
