import { JobStatus, Worker } from '@traba/types'

const JobStatusOrder = [
  JobStatus.ToDo,
  JobStatus.InProgress,
  JobStatus.OnBreak,
  JobStatus.Complete,
  JobStatus.Abandoned,
  JobStatus.Rejected,
  JobStatus.Appeased,
  JobStatus.NoShow,
  JobStatus.Canceled,
]

export const sortByJobStatus = (a: JobStatus, b: JobStatus) => {
  return JobStatusOrder.indexOf(a) - JobStatusOrder.indexOf(b)
}

export const sortAlphabetically = (a: string, b: string) => {
  return a.localeCompare(b)
}

export function sortWorkersByFirstNameThenLastName(
  a: Worker,
  b: Worker,
): number {
  if (a.firstName === b.firstName) {
    if (a.lastName === b.lastName) {
      return 0
    }
    return a.lastName > b.lastName ? 1 : -1
  }
  return a.firstName > b.firstName ? 1 : -1
}
