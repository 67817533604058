import { Button, Divider } from 'src/components/base'
import styled from 'styled-components'

export const EditButton = styled(Button)`
  padding: 0px 0px 0px 8px;
`
export const UpcomingShiftDetailsDivider = styled(Divider)`
  padding: ${({ theme }) => theme.space.sm}px;
  padding: 20px;
`

export const ShiftDetailsSectionContainer = styled.div`
  display: grid;
  gap: 1em;
  margin-bottom: ${({ theme }) => theme.space.xl}px;

  @media (min-width: ${({ theme }) => theme.media.minExtraLarge}) {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
`
