import { SvgIconProps } from '@traba/types'

export default function Block({
  size,
  color = '#7A8A99',
  strokeWidth = 1.5,
  ...props
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}>
      <circle cx="8" cy="8" r="6" stroke={color} strokeWidth={strokeWidth} />
      <path d="M4 12L12 4" stroke={color} strokeWidth={strokeWidth} />
    </svg>
  )
}
