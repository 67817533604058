import * as Sentry from '@sentry/react'
import { MAX_ANNOUNCEMENT_MESSAGE_LENGTH } from '@traba/consts'
import { useAlert } from '@traba/context'
import { FileType, useFileUploader } from '@traba/hooks'
import { MediaUploader } from '@traba/react-components'
import { ChatEndedStatus, ChatSummary } from '@traba/types'
import { InputStatus } from '@traba/types'
import { useEffect, useState } from 'react'
import {
  Button,
  ButtonVariant,
  Input,
  Row,
  SvgIcon,
  Text,
} from 'src/components/base'
import { postChatMessage, useMessages } from 'src/hooks/useChatMessages'
import { useUser } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'

interface Props {
  companyEndedChatStatus?: ChatEndedStatus
  isFromChatWidget: boolean
  buttonRef?: React.RefObject<HTMLButtonElement>
  summaryId: string
  setSelectedChat: React.Dispatch<React.SetStateAction<ChatSummary | undefined>>
  setInviteWorkerOpen: (open: boolean) => void
}
export const MessageInputSection = ({
  setSelectedChat,
  isFromChatWidget,
  buttonRef,
  summaryId,
  companyEndedChatStatus,
  setInviteWorkerOpen,
}: Props) => {
  const [content, setContent] = useState('')

  const [showEndedChatCTA, setShowEndedChatCTA] = useState(false)
  const [textareaHeight, setTextareaHeight] = useState('auto')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [file, setFile] = useState<File | undefined>()
  const [imageUrl, setImageUrl] = useState<string | undefined>()

  const { handleUpload } = useFileUploader()
  const { user } = useUser()
  const { refetch: refetchMessages } = useMessages(summaryId)

  const { showError } = useAlert()
  useEffect(() => {
    setShowEndedChatCTA(companyEndedChatStatus === ChatEndedStatus.ENDED)
  }, [companyEndedChatStatus])

  async function onChangeFile(f: File | undefined) {
    setIsButtonLoading(true)
    setFile(f)
    if (f) {
      const url = await handleUpload({
        fileType: FileType.SHIFT_IMAGES,
        media: f,
        userId: user?.uid,
      })
      setImageUrl(url)
    }
    setIsButtonLoading(false)
  }

  async function onDeleteFile() {
    setFile(undefined)
    setImageUrl(undefined)
  }

  const onSubmitMessage = async () => {
    setIsButtonLoading(true)
    try {
      await postChatMessage(
        {
          messageContent: content,
          attachmentUrl: imageUrl,
        },
        summaryId,
      )
    } catch (e) {
      Sentry.captureException(e)
      showError('Something went wrong. Please try again.')
    }
    setContent('')
    setImageUrl(undefined)
    setIsButtonLoading(false)
    setFile(undefined)

    await refetchMessages()
  }

  if (showEndedChatCTA) {
    return (
      <Text style={{ textAlign: 'center' }} variant="body2">
        This chat has been ended.
        <Text
          variant="link"
          onClick={() => {
            setShowEndedChatCTA(false)
          }}
          ml={theme.space.xxs}
        >
          Start a new chat
        </Text>
      </Text>
    )
  }

  return isFromChatWidget ? (
    <Row alignCenter center>
      <MediaUploader
        file={file}
        onChange={onChangeFile}
        onDelete={onDeleteFile}
        onError={showError}
        maxFileSizeMB={10}
        onlyIcon={true}
      />
      <Input
        rows={1}
        label="Your message"
        type="textarea"
        value={content}
        containerStyle={{
          width: '100%',
          flex: 1,
          marginLeft: theme.space.xxs,
          marginRight: theme.space.xxs,
        }}
        style={{ height: textareaHeight }}
        onChange={(e) => {
          setContent(e.target.value)
          // Update textarea height based on the scrollHeight
          setTextareaHeight(`${e.target.scrollHeight}px`)
        }}
        inputStatus={
          content.length > MAX_ANNOUNCEMENT_MESSAGE_LENGTH
            ? InputStatus.error
            : InputStatus.default
        }
        errorMessage={`Please limit your message to under ${MAX_ANNOUNCEMENT_MESSAGE_LENGTH} characters. You have used ${content.length} characters.`}
      />
      <Button
        loading={isButtonLoading}
        style={{
          padding: theme.space.xxs,
          marginTop: theme.space.xs,
        }}
        onClick={onSubmitMessage}
        disabled={content === '' && !imageUrl}
      >
        <SvgIcon name="send" color={theme.colors.White} />
      </Button>
    </Row>
  ) : (
    <>
      <Input
        rows={4}
        label="Your message"
        type="textarea"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        inputStatus={
          content.length > MAX_ANNOUNCEMENT_MESSAGE_LENGTH
            ? InputStatus.error
            : InputStatus.default
        }
        errorMessage={`Please limit your message to under ${MAX_ANNOUNCEMENT_MESSAGE_LENGTH} characters. You have used ${content.length} characters.`}
      />
      <Row>
        <MediaUploader
          file={file}
          onChange={onChangeFile}
          onDelete={onDeleteFile}
          onError={showError}
          maxFileSizeMB={10}
        />
        <Button
          variant={ButtonVariant.OUTLINED}
          style={{ marginLeft: theme.space.xs, marginTop: theme.space.xs }}
          onClick={() => setInviteWorkerOpen(true)}
        >
          Invite to shift
        </Button>
      </Row>
      <Row justifyEnd mt={isFromChatWidget ? 0 : theme.space.xxs}>
        <Button
          ref={buttonRef}
          slim
          onClick={() => {
            setSelectedChat(undefined)
          }}
          variant={ButtonVariant.OUTLINED}
          style={{ marginRight: theme.space.xxs }}
        >
          Cancel
        </Button>
        <Button
          loading={isButtonLoading}
          slim
          disabled={
            (content === '' && !imageUrl) ||
            content.length > MAX_ANNOUNCEMENT_MESSAGE_LENGTH
          }
          onClick={onSubmitMessage}
          variant={ButtonVariant.FILLED}
          style={{
            paddingLeft: theme.space.med,
            paddingRight: theme.space.med,
          }}
        >
          Send
        </Button>
      </Row>
    </>
  )
}
