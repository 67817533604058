import { LoadingSpinner, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { DailyViewOfScheduleDetails } from '@traba/types'
import { useState } from 'react'
import { Col } from 'src/components'
import { ShiftPastAnnouncements } from 'src/screens/Messaging/components/ShiftPastAnnouncements'
import { canSendNewAnnouncement } from 'src/utils/announcementUtils'
import { ScheduleDailyViewAddAnnouncementContent } from './ScheduleDailyViewAddAnnouncementContent'
import { ScheduleDailyViewShiftTitle } from './ScheduleDailyViewShiftTitle'

interface Props {
  shiftRequestParentDayDetails: DailyViewOfScheduleDetails | undefined
}

export const ScheduleDailyViewAnnouncements = (props: Props) => {
  const { shiftRequestParentDayDetails } = props
  const individualMetadata = Object.values(
    shiftRequestParentDayDetails?.shiftRequestToShiftMetadataMap ?? {},
  )
  const canSendAnnouncement =
    !!individualMetadata?.[0].shift.endTime &&
    canSendNewAnnouncement(individualMetadata?.[0].shift.endTime)
  const [isAdding, setIsAdding] = useState(false)

  if (!individualMetadata) {
    return <LoadingSpinner />
  }

  return (
    <Col mb={theme.space.sm}>
      <Row justifyBetween>
        <Text variant="h4">Announcements</Text>{' '}
        {canSendAnnouncement && (
          <Text
            variant="link1"
            onClick={() => {
              setIsAdding((prev) => !prev)
            }}
            style={{ cursor: 'pointer' }}
          >
            {isAdding ? 'Cancel' : '+ New announcement'}
          </Text>
        )}
      </Row>

      <Text variant="body2">
        {canSendAnnouncement
          ? 'Send an announcement to all workers on shifts, and view past announcements here.'
          : 'View past announcements here.'}
      </Text>
      {isAdding ? (
        <ScheduleDailyViewAddAnnouncementContent
          setIsAdding={setIsAdding}
          individualMetadata={individualMetadata}
        />
      ) : (
        Object.values(
          shiftRequestParentDayDetails?.shiftRequestToShiftMetadataMap ?? {},
        ).map((individualMetadata) => (
          <Col
            key={individualMetadata.shift.shiftId}
            mt={theme.space.sm}
            mb={theme.space.xs}
            gap={theme.space.xs}
          >
            <ScheduleDailyViewShiftTitle
              individualMetadata={individualMetadata}
            />
            <ShiftPastAnnouncements
              shiftId={individualMetadata.shift.shiftId}
              hideTitle
              showEmptyState
            />
          </Col>
        ))
      )}
    </Col>
  )
}
