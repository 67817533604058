import { useHotSettings } from '@traba/hooks'
import {
  Shift,
  WorkerShiftTimeToDestinationResponseDto,
  WorkerShift,
  WorkerShiftWithWorkerDetails,
} from '@traba/types'
import { calculateTimeWorkedInMinutes, getShiftTimeString } from '@traba/utils'
import { isBefore } from 'date-fns'
import { useState } from 'react'
import { UseMutateAsyncFunction } from 'react-query'
import { useNavigate } from 'react-router-dom'
import {
  ClockInOutResult,
  ClockInWorkers,
  ClockOutWorkers,
} from 'src/hooks/workerShiftHooks'
import { BULLET_CHAR } from 'src/libs/constants'
import { theme } from 'src/libs/theme'
import { EarlyIncentiveBadge } from 'src/screens/TimeSheetDetailsScreen/components/EarlyIncentiveBadge'
import { formatDuration } from 'src/shared/utils/dateUtils'
import { getWorkerShiftUpdatesWithoutEarlyArrival } from 'src/utils/moneyUtils'
import { getIsCompleteStatus } from 'src/utils/workerShiftUtils'

import { WebToRNEventName } from '../../types/events'
import { Avatar, Col, Divider, Row, SvgIcon, Text } from '../base'
import { Checkbox } from '../base/CheckboxThemed'
import { Dialog } from '../base/Dialog/Dialog'
import { RookieBadge } from '../RookieBadge/RookieBadge'
import { WorkerLinkText } from '../WorkerDetails/WorkerLinkText'
import { WorkerOnShiftBadge } from '../WorkerOnShiftBadge/WorkerOnShiftBadge'
import { LiveWorkerEarning } from './components/liveWorkerEarning'
import { TimesheetBadge } from './components/TimeSheetBadge'
import { WorkerOnShiftTableBreaksField } from './components/WorkerOnShiftTableBreaksField'
import { WorkerOnShiftTableClockInField } from './components/WorkerOnShiftTableClockInField'
import { WorkerOnShiftTableClockOutField } from './components/WorkerOnShiftTableClockOutField'
import { WorkerOnShiftTableTotalWorked } from './components/WorkerOnShiftTableTotalWorked'

interface Props {
  workerShift: WorkerShiftWithWorkerDetails
  timeToDestinationsByWorkerId: Map<
    string,
    WorkerShiftTimeToDestinationResponseDto
  >
  shift: Shift
  editMode?: boolean
  isUpcoming?: boolean
  isBackup: boolean
  isPBUShift: boolean
  clockInWorkers: UseMutateAsyncFunction<
    ClockInOutResult,
    Error,
    ClockInWorkers
  >
  editClockInWorkers: UseMutateAsyncFunction<
    ClockInOutResult,
    Error,
    ClockInWorkers
  >
  clockOutWorkers: UseMutateAsyncFunction<
    ClockInOutResult,
    Error,
    ClockOutWorkers
  >
  showEarnedMoney: boolean
  timezone: string
  onEditWorkerShift?: (
    newWorkerShift: Partial<WorkerShift> & {
      workerId: string
      shiftId: string
    },
  ) => void
  selectedRows: WorkerShiftWithWorkerDetails[]
  setSelectedRows: (
    value: React.SetStateAction<WorkerShiftWithWorkerDetails[]>,
  ) => void
  refetchWorkerShifts: () => void
  isFromTimesheetDetails: boolean
  tableRowStyle?: React.CSSProperties
  hideBottomDivider: boolean
  dayViewRoleColor?: string
  isFirstItem?: boolean
}

export const MobileWorkersOnShiftListItem = ({
  workerShift,
  timeToDestinationsByWorkerId,
  shift,
  editMode,
  isUpcoming,
  clockInWorkers,
  editClockInWorkers,
  clockOutWorkers,
  isBackup,
  timezone,
  showEarnedMoney,
  onEditWorkerShift,
  isPBUShift,
  selectedRows,
  setSelectedRows,
  isFromTimesheetDetails = false,
  refetchWorkerShifts,
  tableRowStyle,
  hideBottomDivider,
  dayViewRoleColor,
  isFirstItem,
}: Props) => {
  const { hotSettings } = useHotSettings()
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const navigate = useNavigate()
  const timeToDestination = timeToDestinationsByWorkerId.get(
    workerShift.workerId,
  )
  const worker = workerShift.worker

  const isWorkerShiftComplete = getIsCompleteStatus(workerShift.jobStatus)
  const workerShiftCharge = getWorkerShiftUpdatesWithoutEarlyArrival(
    shift,
    workerShift,
  )

  const earlyTime =
    !!shift?.businessStartTime &&
    !!workerShift.clockInTime &&
    isBefore(workerShift.clockInTime, shift.businessStartTime)
      ? true
      : false

  const renderEarlyTimeBadge = () => {
    return earlyTime ? <EarlyIncentiveBadge /> : <></>
  }
  const checked = !!selectedRows.find(
    ({ workerId }) => workerId === workerShift.workerId,
  )

  const formattedTimeWorked = formatDuration(
    calculateTimeWorkedInMinutes(
      workerShift,
      hotSettings?.enableWorkerEditTime,
    ),
  )

  return (
    <Col
      mt={theme.space.us}
      mb={theme.space.us}
      mr={theme.space.xxs}
      style={{
        borderLeft: dayViewRoleColor ? `4px solid ${dayViewRoleColor}` : 'none',
      }}
    >
      <Col>
        {isFirstItem && dayViewRoleColor && (
          <Text
            color={theme.colors.White}
            style={{
              borderBottomRightRadius: theme.space.ms,
              backgroundColor: dayViewRoleColor,
              padding: theme.space.us,
              textAlign: 'center',
            }}
            variant="caption"
          >
            {getShiftTimeString(
              workerShift.shiftInfo.startTime,
              workerShift.shiftInfo.endTime,
              workerShift.shiftInfo.timezone,
            )}
            {` ${BULLET_CHAR} `}
            {workerShift.shiftInfo.shiftRole}
          </Text>
        )}
      </Col>
      <Row
        alignCenter
        justifyBetween
        style={{
          ...tableRowStyle,
        }}
      >
        <Row alignCenter pt={theme.space.xxs} pb={theme.space.xxs}>
          {!isFromTimesheetDetails && !dayViewRoleColor && (
            <Checkbox
              selected={checked}
              hidden={editMode}
              onClick={() => {
                if (checked) {
                  setSelectedRows(
                    selectedRows.filter(
                      ({ workerId }) => workerId !== workerShift.workerId,
                    ),
                  )
                } else {
                  setSelectedRows([...selectedRows, workerShift])
                }
              }}
              style={{ justifyContent: 'center', marginRight: theme.space.xxs }}
            />
          )}
          <Col>
            <Row
              alignCenter
              gap={theme.space.xxs}
              mr={theme.space.xxs}
              ml={theme.space.xxs}
            >
              <Avatar
                src={worker.photoUrl}
                name={`${worker.firstName} ${worker.lastName}` ?? 'worker'}
                size={50}
              />
              <Col>
                <WorkerLinkText
                  variant="h6"
                  onClick={() => navigate(`/worker/${workerShift.workerId}`)}
                >
                  {worker.firstName} {worker?.lastName}
                </WorkerLinkText>
                {isUpcoming ? (
                  <WorkerOnShiftBadge
                    workerShift={workerShift}
                    timeToDestination={timeToDestination}
                    isBackup={isBackup}
                  />
                ) : (
                  <TimesheetBadge workerShift={workerShift} />
                )}
              </Col>
              {showEarnedMoney && (
                <LiveWorkerEarning
                  workerShift={workerShift}
                  workerShiftCharge={workerShiftCharge}
                  editMode={editMode}
                  shiftMarkup={shift.calculatedMarkup}
                />
              )}
            </Row>
          </Col>
        </Row>
        {!isFromTimesheetDetails && (
          <SvgIcon
            name="ellipses"
            color={theme.colors.MidnightBlue}
            onClick={() => {
              window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                  event: WebToRNEventName.FULL_SCREEN_MODAL_OPEN,
                }),
              )
              setShowDetailsModal(!showDetailsModal)
            }}
          />
        )}
      </Row>

      {isFromTimesheetDetails && (
        <Row justifyBetween mb={theme.space.xs}>
          <Col>
            <Text variant="body3">Clock in</Text>
            <WorkerOnShiftTableClockInField
              showEditableTimeInput={!!(editMode && isWorkerShiftComplete)}
              clockInWorkers={clockInWorkers}
              clockOutWorkers={clockOutWorkers}
              editClockInWorkers={editClockInWorkers}
              workerShift={workerShift}
              timezone={timezone}
              onEditWorkerShift={onEditWorkerShift}
              refetchWorkerShifts={refetchWorkerShifts}
              isFromTimesheetDetails={isFromTimesheetDetails}
            />
          </Col>

          <Col>
            <Text variant="body3">Clock out</Text>
            <WorkerOnShiftTableClockOutField
              showEditableTimeInput={!!(editMode && isWorkerShiftComplete)}
              clockInWorkers={clockInWorkers}
              clockOutWorkers={clockOutWorkers}
              editClockInWorkers={editClockInWorkers}
              workerShift={workerShift}
              timezone={timezone}
              onEditWorkerShift={onEditWorkerShift}
              refetchWorkerShifts={refetchWorkerShifts}
              isFromTimesheetDetails={isFromTimesheetDetails}
            />
          </Col>

          <Col>
            <Text variant="body3">Break time</Text>
            <Row
              style={{
                width: isFromTimesheetDetails && editMode ? '80px' : undefined,
              }}
            >
              <WorkerOnShiftTableBreaksField
                workerShift={workerShift}
                onEditWorkerShift={onEditWorkerShift}
                editMode={editMode}
                timezone={timezone}
                isFromTimesheetDetails={isFromTimesheetDetails}
                scheduledBreaks={shift.scheduledBreaks}
                refetchWorkerShifts={refetchWorkerShifts}
              />
            </Row>
          </Col>

          {editMode ? null : (
            <Col>
              <Text variant="body3">Worked time</Text>
              {isPBUShift ? (
                <Text variant="body2">
                  {workerShift.unitsWorked || 0} units
                </Text>
              ) : (
                <Text
                  style={{ marginTop: theme.space.xxxs }}
                  variant="body3"
                  color={theme.colors.Blue100}
                >
                  {formattedTimeWorked}
                </Text>
              )}
            </Col>
          )}
        </Row>
      )}

      {!hideBottomDivider && <Divider />}
      <Dialog
        hideFooter
        dialogTitle={'Shift details'}
        onClose={() => {
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              event: WebToRNEventName.FULL_SCREEN_MODAL_CLOSE,
            }),
          )
          setShowDetailsModal(false)
        }}
        open={showDetailsModal}
      >
        <Col mt={theme.space.sm}>
          <Col
            gap={theme.space.xxs}
            style={{
              alignSelf: 'center',
              alignItems: 'center',
              marginBottom: theme.space.xs,
            }}
          >
            <Avatar
              src={worker.photoUrl}
              name={`${worker.firstName} ${worker.lastName}` ?? 'worker'}
              size={50}
            />
            <Text variant="h5">
              {worker.firstName} {worker?.lastName}
            </Text>

            {/* All the badges here */}
            {isUpcoming ? (
              <WorkerOnShiftBadge
                workerShift={workerShift}
                timeToDestination={timeToDestination}
                isBackup={isBackup}
              />
            ) : (
              <TimesheetBadge workerShift={workerShift} />
            )}
            {workerShift.isFirstTimeWorkerShiftWithCompany && <RookieBadge />}
            {renderEarlyTimeBadge()}
          </Col>

          <Row justifyBetween py={theme.space.xs}>
            <Text variant="body1">Clock in</Text>
            <WorkerOnShiftTableClockInField
              showEditableTimeInput={!!(editMode && isWorkerShiftComplete)}
              clockInWorkers={clockInWorkers}
              clockOutWorkers={clockOutWorkers}
              editClockInWorkers={editClockInWorkers}
              workerShift={workerShift}
              timezone={timezone}
              refetchWorkerShifts={refetchWorkerShifts}
              isFromTimesheetDetails={isFromTimesheetDetails}
            />
          </Row>
          <Divider />

          <Row justifyBetween py={theme.space.xs}>
            <Text variant="body1">Clock out</Text>
            <WorkerOnShiftTableClockOutField
              showEditableTimeInput={!!(editMode && isWorkerShiftComplete)}
              clockInWorkers={clockInWorkers}
              clockOutWorkers={clockOutWorkers}
              editClockInWorkers={editClockInWorkers}
              workerShift={workerShift}
              timezone={timezone}
              refetchWorkerShifts={refetchWorkerShifts}
              isFromTimesheetDetails={isFromTimesheetDetails}
            />
          </Row>
          <Divider />
          <Row justifyBetween py={theme.space.xs}>
            <Text variant="body1">Breaks</Text>
            <WorkerOnShiftTableBreaksField
              workerShift={workerShift}
              onEditWorkerShift={onEditWorkerShift}
              editMode={editMode}
              timezone={timezone}
              scheduledBreaks={shift.scheduledBreaks}
              refetchWorkerShifts={refetchWorkerShifts}
            />
          </Row>
          <Divider />
          <Row justifyBetween py={theme.space.xs}>
            <Text variant="body1">Total worked</Text>

            {isPBUShift ? (
              <Text variant="body2">{workerShift.unitsWorked || 0} units</Text>
            ) : null}
            <WorkerOnShiftTableTotalWorked
              isWorkerShiftComplete={isWorkerShiftComplete}
              editMode={editMode}
              workerShift={workerShift}
            />
          </Row>
        </Col>
      </Dialog>
    </Col>
  )
}
