import { SvgIconProps } from '@traba/types'

export default function FilledCheck({
  size = 24,
  color,
  ...rest
}: SvgIconProps) {
  return (
    <svg viewBox="0 0 24 24" fill="none" width={size} height={size} {...rest}>
      <rect width="24" height="24" rx="12" fill={color} />
      <path
        d="M18 7L9.42857 17L6 13"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
