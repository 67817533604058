export enum UserRole {
  Admin = 'ADMIN',
  Accounting = 'ACCOUNTING',
  LimitedShiftCreator = 'LIMITED_SHIFT_CREATOR',
  ShiftCreator = 'SHIFT_CREATOR',
  Supervisor = 'SUPERVISOR',
}

export const USER_ROLE_NAMES: { [K in UserRole]: string } = {
  [UserRole.Admin]: 'Admin',
  [UserRole.Accounting]: 'Accounting',
  [UserRole.LimitedShiftCreator]: 'Shift Creator',
  [UserRole.ShiftCreator]: 'Shift & Role Manager',
  [UserRole.Supervisor]: 'Supervisor',
}

export const USER_ROLES = Object.values(UserRole)

export const USER_ROLE_OPTIONS = USER_ROLES.map((value) => ({
  value,
  label: USER_ROLE_NAMES[value],
}))
