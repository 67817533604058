import { Invoice, LineItem } from '@traba/types'
import { format } from 'date-fns'
import { getPeriodFromInvoice } from 'src/components/InvoiceTable/utils'
import { formatDuration } from 'src/shared/utils/dateUtils'
import { getMoneyString } from 'src/utils/moneyUtils'

import { InvoiceDetailsTableRow } from './InvoiceDetailsTable.ui'

export const INVOICE_DETAILS_HEADERS = [
  `DATE`,
  `DESCRIPTION`,
  `TOTAL CHARGE`,
  `WORKER PAY`,
  `TOTAL AMOUNT`,
]

const LATE_CANCEL_FEE_TEXT = 'Late Cancel Fee'
const getIsLateCancelFee = (description: string) => {
  return description.includes(LATE_CANCEL_FEE_TEXT)
}

/** Sums up all the lineItems' gross pay */
export const calculateTotalGrossPayToWorker = (lineItems: LineItem[]) => {
  return lineItems.reduce((prevItem, currentItem) => {
    const isLateCancelFee = getIsLateCancelFee(currentItem.description)
    return currentItem?.grossPayToWorker?.amount && !isLateCancelFee
      ? prevItem + currentItem.grossPayToWorker.amount
      : prevItem
  }, 0)
}

/** Creates an Invoice Line Item Row */
export function convertToRowData(
  lineItems: LineItem[],
): InvoiceDetailsTableRow[] {
  return (
    lineItems.map((lineItem) => {
      const isLateCancelFee = getIsLateCancelFee(lineItem.description)
      const totalTimeWorked = lineItem.totalTimeWorked
        ? formatDuration(lineItem.totalTimeWorked)
        : ''
      const totalUnitsWorked = lineItem.totalUnitsWorked
        ? lineItem.totalUnitsWorked + ' units'
        : ''
      return {
        shiftId: lineItem.shiftId,
        cells: [
          lineItem.date ? format(new Date(lineItem.date), 'eee, MMM d') : `--`,
          lineItem.description,
          lineItem.totalUnitsWorked ? totalUnitsWorked : totalTimeWorked,
          lineItem.grossPayToWorker && !isLateCancelFee
            ? getMoneyString(lineItem.grossPayToWorker)
            : `--`,
          getMoneyString(lineItem.chargeToBusiness),
        ],
      }
    }) || []
  )
}

export const getInvoiceSubtitle = (invoice: Invoice) => {
  const { periodStart, periodEnd } = getPeriodFromInvoice(invoice)
  const invoiceSubtitleType =
    invoice.invoiceGroupId && invoice.invoiceGroupName
      ? `'${invoice.invoiceGroupName}'`
      : ''

  return `Here is your ${invoiceSubtitleType} invoice for the period ${format(
    periodStart,
    'M/dd/yyyy',
  )} - ${format(periodEnd, 'M/dd/yyyy')}`
}
