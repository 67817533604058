import { trabaApi } from '@traba/api-utils'
import { BIZ_REFERRAL_AMOUNT } from '@traba/consts'
import { useAlert } from '@traba/context'
import { useFormik } from 'formik'
import { useState } from 'react'
import {
  Button,
  ButtonVariant,
  Divider,
  Icon,
  Input,
  InputPhone,
  MainLayout,
  Row,
  Text,
} from 'src/components'
import PlacesAutocomplete from 'src/components/PlacesAutocomplete/PlacesAutocomplete'

import { REQUIRED_FIELD_MESSAGE } from 'src/libs/constants'
import { theme } from 'src/libs/theme'
import { Address } from 'src/types'
import { email, name, phoneNumber } from 'src/utils/formUtils'
import * as yup from 'yup'

export default function ReferScreen() {
  const { showSuccess, showError } = useAlert()
  const [locationAddress, setLocationAddress] = useState<Address | undefined>(
    undefined,
  )
  const formik = useFormik({
    initialValues: {
      name: '',
      companyName: '',
      phoneNumber: '',
      locationString: '',
      emailAddress: '',
    },
    validationSchema: yup.object({
      name: name(),
      companyName: yup.string().required(REQUIRED_FIELD_MESSAGE),
      phoneNumber: phoneNumber(false),
      locationString: yup.string(),
      email: email(false),
    }),
    onSubmit: async (values) => {
      formik.setStatus({})
      const event = {
        ...values,
        locationAddress,
      }
      window.analytics.track('Submit Business Referral', event)
      setShowLoading(true)
      try {
        await trabaApi.post('/my-company/submit-referral', {
          ...values,
          location: locationAddress,
        })
        showSuccess(
          'Thank you for your referral! Someone from Traba will contact you soon!',
        )
        formik.resetForm()
        formik.setValues(
          {
            phoneNumber: '1',
            companyName: '',
            name: '',
            emailAddress: '',
            locationString: '',
          },
          false,
        )
      } catch (e: any) {
        showError(e.message, 'Error', 10000)
      } finally {
        setShowLoading(false)
      }
    },
  })
  const [showLoading, setShowLoading] = useState(false)
  const { errors, touched } = formik

  function handleChangePlacesAutocomplete(val: Address) {
    formik.setFieldValue('locationString', val.street1)
    setLocationAddress(val)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <MainLayout title={`Refer & Earn $${BIZ_REFERRAL_AMOUNT}`}>
        <Text variant="h1">
          Refer a Business to Traba, Earn ${BIZ_REFERRAL_AMOUNT}!
        </Text>
        <Text variant="body2" mt={theme.space.xs}>
          For every new company you refer to Traba that completes 100 hours of
          Traba shifts, you get ${BIZ_REFERRAL_AMOUNT}!
        </Text>
        <div>
          <Row mt={theme.space.med}>
            <Text variant="h3">{'Share Traba with your peers'}</Text>
          </Row>
          <Row mt={theme.space.xs} pb={theme.space.xs}>
            <Text variant="body2">
              Invite people whose companies would benefit from using Traba.
            </Text>
          </Row>
          <Input
            full
            label="Name"
            {...formik.getFieldProps('name')}
            inputStatus={touched.name && errors.name ? 3 : 1}
            errorMessage={errors.name}
            onChange={formik.handleChange}
          />
          <Input
            full
            label="Name of Business"
            {...formik.getFieldProps('companyName')}
            inputStatus={touched.companyName && errors.companyName ? 3 : 1}
            errorMessage={errors.companyName}
            onChange={formik.handleChange}
          />
          <InputPhone
            value={formik.values.phoneNumber}
            onChange={(value: string) =>
              formik.handleChange({ target: { value, name: 'phoneNumber' } })
            }
            error={touched.phoneNumber ? errors.phoneNumber : null}
            title="Phone Number (optional)"
          />
          <Row mt={24}>
            <PlacesAutocomplete
              onSelect={handleChangePlacesAutocomplete}
              value={formik.values?.locationString}
              onChange={(value: string) =>
                formik.setFieldValue('locationString', value)
              }
              label="Location (optional)"
              errorMessage={
                touched.locationString && errors.locationString
                  ? errors.locationString
                  : null
              }
            />
          </Row>
          <Input
            full
            label="Email Address (optional)"
            {...formik.getFieldProps('emailAddress')}
            value={formik.values.emailAddress}
            onChange={formik.handleChange}
            inputStatus={touched.emailAddress && errors.emailAddress ? 3 : 1}
            errorMessage={errors.emailAddress}
          />
        </div>
        <Row my={32} justifyEnd={true}>
          <Button type="submit" loading={showLoading}>
            Submit
          </Button>
        </Row>
        <Divider />
        <Text variant="h3" mt={theme.space.lg}>
          How Referrals Work
        </Text>
        <Button
          disabled
          variant={ButtonVariant.OUTLINED}
          style={{ padding: theme.space.xs, marginTop: theme.space.med }}
        >
          <Icon name="userProfile" />
        </Button>
        <Text variant="h4" mt={theme.space.xs}>
          Invite a new company to Traba
        </Text>
        <Text variant="body2" mt={theme.space.xs}>
          Fill in quick information and invite a company who doesn't have an
          account with Traba.
        </Text>
        <Button
          disabled
          variant={ButtonVariant.OUTLINED}
          style={{ padding: theme.space.xs, marginTop: theme.space.med }}
        >
          <Icon name="sign_up_for_traba" />
        </Button>
        <Text variant="h4" mt={theme.space.xs}>
          They post a shift
        </Text>
        <Text variant="body2" mt={theme.space.xs}>
          They sign up and complete 100 hours of Traba shifts.
        </Text>
        <Button
          disabled
          variant={ButtonVariant.OUTLINED}
          style={{ padding: theme.space.xs, marginTop: theme.space.med }}
        >
          <Icon name="dollar" />
        </Button>
        <Text variant="h4" mt={theme.space.xs}>
          You get paid!
        </Text>
        <Text variant="body2" mt={theme.space.xs}>
          You get ${BIZ_REFERRAL_AMOUNT} for connecting us.
        </Text>
      </MainLayout>
    </form>
  )
}
