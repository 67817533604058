import { SvgIconProps } from 'src/types/svg-types'

export default function Dot({
  size = 4,
  fill = '#E2E6E9',
  ...rest
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 4 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="2" cy="2" r="2" fill={fill} />
    </svg>
  )
}
