import {
  CancellationSource,
  ShiftInvitationStatus,
  WorkerShift,
} from '@traba/types'
import { isAfter } from 'date-fns'
import { Badge } from '../base-components/Badge/Badge'
import { BadgeVariant } from '../base-components/Badge/Badge.styles'

export function ShiftInvitationBadge({
  id,
  status,
  canceledWorkerShift,
  shiftOpenToPublicTime,
}: {
  id: string
  status: ShiftInvitationStatus
  canceledWorkerShift?: WorkerShift
  shiftOpenToPublicTime?: Date
}) {
  let badgeTitle = ''
  let badgeVariant: BadgeVariant
  switch (status) {
    case ShiftInvitationStatus.Accepted:
      if (
        canceledWorkerShift &&
        canceledWorkerShift.cancellationSource !== CancellationSource.Business
      ) {
        badgeVariant = 'danger'
        if (
          canceledWorkerShift.cancellationSource === CancellationSource.Worker
        ) {
          badgeTitle = 'Declined'
        } else {
          badgeTitle = 'Removed by Traba'
        }
      } else {
        badgeTitle = 'Accepted'
        badgeVariant = 'success'
      }

      break
    case ShiftInvitationStatus.Declined:
      badgeTitle = 'Declined'
      badgeVariant = 'danger'
      break
    default:
      if (
        shiftOpenToPublicTime !== undefined &&
        isAfter(new Date(), shiftOpenToPublicTime)
      ) {
        badgeTitle = 'Did Not Respond'
        badgeVariant = 'warning'
        break
      }
      badgeTitle = 'Waiting for a response'
      badgeVariant = 'warning'
      break
  }
  return <Badge key={id} title={badgeTitle} variant={badgeVariant} />
}
