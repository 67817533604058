import { useAlert } from '@traba/context'
import { theme } from '@traba/theme'
import {
  CancellationSource,
  CancelRecurringWorkerShiftRequest,
  RecurringWorkerShiftEditType,
  Shift,
} from '@traba/types'
import { useState } from 'react'
import { Dialog } from '../base-components/Dialog/Dialog'
import { LoadingSpinner } from '../base-components/LoadingSpinner'
import { MODAL_SIZE } from '../base-components/Modal'
import { RadioButton } from '../base-components/RadioButton'
import Row from '../base-components/Row'
import { Text } from '../base-components/Text'
import { ShiftItem } from '../shifts/ShiftItem'

interface Props {
  shifts?: Shift[]
  workerIds?: string[]
  showRemoveWorkersModal: boolean
  onCloseModal: () => void
  isLoading: boolean
  shiftRequestId?: string
  refetchWorkersOnSchedule: () => void
  cancelRecurringWorkerShift: (
    request: CancelRecurringWorkerShiftRequest,
  ) => Promise<void>
  isFromOpsConsole?: boolean
}

enum RemoveMode {
  SELECTED = 'SELECTED',
  ALL = 'ALL',
}

export const RemoveWorkersFromScheduleModalContent = (props: Props) => {
  const {
    isLoading,
    shifts,
    workerIds,
    showRemoveWorkersModal,
    onCloseModal,
    shiftRequestId,
    refetchWorkersOnSchedule,
    cancelRecurringWorkerShift,
    isFromOpsConsole,
  } = props
  const [selected, setSelected] = useState<Shift[]>([])
  const [isConfirming, setIsConfirming] = useState(false)
  const [selectedRemoveMode, setSelectedRemoveMode] = useState<
    RemoveMode | undefined
  >()
  const { showError, showSuccess } = useAlert()
  const STEP_ONE = (
    <>
      <Text variant="h6">
        Do you want to remove selected workers from the whole schedule or
        selected shifts?
      </Text>
      {Object.values(RemoveMode).map((mode) => (
        <Row
          key={mode}
          alignCenter
          my={theme.space.xs}
          gap={theme.space.xs}
          style={{
            cursor: 'pointer',
            backgroundColor:
              mode === selectedRemoveMode
                ? theme.colors.Grey10
                : theme.colors.White,
            padding: theme.space.xs,
            borderRadius: theme.space.xs,
            border: `1px solid ${theme.colors.Grey20}`,
          }}
          onClick={() => {
            setSelectedRemoveMode(mode)
          }}
        >
          <RadioButton selected={mode === selectedRemoveMode} size={24} />
          <Text variant={'body1'}>
            {mode === RemoveMode.ALL
              ? 'Remove from the whole schedule'
              : 'Remove from selected shifts'}
          </Text>
        </Row>
      ))}
    </>
  )

  const onConfirm = async () => {
    if (!workerIds) {
      return showError('No workers selected')
    }
    if (!shiftRequestId) {
      return showError('No shift request id')
    }

    if (selectedRemoveMode === RemoveMode.ALL) {
      if (!shifts || shifts.length === 0) {
        return showError('This schedule has no shifts anymore')
      }
      try {
        setIsConfirming(true)
        await cancelRecurringWorkerShift({
          workerIds,
          cancellationSource: isFromOpsConsole
            ? CancellationSource.Ops
            : CancellationSource.Business,
          originalStartTime: shifts[0].originalStartTime,
          shiftRequestId,
          editType: RecurringWorkerShiftEditType.ALL_FUTURE,
        })
      } catch (error) {
        showError(
          'Failed to remove workers from the schedule, please try again',
        )
      }
      refetchWorkersOnSchedule()
      setIsConfirming(false)
      onCloseModal()
      showSuccess('Workers removed from the schedule')
    } else {
      if (!selected || selected.length === 0) {
        return showError(
          'Please select at least one shift to remove the worker from',
        )
      }
      try {
        setIsConfirming(true)
        await Promise.all(
          selected.map((shift) =>
            cancelRecurringWorkerShift({
              workerIds,
              cancellationSource: isFromOpsConsole
                ? CancellationSource.Ops
                : CancellationSource.Business,
              originalStartTime: shift.originalStartTime,
              shiftRequestId,
              shiftId: shift.shiftId,
              editType: RecurringWorkerShiftEditType.SINGLE,
            }),
          ),
        )
      } catch (error) {
        showError('Failed to remove worker from shifts, please try again')
      }
      refetchWorkersOnSchedule()
      setIsConfirming(false)
      onCloseModal()
      showSuccess('Workers removed from shifts')
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="paper"
      open={showRemoveWorkersModal}
      onClose={onCloseModal}
      onConfirmCTA="Confirm"
      dialogTitle={`Remove selected workers`}
      formId="create-roster"
      onConfirm={onConfirm}
      size={MODAL_SIZE.LARGE}
      confirming={isConfirming}
    >
      {STEP_ONE}
      {(selectedRemoveMode !== undefined && isLoading) ||
      !shifts ||
      !workerIds ? (
        <LoadingSpinner />
      ) : selectedRemoveMode === RemoveMode.SELECTED ? (
        <>
          <Text variant="h6">
            Select the shifts you want to remove workers from (up to 3 weeks
            into the future)
          </Text>
          {shifts.map((shift: Shift) => (
            <ShiftItem
              key={shift.id}
              shift={shift}
              selected={selected}
              setSelected={setSelected}
            />
          ))}
        </>
      ) : null}
    </Dialog>
  )
}
