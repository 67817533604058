import React from 'react'
import { Text } from 'src/components/base'
import styled from 'styled-components'

import { TextVariant } from './Text'

type Props = {
  full?: boolean
  type?: string
  name?: string
  label?: string | JSX.Element
  value?: any
  placeholder?: string
  defaultChecked?: boolean
  labelStyle?: { [key: string]: string | number }
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => any
  labelVariant?: TextVariant
  checked?: boolean
}

const StyledCheckbox = styled.label`
  display: block;
  position: relative;
  padding-left: 37px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* On mouse-over, add a grey background color */
  :hover input ~ .checkmark {
    background-color: #ffffff;
    border: 2px solid ${({ theme }) => theme.colors.Grey50};
  }

  /* When the checkbox is checked, add a theme-colored background */
  input:checked ~ .checkmark {
    background-color: #8000ff;
    border: 2px solid ${({ theme }) => theme.colors.brand};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #ffffff;
    border: 2px solid ${({ theme }) => theme.colors.Grey30};
    border-radius: 8px;
  }

  /* Checkmark indicator */
  .checkmark:after {
    left: 8px;
    top: 2px;
    width: 4px;
    height: 11px;
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

export default function Checkbox({
  labelStyle,
  labelVariant = 'body2',
  ...props
}: Props) {
  return (
    <StyledCheckbox style={labelStyle}>
      <input type="checkbox" {...props} />
      <span className="checkmark"></span>
      <Text variant={labelVariant}>{props.label}</Text>
    </StyledCheckbox>
  )
}
