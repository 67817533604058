import React from 'react'
import { Button, ButtonVariant, Text } from 'src/components/base'
import { MemberRoleInfo } from 'src/components/MemberRoleInfo/MemberRoleInfo'
import { theme } from 'src/libs/theme'

import { ButtonIcon } from '../../PersonalProfile/PersonalProfile.styles'

interface MemberRoleInfoModalProps {
  handleClose: () => void
}

export const MemberRoleInfoModal = (props: MemberRoleInfoModalProps) => {
  const { handleClose } = props
  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          padding: theme.space.med,
          paddingTop: theme.space.lg,
          paddingBottom: theme.space.sm,
          borderBottom: `1px solid ${theme.colors.Grey20}`,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text variant="h2">Team member roles</Text>
          <ButtonIcon
            name="cancel"
            style={{ cursor: 'pointer' }}
            onClick={handleClose}
          />
        </div>
        <Text variant="body2" style={{ marginTop: theme.space.xxs }}>
          Refer to the chart below to see the abilities associated with each
          type of team member role.
        </Text>
      </div>
      <MemberRoleInfo />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: theme.space.med,
          borderTop: `1px solid ${theme.colors.Grey20}`,
        }}
      >
        <Button onClick={handleClose} variant={ButtonVariant.FILLED}>
          Done
        </Button>
      </div>
    </div>
  )
}
