import { createTheme } from '@mui/material'
import { addPixelUnit } from 'src/helpers'

export interface TrabaTheme {
  ActiveBackColor: string
  ReverseBackColor: string
  TextButton: string
  ActiveHoverColor: string
  ReverseHoverColor: string
  BorderColor: string
  HoverBorderColor: string
  HoverTextButton: string
  error: {
    border: string
  }
  default: {
    fontSize: string
  }
  border: {
    radius: string
    color: string
  }
  colors: {
    brand: string
    reverseBackColor: string
    textButton: string
    red: string
    Red10: string
    Violet: string
    Violet10: string
    Violet40: string
    Violet70: string
    Violet80: string
    Violet90: string
    Blue10: string
    Blue80: string
    Green10: string
    Green40: string
    Green70: string
    Green80: string
    Grey60: string
    Grey50: string
    Grey10: string
    Grey80: string
    Grey90: string
    Blue100?: string
    Grey: string
    TrabaBlue: string
    MidnightBlue: string
    Violet20: string
    Violet30: string
    Violet50: string
    Grey20: string
    Grey30: string
    Grey40: string
    Yellow10: string
    Yellow70: string
    Yellow80: string
    Orange10: string
    Orange60: string
    Orange70: string
    Red30: string
    Red40: string
    Red50: string
    Red60: string
    Red70: string
    Red80: string
    White: string

    Peach10: string
    Peach40: string
    Peach70: string
    Peach100: string
  }
  palette: {
    mode: string
    primary: {
      main: string
      darker: string
    }
    neutral: {
      main: string
    }
  }
  media: {
    maxExtraSmall: string
    minSmall: string
    maxSmall: string
    minMedium: string
    maxMedium: string
    minLarge: string
    maxLarge: string
    minExtraLarge: string
  }
  space: {
    zero: number
    us: number
    ms: number
    xms: number
    xxs: number
    xxxs: number
    xsmed: number
    xs: number
    sm: number
    med: number
    lg: number
    xl: number
    xxl: number
    xxxl: number
  }
}

interface TrabaThemeOptions {
  ActiveBackColor?: string
  ReverseBackColor?: string
  TextButton?: string
  ActiveHoverColor?: string
  ReverseHoverColor?: string
  BorderColor?: string
  HoverBorderColor?: string
  HoverTextButton?: string
  error?: {
    border?: string
  }
  default?: {
    fontSize?: string
  }
  border?: {
    radius?: string
    color?: string
  }
  colors?: {
    brand?: string
    reverseBackColor?: string
    textButton?: string
    red?: string
    Violet10?: string
    Grey60?: string
    Grey50?: string
    Grey10?: string
    Grey90?: string
    Blue100?: string
    Violet?: string
    TrabaBlue?: string
    MidnightBlue?: string
    Grey?: string
    Violet20: string
    Violet40: string
    Violet50: string
    Violet70: string
    Violet80: string
    Grey20: string
    Grey30: string
    Blue10: string
    Blue80: string
    Green10: string
    Green40: string
    Green70: string
    Green80: string
    Yellow10: string
    Yellow70: string
    Yellow80: string
    Orange10: string
    Orange60: string
    Orange70: string
    Red10: string
    Red30: string
    Red40: string
    Red50: string
    Red60: string
    Red70: string
    Red80: string
    White: string
    Peach10: string
    Peach40: string
    Peach70: string
    Peach100: string
  }
  media: {
    maxExtraSmall: string
    minSmall: string
    maxSmall: string
    minMedium: string
    maxMedium: string
    minLarge: string
    maxLarge: string
    minExtraLarge: string
  }
  space: {
    zero: number
    us: number
    ms: number
    xms: number
    xxs: number
    xxxs: number
    xsmed: number
    xs: number
    sm: number
    med: number
    lg: number
    xl: number
    xxl: number
    xxxl: number
  }
  palette?: {
    mode?: string
    primary?: {
      main?: string
      darker?: string
    }
    neutral?: {
      main?: string
    }
  }
}

declare module 'styled-components' {
  export interface DefaultTheme extends TrabaTheme {}
}

declare module '@mui/material/styles' {
  interface Theme extends TrabaTheme {}
  interface ThemeOptions extends TrabaThemeOptions {}

  interface Palette {
    neutral: Palette['primary']
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary']
  }
  interface PaletteColor {
    darker?: string
  }
  interface SimplePaletteColorOptions {
    darker?: string
  }
}

export type SpacingPoints =
  | 'us'
  | 'ms'
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'med'
  | 'lg'
  | 'xl'
export const spacingPoints = ['us', 'xxs', 'xs', 'sm', 'med', 'lg', 'xl', 'xxl']

export const BrandColors = {
  Violet: '#8000FF',
  TrabaBlue: '#8793FF',
  MidnightBlue: '#08105E',
  Grey: '#E2E5E9',
  White: '#FFFFFF',
}

export const otherColors = {
  Violet10: '#F5EBFF',
  Violet20: '#E6CCFF',
  Violet30: '#D9B3FF',
  Violet40: '#B366FF',
  Violet50: '#9933FF',
  Violet70: '#6600CC',
  Violet80: '#4D0099',
  Violet90: '#330066',
  Grey10: '#F7F7F8',
  Grey20: '#E2E6E9',
  Grey30: '#C4CCD4',
  Grey40: '#B6BFC9',
  Grey50: '#7A8A99',
  Grey60: '#66788A',
  Grey80: '#414D58',
  Grey90: '#2B333B',
  Blue10: '#E9EFFC',
  Blue80: '#133986',
  Blue100: '#08105E',
  Green10: '#EDF8F3',
  Green40: '#94D1B8',
  Green70: '#1AB273',
  Green80: '#138656',
  Yellow10: '#FFF9E6',
  Yellow70: '#CC9900',
  Yellow80: '#997300',
  Orange10: '#FEF4E7',
  Orange60: '#F2930D',
  Orange70: '#C2750A',
  Red30: '#FA9EBD',
  Red10: '#FEE7EE',
  Red40: '#F76E9C',
  Red50: '#F53D7A',
  Red60: '#F20D59',
  Red70: '#C20A47',
  Red80: '#910836',
  Peach10: '#FEEDE7',
  Peach40: '#F7906E',
  Peach70: '#C2380A',
  Peach100: '300E03',
}

const RED = {
  60: '#F20D59',
}

export const MEDIA = {
  EXTRA_SMALL: 481,
  SMALL: 600,
  MEDIUM: 768,
  LARGE: 1131,
  EXTRA_LARGE: 1200,
}

export const theme = createTheme({
  ActiveBackColor: BrandColors.Violet,
  ReverseBackColor: otherColors.Violet10,
  ActiveHoverColor: otherColors.Violet50,
  ReverseHoverColor: otherColors.Violet20,
  TextButton: otherColors.Grey60,
  HoverTextButton: BrandColors.MidnightBlue,
  BorderColor: otherColors.Grey20,
  HoverBorderColor: otherColors.Grey30,
  media: {
    maxExtraSmall: addPixelUnit(MEDIA.SMALL - 1),
    minSmall: addPixelUnit(MEDIA.SMALL),
    maxSmall: addPixelUnit(MEDIA.MEDIUM - 1),
    minMedium: addPixelUnit(MEDIA.MEDIUM),
    maxMedium: addPixelUnit(MEDIA.LARGE - 1),
    minLarge: addPixelUnit(MEDIA.LARGE),
    maxLarge: addPixelUnit(MEDIA.EXTRA_LARGE - 1),
    minExtraLarge: addPixelUnit(MEDIA.EXTRA_LARGE),
  },
  error: {
    border: RED[60],
  },
  border: {
    radius: '8px',
    color: otherColors.Grey20,
  },
  colors: {
    brand: BrandColors.Violet,
    reverseBackColor: otherColors.Violet10,
    textButton: otherColors.Grey60,
    red: RED[60],
    ...otherColors,
    ...BrandColors,
  },
  default: {
    fontSize: '14px',
  },
  typography: {
    fontFamily: 'Poppins',
    allVariants: {
      fontFamily: 'Poppins',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: BrandColors.Violet,
      darker: BrandColors.MidnightBlue,
    },
    neutral: {
      main: otherColors.Grey60,
    },
    error: {
      main: '#f20d59',
    },
  },
  shape: {
    borderRadius: 8,
  },
  space: {
    zero: 0,
    us: 2,
    ms: 4,
    xms: 6,
    xxs: 8,
    xxxs: 4,
    xsmed: 12,
    xs: 16,
    sm: 24,
    med: 32,
    lg: 40,
    xl: 48,
    xxl: 56,
    xxxl: 64,
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '7px',
          borderColor: otherColors.Grey20,
        },
        input: {
          padding: '7px',
          fontWeight: 300,
          fontSize: '13.333px',
        },
        notchedOutline: {
          borderColor: otherColors.Grey20,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          fontWeight: 300,
          fontSize: '14px',
          color: otherColors.Grey50,

          '&.Mui-focused': {
            fontWeight: 500,
            fontSize: '16px',
          },
          '&.MuiInputLabel-shrink': {
            fontWeight: 500,
            fontSize: '16px',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          maxWidth: '400px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderColor: otherColors.Grey20,
          borderRadius: '8px',
        },
        input: {
          fontFamily: 'Poppins',
          fontWeight: 300,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: `1px solid ${otherColors.Grey20}`,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          borderColor: otherColors.Grey60,
          backgroundColor: otherColors.Violet10,
          boxShadow: 'none',
          color: BrandColors.Violet,
          '&:hover': {
            backgroundColor: otherColors.Violet20,
          },
        },
        outlined: {
          color: otherColors.Grey60,
          borderColor: BrandColors.Grey,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          fontWeight: 500,
          fontSize: 14,
          color: BrandColors.MidnightBlue,
        },
      },
    },
  },
})
