import { Fade, Popper } from '@mui/material'
import { useHotSettings } from '@traba/hooks'
import { MODAL_SIZE } from '@traba/react-components'
import { Shift, ShiftRequestParent, ShiftStatus } from '@traba/types'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MainLayout } from 'src/components'
import { Col, Row } from 'src/components/base'
import { Dialog } from 'src/components/base/Dialog/Dialog'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'
import { useUserContext } from 'src/context/user/UserContext'
import useMobile from 'src/hooks/useMobile'
import useCalendarFilters from 'src/hooks/useShiftFilters.hooks'
import { useShiftRequestParents } from 'src/hooks/useShiftRequestParents'
import { ShiftAndAddress, useShifts } from 'src/hooks/useShifts'
import { useNavigateToTimeSheetDailyView } from 'src/hooks/useTimesheet'
import { useUserPermissions } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { UserRolePermission } from 'src/types'
import { getCalendarMonthDisplayRange } from 'src/utils/calendarUtils'

import { CalendarFilters } from './Calendar/CalendarFilters'
import { CalendarFindWorker } from './Calendar/CalendarFindWorker'
import { CalendarMobileWidget } from './Calendar/CalendarMobileWidget'
import { CalendarRoleSelector } from './Calendar/CalendarRoleSelector'
import { CalendarShiftDetails } from './Calendar/CalendarShiftDetails'
import { CalendarShiftRequestParentSelector } from './Calendar/CalendarShiftRequestParentSelector'
import { CalendarView } from './Calendar/CalendarView'

export const CalendarScreen = () => {
  const { isMobileViewOrReactNative } = useMobile()
  const userCanViewWages = useUserPermissions([UserRolePermission.ViewPay])
  const [selectedShift, setSelectedShift] = useState<
    ShiftAndAddress | undefined
  >()

  const currDate = new Date()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [endAfter, setEndAfter] = useState<Date>(
    getCalendarMonthDisplayRange(currDate)[0],
  )
  const [startBefore, setStartBefore] = useState<Date>(
    getCalendarMonthDisplayRange(currDate)[1],
  )
  const [filters, dispatch] = useCalendarFilters({
    regionId: '',
    supervisorId: '',
    locationId: '',
    statuses: [ShiftStatus.ACTIVE, ShiftStatus.COMPLETE],
  })
  const [shiftRequestParentIdFilter, setShiftRequestParentIdFilter] = useState<
    string | undefined
  >(undefined)
  const { hotSettings } = useHotSettings()
  const { state } = useUserContext()
  const navigate = useNavigate()
  const navigateToTimeSheetDailyView = useNavigateToTimeSheetDailyView()
  const { shiftRequestParents: shiftRequestParentOptions } =
    useShiftRequestParents({
      ignoreRegionalFilter: false,
    })

  const { data: shifts, isLoading } = useShifts(
    {
      ...filters,
      startBefore,
      endAfter,
    },
    undefined,
    undefined,
    true,
  )

  //prefetch for previous month
  useShifts(
    {
      ...filters,
      startBefore: getCalendarMonthDisplayRange(endAfter)[1],
      endAfter: getCalendarMonthDisplayRange(endAfter)[0],
    },
    undefined,
    undefined,
    true,
  )
  //prefetch for next month
  useShifts(
    {
      ...filters,
      startBefore: getCalendarMonthDisplayRange(startBefore)[1],
      endAfter: getCalendarMonthDisplayRange(startBefore)[0],
    },
    undefined,
    undefined,
    true,
  )
  const { dispatch: appContextDispatch } = useAppContext()

  useEffect(() => {
    appContextDispatch({
      type: 'SET_REGIONAL_FILTER_STATUS',
      value: RegionalFilterStatus.ENABLED,
    })
    return () => {
      appContextDispatch({
        type: 'SET_REGIONAL_FILTER_STATUS',
        value: RegionalFilterStatus.HIDE,
      })
    }
  }, [appContextDispatch])

  const shiftsByShiftRequestParentId = new Map<string, Shift[]>()
  const shiftRequestParents = new Map<string, ShiftRequestParent>()
  if (shifts) {
    for (const shift of shifts) {
      if (shift.shiftRequestParentId) {
        let arr = shiftsByShiftRequestParentId.get(shift.shiftRequestParentId)
        if (!arr) {
          arr = []
          shiftsByShiftRequestParentId.set(shift.shiftRequestParentId, arr)
        }
        arr.push(shift)
      }
      if (shift.shiftRequestParent) {
        shiftRequestParents.set(
          shift.shiftRequestParent.shiftRequestParentId,
          shift.shiftRequestParent,
        )
      }
    }
  }

  return (
    <MainLayout title="Calendar">
      <Row fullWidth justifyBetween>
        <div
          style={{
            minWidth: isMobileViewOrReactNative ? '100%' : '80%',
            height: '100%',
          }}
        >
          <CalendarView
            isLoading={isLoading}
            setSelectedShift={setSelectedShift}
            setAnchorEl={setAnchorEl}
            selectedShift={selectedShift}
            shifts={shifts ?? []}
            endAfter={endAfter}
            startBefore={startBefore}
            setEndAfter={setEndAfter}
            setStartBefore={setStartBefore}
            shiftRequestParentIdFilter={shiftRequestParentIdFilter}
          />
        </div>
        {!isMobileViewOrReactNative && (
          <Col ml={theme.space.lg} mt={150} width={250}>
            {hotSettings?.recurringSchedulesEnabledCompanies?.includes(
              state.userProfile?.companyId || '',
            ) &&
            hotSettings?.recurringSchedulesEnabled &&
            shiftRequestParents.size > 0 ? (
              <CalendarShiftRequestParentSelector
                shiftRequestParents={shiftRequestParents}
                shiftRequestParentIdFilter={shiftRequestParentIdFilter}
                setShiftRequestParentIdFilter={setShiftRequestParentIdFilter}
              />
            ) : undefined}
            <CalendarRoleSelector shifts={shifts} isLoading={isLoading} />
            <CalendarFindWorker dispatch={dispatch} />
            <CalendarFilters
              dispatch={dispatch}
              filters={filters}
              shiftRequestParents={shiftRequestParentOptions || []}
            />
          </Col>
        )}
      </Row>
      {selectedShift && isMobileViewOrReactNative && (
        <Dialog
          open={!!selectedShift}
          onClose={() => setSelectedShift(undefined)}
          style={{ display: 'flex', flexDirection: 'column' }}
          size={MODAL_SIZE.LARGE}
          dialogTitle={selectedShift.shiftRole}
          onConfirm={() => {
            if (new Date() > selectedShift.endTime) {
              navigateToTimeSheetDailyView(
                selectedShift.shiftId,
                selectedShift.businessStartTime ?? selectedShift.startTime,
              )
            } else {
              navigate(`/calendar/${selectedShift.shiftId}`)
            }
            setSelectedShift(undefined)
          }}
          onConfirmCTA="Go to shift"
        >
          <CalendarShiftDetails
            isMobileView={isMobileViewOrReactNative}
            shift={selectedShift}
            setSelectedShift={setSelectedShift}
            userCanViewWages={userCanViewWages}
          />
        </Dialog>
      )}
      {selectedShift && !isMobileViewOrReactNative && (
        <Popper
          anchorEl={anchorEl}
          open={!!anchorEl}
          style={{
            borderRadius: 10,
            boxShadow: `0px 0px 10px 0px ${theme.colors.Violet30}`,
            backgroundColor: theme.colors.White,
            overflow: selectedShift ? 'hidden' : 'auto',
            paddingRight: theme.space.xxs,
            paddingLeft: theme.space.xxs,
            paddingTop: theme.space.xs,
            zIndex: 100,
          }}
          placement="auto"
          transition
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, theme.space.xs],
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} in={!!selectedShift} timeout={300}>
              {/* NOTE: You have to have a div directly under Fade, it's a material ui bug */}
              <div>
                <CalendarShiftDetails
                  isMobileView={isMobileViewOrReactNative}
                  shift={selectedShift}
                  setSelectedShift={setSelectedShift}
                  userCanViewWages={userCanViewWages}
                />
              </div>
            </Fade>
          )}
        </Popper>
      )}
      {isMobileViewOrReactNative && (
        <CalendarMobileWidget
          shifts={shifts}
          isLoading={isLoading}
          dispatch={dispatch}
          filters={filters}
          isFromCalendarScreen={true}
        />
      )}
    </MainLayout>
  )
}
