import styled, { keyframes } from 'styled-components'

const expandAnimation = keyframes`
  0% { max-height: 0; overflow: hidden; }
  100% { max-height: 1000px; overflow: visible}
`

export const SlideOut = styled.div`
  /* padding: ${({ theme }) => theme.space.sm}px; */
  box-shadow:
    inset 0px 10px 8px -8px rgba(0, 0, 0, 0.07),
    inset 0px -10px 8px -8px rgba(0, 0, 0, 0.07);
  animation: ${expandAnimation} 1s ease;
`
