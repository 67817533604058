import { ShiftRequestParentWithShiftRequest } from '@traba/types'

import { useBookShiftsScreen } from '../BookShifts/BookShiftsScreen.hooks'
import { BookShiftsConfirmContentShiftDataModel } from '../BookShifts/steps/BookShiftsConfirmContentShiftDataModel'
import AddShiftToSchedule from './steps/AddShiftToSchedule'
import SelectAddToExistingScheduleOptions from './steps/SelectAddToExistingScheduleOptions'

type CreateShiftRequestParent = Omit<
  ShiftRequestParentWithShiftRequest,
  'shiftsRequestParentId' | 'companyId' | 'createdAt'
>

interface CreateShiftRequestParentMetadata {
  scheduleExpanded: boolean
}

type Step = {
  title: string
  Component: any
  validate?: (
    data: CreateShiftRequestParent,
    metadata: CreateShiftRequestParentMetadata,
  ) =>
    | {
        message: string
        title: string
      }
    | undefined
  hideInSideBar?: boolean
  type: StepType
}

enum StepType {
  SELECT_OPTIONS = 'SELECT_OPTIONS',
  SCHEDULE = 'SCHEDULE',
  CONFIRM = 'CONFIRM',
}

export const useAddToExistingSchedulesScreen = ({
  shiftRequestParent,
}: {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
}) => {
  const {
    shiftRequest,
    setShiftRequest,
    shiftRequestMetadata,
    setShiftRequestMetadata,
    onNavigate,
    onContinue,
    onBook,
    onPrevious,
    addNewRole,
    removeRole,
    updateRoleInfoForCreateShiftRequest,
    recurringRoles,
    setRecurringRoles,
    completedThroughStep,
    activeStep,
    scrollContainer,
    getWorkerById,
  } = useBookShiftsScreen({
    shiftRequestTemplate: shiftRequestParent.shiftRequests[0],
    ignoreRecurrence: true,
    isEdit: true,
    shiftRequestsInParent: shiftRequestParent.shiftRequests,
  })

  const steps: Step[] = [
    {
      title: 'Select Options',
      Component: SelectAddToExistingScheduleOptions,
      type: StepType.SELECT_OPTIONS,
    },
    {
      title: 'Add to Schedule',
      Component: AddShiftToSchedule,
      type: StepType.SCHEDULE,
    },
    {
      title: 'Confirm',
      Component: BookShiftsConfirmContentShiftDataModel,
      type: StepType.CONFIRM,
    },
  ]

  return {
    steps,
    activeStep,
    completedThroughStep,
    scrollContainer,
    onContinue,
    onPrevious,
    onNavigate,
    onBook,
    shiftRequest,
    setShiftRequest,
    shiftRequestMetadata,
    setShiftRequestMetadata,
    recurringRoles,
    setRecurringRoles,
    addNewRole,
    updateRoleInfoForCreateShiftRequest,
    removeRole,
    getWorkerById,
  }
}
