import { Tooltip } from '@mui/material'
import { SvgIcon } from '@traba/react-components'
import { IconName } from '@traba/types'
import { WorkerProfile } from '@traba/types'
import { RoleAttributeCategory } from '@traba/types'
import React from 'react'
import { theme, otherColors } from 'src/libs/theme'
import Row from '../Row'
import { Text } from '../Text'

export interface WorkerBadgeProps {
  title: string
  iconName: IconName
  popup?: string
  onClick?: () => void
}

export function WorkerBadge({
  iconName,
  title,
  popup,
  onClick,
}: WorkerBadgeProps) {
  return (
    <Tooltip title={popup} placement="top">
      <div>
        <Row
          onClick={onClick}
          style={{
            borderRadius: 10000,
            background: `linear-gradient(to right, ${otherColors.Violet10}, ${otherColors.Violet30})`,
            paddingLeft: theme.space.xs,
            paddingRight: theme.space.xs,
            marginRight: theme.space.xxs,
            alignItems: 'center',
            height: 27,
          }}
        >
          <SvgIcon
            name={iconName}
            size={18}
            style={{
              marginRight: theme.space.xxxs,
            }}
          />
          <Text variant="badge">{title}</Text>
        </Row>
      </div>
    </Tooltip>
  )
}

export function getWorkerBadges({
  profile,
  completedShifts = 0,
  onTimeRate = 0,
  favoriteCount = 0,
  perfectShiftsStreak = 0,
}: {
  profile: WorkerProfile[]
  completedShifts?: number
  onTimeRate?: number
  perfectShiftsStreak?: number
  favoriteCount?: number
}) {
  // Experience badge
  const badges: { title: string; iconName: IconName; popup?: string }[] = []
  let title = ''
  const titles = [
    { threshold: 2, title: 'Emerging talent' },
    { threshold: 5, title: 'Up-and-comer' },
    { threshold: 10, title: 'Rising Star' },
    { threshold: 25, title: 'Top' },
  ]
  title = titles.find((t) => completedShifts < t.threshold)?.title || 'Pro'
  badges.push({
    title: title,
    iconName: 'award',
  })

  // Skills Badge
  const skills = profile.filter(
    (skill) => skill.category !== RoleAttributeCategory.Gear,
  ).length
  const skillLevels = [
    { threshold: 12, title: 'Seasoned' },
    { threshold: 8, title: 'Highly skilled' },
    { threshold: 4, title: 'Proficient' },
  ]
  const skillLevel = skillLevels.find((level) => skills > level.threshold)
  if (skillLevel) {
    badges.push({
      title: skillLevel.title,
      iconName: 'award' as IconName,
      popup: 'Highly skilled worker',
    })
  }

  // OTA Rate
  if (onTimeRate && onTimeRate > 0.75) {
    badges.push({
      title: 'Early bird',
      iconName: 'clockPurple' as IconName,
      popup: 'Consistently arrives on time for their shifts',
    })
  }

  // On a Streak of perfect shifts
  if (perfectShiftsStreak > 2) {
    badges.push({
      title: 'On a streak',
      iconName: 'lightning' as IconName,
      popup:
        'Has had perfect shifts with punctual arrivals, exceptional shift performance, no incidents or cancellations',
    })
  }

  // Favorites
  if (favoriteCount && favoriteCount > 2) {
    badges.push({
      title: 'Popular Pick',
      iconName: 'heartHollow' as IconName,
      popup: 'Has exceptional shift performance and behavior',
    })
  }

  return badges
}
