import { useHotSettings } from '@traba/hooks'
import { LoadingSpinner } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Money,
  ShiftWithWorkerShiftsAndCharges,
  WorkerShift,
  WorkerWithWorkerShiftAndCharges,
} from '@traba/types'
import { useMemo } from 'react'
import { useCompany } from 'src/hooks/useCompany'
import useMobile from 'src/hooks/useMobile'
import { useUserPermissions } from 'src/hooks/useUser'
import { TimesheetsGroupBy } from 'src/screens/TimeSheets/types'
import { UserRolePermission } from 'src/types'
import { EmptyPlaceholderTile } from '../base'
import { EmptyDataLocationSubtitle } from '../base/RegionalFilterSelect/EmptyDataLocationSubtitle'
import { Table, Td, Tr } from '../Table/Table'
import { TimeSheetsTableWorkerOrShiftSection } from './components/TimeSheetsTableWorkerOrShiftSection'
import { TimeSheetsTableBaseProps } from './types'
import { parseTimesheetData } from './utils'

interface TimeSheetsTableProps extends TimeSheetsTableBaseProps {
  data:
    | WorkerWithWorkerShiftAndCharges[]
    | ShiftWithWorkerShiftsAndCharges[]
    | undefined
  workerShiftChargeMap?: Record<
    string,
    { charge: Money | undefined; isLoading: boolean }
  >
  isInShiftSummary?: boolean
  editedWorkerShifts?: Record<string, Partial<WorkerShift>>
}

export const TimeSheetsTable = (props: TimeSheetsTableProps) => {
  const {
    data,
    groupBy,
    isLoading,
    editMode,
    hideHeaderRow,
    hideEmptyState,
    stickyApprovalsColumn,
    editedWorkerShifts,
  } = props
  const isGroupedByShift = groupBy === TimesheetsGroupBy.SHIFT

  const userCanViewWages = useUserPermissions([UserRolePermission.ViewPay])
  const userCanManageTimesheets = useUserPermissions([
    UserRolePermission.ManageTimesheets,
  ])
  const userCanManagePaymentSettings = useUserPermissions([
    UserRolePermission.ManagePaymentSettings,
  ])
  const { isMobileViewOrReactNative } = useMobile()
  const { hotSettings } = useHotSettings()
  const { company } = useCompany()
  const enableWorkerSegments =
    hotSettings?.costCenterEnabledByCompanyIds?.includes(
      company?.companyId ?? '',
    )

  const headers = [
    ...(!isGroupedByShift
      ? [
          ...(hideHeaderRow
            ? ['Worker', 'Shift', 'Date']
            : ['Shift', 'Date', 'Location']),
        ]
      : ['Worker']),
    ...(enableWorkerSegments ? ['Cost Center'] : []),
    'Clock in',
    'Clock out',
    'Break Time',
    'Total Hrs',
    ...(userCanViewWages ? ['Worker Charge'] : []),
    ...(userCanManageTimesheets && !editMode ? ['Approval'] : []),
    ...(isGroupedByShift && !editMode ? ['Actions'] : []),
  ]

  const memoizedParsedData:
    | WorkerWithWorkerShiftAndCharges[]
    | ShiftWithWorkerShiftsAndCharges[] = useMemo(
    () => parseTimesheetData(data, editedWorkerShifts),
    [data, editedWorkerShifts],
  )

  const tableContent = isLoading ? (
    <LoadingSpinner />
  ) : memoizedParsedData?.length ? (
    memoizedParsedData.map((item, index) => (
      <TimeSheetsTableWorkerOrShiftSection
        key={`${isGroupedByShift ? 'shift' : 'worker'}_${!isGroupedByShift ? (item as WorkerWithWorkerShiftAndCharges).workerId : item.id}_${index}`}
        item={item}
        userCanManageTimesheets={userCanManageTimesheets}
        userCanViewWages={userCanViewWages}
        userCanManagePaymentSettings={userCanManagePaymentSettings}
        numberOfColumns={headers.length}
        enableWorkerSegments={enableWorkerSegments}
        {...props}
      />
    ))
  ) : !hideEmptyState ? (
    <Tr>
      <Td colSpan={headers.length}>
        <EmptyPlaceholderTile
          iconName="info"
          title={
            'There are no timesheets found with the current filters applied.'
          }
          subtitle={
            <EmptyDataLocationSubtitle
              isMobileView={false}
              extraText="to view timesheets for other locations."
            />
          }
          style={{ border: 'none' }}
        />
      </Td>
    </Tr>
  ) : null

  return (
    <div style={{ overflowX: !isMobileViewOrReactNative ? 'auto' : undefined }}>
      {!isMobileViewOrReactNative ? (
        <Table
          headers={headers}
          style={{
            border: !hideHeaderRow
              ? `1px solid ${theme.colors.Grey20}`
              : undefined,
            position: 'relative',
            borderCollapse: 'collapse',
          }}
          headerStyle={{
            backgroundColor: theme.colors.Grey20,
            borderRadius: 0,
          }}
          stickyLastColumn={stickyApprovalsColumn}
        >
          {tableContent}
        </Table>
      ) : (
        <div style={{ border: `1px solid ${theme.colors.Grey20}` }}>
          {tableContent}
        </div>
      )}
    </div>
  )
}
