import { theme } from '@traba/theme'
import { useMemo, useState } from 'react'
import { DateObject, MapDaysProps } from 'react-multi-date-picker'
import { ThemedMultiDatePicker } from './MultiDatePicker.styles'

interface MultiDatePickerProps {
  minDate?: Date
  maxDate?: Date
  selectedDates: Date[] | null
  onSelectDates: (dates: Date[]) => void
  disabled?: boolean
  multiple?: boolean
  mapDays?: (props: MapDaysProps<boolean, boolean>) => void
  calendarPosition?: 'top' | 'bottom' | 'top-start'
}

const convertToDateObject = (date: Date): DateObject => {
  return new DateObject(date)
}

export function MultiDatePicker(props: MultiDatePickerProps) {
  const {
    minDate,
    maxDate,
    selectedDates,
    onSelectDates,
    multiple = true,
    mapDays,
    calendarPosition,
  } = props
  const [lastSelectedDate, setLastSelectedDate] = useState<DateObject>(
    new DateObject(selectedDates ? selectedDates[0] : new Date()),
  )
  const selected = useMemo(() => {
    return selectedDates === null || selectedDates.length === 0
      ? []
      : selectedDates.map((sd) => convertToDateObject(sd))
  }, [selectedDates])

  const formatDate = (datesInString?: string) => {
    if (!datesInString || typeof datesInString !== 'string') {
      return ''
    }
    const dates = datesInString.split(',')
    const sortedDates = dates.sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime()
    })

    return sortedDates
      .map((d) => {
        return new DateObject(d.replace(' ', '')).format('MM/DD/YYYY')
      })
      .join(', ')
  }

  return (
    <ThemedMultiDatePicker
      mapDays={mapDays}
      disabled={props.disabled}
      style={{ height: 40, width: '100%', letterSpacing: 1 }}
      value={selected}
      weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
      multiple={multiple}
      sort
      render={(datesInString: string, openCalendar) => {
        return (
          <input
            readOnly
            value={formatDate(datesInString)}
            onFocus={openCalendar}
            style={{
              padding: '10px',
              width: '300px',
              color: theme.colors.MidnightBlue,
              letterSpacing: '2px',
              border: `1px solid ${theme.colors.Grey30}`,
              borderRadius: theme.space.xxs,
            }}
          />
        )
      }}
      calendarPosition={calendarPosition}
      minDate={minDate}
      maxDate={maxDate}
      onChange={(selectedDates: DateObject[]) => {
        if (multiple) {
          if (selectedDates.length === 1) {
            setLastSelectedDate(selectedDates[0])
          }

          const selectedDatesInDate = selectedDates.map((sd) => sd.toDate())
          onSelectDates(
            selectedDatesInDate.length !== 0
              ? selectedDatesInDate
              : [lastSelectedDate.toDate()],
          )
        } else {
          // Single date picker should have only one date diff every time
          const diff = selectedDates.filter((sd) => !selected.includes(sd))
          if (diff.length > 1) {
            return
          }
          if (diff.length === 0) {
            return onSelectDates([])
          }
          onSelectDates([diff[0].toDate()])
        }
      }}
    />
  )
}
