import {
  RoleAttribute,
  RoleAttributeCategory,
  ShiftAttribute,
} from '@traba/types'

export const REVIEW_CATEGORIES = [
  RoleAttributeCategory.Lifting,
  RoleAttributeCategory.Forklift,
  RoleAttributeCategory.Responsibilities,
  RoleAttributeCategory.Languages,
  RoleAttributeCategory.Equipment,
]

export const REVIEW_CATEGORY_ORDER = Object.fromEntries(
  REVIEW_CATEGORIES.map((c, i) => [c, i]),
)

export const REVIEW_CATEGORY_SET = new Set(REVIEW_CATEGORIES)

function isRoleAttribute(
  roleAttribute?: RoleAttribute,
): roleAttribute is RoleAttribute {
  return !!roleAttribute
}

// This function filters and sorts the shift's required attributes
// based on the ones we do / don't want to solicit feedback on, and
// the priority of ones we _do_ want feedback on.
export function getReviewAttributes(
  requiredAttributes?: ShiftAttribute[],
  roleAttributes?: RoleAttribute[],
) {
  if (!requiredAttributes || !roleAttributes) {
    return []
  }
  return requiredAttributes
    .map((ra) => roleAttributes.find((a) => a.type === ra.type))
    .filter(isRoleAttribute)
    .filter((ra) => REVIEW_CATEGORY_SET.has(ra.category))
    .sort(
      (a, b) =>
        REVIEW_CATEGORY_ORDER[a.category] - REVIEW_CATEGORY_ORDER[b.category],
    )
}
