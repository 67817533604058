import { Card } from '@traba/react-components'
import styled, { css } from 'styled-components'

export const CreditCardTileContainer = styled(Card)`
  margin-top: 30px;

  ${({ theme }) => {
    return css`
      @media only screen and (min-width: ${theme.media.minMedium}) {
        min-width: 500px;
      }
    `
  }}
`
