import { Menu, MenuItem, Tooltip } from '@mui/material'
import { theme } from '@traba/theme'
import { WorkerShiftWithWorkerDetails } from '@traba/types'
import { useState } from 'react'
import { Text } from '../base'
import { WorkerAction } from '../WorkersManagementMenu/WorkersManagementMenu'
import ClockInAndOutTooltip from './ClockInAndOutTooltip'

interface EmptyStateProps {
  onChooseTime: (newTime: Date) => void
  timezone: string
  cta: string
  key: string
  defaultTime: Date
  startDate: Date
  endDate: Date
  workerShift: WorkerShiftWithWorkerDetails
  workerAction: WorkerAction
}

export function EmptyState(props: EmptyStateProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = !!anchorEl
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const [showTimePicker, setShowTimePicker] = useState(false)

  return (
    <div>
      <Tooltip
        key={props.workerShift.workerId}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onClose={() => {
          showTimePicker && setShowTimePicker(false)
        }}
        open={showTimePicker}
        placement="bottom-start"
        sx={{ m: 1 }}
        PopperProps={{
          disablePortal: false, // display outside of container
        }}
        componentsProps={{
          tooltip: {
            style: {
              maxWidth: 500,
              minWidth: 360,
              background: theme.colors.White,
              border: `1px solid ${theme.colors.Grey20}`,
              padding: 0,
            },
          },
        }}
        title={
          <div>
            <ClockInAndOutTooltip
              onConfirm={(newTime) => {
                newTime && props.onChooseTime(newTime)
                setShowTimePicker(false)
              }}
              handleClose={() => {
                setShowTimePicker(false)
              }}
              startDate={props.startDate}
              endDate={props.endDate}
              defaultDate={props.defaultTime}
              timezone={props.timezone}
              workerShift={props.workerShift}
              workerAction={props.workerAction}
            />
          </div>
        }
      >
        <div onClick={handleClick}>
          <Text variant="link">{props.cta} +</Text>
        </div>
      </Tooltip>
      <Menu
        id={`clock-in-out-menu-${props.key}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `clock-in-out-menu-${props.key}`,
        }}
      >
        <MenuItem
          onClick={(e) => {
            props.onChooseTime(new Date())
            handleClose(e)
          }}
        >
          {props.cta} now
        </MenuItem>

        <MenuItem
          onClick={(e) => {
            setShowTimePicker(true)
            handleClose(e)
          }}
        >
          Choose time
        </MenuItem>
      </Menu>
    </div>
  )
}
