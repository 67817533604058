import React from 'react'
import { useRoles } from 'src/hooks/useRoles'

import { RolesProfileUI } from './RolesProfile.ui'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook

export const RolesProfile = () => {
  const { roles } = useRoles()
  return <RolesProfileUI roles={roles} />
}
