import Rating from '@mui/material/Rating/Rating'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { useReward } from 'react-rewards'
import Row from '../Row'
import { SvgIcon } from '../SvgIcon'
import { Text } from '../Text'

export const StarRating = (props: {
  value: number | null
  onValueChange?: (newValue: number | null) => void
  id: string
  maxStars?: number
  readOnly?: boolean
  precise?: boolean
  size?: number
  hideLabel?: boolean
  showValue?: boolean
}) => {
  const maxStars = props.maxStars || 5
  const [hoveredValue, setHoveredValue] = useState<number>(-1)
  const { reward } = useReward(props.id, 'confetti', {
    position: 'absolute',
  })
  const handleValueChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number | null,
  ) => {
    if (newValue === null || !props.onValueChange) {
      return // don't allow null values
    }
    props.onValueChange(newValue)
    setHoveredValue(-1)
    // If the new rating is 5, show confetti
    if (newValue === maxStars) {
      reward()
    }
  }

  const handleHoveredValueChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    if (!props.onValueChange) {
      return
    }
    setHoveredValue(newValue)
  }

  const isHovering = hoveredValue > -1
  const size = props.size || 32

  return (
    <Row alignCenter>
      {props.value !== undefined && props.value !== null && props.showValue && (
        <Text style={{ marginRight: theme.space.xxxs }} variant="body1">
          {props.value.toFixed(1)}
        </Text>
      )}
      <Rating
        value={props.value}
        onChangeActive={handleHoveredValueChange}
        onChange={handleValueChange}
        max={maxStars}
        getLabelText={(value) => `${value} Star${value !== 1 ? 's' : ''}`}
        emptyIcon={
          <SvgIcon
            name="star"
            color={props.precise ? theme.colors.Violet : theme.colors.Grey50}
            style={{ marginRight: theme.space.xxxs }}
            size={size}
            strokeWidth={0.5}
          />
        }
        icon={
          <SvgIcon
            name="star"
            color={theme.colors.Violet}
            fillColor={theme.colors.Violet30}
            style={{ marginRight: theme.space.xxxs }}
            size={size}
            strokeWidth={0.5}
          />
        }
        readOnly={props.readOnly}
        precision={props.precise ? 0.1 : undefined}
      />
      <span
        id={props.id}
        style={{
          zIndex: 9999,
        }}
      />
      {!props.hideLabel ? (
        <Text
          variant="h6"
          ml={theme.space.xxs}
          color={isHovering ? theme.colors.Grey50 : theme.colors.MidnightBlue}
        >
          {getLabelForValue(isHovering ? hoveredValue : props.value)}
        </Text>
      ) : undefined}
    </Row>
  )
}

const getLabelForValue = (value: number | null) => {
  switch (value) {
    case 0:
      return 'No Rating'
    case 1:
      return 'Poor'
    case 2:
      return 'Fair'
    case 3:
      return 'Good'
    case 4:
      return 'Very Good'
    case 5:
      return 'Excellent'
    default:
      return ''
  }
}
