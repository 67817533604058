import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'

import { Col, Row, SvgIcon, Text } from '../base'

export const WorkersRightCol = ({
  city,
  isFromBasicDetailOrEmailVerification,
}: {
  city?: string
  isFromBasicDetailOrEmailVerification?: boolean
}) => {
  const { isMobileViewOrReactNative } = useMobile()

  return isFromBasicDetailOrEmailVerification ? (
    <Col style={{ width: '80%' }}>
      <Col
        style={{
          borderRadius: '8px',
          border: '1.5px solid var(--violet-40, #a95cd9)',
          background: isMobileViewOrReactNative ? 'none' : theme.colors.White,
          padding: theme.space.sm,
        }}
      >
        <Row alignCenter>
          <SvgIcon name="locationPin" size={24} />
          <Text
            ml={theme.space.xxs}
            variant="h4"
            center
            color={theme.colors.Violet70}
            style={{
              textAlign: 'left',
            }}
          >
            {city
              ? `5k+ workers in the ${city} area`
              : '5k+ workers in your area'}
          </Text>
        </Row>
      </Col>
      <img
        src={'/img/signup-workers.png'}
        alt="Messaging Center"
        style={{
          width: '100%',
          marginTop: theme.space.lg,
          alignSelf: 'center',
        }}
      />
    </Col>
  ) : (
    <img
      src={'/img/signup-points.png'}
      alt="Messaging Center"
      style={{
        width: '80%',
        marginTop: theme.space.lg,
        alignSelf: 'center',
      }}
    />
  )
}
