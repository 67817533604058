import { WorkerShift } from '@traba/types'
import React, { memo } from 'react'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { DesktopShiftTile } from './DesktopShiftTile.ui'
import { DesktopShiftTileWithWorkersList } from './DesktopShiftTileWithWorkersList'
import { MobileShiftTile } from './MobileShiftTile'
import { MobileShiftTileWithWorkersList } from './MobileShiftTileWithWorkersList'
import { ShiftTileProps, useShiftTile } from './ShiftTile.hooks'

export const ShiftTile: React.FC<
  ShiftTileProps & { shift?: ShiftAndAddress; workerShift?: WorkerShift }
> = memo((props) => {
  const { shift, workerShift, ...rest } = props
  const logic = useShiftTile(rest, shift, workerShift)

  return props.showDesktopCard ? (
    props.showWorkerShifts ? (
      shift && (
        <DesktopShiftTileWithWorkersList {...logic} {...rest} {...shift} />
      )
    ) : (
      <>
        {shift && <DesktopShiftTile {...logic} {...rest} shift={shift} />}
        {workerShift && (
          <DesktopShiftTile {...logic} {...rest} workerShift={workerShift} />
        )}
      </>
    )
  ) : props.showWorkerShifts ? (
    shift && <MobileShiftTileWithWorkersList {...logic} {...rest} {...shift} />
  ) : (
    <>
      {shift && <MobileShiftTile {...logic} {...rest} shift={shift} />}
      {workerShift && (
        <MobileShiftTile {...logic} {...rest} workerShift={workerShift} />
      )}
    </>
  )
})
