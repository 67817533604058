import { CardTile } from '@traba/react-components'
import { DotMenu } from '@traba/react-components'
import { Roster } from '@traba/types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import useMobile from 'src/hooks/useMobile'
import { RosterWithWorkers } from 'src/hooks/useRoster'
import { theme } from 'src/libs/theme'

import { Button, ButtonVariant, Row, SvgIcon, Text } from '../base'
import { Table, Td, Tr } from '../Table/Table'
import { WorkerLinkText } from '../WorkerDetails/WorkerLinkText'

type RostersTableProps = {
  data: Roster[]
  onClickNewRoster: () => void
  onClickEditRoster: React.Dispatch<
    React.SetStateAction<Roster | RosterWithWorkers | undefined>
  >
  onClickDeleteRoster: React.Dispatch<React.SetStateAction<Roster | undefined>>
}

// TODO(polyphilz): Update this to not use <Tr> / <Td> so you can style more
// easily with flex
export const RostersTable = (props: RostersTableProps) => {
  const { data, onClickNewRoster, onClickEditRoster, onClickDeleteRoster } =
    props
  const { isMobileViewOrReactNative } = useMobile()
  const navigate = useNavigate()

  const cardTileSize = isMobileViewOrReactNative ? 28 : 56
  const RostersRow = (props: Roster) => {
    const { id, rosterName, workers } = props
    return (
      <Tr key={id}>
        <Td
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CardTile
            size={`${cardTileSize}px`}
            style={{ minWidth: cardTileSize }}
          >
            <SvgIcon
              name="twoUser"
              size={theme.space.sm}
              color={theme.colors.Grey50}
            />
          </CardTile>
          <WorkerLinkText
            color={theme.colors.MidnightBlue}
            variant={isMobileViewOrReactNative ? 'h6' : 'h5'}
            style={{
              marginLeft: theme.space.sm,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            onClick={() => navigate(`/roster/${id}`)}
          >
            {rosterName}
          </WorkerLinkText>
        </Td>
        <Td
          style={{ width: isMobileViewOrReactNative ? 'auto' : '40%' }}
        >{`${workers.length} workers`}</Td>
        <Td style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DotMenu
            type="rosters"
            dotMenuKey={id}
            menuItems={[
              {
                title: 'Edit Roster',
                onClick: () => {
                  window?.analytics.track(`User Clicked Edit Roster`, {
                    rosterId: id,
                  })
                  onClickEditRoster(props)
                },
              },
              // { title: 'Book a shift', onClick: () => null },
              {
                title: 'Delete Roster',
                onClick: () => {
                  window?.analytics.track(`User Clicked Delete Roster`, {
                    rosterId: id,
                  })
                  onClickDeleteRoster(props)
                },
              },
            ]}
          />
        </Td>
      </Tr>
    )
  }

  return (
    <>
      <Row justifyBetween alignCenter mt={theme.space.xs}>
        <Text variant="h5">{`Rosters (${data.length})`}</Text>
        <Button
          variant={ButtonVariant.FILLED}
          trackingEvent={{ message: 'User Clicked New Roster' }}
          onClick={() => onClickNewRoster()}
        >
          New Roster
        </Button>
      </Row>

      <Table>
        {data.map((rosterDetails: Roster) => (
          <RostersRow key={rosterDetails.id} {...rosterDetails} />
        ))}
      </Table>
    </>
  )
}

export default RostersTable
