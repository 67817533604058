import { compact } from 'lodash'
import { useState } from 'react'
import { useMembers } from 'src/hooks/useMembers'
import { theme } from 'src/libs/theme'
import { validateEmail, validateUniqueEmails } from 'src/utils/stringUtils'

import { Col, Row } from '../base'
import { Autocomplete } from '../base/Autocomplete/Autocomplete'
import { Dialog } from '../base/Dialog/Dialog'

type BillingContactsModalProps = React.HTMLProps<HTMLElement> & {
  isOpen: boolean
  setShowModal: (value: boolean) => void
  onAddNewEmails: (emails: string[]) => void
  billingEmails: string[]
}
export const BillingContactsModal: React.FC<BillingContactsModalProps> = (
  props,
) => {
  const [emailsToAdd, setEmailsToAdd] = useState<string[]>([])

  const { members } = useMembers()

  const onAdd = () => {
    props.onAddNewEmails(emailsToAdd)
    props.setShowModal(false)
    setEmailsToAdd([])
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={() => props.setShowModal(false)}
      dialogTitle={'Add billing contacts'}
      onConfirmCTA={'Add'}
      onConfirm={onAdd}
      confirmDisabled={emailsToAdd.length === 0}
    >
      <Row style={{ marginBottom: theme.space.xs }}>
        <Col style={{ width: '100%' }}>
          <Autocomplete
            label="Email addresses"
            value={emailsToAdd}
            options={compact(
              members
                .filter(
                  // Only show additional contact users (not users with role accounts) and emails which are not already added as billing contacts
                  (m) =>
                    !m.role && !props.billingEmails.includes(m.email || ''),
                )
                .map((m) => m.email),
            )}
            onChangeValues={(_, value) => {
              setEmailsToAdd(validateUniqueEmails(value))
            }}
            errorMessage="Email address is invalid"
            validateInput={validateEmail}
          />
        </Col>
      </Row>
    </Dialog>
  )
}
