import { createContext, Dispatch, SetStateAction, useState } from 'react'

type ContextValue = {
  timezoneOverride: string | null
  setSettings: Dispatch<SetStateAction<ContextValue>>
}

const defaultContextValue: ContextValue = {
  timezoneOverride: null,
  setSettings: () => {
    // do nothing
  },
}
export const SettingsContext = createContext(defaultContextValue)

export function SettingsContextProvider({
  children,
}: {
  children: JSX.Element
}) {
  const [settings, setSettings] = useState(defaultContextValue)
  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        setSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}
