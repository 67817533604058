// usePrompt is still unstable, but it's the only way to do this,
// more info here: https://github.com/remix-run/react-router/issues/8139#issuecomment-1396078490
import { unstable_usePrompt as usePrompt } from 'react-router-dom'

interface NavigationGuardProps {
  when: boolean
  message?: string
}

function useNavigationGuard({ when, message }: NavigationGuardProps): void {
  return usePrompt({
    when,
    message:
      message || 'You have unsaved changes, do you really want to leave?',
  })
}

export default useNavigationGuard
