import { LoadingSpinner } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { theme } from '@traba/theme'
import { endOfDay, startOfDay } from 'date-fns'
import { useState } from 'react'
import { Icon, Row, Text } from 'src/components/base'
import { AppScreen } from 'src/hooks/useMobile'
import {
  useTimeSheetMutations,
  useWorkerShiftsForTimesheetWithPagination,
} from 'src/hooks/useTimesheet'
import { TimesheetsPage, TimesheetsGroupBy } from 'src/screens/TimeSheets/types'
import { ShiftTileWorkerListContainer } from '../ShiftTile/ShiftTile.styles'
import { TimeSheetsTable } from '../TimeSheetsTable/TimeSheetsTable'
import { TimeSheetsTableLoggingSource } from '../TimeSheetsTable/types'
interface Props {
  locationName: string
  activeLocationId: string
  numWorkerShifts: number
  tabIndex: number
  timePeriod: [Date | null, Date | null]
  refetchSummary: () => void
  isLastTile: boolean
}

export const MobileTimeSheetApprovalLocationTile = (props: Props) => {
  const {
    locationName,
    numWorkerShifts,
    tabIndex,
    timePeriod,
    activeLocationId,
    refetchSummary,
    isLastTile,
  } = props
  const [isExpanded, setIsExpanded] = useState(true)
  const isPendingTab = tabIndex === 0

  const query = {
    activeLocationIds: [activeLocationId],
    after: timePeriod[0] ? startOfDay(timePeriod[0]) : undefined,
    before: timePeriod[1] ? endOfDay(timePeriod[1]) : undefined,
    groupBy: TimesheetsGroupBy.WORKER,
    hideApproved: isPendingTab,
    hideUnapproved: !isPendingTab,
    enabled: isExpanded,
  }

  const {
    data: response,
    refetch: refetchWorkerShifts,
    isLoading: isLoadingWorkerShifts,
  } = useWorkerShiftsForTimesheetWithPagination(query, {
    limit: numWorkerShifts,
    startAt: 0,
  })

  const data = response?.result
  const { approveWorkerShifts, unapproveWorkerShifts } = useTimeSheetMutations()
  const refetch = () => {
    refetchWorkerShifts()
    refetchSummary()
  }

  return (
    <>
      <Row
        py={theme.space.xs}
        px={theme.space.xs}
        justifyBetween
        onClick={() => {
          window.analytics.track('Timesheets Approval Location Tile clicked', {
            expanded: !isExpanded,
            screen: AppScreen.MOBILE,
            activeLocationId,
            page: TimesheetsPage.APPROVAL,
          })
          setIsExpanded((isExpanded) => !isExpanded)
        }}
        style={{
          cursor: 'pointer',
          backgroundColor: theme.colors.Grey10,
          borderColor: theme.colors.Red10,
          borderTop: `1px solid ${theme.colors.Grey30}`,
          borderBottom:
            isLastTile || isExpanded
              ? `1px solid ${theme.colors.Grey30}`
              : 'none',
          width: '100%',
        }}
        wrap
      >
        <Row alignCenter>
          <Icon name="location" width={20} height={20} />
          <Text variant="h6" ml={theme.space.xxxs}>
            {locationName}
          </Text>
        </Row>
        <Row alignCenter>
          <Icon name="two_user" width={20} height={20} />
          <Text variant="body3" ml={theme.space.xxxs} mr={theme.space.xxs}>
            {`${numWorkerShifts} worker${makePlural(numWorkerShifts)} time${makePlural(numWorkerShifts)} ${isPendingTab ? 'unapproved' : 'approved'}`}
          </Text>
          <Icon
            name={isExpanded ? 'chevronUp' : 'chevronDown'}
            width={16}
            height={16}
          />
        </Row>
      </Row>
      {isExpanded && (!!data?.length || isLoadingWorkerShifts) && (
        <ShiftTileWorkerListContainer>
          {isLoadingWorkerShifts ? (
            <LoadingSpinner />
          ) : (
            <TimeSheetsTable
              groupBy={TimesheetsGroupBy.WORKER}
              data={data}
              refetchData={refetch}
              approveWorkerShifts={approveWorkerShifts}
              unapproveWorkerShifts={unapproveWorkerShifts}
              isLoading={isLoadingWorkerShifts}
              editMode={false}
              hideTotalsRow={true}
              hideEmptyState={true}
              loggingContext={{
                source: TimeSheetsTableLoggingSource.TIMESHEETS_APPROVAL_SCREEN,
                screen: AppScreen.MOBILE,
              }}
            />
          )}
        </ShiftTileWorkerListContainer>
      )}
    </>
  )
}
