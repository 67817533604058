import { CreateShiftRequest } from '@traba/types'
import { isEqual } from 'date-fns'
import { isEqual as isEqualLodash } from 'lodash'
import { StepType } from '../BookShifts/BookShiftsScreen.hooks'

export const DECREASE_WORKER_SLOTS_TIME_WINDOW = 18 // hours

function hasChanges(
  key: keyof CreateShiftRequest,
  defaultShiftRequest: CreateShiftRequest,
  shiftRequest: CreateShiftRequest,
) {
  return shiftRequest[key] && defaultShiftRequest[key] !== shiftRequest[key]
}

export function getChangesFromStep(
  stepType: StepType,
  defaultShiftRequest: CreateShiftRequest,
  shiftRequest: CreateShiftRequest,
) {
  switch (stepType) {
    case StepType.DETAILS:
      return {
        ...(hasChanges('roleId', defaultShiftRequest, shiftRequest)
          ? { roleId: shiftRequest.roleId }
          : {}),
        ...(hasChanges('locationId', defaultShiftRequest, shiftRequest)
          ? { locationId: shiftRequest.locationId }
          : {}),
        ...(defaultShiftRequest.parkingLocationId !==
        shiftRequest.parkingLocationId
          ? { parkingLocationId: shiftRequest.parkingLocationId }
          : {}),
        ...(hasChanges('supervisorId', defaultShiftRequest, shiftRequest)
          ? { supervisorId: shiftRequest.supervisorId }
          : {}),
        ...(hasChanges('additionalEmails', defaultShiftRequest, shiftRequest)
          ? { additionalEmails: shiftRequest.additionalEmails }
          : {}),
        ...(hasChanges(
          'shiftCodesReceiverIds',
          defaultShiftRequest,
          shiftRequest,
        )
          ? { shiftCodesReceiverIds: shiftRequest.shiftCodesReceiverIds }
          : {}),
        ...(shiftRequest.schedules[0].timeZone !==
        defaultShiftRequest.schedules[0].timeZone
          ? { timezone: shiftRequest.schedules[0].timeZone }
          : {}),
      }
    case StepType.SCHEDULE:
      return {
        ...(hasChanges('scheduledBreaks', defaultShiftRequest, shiftRequest)
          ? { scheduledBreaks: shiftRequest.scheduledBreaks }
          : {}),
        ...(!isEqual(
          shiftRequest.schedules[0].startTime,
          defaultShiftRequest.schedules[0].startTime,
        )
          ? {
              startTime: shiftRequest.schedules[0].startTime,
              endTime: shiftRequest.schedules[0].endTime,
            }
          : {}),
        ...(!isEqual(
          shiftRequest.schedules[0].endTime,
          defaultShiftRequest.schedules[0].endTime,
        )
          ? {
              startTime: shiftRequest.schedules[0].startTime,
              endTime: shiftRequest.schedules[0].endTime,
            }
          : {}),
      }
    case StepType.WORKERS:
      return {
        ...(hasChanges('invitedWorkers', defaultShiftRequest, shiftRequest)
          ? { invitedWorkers: shiftRequest.invitedWorkers }
          : {}),
        ...(hasChanges('forwardFillMax', defaultShiftRequest, shiftRequest)
          ? { forwardFillMax: shiftRequest.forwardFillMax }
          : {}),
        ...(hasChanges('payRate', defaultShiftRequest, shiftRequest)
          ? { payRate: shiftRequest.payRate }
          : {}),
        ...(hasChanges('numberOfUnits', defaultShiftRequest, shiftRequest)
          ? { numberOfUnits: shiftRequest.numberOfUnits }
          : {}),
        ...(hasChanges('payType', defaultShiftRequest, shiftRequest)
          ? { payType: shiftRequest.payType }
          : {}),
        ...(hasChanges('minSlotsRequested', defaultShiftRequest, shiftRequest)
          ? { minSlotsRequested: shiftRequest.minSlotsRequested }
          : {}),
        ...(hasChanges('slotsRequested', defaultShiftRequest, shiftRequest)
          ? { slotsRequested: shiftRequest.slotsRequested }
          : {}),
        ...(hasChanges('videoIds', defaultShiftRequest, shiftRequest)
          ? { videoIds: shiftRequest.videoIds }
          : {}),
        ...(defaultShiftRequest.genderPreference !==
        shiftRequest.genderPreference
          ? {
              genderPreference:
                shiftRequest.genderPreference !== undefined
                  ? shiftRequest.genderPreference
                  : null,
            }
          : {}),
      }
    default:
      return {}
  }
}

export function hasAdditionalShiftInvitations(
  defaultShiftRequest: CreateShiftRequest,
  shiftRequest: CreateShiftRequest,
) {
  const defaultShiftInvitationWorkerIds =
    defaultShiftRequest.shiftInvitations?.flatMap((invitation) =>
      invitation.workers.map((worker) => worker.workerId),
    ) ?? []
  const shiftInvitationWorkerIds =
    shiftRequest.shiftInvitations?.flatMap((invitation) =>
      invitation.workers.map((worker) => worker.workerId),
    ) ?? []
  return (
    !!shiftRequest.shiftInvitations &&
    !isEqualLodash(defaultShiftInvitationWorkerIds, shiftInvitationWorkerIds)
  )
}
