import { SvgIconProps } from 'src/types/svg-types'

export default function Cancel({
  size,
  color,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
      <g clipPath="url(#clip0_226_6131)">
        <path
          d="M3.75827 12.2426L12.2435 3.75736M3.75827 3.75736L12.2435 12.2426"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_226_6131">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
