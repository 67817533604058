import { theme } from '@traba/theme'
import styled from 'styled-components'
import Row from '../Row'
import { Text } from '../Text'

type AvatarProps = {
  name: string
  src?: string | null
  size?: number
}

const AvatarWrapper = styled.div<{ size?: number }>`
  height: ${(props) => props.size ?? 112}px;
  width: ${(props) => props.size ?? 112}px;
  border: 4px solid #ffffff;
  border-radius: 16px;
  background-color: #eee;
  overflow: hidden;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`

const Avatar = ({ src, size, name }: AvatarProps) => {
  return (
    <AvatarWrapper size={size}>
      {src ? (
        <img src={src} alt={'Worker Profile'} />
      ) : (
        <Row alignCenter center fullHeight>
          <Text variant="h3" style={{ color: theme.colors.Violet }}>
            {name[0]}
          </Text>
        </Row>
      )}
    </AvatarWrapper>
  )
}

export default Avatar
