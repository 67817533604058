import { SvgIconProps } from '@traba/types'

export default function ({ size, color, strokeWidth, ...props }: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.06055 1.98865L8.06055 0.988647"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M8.06055 15.4248L8.06055 14.4248"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M8.06055 4.80591L8.06238 12.0956"
        strokeWidth={strokeWidth}
        stroke={color}
      />
      <path
        d="M10.5168 5.89478L5.60803 10.8036"
        strokeWidth={strokeWidth}
        stroke={color}
      />
      <path
        d="M4.66431 8.22485L11.4914 8.22485"
        strokeWidth={strokeWidth}
        stroke={color}
      />
      <path
        d="M10.4731 10.542L5.56433 5.63317"
        strokeWidth={strokeWidth}
        stroke={color}
      />
      <path
        d="M14.1467 8.22485L15.1467 8.22485"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M0.860596 8.22485L1.8606 8.22485"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M12.759 4.28345L13.4661 3.57634"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M3.17969 13.8973L3.88679 13.1902"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M12.3359 12.4576L13.043 13.1647"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M3.13013 3.25183L3.84406 3.95204"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <circle
        cx="8.06055"
        cy="8.22486"
        r="3.77515"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <circle
        cx="8.06055"
        cy="8.22485"
        r="7.5"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
