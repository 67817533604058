import { DEFAULT_PAGE_SIZE } from '@traba/consts'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useMembers } from 'src/hooks/useMembers'
import { useRegions } from 'src/hooks/useRegions'
import { useShiftPagination } from 'src/hooks/useShiftPagination'
import { ShiftAndAddress, useShifts } from 'src/hooks/useShifts'

import { MEDIA } from 'src/libs/theme'
import useCalendarFilters from '../../hooks/useShiftFilters.hooks'
import { TextVariant } from '../base/Text'
import { ShiftCalendarProps } from './ShiftCalendar.ui'

export const useShiftCalendar = (
  before?: Date,
  after?: Date,
  workerId?: string,
  cacheKey?: string,
  hideWorkers?: boolean,
  hideFilters?: boolean,
  sectionTitleTextVariant?: TextVariant,
): ShiftCalendarProps => {
  const [filters, dispatch] = useCalendarFilters()

  const {
    page,
    onPageLeft,
    onPageRight,
    data: shifts = [],
    isLoading: isLoadingShifts,
  } = useShiftPagination<ShiftAndAddress>(
    cacheKey ? cacheKey : 'shifts',
    {
      limit: DEFAULT_PAGE_SIZE + 1,
      sortBy: 'startTime',
      sortDir: 'asc',
      ...filters,
      endBefore: before,
      endAfter: after,
      workerId,
    },
    useShifts,
    DEFAULT_PAGE_SIZE,
  )

  const { regions = [], isFetched: isRegionsFetched } = useRegions()

  const { members = [], isFetched: isMembersFetched } = useMembers()

  const showDesktopCard = useMediaQuery({
    query: `(min-width: ${MEDIA.LARGE}px)`,
  })

  const [isShowWorkersToggled, setIsShowWorkersToggled] = useState(true)

  return {
    shifts,
    isShiftsFetched: !isLoadingShifts,
    regions,
    isRegionsFetched,
    members,
    isMembersFetched,
    filters,
    dispatch,
    page,
    onPageLeft,
    onPageRight,
    showDesktopCard,
    hideWorkers,
    hideFilters,
    sectionTitleTextVariant,
    isShowWorkersToggled,
    setIsShowWorkersToggled,
  }
}
