import { SvgIconProps } from '@traba/types'

export default function Send({
  size,
  color = '#7A8A99',
  strokeWidth = 1.5,
  ...props
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M5.35853 12.0159L4.14654 16.046C3.38055 18.593 5.86709 20.8673 8.112 19.6729L17.431 14.715C19.5212 13.6029 19.5235 10.4241 17.4348 9.30862L8.11093 4.32915C5.86721 3.13088 3.37713 5.40233 4.14065 7.95083L5.35853 12.0159ZM5.35853 12.0159H8.32953"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  )
}
