import { useHotSettings } from '@traba/hooks'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUserContext } from 'src/context/user/UserContext'
import { useBilling } from 'src/hooks/useBilling'
import { useCompanyEmploymentType } from 'src/hooks/useCompanyEmploymentType'
import { hasPermissions } from 'src/utils/userUtils'

import { COMPANY_SETTINGS_TABS } from '../constants'
import { SETTINGS_TAB_KEYS } from '../types'
import { getInitialTabIndex } from '../utils'

export const useCompanySettings = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const userContext = useUserContext()
  const { hotSettings } = useHotSettings()
  const { showInvoicing } = useCompanyEmploymentType()
  const showCostCenters = useMemo(
    () =>
      hotSettings?.costCenterEnabledByCompanyIds?.includes(
        userContext.state.userProfile?.companyId ?? '',
      ),
    [
      hotSettings?.costCenterEnabledByCompanyIds,
      userContext.state.userProfile?.companyId,
    ],
  )

  const activeTabs = useMemo(
    () =>
      COMPANY_SETTINGS_TABS.filter((tab) => {
        switch (tab.key) {
          case SETTINGS_TAB_KEYS.COST_CENTERS:
            return showCostCenters
          case SETTINGS_TAB_KEYS.BILLING:
            return showInvoicing
          default:
            return hasPermissions(
              userContext.state.userProfile,
              tab.permissions,
            )
        }
      }),
    [showCostCenters, showInvoicing, userContext.state.userProfile],
  )

  const [currentTabIndex, setCurrentTabIndex] = useState(
    getInitialTabIndex(location.search, activeTabs),
  )

  useEffect(() => {
    const { key } = activeTabs[currentTabIndex]
    navigate('/company-settings', { replace: true, state: { tab: key } })
    window.analytics.track(`User Navigated to Company Settings Tab`, {
      tab: key,
    })
  }, [navigate, activeTabs, currentTabIndex])

  const { retrieveCheckoutSession } = useBilling()

  const handleStripeRedirectReturn = async (search: string) => {
    if (search) {
      const params = new URLSearchParams(search)
      const sessionId = params.get('session_id')
      if (sessionId) {
        await retrieveCheckoutSession(sessionId)
        navigate(`/company-settings?tab=${SETTINGS_TAB_KEYS.BILLING}`)
      }
    }
  }
  useEffect(() => {
    handleStripeRedirectReturn(location.search)
  }, [location, handleStripeRedirectReturn])

  return { currentTabIndex, setCurrentTabIndex, activeTabs }
}
