import { Card } from '@traba/react-components'
import { AxiosError } from 'axios'
import React from 'react'
import { UseMutateFunction } from 'react-query'
import { Button, ButtonVariant, Row, Text } from 'src/components/base'
import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'
import { UserProfile } from 'src/types'
import { formatPhoneNumber } from 'src/utils/phoneNumberUtils'

import { PersonalProfileListItem } from './PersonalProfileListItem'
import { UpdatePasswordSection } from './UpdatePasswordSection'
import { UpdatePersonalInfoSection } from './UpdatePersonalInfoSection'

interface Props {
  editMode: 'information' | 'password' | undefined
  setEditMode: React.Dispatch<
    React.SetStateAction<'information' | 'password' | undefined>
  >
  user: UserProfile | undefined
  patchUser: UseMutateFunction<
    UserProfile,
    AxiosError<any>,
    Partial<UserProfile>,
    UserProfile
  >
  showUpdatePassword: boolean
}

export const PersonalInfoSection = ({
  editMode,
  setEditMode,
  user,
  patchUser,
  showUpdatePassword,
}: Props) => {
  const { isMobileViewOrReactNative } = useMobile()

  switch (editMode) {
    case 'information':
      return (
        <UpdatePersonalInfoSection
          user={user}
          patchUser={patchUser}
          setEditMode={setEditMode}
        />
      )
    case 'password':
      return <UpdatePasswordSection setEditMode={setEditMode} />
    default:
      return (
        <Card>
          <Text variant="h5" mb={theme.space.xs}>
            Personal Information
          </Text>
          <PersonalProfileListItem
            itemName="Full name"
            itemValue={user?.firstName + ` ` + user?.lastName}
          />
          <PersonalProfileListItem
            itemName="Email address"
            itemValue={user?.email ?? ''}
          />
          <PersonalProfileListItem
            itemName="Phone number"
            itemValue={formatPhoneNumber(user?.phoneNumber || '', true)}
          />
          <Row mt={theme.space.med}>
            {showUpdatePassword && (
              <Button
                style={{ marginRight: theme.space.xs, height: 40 }}
                full
                onClick={() => {
                  setEditMode('password')
                }}
                variant={ButtonVariant.OUTLINED}
                slim={isMobileViewOrReactNative}
              >
                Update Password
              </Button>
            )}
            <Button
              style={{ height: 40 }}
              full
              onClick={() => {
                setEditMode('information')
              }}
              variant={ButtonVariant.FILLED}
              slim={isMobileViewOrReactNative}
            >
              Edit profile
            </Button>
          </Row>
        </Card>
      )
  }
}
