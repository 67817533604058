import { WorkerShift } from '@traba/types'
import { getShiftDateString } from '@traba/utils'
import { useMemo } from 'react'
import useMobile from 'src/hooks/useMobile'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { useUserPermissions } from 'src/hooks/useUser'
import { Money, UserRolePermission } from 'src/types'
import { calculateExpectedCost } from 'src/utils/moneyUtils'
import { getAddressString } from 'src/utils/stringUtils'

export interface ShiftTileProps {
  isPastShift?: boolean
  onClick?: () => void
  onClickApproveHours?: (shiftId?: string) => void
  showClockCodes?: boolean
  showDesktopCard: boolean
  showTimeBar: boolean
  showRequiredAttributes?: boolean
  showWorkerShifts: boolean
  defaultShowWorkerShifts?: boolean
  alwaysHideWorkers?: boolean
  showTotals?: boolean
  showSignedUp?: boolean
  hideBorders?: boolean
  hideCTAs?: boolean
}

export interface ShiftTileUIProps extends ShiftTileProps {
  slotsRemaining?: number
  minEstimatedCost?: Money
  maxEstimatedCost?: Money
  dateString: string
  addressString: string
  userCanViewWages: boolean
  path: string
  alwaysHideWorkers?: boolean
  showTotals?: boolean
  showSignedUp?: boolean
}

type ShiftLogic = Pick<
  ShiftTileUIProps,
  | 'slotsRemaining'
  | 'minEstimatedCost'
  | 'maxEstimatedCost'
  | 'dateString'
  | 'addressString'
  | 'userCanViewWages'
  | 'path'
>

const useShiftLogic = (
  props: ShiftTileProps,
  shift?: ShiftAndAddress,
): ShiftLogic | undefined => {
  const { isMobileViewOrReactNative } = useMobile()
  const userCanViewWages = useUserPermissions([UserRolePermission.ViewPay])

  const { minEstimatedCost, maxEstimatedCost } = useMemo(
    () =>
      shift
        ? calculateExpectedCost(
            {
              startTime: shift.startTime,
              endTime: shift.endTime,
              payRate: shift.payRate,
              payType: shift.payType,
              scheduledBreaks: shift.scheduledBreaks,
              minSlotsRequested: shift.minSlotsRequested,
              slotsRequested: shift.slotsRequested,
              calculatedMarkup: shift.calculatedMarkup,
              breakType: shift.breakType,
              numberOfUnits: shift.numberOfUnits || 0,
            },
            shift.businessStartTime,
          )
        : { minEstimatedCost: undefined, maxEstimatedCost: undefined },
    [shift],
  )

  if (!shift) {
    return
  }

  const { isPastShift } = props

  const {
    address,
    startTime,
    businessStartTime,
    endTime,
    slotsFilled,
    slotsRequested,
    timezone,
    locationName,
  } = shift

  const path = isPastShift ? 'timesheet' : 'calendar'

  const addressString = getAddressString(address, locationName)
  const dateString = getShiftDateString(
    businessStartTime ?? startTime,
    endTime,
    timezone,
    {
      year: isMobileViewOrReactNative ? undefined : 'numeric',
      weekday: isMobileViewOrReactNative ? 'short' : undefined,
    },
  )

  const slotsRemaining = Math.max(slotsRequested - slotsFilled, 0)

  return {
    path,
    slotsRemaining,
    minEstimatedCost,
    maxEstimatedCost,
    dateString,
    addressString,
    userCanViewWages,
  }
}

const useWorkerShiftLogic = (
  props: ShiftTileProps,
  workerShift?: WorkerShift,
): ShiftLogic | undefined => {
  const { isMobileViewOrReactNative } = useMobile()
  const userCanViewWages = useUserPermissions([UserRolePermission.ViewPay])

  if (!workerShift) {
    return
  }

  const { isPastShift } = props

  const { address, locationName, clockInTime, clockOutTime, shiftInfo } =
    workerShift

  const path = isPastShift ? 'timesheet' : 'calendar'

  const addressString = address ? getAddressString(address, locationName) : ''
  const dateString = getShiftDateString(
    clockInTime ?? shiftInfo.startTime,
    clockOutTime ?? shiftInfo.endTime,
    shiftInfo.timezone,
    {
      year: isMobileViewOrReactNative ? undefined : 'numeric',
      weekday: isMobileViewOrReactNative ? 'short' : undefined,
    },
  )

  return {
    path,
    dateString,
    addressString,
    userCanViewWages,
  }
}

export const useShiftTile = (
  props: ShiftTileProps,
  shift?: ShiftAndAddress,
  workerShift?: WorkerShift,
): ShiftLogic => {
  const shiftLogic = useShiftLogic(props, shift)
  const workerShiftLogic = useWorkerShiftLogic(props, workerShift)

  if (shiftLogic) {
    return shiftLogic
  }
  if (workerShiftLogic) {
    return workerShiftLogic
  }

  throw new Error('Either shift or workerShift must be provided')
}
