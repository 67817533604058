import { ClickAwayListener, Tooltip } from '@mui/material'
import { SvgIcon } from '@traba/react-components'
import { Worker } from '@traba/types'
import { useState } from 'react'
import { theme } from 'src/libs/theme'

import { Button, ButtonVariant, Text } from '../base'
import * as S from './AddToRosterMenu.styles'
import { AddToRosterView } from './AddToRosterView'

interface AddToRosterMenuProps {
  menuKey: string | number
  worker: Worker
  onClickNewRoster?: (workerIds: string[]) => void
  disabled?: boolean
  buttonVariant?: ButtonVariant
  buttonStyle?: React.CSSProperties
}

function AddToRosterMenu({
  menuKey,
  worker,
  onClickNewRoster,
  disabled,
  buttonVariant,
  buttonStyle,
}: AddToRosterMenuProps) {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')

  const handleTooltipClose = () => {
    tooltipOpen && setTooltipOpen(false)
  }

  const handleTooltipOpen = () => {
    window.analytics?.track(`User Clicked Add To Roster Menu`, {
      workerId: worker.uid,
    })
    !tooltipOpen && setTooltipOpen(!tooltipOpen)
  }

  function AddToRosterTooltip() {
    if (disabled) {
      return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Text
            center
            style={{ padding: `${theme.space.xxs}px ${theme.space.xs}px` }}
            variant="body2"
          >
            {`This action is disabled for ${worker.firstName} because they are blocked.`}
          </Text>
        </ClickAwayListener>
      )
    }
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <S.AddToRosterMenuContainer>
          <AddToRosterView
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            workerIds={[worker.uid || worker.workerId]}
            onClickNewRoster={onClickNewRoster}
            handleClose={handleTooltipClose}
          />
        </S.AddToRosterMenuContainer>
      </ClickAwayListener>
    )
  }

  return (
    <Tooltip
      key={menuKey}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      onClose={handleTooltipClose}
      open={tooltipOpen}
      placement="bottom-start"
      sx={{ m: 1 }}
      PopperProps={{
        disablePortal: false, // display outside of container
      }}
      componentsProps={{
        tooltip: {
          style: {
            maxWidth: 500,
            minWidth: 360,
            background: theme.colors.White,
            border: `1px solid ${theme.colors.Grey20}`,
            padding: 0,
          },
        },
      }}
      title={AddToRosterTooltip()}
    >
      <Button
        variant={buttonVariant ?? ButtonVariant.OUTLINED}
        style={{
          height: 48,
          width: 48,
          ...(tooltipOpen && !disabled
            ? { boxShadow: 'none', border: `1px solid ${theme.colors.brand}` }
            : {}),
          ...buttonStyle,
        }}
        onClick={handleTooltipOpen}
      >
        <SvgIcon
          name="menu"
          color={
            disabled
              ? theme.colors.Grey30
              : tooltipOpen
                ? theme.colors.brand
                : theme.colors.Grey50
          }
          id={`add-to-roster-menu-button-${menuKey}`}
          aria-controls={
            tooltipOpen ? `add-to-roster-menu-${menuKey}` : undefined
          }
          aria-haspopup="true"
          aria-expanded={tooltipOpen ? 'true' : undefined}
        />
      </Button>
    </Tooltip>
  )
}

export default AddToRosterMenu
