import { theme } from '@traba/theme'
import {
  AnalyticsTrackingFunction,
  COMPANY_WIDE_ID,
  COMPANY_WIDE_TEXT,
  IconName,
  InputStatus,
  LocationResponse,
  User,
} from '@traba/types'
import {
  getLocationNameOrTruncatedAddress,
  getUserFullName,
  isBizMemberCompanyWide,
} from '@traba/utils'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { LoadingSpinner, Row, SearchSelect, Col } from '../base-components'
import { IMenuItem, MenuItemGroup } from '../base-components/Select/Select'
import { SvgIcon } from '../base-components/SvgIcon'
import { Text } from '../base-components/Text'

const LOCATION_ASSIGNED_DEFAULT_ID = 'location-assigned'
const LOCATION_ASSIGNED_TEXT = 'Location assigned'

export type ReplaceSupervisorForLocationsSectionProps = {
  member: Partial<User>
  replacementMembers: Partial<User>[]
  memberIdToMemberMap: Map<string, Partial<User>>
  locations: LocationResponse[]
  replacementSupervisorMap: Record<string, string> // this map represents the locationIdToReplaceThisMember -> replacementMemberId
  setReplacementSupervisorMap: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >
  loading?: boolean
  analyticsTrackingFunction?: AnalyticsTrackingFunction
}

const createItemFromMember = (member: Partial<User>): IMenuItem => {
  const isMemberCompanyWide = isBizMemberCompanyWide(member)
  const tagsRow = isMemberCompanyWide
    ? [
        {
          title: COMPANY_WIDE_TEXT,
          iconName: 'location' as IconName,
          variant: 'darkOrange',
        },
      ]
    : (member.locations || []).map((loc) => ({
        title: getLocationNameOrTruncatedAddress(loc),
        iconName: 'location' as IconName,
        variant: 'business',
      }))
  return {
    value: member.uid as string,
    label: getUserFullName(member),
    tagsRow,
    groupId: isMemberCompanyWide
      ? COMPANY_WIDE_ID
      : LOCATION_ASSIGNED_DEFAULT_ID,
  }
}

export function ReplaceSupervisorForLocationsSection({
  member,
  replacementMembers,
  memberIdToMemberMap,
  locations,
  replacementSupervisorMap,
  setReplacementSupervisorMap,
  loading,
  analyticsTrackingFunction,
}: ReplaceSupervisorForLocationsSectionProps) {
  const [replacementErrors, setReplacementErrors] = useState<
    Record<string, string | undefined>
  >({})

  const handleSetReplaceUserForLocation = useCallback(
    (locationId: string) => (menuItem: IMenuItem | undefined) => {
      const newUserId = menuItem?.value
      setReplacementSupervisorMap((prevMap) => ({
        ...prevMap,
        [locationId]: newUserId || '',
      }))
      setReplacementErrors((prevErrors) => ({
        ...prevErrors,
        [locationId]: undefined,
      }))

      if (analyticsTrackingFunction) {
        analyticsTrackingFunction(
          `User Set a Replacement Supervisor For Location Of User`,
          {
            member,
            locationId,
            newSupervisorId: newUserId,
          },
        )
      }
    },
    [setReplacementSupervisorMap],
  )

  const groups: MenuItemGroup[] = useMemo(() => {
    return [
      {
        id: LOCATION_ASSIGNED_DEFAULT_ID,
        title: LOCATION_ASSIGNED_TEXT,
      },
      {
        id: COMPANY_WIDE_ID,
        title: COMPANY_WIDE_TEXT,
      },
    ]
  }, [])

  const getReplacementMembersForLocation = useCallback(
    (locationId: string) => {
      return replacementMembers
        .filter((m) => {
          const isNotCurrentUser = m.uid !== member.uid
          const isUserInLocation =
            isBizMemberCompanyWide(m) ||
            m.locations?.find((loc) => locationId === loc.locationId)
          return isNotCurrentUser && isUserInLocation
        })
        .map(createItemFromMember)
    },
    [replacementMembers, member.uid],
  )

  const onBlurSelect = (locationId: string) => () => {
    if (!replacementSupervisorMap[locationId]) {
      setReplacementErrors((prevErrors) => ({
        ...prevErrors,
        [locationId]: 'You must select a replacement member.',
      }))
    }
  }

  useEffect(() => {
    if (analyticsTrackingFunction) {
      analyticsTrackingFunction(
        'Replacement Supervisor For Supervisor Locations Section Shown',
        {
          member,
          locationIds: locations.map((l) => l.locationId),
        },
      )
    }
  }, [])

  if (loading) {
    return <LoadingSpinner />
  }

  const emptyItem = { label: `--`, value: '' }

  return (
    <Col gap={theme.space.sm}>
      {locations.map((loc) => {
        const selectedMemberId = replacementSupervisorMap[loc.locationId]
        const selectedMember = memberIdToMemberMap.get(selectedMemberId)
        const selectedMemberItem = selectedMember
          ? createItemFromMember(selectedMember)
          : emptyItem

        return (
          <Col key={loc.locationId} gap={theme.space.xxs}>
            <Row gap={theme.space.xxxs} alignCenter>
              <SvgIcon name="location" size={16} color={theme.colors.Grey50} />
              <Text variant="h6">{getLocationNameOrTruncatedAddress(loc)}</Text>
            </Row>
            <SearchSelect
              placeholder="Select a replacement supervisor"
              selectItem={selectedMemberItem}
              handleSelect={handleSetReplaceUserForLocation(loc.locationId)}
              options={[
                emptyItem,
                ...getReplacementMembersForLocation(loc.locationId),
              ]}
              onlyShowLabel
              selectStyle={{ maxWidth: '480px' }}
              menuItemStyle={{ maxWidth: '480px' }}
              onBlur={onBlurSelect(loc.locationId)}
              inputStatus={
                replacementErrors[loc.locationId]
                  ? InputStatus.error
                  : undefined
              }
              errorMessage={replacementErrors[loc.locationId]}
              groups={groups}
              groupByGroup
            />
          </Col>
        )
      })}
    </Col>
  )
}
