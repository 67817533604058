import {
  Accordion,
  AccordionSummary,
  AccordionSummaryProps,
} from '@mui/material'
import { Link as BaseLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

type LinkProps = {
  $isActive?: boolean
}

export const SidebarWrapper = styled.div`
  background-color: #f7f7f8;
  padding: 32px;
  width: 100%;
  height: calc(100%);
  overflow: auto;
`

export const SidebarLinks = styled.div`
  margin-top: ${({ theme }) => theme.space.xs}px;
  padding-top: ${({ theme }) => theme.space.xs}px;
  border-top: 1px solid #e2e6e9;
`

export const BottomLinksWrapper = styled.div`
  margin-top: ${({ theme }) => theme.space.xs}px;
  padding-top: ${({ theme }) => theme.space.xs}px;
  border-top: 1px solid #e2e6e9;
`

export const Link = styled(BaseLink)<LinkProps>`
  font-style: normal;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 19px;
  margin: 1px 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.004em;
  color: ${({ theme }) => theme.TextButton};
  transition: all 1s;
  background-color: rgba(255, 255, 255, 0);

  img {
    flex-shrink: 0;
    padding: 0 4px;
    margin-right: ${({ theme }) => theme.space.xs}px;
    width: 28px;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 1px 1px rgba(196, 204, 212, 0.24);
      border-radius: 8px;
      color: #08105e;
    `}
`

export const CurrentShiftAccordion = styled(Accordion)`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.Violet10};
  border: none;
  overflow: hidden;
`

export const CurrentShiftAccordionSummary = styled(
  AccordionSummary,
)<AccordionSummaryProps>``
export const CurrentShiftAccordionLink = styled(BaseLink)<LinkProps>`
  text-decoration: none !important;
  display: block;
  &:hover {
    background-color: ${({ theme }) => theme.colors.Violet20};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.Violet30};
  }
`
