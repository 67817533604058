import { SvgIconProps } from 'src/types/svg-types'

export default function LocationPin({
  size,
  color = '#7A8A99',
  ...props
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 26" fill="none" {...props}>
      <path
        d="M9.09321 0.611084C6.8632 0.613516 4.72549 1.49979 3.15027 3.07497C1.57504 4.65015 0.691317 6.78523 0.693485 9.01057C0.693485 13.6501 6.4877 25.7953 9.09321 25.7953C11.6548 25.7953 17.494 13.6501 17.494 9.01057C17.495 7.9086 17.2786 6.81721 16.857 5.79872C16.4354 4.78023 15.8169 3.85457 15.0368 3.07461C14.2567 2.29464 13.3303 1.67565 12.3105 1.25296C11.2907 0.830263 10.1975 0.612154 9.09321 0.611084ZM9.09321 14.5919C8.0987 14.5939 7.12594 14.3014 6.29805 13.7515C5.47016 13.2016 4.82436 12.419 4.44237 11.5027C4.06039 10.5864 3.9594 9.5776 4.15218 8.604C4.34496 7.63039 4.82284 6.73573 5.52536 6.03326C6.22787 5.33079 7.12343 4.85209 8.09869 4.65774C9.07394 4.46338 10.0851 4.5621 11.0041 4.94142C11.9231 5.32074 12.7086 5.9636 13.2614 6.78864C13.8141 7.61368 14.1092 8.5838 14.1092 9.57623C14.1098 10.2342 13.9806 10.8859 13.7289 11.4941C13.4772 12.1022 13.1079 12.655 12.6421 13.1207C12.1764 13.5865 11.6232 13.9561 11.0143 14.2085C10.4054 14.4609 9.75258 14.5912 9.09321 14.5919Z"
        fill={color}
      />
    </svg>
  )
}
