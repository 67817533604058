import { Button, ButtonVariant, SvgIcon } from 'src/components/base'
import { useTrainingVideos } from 'src/hooks/useTrainingVideos'
import { theme } from 'src/libs/theme'
import { TrainingVideo } from 'src/types'

import { Table, Td, Tr } from '../Table/Table'

interface Props {
  videos: TrainingVideo[]
  userCanEditVideos: boolean
}

export const DesktopTrainingVideos = ({ videos, userCanEditVideos }: Props) => {
  const { removeTrainingVideo } = useTrainingVideos()
  return (
    <Table
      headers={[
        'Name',
        'Video',
        'Required for all shifts',
        'Created At',
        ...(userCanEditVideos ? [''] : []),
      ]}
      itemType="videos"
      showEmptyState
      centerHeaders
    >
      {videos.map((v) => (
        <Tr key={v.id}>
          <Td>{v.name}</Td>
          <Td style={{ textAlign: 'center' }}>
            <video width="200" controls>
              <source src={v.videoLink} />
            </video>
          </Td>
          <Td style={{ textAlign: 'center' }}>
            {v.requiredForAll ? 'Yes' : 'No'}
          </Td>
          <Td style={{ textAlign: 'center' }}>
            {new Date(v.createdAt).toLocaleString('en-US')}
          </Td>
          <Td>
            <Button
              variant={ButtonVariant.OUTLINED}
              onClick={() => {
                removeTrainingVideo(v.id)
                window.analytics.track(`User Removed Training Video`, {
                  videoId: v.id,
                })
              }}
            >
              <SvgIcon name="trash" color={theme.colors.Grey50} />
            </Button>
          </Td>
        </Tr>
      ))}
    </Table>
  )
}
