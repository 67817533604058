import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  FilterOptionsState,
  TextField,
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { ShiftStatus } from '@traba/types'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { BULLET_CHAR } from 'src/libs/constants'
import { theme } from 'src/libs/theme'
import {
  formatShiftTime,
  getShiftNumericDateAndTimeString,
} from 'src/shared/utils/dateUtils'
import { Col, Row, Text } from '../base'
import { containsText } from '../base/Autocomplete/Autocomplete'
import {
  NO_COLOR_THRESHOLD_MS,
  getTimeDeltaString,
} from '../ShiftTile/ShiftTileTimebarUtils'

const filterOptions = (
  options: ShiftAndAddress[],
  state: FilterOptionsState<ShiftAndAddress>,
  input: string,
) =>
  options.filter((o) => {
    const shiftTimeString = formatShiftTime(
      o.businessStartTime ?? o.startTime,
      o.endTime,
      o.timezone,
    )
    return (
      (!input.includes(o.shiftRole) &&
        containsText(o.shiftRole, state.inputValue)) ||
      (!input.includes(shiftTimeString) &&
        containsText(shiftTimeString, state.inputValue))
    )
  })

const renderInput = (
  params: AutocompleteRenderInputParams,
  label = 'Search shifts',
) => <TextField {...params} label={label} aria-label={label} />

export function ShiftSelector({
  shifts,
  input,
  setInput,
  setSelectedShift,
  isLoading,
}: {
  shifts: ShiftAndAddress[]
  input: string
  setInput: (s: string) => void
  setSelectedShift: (shift: ShiftAndAddress | undefined) => void
  isLoading?: boolean
}) {
  const currTime = Date.now()

  return (
    <Autocomplete
      fullWidth
      filterSelectedOptions
      loading={isLoading}
      style={{ marginTop: theme.space.xs, marginBottom: theme.space.xs }}
      options={shifts}
      renderOption={(props, option: ShiftAndAddress) => {
        const { endTime, timezone, id, status } = option
        const startTime = option.businessStartTime ?? option.startTime
        const isOngoingShift =
          startTime.getTime() <= currTime && endTime.getTime() >= currTime
        const isSoonToStartShift =
          startTime.getTime() - currTime <= NO_COLOR_THRESHOLD_MS &&
          startTime.getTime() - currTime > 0

        return (
          <li {...props} key={id}>
            <Row>
              <Col>
                <Row>
                  <Text variant="h6">{option.shiftRole}</Text>
                  {isOngoingShift && (
                    <Text color={theme.colors.Violet} ml={theme.space.xxs}>
                      {`${BULLET_CHAR} Remaining ${getTimeDeltaString(
                        Date.now(),
                        option.endTime.getTime(),
                      )}`}
                    </Text>
                  )}
                  {isSoonToStartShift && (
                    <Text color={theme.colors.Violet} ml={theme.space.xxs}>
                      {`${BULLET_CHAR} Starts in ${getTimeDeltaString(
                        currTime,
                        startTime.getTime(),
                      )}`}
                    </Text>
                  )}
                  {status === ShiftStatus.COMPLETE && (
                    <Text color={theme.colors.Violet} ml={theme.space.xxs}>
                      {`${BULLET_CHAR}  Completed`}
                    </Text>
                  )}
                </Row>
                <Text variant="body1">
                  {formatShiftTime(startTime, endTime, timezone)}
                </Text>
              </Col>
            </Row>
          </li>
        )
      }}
      inputValue={input}
      onInputChange={(event, value: string) => {
        if (event) {
          if (event.type === 'keydown') {
            const nativeEvent = event.nativeEvent as KeyboardEvent
            if (nativeEvent.key === 'Enter') {
              setInput(value)
            }
          } else {
            setInput(value)
          }
        }
      }}
      onChange={(_, value) => {
        if (value) {
          setSelectedShift(value)
        }
        if (value === null) {
          setSelectedShift(undefined)
        }
      }}
      filterOptions={(options, state) => filterOptions(options, state, input)}
      renderInput={renderInput}
      getOptionLabel={(option: ShiftAndAddress) =>
        `${option.shiftRole} ${BULLET_CHAR} ${formatShiftTime(
          option.businessStartTime ?? option.startTime,
          option.endTime,
          option.timezone,
        )}`
      }
    />
  )
}

export function MultiShiftSelector({
  shifts,
  input,
  setInput,
  selectedShifts,
  setSelectedShiftIds,
  isLoading,
}: {
  shifts: ShiftAndAddress[] | undefined
  input: string
  setInput: (s: string) => void
  selectedShifts: ShiftAndAddress[]
  setSelectedShiftIds: (shifts: string[] | undefined) => void
  isLoading?: boolean
}) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      loading={isLoading}
      style={{ marginTop: theme.space.xs, marginBottom: theme.space.xs }}
      options={shifts ?? []}
      renderOption={(props, option: ShiftAndAddress, { selected }) => {
        const { endTime, timezone, id } = option
        const startTime = option.businessStartTime ?? option.startTime
        return (
          <li {...props} key={id}>
            <Row>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <Col>
                <Text variant="h6">{option.shiftRole}</Text>
                <Text variant="body1">
                  {getShiftNumericDateAndTimeString({
                    startTime,
                    endTime,
                    timezone,
                    separatorChar: ', ',
                  })}
                </Text>
              </Col>
            </Row>
          </li>
        )
      }}
      inputValue={input}
      onInputChange={(event, value: string) => {
        if (event) {
          if (event.type === 'keydown') {
            const nativeEvent = event.nativeEvent as KeyboardEvent
            if (nativeEvent.key === 'Enter') {
              setInput(value)
            }
          } else {
            setInput(value)
          }
        }
      }}
      onChange={(_, value) => {
        if (value) {
          setSelectedShiftIds(value.map((s) => s.shiftId))
        }
        if (value === null) {
          setSelectedShiftIds(undefined)
        }
      }}
      value={selectedShifts}
      filterOptions={(options, state) => filterOptions(options, state, input)}
      renderInput={(params) => renderInput(params, 'Filter shifts')}
      getOptionLabel={(option: ShiftAndAddress) => {
        const { endTime, timezone } = option
        const startTime = option.businessStartTime ?? option.startTime

        const shiftTimeString = getShiftNumericDateAndTimeString({
          startTime,
          endTime,
          timezone,
          separatorChar: ', ',
        })
        return `${option.shiftRole} ${BULLET_CHAR} ${shiftTimeString}`
      }}
    />
  )
}
