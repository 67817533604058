import { SvgIconProps } from '@traba/types'

export default function Refresh({
  size,
  color = '#7A8A99',
  strokeWidth = 1.5,
  ...props
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M2.08691 8.10169C2.08691 11.3592 4.89 14 8.34778 14C11.8056 14 14.6087 11.3592 14.6087 8.10169C14.6087 4.84415 11.826 2.20339 8.34778 2.20339C4.17387 2.20339 2.08691 5.48022 2.08691 5.48022M2.08691 5.48022L2.08691 2M2.08691 5.48022H5.32525"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
