import { Text } from 'src/components/base'
import styled from 'styled-components'

export const ButtonText = styled(Text)`
  cursor: pointer;
  padding: 0px;
  :hover {
    color: ${({ theme }) => theme.colors.Violet80};
  }
`
