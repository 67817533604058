import { theme } from '@traba/theme'
import { Helmet } from 'react-helmet'
import { Col, Link, Row } from 'src/components/base'
import { Logo } from 'src/components/svgs/Logo'
import useMobile from 'src/hooks/useMobile'

import { AuthFormLeftContainer } from './styles'

type Props = {
  helmetTitle?: string
  leftCol?: React.ReactNode
  rightCol?: React.ReactNode
}

export default function AuthScreenWrapper(props: Props) {
  const { isMobileViewOrReactNative } = useMobile()

  return (
    <>
      <Helmet defer={false}>
        <title>{props.helmetTitle ?? 'Traba - Business'}</title>
      </Helmet>
      <Row
        px={theme.space.lg}
        py={isMobileViewOrReactNative ? theme.space.xxxl : theme.space.lg}
        style={{
          minHeight: '100vh',
          backgroundImage:
            'radial-gradient(circle at top right,#b2b3f1,#fff 42%,#0000 0),radial-gradient(circle at bottom left,#b2b3f1,#fff 42%,#0000 0)',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
        gap={theme.space.xl}
        wrap
      >
        <Link
          to="/"
          style={{
            position: 'absolute',
            left: isMobileViewOrReactNative ? '50%' : theme.space.xl,
            transform: isMobileViewOrReactNative
              ? 'translateX(-50%)'
              : undefined,
          }}
        >
          <Logo size={isMobileViewOrReactNative ? 100 : 72} />
        </Link>
        <Row
          gap={theme.space.lg}
          wrap
          ml={isMobileViewOrReactNative ? 'undefined' : theme.space.xl}
        >
          <Col
            mt={isMobileViewOrReactNative ? theme.space.xxxl : 100}
            width={
              isMobileViewOrReactNative
                ? '90vw'
                : props.rightCol
                  ? undefined
                  : '50vw'
            }
            style={{ flex: 1 }}
          >
            <AuthFormLeftContainer isMobileView={isMobileViewOrReactNative}>
              {props.leftCol}
            </AuthFormLeftContainer>
          </Col>
          {!isMobileViewOrReactNative && (
            <Col mt={140} width={props.rightCol ? '40%' : undefined}>
              {props.rightCol}
            </Col>
          )}
        </Row>
      </Row>
    </>
  )
}
