import { Dialog, MODAL_SIZE } from '@traba/react-components'
import { EligibilityConnectionMutationPayload, Worker } from '@traba/types'
import { useState } from 'react'
import { useBizEligibilityConnectionsMutation } from 'src/hooks/useEligibilityConnections'
import { EligibilityConnectionContent } from './EligibilityConnectionContent'

type CompanyConnectionModalProps = {
  companyId: string
  handleClose: () => void
  isOpen: boolean
  worker: Worker
}

export const CompanyEligibilityConnectionModal = (
  props: CompanyConnectionModalProps,
) => {
  const { isOpen, handleClose, companyId, worker } = props

  const [payload, setPayload] = useState<EligibilityConnectionMutationPayload>()
  const { mutate: updateEligibilityConnections } =
    useBizEligibilityConnectionsMutation()

  const onConfirm = () => {
    if (!payload) {
      return
    }

    updateEligibilityConnections(payload)
    setPayload(undefined)
    handleClose()
  }

  const onClose = () => {
    setPayload(undefined)
    handleClose()
  }

  return (
    <Dialog
      maxWidth={false}
      style={{ width: '50vw', maxWidth: '75vw' }}
      size={MODAL_SIZE.MEDIUM}
      onClose={onClose}
      onConfirm={onConfirm}
      open={isOpen}
      dialogTitle={`Toggle Worker Eligibility for ${worker?.firstName} ${worker?.lastName}`}
    >
      <EligibilityConnectionContent
        companyId={companyId}
        workerId={worker.uid}
        setPayload={setPayload}
      />
    </Dialog>
  )
}
