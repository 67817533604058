import {
  EligibilityConnectionLevel,
  EligibilityConnectionMutationPayload,
  EligibilityConnectionsByType,
  EligibilityConnectionType,
} from '@traba/types'
import { IMenuItem } from '../base-components/Select/Select'

export const eligibilityConnectionsToPayload = ({
  workerId,
  companyId,
  globalEligibilityForRoles,
  globalEligibilityForLocations,
  eligibleRoleItems,
  eligibleLocationItems,
  roleEntries,
  locationEntries,
  initiatedBy,
}: {
  workerId: string
  companyId: string
  globalEligibilityForRoles: EligibilityConnectionType
  globalEligibilityForLocations: EligibilityConnectionType
  eligibleRoleItems: IMenuItem[]
  eligibleLocationItems: IMenuItem[]
  roleEntries: (readonly [string, string])[]
  locationEntries: (readonly [string, string])[]
  initiatedBy: string
}) => {
  const roleIds = roleEntries.map((id) => id[0])
  const locationIds = locationEntries.map((id) => id[0])

  const eligibleRoleIdSet = new Set(eligibleRoleItems.map((item) => item.value))
  const eligibleLocationIdSet = new Set(
    eligibleLocationItems.map((item) => item.value),
  )

  const eligibleRoleIds = eligibleRoleItems.map((item) => item.value)
  const ineligibleRoleIds = roleIds.filter((id) => !eligibleRoleIdSet.has(id))

  const eligibleLocationIds = eligibleLocationItems.map((item) => item.value)
  const ineligibleLocationIds = locationIds.filter(
    (id) => !eligibleLocationIdSet.has(id),
  )

  const roleData = [
    ...ineligibleRoleIds.map((roleId) => ({
      roleId,
      eligibilityConnectionType: EligibilityConnectionType.INELIGIBLE,
      eligibilityConnectionLevel: EligibilityConnectionLevel.ROLE,
    })),
    ...eligibleRoleIds.map((roleId) => ({
      roleId,
      eligibilityConnectionType: EligibilityConnectionType.ELIGIBLE,
      eligibilityConnectionLevel: EligibilityConnectionLevel.ROLE,
    })),
  ]
  const locationData = [
    ...ineligibleLocationIds.map((locationId) => ({
      locationId,
      eligibilityConnectionType: EligibilityConnectionType.INELIGIBLE,
      eligibilityConnectionLevel: EligibilityConnectionLevel.LOCATION,
    })),
    ...eligibleLocationIds.map((locationId) => ({
      locationId,
      eligibilityConnectionType: EligibilityConnectionType.ELIGIBLE,
      eligibilityConnectionLevel: EligibilityConnectionLevel.LOCATION,
    })),
  ]

  const eligibilityConnections: EligibilityConnectionsByType = {
    roles: roleData,
    locations: locationData,
    locationAndRole: [],
  }

  const payload: EligibilityConnectionMutationPayload = {
    workerId,
    companyId,
    initiatedBy,
    globalEligibilities: {
      globalEligibilityForLocations: globalEligibilityForLocations,
      globalEligibilityForRoles: globalEligibilityForRoles,
    },
    eligibilityConnections,
  }
  return payload
}
