import { useHotSettings } from '@traba/hooks'
import { Text } from '@traba/react-components'
import { WorkerShift } from '@traba/types'
import { calculateTimeWorkedInMinutes } from '@traba/utils'
import { StrikeThrough } from 'src/components/base/StrikeThrough'
import { theme } from 'src/libs/theme'
import { formatDuration } from 'src/shared/utils/dateUtils'

interface Props {
  workerShift: WorkerShift
  isWorkerShiftComplete: boolean
  editMode: boolean | undefined
}

export const WorkerOnShiftTableTotalWorked = ({
  workerShift,
  isWorkerShiftComplete,
  editMode,
}: Props) => {
  const { hotSettings } = useHotSettings()
  const recalculatedTimeWorked = calculateTimeWorkedInMinutes(
    workerShift,
    hotSettings?.enableWorkerEditTime,
  )

  return isWorkerShiftComplete ? (
    editMode && workerShift.timeWorked !== recalculatedTimeWorked ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <StrikeThrough
          strikeThrough
          color={theme.colors.Grey60}
          style={{ color: theme.colors.Grey60 }}
        >
          {formatDuration(workerShift.timeWorked ?? 0)}
        </StrikeThrough>
        <div>{formatDuration(recalculatedTimeWorked)}</div>
      </div>
    ) : (
      <Text variant="body2" color={theme.colors.MidnightBlue}>
        {formatDuration(recalculatedTimeWorked ?? 0)}
      </Text>
    )
  ) : null
}
