import { DeactivateUserModalContent } from '@traba/react-components'
import { useCallback } from 'react'
import { useMembers } from 'src/hooks/useMembers'
import { UserData } from 'src/types'

export type DeactivateUserModalProps = {
  member: UserData
  handleClose: () => void
}

export function DeactivateUserModal({
  member,
  handleClose,
}: DeactivateUserModalProps) {
  const { changeMemberRole, isChangeMemberRoleLoading } = useMembers()

  const handleDeactiveMember = useCallback(async () => {
    await changeMemberRole(
      { role: null, uid: member.uid },
      {
        onSettled: handleClose,
      },
    )
    window.analytics.track(`User Deactivated Member`, {
      member: member,
    })
  }, [changeMemberRole, member, handleClose])

  return (
    <DeactivateUserModalContent
      member={member}
      onDeactivateMember={handleDeactiveMember}
      handleClose={handleClose}
      loading={isChangeMemberRoleLoading}
    />
  )
}
