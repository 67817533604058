import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { ONE_MINUTE_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import { AxiosError } from 'axios'
import { openInNewTab } from 'src/utils/miscUtils'

interface InvoiceDownloadUrl {
  downloadUrl?: string
}

async function getInvoiceDownloadUrl(
  invoiceId?: string,
): Promise<InvoiceDownloadUrl> {
  if (!invoiceId) {
    return {
      downloadUrl: undefined,
    }
  }
  const res = await trabaApi.get(
    `my-company/invoices/${invoiceId}/download-url`,
  )
  return res.data
}

export function useInvoiceDownload(invoiceId?: string, companyId?: string) {
  const { isLoading, isError, data, error, refetch } = useQuery<
    InvoiceDownloadUrl,
    AxiosError
  >({
    queryKey: ['invoice_download_url', invoiceId],
    queryFn: () => getInvoiceDownloadUrl(invoiceId),
    staleTime: ONE_MINUTE_IN_MS,
  })

  const downloadInvoice = () => {
    openInNewTab(data?.downloadUrl)
    window.analytics?.track(`Downloaded Invoice`, {
      invoiceId,
      downloadedAt: new Date(),
      ...(companyId ? { companyId } : undefined),
    })
  }

  return {
    isLoading,
    isError,
    downloadInvoice,
    error,
    refetch,
  }
}

export function useInvoiceDownloadButton() {
  const { handleError } = useAlert()

  const downloadInvoice = async (invoiceId: string) => {
    try {
      const { downloadUrl } = await getInvoiceDownloadUrl(invoiceId)
      openInNewTab(downloadUrl)
    } catch (error) {
      handleError(
        error,
        'useInvoiceDownloadButton -> downloadInvoice()',
        `Unable to fetch invoice download url`,
      )
    }
  }

  return { downloadInvoice }
}
