import { Divider, EmptyPlaceholderTile, Text } from 'src/components'
import { AnnouncementItem } from 'src/components/UpcomingShiftDetails/components/AnnouncementItem'
import { useShiftAnnouncements } from 'src/hooks/useShiftAnnouncements'
import { theme } from 'src/libs/theme'

interface Props {
  shiftId: string
  timezone?: string
  hideTitle?: boolean
  showEmptyState?: boolean
}

export const ShiftPastAnnouncements = ({
  shiftId,
  timezone,
  hideTitle,
  showEmptyState,
}: Props) => {
  const { announcements } = useShiftAnnouncements(shiftId)

  return announcements && announcements.length > 0 ? (
    <>
      {!hideTitle && (
        <>
          <Divider />
          <Text variant="h5" mt={theme.space.med}>
            Shift past announcements
          </Text>
        </>
      )}
      {announcements?.map((announcement) => (
        <AnnouncementItem
          announcement={announcement}
          timezone={timezone}
          key={announcement.id}
        />
      ))}
    </>
  ) : showEmptyState ? (
    <EmptyPlaceholderTile
      iconName="info"
      title={`You don't currently have any announcements for this shift.`}
    />
  ) : null
}
