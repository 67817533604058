import { SvgIconProps } from '@traba/types'

export default function Menu({
  size,
  color,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
      <path
        d="M2 13L8 13M13.9997 8L2 8M6 3L2 3"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M15 13L12 13"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M13.5 14.5V11.5"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  )
}
