import 'react-big-calendar/lib/css/react-big-calendar.css'

import { Calendar } from 'react-big-calendar'
import { theme } from 'src/libs/theme'
import styled from 'styled-components'

export const styleCombos = [
  {
    backgroundColor: theme.colors.Blue10,
    borderColor: theme.colors.Blue80,
  },
  {
    backgroundColor: theme.colors.Green10,
    borderColor: theme.colors.Green70,
  },
  {
    backgroundColor: theme.colors.Orange10,
    borderColor: theme.colors.Orange70,
  },
  {
    backgroundColor: theme.colors.Violet10,
    borderColor: theme.colors.Violet,
  },

  {
    backgroundColor: theme.colors.Yellow10,
    borderColor: theme.colors.Yellow70,
  },
  {
    backgroundColor: theme.colors.Peach10,
    borderColor: theme.colors.Peach70,
  },
  //Colors in design system are limited so we need to use some colors outside of it to ensure we have enough colors for all shifts
  { backgroundColor: '#E6E6FA', borderColor: '#9370DB' },
  { backgroundColor: '#AFEEEE', borderColor: '#00CED1' },
  { backgroundColor: '#E0FFFF', borderColor: '#B0E0E6' },
  {
    backgroundColor: '#F0E68C',
    borderColor: '#BDB76B',
  },
  {
    backgroundColor: '#BDB76B',
    borderColor: '#808000',
  },
  {
    backgroundColor: '#E6A8D7',
    borderColor: '#DA70D6',
  },
  {
    backgroundColor: '#AFEEEE',
    borderColor: '#40E0D0',
  },
  { backgroundColor: '#87CEEB', borderColor: '#4682B4' },
  { backgroundColor: '#FFE4B5', borderColor: '#DAA520' },
  { backgroundColor: '#00FA9A', borderColor: '#008000' },
  { backgroundColor: '#D8BFD8', borderColor: '#9932CC' },
  { backgroundColor: '#FFD700', borderColor: '#FFA500' },
  { backgroundColor: '#7B68EE', borderColor: '#6A5ACD' },
  { backgroundColor: '#98FB98', borderColor: '#008000' },
]

export const greyStyle = {
  backgroundColor: theme.colors.Grey,
  borderColor: theme.colors.Grey60,
}

export const canceledStyle = {
  backgroundColor: theme.colors.Red10,
  borderColor: theme.colors.Red70,
}

export const getColorForGroupId = (groupId: string, colorMap: any) => {
  if (!colorMap[groupId]) {
    colorMap[groupId] = styleCombos[colorMap.count % styleCombos.length]
    colorMap.count += 1
  }

  return colorMap[groupId]
}

export const CalendarView = styled(Calendar)<{
  isReactNativeApp: boolean
  isFromScheduleDetails?: boolean
}>`
  /* Block of today */
  .rbc-today {
    background-color: ${({ theme }) => theme?.colors.White};
  }

  .rbc-event-label {
    display: none;
  }

  /* Line indicator of current time */
  .rbc-current-time-indicator {
    height: 2px;
    background-color: ${({ theme }) => theme?.colors.brand};
  }

  /* whole table of month */
  .rbc-month-view {
    border-radius: 10px;
    margin-top: ${({ isReactNativeApp, isFromScheduleDetails }) =>
      isReactNativeApp && !isFromScheduleDetails ? '170px' : undefined};
  }

  /* whole table of week and day */
  .rbc-time-view {
    border-radius: 10px;
    margin-top: ${({ isReactNativeApp, isFromScheduleDetails }) =>
      isReactNativeApp && !isFromScheduleDetails ? '170px' : undefined};
  }

  .rbc-button-link {
    color: ${({ theme }) => theme?.colors.Grey60};
  }

  /* To make word don't break on slot card */
  .rbc-day-slot .rbc-event-content {
    width: auto;
  }

  /* Don't show grey background for off range slots */
  .rbc-off-range {
    color: #fff;
  }
  .rbc-off-range-bg {
    background: #fff;
  }

  .rbc-event {
    border: 1px solid #265077;
  }

  /* don't show the space reserved for all day event */
  .rbc-allday-cell {
    height: 0px;
  }

  .rbc-timeslot-group {
    height: 40px;
    min-height: 40px;
    color: ${({ theme }) => theme?.colors.Grey60};
  }

  .rbc-label {
    font-weight: 250;
    font-size: 14px;
  }

  .rbc-time-slot {
    margin-top: 6px;
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: none;
  }

  /*  EXPERIMENT ZONE  */
`
