import { useState } from 'react'
import { Col, Modal } from 'src/components'
import { AddToRosterView } from 'src/components/AddToRosterMenu/AddToRosterView'

export function AddToRosterModal({
  workerIds,
  isOpen,
  handleClose,
  onClickNewRoster,
  onClickAdd,
}: {
  workerIds: string[]
  isOpen: boolean
  handleClose: () => void
  onClickNewRoster: () => void
  onClickAdd?: () => void
}) {
  const [searchFilter, setSearchFilter] = useState('')
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <Col style={{ flex: 1 }}>
        <AddToRosterView
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
          workerIds={workerIds}
          handleClose={handleClose}
          onClickNewRoster={onClickNewRoster}
          onClickAdd={onClickAdd}
        />
      </Col>
    </Modal>
  )
}
