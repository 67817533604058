import { theme } from '@traba/theme'
import { SvgIconProps } from '@traba/types'

export default function MiniCheck({
  size = 16,
  color = theme.colors.MidnightBlue,
  ...props
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 17 17" fill="none" {...props}>
      <path
        d="M2.55929 6.41684C2.99362 4.56523 4.43937 3.11947 6.29099 2.68514C7.62821 2.37147 9.01987 2.37147 10.3571 2.68514C12.2087 3.11947 13.6545 4.56523 14.0888 6.41685C14.4025 7.75406 14.4025 9.14572 14.0888 10.4829C13.6545 12.3346 12.2087 13.7803 10.3571 14.2146C9.01987 14.5283 7.62821 14.5283 6.29099 14.2146C4.43938 13.7803 2.99362 12.3346 2.55929 10.4829C2.24562 9.14572 2.24562 7.75406 2.55929 6.41684Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M6.49071 8.28323L7.82404 9.61656L10.1574 7.11656"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
