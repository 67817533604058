import { trabaApi } from '@traba/api-utils'
import { OUTSIDE_REGION_ID } from '@traba/consts'
import { Region, RegionIdToRegionMap } from '@traba/types'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

const getRegions = async () => {
  try {
    const response = await trabaApi.get(`/my-company/location-regions`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const useLocationRegions = () => {
  const {
    isLoading,
    isError,
    data: regions,
    error,
    isFetched,
  } = useQuery<Region[], Error>('location-regions', getRegions)

  return {
    isLoading,
    isError,
    error,
    isFetched,
    regions,
  }
}

export function useValidRegionsMap() {
  const { isLoading, regions = [] } = useLocationRegions()

  const regionMap = useMemo(() => {
    // Don't show outside region
    const validRegions = (regions || []).filter(
      (region) => region.regionId !== OUTSIDE_REGION_ID,
    )
    return validRegions.reduce<RegionIdToRegionMap>((acc, region) => {
      acc[region.regionId] = region
      return acc
    }, {})
  }, [regions])

  return { isLoading, regionMap }
}
