import { CardTile, LoadingSpinner, MODAL_SIZE } from '@traba/react-components'
import { EmploymentType } from '@traba/types'
import { format, isBefore } from 'date-fns'
import React, { useState } from 'react'
import {
  Button,
  ButtonVariant,
  Col,
  Icon,
  Row,
  SvgIcon,
} from 'src/components/base'
import { Modal } from 'src/components/base/Modal/Modal'
import { Text } from 'src/components/base/Text'
import { SupportModalContent } from 'src/components/SupportModal/SupportModalContent'
import { useCompany } from 'src/hooks/useCompany'
import { useUserPermissions } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { Invoice, UserRolePermission } from 'src/types'

import InvoiceStatusBadge from '../InvoiceStatusBadge/InvoiceStatusBadge.ui'
import { InvoiceButton } from '../InvoiceTable/InvoiceButtons/InvoiceButton'
import { useInvoiceDownload } from '../InvoiceTable/InvoiceButtons/InvoiceButton.hooks'
import * as S2 from '../InvoiceTable/InvoiceTable.styles'
import { Table, Td, Tr } from '../Table/Table'
import InvoiceDetailsFooter from './InvoiceDetailsFooter'
import { InvoiceDetails } from './InvoiceDetailsTable.hooks'
import * as S from './InvoiceDetailsTable.styles'
import InvoiceDisclaimerText from './InvoiceDisclaimerText/InvoiceDisclaimerText'
import { INVOICE_DETAILS_HEADERS } from './utils'

export interface InvoiceDetailsTableProps extends Partial<Invoice> {
  lineItemRows: InvoiceDetailsTableRow[]
  loading: boolean
  invoiceDetails?: InvoiceDetails
  upflowPortalUrl?: string
}

export interface InvoiceDetailsTableRow {
  cells: string[]
  shiftId?: string
}

const DateTile = ({ formattedDate }: { formattedDate: string }) => {
  return (
    <S2.TdMultiLeft>
      <CardTile size="40px">
        <Icon name="document" />
      </CardTile>
      <S2.SmallMarginLeft>{formattedDate}</S2.SmallMarginLeft>
    </S2.TdMultiLeft>
  )
}

export const InvoiceDetailsTableUI: React.FC<InvoiceDetailsTableProps> = (
  props: InvoiceDetailsTableProps,
) => {
  const { loading, lineItemRows, invoiceDetails } = props
  const userCanManageInvoices = useUserPermissions([
    UserRolePermission.ManageInvoices,
  ])
  const [showSupportModal, setShowSupportModal] = useState(false)
  const { isLoading: isLoadingInvoiceDownload, downloadInvoice } =
    useInvoiceDownload(props.invoiceId)
  const { company } = useCompany()

  if (loading || !invoiceDetails) {
    return <LoadingSpinner />
  }

  const {
    hostedInvoiceUrl,
    stripeInvoiceNumber,
    totalCharge,
    dueDate,
    status,
    totalGrossPay,
    invoiceSubtitle,
    stripeInvoiceId,
  } = invoiceDetails

  const isInvoiceInPast = isBefore(dueDate, new Date())
  const isInvoicePaid = status === 'paid'
  return (
    <>
      <S.InvoiceDetailsTableContainer>
        <Row justifyBetween={true} alignCenter={true}>
          <Col>
            <Row>
              <Text variant="h2">Invoice # {stripeInvoiceNumber}</Text>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 12,
                  minWidth: 74,
                }}
              >
                <InvoiceStatusBadge
                  invoiceStatus={status}
                  isPastDueDate={!!isInvoiceInPast}
                />
              </div>
            </Row>
            <Text
              variant="body2"
              style={{ marginTop: '16px', marginBottom: '56px' }}
            >
              {invoiceSubtitle}
              <div style={{ maxWidth: '95%' }}>
                This invoice is due by {format(dueDate, 'M/dd/yyyy')}. <br />
                <InvoiceDisclaimerText
                  isW2Company={company?.activeEmploymentTypes?.includes(
                    EmploymentType.W2,
                  )}
                />
              </div>
            </Text>
          </Col>
          <Row>
            {userCanManageInvoices ? (
              <Button
                onClick={() => setShowSupportModal(true)}
                disabled={isInvoicePaid}
                variant={ButtonVariant.OUTLINED}
                leftIcon={<Icon name="send" />}
              >
                Dispute
              </Button>
            ) : null}
            <InvoiceButton
              invoiceUrl={hostedInvoiceUrl}
              isInvoicePaid={isInvoicePaid}
              stripeInvoiceId={stripeInvoiceId}
              userCanManageInvoices={userCanManageInvoices}
              upflowPortalUrl={props.upflowPortalUrl}
            />
            <Button
              variant={ButtonVariant.OUTLINED}
              onClick={() => downloadInvoice()}
              style={{ marginLeft: theme.space.xxxs }}
              disabled={isLoadingInvoiceDownload}
            >
              <SvgIcon
                color={theme.colors.Grey60}
                size={16}
                name={'download'}
                style={{ marginRight: theme.space.xxs }}
              />
              Download PDF
            </Button>
          </Row>
        </Row>
        <Table headers={INVOICE_DETAILS_HEADERS}>
          {lineItemRows?.map((row: InvoiceDetailsTableRow) => {
            return (
              <Tr
                key={row.shiftId}
                onClick={() =>
                  row.shiftId &&
                  window.open(`/calendar/${row.shiftId}`, '_blank')
                }
                style={{ cursor: row.shiftId ? 'pointer' : 'auto' }}
              >
                {row.cells.map((cell: string, index: number) =>
                  index == 0 ? (
                    <DateTile formattedDate={cell} />
                  ) : (
                    <Td>{cell}</Td>
                  ),
                )}
              </Tr>
            )
          })}
          {totalCharge && totalGrossPay && (
            <InvoiceDetailsFooter
              totalCharge={totalCharge}
              totalGrossPay={totalGrossPay}
            />
          )}
        </Table>
      </S.InvoiceDetailsTableContainer>
      <Modal
        size={MODAL_SIZE.SMALL}
        isOpen={showSupportModal}
        handleClose={() => setShowSupportModal(false)}
      >
        <SupportModalContent
          stripeInvoiceNumber={stripeInvoiceNumber}
          handleClose={() => setShowSupportModal(false)}
        />
      </Modal>
    </>
  )
}
