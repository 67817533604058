import { SvgIconProps } from '@traba/types'

export default function Unfavorite({ size, color, ...rest }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
      <path
        d="M9 13.0002C8.01499 14.3241 7.06366 13.6644 6.07865 12.3405L2.65593 7.74046C1.78136 6.56505 1.78136 4.89486 2.65593 3.71945C3.93619 1.99881 6.89526 2.50984 8 4.36578C9.10474 2.50984 12.0638 1.75835 13.3441 3.47899C14.2186 4.6544 14.2186 6.32459 13.3441 7.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_173_108350)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5 8C10.1193 8 9 9.11929 9 10.5C9 11.8807 10.1193 13 11.5 13C12.8807 13 14 11.8807 14 10.5C14 9.11929 12.8807 8 11.5 8ZM10.9699 9.26256C10.7746 9.0673 10.458 9.0673 10.2628 9.26256C10.0675 9.45783 10.0675 9.77441 10.2628 9.96967L10.7931 10.5L10.2628 11.0303C10.0675 11.2256 10.0675 11.5422 10.2628 11.7374C10.458 11.9327 10.7746 11.9327 10.9699 11.7374L11.5002 11.2071L12.0305 11.7374C12.2258 11.9327 12.5424 11.9327 12.7376 11.7374C12.9329 11.5422 12.9329 11.2256 12.7376 11.0303L12.2073 10.5L12.7376 9.96967C12.9329 9.77441 12.9329 9.45783 12.7376 9.26256C12.5424 9.0673 12.2258 9.0673 12.0305 9.26256L11.5002 9.79289L10.9699 9.26256Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_173_108350">
          <rect width="5" height="5" fill="white" transform="translate(9 8)" />
        </clipPath>
      </defs>
    </svg>
  )
}
