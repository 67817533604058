export enum ParentInvoiceGroupStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export type ParentInvoiceGroup = {
  id: string
  createdAt: Date
  updatedAt?: Date
  name: string
  description?: string
  status: ParentInvoiceGroupStatus
  nextDateToCharge?: Date
  recurring: boolean
  companyId: string
}

export type CreateParentInvoiceGroup = {
  name: string
  description?: string
  recurring: boolean
}

export interface CreateParentInvoiceGroupOps extends CreateParentInvoiceGroup {
  companyId: string
}

export type UpdateParentInvoiceGroup = {
  name?: string
  description?: string
  status?: ParentInvoiceGroupStatus
  recurring?: boolean
}
