import { BrandColors, otherColors } from '@traba/theme'
import styled, { css } from 'styled-components'

type ColorVariant = {
  color: string
  backgroundColor: string
  borderColor?: string
}

export const COLOR_VARIANTS: Record<string, ColorVariant> = {
  info: {
    color: otherColors.Blue80,
    backgroundColor: otherColors.Blue10,
  },
  brand: {
    color: BrandColors.Violet,
    backgroundColor: otherColors.Violet10,
  },
  disabled: {
    color: otherColors.Grey60,
    backgroundColor: otherColors.Grey10,
  },
  success: {
    color: otherColors.Green80,
    backgroundColor: otherColors.Green10,
  },
  warning: {
    color: otherColors.Yellow70,
    backgroundColor: otherColors.Yellow10,
  },
  additional: {
    color: otherColors.Violet70,
    backgroundColor: otherColors.Violet10,
  },
  business: {
    color: otherColors.Orange70,
    backgroundColor: otherColors.Orange10,
  },
  danger: {
    color: otherColors.Red60,
    backgroundColor: otherColors.Red10,
  },
  opaqueRed: {
    color: '#FFFFFF',
    backgroundColor: otherColors.Red40,
  },
  certification: {
    color: otherColors.Orange70,
    backgroundColor: otherColors.Orange10,
  },
  blueCertification: {
    color: otherColors.Blue80,
    backgroundColor: otherColors.Blue10,
  },
  yellow: {
    color: otherColors.Yellow80,
    backgroundColor: otherColors.Yellow10,
  },
  darkOrange: {
    color: otherColors.Peach70,
    backgroundColor: otherColors.Peach10,
  },
  darkRed: {
    color: otherColors.Red70,
    backgroundColor: otherColors.Red10,
  },
  pink: {
    color: otherColors.Red80,
    backgroundColor: '#FFC0CB',
  },
  clear: {
    color: otherColors.Grey50,
    backgroundColor: 'transparent',
    borderColor: otherColors.Grey30,
  },
}

export type BadgeVariant = keyof typeof COLOR_VARIANTS

type BadgeContainerProps = {
  round?: boolean
  variant: BadgeVariant
  hoverBorderColor?: string
  hoverTextColor?: string
  sm?: boolean
}

export const BadgeContainer = styled.div<BadgeContainerProps>`
  border-radius: 4px;
  padding: 6px 12px;
  background-color: ${({ variant }) => COLOR_VARIANTS[variant].backgroundColor};
  color: ${({ variant }) => COLOR_VARIANTS[variant].color};
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${({ variant }) => COLOR_VARIANTS[variant].borderColor || 'transparent'};

  &:hover {
    border-color: ${(props) => props.hoverBorderColor || 'transparent'};
    color: ${(props) =>
      props.hoverTextColor || COLOR_VARIANTS[props.variant].color};
  }

  ${({ round }) =>
    round &&
    css`
      border-radius: 100%;
      padding: 0;
      width: 24px;
      height: 24px;
    `};

  ${({ sm }) =>
    sm &&
    css`
      margin-right: 4px;
      margin-bottom: 4px;
      font-size: 8px;
      padding: 4px 6px;
    `};
`
