import { trabaApi } from '@traba/api-utils'
import {
  ChatArchivalStatus,
  ChatMessageContent,
  ChatReadStatus,
  ChatSummariesResponse,
  ChatSummary,
  UpdateChatRequest,
} from '@traba/types'
import { useQuery } from 'react-query'
import { useAppContext } from 'src/context/appContext/AppContext'

const getChats = async (
  archivalStatus?: ChatArchivalStatus,
  startTime?: Date,
) => {
  try {
    let path = archivalStatus
      ? `/my-company/chats?archivalStatus=${archivalStatus}`
      : `/my-company/chats?archivalStatus=${ChatArchivalStatus.NONE}`

    if (startTime) {
      path += `&startTime=${startTime}`
    }
    const response = await trabaApi.get(path)
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const postChats = async (
  request: ChatMessageContent,
  workerIds: string[],
) => {
  const response = await trabaApi.post(
    `my-company/chats?workerIds=${workerIds}`,
    request,
  )
  return response.data
}

export const updateChat = async (
  request: UpdateChatRequest,
  summaryIds: string[],
) => {
  const response = await trabaApi.patch(
    `my-company/chats?summaryIds=${summaryIds}`,
    request,
  )
  return response.data
}

export const useChats = (
  archivalStatus?: ChatArchivalStatus,
  startTime?: Date,
) => {
  const {
    isLoading,
    isError,
    data: chats,
    refetch,
    error,
    isFetched,
  } = useQuery<ChatSummariesResponse, Error>(
    ['chats', archivalStatus, startTime],
    () => getChats(archivalStatus, startTime),
  )

  // we are intentionally not using useSelectedRegionalFilterLocations b/c the chat window is
  // preserved across multiple pages and appearing/disappearing chats is a bad UX
  // --> this is still not the best UX but will need to be revisited
  const {
    state: { regionalFilter },
  } = useAppContext()
  const selectedRegionIds = new Set(Object.keys(regionalFilter || {}))
  const filteredChats = chats?.summaries.filter((chat: ChatSummary) =>
    selectedRegionIds.has(chat.recipient.regionId),
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    refetch,
    allChats: chats?.summaries,
    chats: filteredChats,
    unReadChats: chats?.summaries.filter(
      (chat: ChatSummary) => chat.readStatus === ChatReadStatus.UNREAD,
    ),
  }
}
