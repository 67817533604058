import { isEmpty } from 'lodash'
import React from 'react'
import { ButtonVariant, Text } from 'src/components/base'
import { Button, Icon } from 'src/components/base'
import { CreditCardTile } from 'src/components/base/CreditCardTile'
import { useBilling } from 'src/hooks/useBilling'

import { Billing } from '../../types'
import * as S from './BillingProfile.styles'

export type BillingProfileProps = {
  billing: Billing
  userCanEditBilling: boolean
  isLoading?: any
}

export const BillingProfileUI: React.FC<BillingProfileProps> = (props) => {
  const { billing, userCanEditBilling } = props
  const {
    paymentMethods,
    paymentSettings: { phoneNumber = '', email = '' } = {},
  } = billing
  const hasPaymentMethods = !isEmpty(paymentMethods)
  const { startStripeSetupIntent, setIsLoading, stripe } = useBilling()
  return (
    <S.BillingProfileContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text variant="body2">
          {userCanEditBilling ? 'Manage' : 'View'} your company‘s billing
          information.
        </Text>
        {!hasPaymentMethods || !userCanEditBilling ? (
          false
        ) : (
          <Button
            style={{ padding: '0px 0px 0px 24px', color: '#8000ff' }}
            leftIcon={<Icon name="editPurple" />}
            reverse
            variant={ButtonVariant.TEXT}
            onClick={async () => {
              setIsLoading(true)
              const checkoutSessionId = await startStripeSetupIntent({
                email,
                phoneNumber,
                returnPath: '/account-settings',
              })
              await stripe.redirectToCheckout({
                sessionId: checkoutSessionId,
              })
            }}
          >
            Update via Stripe
          </Button>
        )}
      </div>
      {hasPaymentMethods ? (
        <CreditCardTile {...props.billing.paymentMethods[0].card} />
      ) : (
        false
      )}
    </S.BillingProfileContainer>
  )
}
