import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUserContext } from 'src/context/user/UserContext'
import useMobile from 'src/hooks/useMobile'
import { hasPermissions } from 'src/utils/userUtils'
import { MobileDisclaimer } from '../../Disclaimer/MobileDisclaimer'
import { NotificationSettings } from '../../NotificationSettings/NotificationSettings'
import { ACCOUNT_SETTINGS_TABS } from '../constants'
import { SETTINGS_TAB_KEYS } from '../types'
import { getInitialTabIndex } from '../utils'

export const useAccountSettings = () => {
  const { isExclusivelyMobileView } = useMobile()
  const navigate = useNavigate()
  const location = useLocation()
  const userContext = useUserContext()

  const activeTabs = useMemo(
    () =>
      ACCOUNT_SETTINGS_TABS.map((tab) => {
        switch (tab.key) {
          case SETTINGS_TAB_KEYS.NOTIFICATION_SETTINGS:
            // On mobile web, show the mobile disclaimer instead of the
            // notification settings view if the user clicks on the notification
            // settings tab. This isn't handled at the component level
            // (<NotificationSettingsTables />) because the component is a shared
            // component and therefore we can't access the business app-specific
            // hooks to determine if the user is on mobile web.
            return {
              ...tab,
              Component: isExclusivelyMobileView
                ? MobileDisclaimer
                : NotificationSettings,
            }
          default:
            return tab
        }
      }).filter((tab) =>
        hasPermissions(userContext.state.userProfile, tab.permissions),
      ),
    [userContext.state.userProfile, isExclusivelyMobileView],
  )

  const [currentTabIndex, setCurrentTabIndex] = useState(
    getInitialTabIndex(location.search, activeTabs),
  )

  useEffect(() => {
    const { key } = activeTabs[currentTabIndex]
    navigate('/account-settings', { replace: true, state: { tab: key } })
    window.analytics.track(`User Navigated to Account Settings Tab`, {
      tab: key,
    })
  }, [navigate, activeTabs, currentTabIndex])

  return { currentTabIndex, setCurrentTabIndex, activeTabs }
}
