import { SvgIconProps } from '@traba/types'

export default function User({
  size = 16,
  color = '#08105E',
  strokeWidth = '1.5',
  ...rest
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 12.6361C3 10.8933 4.21238 10.4088 5.86003 10.1341L6.00844 10.1094C7.3278 9.88946 8.6722 9.88946 9.99156 10.1094L10.14 10.1341C11.7876 10.4088 13 10.8933 13 12.6361C13 13.3893 12.4154 14 11.6942 14H4.30581C3.58463 14 3 13.3893 3 12.6361Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M10.9167 4.84375C10.9167 6.41431 9.61085 7.6875 8.00001 7.6875C6.38918 7.6875 5.08335 6.41431 5.08335 4.84375C5.08335 3.27319 6.38918 2 8.00001 2C9.61085 2 10.9167 3.27319 10.9167 4.84375Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
