import { Tooltip } from '@mui/material'
import { MODAL_SIZE, LoadingSpinner } from '@traba/react-components'
import { Shift, ShiftStatus } from '@traba/types'
import { differenceInHours } from 'date-fns'
import { useState } from 'react'
import { Button, ButtonVariant, Icon, SvgIcon } from 'src/components/base'
import { Modal } from 'src/components/base/Modal/Modal'
import { ErrorModal } from 'src/components/Modals/ErrorModal'
import { useCompany } from 'src/hooks/useCompany'
import useMobile from 'src/hooks/useMobile'
import { useShiftsByShiftRequestId } from 'src/hooks/useShifts'
import { useHotSettings } from 'src/hooks/useSystem'
import EditShiftMenu from 'src/screens/EditShifts/EditShiftsMenu'

import { CancelShiftModal } from '../../Modals/CancelShiftModal'
import { SeeInOpsConsoleAnchor } from '../seeInOpsConsoleAnchor/seeInOpsConsoleAnchor'
import * as S from './ShiftDetailsHeader.styles'

export interface ShiftDetailsHeaderProps {
  showApproveHoursButton?: boolean
  shift: Shift
  userCanManageShift: boolean
}

export const ShiftDetailsHeader = (props: ShiftDetailsHeaderProps) => {
  const { isMobileViewOrReactNative } = useMobile()

  const {
    shift: { status },
    userCanManageShift,
  } = props
  const { company, isError: companyError } = useCompany()
  const [modifyModalVisible, setModifyModalVisible] = useState(false)
  const { shifts, isError } = useShiftsByShiftRequestId(
    props.shift.shiftRequestId,
    true,
  )
  const allowShiftActions =
    status === ShiftStatus.ACTIVE &&
    differenceInHours(
      props.shift.businessStartTime ?? props.shift.startTime,
      new Date(),
    ) > 1
  const { hotSettings } = useHotSettings()
  const shouldFreezeShiftPosting = hotSettings?.freezeShiftsPosting

  const errorModalFunc = () => {
    setModifyModalVisible(false)
  }

  const shiftActions = (
    <S.ActionItemsContainer>
      <SeeInOpsConsoleAnchor
        linkSuffix={`field-monitor/${props.shift.shiftId}`}
      />
      {allowShiftActions ? (
        <>
          <Tooltip
            title={
              shouldFreezeShiftPosting
                ? `Shift cancellation temporarily disabled`
                : ''
            }
          >
            <Button
              onClick={() => setModifyModalVisible(true)}
              variant={ButtonVariant.OUTLINED}
              leftIcon={<Icon name="cancel" />}
              disabled={shouldFreezeShiftPosting}
            >
              Cancel
            </Button>
          </Tooltip>
          <EditShiftMenu
            shift={props.shift}
            isSeries={!!(shifts?.length && shifts.length > 1)}
          />
        </>
      ) : null}
    </S.ActionItemsContainer>
  )

  const MobileShiftActions = (
    <>
      <SeeInOpsConsoleAnchor
        linkSuffix={`field-monitor/${props.shift.shiftId}`}
      />
      <SvgIcon
        name="cancel"
        onClick={() => {
          if (!shouldFreezeShiftPosting) {
            setModifyModalVisible(true)
          }
        }}
      />
      <EditShiftMenu
        shift={props.shift}
        isSeries={!!(shifts?.length && shifts.length > 1)}
      />
    </>
  )

  return (
    <>
      {isMobileViewOrReactNative ? (
        userCanManageShift && allowShiftActions ? (
          MobileShiftActions
        ) : null
      ) : (
        <S.ShiftDetailsHeaderContainer>
          {userCanManageShift ? shiftActions : null}
        </S.ShiftDetailsHeaderContainer>
      )}
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={modifyModalVisible}
        handleClose={() => setModifyModalVisible(false)}
      >
        {isError || companyError ? (
          <ErrorModal
            cancelFunc={errorModalFunc}
            titleText={'There was an error loading your shift details'}
            bodyText={'Please try again later'}
          />
        ) : company && shifts && shifts.length > 0 ? (
          <CancelShiftModal
            shift={props.shift}
            allShiftsInSeries={shifts}
            setModalVisible={setModifyModalVisible}
            company={company}
          />
        ) : (
          <LoadingSpinner />
        )}
      </Modal>
    </>
  )
}
