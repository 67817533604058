import {
  Tab as MuiTab,
  Tabs as MuiTabs,
  TabProps as MuiTabProps,
  TabsProps as MuiTabsProps,
} from '@mui/material'
import styled from 'styled-components'

interface TabProps extends MuiTabProps {
  isReactNative?: boolean
}

interface TabsProps extends MuiTabsProps {
  isReactNative?: boolean
}

export const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: ${({ theme }) => theme.space.sm}px 0
    ${({ theme }) => theme.space.sm}px;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.colors.Grey};
`

export const Tabs = styled(MuiTabs)<TabsProps>`
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.colors.Grey};
  display: ${({ isReactNative }) => (isReactNative ? 'flex' : 'auto')};
`

export const Tab = styled(MuiTab)<TabProps>`
  display: flex; // Set display to flex
  justify-content: center; // Center content horizontally
  align-self: center; // Center content vertically
  align-items: center; // Center content vertically
  font-family: Poppins;
  font-weight: 600;
  font-size: ${({ isReactNative }) => (isReactNative ? '14px' : '16px')};
  line-height: 24px;
  letter-spacing: -0.004em;
  color: ${({ theme }) => theme.colors.Grey60};
  text-transform: none;
  padding-left: 0;
  padding-right: 0;
  margin-right: ${({ isReactNative }) => (isReactNative ? 'auto' : '40px')};
  align-items: flex-start;
  min-width: unset;
  flex-grow: ${({ isReactNative }) => (isReactNative ? '1' : 'auto')};
  > * {
    font-weight: 600;
  }

  &.Mui-selected,
  &:hover {
    color: ${({ theme }) => theme.colors.MidnightBlue};
  }
`

export const MobileTab = styled(MuiTab)`
  font-family: Poppins;
  letter-spacing: -0.004em;
  color: ${({ theme }) => theme.colors.Grey60};
  text-transform: none;
  padding-left: ${({ theme }) => theme.space.ms}px;
  padding-right: ${({ theme }) => theme.space.ms}px;
  margin-right: ${({ theme }) => theme.space.ms}px;
  align-items: flex-start;
  min-width: unset;
  > * {
    font-weight: 600;
  }

  &.Mui-selected,
  &:hover {
    color: ${({ theme }) => theme.colors.MidnightBlue};
  }
`

export const TabPanelContainer = styled.div<{ overflowX?: boolean }>`
  width: 100%;
  overflow-x: ${({ overflowX }) => (overflowX ? 'auto' : 'hidden')};
`

export function TabPanel(props: any) {
  const { children, value, index, overflowX } = props

  return (
    <TabPanelContainer overflowX={overflowX}>
      {value === index && <span>{children}</span>}
    </TabPanelContainer>
  )
}
