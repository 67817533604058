import { Collapse } from '@mui/material'
import { makePlural } from '@traba/string-utils'
import { ForwardFillMax, RoleInfoForCreateShiftRequest } from '@traba/types'
import { useState } from 'react'
import { Button, ButtonVariant, Icon, Row, Text } from 'src/components'
import { WorkerDetails } from 'src/hooks/useCompanyWorkers'
import { theme } from 'src/libs/theme'

export function WorkersInvited({
  roleInfo,
  workersMap,
  subdued,
}: {
  roleInfo: RoleInfoForCreateShiftRequest
  workersMap: Map<string, WorkerDetails>
  subdued?: boolean
}) {
  const [expanded, setExpanded] = useState(false)
  const { shiftInvitations, forwardFillMax } = roleInfo

  const numWorkersInvited =
    shiftInvitations?.reduce(
      (total, invitation) => total + invitation.workers.length,
      0,
    ) ?? 0

  if (
    !numWorkersInvited ||
    (forwardFillMax !== ForwardFillMax.INVITED_ONLY &&
      forwardFillMax !== ForwardFillMax.INVITED_FIRST)
  ) {
    return null
  }

  return (
    <div>
      <Row alignCenter justifyBetween>
        <Text variant={!subdued ? 'h6' : 'body1'}>
          {numWorkersInvited} Worker{makePlural(numWorkersInvited)} Invited
        </Text>
        <Button
          variant={ButtonVariant.BRANDLINK}
          onClick={() => {
            setExpanded((prev) => !prev)
            window.analytics.track(
              `User ${expanded ? 'Collapsed' : 'Expanded'} Invited Workers`,
            )
          }}
        >
          <Icon
            name={expanded ? 'arrowTop_primary' : 'arrowBottom_primary'}
            style={{
              paddingRight: !subdued ? theme.space.xxs : theme.space.zero,
            }}
          />
          {subdued ? null : (
            <Text variant="link1" className="xs-hide sm-show">
              {expanded ? 'Hide' : 'Show'} all shifts
            </Text>
          )}
        </Button>
      </Row>
      <Collapse in={expanded}>
        {shiftInvitations?.map((shiftInvitation, i) =>
          shiftInvitation.workers.map((worker, j) => (
            <Text
              key={`shiftInvitation_${i}_${j}`}
              variant="body1"
              style={{
                ...(!subdued && { marginTop: theme.space.xxs }),
              }}
            >
              {workersMap.get(worker.workerId)?.worker.firstName}{' '}
              {workersMap.get(worker.workerId)?.worker.lastName}
            </Text>
          )),
        )}
        <Text variant="body1" mt="xxs">
          {`We WILL ${
            forwardFillMax === ForwardFillMax.INVITED_ONLY ? 'NOT' : ''
          } invite other qualified Traba workers if these workers are
            not available.`}
        </Text>
      </Collapse>
    </div>
  )
}
