import { Col, MODAL_SIZE, MultiDatePicker } from '@traba/react-components'
import { ShiftRequestParentWithShiftRequest } from '@traba/types'
import {
  combineTwoDatesForDateAndTime,
  dateMinFromArray,
  getMinDateForScheduleChangeDatePicker,
  getShiftDatesForSchedules,
  isRecurringShiftRequest,
} from '@traba/utils'
import { isSameDay } from 'date-fns'
import { Dispatch, SetStateAction, useState } from 'react'
import { DateObject } from 'react-multi-date-picker'
import {
  Button,
  ButtonVariant,
  Modal,
  Row,
  SvgIcon,
  Text,
} from 'src/components'
import { theme } from 'src/libs/theme'

export function EditScheduleSaveChangesModal({
  showSelectModal,
  setShowSelectModal,
  setSelectedEffectiveDate,
  onSaveChanges,
  isLoadingShifts,
  shiftRequestParent,
}: {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  setSelectedEffectiveDate: Dispatch<SetStateAction<Date | null>>
  showSelectModal?: boolean
  setShowSelectModal: Dispatch<SetStateAction<boolean>>
  onSaveChanges: () => Promise<void>
  isLoadingShifts: boolean
}) {
  const [isUpdating, setIsUpdating] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date | null>()
  const firstRecurringShiftRequest = shiftRequestParent.shiftRequests.find(
    isRecurringShiftRequest,
  )
  if (isLoadingShifts || !firstRecurringShiftRequest) {
    return null
  }

  const shiftTimes = getShiftDatesForSchedules(
    firstRecurringShiftRequest.schedules,
  )

  const mapDaysForDatePicker = ({ date }: { date: DateObject }) => {
    const isUnavailable = !shiftTimes.some((shiftTime) =>
      isSameDay(date.toDate(), new Date(shiftTime)),
    )
    return {
      disabled: isUnavailable,
    }
  }

  return (
    <Modal
      size={MODAL_SIZE.SMALL}
      isOpen={!!showSelectModal}
      handleClose={() => {
        setShowSelectModal(false)
      }}
    >
      <Col p={theme.space.xl}>
        <Col style={{ minHeight: 350 }} gap={theme.space.xs}>
          <Row justifyBetween alignCenter>
            <Text variant="h5">When should your changes take effect?</Text>
            <SvgIcon
              name="cancel"
              color={theme.colors.Grey60}
              size={theme.space.sm}
              style={{ cursor: 'pointer' }}
              onClick={() => setShowSelectModal(false)}
            />
          </Row>
          <Text variant="body2">
            For example, if you want changes to take effect starting on and
            after June 30th, select June 30th
          </Text>
          <Row alignCenter gap={theme.space.xxs} mb={theme.space.xxl}>
            <SvgIcon name="calendar" color={theme.colors.Violet} />
            <MultiDatePicker
              minDate={getMinDateForScheduleChangeDatePicker(
                combineTwoDatesForDateAndTime(
                  new Date(),
                  dateMinFromArray(
                    firstRecurringShiftRequest.schedules.map(
                      (s) => s.startTime,
                    ),
                  ),
                ),
              )}
              selectedDates={selectedDate ? [selectedDate] : null}
              onSelectDates={(dates: Date[]) => {
                setSelectedDate(dates[0])
                setSelectedEffectiveDate(dates[0])
              }}
              multiple={false}
              mapDays={mapDaysForDatePicker}
            />
          </Row>
        </Col>
        <Row justifyBetween>
          <Button
            onClick={() => {
              setShowSelectModal(false)
            }}
            variant={ButtonVariant.OUTLINED}
          >
            Cancel
          </Button>
          <Button
            loading={isUpdating}
            disabled={!selectedDate}
            onClick={async () => {
              try {
                setIsUpdating(true)
                await onSaveChanges()
                setIsUpdating(false)
                setShowSelectModal(false)
              } catch (e) {
                console.error(e)
              } finally {
                setIsUpdating(false)
              }
            }}
          >
            Confirm
          </Button>
        </Row>
      </Col>
    </Modal>
  )
}
