import { Modal } from '@mui/material'
import { Announcement, AnnouncementStatus } from '@traba/types'
import { formatDistanceToNow, isAfter } from 'date-fns'
import { useState } from 'react'
import { Col, Divider, Icon, Row, Text } from 'src/components/base'
import { BULLET_CHAR } from 'src/libs/constants'
import { theme } from 'src/libs/theme'

import { formatTimeDateString } from 'src/shared/utils/dateUtils'
import Badge from '../../base/Badge'

interface Props {
  announcement: Announcement
  timezone?: string
}

export const AnnouncementItem = ({ announcement, timezone }: Props) => {
  const isSent = announcement.status === AnnouncementStatus.SENT
  const [showImageModal, setShowImageModal] = useState(false)

  // If the announcement is pending and scheduled for the future, show the
  // scheduled time, else show the distance to the time from when the
  // announcement was sent
  function getRelevantAnnouncementTime() {
    const isPending = announcement.status === AnnouncementStatus.PENDING
    const isScheduledForFuture =
      announcement?.scheduledFor &&
      isAfter(announcement?.scheduledFor, new Date())
    if (isPending && isScheduledForFuture) {
      const tz = timezone || announcement.shift?.timezone
      if (tz) {
        return `Scheduled for ${formatTimeDateString(
          announcement.scheduledFor!,
          tz,
        )}`
      } else {
        return 'Scheduled for later'
      }
    }
    return formatDistanceToNow(announcement.createdAt, {
      addSuffix: true,
    })
  }

  return (
    <Col>
      <Row justifyBetween mt={theme.space.sm}>
        <Text mr={theme.space.xl} variant="h5" style={{ maxWidth: 800 }}>
          {announcement.content}
          {announcement.imageUrls?.[0] && (
            <Text
              ml={theme.space.xxs}
              variant="linkUnderline"
              onClick={() => setShowImageModal(true)}
            >
              See attached image
            </Text>
          )}
        </Text>
        <Badge
          style={{ alignSelf: 'flex-start' }}
          variant={isSent ? 'success' : 'warning'}
          title={announcement.status}
        />
      </Row>

      <Text mb={theme.space.xs} variant="body2">{`Sent by ${
        announcement.senderEmail
      } ${BULLET_CHAR} ${getRelevantAnnouncementTime()}`}</Text>
      <Divider />

      <Modal
        open={!!showImageModal}
        onClose={() => setShowImageModal(false)}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        disableAutoFocus
      >
        <div>
          <img
            src={announcement.imageUrls?.[0]}
            style={{
              maxHeight: '300px',
              width: 'auto',
              borderRadius: theme.space.xxs,
            }}
            alt="announcement"
          />

          <Icon
            style={{
              position: 'absolute',
              top: theme.space.xxs,
              right: theme.space.xxs,
            }}
            height={theme.space.xs}
            name="cancel"
            onClick={() => setShowImageModal(false)}
          />
        </div>
      </Modal>
    </Col>
  )
}
