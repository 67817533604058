import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { CompanyWorkerFeedback } from '@traba/types'
import { useQuery } from 'react-query'

export interface WorkerMostRecentReviewResponseDto {
  mostRecentReview?: CompanyWorkerFeedback
}

const getMostRecentReview = async (
  workerId: string,
): Promise<WorkerMostRecentReviewResponseDto | undefined> => {
  try {
    const response = await trabaApi.get(
      `/my-company/workers/${workerId}/most-recent-review`,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const useMostRecentWorkerReview = (workerId: string) => {
  const {
    isLoading,
    isError,
    data: mostRecentReview,
    error,
    isFetched,
    refetch,
  } = useQuery<WorkerMostRecentReviewResponseDto | undefined, Error>(
    `most-recent-review_${workerId}`,
    () => getMostRecentReview(workerId),
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    mostRecentReview,
    refetch,
  }
}
