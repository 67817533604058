import { SvgIconProps } from '@traba/types'

export default function Award({
  size = 16,
  strokeWidth,
  color = '#9933FF',
  ...props
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.71372 9.64003L4.94914 12.6446C4.71958 13.5467 5.68457 14.2964 6.54401 13.8837L7.83254 13.2648C8.25313 13.0628 8.74703 13.0628 9.16762 13.2648L10.4561 13.8837C11.3156 14.2964 12.2806 13.5467 12.051 12.6446L11.2864 9.64003C10.8995 9.91597 10.4532 10.1191 9.96839 10.2293C9.00262 10.4487 7.99754 10.4487 7.03177 10.2293C6.54695 10.1191 6.10065 9.91597 5.71372 9.64003Z"
        fill="none"
        strokeWidth={strokeWidth}
      />
      <path
        d="M4.33665 4.77482C4.65034 3.47964 5.69449 2.46836 7.03177 2.16455C7.99754 1.94515 9.00263 1.94515 9.96839 2.16455C11.3057 2.46836 12.3498 3.47965 12.6635 4.77482C12.89 5.71018 12.89 6.68362 12.6635 7.61898C12.3498 8.91416 11.3057 9.92544 9.96839 10.2293C9.00262 10.4487 7.99754 10.4487 7.03177 10.2293C5.69449 9.92545 4.65034 8.91416 4.33665 7.61899C4.11011 6.68363 4.11011 5.71018 4.33665 4.77482Z"
        fill="none"
        strokeWidth={strokeWidth}
      />
      <path
        d="M9.96839 10.2293C10.4532 10.1191 10.8995 9.91597 11.2864 9.64003L12.051 12.6446C12.2806 13.5467 11.3156 14.2964 10.4561 13.8837L9.16762 13.2648C8.74703 13.0628 8.25313 13.0628 7.83254 13.2648L6.54401 13.8837C5.68457 14.2964 4.71958 13.5467 4.94914 12.6446L5.71372 9.64003C6.10065 9.91597 6.54695 10.1191 7.03177 10.2293M9.96839 10.2293C9.00262 10.4487 7.99754 10.4487 7.03177 10.2293M9.96839 10.2293C11.3057 9.92544 12.3498 8.91416 12.6635 7.61898C12.89 6.68362 12.89 5.71018 12.6635 4.77482C12.3498 3.47965 11.3057 2.46836 9.96839 2.16455C9.00263 1.94515 7.99754 1.94515 7.03177 2.16455C5.69449 2.46836 4.65034 3.47964 4.33665 4.77482C4.11011 5.71018 4.11011 6.68363 4.33665 7.61899C4.65034 8.91416 5.69449 9.92545 7.03177 10.2293"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
