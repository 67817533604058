import { Modal } from '@mui/material'
import React, { useCallback } from 'react'
import { theme } from 'src/libs/theme'

import Icon from '../Icon'

export interface ImageProps {
  src: string
  alt: string
  style?: { [key: string]: string | number }
  expandable?: boolean
}

export const Image = (props: ImageProps) => {
  const { src, alt, style, expandable, ...rest } = props
  const [expanded, setExpanded] = React.useState(false)

  const onClose = useCallback(() => {
    setExpanded(false)
  }, [])

  return (
    <>
      {expanded && (
        <Modal
          open
          onClose={onClose}
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          disableAutoFocus
        >
          <div>
            <img
              src={src}
              alt={alt || 'worker-profile-image'}
              style={{
                maxHeight: '300px',
                borderRadius: theme.space.xxs,
              }}
              draggable={false}
            />
            <Icon
              style={{
                position: 'absolute',
                top: theme.space.xxs,
                right: theme.space.xxs,
                color: theme.colors.Green80,
              }}
              height={theme.space.xs}
              name="cancel"
              onClick={onClose}
            />
          </div>
        </Modal>
      )}
      <img
        src={src}
        alt={alt}
        style={{ ...style, cursor: expandable ? 'pointer' : 'default' }}
        {...rest}
        onClick={() => expandable && setExpanded(true)}
      />
    </>
  )
}

export default Image
