import { theme } from 'src/libs/theme'

import { Row, Text } from '..'

export const RookieBadge = () => {
  return (
    <Row
      alignCenter
      style={{
        backgroundColor: theme.colors.Violet10,
        borderRadius: 4,
        marginTop: 3,
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 3,
        paddingBottom: 3,
      }}
    >
      <Text color={theme.colors.Violet70} variant="caption">
        FIRST SHIFT WITH YOU
      </Text>
    </Row>
  )
}
