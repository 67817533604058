import { Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import useMobile from 'src/hooks/useMobile'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { GoToScheduleTabButton } from './GoToScheduleTabButton'
import { ScheduleDailyViewWorkerTableContent } from './ScheduleDailyViewWorkerTableContent'
import { ScheduleDailyViewWorkerTableContentMobile } from './ScheduleDailyViewWorkerTableContentMobile'

interface Props {
  shifts?: ShiftAndAddress[]
  shiftRequestParentId: string
  dayOfShift: string
}

export const ScheduleDailyViewWorkerTable = (props: Props) => {
  const { shifts, shiftRequestParentId, dayOfShift } = props
  const { isMobileViewOrReactNative } = useMobile()

  if (!shifts) {
    return null
  }

  return (
    <>
      <Row alignEnd>
        <Text variant="h4" style={{ marginRight: theme.space.xxs }}>
          Workers
        </Text>
        <Text variant="h6" color={theme.colors.Grey60}>
          on this shift
        </Text>
      </Row>
      <Text variant="body2" mb={theme.space.xs}>
        Manage the workers on {dayOfShift} here. If you want to manage workers
        on the full schedule, you can do so by{' '}
        <GoToScheduleTabButton
          shiftRequestParentId={shiftRequestParentId}
          tab={2}
        />
      </Text>
      {isMobileViewOrReactNative ? (
        <ScheduleDailyViewWorkerTableContentMobile shifts={shifts} />
      ) : (
        <ScheduleDailyViewWorkerTableContent shifts={shifts} />
      )}
    </>
  )
}
