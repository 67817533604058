import { CardTile } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Worker } from '@traba/types'
import { useNavigate } from 'react-router-dom'
import { Col, Image, Row, Text } from 'src/components/base'
import { RookieBadge } from 'src/components/RookieBadge/RookieBadge'
import { WorkerLinkText } from 'src/components/WorkerDetails/WorkerLinkText'
import { getInitials } from 'src/utils/stringUtils'

interface Props {
  worker: Worker
  isFirstTimeWorkerShiftWithCompany?: boolean
  disableLink?: boolean
  onClickWorkerName?: () => void
}

export const WorkerPhotoAndName = (props: Props) => {
  const {
    worker,
    isFirstTimeWorkerShiftWithCompany,
    disableLink,
    onClickWorkerName,
  } = props
  const navigate = useNavigate()
  return (
    <Row gap={theme.space.xxs} alignCenter>
      <CardTile size="48px" style={{ minWidth: 48 }}>
        {worker.photoUrl ? (
          <Image
            style={{
              width: 48,
              height: 48,
              borderRadius: theme.space.xxs,
            }}
            src={worker.photoUrl}
            alt="worker-photo"
          />
        ) : (
          <Text variant="h5">
            {getInitials(worker.firstName, worker.lastName)}
          </Text>
        )}
      </CardTile>
      <Col>
        {disableLink ? (
          <Text>
            {worker.firstName} {worker?.lastName}
          </Text>
        ) : (
          <WorkerLinkText
            onClick={() => {
              onClickWorkerName?.()
              navigate(`/worker/${worker.workerId}`)
            }}
          >
            {worker.firstName} {worker?.lastName}
          </WorkerLinkText>
        )}
        {isFirstTimeWorkerShiftWithCompany && <RookieBadge />}
      </Col>
    </Row>
  )
}
