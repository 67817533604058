import * as Sentry from '@sentry/react'
import {
  AlertAction,
  AlertDispatch,
  AlertReducer,
  AlertState,
} from '@traba/types'
import { createContext, useCallback, useContext, useReducer } from 'react'

const initialState: AlertState = {
  severity: 'success',
  message: '',
  title: '',
  durationInMs: 5000,
  active: false,
}

function alertReducer(state: AlertState, action: AlertAction): AlertState {
  const stateMod = {
    ...state,
    active: true,
    title: action.title || '',
    durationInMs: action.durationInMs || 10000,
    message: action.message || '',
  }
  switch (action.type) {
    case 'SHOW_SUCCESS':
      return {
        ...stateMod,
        severity: 'success',
      }
    case 'SHOW_INFO':
      return {
        ...stateMod,
        severity: 'info',
      }
    case 'SHOW_WARNING':
      return {
        ...stateMod,
        severity: 'warning',
      }
    case 'SHOW_ERROR':
      return {
        ...stateMod,
        severity: 'error',
      }
    case 'CLOSE':
      return { ...state, active: false }

    default:
      throw new Error()
  }
}

const AlertContext = createContext<{
  state: AlertState
  dispatch: AlertDispatch
  showSuccess: (message: string, title?: string, durationInMs?: number) => void
  showInfo: (message: string, title: string, durationInMs?: number) => void
  showError: (message: string, title?: string, durationInMs?: number) => void
  showWarning: (message: string, title: string, durationInMs?: number) => void
  cancelAlert: () => void
  handleError: (
    error: unknown,
    location: string,
    message: string,
    title?: string,
    durationInMs?: number,
  ) => void
}>({
  state: initialState,
  dispatch: () => null,
  showSuccess: () => null,
  showError: () => null,
  showInfo: () => null,
  showWarning: () => null,
  cancelAlert: () => null,
  handleError: () => null,
})

export function useAlert() {
  return useContext(AlertContext)
}

export function AlertContextProvider({ children }: { children: JSX.Element }) {
  const [state, dispatch] = useReducer<AlertReducer>(alertReducer, initialState)

  const showSuccess = useCallback(
    (message: string, title?: string, durationInMs?: number) =>
      dispatch({
        type: 'SHOW_SUCCESS',
        title,
        message,
        durationInMs,
      }),
    [],
  )
  const showWarning = useCallback(
    (message: string, title: string, durationInMs?: number) =>
      dispatch({
        type: 'SHOW_WARNING',
        title,
        message,
        durationInMs,
      }),
    [],
  )
  const showInfo = useCallback(
    (message: string, title: string, durationInMs?: number) =>
      dispatch({
        type: 'SHOW_INFO',
        title,
        message,
        durationInMs,
      }),
    [],
  )
  const showError = useCallback(
    (message: string, title?: string, durationInMs?: number) =>
      dispatch({
        type: 'SHOW_ERROR',
        title,
        message,
        durationInMs,
      }),
    [],
  )
  const cancelAlert = useCallback(() => dispatch({ type: 'CLOSE' }), [])

  const handleError = (
    error: unknown,
    location: string,
    message: string,
    title?: string,
    durationInMs?: number,
  ) => {
    Sentry.captureException(`${location} ERROR ${error}`)
    console.error(error)
    return showError(message, title, durationInMs)
  }

  const exportedContext = {
    state,
    dispatch,
    showSuccess,
    showError,
    showInfo,
    showWarning,
    cancelAlert,
    handleError,
  }

  return (
    <AlertContext.Provider value={exportedContext}>
      {children}
    </AlertContext.Provider>
  )
}
