import { useAlert } from '@traba/context'
import { ModalPaddingContainer } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { ParentInvoiceGroup, ParentInvoiceGroupStatus } from '@traba/types'
import { useState } from 'react'
import { Button, ButtonVariant, Row, Text } from 'src/components/base'
import { InvoiceGroup } from 'src/components/BillingSettings/InvoiceGroup'
import { ButtonIcon } from 'src/components/PersonalProfile/PersonalProfile.styles'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { theme } from 'src/libs/theme'

type ArchiveInvoiceGroupModalProps = {
  group: ParentInvoiceGroup
  handleClose: () => void
}

export const ArchiveInvoiceGroupModal = (
  props: ArchiveInvoiceGroupModalProps,
) => {
  const [archiving, setArchiving] = useState(false)

  const { editInvoiceGroup } = useInvoiceGroups()
  const { showError } = useAlert()

  const handleArchiveClick = async () => {
    setArchiving(true)
    await editInvoiceGroup(
      {
        groupId: props.group.id,
        updateGroupData: { status: ParentInvoiceGroupStatus.ARCHIVED },
      },
      {
        onSettled: () => {
          setArchiving(false)
          props.handleClose()
        },
        onError: () => {
          showError(
            'An error has occurred. Please try again or contact support if the issue persists.',
            'Unable to archive invoice group',
          )
        },
      },
    )
    window.analytics.track(`User Archived Invoice Group`, {
      invoiceGroup: props.group,
    })
  }

  return (
    <ModalPaddingContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Text variant="h2">Archive Invoice Group</Text>
        <ButtonIcon name="cancel" onClick={() => props.handleClose()} />
      </div>

      <Text variant="body2">
        Archiving an invoice group will remove it from the dropdown list when
        creating a shift. However, any outstanding shifts remaining in the group
        will still be invoiced as such.
      </Text>

      <Text
        style={{ marginTop: theme.space.xs, marginBottom: theme.space.xxs }}
        variant="h5"
      >
        Invoice group to archive
      </Text>

      <Card
        style={{
          marginTop: '8px',
          width: '100%',
        }}
      >
        <InvoiceGroup group={props.group} />
      </Card>

      <Row justifyBetween pt={theme.space.med}>
        <Button
          type="button"
          variant={ButtonVariant.OUTLINED}
          onClick={() => props.handleClose()}
        >
          Cancel
        </Button>
        <Button
          variant={ButtonVariant.CANCEL}
          loading={archiving}
          onClick={handleArchiveClick}
        >
          Archive
        </Button>
      </Row>
    </ModalPaddingContainer>
  )
}
