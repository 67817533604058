import { Button, SvgIcon, Text, ButtonVariant } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Td } from '../../Table/Table'

export function AddCostCentersButton({
  handleAddCostCentersClick,
}: {
  handleAddCostCentersClick: () => void
}) {
  return (
    <Td
      style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
      onClick={handleAddCostCentersClick}
    >
      <Button variant={ButtonVariant.BRANDLINK}>
        <Text variant="link">Add</Text>
        <SvgIcon
          name="plus"
          size={14}
          style={{ marginLeft: theme.space.xxs }}
        />
      </Button>
    </Td>
  )
}
