import styled from 'styled-components'

import { Row, Card } from '../base'

export const EditableContentCard = styled.div`
  padding: ${({ theme }) => theme.space.xs}px;
  background-color: ${({ theme }) => theme.colors.Grey10};
  border-radius: ${({ theme }) => theme.border.radius};
`

export const EditableContentColumn = styled.div`
  padding: 12px;
  border-radius: ${({ theme }) => theme.border.radius};
  background-color: white;
`

export const EditableContentCardSection = styled.div`
  margin-top: ${({ theme }) => theme.space.sm}px;

  &:first-child {
    margin-top: 0;
  }
`

export const EditableContentCardHeader = styled(Row)`
  align-items: flex-end;
  margin-bottom: ${({ theme }) => theme.space.xxs}px;
`

export const EditableContentCardEntry = styled.div`
  margin-top: ${({ theme }) => theme.space.xs}px;

  &:first-child {
    margin-top: 0;
  }
`

export const EditableContentCardV2 = styled(Card)`
  padding: ${({ theme }) => theme.space.xs}px;
  background-color: ${({ theme }) => theme.colors.White};
  border-color: ${({ theme }) => theme.colors.Grey20};
  border-radius: ${({ theme }) => theme.border.radius};
`
