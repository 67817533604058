import { useParams } from 'react-router-dom'
import { MainLayout } from 'src/components'
import { InvoiceDetailsTable } from 'src/components/InvoiceDetailsTable/InvoiceDetails'

export default function InvoiceDetailsScreen() {
  const { invoiceId } = useParams<string>()

  return (
    <MainLayout title="Invoice Details">
      <InvoiceDetailsTable invoiceId={invoiceId ?? ''} />
    </MainLayout>
  )
}
