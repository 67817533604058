import { InlineBanner } from 'src/components'
import { EditableContentCard } from 'src/components/EditableContentCard/EditableContentCard'
import { useLocations } from 'src/hooks/useLocations'
import { theme } from 'src/libs/theme'

import { getLocationCardEntries } from '../../utils'

export function BookShiftsLocationSection(props: {
  locationId?: string
  activeRegion: boolean | null
}) {
  const { locationId, activeRegion } = props
  const { activeLocations } = useLocations()
  const location = locationId
    ? activeLocations.find((l) => l.locationId === locationId)
    : undefined
  if (!location) {
    return null
  }
  return (
    <>
      <EditableContentCard
        style={{ marginTop: theme.space.xs }}
        sections={[{ entries: getLocationCardEntries(location) }]}
      />
      {activeRegion === false ? (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity="error"
          text={
            <>
              The location you‘ve selected is not in an area where Traba
              currently operates. Please contact our team at{' '}
              <a
                style={{
                  color: theme.colors.brand,
                  textDecoration: 'none',
                  fontWeight: 500,
                }}
                href="mailto:hello@traba.work"
                target="_blank"
                rel="noreferrer"
              >
                hello@traba.work
              </a>{' '}
              or learn more about where we operate{' '}
              <a
                target="_blank"
                href="https://www.traba.work/business-faq#faq_question1"
                style={{
                  color: theme.colors.brand,
                  textDecoration: 'none',
                  fontWeight: 500,
                }}
                rel="noreferrer"
              >
                here
              </a>
              .
            </>
          }
        />
      ) : null}
    </>
  )
}
