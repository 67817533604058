import { JobStatus, ShiftStatus } from '@traba/types'
import { shiftStatusBadge } from 'src/libs/constants'

import { Badge } from '..'

interface CompleteBadgeProps {
  status?: ShiftStatus
  jobStatus?: JobStatus
}

export function CompleteBadge({ status, jobStatus }: CompleteBadgeProps) {
  if (status !== ShiftStatus.COMPLETE && jobStatus !== JobStatus.Complete) {
    return null
  }
  return (
    <Badge
      variant="success"
      title={shiftStatusBadge[ShiftStatus.COMPLETE]}
      style={{ marginLeft: 6 }}
    />
  )
}
