import { ReactNode } from 'react'
import { WORKER_DISTANCE_WARNING_LEVEL } from 'src/libs/constants'
import { theme } from 'src/libs/theme'
import { AlertIcon } from '../assets/svg'
import { Row } from '../base'

export const DistanceText = (workerDistance: number): ReactNode => {
  const text = `${workerDistance.toFixed(1)} miles away`
  if (workerDistance > WORKER_DISTANCE_WARNING_LEVEL) {
    return (
      <Row
        gap={theme.space.xxxs}
        style={{ color: theme.colors.Red80 }}
        alignCenter
      >
        <AlertIcon />
        <span>{text}</span>
      </Row>
    )
  }
  return text
}
