import { theme } from '@traba/theme'
import { ChevronDown } from '../base-components/ChevronDown'
import Row from '../base-components/Row'
import { SvgIcon } from '../base-components/SvgIcon'
import { Text } from '../base-components/Text'

interface Props {
  textColor: string
  isSettingOn: boolean
  selectedLocationLength: number
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}

export const NotificationSettingsLocationSelect = ({
  textColor,
  isSettingOn,
  selectedLocationLength,
  onClick,
}: Props) => {
  return (
    <Row
      alignCenter
      onClick={onClick}
      style={{ pointerEvents: isSettingOn ? 'auto' : 'none' }}
    >
      <SvgIcon name="location" color={textColor} />
      <Text mr={theme.space.xxxs} ml={theme.space.xxxs} color={textColor}>
        {isSettingOn
          ? `${selectedLocationLength} locations`
          : 'Select locations'}
      </Text>
      <ChevronDown color={textColor} />
    </Row>
  )
}
