import styled from 'styled-components'

import { Icon } from '../base'

export const PersonalProfileContainer = styled.div`
  max-width: 502px;
`

export const ButtonIcon = styled(Icon)`
  height: 16px;
  width: 16px;
`
