import { TabPanel, Tab, Tabs, TabsContainer } from '@traba/react-components'
import { useEffect, useState } from 'react'
import { MainLayout } from 'src/components'
import { Badge, Col, Row, Text } from 'src/components/base'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'
import { useChats } from 'src/hooks/useChats'
import { theme } from 'src/libs/theme'

import { AnnouncementsTab } from './components/AnnouncementsTab'
import { MessagesTab } from './components/MessagesTab'

const MessagingScreen = () => {
  const [currentTab, setCurrentTab] = useState(0)
  const { unReadChats } = useChats()
  const { dispatch: appContextDispatch } = useAppContext()

  const TABS = [
    {
      key: 'messages',
      label: 'Direct messages',
      Component: <MessagesTab />,
      newMessageCount: unReadChats?.length,
    },
    {
      key: 'announcements',
      label: 'Announcements',
      Component: <AnnouncementsTab />,
    },
  ]

  useEffect(() => {
    appContextDispatch({
      type: 'SET_REGIONAL_FILTER_STATUS',
      value: RegionalFilterStatus.ENABLED,
    })
    return () => {
      appContextDispatch({
        type: 'SET_REGIONAL_FILTER_STATUS',
        value: RegionalFilterStatus.HIDE,
      })
    }
  }, [appContextDispatch])

  return (
    <MainLayout title="Messaging Center">
      <Row
        justifyBetween
        alignCenter
        style={{ marginBottom: theme.space.xs, columnGap: theme.space.xxs }}
      >
        <Col>
          <Text variant="h2">Messaging Center</Text>
          <Text style={{ marginTop: theme.space.xs }} variant="body2">
            Manage your conversations with workers and send announcements to
            workers on shifts.
            <Text
              ml={theme.space.ms}
              variant="link"
              href="https://help.traba.work/en/articles/8537521-message-your-workers"
              target="_blank"
            >
              Learn more about how it works.
            </Text>
          </Text>
        </Col>
      </Row>
      <TabsContainer>
        <Tabs
          variant="scrollable"
          value={currentTab}
          onChange={(_, v) => setCurrentTab(v)}
        >
          {TABS.map((t, index) => (
            <Tab
              key={index}
              label={
                <Row>
                  <Text
                    variant="h5"
                    color={
                      currentTab === index
                        ? theme.colors.MidnightBlue
                        : theme.colors.Grey60
                    }
                  >
                    {t.label}
                  </Text>
                  {!!t.newMessageCount && (
                    <Badge
                      title={t.newMessageCount.toString()}
                      variant="brand"
                      style={{
                        marginLeft: theme.space.xxs,
                        borderRadius: theme.space.lg,
                      }}
                    />
                  )}
                </Row>
              }
            />
          ))}
        </Tabs>
      </TabsContainer>
      {TABS.map(({ Component }, i) => (
        <TabPanel value={currentTab} index={i} key={i}>
          {Component}
        </TabPanel>
      ))}
    </MainLayout>
  )
}

export default MessagingScreen
