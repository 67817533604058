import { MODAL_SIZE } from '@traba/react-components'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { MainLayout, Modal } from 'src/components'

import { ShiftCreatedModalContent } from 'src/components/ShiftCreatedModalContent/ShiftCreatedModalContent'
import { UpcomingShiftDetails } from 'src/components/UpcomingShiftDetails/UpcomingShiftDetails'

type ShiftParams = {
  shiftId: string
}

export default function UpcomingShiftDetailsScreen() {
  const { shiftId } = useParams<ShiftParams>()
  const location = useLocation()
  const [showNewShiftBookedModal, setNewShiftBookedModal] = useState(false)
  const { modalType, shiftCount } = location.state || {}

  useEffect(() => {
    switch (modalType) {
      case 'create-shift-success':
        setNewShiftBookedModal(true)
        window.history.replaceState({}, '') // clears modalType so it doesn't show on refresh
        return
    }
  }, [])

  return (
    <MainLayout title="Upcoming Shift">
      <UpcomingShiftDetails shiftId={shiftId || ''} />
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showNewShiftBookedModal}
        handleClose={() => setNewShiftBookedModal(true)}
      >
        <ShiftCreatedModalContent
          shiftCount={shiftCount}
          setShowModal={setNewShiftBookedModal}
        />
      </Modal>
    </MainLayout>
  )
}
