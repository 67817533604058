import { useAlert } from '@traba/context'
import {
  ModalPaddingContainer,
  Card,
  Button,
  ButtonVariant,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { CostCenterType } from '@traba/types'
import { useCallback, useState } from 'react'
import { CostCenter } from 'src/components/CostCenter/CostCenter'
import { ButtonIcon } from 'src/components/PersonalProfile/PersonalProfile.styles'
import { useCostCentersMutations } from 'src/hooks/useCostCenters'

export type ArchiveCostCenterModalProps = {
  costCenter: CostCenterType
  handleClose: () => void
}

export const ArchiveCostCenterModal = (props: ArchiveCostCenterModalProps) => {
  const { deleteCostCenter } = useCostCentersMutations()
  const { showError } = useAlert()
  const [loading, setLoading] = useState(false)

  const handleArchiveClick = useCallback(async () => {
    setLoading(true)
    await deleteCostCenter(props.costCenter.id, {
      onSettled: () => {
        setLoading(false)
        props.handleClose()
      },
      onError: (err) => {
        if (err.message === 'archive/active-shifts') {
          showError(
            'Unfortunately, you cannot archive a cost center associated with a current or upcoming shift. Please reach out to support if you need this cost center archived.',
            'Unable to archive cost center',
          )
        } else {
          showError(
            'An error has occurred. Please try again or contact support if the issue persists.',
            'Unable to archive cost center',
          )
        }
      },
    })
    window.analytics.track(`Cost Center Archived`, {
      costCenter: props.costCenter,
    })
  }, [deleteCostCenter, props, showError])

  return (
    <ModalPaddingContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Text variant="h2">Archive Cost Center</Text>
        <ButtonIcon name="cancel" onClick={() => props.handleClose()} />
      </div>
      <Text variant="body2">
        Archiving a cost center will remove it from the dropdown list when
        creating a shift. However, the cost center information will continue to
        show up for any previous shift it was tied to.
      </Text>

      <Text
        style={{ marginTop: theme.space.xs, marginBottom: theme.space.xxs }}
        variant="h5"
      >
        Cost Center to Archive
      </Text>
      <Card
        style={{
          marginTop: '8px',
          width: '100%',
          display: 'flex',
        }}
      >
        <CostCenter costCenter={props.costCenter} />
      </Card>
      <Row justifyBetween pt={theme.space.med}>
        <Button
          type="button"
          variant={ButtonVariant.OUTLINED}
          onClick={props.handleClose}
        >
          Back
        </Button>
        <Button
          variant={ButtonVariant.CANCEL}
          onClick={handleArchiveClick}
          loading={loading}
        >
          Archive
        </Button>
      </Row>
    </ModalPaddingContainer>
  )
}
