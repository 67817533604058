import { DEFAULT_TIMEZONE } from '@traba/consts'
import { Button, ButtonVariant } from '@traba/react-components'
import { CreateSchedule } from '@traba/types'
import {
  addDays,
  format,
  getHours,
  getMinutes,
  getSeconds,
  isFuture,
  isValid,
  set,
  startOfDay,
} from 'date-fns'
import { useState } from 'react'
import { Col, InlineBanner, Input, Row, Text } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { RadioButton } from 'src/components/RadioButton'
import { useLocations } from 'src/hooks/useLocations'
import { theme } from 'src/libs/theme'
import { getTimeZoneAbbreviation } from 'src/shared/utils/dateUtils'
import styled from 'styled-components'
import { BookShiftsProps } from '../BookShifts/BookShiftsScreen'
import { updateSchedules } from '../BookShifts/utils'
import { getEndDateError, MAX_END_DATE } from '../BookShifts/validation'

const MobileRow = styled(Row)`
  column-gap: ${({ theme }) => theme.space.med}px;
  @media only screen and (max-width: (${({ theme }) =>
      theme.media.maxExtraSmall})) {
    justify-content: flex-start;
    column-gap: ${({ theme }) => theme.space.xs}px;
  }
`

function getInitialEndDate({ recurringSchedule }: CreateSchedule): Date {
  if (recurringSchedule?.endDate && isFuture(recurringSchedule.endDate)) {
    return recurringSchedule.endDate
  }
  return startOfDay(addDays(new Date(), 13))
}

export function EditScheduleEndDate({
  shiftRequest,
  setShiftRequest,
  setShiftRequestMetadata,
  onContinue,
  onPrevious,
  shiftRequestMetadata,
  setEndDateChanged,
}: BookShiftsProps) {
  const { locationId } = shiftRequest
  const [scheduleA, scheduleB] = shiftRequest.schedules
  const { startTime, endTime, isRecurringSchedule, recurringSchedule } =
    scheduleA
  const [endDate, setEndDate] = useState<Date>(getInitialEndDate(scheduleA))
  const { getLocationById } = useLocations()
  const location = getLocationById(locationId)
  const timezone = location?.timezone || DEFAULT_TIMEZONE
  const recurring = isRecurringSchedule && !!recurringSchedule
  const isOvernight = startTime.getDate() !== endTime.getDate()
  const [isOngoing, setIsOngoing] = useState<boolean>(endTime === undefined)

  const endTextArr: string[] = []
  const timeZoneAbrv = getTimeZoneAbbreviation(startTime, timezone)

  if (timeZoneAbrv) {
    endTextArr.push(timeZoneAbrv)
  }
  const overnightDate = isOvernight ? `${format(endTime, 'MM/dd/yy')}` : ''
  if (overnightDate) {
    endTextArr.push(overnightDate)
  }

  function removeEndDateFromSchedules() {
    if (shiftRequest.schedules.length > 0) {
      window.analytics.track('Selected Ongoing Schedule')
      const schedules: CreateSchedule[] = updateSchedules(
        scheduleA,
        scheduleB,
        {
          removeEndDate: true,
        },
      )
      setShiftRequest({ schedules })
    }
    setEndDateChanged && setEndDateChanged(true)
  }

  function updateEndDate(newEndDate: Date) {
    const { endTime } = scheduleA
    const newEndDateWithEndTime = set(newEndDate, {
      hours: getHours(endTime),
      minutes: getMinutes(endTime),
      seconds: getSeconds(endTime),
    })
    if (!recurring) {
      return
    }
    if (!isValid(newEndDateWithEndTime)) {
      return
    }
    if (newEndDateWithEndTime.getTime() > MAX_END_DATE.getTime()) {
      return
    }
    if (newEndDateWithEndTime.getTime() < Date.now()) {
      return
    }
    const schedules = updateSchedules(scheduleA, scheduleB, {
      endDate: newEndDateWithEndTime,
    })
    setShiftRequest({ schedules })
    setEndDate(newEndDateWithEndTime)
    setIsOngoing(false)
    setEndDateChanged && setEndDateChanged(true)
  }

  const dateError = getEndDateError(shiftRequest)?.message

  return (
    <>
      <Text variant="h5" style={{ margin: `0 0 ${theme.space.sm}px 0` }}>
        Modify schedule end date
      </Text>

      <>
        <Col mt={theme.space.lg} mb={theme.space.xxs}>
          <Text variant="h5">Schedule name</Text>
          <Input
            value={shiftRequestMetadata.shiftRequestParentTitle}
            onChange={(e) =>
              setShiftRequestMetadata({
                shiftRequestParentTitle: e.target.value,
              })
            }
            placeholder="Give your schedule a name!"
          />
        </Col>
        <MobileRow gap={theme.space.xxs} justifyBetween>
          <Col>
            <Text variant="h5" mt={theme.space.lg} mb={theme.space.sm}>
              First shift
            </Text>
            <DatePicker
              isDisabled
              aria-label="First shift start date"
              date={startTime}
              isClearable={false}
              granularity="day"
              timezone={timezone}
            />
          </Col>
          <Col>
            <Text variant="h5" mt={theme.space.lg} mb={theme.space.sm}>
              Last shift
            </Text>
            <Col gap={theme.space.xs}>
              <Row
                alignCenter
                style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
                onClick={() => {
                  setIsOngoing(true)
                  removeEndDateFromSchedules()
                }}
              >
                <RadioButton selected={isOngoing} />
                <Text variant="body1">Ongoing</Text>
              </Row>

              <Row
                alignCenter
                style={{
                  columnGap: theme.space.xs,
                  cursor: 'pointer',
                  rowGap: theme.space.xs,
                }}
                onClick={() => {
                  setIsOngoing(false)
                  updateEndDate(endDate)
                }}
                wrap
              >
                <RadioButton selected={!isOngoing} />
                <Text variant="body1">On</Text>
                <DatePicker
                  aria-label="End date"
                  date={recurringSchedule?.endDate ?? endDate}
                  setDate={(d) => {
                    d && updateEndDate(d)
                    window.analytics.track('User Updated End Date', {
                      date: d,
                      schedules: [scheduleA, scheduleB],
                    })
                  }}
                  isClearable={false}
                  granularity="day"
                  minDate={startTime}
                  maxDate={MAX_END_DATE}
                  timezone={timezone}
                />
              </Row>
            </Col>
          </Col>
        </MobileRow>
        {dateError ? (
          <InlineBanner
            style={{ marginTop: theme.space.xs }}
            severity="error"
            text={dateError}
          />
        ) : null}
      </>
      <Row
        style={{ justifyContent: 'space-between', marginTop: theme.space.lg }}
      >
        <>
          <Button onClick={onPrevious} variant={ButtonVariant.OUTLINED}>
            Previous
          </Button>
          <Button onClick={onContinue} disabled={!!dateError}>
            Continue
          </Button>
        </>
      </Row>
    </>
  )
}
