import { Row, Text } from '..'

export const StripePaymentTerms = () => {
  return (
    <>
      <Row mt={32} pb={16}>
        <Text variant="body2">
          Traba partners with Stripe to store your billing details and process
          payments and invoices. Please continue to Stripe below to enter your
          preferred billing details.
        </Text>
      </Row>
      <Row mt={16} pb={16}>
        <Text variant="body2">
          You won't be charged for any shifts until after they've been worked,
          and you will have 30 days to pay invoices after they've been created.
        </Text>
      </Row>
    </>
  )
}
