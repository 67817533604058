import React from 'react'
import { Button, ButtonVariant, Td } from '../../base-components'

export function AddRowButtons(props: {
  onAddBreakClick: () => void
  onAddTimeClick: () => void
}): React.JSX.Element {
  return (
    <>
      <Td>
        <Button
          variant={ButtonVariant.BRANDLINK}
          onClick={props.onAddBreakClick}
        >
          + Add Break
        </Button>
      </Td>
      <Td>
        <Button
          variant={ButtonVariant.BRANDLINK}
          onClick={props.onAddTimeClick}
        >
          + Add Time
        </Button>
      </Td>
    </>
  )
}
