import { theme } from './theme'

export const calendarStyleCombos = [
  {
    backgroundColor: theme.colors.Blue10,
    borderColor: theme.colors.Blue80,
  },
  {
    backgroundColor: theme.colors.Green10,
    borderColor: theme.colors.Green70,
  },
  {
    backgroundColor: theme.colors.Orange10,
    borderColor: theme.colors.Orange70,
  },
  {
    backgroundColor: theme.colors.Violet10,
    borderColor: theme.colors.Violet,
  },

  {
    backgroundColor: theme.colors.Yellow10,
    borderColor: theme.colors.Yellow70,
  },
  {
    backgroundColor: theme.colors.Peach10,
    borderColor: theme.colors.Peach70,
  },
  //Colors in design system are limited so we need to use some colors outside of it to ensure we have enough colors for all shifts
  { backgroundColor: '#E6E6FA', borderColor: '#9370DB' },
  { backgroundColor: '#AFEEEE', borderColor: '#00CED1' },
  { backgroundColor: '#E0FFFF', borderColor: '#B0E0E6' },
  {
    backgroundColor: '#F0E68C',
    borderColor: '#BDB76B',
  },
  {
    backgroundColor: '#BDB76B',
    borderColor: '#808000',
  },
  {
    backgroundColor: '#E6A8D7',
    borderColor: '#DA70D6',
  },
  {
    backgroundColor: '#AFEEEE',
    borderColor: '#40E0D0',
  },
  { backgroundColor: '#87CEEB', borderColor: '#4682B4' },
  { backgroundColor: '#FFE4B5', borderColor: '#DAA520' },
  { backgroundColor: '#00FA9A', borderColor: '#008000' },
  { backgroundColor: '#D8BFD8', borderColor: '#9932CC' },
  { backgroundColor: '#FFD700', borderColor: '#FFA500' },
  { backgroundColor: '#7B68EE', borderColor: '#6A5ACD' },
  { backgroundColor: '#98FB98', borderColor: '#008000' },
]
