import { theme } from '@traba/theme'
import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import styled from 'styled-components'
import { Button, ButtonVariant } from '../Button/Button'
import { Text } from '../Text'

export function DevelopmentFallbackComponent({
  error,
  resetErrorBoundary,
}: FallbackProps) {
  return (
    <ErrorPage>
      <Text variant="h2" color={theme.colors.red}>
        Unexpected Application Error!
      </Text>
      <Text
        variant="body1"
        className="meticulous-ignore"
        color={theme.colors.MidnightBlue}
      >
        {error.message}
      </Text>
      <pre className="meticulous-ignore">
        <Text variant="body1">{error.stack}</Text>
      </pre>
      <Button
        variant={ButtonVariant.FILLED}
        style={{ marginTop: theme.space.xs }}
        onClick={resetErrorBoundary}
      >
        Go Back
      </Button>
    </ErrorPage>
  )
}

const ErrorPage = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => `${theme.space.lg}px`};
  width: 100%;
  pre {
    text-align: left;
    color: ${({ theme }) => theme.colors.MidnightBlue};
    margin-top: ${({ theme }) => `${theme.space.med}px`};
    width: 100%;
    white-space: pre-wrap;
    background-color: ${({ theme }) => theme.colors.Grey20};
    padding: ${({ theme }) => `${theme.space.sm}px`};
    border: 1px solid ${({ theme }) => theme.colors.Grey};
  }
`
