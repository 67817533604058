import { Tab, TabPanel, Tabs, TabsContainer } from '@traba/react-components'
import { ShiftRequestParentWithShiftRequest } from '@traba/types'
import { useState } from 'react'
import { ScheduleDetailsInvitations } from 'src/components/ScheduleDetails/ScheduleDetailsInvitations'
import { ScheduleDetailsShifts } from 'src/components/ScheduleDetails/ScheduleDetailsShifts'
import { ScheduleDetailsWeeklyWorkers } from 'src/components/ScheduleDetails/ScheduleDetailsWeeklyWorkers'
import { ScheduleDetailsWorkerManagement } from 'src/components/ScheduleDetails/ScheduleDetailsWorkerManagement'
import { getScheduleDetailsTileInfo } from 'src/utils/scheduleUtils'
import { ScheduleDetailsTile } from './ScheduleDetailsTile/ScheduleDetailsTile'

interface DesktopScheduleDetailsProps {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  targetTab: string | null
}

export const DesktopScheduleDetails: React.FC<DesktopScheduleDetailsProps> = ({
  shiftRequestParent,
  targetTab,
}) => {
  const scheduleDetailsTileInfo = getScheduleDetailsTileInfo(shiftRequestParent)
  const [currentTab, setCurrentTab] = useState(
    targetTab ? Number(targetTab) : 0,
  )

  const TABS = [
    {
      label: 'Shifts',
      component: (
        <ScheduleDetailsShifts
          shiftRequestParentId={shiftRequestParent.shiftRequestParentId}
        />
      ),
    },
    {
      label: 'Weekly worker schedule',
      component: (
        <ScheduleDetailsWeeklyWorkers
          shiftRequestParentId={shiftRequestParent.shiftRequestParentId}
        />
      ),
    },
    {
      label: 'Worker management',
      component: (
        <ScheduleDetailsWorkerManagement
          shiftRequestParentId={shiftRequestParent.shiftRequestParentId}
        />
      ),
    },
    {
      label: 'Schedule invitations',
      component: (
        <ScheduleDetailsInvitations
          shiftRequestParentId={shiftRequestParent.shiftRequestParentId}
        />
      ),
    },
  ]
  return (
    <>
      <ScheduleDetailsTile
        shiftRequestParentId={shiftRequestParent.shiftRequestParentId}
        scheduleDetailsTileInfo={scheduleDetailsTileInfo}
      />
      <TabsContainer>
        <Tabs
          variant="scrollable"
          value={currentTab}
          onChange={(_, v) => setCurrentTab(v)}
        >
          {TABS.map((t, index) => (
            <Tab key={index} label={t.label} />
          ))}
        </Tabs>
      </TabsContainer>
      {TABS.map(({ component }, i) => (
        <TabPanel value={currentTab} index={i} key={i}>
          {component}
        </TabPanel>
      ))}
    </>
  )
}
