import { ModalPaddingContainer } from '@traba/react-components'
import { useState } from 'react'
import { Divider, Row, Text } from 'src/components/base'
import { RoleForm } from 'src/components/RoleForm'
import { RoleStepper } from 'src/components/RoleStepper'
import { useRoles } from 'src/hooks/useRoles'
import { PAY_RATE_DEFAULT } from 'src/hooks/useShiftRequests'
import { theme } from 'src/libs/theme'
import { CreateRoleData, RoleData } from 'src/types'

import { ButtonIcon } from '../../PersonalProfile/PersonalProfile.styles'

const INITIAL_ROLE_STATE = {
  roleName: '',
  roleDescription: '',
  requiredAttire: '',
  requiredCertifications: [],
  requiredAttributes: [],
  freeformAttributes: {},
  defaultPayRate: PAY_RATE_DEFAULT,
  location: undefined,
}

export type CreateRoleModalProps = {
  onClose: () => void
  onCreate?: (r: RoleData) => void
  isEventCompany: boolean
  fromPrevious: boolean
  duplicatePreviousRoleId?: string
  filterLocationId?: string
}

export const CreateRoleModal = (props: CreateRoleModalProps) => {
  const {
    onCreate,
    onClose,
    isEventCompany,
    fromPrevious,
    duplicatePreviousRoleId,
    filterLocationId,
  } = props
  const [submitLoading, setSubmitLoading] = useState(false)
  const { createRole, getRoleById } = useRoles()

  function handleCreate(role: CreateRoleData) {
    setSubmitLoading(true)
    createRole(role, {
      onSuccess: (r) => {
        if (onCreate) {
          onCreate(r)
        }
        onClose()
      },
      onSettled: () => setSubmitLoading(false),
    })
  }

  function getModalTitle() {
    if (fromPrevious) {
      if (duplicatePreviousRoleId) {
        return 'Duplicate role'
      } else if (filterLocationId) {
        return 'Create a role using your previous roles for this shift'
      } else {
        return 'Create a role using your previous roles'
      }
    } else {
      if (filterLocationId) {
        return 'Create a new role for this shift'
      } else {
        return 'Create a new role'
      }
    }
  }

  const {
    roleName = INITIAL_ROLE_STATE.roleName,
    roleDescription = INITIAL_ROLE_STATE.roleDescription,
    requiredAttire = INITIAL_ROLE_STATE.requiredAttire,
    requiredCertifications = INITIAL_ROLE_STATE.requiredCertifications,
    requiredAttributes = INITIAL_ROLE_STATE.requiredAttributes,
    defaultPayRate = INITIAL_ROLE_STATE.defaultPayRate,
    genderPreference = undefined,
  } = duplicatePreviousRoleId ? getRoleById(duplicatePreviousRoleId) || {} : {}

  return isEventCompany ? (
    <ModalPaddingContainer>
      <Row justifyBetween style={{ marginBottom: theme.space.xs }}>
        <Text variant="h2">{getModalTitle()}</Text>
        <ButtonIcon name="cancel" onClick={props.onClose} />
      </Row>
      <RoleForm
        onConfirm={handleCreate}
        onCancel={onClose}
        submitLoading={submitLoading}
        isEdit={false}
        initialRoleState={{
          roleName,
          roleDescription,
          requiredAttire,
          requiredCertifications,
          requiredAttributes,
          defaultPayRate,
          genderPreference,
        }}
        filterLocationId={filterLocationId}
      />
    </ModalPaddingContainer>
  ) : (
    <div style={{ width: '100%' }}>
      <Row justifyBetween alignCenter style={{ margin: theme.space.sm }}>
        <Text variant="h4">{getModalTitle()}</Text>
        <ButtonIcon name="cancel" onClick={onClose} />
      </Row>
      <Divider />
      <RoleStepper
        initialRoleState={INITIAL_ROLE_STATE}
        fromPrevious={fromPrevious}
        duplicatePreviousRoleId={duplicatePreviousRoleId}
        filterLocationId={filterLocationId}
        onConfirm={handleCreate}
        onCancel={onClose}
        submitLoading={submitLoading}
        isEdit={false}
      />
    </div>
  )
}
