import { Box, FormControlLabel } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { ARCHIVED_REGION_ID } from '@traba/consts'
import { theme } from '@traba/theme'
import { LocationResponse, Region } from '@traba/types'
import { getAddressString } from '@traba/utils'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { ChevronDown, ChevronDownWrapper } from './base-components/ChevronDown'
import { Text } from './base-components/Text'

interface CheckboxGroupsProps {
  selectedLocations: Set<string>
  onUpdateSelectedLocations: (newSelectedLocations: Set<string>) => void
  regionMap: Record<string, Region>
  expandedRegions: Record<string, boolean>
  setExpandedRegions: Dispatch<SetStateAction<Record<string, boolean>>>
  searchText: string
  regionIdToFilteredLocations: Record<string, LocationResponse[]>
}

function getLabelColor(regionId: string) {
  return regionId === ARCHIVED_REGION_ID
    ? theme.colors.Grey60
    : theme.colors.MidnightBlue
}

export default function RegionAndLocationCheckboxGroups({
  selectedLocations,
  onUpdateSelectedLocations,
  regionMap,
  expandedRegions,
  setExpandedRegions,
  searchText,
  regionIdToFilteredLocations,
}: CheckboxGroupsProps) {
  const [sortedRegionIds, setSortedRegionIds] = useState<string[]>([])

  useEffect(() => {
    // Sort the regions by number of selected locations
    const sorted = Object.entries(regionIdToFilteredLocations).sort((a, b) => {
      const [regionId1, locations1] = a
      const [regionId2, locations2] = b

      // Always place ARCHIVED_OUTSIDE_REGION_ID at the end
      if (regionId1 === ARCHIVED_REGION_ID) {
        return 1
      }
      if (regionId2 === ARCHIVED_REGION_ID) {
        return -1
      }

      const selectedLocations1 = locations1.filter((location) =>
        selectedLocations.has(location.locationId),
      ).length
      const selectedLocations2 = locations2.filter((location) =>
        selectedLocations.has(location.locationId),
      ).length

      // If both regions have selected locations or both don't, sort by number of locations
      if (
        (selectedLocations1 > 0 && selectedLocations2 > 0) ||
        (selectedLocations1 === 0 && selectedLocations2 === 0)
      ) {
        return locations2.length - locations1.length
      }

      // If only one region has selected locations, it should come first
      if (selectedLocations1 >= selectedLocations2) {
        return -1
      }
      return 1
    })

    // Set regions to be expanded if they have selected location with setExpandedRegions
    const newExpandedRegions: Record<string, boolean> = {}
    for (const [regionId, locations] of sorted) {
      // Archived locations group is always collapsed initially
      if (regionId === ARCHIVED_REGION_ID) {
        continue
      }
      const hasSelectedLocation = locations.some((location) =>
        selectedLocations.has(location.locationId),
      )
      newExpandedRegions[regionId] = hasSelectedLocation
    }
    setExpandedRegions(() => newExpandedRegions)
    setSortedRegionIds(sorted.map(([regionId]) => regionId))
  }, [])

  const onRegionCheckboxChange = useCallback(
    (
      regionId: string,
      locations: LocationResponse[],
      selectedLocationsArg: Set<string>,
      searchTextArg: string,
    ) => {
      const areAllLocationsSelected = locations.every((location) =>
        selectedLocationsArg.has(location.locationId),
      )

      const newSet = new Set(selectedLocations)
      locations.forEach((location) => {
        if (areAllLocationsSelected) {
          newSet.delete(location.locationId)
        } else {
          newSet.add(location.locationId)
        }
      })

      onUpdateSelectedLocations(newSet)
      // Expand or collapse the region
      if (searchTextArg === '') {
        setExpandedRegions((prev) => ({
          ...prev,
          [regionId]: !areAllLocationsSelected,
        }))
      }
    },
    [onUpdateSelectedLocations, setExpandedRegions],
  )

  return (
    <div style={{ overflowY: 'auto' }}>
      {sortedRegionIds.map((regionId) => {
        const filteredLocations = regionIdToFilteredLocations[regionId]
        if (!filteredLocations) {
          return null
        }
        const allLocationsSelected = filteredLocations.every((location) =>
          selectedLocations.has(location.locationId),
        )
        const someLocationsSelected = filteredLocations.some((location) =>
          selectedLocations.has(location.locationId),
        )
        return (
          <div key={regionId} style={{ marginLeft: theme.space.xxs }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() =>
                        onRegionCheckboxChange(
                          regionId,
                          filteredLocations,
                          selectedLocations,
                          searchText,
                        )
                      }
                      checked={allLocationsSelected}
                      indeterminate={
                        someLocationsSelected && !allLocationsSelected
                      }
                      sx={{
                        color: theme.colors.Grey30,
                        '&.Mui-checked': {
                          color: theme.colors.Violet,
                        },
                      }}
                    />
                  }
                  label={
                    <Text variant="h6" color={getLabelColor(regionId)}>
                      {(regionId === ARCHIVED_REGION_ID
                        ? 'Archived Locations'
                        : regionMap[regionId] &&
                          regionMap[regionId].displayName) ??
                        'Outside of operating region'}
                    </Text>
                  }
                />
                <div>
                  <ChevronDownWrapper
                    id="regional-filter-dropdown-icon"
                    onClick={() =>
                      setExpandedRegions((prevExpandedRegions) => ({
                        ...prevExpandedRegions,
                        [regionId]: !prevExpandedRegions[regionId],
                      }))
                    }
                  >
                    <ChevronDown
                      $isActive={expandedRegions[regionId]}
                      color={theme.colors.MidnightBlue}
                    />
                  </ChevronDownWrapper>
                </div>
              </Box>
              <div
                style={{
                  marginLeft: theme.space.sm,
                  flex: 'auto',
                  flexDirection: 'column',
                }}
              >
                {expandedRegions[regionId] &&
                  filteredLocations.map((location) => (
                    <FormControlLabel
                      key={location.locationId}
                      style={{ width: '100%' }}
                      control={
                        <Checkbox
                          checked={selectedLocations.has(location.locationId)}
                          onChange={() => {
                            if (selectedLocations.has(location.locationId)) {
                              const newSet = new Set(selectedLocations)
                              newSet.delete(location.locationId)
                              onUpdateSelectedLocations(newSet)
                            } else {
                              onUpdateSelectedLocations(
                                new Set(selectedLocations).add(
                                  location.locationId,
                                ),
                              )
                            }
                          }}
                          sx={{
                            color: theme.colors.Grey30,
                            '&.Mui-checked': {
                              color: theme.colors.Violet,
                            },
                          }}
                        />
                      }
                      label={
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          {location.name?.trim() !== '' && (
                            <Text
                              style={{
                                color: getLabelColor(regionId),
                              }}
                              variant="h6"
                            >
                              {location.name}
                            </Text>
                          )}
                          <Text
                            variant="body3"
                            style={{
                              color: getLabelColor(regionId),
                            }}
                          >
                            {getAddressString(location.address)}
                          </Text>
                        </Box>
                      }
                    />
                  ))}
              </div>
            </Box>
          </div>
        )
      })}
    </div>
  )
}
