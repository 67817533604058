import { ListItem, ListItemAvatar, ListItemButton } from '@mui/material'
import { ChatEndedStatus, ChatReadStatus, ChatSummary } from '@traba/types'
import { memo } from 'react'
import { Avatar, Col, Divider, Row, Text } from 'src/components/base'
import { Checkbox } from 'src/components/base/CheckboxThemed'
import { theme } from 'src/libs/theme'
import { formatTimeDistanceToNow } from 'src/utils/timeUtils'

interface Props {
  chat: ChatSummary
  selected: boolean
  onClick: () => void
  isPanelOpen?: boolean
  isFromChatWidget?: boolean
  isMobileView?: boolean
  selectedSummaries?: string[]
  handleSummarySelection?: (summaryId: string) => void
}

// Message List Item used in both message tab in communication center and chat widget
const MessageListItem = memo(
  ({
    isFromChatWidget,
    isPanelOpen,
    isMobileView,
    selected,
    onClick,
    chat,
    handleSummarySelection,
    selectedSummaries,
  }: Props) => {
    const {
      mostRecentMessageContent,
      mostRecentMessageAttachmentUrl,
      mostRecentMessageSentAt,
      name,
      profilePictureUrl,
      readStatus,
      companyEndedChatStatus,
      summaryId,
    } = chat
    const isRead = readStatus === ChatReadStatus.READ
    const isEnded = companyEndedChatStatus === ChatEndedStatus.ENDED

    return (
      <>
        <ListItem
          style={{
            width: '100%',
            overflowX: 'hidden',
            padding: 0,
          }}
        >
          {!isPanelOpen && handleSummarySelection && (
            <Checkbox
              selected={!!selectedSummaries?.includes(summaryId)}
              onClick={() => {
                handleSummarySelection(summaryId)
              }}
              style={{ justifyContent: 'center' }}
            />
          )}
          <ListItemButton
            onClick={onClick}
            sx={{
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
            selected={selected}
          >
            <Row alignCenter>
              <ListItemAvatar>
                <Avatar
                  src={profilePictureUrl}
                  name={name}
                  size={isFromChatWidget ? 50 : 60}
                />
              </ListItemAvatar>
              <Col
                ml={
                  isPanelOpen || isFromChatWidget
                    ? theme.space.ms
                    : theme.space.xs
                }
              >
                <Text variant="h5">{name}</Text>
                {isEnded ? (
                  <Text variant="body2">This chat has been ended.</Text>
                ) : (
                  <Text
                    variant={isRead ? 'body1' : 'h6'}
                    color={theme.colors.MidnightBlue}
                    style={{
                      textOverflow: 'ellipsis',
                      maxWidth:
                        isFromChatWidget || isPanelOpen || isMobileView
                          ? 120
                          : 600,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      overflowWrap: 'break-word',
                      display: 'block',
                    }}
                  >
                    {mostRecentMessageContent ??
                      (mostRecentMessageAttachmentUrl ? '1 attachment' : '')}
                  </Text>
                )}
              </Col>
            </Row>
            {mostRecentMessageSentAt && (
              <Text
                variant={isRead ? 'body1' : 'h6'}
                color={theme.colors.MidnightBlue}
                mt={theme.space.xxs}
                style={{ textAlign: 'end' }}
              >
                {formatTimeDistanceToNow(mostRecentMessageSentAt, false)}
              </Text>
            )}
          </ListItemButton>
        </ListItem>
        <Divider />
      </>
    )
  },
)

export default MessageListItem
