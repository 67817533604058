import {
  CreateSchedule,
  RecurringSchedule,
  CreateShiftRequest,
} from '@traba/types'
import { getNextStartAndEndTime } from '@traba/utils'
import { Dispatch, useState } from 'react'
import { WeekdayStr } from 'rrule'
import {
  Button,
  ButtonVariant,
  Col,
  InlineBanner,
  Text,
} from 'src/components/base'
import { MultiSelector } from 'src/components/base/MultiSelector/MultiSelector'
import { SingleSelectorUI as SingleSelector } from 'src/components/base/SingleSelector/SingleSelector.ui'
import { theme } from 'src/libs/theme'

enum CadenceOptions {
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
}

export const REPEAT_ON_OPTIONS: { value: WeekdayStr; label: string }[] = [
  { value: 'SU', label: 'Sun' },
  { value: 'MO', label: 'Mon' },
  { value: 'TU', label: 'Tue' },
  { value: 'WE', label: 'Wed' },
  { value: 'TH', label: 'Thu' },
  { value: 'FR', label: 'Fri' },
  { value: 'SA', label: 'Sat' },
]

const REPEAT_ON_OPTIONS_XS: { value: WeekdayStr; label: string }[] = [
  { value: 'SU', label: 'Su' },
  { value: 'MO', label: 'Mo' },
  { value: 'TU', label: 'Tu' },
  { value: 'WE', label: 'We' },
  { value: 'TH', label: 'Th' },
  { value: 'FR', label: 'Fr' },
  { value: 'SA', label: 'Sa' },
]

const CADENCE_OPTIONS: { value: CadenceOptions; label: string }[] = [
  { value: CadenceOptions.WEEKLY, label: 'Every Week' },
  { value: CadenceOptions.BIWEEKLY, label: 'Every 2 Weeks' },
]

export default function RecurringScheduleSelector({
  shiftRequest,
  setShiftRequest,
  isScheduleEdit,
}: {
  shiftRequest: CreateShiftRequest
  setShiftRequest: Dispatch<Partial<CreateShiftRequest>>
  isScheduleEdit?: boolean
}) {
  const [cadence, setCadence] = useState<CadenceOptions>(CadenceOptions.WEEKLY)
  const [repeatOnB, setRepeatOnB] = useState<WeekdayStr[]>([])

  function onChangeCadence(newCadence: CadenceOptions) {
    const baseRecurringSchedule: RecurringSchedule = {
      endDate: scheduleA.recurringSchedule?.endDate,
      freq: scheduleA.recurringSchedule?.freq ?? 'WEEKLY',
      interval: newCadence === CadenceOptions.BIWEEKLY ? 2 : 1,
      repeatOn: scheduleA.recurringSchedule?.repeatOn ?? [],
    }
    const updatedScheduleA: CreateSchedule = {
      ...scheduleA,
      recurringSchedule: baseRecurringSchedule,
    }
    if (newCadence === CadenceOptions.WEEKLY) {
      setShiftRequest({
        schedules: [updatedScheduleA],
      })
    } else if (newCadence === CadenceOptions.BIWEEKLY) {
      const updatedScheduleB = scheduleB ?? {
        ...scheduleA,
        recurringSchedule: {
          ...baseRecurringSchedule,
          repeatOn: repeatOnB,
        },
      }

      const { startTime, endTime } = getNextStartAndEndTime(
        scheduleA,
        updatedScheduleB,
      )

      updatedScheduleB.startTime = startTime
      updatedScheduleB.endTime = endTime

      setShiftRequest({
        schedules: [updatedScheduleA, updatedScheduleB],
      })
    }
    setCadence(newCadence)
  }

  // Week A
  const [scheduleA, scheduleB] = shiftRequest.schedules
  const recurringScheduleA = scheduleA.recurringSchedule
  const isRecurringA = scheduleA.isRecurringSchedule && !!recurringScheduleA

  // Week B
  let recurringScheduleB: RecurringSchedule | undefined
  let isRecurringB = false
  if (scheduleB) {
    recurringScheduleB = scheduleB.recurringSchedule
    isRecurringB = scheduleB.isRecurringSchedule && !!recurringScheduleB
  }

  function setRepeatOnWeekA(repeatOn: WeekdayStr[]) {
    if (!isRecurringA) {
      return
    }

    window.analytics.track(`User Selected Repeat on Days for Week A`, {
      repeatOn,
    })

    const schedules: CreateSchedule[] = [
      {
        ...scheduleA,
        recurringSchedule: {
          ...recurringScheduleA,
          repeatOn,
        },
      },
    ]
    if (shiftRequest.schedules[1]) {
      schedules.push(shiftRequest.schedules[1])
    }
    setShiftRequest({ schedules })
  }

  function setRepeatOnWeekB(repeatOn: WeekdayStr[]) {
    if (!isRecurringB) {
      return
    }

    window.analytics.track(`User Selected Repeat on Days for Week B`, {
      repeatOn,
    })

    const schedules: CreateSchedule[] = [shiftRequest.schedules[0]]
    schedules.push({
      ...scheduleB,
      recurringSchedule: {
        ...(recurringScheduleB as RecurringSchedule),
        repeatOn,
      },
    })
    setRepeatOnB(repeatOn)
    setShiftRequest({ schedules })
  }

  if (isRecurringA) {
    return (
      <>
        <Text
          variant="h5"
          style={{ margin: `${theme.space.lg}px 0 ${theme.space.sm}px` }}
        >
          Repeats
        </Text>
        <SingleSelector
          disabled={isScheduleEdit}
          style={{ maxWidth: '60%' }}
          options={CADENCE_OPTIONS}
          selectedOption={cadence}
          onChange={onChangeCadence}
        />
        <Text
          variant="h5"
          style={{ margin: `${theme.space.lg}px 0 ${theme.space.sm}px` }}
        >
          {cadence === CadenceOptions.BIWEEKLY ? 'Week A' : 'On which days?'}
        </Text>

        <MultiSelector
          disabledOptions={
            isScheduleEdit ? REPEAT_ON_OPTIONS.map((w) => w.value) : undefined
          }
          className="xs-hide sm-show"
          options={REPEAT_ON_OPTIONS}
          selectedOptions={recurringScheduleA.repeatOn}
          onChange={setRepeatOnWeekA}
        />
        <MultiSelector
          disabledOptions={
            isScheduleEdit ? REPEAT_ON_OPTIONS.map((w) => w.value) : undefined
          }
          className="xs-show sm-hide"
          options={REPEAT_ON_OPTIONS_XS}
          selectedOptions={recurringScheduleA.repeatOn}
          onChange={setRepeatOnWeekA}
        />

        {cadence === CadenceOptions.BIWEEKLY ? (
          <Col mt={theme.space.xxxs}>
            <Text
              variant="h5"
              style={{ margin: `${theme.space.lg}px 0 ${theme.space.sm}px` }}
            >
              Week B
            </Text>
            <MultiSelector
              disabledOptions={
                isScheduleEdit
                  ? REPEAT_ON_OPTIONS.map((w) => w.value)
                  : undefined
              }
              className="xs-hide sm-show"
              options={REPEAT_ON_OPTIONS}
              selectedOptions={recurringScheduleB?.repeatOn ?? []}
              onChange={setRepeatOnWeekB}
            />
            <MultiSelector
              disabledOptions={
                isScheduleEdit
                  ? REPEAT_ON_OPTIONS.map((w) => w.value)
                  : undefined
              }
              className="xs-show sm-hide"
              options={REPEAT_ON_OPTIONS_XS}
              selectedOptions={recurringScheduleB?.repeatOn ?? []}
              onChange={setRepeatOnWeekB}
            />
            {repeatOnB.length === 0 && (
              <InlineBanner
                style={{ marginTop: theme.space.xs }}
                severity="error"
                text={'Must Select Days for Week B'}
              />
            )}
          </Col>
        ) : (
          <Button
            disabled={isScheduleEdit}
            variant={ButtonVariant.TEXT}
            onClick={() => {
              setRepeatOnWeekA(['MO', 'TU', 'WE', 'TH', 'FR'])
              window.analytics.track(`User Clicked Select All Weekdays`)
            }}
            style={{
              color: theme.colors.brand,
              padding: 0,
              marginTop: theme.space.xs,
              alignSelf: 'flex-start',
              fontSize: 12,
            }}
          >
            Select all weekdays
          </Button>
        )}
      </>
    )
  }
}
