import { InputStatus } from '@traba/types'
import { useEffect, useState } from 'react'
import { Col, Input } from 'src/components'
import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'

interface BreakTimeProps {
  onUpdate: (x: number) => void
  onValidate?: (valid: boolean) => void
  currentBreakTime: number
  currentBreakTimeString: string
  maxBreakTime: number
  isMultiEdit: boolean
  width?: string
  isFromTimesheetDetails?: boolean
}

export const BreakTimeInput = ({
  onUpdate,
  onValidate,
  currentBreakTime,
  currentBreakTimeString,
  maxBreakTime,
  isMultiEdit,
  width,
  isFromTimesheetDetails = false,
}: BreakTimeProps) => {
  const { isReactNativeApp } = useMobile()

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  )

  const [inputValue, setInputValue] = useState(currentBreakTime)
  useEffect(() => {
    setInputValue(currentBreakTime)
  }, [currentBreakTime])

  const validateAndSetInput = () => {
    if (inputValue < 0) {
      setErrorMessage('The break time cannot be negative.')
      onValidate && onValidate(false)
    } else if (inputValue > maxBreakTime) {
      setErrorMessage(
        isMultiEdit
          ? 'The break time cannot be greater than the clocked-in time for any worker.'
          : 'The break time cannot be greater than the clocked-in time.',
      )
      onValidate && onValidate(false)
    } else {
      setErrorMessage(undefined)
      onValidate && onValidate(true)
      const newBreakTime = isNaN(inputValue) ? currentBreakTime : inputValue
      setInputValue(newBreakTime)
      if (newBreakTime !== currentBreakTime) {
        onUpdate(newBreakTime)
      }
    }
  }

  return (
    <Col>
      <Input
        placeholder={currentBreakTimeString}
        value={inputValue}
        name="breakTime"
        onChange={(e) => {
          setInputValue(parseInt(e.target.value, 10))
        }}
        inputStatus={errorMessage ? InputStatus.error : undefined}
        onBlur={validateAndSetInput}
        onKeyPress={(event: any) => {
          if (event.key === 'Enter') {
            validateAndSetInput()
          }
        }}
        rows={1}
        containerStyle={{
          marginTop: 0,
          minWidth: '80px',
        }}
        type="number"
        errorMessage={errorMessage}
        width={width ? width : '100%'}
        style={
          isFromTimesheetDetails && isReactNativeApp
            ? {
                paddingTop: 4,
                paddingBottom: 4,
                border: `1px solid ${theme.colors.Grey30}`,
              }
            : undefined
        }
      />
    </Col>
  )
}
