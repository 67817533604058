import { EmploymentType } from '@traba/types'
import { useCompany } from './useCompany'

export const useCompanyEmploymentType = () => {
  const { isLoading, isError, company } = useCompany()

  const isW2 = company?.activeEmploymentTypes?.includes(EmploymentType.W2)
  const isW2Exclusive =
    company?.activeEmploymentTypes?.length === 1 &&
    company?.activeEmploymentTypes[0] === EmploymentType.W2

  const inviteOnly = isW2Exclusive
  const showInvoicing = !isW2Exclusive

  return {
    isLoading,
    isError,
    isW2,
    isW2Exclusive,
    inviteOnly,
    showInvoicing,
  }
}
