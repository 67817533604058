import { SvgIconProps } from 'src/types/svg-types'

export default function Manufacturing({ size, color, ...rest }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 15 14" fill="none" {...rest}>
      <path
        d="M3.50309 3.24943L6.50309 6.24943M3.50309 3.24943H1.50309L0.836426 1.24943L1.50309 0.582764L3.50309 1.24943V3.24943ZM12.3424 1.07677L10.5907 2.82852C10.3267 3.09253 10.1947 3.22453 10.1452 3.37675C10.1017 3.51065 10.1017 3.65488 10.1452 3.78878C10.1947 3.94099 10.3267 4.073 10.5907 4.33701L10.7488 4.49518C11.0129 4.75919 11.1449 4.8912 11.2971 4.94066C11.431 4.98416 11.5752 4.98416 11.7091 4.94066C11.8613 4.8912 11.9933 4.75919 12.2573 4.49518L13.8959 2.85658C14.0724 3.28602 14.1698 3.75636 14.1698 4.24943C14.1698 6.27447 12.5281 7.9161 10.5031 7.9161C10.259 7.9161 10.0204 7.89224 9.7896 7.84672C9.4655 7.7828 9.30346 7.75084 9.20522 7.76063C9.10078 7.77104 9.04931 7.7867 8.95677 7.83622C8.86972 7.8828 8.78241 7.97011 8.60778 8.14474L3.83643 12.9161C3.28414 13.4684 2.38871 13.4684 1.83643 12.9161C1.28414 12.3638 1.28414 11.4684 1.83643 10.9161L6.60778 6.14474C6.78241 5.97011 6.86972 5.8828 6.9163 5.79576C6.96583 5.70322 6.98148 5.65174 6.99189 5.5473C7.00168 5.44907 6.96972 5.28702 6.9058 4.96292C6.86029 4.73214 6.83643 4.49357 6.83643 4.24943C6.83643 2.22439 8.47805 0.582764 10.5031 0.582764C11.1734 0.582764 11.8017 0.762646 12.3424 1.07677ZM7.50313 9.24939L11.1698 12.916C11.722 13.4683 12.6175 13.4683 13.1698 12.916C13.722 12.3637 13.722 11.4683 13.1697 10.916L10.1533 7.89963C9.93977 7.87942 9.73156 7.8409 9.53028 7.78565C9.27091 7.71446 8.98639 7.76613 8.7962 7.95632L7.50313 9.24939Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </svg>
  )
}
