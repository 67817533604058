import { MODAL_SIZE } from '@traba/react-components'
import { getAuth } from 'firebase/auth'
import { memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthScreenWrapper } from 'src/components/auth'
import { WorkersRightCol } from 'src/components/auth/WorkersRightCol'
import {
  Button,
  ButtonVariant,
  Col,
  Modal,
  Row,
  Text,
} from 'src/components/base'
import { SupportModalContent } from 'src/components/SupportModal/SupportModalContent'
import useAppAuth from 'src/hooks/authHook'
import { useEmailVerification } from 'src/hooks/useEmailVerification'
import useMobile from 'src/hooks/useMobile'
import { PREFILLED_INFO_CITY } from 'src/libs/constants'
import { theme } from 'src/libs/theme'

export const EmailVerificationScreen = memo(() => {
  const { isReactNativeApp } = useMobile()
  const { handleLogout } = useAppAuth()
  const city = localStorage.getItem(PREFILLED_INFO_CITY)
  const [showSupportModal, setShowSupportModal] = useState(false)
  const { timeRemainingBeforeResend, onSendVerificationEmail } =
    useEmailVerification()
  const navigate = useNavigate()

  /**
   * Poll for checking if email is verified
   */
  useEffect(() => {
    const auth = getAuth()
    const user = auth.currentUser
    const intervalId = window.setInterval(async () => {
      // get auth again
      await user?.reload()
      if (user?.emailVerified) {
        window.location.reload()
      }
    }, 2000)
    return () => clearInterval(intervalId)
  }, [])

  const auth = getAuth()
  const user = auth.currentUser
  const leftCol = (
    <>
      <Col gap={theme.space.sm}>
        <Text variant="h5">Step 2/3</Text>
        <Text variant="h2">Verify your email</Text>
        <Row>
          <Text variant="body1">
            A verification email has been sent to: {user?.email}
          </Text>
        </Row>
        <Row>
          <Text variant="body1">
            Click on the link in your email to complete your sign up. You may
            need to check your spam folder.
          </Text>
        </Row>
        <Row wrap gap={theme.space.us} mt={theme.space.med}>
          {!!timeRemainingBeforeResend && (
            <Text variant="body3">
              Wait {timeRemainingBeforeResend} seconds before attempting to
              resend.
            </Text>
          )}
          <Button
            disabled={
              timeRemainingBeforeResend === null ||
              timeRemainingBeforeResend > 0
            }
            variant={ButtonVariant.FILLED}
            style={{ width: '100%' }}
            onClick={() => {
              onSendVerificationEmail()
            }}
          >
            Resend verification email
          </Button>

          <Button
            variant={ButtonVariant.OUTLINED}
            style={{ width: '100%', marginTop: theme.space.xs }}
            onClick={() => {
              setShowSupportModal(true)
            }}
          >
            Contact support
          </Button>
        </Row>
      </Col>
      {!isReactNativeApp && (
        <Row center mt={40}>
          <Text
            variant="link"
            onClick={() => {
              handleLogout()
              navigate('/login')
            }}
          >
            Go back to log in
          </Text>
        </Row>
      )}
      <Modal
        size={MODAL_SIZE.SMALL}
        isOpen={showSupportModal}
        handleClose={() => setShowSupportModal(false)}
      >
        <SupportModalContent handleClose={() => setShowSupportModal(false)} />
      </Modal>
    </>
  )

  return (
    <AuthScreenWrapper
      leftCol={leftCol}
      rightCol={
        <WorkersRightCol
          city={city ?? undefined}
          isFromBasicDetailOrEmailVerification
        />
      }
    />
  )
})
