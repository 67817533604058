import { THREE_WEEKS_IN_DAYS } from '@traba/consts'
import { useHotSettings } from '@traba/hooks'
import { ButtonVariant, LoadingSpinner, Row } from '@traba/react-components'
import {
  CreateShiftRequest,
  DailyViewOfScheduleDetails,
  RoleInfoForCreateShiftRequest,
  ShiftRequest,
  ShiftRequestParentWithShiftRequest,
  ShiftStatus,
  UserRole,
} from '@traba/types'
import { addDays } from 'date-fns'
import { Dispatch, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { MainLayout } from 'src/components'
import useNavigationGuard from 'src/components/NavigationGuard/NavigationGuard'
import { useQueryParams } from 'src/helpers'
import { useCompany } from 'src/hooks/useCompany'
import { useEditSchedule } from 'src/hooks/useEditSchedule'
import { useMembers } from 'src/hooks/useMembers'
import { useShiftRequestParent } from 'src/hooks/useShiftRequestParent'
import { ShiftAndAddress, useShifts } from 'src/hooks/useShifts'
import { useUser } from 'src/hooks/useUser'
import { BULLET_CHAR } from 'src/libs/constants'
import { getRoleInfoForCreateShiftRequest } from '../AddToExistingSchedule/utils'
import { ScheduleEditType } from '../BookShifts/BookShiftsScreen.hooks'
import * as S from '../BookShifts/BookShiftsScreen.styles'
import { EditOperation } from '../BookShifts/types'
import { EditScheduleSaveChangesModal } from './EditScheduleSaveChangesModal'

export function EditScheduleScreen() {
  const { shiftRequestParentId = '' } = useParams<{
    shiftRequestParentId: string
  }>()
  const { shiftRequestParent, isLoading: isLoadingShiftRequestParent } =
    useShiftRequestParent(shiftRequestParentId)

  const query = useQueryParams()
  const editOperation = query.get('editOperation') ?? undefined

  const { isLoading: isLoadingUser } = useUser()
  const { isLoading: isLoadingCompany } = useCompany()
  const { isLoading: isLoadingHotSettings } = useHotSettings()
  const { isLoading: isLoadingMembers } = useMembers()

  const { recurringShiftRequests, recurringRoles } = useMemo(() => {
    const recurringShiftRequests =
      shiftRequestParent?.shiftRequests?.filter((sr) =>
        sr.schedules.some((s) => s.isRecurringSchedule),
      ) ?? []

    const recurringRoles = recurringShiftRequests.map((sr) =>
      getRoleInfoForCreateShiftRequest({
        shiftRequest: sr,
        copyRoleId: true,
      }),
    )
    return { recurringShiftRequests, recurringRoles }
  }, [shiftRequestParent?.shiftRequests])

  return (
    <MainLayout hideSidebar title="Book Shifts">
      {isLoadingShiftRequestParent ||
      isLoadingUser ||
      isLoadingCompany ||
      isLoadingHotSettings ||
      isLoadingMembers ||
      !shiftRequestParent ? (
        <LoadingSpinner />
      ) : (
        <EditScheduleScreenUI
          shiftRequestParent={shiftRequestParent}
          rolesTemplate={recurringRoles}
          recurringShiftRequests={recurringShiftRequests}
          editOperation={editOperation as EditOperation}
        />
      )}
    </MainLayout>
  )
}

function EditScheduleScreenUI(props: {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  rolesTemplate: RoleInfoForCreateShiftRequest[]
  recurringShiftRequests: ShiftRequest[]
  editOperation?: EditOperation
  shiftRequestParentDayDetails?: DailyViewOfScheduleDetails
}) {
  const {
    shiftRequestParent,
    rolesTemplate,
    recurringShiftRequests,
    editOperation,
  } = props

  const {
    user,
    company,
    recurringRoles,
    setRecurringRoles,
    addNewRole,
    removeRole,
    updateRoleInfoForCreateShiftRequest,
    getWorkerById,
    existingScheduleInvitations,
    onSaveChanges,
    // navigation
    activeStep,
    onNavigate,
    onContinue,
    onPrevious,
    navigate,
    location,
    activeStepRef,
    isBookingInDraftRef,
    isBookingInDraft,
    scrollContainer,
    // other
    EDIT_SCHEDULE_STEPS,
    // isReactNativeApp,
    showSelectModal,
    setShowSelectModal,
    isCreatingOrUpdating,
    // even more other?
    // editsResult,
    // setEditsResult,
    setSelectedEffectiveDate,
    shiftRequest,
    setShiftRequest,
    shiftRequestMetadata,
    setShiftRequestMetadata,
    preSelectedDate,
    endDateChanged,
    setEndDateChanged,
    dateReliantUpdate,
  } = useEditSchedule({
    shiftRequestParent,
    rolesTemplate,
    recurringShiftRequests,
  })

  if (editOperation === EditOperation.SERIES) {
    setShowSelectModal(true)
  }

  useNavigationGuard({
    when: !!isBookingInDraft && !isCreatingOrUpdating,
    message: "You haven't saved the changes. Are you sure you want to leave?",
  })

  // runs the abandon flow track event effect when react-router location changes
  useEffect(() => {
    return () => {
      isBookingInDraftRef?.current &&
        window.analytics.track(`User Abandoned Edit Schedule`, {
          step: EDIT_SCHEDULE_STEPS[activeStepRef?.current || 0].title,
        })
    }
  }, [location.pathname])

  const isLimitedShiftCreator = user?.role === UserRole.LimitedShiftCreator

  const { data: rawShifts = [], isLoading: isLoadingShifts } = useShifts({
    shiftRequestParentIds: [shiftRequestParent.shiftRequestParentId],
    startAfter: new Date(),
    status: ShiftStatus.ACTIVE,
    endBefore: addDays(new Date(), THREE_WEEKS_IN_DAYS),
  })

  const shifts = useMemo(() => {
    const preselectedDateString = preSelectedDate?.toDateString()
    const uniqueOriginalStartTimes = new Set()
    return rawShifts.reduce((acc: ShiftAndAddress[], shift) => {
      const originalStartTime = shift.originalStartTime.getTime()
      const originalDateString = shift.originalStartTime.toDateString()
      if (
        !uniqueOriginalStartTimes.has(originalStartTime) &&
        (!preSelectedDate || preselectedDateString === originalDateString)
      ) {
        uniqueOriginalStartTimes.add(originalStartTime)
        acc.push(shift)
      }
      return acc
    }, [])
  }, [rawShifts, preSelectedDate])

  return (
    <>
      {/* STEP TITLE */}
      <S.BookShiftsContainer>
        <div className="xs-hide md-show md-3">
          <S.BookShiftsNavContainer>
            <S.BookShiftsNav>
              {EDIT_SCHEDULE_STEPS.map((s, i) => (
                <S.BookShiftsNavTitle
                  key={i}
                  active={i === activeStep}
                  onClick={() => onNavigate(i)}
                  style={{ ...(s.hideInSideBar && { display: 'none' }) }}
                >
                  {i === activeStep ? `${BULLET_CHAR} ` : ''}
                  {s.title}
                </S.BookShiftsNavTitle>
              ))}
            </S.BookShiftsNav>
          </S.BookShiftsNavContainer>
        </div>

        {/* STEP CONTENT */}
        <div className="xs-12 md-9" style={{ position: 'relative' }}>
          <div
            ref={scrollContainer}
            style={{ width: '100%', overflow: 'auto' }}
          >
            <S.BookShiftsContent>
              {EDIT_SCHEDULE_STEPS.map(({ Component }, i) =>
                Component ? (
                  <S.BookShiftsComponentContainer
                    key={i}
                    active={activeStep === i}
                  >
                    {/* MAIN CONTENT */}
                    <Component
                      scheduleEditType={ScheduleEditType.SCHEDULE}
                      shiftRequest={shiftRequest}
                      setShiftRequest={
                        setShiftRequest as Dispatch<
                          React.SetStateAction<Partial<CreateShiftRequest>>
                        >
                      }
                      shiftRequestMetadata={shiftRequestMetadata}
                      setShiftRequestMetadata={setShiftRequestMetadata}
                      onContinue={onContinue}
                      onPrevious={onPrevious}
                      onNavigate={onNavigate}
                      setShowSelectModal={setShowSelectModal}
                      userHasLimitedShiftManagementAbility={
                        isLimitedShiftCreator
                      }
                      showRequiredMultiShiftToggle={
                        !!company?.showRequiredMultiShiftToggle
                      }
                      showGenderPreferenceSelection={
                        !!company?.allowGenderPreference
                      }
                      existingScheduleInvitations={existingScheduleInvitations}
                      getWorkerById={getWorkerById}
                      defaultBreaks={company?.defaultBreaks ?? undefined}
                      recurringRoles={recurringRoles}
                      setRecurringRoles={setRecurringRoles}
                      updateRoleInfoForCreateShiftRequest={
                        updateRoleInfoForCreateShiftRequest
                      }
                      addNewRole={addNewRole}
                      removeRole={removeRole}
                      // TODO(gavin): not used but required -- make these optional later
                      selectedSingleShiftDates={[]}
                      setSelectSingleShiftDates={() => null}
                      onSaveChanges={async () => {
                        return
                      }}
                      shiftUpdates={{}}
                      role={recurringRoles[0]}
                      preSelectedDate={preSelectedDate}
                      endDateChanged={endDateChanged}
                      setEndDateChanged={setEndDateChanged}
                    />

                    {/* MEDIUM DOWN */}
                    {activeStep === 0 ? (
                      <Row justifyBetween>
                        <S.EditShiftsReturnButton
                          onClick={() => {
                            navigate(
                              `/schedule/${shiftRequestParent.shiftRequestParentId}`,
                            )
                          }}
                          variant={ButtonVariant.OUTLINED}
                        >
                          Return to Schedule Details
                        </S.EditShiftsReturnButton>
                        <S.EditShiftsReturnButton
                          onClick={async () => {
                            if (
                              (preSelectedDate && shifts.length === 1) ||
                              !dateReliantUpdate
                            ) {
                              await onSaveChanges(shifts)
                            } else {
                              setShowSelectModal && setShowSelectModal(true)
                            }
                          }}
                          variant={ButtonVariant.FILLED}
                        >
                          Save Changes
                        </S.EditShiftsReturnButton>
                      </Row>
                    ) : null}
                  </S.BookShiftsComponentContainer>
                ) : null,
              )}
            </S.BookShiftsContent>
          </div>
        </div>
        <EditScheduleSaveChangesModal
          shiftRequestParent={shiftRequestParent}
          showSelectModal={showSelectModal}
          setShowSelectModal={setShowSelectModal}
          setSelectedEffectiveDate={setSelectedEffectiveDate}
          onSaveChanges={onSaveChanges}
          isLoadingShifts={isLoadingShifts}
        />
      </S.BookShiftsContainer>
    </>
  )
}
