import { EmploymentType } from './employmentType'
import { Money } from './money'
import { StringBoolean } from './string-boolean'

export enum LineItemType {
  SHIFT = 'SHIFT',
  WORKER_SHIFT = 'WORKER_SHIFT',
  PENALTY = 'PENALTY',
  BONUS = 'BONUS',
}

export enum InvoiceStatus {
  DELETED = 'deleted',
  DRAFT = 'draft',
  OPEN = 'open',
  PAID = 'paid',
  VOID = 'void',
  UNCOLLECTIBLE = 'uncollectible',
  PAYMENT_ACTION_REQUIRED = 'payment_action_required',
  PAYMENT_FAILED = 'payment_failed',
  PAYMENT_PROCESSING = 'payment_processing',
}

export enum InvoiceVoidType {
  INCORRECT_VALUES = 'INCORRECT_VALUES',
  MISSING_LINE_ITEM = 'MISSING_LINE_ITEM',
  INCORRECT_INVOICE_GROUP_OR_LOCATIONS = 'INCORRECT_INVOICE_GROUP_OR_LOCATIONS',
  TIMESHEETS = 'TIMESHEETS',
  MISSING_ADJUSTMENT = 'MISSING_ADJUSTMENT',
}

export const InvoiceVoidTypeDisplayNames: Record<InvoiceVoidType, string> = {
  [InvoiceVoidType.INCORRECT_VALUES]: 'Incorrect Values',
  [InvoiceVoidType.MISSING_LINE_ITEM]: 'Missing Line Item',
  [InvoiceVoidType.INCORRECT_INVOICE_GROUP_OR_LOCATIONS]:
    'Incorrect Invoice Group or Locations',
  [InvoiceVoidType.TIMESHEETS]: 'Timesheets',
  [InvoiceVoidType.MISSING_ADJUSTMENT]: 'Missing Adjustment',
}

export type VoidInvoiceDto = {
  voidReason: InvoiceVoidType
}

export interface StripeInvoiceMetadata {
  invoiceId?: string
  invoiceGroupId?: string
  shiftIds?: string
}

export enum InvoiceChargeCycle {
  GROUPED = 'GROUPED',
  WEEKLY = 'WEEKLY',
}

export interface LineItem {
  workerId?: string
  shiftId: string
  type:
    | LineItemType.SHIFT
    | LineItemType.WORKER_SHIFT
    | LineItemType.PENALTY
    | LineItemType.BONUS
  chargeToBusiness: Money
  date: Date
  description: string
  stripeDescription: string
  stripeItemId?: string
  grossPayToWorker?: Money
  totalUnitsWorked?: number
  totalTimeWorked?: number
  chargeIds?: string[]
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum OrderBy {
  CREATED_AT = 'createdAt',
  INVOICE_ID = 'invoiceId',
  COMPANY_ID = 'companyId',
  STATUS = 'status',
}

export type Pagination = {
  startAt?: number
  orderBy?: OrderBy
  sortOrder?: SortOrder
  limit?: number
}

export type CreateLineItemResponseDto = {
  shiftId?: string
  workerId?: string
  type: LineItemType
  chargeToBusiness: Money
  grossPayToWorker?: Money
  totalTimeWorked?: number
  totalUnitsWorked?: number
  date: Date
  description: string
  stripeDescription: string // TODO: Deprecate after backend changes
  stripeItemId?: string
  shiftIds?: string[]
}

export type GenerateLineItemsResponseDto = {
  lineItems?: CreateLineItemResponseDto[]
  stripeMemo?: string
  needsManualInvoiceAdjustment?: boolean
}

export type CreateInvoiceDataDto = {
  companyId: string
  stripeInvoiceId?: string
  stripeInvoiceNumber?: string
  hostedInvoiceUrl?: string
  status?: InvoiceStatus
  startDate?: string | Date
  endDate?: string | Date
  regionIds?: string[]
  dueDate?: string | Date | null
  lineItems?: CreateLineItemDto[]
  parentInvoiceGroupId?: string
  stripeMemo?: string
  fromInvoice?: {
    id: string
  }
  disableLateFees?: boolean
  nextLateFeeDate?: Date
  customFields?: CustomField[]
}

export type CustomField = {
  name: string
  value: string
}

export enum InvoiceResyncType {
  STRIPE_MISMATCH,
  INTERNAL_MISMATCH,
}

export interface Invoice {
  companyId: string
  invoiceId: string
  stripeInvoiceId: string // Eg: in_1K4JRtJxJIrs34m7nQSlRDCF
  // The invoice number generated by stripe. Is not populated until an invoice is finalized
  stripeInvoiceNumber?: string // Eg: 988C859D-0004
  stripeMemo?: string
  hostedInvoiceUrl?: string
  status: InvoiceStatus
  startDate: Date
  endDate: Date
  dueDate: Date
  createdAt: Date
  totalCharge: Money
  lineItems: LineItem[]
  invoiceGroupId?: string
  invoiceGroupName?: string
  updatedAt?: Date
  paidAt?: Date
  regionIds?: string[]
  fromInvoice?: {
    id: string
    stripeId?: string
  }
  baseInvoiceId?: string // Reference relevant for invoice for fees added after the fact (i.e. late fees)
  disableLateFees?: boolean
  nextLateFeeDate?: Date
  customFields?: CustomField[]
  type?: LineItemType
  requiresResync?: InvoiceResyncType
  employmentType?: EmploymentType
}

export type ResyncInvoiceDto = {
  formatByType?: FormatLineItemType
}

export type ReviseInvoiceDto = {
  shouldRegenerateLineItems?: boolean
  voidReason: InvoiceVoidType
}

export type CreateLineItemDto = {
  shiftId?: string
  workerId?: string
  type: LineItemType
  chargeToBusiness: Money
  grossPayToWorker?: Money
  totalTimeWorked?: number
  totalUnitsWorked?: number
  date: string | Date
  description: string
  stripeDescription: string // TODO: Remove once backend deprecates
  stripeItemId?: string
  shiftIds?: string[]
}

export type EditLineItemDto = {
  id: string
  stripeDescription?: string // TODO: Remove once backend deprecates
  description?: string
}

export type CreateInvoiceQuery = {
  createStripeInvoice: StringBoolean
  shouldGenerateLineItems: StringBoolean
  shouldValidate?: StringBoolean
  showWorkerLineItems?: StringBoolean
}

export enum FormatLineItemType {
  SHIFT = 'SHIFT',
  WORKER_SHIFT = 'WORKER_SHIFT',
}

// New Invoice Preview DTOs

export type LineItemPreviewDto = {
  shiftId?: string
  type: LineItemType
  chargeToBusiness: Money
  description: string
  stripeDescription: string // Used in Node Server but to be deprecated soon
}

export type LineItemsPreviewResponseDto = {
  lineItems: LineItemPreviewDto[]
  stripeInvoiceMemo: string
  missingPendingAdjustments: boolean
  customFields: CustomField[]
}

export type ManuallyCreateInvoiceDto = {
  companyId: string
  formatByType: FormatLineItemType
  shiftIds: string[]
  invoiceGroupId?: string
}
