export interface EditSegment {
  id: string
  costCenterId: string | undefined
  startTime: Date | undefined
  endTime?: Date | undefined
  isBreak: boolean
  isUnaccounted?: boolean
}

enum SegmentError {
  START_AFTER_END = 'Start time is after end time.',
  START_BEFORE_CLOCK_IN = 'Start time is before clock in.',
  END_AFTER_CLOCK_OUT = 'End time is after clock out.',
  MISSING_START = 'Start time is missing.',
  MISSING_END = 'End time is missing.',
  START_OVERLAPPING = 'Start time is overlapping with the previous row.',
  END_OVERLAPPING = 'End time is overlapping with the next row.',
  START_IN_THE_FUTURE = 'Start time cannot be in the future.',
  END_IN_THE_FUTURE = 'End time cannot be in the future.',
  ZERO_MINUTE_SEGMENT = 'There are rows that start and end within the same minute. These will be ignored. Please check if this is expected.',
  WORKER_SHIFT_NOT_ON_BREAK = 'Worker is not on a break. Please specify end time.',
  WORKER_SHIFT_ON_BREAK = 'Worker is on a break. Please add an in progress break.',
}

const SegmentErrorProperties: {
  [key in SegmentError]: {
    invalidStart?: boolean
    invalidEnd?: boolean
    isWarning?: boolean
  }
} = {
  [SegmentError.START_AFTER_END]: { invalidStart: true, invalidEnd: true },
  [SegmentError.START_BEFORE_CLOCK_IN]: { invalidStart: true },
  [SegmentError.END_AFTER_CLOCK_OUT]: { invalidEnd: true },
  [SegmentError.MISSING_START]: { invalidStart: true },
  [SegmentError.MISSING_END]: { invalidEnd: true },
  [SegmentError.START_OVERLAPPING]: { invalidStart: true },
  [SegmentError.END_OVERLAPPING]: { invalidEnd: true },
  [SegmentError.START_IN_THE_FUTURE]: { invalidStart: true },
  [SegmentError.END_IN_THE_FUTURE]: { invalidEnd: true },
  [SegmentError.ZERO_MINUTE_SEGMENT]: {
    invalidStart: true,
    invalidEnd: true,
    isWarning: true,
  },
  [SegmentError.WORKER_SHIFT_NOT_ON_BREAK]: { invalidEnd: true },
  [SegmentError.WORKER_SHIFT_ON_BREAK]: {},
}

export { SegmentError, SegmentErrorProperties }
