import { SvgIconProps } from '@traba/types'

export default function Undo({ size, color, ...rest }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...rest}>
      <path
        d="M1 7.10169C1 10.3592 3.68629 13 7 13C10.3137 13 13 10.3592 13 7.10169C13 3.84415 10.3333 1.20339 7 1.20339C3 1.20339 1 4.48022 1 4.48022M1 4.48022L1 1M1 4.48022H4.1034"
        stroke={color ?? '#7A8A99'}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
