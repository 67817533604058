import {
  CompanyWorkerFeedback,
  CreateCompanyWorkerFeedback,
  ConnectionReason,
} from '@traba/types'
import { useConnections } from 'src/hooks/useConnections'

import { createReviewApi, updateReviewApi } from './useReviews'

const isExistingReview = (
  review: CreateCompanyWorkerFeedback | CompanyWorkerFeedback,
): review is CompanyWorkerFeedback => {
  return !!(review as CompanyWorkerFeedback).id
}

export const useBlocks = () => {
  const { blockWorker } = useConnections()

  const blockAndReviewWorker = async (
    workerId: string,
    connectionReasons: ConnectionReason[],
    review?: CreateCompanyWorkerFeedback | CompanyWorkerFeedback,
  ): Promise<void> => {
    const blockConnection = await blockWorker(workerId, connectionReasons)
    if (review) {
      const reviewToCreate = {
        ...review,
        connectionId: blockConnection.id,
      }
      if (isExistingReview(reviewToCreate)) {
        await updateReviewApi(reviewToCreate.id, reviewToCreate)
      } else {
        await createReviewApi(reviewToCreate)
      }
    }
  }

  return { blockAndReviewWorker }
}
