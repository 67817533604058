import {
  LocationResponse,
  Shift,
  WorkerShiftWithWorkerDetails,
} from '@traba/types'
import {
  getShiftDateString,
  getShiftTimeString,
  getTotalBreaksTimeString,
} from '@traba/utils'
import { UserState } from 'src/context/user/types'
import {
  formatDuration,
  getReadableTimeInTimezone,
} from 'src/shared/utils/dateUtils'
import { downloadAsCSV } from 'src/utils/miscUtils'
import { getAddressString, sortByName } from 'src/utils/stringUtils'
import { getIsCompleteStatus } from 'src/utils/workerShiftUtils'

export const buildWorkerTableCSV = (
  workersOnShift: WorkerShiftWithWorkerDetails[],
  shift: Shift,
  location: LocationResponse | undefined,
  userContext: UserState,
  enableWorkerEditTime?: boolean,
) => {
  const csv = []

  const copyArray = Array.from(workersOnShift)
  copyArray.sort((a, b) => sortByName(a.worker, b.worker))

  if (shift && location) {
    const dateString = getShiftDateString(
      shift.businessStartTime ?? shift.startTime,
      shift.endTime,
      shift.timezone,
    )
    const timeString = getShiftTimeString(
      shift.businessStartTime ?? shift.startTime,
      shift.endTime,
      shift.timezone,
    )

    const addressString = getAddressString(location.address, location.name)

    csv.push(`"Role","${shift.shiftRole}"`)
    csv.push(`"Date","${dateString},  ${timeString}"`)
    csv.push(`"Address","${addressString}"`)
    csv.push(`"Clock In","${shift.confirmationCode}"`)
    csv.push(`"Clock Out","${shift.clockOutCode}"`)
    csv.push('')
    csv.push(`"Name","Clocked In","Clocked Out","Break Time","Worked Time"`)

    for (const workerOnShift of copyArray) {
      const row = []
      row.push(
        `"${workerOnShift.worker.firstName} ${workerOnShift.worker.lastName}"`,
      )
      row.push(
        `"${
          workerOnShift.clockInTime
            ? getReadableTimeInTimezone(
                workerOnShift.clockInTime,
                shift.timezone,
              )
            : ''
        }"`,
      )
      row.push(
        `"${
          workerOnShift.clockOutTime
            ? getReadableTimeInTimezone(
                workerOnShift.clockOutTime,
                shift.timezone,
              )
            : ''
        }"`,
      )
      row.push(
        `"${
          Number(workerOnShift.breaks?.length) > 0
            ? getTotalBreaksTimeString({
                workerShift: workerOnShift,
                displayHours: true,
                enableWorkerEditTime,
              })
            : '00m'
        }"`,
      )
      row.push(
        `"${
          getIsCompleteStatus(workerOnShift.jobStatus)
            ? formatDuration(workerOnShift.timeWorked ?? 0)
            : ''
        }"`,
      )
      csv.push(row.join(','))
    }
    const filename = `Traba_Shift_${shift.shiftRole.replace(
      /\s/g,
      '_',
    )}_${(shift.businessStartTime ?? shift.startTime).toLocaleDateString()}.csv`
    downloadAsCSV(csv, filename)
    window.analytics?.track(`Downloaded Worker Roster`, {
      shiftId: shift.id,
      companyId: shift.companyId,
      userRole: userContext.userProfile?.role,
      userId: userContext.userProfile?.uid,
      downloadedAt: new Date(),
    })
  }
}
