import { Row } from 'src/components'
import styled from 'styled-components'

export const ShiftRequestItemContainer = styled(Row)<{
  disableChecked?: boolean
}>`
  :not(:first-child):not(:last-child) {
    margin: ${({ theme }) => theme.space.xxs}px 0;
  }
  padding: ${({ theme }) => theme.space.xs}px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.Grey20};
  border-radius: ${({ theme }) => theme.space.xxs}px;
  cursor: ${({ disableChecked }) => (disableChecked ? 'default' : 'pointer')};
`
