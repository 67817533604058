import { useAuthProviderIds } from '@traba/hooks'
import { Anchor } from '@traba/react-components'
import { useForm } from '@traba/utils'
import { useFormik } from 'formik'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { AuthScreenWrapper, Divider } from 'src/components/auth'
import { AppleAuthButton } from 'src/components/auth/AppleAuthButton'
import { GoogleAuthButton } from 'src/components/auth/GoogleAuthButton'
import { LoginRightCol } from 'src/components/auth/LoginRightCol'
import { OktaAuthButton } from 'src/components/auth/OktaAuthButton'
import {
  Alert,
  Button,
  ButtonVariant,
  Input,
  Link,
  Row,
  Text,
} from 'src/components/base'
import { useQueryParams } from 'src/helpers'
import useAppAuth from 'src/hooks/authHook'
import { REQUIRED_FIELD_MESSAGE } from 'src/libs/constants'
import { theme } from 'src/libs/theme'
import { email } from 'src/utils/formUtils'
import * as yup from 'yup'

export default function LoginScreen() {
  const [isLoading, setIsLoading] = useState(false)
  const { handleLogin } = useAppAuth()
  const { handleOnSubmitError } = useForm()

  // if there is a referral code in the query string, create a cookie.
  const query = useQueryParams()
  useEffect(() => {
    const newReferralCode = query.get('referralCode')
    if (newReferralCode) {
      Cookies.set('referralCode', newReferralCode.toUpperCase(), { expires: 1 })
    }
  }, [])

  const { authProviderIds, updateProviderIdMap, providerIdMap } =
    useAuthProviderIds(query.get('companyId') || undefined)

  useEffect(() => {
    updateProviderIdMap()
  }, [authProviderIds])

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: yup.object({
      email: email(),
      password: yup.string().required(REQUIRED_FIELD_MESSAGE), // not applying min length since we weren't validating this properly before Dec 2023
    }),
    onSubmit: async (values) => {
      try {
        formik.setStatus({})
        setIsLoading(true)
        await handleLogin(values.email, values.password)
      } catch (err) {
        handleOnSubmitError(err, formik)
        setIsLoading(false)
      }
    },
  })

  const { errors, touched } = formik

  const pageTitle = 'Log in to your Business Account'
  const leftCol = (
    <>
      <Text
        variant="h2"
        style={{
          marginBottom: !providerIdMap.email ? theme.space.med : undefined,
        }}
      >
        {pageTitle}
      </Text>
      {providerIdMap.email && (
        <>
          <Input
            full
            label="Email address"
            {...formik.getFieldProps('email')}
            inputStatus={touched.email && errors.email ? 3 : 1}
            errorMessage={formik.errors.email}
          />
          <Input
            full
            type="password"
            label="Password"
            {...formik.getFieldProps('password')}
            inputStatus={touched.password && errors.password ? 3 : 1}
            errorMessage={formik.errors.password}
          />
          <Row mt={theme.space.xs}>
            <Text variant="body2">
              <Link to="/forgot-password">Forgot Password?</Link>
            </Text>
          </Row>
          {formik.status && formik.status.error && (
            <Alert mt={theme.space.sm}>{formik.status.message}</Alert>
          )}

          <Row my={theme.space.sm}>
            <Button
              type="submit"
              id="log_in"
              variant={ButtonVariant.FILLED}
              style={{ width: '100%' }}
              loading={isLoading}
            >
              Log in
            </Button>
          </Row>
          <Divider text="OR" />
        </>
      )}

      {providerIdMap.google && <GoogleAuthButton />}
      {providerIdMap.apple && <AppleAuthButton />}
      {providerIdMap.okta && <OktaAuthButton providerId={providerIdMap.okta} />}

      {providerIdMap.email && (
        <Row mt={theme.space.sm} center>
          <Text variant="body2" center>
            Don't have an account yet? <Link to="/landing">Create account</Link>
          </Text>
        </Row>
      )}
      <Row center>
        <Text variant="body2" center>
          Looking for work?{' '}
          <Anchor href="https://www.traba.work/workers">
            Download our worker app
          </Anchor>
        </Text>
      </Row>
    </>
  )

  return (
    <form onSubmit={formik.handleSubmit}>
      <AuthScreenWrapper
        helmetTitle={pageTitle}
        leftCol={leftCol}
        rightCol={<LoginRightCol />}
      />
    </form>
  )
}
