import { theme } from '@traba/theme'
import { NotificationItem, NotificationPreferenceStatus } from '@traba/types'
import { mapStatusToShiftSelectedStatus } from '@traba/utils'
import { ChevronDown } from '../base-components/ChevronDown'
import Row from '../base-components/Row'
import { SvgIcon } from '../base-components/SvgIcon'
import { Text } from '../base-components/Text'

interface Props {
  setting: NotificationItem
  textColor: string
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}

export const NotificationSettingsShiftsSelect = ({
  setting,
  textColor,
  onClick,
}: Props) => {
  const selectedStatus = mapStatusToShiftSelectedStatus(setting)

  return (
    <Row
      alignCenter
      onClick={onClick}
      style={{
        pointerEvents:
          setting.status === NotificationPreferenceStatus.NONE
            ? 'none'
            : 'auto',
      }}
    >
      {selectedStatus && <SvgIcon name="briefcase" color={textColor} />}
      <Text
        variant="body2"
        color={textColor}
        ml={theme.space.xxxs}
        mr={theme.space.xxxs}
      >
        {selectedStatus}
      </Text>
      {selectedStatus && <ChevronDown color={textColor} />}
    </Row>
  )
}
