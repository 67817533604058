import { theme } from '@traba/theme'
import styled from 'styled-components'
import { Row, Text } from '../base'
import { Checkbox } from '../base/CheckboxThemed'

export interface ConfirmConditionProps {
  label?: string
  checked?: boolean
  onClick?: () => void
}

export function ConfirmConditions(props: ConfirmConditionProps) {
  const { label, checked, onClick } = props
  return (
    <ConfirmConditionsContainer>
      <Checkbox selected={!!checked} onClick={onClick} />
      <Text ml={theme.space.xs} variant="body3" style={{ lineHeight: 1.5 }}>
        {label}
      </Text>
    </ConfirmConditionsContainer>
  )
}

const ConfirmConditionsContainer = styled(Row)`
  border-radius: ${theme.border.radius};
  background: ${theme.colors.Grey10};
  padding: ${theme.space.xs}px;
  align-items: center;
  display: flex;
  margin-top: ${theme.space.xs}px;
  margin-bottom: ${theme.space.xs}px;
`
