import { SvgIconProps } from '@traba/types'

export default function Reset({
  size,
  color = '#7A8A99',
  strokeWidth = 1.5,
  ...props
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M21 12.1525C21 17.0389 16.9706 21 12 21C7.02944 21 3 17.0389 3 12.1525C3 7.26622 7 3.30508 12 3.30508C18 3.30508 21 8.22034 21 8.22034M21 8.22034L21 3M21 8.22034H16.3449"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
