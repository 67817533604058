import {
  CompanyInvitation,
  CompanyInvitationStatus,
  LocationResponse,
  ReplacementSupervisorForUser,
  ReplacementSupervisorUserResponse,
  User,
  USER_ROLE_NAMES,
  UserAccessLevel,
  UserWithOptionalRole,
  UserWithRole,
} from '@traba/types'
import { isAfter, subWeeks } from 'date-fns'
import { isLocationActive } from './locationUtils'

export function isBizMemberCompanyWide(user: Partial<User> | undefined) {
  if (!user) {
    return false
  }
  return user.userAccessLevel === UserAccessLevel.COMPANY_WIDE
}

export function isBizMemberLocationAssigned(user: Partial<User>) {
  return user.userAccessLevel === UserAccessLevel.LOCATIONS_ASSIGNED
}

export function assignedActiveLocationsForMember(
  user: Pick<User, 'userAccessLevel' | 'locations'>,
): LocationResponse[] {
  return user?.userAccessLevel === UserAccessLevel.LOCATIONS_ASSIGNED
    ? user.locations?.filter(isLocationActive) || []
    : []
}

export function assignedLocationIdsForMember(
  user: Partial<User> | null,
): string[] {
  return user?.userAccessLevel === UserAccessLevel.LOCATIONS_ASSIGNED
    ? user?.locations?.map((loc) => loc.locationId) || []
    : []
}

export function doesUserHaveAccessToLocation({
  user,
  locationId,
}: {
  user?: Partial<User>
  locationId: string
}) {
  if (!user) {
    return false
  }

  return (
    isBizMemberCompanyWide(user) ||
    assignedLocationIdsForMember(user).includes(locationId)
  )
}

export function doesUserHaveAccessToAnyLocation({
  user,
  locationIds,
}: {
  user?: Pick<User, 'userAccessLevel' | 'locations'>
  locationIds: Set<string>
}) {
  if (!user) {
    return false
  }

  return (
    isBizMemberCompanyWide(user) ||
    assignedLocationIdsForMember(user).some((locationId) =>
      locationIds.has(locationId),
    )
  )
}

export function allMemberIdsForLocation({
  locationId,
  members,
}: {
  locationId?: string
  members?: Partial<User>[]
}): string[] {
  return getMembersWithAccessAtLocation({
    locationId,
    members,
  })
    .filter((member): member is { uid: string } => !!member.uid)
    .map((member) => member.uid) as string[]
}

export function allCompanyWideMembers(
  members: Partial<User>[] = [],
): (Partial<User> & { uid: string })[] {
  return members.filter(
    (member): member is Partial<User> & { uid: string } =>
      isBizMemberCompanyWide(member) && !!member.uid,
  )
}

export function allCompanyWideMemberIds(
  members: Partial<User>[] = [],
): string[] {
  return members
    .filter(
      (member): member is { uid: string } =>
        isBizMemberCompanyWide(member) && !!member.uid,
    )
    .map((member) => member.uid) as string[]
}

export function getLocationAssignedMembersForLocation({
  locationId,
  members = [],
}: {
  locationId?: string
  members?: Partial<User>[]
}): Partial<User>[] {
  if (!locationId || members.length === 0) {
    return []
  }
  return members.filter(
    (m) =>
      m.userAccessLevel === UserAccessLevel.LOCATIONS_ASSIGNED &&
      m.locations?.some((loc) => loc.locationId === locationId),
  )
}

export function getMembersWithAccessAtLocation({
  locationId,
  members = [],
}: {
  locationId?: string
  members?: Partial<User>[]
}): Partial<User>[] {
  if (!locationId || members.length === 0) {
    return []
  }
  return members.filter(
    (m) =>
      m.userAccessLevel === UserAccessLevel.COMPANY_WIDE ||
      (m.userAccessLevel === UserAccessLevel.LOCATIONS_ASSIGNED &&
        m.locations?.some((loc) => loc.locationId === locationId)),
  )
}

export function getMembersWithAccessToAnyGivenLocations<
  T extends Pick<User, 'userAccessLevel' | 'locations'>,
>({ locationIds, members }: { locationIds: Set<string>; members: T[] }): T[] {
  const locationIdSet = new Set<string>(locationIds)
  return members.filter(
    (member) =>
      // Company wide users will be always returned
      member.userAccessLevel === UserAccessLevel.COMPANY_WIDE ||
      (member.userAccessLevel === UserAccessLevel.LOCATIONS_ASSIGNED &&
        doesUserHaveAccessToAnyLocation({
          user: member,
          locationIds: locationIdSet,
        })),
  )
}

export function isBizInvitationCompanyWide(
  invitation: Partial<CompanyInvitation>,
) {
  return invitation.userAccessLevel === UserAccessLevel.COMPANY_WIDE
}

export function isBizInvitationLocationAssigned(
  invitation: Partial<CompanyInvitation>,
) {
  return invitation.userAccessLevel === UserAccessLevel.LOCATIONS_ASSIGNED
}

export function assignedActiveLocationsForInvitation(
  invitation: Partial<CompanyInvitation>,
): LocationResponse[] {
  return invitation.userAccessLevel === UserAccessLevel.LOCATIONS_ASSIGNED
    ? invitation.locations?.filter(isLocationActive) || []
    : []
}

export function hasRole(u: Partial<User>): u is UserWithRole {
  return !!u.role
}

export function isStatusAcceptable(status: CompanyInvitation['status']) {
  return [
    CompanyInvitationStatus.PENDING,
    CompanyInvitationStatus.SENT,
  ].includes(status)
}

export function isInvitationExpired(i: CompanyInvitation): boolean {
  return +i.expiresAt < Date.now()
}

export function isAfterTwoWeeksAgo(d: Date) {
  const twoWeeksAgo = subWeeks(new Date(), 2)
  return isAfter(d, twoWeeksAgo)
}

export function shouldDisplayInvitation(i: CompanyInvitation): boolean {
  return isStatusAcceptable(i.status) && isAfterTwoWeeksAgo(i.expiresAt)
}

export function matchesOutstandingOrAcceptedInvitation(
  m: Partial<User>,
  i: CompanyInvitation,
): boolean {
  if (i.email !== m.email) {
    return false
  }
  if (i.status === CompanyInvitationStatus.ACCEPTED) {
    return true
  }
  if (!isStatusAcceptable(i.status)) {
    return false
  }
  return !isInvitationExpired(i)
}

export function getUserFullName(
  member: Pick<User, 'firstName' | 'lastName'>,
): string {
  return `${member.firstName} ${member.lastName}`.trim()
}

export function sortMemberByFullName(
  bizMemberA: Partial<User>,
  bizMemberB: Partial<User>,
) {
  const memberFullNameA = getUserFullName(bizMemberA)
  const memberFullNameB = getUserFullName(bizMemberB)
  return memberFullNameA.localeCompare(memberFullNameB)
}

export function getUserNameWithRole(
  userData: Pick<UserWithOptionalRole, 'firstName' | 'lastName' | 'role'>,
): string {
  const memberName = getUserFullName(userData)
  return userData.role
    ? `${memberName} (${USER_ROLE_NAMES[userData.role]})`
    : `${memberName} (Contact)`
}

export function getLocationsToReplaceSupervisorsForMember({
  locationsForFutureShifts = [],
  userAccessLevel,
  assignedLocationIds = new Set(),
}: {
  locationsForFutureShifts?: LocationResponse[]
  userAccessLevel?: UserAccessLevel
  assignedLocationIds?: Set<string>
}): LocationResponse[] {
  return locationsForFutureShifts.filter(
    (loc) =>
      userAccessLevel === UserAccessLevel.LOCATIONS_ASSIGNED &&
      !assignedLocationIds.has(loc.locationId),
  )
}

export function getSupervisorsToReplaceAtLocation({
  assignedMemberIdsAtLocation, // the new assigned members at that location to check against
  supervisorsForFutureShiftAtLocation, // a list of supervisors who have future shifts at the location
}: {
  assignedMemberIdsAtLocation: Set<string>
  supervisorsForFutureShiftAtLocation: ReplacementSupervisorUserResponse[]
}): (ReplacementSupervisorUserResponse & { uid: string })[] {
  return supervisorsForFutureShiftAtLocation.filter(
    (member): member is ReplacementSupervisorUserResponse & { uid: string } =>
      !!member.uid &&
      !isBizMemberCompanyWide(member) &&
      !assignedMemberIdsAtLocation.has(member.uid),
  )
}

export function getReplacementSupervisorsForUsers({
  replacementSupervisorMap, // a map of oldSuperVisorId to newSupervisorId replacements
  supervisorIdsNeedingReplacement, // the set of supervisor ids that need replacement at this location
}: {
  replacementSupervisorMap: Record<string, string>
  supervisorIdsNeedingReplacement: Set<string>
}): ReplacementSupervisorForUser[] {
  const replacementSupervisorsForUsers = Object.entries(
    replacementSupervisorMap,
  )
    .filter(
      ([oldSupervisorId, newSupervisorId]) =>
        supervisorIdsNeedingReplacement.has(oldSupervisorId) &&
        !!newSupervisorId,
    )
    .map(([oldSupervisorId, newSupervisorId]) => ({
      oldSupervisorId,
      newSupervisorId,
    }))

  return replacementSupervisorsForUsers
}

export function canManageUserByLocationAccess({
  currentUserAccessLevel,
  targetUserAccessLevel,
  targetLocationIds,
  activeLocationIdsForCurrentUser,
}: {
  currentUserAccessLevel?: UserAccessLevel
  targetUserAccessLevel?: UserAccessLevel
  targetLocationIds: string[]
  activeLocationIdsForCurrentUser: Set<string>
}) {
  // Company-wide users can manage all (if they have permission)
  if (currentUserAccessLevel === UserAccessLevel.COMPANY_WIDE) {
    return true
  }

  // Location-assigned users cannot manage company-wide users
  if (
    currentUserAccessLevel === UserAccessLevel.LOCATIONS_ASSIGNED &&
    targetUserAccessLevel === UserAccessLevel.COMPANY_WIDE
  ) {
    return false
  }

  // Find all assigned locations
  const targetLocationIdsSet = new Set(targetLocationIds)

  // Check if user's locationIds is superset of target locationIds
  return (
    targetLocationIdsSet.size <= activeLocationIdsForCurrentUser.size &&
    Array.from(targetLocationIdsSet).every((locationId) =>
      activeLocationIdsForCurrentUser.has(locationId),
    )
  )
}
