import { FileType } from '@traba/hooks'
import React, { useState } from 'react'
import {
  Button,
  ButtonVariant,
  EmptyPlaceholderTile,
  Modal,
  Row,
  Text,
} from 'src/components/base'
import useMobile from 'src/hooks/useMobile'
import { useUserPermissions } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { TrainingVideo, UserRolePermission } from 'src/types'

import { Plus } from '../assets/svg'
import { UploadVideoModal } from '../Modals/UploadVideoModal'
import { DesktopTrainingVideos } from './DesktopTrainingVideos'
import { MobileTrainingVideos } from './MobileTrainingVideos'

export type TrainingVideosProfileProps = {
  videos: TrainingVideo[]
}

export const TrainingVideosProfileUI: React.FC<TrainingVideosProfileProps> = (
  props: TrainingVideosProfileProps,
) => {
  const { isMobileViewOrReactNative } = useMobile()

  const userCanEditVideos: boolean = useUserPermissions([
    UserRolePermission.ManageShifts,
    UserRolePermission.ManageCompanySettings,
  ])
  const [isModalOpen, setIsModalOpen] = useState(false)

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  return (
    <div style={{ maxWidth: theme.media.minLarge }}>
      <Row alignCenter justifyBetween mb={theme.space.xs}>
        <div style={{ width: '75%' }}>
          <Text style={{ marginBottom: theme.space.xxs }} variant="h5">
            Training videos
          </Text>
          <Text variant="body2">
            {userCanEditVideos ? 'Manage' : 'View'} your company's training
            videos. If a video is selected as "Required by all shifts", it will
            show up in all shifts your company post in the future.
          </Text>
        </div>
        {userCanEditVideos && (
          <Button
            leftIcon={<Plus />}
            onClick={() => {
              openModal()
            }}
            reverse
            variant={ButtonVariant.OUTLINED}
          >
            Upload Video
          </Button>
        )}
      </Row>

      {props.videos.length ? (
        isMobileViewOrReactNative ? (
          <MobileTrainingVideos videos={props.videos} />
        ) : (
          <DesktopTrainingVideos
            videos={props.videos}
            userCanEditVideos={userCanEditVideos}
          />
        )
      ) : (
        <EmptyPlaceholderTile
          title="You don't have any training videos yet"
          subtitle='Click on "Upload Video" button to add a new video'
          iconName="company"
        />
      )}
      <Modal isOpen={isModalOpen} handleClose={closeModal}>
        <UploadVideoModal
          onClose={closeModal}
          fileType={FileType.TRAINING_VIDEOS}
        />
      </Modal>
    </div>
  )
}
