import { useForm } from '@traba/utils'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Button, ButtonVariant, InputPhone } from 'src/components/base'
import { Text } from 'src/components/base/Text'
import { usePersonalProfile } from 'src/components/PersonalProfile/PersonalProfile.hooks'
import { useUserContext } from 'src/context/user/UserContext'
import { theme } from 'src/libs/theme'
import { phoneNumber } from 'src/utils/formUtils'
import {
  checkArePhoneNumbersMatch,
  formatPhoneNumber,
} from 'src/utils/phoneNumberUtils'

import * as yup from 'yup'
import { ModalWithImage } from '../ModalWithImage'

export type SmsConsentModalContentProps = {
  setShowSmsConsentModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const SmsConsentModalContent = ({
  setShowSmsConsentModal,
}: SmsConsentModalContentProps) => {
  const { handleOnSubmitError } = useForm()
  const [isPhoneMismatch, setIsPhoneMismatch] = useState(false)
  const { patchUser } = usePersonalProfile()
  const { state } = useUserContext()
  const defaultUser = {
    phoneNumber: formatPhoneNumber(state.userProfile?.phoneNumber || ''),
  }

  const formik = useFormik({
    initialValues: defaultUser,
    validationSchema: yup.object({
      phoneNumber: phoneNumber(),
    }),
    onSubmit: async (values) => {
      try {
        if (
          !checkArePhoneNumbersMatch(
            values.phoneNumber,
            defaultUser.phoneNumber,
          )
        ) {
          setIsPhoneMismatch(true)
          return
        }
        patchUser({
          smsConsent: { agreedToSmsConsent: true },
          communicationPreferences: {
            shiftCodesSmsEnabled: true,
          },
        })
        window.analytics.track('User Agreed to SMS Consent')
        setShowSmsConsentModal(false)
      } catch (err: unknown) {
        handleOnSubmitError(err, formik)
      }
    },
  })

  const { errors } = formik

  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalWithImage
        hideIcon
        imgSrc="/img/worker-with-one-box.png"
        handleClose={() => setShowSmsConsentModal(false)}
      >
        <Text variant="h3">
          You can now receive clock in/out codes via text messages!
        </Text>
        <Text variant="body2" mt={theme.space.sm} mb={theme.space.xxs}>
          To receive the clock in/out codes for upcoming shifts via text
          message:
        </Text>
        <Text variant="body2">
          • Simply add any members to the “Shift notifications” during shift
          creation
        </Text>
        <Text variant="body2" mb={theme.space.xxs}>
          • Or ask Admin to add any members to receive sms for all shifts in
          account settings.
        </Text>
        <InputPhone
          value={formik.values.phoneNumber}
          onChange={(val: string) =>
            formik.handleChange({
              target: { value: val, name: 'phoneNumber' },
            })
          }
        />
        {errors && (
          <Text variant="error" mt={theme.space.xxs}>
            {errors.phoneNumber}
          </Text>
        )}
        <Text variant="body3" mt={theme.space.xxs} mb={theme.space.xxs}>
          By clicking on “I agree”, I consent to receive text messages from
          Traba Inc. at the number provided above, and I agree that texts may be
          sent using through an autodialer or other technology.
        </Text>
        {isPhoneMismatch && (
          <Text variant="error" mb={theme.space.xxs}>
            {`The phone number you entered is ${formatPhoneNumber(
              formik.values.phoneNumber,
              true,
            )}, which is different from the phone number on your account: ${formatPhoneNumber(
              defaultUser.phoneNumber,
              true,
            )}.
            Do you want to update it? `}
          </Text>
        )}
        {isPhoneMismatch ? (
          <Button
            onClick={() => {
              patchUser({
                smsConsent: { agreedToSmsConsent: true },
                communicationPreferences: {
                  shiftCodesSmsEnabled: true,
                },
                phoneNumber: formik.values.phoneNumber,
              })
              window.analytics.track('User Agreed to SMS Consent')
              setShowSmsConsentModal(false)
            }}
          >
            Update and continue
          </Button>
        ) : (
          <Button type="submit">I agree</Button>
        )}
        <Button
          variant={ButtonVariant.OUTLINED}
          style={{ marginTop: theme.space.xs }}
          onClick={() => setShowSmsConsentModal(false)}
          aria-label="skip"
        >
          Maybe Later
        </Button>
      </ModalWithImage>
    </form>
  )
}
