import { useForm } from '@traba/utils'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthScreenWrapper } from 'src/components/auth'
import {
  Alert,
  Button,
  ButtonVariant,
  Input,
  Link,
  Row,
  Text,
} from 'src/components/base'
import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'
import { email } from 'src/utils/formUtils'
import * as yup from 'yup'

export default function ForgotPasswordScreen() {
  const { isReactNativeApp } = useMobile()

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = React.useState(false)
  const [successfulSubmit, setSuccessfulSubmit] = React.useState(false)
  const { handleOnSubmitError } = useForm()

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: yup.object({
      email: email(),
    }),
    onSubmit: async (values) => {
      try {
        formik.setStatus({})
        setIsLoading(true)
        const auth = getAuth()
        await sendPasswordResetEmail(auth, values.email)
        setIsLoading(false)
        setSuccessfulSubmit(true)
      } catch (err) {
        handleOnSubmitError(err, formik)
        setIsLoading(false)
      }
    },
  })

  const { errors, touched } = formik
  const leftCol = (
    <>
      <Text variant="h2">Forgot your Password?</Text>
      {!successfulSubmit ? (
        <>
          <Row mt={theme.space.xs} mb={theme.space.lg}>
            <Text variant="body2">
              Enter your email below to receive a reset password link
            </Text>
          </Row>
          <Input
            full
            label="Email address"
            {...formik.getFieldProps('email')}
            inputStatus={touched.email && errors.email ? 3 : 1}
            errorMessage={formik.errors.email}
          />
          {formik.status && formik.status.error && (
            <Alert mt={theme.space.med}>{formik.status.message}</Alert>
          )}
          <Row mt={theme.space.lg}>
            <Button
              type="submit"
              variant={ButtonVariant.FILLED}
              style={{ width: '100%' }}
              loading={isLoading}
            >
              Send Reset Link
            </Button>
          </Row>
          {!isReactNativeApp && (
            <>
              <Row center mt={theme.space.med}>
                <Text variant="body2" center>
                  <Link to="/login">Go back to Log in</Link>
                </Text>
              </Row>
              <Row center mt={theme.space.sm}>
                <Text variant="body2" center>
                  Don't have an account yet?{' '}
                  <Link to="/landing">Create account</Link>
                </Text>
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          <Row mt={theme.space.sm} mb={theme.space.lg}>
            <Text variant="body2">
              If you have an account with us, you will receive an email with a
              link to reset your password.
            </Text>
          </Row>
          <Row mb={theme.space.lg}>
            <Button
              onClick={() => navigate('/login')}
              variant={ButtonVariant.FILLED}
              style={{ width: '100%' }}
            >
              Return to Login
            </Button>
          </Row>
        </>
      )}
    </>
  )
  return (
    <form onSubmit={formik.handleSubmit}>
      <AuthScreenWrapper
        leftCol={leftCol}
        helmetTitle="Traba - Forgot Password"
      />
    </form>
  )
}
