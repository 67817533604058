import React from 'react'

import { useInvoiceTable } from './InvoiceTable.hooks'
import { InvoiceTableUI } from './InvoiceTable.ui'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook

export const InvoiceTable = (props: any) => {
  const logic = useInvoiceTable(props)

  return <InvoiceTableUI {...logic} {...props} />
}
