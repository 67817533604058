import { LoadingSpinner, MODAL_SIZE } from '@traba/react-components'
import React from 'react'
import { theme } from 'src/libs/theme'

import { Col, Divider, Row } from '../base'
import { Button, ButtonVariant } from '../base/Button'
import { Dialog, DialogProps } from '../base/Dialog/Dialog'
import { WizardHeader } from './components/WizardHeader'
import { useWizard } from './useWizard'

export type WizardStep = {
  stepTitle: string
  id: string
  stepSubtitle?: string | JSX.Element
  stepPicture: string
  stepBody: JSX.Element
  onValidate: () => boolean
  renderStepAs?: React.ElementType
  isComplete?: boolean
}

type WizardDialogProps = {
  title: string
  steps: WizardStep[]
  startingStepIndex?: number
  onClose: DialogProps['onClose']
  open: DialogProps['open']
  size?: MODAL_SIZE
  isLoading?: boolean
  allowDismiss?: boolean
}

export const WizardDialog: React.FC<WizardDialogProps> = ({
  title,
  steps,
  startingStepIndex,
  onClose,
  open,
  size,
  isLoading,
  allowDismiss,
}) => {
  const {
    currentStepIndex,
    hasNextStep,
    hasPrevStep,
    nextStep,
    prevStep,
    goToStep,
  } = useWizard({
    startingStepIndex: startingStepIndex || 0,
    totalSteps: steps.length,
  })

  const currentStep = steps[currentStepIndex]

  const footer = (
    <>
      <Button
        style={{ minWidth: 100 }}
        variant={ButtonVariant.OUTLINED}
        onClick={prevStep}
        disabled={!hasPrevStep()}
      >
        Previous
      </Button>
      <Button
        style={{ minWidth: 100 }}
        variant={ButtonVariant.FILLED}
        onClick={hasNextStep() ? nextStep : onClose}
        disabled={hasNextStep() && !currentStep.onValidate()}
        aria-label="skip"
      >
        {hasNextStep() ? 'Next' : 'Finish'}
      </Button>
    </>
  )

  return (
    <Dialog
      open={open}
      dialogTitle={title}
      onClose={onClose}
      onDismiss={onClose}
      renderDialogFooterAs={() => footer}
      style={{
        padding: 0,
        width: '100%',
      }}
      size={size}
      dismissable={allowDismiss}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Col
          style={{
            height: '100%',
          }}
        >
          <WizardHeader
            steps={steps}
            currentStepIndex={currentStepIndex}
            goToStep={goToStep}
          />
          <Divider />
          <Row
            key={currentStep?.id}
            fullWidth
            px={theme.space.sm}
            py={theme.space.sm}
            style={{
              overflowY: 'auto',
            }}
          >
            {currentStep?.stepBody}
          </Row>
        </Col>
      )}
    </Dialog>
  )
}
