import { Shift } from '@traba/types'
import { useState } from 'react'
import { Button } from 'src/components/base'

import EditShiftsSelectedShiftsButton from '../EditShifts/EditShiftsSelectedShiftsButton'

export default function EditShiftsSaveButton({
  onSaveChanges,
  validationError,
  selectedShifts,
  setShowSelectModal,
}: {
  onSaveChanges: () => Promise<void>
  validationError?: { message: string; title: string }
  selectedShifts?: Shift[]
  setShowSelectModal?: (show: boolean) => void
}) {
  const [loading, setLoading] = useState<boolean>(false)
  async function onSave() {
    try {
      setLoading(true)
      await onSaveChanges()
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      style={{
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <EditShiftsSelectedShiftsButton
        selectedShifts={selectedShifts}
        setShowSelectModal={setShowSelectModal}
      />
      <Button loading={loading} onClick={onSave} disabled={!!validationError}>
        Save Changes
      </Button>
    </div>
  )
}
