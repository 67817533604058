import { RaisedCardTile } from '@traba/react-components'
import React from 'react'
import { Row, Text } from 'src/components/base'

export interface ClockCodeProps {
  code: number
  tileHeight?: number
  tileWidth?: number
  fontSize?: number
}

export const ClockCode = (props: ClockCodeProps) => {
  const { tileHeight = 72, tileWidth = 56, fontSize = 24 } = props
  const code = props.code === 0 ? '0000' : props.code.toString()
  return (
    <Row style={{ gap: 2 }}>
      {code.split('').map((digit: string, i) => {
        return (
          <RaisedCardTile
            key={`clock-code_raised-card-tile_${i}`}
            style={{ width: tileWidth, height: tileHeight }}
          >
            <Text variant="h3" style={{ fontSize: fontSize }}>
              {digit}
            </Text>
          </RaisedCardTile>
        )
      })}
    </Row>
  )
}
