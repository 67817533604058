import { BreakType } from '@traba/types'
import { JobStatus } from 'src/types'

export function getIsCompleteStatus(jobStatus: JobStatus) {
  return (
    jobStatus !== JobStatus.InProgress &&
    jobStatus !== JobStatus.ToDo &&
    jobStatus !== JobStatus.OnBreak
  )
}

export function getIsReviewableStatus(jobStatus: JobStatus) {
  return [
    JobStatus.Complete,
    JobStatus.InProgress,
    JobStatus.Abandoned,
    JobStatus.Rejected,
    JobStatus.OnBreak,
  ].includes(jobStatus)
}

export function getWorkerShiftStringId(workerId: string, shiftId: string) {
  return `${workerId.substring(0, 8)}_${shiftId}`
}

export function isBreaksEditable(breakType: BreakType, jobStatus: JobStatus) {
  if (breakType !== BreakType.MANUAL_UNPAID) {
    return jobStatus === JobStatus.Complete
  }
  return [JobStatus.InProgress, JobStatus.OnBreak, JobStatus.Complete].includes(
    jobStatus,
  )
}
