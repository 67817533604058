import { ShiftStatus } from './shift-enums'

// Worker's status of reading the announcement
export enum WorkerAnnouncementReadStatus {
  READ = 'READ',
  UNREAD = 'UNREAD',
}

// Announcement's sent status
export enum AnnouncementStatus {
  PENDING = 'PENDING',
  SENT = 'SENT',
  FAILED = 'FAILED',
}

export type WorkerAnnouncementStatus = {
  id: string
  createdAt: Date
  updatedAt?: Date
  workerId: string
  announcementId: string
  status: WorkerAnnouncementReadStatus
  announcement?: Announcement
}

export type CreateAnnouncementRequest = {
  imageUrls?: string[]
  content: string
  sentByOps: boolean
  opsOnly: boolean
  shiftId: string
  scheduledFor: Date
}

export type Announcement = {
  id: string
  createdAt: Date
  updatedAt?: Date
  status: AnnouncementStatus
  imageUrls?: string[]
  content: string
  senderEmail: string
  sentByOps: boolean
  opsOnly: boolean
  shiftId: string
  shift?: {
    id: string
    startTime: Date
    endTime: Date
    status: ShiftStatus
    role: {
      roleName: string
    }
    timezone?: string
  }
  scheduledFor?: Date
}
