import { useMutation } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  ShiftTime,
  WorkerShift,
  WorkerShiftWithWorkerDetails,
} from '@traba/types'
import { AxiosResponse } from 'axios'
import {
  useMutation as useMutationV3,
  useQuery,
  useQueryClient,
} from 'react-query'
import { ONE_MINUTE_IN_MS } from 'src/libs/constants'

export type BulkWorkerActionBasePayload = {
  workerIds: string[]
  shiftId: string
}

export interface BlockWorkers extends BulkWorkerActionBasePayload {
  blockReason: string
}

export interface RejectWorkers extends BulkWorkerActionBasePayload {
  rejectionReason: string
  shouldNotifyWorker: boolean
}

export interface ClockInWorkers extends BulkWorkerActionBasePayload {
  clockInTime: Date
}

export interface ClockOutWorkers extends BulkWorkerActionBasePayload {
  clockOutTime: Date
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AllSettledResultWithStatus<T = any> = {
  rejected: { message: string; status: number }[]
  fulfilled: T[]
}

export type ClockInOutResult = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: AllSettledResultWithStatus<WorkerShift>
}

function buildErrorMessages(
  failed: { message: string; status: number; [x: string]: any }[],
  data?: WorkerShiftWithWorkerDetails[],
) {
  if (failed.length > 0) {
    return failed.map(({ message, workerId }) => {
      const { firstName, lastName } =
        data?.find(({ worker }) => worker.uid === workerId)?.worker || {}
      return `${firstName || ''} ${lastName ? lastName + ' - ' : ''}${
        message ? `${message}` : ``
      }`
    })
  }
  return []
}

async function getWorkerShiftByShiftId(shiftId: string): Promise<any> {
  try {
    if (shiftId) {
      const res = await trabaApi.get(
        `my-company/shifts/${shiftId}/worker-shifts`,
      )
      return res.data
    }
  } catch (error) {
    console.error(error)
  }
}

export function parseAllSettledWithStatus<T>(
  result: PromiseSettledResult<T>[],
): AllSettledResultWithStatus<T> {
  const resp: AllSettledResultWithStatus<T> = {
    rejected: [],
    fulfilled: [],
  }
  result.forEach((r) => {
    switch (r.status) {
      case 'rejected':
        resp.rejected.push({
          message: r.reason.message,
          status: r.reason.status,
        })
        break
      case 'fulfilled':
        resp.fulfilled.push(r.value)
        break
      default:
        break
    }
  })

  return resp
}

export function useWorkerShiftMutations({
  data,
  onSuccess,
}: {
  data?: WorkerShiftWithWorkerDetails[]
  onSuccess?: () => void
}) {
  const { showSuccess, showError } = useAlert()
  const queryClient = useQueryClient()

  const handleActionOutcome = (
    response: { data: AllSettledResultWithStatus },
    successActionString: string,
    errorActionString: string,
  ) => {
    onSuccess && onSuccess()
    const success = response.data.fulfilled
    const errors = buildErrorMessages(response.data.rejected, data)
    errors.length
      ? showError(
          errors.join(`\n`),
          `${errorActionString} ${
            errors.length > 1 ? `${errors.length} workers` : `worker`
          }`,
        )
      : showSuccess(
          `${successActionString} ${
            success.length > 1 ? `${success.length} workers.` : 'worker.'
          }`,
        )
  }

  const clockInWorkersMutation = useMutationV3<
    ClockInOutResult,
    Error,
    ClockInWorkers
  >(
    (payload: ClockInWorkers) =>
      trabaApi.patch(
        `/my-company/worker-shifts/${payload.shiftId}/start-shift`,
        { clockInTime: payload.clockInTime },
        { params: { workerIds: payload.workerIds } },
      ),
    {
      onSuccess: (response: ClockInOutResult) => {
        handleActionOutcome(
          response,
          'Successfully clocked in',
          'Error clocking in',
        )
        // Invalidate shift stats query for this day to force recalculation
        response.data.fulfilled.forEach((ws) => {
          queryClient.invalidateQueries([
            'shiftStats',
            ws.shiftInfo.startTime.getDate(),
          ])
        })
      },
      onError: (e: Error) => {
        showError(e.message, 'Error clocking in workers')
      },
    },
  )

  const editClockInWorkersMutation = useMutationV3<
    ClockInOutResult,
    Error,
    ClockInWorkers
  >(
    (payload: ClockInWorkers) =>
      trabaApi.patch(
        `/my-company/worker-shifts/${payload.shiftId}`,
        { clockInTime: payload.clockInTime },
        { params: { workerIds: payload.workerIds } },
      ),
    {
      onSuccess: (response: ClockInOutResult) => {
        handleActionOutcome(
          response,
          'Successfully edited clock in times for',
          'Error editing clock in times for',
        )
      },
      onError: (e: Error) => {
        showError(e.message, 'Error clocking in workers')
      },
    },
  )

  const clockOutWorkersMutation = useMutationV3<
    ClockInOutResult,
    Error,
    ClockOutWorkers
  >(
    (payload: ClockOutWorkers) =>
      trabaApi.patch(
        `/my-company/worker-shifts/${payload.shiftId}/end-shift`,
        { clockOutTime: payload.clockOutTime },
        { params: { workerIds: payload.workerIds } },
      ),
    {
      onSuccess: (response: ClockInOutResult) => {
        handleActionOutcome(
          response,
          'Successfully clocked out',
          'Error clocking out',
        )
        // Invalidate shift stats query for this day to force recalculation
        response.data.fulfilled.forEach((ws) => {
          queryClient.invalidateQueries([
            'shiftStats',
            ws.shiftInfo.startTime.getDate(),
          ])
        })
      },
      onError: (e: Error) => {
        showError(e.message, 'Error clocking out workers')
      },
    },
  )

  const abandonWorkersMutation = useMutationV3<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    ClockOutWorkers
  >(
    async (payload: ClockOutWorkers) =>
      Promise.allSettled(
        payload.workerIds.map((workerId) =>
          trabaApi.patch(
            `/my-company/shifts/${payload.shiftId}/${workerId}/abandon`,
            {
              clockOutTime: payload.clockOutTime,
            },
          ),
        ),
      ),
    {
      onSuccess: (response: PromiseSettledResult<AxiosResponse>[]) => {
        handleActionOutcome(
          {
            data: parseAllSettledWithStatus(response),
          },
          'Marked abandoned for',
          'Error marking abandoned for',
        )
      },
      onError: (e: Error) => {
        showError(e.message, 'Error marking workers as abandoned')
      },
    },
  )

  const rejectWorkersMutation = useMutationV3<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    RejectWorkers
  >(
    async (payload: RejectWorkers) =>
      Promise.allSettled(
        payload.workerIds.map((workerId) =>
          trabaApi.patch(
            `/my-company/shifts/${payload.shiftId}/${workerId}/reject`,
            {
              rejectionReason: payload.rejectionReason,
              shouldNotifyWorker: payload.shouldNotifyWorker,
            },
          ),
        ),
      ),
    {
      onSuccess: (response: PromiseSettledResult<AxiosResponse>[]) => {
        handleActionOutcome(
          {
            data: parseAllSettledWithStatus(response),
          },
          'Rejected',
          'Error rejecting',
        )
      },
      onError: (e: Error) => {
        showError(e.message, 'Error marking workers as rejected')
      },
    },
  )

  const noShowWorkersMutation = useMutationV3<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    BulkWorkerActionBasePayload
  >(
    async (payload: BulkWorkerActionBasePayload) =>
      Promise.allSettled(
        payload.workerIds.map((workerId) =>
          trabaApi.patch(
            `/my-company/shifts/${payload.shiftId}/${workerId}/no-show`,
          ),
        ),
      ),
    {
      onSuccess: (response: PromiseSettledResult<AxiosResponse>[]) => {
        handleActionOutcome(
          {
            data: parseAllSettledWithStatus(response),
          },
          'Marked no-show for',
          'Error marking no-show for',
        )
      },
      onError: (e: Error) => {
        showError(e.message, 'Error marking workers as no-show')
      },
    },
  )

  const bizCancelRemoveWorkersMutation = useMutationV3<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    BulkWorkerActionBasePayload
  >(
    async (payload: BulkWorkerActionBasePayload) =>
      Promise.allSettled(
        payload.workerIds.map((workerId) =>
          trabaApi.put(
            `/my-company/worker-shifts/${payload.shiftId}/cancel/${workerId}`,
          ),
        ),
      ),
    {
      onSuccess: (
        response: PromiseSettledResult<AxiosResponse<WorkerShift>>[],
      ) => {
        const parsedResult = parseAllSettledWithStatus(response)
        handleActionOutcome(
          {
            data: parsedResult,
          },
          'Rejected',
          'Error rejecting',
        )
        // Invalidate shift stats query for this day to force recalculation
        parsedResult.fulfilled.forEach(
          (response: AxiosResponse<WorkerShift>) => {
            queryClient.invalidateQueries([
              'shiftStats',
              response.data.shiftInfo.startTime.getDate(),
            ])
          },
        )
      },
      onError: (e: Error) => {
        showError(e.message, 'Error removing workers')
      },
    },
  )

  return {
    clockInWorkers: clockInWorkersMutation.mutateAsync,
    editClockInWorkers: editClockInWorkersMutation.mutateAsync,
    clockOutWorkers: clockOutWorkersMutation.mutateAsync,
    noShowWorkers: noShowWorkersMutation.mutateAsync,
    rejectWorkers: rejectWorkersMutation.mutateAsync,
    abandonWorkers: abandonWorkersMutation.mutateAsync,
    bizCancelRemoveWorkers: bizCancelRemoveWorkersMutation.mutateAsync,
  }
}

export function useWorkerShifts(shiftId = '', isEnabled = true) {
  // Get Shift By Id
  const { isLoading, isError, data, error, isFetched, refetch } = useQuery<
    WorkerShiftWithWorkerDetails[],
    Error
  >(['workerShifts', shiftId], () => getWorkerShiftByShiftId(shiftId), {
    enabled: isEnabled,
    staleTime: ONE_MINUTE_IN_MS,
  })

  return {
    isLoading,
    isError,
    data,
    error,
    isFetched,
    refetch,
  }
}

const updateBreaks = async ({
  shiftId,
  workerId,
  completedBreak,
}: {
  shiftId: string
  workerId: string
  completedBreak: ShiftTime
}) => {
  const res = await trabaApi.patch(
    `/my-company/worker-shifts/${shiftId}/${workerId}/update-breaks`,
    {
      completedBreak,
    },
  )
  return res.data
}

export function useWorkerShiftBreaks() {
  const { showSuccess, showError } = useAlert()

  const mutation = useMutation<
    WorkerShiftWithWorkerDetails,
    Error,
    {
      shiftId: string
      workerId: string
      completedBreak: ShiftTime
    }
  >({
    mutationFn: ({
      shiftId,
      workerId,
      completedBreak,
    }: {
      shiftId: string
      workerId: string
      completedBreak: ShiftTime
    }) =>
      updateBreaks({
        shiftId,
        workerId,
        completedBreak,
      }),
    onSuccess: () => {
      showSuccess('Successfully updated breaks for the worker.')
    },
    onError: () => {
      showError(
        'There was an error while resetting all locations to the specified kiosk mode type.',
        'Error updating breaks for the worker.',
      )
    },
  })

  return {
    mutateAsync: mutation.mutateAsync,
    isPending: mutation.isPending,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  }
}
