export const reNonDigits = /\D/g

export const formatPhoneNumber = (
  phoneNumber: string,
  userFacing?: boolean,
) => {
  const cleaned = ('' + phoneNumber).replace(reNonDigits, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    if (userFacing) {
      return `+1 (${match[2]}) ${match[3]}-${match[4]}`
    }
    return ['+1', match[2], match[3], match[4]].join('')
  }
  return phoneNumber
}

export const checkArePhoneNumbersMatch = (
  phoneNumberOne?: string,
  phoneNumberTwo?: string,
) => {
  return (
    ('' + phoneNumberOne).replace(reNonDigits, '') ===
    ('' + phoneNumberTwo).replace(reNonDigits, '')
  )
}
