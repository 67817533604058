import { useLocation } from 'react-router-dom'
import { AuthScreenWrapper } from 'src/components/auth'
import { CompanyDetailsForm } from 'src/components/auth/CompanyDetailsForm'
import { WorkersRightCol } from 'src/components/auth/WorkersRightCol'

export default function CompanyDetailsScreen(): JSX.Element {
  const currentLocation = useLocation()

  return (
    <AuthScreenWrapper
      helmetTitle="Company Details"
      leftCol={<CompanyDetailsForm windowLocation={currentLocation} />}
      rightCol={
        <WorkersRightCol
          isFromBasicDetailOrEmailVerification={
            currentLocation.state?.isFromBasicDetailOrEmailVerification
          }
        />
      }
    />
  )
}
