import { MEDIA } from '@traba/theme'
import { useMediaQuery } from 'react-responsive'
import { useAppContext } from '../context/appContext/AppContext'

export enum AppScreen {
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
}

function useMobile() {
  const isMobileView = useMediaQuery({
    maxWidth: MEDIA.LARGE,
  })
  const {
    state: { isReactNativeApp },
  } = useAppContext()

  // We don't return the full list of permutations of these booleans because we
  // only care about the following three cases:
  //   1. Whether the running app is the supervisor app (isReactNativeApp)
  //       - isMobileView will *also* be true in this case since the supervisor
  //         app always runs in a mobile view, but if we're using this variable,
  //         it's because we want to ensure that the code we're about to run is
  //         only running for the supervisor app
  //   2. Whether the running app is mobile OR supervisor (isMobileViewOrReactNative)
  //   3. Whether the running app is mobile but NOT the supervisor app (isExclusivelyMobileView)
  return {
    isReactNativeApp,
    isMobileViewOrReactNative: isMobileView || isReactNativeApp,
    isExclusivelyMobileView: isMobileView && !isReactNativeApp,
  }
}

export default useMobile
