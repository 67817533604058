import { Text } from '@traba/react-components'
import { formatDuration } from 'src/shared/utils/dateUtils'
import { ScheduledBreak } from 'src/types'

interface Props {
  scheduledBreaks: ScheduledBreak[]
}

export const ScheduledBreaksInfo = (props: Props) => {
  const { scheduledBreaks } = props
  if (scheduledBreaks.length === 0) {
    return <Text variant="body1">None</Text>
  }
  const resultingBreaks: string[] = []
  let index = 0
  for (const sb of scheduledBreaks) {
    for (let i = 0; i < sb.count; i++) {
      index = index + i
      resultingBreaks.push(`1 x ${formatDuration(sb.breakLength)}`)
    }
    index++
  }

  return <Text variant="body1">{resultingBreaks.join(', ')}</Text>
}
