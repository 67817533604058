import { SvgIconProps } from 'src/types/svg-types'

export default function Dumbbell({
  size = 12,
  color = '#7A8A99',
  fill = '#7A8A99',
  strokeWidth = 1.5,
  ...rest
}: SvgIconProps) {
  return (
    <svg
      width={size * 2}
      height={size}
      viewBox="0 0 23 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7 6L16 6"
        stroke={color}
        stroke-width={strokeWidth}
        strokeLinecap="square"
      />
      <path
        d="M5 1L5 11"
        stroke={color}
        stroke-width={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M18 1V11"
        stroke={color}
        stroke-width={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M20 2V10"
        stroke={color}
        stroke-width={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M3 2L3 10"
        stroke={color}
        stroke-width={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M3 6H1"
        stroke={color}
        stroke-width={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M22 6.75C22.4142 6.75 22.75 6.41421 22.75 6C22.75 5.58579 22.4142 5.25 22 5.25V6.75ZM21 5.25H20.25V6.75H21V5.25ZM22 5.25H21V6.75H22V5.25Z"
        fill={fill}
      />
    </svg>
  )
}
