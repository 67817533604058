import { SvgIconProps } from '@traba/types'

export default function NoShow({ size, color, ...rest }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
      <path
        d="M8 14H4.30581C3.58463 14 3 13.3893 3 12.6361C3 10.8933 4.21238 10.4088 5.86003 10.1341L6.00844 10.1094C6.89808 9.96109 7.79911 9.91279 8.69617 9.96449M10.9167 4.84375C10.9167 6.41431 9.61085 7.6875 8.00001 7.6875C6.38918 7.6875 5.08335 6.41431 5.08335 4.84375C5.08335 3.27319 6.38918 2 8.00001 2C9.61085 2 10.9167 3.27319 10.9167 4.84375Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 10C11.1193 10 10 11.1193 10 12.5C10 13.8807 11.1193 15 12.5 15C13.8807 15 15 13.8807 15 12.5C15 11.1193 13.8807 10 12.5 10ZM11.7931 11.4393C11.6955 11.3417 11.5372 11.3417 11.4395 11.4393C11.3419 11.537 11.3419 11.6953 11.4395 11.7929L12.1466 12.5L11.4395 13.2071C11.3419 13.3047 11.3419 13.463 11.4395 13.5607C11.5372 13.6583 11.6955 13.6583 11.7931 13.5607L12.5002 12.8536L13.2073 13.5607C13.3049 13.6583 13.4632 13.6583 13.5608 13.5607C13.6585 13.463 13.6585 13.3047 13.5608 13.2071L12.8537 12.5L13.5608 11.7929C13.6585 11.6953 13.6585 11.537 13.5608 11.4393C13.4632 11.3417 13.3049 11.3417 13.2073 11.4393L12.5002 12.1464L11.7931 11.4393Z"
        fill={color}
      />
    </svg>
  )
}
