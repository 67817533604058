import { JobStatus } from './worker-shift'

export type CostCenterType = {
  id: string
  name: string
  status: CostCenterStatus
  locations: CostCenterLocation[]
}

export type CostCenterForShiftType = {
  id: string
  name: string
}

export type PaginatedResponse<T> = {
  members: T[]
  page: number
  pageCount: number
  pageSize: number
}

export type CostCenterResponse = PaginatedResponse<CostCenterType>
export type CostCentersForShiftResponse =
  PaginatedResponse<CostCenterForShiftType>

export type CostCenterLocation = {
  id: string
  name: string
}

export type CreateCostCenter = {
  name: string
  locationIds?: string[]
}

export type UpdateCostCenter = {
  id: string
  name?: string
  status?: CostCenterStatus
  locationIds?: string[]
}

export type CostCenterFormData = {
  name?: string
  status?: CostCenterStatus
  locationIds?: string[]
}

export enum CostCenterStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type WorkerSegment = {
  costCenter?: {
    id: string
    name: string
  }
  startTime: Date
  endTime?: Date
  breakTime?: number
  workedTime?: number
  isBreak: boolean
}

export type WorkersWithSegmentsResponse = PaginatedResponse<WorkerWithSegments>

export type WorkerWithSegments = {
  worker: {
    id: string
    workerShiftId: string
    firstName: string
    lastName: string
    photoUrl?: string
  }
  status: JobStatus
  clockInTime?: Date
  clockOutTime?: Date
  totalBreakTime: number
  workedTime: number
  isPending: boolean
  hasCostCenters: boolean
  segments: WorkerSegment[]
}

export type WorkersSegmentsMap = {
  [workerId: string]: WorkerWithSegments
}

export type UpdateSegmentsForWorkerShiftRequest = {
  clockInTime?: string
  clockOutTime?: string
  adjustmentReason?: string
  segments: {
    costCenterId?: string
    startTime: string
    endTime?: string
    isBreak: boolean
  }[]
}
