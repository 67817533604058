import DatePicker from 'react-multi-date-picker'
import styled from 'styled-components'

export const ThemedMultiDatePicker = styled(DatePicker)`
  /* Arrow styles */
  .rmdp-arrow {
    border: ${({ theme }) => `solid ${theme?.colors.Violet} `};
    border-width: 0 3px 3px 0;
  }

  .rmdp-arrow-container:hover {
    background-color: ${({ theme }) => theme?.colors.Violet};
  }

  /* Weekday styles */
  .rmdp-week-day {
    color: ${({ theme }) => theme?.colors.MidnightBlue};
    font-weight: bold;
    padding: 5px;
    border-radius: 4px;
  }

  /* Optional: Align text or adjust font size */
  .rmdp-week-day {
    text-align: center;
    font-size: 14px;
  }

  .rmdp-day.rmdp-selected {
    background-color: ${({ theme }) => theme?.colors.White};
  }

  .rmdp-day:hover {
    background-color: ${({ theme }) => theme?.colors.White};
  }

  .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: ${({ theme }) => theme?.colors.Violet};
  }

  .rmdp-day.rmdp-today.rmdp-selected span:not(.highlight) {
    color: ${({ theme }) => theme?.colors.White};
    background-color: ${({ theme }) => theme?.colors.Violet};
  }

  .rmdp-day.rmdp-today span {
    background-color: ${({ theme }) => theme?.colors.White};
    color: ${({ theme }) => theme?.colors.MidnightBlue};
    font-weight: bold;
  }
`
