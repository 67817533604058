import MuiModal from '@mui/material/Modal'
import { MODAL_SIZE, ModalContainer } from '@traba/react-components'
import React, { useState } from 'react'

export interface ModalProps {
  children: any
  handleClose: () => void
  isOpen: boolean
  size?: MODAL_SIZE
  style?: { [key: string]: string | number }
  disableCloseOnBackdropClick?: boolean
}

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    children,
    size = MODAL_SIZE.DEFAULT,
    isOpen,
    handleClose,
    disableCloseOnBackdropClick,
  } = props

  return (
    <MuiModal
      open={isOpen}
      onClose={(_event, reason: string) => {
        if (reason === 'backdropClick' && disableCloseOnBackdropClick) {
          return
        }
        handleClose()
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      BackdropProps={{ style: { backgroundColor: '#08105E', opacity: 0.5 } }}
    >
      <ModalContainer size={size} style={props.style}>
        {children}
      </ModalContainer>
    </MuiModal>
  )
}

export interface ModalState {
  isOpen: boolean
  handleClose: () => void
  open: () => void
}

export function useModal(): ModalState {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return {
    isOpen: isModalOpen,
    handleClose: () => setIsModalOpen(false),
    open: () => setIsModalOpen(true),
  }
}
