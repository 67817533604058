import { useState } from 'react'

interface WizardHook {
  currentStepIndex: number
  goToStep: (step: number) => void
  nextStep: () => void
  prevStep: () => void
  hasPrevStep: () => boolean
  hasNextStep: () => boolean
}

interface WizardProps {
  totalSteps: number
  /**
   * The index of the step to start on (starts with 0)
   * @default 0
   */
  startingStepIndex?: number
}

export const useWizard = ({
  totalSteps,
  startingStepIndex = 0,
}: WizardProps): WizardHook => {
  const [currentStepIndex, setCurrentStep] = useState(startingStepIndex)
  const hasPrevStep = () => currentStepIndex > 0
  const hasNextStep = () => currentStepIndex < totalSteps - 1

  const goToStep = (step: number) => {
    if (step >= 0 && step < totalSteps) {
      setCurrentStep(step)
    }
  }

  const nextStep = () => {
    if (hasNextStep()) {
      setCurrentStep(currentStepIndex + 1)
    }
  }

  const prevStep = () => {
    if (hasPrevStep()) {
      setCurrentStep(currentStepIndex - 1)
    }
  }

  return {
    currentStepIndex,
    goToStep,
    nextStep,
    prevStep,
    hasPrevStep,
    hasNextStep,
  }
}
