import { theme } from '@traba/theme'
import {
  COMPANY_WIDE_TEXT,
  DEFAULT_ROLE_LOCATION_ID,
  EligibilityConnection,
  EligibilityConnectionMutationPayload,
  EligibilityConnectionsByType,
  EligibilityConnectionType,
  GlobalEligibilityConnections,
  LocationResponse,
  Role,
} from '@traba/types'
import { getLocationNameOrTruncatedAddress } from '@traba/utils'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Checkbox } from '../base-components/Checkbox'
import Row from '../base-components/Row'
import { SearchSelect } from '../base-components/SearchSelect/SearchSelect'
import { IMenuItem, MenuItemGroup } from '../base-components/Select/Select'
import { SvgIcon } from '../base-components/SvgIcon'
import { Text } from '../base-components/Text'
import { Toggle } from '../base-components/Toggle/Toggle'
import { eligibilityConnectionsToPayload } from './eligibilityConnectionsUtils'

export type IneligibleContentProps = {
  eligibilityConnections: EligibilityConnection[]
  setEligibilityConnections: Dispatch<SetStateAction<EligibilityConnection[]>>
}

export const EligibilityItem = (props: {
  id: string
  displayName: string
  selected: boolean
  onChange: (type: string) => void
}) => {
  return (
    <Row style={{ marginTop: theme.space.xs, width: '50%' }}>
      <Checkbox
        checked={props.selected}
        onChange={() => props.onChange(props.id)}
        label={props.displayName}
      />
    </Row>
  )
}

// this function sets up all the states in the IneligibleSelect component
export const ineligibleSelectSetup = ({
  eligibilityConnections,
  globalEligibilityConnection,
  setGlobalEligibilityForRoles,
  setGlobalEligibilityForLocations,
  roleEntries,
  locationEntries,
  setEligibleRoleItems,
  setEligibleLocationItems,
}: {
  eligibilityConnections: EligibilityConnectionsByType
  globalEligibilityConnection: GlobalEligibilityConnections
  setGlobalEligibilityForRoles: Dispatch<
    SetStateAction<EligibilityConnectionType>
  >
  setGlobalEligibilityForLocations: Dispatch<
    SetStateAction<EligibilityConnectionType>
  >
  roleEntries: (readonly [string, string])[]
  locationEntries: (readonly [string, string])[]
  setEligibleRoleItems: Dispatch<SetStateAction<IMenuItem[]>>
  setEligibleLocationItems: Dispatch<SetStateAction<IMenuItem[]>>
}) => {
  const globalEligibilityForRoles =
    globalEligibilityConnection.globalEligibilityForRoles ??
    EligibilityConnectionType.ELIGIBLE

  const globalEligibilityForLocations =
    globalEligibilityConnection.globalEligibilityForLocations ??
    EligibilityConnectionType.ELIGIBLE

  setGlobalEligibilityForRoles(globalEligibilityForRoles)
  setGlobalEligibilityForLocations(globalEligibilityForLocations)

  // determine which roles are ineligible
  let ineligibleRoles = new Set<string>()
  if (globalEligibilityForRoles === EligibilityConnectionType.INELIGIBLE) {
    ineligibleRoles = new Set(roleEntries.map((id) => id[0]))
    eligibilityConnections.roles.forEach((c) => {
      if (
        c.roleId &&
        c.eligibilityConnectionType === EligibilityConnectionType.ELIGIBLE
      ) {
        ineligibleRoles.delete(c.roleId)
      }
    })
  } else {
    ineligibleRoles = new Set(
      eligibilityConnections.roles
        .filter(
          (c) =>
            c.eligibilityConnectionType ===
            EligibilityConnectionType.INELIGIBLE,
        )
        .map((c) => c.roleId || ''),
    )
  }
  setEligibleRoleItems(
    roleEntries
      .filter(([key, _]) => !ineligibleRoles.has(key))
      .map(([key, value]) => {
        return { label: value, value: key }
      }),
  )

  // determine which locations are ineligible
  let ineligibleLocations = new Set<string>()
  if (globalEligibilityForLocations === EligibilityConnectionType.INELIGIBLE) {
    ineligibleLocations = new Set(locationEntries.map((id) => id[0]))
    eligibilityConnections.locations.forEach((c) => {
      if (
        c.locationId &&
        c.eligibilityConnectionType === EligibilityConnectionType.ELIGIBLE
      ) {
        ineligibleLocations.delete(c.locationId)
      }
    })
  } else {
    ineligibleLocations = new Set(
      eligibilityConnections.locations
        .filter(
          (c) =>
            c.eligibilityConnectionType ===
            EligibilityConnectionType.INELIGIBLE,
        )
        .map((c) => c.locationId || ''),
    )
  }

  setEligibleLocationItems(
    locationEntries
      .filter(([key, _]) => !ineligibleLocations.has(key))
      .map(([key, value]) => {
        return { label: value, value: key }
      }),
  )
}

export const IneligibleSelect = ({
  eligibilityConnections,
  globalEligibilityConnection,
  roles,
  locations,
  workerId,
  companyId,
  setPayload,
  initiatedBy,
}: {
  eligibilityConnections: EligibilityConnectionsByType
  globalEligibilityConnection: GlobalEligibilityConnections
  roles: Pick<Role, 'roleId' | 'roleName' | 'location'>[]
  locations: LocationResponse[]
  workerId: string
  companyId: string
  setPayload: Dispatch<
    SetStateAction<EligibilityConnectionMutationPayload | undefined>
  >
  initiatedBy: string
}) => {
  const [globalEligibilityForRoles, setGlobalEligibilityForRoles] = useState(
    EligibilityConnectionType.ELIGIBLE,
  )

  const [globalEligibilityForLocations, setGlobalEligibilityForLocations] =
    useState(EligibilityConnectionType.ELIGIBLE)

  const roleEntries = roles.map((r) => [r.roleId, r.roleName] as const)

  const locationEntries = locations.map(
    (l) => [l.locationId, getLocationNameOrTruncatedAddress(l)] as const,
  )

  const locationIdToGroupMap = new Map(
    locations.map((l) => [l.locationId, l.regionId]),
  )

  const roleIdToGroupMap = new Map(
    roles.map((r) => [r.roleId, r.location?.locationId]),
  )

  const [eligibleLocationItems, setEligibleLocationItems] = useState<
    IMenuItem[]
  >([])
  const [eligibleRoleItems, setEligibleRoleItems] = useState<IMenuItem[]>([])

  useEffect(() => {
    ineligibleSelectSetup({
      eligibilityConnections,
      globalEligibilityConnection,
      setGlobalEligibilityForRoles,
      setGlobalEligibilityForLocations,
      roleEntries,
      locationEntries,
      setEligibleRoleItems,
      setEligibleLocationItems,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, workerId, eligibilityConnections, globalEligibilityConnection])

  useEffect(() => {
    setPayload(
      eligibilityConnectionsToPayload({
        workerId,
        companyId,
        globalEligibilityForRoles,
        globalEligibilityForLocations,
        eligibleRoleItems,
        eligibleLocationItems,
        roleEntries,
        locationEntries,
        initiatedBy,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    globalEligibilityForRoles,
    globalEligibilityForLocations,
    eligibleRoleItems,
    eligibleLocationItems,
  ])

  const { groups, groupedLocationOptions, groupedRoleOptions } = useMemo(() => {
    const uniqueRegions = [
      { value: DEFAULT_ROLE_LOCATION_ID, label: COMPANY_WIDE_TEXT },
      ...Array.from(new Set(locations.map((r) => r.regionId))).map((r) => ({
        value: r,
        label: r,
      })),
      ...locationEntries.map(([locationId, locationName]) => ({
        value: locationId,
        label: locationName,
      })),
    ]
    const groups: MenuItemGroup[] = [
      ...uniqueRegions.flatMap((r) => ({
        id: r.value,
        title: r.label,
      })),
    ]

    const groupedLocationOptions = locationEntries.map(
      ([locationId, locationName]) => {
        const regionId =
          locationIdToGroupMap.get(locationId) || DEFAULT_ROLE_LOCATION_ID
        return {
          label: locationName,
          value: locationId,
          groupId: regionId,
        }
      },
    )

    const groupedRoleOptions = roleEntries.map(([roleId, roleName]) => {
      const regionId = roleIdToGroupMap.get(roleId) || DEFAULT_ROLE_LOCATION_ID
      return {
        label: roleName,
        value: roleId,
        groupId: regionId,
      }
    })
    return { groups, groupedLocationOptions, groupedRoleOptions }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onToggleGlobalEligibilityForLocations = useCallback(() => {
    setGlobalEligibilityForLocations((oldValue) =>
      oldValue === EligibilityConnectionType.ELIGIBLE
        ? EligibilityConnectionType.INELIGIBLE
        : EligibilityConnectionType.ELIGIBLE,
    )
  }, [setGlobalEligibilityForLocations])

  const onToggleGlobalEligibilityForRoles = useCallback(() => {
    setGlobalEligibilityForRoles((oldValue) =>
      oldValue === EligibilityConnectionType.ELIGIBLE
        ? EligibilityConnectionType.INELIGIBLE
        : EligibilityConnectionType.ELIGIBLE,
    )
  }, [setGlobalEligibilityForRoles])

  return (
    <>
      <Row alignCenter justifyStart mb={theme.space.xs} mr={theme.space.xxs}>
        <SvgIcon
          name="briefcase"
          color={theme.colors.brand}
          size={theme.space.sm}
        />
        <Text variant="h4">Roles</Text>
      </Row>
      <Row alignCenter justifyBetween mb={theme.space.xxl} mr={theme.space.xxl}>
        <SearchSelect
          options={groupedRoleOptions}
          groups={groups}
          multiple={true}
          groupByGroup={true}
          isGroupsSelectable={true}
          selectedItems={eligibleRoleItems}
          handleSelectMultiple={setEligibleRoleItems}
          shouldAlsoSearchSecondaryLabel={true}
          style={{ width: '50%', marginRight: theme.space.xxl }}
        />

        <Row
          style={{
            position: 'sticky',
            bottom: theme.space.sm,
            right: theme.space.sm,
            justifyContent: 'flex-end',
            zIndex: 1,
          }}
        >
          <Toggle
            style={{ position: 'sticky' }}
            label="Eligible for future roles"
            buttonState={
              globalEligibilityForRoles === EligibilityConnectionType.ELIGIBLE
            }
            runOnChange={onToggleGlobalEligibilityForRoles}
          />
        </Row>
      </Row>
      <Row alignCenter justifyStart mb={theme.space.xs} mr={theme.space.xxs}>
        <SvgIcon
          name="location"
          color={theme.colors.brand}
          size={theme.space.sm}
        />
        <Text variant="h4">Locations</Text>
      </Row>
      <Row alignCenter justifyBetween>
        <SearchSelect
          options={groupedLocationOptions}
          groups={groups}
          multiple={true}
          groupByGroup={true}
          isGroupsSelectable={true}
          selectedItems={eligibleLocationItems}
          handleSelectMultiple={setEligibleLocationItems}
          shouldAlsoSearchSecondaryLabel={true}
          style={{ width: '50%', marginRight: theme.space.xxl }}
        />

        <Row
          style={{
            position: 'sticky',
            bottom: theme.space.sm,
            right: theme.space.sm,
            justifyContent: 'flex-end',
            zIndex: 1,
          }}
        >
          <Toggle
            style={{ position: 'sticky' }}
            label="Eligible for future locations"
            buttonState={
              globalEligibilityForLocations ===
              EligibilityConnectionType.ELIGIBLE
            }
            runOnChange={onToggleGlobalEligibilityForLocations}
          />
        </Row>
      </Row>
    </>
  )
}
