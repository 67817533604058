import { Avatar, Step, StepLabel, Stepper } from '@mui/material'
import React from 'react'
import { theme } from 'src/libs/theme'

import { Row, SvgIcon, Text } from '../../base'
import { WizardStep } from '../WizardDialog'
import { WizardStepConnector } from './WizardConnector'

export const WizardHeader: React.FC<{
  steps: WizardStep[]
  currentStepIndex: number
  goToStep: (stepIndex: number) => void
}> = ({ steps, currentStepIndex, goToStep }) => {
  return (
    <Row fullWidth px={theme.space.lg} py={theme.space.lg}>
      <Stepper
        activeStep={currentStepIndex}
        alternativeLabel
        sx={{ width: '100%' }}
        connector={<WizardStepConnector />}
      >
        {steps.map((step, i) => (
          <Step key={step.id}>
            <StepLabel
              onClick={() => goToStep(i)}
              style={{
                cursor: 'pointer',
              }}
              StepIconComponent={
                step.renderStepAs || (() => <StepAvatar step={step} />)
              }
            >
              <Row center>
                <Text
                  variant="h7"
                  color={
                    i === currentStepIndex ? undefined : theme.colors.Grey30
                  }
                >
                  {step.stepTitle}
                </Text>
              </Row>
              <Row center>
                <Text
                  variant="h8"
                  color={
                    i === currentStepIndex ? undefined : theme.colors.Grey30
                  }
                >
                  {step.stepSubtitle}
                </Text>
              </Row>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Row>
  )
}

const StepAvatar = ({ step }: { step: WizardStep }) => (
  <Avatar
    sx={{
      bgcolor: theme.colors.Violet10,
      height: theme.space.med,
      width: theme.space.med,
    }}
    src={step.isComplete ? undefined : step.stepPicture}
  >
    {step.isComplete && (
      <SvgIcon name="check" color={theme.colors.Grey50} size={12} />
    )}
  </Avatar>
)
