import { SvgIconProps } from 'src/types/svg-types'

export default function Pencil({
  color = 'currentColor',
  size = 16,
  strokeWidth = 1.5,
  ...props
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M11.4997 7.32805C10.0856 7.79939 8.20027 5.91401 8.67161 4.49998M9.25256 3.91902L6.20615 6.96544C4.88174 8.28984 3.94218 9.94929 3.48792 11.7664L3.34072 12.3552C3.29485 12.5386 3.46103 12.7048 3.6445 12.6589L4.2333 12.5117C6.05037 12.0575 7.70981 11.1179 9.03421 9.79351L12.0806 6.74709C12.4557 6.37207 12.6663 5.86342 12.6663 5.33306C12.6663 4.22863 11.771 3.33331 10.6666 3.33331C10.1362 3.33331 9.62759 3.544 9.25256 3.91902Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
