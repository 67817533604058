import { Card } from '@traba/react-components'
import { ShiftRequestParentWithShiftRequest } from '@traba/types'
import { Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Col, Row, Text } from 'src/components'
import { RadioButton } from 'src/components/RadioButton'
import { theme } from 'src/libs/theme'
import { BookShiftsProps } from 'src/screens/BookShifts/BookShiftsScreen'

export enum AddToExistingScheduleOptions {
  ADD_SINGLE_DAY_TO_SCHEDULE = 'ADD_SINGLE_DAY_TO_SCHEDULE',
  ADD_ROLE_OR_WORKERS_TO_SCHEDULE = 'ADD_ROLE_OR_WORKERS_TO_SCHEDULE',
  ADD_RECURRING_DAY_TO_SCHEDULE = 'ADD_RECURRING_DAY_TO_SCHEDULE',
}

interface AddToExistingScheduleProps extends BookShiftsProps {
  selectedOption: AddToExistingScheduleOptions
  setSelectedOption: Dispatch<SetStateAction<AddToExistingScheduleOptions>>
  shiftRequestParent: ShiftRequestParentWithShiftRequest
}

export default function SelectAddToExistingScheduleOptions(
  props: AddToExistingScheduleProps,
) {
  const { shiftRequestParent, selectedOption, setSelectedOption, onContinue } =
    props

  const navigate = useNavigate()

  const goToEditSchedule = () =>
    navigate(`/edit-schedule/${shiftRequestParent.shiftRequestParentId}`)

  return (
    <Col gap={theme.space.xs}>
      <Card
        isClickable
        onClick={() =>
          setSelectedOption(
            AddToExistingScheduleOptions.ADD_SINGLE_DAY_TO_SCHEDULE,
          )
        }
      >
        <Row alignCenter fullWidth gap={theme.space.xs}>
          <RadioButton
            selected={
              selectedOption ===
              AddToExistingScheduleOptions.ADD_SINGLE_DAY_TO_SCHEDULE
            }
          />
          <Row style={{ marginLeft: theme.space.xs }}>
            <Col>
              <Text color={theme.colors.MidnightBlue} variant="h5">
                Add a single day to the schedule (one-off shift)
              </Text>
              <Text variant="body2">
                Select if you need to add a one-off shift to this schedule.
              </Text>
              <Text variant="caption1">
                e.g. Add a single Thursday shift to the schedule
              </Text>
            </Col>
          </Row>
        </Row>
      </Card>

      <Card
        isClickable
        onClick={() =>
          setSelectedOption(
            AddToExistingScheduleOptions.ADD_ROLE_OR_WORKERS_TO_SCHEDULE,
          )
        }
      >
        <Row alignCenter fullWidth gap={theme.space.xs}>
          <RadioButton
            selected={
              selectedOption ===
              AddToExistingScheduleOptions.ADD_ROLE_OR_WORKERS_TO_SCHEDULE
            }
          />
          <Row style={{ marginLeft: theme.space.xs }}>
            <Col>
              <Text color={theme.colors.MidnightBlue} variant="h5">
                Add another role or workers to the whole schedule
              </Text>
              <Text variant="body2">
                Select if you need another role and more workers for that role
                on the schedule. You can select which days of the schedule you
                want this change to apply to.
              </Text>
              <Text variant="caption1">
                e.g. Add 2 pickers to all days in the schedule
              </Text>
            </Col>
          </Row>
        </Row>
      </Card>

      <Text variant="body2" width="100%">
        If you just need to extend the existing schedule, you can so by going to
        <Text
          ml={theme.space.xxs}
          variant="linkUnderline"
          color={theme.colors.brand}
          style={{ fontWeight: 600 }}
          onClick={goToEditSchedule}
        >
          Edit Schedule
        </Text>
      </Text>
      <Row style={{ justifyContent: 'flex-end', marginTop: theme.space.lg }}>
        <Button
          onClick={
            selectedOption ===
            AddToExistingScheduleOptions.ADD_ROLE_OR_WORKERS_TO_SCHEDULE
              ? goToEditSchedule
              : onContinue
          }
        >
          Continue
        </Button>
      </Row>
    </Col>
  )
}
