import {
  ChatArchivalStatus,
  ChatMessageContent,
  ChatSummary,
} from '@traba/types'
import { useEffect, useRef, useState } from 'react'
import { Row } from 'src/components'
import { useAppContext } from 'src/context/appContext/AppContext'
import { postChats, useChats } from 'src/hooks/useChats'
import { clearSuggestionCache } from 'src/hooks/useChatSuggestion'

import useMobile from 'src/hooks/useMobile'
import { MessageHeader } from './MessageHeader'
import MessageListItem from './MessageListItem'
import { MessageEmptyLoading } from './MessagesEmptyLoading'
import { MessagesPanel } from './MessagesPanel'
import { NewMessageSection } from './NewMessageSection'

export const MessagesTab = () => {
  const { isMobileViewOrReactNative } = useMobile()
  const appContext = useAppContext()
  const { setIsChatWidgetOpen } = appContext
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [selectedChat, setSelectedChat] = useState<ChatSummary | undefined>(
    undefined,
  )
  const [startDate, setStartDate] = useState<Date | undefined>()
  const [selectedSummaries, setSelectedSummaries] = useState<string[]>([])
  const [viewArchivedStatus, setViewArchivedStatus] = useState<
    ChatArchivalStatus | undefined
  >()
  const [isAddingNewMessage, setIsAddingNewMessage] = useState(false)
  const [shouldGoToFirstItem, setShouldGoToFirstItem] = useState(false)
  const isPanelOpen = selectedChat !== undefined
  const {
    isLoading: isLoadingChats,
    chats,
    refetch,
  } = useChats(viewArchivedStatus, startDate)

  const handleClickNewMessage = () => {
    // no need to await, just let it happen in background
    clearSuggestionCache()
    setSelectedChat(undefined)
    setIsAddingNewMessage(true)
  }

  const handleSummarySelection = (summaryId: string) => {
    const isSelected = selectedSummaries.includes(summaryId)
    setSelectedSummaries((prevSelectedSummaries) => {
      if (isSelected) {
        return prevSelectedSummaries.filter((s) => s !== summaryId)
      } else {
        // If not selected, add it to the array
        return [...prevSelectedSummaries, summaryId]
      }
    })
  }

  useEffect(() => {
    if (shouldGoToFirstItem) {
      setSelectedChat(chats?.[0])
      setShouldGoToFirstItem(false)
    }
  }, [shouldGoToFirstItem])

  return (
    <div
      style={{
        overflow: 'hidden',
      }}
    >
      {isAddingNewMessage ? (
        <NewMessageSection
          onSubmit={async (
            content: ChatMessageContent,
            workerIds: string[],
          ) => {
            setSelectedChat(undefined)
            await postChats(content, workerIds)
            await refetch()
            setIsAddingNewMessage(false)
            if (!isMobileViewOrReactNative) {
              setShouldGoToFirstItem(true)
            }
          }}
          onClickBack={() => {
            setIsAddingNewMessage(false)
          }}
        />
      ) : (
        <>
          {!isPanelOpen && (
            <MessageHeader
              isPanelOpen={isPanelOpen}
              onClickNewMessage={handleClickNewMessage}
              hasMessages={!!chats && chats.length > 0}
              onToggleArchived={() => {
                if (viewArchivedStatus === ChatArchivalStatus.ARCHIVED) {
                  setViewArchivedStatus(undefined)
                } else {
                  setViewArchivedStatus(ChatArchivalStatus.ARCHIVED)
                }
              }}
              viewArchived={viewArchivedStatus === ChatArchivalStatus.ARCHIVED}
              selectedSummaries={selectedSummaries}
              setSelectedSummaries={setSelectedSummaries}
              setStartDate={setStartDate}
              refetchChats={refetch}
            />
          )}

          {isLoadingChats || !chats || chats.length === 0 ? (
            <MessageEmptyLoading
              isLoading={isLoadingChats}
              viewArchived={viewArchivedStatus === ChatArchivalStatus.ARCHIVED}
            />
          ) : (
            <Row>
              <div
                style={{
                  overflow: 'auto',
                  height: 800,
                  width: !isPanelOpen ? '100% ' : '40%',
                }}
              >
                {isPanelOpen && (
                  <MessageHeader
                    onClickNewMessage={handleClickNewMessage}
                    isPanelOpen={isPanelOpen}
                    hasMessages={!!chats}
                    setStartDate={setStartDate}
                    refetchChats={refetch}
                  />
                )}
                {chats.map((chat: ChatSummary, index: number) => (
                  <MessageListItem
                    chat={chat}
                    key={index}
                    selected={selectedChat?.summaryId === chat.summaryId}
                    isPanelOpen={isPanelOpen}
                    isMobileView={isMobileViewOrReactNative}
                    selectedSummaries={selectedSummaries}
                    handleSummarySelection={handleSummarySelection}
                    onClick={() => {
                      if (isMobileViewOrReactNative) {
                        // If it's mobile view, we use widget because page is too crowded
                        setIsChatWidgetOpen(true, chat.recipient.workerId)
                      } else {
                        if (selectedChat?.summaryId !== chat.summaryId) {
                          setSelectedChat(chat)
                          refetch()
                        } else {
                          setSelectedChat(undefined)
                        }
                      }
                    }}
                  />
                ))}
              </div>
              {isPanelOpen && (
                <MessagesPanel
                  isMobileView={isMobileViewOrReactNative}
                  selectedChat={selectedChat}
                  setSelectedChat={setSelectedChat}
                  refetchChats={refetch}
                  buttonRef={buttonRef}
                />
              )}
            </Row>
          )}
        </>
      )}
    </div>
  )
}
