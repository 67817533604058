import { ARCHIVED_REGION_ID } from '@traba/consts'
import { useLocationRegions, useLocations } from '@traba/hooks'
import { theme } from '@traba/theme'

import { LocationResponse, RecordStatus, Region } from '@traba/types'
import { getAddressString } from '@traba/utils'
import { useMemo, useState } from 'react'
import Input from './base-components/Input/Input'
import { LoadingSpinner } from './base-components/LoadingSpinner'
import Row from './base-components/Row'
import { Text } from './base-components/Text'
import RegionAndLocationCheckboxGroups from './RegionAndLocationCheckboxGroups'

interface Props {
  companyId?: string
  selectedLocations: Set<string>
  onUpdateSelectedLocations: (newSelectedLocations: Set<string>) => void
  allowedLocationIds?: Set<string>
}

export const RegionAndLocationSelectWithSearch = ({
  companyId,
  selectedLocations,
  onUpdateSelectedLocations,
  allowedLocationIds,
}: Props) => {
  const { uniqueLocations, isLoading } = useLocations(companyId)
  const [searchText, setSearchText] = useState('')
  const { regions = [], isLoading: isLoadingLocationRegions } =
    useLocationRegions(companyId)
  const [expandedRegions, setExpandedRegions] = useState<
    Record<string, boolean>
  >({})
  const [validLocations, regionLocationMap] = useMemo(() => {
    // Don't show archived locations
    const validLocations = uniqueLocations.filter(
      (location) =>
        location.recordStatus !== RecordStatus.Archived &&
        (allowedLocationIds
          ? allowedLocationIds.has(location.locationId)
          : // If no allowedLocationIds are provided, show all
            true),
    )
    return [
      validLocations,
      validLocations.reduce<Record<string, LocationResponse[]>>(
        (acc, location) => {
          const key = location.regionId
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key].push(location)
          return acc
        },
        {},
      ),
    ]
  }, [uniqueLocations])
  // RegionId to locations that match searchText
  const regionIdToFilteredLocations = useMemo(() => {
    return validLocations.reduce<Record<string, LocationResponse[]>>(
      (acc, location) => {
        const lowerCaseSearchText = searchText.toLowerCase()
        if (
          (location.name ?? 'N/A')
            .toLowerCase()
            .includes(lowerCaseSearchText) ||
          getAddressString(location.address)
            .toLowerCase()
            .includes(lowerCaseSearchText)
        ) {
          const key = location.regionId
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key].push(location)
        }
        return acc
      },
      {},
    )
  }, [searchText, validLocations])
  const regionMap = useMemo(() => {
    // Don't show outside region
    const validRegions = regions.filter(
      (region) => region.regionId !== ARCHIVED_REGION_ID,
    )
    return validRegions.reduce<Record<string, Region>>((acc, region) => {
      acc[region.regionId] = region
      return acc
    }, {})
  }, [regions])

  const allLocations = useMemo(() => {
    return new Set(validLocations.map((location) => location.locationId))
  }, [validLocations])

  const allExpandedRegions = useMemo(() => {
    return Object.keys(regionLocationMap).reduce<Record<string, boolean>>(
      (acc, regionId) => {
        acc[regionId] = true
        return acc
      },
      {},
    )
  }, [regionLocationMap])

  // Location ids that will be selected when "select all" is clicked
  const filteredLocationIds = useMemo(() => {
    if (searchText === '') {
      return Array.from(allLocations)
    }
    return Object.values(regionIdToFilteredLocations).reduce<string[]>(
      (acc, locations) => {
        locations.forEach((location) => acc.push(location.locationId))
        return acc
      },
      [],
    )
  }, [regionIdToFilteredLocations, allLocations, searchText])

  const selectAllFilteredLocations = () => {
    // If user is not searching, select or unselect all entire locations
    if (searchText === '') {
      if (selectedLocations.size === allLocations.size) {
        onUpdateSelectedLocations(new Set())
        setExpandedRegions({})
        return
      }
      onUpdateSelectedLocations(allLocations)
      setExpandedRegions(allExpandedRegions)
      return
    }

    // Check if all of the searched locations are already selected, if it is, unselect them
    const newSelectedLocations = new Set(selectedLocations)
    if (
      filteredLocationIds.every((locationId) =>
        selectedLocations.has(locationId),
      )
    ) {
      for (const locationId of filteredLocationIds) {
        newSelectedLocations.delete(locationId)
      }
    } else {
      for (const locationId of filteredLocationIds) {
        newSelectedLocations.add(locationId)
      }
    }

    onUpdateSelectedLocations(newSelectedLocations)
  }

  if (isLoading || isLoadingLocationRegions) {
    return <LoadingSpinner />
  }
  return (
    <>
      <Input
        type="text"
        containerStyle={{
          marginTop: theme.space.xs,
          width: '20%',
        }}
        onChange={(e) => {
          setSearchText(e.target.value)

          if (e.target.value === '') {
            // When the search is not happening, recalculate the expanded regions
            setExpandedRegions(
              Object.keys(regionLocationMap).reduce<Record<string, boolean>>(
                (acc, regionId) => {
                  const locations = regionLocationMap[regionId]
                  for (const location of locations) {
                    if (selectedLocations.has(location.locationId)) {
                      acc[regionId] = true
                      break
                    }
                  }
                  return acc
                },
                {},
              ),
            )
          } else {
            // When a search is ongoing, display all locations that match the search criteria
            setExpandedRegions(allExpandedRegions)
          }
        }}
        placeholder="Search for location"
        leftIconName="search"
      />

      <Row alignCenter my={theme.space.xxs} mx={theme.space.xxs}>
        <Text variant="h6">{`${selectedLocations.size} selected`}</Text>
        <Text
          variant="link"
          color={theme.colors.brand}
          onClick={selectAllFilteredLocations}
          ml={theme.space.xs}
        >
          {selectedLocations.size === allLocations.size
            ? 'Unselect all'
            : 'Select all'}
        </Text>
      </Row>
      <RegionAndLocationCheckboxGroups
        selectedLocations={selectedLocations}
        onUpdateSelectedLocations={onUpdateSelectedLocations}
        regionMap={regionMap}
        expandedRegions={expandedRegions}
        setExpandedRegions={setExpandedRegions}
        searchText={searchText}
        regionIdToFilteredLocations={regionIdToFilteredLocations}
      />
    </>
  )
}
