import { CardTile } from '@traba/react-components'

import * as S from './CompanyLogo.styles'

export interface CompanyLogoProps {
  companyName?: string
  companyLogoUrl?: string
  style?: { [key: string]: string | number }
}

export const CompanyLogo = ({
  companyName,
  companyLogoUrl,
  ...props
}: CompanyLogoProps) => {
  return companyLogoUrl ? (
    <S.LogoImageWrapper {...props}>
      <S.LogoImage src={companyLogoUrl} alt="Company Logo Image" />
    </S.LogoImageWrapper>
  ) : (
    <S.LogoIconWrapper {...props}>
      <CardTile size="100%">
        {companyName && (
          <S.CompanyFirstLetter
            fontSize={
              typeof props.style?.width === 'number'
                ? `${props.style.width / 2}px`
                : '32px'
            }
          >
            {companyName[0]}
          </S.CompanyFirstLetter>
        )}
      </CardTile>
    </S.LogoIconWrapper>
  )
}
