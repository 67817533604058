import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { theme } from '@traba/theme'
import { WorkerOnSchedule, WorkerOnScheduleShiftInfo } from '@traba/types'
import { anyToDate } from '@traba/utils'
import * as React from 'react'
import Avatar from '../base-components/Avatar/Avatar'
import Row from '../base-components/Row'
import { Text } from '../base-components/Text'
import { LinkText } from '../base-components/WorkerLinkText'
import { WorkerOnScheduleTableCell } from './WorkerOnScheduleTableCell'
import { WorkersOnScheduleTableCard } from './WorkersOnScheduleTableCard'

interface Props {
  workers?: WorkerOnSchedule[]
  onClickWorker: (workerId: string) => void
  getStylesForShiftRequest: (shiftRequestId: string) => {
    backgroundColor: string
    borderColor: string
  }
}

const formatWorkerShifts = (workerShifts: WorkerOnScheduleShiftInfo[]) => {
  const formatWorkerShifts: (WorkerOnScheduleShiftInfo | null)[] =
    Array(7).fill(null)
  workerShifts.forEach((ws) => {
    const day = anyToDate(ws.date).getDay()
    formatWorkerShifts[day] = ws
  })
  return formatWorkerShifts
}

export const WorkersOnScheduleTable = ({
  workers,
  onClickWorker,
  getStylesForShiftRequest,
}: Props) => {
  if (!workers || workers.length === 0) {
    return <Text variant="h6">No workers in this period</Text>
  }
  const REPEAT_ON = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <WorkerOnScheduleTableCell
              title="WORKERS"
              backgroundColor={theme.colors.Grey20}
            />

            {REPEAT_ON.map((day) => (
              <WorkerOnScheduleTableCell
                key={day}
                title={day}
                sx={{ border: `1px solid ${theme.colors.Grey30}` }}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {workers?.map((worker) => (
            <TableRow key={worker.workerId}>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  border: `1px solid ${theme.colors.Grey30}`,
                  width: `${100 / 8}%`,
                }}
              >
                <Row alignCenter>
                  <Avatar
                    src={worker.photoUrl}
                    name={`${worker.firstName} ${worker.lastName}` ?? 'worker'}
                    size={40}
                  />{' '}
                  <LinkText
                    variant="h6"
                    style={{
                      marginLeft: theme.space.xs,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                    onClick={() => onClickWorker(worker.workerId)}
                  >
                    {`${worker.firstName} ${worker.lastName}`}
                  </LinkText>
                </Row>
              </TableCell>
              {formatWorkerShifts(worker.workerShifts).map((ws) => (
                <TableCell
                  key={ws?.date ?? Math.random()}
                  align="left"
                  sx={{
                    border: `1px solid ${theme.colors.Grey30}`,
                    padding: 0,
                    width: `${100 / 8}%`,
                  }}
                >
                  <WorkersOnScheduleTableCard
                    ws={ws}
                    getStylesForShiftRequest={getStylesForShiftRequest}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
