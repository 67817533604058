import { SvgIconProps } from '@traba/types'

export default function Lightning({ size = 16, ...props }: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 2L5 8H11L7 14"
        stroke="#9933FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
