import { useAlert } from '@traba/context'
import { Col, LoadingSpinner } from '@traba/react-components'
import { theme } from '@traba/theme'
import { DailyViewShiftMetadata } from '@traba/types'
import React from 'react'
import ShareShiftDetails from 'src/components/UpcomingShiftDetails/components/ShareShiftDetails'
import { useUpcomingShiftDetails } from 'src/components/UpcomingShiftDetails/UpcomingShiftDetails.hooks'
import { useShiftById } from 'src/hooks/useShifts'
import { ScheduleDailyViewShiftTitle } from './ScheduleDailyViewShiftTitle'

interface Props {
  shiftId: string
  individualMetadata: DailyViewShiftMetadata
}

export const ScheduleDailyViewShareShiftDetailsContent = (props: Props) => {
  const { shiftId, individualMetadata } = props
  const { shift, isLoading } = useShiftById(shiftId)

  const { showSuccess, handleError } = useAlert()
  const onShiftUpdateSuccess = () => {
    showSuccess('Shift updated successfully!')
  }
  const onShiftUpdateError = (error: any) => {
    handleError(
      error,
      'useUpcomingShiftDetails',
      'Failed to update the shift. Please try again or contact support.',
      'Error updating shift',
    )
  }
  const { setShiftToUpdate, shiftToUpdate, editing, setEditing, updateShift } =
    useUpcomingShiftDetails(shiftId, onShiftUpdateSuccess, onShiftUpdateError)

  if (isLoading) {
    return <LoadingSpinner />
  }
  if (!shift) {
    return null
  }

  return (
    <Col mt={theme.space.sm}>
      <ScheduleDailyViewShiftTitle individualMetadata={individualMetadata} />
      <ShareShiftDetails
        shift={shift}
        editing={editing}
        setEditing={setEditing}
        setShiftToUpdate={setShiftToUpdate}
        shiftToUpdate={shiftToUpdate}
        updateShift={updateShift}
        hideTitle
      />
    </Col>
  )
}
