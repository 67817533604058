import { SvgIconProps } from '@traba/types'

export default function ClockOut({ size, color, ...rest }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
      <path
        d="M10.7678 5.59638L13.5962 2.76795M13.5962 2.76795L10.0588 2.76984M13.5962 2.76795L13.5943 6.30538"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7647 9.03304C12.3304 10.8847 10.8847 12.3304 9.03304 12.7648C7.69583 13.0784 6.30417 13.0784 4.96696 12.7647C3.11534 12.3304 1.66958 10.8847 1.23525 9.03305C0.921583 7.69583 0.921583 6.30417 1.23525 4.96695C1.66958 3.11534 3.11534 1.66958 4.96696 1.23525C6.30417 0.921583 7.69583 0.921583 9.03305 1.23525"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M8.89239 8.66667L7 7.4626V5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
