import { SvgIconProps } from 'src/types/svg-types'

export default function Dollar({
  size = 16,
  color = '#7A8A99',
  ...rest
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1.92294 5.89916C2.37138 4.09322 3.80336 2.69689 5.61972 2.29441L5.9223 2.22737C7.29042 1.92421 8.70828 1.92421 10.0764 2.22737L10.379 2.29441C12.1953 2.69689 13.6273 4.09323 14.0758 5.89917C14.4183 7.27877 14.4183 8.72123 14.0758 10.1008C13.6273 11.9068 12.1953 13.3031 10.379 13.7056L10.0764 13.7726C8.70828 14.0758 7.29041 14.0758 5.9223 13.7726L5.61972 13.7056C3.80336 13.3031 2.37138 11.9068 1.92294 10.1008C1.58037 8.72124 1.58037 7.27876 1.92294 5.89916Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 4.6665C8.25506 4.6665 8.4617 4.86116 8.4617 5.10129V5.45699H8.51282C9.37115 5.45699 10 6.15994 10 6.94579C10 7.18592 9.79336 7.38058 9.53846 7.38058C9.28356 7.38058 9.07692 7.18592 9.07692 6.94579C9.07692 6.56741 8.78738 6.32656 8.51282 6.32656H8.4617V7.69533L9.08486 7.9088C9.65113 8.10278 10 8.63034 10 9.18905C10 9.90023 9.43006 10.5426 8.6444 10.5426H8.4617V10.8984C8.4617 11.1385 8.25506 11.3332 8.00016 11.3332C7.74526 11.3332 7.53863 11.1385 7.53863 10.8984V10.5426H7.48718C6.62885 10.5426 6 9.83969 6 9.05383C6 8.81371 6.20664 8.61905 6.46154 8.61905C6.71644 8.61905 6.92308 8.81371 6.92308 9.05383C6.92308 9.43221 7.21262 9.67307 7.48718 9.67307H7.53863V8.3044L6.91514 8.09082C6.34887 7.89684 6 7.36929 6 6.81057C6 6.0994 6.56994 5.45699 7.3556 5.45699H7.53863V5.10129C7.53863 4.86116 7.74526 4.6665 8.00016 4.6665ZM7.53863 6.32656H7.3556C7.1537 6.32656 6.92308 6.50687 6.92308 6.81057C6.92308 7.04281 7.06671 7.21747 7.2306 7.27361L7.53863 7.37913V6.32656ZM8.4617 8.62061V9.67307H8.6444C8.8463 9.67307 9.07692 9.49275 9.07692 9.18905C9.07692 8.95681 8.93329 8.78215 8.7694 8.72601L8.4617 8.62061Z"
        fill={color}
      />
    </svg>
  )
}
