import { Money, ShiftStatus } from '@traba/types'
import { CancellationSource, Shift } from '@traba/types'

export const getIsShiftLateCanceled = (shift: Shift, totalCharge?: Money) =>
  !!(
    shift.status === ShiftStatus.CANCELED &&
    shift.cancellationSource === CancellationSource.Business &&
    totalCharge &&
    totalCharge.amount > 0
  )
