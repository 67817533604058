import MuiModal from '@mui/material/Modal'
import { theme, Z_INDEXES } from '@traba/theme'
import React, { useState } from 'react'
import { SvgIcon } from '../SvgIcon'
import { Text } from '../Text'
import {
  HeaderRow,
  IconWrapper,
  MODAL_SIZE,
  ModalContainer,
  ModalInnerContainer,
} from './Modal.styles'

export interface ModalProps {
  title?: string
  children: any
  handleClose: () => void
  isOpen: boolean
  size?: MODAL_SIZE
  style?: { [key: string]: string | number }
  disableCloseOnBackdropClick?: boolean
}

export function Modal(props: ModalProps): React.JSX.Element {
  const {
    title,
    children,
    size = MODAL_SIZE.DEFAULT,
    isOpen,
    handleClose,
    disableCloseOnBackdropClick,
  } = props

  return (
    <MuiModal
      open={isOpen}
      onClose={(_event, reason: string) => {
        if (reason === 'backdropClick' && disableCloseOnBackdropClick) {
          return
        }
        handleClose()
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={{
        backdrop: {
          style: { backgroundColor: theme.colors.MidnightBlue, opacity: 0.5 },
        },
      }}
      sx={{ zIndex: Z_INDEXES.MODAL }}
    >
      <ModalContainer
        size={size}
        style={{
          ...props.style,
          flexDirection: 'column',
          alignItems: 'normal',
          padding: theme.space.xsmed,
        }}
      >
        {title && (
          <HeaderRow>
            <Text style={{ textTransform: 'capitalize' }} variant={'h4'}>
              {title}
            </Text>
            <IconWrapper onClick={handleClose}>
              <SvgIcon name="cancel" />
            </IconWrapper>
          </HeaderRow>
        )}
        <ModalInnerContainer>{children}</ModalInnerContainer>
      </ModalContainer>
    </MuiModal>
  )
}

export interface ModalState {
  isOpen: boolean
  handleClose: () => void
  open: () => void
}

export function useModal(): ModalState {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return {
    isOpen: isModalOpen,
    handleClose: () => setIsModalOpen(false),
    open: () => setIsModalOpen(true),
  }
}
