import { useState } from 'react'
import { useQueryClient, UseQueryResult } from 'react-query'
import { Id, SearchParams } from 'src/types'

/** Front-end only pagination */
export function useSimplePagination<T>(pageSize: number, data?: T[]) {
  const [page, setPage] = useState(0)

  const onPageLeft = () => {
    setPage(page - 1)
  }

  const onPageRight = () => {
    setPage(page + 1)
  }

  const resetPagination = () => {
    setPage(0)
  }

  return {
    page,
    onPageLeft,
    onPageRight,
    data: data?.slice(page * pageSize, (page + 1) * pageSize + 1) || [],
    resetPagination,
  }
}

/** Standard Pagination, for use when backend supports it */
export function useShiftPagination<T extends Id>(
  queryKey: string,
  params: SearchParams,
  useQuery: (
    params: SearchParams,
    reverseResponse?: boolean,
  ) => UseQueryResult<T[], Error>,
  pageSize: number,
  startPage = 0,
) {
  const defaultSortDir = params.sortDir ?? 'asc'
  const reverseSortDir = defaultSortDir === 'asc' ? 'desc' : 'asc'
  const [page, setPage] = useState(startPage)
  const [startAt, setStartAt] = useState<string | undefined>(undefined)
  const [startAtIndex, setStartAtIndex] = useState<number>(page * pageSize)
  const { data, refetch, isLoading } = useQuery(
    {
      ...params,
      sortDir: defaultSortDir,
      startAt,
      startAtIndex: startAtIndex?.toString(),
    },
    defaultSortDir === reverseSortDir,
  )

  const queryClient = useQueryClient()

  const onPageLeft = () => {
    setStartAtIndex((page - 1) * pageSize)
    setPage(page - 1)
  }

  const onPageRight = () => {
    setStartAtIndex((page + 1) * pageSize)
    setPage(page + 1)
  }

  const invalidateQueryClient = async () => {
    await queryClient.invalidateQueries(queryKey)
  }

  const resetPagination = () => {
    setPage(0)
    setStartAt('')
    setStartAtIndex(0)
  }

  return {
    page,
    startAt,
    setStartAt,
    setStartAtIndex,
    onPageLeft,
    onPageRight,
    data,
    refetch,
    isLoading,
    invalidateQueryClient,
    resetPagination,
  }
}
