import { SvgIconProps } from '@traba/types'

export function DragHandle({ size, color = '#C4CCD4', ...rest }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 24" fill="none" {...rest}>
      <rect width="6" height="6" rx="3" fill={color} />
      <rect x="10" width="6" height="6" rx="3" fill={color} />
      <rect y="9" width="6" height="6" rx="3" fill={color} />
      <rect x="10" y="9" width="6" height="6" rx="3" fill={color} />
      <rect y="18" width="6" height="6" rx="3" fill={color} />
      <rect x="10" y="18" width="6" height="6" rx="3" fill={color} />
    </svg>
  )
}
