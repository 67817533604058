import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { WorkerAccountStatusForBusiness } from '@traba/types'
import { useQuery } from 'react-query'

async function getWorkerAccountStatusById(
  workerId?: string,
): Promise<WorkerAccountStatusForBusiness | undefined> {
  try {
    if (!workerId) {
      return undefined
    }
    const res = await trabaApi.get(
      `/my-company/workers/${workerId}/account-status`,
    )
    return res.data
  } catch (error) {
    console.error(
      'useWorkerAccountStatus -> getWorkerAccountStatusById() ERROR',
      error,
    )
    Sentry.captureException(error, {
      tags: {
        action: 'useWorkerAccountStatus -> getWorkerAccountStatusById()',
      },
    })
  }
}

export function useWorkerAccountStatus(workerId?: string) {
  const { isLoading: isLoadingWorkerAccountStatus, data: workerAccountStatus } =
    useQuery<WorkerAccountStatusForBusiness | undefined, Error>(
      ['workerAccountStatusById', workerId],
      () => getWorkerAccountStatusById(workerId),
    )

  return {
    isLoading: isLoadingWorkerAccountStatus,
    workerAccountStatus,
  }
}
