import { DialogTitle } from '@mui/material'
import {
  Dialog as MuiDialog,
  DialogContent,
  DialogProps as MuiDialogProps,
  ModalProps,
} from '@mui/material'
import { MEDIA, theme } from '@traba/theme'
import { IconName } from '@traba/types'
import React, { useCallback, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Button, ButtonVariant } from '../Button/Button'
import { MODAL_SIZE, modalHeights } from '../Modal/Modal.styles'
import Row from '../Row'
import { SvgIcon } from '../SvgIcon'
import { Text } from '../Text'
import * as S from './Dialog.styles'

export interface DialogProps extends MuiDialogProps {
  children?: React.ReactNode
  dialogTitleIcon?: IconName
  dialogTitle: string | JSX.Element
  dialogTitleColor?: string
  dialogSubtitle?: string | JSX.Element
  onClose: () => void
  onDismiss?: () => void
  confirmDisabled?: boolean
  onConfirm?: () => void
  confirming?: boolean
  onConfirmCTA?: string
  renderDialogFooterAs?: () => React.ReactNode
  hideFooter?: boolean
  confirmButtonVariant?: ButtonVariant
  style?: { [key: string]: string | number }
  formId?: string
  dividers?: boolean
  size?: MODAL_SIZE
  /**
   * If true, the dialog can be dismissed by clicking outside of it
   * @default true
   */
  dismissable?: boolean
}

export const Dialog: React.FC<DialogProps> = (props) => {
  const {
    children,
    dialogTitle,
    onClose,
    dismissable,
    onConfirm,
    onConfirmCTA,
    confirmDisabled,
    formId,
    size,
    dialogTitleIcon,
    confirmButtonVariant,
    dialogTitleColor,
    confirming,
    style,
    renderDialogFooterAs,
    hideFooter,
    ...rest
  } = props
  const isMobile = !useMediaQuery({
    query: `(min-width: ${MEDIA.MEDIUM}px)`,
  })

  const onCloseDialog: ModalProps['onClose'] = (e, reason) => {
    if (reason === 'backdropClick' && dismissable !== false) {
      onClose()
    }
  }

  // Function to handle keyboard events
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.metaKey && event.shiftKey && event.key === 'Enter') {
        // Trigger the click on the secondary button
        onClose()
      } else if (event.metaKey && event.key === 'Enter') {
        // Trigger the click on the primary button
        !confirmDisabled && onConfirm && onConfirm()
      }
    },
    [confirmDisabled, onClose, onConfirm],
  )

  // Add event listeners when the component mounts
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)

    // Clean up event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  return (
    <MuiDialog
      fullScreen={isMobile}
      onClose={onCloseDialog}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      {...rest}
    >
      <DialogTitle>
        <Row
          justifyBetween
          alignCenter
          style={{
            paddingRight: theme.space.xs,
          }}
        >
          <div>
            <Row alignCenter>
              {dialogTitleIcon && (
                <SvgIcon
                  name={dialogTitleIcon}
                  width={theme.space.sm}
                  height={theme.space.sm}
                  style={{ marginRight: theme.space.xs }}
                />
              )}
              <Text color={dialogTitleColor} variant="h5" size={18}>
                {dialogTitle}
              </Text>
            </Row>

            {props.dialogSubtitle && (
              <Text variant="body2" mt={theme.space.xxs}>
                {props.dialogSubtitle}
              </Text>
            )}
          </div>
        </Row>
      </DialogTitle>
      <S.ButtonIcon
        style={{
          position: 'absolute',
          right: theme.space.xs,
          top: theme.space.xs,
        }}
        name="cancel"
        onClick={onClose}
      />

      {children && (
        <DialogContent
          dividers={props.dividers != false}
          style={{
            height: size ? modalHeights[size] : undefined,
            minWidth: isMobile ? undefined : 540,
            ...style,
          }}
        >
          {children}
        </DialogContent>
      )}
      {!hideFooter && (
        <S.StyledDialogActions>
          {renderDialogFooterAs ? (
            renderDialogFooterAs()
          ) : (
            <>
              <Button
                type="button"
                variant={ButtonVariant.OUTLINED}
                onClick={onClose}
                aria-label="Cancel"
              >
                Cancel
              </Button>
              <Button
                disabled={confirmDisabled}
                loading={confirming}
                variant={confirmButtonVariant || ButtonVariant.FILLED}
                type="submit"
                onClick={onConfirm}
                form={formId}
                aria-label={onConfirmCTA || 'Confirm'}
              >
                {onConfirmCTA || 'Confirm'}
              </Button>
            </>
          )}
        </S.StyledDialogActions>
      )}
    </MuiDialog>
  )
}
