import { theme } from '@traba/theme'
import {
  COMPANY_WIDE_ID,
  COMPANY_WIDE_TEXT,
  IMenuItem,
  InputStatus,
  Role,
} from '@traba/types'
import { getLocationNameOrTruncatedAddress } from '@traba/utils'
import React, { useCallback, useMemo } from 'react'
import { SearchSelect } from '../base-components/SearchSelect/SearchSelect'
import { MenuItemGroup } from '../base-components/Select/Select'

interface RoleSearchSelectProps {
  roles: Pick<Role, 'roleId' | 'roleName' | 'roleDescription' | 'location'>[]
  selectedRoleId?: string
  selectedLocationId?: string
  disabledRoleIds?: string[]
  handleRoleChange: (roleId: string) => void
  placeholder?: string
  errorMessage?: string
  style?: React.CSSProperties
  selectStyle?: React.CSSProperties
  labelStyle?: React.CSSProperties
  disabled?: boolean
  label?: string
}

const compareByGroups = (group1: MenuItemGroup, group2: MenuItemGroup) => {
  if (group1.id === group2.id) {
    return 0
  } else if (group1.id === COMPANY_WIDE_ID) {
    return 1
  } else if (group2.id === COMPANY_WIDE_ID) {
    return -1
  }
  return group1.id.localeCompare(group2.id)
}

export const RoleSearchSelect: React.FC<RoleSearchSelectProps> = ({
  roles,
  selectedRoleId,
  selectedLocationId,
  disabledRoleIds,
  handleRoleChange,
  placeholder,
  errorMessage,
  style,
  selectStyle,
  labelStyle,
  disabled,
  label,
}) => {
  const groups: MenuItemGroup[] = useMemo(() => {
    return Object.values(
      roles.reduce<Record<string, MenuItemGroup>>((acc, role) => {
        if (
          role.location &&
          selectedLocationId &&
          role.location.locationId !== selectedLocationId
        ) {
          return acc
        }
        const locationName = role.location
          ? getLocationNameOrTruncatedAddress(role.location)
          : COMPANY_WIDE_TEXT
        const locationId = role.location?.locationId ?? COMPANY_WIDE_ID

        acc[locationId] = {
          id: locationId,
          title: locationName,
        }

        return acc
      }, {}),
    )
  }, [roles, selectedLocationId]).sort(compareByGroups)

  const disabledRoleIdsSet = useMemo(
    () => new Set(disabledRoleIds),
    [disabledRoleIds],
  )

  const roleOptions = useMemo(() => {
    return roles.flatMap((role) => {
      return {
        value: role.roleId,
        label: role.roleName,
        secondaryLabel:
          role.roleDescription.length > 100
            ? `${role.roleDescription.slice(0, 100)}...`
            : role.roleDescription,
        groupId: role.location?.locationId ?? COMPANY_WIDE_ID,
        disabled: disabledRoleIdsSet.has(role.roleId),
      }
    })
  }, [roles, disabledRoleIdsSet])

  const roleOptionsWithBadges: IMenuItem[] = useMemo(() => {
    return roleOptions.map((option) => {
      const groupForOption = groups.find((g) => g.id === option.groupId)
      const tags = groupForOption
        ? [
            {
              title: groupForOption.title,
              iconName: 'location',
              variant:
                groupForOption.title === COMPANY_WIDE_TEXT
                  ? 'darkOrange'
                  : 'business',
            },
          ]
        : undefined
      return { ...option, trailingTags: tags }
    })
  }, [roleOptions, groups])

  const selectItem = useMemo(
    () => roleOptionsWithBadges.find((item) => item.value === selectedRoleId),
    [roleOptionsWithBadges, selectedRoleId],
  )

  const handleSelectItem = useCallback(
    (item?: IMenuItem) => {
      if (!item?.value) {
        return
      }
      handleRoleChange(item?.value)
    },
    [handleRoleChange],
  )

  return (
    <SearchSelect
      options={roleOptionsWithBadges}
      handleSelect={handleSelectItem}
      selectItem={selectItem}
      shouldAlsoSearchSecondaryLabel={true}
      style={{
        minWidth: '40%',
        marginTop: theme.space.xxs,
        marginBottom: theme.space.xxs,
        ...style,
      }}
      selectStyle={selectStyle}
      labelStyle={labelStyle}
      groupByGroup={true}
      onlyShowLabel={false}
      groups={groups}
      placeholder={placeholder}
      inputStatus={errorMessage ? InputStatus.error : InputStatus.default}
      errorMessage={errorMessage}
      disabled={disabled}
      label={label}
    />
  )
}
