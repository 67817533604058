import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { DailyViewShiftMetadata } from '@traba/types'
import { BULLET_CHAR } from 'src/libs/constants'

interface Props {
  individualMetadata: DailyViewShiftMetadata
}

export const ScheduleDailyViewShiftTitle = (props: Props) => {
  const { individualMetadata } = props
  const { shift } = individualMetadata
  return (
    <Text variant="h5" color={theme.colors.Violet}>
      {shift.shiftRole} {BULLET_CHAR} {shift.slotsRequested} workers{' '}
      {BULLET_CHAR} {individualMetadata.startTimeToEndTime}
    </Text>
  )
}
