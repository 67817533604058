import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { DailyViewOfScheduleDetails } from '@traba/types'

const getShiftRequestParentsDetailsByDay = async (
  shiftRequestParentId: string,
  day: string,
) => {
  try {
    const response = await trabaApi.get(
      `my-company/shift-request-parents/${shiftRequestParentId}/get-details-by-day/${day}`,
    )
    return response.data
  } catch (error) {
    console.error(
      'Error querying for shift request parent details by day ',
      error,
    )
    throw error
  }
}

export const useShiftRequestParentDetailsByDay = ({
  shiftRequestParentId,
  day,
}: {
  shiftRequestParentId: string
  day: string
}) => {
  const {
    isLoading,
    isError,
    data: shiftRequestParentDayDetails,
    error,
    isFetched,
    refetch,
  } = useQuery<DailyViewOfScheduleDetails, Error>({
    queryKey: ['shift-request-parents-details', { shiftRequestParentId, day }],
    queryFn: () =>
      getShiftRequestParentsDetailsByDay(shiftRequestParentId, day),
    staleTime: FIVE_MINUTES_IN_MS,
    refetchOnWindowFocus: true,
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    shiftRequestParentDayDetails,
    refetch,
  }
}
