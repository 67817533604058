import { MODAL_SIZE } from '@traba/react-components'
import { WorkerShift } from '@traba/types'
import { compact } from 'lodash'
import { useState } from 'react'
import { WizardDialog } from 'src/components/Wizard/WizardDialog'
import { useCompanyWorkersByIds } from 'src/hooks/useCompanyWorkers'
import { theme } from 'src/libs/theme'
import { formatDateString } from 'src/shared/utils/dateUtils'

import { Col, SvgIcon, Text } from '../../base'

import { ReviewWorkerForm } from '../ReviewWorkerForm'

export interface ReviewWorkerWizardProps {
  open: boolean
  onClose: () => void
  workerShiftsToReview: WorkerShift[]
  title?: string
}

export const ReviewWorkerWizard = (props: ReviewWorkerWizardProps) => {
  const { open, onClose, workerShiftsToReview, title } = props

  const { workers: allWorkers, isLoading: loadingAllWorkers } =
    useCompanyWorkersByIds({
      workerIds: workerShiftsToReview.map((w) => w.workerId),
    })

  const [workerReviewCompletionStatus, setWorkerReviewCompletionStatus] =
    useState(workerShiftsToReview.map(() => false))

  function onWorkerReviewSaved(workerIndex: number) {
    const newCompletionStatus = [...workerReviewCompletionStatus]
    newCompletionStatus[workerIndex] = true
    setWorkerReviewCompletionStatus(newCompletionStatus)
  }

  const onCloseWizard = () => {
    onClose()
    const numberOfReviewedWorkers = compact(workerReviewCompletionStatus)
    window.analytics.track(`User closed review worker wizard`, {
      numberOfReviewedWorkers,
    })
  }

  return (
    <WizardDialog
      title={title || 'Rate Workers and help us match you better!'}
      onClose={() => onCloseWizard()}
      size={MODAL_SIZE.LARGE}
      allowDismiss={false}
      open={open}
      isLoading={loadingAllWorkers || !allWorkers}
      steps={workerShiftsToReview.map((w, i) => {
        const shiftToReview = workerShiftsToReview[i]
        const workerDetails = allWorkers?.find(
          (workerDetails) =>
            workerDetails.worker.workerId === w.workerId ||
            workerDetails.worker.uid === w.workerId,
        )
        const workerName = workerDetails
          ? workerDetails.worker.firstName + ' ' + workerDetails.worker.lastName
          : ''
        const workerImage = workerDetails ? workerDetails.worker.photoUrl : ''
        return {
          stepTitle: workerName,
          id: w.workerId,
          stepPicture: workerImage || '',
          isComplete: workerReviewCompletionStatus[i],
          stepBody:
            shiftToReview && workerDetails ? (
              <Col
                style={{
                  minHeight: 300,
                  width: '100%',
                }}
              >
                <Text
                  variant="h6"
                  mb={theme.space.xs}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.space.xxs,
                  }}
                >
                  {shiftToReview?.shiftInfo.shiftRole}
                  <SvgIcon name="dot" size={4} />
                  {formatDateString(
                    shiftToReview.shiftInfo.startTime,
                    shiftToReview.shiftInfo.timezone,
                  )}
                </Text>
                <ReviewWorkerForm
                  workerId={w.workerId}
                  shiftId={shiftToReview.shiftId}
                  workerName={workerName}
                  requiredAttributes={
                    shiftToReview.shiftInfo.requiredAttributes || []
                  }
                  onSaveReview={() => {
                    onWorkerReviewSaved(i)
                  }}
                />
              </Col>
            ) : (
              <Text>Could not find shift to review</Text>
            ),
          onValidate: () => true, // always allow skipping workers
        }
      })}
    />
  )
}
