import 'react-phone-input-2/lib/material.css'

import styled, { css } from 'styled-components'

type Props = {
  error?: boolean
  focused?: boolean
}

export const PhoneInputWrapper = styled.span<Props>`
  .react-tel-input {
    width: 100%;
    margin-top: 24px;
    cursor: pointer;

    :hover {
      .special-label {
        color: #8000ff;
      }

      input.form-control {
        border: 1px solid #8000ff;
        box-shadow: none;
      }
    }

    .special-label {
      margin-left: -15px;
      margin-top: -3px;
      cursor: pointer;
      font-size: 12px;
      font-weight: 500;
      color: #7a8a99;
    }

    input.form-control {
      width: 100%;
      height: 48px;
      border-radius: 8px;
      font-weight: 300;
      font-size: 14px;
      border: 1px solid ${({ theme }) => theme.border.color};
    }

    input.form-control:focus {
      box-shadow: none;
    }

    .country-list {
      border-radius: 8px;
      box-shadow: none;
      border: 1px solid #e0e0e0;

      .search-box {
        width: 100%;
        margin: 0;
        margin-left: -10px;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid #e0e0e0;
        font-weight: 300;
      }

      .country.highlight {
        background-color: #f5f5f5;
        border-radius: 8px;
      }

      .country {
        margin: 3px;

        :hover {
          background-color: #f5ebff;
          border-radius: 8px;
        }

        .country-name {
          font-weight: 300;
          color: #08105e;
        }
        .dial-code {
          font-weight: 300;
          color: #08105e;
        }
        .dial-code::before {
          content: '(';
        }
        .dial-code::after {
          content: ')';
        }
      }
    }

    ${({ error, theme, focused }) =>
      error
        ? css`
            .special-label {
              color: ${theme.error.border} !important;
            }

            input.form-control {
              border: 1px solid ${theme.error.border} !important;
            }
          `
        : focused
          ? css`
              .special-label {
                color: ${theme.colors.brand};
              }

              input.form-control:focus {
                border: 1px solid ${theme.colors.brand};
              }
            `
          : ''}
  }
`
