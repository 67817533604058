import React from 'react'
import { Col, Icon, Row, Text } from 'src/components/base'
import { RowProps } from 'src/components/base/Row'

type AlertProps = {
  children: React.ReactNode
  variant?: 'error' | 'inputError'
} & RowProps

function Alert({ variant = 'error', children, ...props }: AlertProps) {
  return (
    <Row {...props}>
      <Icon name="error" />{' '}
      <Col ml={10} width="auto">
        <Text variant={variant} role="alert">
          {children}
        </Text>
      </Col>
    </Row>
  )
}

export default Alert
