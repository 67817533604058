import { CardTile } from '@traba/react-components'
import React from 'react'
import { SvgIconName } from 'src/assets/svg/icons'
import { Icon, Text } from 'src/components'

import { EmptyPlaceholderTileContainer } from './EmptyPlaceholderTile.styles'

export interface EmptyPlaceholderTileProps {
  title: string
  subtitle?: string | React.ReactNode
  subtitleSmall?: string
  iconName: SvgIconName
  content?: React.ReactNode
  style?: React.CSSProperties
}

export const EmptyPlaceholderTile = (props: EmptyPlaceholderTileProps) => {
  const subtitleSmall = props.subtitleSmall ?? props.subtitle
  return (
    <EmptyPlaceholderTileContainer style={props.style}>
      <CardTile size="56px" style={{ marginBottom: 24 }}>
        <Icon name={props.iconName} />
      </CardTile>
      <Text variant="h5" style={{ textAlign: 'center' }}>
        {props.title}
      </Text>
      {props.subtitle &&
        (typeof props.subtitle === 'string' ? (
          <Text
            variant="body2"
            className="xs-hide md-show"
            style={{ textAlign: 'center', marginTop: 4 }}
          >
            {props.subtitle}
          </Text>
        ) : (
          props.subtitle
        ))}
      {subtitleSmall && (
        <Text
          variant="body2"
          className="md-hide"
          style={{ textAlign: 'center' }}
        >
          {subtitleSmall}
        </Text>
      )}
      {props.content}
    </EmptyPlaceholderTileContainer>
  )
}

export default EmptyPlaceholderTile
