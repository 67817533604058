import { SvgIconProps } from 'src/types/svg-types'

export default function Heart({
  size,
  color = '#7A8A99',
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
      <path
        d="M2.65593 7.7404L6.07865 12.3405C7.06366 13.6643 8.93633 13.6643 9.92135 12.3405L13.3441 7.7404C14.2186 6.56499 14.2186 4.89481 13.3441 3.71939C12.0638 1.99875 9.10474 2.50978 8 4.36572C6.89526 2.50978 3.93619 1.99875 2.65593 3.71939C1.78136 4.8948 1.78136 6.56499 2.65593 7.7404Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
