import {
  Autocomplete as MuiAutocomplete,
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
  Chip,
  FilterOptionsState,
  TextField,
} from '@mui/material'
import { ButtonVariant, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { DailyViewShiftMetadata } from '@traba/types'
import { containsText } from '@traba/utils'
import React, { useMemo } from 'react'
import { useState } from 'react'
import { BULLET_CHAR } from 'src/libs/constants'
import { ScheduleDailyViewAddAnnouncementForm } from './ScheduleDailyViewAddAnnouncementForm'

interface Props {
  individualMetadata: DailyViewShiftMetadata[]
  setIsAdding: React.Dispatch<React.SetStateAction<boolean>>
}

export const ScheduleDailyViewAddAnnouncementContent = (props: Props) => {
  const { individualMetadata, setIsAdding } = props
  const individualMetadataWithShiftIds: DailyViewShiftMetadata[] = useMemo(
    () =>
      individualMetadata.filter(
        (im): im is DailyViewShiftMetadata => !!im.shift.shiftId,
      ),
    [individualMetadata],
  )
  const [input, setInput] = useState('')
  const [selectedShiftId, setSelectedShiftId] = useState<string>()
  const filterOptions = (
    options: DailyViewShiftMetadata[],
    state: FilterOptionsState<DailyViewShiftMetadata>,
  ) =>
    options.filter(
      (o) =>
        !input.includes(o.shift.shiftRole) &&
        containsText(o.shift.shiftRole, state.inputValue),
    )

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} label={'Select shift'} />
  )

  const renderTags = (
    tags: DailyViewShiftMetadata[],
    getTagProps: AutocompleteGetTagProps,
  ) =>
    tags.map((option: DailyViewShiftMetadata, index: number) => (
      <Chip
        variant={ButtonVariant.OUTLINED}
        label={option.shift.shiftRole}
        {...getTagProps({ index })}
      />
    ))

  return (
    <>
      <Text variant="h5" mt={theme.space.sm} mb={theme.space.xs}>
        Select a shift to send announcement
      </Text>
      <MuiAutocomplete
        fullWidth
        filterSelectedOptions
        options={individualMetadataWithShiftIds}
        renderOption={(props, option: DailyViewShiftMetadata) => {
          const { shift, startTimeToEndTime } = option
          const { slotsRequested, shiftId, shiftRole } = shift

          return (
            <li {...props} key={shiftId}>
              <Row alignCenter>
                <Text variant="h6" ml={theme.space.xxs}>
                  {shiftRole} {BULLET_CHAR} {slotsRequested} workers{' '}
                  {BULLET_CHAR} {startTimeToEndTime}
                </Text>
              </Row>
            </li>
          )
        }}
        inputValue={input}
        onInputChange={(event, value: string) => {
          if (event) {
            if (event.type === 'keydown') {
              const nativeEvent = event.nativeEvent as KeyboardEvent
              if (nativeEvent.key === 'Enter') {
                setInput(value)
              }
            } else {
              setInput(value)
            }
          }
        }}
        onChange={(_, value) => {
          if (value) {
            setSelectedShiftId(value.shift.shiftId)
          }
        }}
        filterOptions={filterOptions}
        renderInput={renderInput}
        getOptionLabel={(option: DailyViewShiftMetadata) =>
          `${option.shift.shiftRole}`
        }
        renderTags={renderTags}
        style={{ marginBottom: theme.space.sm }}
      />
      {selectedShiftId && (
        <ScheduleDailyViewAddAnnouncementForm
          shiftId={selectedShiftId}
          onSubmitOrCancel={() => {
            setIsAdding(false)
            setSelectedShiftId(undefined)
          }}
        />
      )}
    </>
  )
}
