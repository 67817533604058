import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { Dialog, MODAL_SIZE, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Roster, ShiftRequest } from '@traba/types'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useRoster } from 'src/hooks/useRoster'
import { useScheduleInvitations } from 'src/hooks/useScheduleInvitations'
import { BookShiftsInvitationsModal } from 'src/screens/BookShifts/steps/sections/BookShiftsInvitationsModal'
import { Row } from '../base'
import { Checkbox } from '../base/CheckboxThemed'

interface ScheduleInvitationModalProps {
  shiftRequestParentId: string
  shiftRequests: ShiftRequest[] | undefined
  showInvitationModal: boolean
  setShowInvitationModal: Dispatch<SetStateAction<boolean>>
  workersInvitedByShiftRequest: Map<string, Set<string>> | undefined
}

enum SCHEDULE_INVITE_STEP {
  SELECT_ROLES,
  SELECT_WORKERS,
}

export const ScheduleInvitationModal = ({
  shiftRequests,
  showInvitationModal,
  setShowInvitationModal,
  shiftRequestParentId,
  workersInvitedByShiftRequest,
}: ScheduleInvitationModalProps) => {
  const [selectShiftRequestIds, setSelectShiftRequestIds] = useState(
    new Set<string>(),
  )
  const [isConfirming, setIsConfirming] = useState(false)
  const [selectedWorkers, setSelectedWorkers] = useState(new Set<string>())
  const [currStep, setCurrentStep] = useState<SCHEDULE_INVITE_STEP>(
    SCHEDULE_INVITE_STEP.SELECT_ROLES,
  )
  const [selectedRoster, setSelectedRoster] = useState<Roster | undefined>()
  const { showSuccess, showError } = useAlert()
  const { locationId, roleId } = shiftRequests
    ? shiftRequests[0]
    : { locationId: undefined, roleId: undefined }
  const { roster } = useRoster(selectedRoster?.id || '', locationId, roleId)
  const { refetch } = useScheduleInvitations(shiftRequestParentId)
  const disabledWorkerIdsSet = useMemo(() => {
    const disabledWorkerIds = new Set<string>()
    if (workersInvitedByShiftRequest) {
      selectShiftRequestIds.forEach((shiftRequestId) => {
        const workerIds = workersInvitedByShiftRequest.get(shiftRequestId)
        if (workerIds) {
          workerIds.forEach((workerId) => {
            disabledWorkerIds.add(workerId)
          })
        }
      })
    }
    return disabledWorkerIds
  }, [selectShiftRequestIds, workersInvitedByShiftRequest])

  const onClickShiftRequest = (shiftRequestId: string) => {
    const newShiftRequestIds = new Set(selectShiftRequestIds)
    if (selectShiftRequestIds.has(shiftRequestId)) {
      newShiftRequestIds.delete(shiftRequestId)
    } else {
      newShiftRequestIds.add(shiftRequestId)
    }
    setSelectShiftRequestIds(newShiftRequestIds)
  }

  const onClickWorker = (workerId: string) => {
    const newWorkers = new Set(selectedWorkers)
    if (selectedWorkers.has(workerId)) {
      newWorkers.delete(workerId)
    } else {
      newWorkers.add(workerId)
    }
    setSelectedWorkers(newWorkers)
  }

  const onConfirmInvite = async () => {
    try {
      setIsConfirming(true)
      const workerIdArray = Array.from(selectedWorkers)
      const shiftRequestIdArray = Array.from(selectShiftRequestIds)

      if (workerIdArray.length > 0 && shiftRequestIdArray.length > 0) {
        await Promise.all(
          shiftRequestIdArray.map((shiftRequestId) =>
            trabaApi.post(
              `my-company/shift-request/${shiftRequestId}/invitations`,
              { workerIds: Array.from(selectedWorkers) },
            ),
          ),
        )
      }
      refetch()
    } catch (error) {
      setIsConfirming(false)
      showError('Something went wrong, please try again')
      return
    }
    setIsConfirming(false)
    setCurrentStep(SCHEDULE_INVITE_STEP.SELECT_ROLES)
    setShowInvitationModal(false)
    showSuccess('Invitations sent successfully')
  }

  const onCloseModal = () => {
    setCurrentStep(SCHEDULE_INVITE_STEP.SELECT_ROLES)
    setShowInvitationModal(false)
    setSelectedRoster(undefined)
    setSelectedWorkers(new Set())
    setSelectShiftRequestIds(new Set())
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        scroll="paper"
        open={
          showInvitationModal && currStep === SCHEDULE_INVITE_STEP.SELECT_ROLES
        }
        onClose={onCloseModal}
        onConfirmCTA="Next"
        dialogTitle={'Invite workers to this schedule '}
        confirmDisabled={selectShiftRequestIds.size === 0}
        formId="invite-worker"
        size={MODAL_SIZE.LARGE}
        onConfirm={() => {
          setCurrentStep(SCHEDULE_INVITE_STEP.SELECT_WORKERS)
        }}
      >
        <Text variant="h5" mb={theme.space.sm} mt={theme.space.xs}>
          Which roles you want to invite workers for?
        </Text>
        {shiftRequests?.map((sr) => (
          <Row
            style={{
              borderRadius: 10,
              border: `1px solid ${theme.colors.Grey20}`,
              backgroundColor: selectShiftRequestIds.has(sr.shiftRequestId)
                ? theme.colors.Grey10
                : theme.colors.White,
              padding: theme.space.sm,
            }}
            gap={theme.space.sm}
            mb={theme.space.sm}
          >
            <Checkbox
              selected={selectShiftRequestIds.has(sr.shiftRequestId)}
              onClick={() => onClickShiftRequest(sr.shiftRequestId)}
            />
            <Text>{sr.shiftRole}</Text>
          </Row>
        ))}
      </Dialog>
      <BookShiftsInvitationsModal
        isOpen={
          showInvitationModal &&
          currStep === SCHEDULE_INVITE_STEP.SELECT_WORKERS
        }
        setIsOpen={() => {
          setCurrentStep(SCHEDULE_INVITE_STEP.SELECT_ROLES)
          setShowInvitationModal(false)
        }}
        isConfirming={isConfirming}
        selectedRoster={selectedRoster}
        setSelectedRoster={setSelectedRoster}
        selectedWorkers={selectedWorkers}
        setSelectedWorkers={setSelectedWorkers}
        disabledWorkerIdsSet={disabledWorkerIdsSet}
        onConfirm={onConfirmInvite}
        roster={roster}
        onClickWorker={onClickWorker}
        onCloseModal={onCloseModal}
      />
    </>
  )
}
