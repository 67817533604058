import { CardTile, ShiftInvitationBadge } from '@traba/react-components'
import { DotMenu } from '@traba/react-components'
import {
  Shift,
  ShiftInvitation,
  ShiftInvitationStatus,
  WorkerShift,
} from '@traba/types'
import { getTimeUntilShiftOpen } from '@traba/utils'
import { AxiosError } from 'axios'
import { isAfter } from 'date-fns'
import { useState } from 'react'
import { UseMutateFunction } from 'react-query'
import { useNavigate } from 'react-router-dom'
import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'
import { anyToDate } from 'src/shared/utils/dateUtils'
import { getInitials } from 'src/utils/stringUtils'

import { EmptyPlaceholderTile, Image, Row, Text } from '../../base'
import { ConfirmationDialog } from '../../Modals/ConfirmationDialog/ConfirmationDialog'
import { Table, Td, Tr } from '../../Table/Table'
import { WorkerLinkText } from '../../WorkerDetails/WorkerLinkText'

export function ShiftInvitationsTable({
  shift,
  shiftInvitations,
  canceledWorkerShifts,
  rescindInvitation,
  onClickInviteWorker,
  styles,
  hideTitle,
}: {
  shift: Shift
  shiftInvitations: ShiftInvitation[]
  canceledWorkerShifts: WorkerShift[]
  rescindInvitation: UseMutateFunction<
    ShiftInvitation,
    AxiosError,
    ShiftInvitation
  >
  onClickInviteWorker: () => void
  styles?: React.CSSProperties
  hideTitle?: boolean
}) {
  const { isMobileViewOrReactNative } = useMobile()

  const navigate = useNavigate()
  const [invitationToRescind, setInvitationToRescind] = useState<
    ShiftInvitation | undefined
  >(undefined)
  const { endTime } = shift
  const startTime = shift.businessStartTime ?? shift.startTime
  const isShiftInTheFuture = isAfter(anyToDate(startTime), new Date())
  const shiftOpenToPublicTime = getTimeUntilShiftOpen(startTime, endTime)

  return (
    <div
      style={{
        overflowX: 'auto',
        marginBottom: theme.space.xl,
        marginTop: theme.space.xs,
        minHeight: 400,
        ...styles,
      }}
    >
      {!hideTitle && (
        <Row justifyBetween mb="sm">
          <Text variant="h4">Shift Invitations</Text>
          {isShiftInTheFuture && (
            <Text variant="link" onClick={onClickInviteWorker}>
              {isMobileViewOrReactNative
                ? '+ Invite'
                : '+ Invite Additional Workers'}
            </Text>
          )}
        </Row>
      )}
      {shiftInvitations.length ? (
        <Table headers={['WORKER NAME', 'INVITATION STATUS', '']}>
          {shiftInvitations.map((shiftInvitation) => {
            const { id, worker, workerId, status } = shiftInvitation
            const canceledWorkerShift = canceledWorkerShifts.find(
              (ws) => ws.workerId === workerId,
            )
            return (
              <Tr key={id}>
                <Td
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <CardTile size="40px" style={{ minWidth: 40 }}>
                    {worker.photoUrl ? (
                      <Image
                        style={{
                          width: '40px',
                          height: '40px',
                          borderRadius: theme.space.xxs,
                        }}
                        src={worker.photoUrl}
                        alt="worker-photo"
                      />
                    ) : (
                      <Text variant="h6">
                        {getInitials(worker.firstName, worker.lastName)}
                      </Text>
                    )}
                  </CardTile>
                  <WorkerLinkText
                    variant="h6"
                    style={{
                      marginLeft: theme.space.sm,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                    onClick={() => navigate(`/worker/${workerId}`)}
                  >
                    {`${worker.firstName} ${worker.lastName}`}
                  </WorkerLinkText>
                </Td>
                <Td>
                  <ShiftInvitationBadge
                    id={id}
                    status={status}
                    canceledWorkerShift={canceledWorkerShift}
                    shiftOpenToPublicTime={shiftOpenToPublicTime}
                  />
                </Td>
                {(status === ShiftInvitationStatus.Pending ||
                  status === ShiftInvitationStatus.Sent) && (
                  <Td>
                    <DotMenu
                      type="rosters"
                      dotMenuKey={workerId}
                      menuItems={[
                        {
                          title: 'Rescind Invitation',
                          onClick: () =>
                            setInvitationToRescind(shiftInvitation),
                        },
                      ]}
                    />
                  </Td>
                )}
              </Tr>
            )
          })}
        </Table>
      ) : (
        <Row center>
          <EmptyPlaceholderTile
            iconName="info"
            title="You don't currently have any pending or accepted shift invitations."
            subtitle={
              isShiftInTheFuture
                ? 'Click on Invite Additional Workers to invite workers to the shift.'
                : ''
            }
          />
        </Row>
      )}
      <ConfirmationDialog
        open={!!invitationToRescind}
        onConfirm={() => {
          invitationToRescind && rescindInvitation(invitationToRescind)
          setInvitationToRescind(undefined)
        }}
        onClose={() => setInvitationToRescind(undefined)}
        onConfirmCTA="Rescind"
        title="Rescind Invitation?"
        confirmationText={`Are you sure you want to rescind invitation for ${invitationToRescind?.worker.firstName} ${invitationToRescind?.worker.lastName}?`}
      />
    </div>
  )
}
