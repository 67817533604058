import { ChangeMemberRoleModalContent } from '@traba/react-components'
import { UserRole } from '@traba/types'
import React, { useCallback } from 'react'

import { useMembers } from 'src/hooks/useMembers'

import { UserWithRole } from 'src/types'

export interface ChangeMemberRoleModalProps {
  member: UserWithRole
  handleClose: () => void
}

export function ChangeMemberRoleModal(props: ChangeMemberRoleModalProps) {
  const { member, handleClose } = props
  const { changeMemberRole, isChangeMemberRoleLoading } = useMembers()

  const handleChangeMemberRole = useCallback(
    async (role: UserRole) => {
      await changeMemberRole(
        { role, uid: member.uid },
        {
          onSettled: handleClose,
        },
      )
      window.analytics.track(`User Saved Member Role Change`, {
        member: member,
        role,
      })
    },
    [changeMemberRole, member, handleClose],
  )

  return (
    <ChangeMemberRoleModalContent
      member={member}
      handleClose={handleClose}
      onChangeMemberRole={handleChangeMemberRole}
      loading={isChangeMemberRoleLoading}
    />
  )
}
