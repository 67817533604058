import {
  AriaPopoverProps,
  DismissButton,
  Overlay,
  usePopover,
} from '@react-aria/overlays'
import { theme } from '@traba/theme'
import React, { useRef } from 'react'
import styled from 'styled-components'
import type { OverlayTriggerState } from '@react-stately/overlays'

interface PopoverProps extends Omit<AriaPopoverProps, 'popoverRef'> {
  children: React.ReactNode
  state: OverlayTriggerState
}

const PopoverContainer = styled.div`
  background-color: white;
  border: 1px solid ${theme.colors.Grey};
  border-radius: 5px;
  position: absolute;
  z-index: 10;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  margin-top: 4px;
  outline: none;
  overflow: auto;
`

export function Popover({ children, state, ...props }: PopoverProps) {
  const popoverRef = useRef(null)
  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      popoverRef,
    },
    state,
  )

  // DismissButton is a hidden button to allow screen readers to easily dismiss the popup
  return (
    <Overlay>
      <div {...underlayProps} style={{ position: 'fixed', inset: 0 }} />
      <PopoverContainer
        {...popoverProps}
        ref={popoverRef}
        style={{
          ...popoverProps.style,
        }}
      >
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </PopoverContainer>
    </Overlay>
  )
}
