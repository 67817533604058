import { useAlert } from '@traba/context'
import { Col, LoadingSpinner } from '@traba/react-components'
import { theme } from '@traba/theme'
import { DailyViewShiftMetadata } from '@traba/types'
import { ShiftCostSummary } from 'src/components/UpcomingShiftDetails/components/ShiftCostSummary'
import { useUpcomingShiftDetails } from 'src/components/UpcomingShiftDetails/UpcomingShiftDetails.hooks'
import { useShiftById } from 'src/hooks/useShifts'
import { useTimesheetForShift } from 'src/hooks/useTimesheetForShift'
import { useWorkerShifts } from 'src/hooks/workerShiftHooks'
import { ScheduleDailyViewShiftTitle } from './ScheduleDailyViewShiftTitle'

interface Props {
  shiftId: string
  individualMetadata: DailyViewShiftMetadata
}

export const ScheduleDailyViewEstimatedPayContent = (props: Props) => {
  const { shiftId, individualMetadata } = props
  const { shift, isLoading } = useShiftById(shiftId)
  const { data: workerShifts, isLoading: isLoadingWorkerShifts } =
    useWorkerShifts(shiftId)
  const { timesheet } = useTimesheetForShift(shiftId)
  const { showSuccess, handleError } = useAlert()
  const onShiftUpdateSuccess = () => {
    showSuccess('Shift updated successfully!')
  }
  const onShiftUpdateError = (error: any) => {
    handleError(
      error,
      'useUpcomingShiftDetails',
      'Failed to update the shift. Please try again or contact support.',
      'Error updating shift',
    )
  }
  const { expectedCost } = useUpcomingShiftDetails(
    shiftId,
    onShiftUpdateSuccess,
    onShiftUpdateError,
  )

  if (isLoading || isLoadingWorkerShifts) {
    return <LoadingSpinner />
  }
  if (!shift) {
    return null
  }

  return (
    <Col mt={theme.space.sm}>
      <ScheduleDailyViewShiftTitle individualMetadata={individualMetadata} />
      <ShiftCostSummary
        hideTitle
        workerShifts={workerShifts ?? []}
        expectedCost={expectedCost}
        shift={shift}
        timesheet={timesheet}
      />
    </Col>
  )
}
