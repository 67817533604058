import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useQuery } from 'react-query'

const getUpflowPortalUrl = async () => {
  try {
    const response = await trabaApi.get(`/my-company/upflow-portal-url`, {
      validateStatus: function (status) {
        return (status >= 200 && status < 300) || status === 404 // Resolve typical results as valid plus 404 since it didn't fail it just didn't have a company
      },
    })
    if (response.status === 404) {
      return
    }

    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const useUpflowPortalUrl = (enabled = true) => {
  const { isLoading, isError, data, error, isFetched, refetch, isFetching } =
    useQuery<{ upflowPortalUrl: string }, Error>(
      'upflowPortalUrl',
      getUpflowPortalUrl,
      {
        enabled,
        staleTime: FIVE_MINUTES_IN_MS,
        refetchOnWindowFocus: 'always',
      },
    )

  return {
    isLoading,
    isFetching,
    isError,
    error,
    isFetched,
    upflowPortalUrl: data?.upflowPortalUrl,
    refetch,
  }
}
