import { BreakType } from './companies'
import { CancellationSource, CancellationType } from './shifts'
import { JobStatus } from './worker-shift'

export enum RecurringWorkerShiftEditType {
  ALL_FUTURE = 'ALL_FUTURE',
  SINGLE = 'SINGLE',
}

export type RecurringWorkerShiftEdit = {
  recurringWorkerShiftEditId: string
  cancellationSource?: CancellationSource
  canceledAt?: Date
  cancellationReason?: string
  cancellationType?: CancellationType
  overridePayRate?: number
  overrideMinimumPaidTime?: number
  editType: RecurringWorkerShiftEditType
  originalStartTime: Date
  jobStatus?: JobStatus
  overrideBreakType?: BreakType
  workerId: string
  shiftRequestId: string
}

export type CancelRecurringWorkerShiftRequest = {
  workerIds: string[]
  shiftRequestId: string
  cancellationSource: CancellationSource
  cancellationReason?: string
  originalStartTime: Date
  shiftId?: string
  editType: RecurringWorkerShiftEditType
}
