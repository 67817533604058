import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import { ShiftStatus } from '@traba/types'
import { useCallback } from 'react'
import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'

import { Text } from '../base'
import { MembersWithLocationsFilteredSearchSelect } from '../MembersWithLocationsFilteredSearchSelect'
import * as S from '../ShiftCalendar/ShiftCalendar.styles'
import { ShiftCalendarProps } from '../ShiftCalendar/ShiftCalendar.ui'

interface ShiftFiltersProps
  extends Omit<
    ShiftCalendarProps,
    | 'shifts'
    | 'isShiftsFetched'
    | 'page'
    | 'onPageLeft'
    | 'onPageRight'
    | 'isShowWorkersToggled'
    | 'setIsShowWorkersToggled'
    | 'regions'
    | 'members'
  > {
  className?: string
  fullWidth?: boolean
  style?: React.CSSProperties
  hideCanceledFilter?: boolean
  fieldToUse?: 'status' | 'statuses'
}

export function ShiftFilters(props: ShiftFiltersProps) {
  const { className, filters, dispatch, fullWidth, hideCanceledFilter, style } =
    props
  const { isReactNativeApp } = useMobile()
  const onChangeSupervisorId = useCallback(
    (value: string) => {
      dispatch({ type: 'SET_SUPERVISOR', supervisorId: value })
    },
    [dispatch],
  )

  return (
    <S.FiltersContainer
      className={className}
      fullWidth={fullWidth || isReactNativeApp}
      style={{ ...style, gap: theme.space.xxs }}
    >
      <MembersWithLocationsFilteredSearchSelect
        supervisorId={filters.supervisorId}
        onChange={onChangeSupervisorId}
        placeholder="Filter by onsite contact"
      />
      {!hideCanceledFilter && !isReactNativeApp && (
        <FormGroup style={{ marginRight: theme.space.xs }}>
          <FormControlLabel
            labelPlacement="start"
            style={{ marginLeft: theme.space.xxs }}
            control={
              <Switch
                checked={filters.statuses?.includes(ShiftStatus.CANCELED)}
                onChange={() =>
                  dispatch({
                    type: 'SET_STATUSES',
                    statuses: filters.statuses?.includes(ShiftStatus.CANCELED)
                      ? filters.statuses.filter(
                          (status) => status !== ShiftStatus.CANCELED,
                        )
                      : [...(filters.statuses || []), ShiftStatus.CANCELED],
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Text variant="h6" color={theme.colors.MidnightBlue}>
                Show canceled shifts
              </Text>
            }
          />
        </FormGroup>
      )}
    </S.FiltersContainer>
  )
}
