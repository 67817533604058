import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import { useHotSettings } from '@traba/hooks'
import {
  Col,
  SELECT_ALL_OPTION_ID,
  SupervisorForShiftSearchSelect,
} from '@traba/react-components'
import { ShiftRequestParentWithShiftRequest, ShiftStatus } from '@traba/types'
import { getMembersWithAccessToAnyGivenLocations } from '@traba/utils'
import { useCallback, useMemo } from 'react'
import { Text } from 'src/components'
import { LocationSingleSelectFilter } from 'src/components/SearchWithLocationSelectionRow/LocationSingleSelectFilter'
import { ShowWorkersToggle } from 'src/components/ShiftCalendar/ShowWorkersToggle'
import { FilterDropdown } from 'src/components/ShiftFilters/FilterDropdown'
import { useMembers } from 'src/hooks/useMembers'
import useMobile from 'src/hooks/useMobile'
import { useSelectedRegionalFilterLocations } from 'src/hooks/useRegionalFilter'
import {
  CalendarFilters as CalendarFiltersType,
  FiltersAction,
} from 'src/hooks/useShiftFilters.hooks'
import { theme } from 'src/libs/theme'

interface Props {
  dispatch: React.Dispatch<FiltersAction>
  filters: CalendarFiltersType
  isShowWorkersToggled?: boolean
  setIsShowWorkersToggled?: React.Dispatch<React.SetStateAction<boolean>>
  shiftRequestParents?: ShiftRequestParentWithShiftRequest[]
}

export const CalendarFilters = ({
  dispatch,
  filters,
  isShowWorkersToggled,
  setIsShowWorkersToggled,
  shiftRequestParents,
}: Props) => {
  const { members } = useMembers()
  const { isMobileViewOrReactNative } = useMobile()
  const { hotSettings } = useHotSettings()
  const { selectedActiveLocationIds } = useSelectedRegionalFilterLocations()
  const supervisorsForLocations = useMemo(() => {
    return getMembersWithAccessToAnyGivenLocations({
      locationIds: selectedActiveLocationIds,
      members,
    })
  }, [members, selectedActiveLocationIds])
  const showCancelledSchedules = filters.statuses === undefined

  const onChangeLocation = useCallback(
    (newLocationId: string) => {
      const locationId =
        newLocationId === SELECT_ALL_OPTION_ID ? '' : newLocationId
      dispatch({ type: 'SET_LOCATION', locationId })
    },
    [dispatch],
  )

  const onChangeSupervisor = useCallback(
    (supervisorId: string) => {
      window.analytics.track(
        'User Updated Supervisor Filter for Calendar Filters',
        { supervisorId },
      )
      dispatch({ type: 'SET_SUPERVISOR', supervisorId })
    },
    [dispatch],
  )

  const onChangeScheduleName = useCallback(
    (shiftRequestParentId?: string) => {
      window.analytics.track(
        'User Updated Schedule Name Filter for Calendar Filters',
        { shiftRequestParentId },
      )
      dispatch({
        type: 'SET_SHIFT_REQUEST_PARENT',
        shiftRequestParentIds: shiftRequestParentId
          ? [shiftRequestParentId]
          : undefined,
      })
    },
    [dispatch],
  )

  const onToggleShowCanceledShifts = useCallback(() => {
    window.analytics.track(
      'User Toggled Show Canceled Shifts for Calendar Filters',
      { newShowCancelledShiftsStatus: !showCancelledSchedules },
    )
    dispatch({
      type: 'SET_STATUSES',
      statuses: showCancelledSchedules
        ? [ShiftStatus.ACTIVE, ShiftStatus.COMPLETE]
        : undefined,
    })
  }, [dispatch, showCancelledSchedules])

  const onToggleShowWorkers = useCallback(() => {
    if (setIsShowWorkersToggled) {
      window.analytics.track('User Toggled Show Workers for Calendar Filters', {
        newShowWorkersStatus: !isShowWorkersToggled,
      })
      setIsShowWorkersToggled(!isShowWorkersToggled)
    }
  }, [setIsShowWorkersToggled, isShowWorkersToggled])

  return (
    <Col mt={theme.space.med} mb={theme.space.lg} gap={theme.space.xs}>
      <Text variant="h5">Filter by</Text>

      <LocationSingleSelectFilter
        selectedLocation={filters.locationId || SELECT_ALL_OPTION_ID}
        setSelectedLocation={onChangeLocation}
        analyticsKey="Calendar Filters"
        label=""
      />

      <SupervisorForShiftSearchSelect
        placeholder="Onsite contact"
        selectedSupervisorId={filters.supervisorId}
        allSupervisors={supervisorsForLocations}
        handleSupervisorChange={onChangeSupervisor}
        disabled={members.length === 0}
        selectStyle={{ height: '48px' }}
        style={{ maxWidth: '100%' }}
      />

      {shiftRequestParents && hotSettings?.recurringSchedulesEnabled && (
        <FilterDropdown
          style={{ width: '100%', marginBottom: 0 }}
          label="Schedule name"
          selected={filters.shiftRequestParentIds?.[0] ?? undefined}
          items={shiftRequestParents
            .map((parent) => ({
              value: parent.shiftRequestParentId,
              label: `${parent.title}`,
            }))
            .sort((a, b) => (a.label < b.label ? -1 : 1))}
          onSelectItem={onChangeScheduleName}
        />
      )}
      <FormGroup style={{ marginRight: theme.space.xs }}>
        <FormControlLabel
          labelPlacement="start"
          style={{ marginLeft: 0 }}
          control={
            <Switch
              checked={showCancelledSchedules}
              onChange={onToggleShowCanceledShifts}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={
            <Text variant="h6" color={theme.colors.MidnightBlue}>
              Show canceled shifts
            </Text>
          }
        />
      </FormGroup>
      {isMobileViewOrReactNative &&
        isShowWorkersToggled !== undefined &&
        setIsShowWorkersToggled && (
          <ShowWorkersToggle
            isShowWorkersToggled={isShowWorkersToggled}
            setIsShowWorkersToggled={onToggleShowWorkers}
          />
        )}
    </Col>
  )
}
