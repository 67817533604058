import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'

import { Col, Row, SvgIcon, Text } from '../base'

export const FillRateRightCol = () => {
  const { isMobileViewOrReactNative } = useMobile()

  return (
    <>
      <Col
        style={{
          borderRadius: '8px',
          border: '2px solid var(--violet-40, #BF80FF)',
          background: isMobileViewOrReactNative ? 'none' : theme.colors.White,
          padding: theme.space.sm,
        }}
      >
        <Row>
          <SvgIcon name="flash" size={24} />
          <Text
            ml={theme.space.xxs}
            variant="h5"
            center
            color={theme.colors.Violet80}
            style={{
              textAlign: 'left',
            }}
          >
            98% Fill Rate. How do we do it?
          </Text>
        </Row>
        <Text
          variant="body1"
          style={{
            textAlign: 'left',
          }}
          color={theme.colors.Violet80}
          mt={theme.space.xs}
        >
          {'1) Every shift is monitored by our 24/7 live operations team.'}
        </Text>
        <Text
          color={theme.colors.Violet80}
          variant="body1"
          style={{
            textAlign: 'left',
          }}
          mt={theme.space.xxs}
        >
          {
            '2) In-App confirmation and reminder calls 2 hours before shift starts'
          }
        </Text>
        <Text
          color={theme.colors.Violet80}
          variant="body1"
          style={{
            textAlign: 'left',
          }}
          mt={theme.space.xxs}
        >
          {'3) Worker tracking to ensure on-time arrival'}
        </Text>
        <Text
          color={theme.colors.Violet80}
          variant="body1"
          style={{
            textAlign: 'left',
          }}
          mt={theme.space.xxs}
        >
          {'4) Backup workers for late cancels and no shows'}
        </Text>
      </Col>
      <img
        src="/img/signup-map.png"
        alt="Messaging Center"
        style={{
          boxShadow: 'none',
          width: '100%',
          marginTop: theme.space.lg,
          alignSelf: 'center',
        }}
      />
    </>
  )
}
