import { recurringSchedulesEnabled } from '@traba/utils'
import {
  Button,
  ButtonVariant,
  Card,
  Divider,
  InlineBanner,
  Row,
} from 'src/components'
import { useCompany } from 'src/hooks/useCompany'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import EditShiftsSaveButton from 'src/screens/EditShifts/EditShiftsSaveButton'

import { BookShiftsProps } from '../BookShiftsScreen'
import {
  validateScheduleStep,
  validateScheduleStepShiftDataModel,
  validateShiftEdits,
} from '../validation'
import { BookShiftsScheduleBreaksSection } from './sections/BookShiftsScheduleBreaksSection'
import { BookShiftsScheduleDateSection } from './sections/BookShiftsScheduleDateSection'
import { BookShiftsScheduleDateSectionShiftDataModel } from './sections/BookShiftsScheduleDateSectionShiftDataModel'

export function BookShiftsScheduleContent(props: BookShiftsProps) {
  const {
    isEdit,
    shiftRequest,
    shiftRequestMetadata,
    onContinue,
    onPrevious,
    onSaveChanges,
    selectedShifts,
    setShowSelectModal,
    shiftUpdates,
    defaultShiftRequest,
  } = props

  const { hotSettings } = useHotSettings()
  const { company } = useCompany()

  const validationError = recurringSchedulesEnabled({
    companyId: company?.companyId,
    hotSettings,
  })
    ? validateScheduleStepShiftDataModel(shiftRequest, shiftRequestMetadata)
    : validateScheduleStep(shiftRequest, shiftRequestMetadata)
  const editsError =
    selectedShifts &&
    defaultShiftRequest &&
    validateShiftEdits(defaultShiftRequest, selectedShifts, shiftUpdates)
  const isRecurringScheduleEnabled = recurringSchedulesEnabled({
    companyId: company?.companyId,
    hotSettings,
  })
  return (
    <Card borderWidth={isRecurringScheduleEnabled ? '2px' : '0px'}>
      {isRecurringScheduleEnabled ? (
        <BookShiftsScheduleDateSectionShiftDataModel {...props} />
      ) : (
        <BookShiftsScheduleDateSection {...props} />
      )}
      <Divider
        wrapperStyle={{ margin: `${theme.space.lg}px 0`, width: '100%' }}
      />
      <BookShiftsScheduleBreaksSection {...props} />

      {isEdit && editsError && (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity={'error'}
          text={editsError.message}
        />
      )}
      <Row
        style={{ justifyContent: 'space-between', marginTop: theme.space.lg }}
      >
        {isEdit ? (
          <EditShiftsSaveButton
            onSaveChanges={onSaveChanges}
            validationError={editsError || validationError}
            selectedShifts={selectedShifts}
            setShowSelectModal={setShowSelectModal}
          />
        ) : (
          <>
            <Button onClick={onPrevious} variant={ButtonVariant.OUTLINED}>
              Previous
            </Button>
            <Button onClick={onContinue} disabled={!!validationError}>
              Continue
            </Button>
          </>
        )}
      </Row>
    </Card>
  )
}
