import React from 'react'
import { Button, ButtonVariant, Row } from '../../base-components'

export function ModalButtons(props: {
  isUpdating: boolean
  isSaved: boolean
  saveDisabled: boolean
  onSave: () => Promise<void>
  onCancel: () => void
}): React.JSX.Element {
  return (
    <Row justifyBetween>
      <Button variant={ButtonVariant.OUTLINED} onClick={props.onCancel}>
        Cancel
      </Button>
      {props.isSaved ? (
        <Button variant={ButtonVariant.BRANDNEW} style={{ width: 160 }}>
          Saved
        </Button>
      ) : (
        <Button
          variant={ButtonVariant.FILLED}
          onClick={props.onSave}
          disabled={props.saveDisabled}
          loading={props.isUpdating}
          style={{ width: 160 }}
        >
          Save Changes
        </Button>
      )}
    </Row>
  )
}
