import { useReducer } from 'react'

export interface Filters {
  regionId: string
}

interface SetFiltersAction {
  type: 'SET_FILTERS'
  filters: Filters
}

interface SetRegionAction {
  type: 'SET_REGION'
  regionId: Filters['regionId']
}

export type FiltersAction = SetFiltersAction | SetRegionAction

function filtersReducer(state: Filters, action: FiltersAction): Filters {
  switch (action.type) {
    case 'SET_FILTERS': {
      return action.filters
    }
    case 'SET_REGION': {
      return {
        ...state,
        regionId: action.regionId,
      }
    }
    default: {
      return state
    }
  }
}

export default function useInvoiceFilters(
  initialFilters: Filters = { regionId: '' },
) {
  return useReducer(filtersReducer, initialFilters)
}
