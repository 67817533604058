import {
  LoadingSpinner,
  Text,
  WorkersManagementTable,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerOnSchedule } from '@traba/types'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from 'src/context/appContext/AppContext'
import useMobile from 'src/hooks/useMobile'
import { useWorkersOnSchedule } from 'src/hooks/useWorkersOnSchedule'
import { EmptyPlaceholderTile, Row } from '../base'
import { RemoveWorkersFromScheduleModal } from './RemoveWorkersFromScheduleModal'

interface Props {
  shiftRequestParentId: string
}

export const ScheduleDetailsWorkerManagement = ({
  shiftRequestParentId,
}: Props) => {
  const navigate = useNavigate()
  const {
    state: { isChatWidgetOpen, selectedWorkerId },
    setIsChatWidgetOpen,
  } = useAppContext()
  const { isMobileViewOrReactNative } = useMobile()
  const [selectedWorkerIds, setSelectedWorkerIds] = useState<string[]>([])
  const [selectedShiftRequestId, setSelectedShiftRequestId] = useState<string>()
  const [showRemoveWorkersModal, setShowRemoveWorkersModal] = useState(false)
  const {
    scheduledWorkers,
    isLoading,
    refetch: refetchWorkersOnSchedule,
  } = useWorkersOnSchedule(shiftRequestParentId, {})

  const shiftRequestWorkerMap: {
    [key: string]: WorkerOnSchedule[]
  } = useMemo(() => {
    const shiftRequestWorkerMap: {
      [key: string]: WorkerOnSchedule[]
    } = {}
    scheduledWorkers?.forEach((worker) => {
      if (!worker.longTermShiftRequestId) {
        return
      }
      if (!shiftRequestWorkerMap[worker.longTermShiftRequestId]) {
        shiftRequestWorkerMap[worker.longTermShiftRequestId] = []
      }
      shiftRequestWorkerMap[worker.longTermShiftRequestId].push(worker)
    })
    return shiftRequestWorkerMap
  }, [scheduledWorkers])

  const workersByShiftRequest = useMemo(() => {
    return Object.entries(shiftRequestWorkerMap)
  }, [shiftRequestWorkerMap])

  const onClickWorker = (workerId: string) => {
    navigate(`/workers/${workerId}`)
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  const onRemoveWorkers = (workerIds: string[], shiftRequestId: string) => {
    setSelectedWorkerIds(workerIds)
    setSelectedShiftRequestId(shiftRequestId)
    setShowRemoveWorkersModal(true)
  }

  const onCloseModal = () => {
    setShowRemoveWorkersModal(false)
  }

  const onClickMessageWorker = (workerId: string) => {
    setIsChatWidgetOpen(true, workerId)
  }

  return (
    <>
      <Row alignEnd gap={theme.space.xxs}>
        <Text variant="h4">Workers</Text>
        <Text variant="h6" color={theme.colors.Grey60}>
          on this schedule
        </Text>
      </Row>
      {workersByShiftRequest.length === 0 ? (
        <EmptyPlaceholderTile
          title="No workers found on this schedule"
          iconName="workers"
        />
      ) : (
        workersByShiftRequest.map(([_, workers]) => (
          <WorkersManagementTable
            scheduledWorkers={workers}
            onClickWorker={onClickWorker}
            isFromOpsConsole={false}
            onRemoveWorkers={onRemoveWorkers}
            onClickMessageWorker={onClickMessageWorker}
            isChatWidgetOpen={isChatWidgetOpen}
            selectedWorkerId={selectedWorkerId}
            isMobileViewOrReactNative={isMobileViewOrReactNative}
          />
        ))
      )}
      <RemoveWorkersFromScheduleModal
        shiftRequestId={selectedShiftRequestId}
        workerIds={selectedWorkerIds}
        showRemoveWorkersModal={showRemoveWorkersModal}
        onCloseModal={onCloseModal}
        refetchWorkersOnSchedule={refetchWorkersOnSchedule}
      />
    </>
  )
}
