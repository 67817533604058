import React from 'react'
import { Text } from 'src/components/base'

import { TextVariant } from '../base/Text'
import * as S from './BorderlessCard.styles'

export interface BorderlessCardProps {
  children?: JSX.Element | JSX.Element[] | string
  title?: string
  rightContent?: JSX.Element
  style?: React.CSSProperties
  containerStyle?: React.CSSProperties
  titleVariant?: TextVariant
}

export const BorderlessCard = ({
  children,
  title,
  rightContent,
  style,
  containerStyle,
  titleVariant,
}: BorderlessCardProps) => {
  return (
    <S.BorderlessCardContainer style={containerStyle}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 0',
          ...style,
        }}
      >
        <Text variant={titleVariant ? titleVariant : 'h5'}>{title}</Text>
        {rightContent}
      </div>

      {children}
    </S.BorderlessCardContainer>
  )
}
