import { SvgIconProps } from '@traba/types'

export function Back({
  size,
  color = '#7A8A99',
  strokeWidth = 1.5,
  ...props
}: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M14 8L3 8M3 8L7.58824 12M3 8L7.58824 4"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
