export function BaseChevronDown(props: {
  width?: number
  height?: number
  color?: string
}) {
  const { width = 12, height = 7, color = '#7A8A99' } = props
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0.999999L6 6L1 1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
