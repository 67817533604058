import { theme } from '@traba/theme'
import { IconName } from '@traba/types'
import { Col } from '../Col'
import Row from '../Row'
import { SvgIcon } from '../SvgIcon'
import { Text } from '../Text'

interface BannerWithTitleAndLinkProps {
  title: string
  body?: string
  iconName?: IconName
  linkText?: string
  onClickCta?: () => void
}

export const BannerWithTitleAndLink = ({
  title,
  body,
  iconName = 'notification',
  linkText,
  onClickCta,
}: BannerWithTitleAndLinkProps) => {
  return (
    <Row
      p={theme.space.xs}
      justifyBetween
      alignCenter
      fullWidth
      gap={theme.space.xs}
      style={{
        backgroundColor: theme.colors.Violet10,
        borderRadius: theme.space.xxs,
      }}
    >
      <Row alignCenter gap={theme.space.xs}>
        <Row alignCenter style={{ minWidth: '24px' }}>
          <SvgIcon name={iconName} size={24} />
        </Row>
        <Col gap="2px">
          <Text variant="h5" style={{ fontWeight: 500 }}>
            {title}
          </Text>
          <Text variant="body2" style={{ color: theme.colors.Grey60 }}>
            {body}
          </Text>
        </Col>
      </Row>
      {linkText && onClickCta && (
        <Row gap={theme.space.xxs} alignCenter onClick={onClickCta}>
          <Text variant="link">{linkText}</Text>
          <SvgIcon name="arrowRight" />
        </Row>
      )}
    </Row>
  )
}
