import InfoIcon from '@mui/icons-material/InfoOutlined'
import { IconButton, Tooltip, TooltipProps } from '@mui/material'
import { theme } from '@traba/theme'

interface InfoTooltipProps {
  title: string
  subTitle?: string
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>
}

export const InfoTooltip = (props: InfoTooltipProps) => {
  const { title, subTitle, tooltipProps } = props

  return (
    <Tooltip
      title={
        <div>
          <div>{title}</div>
          {subTitle && <div>{subTitle}</div>}
        </div>
      }
      {...tooltipProps}
    >
      <IconButton style={{ padding: theme.space.xxxs }}>
        <InfoIcon sx={{ fontSize: 14 }} />
      </IconButton>
    </Tooltip>
  )
}
