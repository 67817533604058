import { SvgIconProps } from 'src/types/svg-types'

export default function Lifting({ size = 52, ...props }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 53 53" fill="none" {...props}>
      <circle cx="26.4631" cy="26.9044" r="26" fill="#F5EBFF" />
      <rect
        x="9.23071"
        y="22.874"
        width="34.4648"
        height="2.61088"
        rx="1.30544"
        fill="#6600CC"
      />
      <rect
        x="15.7366"
        y="32.3395"
        width="16.1232"
        height="2.61088"
        rx="1.30544"
        transform="rotate(-90 15.7366 32.3395)"
        fill="#6600CC"
      />
      <rect
        x="12.4905"
        y="29.6252"
        width="10.8915"
        height="2.61088"
        rx="1.30544"
        transform="rotate(-90 12.4905 29.6252)"
        fill="#6600CC"
      />
      <rect
        x="34.3989"
        y="32.241"
        width="16.1232"
        height="2.61088"
        rx="1.30544"
        transform="rotate(-90 34.3989 32.241)"
        fill="#6600CC"
      />
      <rect
        x="38.0056"
        y="29.4285"
        width="10.8915"
        height="2.61088"
        rx="1.30544"
        transform="rotate(-90 38.0056 29.4285)"
        fill="#6600CC"
      />
    </svg>
  )
}
