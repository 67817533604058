import { JobStatus, UpdateSegmentsForWorkerShiftRequest } from '@traba/types'
import { differenceInSeconds, startOfMinute } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import { EditSegment, SegmentError, SegmentErrorProperties } from './types'

const NOT_ROW_SPECIFIC_ERROR = '_none'

export function sortSegments(segments: EditSegment[]): EditSegment[] {
  return segments.slice().sort((a, b) => {
    if (!a.startTime || !b.startTime) {
      return 0
    }
    return a.startTime.getTime() - b.startTime.getTime()
  })
}

export function flattenSegments(segments: EditSegment[]): EditSegment[] {
  return segments.reduce((agg, segment) => {
    if (!agg.length) {
      return [segment]
    }

    const head = agg.slice(0, -1)
    const prevSegment = agg[agg.length - 1]

    if (!segment.startTime && !segment.endTime) {
      return agg
    }

    if (!segment.startTime || !prevSegment.endTime) {
      return [...agg, segment]
    }

    const timeDiff = segment.startTime.getTime() - prevSegment.endTime.getTime()
    if (timeDiff > 60_000) {
      return [...agg, segment]
    }

    if (
      prevSegment.costCenterId === segment.costCenterId &&
      prevSegment.isBreak === segment.isBreak
    ) {
      prevSegment.endTime = segment.endTime
      return [...head, prevSegment]
    }

    return [...agg, segment]
  }, [] as EditSegment[])
}

export function roundSegment(
  clockInTime: Date,
  clockOutTime: Date | undefined,
  segments: EditSegment,
): EditSegment {
  const { startTime, endTime, ...rest } = segments
  const truncatedStart = startTime ? startOfMinute(startTime) : undefined
  const truncatedEnd = endTime ? startOfMinute(endTime) : undefined

  const roundedStartTime =
    truncatedStart === startOfMinute(clockInTime) ? clockInTime : truncatedStart

  const roundedEndTime =
    clockOutTime && truncatedEnd === startOfMinute(clockOutTime)
      ? clockOutTime
      : truncatedEnd

  return { startTime: roundedStartTime, endTime: roundedEndTime, ...rest }
}

export function fillInTheGaps(
  clockInTime: Date,
  clockOutTime: Date | undefined,
  segments: EditSegment[],
): EditSegment[] {
  if (segments.length === 0) {
    return segments
  }

  if (!segments[0].startTime) {
    return segments
  }

  const hasGapBetweenClockInAndFirstSegment =
    differenceInSeconds(
      startOfMinute(segments[0].startTime),
      startOfMinute(clockInTime),
    ) > 0

  const head = hasGapBetweenClockInAndFirstSegment
    ? [
        {
          id: uuidv4(),
          isBreak: false,
          costCenterId: undefined,
          startTime: clockInTime,
          endTime: segments[0].startTime,
        },
      ]
    : []

  if (!hasGapBetweenClockInAndFirstSegment) {
    segments[0].startTime = clockInTime
  }

  const updatedSegments = segments.reduce((agg, segment) => {
    if (!agg.length) {
      return [segment]
    }

    const head = agg.slice(0, -1)
    const prevSegment = agg[agg.length - 1]

    if (!segment.startTime || !prevSegment.endTime) {
      return [...head, prevSegment, segment]
    }

    const timeDiff =
      startOfMinute(segment.startTime).getTime() -
      startOfMinute(prevSegment.endTime).getTime()
    if (timeDiff < 60_000) {
      return [...head, prevSegment, segment]
    }

    const gapSegment: EditSegment = {
      id: uuidv4(),
      isBreak: false,
      costCenterId: undefined,
      startTime: prevSegment.endTime,
      endTime: segment.startTime,
      isUnaccounted: true,
    }

    return [...head, prevSegment, gapSegment, segment]
  }, [] as EditSegment[])

  const lastSegment = updatedSegments[updatedSegments.length - 1]
  const hasGapBetweenLastSegmentAndClockOut =
    clockOutTime &&
    lastSegment.endTime &&
    differenceInSeconds(
      startOfMinute(clockOutTime),
      startOfMinute(lastSegment.endTime),
    ) > 0

  const tail = hasGapBetweenLastSegmentAndClockOut
    ? [
        {
          id: uuidv4(),
          isBreak: false,
          costCenterId: undefined,
          startTime: lastSegment.endTime,
          endTime: clockOutTime,
        },
      ]
    : []

  if (
    clockOutTime &&
    lastSegment.endTime &&
    !hasGapBetweenLastSegmentAndClockOut
  ) {
    lastSegment.endTime = clockOutTime
  }

  return [...head, ...updatedSegments, ...tail]
}

export function validateSegments({
  status,
  clockInTime,
  clockOutTime,
  segments,
}: {
  status: JobStatus
  clockInTime: Date
  clockOutTime?: Date
  segments: EditSegment[]
}): Record<string, SegmentError[]> {
  if (segments.length === 0) {
    return {}
  }

  const invalid: Record<string, SegmentError[]> = {}

  segments
    .sort((a, b) => {
      if (!a.startTime || !b.startTime) {
        return 0
      }
      return a.startTime.getTime() - b.startTime.getTime()
    })
    .reduce((prevSegment, segment) => {
      if (!segment.startTime) {
        return segment
      }

      if (!prevSegment.endTime) {
        invalid[prevSegment.id] = [
          ...(invalid[prevSegment.id] ?? []),
          SegmentError.MISSING_END,
        ]
      } else if (
        differenceInSeconds(segment.startTime, prevSegment.endTime) < 0
      ) {
        invalid[prevSegment.id] = [
          ...(invalid[prevSegment.id] ?? []),
          SegmentError.END_OVERLAPPING,
        ]
        invalid[segment.id] = [
          ...(invalid[segment.id] ?? []),
          SegmentError.START_OVERLAPPING,
        ]
      }

      return segment
    })

  segments.forEach(({ id, startTime, endTime, isBreak }) => {
    const hasEndTimeWithNoStartTime = !startTime && !!endTime
    if (hasEndTimeWithNoStartTime) {
      invalid[id] = [...(invalid[id] ?? []), SegmentError.MISSING_START]
      return
    }

    const startTimeBeforeClockIn =
      startTime && differenceInSeconds(startTime, clockInTime) < 0
    if (startTimeBeforeClockIn) {
      invalid[id] = [...(invalid[id] ?? []), SegmentError.START_BEFORE_CLOCK_IN]
    }

    if (startTime && startTime > new Date()) {
      invalid[id] = [...(invalid[id] ?? []), SegmentError.START_IN_THE_FUTURE]
    }

    if (endTime && endTime > new Date()) {
      invalid[id] = [...(invalid[id] ?? []), SegmentError.END_IN_THE_FUTURE]
    }

    const endTimeAfterClockOut =
      clockOutTime && endTime && differenceInSeconds(endTime, clockOutTime) > 0
    if (endTimeAfterClockOut) {
      invalid[id] = [...(invalid[id] ?? []), SegmentError.END_AFTER_CLOCK_OUT]
    }

    const hasNoTimeSet = !startTime && !endTime
    if (hasNoTimeSet) {
      return
    }

    const hasClockOutButNoEndTime = clockOutTime && !endTime
    if (hasClockOutButNoEndTime) {
      invalid[id] = [...(invalid[id] ?? []), SegmentError.MISSING_END]
    }

    const inProgressBreak = isBreak && !endTime
    if (inProgressBreak && status !== JobStatus.OnBreak) {
      invalid[id] = [
        ...(invalid[id] ?? []),
        SegmentError.WORKER_SHIFT_NOT_ON_BREAK,
      ]
    }

    const isNotFullySet = !startTime || !endTime
    if (isNotFullySet) {
      return
    }

    const minutes = differenceInSeconds(
      startOfMinute(endTime),
      startOfMinute(startTime),
    )
    if (minutes < 0) {
      invalid[id] = [...(invalid[id] ?? []), SegmentError.START_AFTER_END]
    }

    if (
      minutes === 0 &&
      startTime !== clockInTime &&
      endTime !== clockOutTime
    ) {
      invalid[id] = [...(invalid[id] ?? []), SegmentError.ZERO_MINUTE_SEGMENT]
    }
  })

  if (status === JobStatus.OnBreak) {
    const hasInProgressBreak = segments.some(
      ({ isBreak, endTime }) => isBreak && !endTime,
    )

    if (!hasInProgressBreak) {
      invalid[NOT_ROW_SPECIFIC_ERROR] = [
        ...(invalid[NOT_ROW_SPECIFIC_ERROR] ?? []),
        SegmentError.WORKER_SHIFT_ON_BREAK,
      ]
    }
  }

  return invalid
}

export function generateUpdateRequest(
  segments: EditSegment[],
): UpdateSegmentsForWorkerShiftRequest {
  const validSegments = segments
    .filter(
      (s): s is EditSegment & { startTime: Date } => s.startTime !== undefined,
    )
    .map((segment) => ({
      costCenterId: segment.costCenterId,
      startTime: segment.startTime.toISOString(),
      endTime: segment.endTime?.toISOString(),
      isBreak: segment.isBreak,
    }))

  if (validSegments.length !== segments.length) {
    throw new Error('Not all start times are defined')
  }

  return {
    segments: validSegments,
    adjustmentReason: 'Cost Center Timesheet Adjustment',
  }
}

export function isStartTimeInvalid(
  validationErrors: SegmentError[] | undefined,
): boolean {
  if (!validationErrors) {
    return false
  }

  return validationErrors
    .map((err) => SegmentErrorProperties[err])
    .some(({ invalidStart, isWarning }) => invalidStart && !isWarning)
}

export function isEndTimeInvalid(
  validationErrors: SegmentError[] | undefined,
): boolean {
  if (!validationErrors) {
    return false
  }

  return validationErrors
    .map((err) => SegmentErrorProperties[err])
    .some(({ invalidEnd, isWarning }) => invalidEnd && !isWarning)
}

export function shouldWarnOnTime(
  validationErrors: SegmentError[] | undefined,
): boolean {
  if (!validationErrors) {
    return false
  }

  return validationErrors
    .map((err) => SegmentErrorProperties[err])
    .some(({ isWarning }) => isWarning)
}
