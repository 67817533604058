import { makePlural } from '@traba/string-utils'
import { addHours } from 'date-fns'
import { theme } from 'src/libs/theme'

export const START_TIME_TIMEBAR_THRESHOLD_HOURS = 24
export const NO_COLOR_THRESHOLD_HRS = 6
export const NO_COLOR_THRESHOLD_MS = NO_COLOR_THRESHOLD_HRS * 60 * 60 * 1000

export const isInProgressOrStartsInThreshold = (
  shiftStartTime: Date,
  shiftEndTime: Date,
) => {
  const currentTime = new Date()

  const isInProgress =
    shiftStartTime <= currentTime && shiftEndTime >= currentTime

  const isUnderStartThreshold =
    shiftStartTime <= addHours(currentTime, START_TIME_TIMEBAR_THRESHOLD_HOURS)

  return isInProgress || isUnderStartThreshold
}

export const getTimeDeltaString = (
  currentTime: number,
  comparisonTime: number,
  shouldUppercase = false,
) => {
  const timeDiffInMillis = Math.abs(comparisonTime - currentTime)

  const timeDiffInMinutes = Math.floor(timeDiffInMillis / (1000 * 60))
  const timeDiffInHours = Math.floor(timeDiffInMinutes / 60)

  const timeLeftString =
    timeDiffInHours > 0
      ? `${timeDiffInHours} hr${makePlural<number>(timeDiffInHours)}`
      : `${timeDiffInMinutes} min${makePlural<number>(timeDiffInMinutes)}`

  return shouldUppercase ? timeLeftString.toUpperCase() : timeLeftString
}

export const getShiftStatusBadgeConfig = ({
  shiftStartTime,
  shiftEndTime,
  shiftIsCanceled,
  isRecurringSchedulesEnabled,
}: {
  shiftStartTime: Date
  shiftEndTime: Date
  shiftIsCanceled: boolean
  isRecurringSchedulesEnabled?: boolean
}):
  | {
      text: string
      backgroundColor: string
      textColor: string
    }
  | undefined => {
  if (shiftIsCanceled) {
    return {
      text: 'CANCELED',
      backgroundColor: theme.colors.Red10,
      textColor: theme.colors.Red60,
    }
  }

  const currentTime = Date.now()

  const hasShiftStarted = shiftStartTime.getTime() <= currentTime
  const hasShiftEnded = shiftEndTime.getTime() <= currentTime

  if (hasShiftEnded) {
    const text = `ENDED — ${getTimeDeltaString(
      currentTime,
      shiftEndTime.getTime(),
      true, // should uppercase
    )} AGO`
    return {
      text,
      backgroundColor: theme.colors.Green10,
      textColor: theme.colors.Green70,
    }
  }

  if (hasShiftStarted) {
    if (isRecurringSchedulesEnabled) {
      return {
        text: 'IN PROGRESS',
        backgroundColor: theme.palette.primary.main,
        textColor: theme.colors.Violet10,
      }
    }
    const text = `REMAINING — ${getTimeDeltaString(
      currentTime,
      shiftEndTime.getTime(),
      true, // should uppercase
    )}`
    return {
      text,
      backgroundColor: theme.palette.primary.main,
      textColor: theme.colors.Violet10,
    }
  }

  const startingInText = `STARTING — IN ${getTimeDeltaString(
    currentTime,
    shiftStartTime.getTime(),
    true, // should uppercase
  )}`

  // Shifts starting in <= 6hrs
  if (shiftStartTime.getTime() - currentTime <= NO_COLOR_THRESHOLD_MS) {
    return {
      text: startingInText,
      backgroundColor: theme.colors.Orange10,
      textColor: theme.colors.Orange60,
    }
  }

  // Other shifts starting in > 6hrs
  return {
    text: startingInText,
    backgroundColor: theme.colors.Grey10,
    textColor: theme.colors.Grey60,
  }
}
