import { Text } from './Text'

export function ToText() {
  return (
    <Text
      variant="body1"
      className="xs-hide sm-show"
      style={{
        textAlign: 'center',
      }}
    >
      to
    </Text>
  )
}
