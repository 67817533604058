import { TrabaTheme } from 'src/libs/theme'
import { css } from 'styled-components'

/**
 * Apply this function for styling based on device width breakpoints.
 *
 * Breakpoints are defined in src/libs/theme and default to:
 * xs: 481px
 * sm: 600px
 * md: 768px
 * lg: 992px
 * xl: 1200px
 */

const NUM_COLUMNS = 12

function createColumnSelectors(breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl') {
  let styles = ''
  for (let i = 1; i <= NUM_COLUMNS; i += 1) {
    styles += `
      .${breakpoint}-${i} {
        width: ${100 * (i / NUM_COLUMNS)}%;
        display: flex;
      }
    `
  }
  return css`
    ${styles}
  `
}

export function useGrid(props: { theme: TrabaTheme }) {
  const { theme } = props

  return css`
    .xs-hide {
      display: none;
    }
    .xs-show {
      display: flex;
    }
    ${createColumnSelectors('xs')}

    @media only screen and (min-width: ${theme.media.minSmall}) {
      .sm-hide {
        display: none;
      }
      .sm-show {
        display: flex;
      }
      ${createColumnSelectors('sm')}
    }

    @media only screen and (min-width: ${theme.media.minMedium}) {
      .md-hide {
        display: none;
      }
      .md-show {
        display: flex;
      }
      ${createColumnSelectors('md')}
    }

    @media only screen and (min-width: ${theme.media.minLarge}) {
      .lg-hide {
        display: none;
      }
      .lg-show {
        display: flex;
      }
      ${createColumnSelectors('lg')}
    }

    @media only screen and (min-width: ${theme.media.minExtraLarge}) {
      .xl-hide {
        display: none;
      }
      .xl-show {
        display: flex;
      }

      ${createColumnSelectors('xl')}
    }
  `
}
