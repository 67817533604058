import { InputStatus } from '@traba/types'
import { useState } from 'react'
import { theme } from 'src/libs/theme'

import { Required } from '../Required'
import SvgIcon from '../SvgIcon'
import * as S from './Input.styles'
import { InputProps } from './InputProps'

export default function Input(props: InputProps) {
  const {
    onChange,
    onBlur,
    onKeyPress,
    placeholder,
    value,
    name,
    maxLength,
    type,
    inputStatus,
    className,
    required,
    pattern,
    disabled,
    errorMessage,
    rows,
    width,
    style,
    errorStyle,
    containerStyle,
    onClear,
    leftIconName,
  } = props
  const [isVisible, setIsVisible] = useState(props.type !== 'password')
  const isValid = typeof props.value === 'string' && props.value.length

  return (
    <>
      <S.InputContainer style={{ ...containerStyle, width: width }}>
        {type === 'textarea' ? (
          <S.MultiLineInput
            className={`${isValid ? 'valid' : ''} ${className ?? ''}`}
            name={name}
            placeholder={placeholder}
            style={{
              ...style,
              width: '100%',
              paddingRight: theme.space.med,
              resize: 'vertical',
              minHeight: style?.minHeight || theme.space.xl,
            }}
            rows={rows}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        ) : (
          <S.Input
            {...props}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            value={value}
            maxLength={maxLength}
            type={
              isVisible && props.type === 'password'
                ? 'text'
                : props.type || 'text'
            }
            className={`${isValid ? 'valid' : ''} ${className ?? ''}`}
            pattern={pattern}
            style={{
              ...props.style,
              width: '100%',
              paddingRight: theme.space.med,
              paddingLeft: leftIconName ? theme.space.med : undefined,
            }}
            inputStatus={inputStatus}
            disabled={disabled}
            {...(props.type === 'password' && {
              'data-dd-privacy': 'mask-user-input',
            })}
          />
        )}
        {leftIconName && (
          <S.LeftIconWrapper>
            <SvgIcon
              name={leftIconName}
              color={theme.colors.Grey40}
              strokeWidth={1}
            />
          </S.LeftIconWrapper>
        )}
        {type === 'password' && (
          <S.RightIconWrapper onClick={() => setIsVisible(!isVisible)}>
            <SvgIcon
              name={isVisible ? 'eye' : 'eyeSlash'}
              color={theme.colors.Grey50}
            />
          </S.RightIconWrapper>
        )}
        {onClear && (
          <S.RightIconWrapper onClick={onClear}>
            <SvgIcon
              name="cancel"
              color={theme.colors.Grey40}
              strokeWidth={1}
            />
          </S.RightIconWrapper>
        )}

        <S.Label inputStatus={inputStatus}>
          {props.label}
          <Required required={required} inputStatus={inputStatus} />
        </S.Label>
      </S.InputContainer>
      {inputStatus === InputStatus.error ? (
        <S.StyledInputError message={errorMessage} style={errorStyle} />
      ) : null}
    </>
  )
}
