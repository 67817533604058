import { SvgIconProps } from '@traba/types'

export default function HeartHollow({ size = 16, ...props }: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.15593 5.74037L4.57865 10.3404C5.56366 11.6643 7.43633 11.6643 8.42135 10.3404L11.8441 5.74037C12.7186 4.56496 12.7186 2.89477 11.8441 1.71936C10.5638 -0.00127584 7.60474 0.509754 6.5 2.36569C5.39526 0.509754 2.43619 -0.0012756 1.15593 1.71936C0.281357 2.89477 0.281355 4.56496 1.15593 5.74037Z"
        fill="white"
        stroke="#9933FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
