import { WorkerImg, WorkerImgContainer } from '@traba/react-components'
import { CardTile } from '@traba/react-components'
import { HTMLProps } from 'react'
import SvgIcon from 'src/components/base/SvgIcon'
import { DualColumnLayout } from 'src/components/DualColumnLayout/DualColumnLayout'
import { theme } from 'src/libs/theme'

export function ModalWithImage({
  children,
  handleClose,
  imgSrc = '/img/worker-with-boxes.png',
  imgStyle,
  hideIcon,
}: {
  children: React.ReactNode
  handleClose: () => void
  imgSrc?: string
  imgStyle?: HTMLProps<HTMLImageElement>['style']
  hideIcon?: boolean
}) {
  return (
    <>
      <DualColumnLayout
        rightBasis="37%"
        rightClassName="xs-hide md-show"
        leftColumn={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: theme.space.xl,
            }}
          >
            {!hideIcon && <CardTile size="56px">🚀</CardTile>}
            {children}
          </div>
        }
        rightColumn={
          <WorkerImgContainer>
            <WorkerImg src={imgSrc} alt="Traba workers" style={imgStyle} />
          </WorkerImgContainer>
        }
        rightStyle={{
          backgroundColor: '#ECF8F3',
          flexShrink: 0,
          margin: '3px',
          borderRadius: '16px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
      <div
        onClick={handleClose}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          right: theme.space.med,
          top: theme.space.med,
        }}
        id="modal-close-icon"
      >
        <SvgIcon name="cancel" size={18} color={theme.colors.MidnightBlue} />
      </div>
    </>
  )
}
