import { theme } from '@traba/theme'
import { ShiftRequestParentWithShiftRequest } from '@traba/types'
import { ScheduleTile } from './ScheduleTile/ScheduleTile'

interface SchedulesListProps {
  shiftRequestParents: ShiftRequestParentWithShiftRequest[]
}

export const SchedulesList: React.FC<SchedulesListProps> = ({
  shiftRequestParents,
}) => {
  return (
    <div style={{ marginTop: theme.space.sm }}>
      {shiftRequestParents
        .filter(
          (shiftRequestParent: ShiftRequestParentWithShiftRequest) =>
            shiftRequestParent.shiftRequests &&
            shiftRequestParent.shiftRequests.length >= 1,
        )
        .map((shiftRequestParent: ShiftRequestParentWithShiftRequest) => {
          return (
            <ScheduleTile
              key={shiftRequestParent.shiftRequestParentId}
              shiftRequestParent={shiftRequestParent}
            />
          )
        })}
    </div>
  )
}
