import { CardTile } from '@traba/react-components'
import { Button, ButtonVariant, Col, Icon } from 'src/components/base'
import { Text } from 'src/components/base/Text'
import { theme } from 'src/libs/theme'
import {
  BILLING_SUPPORT_EMAIL,
  BUSINESS_SUPPORT_NUMBER,
} from 'src/utils/supportUtils'

export function SupportModalContent(props: {
  stripeInvoiceNumber?: string | null
  handleClose: () => void
}) {
  const mailToText = props.stripeInvoiceNumber
    ? `?subject=Inquiry about Invoice #${props.stripeInvoiceNumber}&body=Hello, \n I'm writing about invoice #${props.stripeInvoiceNumber} from Traba.`
    : ``

  return (
    <Col
      style={{
        padding: theme.space.xl,
        minWidth: 'min(528px, 100%)',
      }}
    >
      <CardTile size="56px" style={{ alignSelf: 'center' }}>
        ☎️
      </CardTile>
      <Text
        variant="h3"
        style={{ marginTop: theme.space.med, marginBottom: theme.space.xs }}
      >
        Contact Support
      </Text>
      <Text variant="body2" style={{ marginBottom: theme.space.xl }}>
        Our support team is at your disposal. Email us or send a text at{' '}
        <span style={{ fontWeight: 500 }}>{BUSINESS_SUPPORT_NUMBER}</span> and
        we will get back to you soon.
      </Text>
      <a
        style={{ color: 'white', textDecoration: 'none', width: '100%' }}
        href={`mailto:${BILLING_SUPPORT_EMAIL}${mailToText}`}
      >
        <Button
          full
          style={{ margin: '4px 0' }}
          leftIcon={<Icon name="email_small" />}
        >
          Email Us
        </Button>
      </a>
      <Button
        full
        style={{ margin: '4px 0' }}
        variant={ButtonVariant.OUTLINED}
        onClick={props.handleClose}
      >
        Close
      </Button>
    </Col>
  )
}
