import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { Region } from '@traba/types'
import { useQuery } from 'react-query'

const getRegions = async (companyId?: string) => {
  try {
    const path = companyId
      ? `/companies/${companyId}/location-regions`
      : `/my-company/location-regions`
    const response = await trabaApi.get(path)
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const useLocationRegions = (companyId?: string) => {
  const {
    isLoading,
    isError,
    data: regions,
    error,
    isFetched,
  } = useQuery<Region[], Error>(['location-regions', companyId], () =>
    getRegions(companyId),
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    regions,
  }
}
