import { getPayUnitString } from 'src/utils/moneyUtils'
import { truncateString } from 'src/utils/stringUtils'

import { Divider, Text } from '..'
import * as S from './CostSummary.styles'
import { FunnyMoney } from './FunnyMoney'
import { CostSummaryProps } from '.'

export const ShiftPayLineItems = (
  props: CostSummaryProps & {
    payRateFixed: string
    workerPay: number | number[]
  },
) => {
  const { payRateFixed, workerPay, shift } = props

  // truncate markup string to 5 characters, e.g. 28.99%
  const truncatedMarkupPercent = truncateString(
    `${shift.calculatedMarkup * 100}`,
    5,
  )

  return (
    <div>
      <S.SummaryRow>
        <S.SummaryRowItem>
          WORKER CHARGE (${payRateFixed}
          {getPayUnitString(props.shift.payType)})
          {props.workerPaySubtext ? ` ${props.workerPaySubtext}` : null}
        </S.SummaryRowItem>
        <S.SummaryRowValue>
          <FunnyMoney key="worker-pay" amount={workerPay} />
        </S.SummaryRowValue>
      </S.SummaryRow>
      <Divider />
      <S.SummaryRow>
        <S.SummaryRowItem>{`MARKUP (${truncatedMarkupPercent}%)`}</S.SummaryRowItem>
        <S.SummaryRowValue>
          <FunnyMoney key="markup" amount={props.serviceFee} />
        </S.SummaryRowValue>
      </S.SummaryRow>
      <Divider />
      <S.SummaryRow>
        <Text variant="h4">TOTAL</Text>
        <S.SummaryRowValue>
          <FunnyMoney
            key="estimated-cost"
            amount={props.estimatedCost}
            superSize
          />
        </S.SummaryRowValue>
      </S.SummaryRow>
      <Text variant="body2">
        {props.summary
          ? props.summary
          : `*The final approximate cost is based on the potential number of
            workers, the number of shifts and the pay rate.`}
      </Text>
    </div>
  )
}
