import { SvgIconProps } from '@traba/types'

export default function Eye({ size, color, ...rest }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M3.9074 8.65134L3.26013 8.27246H3.26013L3.9074 8.65134ZM20.0926 8.65134L20.7399 8.27246V8.27246L20.0926 8.65134ZM20.0926 15.3487L19.4453 14.9698L20.0926 15.3487ZM3.9074 15.3487L4.55466 14.9698L3.9074 15.3487ZM4.55466 9.03021C7.89524 3.32326 16.1048 3.32326 19.4453 9.03021L20.7399 8.27246C16.82 1.57585 7.18 1.57585 3.26013 8.27246L4.55466 9.03021ZM19.4453 9.03021C20.5182 10.8631 20.5182 13.1369 19.4453 14.9698L20.7399 15.7275C22.0867 13.4266 22.0867 10.5734 20.7399 8.27246L19.4453 9.03021ZM19.4453 14.9698C16.1048 20.6767 7.89523 20.6767 4.55466 14.9698L3.26013 15.7275C7.18 22.4242 16.82 22.4242 20.7399 15.7275L19.4453 14.9698ZM4.55466 14.9698C3.48178 13.1369 3.48178 10.8631 4.55466 9.03021L3.26013 8.27246C1.91329 10.5734 1.91329 13.4266 3.26013 15.7275L4.55466 14.9698ZM14.8067 12.0607C14.8067 13.6528 13.5387 14.9233 11.9994 14.9233V16.4233C14.3887 16.4233 16.3067 14.4595 16.3067 12.0607H14.8067ZM11.9994 14.9233C10.4605 14.9233 9.19331 13.6531 9.19331 12.0607H7.69331C7.69331 14.4592 9.60988 16.4233 11.9994 16.4233V14.9233ZM9.19331 12.0607C9.19331 10.467 10.4606 9.19699 11.9994 9.19699V7.69699C9.60973 7.69699 7.69331 9.66125 7.69331 12.0607H9.19331ZM11.9994 9.19699C13.5385 9.19699 14.8067 10.4673 14.8067 12.0607H16.3067C16.3067 9.66094 14.3888 7.69699 11.9994 7.69699V9.19699Z"
        fill={color}
      />
    </svg>
  )
}
