import { LoadingSpinner } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { theme } from '@traba/theme'
import { UserRolePermission, WorkerShiftWithCharges } from '@traba/types'
import { endOfDay, startOfDay } from 'date-fns'
import { useState } from 'react'
import {
  Button,
  ButtonVariant,
  Col,
  Icon,
  Row,
  SvgIcon,
  Text,
} from 'src/components/base'
import { AppScreen } from 'src/hooks/useMobile'
import {
  useTimeSheetMutations,
  useWorkerShiftsForTimesheetWithPagination,
} from 'src/hooks/useTimesheet'
import { useUserPermissions } from 'src/hooks/useUser'
import { TimesheetsPage, TimesheetsGroupBy } from 'src/screens/TimeSheets/types'
import { getWorkerShiftStringId } from 'src/utils/workerShiftUtils'
import {
  ExpandableShiftTileCard,
  ShiftTileWorkerListContainer,
} from '../ShiftTile/ShiftTile.styles'
import { TimeSheetsTable } from '../TimeSheetsTable/TimeSheetsTable'
import {
  ChargeApprovalAction,
  TimeSheetsTableLoggingSource,
} from '../TimeSheetsTable/types'
import {
  chargeIsApprovable,
  chargeIsUnapprovable,
} from '../TimeSheetsTable/utils'
interface Props {
  locationName: string
  activeLocationId: string
  numShifts: number
  numWorkerShifts: number
  tabIndex: number
  timePeriod: [Date | null, Date | null]
  refetchSummary: () => void
  userCanManageTimesheets: boolean
}

export const DesktopTimeSheetApprovalLocationTile = (props: Props) => {
  const {
    locationName,
    numShifts,
    numWorkerShifts,
    tabIndex,
    timePeriod,
    activeLocationId,
    refetchSummary,
    userCanManageTimesheets,
  } = props
  const [isExpanded, setIsExpanded] = useState(false)
  const isPendingTab = tabIndex === 0

  const query = {
    activeLocationIds: [activeLocationId],
    after: timePeriod[0] ? startOfDay(timePeriod[0]) : undefined,
    before: timePeriod[1] ? endOfDay(timePeriod[1]) : undefined,
    groupBy: TimesheetsGroupBy.WORKER,
    hideApproved: isPendingTab,
    hideUnapproved: !isPendingTab,
    enabled: isExpanded,
  }

  const userCanManagePaymentSettings = useUserPermissions([
    UserRolePermission.ManagePaymentSettings,
  ])

  const {
    data: response,
    refetch: refetchWorkerShifts,
    isLoading: isLoadingWorkerShifts,
  } = useWorkerShiftsForTimesheetWithPagination(query, {
    limit: numWorkerShifts,
    startAt: 0,
  })

  const data = response?.result
  const workerShiftWithCharges = data?.flatMap(
    (item: { workerShifts: WorkerShiftWithCharges[] }) => item.workerShifts,
  )
  const { approveWorkerShifts, unapproveWorkerShifts } = useTimeSheetMutations()
  const refetch = () => {
    refetchWorkerShifts()
    refetchSummary()
  }

  const handleBulkChargeApprovalAction = async (
    action: ChargeApprovalAction,
  ) => {
    if (!workerShiftWithCharges) {
      return
    }

    if (action === ChargeApprovalAction.APPROVE) {
      approveWorkerShifts(
        workerShiftWithCharges
          .filter(chargeIsApprovable)
          .map((ws: WorkerShiftWithCharges) => ({
            workerId: ws.workerId,
            shiftId: ws.shiftId,
          })),
      )
    } else {
      unapproveWorkerShifts(
        workerShiftWithCharges
          .filter(chargeIsUnapprovable)
          .map((ws: WorkerShiftWithCharges) => ({
            workerId: ws.workerId,
            shiftId: ws.shiftId,
          })),
      )
    }

    window.analytics.track(`Timesheets Approval ${action} All Clicked`, {
      workerShiftWithCharges: workerShiftWithCharges.map(
        (workerShiftWithCharge: WorkerShiftWithCharges) =>
          getWorkerShiftStringId(
            workerShiftWithCharge.workerId,
            workerShiftWithCharge.shiftId,
          ),
      ),
      activeLocationId,
      page: TimesheetsPage.APPROVAL,
      screen: AppScreen.DESKTOP,
    })
    refetch()
  }

  const handleApproveAllWorkerShifts = () =>
    handleBulkChargeApprovalAction(ChargeApprovalAction.APPROVE)

  const handleUnapproveAllWorkerShifts = () =>
    handleBulkChargeApprovalAction(ChargeApprovalAction.UNAPPROVE)

  const chargesAreNotActionable =
    isExpanded && (isLoadingWorkerShifts || !data?.length)
  const isApproveAllDisabled =
    chargesAreNotActionable || !workerShiftWithCharges?.some(chargeIsApprovable)
  const isUnapproveAllDisabled =
    chargesAreNotActionable ||
    !workerShiftWithCharges?.some(chargeIsUnapprovable)

  return (
    <>
      <ExpandableShiftTileCard
        isExpanded={isExpanded}
        style={{ marginTop: theme.space.sm }}
      >
        <Row
          py={theme.space.sm}
          px={theme.space.xs}
          justifyBetween
          onClick={() => {
            window.analytics.track(
              'Timesheets Approval Location Tile clicked',
              {
                expanded: !isExpanded,
                screen: AppScreen.DESKTOP,
                activeLocationId,
                page: TimesheetsPage.APPROVAL,
              },
            )
            setIsExpanded((isExpanded) => !isExpanded)
          }}
          style={{ cursor: 'pointer' }}
          wrap
        >
          <Row alignCenter>
            <Col>
              <Row alignCenter>
                <Icon name="location" width={20} height={20} />
                <Text variant="h6" ml={theme.space.xxxs}>
                  {locationName}
                </Text>
              </Row>
            </Col>
            <Col mx={theme.space.xs}>
              <Row alignCenter>
                <Icon name="company" width={20} height={20} />
                <Text
                  variant="h6"
                  ml={theme.space.xxxs}
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >{`${numShifts} shift${makePlural(numShifts)}`}</Text>
              </Row>
            </Col>
            <Col mx={theme.space.xs}>
              <Row alignCenter>
                <Icon name="two_user" width={20} height={20} />
                <Text variant="h6" ml={theme.space.xxxs} mr={theme.space.xxs}>
                  {`${numWorkerShifts} worker${makePlural(numWorkerShifts)} time${makePlural(numWorkerShifts)} ${isPendingTab ? 'unapproved' : 'approved'}`}
                </Text>
                <Icon
                  name={isExpanded ? 'chevronUp' : 'chevronDown'}
                  width={16}
                  height={16}
                />
              </Row>
            </Col>
          </Row>
          <Row alignCenter>
            {isExpanded && isPendingTab && userCanManageTimesheets && (
              <Button
                rightIcon={<SvgIcon name="check" />}
                variant={ButtonVariant.BRANDLINK}
                iconPadding={`${theme.space.xxs}px`}
                disabled={isApproveAllDisabled}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  handleApproveAllWorkerShifts()
                }}
              >
                {'Approve All'}
              </Button>
            )}
            {isExpanded && !isPendingTab && userCanManagePaymentSettings && (
              <Button
                rightIcon={
                  <SvgIcon
                    name="cancel"
                    color={
                      isUnapproveAllDisabled
                        ? theme.colors.Grey40
                        : theme.colors.red
                    }
                  />
                }
                variant={ButtonVariant.ERROR}
                iconPadding={`${theme.space.xxs}px`}
                disabled={isUnapproveAllDisabled}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  handleUnapproveAllWorkerShifts()
                }}
              >
                {'Unapprove All'}
              </Button>
            )}
          </Row>
        </Row>
      </ExpandableShiftTileCard>
      {isExpanded && (!!data?.length || isLoadingWorkerShifts) && (
        <ShiftTileWorkerListContainer>
          {isLoadingWorkerShifts ? (
            <LoadingSpinner />
          ) : (
            <TimeSheetsTable
              groupBy={TimesheetsGroupBy.WORKER}
              data={data}
              refetchData={refetch}
              approveWorkerShifts={approveWorkerShifts}
              unapproveWorkerShifts={unapproveWorkerShifts}
              isLoading={isLoadingWorkerShifts}
              editMode={false}
              hideHeaderRow={true}
              hideTotalsRow={true}
              hideEmptyState={true}
              stickyApprovalsColumn={isPendingTab}
              loggingContext={{
                source: TimeSheetsTableLoggingSource.TIMESHEETS_APPROVAL_SCREEN,
                screen: AppScreen.DESKTOP,
              }}
            />
          )}
        </ShiftTileWorkerListContainer>
      )}
    </>
  )
}
