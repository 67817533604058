import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { Worker, WorkerShift } from '@traba/types'
import { useMemo } from 'react'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { useSelectedRegionalFilterLocations } from './useRegionalFilter'

const COMPANY_WORKERS_QUERY_KEY = 'companyWorkerByIds'

export type ShiftHistory = {
  mostRecentShift?: WorkerShift
  mostRecentShiftId?: string
  mostRecentShiftStart?: Date
  totalShiftCount: number
  totalMinutesWorked?: number
  locationIds: string[]
}

export type WorkerDetails = {
  worker: Worker
  shiftHistory: ShiftHistory
}

async function getCompanyWorkersByIds({
  workerIds,
  excludeWorkerIds,
  excludeInvalidStatusWorkers,
}: {
  workerIds?: string[]
  excludeWorkerIds?: string[]
  excludeInvalidStatusWorkers?: boolean
}): Promise<WorkerDetails[] | undefined> {
  try {
    const res = await trabaApi.get(`my-company/workers`, {
      params: {
        workerIds: workerIds,
        excludeWorkerIds,
        excludeInvalidStatusWorkers,
      },
    })
    return res.data
  } catch (error) {
    console.error('useWorker -> getCompanyWorkersByIds() ERROR', error)
  }
}

// Returns workers who have worker shifts associated with this company
export function useCompanyWorkersByIds({
  workerIds,
  excludeWorkerIds,
  isEnabled = true,
  excludeInvalidStatusWorkers,
}: {
  workerIds?: string[]
  excludeWorkerIds?: string[]
  isEnabled?: boolean
  excludeInvalidStatusWorkers?: boolean
} = {}) {
  const { selectedLocationIds } = useSelectedRegionalFilterLocations()
  const shouldFetchWorkers = workerIds ? !!workerIds.length : true

  const {
    isLoading,
    isError,
    data: workers = [],
    error,
    isFetched,
  } = useQuery<WorkerDetails[] | undefined, Error>({
    queryKey: [
      COMPANY_WORKERS_QUERY_KEY,
      workerIds,
      excludeWorkerIds,
      excludeInvalidStatusWorkers,
    ],
    queryFn: () => {
      return getCompanyWorkersByIds({
        workerIds,
        excludeWorkerIds,
        excludeInvalidStatusWorkers,
      })
    },
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: isEnabled && shouldFetchWorkers,
  })

  const workersMap = useMemo(
    () => new Map(workers.map((worker) => [worker.worker.uid, worker])),
    [workers],
  )

  // Ideally this can be filtered in the backend,
  // *however* currently *worker metrics* & *total past shifts are calculated in company level.
  // Therefore, it can cause confusion if we show part of the locations for the worker but company wide metrics.
  // --> the endpoint could use more thought but for now filtering here will be sufficient for our purposes.
  const workersFilteredBySelectedLocations = useMemo(() => {
    return workers.filter((worker) => {
      const workedLocationIds = worker.shiftHistory.locationIds
      return (
        // Show workers who haven't worked anywhere yet or worked in selected location
        workedLocationIds.length === 0 ||
        workedLocationIds.some((locationId) =>
          selectedLocationIds.has(locationId),
        )
      )
    })
  }, [workers, selectedLocationIds])

  return {
    isLoading,
    isError,
    workers,
    workersFilteredBySelectedLocations,
    workersMap,
    error,
    isFetched,
  }
}
