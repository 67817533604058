import { LoadingSpinner } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftRequestParentWithShiftRequest, UserRole } from '@traba/types'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { MainLayout, Row, Text } from 'src/components'
import { EstimatedTotal } from 'src/components/EstimatedTotal/EstimatedTotal'
import { useCompany } from 'src/hooks/useCompany'
import { useLocations } from 'src/hooks/useLocations'
import { useMembers } from 'src/hooks/useMembers'
import { useRoles } from 'src/hooks/useRoles'
import { useShiftRequestParent } from 'src/hooks/useShiftRequestParent'
import { useUser } from 'src/hooks/useUser'
import { ScheduleEditType } from '../BookShifts/BookShiftsScreen.hooks'
import { StartOverButton } from '../BookShifts/components/StartOverButton'
import { useAddToExistingSchedulesScreen } from './AddToExistingScheduleScreen.hooks'
import * as S from './AddToExistingScheduleScreen.styles'
import { AddToExistingScheduleOptions } from './steps/SelectAddToExistingScheduleOptions'

export default function AddToExistingScheduleScreen() {
  const { shiftRequestParentId = '' } = useParams<{
    shiftRequestParentId: string
  }>()

  const { isLoading: isLoadingMembers } = useMembers()
  const { isLoading: isLoadingRoles } = useRoles()
  const { isLoading: isLoadingLocations } = useLocations()

  const { shiftRequestParent, isLoading: isLoadingShiftRequestParent } =
    useShiftRequestParent(shiftRequestParentId)

  return (
    <MainLayout hideSidebar title="Add to Existing Schedule">
      {isLoadingShiftRequestParent ||
      !shiftRequestParent ||
      isLoadingMembers ||
      isLoadingRoles ||
      isLoadingLocations ? (
        <LoadingSpinner />
      ) : (
        <AddToExistingScheduleScreenUI
          shiftRequestParent={shiftRequestParent}
        />
      )}
    </MainLayout>
  )
}

function AddToExistingScheduleScreenUI({
  shiftRequestParent,
}: {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
}) {
  const { user } = useUser()
  const { company } = useCompany()

  const {
    steps,
    activeStep,
    completedThroughStep,
    scrollContainer,
    onNavigate,
    onContinue,
    onPrevious,
    onBook,
    shiftRequest,
    setShiftRequest,
    shiftRequestMetadata,
    setShiftRequestMetadata,
    recurringRoles,
    setRecurringRoles,
    updateRoleInfoForCreateShiftRequest,
    addNewRole,
    getWorkerById,
    removeRole,
  } = useAddToExistingSchedulesScreen({ shiftRequestParent })

  const [selectedOption, setSelectedOption] =
    useState<AddToExistingScheduleOptions>(
      AddToExistingScheduleOptions.ADD_SINGLE_DAY_TO_SCHEDULE,
    )

  const isLimitedShiftCreator = user?.role === UserRole.LimitedShiftCreator

  const confirmContent =
    activeStep !== steps.length - 1 ? null : (
      <EstimatedTotal
        onBook={onBook}
        shiftRequest={shiftRequest}
        shiftRequestMetadata={shiftRequestMetadata}
        setShiftRequestMetadata={setShiftRequestMetadata}
        userCanViewPay={!isLimitedShiftCreator} // only limited shift creators can be on book shifts page but cant see pay
        recurringRoles={recurringRoles}
      />
    )

  return (
    <S.BookShiftsContainer>
      {/* STEP TITLE */}
      <div className="xs-hide md-show md-3">
        <S.BookShiftsNavContainer>
          <S.BookShiftsNav>
            {steps.map((s, i) => (
              <S.BookShiftsNavTitle
                key={i}
                active={i === activeStep}
                disabled={i > completedThroughStep + 1}
                onClick={() => onNavigate(i)}
                style={{ ...(s.hideInSideBar ? { display: 'none' } : {}) }}
              >
                {i === activeStep ? '• ' : ''}
                {s.title}
              </S.BookShiftsNavTitle>
            ))}
          </S.BookShiftsNav>
        </S.BookShiftsNavContainer>
      </div>

      {/* STEP CONTENT */}
      <div className="xs-12 md-9" style={{ position: 'relative' }}>
        <div ref={scrollContainer} style={{ width: '100%', overflow: 'auto' }}>
          <S.BookShiftsContent>
            {steps.map(({ Component }, i) =>
              Component ? (
                <S.BookShiftsComponentContainer
                  key={i}
                  active={activeStep === i}
                >
                  {/* MOBILE ONLY */}
                  {activeStep !== steps.length - 1 ? (
                    <Row justifyBetween alignCenter mb={theme.space.xs}>
                      <Text variant="h4" className="xs-show md-hide">
                        Add to Existing Schedule
                      </Text>
                      <StartOverButton />
                    </Row>
                  ) : null}

                  {/* MAIN CONTENT */}
                  <Component
                    scheduleEditType={ScheduleEditType.ADD_TO_EXISTING}
                    onContinue={onContinue}
                    onPrevious={onPrevious}
                    onNavigate={onNavigate}
                    shiftRequestParent={shiftRequestParent}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    onBook={onBook}
                    shiftRequest={shiftRequest}
                    setShiftRequest={setShiftRequest}
                    recurringRoles={recurringRoles}
                    setRecurringRoles={setRecurringRoles}
                    updateRoleInfoForCreateShiftRequest={
                      updateRoleInfoForCreateShiftRequest
                    }
                    addNewRole={addNewRole}
                    shiftRequestMetadata={shiftRequestMetadata}
                    setShiftRequestMetadata={setShiftRequestMetadata}
                    userHasLimitedShiftManagementAbility={isLimitedShiftCreator}
                    showRequiredMultiShiftToggle={
                      company?.showRequiredMultiShiftToggle
                    }
                    showGenderPreferenceSelection={
                      company?.allowGenderPreference
                    }
                    getWorkerById={getWorkerById}
                    removeRole={removeRole}
                  />

                  {/* MEDIUM DOWN */}
                  {confirmContent ? (
                    <S.BookShiftsConfirmBlockBottom>
                      {confirmContent}
                    </S.BookShiftsConfirmBlockBottom>
                  ) : null}
                </S.BookShiftsComponentContainer>
              ) : null,
            )}
          </S.BookShiftsContent>
        </div>

        {/* LARGE */}
        {confirmContent ? (
          <S.BookShiftsConfirmBlockRight>
            {confirmContent}
          </S.BookShiftsConfirmBlockRight>
        ) : null}
      </div>
    </S.BookShiftsContainer>
  )
}
