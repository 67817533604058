import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { BizUserSetting, updateUserSettingsRequest } from '@traba/types'
import { formatNotificationData } from '@traba/utils'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useBusinessUser } from './useBusinessUser'

export async function updateBusinessUserSettings(
  updatedUserSettings: updateUserSettingsRequest,
  userId?: string,
) {
  try {
    const path = userId ? `/users/${userId}/user-settings` : `/user-settings`
    await trabaApi.patch(path, updatedUserSettings)
  } catch (error) {
    console.error(error)
  }
}

async function getBusinessUserSettings(
  businessUserId?: string,
): Promise<BizUserSetting[] | undefined> {
  try {
    const path = businessUserId
      ? `/users/${businessUserId}/user-settings`
      : `/user-settings`
    const res = await trabaApi.get(path)
    return res.data
  } catch (error) {
    console.error(error)
  }
}

export function useBusinessUserSettings(businessUserId?: string) {
  const { user } = useBusinessUser(businessUserId)
  const {
    isLoading,
    isError,
    data: userSettings,
    error,
    isFetched,
    refetch,
  } = useQuery<BizUserSetting[] | undefined, Error>(
    [`user-settings`, businessUserId],
    () => getBusinessUserSettings(businessUserId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  const {
    userShiftEmailsSettings,
    userInvoiceEmailsSettings,
    userShiftTextsSettings,
    emailsSettingsMap,
    textSettingsMap,
    hasAtLeastOneOff,
    isAllOff,
  } = useMemo(
    () => formatNotificationData(userSettings, user?.role),
    [userSettings, user?.role],
  )

  return {
    isLoading,
    isError,
    userShiftEmailsSettings,
    userInvoiceEmailsSettings,
    userShiftTextsSettings,
    emailsSettingsMap,
    textSettingsMap,
    hasAtLeastOneOff,
    isAllOff,
    error,
    isFetched,
    refetch,
  }
}
