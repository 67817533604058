import { DEFAULT_PAGE_SIZE } from '@traba/consts'
import { IMenuItem, Input, Row, SelectDropdown } from '@traba/react-components'
import { theme } from '@traba/theme'
import React from 'react'

import useMobile from 'src/hooks/useMobile'
import { TimeSheetsWorkerShiftResponse } from 'src/hooks/useTimesheet'
import { Pagination } from '../base/Pagination'

const FILTER_MIN_WIDTH = 160
const FILTER_MAX_WIDTH = 200

interface WorkerNameSearchProps {
  additionalWorkerActions?: JSX.Element
  resetPagination: () => void
  setFirstName: (newState: string) => void
  setLastName: (newState: string) => void
  setSupervisorId?: (newState: string) => void
  firstName: string
  lastName: string
  supervisorId?: string
  page: number
  doPageLeft: () => void
  doPageRight: () => void
  response: TimeSheetsWorkerShiftResponse | undefined
  cachedSupervisorNames?: IMenuItem[]
}

export const WorkerNameSearch = (props: WorkerNameSearchProps) => {
  const {
    additionalWorkerActions,
    resetPagination,
    setFirstName,
    setLastName,
    setSupervisorId,
    supervisorId,
    firstName,
    lastName,
    page,
    doPageLeft,
    doPageRight,
    response,
    cachedSupervisorNames,
  } = props
  const { isMobileViewOrReactNative } = useMobile()

  return (
    <Row
      gap={theme.space.xxs}
      alignCenter
      mb={theme.space.xs}
      justifyBetween
      mt={theme.space.xxs}
      wrap={true}
    >
      <Row>
        <Input
          type="text"
          containerStyle={{
            marginRight: theme.space.xs,
            marginTop: 0,
            width: '20%',
            minWidth: FILTER_MIN_WIDTH,
            maxWidth: FILTER_MAX_WIDTH,
          }}
          onChange={(e) => {
            resetPagination()
            setFirstName(e.target.value)
          }}
          value={firstName}
          placeholder="Worker first name"
          leftIconName="search"
        />
        <Input
          type="text"
          containerStyle={{
            marginRight: theme.space.xs,
            marginTop: 0,
            width: '20%',
            minWidth: FILTER_MIN_WIDTH,
            maxWidth: FILTER_MAX_WIDTH,
          }}
          onChange={(e) => {
            resetPagination()
            setLastName(e.target.value)
          }}
          value={lastName}
          placeholder="Worker last name"
          leftIconName="search"
        />
        {setSupervisorId && (
          <SelectDropdown
            label={'Onsite contact'}
            value={supervisorId}
            menuItems={cachedSupervisorNames || []}
            handleSelect={(value) => {
              setSupervisorId(value)
              resetPagination()
            }}
            style={{ width: 200 }}
          />
        )}
      </Row>
      <Row justifyEnd fullWidth={isMobileViewOrReactNative}>
        {additionalWorkerActions ? additionalWorkerActions : null}
        <Pagination
          page={page}
          pageSize={DEFAULT_PAGE_SIZE}
          onPageLeft={doPageLeft}
          onPageRight={doPageRight}
          dataSize={response?.count ?? 0}
        />
      </Row>
    </Row>
  )
}
