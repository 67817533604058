import { useHotSettings } from '@traba/hooks'
import { JobStatus, ScheduledBreak } from '@traba/types'
import { WorkerShiftWithWorkerDetails } from '@traba/types'
import {
  calculateBreaksInMinutes,
  calculateClockedInTimeInSeconds,
  calculatedScheduledBreaksInMinutes,
  getScheduledBreaksTimeString,
  getTotalBreaksTimeString,
} from '@traba/utils'
import { differenceInMinutes } from 'date-fns'
import { useMemo, useState } from 'react'
import {
  Button,
  ButtonVariant,
  InlineBanner,
  Row,
  Text,
} from 'src/components/base'
import { NoTextDivider } from 'src/components/base/Divider'
import { BreakTimeInput } from 'src/components/WorkersOnShiftTable/components/BreakTimeInput'
import { theme } from 'src/libs/theme'

import * as S from './UpdateTimeSheetModal.styles'

export type EditBreaksModalProps = {
  handleClose: () => void
  onConfirm: (x: number) => void
  workerShifts: WorkerShiftWithWorkerDetails[]
  scheduledBreaks: ScheduledBreak[]
  forIndividualWorkerShift?: WorkerShiftWithWorkerDetails
}

export const EditBreaksModal = (props: EditBreaksModalProps) => {
  const {
    handleClose,
    onConfirm,
    workerShifts,
    scheduledBreaks,
    forIndividualWorkerShift,
  } = props
  const { hotSettings } = useHotSettings()
  const scheduledBreakTime = calculatedScheduledBreaksInMinutes(scheduledBreaks)
  const [newBreakTime, setNewBreakTime] = useState(scheduledBreakTime)
  const [validInput, setValidInput] = useState(true)
  const maxBreakTime = useMemo(
    () =>
      Math.min(
        ...workerShifts.map((ws) =>
          ws.jobStatus !== JobStatus.Complete
            ? differenceInMinutes(
                new Date(),
                new Date(ws.clockInTime ?? ws.shiftInfo.startTime),
              )
            : Math.floor(calculateClockedInTimeInSeconds(ws) / 60),
        ),
      ),
    [workerShifts],
  )

  const calculatedBreaksInMinutes = calculateBreaksInMinutes(
    workerShifts[0],
    hotSettings?.enableWorkerEditTime,
  )
  const currentBreakTime =
    workerShifts.length === 1 && calculatedBreaksInMinutes
      ? calculatedBreaksInMinutes
      : scheduledBreakTime
  const currentBreakTimeString =
    workerShifts.length === 1 && calculatedBreaksInMinutes
      ? getTotalBreaksTimeString({
          workerShift: workerShifts[0],
          displayHours: true,
          enableWorkerEditTime: hotSettings?.enableWorkerEditTime,
        })
      : getScheduledBreaksTimeString(scheduledBreaks)

  return (
    <S.UpdateTimeSheetModalContainer>
      <Row alignCenter style={{ marginBottom: `${theme.space.xs}px` }}>
        <Text variant="h4">
          {' '}
          {`Edit breaks for ${
            forIndividualWorkerShift
              ? `${forIndividualWorkerShift.worker.firstName} ${forIndividualWorkerShift.worker.lastName}`
              : 'all workers'
          }`}
        </Text>
      </Row>
      <NoTextDivider />
      <InlineBanner
        text={`This shift has ${scheduledBreakTime} mins of unpaid scheduled break time.${
          forIndividualWorkerShift
            ? ''
            : ` Editing the break time will impact the break times for all workers in this shift.`
        }`}
        style={{ marginTop: `${theme.space.xs}px` }}
      />
      <Row
        style={{
          marginTop: `${theme.space.xs}px`,
          alignItems: 'center',
          gap: theme.space.xs,
        }}
      >
        <Text variant="caption">{'BREAK TIME'}</Text>
        <BreakTimeInput
          onUpdate={(x) => setNewBreakTime(x)}
          onValidate={(valid) => setValidInput(valid)}
          currentBreakTime={currentBreakTime}
          currentBreakTimeString={currentBreakTimeString}
          maxBreakTime={maxBreakTime}
          isMultiEdit={true}
        />
      </Row>
      <NoTextDivider
        style={{
          marginTop: `${theme.space.xs}px`,
        }}
      />
      <Row
        justifyBetween
        style={{ marginTop: theme.space.xs, gap: theme.space.xxs }}
      >
        <Button
          variant={ButtonVariant.OUTLINED}
          style={{ margin: `${theme.space.xxs / 2}px 0`, width: '200px' }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant={ButtonVariant.FILLED}
          style={{ margin: `${theme.space.xxs / 2}px 0`, width: '200px' }}
          onClick={async () => {
            onConfirm(newBreakTime)
            handleClose()
          }}
          disabled={!validInput}
        >
          Confirm
        </Button>
      </Row>
    </S.UpdateTimeSheetModalContainer>
  )
}
