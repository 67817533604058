import { SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Row } from 'src/components'
import { useLocations } from 'src/hooks/useLocations'

interface ScheduleTileLocationProps {
  locationId: string
}

export const ScheduleTileLocation: React.FC<ScheduleTileLocationProps> = ({
  locationId,
}) => {
  const { getLocationById } = useLocations()
  return (
    <Row
      justifyBetween
      alignCenter
      style={{
        padding: theme.space.sm,
        borderBottom: `1px solid ${theme.colors.Grey20}`,
      }}
    >
      <Row alignCenter>
        <SvgIcon
          name={'location'}
          color={theme.colors.MidnightBlue}
          size={20}
        />
        <Text style={{ marginLeft: theme.space.xxs }} variant="h6">
          {getLocationById(locationId)?.name}
        </Text>
      </Row>
    </Row>
  )
}
