import { ChatMessageDeliveryStatus } from '@traba/types'
import { Row, SvgIcon, Text } from 'src/components/base'
import CircleCheck from 'src/components/svgs/CircleCheck'
import { BULLET_CHAR } from 'src/libs/constants'
import { theme } from 'src/libs/theme'

interface Props {
  deliveryStatus: ChatMessageDeliveryStatus
  isFromChatWidget?: boolean
}

const MessageItemStatus = ({ deliveryStatus, isFromChatWidget }: Props) => {
  switch (deliveryStatus) {
    case ChatMessageDeliveryStatus.UNDELIVERED:
      return (
        <Row alignCenter>
          <Text
            variant={isFromChatWidget ? 'body3' : 'body2'}
            mr={theme.space.ms}
          >
            {BULLET_CHAR}
          </Text>
          <SvgIcon name="clock" size={16} color={theme.colors.Grey60} />
        </Row>
      )
    case ChatMessageDeliveryStatus.MODERATED_EXPLICIT:
      return (
        <Row alignCenter>
          <Text
            variant={isFromChatWidget ? 'body3' : 'body2'}
            mr={theme.space.ms}
          >
            {BULLET_CHAR}
          </Text>
          <SvgIcon name="info" size={16} color={theme.colors.Red50} />
        </Row>
      )
    default:
      return (
        <Row alignCenter>
          <Text
            variant={isFromChatWidget ? 'body3' : 'body2'}
            mr={theme.space.ms}
          >
            {BULLET_CHAR}
          </Text>
          <CircleCheck
            name="circleCheck"
            size={14}
            checkColor={theme.colors.Green70}
            color={theme.colors.Green70}
          />
        </Row>
      )
  }
}

export default MessageItemStatus
