export enum WebToRNEventName {
  LOGGED_IN = 'Logged in',
  WEBVIEW_READY = 'Webview ready',
  SIGN_UP_COMPLETE = 'Sign up complete',
  SHIFT_BOOKED = 'Shift booked',
  SHIFT_BOOKING_START_OVER = 'Shift booking start over',
  FULL_SCREEN_MODAL_OPEN = 'Full screen modal open',
  FULL_SCREEN_MODAL_CLOSE = 'Full screen modal close',
  RELOAD_WEBVIEW_SCREENS = 'Reload webview screens',
}

export enum RNToWebEventName {
  LOG_IN = 'Log in',
  LOG_OUT = 'Log out',
}

export interface EventType {
  event: WebToRNEventName | RNToWebEventName
  token?: string
  email?: string
}
