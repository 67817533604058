import { FileType } from '@traba/hooks'
import { ModalPaddingContainer } from '@traba/react-components'
import React from 'react'
import { Row, Text } from 'src/components/base'
import { UploadVideo } from 'src/components/UploadVideo/UploadVideo'
import { theme } from 'src/libs/theme'

import { ButtonIcon } from '../../PersonalProfile/PersonalProfile.styles'

export type UploadVideoModalProps = {
  fileType: FileType
  onClose: () => void
  onUploadSuccess?: (videoId: string) => void
}

export const UploadVideoModal = (props: UploadVideoModalProps) => {
  return (
    <ModalPaddingContainer>
      <Row justifyBetween style={{ marginBottom: theme.space.med }}>
        <Text variant="h2">Upload training video</Text>
        <ButtonIcon name="cancel" onClick={props.onClose} />
      </Row>
      <UploadVideo {...props} />
    </ModalPaddingContainer>
  )
}
