import styled from 'styled-components'

import { Row } from '../base'

export const ClockInAndOutTooltipContainer = styled.div`
  color: ${({ theme }) => theme.colors.White};
  box-shadow: 1px 2px 5px 3px rgba(0, 0, 0, 0.25);
  border-radius: ${({ theme }) => theme.space.xxs}px;
`

export const ClockInAndOutTooltipHeader = styled(Row)`
  padding: ${({ theme }) => theme.space.xs}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey20};
`

export const DatePickerContainer = styled.div`
  padding: ${({ theme }) => theme.space.xs}px;
  max-height: 320px;
  width: 100%;
`

export const ClockInAndOutTooltipFooter = styled(Row)`
  padding: ${({ theme }) => theme.space.xs}px;
  border-top: 1px solid ${({ theme }) => theme.colors.Grey20};
`
