import { LoadingSpinner } from '@traba/react-components'
import { AddAnnouncementSection } from 'src/components/UpcomingShiftDetails/components/AddAnnouncementSection'
import { useLocations } from 'src/hooks/useLocations'
import { useShiftById } from 'src/hooks/useShifts'

interface Props {
  shiftId: string
  onSubmitOrCancel: () => void
}

export const ScheduleDailyViewAddAnnouncementForm = (props: Props) => {
  const { shiftId, onSubmitOrCancel } = props
  const { shift, isLoading } = useShiftById(shiftId)
  const { getLocationById } = useLocations()

  if (isLoading || !shift) {
    return <LoadingSpinner />
  }

  return (
    <AddAnnouncementSection
      shift={shift}
      onSubmitOrCancel={onSubmitOrCancel}
      location={getLocationById(shift.locationId)}
      parkingLocation={
        shift.parkingLocationId
          ? getLocationById(shift.parkingLocationId)
          : undefined
      }
    />
  )
}
