import { WorkerShiftWithWorkerDetails } from '@traba/types'
import {
  ClockInClockOutButton,
  EditableTimeInput,
} from 'src/components/ClockInOutButton/clockInOutButton'

import { WorkerAction } from 'src/components/WorkersManagementMenu/WorkersManagementMenu'
import { WorkersOnShiftTableProps } from '../WorkersOnShiftTable.hooks'

interface Props
  extends Pick<
    WorkersOnShiftTableProps,
    'clockInWorkers' | 'editClockInWorkers' | 'clockOutWorkers'
  > {
  workerShift: WorkerShiftWithWorkerDetails
  timezone: string
  showEditableTimeInput: boolean
  onEditWorkerShift?: (
    newWorkerShift: Partial<WorkerShiftWithWorkerDetails> & {
      workerId: string
      shiftId: string
    },
  ) => void
  refetchWorkerShifts: () => void
  isFromTimesheetDetails: boolean | undefined
}

export const WorkerOnShiftTableClockOutField = ({
  showEditableTimeInput,
  workerShift,
  timezone,
  clockInWorkers,
  clockOutWorkers,
  editClockInWorkers,
  onEditWorkerShift,
  refetchWorkerShifts,
  isFromTimesheetDetails,
}: Props) => {
  return showEditableTimeInput ? (
    EditableTimeInput({
      value:
        workerShift.clockOutTime ||
        workerShift.clockOutTimeBeforeWorkerEdit ||
        null,
      timezone,
      startDate: workerShift.shiftInfo.startTime,
      endDate: workerShift.shiftInfo.endTime,
      onChange: (newDate) => {
        onEditWorkerShift &&
          onEditWorkerShift({
            workerId: workerShift.workerId,
            shiftId: workerShift.shiftId,
            clockOutTime: newDate,
          })
      },
    })
  ) : (
    <ClockInClockOutButton
      clockInWorkers={clockInWorkers}
      clockOutWorkers={clockOutWorkers}
      editClockInWorkers={editClockInWorkers}
      workerShift={workerShift}
      timezone={timezone}
      workerAction={WorkerAction.ClockOut}
      refetchWorkerShifts={refetchWorkerShifts}
      isFromTimesheetDetails={isFromTimesheetDetails}
    />
  )
}
