import { Card, LoadingSpinner, SvgIcon } from '@traba/react-components'
import { Pill } from '@traba/react-components'
import {
  RoleAttribute,
  RoleAttributeCategory,
  WorkerProfile,
  WorkerProfileStatus,
  WorkerCertificationStatus,
  CompanyCategory,
  CompanyCategoryValues,
  CompanyCategoryIcon,
} from '@traba/types'
import { format } from 'date-fns'
import React from 'react'

import { useRoleAttributes } from 'src/hooks/useAttributes'
import { useHotSettings } from 'src/hooks/useSystem'
import { useWorkerAccountStatus } from 'src/hooks/useWorkerAccountStatus'
import { useWorkerProfileCertsRosters } from 'src/hooks/useWorkerProfile'
import { useWorkerRegion } from 'src/hooks/useWorkerRegion'
import { useWorkerScores } from 'src/hooks/useWorkerScores'
import { useWorkerWorkHistory } from 'src/hooks/useWorkerWorkHistory'
import { theme } from 'src/libs/theme'
import { IconName } from 'src/types/svg-types'
import { Col, Divider, Row, Text } from '../base'
import { IndustryBadge } from '../base/WorkerBadge/IndustryBadge'
import { getWorkerBadges, WorkerBadge } from '../base/WorkerBadge/WorkerBadge'
import { HighlyRatedSection } from './HighlyRatedSection'

export type WorkProfileProps = {
  workerId: string
}

export function WorkProfile({ workerId }: WorkProfileProps) {
  const { roleAttributes, isLoading: roleAttributesAreLoading } =
    useRoleAttributes(undefined, true)
  const { isLoading: scoresAreLoading, workerScores } =
    useWorkerScores(workerId)
  const { isLoading: regionIsLoading, region } = useWorkerRegion(workerId)
  const { isLoading: historyIsLoading, workHistory } =
    useWorkerWorkHistory(workerId)
  const profilesCertsRosters = useWorkerProfileCertsRosters(workerId)
  const {
    completedTrainings,
    profile: workerProfiles,
    isLoading: profileIsLoading,
    worker,
    workerTrainingStatus,
  } = profilesCertsRosters
  const certifications = profilesCertsRosters.certifications
    ?.filter((cert) => cert.status === WorkerCertificationStatus.Approved)
    .sort(
      (a, b) =>
        (b.createdAt?.getUTCMilliseconds() || 0) -
        (a.createdAt?.getUTCMilliseconds() || 0),
    )
  const { hotSettings, isLoading: hotSettingsAreLoading } = useHotSettings()
  const { workerAccountStatus, isLoading: accountStatusIsLoading } =
    useWorkerAccountStatus(workerId)

  const WORK_HISTORY_MAX = 6

  if (
    !workerScores ||
    scoresAreLoading ||
    !worker ||
    !region ||
    regionIsLoading ||
    !workHistory ||
    historyIsLoading ||
    !certifications ||
    !completedTrainings ||
    !workerProfiles ||
    profileIsLoading ||
    !hotSettings ||
    hotSettingsAreLoading ||
    !workerAccountStatus ||
    accountStatusIsLoading ||
    !workerTrainingStatus ||
    roleAttributesAreLoading ||
    !roleAttributes
  ) {
    return <LoadingSpinner />
  }
  const certificationsMap = new Map(
    hotSettings.certifications.map((certification) => [
      certification.type,
      certification,
    ]),
  )
  const trainings = [
    ...(workerAccountStatus.isTrainingShiftComplete
      ? [
          {
            name: 'Training Shift',
            description:
              'Helps workers ramp up with the Traba App and prepare for future shifts.',
          },
        ]
      : []),
    ...(workerTrainingStatus.some((training) => training.training)
      ? [
          {
            name: 'Good Manufacturing Practices Awareness Education',
            description:
              'Ensures workers adhere to quality and safety standards in regulated industries.',
          },
        ]
      : []),
  ]
  const roleAttributesMap = new Map<string, RoleAttribute>(
    roleAttributes.map((roleAttribute) => [roleAttribute.type, roleAttribute]),
  )

  function getWorkProfilesForCategory(category: RoleAttributeCategory) {
    if (!workerProfiles) {
      return []
    }
    return workerProfiles.filter((profile) => {
      if (profile.status === WorkerProfileStatus.Revoked) {
        return false
      }
      if (profile.category !== category) {
        return false
      }
      if (profile.profileField === 'gmp') {
        return false // we handle gmp in another section
      }
      return true
    })
  }
  const warehousingSkills = getWorkProfilesForCategory(
    RoleAttributeCategory.Responsibilities,
  )
  const manufacturingSkills = getWorkProfilesForCategory(
    RoleAttributeCategory.MANUFACTURING_AND_PRODUCTION,
  )
  const equipmentSkills = getWorkProfilesForCategory(
    RoleAttributeCategory.Equipment,
  )
  const liftingSkills = getWorkProfilesForCategory(
    RoleAttributeCategory.Lifting,
  )
  const languagesSkills = getWorkProfilesForCategory(
    RoleAttributeCategory.Languages,
  )
  const skillsSections: {
    iconName: IconName
    sectionName: string
    skills: WorkerProfile[]
  }[] = [
    ...(warehousingSkills.length > 0
      ? [
          {
            iconName: 'warehousing' as IconName,
            sectionName: 'Warehousing',
            skills: warehousingSkills,
          },
        ]
      : []),
    ...(manufacturingSkills.length > 0
      ? [
          {
            iconName: 'manuAndProd' as IconName,
            sectionName: 'Manufacturing & Production',
            skills: manufacturingSkills,
          },
        ]
      : []),
    ...(equipmentSkills.length > 0
      ? [
          {
            iconName: 'equipment' as IconName,
            sectionName: 'Equipment',
            skills: equipmentSkills,
          },
        ]
      : []),
    ...(liftingSkills.length > 0
      ? [
          {
            iconName: 'lifting' as IconName,
            sectionName: 'Lifting',
            skills: liftingSkills,
          },
        ]
      : []),
    ...(languagesSkills.length > 0
      ? [
          {
            iconName: 'language' as IconName,
            sectionName: 'Languages',
            skills: languagesSkills,
          },
        ]
      : []),
  ]

  const aboutWorkerSection = (
    <Col>
      <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
        About {worker.firstName}
      </Text>
      <Row style={{ alignItems: 'center' }}>
        <SvgIcon
          name="location"
          color={theme.colors.MidnightBlue}
          style={{ marginRight: theme.space.xxxs }}
        />
        <Text style={{ marginLeft: theme.space.xxxs }}>
          {region.displayName}
        </Text>
      </Row>
    </Col>
  )

  const workerBadgesItems = getWorkerBadges({
    profile: workerProfiles,
    completedShifts: workerScores.numberOfCompletions,
    onTimeRate: workerScores.onTimeArrivalRate,
    perfectShiftsStreak: workerScores.perfectShiftsStreak,
  })

  const workerBadgesSection =
    workerBadgesItems.length > 0 ? (
      <>
        <Divider
          wrapperStyle={{
            marginTop: theme.space.sm,
            marginBottom: theme.space.sm,
          }}
        />
        <Text variant="h5" style={{ marginBottom: theme.space.xxxs }}>
          Compliment & Badges
        </Text>
        <Row
          style={{
            flexWrap: 'wrap',
            rowGap: theme.space.xxs,
            marginBottom: theme.space.xxs,
          }}
        >
          {workerBadgesItems.map((item, i) => {
            return <WorkerBadge key={i} {...item} />
          })}
        </Row>
      </>
    ) : undefined

  const attributesSection =
    workerScores.highlyRatedRoleAttributes.length > 0 ||
    workerScores.highlyRatedBehaviorAttributes.length > 0 ? (
      <>
        {workerBadgesItems.length === 0 && (
          <Divider
            wrapperStyle={{
              marginTop: theme.space.sm,
              marginBottom: theme.space.sm,
            }}
          />
        )}
        <Col>
          <Col style={{ alignItems: 'start' }}>
            <HighlyRatedSection
              highlyRatedBehaviorAttributes={
                workerScores.highlyRatedBehaviorAttributes
              }
              highlyRatedRoleAttributes={workerScores.highlyRatedRoleAttributes}
            />
          </Col>
        </Col>
      </>
    ) : undefined

  function getSkills(skills: WorkerProfile[]) {
    if (!workerProfiles) {
      return
    }

    return (
      <Row
        style={{
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          gap: theme.space.xxs,
        }}
      >
        {skills.map((profile) => {
          const roleAttribute = roleAttributesMap.get(profile.profileField)
          if (!roleAttribute) {
            return null
          }

          return (
            <Pill key={roleAttribute.type}>
              <Text
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  overflowWrap: 'break-word',
                  display: 'block',
                  textOverflow: 'ellipsis',
                  lineClamp: 1,
                  maxLines: 1,
                  marginRight: theme.space.xxxs,
                }}
              >
                {roleAttribute.displayName}
              </Text>
              <SvgIcon name="check" size={12} />
            </Pill>
          )
        })}
      </Row>
    )
  }

  function getSkillSection({
    iconName,
    sectionName,
    skills,
  }: {
    iconName: IconName
    sectionName: string
    skills: WorkerProfile[]
  }) {
    return (
      <Card
        style={{
          alignItems: 'center',
          width: '352px',
        }}
        key={sectionName}
      >
        <Col
          style={{
            marginBottom: theme.space.xs,
            alignItems: 'center',
          }}
        >
          <SvgIcon name={iconName} size={52} />
          <Text variant="h4">{sectionName}</Text>
        </Col>
        {getSkills(skills)}
      </Card>
    )
  }

  const skillsSection =
    skillsSections.length > 0 ? (
      <>
        <Divider
          wrapperStyle={{
            marginTop: theme.space.sm,
            marginBottom: theme.space.sm,
          }}
        />
        <Text style={{ marginBottom: theme.space.xs }} variant="h5">
          Skills
        </Text>
        <Row
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: theme.space.xs,
          }}
        >
          {skillsSections.map((section) => {
            return getSkillSection(section)
          })}
        </Row>
      </>
    ) : undefined

  const certificationsAndTrainingsSection =
    certifications.length > 0 || trainings.length > 0 ? (
      <>
        <Divider
          wrapperStyle={{
            marginTop: theme.space.sm,
            marginBottom: theme.space.sm,
          }}
        />
        <Text variant="h5" style={{ marginBottom: theme.space.xs }}>
          Certifications & Training
        </Text>
        {certifications.map((certification, i) => {
          const certSetting = certificationsMap.get(
            certification.certificationType,
          )
          if (!certSetting) {
            return undefined
          }
          return (
            <Row
              style={{
                alignItems: 'center',
                marginBottom: theme.space.xs,
              }}
              key={`${certification.certificationType}_${i}`}
            >
              <SvgIcon
                name="certification"
                size={32}
                style={{ marginRight: theme.space.xxs }}
              />
              <Col>
                <Text variant="h6">{certSetting.en.name}</Text>
                <Text variant="body3">
                  {format(certification.issueDate, 'MM/dd/yyyy')}
                </Text>
              </Col>
            </Row>
          )
        })}
        {trainings.map((training, i) => {
          return (
            <Row
              style={{
                alignItems: 'center',
                marginBottom: theme.space.xs,
              }}
              key={`${training}_${i}`}
            >
              <SvgIcon
                name="training"
                size={32}
                style={{ marginRight: theme.space.xxs }}
              />
              <Col>
                <Text variant="h6">{training.name}</Text>
                <Text variant="body3">{training.description}</Text>
              </Col>
            </Row>
          )
        })}
      </>
    ) : undefined

  // Create a map of industries to the number of times they have been worked in
  const industries = new Map<CompanyCategory, number>()
  for (const item of workHistory.history) {
    const industry = item.industry as CompanyCategory
    industries.set(industry, (industries.get(industry) || 0) + 1)
  }

  // Sort industries by the number of times each industry has been worked in
  const sortedIndustries = Array.from(industries.entries()).sort(
    (a, b) => b[1] - a[1],
  )

  const industriesSection =
    sortedIndustries.length > 0 ? (
      <>
        <Divider
          wrapperStyle={{
            marginTop: theme.space.sm,
            marginBottom: theme.space.sm,
          }}
        />
        <Text variant="h5" style={{ marginBottom: theme.space.xs }}>
          Industries
        </Text>
        <Row
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: theme.space.xs,
          }}
        >
          {sortedIndustries.map(([industry]) => (
            <IndustryBadge key={industry} industry={industry} />
          ))}
        </Row>
      </>
    ) : undefined

  const rolesSection =
    workHistory.history.length > 0 ? (
      <>
        <Divider
          wrapperStyle={{
            marginTop: theme.space.sm,
            marginBottom: theme.space.sm,
          }}
        />
        <Text variant="h5" style={{ marginBottom: theme.space.xs }}>
          Roles
        </Text>
        {workHistory.history.slice(0, WORK_HISTORY_MAX).map((item) => {
          return (
            <Col
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: theme.space.xxs,
              }}
              key={item.roleId}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: theme.colors.Violet10,
                  borderRadius: '50%',
                  width: '36px',
                  height: '36px',
                  marginRight: theme.space.xs,
                }}
              >
                <SvgIcon
                  name={
                    item.industry
                      ? CompanyCategoryIcon[item.industry as CompanyCategory]
                      : 'warehousing'
                  }
                  size={24}
                  strokeWidth={1}
                />
              </div>
              <Text variant="h6">
                {item.roleName},{' '}
                {item.industry
                  ? CompanyCategoryValues[item.industry as CompanyCategory]
                  : ''}
              </Text>
            </Col>
          )
        })}
      </>
    ) : undefined

  return (
    <div>
      {aboutWorkerSection}
      {workerBadgesSection}
      {attributesSection}
      {skillsSection}
      {certificationsAndTrainingsSection}
      {industriesSection}
      {rolesSection}
    </div>
  )
}
