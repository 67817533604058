import { DEFAULT_TIMEZONE } from '@traba/consts'
import { ShiftPayType } from '@traba/types'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Divider, Row, Text } from 'src/components/base'
import GenderBadge from 'src/components/GenderBadge'
import {
  certificationName,
  useCertifications,
} from 'src/hooks/useCertifications'
import { useCompany } from 'src/hooks/useCompany'
import { useMembers } from 'src/hooks/useMembers'
import { useTrainingVideos } from 'src/hooks/useTrainingVideos'
import { MEDIA, theme } from 'src/libs/theme'
import { getTrainingVideoText } from 'src/screens/BookShifts/utils'
import { getMoneyString, getPayUnitString } from 'src/utils/moneyUtils'
import { getAddressString } from 'src/utils/stringUtils'

import { ClockCode } from '../../base/ClockCode'
import { ShiftDetailsHeader } from '../../base/UpcomingShiftDetailsHeader/ShiftDetailsHeader'
import { BorderlessCard } from '../../BorderlessCard/BorderlessCard'
import { PointOfContact } from '../../PointOfContact'
import { RoleBadges } from '../../RoleBadges'
import { ShiftBadges } from '../../ShiftBadges'
import { UpcomingShiftDetailsProps } from '../UpcomingShiftDetails.hooks'
import * as S from '../UpcomingShiftDetails.styles'
import { ScheduledBreaksInfo } from './ScheduledBreaksInfo'
import { ShiftDateAndTime } from './ShiftDateAndTime'

export const UpcomingShiftInfo: React.FC<UpcomingShiftDetailsProps> = (
  props: UpcomingShiftDetailsProps,
) => {
  const { showHeader = true } = props
  const {
    confirmationCode,
    clockOutCode,
    endTime,
    attireDescription,
    scheduledBreaks = [],
    shiftRole,
    status,
    requiredCertifications,
    requiredAttributes,
    videoIds,
    payType,
    numberOfUnits,
    payRate,
    genderPreference,
    shiftCreatorId,
  } = props.shift
  const startTime = props.shift.businessStartTime ?? props.shift.startTime
  const { location, parkingLocation, userCanViewWages } = props
  const timezone = location?.timezone || DEFAULT_TIMEZONE
  const { certifications } = useCertifications()
  const { company } = useCompany()
  const { trainingVideos } = useTrainingVideos()
  const { getMemberById } = useMembers()

  // Traba Operations Team leaves behind a null for the shiftCreatorId
  // A deleted user will still leave behind their id
  const creatorEmail = shiftCreatorId
    ? getMemberById(shiftCreatorId)?.email || 'Unknown User'
    : 'Traba Operations Team'

  const isLargeScreen = useMediaQuery({
    query: `(min-width: ${MEDIA.LARGE}px)`,
  })

  const shouldShowGenderBadge =
    company?.allowGenderPreference && !!genderPreference

  const header = showHeader ? (
    <>
      <Row justifyBetween style={{ alignItems: 'flex-start' }}>
        <Row alignCenter>
          <Text variant="h2">{shiftRole}</Text>
          <ShiftBadges
            status={status}
            requiredCertifications={requiredCertifications}
          />
        </Row>
        <div className="xs-hide lg-show">
          <ShiftDetailsHeader
            shift={props.shift}
            userCanManageShift={props.userCanManageShift}
          />
        </div>
      </Row>
      {requiredAttributes && (
        <RoleBadges requiredAttributes={requiredAttributes} />
      )}
      <div style={{ marginTop: theme.space.xxs }}>
        <ShiftDateAndTime
          startTime={startTime}
          endTime={endTime}
          timezone={timezone}
        />
      </div>
      <div className="xs-show lg-hide" style={{ marginTop: theme.space.xs }}>
        <ShiftDetailsHeader
          shift={props.shift}
          userCanManageShift={props.userCanManageShift}
        />
      </div>
      <Divider
        wrapperStyle={{
          marginTop: isLargeScreen ? theme.space.med : theme.space.sm,
          marginBottom: isLargeScreen ? theme.space.med : theme.space.sm,
        }}
      />
    </>
  ) : undefined
  return (
    <div>
      {header}
      <Text variant="h4">Shift Details</Text>
      <S.ShiftDetailsSectionContainer>
        <div>
          <BorderlessCard
            title={'Clock in code'}
            containerStyle={{
              paddingTop: theme.space.xxs,
              paddingBottom: theme.space.xxs,
            }}
          >
            <ClockCode code={confirmationCode} />
          </BorderlessCard>

          <BorderlessCard
            title={'Clock out code'}
            containerStyle={{
              paddingTop: theme.space.xxs,
              paddingBottom: theme.space.xxs,
            }}
          >
            <ClockCode code={clockOutCode} />
          </BorderlessCard>
        </div>

        <div style={{ paddingTop: theme.space.sm }}>
          {location?.address && (
            <>
              <Text variant="caption">WORK SITE ADDRESS</Text>
              <Text variant="body1">{location.name}</Text>
              <Text variant="body1">{getAddressString(location.address)}</Text>
            </>
          )}
          {parkingLocation?.address && (
            <>
              <Text variant="caption" style={{ paddingTop: theme.space.sm }}>
                PARKING ADDRESS
              </Text>
              <Text variant="body1">{parkingLocation.name}</Text>
              <Text variant="body1">
                {getAddressString(parkingLocation.address)}
              </Text>
            </>
          )}
          {props.pointOfContact?.firstName ? (
            <>
              <Text variant="caption" style={{ paddingTop: theme.space.sm }}>
                ON-SITE POINT OF CONTACT
              </Text>
              <PointOfContact user={props.pointOfContact} slim />
            </>
          ) : null}
          {userCanViewWages && (
            <>
              <Text variant="caption" style={{ paddingTop: theme.space.xs }}>
                PAY RATE
              </Text>
              <Text variant="body1">
                {getMoneyString(payRate)}
                {getPayUnitString(payType)}
              </Text>
            </>
          )}
          {userCanViewWages && (
            <>
              <Text variant="caption" style={{ paddingTop: theme.space.xs }}>
                POSTED BY
              </Text>
              <Text variant="body1">{creatorEmail}</Text>
            </>
          )}
          {payType === ShiftPayType.UNIT && (
            <>
              <Text variant="caption" style={{ paddingTop: theme.space.xs }}>
                UNITS
              </Text>
              <Text variant="body1">{numberOfUnits}</Text>
            </>
          )}
        </div>

        <div style={{ paddingTop: theme.space.sm }}>
          <Text variant="caption">BREAKS</Text>
          <Text variant="body1">
            <ScheduledBreaksInfo scheduledBreaks={scheduledBreaks} />
          </Text>
          <Text variant="caption" style={{ paddingTop: theme.space.sm }}>
            CERTIFICATIONS
          </Text>
          <Text variant="body1">
            {requiredCertifications?.length && certifications
              ? requiredCertifications
                  .map((rc) => certificationName(rc, certifications))
                  .join(', ')
              : 'None'}
          </Text>
          {shouldShowGenderBadge && (
            <>
              <Text variant="caption" style={{ paddingTop: theme.space.sm }}>
                GENDER PREFERENCE
              </Text>
              <GenderBadge gender={genderPreference} />
            </>
          )}
          <Text variant="caption" style={{ paddingTop: theme.space.sm }}>
            REQUIRED ATTIRE
          </Text>
          <Text variant="body1">{attireDescription}</Text>
          {videoIds && (
            <>
              <Text variant="caption" style={{ paddingTop: theme.space.sm }}>
                REQUIRED TRAINING VIDEOS
              </Text>
              <Text variant="body1">
                {getTrainingVideoText(trainingVideos, videoIds)}
              </Text>
            </>
          )}
        </div>
      </S.ShiftDetailsSectionContainer>
    </div>
  )
}
