import { makePlural } from '@traba/string-utils'
import { theme } from '@traba/theme'
import { RecurringShiftsOutput, Schedule } from '@traba/types'
import { combineRecurringShifts, getRecurringShifts } from '@traba/utils'
import { useMemo } from 'react'
import { Col } from '../base-components/Col'
import Row from '../base-components/Row'
import { SvgIcon } from '../base-components/SvgIcon'
import { Text } from '../base-components/Text'
import { ScheduleDays } from './ScheduleDays'

type ScheduleStripProps = {
  schedules: Schedule[]
  timeToString: (date: Date, showTimezone?: boolean) => string
}

function getIntervalFreq(interval: number, freq: string) {
  if (interval === 1) {
    return freq.toLowerCase()
  } else if (interval === 2) {
    return `bi-${freq.toLowerCase()}`
  }
  return `every ${interval} weeks`
}

export function ScheduleStrip(props: ScheduleStripProps) {
  const { schedules, timeToString } = props

  const [scheduleA, scheduleB] = schedules
  const { startTime, endTime, recurringSchedule } = scheduleA

  const { shiftCount } = useMemo((): RecurringShiftsOutput => {
    if (!recurringSchedule) {
      return {
        shiftCount: 1,
        allShiftDates: [startTime],
        shiftCountWeekly: 1,
      }
    }
    const recurringShiftsA = getRecurringShifts(scheduleA)
    if (scheduleB) {
      const recurringShiftsB = getRecurringShifts(scheduleB)
      return combineRecurringShifts(recurringShiftsA, recurringShiftsB)
    }
    return recurringShiftsA
  }, [scheduleA, scheduleB, recurringSchedule, startTime])

  // Convert startTime and endTime to strings
  const startTimeStr = timeToString(startTime, false)
  const endTimeStr = timeToString(endTime)

  // Convert startTime and endDate to date strings
  const startDateStr = startTime.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  })

  let recurringSection: JSX.Element | undefined
  if (recurringSchedule) {
    const { freq, interval, endDate } = recurringSchedule
    const endDateStr = endDate?.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })

    recurringSection = (
      <Row gap={theme.space.xms} alignCenter wrap>
        <Row gap={theme.space.xms} alignCenter wrap>
          <SvgIcon
            name="calendar"
            size={theme.space.xs}
            color={theme.colors.Grey60}
            strokeWidth={1}
          />
          {endDateStr ? (
            <Text variant="body3">
              {startDateStr} - {endDateStr} ({shiftCount} shift
              {makePlural(shiftCount)})
            </Text>
          ) : (
            <Text variant="body3"> {startDateStr} - no end</Text>
          )}
        </Row>

        <Row gap={theme.space.xms} alignCenter wrap>
          <SvgIcon
            name="rotateLeft"
            strokeWidth={1}
            size={theme.space.xs}
            color={theme.colors.Grey50}
          />
          <Text variant="body3">Repeats {getIntervalFreq(interval, freq)}</Text>
          <Col gap={theme.space.xxs}>
            {schedules.map(
              ({ recurringSchedule }, index) =>
                recurringSchedule && (
                  <ScheduleDays
                    repeatOn={recurringSchedule.repeatOn}
                    key={index}
                    style={{ padding: 0, marginRight: theme.space.xxxs }}
                  />
                ),
            )}
          </Col>
        </Row>
      </Row>
    )
  }

  return (
    <Col gap={theme.space.xxs}>
      <Row gap={theme.space.xxs} alignCenter wrap>
        <SvgIcon
          name="clock"
          size={theme.space.xs}
          color={theme.colors.Grey50}
        />
        <Text variant="body3">
          {startTimeStr} to {endTimeStr}
        </Text>
      </Row>

      {!!recurringSection && recurringSection}
    </Col>
  )
}
