import { InvoiceStatus } from '@traba/types'
import { Badge } from 'src/components/base'
import { BadgeVariant } from '../base/Badge/Badge.styles'

function shouldShowOpenBadge(invoiceStatus: InvoiceStatus): boolean {
  switch (invoiceStatus) {
    case InvoiceStatus.OPEN:
    case InvoiceStatus.UNCOLLECTIBLE:
    case InvoiceStatus.PAYMENT_ACTION_REQUIRED:
    case InvoiceStatus.PAYMENT_FAILED:
      return true
  }

  return false
}

/** We currently only display open and paid invoices in the portal */
function getBadgeColorFromInvoiceStatus(
  invoiceStatus: InvoiceStatus,
  isPastDueDate: boolean,
): BadgeVariant {
  if (shouldShowOpenBadge(invoiceStatus)) {
    if (isPastDueDate) {
      return 'danger'
    }
    return 'info'
  }

  switch (invoiceStatus) {
    case InvoiceStatus.PAID:
      return 'success'
    default:
      return 'brand'
  }
}

function getBadgeTextFromInvoiceStatus(
  invoiceStatus: InvoiceStatus,
  isPastDueDate: boolean,
): string {
  if (shouldShowOpenBadge(invoiceStatus)) {
    if (isPastDueDate) {
      return 'Past Due'
    }
    return 'Open'
  }

  switch (invoiceStatus) {
    case InvoiceStatus.PAID:
      return 'Paid'
    case InvoiceStatus.PAYMENT_PROCESSING:
      return 'Processing Payment'
    default:
      return 'Other'
  }
}

const InvoiceStatusBadge = ({
  invoiceStatus,
  isPastDueDate,
}: {
  invoiceStatus: InvoiceStatus
  isPastDueDate: boolean
}) => {
  const title = getBadgeTextFromInvoiceStatus(invoiceStatus, isPastDueDate)
  return (
    <Badge
      title={title}
      variant={getBadgeColorFromInvoiceStatus(invoiceStatus, isPastDueDate)}
      style={{ whiteSpace: 'nowrap' }}
    />
  )
}

export default InvoiceStatusBadge
