export const firebaseConfig = {
  apiKey:
    import.meta.env.VITE_FIREBASE_API_KEY ||
    'AIzaSyBY9q15vVIj2uOJLmtHm6X06tVjyms_h10',
  authDomain:
    import.meta.env.VITE_FIREBASE_AUTH_DOMAIN ||
    'traba-dev-app.firebaseapp.com',
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID || 'traba-dev-app',
  storageBucket:
    import.meta.env.VITE_FIREBASE_STORAGE_BUCKET || 'traba-dev-app.appspot.com',
  messagingSenderId:
    import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID || '65376754965',
  appId:
    import.meta.env.VITE_FIREBASE_APP_ID ||
    '1:65376754965:web:1d782f3263ff6442211d1c',
}
