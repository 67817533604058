import { SvgIconProps } from 'src/types/svg-types'

export default function Star({
  size = 14,
  stroke = '#7A8A99',
  strokeWidth = 1.5,
  ...rest
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5.43596 2.05764C6.0214 0.647453 7.9786 0.647453 8.56404 2.05764L8.97182 3.03989C9.21158 3.6174 9.73797 4.01776 10.3485 4.08694L11.4864 4.2159C12.9235 4.37875 13.5171 6.18366 12.4675 7.19923L11.5013 8.13411C11.0765 8.54515 10.8888 9.15053 11.0046 9.73633L11.2378 10.9172C11.5331 12.412 9.93178 13.5406 8.66787 12.7285L7.90473 12.2381C7.35176 11.8828 6.64824 11.8828 6.09527 12.2381L5.33213 12.7285C4.06822 13.5406 2.46687 12.412 2.76216 10.9172L2.99543 9.73633C3.11115 9.15053 2.92345 8.54515 2.49865 8.13411L1.53247 7.19923C0.482921 6.18366 1.07648 4.37875 2.51356 4.2159L3.65151 4.08694C4.26203 4.01776 4.78842 3.6174 5.02818 3.03989L5.43596 2.05764Z"
        stroke={stroke}
        stroke-width={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
