import { Envs } from '@traba/types'

type Config = {
  FRONTEND_URL?: string
}

function getConfigEnv(): Envs {
  // process.env.NODE_ENV is a Render thing; it means that this instance is
  // running on Render.
  if (process.env.NODE_ENV !== 'production') {
    return Envs.LOCAL
  }
  return import.meta.env.VITE_ENV
}

// Getting environment name from env vars
export const configEnv = getConfigEnv()

// Setting up config vars
const config: Record<Envs, Config> = {
  [Envs.LOCAL]: {
    FRONTEND_URL: 'http://127.0.0.1:3000',
  },
  [Envs.DEVELOPMENT]: {
    FRONTEND_URL: 'https://devapp.traba.work',
  },
  [Envs.STAGING]: {
    FRONTEND_URL: 'https://stagingapp.traba.work',
  },
  [Envs.PRODUCTION]: {
    FRONTEND_URL: 'https://app.traba.work',
  },
  [Envs.TEST]: {
    FRONTEND_URL: 'http://127.0.0.1:3000',
  },
}

export function isDebugEnv(queryParams: URLSearchParams): boolean {
  return (
    configEnv === Envs.DEVELOPMENT ||
    configEnv === Envs.LOCAL ||
    configEnv === Envs.TEST ||
    queryParams.get('debug') === 'true'
  )
}

export default config[configEnv]
