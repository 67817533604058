import { SettingsPageWithTabs } from '../SettingsPageWithTabs'
import { useAccountSettings } from './AccountSettings.hooks'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook

export const AccountSettings = () => {
  const { currentTabIndex, setCurrentTabIndex, activeTabs } =
    useAccountSettings()

  return (
    <SettingsPageWithTabs
      pageTitle="Account Settings"
      currentTabIndex={currentTabIndex}
      setCurrentTabIndex={setCurrentTabIndex}
      activeTabs={activeTabs}
    />
  )
}
