import { SvgIconProps } from '@traba/types'

export default function ({
  size = 14,
  color,
  strokeWidth,
  ...props
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size ?? (size * 18) / 14}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="10.2372"
        cy="12.2719"
        r="0.898363"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <circle
        cx="7.09319"
        cy="12.2719"
        r="0.898363"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M4.97449 10.026H7.99144V6.88171M7.99144 6.88171V7.78008H4.84717V10.9243C4.84717 11.6686 5.45048 12.2719 6.19471 12.2719V12.2719M7.99144 6.88171H9.78816L11.6588 8.3782C11.896 8.56797 12.0341 8.85528 12.0341 9.15906V9.5768M10.6865 7.78008H10.2373V9.5768H12.0341M12.0341 9.5768V11.3735C12.0341 11.8697 11.6319 12.2719 11.1357 12.2719V12.2719M9.33898 12.2719H7.99144"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M4.23362 12.853H3.3836V7.23001V5.58981H0.558594M13.6186 5.58981H0.558594M13.6186 5.58981V7.23001V12.853H12.7927M13.6186 5.58981H16.5626L12.5795 1.27942H4.40994L0.558594 5.58981"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
