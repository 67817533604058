import { theme } from 'src/libs/theme'
import styled, { css } from 'styled-components'

export const MultiSelectorContainer = styled.div<{ columnGap?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${(args) => {
    return `${args.columnGap ?? theme.space.xxs}px`
  }};
`

export const OptionItem = styled.div<{
  isActive: boolean
  disabled: boolean
}>`
  width: 100%;
  border: 1px solid;
  border-color: ${(props) =>
    props.isActive ? theme.colors.Violet20 : theme.border.color};
  :hover {
    ${({ disabled }) =>
      disabled
        ? ''
        : css`
            box-shadow: 0px 2px 5px rgba(196, 204, 212, 0.55);
          `}
  }
  box-shadow: 0px 1px 2px rgba(196, 204, 212, 0.4);
  background-color: ${({ isActive, disabled, theme }) =>
    isActive
      ? theme.colors.Violet10
      : disabled
        ? theme.colors.Grey10
        : '#ffffff'};
  border-radius: ${theme.border.radius};
  padding: ${({ theme }) => theme.space.sm / 2}px
    ${({ theme }) => theme.space.xs}px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.MidnightBlue};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  @media only screen and (max-width: (${({ theme }) =>
      theme.media.maxExtraSmall})) {
    padding: ${({ theme }) => theme.space.sm / 2}px
      ${({ theme }) => theme.space.xxs}px;
  }
`

export const OptionItemContainer = styled.div<any>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`
