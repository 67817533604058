import useAppAuth from 'src/hooks/authHook'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import { Button, ButtonVariant, Text } from '../base'
import { AppleIcon } from './AuthIcon'

export const AppleAuthButton = ({ disabled }: { disabled?: boolean }) => {
  const { handleLoginWithApple } = useAppAuth()
  const { hotSettings } = useHotSettings()
  if (!hotSettings?.allowSignInWithAppleWeb) {
    return null
  }
  return (
    <Button
      disabled={disabled}
      variant={ButtonVariant.OUTLINED}
      type="button"
      leftIcon={<AppleIcon />}
      style={{
        width: '100%',
        marginBottom: theme.space.xs,
      }}
      onClick={handleLoginWithApple}
    >
      <Text ml={theme.space.xxs} mt={theme.space.us} variant="h6">
        Continue with Apple
      </Text>
    </Button>
  )
}
