import { useParams } from 'react-router-dom'
import { MainLayout } from 'src/components'
import RosterDetails from 'src/components/RosterDetails/RosterDetails'

export default function RosterScreen() {
  const { rosterId } = useParams<{ rosterId: string }>()

  return (
    <MainLayout title="Roster">
      <RosterDetails rosterId={rosterId || ''} />
    </MainLayout>
  )
}
