import { Tooltip } from '@mui/material'
import * as Sentry from '@sentry/react'
import { useHotSettings } from '@traba/hooks'
import { Pill } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import {
  BehaviorAttribute,
  RoleAttribute,
  RoleAttributeCategory,
  HighlyRatedBehaviorAttribute,
  HighlyRatedRoleAttribute,
} from '@traba/types'
import {
  useBehaviorAttributes,
  useRoleAttributes,
} from 'src/hooks/useAttributes'
import { theme } from 'src/libs/theme'
import { Col, Row, Text } from '../base'

function HighlyRatedRoleAttributes({
  highlyRatedRoleAttributes,
  roleAttributesMap,
}: {
  highlyRatedRoleAttributes: HighlyRatedRoleAttribute[]
  roleAttributesMap: Map<string, RoleAttribute>
}) {
  return (
    <Col>
      {highlyRatedRoleAttributes.length > 1 ? (
        <Col>
          <Text
            variant="h6"
            style={{
              marginTop: theme.space.xs,
              marginBottom: theme.space.xxxs,
            }}
          >
            Highly rated shift attributes
          </Text>
          <Row style={{ flexWrap: 'wrap', rowGap: theme.space.xxs }}>
            {highlyRatedRoleAttributes
              .sort((attrA, attrB) => {
                return attrB.count - attrA.count
              })
              .map((highlyRatedAttribute) => {
                const roleAttribute = roleAttributesMap.get(
                  highlyRatedAttribute.roleAttributeType,
                )
                let displayName: string | undefined
                if (roleAttribute) {
                  if (
                    roleAttribute.category !==
                    RoleAttributeCategory.Responsibilities
                  ) {
                    return undefined
                  }
                  displayName = roleAttribute.displayName
                } else {
                  Sentry.captureMessage(
                    `no display name for role attribute type ${highlyRatedAttribute.roleAttributeType}`,
                  )
                  displayName = highlyRatedAttribute.roleAttributeType
                }
                return (
                  <Tooltip
                    title={`${highlyRatedAttribute.count} positive reviews for ${displayName}`}
                    placement="right"
                    key={`${highlyRatedAttribute.roleAttributeType}_tooltip`}
                  >
                    <div>
                      <Pill
                        addMargin={true}
                        children={
                          <Text
                            variant="body1"
                            style={{
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              overflowWrap: 'break-word',
                              display: 'block',
                              textOverflow: 'ellipsis',
                              lineClamp: 1,
                              maxLines: 1,
                            }}
                          >
                            {displayName}
                          </Text>
                        }
                      />
                    </div>
                  </Tooltip>
                )
              })}
          </Row>
        </Col>
      ) : undefined}
    </Col>
  )
}

function HighlyRatedBehaviorAttributes({
  highlyRatedBehaviorAttributes,
  behaviorAttributesMap,
  useBehaviorAttributesFromRoleAttributes,
}: {
  highlyRatedBehaviorAttributes: HighlyRatedBehaviorAttribute[]
  behaviorAttributesMap: Map<string, BehaviorAttribute | RoleAttribute>
  useBehaviorAttributesFromRoleAttributes?: boolean
}) {
  return (
    <Col>
      {highlyRatedBehaviorAttributes.length > 0 && (
        <>
          <Text
            variant="h6"
            style={{
              marginTop: theme.space.xs,
              marginBottom: theme.space.xxxs,
            }}
          >
            Highly rated behavioral skills
          </Text>
          <Row style={{ flexWrap: 'wrap', rowGap: theme.space.xxs }}>
            {highlyRatedBehaviorAttributes
              .sort((attrA, attrB) => {
                return attrB.count - attrA.count
              })
              .map((highlyRatedAttribute) => {
                const behavioralAttribute = behaviorAttributesMap.get(
                  highlyRatedAttribute.behaviorAttributeType,
                )
                let displayName: string | undefined
                if (!behavioralAttribute) {
                  Sentry.captureMessage(
                    `no display name for behavioral attribute type ${highlyRatedAttribute.behaviorAttributeType}`,
                  )
                  displayName = highlyRatedAttribute.behaviorAttributeType
                } else {
                  displayName = useBehaviorAttributesFromRoleAttributes
                    ? (behavioralAttribute as RoleAttribute).displayName
                    : (behavioralAttribute as BehaviorAttribute).displayNameEn
                }
                return (
                  <Tooltip
                    key={`${highlyRatedAttribute.behaviorAttributeType}_tooltip`}
                    title={`${
                      highlyRatedAttribute.count
                    } positive review${makePlural(
                      highlyRatedAttribute.count,
                    )} for ${displayName}`}
                    placement="top"
                  >
                    <div>
                      <Pill
                        addMargin={true}
                        children={<Text variant="body1">{displayName}</Text>}
                      />
                    </div>
                  </Tooltip>
                )
              })}
          </Row>
        </>
      )}
    </Col>
  )
}

export function HighlyRatedSection({
  highlyRatedBehaviorAttributes,
  highlyRatedRoleAttributes,
}: {
  highlyRatedBehaviorAttributes: HighlyRatedBehaviorAttribute[]
  highlyRatedRoleAttributes: HighlyRatedRoleAttribute[]
}) {
  const { hotSettings } = useHotSettings()
  const { roleAttributes, isLoading: roleAttributesAreLoading } =
    useRoleAttributes(undefined, true)
  const { behaviorAttributes, isLoading: behaviorAttributesAreLoading } =
    useBehaviorAttributes()
  if (
    (highlyRatedRoleAttributes.length < 1 &&
      highlyRatedBehaviorAttributes.length < 1) ||
    roleAttributesAreLoading ||
    behaviorAttributesAreLoading ||
    !roleAttributes ||
    !behaviorAttributes
  ) {
    return undefined
  }
  const roleAttributesMap = new Map<string, RoleAttribute>(
    roleAttributes.map((roleAttribute) => [roleAttribute.type, roleAttribute]),
  )
  const behaviorAttributesMap = new Map<
    string,
    BehaviorAttribute | RoleAttribute
  >(
    behaviorAttributes.map((behaviorAttribute) => [
      behaviorAttribute.type,
      behaviorAttribute,
    ]),
  )
  return (
    <>
      <HighlyRatedRoleAttributes
        highlyRatedRoleAttributes={highlyRatedRoleAttributes}
        roleAttributesMap={roleAttributesMap}
      />
      <HighlyRatedBehaviorAttributes
        behaviorAttributesMap={behaviorAttributesMap}
        highlyRatedBehaviorAttributes={highlyRatedBehaviorAttributes}
        useBehaviorAttributesFromRoleAttributes={
          hotSettings?.useBehaviorAttributesFromRoleAttributes
        }
      />
    </>
  )
}
