import { Card } from '@traba/react-components'
import styled from 'styled-components'

import { Row } from '../base'

export const AddToRosterMenuContainer = styled.div`
  color: ${({ theme }) => theme.colors.White};
  box-shadow: 1px 2px 5px 3px rgba(0, 0, 0, 0.25);
  border-radius: ${({ theme }) => theme.space.xxs}px;
  width: 360px;
`

export const AddToRosterMenuHeader = styled(Row)`
  padding: ${({ theme }) => theme.space.xs}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey20};
`

export const RostersContainer = styled.div`
  padding: ${({ theme }) => theme.space.xs}px;
  max-height: 320px;
  overflow-y: scroll;
`

export const RosterCard = styled(Card)`
  cursor: pointer;
  padding: 12px ${({ theme }) => theme.space.xs}px;
  border: 1px solid ${({ theme }) => theme.colors.Grey20};
  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space.xxs}px;
  }
`

export const AddToRosterMenuFooter = styled(Row)`
  padding: ${({ theme }) => theme.space.xs}px;
  border-top: 1px solid ${({ theme }) => theme.colors.Grey20};
`
