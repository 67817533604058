import { theme } from '@traba/theme'
import { MODAL_SIZE } from '../Modal'
import { Text } from '../Text'
import { Dialog } from './Dialog'

export function ConfirmationDialog({
  open,
  confirmationText,
  title,
  onClose,
  onConfirm,
  onConfirmCTA,
}: {
  open: boolean
  title?: string
  confirmationText: string
  onClose: () => void
  onConfirm: () => void
  onConfirmCTA: string
}) {
  return open ? (
    <Dialog
      maxWidth="sm"
      open={!!open}
      onClose={onClose}
      onConfirm={() => onConfirm()}
      onConfirmCTA={onConfirmCTA}
      dialogTitle={title || 'Confirmation'}
      size={MODAL_SIZE.SMALL}
    >
      <Text variant="body1" color={theme.colors.MidnightBlue}>
        {confirmationText}
      </Text>
    </Dialog>
  ) : null
}
