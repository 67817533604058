import { LoadingSpinner } from '@traba/react-components'
import { EmptyPlaceholderTile, Row } from 'src/components/base'
import { EmptyDataLocationSubtitle } from 'src/components/base/RegionalFilterSelect/EmptyDataLocationSubtitle'
import useMobile from 'src/hooks/useMobile'

interface Props {
  isLoading: boolean
}

export const AnnouncementEmptyLoading = ({ isLoading }: Props) => {
  const { isMobileViewOrReactNative } = useMobile()
  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Row center>
      <EmptyPlaceholderTile
        iconName="info"
        title="You don't currently have any shift announcements in the selected locations."
        subtitle={
          <EmptyDataLocationSubtitle
            isMobileView={isMobileViewOrReactNative}
            extraText="to see announcements from shifts in those locations."
          />
        }
      />
    </Row>
  )
}
