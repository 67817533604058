import { AuthScreenWrapper } from 'src/components/auth'
import { BasicDetailsForm } from 'src/components/auth/BasicDetailsForm'
import { FillRateRightCol } from 'src/components/auth/FillRateRightCol'
import { Text } from 'src/components/base'
import { theme } from 'src/libs/theme'

export default function BasicDetailsScreen() {
  const leftCol = (
    <>
      <>
        <Text variant="h5" mb={theme.space.xs}>
          Step 1/2
        </Text>
        <Text variant="h2">Basic Details</Text>
      </>
      <BasicDetailsForm cta="Continue" />
    </>
  )
  return (
    <AuthScreenWrapper
      helmetTitle={'Basic Details'}
      leftCol={leftCol}
      rightCol={<FillRateRightCol />}
    />
  )
}
