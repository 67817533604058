import {
  Button,
  ButtonVariant,
  InvitationsTable,
  LoadingSpinner,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { isRecurringShiftRequest } from '@traba/utils'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useScheduleInvitations } from 'src/hooks/useScheduleInvitations'
import { useShiftRequestParent } from 'src/hooks/useShiftRequestParent'
import { Col, EmptyPlaceholderTile, Row } from '../base'
import { ScheduleInvitationModal } from './ScheduleInvitationModal'

interface Props {
  shiftRequestParentId: string
}

export const ScheduleDetailsInvitations = ({ shiftRequestParentId }: Props) => {
  const navigate = useNavigate()
  const { scheduleInvitations, isLoading, rescindScheduleInvitation } =
    useScheduleInvitations(shiftRequestParentId)
  const { shiftRequestParent } = useShiftRequestParent(shiftRequestParentId)
  const [showInvitationModal, setShowInvitationModal] = useState(false)

  const onClickWorker = (workerId: string) => {
    navigate(`/worker/${workerId}`)
  }
  const hasSentInvitations = scheduleInvitations.some(
    (si) => si.invitations.length > 0,
  )

  const workersInvitedByShiftRequest = useMemo(
    () =>
      scheduleInvitations.reduce((acc, si) => {
        si.invitations.forEach((invitation) => {
          const curr = acc.get(invitation.shiftRequestId)
          if (!curr) {
            acc.set(
              invitation.shiftRequestId,
              new Set<string>([invitation.workerId]),
            )
          } else {
            acc.set(invitation.shiftRequestId, curr.add(invitation.workerId))
          }
        })
        return acc
      }, new Map<string, Set<string>>()),
    [scheduleInvitations],
  )

  if (isLoading) {
    return <LoadingSpinner />
  }

  const onClickInviteWorkers = () => {
    setShowInvitationModal(true)
  }

  const onRescindScheduleInvitation = async ({
    shiftRequestId,
    workerId,
  }: {
    shiftRequestId: string
    workerId: string
  }) => {
    rescindScheduleInvitation({ shiftRequestId, workerId })
  }

  return (
    <>
      <Row
        alignCenter
        justifyBetween
        mb={theme.space.sm}
        wrap
        style={{ rowGap: theme.space.xs }}
      >
        <Col>
          <Text variant="h4">Schedule invitations</Text>
          <Text variant="body2" color={theme.colors.Grey60}>
            Invite workers to one or more roles in this schedule.
          </Text>
        </Col>
        <Button variant={ButtonVariant.OUTLINED} onClick={onClickInviteWorkers}>
          Invite workers to schedule
        </Button>
      </Row>

      {scheduleInvitations.length && hasSentInvitations ? (
        scheduleInvitations.map((si, index) => (
          <InvitationsTable
            rescindScheduleInvitation={onRescindScheduleInvitation}
            invitations={si.invitations}
            roleName={si.shiftRole}
            onClickWorker={onClickWorker}
            key={`${si.shiftRole}-${index}`}
          />
        ))
      ) : (
        <EmptyPlaceholderTile
          title="No invitations found for this schedule"
          iconName="info"
        />
      )}
      <ScheduleInvitationModal
        shiftRequests={shiftRequestParent?.shiftRequests.filter((sr) =>
          isRecurringShiftRequest(sr),
        )}
        showInvitationModal={showInvitationModal}
        setShowInvitationModal={setShowInvitationModal}
        shiftRequestParentId={shiftRequestParentId}
        workersInvitedByShiftRequest={workersInvitedByShiftRequest}
      />
    </>
  )
}
