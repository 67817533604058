import React, { SVGProps } from 'react'

export const AlertIcon: React.FunctionComponent<SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.98017 11.7709C6.67773 12.0764 5.32227 12.0764 4.01983 11.7709C2.13894 11.3297 0.670327 9.86106 0.229132 7.98017C-0.0763779 6.67773 -0.0763779 5.32227 0.229132 4.01983C0.670329 2.13894 2.13894 0.670327 4.01983 0.229132C5.32227 -0.0763779 6.67773 -0.0763769 7.98017 0.229133C9.86105 0.670328 11.3297 2.13894 11.7709 4.01983C12.0764 5.32227 12.0764 6.67773 11.7709 7.98017C11.3297 9.86106 9.86106 11.3297 7.98017 11.7709ZM6.00002 7.2764C5.64755 7.2764 5.36183 7.56213 5.36183 7.91459C5.36183 8.26705 5.64755 8.55278 6.00002 8.55278C6.35248 8.55278 6.63821 8.26705 6.63821 7.91459C6.63821 7.56213 6.35248 7.2764 6.00002 7.2764ZM6.00002 6.79776C5.73567 6.79776 5.52137 6.58346 5.52137 6.31911V3.76635C5.52137 3.502 5.73567 3.28771 6.00002 3.28771C6.26436 3.28771 6.47866 3.502 6.47866 3.76635V6.31911C6.47866 6.58346 6.26436 6.79776 6.00002 6.79776Z"
      />
    </svg>
  )
}
