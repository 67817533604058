import { calendarStyleCombos } from '@traba/theme'
import { WorkerShiftWithWorkerDetails } from '@traba/types'
import { useState } from 'react'
import { EmptyPlaceholderTile, Input } from 'src/components'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { ScheduleDailyViewWorkerTableRoleChunkMobile } from './ScheduleDailyViewWorkerTableRoleChunkMobile'

interface Props {
  shifts: ShiftAndAddress[]
  isFromDashboard?: boolean
}

export function ScheduleDailyViewWorkerTableContentMobile(props: Props) {
  const { shifts, isFromDashboard } = props
  const [selectedRows, setSelectedRows] = useState<
    WorkerShiftWithWorkerDetails[]
  >([])
  const [searchFilter, setSearchFilter] = useState('')
  const searchInput = (
    <Input
      placeholder="Search workers..."
      leftIconName="search"
      name="workerSearch"
      containerStyle={{
        margin: 0,
      }}
      type="text"
      defaultValue=""
      width="225px"
      value={searchFilter}
      onChange={(e) => {
        e.preventDefault()
        setSearchFilter(e.target.value)
      }}
      onClear={() => setSearchFilter('')}
    />
  )

  return (
    <>
      {!isFromDashboard && searchInput}
      {shifts.length > 0 ? (
        <>
          {shifts.map((shift, index) => {
            return (
              <ScheduleDailyViewWorkerTableRoleChunkMobile
                key={index}
                {...props}
                shift={shift}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                dayViewRoleColor={
                  calendarStyleCombos[index % calendarStyleCombos.length]
                    .borderColor
                }
              />
            )
          })}
        </>
      ) : (
        <EmptyPlaceholderTile
          iconName="info"
          title="You don't currently have any workers on this shift"
        />
      )}
    </>
  )
}
