import React, { HTMLAttributes } from 'react'
import { Col, Row } from 'src/components/base'

type DualColumnLayoutProps = {
  leftColumn: React.ReactNode
  rightColumn: React.ReactNode
  gap?: string
  leftBasis?: string
  rightBasis?: string
  leftStyle?: HTMLAttributes<HTMLDivElement>['style']
  rightStyle?: HTMLAttributes<HTMLDivElement>['style']
  leftClassName?: string
  rightClassName?: string
  mobileStack?: boolean
}

export function DualColumnLayout({
  leftColumn,
  rightColumn,
  gap = '',
  leftBasis = '',
  rightBasis = '',
  leftStyle = {},
  rightStyle = {},
  leftClassName = '',
  rightClassName = '',
  mobileStack = false,
}: DualColumnLayoutProps) {
  return (
    <>
      <Row
        className={mobileStack ? 'xs-hide lg-show' : ''}
        fullHeight
        style={{ gap }}
      >
        <Col
          style={{ flexBasis: leftBasis, ...leftStyle }}
          className={leftClassName}
        >
          {leftColumn}
        </Col>
        <Col
          style={{ flexBasis: rightBasis, ...rightStyle }}
          className={rightClassName}
        >
          {rightColumn}
        </Col>
      </Row>
      {mobileStack && (
        <Row className="lg-hide" fullHeight>
          <Col style={{ maxWidth: `100%` }}>
            {leftColumn}
            {rightColumn}
          </Col>
        </Row>
      )}
    </>
  )
}
