import { ListItemText, Checkbox } from '@mui/material'
import { theme } from '@traba/theme'
import { Badge } from '../Badge/Badge'
import { Col } from '../Col'
import Row from '../Row'
import { IMenuItem } from '../Select/Select'
import {
  CheckedStatus,
  StyledSelectCheckbox,
} from '../Select/StyledSelectCheckbox'
import { Text } from '../Text'

export interface SearchSelectItemContentProps {
  multiple?: boolean
  onlyShowLabel?: boolean
  option: IMenuItem
  isSelected?: boolean
  disabled?: boolean
  style?: React.CSSProperties
}

export function SearchSelectItemContent(props: SearchSelectItemContentProps) {
  const { option, multiple, isSelected, onlyShowLabel, disabled, style } = props

  return (
    <Row alignCenter fullWidth style={style}>
      {multiple && (
        <Checkbox
          checked={isSelected}
          style={{
            padding: theme.space.xxs,
            margin: '-4px',
            marginRight: theme.space.xxxs,
          }}
          icon={<StyledSelectCheckbox disabled={disabled} />}
          checkedIcon={
            <StyledSelectCheckbox
              checkedStatus={CheckedStatus.CHECKED}
              disabled={disabled}
            />
          }
        />
      )}
      <Col style={{ width: '100%' }}>
        <Row alignCenter justifyEnd fullWidth wrap gap={theme.space.xxs}>
          <ListItemText
            primary={
              <Text variant="body1" style={{ textWrap: 'wrap' }}>
                {option.label}
              </Text>
            }
            secondary={
              onlyShowLabel ? undefined : (
                <Text variant="body3" style={{ textWrap: 'wrap' }}>
                  {option.secondaryLabel ?? option.value}
                </Text>
              )
            }
          />

          <Row alignCenter gap={theme.space.xxs} wrap>
            {option.trailingTags?.map((tag) => (
              <Badge
                key={tag.title}
                title={tag.title}
                iconName={tag.iconName}
                variant={tag.variant}
              />
            ))}
          </Row>
        </Row>
        {option.tagsRow && option.tagsRow.length > 0 && (
          <Row py={theme.space.xxxs} gap={theme.space.xxs} wrap fullWidth>
            {option.tagsRow.map((tag) => (
              <Badge
                key={tag.title}
                title={tag.title}
                iconName={tag.iconName}
                variant={tag.variant}
                style={{ margin: 0 }}
                sm
              />
            ))}
          </Row>
        )}
      </Col>
    </Row>
  )
}
