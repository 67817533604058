import { CardTile, LoadingSpinner } from '@traba/react-components'
import { endOfDay, startOfDay } from 'date-fns'
import useMobile from 'src/hooks/useMobile'
import { CalendarFilters } from 'src/hooks/useShiftFilters.hooks'
import { useShiftStats } from 'src/hooks/useShifts'
import { theme } from 'src/libs/theme'
import { IconName } from 'src/types/svg-types'
import { Col, Row, SvgIcon, Text } from '../base'

type ShiftStatsProps = {
  title?: string
  date: Date
  filters: CalendarFilters
  showDesktopCard: boolean
}

type StatProps = {
  title: string
  iconName: IconName
  value: number | string
  verticalBorder?: boolean
  isLoading: boolean
  showDesktopCard: boolean
  size?: number
  strokeWidth?: number
  isDayStat?: boolean
}

const Stat = ({
  title,
  iconName,
  value,
  verticalBorder,
  isLoading,
  showDesktopCard,
  size,
  strokeWidth,
  isDayStat = false,
}: StatProps) => {
  const { isMobileViewOrReactNative } = useMobile()

  return (
    <Col
      px={showDesktopCard ? theme.space.xs : 0}
      py={theme.space.xxs}
      style={{
        ...(verticalBorder
          ? { borderRight: `2px solid ${theme.colors.Grey}` }
          : {}),
        alignItems: 'center',
        paddingLeft: isMobileViewOrReactNative
          ? '0px'
          : isDayStat
            ? theme.space.med
            : theme.space.xs,
        paddingRight: isMobileViewOrReactNative
          ? '0px'
          : isDayStat
            ? theme.space.med
            : theme.space.xs,
      }}
    >
      <Row alignCenter>
        {showDesktopCard && (
          <SvgIcon
            name={iconName}
            color={theme.colors.MidnightBlue}
            size={size || 14}
            style={{
              marginLeft: theme.space.xxxs,
            }}
            strokeWidth={strokeWidth}
          />
        )}
        <Text
          variant={showDesktopCard ? 'body1' : 'body3'}
          ml={theme.space.xxxs}
          mr={theme.space.xxxs}
          color={theme.colors.MidnightBlue}
        >
          {title}
        </Text>
      </Row>

      {isLoading ? (
        <LoadingSpinner
          style={{ width: theme.space.sm, height: theme.space.sm }}
        />
      ) : (
        <Text
          variant={'h5'}
          mt={theme.space.xxxs}
          size={isDayStat ? 20 : undefined}
        >
          {value}
        </Text>
      )}
    </Col>
  )
}

export const ShiftStats = (props: ShiftStatsProps) => {
  const { title, date, filters, showDesktopCard } = props

  const { isMobileViewOrReactNative } = useMobile()

  const { data: shiftStats, isLoading } = useShiftStats({
    ...filters,
    startBefore: endOfDay(date),
    startAfter: startOfDay(date),
  })

  return (
    <CardTile
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
      }}
    >
      <Row
        justifyBetween={!showDesktopCard}
        fullWidth
        px={!showDesktopCard ? theme.space.xs : 0}
      >
        {title && !isMobileViewOrReactNative && (
          <Stat
            iconName="calendar"
            value={title}
            title="Day"
            verticalBorder={showDesktopCard}
            isLoading={isLoading}
            showDesktopCard={showDesktopCard}
            strokeWidth={0.5}
            size={16}
            isDayStat={true}
          />
        )}
        <Stat
          iconName="company"
          value={shiftStats?.totalShifts || 0}
          title="Shifts"
          verticalBorder={showDesktopCard}
          isLoading={isLoading}
          showDesktopCard={showDesktopCard}
          strokeWidth={0.5}
        />
        <Stat
          iconName="twoUser"
          value={shiftStats?.scheduledWorkers || 0}
          title={showDesktopCard ? 'Scheduled workers' : 'Scheduled'}
          verticalBorder={showDesktopCard}
          isLoading={isLoading}
          size={16}
          strokeWidth={2}
          showDesktopCard={showDesktopCard}
        />
        <Stat
          iconName="clockIn"
          value={
            showDesktopCard
              ? `${shiftStats?.clockedInWorkers}/${shiftStats?.scheduledWorkers}`
              : `${shiftStats?.clockedInWorkers}`
          }
          title="Clocked in"
          isLoading={isLoading}
          showDesktopCard={showDesktopCard}
        />
        <Stat
          iconName="clockOut"
          value={
            showDesktopCard
              ? `${shiftStats?.clockedOutWorkers}/${shiftStats?.scheduledWorkers}`
              : `${shiftStats?.clockedOutWorkers}`
          }
          title="Clocked out"
          isLoading={isLoading}
          showDesktopCard={showDesktopCard}
        />
      </Row>
    </CardTile>
  )
}
