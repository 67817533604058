import { Card, CardTile } from '@traba/react-components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthScreenWrapper } from 'src/components/auth'
import { AppleAuthButton } from 'src/components/auth/AppleAuthButton'
import { GoogleAuthButton } from 'src/components/auth/GoogleAuthButton'
import { LoginRightCol } from 'src/components/auth/LoginRightCol'
import {
  Button,
  ButtonVariant,
  Divider,
  Icon,
  Link,
  Row,
  Text,
} from 'src/components/base'
import { RadioButton } from 'src/components/RadioButton'
import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'

import * as S from './LandingRegistrationScreen.styles'

export default function LandingRegistrationScreen() {
  const { isReactNativeApp } = useMobile()

  const navigate = useNavigate()
  const [selectedType, setSelectedType] = React.useState<
    'business' | 'worker' | undefined
  >()
  const businessCardText = "A Business - I'm looking for staff"
  const workerCardText = "A Worker - I'm looking for work"
  const pageTitle = 'Get Started as a Business or a Worker'

  const leftCol = (
    <>
      <Text variant="h2" mb={theme.space.xl}>
        {pageTitle}
      </Text>
      <S.StyledCard
        id="business_card"
        onClick={() => {
          setSelectedType('business')
        }}
        isClickable
        style={{
          marginBottom: theme.space.lg,
          borderColor:
            selectedType === 'business' ? theme.colors.brand : undefined,
        }}
      >
        <Row alignCenter justifyBetween>
          <Row>
            <RadioButton size={22} selected={selectedType === 'business'} />
            <Text variant="h5" style={{ paddingLeft: theme.space.sm }}>
              {businessCardText}
            </Text>
          </Row>
          <CardTile size="48px" isClickable={false}>
            <Icon name="company" style={{ height: 18, width: 18 }} />
          </CardTile>
        </Row>
      </S.StyledCard>
      <Card
        id="worker_card"
        style={{
          marginBottom: theme.space.med,
          cursor: 'pointer',
          borderColor:
            selectedType === 'worker' ? theme.colors.brand : undefined,
        }}
        isClickable
        onClick={() => {
          setSelectedType('worker')
        }}
      >
        <Row justifyBetween alignCenter>
          <Row>
            <RadioButton size={22} selected={selectedType === 'worker'} />
            <Text variant="h5" style={{ paddingLeft: theme.space.sm }}>
              {workerCardText}
            </Text>
          </Row>
          <CardTile size="48px">
            <Icon name="workers" />
          </CardTile>
        </Row>
      </Card>

      <Button
        style={{
          marginTop: theme.space.sm,
          marginBottom: theme.space.sm,
        }}
        id="continue_to_business_or_worker_registration"
        disabled={selectedType === undefined}
        variant={ButtonVariant.FILLED}
        full
        onClick={() => {
          if (selectedType === 'business') {
            window.analytics?.track(`User Clicked ${businessCardText}`)
            navigate('/basic-info')
          } else {
            window.analytics?.track(`User Clicked ${workerCardText}`)
            window.location.href = 'https://www.traba.work/workers'
          }
        }}
      >
        Continue
      </Button>

      {selectedType !== 'worker' && (
        <>
          <Divider text="OR" />
          <GoogleAuthButton disabled={selectedType === undefined} />
          <AppleAuthButton disabled={selectedType === undefined} />
        </>
      )}
      {!isReactNativeApp && (
        <Row center>
          <Text variant="body2">
            Already have a business account? <Link to="/login">Log in</Link>
          </Text>
        </Row>
      )}
    </>
  )
  return (
    <AuthScreenWrapper
      helmetTitle={pageTitle}
      rightCol={<LoginRightCol />}
      leftCol={leftCol}
    />
  )
}
