import { Skeleton } from '@mui/material'
import {
  RoleSearchSelect,
  SupervisorForShiftSearchSelect,
} from '@traba/react-components'
import { CompanyCategory, ShiftPayType } from '@traba/types'
import {
  getMembersWithAccessAtLocation,
  getRoleSearchSelectErrorMessage,
  getSelectSupervisorForBookingShiftErrorMessage,
  getUserFullName,
  recurringSchedulesEnabled,
} from '@traba/utils'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, InlineBanner, Modal, Row } from 'src/components'
import { LocationSingleSearchSelector } from 'src/components/LocationSingleSearchSelector'
import { CreateMemberModal } from 'src/components/Modals/CreateMemberModal'
import { CreateOrEditLocationModal } from 'src/components/Modals/CreateOrEditLocationModal/CreateOrEditLocationModal'
import { CreateRoleModal } from 'src/components/Modals/CreateRoleModal'
import { CreateOrEditInvoiceGroupModal } from 'src/components/Modals/InvoiceGroups/CreateOrEditInvoiceGroupModal'
import { useCompany } from 'src/hooks/useCompany'
import { useCompanyEmploymentType } from 'src/hooks/useCompanyEmploymentType'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { getActiveRegion, useLocations } from 'src/hooks/useLocations'
import { useMembers } from 'src/hooks/useMembers'
import { useSelectedRegionalFilterLocations } from 'src/hooks/useRegionalFilter'
import { useRoles } from 'src/hooks/useRoles'
import { PAY_RATE_DEFAULT } from 'src/hooks/useShiftRequests'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import { RoleCreationContent } from 'src/screens/AddToExistingSchedule/components/RoleCreationContent'
import EditShiftsSaveButton from 'src/screens/EditShifts/EditShiftsSaveButton'
import { BookShiftsProps } from '../BookShiftsScreen'
import { CustomDropdownSearchSelectContainer } from '../BookShiftsScreen.styles'
import { BookShiftStepSection, Section } from '../components/BookShiftSection'
import {
  getErrorMessageForLocationSelection,
  validateShiftEdits,
  validateSiteStep,
  validateSiteStepShiftDataModel,
} from '../validation'
import { BookShiftsInvoiceGroupSection } from './sections/BookShiftsInvoiceGroupSection'
import { BookShiftsParkingLocationSection } from './sections/BookShiftsParkingLocationSection'
import { BookShiftsSiteContactSection } from './sections/BookShiftsSiteContactSection'
import { BookShiftsSiteLocationSection } from './sections/BookShiftsSiteLocationSection'
import { BookShiftsSiteRoleSection } from './sections/BookShiftsSiteRoleSection'

type BookShiftsSiteContentModal =
  | 'ROLE'
  | 'ROLE_PREVIOUS'
  | 'LOCATION'
  | 'PARKING_LOCATION'
  | 'CONTACT'
  | 'INVOICE_GROUP'

export function BookShiftsDetailsContent(props: BookShiftsProps) {
  const {
    shiftRequest,
    setShiftRequest,
    shiftRequestMetadata,
    setShiftRequestMetadata,
    onContinue,
    onSaveChanges,
    selectedShifts,
    setShowSelectModal,
    shiftUpdates,
    isEdit,
    defaultShiftRequest,
    userHasLimitedShiftManagementAbility,
  } = props
  const { roles, isLoading: isLoadingRoles } = useRoles()
  const { activeLocations, isLoading: isLoadingLocations } = useLocations()
  const { members, isLoading: isLoadingMembers } = useMembers()
  const { company, isLoading: isLoadingCompany } = useCompany()
  const { hotSettings } = useHotSettings()

  const isEventCompany = company?.category === CompanyCategory.EVENTS
  const {
    activeInvoiceGroups: invoiceGroups,
    isLoading: isLoadingInvoiceGroups,
  } = useInvoiceGroups()

  const [modalType, setModalType] = useState<BookShiftsSiteContentModal | null>(
    null,
  )
  function closeModal() {
    setModalType(null)
  }

  const {
    roleId,
    locationId,
    parkingLocationId,
    supervisorId,
    parentInvoiceGroupId,
    payType,
  } = shiftRequest

  const postalCode = activeLocations.find((l) => l.locationId === locationId)
    ?.address?.postalCode

  const parkingPostalCode = activeLocations.find(
    (l) => l.locationId === parkingLocationId,
  )?.address?.postalCode

  useEffect(() => {
    async function checkActiveRegion() {
      if (postalCode) {
        const active = await getActiveRegion(postalCode)
        setShiftRequestMetadata({ activeRegion: !!active })
        !active &&
          window.analytics.track(
            `User Selected Location Outside of Active Regions`,
            { postalCode, isEdit },
          )
      }
    }

    checkActiveRegion()
  }, [postalCode, setShiftRequestMetadata, isEdit])

  useEffect(() => {
    async function checkActiveRegion() {
      if (parkingPostalCode) {
        const active = await getActiveRegion(parkingPostalCode)
        setShiftRequestMetadata({ activeParkingRegion: !!active })
        !active &&
          window.analytics.track(
            `User Selected Parking Location Outside of Active Regions`,
            { parkingPostalCode, isEdit },
          )
      }
    }

    checkActiveRegion()
  }, [parkingPostalCode, setShiftRequestMetadata, isEdit])

  useEffect(() => {
    const isShiftRequestCompanyMembersUpToDate =
      (shiftRequestMetadata.companyUsers?.length || 0) === members.length
    if (!isShiftRequestCompanyMembersUpToDate && !isLoadingMembers) {
      setShiftRequestMetadata({ companyUsers: members })
    }
  }, [
    members,
    setShiftRequestMetadata,
    isLoadingMembers,
    shiftRequestMetadata.companyUsers?.length,
  ])

  const baseLocationOptions = {
    singular: 'location',
    plural: 'locations',
    options: activeLocations.map((l) =>
      l.name
        ? {
            value: l.locationId,
            label: l.name,
            subtitle: `${l.address.street1}, ${l.address.city}, ${l.address.state}`,
          }
        : {
            value: l.locationId,
            label: `${l.address.street1}, ${l.address.city}, ${l.address.state}`,
          },
    ),
  }

  function onCreateRole() {
    setModalType('ROLE')
    window.analytics.track(`User Clicked Create New Role`, { isEdit })
  }
  function onCreateRoleFromPrevious() {
    setModalType('ROLE_PREVIOUS')
    window.analytics.track(`User Clicked Create Role From Templates`, {
      isEdit,
    })
  }

  const onChangeRole = useCallback(
    (v: string) => {
      const selectedRole = roles.find((r) => r.roleId === v)
      const rolePayRate = selectedRole?.defaultPayRate ?? PAY_RATE_DEFAULT
      setShiftRequest({
        roleId: v,
        ...(payType === ShiftPayType.HOURLY && {
          payRate: rolePayRate,
        }),
        genderPreference: selectedRole?.genderPreference,
      })
      window.analytics.track(`User Selected Role`, {
        roleId: v,
        isEdit,
      })
    },
    [isEdit, payType, roles, setShiftRequest],
  )

  const onCreateNewParkingLocation = useCallback(() => {
    setModalType('PARKING_LOCATION')
    window.analytics.track(`User Clicked Create New Parking Location`, {
      isEdit,
    })
  }, [isEdit])

  const onChangeParkingLocation = useCallback(
    (parkingLocationId?: string) => {
      if (!parkingLocationId) {
        return
      }

      setShiftRequest({
        parkingLocationId,
      })
      window.analytics.track(`User Selected Parking Location`, {
        parkingLocationId,
        isEdit,
      })
    },
    [isEdit, setShiftRequest],
  )

  const onCreateLocation = useCallback(() => {
    setModalType('LOCATION')
    window.analytics.track(`User Clicked Create New Location`, {
      isEdit,
    })
  }, [isEdit])

  const onChangeLocation = useCallback(
    (locationId?: string) => {
      if (!locationId) {
        return
      }
      const location = activeLocations.find((l) => l.locationId === locationId)
      setShiftRequest({
        locationId,
        schedules: location
          ? [
              {
                ...shiftRequest.schedules[0],
                timeZone: location.timezone,
              },
            ]
          : shiftRequest.schedules,
      })
      window.analytics.track(`User Selected Location`, {
        locationId,
        isEdit,
      })
    },
    [activeLocations, shiftRequest, isEdit, setShiftRequest],
  )

  const selectedRole = roles.find((role) => role.roleId === roleId)
  const { selectedActiveLocationIds } = useSelectedRegionalFilterLocations()
  const sortedDisplayedRolesForDropdown = useMemo(() => {
    const rolesToDisplay = hotSettings?.enableRegionalAccessPhase2
      ? roles.filter(
          (r) =>
            !r.location?.locationId ||
            r.location.locationId === locationId ||
            selectedActiveLocationIds.has(r.location.locationId),
        )
      : roles
    return rolesToDisplay.sort((a, b) => a.roleName.localeCompare(b.roleName))
  }, [
    roles,
    selectedActiveLocationIds,
    hotSettings?.enableRegionalAccessPhase2,
    locationId,
  ])

  const { showInvoicing } = useCompanyEmploymentType()

  const hideInvoicing =
    !showInvoicing ||
    recurringSchedulesEnabled({
      companyId: company?.companyId,
      hotSettings,
    })

  const supervisorsForLocation = locationId
    ? getMembersWithAccessAtLocation({ members, locationId })
    : members
  const supervisorOptions = supervisorsForLocation.map((supervisor) => ({
    value: supervisor.uid!,
    label: getUserFullName(supervisor),
  }))

  const errorMessageForSupervisor =
    getSelectSupervisorForBookingShiftErrorMessage({
      locationId,
      supervisor: members.find((s) => s.uid === supervisorId),
      isRebook: props.isRebook,
      isRegionalAccessEnabled: hotSettings?.enableRegionalAccessPhase2,
    })

  const onChangeSupervisor = useCallback(
    (supervisorId: string) => {
      setShiftRequest({ supervisorId })
      window.analytics.track(`User Selected Contact`, {
        supervisorId,
        isEdit,
      })
    },
    [setShiftRequest],
  )

  const sections: Array<Section> = [
    // TODO(gavin): shift data model - this goes into it's own section in the flow
    ...(recurringSchedulesEnabled({
      companyId: company?.companyId,
      hotSettings,
    })
      ? []
      : [
          {
            ...baseLocationOptions,
            title: 'Work site location',
            selected: locationId,
            onChange: onChangeLocation,
            onCreate: onCreateLocation,
            optional: false,
            hide: false,
            contentExpanded: true,
            errorMessage:
              'The location in the previous shift request was archived. Please select a new location.',
            Content: BookShiftsSiteLocationSection,
            customDropdown: (
              <CustomDropdownSearchSelectContainer>
                <LocationSingleSearchSelector
                  placeholder="Choose from existing locations"
                  selectedLocationId={locationId}
                  onChange={onChangeLocation}
                  label=""
                  errorMessage={
                    props.isRebook && !locationId
                      ? getErrorMessageForLocationSelection({
                          isParkingLocationSelection: false,
                          isRegionalAccessPhase2Enabled:
                            hotSettings?.enableRegionalAccessPhase2,
                        })
                      : undefined
                  }
                  selectStyle={{ height: '48px' }}
                  style={{ maxWidth: '100%' }}
                  hideCompanyWideOption
                />
              </CustomDropdownSearchSelectContainer>
            ),
          },
          {
            title: 'Role',
            singular: 'role',
            plural: 'roles',
            options: sortedDisplayedRolesForDropdown.map((r) => ({
              value: r.roleId,
              label: r.roleName,
            })),
            selected: roleId,
            onChange: onChangeRole,
            onCreate: onCreateRole,
            optional: false,
            hide: false,
            contentExpanded: true,
            errorMessage:
              'The role in the previous shift request was archived. Please select a new role.',
            Content: BookShiftsSiteRoleSection,
            CreationContent: () => {
              return (
                <RoleCreationContent
                  cannotCreateRole={userHasLimitedShiftManagementAbility}
                  roles={roles}
                  isEventCompany={isEventCompany}
                  onCreateRole={onCreateRole}
                  onCreateRoleFromPrevious={onCreateRoleFromPrevious}
                />
              )
            },
            customDropdown: (
              <RoleSearchSelect
                roles={sortedDisplayedRolesForDropdown}
                selectedRoleId={roleId}
                selectedLocationId={locationId}
                handleRoleChange={onChangeRole}
                placeholder={'Choose from existing roles'}
                errorMessage={getRoleSearchSelectErrorMessage(
                  locationId,
                  selectedRole,
                )}
                selectStyle={{ height: '48px' }}
                style={{ maxWidth: '100%' }}
              />
            ),
          },
        ]),

    {
      ...baseLocationOptions,
      title: 'Parking location',
      selected: parkingLocationId || '',
      onChange: onChangeParkingLocation,
      onCreate: onCreateNewParkingLocation,
      hide: false,
      contentExpanded: shiftRequestMetadata.parkingLocationExpanded,
      checkboxLabel: 'Same as work site location',
      errorMessage:
        'The parking location in the previous shift request was archived. Please select a new parking location.',
      onClickCheckbox: () => {
        shiftRequestMetadata.parkingLocationExpanded &&
          setShiftRequest({ parkingLocationId: '' })
        setShiftRequestMetadata({
          parkingLocationExpanded:
            !shiftRequestMetadata.parkingLocationExpanded,
        })
      },
      Content: BookShiftsParkingLocationSection,
      customDropdown: (
        <CustomDropdownSearchSelectContainer>
          <LocationSingleSearchSelector
            placeholder="Choose from existing locations"
            selectedLocationId={parkingLocationId}
            onChange={onChangeParkingLocation}
            label=""
            errorMessage={
              props.isRebook && !parkingLocationId
                ? getErrorMessageForLocationSelection({
                    isParkingLocationSelection: true,
                    isRegionalAccessPhase2Enabled:
                      hotSettings?.enableRegionalAccessPhase2,
                  })
                : undefined
            }
            selectStyle={{ height: '48px' }}
            style={{ maxWidth: '100%' }}
            hideCompanyWideOption
          />
        </CustomDropdownSearchSelectContainer>
      ),
    },
    {
      title: 'Onsite contact',
      description:
        "Onsite contact will also receive text messages with shift clock in & out codes 30min before shift start time, unless they've opted out of Traba messages.",
      singular: 'contact',
      plural: 'contacts',
      options: supervisorOptions,
      selected: supervisorId,
      onChange: onChangeSupervisor,
      onCreate: () => {
        setModalType('CONTACT')
        window.analytics.track(`User Clicked Create New Contact`, { isEdit })
      },
      optional: false,
      contentExpanded: true,
      hide: false,
      errorMessage: errorMessageForSupervisor,
      Content: BookShiftsSiteContactSection,
      customDropdown: (
        <CustomDropdownSearchSelectContainer>
          <SupervisorForShiftSearchSelect
            placeholder="Choose from existing contacts"
            selectedSupervisorId={supervisorId}
            selectedLocationId={locationId}
            allSupervisors={members}
            handleSupervisorChange={onChangeSupervisor}
            disabled={!locationId}
            disabledTooltipText="Please select a work site location before selecting a supervisor."
            label=""
            errorMessage={errorMessageForSupervisor}
            selectStyle={{ height: '48px' }}
            style={{ maxWidth: '100%' }}
          />
        </CustomDropdownSearchSelectContainer>
      ),
    },
    // TODO(gavin): shift data model - this goes into it's own section in the flow
    ...(hideInvoicing
      ? []
      : [
          {
            title: 'Invoice group',
            singular: 'invoice group',
            plural: 'invoice groups',
            options: invoiceGroups.map((group) => ({
              value: group.id,
              label: group.name,
            })),
            selected: parentInvoiceGroupId,
            onChange: (groupId: string) => {
              setShiftRequest({
                parentInvoiceGroupId: groupId,
              })
              window.analytics.track(`User Selected Invoice Group`, {
                parentInvoiceGroupId: groupId,
                isEdit,
              })
            },
            onCreate: () => {
              setModalType('INVOICE_GROUP')
              window.analytics.track(`User Clicked Create New Invoice Group`, {
                isEdit,
              })
            },
            description:
              "Use invoice groups to customize how you are invoiced and better reconcile your spend. If the group's shifts span over a week, it will be split into multiple invoices. Choose from an existing invoice group or create a new one below.",
            tooltip:
              'You can choose which shifts are grouped together to receive separate invoices by role, location, or event if desired. For example, if you had two locations for your company, you could create one reusable invoice group for each. Then, you would add all shifts at Location 1 to the Location 1 invoice group and would receive invoices grouped by location.',
            optional: true,
            contentExpanded: true,
            hide: false,
            Content: BookShiftsInvoiceGroupSection,
          },
        ]),
  ]

  const validationError = recurringSchedulesEnabled({
    companyId: company?.companyId,
    hotSettings,
  })
    ? validateSiteStepShiftDataModel(shiftRequest, shiftRequestMetadata)
    : validateSiteStep(shiftRequest, shiftRequestMetadata)

  const editsError =
    selectedShifts &&
    defaultShiftRequest &&
    validateShiftEdits(defaultShiftRequest, selectedShifts, shiftUpdates)

  if (
    isLoadingRoles ||
    isLoadingLocations ||
    isLoadingMembers ||
    isLoadingCompany ||
    isLoadingInvoiceGroups
  ) {
    return (
      <>
        {Array.from(Array(4)).map((_, index) => (
          <React.Fragment key={`bookshiftdetailscontent_${index}`}>
            <Skeleton animation="pulse" width="100%" height="60px" />
            <Skeleton animation="pulse" width="100%" height="120px" />
          </React.Fragment>
        ))}
      </>
    )
  }

  return (
    <>
      {sections
        .filter((s) => !s.hide)
        .map((s, i, arr) => (
          <BookShiftStepSection
            key={i}
            section={s}
            bookShiftProps={props}
            noDivider={i === arr.length - 1}
          />
        ))}

      {isEdit && editsError && (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity={'error'}
          text={editsError.message}
        />
      )}

      <Row style={{ justifyContent: 'flex-end', marginTop: theme.space.lg }}>
        {isEdit ? (
          <EditShiftsSaveButton
            onSaveChanges={onSaveChanges}
            validationError={editsError || validationError}
            selectedShifts={selectedShifts}
            setShowSelectModal={setShowSelectModal}
          />
        ) : (
          <Button onClick={onContinue} disabled={!!validationError}>
            Continue
          </Button>
        )}
      </Row>
      <Modal isOpen={modalType !== null} handleClose={closeModal}>
        {modalType && ['ROLE', 'ROLE_PREVIOUS'].includes(modalType) ? (
          <CreateRoleModal
            onClose={closeModal}
            onCreate={(r) =>
              setShiftRequest({
                roleId: r.roleId,
                payRate: r.defaultPayRate,
                genderPreference: r.genderPreference,
              })
            }
            isEventCompany={isEventCompany}
            fromPrevious={modalType === 'ROLE_PREVIOUS'}
            filterLocationId={shiftRequest.locationId}
          />
        ) : modalType === 'LOCATION' ? (
          <CreateOrEditLocationModal
            setShowModal={closeModal}
            onCreate={(l) =>
              setShiftRequest({
                locationId: l.locationId,
                schedules: [
                  {
                    ...shiftRequest.schedules[0],
                    timeZone: l.timezone,
                  },
                ],
              })
            }
          />
        ) : modalType === 'PARKING_LOCATION' ? (
          <CreateOrEditLocationModal
            setShowModal={closeModal}
            onCreate={(l) =>
              setShiftRequest({ parkingLocationId: l.locationId })
            }
          />
        ) : modalType === 'CONTACT' ? (
          <CreateMemberModal
            onCloseModal={closeModal}
            onCreate={(c) => setShiftRequest({ supervisorId: c.uid! })}
          />
        ) : modalType === 'INVOICE_GROUP' ? (
          <CreateOrEditInvoiceGroupModal
            onClose={closeModal}
            onCreateOrEdit={(newGroup) =>
              setShiftRequest({
                parentInvoiceGroupId: newGroup.id,
              })
            }
          />
        ) : null}
      </Modal>
    </>
  )
}
