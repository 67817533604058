import {
  Input,
  Row,
  Text,
  SvgIcon,
  LoadingSpinner,
} from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { LocationResponse, RecordStatus, Region } from '@traba/types'
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { useAppContext } from 'src/context/appContext/AppContext'
import { useLocationRegions } from 'src/hooks/useLocationRegions'
import { useLocations } from 'src/hooks/useLocations'
import { ARCHIVED_REGION_ID } from 'src/hooks/useRegionalFilter'
import { OUTSIDE_REGION_ID } from 'src/hooks/useRegions'
import { theme } from 'src/libs/theme'
import { getAddressString } from 'src/utils/stringUtils'
import CheckboxGroups from '../base/RegionalFilterSelect/CheckboxGroups'

export interface LocationData {
  validLocations: LocationResponse[]
  regionLocationMap: Record<string, LocationResponse[]>
  uniqueLocations: LocationResponse[]
}

interface LocationSearchAndSelectProps {
  selectedLocations: Set<string>
  setSelectedLocations: React.Dispatch<React.SetStateAction<Set<string>>>
  onLocationsLoaded: (locationData: LocationData) => void
  hideArchivedLocations?: boolean
  children?: React.ReactElement
}

export function LocationSearchAndSelect({
  selectedLocations,
  setSelectedLocations,
  onLocationsLoaded,
  hideArchivedLocations,
  children,
}: LocationSearchAndSelectProps) {
  const { isLoading: isRegionLoading, regions = [] } = useLocationRegions()
  const { isLoading: isLocationLoading, uniqueLocations } = useLocations()
  const [expandedRegions, setExpandedRegions] = useState<
    Record<string, boolean>
  >({})
  const [searchText, setSearchText] = useState('')
  const locationDataLoaded = useRef(false)
  const { state } = useAppContext()

  const isLoading = isRegionLoading || isLocationLoading

  const regionMap = useMemo(() => {
    const validRegions = regions.filter(
      (region) => region.regionId !== OUTSIDE_REGION_ID,
    )
    return validRegions.reduce<Record<string, Region>>((acc, region) => {
      acc[region.regionId] = region
      return acc
    }, {})
  }, [regions])

  const [validLocations, regionLocationMap] = useMemo(() => {
    const validLocations = uniqueLocations.filter(
      (location) => location.regionId !== OUTSIDE_REGION_ID,
    )
    return [
      validLocations,
      validLocations.reduce<Record<string, LocationResponse[]>>(
        (acc, location) => {
          const key =
            location.recordStatus === RecordStatus.Archived
              ? ARCHIVED_REGION_ID
              : location.regionId
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key].push(location)
          return acc
        },
        {},
      ),
    ]
  }, [uniqueLocations])

  const allLocations = useMemo(() => {
    return new Set(validLocations.map((location) => location.locationId))
  }, [validLocations])

  const allExpandedRegions = useMemo(() => {
    return Object.keys(regionLocationMap).reduce<Record<string, boolean>>(
      (acc, regionId) => {
        acc[regionId] = true
        return acc
      },
      {},
    )
  }, [regionLocationMap])

  const regionIdToFilteredLocations = useMemo(() => {
    return validLocations.reduce<Record<string, LocationResponse[]>>(
      (acc, location) => {
        const lowerCaseSearchText = searchText.toLowerCase()
        if (
          (location.name ?? 'N/A')
            .toLowerCase()
            .includes(lowerCaseSearchText) ||
          getAddressString(location.address)
            .toLowerCase()
            .includes(lowerCaseSearchText)
        ) {
          const key =
            location.recordStatus === RecordStatus.Archived
              ? ARCHIVED_REGION_ID
              : location.regionId
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key].push(location)
        }
        return acc
      },
      {},
    )
  }, [searchText, validLocations])

  const filteredLocationIds = useMemo(() => {
    if (searchText === '') {
      return Array.from(allLocations)
    }
    return Object.values(regionIdToFilteredLocations).reduce<string[]>(
      (acc, locations) => {
        locations.forEach((location) => acc.push(location.locationId))
        return acc
      },
      [],
    )
  }, [regionIdToFilteredLocations, allLocations, searchText])

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchText(e.target.value)

      if (e.target.value === '') {
        setExpandedRegions(
          Object.keys(regionLocationMap).reduce<Record<string, boolean>>(
            (acc, regionId) => {
              const locations = regionLocationMap[regionId]
              for (const location of locations) {
                if (selectedLocations.has(location.locationId)) {
                  acc[regionId] = true
                  break
                }
              }
              return acc
            },
            {},
          ),
        )
      } else {
        setExpandedRegions(allExpandedRegions)
      }
    },
    [regionLocationMap, selectedLocations, allExpandedRegions],
  )

  const selectAllFilteredLocations = () => {
    // If user is not searching, select or unselect all entire locations
    if (searchText === '') {
      if (selectedLocations.size === allLocations.size) {
        setSelectedLocations(new Set())
        setExpandedRegions({})
        return
      }
      setSelectedLocations(allLocations)
      setExpandedRegions(allExpandedRegions)
      return
    }

    // Check if all of the searched locations are already selected, if it is, unselect them
    const newSelectedLocations = new Set(selectedLocations)
    if (
      filteredLocationIds.every((locationId) =>
        selectedLocations.has(locationId),
      )
    ) {
      for (const locationId of filteredLocationIds) {
        newSelectedLocations.delete(locationId)
      }
    } else {
      for (const locationId of filteredLocationIds) {
        newSelectedLocations.add(locationId)
      }
    }

    setSelectedLocations(newSelectedLocations)
  }

  useEffect(() => {
    if (
      !isLoading &&
      validLocations.length > 0 &&
      !locationDataLoaded.current
    ) {
      onLocationsLoaded({
        validLocations,
        regionLocationMap,
        uniqueLocations,
      })
      locationDataLoaded.current = true
    }
  }, [
    isLoading,
    validLocations,
    regionLocationMap,
    uniqueLocations,
    onLocationsLoaded,
  ])

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Row flexCol pr={theme.space.xxs} pl={theme.space.xxs}>
      <Input
        type="text"
        containerStyle={{
          marginTop: theme.space.xs,
          width: '20%',
        }}
        onChange={handleSearchChange}
        placeholder="Search for location"
        leftIconName="search"
      />

      <Row>
        <Text
          variant="h6"
          ml={theme.space.xxs}
          mr={theme.space.xs}
          mt={theme.space.xxs}
        >
          {filteredLocationIds.length}
          {' result'}
          {makePlural(filteredLocationIds.length)}
        </Text>
        <Text
          variant="link"
          color={theme.colors.brand}
          onClick={selectAllFilteredLocations}
          mt={theme.space.xxs}
        >
          {selectedLocations.size === allLocations.size
            ? 'Unselect all'
            : 'Select all'}
        </Text>
      </Row>

      <Row
        style={{
          overflowY: 'auto',
          height: !state.isReactNativeApp ? '220px' : '60vh',
        }}
      >
        {validLocations.length === 0 ? (
          <Text
            variant="body2"
            mt={theme.space.xxs}
            ml={theme.space.xxs}
            color={theme.colors.MidnightBlue}
          >
            <Text>
              No location is found in the active regions where Traba operates.
              To resolve this, you can:
            </Text>
            <Text>
              1. Contact your admin for access to additional locations.
            </Text>
            <Text>
              2. Create a new location in an active region via Account Settings
              (if you don't have access, proceed to step 3).
            </Text>
            <Text>
              3. Contact support if you need to operate in a currently inactive
              region.
            </Text>
          </Text>
        ) : (
          <CheckboxGroups
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
            regionMap={regionMap}
            expandedRegions={expandedRegions}
            setExpandedRegions={setExpandedRegions}
            searchText={searchText}
            regionIdToFilteredLocations={regionIdToFilteredLocations}
            hideArchivedLocations={hideArchivedLocations}
          />
        )}
      </Row>
      <Row
        justifyBetween
        mt={theme.space.zero}
        pt={theme.space.xs}
        style={{
          borderTopWidth: 1,
          borderTopColor: theme.colors.Grey20,
          borderTopStyle: 'solid',
        }}
      >
        <Row justifyStart mt={theme.space.xxs}>
          {selectedLocations.size > 0 && (
            <>
              <Text variant="h6" style={{ marginLeft: theme.space.xs }}>
                {selectedLocations.size} locations selected
              </Text>
              {!state.isReactNativeApp && (
                <>
                  <Text
                    variant="link"
                    style={{ marginLeft: theme.space.xs }}
                    onClick={() => {
                      setSelectedLocations(new Set())
                      setExpandedRegions({})
                    }}
                  >
                    Clear all
                  </Text>
                  <SvgIcon
                    name="cancel"
                    color={theme.colors.brand}
                    style={{
                      marginLeft: theme.space.xxxs,
                      marginTop: theme.space.xms,
                    }}
                    size={12}
                  />
                </>
              )}
            </>
          )}
        </Row>
        {children && children}
      </Row>
    </Row>
  )
}
