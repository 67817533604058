import { useAlert } from '@traba/context'
import { MODAL_SIZE } from '@traba/react-components'
import { Roster, Worker } from '@traba/types'
import { useForm } from '@traba/utils'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import { useState } from 'react'
import { UseMutateFunction } from 'react-query'
import { Input } from 'src/components/base'
import { Dialog } from 'src/components/base/Dialog/Dialog'
import { SelectWorkers } from 'src/components/SelectWorkers/SelectWorkers'
import { WorkerDetails } from 'src/hooks/useCompanyWorkers'
import { RosterWithWorkers, UpdateRosterDto } from 'src/hooks/useRoster'
import { theme } from 'src/libs/theme'
import * as yup from 'yup'

export function EditRosterModal({
  workers,
  roster,
  setRoster,
  updateRoster,
}: {
  workers?: (WorkerDetails | Worker)[]
  roster: Roster | RosterWithWorkers
  setRoster: (
    value: React.SetStateAction<Roster | RosterWithWorkers | undefined>,
  ) => void
  updateRoster: UseMutateFunction<
    Roster | RosterWithWorkers,
    AxiosError,
    UpdateRosterDto
  >
}) {
  /**
   * 'shiftHistory' check is necessary because this modal is used on screens where we have
   * a) worker as WorkerDetails (on WorkersScreen)
   * b) worker as Worker (from the worker shift response on TimeSheetDetailsScreen, UpcomingShiftDetails)
   */
  const getWorkerId = (worker: WorkerDetails | { workerId: string }) => {
    return 'worker' in worker ? worker.worker.uid : worker.workerId
  }

  const [workersToAdd, setWorkersToAdd] = useState(new Set<string>())
  const [workersToRemove, setWorkersToRemove] = useState(new Set<string>())
  const workersOnRoster = new Set(roster?.workers.map((w) => getWorkerId(w)))

  const getNewWorkerSet = (workerSet: Set<string>, workerId: string) => {
    const newWorkers = new Set(workerSet)
    let selected = false
    if (workerSet.has(workerId)) {
      newWorkers.delete(workerId)
    } else {
      selected = true
      newWorkers.add(workerId)
    }
    return { newWorkers, selected }
  }

  const onClickWorker = (workerId: string) => {
    const workerOnRoster = workersOnRoster.has(workerId)
    if (workerOnRoster) {
      const { newWorkers, selected } = getNewWorkerSet(
        workersToRemove,
        workerId,
      )
      window.analytics?.track(
        `User ${selected ? 'Selected' : 'Unselected'} Worker To Remove`,
        { workerId, rosterId: roster.id },
      )
      setWorkersToRemove(newWorkers)
    } else {
      const { newWorkers, selected } = getNewWorkerSet(workersToAdd, workerId)
      window.analytics?.track(
        `User ${selected ? 'Selected' : 'Unselected'} Worker To Add`,
        { workerId, rosterId: roster.id },
      )
      setWorkersToAdd(newWorkers)
    }
  }

  const { handleOnSubmitError } = useForm()
  const { handleError } = useAlert()

  const formik = useFormik({
    initialValues: { rosterName: roster?.rosterName || '' },
    validationSchema: yup.object({
      rosterName: yup.string().required('Please enter a roster name'),
    }),
    onSubmit: async (values) => {
      try {
        const reqBody: UpdateRosterDto = {
          ...values,
          addWorkers: Array.from(workersToAdd),
          removeWorkers: Array.from(workersToRemove),
          rosterId: roster.id,
        }
        window.analytics?.track('User Clicked Update Roster', { ...reqBody })
        formik.setStatus({})
        updateRoster(reqBody, {
          onError: (error) => {
            const message =
              'There was an error updating the roster. Please try again or contact support if the issue persists.'
            handleError(
              error,
              'EditRosterModal -> updateRoster()',
              message,
              'Error updating roster',
            )
          },
        })
        setWorkersToAdd(new Set())
        setRoster(undefined)
        formik.resetForm()
      } catch (err) {
        handleOnSubmitError(err, formik)
      }
    },
  })
  const { errors, touched, isValid, values } = formik
  const rosterNameChanged = values.rosterName !== roster.rosterName
  const workersChanged = workersToAdd.size > 0 || workersToRemove.size > 0
  const canContinue = isValid && (rosterNameChanged || workersChanged)

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="paper"
      open={!!roster}
      onClose={() => setRoster(undefined)}
      onConfirmCTA="Save Changes"
      dialogTitle={`Edit ${roster?.rosterName}`}
      confirmDisabled={!canContinue}
      formId="create-roster"
      size={MODAL_SIZE.LARGE}
    >
      <form id="create-roster" onSubmit={formik.handleSubmit}>
        <Input
          placeholder="Roster Name"
          containerStyle={{
            marginTop: theme.space.xxs,
            marginBottom: theme.space.xs,
          }}
          width="100%"
          {...formik.getFieldProps('rosterName')}
          errorMessage={errors.rosterName}
          inputStatus={
            touched.rosterName && errors.rosterName && formik.touched ? 3 : 1
          }
        />
        <SelectWorkers
          selectedWorkers={workersToAdd}
          selectedWorkersRemove={workersToRemove}
          setSelectedWorkers={setWorkersToAdd}
          onClickWorker={onClickWorker}
          workers={workers}
          workersOnList={workersOnRoster}
        />
      </form>
    </Dialog>
  )
}
