import { LoadingSpinner } from '@traba/react-components'
import { DailyViewOfScheduleDetails } from '@traba/types'
import { useShifts } from 'src/hooks/useShifts'
import { ScheduleDailyViewWorkerTable } from './ScheduleDailyViewWorkerTable'

interface ScheduleDailyViewWorkersProps {
  shiftRequestParentId: string
  dayOfShift: string
  shiftRequestParentDayDetails: DailyViewOfScheduleDetails | undefined
}

export const ScheduleDailyViewWorkers = (
  props: ScheduleDailyViewWorkersProps,
) => {
  const { shiftRequestParentDayDetails, shiftRequestParentId, dayOfShift } =
    props
  const individualMetadata = Object.values(
    shiftRequestParentDayDetails?.shiftRequestToShiftMetadataMap ?? {},
  )
  const shiftIds = individualMetadata.map((metadata) => metadata.shift.id)
  const { data: shifts, isLoading } = useShifts({ shiftIds })
  if (!individualMetadata || isLoading) {
    return <LoadingSpinner />
  }

  return shiftIds ? (
    <ScheduleDailyViewWorkerTable
      shifts={shifts}
      shiftRequestParentId={shiftRequestParentId}
      dayOfShift={dayOfShift}
    />
  ) : null
}
