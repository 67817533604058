import { trabaApi } from '@traba/api-utils'
import { Timesheet } from '@traba/types'
import { useQuery } from 'react-query'

async function getTimesheet(shiftId: string): Promise<Timesheet | undefined> {
  try {
    const response = await trabaApi.get(
      `/my-company/shifts/${shiftId}/timesheets/most-recent`,
      {
        validateStatus: function (status) {
          return (status >= 200 && status < 300) || status === 404
        },
      },
    )
    if (response.status === 404) {
      return
    }

    return response.data
  } catch (error) {
    console.log(error)
  }
}

// TODO(Joey - ENG-9545) - remove after server endpoint is migrated
export const useTimesheetForShift = (shiftId: string, enabled = true) => {
  const {
    isLoading,
    isError,
    data: timesheet,
    error,
    isFetched,
    refetch,
    isFetching,
  } = useQuery<Timesheet | undefined, Error>(
    `timesheet_${shiftId}`,
    () => getTimesheet(shiftId),
    { enabled },
  )

  return {
    isLoading,
    isFetching,
    isError,
    error,
    isFetched,
    timesheet,
    refetch,
  }
}
