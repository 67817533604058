import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Col, Row } from 'src/components'

interface MonthAndDayTileProps {
  month: string
  day: string
}

export const MonthAndDayTile: React.FC<MonthAndDayTileProps> = ({
  month,
  day,
}) => {
  return (
    <Row
      flexCol={true}
      alignCenter
      style={{
        padding: theme.space.xxxs,
        border: `1px solid ${theme.colors.Grey}`,
        borderRadius: theme.space.xxs,
        minWidth: 100,
      }}
    >
      {!month ? (
        <Col>
          <Text
            variant="caption"
            color={theme.colors.MidnightBlue}
            style={{
              fontSize: 18,
              paddingTop: theme.space.xs,
              paddingBottom: theme.space.xs,
            }}
          >
            {day}
          </Text>
        </Col>
      ) : (
        <>
          <Col>
            <Text
              variant="caption"
              color={theme.colors.MidnightBlue}
              style={{
                marginBottom: theme.space.xxs,
                paddingTop: theme.space.xxxs,
              }}
            >
              {month}
            </Text>
          </Col>
          <Col>
            <Text
              variant="caption"
              color={theme.colors.MidnightBlue}
              style={{ fontSize: 18, paddingBottom: theme.space.xxxs }}
            >
              {day}
            </Text>
          </Col>
        </>
      )}
    </Row>
  )
}
