import * as Sentry from '@sentry/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import { ELIGIBILITY_CONNECTIONS_QUERY_KEY } from '@traba/hooks'
import {
  EligibilityConnectionMutationPayload,
  EligibilityConnectionResponse,
} from '@traba/types'
import { AxiosResponse } from 'axios'

export type BusinessFilters = {
  locations: string[]
}

const getBizEligibilityConnections = async (
  workerId: string,
  businessFilters: BusinessFilters,
) => {
  try {
    const res = await trabaApi.post(
      `my-company/query-eligibility-connections/`,
      {
        workerId,
        locationIds: businessFilters?.locations,
      },
    )
    return res.data
  } catch (error) {
    console.error(
      'useBizEligibilityConnections -> getBizEligibilityConnections() ERROR',
      error,
    )
    Sentry.captureException(error)
    throw error
  }
}

export const useBizEligibilityConnections = ({
  workerId,
  businessFilters,
}: {
  workerId: string
  businessFilters: BusinessFilters
}) => {
  const { isLoading, isError, data, error, isFetched } = useQuery<
    EligibilityConnectionResponse,
    Error
  >({
    queryKey: [ELIGIBILITY_CONNECTIONS_QUERY_KEY, workerId],
    queryFn: () => {
      return getBizEligibilityConnections(workerId, businessFilters)
    },
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    eligibilityConnections: data?.eligibilityConnections,
    globalEligibilityConnection: data?.globalEligibilities,
    isLoading,
    isError,
    error,
    isFetched,
  }
}

export function useBizEligibilityConnectionsMutation() {
  const { showError, showSuccess } = useAlert()
  const queryClient = useQueryClient()

  const mutation = useMutation<
    AxiosResponse<string, string>,
    Error,
    EligibilityConnectionMutationPayload
  >({
    mutationFn: (query: EligibilityConnectionMutationPayload) => {
      return trabaApi.patch(`my-company/eligibility-connections`, {
        ...query,
      })
    },
    onSuccess: () => {
      showSuccess('Eligibility connections updated successfully')
    },
    onError: (err) => {
      showError(
        `There was an error updating eligibility connections: ${err.message}`,
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [ELIGIBILITY_CONNECTIONS_QUERY_KEY],
      })
    },
  })

  const mutationFnWrapper = (payload: EligibilityConnectionMutationPayload) =>
    mutation.mutate(payload)

  return {
    mutate: mutationFnWrapper,
    isPending: mutation.isPending,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  }
}
